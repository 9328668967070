/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Card, Flex, Heading, Img, Text } from 'pouncejs';
import TelescopeSVG from 'Assets/telescope.svg';
import ElapsedTime from 'Components/ElapsedTime';
import FolderWithMagnifyingGlassSVG from 'Assets/folder-magnifying-glass.svg';
import BulletedLoading from 'Components/BulletedLoading';
import DataScanned from 'Components/DataScanned';

const CONTAINER_HEIGHT = 170;

const AsyncQueryPristineStatus: React.FC = () => (
  <Flex direction="column" justify="center" align="center" spacing={4} height={CONTAINER_HEIGHT}>
    <Img src={TelescopeSVG} nativeWidth={65} nativeHeight={65} alt="Telescope" />
    <Heading size="x-small" color="navyblue-100">
      Nothing searched yet
    </Heading>
  </Flex>
);

const AsyncQueryProvisioningStatus: React.FC = () => (
  <Flex direction="column" justify="center" align="center" spacing={4} height={CONTAINER_HEIGHT}>
    <Card opacity={0.3} variant="dark">
      <ElapsedTime ms={0} />
    </Card>
    <BulletedLoading />
    <Heading size="x-small" color="navyblue-100">
      Provisioning
    </Heading>
  </Flex>
);

interface AsyncQueryEmptyStatusProps {
  timeElapsed: number;
  bytesScanned?: number;
}

const AsyncQueryEmptyStatus: React.FC<AsyncQueryEmptyStatusProps> = ({
  timeElapsed,
  bytesScanned,
}) => (
  <Flex
    direction="column"
    justify="center"
    align="center"
    spacing={6}
    minHeight={CONTAINER_HEIGHT}
    p={7}
  >
    <Flex spacing={2}>
      <Card variant="dark">
        <ElapsedTime ms={timeElapsed} />
      </Card>
      {bytesScanned >= 0 && (
        <Card variant="dark">
          <DataScanned bytes={bytesScanned} />
        </Card>
      )}
    </Flex>
    <Img
      src={FolderWithMagnifyingGlassSVG}
      nativeWidth={50}
      nativeHeight={50}
      alt="Folder with magnifying glass"
    />
    <Heading size="x-small" color="navyblue-100">
      Your query did not return any results
    </Heading>
  </Flex>
);

interface AsyncQueryRunningStatusProps {
  timeElapsed: number;
}

const AsyncQueryRunningStatus: React.FC<AsyncQueryRunningStatusProps> = ({ timeElapsed }) => (
  <Flex direction="column" justify="center" align="center" spacing={4} height={CONTAINER_HEIGHT}>
    <Card variant="dark">
      <ElapsedTime ms={timeElapsed} />
    </Card>
    <BulletedLoading />
    <Heading size="x-small" color="navyblue-100">
      Running
    </Heading>
  </Flex>
);

interface AsyncQueryErroredStatusProps {
  errorMessage: string;
}

const AsyncQueryErroredStatus: React.FC<AsyncQueryErroredStatusProps> = ({ errorMessage }) => (
  <Flex direction="column" justify="center" align="center" spacing={4} height={CONTAINER_HEIGHT}>
    <Text
      p={4}
      m={4}
      borderRadius="large"
      textAlign="center"
      backgroundColor="pink-700"
      fontSize="medium"
    >
      {errorMessage}
    </Text>
  </Flex>
);

const AsyncQueryStatus = {
  Pristine: AsyncQueryPristineStatus,
  Provisioning: AsyncQueryProvisioningStatus,
  Empty: AsyncQueryEmptyStatus,
  Errored: AsyncQueryErroredStatus,
  Running: AsyncQueryRunningStatus,
} as const;

export default AsyncQueryStatus;
