/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Permission } from 'Generated/schema';
import { Box, Link, Table } from 'pouncejs';
import urls from 'Source/urls';
import { formatDatetime } from 'Helpers/utils';
import { Link as RRLink } from 'react-router-dom';
import RoleRestrictedAccess from 'Components/utils/RoleRestrictedAccess';
import StatusBadge from 'Components/badges/StatusBadge';
import PolicyDetailsTableRowOptions from './PolicyDetailsTableRowOptions';
import { GetPolicyResources } from '../graphql/getPolicyResources.generated';

export interface PolicyDetailsTableProps {
  items?: GetPolicyResources['resourcesForPolicy']['items'];
}

const PolicyDetailsTable: React.FC<PolicyDetailsTableProps> = ({ items }) => {
  return (
    <Table>
      <Table.Head>
        <Table.Row>
          <Table.HeaderCell>Resource</Table.HeaderCell>
          <Table.HeaderCell>Source</Table.HeaderCell>
          <Table.HeaderCell align="center">Status</Table.HeaderCell>
          <Table.HeaderCell align="right">Last Modified</Table.HeaderCell>
          <RoleRestrictedAccess
            allowedPermissions={[Permission.PolicyModify, Permission.ResourceModify]}
          >
            <Table.HeaderCell />
          </RoleRestrictedAccess>
        </Table.Row>
      </Table.Head>
      <Table.Body>
        {items.map(resource => (
          <Table.Row key={resource.resourceId} data-testid={`row-${resource.status}`}>
            <Table.Cell
              maxWidth={450}
              truncated
              title={resource.resourceId}
              data-testid="resource-link"
            >
              <Link
                as={RRLink}
                data-tid="link-to-resource-from-policy-details"
                to={urls.data.resources.details(resource.resourceId)}
                py={4}
                pr={4}
              >
                {resource.resourceId}
              </Link>
            </Table.Cell>
            <Table.Cell>{resource.integration?.integrationLabel || 'Unknown'}</Table.Cell>
            <Table.Cell align="center">
              <Box my={-1} display="inline-block">
                <StatusBadge
                  status={resource.status}
                  disabled={resource.suppressed}
                  errorMessage={resource.errorMessage}
                  disabledLabel="IGNORED"
                />
              </Box>
            </Table.Cell>
            <Table.Cell align="right">{formatDatetime(resource.lastUpdated)}</Table.Cell>
            <RoleRestrictedAccess
              allowedPermissions={[Permission.PolicyModify, Permission.ResourceModify]}
            >
              <Table.Cell align="right">
                <Box my={-2}>
                  <PolicyDetailsTableRowOptions complianceItem={resource} />
                </Box>
              </Table.Cell>
            </RoleRestrictedAccess>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  );
};

export default React.memo(PolicyDetailsTable);
