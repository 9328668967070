/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { useWizardContext, WizardPanel } from 'Components/Wizard';
import {
  AbstractButton,
  Box,
  Button,
  Card,
  Flex,
  Grid,
  Img,
  Link,
  Text,
  useSnackbar,
} from 'pouncejs';
import { Link as RRLink } from 'react-router-dom';
import urls from 'Source/urls';
import SuccessStatus from 'Assets/statuses/illustration-success.svg';
import NotificationStatus from 'Assets/statuses/notification.svg';
import LinkButton from 'Components/buttons/LinkButton';
import { extractErrorMessage } from 'Helpers/utils';
import { AlertDeliveryFull } from 'Source/graphql/fragments/AlertDeliveryFull.generated';
import { useSendTestAlertLazyQuery } from 'Source/graphql/queries';
import { WizardData as CreateWizardData } from '../../CreateDestinationWizard';
import { WizardData as EditWizardData } from '../../EditDestinationWizard';

type RowProps = { field: string; value: string | number };

const Row: React.FC<RowProps> = ({ field, value }) => {
  return (
    <React.Fragment>
      <Box as="dt" my="auto">
        {field}
      </Box>
      <Text as="dd" fontWeight="bold">
        {value}
      </Text>
    </React.Fragment>
  );
};

const DestinationTestPanel: React.FC = () => {
  const [testResponses, setTestResponses] = React.useState<AlertDeliveryFull[]>([]);
  const {
    data: { destination },
    reset,
    goToPrevStep,
  } = useWizardContext<CreateWizardData & EditWizardData>();

  const { pushSnackbar } = useSnackbar();

  const [sendTestAlert, { loading }] = useSendTestAlertLazyQuery({
    fetchPolicy: 'network-only', // Don't use cache
    variables: {
      input: {
        outputIds: [destination.outputId],
      },
    },
    // Failed deliveries will also trigger onCompleted as we don't return exceptions
    onCompleted: data => setTestResponses(data.sendTestAlert),
    // This will be fired if there was a network issue or other unknown internal exception
    onError: error => {
      pushSnackbar({
        variant: 'error',
        title:
          extractErrorMessage(error) || 'Failed to send a test alert to the given destination(s)',
      });
    },
  });

  const handleTestAlertClick = React.useCallback(() => {
    sendTestAlert();
    // FIXME: look into hook dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [destination]);

  // We are not expecting more than one response since we are sending one ID
  if (testResponses.length && testResponses[0].success === false) {
    const { dispatchedAt, message, outputId, statusCode, success } = testResponses[0];
    return (
      <Box maxWidth={700} mx="auto">
        <WizardPanel.Heading
          title="Testing your Destination"
          subtitle="Something went wrong and the destination you have configured did not receive the test alert. Please update your destination settings and try again."
        />
        <Flex direction="column" align="center" spacing={6} my={6}>
          <Card backgroundColor="pink-700" p={6}>
            <Grid
              as="dl"
              wordBreak="break-word"
              templateColumns="max-content 1fr"
              fontSize="medium"
              fontWeight="medium"
              gap={4}
            >
              <Row field="Dispatched at" value={dispatchedAt} />
              <Row field="Message" value={message} />
              <Row field="Output ID" value={outputId} />
              <Row field="Status Code" value={statusCode} />
              <Row field="Success" value={success.toString()} />
            </Grid>
          </Card>
          <Text>
            If you don{"'"}t feel like it right now, you can always change the configuration later
          </Text>
          <LinkButton
            to={urls.integrations.destinations.edit(destination.outputId)}
            onClick={goToPrevStep}
          >
            Back to Configuration
          </LinkButton>
          <Link as={RRLink} variant="discreet" to={urls.integrations.destinations.list()}>
            Skip Testing
          </Link>
        </Flex>
      </Box>
    );
  }

  if (testResponses.length && testResponses.every(response => response.success === true)) {
    return (
      <Box maxWidth={700} mx="auto">
        <WizardPanel.Heading
          title="Testing your Destination"
          subtitle="Everything worked as planned and your destination received the triggered alert. You can always send additional test alerts from the destinations page."
        />
        <Flex direction="column" align="center" spacing={6} my={6}>
          <Img
            nativeWidth={120}
            nativeHeight={120}
            alt="Test Alert received"
            src={NotificationStatus}
          />
          <Text>Signed, sealed, and delivered. You are good to go!</Text>
          <LinkButton to={urls.integrations.destinations.list()}>Finish Setup</LinkButton>
          <Link as={AbstractButton} variant="discreet" onClick={reset}>
            Add Another
          </Link>
        </Flex>
      </Box>
    );
  }

  return (
    <Box maxWidth={700} mx="auto">
      <WizardPanel.Heading
        title="Everything looks good!"
        subtitle="Your destination was successfully added and you will receive alerts based on your configuration. You can always edit or delete this destination from the destinations page"
      />
      <Flex direction="column" align="center" spacing={6} my={6}>
        <Img nativeWidth={157} nativeHeight={118} alt="Success" src={SuccessStatus} />
        <Text mb={5}>Do you want to try it out by sending a test Alert?</Text>
        <Box>
          <Button loading={loading} disabled={loading} onClick={handleTestAlertClick}>
            Send Test Alert
          </Button>
        </Box>
        <Link as={RRLink} variant="discreet" to={urls.integrations.destinations.list()}>
          Finish Setup
        </Link>
      </Flex>
    </Box>
  );
};

export default DestinationTestPanel;
