/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { TestSchemaResult } from 'Generated/schema';
import useSession from 'Hooks/useSession';
import { useInferSchema, InferSchemaMutationFn } from 'Source/graphql/queries';
import {
  TestSchemaMutationFn,
  useTestSchema,
} from 'Components/forms/DataSchemaForm/SampleDataSection/graphql/testSchema.generated';

interface SchemaGenerationError {
  title: string;
  description: string;
}

export interface SchemaGenerationContextValue {
  schema: string;
  setSchema: (schema: string) => void;
  results: TestSchemaResult;
  sessionId: string;
  setSessionId: (session: string) => void;
  reset: () => void;
  inferSchema: InferSchemaMutationFn;
  inferringSchema: boolean;
  testSchema: TestSchemaMutationFn;
  testingSchema: boolean;
  error: SchemaGenerationError | null;
  setError: (err: SchemaGenerationError | null) => void;
  // Set to True to disable schema inferring
  readOnly: boolean;
}

const SchemaGenerationContext = React.createContext<SchemaGenerationContextValue>(undefined);

interface SchemaGenerationProviderProps<> {
  children: React.ReactNode;
  sessionKey: string;
  initialSchema?: string;
  readOnly?: boolean;
}

function SchemaGenerationProvider({
  initialSchema,
  sessionKey,
  children,
  readOnly = false,
}: SchemaGenerationProviderProps) {
  const [schema, setSchema] = React.useState(initialSchema);
  const [results, setResults] = React.useState<TestSchemaResult>();
  const [error, setError] = React.useState<SchemaGenerationError>(null);

  const { session: sessionId, setSession: setSessionId, clearSession } = useSession<string>({
    sessionKey,
  });

  const [inferSchema, { loading: inferringSchema }] = useInferSchema();
  const [testSchema, { loading: testingSchema }] = useTestSchema({
    onCompleted: data => setResults(data.testSchema?.result),
  });
  /**
   * @public
   * Reset all related fields to schema generation
   *
   */
  const reset = React.useCallback(() => {
    clearSession();
    setResults(null);
    setError(null);
  }, [clearSession, setResults]);

  const contextValue = React.useMemo(
    () => ({
      schema,
      setSchema,
      results,
      inferringSchema,
      inferSchema,
      testSchema,
      testingSchema,
      sessionId,
      setSessionId,
      reset,
      error,
      setError,
      readOnly,
    }),
    [
      schema,
      setSchema,
      results,
      testSchema,
      testingSchema,
      inferringSchema,
      inferSchema,
      sessionId,
      setSessionId,
      reset,
      error,
      setError,
      readOnly,
    ]
  );

  return (
    <SchemaGenerationContext.Provider value={contextValue}>
      {children}
    </SchemaGenerationContext.Provider>
  );
}

const MemoizedSchemaGenerationProvider = React.memo(SchemaGenerationProvider);

const withSchemaGenerationContext = (config?: Omit<SchemaGenerationProviderProps, 'children'>) => (
  Component: React.FC
) => props => (
  <SchemaGenerationProvider {...config}>
    <Component {...props} />
  </SchemaGenerationProvider>
);

const useSchemaGeneration = () =>
  React.useContext<SchemaGenerationContextValue>(SchemaGenerationContext);

export {
  SchemaGenerationContext,
  MemoizedSchemaGenerationProvider as SchemaGenerationProvider,
  withSchemaGenerationContext,
  useSchemaGeneration,
};
