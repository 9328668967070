/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Form, Formik, FastField } from 'formik';
import { Box, Flex } from 'pouncejs';
import { AlertsInput, SortDirEnum, AlertsSortFieldsEnum } from 'Generated/schema';
import {
  SortingOption,
  wrapSortingOptions,
  extractSortingOptions,
  WrapSortingFormValues,
} from 'Helpers/utils';
import useRequestParamsWithoutPagination from 'Hooks/useRequestParamsWithoutPagination';
import pick from 'lodash/pick';
import FormikAutosave from 'Components/utils/Autosave';
import FormikCombobox from 'Components/fields/ComboBox';
import FormikTextInput from 'Components/fields/TextInput';
import DropdownFilters, { ListAlertsDropdownFiltersValues } from './DropdownFilters';

export type ListAlertsInlineFiltersValues = Pick<
  AlertsInput,
  'sortBy' | 'sortDir' | 'nameContains'
>;

export type ListAlertsFiltersKeys =
  | keyof ListAlertsDropdownFiltersValues
  | keyof ListAlertsInlineFiltersValues;

export interface ListAlertFiltersProps {
  excludedFields?: readonly ListAlertsFiltersKeys[];
}

const filters: (keyof ListAlertsInlineFiltersValues)[] = ['nameContains', 'sortBy', 'sortDir'];

const defaultValues = {
  nameContains: '',
  sorting: null,
};

const sortingOpts: SortingOption<ListAlertsInlineFiltersValues>[] = [
  {
    opt: 'Most Recent',
    resolution: {
      sortBy: AlertsSortFieldsEnum.CreatedAt,
      sortDir: SortDirEnum.Descending,
    },
  },
  {
    opt: 'Oldest',
    resolution: {
      sortBy: AlertsSortFieldsEnum.CreatedAt,
      sortDir: SortDirEnum.Ascending,
    },
  },
];

const ListAlertFilters: React.FC<ListAlertFiltersProps> = ({ excludedFields = [] }) => {
  const { requestParams, updateRequestParams } = useRequestParamsWithoutPagination<AlertsInput>();

  const initialFilterValues = React.useMemo(
    () => ({
      ...defaultValues,
      ...wrapSortingOptions(pick(requestParams, filters), sortingOpts),
    }),
    [requestParams]
  );

  const shouldDisplayFilter = (filter: ListAlertsFiltersKeys) => !excludedFields.includes(filter);
  return (
    <Flex justify="flex-end" align="center" width="80%">
      <Box flexGrow={3}>
        <Formik<WrapSortingFormValues<ListAlertsInlineFiltersValues>>
          enableReinitialize
          initialValues={initialFilterValues}
          onSubmit={values => {
            updateRequestParams(extractSortingOptions(values, sortingOpts));
          }}
        >
          <Form>
            <FormikAutosave threshold={200} />
            <Flex spacing={4} justify="flex-end" align="center" px={4} width="100%">
              {shouldDisplayFilter('nameContains') && (
                <Box minWidth={425} maxWidth={440} flexGrow={3}>
                  <FastField
                    data-tid="alerts-nameContains-filter"
                    name="nameContains"
                    icon="search"
                    iconAlignment="left"
                    as={FormikTextInput}
                    label="Filter Alerts by text"
                    placeholder="Search for an alert..."
                  />
                </Box>
              )}
              {shouldDisplayFilter('sortBy') && (
                <Box>
                  <FastField
                    data-tid="alerts-sorting-options-combobox"
                    name="sorting"
                    data-testid="list-alert-sorting"
                    as={FormikCombobox}
                    items={sortingOpts.map(sortingOption => sortingOption.opt)}
                    placeholder="Select a sort option"
                    label="Sort By"
                  />
                </Box>
              )}
            </Flex>
          </Form>
        </Formik>
      </Box>
      <DropdownFilters excludedFields={excludedFields} />
    </Flex>
  );
};

export default React.memo(ListAlertFilters);
