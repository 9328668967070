/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import * as Types from '../../../../__generated__/schema';

import { RoleDetails } from '../../../graphql/fragments/RoleDetails.generated';
import { GraphQLError } from 'graphql';
import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';

export type UpdateRoleVariables = {
  input: Types.UpdateRoleInput;
};

export type UpdateRole = { updateRole: { role: RoleDetails } };

export const UpdateRoleDocument = gql`
  mutation UpdateRole($input: UpdateRoleInput!) {
    updateRole(input: $input) {
      role {
        ...RoleDetails
      }
    }
  }
  ${RoleDetails}
`;
export type UpdateRoleMutationFn = ApolloReactCommon.MutationFunction<
  UpdateRole,
  UpdateRoleVariables
>;

/**
 * __useUpdateRole__
 *
 * To run a mutation, you first call `useUpdateRole` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRole` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRole, { data, loading, error }] = useUpdateRole({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateRole(
  baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateRole, UpdateRoleVariables>
) {
  return ApolloReactHooks.useMutation<UpdateRole, UpdateRoleVariables>(
    UpdateRoleDocument,
    baseOptions
  );
}
export type UpdateRoleHookResult = ReturnType<typeof useUpdateRole>;
export type UpdateRoleMutationResult = ApolloReactCommon.MutationResult<UpdateRole>;
export type UpdateRoleMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateRole,
  UpdateRoleVariables
>;
export function mockUpdateRole({
  data,
  variables,
  errors,
}: {
  data: UpdateRole;
  variables?: UpdateRoleVariables;
  errors?: GraphQLError[];
}) {
  return {
    request: { query: UpdateRoleDocument, variables },
    result: { data, errors },
  };
}
