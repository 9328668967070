/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Field, Form, Formik } from 'formik';
import { Box, Button, Card, Flex, Popover, PopoverContent, PopoverTrigger } from 'pouncejs';
import { ListSavedQueriesInput } from 'Generated/schema';
import isUndefined from 'lodash/isUndefined';
import TextButton from 'Components/buttons/TextButton';
import FormikCombobox from 'Components/fields/ComboBox';
import FormikMultiCombobox from 'Components/fields/MultiComboBox';
import useRequestParamsWithoutPagination from 'Hooks/useRequestParamsWithoutPagination';
import PopoverAutoSubmit from 'Components/PopoverAutoSubmit';

type ListSavedQueriesDropdownFilterValues = Pick<
  ListSavedQueriesInput,
  'isActive' | 'isScheduled' | 'tags'
>;

const booleanComboboxItems = [true, false];
const booleanComboboxItemToString = (value: boolean | null) => {
  if (value === null) {
    return '';
  }

  return value ? 'Yes' : 'No';
};

const defaultDropdownValues: ListSavedQueriesInput = {
  isActive: null,
  isScheduled: null,
  tags: [],
};

const DropdownFilters: React.FC = () => {
  const { requestParams, updateRequestParams } = useRequestParamsWithoutPagination<
    ListSavedQueriesInput
  >();

  const initialDropdownFilters = React.useMemo(
    () =>
      ({
        ...defaultDropdownValues,
        ...requestParams,
      } as ListSavedQueriesDropdownFilterValues),
    [requestParams]
  ) as ListSavedQueriesDropdownFilterValues;

  const handleSubmit = React.useCallback(
    (values: ListSavedQueriesDropdownFilterValues) => updateRequestParams(values),
    [updateRequestParams]
  );

  const filtersCount = React.useMemo(
    () => Object.keys(defaultDropdownValues).filter(key => !isUndefined(requestParams[key])).length,
    [requestParams]
  );

  return (
    <Popover>
      {({ close: closePopover, isOpen }) => (
        <React.Fragment>
          <PopoverTrigger
            data-tid="saved-queries-dropdown-filters"
            as={Button}
            iconAlignment="right"
            icon="filter-light"
            size="large"
            aria-label="Saved Queries Options"
          >
            Filters {filtersCount ? `(${filtersCount})` : ''}
          </PopoverTrigger>
          <PopoverContent>
            <Card
              shadow="dark300"
              my={14}
              p={6}
              pb={4}
              backgroundColor="navyblue-400"
              width={425}
              data-testid="dropdown-saved-queries-listing-filters"
            >
              <Formik<ListSavedQueriesDropdownFilterValues>
                enableReinitialize
                onSubmit={handleSubmit}
                initialValues={initialDropdownFilters}
              >
                {({ setValues, values }) => (
                  <Form>
                    <PopoverAutoSubmit<ListSavedQueriesDropdownFilterValues>
                      isOpen={isOpen}
                      values={values}
                      onSubmit={updateRequestParams}
                    />
                    <Flex direction="column" spacing={4}>
                      <Field
                        as={FormikCombobox}
                        name="isScheduled"
                        items={booleanComboboxItems}
                        itemToString={booleanComboboxItemToString}
                        label="Is Scheduled?"
                        placeholder="Filter saved queries by schedule..."
                      />
                      <Field
                        as={FormikCombobox}
                        name="isActive"
                        items={booleanComboboxItems}
                        itemToString={booleanComboboxItemToString}
                        label="Is Active?"
                        placeholder="Filter scheduled queries by status..."
                      />
                      <Field
                        as={FormikMultiCombobox}
                        label="Tags"
                        searchable
                        allowAdditions
                        name="tags"
                        items={[]}
                        placeholder="Enter tags to filter by..."
                      />

                      <Flex direction="column" justify="center" align="center" spacing={4} my={2}>
                        <Box>
                          <Button onClick={closePopover}>Apply Filters</Button>
                        </Box>
                        <TextButton role="button" onClick={() => setValues(defaultDropdownValues)}>
                          Clear Filters
                        </TextButton>
                      </Flex>
                    </Flex>
                  </Form>
                )}
              </Formik>
            </Card>
          </PopoverContent>
        </React.Fragment>
      )}
    </Popover>
  );
};

export default React.memo(DropdownFilters);
