/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Flex } from 'pouncejs';
import urls from 'Source/urls';
import FadeInTrail from 'Components/utils/FadeInTrail';
import { NavigationLink } from 'Components/Navigation/Navigation';
import RoleRestrictedAccess from 'Components/utils/RoleRestrictedAccess/RoleRestrictedAccess';
import { Permission } from 'Generated/schema';
import NavLink from '../NavLink';

export const dataEnrichmentLinks: NavigationLink[] = [
  {
    to: urls.enrichment.lookupTables.list(),
    icon: 'lookups',
    label: 'Lookup Tables',
    permissions: [Permission.LookupRead, Permission.LookupModify],
  },
  {
    to: urls.enrichment.sources.home(),
    icon: 'integrations',
    label: 'Sources',
    permissions: [Permission.LookupRead, Permission.LookupModify],
  },
];

const DataEnrichmentNavigation = () => {
  return (
    <Flex direction="column" as="ul">
      <FadeInTrail as="li">
        {dataEnrichmentLinks.map(({ to, icon, label, permissions }) => {
          return permissions?.length ? (
            <RoleRestrictedAccess key={label} allowedPermissions={permissions}>
              <NavLink icon={icon} label={label} to={to} isSecondary />
            </RoleRestrictedAccess>
          ) : (
            <NavLink key={label} icon={icon} label={label} to={to} isSecondary />
          );
        })}
      </FadeInTrail>
    </Flex>
  );
};

export default React.memo(DataEnrichmentNavigation);
