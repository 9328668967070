/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Field, useFormikContext } from 'formik';
import { Box, Flex } from 'pouncejs';

import boxMinimalLogo from 'Assets/box-logo.svg';
import FormikTextInput from 'Components/fields/TextInput';
import FormikMultiCombobox from 'Components/fields/MultiComboBox';
import useAvailableLogTypesForLogPuller from 'Hooks/useAvailableLogTypesForLogPuller';
import { WizardPanel } from 'Components/Wizard';
import EditIntegrationActions from 'Pages/Integrations/components/EditIntegrationActions';
import { BoxLogsSourceWizardValues } from '../BoxLogsSourceWizard';

const BoxLogsSourceConfigurationPanel: React.FC = () => {
  const { initialValues, errors, values } = useFormikContext<BoxLogsSourceWizardValues>();
  const editMode = !!initialValues.integrationId;
  const { availableLogTypes } = useAvailableLogTypesForLogPuller();

  return (
    <WizardPanel>
      <Box width={500} m="auto">
        <WizardPanel.Heading
          title={editMode ? 'Update your Box source' : "Let's start with the basics"}
          subtitle="Give an appropriate name for this integration"
          logo={boxMinimalLogo}
        />
        <Flex direction="column" spacing={4}>
          <Field
            name="integrationLabel"
            as={FormikTextInput}
            label="Name *"
            placeholder="A nickname for your Box log source"
            required
          />
          <Field
            as={FormikMultiCombobox}
            searchable
            placeholder="The Box Log Types you want Panther to process"
            label="Select the Log types you want to monitor *"
            name="logTypes"
            items={availableLogTypes}
            required
            disabled={availableLogTypes.length <= 1}
          />
        </Flex>
      </Box>
      <WizardPanel.Actions>
        {editMode ? (
          <EditIntegrationActions />
        ) : (
          <WizardPanel.ActionNext disabled={!!errors.integrationLabel || !values.integrationLabel}>
            Continue Setup
          </WizardPanel.ActionNext>
        )}
      </WizardPanel.Actions>
    </WizardPanel>
  );
};

export default BoxLogsSourceConfigurationPanel;
