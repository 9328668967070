/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { useSnackbar } from 'pouncejs';
import Page403 from 'Pages/403';
import Page404 from 'Pages/404';
import useRouter from 'Hooks/useRouter';
import { extractErrorMessage } from 'Helpers/utils';
import { AsanaLogsSourceWizardValues } from 'Pages/Integrations/LogSources/Asana/AsanaWizard/AsanaWizard';
import { compose } from 'Helpers/compose';
import withRoleRestrictedAccess from 'Hoc/withRoleRestrictedAccess';
import withSEO from 'Hoc/withSEO';
import { Permission } from 'Generated/schema';
import { useUpdateAsanaLogSource } from '../graphql/updateAsanaLogSource.generated';
import { useGetAsanaLogSource } from '../graphql/getAsanaLogSource.generated';
import AsanaWizard from '../AsanaWizard';

const EditAsanaLogSource = () => {
  const { pushSnackbar } = useSnackbar();
  const { match } = useRouter<{ id: string }>();
  const { data, error: getAsanaError } = useGetAsanaLogSource({
    variables: { id: match.params.id },
    onError: error => {
      pushSnackbar({
        title: extractErrorMessage(error) || 'An unknown error occurred',
        variant: 'error',
      });
    },
  });

  const [updateAsanaLogSource] = useUpdateAsanaLogSource();

  const initialValues = React.useMemo(
    () => ({
      integrationId: match.params.id,
      logTypes: data?.getLogPullingIntegration?.logTypes ?? [],
      integrationLabel: data?.getLogPullingIntegration.integrationLabel ?? 'Loading...',
      organizationId: data?.getLogPullingIntegration?.pullerConfig?.asana?.organizationId ?? '',
      serviceAccountToken:
        data?.getLogPullingIntegration?.pullerConfig?.asana?.serviceAccountToken ?? '',
    }),
    [data, match]
  );

  const handleSubmit = React.useCallback(
    async (values: AsanaLogsSourceWizardValues) => {
      try {
        const {
          data: { updateLogPullingIntegration },
        } = await updateAsanaLogSource({
          variables: {
            input: {
              integrationId: values.integrationId,
              integrationLabel: values.integrationLabel,
              pullerConfig: {
                logTypes: values.logTypes,
                asana: {
                  organizationId: values.organizationId,
                  serviceAccountToken: values.serviceAccountToken,
                },
              },
            },
          },
        });
        return updateLogPullingIntegration;
      } catch (error) {
        return error as Error;
      }
    },
    [updateAsanaLogSource]
  );

  // we optimistically assume that an error in "get" is a 404. We don't have any other info
  if (getAsanaError) {
    return <Page404 />;
  }

  return <AsanaWizard initialValues={initialValues} onSubmit={handleSubmit} />;
};

export default compose(
  withSEO({ title: 'Edit Asana Source' }),
  withRoleRestrictedAccess({
    allowedPermissions: [Permission.LogSourceModify],
    fallback: <Page403 />,
  })
)(EditAsanaLogSource);
