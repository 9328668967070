/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */
import React from 'react';
import { Link as RRLink } from 'react-router-dom';
import { Badge, Box, Card, Flex, Icon, Link, Img, Tooltip } from 'pouncejs';
import BulletDivider from 'Components/BulletDivider';
import { CardMetaValue, CardValue } from 'Components/cards/common';
import Linkify from 'Components/Linkify';
import DisabledLink from 'Components/DisabledLink';
import RoleRestrictedAccess from 'Components/utils/RoleRestrictedAccess';
import { Permission } from 'Generated/schema';
import { formatDatetime, formatNumber, getElapsedTime } from 'Helpers/utils';
import { LookupDetails } from 'Source/graphql/fragments/LookupDetails.generated';
import urls from 'Source/urls';
import LimitItemDisplay from 'Components/LimitItemDisplay';
import AdditionalBadgesJewel from 'Components/AdditionalBadgesJewel';
import SourceHealthWarning from 'Components/SourceHealthWarning';
import useCheckPermissions from 'Hooks/useCheckPermissions';
import IpInfoLogoIcon from 'Assets/ipinfo-logo.svg';
import GreyNoiseLogoIcon from 'Assets/greynoise-logo.svg';
import S3LogoIcon from 'Assets/logProviders/s3.svg';
import LookupCardOptions from './LookupCardOptions';
import ResyncButton from './LookupCardOptions/ResyncButton';

interface LookupCardProps {
  lookup: LookupDetails;
}

const NUMBER_OF_LOGTYPES_DISPLAYED = 3;
const MAX_LENGTH = 50;

const LookupCardTitle = ({ lookup }: LookupCardProps) => {
  const hasLookupModifyPermissions = useCheckPermissions(Permission.LookupModify);

  if (!hasLookupModifyPermissions) {
    return <DisabledLink>{lookup.name}</DisabledLink>;
  }

  const href = lookup.managed
    ? urls.enrichment.sources.details(lookup.id)
    : urls.enrichment.lookupTables.edit(lookup.id);

  return (
    <Link as={RRLink} to={href} data-tid="link-to-lookup">
      {lookup.name}
    </Link>
  );
};

const LookupCardLogo = ({ lookup }: { lookup: LookupDetails }) => {
  const logo = React.useMemo(() => {
    const [namespace] = lookup.activeVersion?.logType?.split('.') ?? [];

    const logoSchemaMap = {
      GreyNoise: {
        icon: GreyNoiseLogoIcon,
        alt: 'GreyNoise logo',
      },
      IPInfo: {
        icon: IpInfoLogoIcon,
        alt: 'IPInfo logo',
      },
    };

    const fallbackIcon = { icon: S3LogoIcon, alt: 'Unknown enrichment source' };

    return logoSchemaMap[namespace] ?? fallbackIcon;
  }, [lookup.activeVersion?.logType]);

  return (
    <Box>
      <Img src={logo.icon} alt={logo.alt} nativeWidth={24} />
    </Box>
  );
};

const LookupCard: React.FC<LookupCardProps> = ({ lookup }) => {
  const associatedLogTypes = lookup.activeVersion?.associatedLogTypes ?? [];
  const hasError = Boolean(lookup.activeVersion?.erroredAt);
  const shouldTruncate = lookup?.reference?.length > MAX_LENGTH;
  const shouldShowResyncButton = !lookup.managed && lookup.refresh;

  const lastRecievedDataValue = React.useMemo(() => {
    if (!lookup.activeVersion?.refreshedAt) {
      return 'No Data Received Yet';
    }

    return getElapsedTime(Math.floor(new Date(lookup.activeVersion.refreshedAt).getTime() / 1000));
  }, [lookup.activeVersion?.refreshedAt]);

  return (
    <Card
      as="section"
      variant="dark"
      p={4}
      data-testid={`LookupCard`}
      {...(hasError && { borderLeft: '4px solid', borderLeftColor: 'red-300' })}
    >
      <Flex spacing={4}>
        {lookup.managed && <LookupCardLogo lookup={lookup} />}
        <Flex direction="column" spacing={3} width="100%">
          <Flex as="header" justify="space-between" width={1}>
            <Flex align="center" mr="auto">
              <Box as="h4" fontWeight="medium" mr="auto" wordBreak="break-word">
                <RoleRestrictedAccess
                  allowedPermissions={[Permission.LookupModify]}
                  fallback={<DisabledLink>{lookup.name}</DisabledLink>}
                >
                  <LookupCardTitle lookup={lookup} />
                </RoleRestrictedAccess>
              </Box>
            </Flex>
            <Flex align="center">
              {!lookup.enabled && (
                <>
                  <Icon type="disabled" size="small" aria-hidden="true" />
                  <Box fontSize="small" ml={1}>
                    Lookup Table Disabled
                  </Box>
                  <BulletDivider />
                </>
              )}

              <CardMetaValue label="Last Received Data" value={lastRecievedDataValue} />
              {shouldShowResyncButton && <ResyncButton lookup={lookup} />}
              <Box ml={2}>
                <LookupCardOptions lookup={lookup} />
              </Box>
            </Flex>
          </Flex>

          {hasError && (
            <SourceHealthWarning
              unhealthyMetrics={[
                {
                  message: 'Healthcheck Failed',
                  healthy: !hasError,
                  rawErrorMessage: lookup.activeVersion?.errorMessage,
                },
              ]}
              title="Source has turned Unhealthy"
            />
          )}

          {lookup.description && <Box as="p">{lookup.description}</Box>}

          <Flex align="flex-end">
            <Flex spacing={6} flexGrow={1}>
              <CardValue label="Key Field" value={lookup?.activeVersion?.primaryKey} />
              <CardValue
                label="Schema"
                value={
                  <Link href={`/data/data-schemas/${lookup?.activeVersion?.logType}`}>
                    {lookup.activeVersion.logType}
                  </Link>
                }
              />
              <CardValue
                label="Entries"
                value={formatNumber(lookup?.activeVersion?.rowCount || 0)}
              />
              {!lookup.managed && (
                <CardValue
                  label="Data Import Method"
                  value={lookup?.refresh === null ? 'Manual Upload' : 'S3 Sync'}
                />
              )}
              {!lookup.managed && (
                <CardValue label="Created" value={formatDatetime(lookup.createdAt)} />
              )}

              {Boolean(lookup.reference) &&
                (shouldTruncate ? (
                  <Tooltip content={<Box maxWidth="300px">{lookup.reference}</Box>}>
                    <div>
                      <CardValue
                        label="Reference"
                        value={
                          <Linkify>{`${lookup.reference.substring(0, MAX_LENGTH)}...`}</Linkify>
                        }
                      />
                    </div>
                  </Tooltip>
                ) : (
                  <CardValue label="Reference" value={<Linkify>{lookup.reference}</Linkify>} />
                ))}
            </Flex>

            <Flex spacing={1} align="center">
              <LimitItemDisplay
                limit={NUMBER_OF_LOGTYPES_DISPLAYED}
                AdditionalItemsComponent={AdditionalBadgesJewel}
              >
                {associatedLogTypes.map(associatedLogType => (
                  <Badge key={associatedLogType.logType} color="navyblue-200">
                    {associatedLogType.logType}
                  </Badge>
                ))}
              </LimitItemDisplay>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Card>
  );
};

export default React.memo(LookupCard);
