/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import * as Types from '../../../../../../../../__generated__/schema';

import { HoldingTankTestJobDetails } from '../../../../../../../graphql/fragments/HoldingTankTestJobDetails.generated';
import { GraphQLError } from 'graphql';
import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';

export type TestHoldingTankSchemasVariables = {
  input: Types.TestHoldingTankSchemasInput;
};

export type TestHoldingTankSchemas = { testHoldingTankSchemas: HoldingTankTestJobDetails };

export const TestHoldingTankSchemasDocument = gql`
  mutation TestHoldingTankSchemas($input: TestHoldingTankSchemasInput!) {
    testHoldingTankSchemas(input: $input) {
      ...HoldingTankTestJobDetails
    }
  }
  ${HoldingTankTestJobDetails}
`;
export type TestHoldingTankSchemasMutationFn = ApolloReactCommon.MutationFunction<
  TestHoldingTankSchemas,
  TestHoldingTankSchemasVariables
>;

/**
 * __useTestHoldingTankSchemas__
 *
 * To run a mutation, you first call `useTestHoldingTankSchemas` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTestHoldingTankSchemas` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [testHoldingTankSchemas, { data, loading, error }] = useTestHoldingTankSchemas({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTestHoldingTankSchemas(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    TestHoldingTankSchemas,
    TestHoldingTankSchemasVariables
  >
) {
  return ApolloReactHooks.useMutation<TestHoldingTankSchemas, TestHoldingTankSchemasVariables>(
    TestHoldingTankSchemasDocument,
    baseOptions
  );
}
export type TestHoldingTankSchemasHookResult = ReturnType<typeof useTestHoldingTankSchemas>;
export type TestHoldingTankSchemasMutationResult = ApolloReactCommon.MutationResult<
  TestHoldingTankSchemas
>;
export type TestHoldingTankSchemasMutationOptions = ApolloReactCommon.BaseMutationOptions<
  TestHoldingTankSchemas,
  TestHoldingTankSchemasVariables
>;
export function mockTestHoldingTankSchemas({
  data,
  variables,
  errors,
}: {
  data: TestHoldingTankSchemas;
  variables?: TestHoldingTankSchemasVariables;
  errors?: GraphQLError[];
}) {
  return {
    request: { query: TestHoldingTankSchemasDocument, variables },
    result: { data, errors },
  };
}
