/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import dayjs from 'dayjs';
import { Box, Flex } from 'pouncejs';
import { ExecuteIndicatorSearchQueryInput, IndicatorType } from 'Generated/schema';
import withAsyncQueryContext from 'Hoc/withAsyncQueryContext';
import useMounted from 'Hooks/useMounted';
import useAsyncQueryContext from 'Hooks/useAsyncQueryContext';
import useAsyncQueryExecution from 'Hooks/useAsyncQueryExecution';
import useAsyncQueryCancellation from 'Hooks/useAsyncQueryCancellation';
import {
  RunIndicatorTimelineQuery,
  RunIndicatorTimelineQueryVariables,
  useRunIndicatorTimelineQuery,
} from 'Source/graphql/queries/runIndicatorTimelineQuery.generated';
import IndicatorInputForm, { IndicatorInputFormValues } from './IndicatorInputForm';
import Results from './Results';

export interface IndicatorSearchModalProps {
  indicator: string;
  dateCreated?: string;
  dateEnded?: string;
}

const IndicatorSearchModal: React.FC<IndicatorSearchModalProps> = ({
  indicator,
  dateCreated,
  dateEnded,
}) => {
  const isMounted = useMounted();
  const { state: { queryStatus, queryId }, dispatch } = useAsyncQueryContext(); // prettier-ignore
  const { cancelQuery } = useAsyncQueryCancellation();
  const [queryMeta, setQueryMeta] = React.useState<ExecuteIndicatorSearchQueryInput>(null);

  const { executeQuery } = useAsyncQueryExecution<
    RunIndicatorTimelineQuery,
    RunIndicatorTimelineQueryVariables
  >({
    mutation: useRunIndicatorTimelineQuery,
    getResponse: data => data.executeIndicatorSearchQuery,
  });

  const executeQueryAndStoreQueryParameters = React.useCallback(
    (values: IndicatorInputFormValues) => {
      const input = {
        indicatorName: IndicatorType.AutoDetectType,
        indicators: [indicator],
        startTime: dayjs.utc(values.startTime).toISOString(),
        endTime: dayjs.utc(values.endTime).toISOString(),
      };
      setQueryMeta(input);
      return executeQuery({
        input,
      });
    },
    [executeQuery, indicator]
  );

  const initialValues = React.useMemo(() => {
    let startTime = dayjs().utc().startOf('date').format();
    let endTime = dayjs().utc().endOf('date').format();

    // Select +/- 24 hours around the event time if only dateCreated is provided
    if (dateCreated) {
      startTime = dayjs.utc(dateCreated).subtract(1, 'day').format();
      endTime = dayjs.utc(dateCreated).add(1, 'day').format();
    }

    // If a start and end time has been explicitly provided, respect those times
    // and prefill the indicator search to the exact range given.
    if (dateCreated && dateEnded) {
      startTime = dayjs.utc(dateCreated).format();
      endTime = dayjs.utc(dateEnded).format();
    }

    return { startTime, endTime };
  }, [dateCreated, dateEnded]);

  React.useEffect(() => {
    dispatch({ type: 'SWITCH_QUERY', payload: { queryId: null } });
    // FIXME: look into hook dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [indicator]);

  // Cancel the query when unmounting.
  React.useEffect(
    () => () => {
      // We have to check if the component is unmounted to avoid
      // calling cancelQuery every time a dependency is updated.
      if (!isMounted.current && queryStatus === 'running') {
        cancelQuery();
      }
    },
    [cancelQuery, queryStatus, isMounted]
  );

  return (
    <Flex
      width={550}
      direction="column"
      align="center"
      maxHeight="60vh"
      overflowY="auto"
      overflowX="hidden"
    >
      <IndicatorInputForm
        initialValues={initialValues}
        onSubmit={executeQueryAndStoreQueryParameters}
      />
      <Box width="100%">
        <Results key={queryId} indicatorQueryMeta={queryMeta} />
      </Box>
    </Flex>
  );
};

export default withAsyncQueryContext({ storage: 'memory' })(IndicatorSearchModal);
