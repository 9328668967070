/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { useSnackbar } from 'pouncejs';
import useAsyncQueryContext from 'Hooks/useAsyncQueryContext';
import { extractErrorMessage } from 'Helpers/utils';
import { useCancelAsyncQuery } from './graphql/cancelAsyncQuery.generated';

const useAsyncQueryCancellation = () => {
  const { pushSnackbar } = useSnackbar();
  const {
    state: { queryId },
    dispatch,
  } = useAsyncQueryContext();

  const [abandonQuery, { loading: isCancelingQuery }] = useCancelAsyncQuery({
    variables: {
      input: {
        id: queryId,
      },
    },
    onError: error => {
      pushSnackbar({
        variant: 'error',
        title: "Couldn't cancel your query",
        description: extractErrorMessage(error),
      });
    },
  });

  const cancelQuery = React.useCallback(() => {
    // Due to the async nature of the hook, the queryId gets set to null, thus we are trying to cancel a query with queryId `null`.
    if (queryId) {
      abandonQuery();
    }
    dispatch({ type: 'QUERY_CANCELLED' });
  }, [abandonQuery, dispatch, queryId]);

  return { cancelQuery, isCancelingQuery };
};

export default useAsyncQueryCancellation;
