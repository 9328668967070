/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { useSnackbar } from 'pouncejs';
import Page404 from 'Pages/404';
import Page403 from 'Pages/403';
import useRouter from 'Hooks/useRouter';
import withSEO from 'Hoc/withSEO';
import { extractErrorMessage } from 'Helpers/utils';
import useAvailableLogTypesForProvider from 'Hooks/useAvailableLogTypesForProvider';
import { compose } from 'Helpers/compose';
import withRoleRestrictedAccess from 'Hoc/withRoleRestrictedAccess';
import { Permission } from 'Generated/schema';
import { useGetSqsLogSource } from './graphql/getSqsLogSource.generated';
import { useUpdateSqsLogSource } from './graphql/updateSqsLogSource.generated';
import SqsSourceWizard, { SqsLogSourceWizardValues } from '../SqsSourceWizard';

const EditSqsLogSource: React.FC = () => {
  const { pushSnackbar } = useSnackbar();
  const { match } = useRouter<{ id: string }>();
  const { data, error: getError } = useGetSqsLogSource({
    variables: { id: match.params.id },
    onError: error => {
      pushSnackbar({
        title: extractErrorMessage(error) || 'An unknown error occurred',
        variant: 'error',
      });
    },
  });

  const [updateSqsLogSource] = useUpdateSqsLogSource();

  const handleSubmit = React.useCallback(async (values: SqsLogSourceWizardValues) => {
    try {
      const resp = await updateSqsLogSource({
        variables: {
          input: {
            integrationId: values.integrationId,
            integrationLabel: values.integrationLabel,
            sqsConfig: {
              logTypes: values.logTypes,
              allowedPrincipalArns: values.allowedPrincipalArns,
              allowedSourceArns: values.allowedSourceArns,
            },
          },
        },
      });
      return resp.data.updateSqsLogIntegration;
    } catch (err) {
      return err as Error;
    }
    // FIXME: look into missing hook dependencies or using refs
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const initialValues = React.useMemo(
    () => ({
      integrationId: match.params.id,
      integrationLabel: data?.getSqsLogIntegration?.integrationLabel ?? 'Loading...',
      logTypes: data?.getSqsLogIntegration.logTypes ?? [],
      allowedPrincipalArns: data?.getSqsLogIntegration.sqsConfig.allowedPrincipalArns ?? [],
      allowedSourceArns: data?.getSqsLogIntegration.sqsConfig.allowedSourceArns ?? [],
      queueUrl: data?.getSqsLogIntegration.sqsConfig.queueUrl,
    }),
    // FIXME: look into missing hook dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data]
  );

  const { availableLogTypes } = useAvailableLogTypesForProvider(
    data?.getSqsLogIntegration?.logProviderId
  );

  // we optimistically assume that an error in "get" is a 404. We don't have any other info
  if (getError) {
    return <Page404 />;
  }

  return (
    <SqsSourceWizard
      initialValues={initialValues}
      availableLogTypes={availableLogTypes}
      onSubmit={handleSubmit}
    />
  );
};

export default compose(
  withSEO({ title: 'Edit SQS Log Source' }),
  withRoleRestrictedAccess({
    allowedPermissions: [Permission.LogSourceModify],
    fallback: <Page403 />,
  })
)(EditSqsLogSource);
