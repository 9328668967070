/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';

const noop = () => {};

interface UseNavigationKeys {
  ref?: React.RefObject<HTMLElement> | React.MutableRefObject<HTMLElement>;
  nextCallback: (event: KeyboardEvent) => void;
  previousCallback: (event: KeyboardEvent) => void;
  disabled?: boolean;
}

const useNavigationKeys = ({
  ref,
  nextCallback = noop,
  previousCallback = noop,
  disabled,
}: UseNavigationKeys) => {
  const listener = React.useCallback(
    (event: KeyboardEvent) => {
      if (!ref || ref.current?.contains(event.target as HTMLElement)) {
        if (event.key === 'ArrowLeft') {
          previousCallback(event);
        }
        if (event.key === 'ArrowRight') {
          nextCallback(event);
        }
      }
    },
    [nextCallback, previousCallback, ref]
  );

  React.useEffect(() => {
    if (!disabled) {
      window.addEventListener('keydown', listener, false);
    }
    return () => {
      if (!disabled) {
        window.removeEventListener('keydown', listener, false);
      }
    };
  }, [disabled, listener]);
};

export default useNavigationKeys;
