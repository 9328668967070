/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import * as Types from '../../../__generated__/schema';

import { GraphQLError } from 'graphql';
import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';

export type GetAsyncQueryResultsVariables = {
  id: Types.Scalars['ID'];
  pageSize?: Types.Maybe<Types.Scalars['Int']>;
  cursor?: Types.Maybe<Types.Scalars['String']>;
};

export type GetAsyncQueryResults = {
  dataLakeQuery: Pick<Types.DataLakeQuery, 'id' | 'status' | 'message'> & {
    results?: Types.Maybe<{
      columnInfo: Pick<Types.DataLakeQueryResultsColumnInfo, 'order'>;
      edges: Array<Pick<Types.DataLakeQueryResultEdge, 'node'>>;
      pageInfo: Pick<Types.DataLakeQueryResultRecordsPageInfo, 'hasNextPage' | 'endCursor'>;
      stats: Pick<Types.DataLakeQueryStats, 'bytesScanned' | 'executionTime'>;
    }>;
  };
};

export const GetAsyncQueryResultsDocument = gql`
  query GetAsyncQueryResults($id: ID!, $pageSize: Int, $cursor: String) {
    dataLakeQuery(id: $id) {
      id
      status
      message
      results(input: { cursor: $cursor, pageSize: $pageSize }) {
        columnInfo {
          order
        }
        edges {
          node
        }
        pageInfo {
          hasNextPage
          endCursor
        }
        stats {
          bytesScanned
          executionTime
        }
      }
    }
  }
`;

/**
 * __useGetAsyncQueryResults__
 *
 * To run a query within a React component, call `useGetAsyncQueryResults` and pass it any options that fit your needs.
 * When your component renders, `useGetAsyncQueryResults` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAsyncQueryResults({
 *   variables: {
 *      id: // value for 'id'
 *      pageSize: // value for 'pageSize'
 *      cursor: // value for 'cursor'
 *   },
 * });
 */
export function useGetAsyncQueryResults(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetAsyncQueryResults,
    GetAsyncQueryResultsVariables
  >
) {
  return ApolloReactHooks.useQuery<GetAsyncQueryResults, GetAsyncQueryResultsVariables>(
    GetAsyncQueryResultsDocument,
    baseOptions
  );
}
export function useGetAsyncQueryResultsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetAsyncQueryResults,
    GetAsyncQueryResultsVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<GetAsyncQueryResults, GetAsyncQueryResultsVariables>(
    GetAsyncQueryResultsDocument,
    baseOptions
  );
}
export type GetAsyncQueryResultsHookResult = ReturnType<typeof useGetAsyncQueryResults>;
export type GetAsyncQueryResultsLazyQueryHookResult = ReturnType<
  typeof useGetAsyncQueryResultsLazyQuery
>;
export type GetAsyncQueryResultsQueryResult = ApolloReactCommon.QueryResult<
  GetAsyncQueryResults,
  GetAsyncQueryResultsVariables
>;
export function mockGetAsyncQueryResults({
  data,
  variables,
  errors,
}: {
  data: GetAsyncQueryResults;
  variables?: GetAsyncQueryResultsVariables;
  errors?: GraphQLError[];
}) {
  return {
    request: { query: GetAsyncQueryResultsDocument, variables },
    result: { data, errors },
  };
}
