/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { IconButton, IconButtonProps } from 'pouncejs';

const OptionsButton = React.forwardRef<HTMLButtonElement, Partial<IconButtonProps>>(
  function OptionsButton(props, ref) {
    return (
      <IconButton
        variant="ghost"
        variantBorderStyle="circle"
        variantColor="navyblue-300"
        icon="more"
        size="small"
        aria-label="Toggle Options"
        {...props}
        ref={ref}
      />
    );
  }
);

export default OptionsButton;
