/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Field } from 'formik';
import * as Yup from 'yup';
import FormikTextInput from 'Components/fields/TextInput';
import SensitiveTextInput from 'Components/fields/SensitiveTextInput';
import { DestinationConfigInput } from 'Generated/schema';
import BaseDestinationForm, {
  BaseDestinationFormValues,
  defaultValidationSchema,
} from 'Components/forms/BaseDestinationForm';
import { isNumber } from 'Helpers/utils';
import FormikMultiCombobox from 'Components/fields/MultiComboBox';
import { Box, FormHelperText, SimpleGrid } from 'pouncejs';

type AsanaFieldValues = Pick<DestinationConfigInput, 'asana'>;

interface AsanaDestinationFormProps {
  initialValues: BaseDestinationFormValues<AsanaFieldValues>;
  onSubmit: (values: BaseDestinationFormValues<AsanaFieldValues>) => void;
}

const AsanaDestinationForm: React.FC<AsanaDestinationFormProps> = ({ onSubmit, initialValues }) => {
  const existing = initialValues.outputId;

  const asanaFieldsValidationSchema = Yup.object().shape({
    outputConfig: Yup.object().shape({
      asana: Yup.object().shape({
        projectGids: Yup.array().of(Yup.string().integer()).required().min(1),
        personalAccessToken: existing ? Yup.string() : Yup.string().required(),
      }),
    }),
  });

  const mergedValidationSchema = defaultValidationSchema.concat(asanaFieldsValidationSchema);

  return (
    <BaseDestinationForm<AsanaFieldValues>
      initialValues={initialValues}
      validationSchema={mergedValidationSchema}
      onSubmit={onSubmit}
    >
      <SimpleGrid gap={5} columns={2} mb={5}>
        <Field
          name="displayName"
          as={FormikTextInput}
          label="* Display Name"
          placeholder="How should we name this?"
          required
        />
        <Field
          as={SensitiveTextInput}
          shouldMask={!!existing}
          name="outputConfig.asana.personalAccessToken"
          label="Personal or Service Access Token"
          placeholder="What's your access token?"
          required={!existing}
        />
      </SimpleGrid>
      <Box as="fieldset">
        <Field
          name="outputConfig.asana.projectGids"
          as={FormikMultiCombobox}
          label="Project GIDs"
          aria-describedby="projectGids-helper"
          allowAdditions
          validateAddition={isNumber}
          searchable
          items={[]}
          placeholder="The GIDs of the projects that will receive the task"
        />
        <FormHelperText id="projectGids-helper" mt={2}>
          Add by pressing the {'<'}Enter{'>'} key
        </FormHelperText>
      </Box>
    </BaseDestinationForm>
  );
};

export default AsanaDestinationForm;
