/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { useFormikContext } from 'formik';
import isEqual from 'lodash/isEqual';
import { Wizard } from 'Components/Wizard';
import LookupBasicInfoPanel from './LookupBasicInfoPanel';
import LookupAssociatedLogTypesPanel from './LookupAssociatedLogTypesPanel';
import LookupTableSchemaPanel from './LookupTableSchemaPanel';
import LookupImportDataPanel from './LookupImportDataPanel';
import { LookupFormValues } from './lookupWizardHelpers';
import LookupImportMethodPanel from './LookupImportMethodPanel';
import LookupS3SourcePanel from './LookupS3SourcePanel';
import LookupIAMPolicyPanel from './LookupIAMPolicyPanel';
import LookupVerifyS3SourcePanel from './LookupVerifyS3SourcePanel';

interface LookupWizardProps {
  title: string;
  isEditing?: boolean;
}

const LookupWizard: React.FC<LookupWizardProps> = ({ title, isEditing = false }) => {
  const { values, initialValues } = useFormikContext<LookupFormValues>();

  // If editing the form, enable all substeps on the "import data" step. Otherwise, rely
  // on the DEFAULT behavior (rather than explicitly disabling the step)
  const disableNestedSteps = isEditing ? false : undefined;

  // Disable the "import data" step if the user has edited their lookup into a state
  // that would cause it to result in strange behavior or outright errors.
  const isImportDataDisabled = React.useMemo(() => {
    const hasInitialLogTypes = initialValues.associatedLogTypes?.some(
      ({ logType, selectors }) => logType && selectors.length > 0
    );

    // If the lookup table coming from the server (initialValues) does not have the necessary
    // data to import data, disable the tab.
    if (!initialValues.schemaName || !initialValues.primaryKey || !hasInitialLogTypes) {
      return true;
    }

    // Second, check if user has edited the lookup table and NOT SAVED it. In this case
    // "Import data" would not error, but it WOULD result in them importing data against
    // a stale version of the lookup table.
    const hasEditedLogTypes = !isEqual(initialValues.associatedLogTypes, values.associatedLogTypes);
    const hasEditedSchemaName = !isEqual(initialValues.schemaName, values.schemaName);
    const hasEditedPrimaryKey = !isEqual(initialValues.primaryKey, values.primaryKey);

    if (hasEditedLogTypes || hasEditedSchemaName || hasEditedPrimaryKey) {
      return true;
    }

    // If none of the above conditions are met, then the lookup is ok to import data.
    return false;
  }, [initialValues, values]);

  return (
    <Wizard headerWidth={320} headerTitle={title} enableAllNavigationSteps={isEditing}>
      <Wizard.Step title="Basic Information">
        <LookupBasicInfoPanel />
      </Wizard.Step>

      <Wizard.Step
        title="Associated Log Types"
        description="Tell us which logs you want to enrich with this lookup table"
      >
        <LookupAssociatedLogTypesPanel />
      </Wizard.Step>

      <Wizard.Step
        title="Table Schema"
        description="Tell us about the shape of your lookup table’s data"
      >
        <LookupTableSchemaPanel />
      </Wizard.Step>

      <Wizard.StepGroup
        title="Import Data"
        description="Import your lookup table data"
        navigationDisabled={isImportDataDisabled}
      >
        {!isEditing && (
          <Wizard.Step title="Choose Import Method">
            <LookupImportMethodPanel />
          </Wizard.Step>
        )}

        {values.uploadMethod === 'file' && (
          <Wizard.Step title="Upload file" navigationDisabled={disableNestedSteps}>
            <LookupImportDataPanel />
          </Wizard.Step>
        )}

        {values.uploadMethod === 's3' && (
          <Wizard.Step title="Set Up Your S3 Source" navigationDisabled={disableNestedSteps}>
            <LookupS3SourcePanel />
          </Wizard.Step>
        )}

        {values.uploadMethod === 's3' && (
          <Wizard.Step title="Set Up an IAM Role" navigationDisabled={disableNestedSteps}>
            <LookupIAMPolicyPanel />
          </Wizard.Step>
        )}

        {values.uploadMethod === 's3' && (
          <Wizard.Step title="Set Up verification" navigationDisabled={disableNestedSteps}>
            <LookupVerifyS3SourcePanel />
          </Wizard.Step>
        )}
      </Wizard.StepGroup>
    </Wizard>
  );
};

export default LookupWizard;
