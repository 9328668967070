/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { capitalize, countPoliciesBySeverityAndStatus } from 'Helpers/utils';
import map from 'lodash/map';
import mapKeys from 'lodash/mapKeys';
import sum from 'lodash/sum';
import { theme, Flex, Card } from 'pouncejs';
import urls from 'Source/urls';
import { SEVERITY_COLOR_MAP } from 'Source/constants';
import useRouter from 'Hooks/useRouter';
import {
  AlertType,
  ComplianceStatusEnum,
  ListDetectionsSortFieldsEnum,
  OrganizationReportBySeverity,
  Severity,
  SortDirEnum,
} from 'Generated/schema';
import ChartSummary from 'Components/charts/ChartSummary';
import BarChart from 'Components/charts/BarChart';

const severityToColorMapping: {
  [key in keyof OrganizationReportBySeverity]: keyof typeof theme['colors'];
} = mapKeys(SEVERITY_COLOR_MAP, (value, key) => key.toLowerCase());

interface PoliciesByStatusChartData {
  policies: OrganizationReportBySeverity;
}

const PoliciesByStatusChart: React.FC<PoliciesByStatusChartData> = ({ policies }) => {
  const { history } = useRouter();
  const severities = Object.keys(severityToColorMapping);
  const totalFailingPolicies = sum(
    severities.map((severity: keyof OrganizationReportBySeverity) =>
      countPoliciesBySeverityAndStatus(policies, severity, ['fail', 'error'])
    )
  );

  const failingPoliciesChartData = React.useMemo(
    () => [
      ...map(severityToColorMapping, (color, severity: keyof OrganizationReportBySeverity) => ({
        value: countPoliciesBySeverityAndStatus(policies, severity, ['fail', 'error']),
        label: capitalize(severity),
        config: { gap: 1, height: 24, justifyValue: 'start' as const, color },
        onClick: () => {
          history.push(
            `${urls.detections.list()}?analysisTypes[]=${AlertType.Policy}&page=1&severity[]=${
              Severity[capitalize(severity)]
            }&sortBy=${ListDetectionsSortFieldsEnum.LastModified}&sortDir=${
              SortDirEnum.Descending
            }&complianceStatus=${ComplianceStatusEnum.Fail}`
          );
        },
      })),
    ],
    [policies, history]
  );

  return (
    <Flex direction="column" align="center" height="100%">
      <ChartSummary total={totalFailingPolicies} title="Failing Policies" color="red-300" />
      <Card variant="dark" pt={4} px={6} mt={6} width="100%" height={200}>
        <BarChart
          data={failingPoliciesChartData}
          showLegend
          title="Severity"
          testId="failing-policies-chart"
        />
      </Card>
    </Flex>
  );
};

export default React.memo(PoliciesByStatusChart);
