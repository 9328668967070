/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { useSelect } from 'Components/utils/SelectContext';
import { Detection, Permission } from 'Generated/schema';
import urls from 'Source/urls';
import { Flex } from 'pouncejs';
import RoleRestrictedAccess from 'Components/utils/RoleRestrictedAccess';
import LinkButton from 'Components/buttons/LinkButton';
import ListDetectionsSelection from './ListDetectionsSelection';
import ListDetectionsFilters from './ListDetectionsFilters';

const ListDetectionsActions: React.FC = () => {
  const { selection } = useSelect<Detection>();
  return selection.length ? (
    <ListDetectionsSelection />
  ) : (
    <Flex justify="flex-end" align="center" spacing={4}>
      <ListDetectionsFilters />
      <RoleRestrictedAccess allowedPermissions={[Permission.RuleModify, Permission.PolicyModify]}>
        <LinkButton
          to={urls.detections.create()}
          title="Create new Detection"
          data-tid="create-new-detection"
        >
          Create New
        </LinkButton>
      </RoleRestrictedAccess>
    </Flex>
  );
};

export default React.memo(ListDetectionsActions);
