/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';

import useRouter from 'Hooks/useRouter';
import { useFormikContext } from 'formik';
import { useWizardContext, WizardEditActions } from 'Components/Wizard';

const EditIntegrationActions = ({ disabled = false }: { disabled?: boolean }) => {
  const { history } = useRouter();
  const { goToLastStep } = useWizardContext();
  const { isSubmitting, isValid, dirty } = useFormikContext();

  return (
    <WizardEditActions>
      <WizardEditActions.Cancel aria-label="Cancel editing" onClick={history.goBack} />
      <WizardEditActions.Save
        aria-live="polite"
        aria-label="Save editing"
        aria-busy={isSubmitting}
        onClick={goToLastStep}
        loading={isSubmitting}
        disabled={isSubmitting || !isValid || !dirty || disabled}
      />
    </WizardEditActions>
  );
};

export default EditIntegrationActions;
