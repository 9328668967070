/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { ApolloError } from '@apollo/client';
import { Flex, Box, Heading, Button, Text, Icon, useSnackbar } from 'pouncejs';
import { useFormikContext } from 'formik';
import get from 'lodash/get';
import { WizardPanel, useWizardContext } from 'Components/Wizard';
import { extractErrorMessage } from 'Helpers/utils';
import LookupPanelHeading from '../LookupPanelHeading';
import { LookupFormValues, LOOKUP_WIZARD_WIDTH } from '../lookupWizardHelpers';
import EditLookupActions from '../EditLookupActions';

interface ImportMethodOptionProps {
  title: string;
  description: string;
  cta: string;
  onSelect: () => void;
  isSelected?: boolean;
  loading?: boolean;
}

const ImportMethodOption: React.FC<ImportMethodOptionProps> = ({
  title,
  description,
  cta,
  onSelect,
  isSelected = false,
  loading = false,
  ...rest
}) => {
  return (
    <Flex
      as="section"
      backgroundColor="navyblue-350"
      align="center"
      alignItems="center"
      spacing={2}
      px={7}
      py={5}
      data-testid="ImportMethodOption"
      {...rest}
    >
      <Box maxWidth="80%">
        <Heading as="h2" color="white-100" fontSize="x-large" mb={1}>
          {title}{' '}
          {isSelected && (
            <>
              <Icon size="large" type="check-circle" color="green-400" aria-hidden="true" />
              <Text visuallyHidden>(Selected)</Text>
            </>
          )}
        </Heading>
        <Text color="gray-300" fontSize="medium">
          {description}
        </Text>
      </Box>
      <Box ml="auto" flexShrink={0}>
        <Button onClick={onSelect} size="medium" loading={loading}>
          {cta}
        </Button>
      </Box>
    </Flex>
  );
};

const LookupImportMethodPanel = () => {
  const {
    values,
    setFieldValue,
    setFieldTouched,
    submitForm,
    isSubmitting,
    initialValues,
    errors,
  } = useFormikContext<LookupFormValues>();
  const { goToNextStep, isLastStep } = useWizardContext();
  const isEditing = Boolean(initialValues.id);

  // When no option has been chosen (create workflow) this panel is the final step
  // in the wizard. So goToNextStep won't work until the other sub-steps
  // are added post-selection. This state allows us to queue up a navigation and wait
  // until the next step has been added to execute it.
  const [hasPendingNavigation, setHasPendingNavigation] = React.useState(false);

  const handleSelect = React.useCallback(
    (method: LookupFormValues['uploadMethod']) => {
      setFieldTouched('uploadMethod');
      setFieldValue('uploadMethod', method);
      setHasPendingNavigation(true);
    },
    [setFieldValue, setFieldTouched]
  );

  const { pushSnackbar } = useSnackbar();

  React.useEffect(() => {
    if (!hasPendingNavigation || isLastStep) {
      return () => {};
    }

    if (!isEditing && values.uploadMethod === 'file' && !isSubmitting) {
      // if the lookup hasn't been created yet, this is where we need to submit the form.
      // and insert the lookup table id if successful, BEFORE we go to the next step
      (async () => {
        const submitResult: unknown = await submitForm();

        // Since the return type of `submitResult` is unknown, we need to extract the lookup id
        // using _.get() to keep TS happy.
        const savedId: unknown = get(submitResult, 'id');

        if (submitResult instanceof Error || typeof savedId !== 'string') {
          // If there was an error submitting, (or the submit return value is not valid)
          // surface the errors to the user.
          pushSnackbar({
            variant: 'error',
            title: "Couldn't create lookup",
            description:
              extractErrorMessage(submitResult as ApolloError) || 'Some unknown error occurred',
          });
        } else {
          // Otherwise, we want to save the id so that any future steps know this
          // lookup already got created...
          setFieldValue('id', savedId);
          // ...and we want to advance to the manual upload
          goToNextStep();
        }
      })();
    } else {
      goToNextStep();
    }

    setHasPendingNavigation(false);

    return () => {};
  }, [
    hasPendingNavigation,
    isLastStep,
    goToNextStep,
    isEditing,
    values.uploadMethod,
    isSubmitting,
    submitForm,
    pushSnackbar,
    setFieldValue,
    errors,
    values,
  ]);

  return (
    <WizardPanel width={LOOKUP_WIZARD_WIDTH} margin="0 auto">
      <LookupPanelHeading
        title="Choose Import Method"
        subtitle="Select the method for how you want to import data."
      />

      <Flex direction="column" spacing={4}>
        <ImportMethodOption
          title="Import via File Upload"
          description="Best for a smaller amount of data that is relatively static."
          isSelected={values.uploadMethod === 'file'}
          cta={values.uploadMethod === 'file' ? 'Edit' : 'Set Up'}
          onSelect={() => handleSelect('file')}
          loading={isSubmitting}
          data-tid="lookup-import-via-file-upload"
        />

        <ImportMethodOption
          title="Sync Data from an S3 Bucket"
          description="Best for a larger amount of data that update more frequently from an S3 bucket. Any changes in the S3 bucket will sync to Panther."
          cta={values.uploadMethod === 's3' ? 'Edit' : 'Set Up'}
          isSelected={values.uploadMethod === 's3'}
          onSelect={() => handleSelect('s3')}
          data-tid="lookup-import-via-s3-sync"
        />
      </Flex>

      {isEditing && <EditLookupActions />}
    </WizardPanel>
  );
};

export default LookupImportMethodPanel;
