/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Alert, Box, Card, FadeIn, Grid } from 'pouncejs';
import { extractErrorMessage } from 'Helpers/utils';
import { useListSavedQueries } from 'Source/graphql/queries/listSavedQueries.generated';
import NoResultsFound from 'Components/NoResultsFound';
import TablePlaceholder from 'Components/TablePlaceholder';
import useInfiniteScroll from 'Hooks/useInfiniteScroll';
import { DEFAULT_LARGE_PAGE_SIZE } from 'Source/constants';
import { useFormikContext } from 'formik';
import { SelectSavedQueryFormValues } from './SelectSavedQueryForm';
import ListSavedQueriesTable from './ListSavedQueriesTable';

interface SavedQueriesTableProps {
  onSelect: (string) => void;
  selectedId: string;
}
const SavedQueriesTable: React.FC<SavedQueriesTableProps> = ({ onSelect, selectedId }) => {
  const { values } = useFormikContext<SelectSavedQueryFormValues>();
  const { loading, error, previousData, data = previousData, fetchMore } = useListSavedQueries({
    variables: {
      input: {
        contains: values.contains,
        pageSize: DEFAULT_LARGE_PAGE_SIZE,
        sortDir: values.sortDir,
      },
    },
  });

  const lastEvaluatedKey = data?.listSavedQueries?.lastEvaluatedKey || null;
  const hasNextPage = !!lastEvaluatedKey;

  const { sentinelRef } = useInfiniteScroll<HTMLDivElement>({
    loading,
    threshold: 500,
    scrollContainer: 'parent',
    onLoadMore: () => {
      fetchMore({
        variables: {
          input: {
            contains: values.contains,
            sortDir: values.sortDir,
            pageSize: DEFAULT_LARGE_PAGE_SIZE,
            exclusiveStartKey: lastEvaluatedKey,
          },
        },
      });
    },
  });

  if (loading && !data) {
    return (
      <Box width="100%">
        <FadeIn from="bottom">
          <TablePlaceholder rowCount={5} rowHeight={20} />
        </FadeIn>
      </Box>
    );
  }
  if (error) {
    return (
      <Alert
        variant="error"
        title={'Failed to fetch '}
        description={extractErrorMessage(error) || 'An unknown error occurred'}
      />
    );
  }

  const savedQueries = data?.listSavedQueries?.savedQueries || [];

  return (
    <Card as="section" position="relative">
      {savedQueries.length ? (
        <Grid overflow="auto" maxHeight={300} willChange="scroll">
          <ListSavedQueriesTable items={savedQueries} onSelect={onSelect} selectedId={selectedId} />
          {hasNextPage && (
            <Box py={8} ref={sentinelRef}>
              <TablePlaceholder rowCount={10} />
            </Box>
          )}
        </Grid>
      ) : (
        <Box my={8}>
          <NoResultsFound />
        </Box>
      )}
    </Card>
  );
};

export default React.memo(SavedQueriesTable);
