/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Field, Form, Formik } from 'formik';
import {
  Box,
  Button,
  Card,
  Flex,
  Popover,
  PopoverContent,
  PopoverTrigger,
  SimpleGrid,
} from 'pouncejs';
import {
  ComplianceStatusEnum,
  DetectionTypeEnum,
  ListDetectionsInput,
  Severity,
} from 'Generated/schema';
import useRequestParamsWithPagination from 'Hooks/useRequestParamsWithPagination';
import isUndefined from 'lodash/isUndefined';
import { capitalize } from 'Helpers/utils';
import useAuth from 'Hooks/useAuth';
import TextButton from 'Components/buttons/TextButton';
import FormikCombobox from 'Components/fields/ComboBox';
import FormikMultiCombobox from 'Components/fields/MultiComboBox';
import { RESOURCE_TYPES } from 'Source/constants';
import {
  useListAvailableLogTypes,
  useListAvailableScheduledQueries,
  useListAvailablePacks,
} from 'Source/graphql/queries';
import { ListPacksDropdownFiltersValues } from 'Pages/ListAnalysisPacks/ListPacksFilters/DropdownFilters';
import PopoverAutoSubmit from 'Components/PopoverAutoSubmit';

export enum CreatorEnum {
  ByMeCreator = 'BY_ME',
  ByTeamCreator = 'BY_TEAM',
  ByPantherCreator = 'BY_PANTHER',
}

export type ListDetectionsUpdateValues = Pick<
  ListDetectionsInput,
  | 'severity'
  | 'enabled'
  | 'analysisTypes'
  | 'complianceStatus'
  | 'createdBy'
  | 'initialSet'
  | 'logTypes'
  | 'packIds'
  | 'resourceTypes'
  | 'tags'
>;

export type ListDetectionsDropdownFilterValues = ListDetectionsUpdateValues & {
  creator: CreatorEnum;
};

const severityFieldItems = Object.values(Severity);
const severityFieldItemToString = (severity: Severity) => capitalize(severity.toLowerCase());

const detectionTypeFieldItems = Object.values(DetectionTypeEnum);
const detectionFieldTypeItemToString = (item: DetectionTypeEnum) =>
  item.toLowerCase().split('_').map(capitalize).join(' ');

const enabledFieldItems = [true, false];
const enabledFieldItemToString = (item: boolean | null) => {
  if (item === null) {
    return '';
  }

  return item ? 'Enabled' : 'Disabled';
};

const creatorFieldItems = Object.values(CreatorEnum);
const creatorFieldItemToString = (item: CreatorEnum) => {
  if (item === CreatorEnum.ByMeCreator) {
    return 'Created by me';
  }
  if (item === CreatorEnum.ByTeamCreator) {
    return 'Created by team';
  }
  return 'Created by Panther';
};

const complianceStatusFieldItems = Object.values(ComplianceStatusEnum);
const complianceStatusFieldItemToString = (status: ComplianceStatusEnum) =>
  capitalize(status.toLowerCase());

const defaultDropdownValues: ListDetectionsInput = {
  enabled: null,
  complianceStatus: null,
  createdBy: null,
  initialSet: null,
  severity: [],
  logTypes: [],
  resourceTypes: [],
  scheduledQueries: [],
  tags: [],
  packIds: [],
  analysisTypes: [],
};

export const rewindCreator = (params, userInfo) => {
  const { creator, initialSet, createdBy, ...rest } = params;

  if (creator === CreatorEnum.ByTeamCreator) {
    return { ...rest, initialSet: false, createdBy: null };
  }
  if (creator === CreatorEnum.ByPantherCreator) {
    return { ...rest, initialSet: true, createdBy: null };
  }
  if (creator === CreatorEnum.ByMeCreator) {
    return { ...rest, createdBy: userInfo.id, initialSet: null };
  }
  return { ...rest, initialSet, createdBy };
};

export const parseCreator = (params, userInfo) => {
  const { createdBy, initialSet, ...rest } = params;
  let creator = null;
  if (createdBy === userInfo.id) {
    creator = CreatorEnum.ByMeCreator;
  } else if (initialSet === false) {
    creator = CreatorEnum.ByTeamCreator;
  } else if (initialSet === true) {
    creator = CreatorEnum.ByPantherCreator;
  }

  return { creator, ...rest };
};

const DropdownFilters: React.FC = () => {
  const { userInfo } = useAuth();
  const { data: logTypeData } = useListAvailableLogTypes();
  const { data: packs } = useListAvailablePacks({
    fetchPolicy: 'cache-and-network',
  });

  const availablePackIds = React.useMemo(() => {
    return packs?.listAnalysisPacks?.packs.map(pack => pack.id) ?? [];
  }, [packs]);

  const { data: scheduledQueriesData } = useListAvailableScheduledQueries();
  const { requestParams, updateRequestParamsAndResetPaging } = useRequestParamsWithPagination<
    ListDetectionsInput
  >();

  const initialDropdownFilters = React.useMemo(
    () =>
      ({
        ...defaultDropdownValues,
        ...parseCreator(requestParams, userInfo),
      } as ListDetectionsUpdateValues),
    [requestParams, userInfo]
  ) as ListPacksDropdownFiltersValues;

  const handleSubmit = React.useCallback(
    (values: ListDetectionsUpdateValues) => {
      updateRequestParamsAndResetPaging(rewindCreator(values, userInfo));
    },
    [updateRequestParamsAndResetPaging, userInfo]
  );

  const filtersCount = Object.keys(defaultDropdownValues).filter(
    key => !isUndefined(requestParams[key])
  ).length;

  return (
    <Popover>
      {({ close: closePopover, isOpen }) => (
        <React.Fragment>
          <PopoverTrigger
            data-tid="detections-dropdown-filters"
            as={Button}
            iconAlignment="right"
            icon="filter-light"
            size="large"
            aria-label="Detection Options"
          >
            Filters {filtersCount ? `(${filtersCount})` : ''}
          </PopoverTrigger>
          <PopoverContent>
            <Card
              shadow="dark300"
              my={14}
              p={6}
              pb={4}
              backgroundColor="navyblue-400"
              width={850}
              data-testid="dropdown-detections-listing-filters"
            >
              <Formik<ListDetectionsUpdateValues>
                enableReinitialize
                onSubmit={handleSubmit}
                initialValues={initialDropdownFilters}
              >
                {({ setValues, values }) => (
                  <Form>
                    <PopoverAutoSubmit<ListDetectionsUpdateValues>
                      isOpen={isOpen}
                      values={values}
                      onSubmit={handleSubmit}
                    />
                    <Flex direction="column" spacing={4}>
                      <SimpleGrid columns={2} spacingX={4} spacingY={5}>
                        <Field
                          as={FormikMultiCombobox}
                          label="Detection Types"
                          name="analysisTypes"
                          placeholder="Select detection types"
                          items={detectionTypeFieldItems}
                          itemToString={detectionFieldTypeItemToString}
                        />
                        <Field
                          name="severity"
                          as={FormikMultiCombobox}
                          items={severityFieldItems}
                          itemToString={severityFieldItemToString}
                          label="Severities"
                          placeholder="Select severities to filter..."
                        />
                      </SimpleGrid>
                      <SimpleGrid columns={3} spacingX={4} spacingY={5}>
                        <Field
                          as={FormikMultiCombobox}
                          searchable
                          label="Log Types"
                          name="logTypes"
                          items={logTypeData?.listAvailableLogTypes?.logTypes ?? []}
                          placeholder="Select log types to filter by..."
                        />
                        <Field
                          as={FormikMultiCombobox}
                          searchable
                          label="Resource Types"
                          name="resourceTypes"
                          items={RESOURCE_TYPES}
                          placeholder="Select resource types to filter by..."
                        />
                        <Field
                          as={FormikMultiCombobox}
                          searchable
                          label="Scheduled Queries"
                          name="scheduledQueries"
                          items={
                            scheduledQueriesData?.listSavedQueries?.savedQueries?.map(
                              q => q.name
                            ) ?? []
                          }
                          placeholder="Select queries to filter by..."
                        />
                      </SimpleGrid>
                      <SimpleGrid columns={3} spacingX={4} spacingY={5}>
                        <Field
                          as={FormikCombobox}
                          name="creator"
                          items={creatorFieldItems}
                          itemToString={creatorFieldItemToString}
                          label="Created by"
                          placeholder="Filter by detection creator..."
                        />
                        <Field
                          as={FormikCombobox}
                          name="complianceStatus"
                          items={complianceStatusFieldItems}
                          itemToString={complianceStatusFieldItemToString}
                          label="Policy Status"
                          placeholder="Filter by policy status..."
                        />
                        <Field
                          as={FormikCombobox}
                          name="enabled"
                          items={enabledFieldItems}
                          itemToString={enabledFieldItemToString}
                          label="State"
                          placeholder="Filter by enabled status..."
                        />
                      </SimpleGrid>
                      <SimpleGrid columns={2} spacingX={4} spacingY={5}>
                        <Field
                          as={FormikMultiCombobox}
                          label="Tags"
                          searchable
                          allowAdditions
                          name="tags"
                          items={[]}
                          placeholder="Enter tags to filter by..."
                        />
                        <Field
                          as={FormikMultiCombobox}
                          label="Packs"
                          searchable
                          name="packIds"
                          items={availablePackIds}
                          placeholder="Select packs to filter by..."
                        />
                      </SimpleGrid>

                      <Flex direction="column" justify="center" align="center" spacing={4} my={2}>
                        <Box>
                          <Button onClick={closePopover}>Apply Filters</Button>
                        </Box>
                        <TextButton role="button" onClick={() => setValues(defaultDropdownValues)}>
                          Clear Filters
                        </TextButton>
                      </Flex>
                    </Flex>
                  </Form>
                )}
              </Formik>
            </Card>
          </PopoverContent>
        </React.Fragment>
      )}
    </Popover>
  );
};

export default React.memo(DropdownFilters);
