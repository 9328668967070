/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Box, FormError, TextInput, TextInputProps } from 'pouncejs';
import { FieldConfig, useField } from 'formik';

interface FormikTextInputProps extends TextInputProps {
  trimPastedText: boolean;
}
const FormikTextInput: React.FC<
  FormikTextInputProps & Required<Pick<FieldConfig, 'name'>>
> = props => {
  const [, meta, helpers] = useField(props.name);

  const isInvalid = meta.touched && !!meta.error;
  const errorElementId = isInvalid ? `${props.name}-error` : undefined;

  const { setValue } = helpers;

  const onPasteWithTrim = event => {
    event.preventDefault();
    const pastedValue = event.clipboardData.getData('Text').trim();
    setValue(pastedValue);
  };

  return (
    <Box>
      <TextInput
        invalid={isInvalid}
        aria-describedby={isInvalid ? errorElementId : undefined}
        onPaste={props.trimPastedText ? onPasteWithTrim : undefined}
        {...props}
      />
      {isInvalid && (
        <FormError mt={2} id={errorElementId}>
          {meta.error}
        </FormError>
      )}
    </Box>
  );
};

export default FormikTextInput;
