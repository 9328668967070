/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Box, Card, Flex, Spinner, useSnackbar } from 'pouncejs';
import ErrorBoundary from 'Components/ErrorBoundary';
import { extractErrorMessage } from 'Helpers/utils';
import withRoleRestrictedAccess from 'Hoc/withRoleRestrictedAccess';
import { ErrorCodeEnum, Permission } from 'Generated/schema';
import Page403 from 'Pages/403';
import { compose } from 'Helpers/compose';
import withSEO from 'Hoc/withSEO';
import APITokenForm from 'Components/forms/APITokenForm';
import Page404 from 'Pages/404';
import Page500 from 'Pages/500';
import { APITokenFormValues } from 'Components/forms/APITokenForm/APITokenForm';
import useRouter from 'Hooks/useRouter';
import urls from 'Source/urls';
import { useUpdateAPIToken } from './graphql/updateAPIToken.generated';
import { useGetAPIToken } from './graphql/getAPIToken.generated';

const EditAPIToken: React.FC = () => {
  const { match, history } = useRouter<{ id: string }>();
  const { pushSnackbar } = useSnackbar();

  const [updateAPIToken] = useUpdateAPIToken({
    onCompleted: data => {
      if (data.updateAPIToken.error) {
        pushSnackbar({ title: data.updateAPIToken.error.message, variant: 'error' });
      } else {
        pushSnackbar({ title: 'Successfully updated API token', variant: 'success' });
        history.push(urls.settings.apiTokens.list());
      }
    },
    onError: error => {
      pushSnackbar({
        title: extractErrorMessage(error) || 'An unknown error occurred',
        variant: 'error',
      });
    },
  });

  const { data, loading, error: runtimeError } = useGetAPIToken({
    variables: { id: match.params.id },
    onError: error => {
      pushSnackbar({
        title: 'Failed to populate populate fields',
        description: extractErrorMessage(error) || 'An unknown error occured',
        variant: 'error',
      });
    },
  });

  const handleSubmit = React.useCallback(
    (values: APITokenFormValues) =>
      updateAPIToken({
        variables: {
          input: { id: match.params.id, name: values.name, permissions: values.permissions },
        },
      }),
    [updateAPIToken, match]
  );

  const initialValues = React.useMemo<APITokenFormValues>(
    () => ({
      id: match.params.id,
      name: data?.getAPIToken.token?.name ?? 'Loading...',
      permissions: data?.getAPIToken.token?.permissions ?? [],
    }),
    [data, match]
  );

  if (loading) {
    return (
      <Flex width="100%" height={300} align="center" justify="center">
        <Spinner />
      </Flex>
    );
  }

  if (runtimeError) {
    return <Page500 />;
  }

  const notFoundError = data?.getAPIToken.error?.code === ErrorCodeEnum.NotFound;
  if (notFoundError) {
    return <Page404 />;
  }

  return (
    <Box mb={6}>
      <ErrorBoundary>
        <Card p={6}>
          <APITokenForm initialValues={initialValues} onSubmit={handleSubmit} />
        </Card>
      </ErrorBoundary>
    </Box>
  );
};

export default compose(
  withSEO({ title: 'Edit API Token' }),
  withRoleRestrictedAccess({
    allowedPermissions: [Permission.OrganizationApiTokenModify],
    fallback: <Page403 />,
  })
)(EditAPIToken);
