/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Box, Tooltip, Button } from 'pouncejs';
import useBulkDownload from 'Hooks/useBulkDownload';

const BulkDownload: React.FC = () => {
  const { download, loading } = useBulkDownload({ fileName: 'all-entities' });
  const handleDownload = React.useCallback(() => download({}), [download]);

  return (
    <Tooltip
      content={
        <Box>Click to bulk download all detections, data models, helpers, and saved queries</Box>
      }
    >
      <Button
        icon="download"
        loading={loading}
        disabled={loading}
        size="medium"
        onClick={handleDownload}
      >
        Download all entities
      </Button>
    </Tooltip>
  );
};

export default React.memo(BulkDownload);
