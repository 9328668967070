/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import * as Types from '../../../../../../__generated__/schema';

import { SnykLogIntegrationDetails } from '../../../../../graphql/fragments/SnykLogIntegrationDetails.generated';
import { GraphQLError } from 'graphql';
import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';

export type AddSnykLogSourceVariables = {
  input: Types.AddLogPullingIntegrationInput;
};

export type AddSnykLogSource = { addLogPullingIntegration: SnykLogIntegrationDetails };

export const AddSnykLogSourceDocument = gql`
  mutation AddSnykLogSource($input: AddLogPullingIntegrationInput!) {
    addLogPullingIntegration(input: $input) {
      ...SnykLogIntegrationDetails
    }
  }
  ${SnykLogIntegrationDetails}
`;
export type AddSnykLogSourceMutationFn = ApolloReactCommon.MutationFunction<
  AddSnykLogSource,
  AddSnykLogSourceVariables
>;

/**
 * __useAddSnykLogSource__
 *
 * To run a mutation, you first call `useAddSnykLogSource` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddSnykLogSource` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addSnykLogSource, { data, loading, error }] = useAddSnykLogSource({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddSnykLogSource(
  baseOptions?: ApolloReactHooks.MutationHookOptions<AddSnykLogSource, AddSnykLogSourceVariables>
) {
  return ApolloReactHooks.useMutation<AddSnykLogSource, AddSnykLogSourceVariables>(
    AddSnykLogSourceDocument,
    baseOptions
  );
}
export type AddSnykLogSourceHookResult = ReturnType<typeof useAddSnykLogSource>;
export type AddSnykLogSourceMutationResult = ApolloReactCommon.MutationResult<AddSnykLogSource>;
export type AddSnykLogSourceMutationOptions = ApolloReactCommon.BaseMutationOptions<
  AddSnykLogSource,
  AddSnykLogSourceVariables
>;
export function mockAddSnykLogSource({
  data,
  variables,
  errors,
}: {
  data: AddSnykLogSource;
  variables?: AddSnykLogSourceVariables;
  errors?: GraphQLError[];
}) {
  return {
    request: { query: AddSnykLogSourceDocument, variables },
    result: { data, errors },
  };
}
