/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { GlobalHelper, Permission } from 'Generated/schema';
import * as Yup from 'yup';
import { Box, Button, Flex, SimpleGrid } from 'pouncejs';
import ErrorBoundary from 'Components/ErrorBoundary';
import { Field, Form, Formik } from 'formik';
import FormSessionRestoration from 'Components/utils/FormSessionRestoration';
import useRouter from 'Hooks/useRouter';
import FormikTextInput from 'Components/fields/TextInput';
import FormikTextArea from 'Components/fields/TextArea';
import FormikEditor from 'Components/fields/Editor';
import RoleRestrictedAccess from 'Components/utils/RoleRestrictedAccess';
import urls from 'Source/urls';
import Panel from 'Components/Panel';
import SaveButton from 'Components/buttons/SaveButton/SaveButton';
import Breadcrumbs from 'Components/Breadcrumbs';
import useBulkDownload from 'Hooks/useBulkDownload';

// The validation checks that Formik will run
const validationSchema = Yup.object().shape({
  id: Yup.string().required(),
  body: Yup.string().required(),
  description: Yup.string().required(),
});

const globalHelperEditableFields = ['id', 'body', 'description', 'managed'] as const;

type GlobalHelperFormValues = Pick<GlobalHelper, typeof globalHelperEditableFields[number]>;

interface GlobalHelperFormProps {
  /** The initial values of the form */
  initialValues: GlobalHelperFormValues;
  /** callback for the submission of the form */
  onSubmit: (values: GlobalHelperFormValues) => void;
  cacheSessionId?: string;
}

const GlobalHelperForm: React.FC<GlobalHelperFormProps> = ({
  initialValues,
  onSubmit,
  cacheSessionId = `global-helper-create`,
}) => {
  const { history } = useRouter();
  const { loading, download } = useBulkDownload({ fileName: initialValues.id });

  return (
    <Box as="article">
      <ErrorBoundary>
        <Formik<GlobalHelperFormValues>
          initialValues={initialValues}
          onSubmit={onSubmit}
          enableReinitialize
          validationSchema={validationSchema}
        >
          {({ values }) => (
            <FormSessionRestoration sessionId={cacheSessionId}>
              {({ clearFormSession }) => (
                <Form>
                  <Flex direction="column" spacing={5}>
                    <Panel title="Helper Settings">
                      <SimpleGrid columns={2} spacing={5}>
                        <Field
                          as={FormikTextInput}
                          label="Helper Name"
                          placeholder="The name that this helper will be imported as"
                          name="id"
                          disabled={!!initialValues.id || initialValues.managed}
                          required
                        />
                        <Field
                          as={FormikTextArea}
                          label="Description"
                          placeholder="Additional context about this global helper"
                          name="description"
                          required
                          disabled={initialValues.managed}
                        />
                      </SimpleGrid>
                    </Panel>
                    <Panel
                      title="Helper Definition"
                      actions={
                        initialValues.id && (
                          <Button
                            disabled={loading}
                            onClick={() => download({ globalHelperIds: [initialValues.id] })}
                          >
                            Download Helper
                          </Button>
                        )
                      }
                    >
                      <Field
                        as={FormikEditor}
                        placeholder="# Enter the body of the global here here..."
                        name="body"
                        width="100%"
                        minHeight={270}
                        language="python"
                        required
                        readOnly={initialValues.managed}
                      />
                    </Panel>
                  </Flex>
                  <RoleRestrictedAccess
                    allowedPermissions={[Permission.RuleModify, Permission.PolicyModify]}
                  >
                    <Breadcrumbs.Actions>
                      <Flex justify="flex-end" spacing={4}>
                        <Button
                          data-tid="helper-form-cancel"
                          variantColor="pink-700"
                          onClick={() => {
                            clearFormSession();
                            history.push(urls.data.globalHelpers.list());
                          }}
                        >
                          Cancel
                        </Button>
                        {initialValues.managed ? (
                          <Button data-tid="helper-form-clone" onClick={() => onSubmit(values)}>
                            Clone & edit
                          </Button>
                        ) : (
                          <SaveButton
                            data-tid={`helper-form-${initialValues.id ? '-update' : 'create'}`}
                          >
                            {initialValues.id ? 'Update' : 'Create'}
                          </SaveButton>
                        )}
                      </Flex>
                    </Breadcrumbs.Actions>
                  </RoleRestrictedAccess>
                </Form>
              )}
            </FormSessionRestoration>
          )}
        </Formik>
      </ErrorBoundary>
    </Box>
  );
};

export default GlobalHelperForm;
