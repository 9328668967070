/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Box, Card, Flex, Link } from 'pouncejs';
import { Link as RRLink } from 'react-router-dom';

import BulletedValue from 'Components/BulletedValue';
import StatusBadge from 'Components/badges/StatusBadge';
import { CardBulletDivider, CardMetaValue, CardValue } from 'Components/cards/common';
import { formatDatetime } from 'Helpers/utils';
import urls from 'Source/urls';
import { ListResources } from '../graphql/listResources.generated';

interface ResourceCardProps {
  resource: ListResources['resources']['resources'][0];
}

const ResourceCard: React.FC<ResourceCardProps> = ({ resource }) => {
  return (
    <Card as="section" variant="dark" p={4}>
      <Flex direction="column" justify="space-between" width={1}>
        <Flex as="header" align="flex-start" mb={2}>
          <Box
            as="h4"
            fontWeight="medium"
            mr="auto"
            maxWidth="60%"
            wordBreak="break-word"
            data-testid="cloud-resource-name"
          >
            <Link as={RRLink} to={urls.data.resources.details(resource.id)}>
              {resource.id}
            </Link>
          </Box>
          {resource.integration && (
            <>
              <CardMetaValue label="Source" value={resource.integration.integrationLabel} />
              <CardBulletDivider />
            </>
          )}
          <CardMetaValue label="Updated" value={formatDatetime(resource.lastModified)} />
        </Flex>
        <Flex justify="space-between">
          <CardValue
            label="Resource Type"
            value={<BulletedValue value={resource.type} data-testid="cloud-resource-type" />}
          />
          <Flex align="flex-end" spacing={4}>
            <StatusBadge status={resource.complianceStatus} />
          </Flex>
        </Flex>
      </Flex>
    </Card>
  );
};

export default React.memo(ResourceCard);
