/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import { Field, Form, Formik } from 'formik';
import React from 'react';
import * as Yup from 'yup';
import { yupPasswordValidationSchema } from 'Helpers/utils';
import { Alert, Flex, FormHelperText, Link, TextInput, Box } from 'pouncejs';
import SubmitButton from 'Components/buttons/SubmitButton';
import FormikTextInput from 'Components/fields/TextInput';
import useAuth from 'Hooks/useAuth';
import FieldPolicyChecker from 'Components/FieldPolicyChecker/FieldPolicyChecker';

interface SetPasswordFormValues {
  confirmNewPassword: string;
  newPassword: string;
  formErrors?: string[];
}

const initialValues = {
  confirmNewPassword: '',
  newPassword: '',
};

const validationSchema = Yup.object().shape({
  newPassword: yupPasswordValidationSchema,
  confirmNewPassword: yupPasswordValidationSchema.oneOf(
    [Yup.ref('newPassword')],
    'Passwords must match'
  ),
});

const SetPasswordForm: React.FC = () => {
  const { setNewPassword } = useAuth();

  return (
    <Formik<SetPasswordFormValues>
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={async ({ newPassword }, { setStatus }) =>
        setNewPassword({
          newPassword,
          onError: ({ message }) =>
            setStatus({
              title: 'Update password failed',
              message,
            }),
        })
      }
    >
      {({ status, values }) => (
        <Form>
          <Flex direction="column" spacing={4}>
            {status && <Alert variant="error" title={status.title} description={status.message} />}
            <Field
              as={TextInput}
              label="New Password"
              placeholder="Type your new password..."
              type="password"
              name="newPassword"
              required
            />
            <Field
              as={FormikTextInput}
              label="Confirm New Password"
              placeholder="Your new password again..."
              type="password"
              name="confirmNewPassword"
              required
            />
            <Box py={3}>
              <FieldPolicyChecker schema={yupPasswordValidationSchema} value={values.newPassword} />
            </Box>
            <SubmitButton aria-describedby="policy-disclaimer" fullWidth>
              Set password
            </SubmitButton>
            <FormHelperText id="policy-disclaimer" textAlign="center">
              By continuing, you agree to Panther&apos;s&nbsp;
              <Link
                external
                href="https://panther-public-shared-assets.s3-us-west-2.amazonaws.com/EULA.pdf"
              >
                End User License Agreement
              </Link>{' '}
              and acknowledge you have read the&nbsp;
              <Link
                external
                href="https://panther-public-shared-assets.s3-us-west-2.amazonaws.com/PrivacyPolicy.pdf"
              >
                Privacy Policy
              </Link>
            </FormHelperText>
          </Flex>
        </Form>
      )}
    </Formik>
  );
};

export default SetPasswordForm;
