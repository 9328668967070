/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import dayjs from 'dayjs';

import { DateRange } from 'Components/charts/TimeSeriesChart';
import { IndicatorSearchResult } from 'Hooks/useIndicatorSearchFlattenedResults';

function useIndicatorSearchResultFiltering(
  flattenedResults: IndicatorSearchResult[],
  filter: DateRange
) {
  return React.useMemo(() => {
    if (!filter) {
      return flattenedResults;
    }
    const startDate = dayjs(filter.startDateTimestamp);
    const endDate = dayjs(filter.endDateTimestamp);
    return flattenedResults.filter(result => {
      const resultDate = dayjs(result.timestamp);
      return (
        (resultDate.isAfter(startDate) || resultDate.isSame(startDate)) &&
        (resultDate.isBefore(endDate) || resultDate.isSame(endDate))
      );
    });
  }, [flattenedResults, filter]);
}

export default useIndicatorSearchResultFiltering;
