/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import useRouter from 'Hooks/useRouter';
import urls from 'Source/urls';
import { WizardEditActions } from 'Components/Wizard';

const CancelCloudAcountAction = () => {
  const { history } = useRouter();

  return (
    <WizardEditActions>
      <WizardEditActions.Cancel
        onClick={() => history.push(urls.integrations.cloudAccounts.list())}
      />
    </WizardEditActions>
  );
};

export default CancelCloudAcountAction;
