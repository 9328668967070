/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Card, Flex } from 'pouncejs';
import { ComplianceStatusEnum, ScannedResources } from 'Generated/schema';
import { countResourcesByStatus } from 'Helpers/utils';
import useRouter from 'Hooks/useRouter';
import ChartSummary from 'Components/charts/ChartSummary';
import BarChart from 'Components/charts/BarChart';
import urls from 'Source/urls';

interface ResourcesByStatusChartProps {
  resources: ScannedResources;
}

const ResourcesByStatusChart: React.FC<ResourcesByStatusChartProps> = ({ resources }) => {
  const { history } = useRouter();
  const totalResources = countResourcesByStatus(resources, ['fail', 'error', 'pass']);

  const failingResourcesChartData = React.useMemo(
    () => [
      {
        value: countResourcesByStatus(resources, ['pass']),
        label: 'Passing Resources',
        config: { color: 'green-400' as const, showLabel: true },
        onClick: () =>
          history.push(
            `${urls.data.resources.list()}?complianceStatus=${ComplianceStatusEnum.Pass}`
          ),
      },
      {
        value: countResourcesByStatus(resources, ['fail', 'error']),
        label: 'Failing Resources',
        config: { color: 'red-300' as const, showLabel: true },
        onClick: () =>
          history.push(
            `${urls.data.resources.list()}?complianceStatus=${ComplianceStatusEnum.Fail}`
          ),
      },
    ],
    [resources, history]
  );

  return (
    <Flex direction="column" align="center" height="100%">
      <ChartSummary total={totalResources} title="Resources with Policies" color="cyan-400" />
      <Card variant="dark" p={6} mt={6} width="100%" height={200}>
        <BarChart data={failingResourcesChartData} withDividers />
      </Card>
    </Flex>
  );
};

export default React.memo(ResourcesByStatusChart);
