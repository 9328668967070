/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import { Alert, Card, Flex, Icon, Text, TextInput, Box } from 'pouncejs';
import { Field, Form, Formik } from 'formik';
import React from 'react';
import * as Yup from 'yup';
import { yupPasswordValidationSchema } from 'Helpers/utils';
import SubmitButton from 'Components/buttons/SubmitButton';
import FormikTextInput from 'Components/fields/TextInput';
import useAuth from 'Hooks/useAuth';
import FieldPolicyChecker from 'Components/FieldPolicyChecker';

interface ChangePasswordFormProps {
  onSuccess: () => void;
}

interface ChangePasswordFormValues {
  oldPassword: string;
  confirmNewPassword: string;
  newPassword: string;
}

const initialValues = {
  oldPassword: '',
  newPassword: '',
  confirmNewPassword: '',
};

const validationSchema = Yup.object().shape({
  oldPassword: yupPasswordValidationSchema,
  newPassword: yupPasswordValidationSchema,
  confirmNewPassword: yupPasswordValidationSchema.oneOf(
    [Yup.ref('newPassword')],
    'Passwords must match'
  ),
});

const ChangePasswordForm: React.FC<ChangePasswordFormProps> = ({ onSuccess }) => {
  const { changePassword, signOut } = useAuth();

  return (
    <Formik<ChangePasswordFormValues>
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={async ({ oldPassword, newPassword }, { setStatus }) =>
        changePassword({
          oldPassword,
          newPassword,
          onSuccess: () => {
            signOut({ global: true });
            onSuccess();
          },
          onError: ({ message }) =>
            setStatus({
              title: 'Update password failed.',
              message,
            }),
        })
      }
    >
      {({ status, values }) => (
        <Form>
          <Flex direction="column" spacing={5}>
            <Card variant="dark" p={3}>
              <Flex align="center">
                <Icon type="alert-circle" size="medium" color="blue-400" />
                <Text fontSize="medium" ml={2}>
                  Updating your password will log you out of all devices you are logged in!
                </Text>
              </Flex>
            </Card>
            {status && <Alert variant="error" title={status.title} description={status.message} />}
            <Field
              as={FormikTextInput}
              label="Current Password"
              placeholder="Enter your current password..."
              type="password"
              name="oldPassword"
              required
            />
            <Field
              as={TextInput}
              label="New Password"
              placeholder="Type your new password..."
              type="password"
              name="newPassword"
              required
            />
            <Field
              as={FormikTextInput}
              label="Confirm New Password"
              placeholder="Type your new password again..."
              type="password"
              name="confirmNewPassword"
              required
            />
            <Box py={3}>
              <FieldPolicyChecker schema={yupPasswordValidationSchema} value={values.newPassword} />
            </Box>
            <SubmitButton fullWidth>Update password</SubmitButton>
          </Flex>
        </Form>
      )}
    </Formik>
  );
};

export default ChangePasswordForm;
