/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import * as Types from '../../../../../__generated__/schema';

import { GraphQLError } from 'graphql';
import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';

export type GetSqlForQueryVariables = {
  id: Types.Scalars['ID'];
};

export type GetSqlForQuery = { dataLakeQuery: Pick<Types.DataLakeQuery, 'id' | 'sql'> };

export const GetSqlForQueryDocument = gql`
  query GetSqlForQuery($id: ID!) {
    dataLakeQuery(id: $id) {
      id
      sql
    }
  }
`;

/**
 * __useGetSqlForQuery__
 *
 * To run a query within a React component, call `useGetSqlForQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSqlForQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSqlForQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetSqlForQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<GetSqlForQuery, GetSqlForQueryVariables>
) {
  return ApolloReactHooks.useQuery<GetSqlForQuery, GetSqlForQueryVariables>(
    GetSqlForQueryDocument,
    baseOptions
  );
}
export function useGetSqlForQueryLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSqlForQuery, GetSqlForQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<GetSqlForQuery, GetSqlForQueryVariables>(
    GetSqlForQueryDocument,
    baseOptions
  );
}
export type GetSqlForQueryHookResult = ReturnType<typeof useGetSqlForQuery>;
export type GetSqlForQueryLazyQueryHookResult = ReturnType<typeof useGetSqlForQueryLazyQuery>;
export type GetSqlForQueryQueryResult = ApolloReactCommon.QueryResult<
  GetSqlForQuery,
  GetSqlForQueryVariables
>;
export function mockGetSqlForQuery({
  data,
  variables,
  errors,
}: {
  data: GetSqlForQuery;
  variables?: GetSqlForQueryVariables;
  errors?: GraphQLError[];
}) {
  return {
    request: { query: GetSqlForQueryDocument, variables },
    result: { data, errors },
  };
}
