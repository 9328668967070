/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Combobox, useSnackbar } from 'pouncejs';
import { extractErrorMessage } from 'Helpers/utils';
import { useListDataLakeDatabases } from './graphql/listDataLakeDatabases.generated';
import { useDataExplorerContext } from '../../DataExplorerContext';

// A database with this prefix should always exist in the database, so it's
// a safe one to use as a default
const DEFAULT_DATABASE_PREFIX = 'panther_logs';

const DatabaseSelector: React.FC = () => {
  const { pushSnackbar } = useSnackbar();
  const {
    state: { selectedDatabase },
    dispatch,
  } = useDataExplorerContext();

  const { data } = useListDataLakeDatabases({
    onCompleted: ({ dataLakeDatabases }) => {
      const dataLakeDatabasesNames = dataLakeDatabases.map(({ name }) => name);
      const selectionIsOutdated = !dataLakeDatabasesNames.includes(selectedDatabase);

      if (!selectedDatabase || selectionIsOutdated) {
        // There must always be one database selected, so apply a default selection.
        // If we fail to find the default, use the first one
        const fallbackDatabaseName =
          dataLakeDatabasesNames.find(name => name.startsWith(DEFAULT_DATABASE_PREFIX)) ||
          dataLakeDatabasesNames[0];

        dispatch({
          type: 'SELECT_DATABASE',
          payload: { database: fallbackDatabaseName },
        });
      }
    },
    onError: error =>
      pushSnackbar({
        variant: 'error',
        title: "Couldn't fetch your databases",
        description: extractErrorMessage(error),
      }),
  });

  return (
    <Combobox
      label="Select Database"
      items={data?.dataLakeDatabases.map(db => db.name) ?? []}
      onChange={database => dispatch({ type: 'SELECT_DATABASE', payload: { database } })}
      value={selectedDatabase}
      placeholder="Select a database..."
      showClearSelectionControl={false}
    />
  );
};

export default DatabaseSelector;
