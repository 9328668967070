/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Link as RRLink } from 'react-router-dom';
import { Flex, Link, Box, Card } from 'pouncejs';

import urls from 'Source/urls';
import { AlertSummaryFull } from 'Source/graphql/fragments/AlertSummaryFull.generated';
import { AlertOriginSystemError } from 'Generated/schema';
import { formatDatetime } from 'Helpers/utils';
import { SelectCheckbox } from 'Components/utils/SelectContext';
import UpdateAlertDropdown from 'Components/dropdowns/UpdateAlertDropdown';
import { CardBulletDivider, CardMetaValue } from 'Components/cards/common';
import useSystemErrorRelatedComponent from 'Hooks/useSystemErrorRelatedComponent';
import DestinationDeliveryWarning from '../DestinationDeliveryWarning';
import { CardVariant, CardVariantConfig } from '../../common/cardVariant';

export interface SystemAlertCardProps {
  alert: AlertSummaryFull;
  hideSystemButton?: boolean;
  selectionEnabled?: boolean;
  isSelected?: boolean;
  variant?: CardVariant;
}

const SystemErrorAlertCard: React.FC<SystemAlertCardProps> = ({
  alert,
  selectionEnabled = false,
  isSelected = false,
  variant = 'dark',
}) => {
  const relatedComponent = useSystemErrorRelatedComponent(alert.origin as AlertOriginSystemError);

  const variantConfig = CardVariantConfig[variant];

  return (
    <Card
      as="section"
      variant={isSelected ? variantConfig.selectedCardVariant : variantConfig.defaultCardVariant}
      p={4}
    >
      <Flex>
        {selectionEnabled && (
          <Box transform="translate3d(-12px,-9px,0)">
            <SelectCheckbox data-tid="single-select-alert" selectionItem={alert.id} />
          </Box>
        )}
        <Flex direction="column" justify="space-between" width={1}>
          <Flex as="header" mb={2} align="flex-start">
            <Box
              as="h4"
              data-testid="single-alert-value"
              fontWeight="medium"
              mr="auto"
              maxWidth="80%"
              wordBreak="break-word"
            >
              <Link as={RRLink} aria-label="Link to Alert" to={urls.alerts.details(alert.id)}>
                {alert.title}
              </Link>
            </Box>
            <UpdateAlertDropdown alert={alert} />
          </Flex>
          <Flex justify="space-between" mt={4}>
            <Flex wrap="wrap">
              <CardMetaValue
                labelColor="gray"
                label="Related Component"
                value={relatedComponent.label}
                link={relatedComponent.to}
                data-tid="link-to-log-sources-from-alert-card"
              />
              <CardBulletDivider />
              <CardMetaValue
                labelColor="gray"
                label="Date & Time"
                value={formatDatetime(alert.createdAt)}
              />
            </Flex>
            <DestinationDeliveryWarning alert={alert} />
          </Flex>
        </Flex>
      </Flex>
    </Card>
  );
};

export default React.memo(SystemErrorAlertCard);
