/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import logo from 'Assets/logProviders/gcp.svg';
import { useFormikContext, Field } from 'formik';
import { WizardPanel } from 'Components/Wizard';
import { Flex } from 'pouncejs';
import FormikTextInput from 'Components/fields/TextInput';
import FormikMultiCombobox from 'Components/fields/MultiComboBox';
import EditIntegrationActions from 'Pages/Integrations/components/EditIntegrationActions';
import { GcsLogSourceWizardValues } from './GcsLogSourceWizard';

const SetupSourcePanel = () => {
  const { initialValues, dirty, errors, status } = useFormikContext<GcsLogSourceWizardValues>();
  const editMode = !!initialValues.integrationId;

  return (
    <WizardPanel width={665} mx="auto">
      <WizardPanel.Heading
        title="Configure your source below"
        subtitle="We need to know where to get your logs from"
        logo={logo}
        divider={null}
        subtitleProps={{ color: 'gray-300', fontWeight: 'normal' }}
      />
      <Flex direction="column" spacing={4}>
        <Field
          name="integrationLabel"
          as={FormikTextInput}
          label="Name"
          placeholder="A nickname for this log source"
          required
        />

        <Field
          as={FormikMultiCombobox}
          searchable
          placeholder="The types of logs that are collected"
          label="Log Types"
          name="logTypes"
          items={status?.availableLogTypes || []}
          required
        />
      </Flex>

      <WizardPanel.Actions>
        {editMode ? (
          <EditIntegrationActions />
        ) : (
          <WizardPanel.ActionNext
            disabled={!dirty || !!errors.integrationLabel || !!errors.logTypes}
          >
            Continue Setup
          </WizardPanel.ActionNext>
        )}
      </WizardPanel.Actions>
    </WizardPanel>
  );
};

export default SetupSourcePanel;
