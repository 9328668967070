/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import * as Types from '../../../__generated__/schema';

import { RuleSummary } from './RuleSummary.generated';
import { GraphQLError } from 'graphql';
import gql from 'graphql-tag';

export type RuleDetails = Pick<Types.Rule, 'body'> & {
  reports: Array<Pick<Types.DetectionReportMapping, 'key' | 'values'>>;
  tests: Array<
    Pick<Types.DetectionTestDefinition, 'expectedResult' | 'name' | 'resource'> & {
      mocks?: Types.Maybe<
        Array<Pick<Types.DetectionTestMockDefinition, 'objectName' | 'returnValue'>>
      >;
    }
  >;
} & RuleSummary;

export const RuleDetails = gql`
  fragment RuleDetails on Rule {
    ...RuleSummary
    body
    reports {
      key
      values
    }
    tests {
      expectedResult
      name
      resource
      mocks {
        objectName
        returnValue
      }
    }
  }
  ${RuleSummary}
`;
