/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Button, useSnackbar } from 'pouncejs';
import usePrevious from 'Hooks/usePrevious';
import difference from 'lodash/difference';
import useAuth from 'Hooks/useAuth';

const useShowPermissionsUpdatedSnackbar = () => {
  const { userInfo } = useAuth();
  const { pushSnackbar } = useSnackbar();

  const permissions = userInfo?.permissions;
  const previousPermissions = usePrevious(permissions);

  React.useEffect(() => {
    if (
      previousPermissions &&
      permissions &&
      (previousPermissions.length !== permissions.length ||
        difference(previousPermissions, permissions).length > 0)
    ) {
      pushSnackbar({
        variant: 'default',
        title: 'Your permissions have changed',
        duration: 30000,
        discardable: true,
        description: [
          'The permissions associated with your account have been updated',
          <br key={0} />,
          'Please reload the page to apply the latest changes',
        ],
        actions: (
          <Button size="medium" onClick={() => window.location.reload()}>
            Reload Now
          </Button>
        ),
      });
    }
    // FIXME: look into hook dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [permissions]);
};

export default useShowPermissionsUpdatedSnackbar;
