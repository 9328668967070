/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Form, Formik } from 'formik';
import { ListSavedQueriesInput } from 'Generated/schema';
import { Flex } from 'pouncejs';
import useRequestParamsWithoutPagination from 'Hooks/useRequestParamsWithoutPagination';
import pick from 'lodash/pick';
import FormikDateRangeInput from 'Components/fields/DateRangeInput';
import FormikAutosave from 'Components/utils/Autosave';
import Breadcrumbs from 'Components/Breadcrumbs';

export type ListSavedQueriesDateFiltersValues = {
  createdAfter: string;
  createdBefore: string;
};

const filterKeys: (keyof Partial<ListSavedQueriesInput>)[] = ['createdAfter', 'createdBefore'];

const ListSavedQueriesBreadcrumbFilters: React.FC = () => {
  const { requestParams, updateRequestParams } = useRequestParamsWithoutPagination<
    ListSavedQueriesInput
  >();

  const initialFilterValues = React.useMemo(() => {
    return pick(requestParams, filterKeys) as ListSavedQueriesDateFiltersValues;
  }, [requestParams]);

  return (
    <Breadcrumbs.Actions>
      <Flex justify="flex-end">
        <Formik<ListSavedQueriesDateFiltersValues>
          enableReinitialize
          initialValues={initialFilterValues}
          onSubmit={updateRequestParams}
        >
          <Form>
            <FormikAutosave threshold={50} />
            <FormikDateRangeInput
              alignment="right"
              withPresets
              withTime
              variant="solid"
              format="MM/DD/YYYY HH:mm"
              labelStart="Date Start"
              labelEnd="Date End"
              placeholderStart="MM/DD/YY HH:mm"
              placeholderEnd="MM/DD/YY HH:mm"
              nameStart="createdAfter"
              nameEnd="createdBefore"
            />
          </Form>
        </Formik>
      </Flex>
    </Breadcrumbs.Actions>
  );
};

export default React.memo(ListSavedQueriesBreadcrumbFilters);
