/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Box } from 'pouncejs';
import { LogIntegration } from 'Generated/schema';
import EventThresholdAlarmConfigurator from 'Components/EventThresholdAlarmConfigurator';
import useModal from 'Hooks/useModal';

export interface SetEventThresholdAlarmModalProps {
  logSource: LogIntegration;
}

const SetEventThresholdAlarmModal: React.FC<SetEventThresholdAlarmModalProps> = ({ logSource }) => {
  const { hideModal } = useModal();
  return (
    <Box maxWidth={620}>
      <EventThresholdAlarmConfigurator
        autoSave={false}
        logSource={logSource}
        onSuccess={hideModal}
      />
    </Box>
  );
};

export default SetEventThresholdAlarmModal;
