/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import * as Types from '../../../../../__generated__/schema';

import { S3LogIntegrationDetails } from '../../../../graphql/fragments/S3LogIntegrationDetails.generated';
import { EventbridgeLogIntegrationDetails } from '../../../../graphql/fragments/EventbridgeLogIntegrationDetails.generated';
import { OktaLogIntegrationDetails } from '../../../../graphql/fragments/OktaLogIntegrationDetails.generated';
import { DuoLogIntegrationDetails } from '../../../../graphql/fragments/DuoLogIntegrationDetails.generated';
import { SalesforceLogIntegrationDetails } from '../../../../graphql/fragments/SalesforceLogIntegrationDetails.generated';
import { GithubLogIntegrationDetails } from '../../../../graphql/fragments/GithubLogIntegrationDetails.generated';
import { ZendeskLogIntegrationDetails } from '../../../../graphql/fragments/ZendeskLogIntegrationDetails.generated';
import { GsuiteLogIntegrationDetails } from '../../../../graphql/fragments/GsuiteLogIntegrationDetails.generated';
import { BoxLogIntegrationDetails } from '../../../../graphql/fragments/BoxLogIntegrationDetails.generated';
import { SlackLogIntegrationDetails } from '../../../../graphql/fragments/SlackLogIntegrationDetails.generated';
import { CrowdstrikeLogIntegrationDetails } from '../../../../graphql/fragments/CrowdstrikeLogIntegrationDetails.generated';
import { M365LogIntegrationDetails } from '../../../../graphql/fragments/M365LogIntegrationDetails.generated';
import { OnePasswordLogIntegrationDetails } from '../../../../graphql/fragments/OnePasswordLogIntegrationDetails.generated';
import { ZoomLogIntegrationDetails } from '../../../../graphql/fragments/ZoomLogIntegrationDetails.generated';
import { AtlassianLogIntegrationDetails } from '../../../../graphql/fragments/AtlassianLogIntegrationDetails.generated';
import { AsanaLogIntegrationDetails } from '../../../../graphql/fragments/AsanaLogIntegrationDetails.generated';
import { WorkdayLogIntegrationDetails } from '../../../../graphql/fragments/WorkdayLogIntegrationDetails.generated';
import { SqsLogSourceIntegrationDetails } from '../../../../graphql/fragments/SqsLogSourceIntegrationDetails.generated';
import { CloudWatchLogIntegrationDetails } from '../../../../graphql/fragments/CloudwatchLogIntegrationDetails.generated';
import { GcsLogSourceIntegrationDetails } from '../../../../graphql/fragments/GcsLogIntegrationDetails.generated';
import { GraphQLError } from 'graphql';
import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';

export type GetMitreLogSourcesVariables = {};

export type GetMitreLogSources = {
  logSources: Array<
    Types.Maybe<
      | S3LogIntegrationDetails
      | CloudWatchLogIntegrationDetails
      | EventbridgeLogIntegrationDetails
      | SqsLogSourceIntegrationDetails
      | GcsLogSourceIntegrationDetails
      | (OktaLogIntegrationDetails &
          DuoLogIntegrationDetails &
          SalesforceLogIntegrationDetails &
          GithubLogIntegrationDetails &
          ZendeskLogIntegrationDetails &
          GsuiteLogIntegrationDetails &
          BoxLogIntegrationDetails &
          SlackLogIntegrationDetails &
          CrowdstrikeLogIntegrationDetails &
          M365LogIntegrationDetails &
          OnePasswordLogIntegrationDetails &
          ZoomLogIntegrationDetails &
          AtlassianLogIntegrationDetails &
          AsanaLogIntegrationDetails &
          WorkdayLogIntegrationDetails)
    >
  >;
};

export const GetMitreLogSourcesDocument = gql`
  query GetMitreLogSources {
    logSources {
      ... on S3LogIntegration {
        ...S3LogIntegrationDetails
      }
      ... on EventBridgeIntegration {
        ...EventbridgeLogIntegrationDetails
      }
      ... on LogPullingIntegration {
        ...OktaLogIntegrationDetails
        ...DuoLogIntegrationDetails
        ...SalesforceLogIntegrationDetails
        ...GithubLogIntegrationDetails
        ...ZendeskLogIntegrationDetails
        ...GsuiteLogIntegrationDetails
        ...BoxLogIntegrationDetails
        ...SlackLogIntegrationDetails
        ...CrowdstrikeLogIntegrationDetails
        ...M365LogIntegrationDetails
        ...OnePasswordLogIntegrationDetails
        ...ZoomLogIntegrationDetails
        ...AtlassianLogIntegrationDetails
        ...AsanaLogIntegrationDetails
        ...WorkdayLogIntegrationDetails
      }
      ... on SqsLogSourceIntegration {
        ...SqsLogSourceIntegrationDetails
      }
      ... on CloudWatchLogIntegration {
        ...CloudWatchLogIntegrationDetails
      }
      ... on GcsLogSourceIntegration {
        ...GcsLogSourceIntegrationDetails
      }
    }
  }
  ${S3LogIntegrationDetails}
  ${EventbridgeLogIntegrationDetails}
  ${OktaLogIntegrationDetails}
  ${DuoLogIntegrationDetails}
  ${SalesforceLogIntegrationDetails}
  ${GithubLogIntegrationDetails}
  ${ZendeskLogIntegrationDetails}
  ${GsuiteLogIntegrationDetails}
  ${BoxLogIntegrationDetails}
  ${SlackLogIntegrationDetails}
  ${CrowdstrikeLogIntegrationDetails}
  ${M365LogIntegrationDetails}
  ${OnePasswordLogIntegrationDetails}
  ${ZoomLogIntegrationDetails}
  ${AtlassianLogIntegrationDetails}
  ${AsanaLogIntegrationDetails}
  ${WorkdayLogIntegrationDetails}
  ${SqsLogSourceIntegrationDetails}
  ${CloudWatchLogIntegrationDetails}
  ${GcsLogSourceIntegrationDetails}
`;

/**
 * __useGetMitreLogSources__
 *
 * To run a query within a React component, call `useGetMitreLogSources` and pass it any options that fit your needs.
 * When your component renders, `useGetMitreLogSources` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMitreLogSources({
 *   variables: {
 *   },
 * });
 */
export function useGetMitreLogSources(
  baseOptions?: ApolloReactHooks.QueryHookOptions<GetMitreLogSources, GetMitreLogSourcesVariables>
) {
  return ApolloReactHooks.useQuery<GetMitreLogSources, GetMitreLogSourcesVariables>(
    GetMitreLogSourcesDocument,
    baseOptions
  );
}
export function useGetMitreLogSourcesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetMitreLogSources,
    GetMitreLogSourcesVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<GetMitreLogSources, GetMitreLogSourcesVariables>(
    GetMitreLogSourcesDocument,
    baseOptions
  );
}
export type GetMitreLogSourcesHookResult = ReturnType<typeof useGetMitreLogSources>;
export type GetMitreLogSourcesLazyQueryHookResult = ReturnType<
  typeof useGetMitreLogSourcesLazyQuery
>;
export type GetMitreLogSourcesQueryResult = ApolloReactCommon.QueryResult<
  GetMitreLogSources,
  GetMitreLogSourcesVariables
>;
export function mockGetMitreLogSources({
  data,
  variables,
  errors,
}: {
  data: GetMitreLogSources;
  variables?: GetMitreLogSourcesVariables;
  errors?: GraphQLError[];
}) {
  return {
    request: { query: GetMitreLogSourcesDocument, variables },
    result: { data, errors },
  };
}
