/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Flex, Box } from 'pouncejs';
import { FastField, Form, Formik } from 'formik';
import FormikTextInput from 'Components/fields/TextInput';
import SubmitButton from 'Components/buttons/SubmitButton';
import * as Yup from 'yup';
import FormikMultiCombobox from 'Components/fields/MultiComboBox';
import FormikTextArea from 'Components/fields/TextArea';
import FormikSwitch from 'Components/fields/Switch';
import ScheduledQueryForm from 'Components/forms/SavedQueryForm/ScheduledQueryForm';
import FormikCombobox from 'Components/fields/ComboBox';
import { useListDataLakeDatabases } from 'Pages/DataExplorer';
import cronstrue from 'cronstrue';

interface SaveQueryFormProps {
  onSubmit: (values: SaveQueryFormValues) => void;
  initialValues: SaveQueryFormValues;
}

export interface SaveQueryFormValues {
  id?: string;
  name: string;
  description?: string;
  tags?: string[];
  defaultDatabase?: string;
  isScheduled?: boolean;
  periodMinutes?: number;
  periodDays?: number;
  timeoutMinutes?: number;
  type: string;
  // Cron fields splitted
  isEnabled?: boolean;
  minutes?: string;
  hours?: string;
  days?: string;
  months?: string;
  weekDays?: string;
  managed?: boolean;
  parentId?: string;
  sqlQuery?: string;
  rateMinutes?: number;
  schedule?: Record<string, unknown>;
}

const partialCronValidate = ({
  minutes = '*',
  hours = '0',
  days = '*',
  months = '*',
  weekdays = '*',
}) => {
  try {
    cronstrue.toString(`${minutes} ${hours} ${days} ${months} ${weekdays}`);
    return true;
  } catch (e) {
    return false;
  }
};

/**
 * Small helper function to determine whether user has selected a scheduled
 * query on an interval period.
 */
const checkIfScheduledQueryPeriod = (values: SaveQueryFormValues) => {
  return values.isScheduled && values.type === 'period';
};

export const validationSchema = Yup.object().shape({
  name: Yup.string().required(),
  description: Yup.string(),
  tags: Yup.array().of(Yup.string()),
  isScheduled: Yup.boolean(),
  defaultDatabase: Yup.string(),
  // Cron values breakdown
  minutes: Yup.string().when(['isScheduled', 'type'], {
    is: (isScheduled, type) => {
      return isScheduled && type === 'cron';
    },
    then: Yup.string()
      .required()
      .test('is-valid-cron-mins', 'Minutes should be a valid cron syntax', value =>
        partialCronValidate({ minutes: value })
      ),

    otherwise: Yup.string,
  }),

  hours: Yup.string().when(['isScheduled', 'type'], {
    is: (isScheduled, type) => {
      return isScheduled && type === 'cron';
    },
    then: Yup.string()
      .required()
      .test('is-valid-cron-hours', 'Hours should be a valid cron syntax', value =>
        partialCronValidate({ hours: value })
      ),

    otherwise: Yup.string,
  }),

  days: Yup.string().when(['isScheduled', 'type'], {
    is: (isScheduled, type) => {
      return isScheduled && type === 'cron';
    },
    then: Yup.string()
      .required()
      .test('is-valid-cron-days', 'Day(s) should be a valid cron syntax', value =>
        partialCronValidate({ days: value })
      ),

    otherwise: Yup.string,
  }),

  months: Yup.string().when(['isScheduled', 'type'], {
    is: (isScheduled, type) => {
      return isScheduled && type === 'cron';
    },
    then: Yup.string()
      .required()
      .required()
      .test('is-valid-cron-months', 'Month(s) should be a valid cron syntax', value =>
        partialCronValidate({ months: value })
      ),
    otherwise: Yup.string,
  }),

  weekDays: Yup.string().when(['isScheduled', 'type'], {
    is: (isScheduled, type) => {
      return isScheduled && type === 'cron';
    },
    then: Yup.string()
      .required()
      .test('is-valid-cron-week-days', 'Week days should be a valid cron syntax', value =>
        partialCronValidate({ weekdays: value })
      ),
    otherwise: Yup.string,
  }),

  timeoutMinutes: Yup.number().when('isScheduled', {
    is: isScheduled => {
      return isScheduled;
    },
    then: Yup.number().positive().required(),
    otherwise: Yup.number,
  }),

  periodMinutes: Yup.number()
    .min(0)
    .test(
      'mutex',
      'Either period minutes or period days must be specified',
      function validateScheduledQueryPeriod(periodMinutes) {
        if (checkIfScheduledQueryPeriod(this.parent)) {
          const hasValidationError = periodMinutes === 0 && this.parent.periodDays === 0;
          return !hasValidationError;
        }

        return true;
      }
    )
    .test('is-min-period-minutes', function validatePeriodMinutesThreshold(periodMinutes) {
      if (checkIfScheduledQueryPeriod(this.parent)) {
        const hasValidationError = this.parent.periodDays === 0 && periodMinutes < 2;
        return !hasValidationError;
      }

      return true;
    }),
  periodDays: Yup.number()
    .min(0)
    .test(
      'mutex',
      'Either period minutes or period days must be specified',
      function validateScheduledQueryPeriod(periodDays) {
        if (checkIfScheduledQueryPeriod(this.parent)) {
          const hasValidationError = periodDays === 0 && this.parent.periodMinutes === 0;
          return !hasValidationError;
        }
        return true;
      }
    ),
});

const dbItemToString = item => item || '';

const SavedQueryForm: React.FC<SaveQueryFormProps> = ({ onSubmit, initialValues }) => {
  const { data } = useListDataLakeDatabases();
  const { managed } = initialValues;

  const databaseList = React.useMemo(() => {
    if (data?.dataLakeDatabases) {
      return data?.dataLakeDatabases.map(({ name }) => name);
    }
    return [];
  }, [data]);

  return (
    <Formik<SaveQueryFormValues>
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ values }) => (
        <Form>
          <Flex direction="column" spacing={5}>
            <FastField
              as={FormikTextInput}
              label="Query Name *"
              placeholder="A query identifier"
              name="name"
              required
              disabled={managed}
            />
            <FastField
              as={FormikMultiCombobox}
              searchable
              label="Tags"
              name="tags"
              items={initialValues.tags}
              allowAdditions
              disabled={managed}
            />
            <FastField
              as={FormikTextArea}
              label="Description"
              placeholder="A short description for the query"
              name="description"
              disabled={managed}
            />
            <FastField
              name="defaultDatabase"
              as={FormikCombobox}
              items={databaseList}
              itemToString={dbItemToString}
              label="Default Database"
              disabled={managed}
            />
            <Flex align="center">
              <Flex>
                <FastField
                  as={FormikSwitch}
                  label={
                    <Flex spacing={2} align="center">
                      <Box>Is this a Scheduled Query?</Box>
                    </Flex>
                  }
                  name="isScheduled"
                />
              </Flex>
              {values.isScheduled && (
                <Flex ml="auto" mr={0} align="flex-end" spacing={2}>
                  <FastField as={FormikSwitch} label="Is it active?" name="isEnabled" />
                </Flex>
              )}
            </Flex>
            <ScheduledQueryForm />
            <SubmitButton
              aria-label={initialValues.id ? 'Update Query' : 'Save Query'}
              aria-describedby="delay-explanation-helper"
              allowPristineSubmission
              variantColor="green-400"
            >
              {initialValues.id ? 'Update Query' : 'Save Query'}
            </SubmitButton>
          </Flex>
        </Form>
      )}
    </Formik>
  );
};

export default React.memo(SavedQueryForm);
