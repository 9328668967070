/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Alert, Flex, Spinner, SimpleGrid } from 'pouncejs';
import { extractErrorMessage } from 'Helpers/utils';
import NoResultsFound from 'Components/NoResultsFound';
import orderBy from 'lodash/orderBy';
import AvailableLogProviderCard from 'Pages/Integrations/LogSources/LogSourceOnboarding/ListAvailableLogProviders/AvailableLogProviderCard';
import { ENABLE_WORKDAY_PULLER } from 'Source/constants';
import { useListAvailableLogProviders } from './graphql/listAvailableLogProviders.generated';

const ListAvailableLogProviders = () => {
  const { data, loading, error } = useListAvailableLogProviders();
  const hasError = !!error || data?.listAvailableLogProviders?.error;

  const logProviders = React.useMemo(() => {
    const rankedLogProviders = orderBy(
      data?.listAvailableLogProviders?.results ?? [],
      ['popularity', 'displayName'],
      ['desc', 'asc']
    );
    // Filter out workday provider if feature flag is not enabled
    return rankedLogProviders.filter(p => p.id !== 'workday' || ENABLE_WORKDAY_PULLER);
  }, [data]);

  if (loading) {
    return (
      <Flex w="100%" justify="center" my={10}>
        <Spinner />
      </Flex>
    );
  }

  if (hasError) {
    return (
      <Alert
        variant="error"
        title="Couldn't load our available log providers"
        description={
          error ? extractErrorMessage(error) : data.listAvailableLogProviders.error.message
        }
      />
    );
  }

  if (!loading && !hasError && !logProviders.length) {
    return <NoResultsFound />;
  }

  return (
    <SimpleGrid columns={3} gap={2}>
      {logProviders.map(logProvider => (
        <AvailableLogProviderCard
          key={logProvider.id}
          id={logProvider.id}
          title={logProvider.displayName}
        />
      ))}
    </SimpleGrid>
  );
};

export default ListAvailableLogProviders;
