/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { TestDetectionSubRecord } from 'Generated/schema';
import { Text, Box, Flex, Button } from 'pouncejs';
import JSONBig from 'json-bigint';
import useCopyToClipboard from 'Hooks/useCopyToClipboard';

interface AlertContextFunctionProps {
  alertContextFunction: TestDetectionSubRecord;
}

const MAX_LENGTH = 140;

const AlertContextFunction: React.FC<AlertContextFunctionProps> = ({ alertContextFunction }) => {
  const [expanded, setExpanded] = React.useState(false);

  const copyToClipboard = useCopyToClipboard();
  const isTruncated =
    alertContextFunction.output && alertContextFunction.output.length > MAX_LENGTH;
  const truncated = isTruncated
    ? `${alertContextFunction.output.substring(0, MAX_LENGTH)}...`
    : alertContextFunction.output;

  const toggle = React.useCallback(() => setExpanded(!expanded), [expanded]);

  const copyJSON = React.useCallback(
    () => copyToClipboard(JSONBig.stringify(alertContextFunction.output, null, '\t')),
    // FIXME: look into hook dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [alertContextFunction.output]
  );

  return !alertContextFunction.error ? (
    <Box as="dd">
      <Flex align="top">
        <Box mr="2" maxWidth="70%">
          {expanded ? alertContextFunction.output : truncated}
        </Box>

        <Flex ml="auto" mr={0} spacing={1}>
          {isTruncated && (
            <Button
              data-testid="toggle-alert-context"
              size="small"
              variantColor="navyblue-300"
              onClick={toggle}
            >
              {expanded ? 'Collapse' : 'Expand'}
            </Button>
          )}
          <Button
            data-testid="copy-json-alert-context"
            size="small"
            variantColor="navyblue-300"
            onClick={copyJSON}
          >
            Copy
          </Button>
        </Flex>
      </Flex>
    </Box>
  ) : (
    <Text as="dd" color="red-200">
      {alertContextFunction.error.message}
    </Text>
  );
};
export default AlertContextFunction;
