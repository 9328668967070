/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Alert, Box } from 'pouncejs';
import urls from 'Source/urls';
import PolicyForm, { PolicyFormValues } from 'Components/forms/PolicyForm';
import { AddPolicyInput, Permission } from 'Generated/schema';
import { DEFAULT_POLICY_FUNCTION } from 'Source/constants';
import { cleanUpEmptyDetectionTestMocks, extractErrorMessage } from 'Helpers/utils';
import useRouter from 'Hooks/useRouter';
import withRoleRestrictedAccess from 'Hoc/withRoleRestrictedAccess';
import pick from 'lodash/pick';
import Page403 from 'Pages/403';
import useUrlParams from 'Hooks/useUrlParams';
import useClonePolicy from 'Hooks/useClonePolicy';
import { useCreatePolicy } from './graphql/createPolicy.generated';
import { CreateDetectionPresetValues } from '../CreateDetection';

interface CreatePolicyProps {
  sessionId?: string;
  presets?: CreateDetectionPresetValues;
}

export const defaultInitialValues = {
  body: DEFAULT_POLICY_FUNCTION,
  description: '',
  displayName: '',
  enabled: true,
  managed: false,
  parentId: null,
  id: '',
  outputIds: [],
  reference: '',
  resourceTypes: [],
  runbook: '',
  severity: null,
  suppressions: [],
  tags: [],
  tests: [],
  reports: [],
};

export const createInitialValues = (
  presets: CreateDetectionPresetValues = {}
): Required<AddPolicyInput> => ({
  ...defaultInitialValues,
  ...presets,
});

const CreatePolicy = ({ sessionId, presets }: CreatePolicyProps) => {
  const { history } = useRouter();
  const { retrieveClonedPolicy } = useClonePolicy();
  const [createPolicy, { error }] = useCreatePolicy({
    onCompleted: data => history.push(urls.analysis.policies.details(data.addPolicy.id)),
    onError: () => null,
  });

  const handleSubmit = React.useCallback(
    ({ tests, ...rest }: PolicyFormValues) =>
      createPolicy({
        variables: { input: { ...rest, tests: cleanUpEmptyDetectionTestMocks(tests) } },
      }),
    [createPolicy]
  );

  const {
    urlParams: { cloneId },
  } = useUrlParams();

  const initialValues = React.useMemo(() => {
    const values = createInitialValues(presets);
    if (cloneId) {
      const clonedPolicy = retrieveClonedPolicy(cloneId);
      return pick(clonedPolicy, Object.keys(values)) as PolicyFormValues;
    }

    return values;
  }, [retrieveClonedPolicy, cloneId, presets]);

  return (
    <Box mb={6}>
      {error && (
        <Box mb={6}>
          <Alert
            variant="error"
            title="Couldn't create your policy"
            description={
              extractErrorMessage(error) ||
              'An unknown error occurred as we were trying to create your policy'
            }
          />
        </Box>
      )}
      <PolicyForm
        initialValues={initialValues}
        onSubmit={handleSubmit}
        cacheSessionId={sessionId}
      />
    </Box>
  );
};

export default withRoleRestrictedAccess({
  allowedPermissions: [Permission.PolicyModify],
  fallback: <Page403 />,
})(CreatePolicy);
