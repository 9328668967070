/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { SimpleGrid, Box, Heading, Link, Divider } from 'pouncejs';
import { SNOWFLAKE_DOC_URL, PANTHER_CONFIG } from 'Source/constants';
import SettingEntry from 'Components/SettingEntry';
import ErrorBoundary from 'Components/ErrorBoundary';

const SnowflakeSection: React.FC = () => {
  return (
    <ErrorBoundary>
      <Heading size="x-small" color="navyblue-100" mb={6}>
        Use the following values to your Snowflake configuration
      </Heading>
      <SimpleGrid columns={2} spacing={4} mb={2}>
        <Box>
          <Divider width="100%" color="navyblue-300" />
          <Box minHeight="70px">
            <SettingEntry
              label="Snowflake Cluster ARNs"
              value={PANTHER_CONFIG.SNOWFLAKE_CLUSTER_ARNS.split(',').join('\n')}
            />
          </Box>
          <Divider width="100%" color="navyblue-300" />
          <Box minHeight="70px">
            <SettingEntry
              label="Snowflake ReadOnly Secret ARN"
              value={PANTHER_CONFIG.SNOWFLAKE_READONLY_SECRET_ARN}
            />
          </Box>
          <Divider width="100%" color="navyblue-300" />
          <Box minHeight="70px">
            <SettingEntry
              label="Snowflake Admin Secret ARN"
              value={PANTHER_CONFIG.SNOWFLAKE_ADMIN_SECRET_ARN}
            />
          </Box>
          <Divider width="100%" color="navyblue-300" />
        </Box>
        <Box>
          <Divider width="100%" color="navyblue-300" />
          <Box minHeight="70px">
            <SettingEntry
              label="Snowflake ReadOnly Lambda Role ARN"
              value={PANTHER_CONFIG.SNOWFLAKE_READONLY_LAMBDA_ROLE_ARN}
            />
          </Box>
          <Divider width="100%" color="navyblue-300" />
          <Box minHeight="70px">
            <SettingEntry
              label="Snowflake Admin Lambda Role ARN"
              value={PANTHER_CONFIG.SNOWFLAKE_ADMIN_LAMBDA_ROLE_ARN}
            />
          </Box>
          <Divider width="100%" color="navyblue-300" />
          <Box minHeight="70px">
            <SettingEntry
              label="Lookup Tables Role ARN"
              value={PANTHER_CONFIG.LOOKUP_TABLES_LAMBDA_ROLE_ARN}
            />
          </Box>
          <Divider width="100%" color="navyblue-300" />
        </Box>
      </SimpleGrid>
      <Link external href={SNOWFLAKE_DOC_URL} fontSize="small-medium" p={2}>
        Read more in our documentation
      </Link>
    </ErrorBoundary>
  );
};

export default SnowflakeSection;
