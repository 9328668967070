/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import withSEO from 'Hoc/withSEO';
import { compose } from 'Helpers/compose';
import withRoleRestrictedAccess from 'Hoc/withRoleRestrictedAccess';
import useAvailableLogTypesForLogPuller from 'Hooks/useAvailableLogTypesForLogPuller';
import { OnePasswordHost, Permission } from 'Generated/schema';
import Page403 from 'Pages/403';
import OnePasswordLogSourceWizard, {
  OnePasswordLogSourceWizardValues,
} from '../OnePasswordLogSourceWizard';
import { useAddOnePasswordLogSource } from './graphql/addOnePasswordLogSource.generated';

const CreateOnePasswordLogSource: React.FC = () => {
  const { defaultLogTypes } = useAvailableLogTypesForLogPuller();
  const initialValues = React.useMemo(
    () => ({
      integrationLabel: '',
      logTypes: defaultLogTypes,
      token: '',
      host: OnePasswordHost.Business,
    }),
    [defaultLogTypes]
  );

  const [addOnePasswordLogSource] = useAddOnePasswordLogSource({
    update: (cache, { data }) => {
      cache.modify({
        fields: {
          logSources: (queryData, { toReference }) => {
            const addedIntegrationCacheRef = toReference(data.addLogPullingIntegration);
            return queryData
              ? [addedIntegrationCacheRef, ...queryData]
              : [addedIntegrationCacheRef];
          },
        },
      });
    },
  });

  const handleSubmit = React.useCallback(
    async (values: OnePasswordLogSourceWizardValues) => {
      try {
        const { data } = await addOnePasswordLogSource({
          variables: {
            input: {
              integrationLabel: values.integrationLabel,
              pullerConfig: {
                logTypes: values.logTypes,
                onePassword: {
                  token: values.token,
                  host: values.host,
                },
              },
            },
          },
        });
        return data.addLogPullingIntegration;
      } catch (err) {
        return err as Error;
      }
    },
    [addOnePasswordLogSource]
  );

  return <OnePasswordLogSourceWizard initialValues={initialValues} onSubmit={handleSubmit} />;
};

export default compose(
  withSEO({ title: 'New 1Password Source' }),
  withRoleRestrictedAccess({
    allowedPermissions: [Permission.LogSourceModify],
    fallback: <Page403 />,
  })
)(CreateOnePasswordLogSource);
