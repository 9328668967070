/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import {
  ComplianceStatusEnum,
  TestPolicyRecord,
  TestPolicyRecordFunctions,
} from 'Generated/schema';
import { Card, Flex, Box, Heading, Grid } from 'pouncejs';
import StatusBadge from 'Components/badges/StatusBadge';
import AlertContextFunction from 'Components/utils/AlertContextFunction/AlertContextFunction';
import DetectionFormListItem, { severityToString } from 'Components/forms/DetectionFormListItem';

interface PolicyFormTestResultProps {
  testResult: TestPolicyRecord;
}

const functionTitleMapping = {
  dedupFunction: 'Dedup String',
  titleFunction: 'Alert Title',
  referenceFunction: 'Reference',
  runbookFunction: 'Runbook',
  descriptionFunction: 'Description',
  severityFunction: 'Severity',
  destinationsFunction: 'Destinations',
};

const PolicyFormTestResult: React.FC<PolicyFormTestResultProps> = ({ testResult }) => {
  const { passed, name, error: unknownError } = testResult;
  const { detectionFunction, alertContextFunction, ...otherFunctions } =
    testResult?.functions ||
    ({
      detectionFunction: () => ({ error: null }),
      alertContextFunction: () => ({ error: null }),
    } as TestPolicyRecordFunctions);

  return (
    <Card p={4} as="article" data-testid="test-result">
      <Flex align="flex-start" spacing={4}>
        <StatusBadge status={passed ? ComplianceStatusEnum.Pass : ComplianceStatusEnum.Fail} />
        <Box>
          <Heading as="h2" size="x-small" fontWeight="medium">
            {name}
          </Heading>
          <Grid
            as="dl"
            wordBreak="break-word"
            templateColumns="max-content 1fr"
            fontSize="medium"
            fontWeight="medium"
            columnGap={4}
            rowGap={2}
            mt={2}
          >
            {unknownError && (
              <DetectionFormListItem title="Error" errorMessage={unknownError.message} />
            )}
            {detectionFunction?.error && (
              <DetectionFormListItem
                title="Policy Body"
                errorMessage={detectionFunction?.error?.message}
              />
            )}
            {Object.entries(otherFunctions)
              // filter out empty functions and typename field
              .filter(([key, value]) => !!value && key !== '__typename')
              .map(([key, value]) => {
                let { output } = value;
                if (key === 'severityFunction') {
                  output = severityToString(output);
                }
                return (
                  <DetectionFormListItem
                    key={key}
                    title={functionTitleMapping[key]}
                    text={output}
                    errorMessage={value?.error?.message}
                  />
                );
              })}
            {alertContextFunction && (
              <React.Fragment>
                <Box as="dt" color="navyblue-100">
                  Alert Context
                </Box>
                <AlertContextFunction alertContextFunction={alertContextFunction} />
              </React.Fragment>
            )}
          </Grid>
        </Box>
      </Flex>
    </Card>
  );
};

export default PolicyFormTestResult;
