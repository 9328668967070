/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { State } from 'Components/utils/AsyncQueryContext';
import BrowserHistoryAsyncQueryContextProvider from './BrowserHistoryAsyncQueryContextProvider';
import MemoryAsyncQueryContextProvider from './MemoryAsyncQueryContextProvider';

export interface AsyncQueryContextProviderProps {
  storage: 'browserHistory' | 'memory';
  initialState?: State;
}

const AsyncQueryContextProvider: React.FC<AsyncQueryContextProviderProps> = ({
  storage,
  ...rest
}) => {
  return storage === 'browserHistory' ? (
    <BrowserHistoryAsyncQueryContextProvider {...rest} />
  ) : (
    <MemoryAsyncQueryContextProvider {...rest} />
  );
};

export default AsyncQueryContextProvider;
