/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Flex, Button, Box, Switch, useSnackbar } from 'pouncejs';
import { Lookup, Permission, UpdateLookupInput } from 'Generated/schema';
import urls from 'Source/urls';
import Breadcrumbs from 'Components/Breadcrumbs';
import LinkButton from 'Components/buttons/utils';
import useCheckPermissions from 'Hooks/useCheckPermissions';
import { useUpdateLookup } from 'Source/graphql/queries/updateLookup.generated';
import { extractErrorMessage } from 'Helpers/utils';
import { transformLookupToUpdateInput } from 'Pages/Enrichment/Lookups/utils';

interface ActionBarProps {
  lookup: Lookup;
}

const ActionBar: React.FC<ActionBarProps> = ({ lookup }) => {
  const hasLookupModifyPermission = useCheckPermissions(Permission.LookupModify);
  const isGreynoise = lookup.name.toLowerCase().includes('greynoise');
  const { pushSnackbar } = useSnackbar();
  const [updateLookup] = useUpdateLookup({
    onCompleted: response => {
      const updatedLookup = response.updateLookup;
      pushSnackbar({
        variant: 'success',
        title: `${updatedLookup.name} successfully ${
          updatedLookup.enabled ? 'enabled' : 'disabled'
        }`,
      });
    },
    onError: err => {
      pushSnackbar({
        variant: 'error',
        title: "Couldn't update lookup",
        description: extractErrorMessage(err) || 'Some unknown error occurred',
      });
    },
  });

  const handleToggleEnabled = React.useCallback(() => {
    const updateLookupInput: UpdateLookupInput = {
      ...transformLookupToUpdateInput(lookup),
      enabled: !lookup.enabled,
    };

    updateLookup({
      variables: {
        input: updateLookupInput,
      },
    });
  }, [lookup, updateLookup]);

  return (
    <Breadcrumbs.Actions>
      <Flex alignItems="center">
        <Box mr={6}>
          <Switch
            checkedText="Yes"
            uncheckedText="No"
            checked={lookup.enabled}
            readOnly={!hasLookupModifyPermission}
            disabled={!hasLookupModifyPermission}
            onChange={handleToggleEnabled}
            label="Enabled"
            data-tid="toggle-enable-switch"
          />
        </Box>
        {!isGreynoise && (
          <LinkButton
            data-tid="view-in-data-explorer"
            to={`${urls.data.dataExplorer()}?snippedId=lookupTable&lookupId=${lookup.id}`}
          >
            <Button variant="outline" variantColor="navyblue-300" as="span" type={null}>
              View in Data Explorer
            </Button>
          </LinkButton>
        )}
      </Flex>
    </Breadcrumbs.Actions>
  );
};

export default ActionBar;
