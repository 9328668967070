/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import { AlertOriginReplayedRule, AlertType } from 'Generated/schema';
import { Link as RRLink } from 'react-router-dom';
import { Badge, Box, Card, Flex, Link } from 'pouncejs';
import React from 'react';
import { AlertSummaryFull } from 'Source/graphql/fragments/AlertSummaryFull.generated';
import SeverityBadge from 'Components/badges/SeverityBadge';
import { formatDatetime } from 'Helpers/utils';
import CardBulletDivider from 'Components/cards/common/CardBulletDivider';
import { CardMetaValue } from 'Components/cards/common';
import LimitItemDisplay from 'Components/LimitItemDisplay';
import urls from 'Source/urls';
import AdditionalBadgesJewel from 'Components/AdditionalBadgesJewel';

export type ReplayedAlertCardProps = {
  alert: AlertSummaryFull;
  replayId: string;
  detectionId: string;
};

const supportedTypes = [AlertType.Rule, AlertType.RuleError];

const ReplayedAlertCard: React.FC<ReplayedAlertCardProps> = ({ alert, replayId, detectionId }) => {
  if (!supportedTypes.includes(alert.type)) {
    return null; // unsupported
  }

  const originData = alert.origin as AlertOriginReplayedRule;
  const listItems = originData.logTypes;

  return (
    <Card as="section" variant="dark" p={4}>
      <Flex>
        <Box width="120px">
          <SeverityBadge severity={alert.severity} />
        </Box>
        <Flex direction="column" justify="space-between" width={1}>
          <Flex as="header" align="flex-start" mb={2}>
            <Box mr="auto" maxWidth="80%">
              <Box
                as="h4"
                data-testid="single-alert-value"
                fontWeight="medium"
                wordBreak="break-word"
              >
                <Link
                  as={RRLink}
                  aria-label="Link to Alert"
                  to={urls.replays.alerts(detectionId, replayId, alert.id)}
                >
                  {alert.title}
                </Link>
              </Box>
              <Flex mt={2} wrap="wrap">
                <CardMetaValue
                  label="Rule"
                  labelColor="gray"
                  value={originData.ruleId}
                  link={urls.analysis.rules.details(originData.ruleId)}
                  data-tid="link-to-rule-from-alert-card"
                />
                <CardBulletDivider />
                <CardMetaValue
                  labelColor="gray"
                  label="Last received event"
                  value={formatDatetime(alert.lastReceivedEventAt)}
                />
                <CardBulletDivider />
                <CardMetaValue
                  labelColor="gray"
                  label="Events"
                  value={
                    originData?.eventsMatched ? originData?.eventsMatched.toLocaleString() : '0'
                  }
                />
              </Flex>
            </Box>
          </Flex>
          <Flex justify="space-between" mt={4}>
            <Flex spacing={1}>
              <LimitItemDisplay limit={4} AdditionalItemsComponent={AdditionalBadgesJewel}>
                {listItems.map(item => (
                  <Badge key={item} color="navyblue-200">
                    {item}
                  </Badge>
                ))}
              </LimitItemDisplay>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Card>
  );
};

export default ReplayedAlertCard;
