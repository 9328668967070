/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import { AlertOriginReplayedRule } from 'Generated/schema';
import React from 'react';
import groupBy from 'lodash/groupBy';
import { Text, Flex, Icon, AbstractButton, Box, Collapse, useSnackbar } from 'pouncejs';
import { AlertDetails } from 'Pages/AlertDetails';
import useAlertDestinationsDeliverySuccess from 'Hooks/useAlertDestinationsDeliverySuccess';
import { AlertDeliveryFull } from 'Source/graphql/fragments/AlertDeliveryFull.generated';
import AlertDeliveryTable from './AlertDeliveryTable';
import { useRetryAlertDelivery } from './graphql/retryAlertDelivery.generated';

interface AlertDeliverySectionProps {
  alert: AlertDetails['alert'];
}

const AlertDeliverySection: React.FC<AlertDeliverySectionProps> = ({ alert }) => {
  const [isHistoryVisible, setHistoryVisibility] = React.useState(false);

  const { pushSnackbar } = useSnackbar();

  const isSuccessful = React.useCallback((deliveryResponses: AlertDeliveryFull[]) => {
    // Create a map of OutputIds to Delivery Responses
    const responsesByOutputId = groupBy(deliveryResponses, r => r.outputId);

    // We iterate over each group's responses. If all groups
    // had at least 1 successful status, we've successfully retried the alert.
    const pass = Object.values(responsesByOutputId).reduce(
      (acc, responses) => responses.some(({ success }) => success) && acc,
      true
    );

    return pass;
  }, []);

  const [retryAlertDelivery, { loading }] = useRetryAlertDelivery({
    update: (cache, { data }) => {
      const dataId = cache.identify({
        __typename: 'Alert',
        id: data.deliverAlert.id,
      });

      cache.modify({
        id: dataId,
        fields: {
          deliveries: () => data.deliverAlert.deliveries,
        },
      });
    },
    onError: () => pushSnackbar({ variant: 'error', title: 'Failed to deliver alert' }),
    onCompleted: data => {
      if (isSuccessful(data.deliverAlert.deliveries)) {
        pushSnackbar({ variant: 'success', title: 'Successfully delivered alert' });
      } else {
        pushSnackbar({ variant: 'error', title: 'Failed to deliver alert' });
      }
    },
  });

  const onAlertDeliveryRetry = React.useCallback(
    (outputId: string) => {
      retryAlertDelivery({
        variables: {
          input: {
            alertId: alert.id,
            outputIds: [outputId],
          },
        },
      });
    },
    [retryAlertDelivery, alert]
  );

  const { deliveries } = alert;
  const {
    enhancedAndSortedAlertDeliveries,
    allDestinationDeliveredSuccessfully,
    loading: loadingDeliverySuccess,
  } = useAlertDestinationsDeliverySuccess({ alert });

  if (!deliveries.length || !enhancedAndSortedAlertDeliveries.length) {
    return (
      <Flex align="center" spacing={4}>
        <Icon type="info" size="medium" color="blue-400" />
        <Text fontWeight="medium">No delivery information could be found for this alert</Text>
      </Flex>
    );
  }

  const successMessage = (alert.origin as AlertOriginReplayedRule)?.replayId
    ? 'Simulated alert deliveries'
    : 'Alert was delivered successfully';

  return (
    <Box>
      <Flex justify="space-between">
        {!loadingDeliverySuccess && allDestinationDeliveredSuccessfully ? (
          <Flex align="center" spacing={4}>
            <Icon type="check-circle" size="medium" color="green-400" />
            <Text fontWeight="medium">{successMessage}</Text>
          </Flex>
        ) : (
          <Flex align="center" spacing={4}>
            <Icon type="alert-circle" size="medium" color="red-300" />
            <Text fontWeight="medium" color="red-300">
              Alert delivery failed
            </Text>
          </Flex>
        )}
        <AbstractButton
          fontSize="medium"
          color="teal-400"
          _hover={{ color: 'teal-300' }}
          onClick={() => setHistoryVisibility(!isHistoryVisible)}
        >
          {isHistoryVisible ? 'Hide History' : 'Show History'}
        </AbstractButton>
      </Flex>
      <Collapse open={isHistoryVisible}>
        <Box backgroundColor="navyblue-400" mt={6}>
          <AlertDeliveryTable
            alertDeliveries={enhancedAndSortedAlertDeliveries}
            onAlertDeliveryRetry={onAlertDeliveryRetry}
            isResending={loading}
          />
        </Box>
      </Collapse>
    </Box>
  );
};

export default React.memo(AlertDeliverySection);
