/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Box, SimpleGrid, Alert, Text } from 'pouncejs';
import ErrorBoundary from 'Components/ErrorBoundary';
import useUrlParams from 'Hooks/useUrlParams';
import withSEO from 'Hoc/withSEO';
import withRoleRestrictedAccess from 'Hoc/withRoleRestrictedAccess';
import { AddPolicyInput, AddRuleInput, DetectionTypeEnum, Permission } from 'Generated/schema';
import { ENTITIES_COLOR_MAP, MITRE_REPORT_KEY } from 'Source/constants';
import Page403 from 'Pages/403/403';
import { compose } from 'Helpers/compose';
import TextButton from 'Components/buttons/TextButton';
import CreateRule from './CreateRule';
import CreatePolicy from './CreatePolicy';
import DetectionSelectionCard from './DetectionSelectionCard';

export interface CreateDetectionUrlParams {
  type?: 'rule' | 'policy' | 'scheduledRule';
  section?: string;
  sessionId?: string;
  mitreTactic?: string;
  mitreTechnique?: string;
}

export interface CreateDetectionPresetValues {
  reports?: AddRuleInput['reports'] | AddPolicyInput['reports'];
}

interface PresetValueOptions {
  mitreTactic?: string;
  mitreTechnique?: string;
}

// getPresetValues reads options and adds presets in reverse priority order.
export const getPresetValues = (opts: PresetValueOptions): CreateDetectionPresetValues => {
  if (opts.mitreTactic && opts.mitreTechnique) {
    return {
      reports: [{ key: MITRE_REPORT_KEY, values: [`${opts.mitreTactic}:${opts.mitreTechnique}`] }],
    };
  }
  return {};
};

const CreateDetection: React.FC = () => {
  const {
    urlParams,
    urlParams: { type, sessionId, mitreTactic, mitreTechnique },
    updateUrlParams,
  } = useUrlParams<CreateDetectionUrlParams>();

  React.useLayoutEffect(() => {
    if (!type) {
      updateUrlParams({ type: 'rule' });
    }
  }, [type, urlParams, updateUrlParams]);

  const presets = getPresetValues({ mitreTactic, mitreTechnique });
  const mitreAutoMapped = mitreTactic && mitreTechnique && presets.reports;

  return (
    <React.Fragment>
      <SimpleGrid spacing={4} columns={3}>
        <DetectionSelectionCard
          type="rule"
          title="Rule"
          description="Python3 functions used to identify suspicious activity and generate helpful signals for your team."
          icon="log-analysis"
          iconColor={ENTITIES_COLOR_MAP[DetectionTypeEnum.Rule]}
        />
        <DetectionSelectionCard
          type="policy"
          title="Policy"
          description="Python3 functions used to identify misconfigured infrastructure and generate alerts for your team."
          icon="cloud-security"
          iconColor={ENTITIES_COLOR_MAP[DetectionTypeEnum.Policy]}
        />
        <DetectionSelectionCard
          type="scheduledRule"
          title="Scheduled Rule"
          description="Python3 functions used to identify suspicious activity that run on a schedule"
          icon="schedule"
          iconColor={ENTITIES_COLOR_MAP[DetectionTypeEnum.ScheduledRule]}
        />
      </SimpleGrid>
      {mitreAutoMapped && (
        <Box mt={6}>
          <Alert
            title="MITRE mapping pre-filled"
            variant="info"
            discardable
            description={
              <>
                <TextButton
                  onClick={() => updateUrlParams({ section: 'mappings' })}
                  fontSize="medium"
                >
                  Report Mapping
                </TextButton>
                <Text as="span">
                  {` has been pre-filled with a mapping to the MITRE tactic technique combo "${mitreTactic}:${mitreTechnique}"`}
                </Text>
              </>
            }
          />
        </Box>
      )}
      <Box py={6}>
        <ErrorBoundary>
          {type === 'rule' && <CreateRule sessionId={`${sessionId}-rule`} presets={presets} />}
          {type === 'policy' && (
            <CreatePolicy sessionId={`${sessionId}-policy`} presets={presets} />
          )}
          {type === 'scheduledRule' && (
            <CreateRule isScheduled sessionId={`${sessionId}-scheduled-rule`} presets={presets} />
          )}
        </ErrorBoundary>
      </Box>
    </React.Fragment>
  );
};

export default compose(
  withSEO({ title: 'New Detection' }),
  withRoleRestrictedAccess({
    allowedPermissions: [Permission.PolicyModify, Permission.RuleModify],
    fallback: <Page403 />,
  })
)(CreateDetection);
