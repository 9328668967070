/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import FormikTextInput from 'Components/fields/TextInput';
import { FastField, useFormikContext } from 'formik';
import { Flex, Heading, SimpleGrid, Text } from 'pouncejs';
import LinkButton from 'Components/buttons/LinkButton';
import ErrorBoundary from 'Components/ErrorBoundary';
import React from 'react';
import { PANTHER_CONFIG } from 'Source/constants';
import { toStackNameFormat, parseS3Url } from 'Helpers/utils';
import { LookupFormValues } from '../../lookupWizardHelpers';

/**
 * Since this component can potentially throw errors (when the S3 URL is not valid), split
 * it out into a separate component so that we can wrap it in an ErrorBoundary
 */
const LaunchAwsConsoleLink = () => {
  const { values } = useFormikContext<LookupFormValues>();
  const { kmsKey, s3FileLocation, name } = values;
  const stackName = toStackNameFormat(name);
  const { bucket, key } = parseS3Url(s3FileLocation);
  const cfnConsoleLink =
    `https://console.aws.amazon.com/cloudformation/home#/stacks/create/review` +
    `?templateURL=https://panther-public-cloudformation-templates.s3-us-west-2.amazonaws.com/panther-s3-lookups-iam/v${PANTHER_CONFIG.PANTHER_VERSION}/template.yml` +
    `&stackName=panther-lookups-${stackName}` +
    `&param_MasterAccountId=${PANTHER_CONFIG.AWS_ACCOUNT_ID}` +
    `&param_RoleSuffix=${stackName}` +
    `&param_S3Object=${key}` +
    `&param_S3Bucket=${bucket}` +
    `&param_KmsKey=${kmsKey || ''}`;

  return (
    <LinkButton
      external
      to={cfnConsoleLink}
      icon="spacecraft"
      variantColor="teal-400"
      data-tid="lookup-iam-role-console-launched"
      data-testid="console-button"
    >
      Launch Console UI
    </LinkButton>
  );
};

const LookupIAMPolicyConsole: React.FC = () => {
  const { values } = useFormikContext<LookupFormValues>();

  return (
    <SimpleGrid gap={8} mx="auto">
      <Flex as="section" spacing={2} direction="column">
        <Heading as="h2" color="teal-200" fontSize="x-large" fontWeight="medium">
          1. Deploy CloudFormation Template
        </Heading>
        <Text color="white-100" fontSize="medium" lineHeight="relaxed">
          Deploy this autogenerated CloudFormation template in AWS Account {values.awsAccountId} to
          create the IAM role that Panther will use to read data from your S3 bucket.
        </Text>

        <Flex justifyContent="center">
          <ErrorBoundary>
            <LaunchAwsConsoleLink />
          </ErrorBoundary>
        </Flex>
      </Flex>

      <Flex as="section" spacing={2} direction="column">
        <Heading as="h2" color="teal-200" fontSize="x-large" fontWeight="medium">
          2. Enter Role ARN
        </Heading>
        <Text color="white-100" fontSize="medium" lineHeight="relaxed">
          Go to the {'"'}Outputs{'"'} tab of the CloudFormation stack that you just deployed and
          copy the value for RoleARN into the box below.
        </Text>

        <FastField
          name="iamRoleArn"
          as={FormikTextInput}
          label="Role ARN"
          placeholder="The ARN that Panther will use to read s3 objects."
          trimPastedText
          required
          data-tid="lookup-iam-role-arn-field"
        />
      </Flex>
    </SimpleGrid>
  );
};

export default LookupIAMPolicyConsole;
