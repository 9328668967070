/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import * as Types from '../../../../../../__generated__/schema';

import { IntegrationItemHealthDetails } from '../../../../../graphql/fragments/IntegrationItemHealthDetails.generated';
import { GraphQLError } from 'graphql';
import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';

export type GetSourceHealthAndAlarmsVariables = {
  id: Types.Scalars['ID'];
};

export type GetSourceHealthAndAlarms = {
  getLogPullingIntegration: Pick<Types.LogPullingIntegration, 'integrationId'> & {
    oauth2?: Types.Maybe<Pick<Types.OAuth2Info, 'mustAuthorize'>>;
    health: {
      logPullingStatus?: Types.Maybe<IntegrationItemHealthDetails>;
      systemAlarmStatus?: Types.Maybe<IntegrationItemHealthDetails>;
      logProcessingErrorsStatus?: Types.Maybe<IntegrationItemHealthDetails>;
    };
    alarms: { eventThreshold?: Types.Maybe<Pick<Types.EventThresholdAlarm, 'minutesThreshold'>> };
  };
};

export const GetSourceHealthAndAlarmsDocument = gql`
  query GetSourceHealthAndAlarms($id: ID!) {
    getLogPullingIntegration(id: $id) {
      integrationId
      oauth2 {
        mustAuthorize
      }
      health {
        logPullingStatus {
          ...IntegrationItemHealthDetails
        }
        systemAlarmStatus {
          ...IntegrationItemHealthDetails
        }
        logProcessingErrorsStatus {
          ...IntegrationItemHealthDetails
        }
      }
      alarms {
        eventThreshold {
          minutesThreshold
        }
      }
    }
  }
  ${IntegrationItemHealthDetails}
`;

/**
 * __useGetSourceHealthAndAlarms__
 *
 * To run a query within a React component, call `useGetSourceHealthAndAlarms` and pass it any options that fit your needs.
 * When your component renders, `useGetSourceHealthAndAlarms` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSourceHealthAndAlarms({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetSourceHealthAndAlarms(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetSourceHealthAndAlarms,
    GetSourceHealthAndAlarmsVariables
  >
) {
  return ApolloReactHooks.useQuery<GetSourceHealthAndAlarms, GetSourceHealthAndAlarmsVariables>(
    GetSourceHealthAndAlarmsDocument,
    baseOptions
  );
}
export function useGetSourceHealthAndAlarmsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetSourceHealthAndAlarms,
    GetSourceHealthAndAlarmsVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<GetSourceHealthAndAlarms, GetSourceHealthAndAlarmsVariables>(
    GetSourceHealthAndAlarmsDocument,
    baseOptions
  );
}
export type GetSourceHealthAndAlarmsHookResult = ReturnType<typeof useGetSourceHealthAndAlarms>;
export type GetSourceHealthAndAlarmsLazyQueryHookResult = ReturnType<
  typeof useGetSourceHealthAndAlarmsLazyQuery
>;
export type GetSourceHealthAndAlarmsQueryResult = ApolloReactCommon.QueryResult<
  GetSourceHealthAndAlarms,
  GetSourceHealthAndAlarmsVariables
>;
export function mockGetSourceHealthAndAlarms({
  data,
  variables,
  errors,
}: {
  data: GetSourceHealthAndAlarms;
  variables?: GetSourceHealthAndAlarmsVariables;
  errors?: GraphQLError[];
}) {
  return {
    request: { query: GetSourceHealthAndAlarmsDocument, variables },
    result: { data, errors },
  };
}
