/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Alert, Box, SimpleGrid } from 'pouncejs';
import Panel from 'Components/Panel';
import { compose } from 'Helpers/compose';
import { Permission } from 'Generated/schema';
import Page403 from 'Pages/403';
import withRoleRestrictedAccess from 'Hoc/withRoleRestrictedAccess';
import ErrorBoundary from 'Components/ErrorBoundary';
import NoResultsFound from 'Components/NoResultsFound';
import { extractErrorMessage } from 'Helpers/utils';
import withSEO from 'Hoc/withSEO';
import { SourceIntegrationType } from 'Pages/Integrations/utils';
import { useListLogSources } from './graphql/listLogSources.generated';
import EmptyDataFallback from './EmptyDataFallback';
import Skeleton from './Skeleton';
import ListLogSourceActions from './ListLogSourceActions';
import useLogSourceFiltering from './useLogSourceFiltering';
import LogSourceCard from './LogSourceCards/LogSourceCard';

const ListLogSources = () => {
  const { loading, error, data } = useListLogSources({
    fetchPolicy: 'cache-and-network', // we should always use this policy due to health checks.
    nextFetchPolicy: 'cache-first',
  });

  const filteredLogIntegrations = useLogSourceFiltering(data);

  if (loading && !data) {
    return <Skeleton />;
  }

  if (error) {
    return (
      <Alert
        variant="error"
        title="Couldn't load your sources"
        description={
          extractErrorMessage(error) ||
          'There was an error when performing your request, please contact support@runpanther.io'
        }
      />
    );
  }

  if (!data?.logSources?.length) {
    return <EmptyDataFallback />;
  }

  return (
    <Box mb={6}>
      <Panel title="Log Sources" actions={<ListLogSourceActions />}>
        <ErrorBoundary>
          <SimpleGrid as="article" columns={1} gap={5}>
            {filteredLogIntegrations.length ? (
              filteredLogIntegrations.map(source => (
                <LogSourceCard
                  source={source as SourceIntegrationType}
                  key={source.integrationId}
                />
              ))
            ) : (
              <Box my={8}>
                <NoResultsFound />
              </Box>
            )}
          </SimpleGrid>
        </ErrorBoundary>
      </Panel>
    </Box>
  );
};

export default compose(
  withSEO({ title: 'Log Analysis Sources' }),
  withRoleRestrictedAccess({
    allowedPermissions: [Permission.LogSourceRead, Permission.LogSourceModify],
    fallback: <Page403 />,
  })
)(ListLogSources);
