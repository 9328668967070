/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { useSnackbar } from 'pouncejs';
import Page404 from 'Pages/404';
import Page403 from 'Pages/403';
import useRouter from 'Hooks/useRouter';
import withSEO from 'Hoc/withSEO';
import withRoleRestrictedAccess from 'Hoc/withRoleRestrictedAccess';
import { compose } from 'Helpers/compose';
import { extractErrorMessage } from 'Helpers/utils';
import { Permission } from 'Generated/schema';
import M365LogsSourceWizard, { M365LogsSourceWizardValues } from '../M365LogsSourceWizard';
import { useGetM365LogSource } from './graphql/getM365LogSource.generated';
import { useUpdateM365LogSource } from './graphql/updateM365LogSource.generated';

const EditM365LogSource: React.FC = () => {
  const { pushSnackbar } = useSnackbar();
  const { match } = useRouter<{ id: string }>();
  const { data, error: getError } = useGetM365LogSource({
    variables: { id: match.params.id },
    onError: error => {
      pushSnackbar({
        title: extractErrorMessage(error) || 'An unknown error occurred',
        variant: 'error',
      });
    },
  });

  const [updateM365LogSource] = useUpdateM365LogSource();

  const initialValues = React.useMemo(
    () => ({
      integrationId: match.params.id,
      logTypes: data?.getLogPullingIntegration?.logTypes ?? [],
      integrationLabel: data?.getLogPullingIntegration?.integrationLabel ?? 'Loading...',
      tenantId: data?.getLogPullingIntegration?.pullerConfig?.m365?.tenantId ?? 'Loading...',
      clientId: data?.getLogPullingIntegration?.pullerConfig?.m365?.clientId ?? 'Loading...',
      clientSecret:
        data?.getLogPullingIntegration?.pullerConfig?.m365?.clientSecret ?? 'Loading...',
    }),
    // FIXME: look into missing hook dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data]
  );

  const handleSubmit = React.useCallback(async (values: M365LogsSourceWizardValues) => {
    try {
      const resp = await updateM365LogSource({
        variables: {
          input: {
            integrationId: values.integrationId,
            integrationLabel: values.integrationLabel,
            pullerConfig: {
              logTypes: values.logTypes,
              m365: {
                tenantId: values.tenantId,
                clientId: values.clientId,
                clientSecret: values.clientSecret,
              },
            },
          },
        },
      });
      return resp.data.updateLogPullingIntegration;
    } catch (err) {
      return err as Error;
    }
    // FIXME: look into missing hook dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // we optimistically assume that an error in "get" is a 404. We don't have any other info
  if (getError) {
    return <Page404 />;
  }

  return <M365LogsSourceWizard initialValues={initialValues} onSubmit={handleSubmit} />;
};

export default compose(
  withSEO({ title: 'Edit M365 Log Source' }),
  withRoleRestrictedAccess({
    allowedPermissions: [Permission.LogSourceModify],
    fallback: <Page403 />,
  })
)(EditM365LogSource);
