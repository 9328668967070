/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import {
  AddPolicyInput,
  DetectionTestDefinition,
  UpdatePolicyInput,
  DetectionReportMapping,
} from 'Generated/schema';
import * as Yup from 'yup';
import {
  Button,
  Flex,
  Box,
  Card,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  IconButton,
  Alert,
} from 'pouncejs';
import { BorderedTab, BorderTabDivider } from 'Components/BorderedTab';
import { Form, Formik } from 'formik';
import useRouter from 'Hooks/useRouter';
import useUrlParams from 'Hooks/useUrlParams';
import invert from 'lodash/invert';
import Breadcrumbs from 'Components/Breadcrumbs';
import SaveButton from 'Components/buttons/SaveButton';
import { BaseDetectionFormEditorSection } from 'Components/forms/BaseDetectionForm';
import ErrorBoundary from 'Components/ErrorBoundary';
import FormSessionRestoration from 'Components/utils/FormSessionRestoration';
import PolicyFormRequiredSection from 'Components/forms/PolicyForm/PolicyFormRequiredSection';
import useDeleteDetectionModal from 'Hooks/useDeleteDetectionModal';
import { PolicyDetails } from 'Source/graphql/fragments/PolicyDetails.generated';
import PolicyFormTestSection from './PolicyFormTestSection';
import PolicyFormCoreSection from './PolicyFormCoreSection';
import BaseDetectionFormMappingsSection from '../BaseDetectionForm/BaseDetectionFormMappingsSection';

// The validation checks that Formik will run
const validationSchema = Yup.object().shape({
  id: Yup.string().required(),
  displayName: Yup.string().required(),
  body: Yup.string().required(),
  severity: Yup.string().required(),
  tests: Yup.array<DetectionTestDefinition>().of(
    Yup.object().shape({
      name: Yup.string().required(),
      expectedResult: Yup.boolean().required(),
      resource: Yup.string().required(),
    })
  ),
  reports: Yup.array<DetectionReportMapping>().of(
    Yup.object().shape({
      key: Yup.string().required(),
      values: Yup.array().of(Yup.string()).required().min(1, 'A report must have at least 1 value'),
    })
  ),
});

export interface PolicyFormUrlParams {
  section?: 'settings' | 'functions' | 'mappings';
}

const sectionToTabIndex: Record<PolicyFormUrlParams['section'], number> = {
  settings: 0,
  functions: 1,
  mappings: 2,
};

const tabIndexToSection = invert(sectionToTabIndex) as Record<
  number,
  PolicyFormUrlParams['section']
>;

export type PolicyFormValues = Required<AddPolicyInput> | Required<UpdatePolicyInput>;
export type PolicyFormProps = {
  /** The initial values of the form */
  initialValues: PolicyFormValues;

  /** callback for the submission of the form */
  onSubmit: (values: PolicyFormValues) => void;

  /** marks whether we're updating a previously saved Policy or not */
  isUpdate?: boolean;

  cacheSessionId?: string;
  policy?: PolicyDetails;
};

const PolicyForm: React.FC<PolicyFormProps> = ({
  initialValues,
  onSubmit,
  cacheSessionId = 'policy-form-create',
  isUpdate = false,
  policy = {
    createdBy: null,
    createdAt: null,
    lastModifiedBy: null,
    lastModified: null,
  } as PolicyDetails,
}) => {
  const { history } = useRouter();
  const showDeleteDetectionsModal = useDeleteDetectionModal();
  const { urlParams, updateUrlParams } = useUrlParams<PolicyFormUrlParams>();

  const isManaged = initialValues?.managed;
  return (
    <Formik<PolicyFormValues>
      initialValues={initialValues}
      onSubmit={onSubmit}
      enableReinitialize
      validationSchema={validationSchema}
      validateOnChange={false}
      validateOnBlur={false}
    >
      {({ isValid }) => (
        <FormSessionRestoration sessionId={cacheSessionId}>
          {({ clearFormSession }) => (
            <Form>
              <Breadcrumbs.Actions>
                <Flex spacing={4} justify="flex-end">
                  {isUpdate && (
                    <IconButton
                      variantColor="gray-600"
                      aria-label="delete-rule"
                      icon="trash"
                      variant="outline"
                      data-tid="delete-policy"
                      onClick={() => showDeleteDetectionsModal([policy])}
                    />
                  )}
                  <Button
                    variantColor="gray-600"
                    icon="close-outline"
                    variant="outline"
                    aria-label="Cancel Policy editing"
                    onClick={() => {
                      clearFormSession();
                      history.goBack();
                    }}
                  >
                    Cancel
                  </Button>
                  <SaveButton allowPristineSubmission={!isUpdate} allowInvalidSubmission>
                    {isUpdate ? 'Update' : 'Save'}
                  </SaveButton>
                </Flex>
              </Breadcrumbs.Actions>
              {!isValid && (
                <Box mb={2}>
                  <Alert
                    variant="error"
                    title="The required fields below must be filled before saving."
                  />
                </Box>
              )}
              <PolicyFormRequiredSection />
              <Card position="relative">
                <Tabs
                  index={sectionToTabIndex[urlParams.section] || 0}
                  onChange={index => updateUrlParams({ section: tabIndexToSection[index] })}
                >
                  <Box px={2}>
                    <TabList>
                      <BorderedTab>Policy Settings</BorderedTab>
                      <BorderedTab>Functions & Tests</BorderedTab>
                      <BorderedTab>Report Mapping</BorderedTab>
                    </TabList>
                  </Box>

                  <BorderTabDivider />
                  <Box p={6}>
                    <TabPanels>
                      <TabPanel data-testid="policy-settings-tabpanel" lazy>
                        <ErrorBoundary>
                          <PolicyFormCoreSection
                            createdBy={policy.createdBy}
                            createdAt={policy.createdAt}
                            lastModified={policy.lastModified}
                            lastModifiedBy={policy.lastModifiedBy}
                          />
                        </ErrorBoundary>
                      </TabPanel>
                      <TabPanel data-testid="policy-functions-tabpanel" lazy>
                        <Flex spacing="6" direction="column">
                          <ErrorBoundary>
                            <BaseDetectionFormEditorSection isManaged={isManaged} type="policy" />
                          </ErrorBoundary>
                          <ErrorBoundary>
                            <PolicyFormTestSection />
                          </ErrorBoundary>
                        </Flex>
                      </TabPanel>
                      <TabPanel data-testid="policy-mapping-tabpanel" lazy>
                        <ErrorBoundary>
                          <BaseDetectionFormMappingsSection />
                        </ErrorBoundary>
                      </TabPanel>
                    </TabPanels>
                  </Box>
                </Tabs>
              </Card>
            </Form>
          )}
        </FormSessionRestoration>
      )}
    </Formik>
  );
};

export default PolicyForm;
