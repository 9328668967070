/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React, { ReactElement } from 'react';
import { Text } from 'pouncejs';
import ReplayStageViewItem from 'Components/forms/ReplayForm/ReplayStatus/ReplayStageViewItem';
import {
  ReplayStageState,
  ReplayStageViewProps,
} from 'Components/forms/ReplayForm/ReplayStatus/ReplayStatus';

const ReplaySetupStageView: React.FC<ReplayStageViewProps> = ({ replay }) => {
  let state: ReplayStageState;
  let body: ReactElement;

  switch (!!replay) {
    case false:
      state = 'running';
      body = <Text>Creating replay...</Text>;
      break;
    default:
      state = 'complete';
      body = <Text>Replay created.</Text>;
  }

  return (
    <ReplayStageViewItem title="Replay Setup" state={state}>
      {body}
    </ReplayStageViewItem>
  );
};

export default ReplaySetupStageView;
