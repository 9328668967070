/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import * as Types from '../../../../__generated__/schema';

import { RoleDetails } from '../../../graphql/fragments/RoleDetails.generated';
import { GraphQLError } from 'graphql';
import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';

export type ListRolesVariables = {
  nameContains?: Types.Maybe<Types.Scalars['String']>;
  sortDir?: Types.Maybe<Types.SortDirEnum>;
};

export type ListRoles = { roles: Array<RoleDetails> };

export const ListRolesDocument = gql`
  query ListRoles($nameContains: String, $sortDir: SortDirEnum) {
    roles(input: { nameContains: $nameContains, sortDir: $sortDir }) {
      ...RoleDetails
    }
  }
  ${RoleDetails}
`;

/**
 * __useListRoles__
 *
 * To run a query within a React component, call `useListRoles` and pass it any options that fit your needs.
 * When your component renders, `useListRoles` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListRoles({
 *   variables: {
 *      nameContains: // value for 'nameContains'
 *      sortDir: // value for 'sortDir'
 *   },
 * });
 */
export function useListRoles(
  baseOptions?: ApolloReactHooks.QueryHookOptions<ListRoles, ListRolesVariables>
) {
  return ApolloReactHooks.useQuery<ListRoles, ListRolesVariables>(ListRolesDocument, baseOptions);
}
export function useListRolesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ListRoles, ListRolesVariables>
) {
  return ApolloReactHooks.useLazyQuery<ListRoles, ListRolesVariables>(
    ListRolesDocument,
    baseOptions
  );
}
export type ListRolesHookResult = ReturnType<typeof useListRoles>;
export type ListRolesLazyQueryHookResult = ReturnType<typeof useListRolesLazyQuery>;
export type ListRolesQueryResult = ApolloReactCommon.QueryResult<ListRoles, ListRolesVariables>;
export function mockListRoles({
  data,
  variables,
  errors,
}: {
  data: ListRoles;
  variables?: ListRolesVariables;
  errors?: GraphQLError[];
}) {
  return {
    request: { query: ListRolesDocument, variables },
    result: { data, errors },
  };
}
