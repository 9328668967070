/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import {
  AbstractButton,
  Box,
  Button,
  Card,
  Flex,
  Grid,
  Heading,
  IconButton,
  Radio,
  TextInput,
} from 'pouncejs';
import { DetectionTypeEnum } from 'Generated/schema';
import Editor from 'Components/Editor';
import ErrorBoundary from 'Components/ErrorBoundary';
import useBulkDownload from 'Hooks/useBulkDownload';
import { formatJSON } from 'Helpers/utils';
import { RuleDetails } from 'Source/graphql/fragments/RuleDetails.generated';
import { PolicyDetails } from 'Source/graphql/fragments/PolicyDetails.generated';

interface DetectionDetailsFunctionProps {
  detection: RuleDetails | PolicyDetails;
}

const prettyFormatTest = (value: string) => {
  try {
    return formatJSON(JSON.parse(value));
  } catch (error) {
    return '{}';
  }
};

const DetectionDetailsFunctionsTab: React.FC<DetectionDetailsFunctionProps> = ({ detection }) => {
  const [showPython, setShowPython] = React.useState(true);
  const [showTests, setShowTests] = React.useState(true);
  const isPolicy = detection.analysisType === DetectionTypeEnum.Policy;
  const { loading, download } = useBulkDownload({ fileName: detection.id });

  const [activeTabIndex, setActiveTabIndex] = React.useState(0);

  const { body, tests } = detection;
  const testsExist = tests.length > 0;

  return (
    <ErrorBoundary>
      <Flex p={4} spacing="6" direction="column">
        <Card variant="dark" p={4}>
          <Flex justify="space-between" spacing={4}>
            <Flex align="center" spacing={4}>
              <IconButton
                variant="ghost"
                variantBorderStyle="circle"
                active={showPython}
                variantColor="navyblue-700"
                icon={showPython ? 'caret-up' : 'caret-down'}
                onClick={() => setShowPython(prev => !prev)}
                size="medium"
                aria-label="Toggle Editor visibility"
              />
              <Heading size="x-small">{isPolicy ? 'Policy Function' : 'Rule Function'} </Heading>
            </Flex>
            <Button disabled={loading} onClick={() => download({ detectionIds: [detection.id] })}>
              Download {isPolicy ? 'Policy' : 'Rule'}
            </Button>
          </Flex>
          {showPython && (
            <Box mt={5}>
              <Editor
                data-testid="detection-details-function-editor"
                language="python"
                value={body}
                readOnly
              />
            </Box>
          )}
        </Card>
        {testsExist && (
          <Card variant="dark" p={4}>
            <Flex spacing={4}>
              <Box mt={2}>
                <IconButton
                  variant="ghost"
                  variantColor="navyblue-700"
                  variantBorderStyle="circle"
                  active={showTests}
                  icon={showTests ? 'caret-up' : 'caret-down'}
                  onClick={() => setShowTests(prev => !prev)}
                  size="medium"
                  aria-label="Toggle Tests visibility"
                />
              </Box>
              <Flex
                as="ul"
                wrap="wrap"
                spacing={4}
                flexGrow={1}
                flexBasis={0}
                mt={1}
                mb={showTests ? 0 : -4}
              >
                {tests.map((test, index) => (
                  <Box as="li" mb={4} key={index}>
                    <AbstractButton
                      borderRadius="pill"
                      px={4}
                      py={2}
                      backgroundColor={activeTabIndex === index ? 'blue-400' : 'navyblue-300'}
                      onClick={() => setActiveTabIndex(index)}
                    >
                      <Flex align="center">{test.name}</Flex>
                    </AbstractButton>
                  </Box>
                ))}
              </Flex>
            </Flex>
            {showTests && (
              <React.Fragment>
                <Grid columnGap={5} templateColumns="1fr 2fr" mt={2} mb={6}>
                  <TextInput
                    disabled={true}
                    name={`tests[${activeTabIndex}].name`}
                    label="Name"
                    value={tests[activeTabIndex].name}
                  />
                  <Flex align="center" spacing={5}>
                    <Box fontSize="medium" fontWeight="medium" flexGrow={1} textAlign="right">
                      {isPolicy
                        ? 'Should this test resource be compliant?'
                        : 'Should this test event trigger an alert?'}
                    </Box>
                    <Radio disabled label="Yes" checked={tests[activeTabIndex].expectedResult} />
                    <Radio disabled label="No" checked={!tests[activeTabIndex].expectedResult} />
                  </Flex>
                </Grid>

                <Box mb={5}>
                  <Editor
                    data-testid="detection-details-tests-editor"
                    readOnly
                    value={prettyFormatTest(tests[activeTabIndex].resource)}
                    language="json"
                    minHeight={340}
                  />
                </Box>
              </React.Fragment>
            )}
          </Card>
        )}
      </Flex>
    </ErrorBoundary>
  );
};

export default React.memo(DetectionDetailsFunctionsTab);
