/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import * as Types from '../../../__generated__/schema';

import { AlertDetailsFull } from './AlertDetailsFull.generated';
import { GraphQLError } from 'graphql';
import gql from 'graphql-tag';

export type AlertSummaryFull = {
  origin:
    | Pick<Types.AlertOriginRule, 'ruleId' | 'logTypes' | 'scheduledQueries' | 'eventsMatched'>
    | Pick<Types.AlertOriginPolicy, 'policyId' | 'resourceTypes' | 'resourceId' | 'policySourceId'>
    | Pick<Types.AlertOriginSystemError, 'relatedComponent'>
    | Pick<
        Types.AlertOriginReplayedRule,
        'ruleId' | 'logTypes' | 'replayId' | 'dedupString' | 'eventsMatched' | 'scheduledQueries'
      >;
} & AlertDetailsFull;

export const AlertSummaryFull = gql`
  fragment AlertSummaryFull on Alert {
    ...AlertDetailsFull
    origin {
      ... on AlertOriginPolicy {
        policyId
        resourceTypes
        resourceId
        policySourceId
      }
      ... on AlertOriginRule {
        ruleId
        logTypes
        scheduledQueries
        eventsMatched
      }
      ... on AlertOriginSystemError {
        relatedComponent
      }
      ... on AlertOriginReplayedRule {
        ruleId
        logTypes
        replayId
        dedupString
        eventsMatched
        scheduledQueries
      }
    }
  }
  ${AlertDetailsFull}
`;
