/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Flex, Card, Box, Link } from 'pouncejs';
import { IndicatorUrlParams } from 'Helpers/utils';
import useAsyncQueryResults from 'Hooks/useAsyncQueryResults';
import useAsyncQueryContext from 'Hooks/useAsyncQueryContext';
import useIndicatorSearchFlattenedResults from 'Hooks/useIndicatorSearchFlattenedResults';
import useAsyncQueryTimer from 'Hooks/useAsyncQueryTimer';
import useUrlParams from 'Hooks/useUrlParams';
import AsyncQueryStatus from 'Components/AsyncQueryStatus';
import ElapsedTime from 'Components/ElapsedTime';
import IndicatorSearchResultCard from 'Components/IndicatorSearchResultCard';
import { ExecuteIndicatorSearchQueryInput } from 'Generated/schema';
import urls from 'Source/urls';
import useIndicatorSearchDbPartitionedResults from 'Hooks/useIndicatorSearchDbPartitionedResults';
import DataScanned from 'Components/DataScanned';

const PAGE_SIZE = 999;

interface ResultsProps {
  indicatorQueryMeta: ExecuteIndicatorSearchQueryInput;
}

const Results: React.FC<ResultsProps> = ({ indicatorQueryMeta }) => {
  const { timeElapsed: timer } = useAsyncQueryTimer();
  const { results, stats } = useAsyncQueryResults({ pageSize: PAGE_SIZE }); // fetch all at once
  const { state: { queryId } } = useAsyncQueryContext(); // prettier-ignore

  const flattenedResults = useIndicatorSearchFlattenedResults(results ?? []);
  const [logMatches, ruleMatches] = useIndicatorSearchDbPartitionedResults(flattenedResults);
  const groupedResults = React.useMemo(() => [...ruleMatches, ...logMatches], [
    ruleMatches,
    logMatches,
  ]);

  const { stringifyUrlParams } = useUrlParams<IndicatorUrlParams>({
    parseNumbers: false,
    parseBooleans: false,
  });

  const getDataExplorerUrl = React.useCallback(
    ({ logType, dbName, tableName }) => {
      const { indicators, indicatorName, startTime, endTime } = indicatorQueryMeta ?? {};
      const params = stringifyUrlParams({
        snippedId: 'indicatorDetail',
        logType,
        i: indicators,
        indicatorName,
        startTime,
        endTime,
        // The BE supports passing `logType` as `tableName` to stay backwards-compatible with
        //  indicator search results in query history that don't return the `tableName`.
        // Once everything in query history returns the table name (30 days after this
        // deploys), we can safely remove the fallback to `dbName`.
        // FIXME: remove after Feb 2021 when all the old data has aged out
        tableName: tableName || logType,
        databaseName: dbName,
      });
      return `${urls.data.dataExplorer()}?${params}`;
    },
    [stringifyUrlParams, indicatorQueryMeta]
  );

  const { state: { queryStatus, globalErrorMessage } } = useAsyncQueryContext(); // prettier-ignore

  const isPristine = queryStatus === null;
  const hasErrored = queryStatus === 'errored';
  const isProvisioning = queryStatus === 'provisioning';
  const isRunning = queryStatus === 'running';
  const bytesScanned = stats?.bytesScanned ?? null;
  const timeElapsed = stats?.executionTime ?? timer;

  if (isPristine) {
    return <AsyncQueryStatus.Pristine />;
  }

  if (hasErrored) {
    return <AsyncQueryStatus.Errored errorMessage={globalErrorMessage} />;
  }

  if (isProvisioning) {
    return <AsyncQueryStatus.Provisioning />;
  }

  if (isRunning) {
    return <AsyncQueryStatus.Running timeElapsed={timeElapsed} />;
  }

  if (!results?.length) {
    return <AsyncQueryStatus.Empty timeElapsed={timeElapsed} bytesScanned={bytesScanned} />;
  }

  return (
    <React.Fragment>
      <Card as="article" pt={4}>
        <Flex as="section" direction="column" spacing={4} align="center" mb={8}>
          <Flex spacing="2">
            <Card variant="dark">
              <ElapsedTime ms={timeElapsed} />
            </Card>
            {bytesScanned >= 0 && (
              <Card variant="dark">
                <DataScanned bytes={bytesScanned} />
              </Card>
            )}
          </Flex>
          <Box px={4} as="ul" width="100%">
            {groupedResults.map((result, i) => (
              <Box as="li" py={2} key={i}>
                <a target="_blank" rel="noopener noreferrer" href={getDataExplorerUrl(result)}>
                  <IndicatorSearchResultCard data={result} variant="dark" />
                </a>
              </Box>
            ))}
          </Box>
          <Link
            textDecoration="underline"
            fontSize="medium"
            data-tid="link-to-indicator-search"
            href={`${urls.data.indicatorSearch()}?queryId=${queryId}`}
          >
            View in Indicator Search
          </Link>
        </Flex>
      </Card>
    </React.Fragment>
  );
};

export default Results;
