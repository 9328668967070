/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Dropdown, DropdownButton, DropdownItem, DropdownMenu } from 'pouncejs';
import usePolicySuppression from 'Hooks/usePolicySuppression';
import { Permission } from 'Generated/schema';
import RoleRestrictedAccess from 'Components/utils/RoleRestrictedAccess';
import OptionsButton from 'Components/buttons/OptionsButton';
import { ResourceDetailsTableItem } from './ResourceDetailsTable';

interface ResourceDetailsTableRowOptionsProps {
  complianceItem: ResourceDetailsTableItem;
}

const ResourceDetailsTableRowOptions: React.FC<ResourceDetailsTableRowOptionsProps> = ({
  complianceItem,
}) => {
  const { suppressPolicies } = usePolicySuppression({
    policyId: complianceItem.policyId,
    resourceId: complianceItem.resourceId,
  });

  return (
    <Dropdown>
      <DropdownButton as={OptionsButton} />
      <DropdownMenu>
        <RoleRestrictedAccess allowedPermissions={[Permission.PolicyModify]}>
          <DropdownItem disabled={complianceItem.suppressed} onSelect={suppressPolicies}>
            Ignore
          </DropdownItem>
        </RoleRestrictedAccess>
      </DropdownMenu>
    </Dropdown>
  );
};

export default ResourceDetailsTableRowOptions;
