/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Dropdown, DropdownButton, DropdownLink, DropdownMenu } from 'pouncejs';
import { DataLakeQueryStatus } from 'Generated/schema';
import { DataLakeQueryDetails } from 'Source/graphql/fragments/DataLakeQueryDetails.generated';
import urls from 'Source/urls';
import { Link as RRLink } from 'react-router-dom';
import OptionsButton from 'Components/buttons/OptionsButton';
import { QueryKindEnum } from '../QueryHistoryCard';

interface QueryHistoryCardOptionsProps {
  query: DataLakeQueryDetails;
}

const QueryHistoryCardOptions: React.FC<QueryHistoryCardOptionsProps> = ({ query }) => {
  const isIndicatorQuery = queryKind => {
    return (
      queryKind === QueryKindEnum.Indicator ||
      queryKind === QueryKindEnum.IndicatorTimeline ||
      queryKind === QueryKindEnum.IndicatorDetail
    );
  };

  if (query.kind === QueryKindEnum.Compaction || query.status === DataLakeQueryStatus.Cancelled) {
    return null;
  }

  return (
    <Dropdown>
      <DropdownButton as={OptionsButton} data-testid="query-history-card-options" />
      <DropdownMenu minWidth="200px">
        <DropdownLink as={RRLink} to={`${urls.data.dataExplorer()}?queryId=${query.id}`}>
          Open in Data Explorer
        </DropdownLink>
        {isIndicatorQuery(query.kind) && (
          <DropdownLink as={RRLink} to={`${urls.data.indicatorSearch()}?queryId=${query.id}`}>
            Open in Indicator Search
          </DropdownLink>
        )}
      </DropdownMenu>
    </Dropdown>
  );
};

export default React.memo(QueryHistoryCardOptions);
