/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import {
  Card,
  Box,
  Heading,
  Flex,
  Dropdown,
  DropdownButton,
  DropdownMenu,
  DropdownLink,
  DropdownItem,
  Link,
  Text,
} from 'pouncejs';
import { Link as RRLink } from 'react-router-dom';
import { getElapsedTime } from 'Helpers/utils';
import useModal from 'Hooks/useModal';
import { GlobalHelperTeaser } from 'Source/graphql/fragments/GlobalHelperTeaser.generated';
import DeleteGlobalHelpersModal from 'Components/modals/DeleteGlobalHelpersModal';
import urls from 'Source/urls';
import RoleRestrictedAccess from 'Components/utils/RoleRestrictedAccess';
import { Permission } from 'Generated/schema';
import DisabledLink from 'Components/DisabledLink';
import useBulkDownload from 'Hooks/useBulkDownload';
import OptionsButton from 'Components/buttons/OptionsButton';

interface GlobalItemProps {
  globalHelper: GlobalHelperTeaser;
}

const GlobalHelperItem: React.FC<GlobalItemProps> = ({ globalHelper }) => {
  const { showModal } = useModal();
  const { download } = useBulkDownload({
    fileName: globalHelper.id,
  });

  const lastModifiedTime = Math.floor(new Date(globalHelper.lastModified).getTime() / 1000);
  return (
    <Card variant="dark" p={4} key={globalHelper.id}>
      <Flex align="center" justify="space-between">
        <Box>
          <Heading as="h4" size="x-small">
            <RoleRestrictedAccess
              allowedPermissions={[Permission.RuleModify, Permission.PolicyModify]}
              fallback={<DisabledLink>{globalHelper.id}</DisabledLink>}
            >
              <Link
                as={RRLink}
                to={urls.data.globalHelpers.edit(globalHelper.id)}
                data-tid="link-to-global-helper"
              >
                {globalHelper.id}
              </Link>
            </RoleRestrictedAccess>
          </Heading>
        </Box>
        <Box mr={0} ml="auto" fontSize="small">
          <Text mr={1} color="navyblue-100" as="span">
            Last updated
          </Text>
          <Text as="time">{getElapsedTime(lastModifiedTime)}</Text>
        </Box>

        <RoleRestrictedAccess allowedPermissions={[Permission.RuleModify, Permission.PolicyModify]}>
          <Dropdown>
            <DropdownButton data-tid="global-helper-card-options-dropdown" as={OptionsButton} />
            <DropdownMenu minWidth="100px">
              <DropdownLink
                data-tid="link-to-global-helper"
                as={RRLink}
                to={urls.data.globalHelpers.edit(globalHelper.id)}
              >
                Edit
              </DropdownLink>
              <DropdownItem
                data-tid="download-global-helper"
                onSelect={() => download({ globalHelperIds: [globalHelper.id] })}
              >
                Download
              </DropdownItem>
              <DropdownItem
                data-tid="delete-global-helper"
                onSelect={() => {
                  showModal(<DeleteGlobalHelpersModal globalHelper={globalHelper} />, {
                    title: `Delete ${globalHelper.id}`,
                  });
                }}
              >
                Delete
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </RoleRestrictedAccess>
      </Flex>
    </Card>
  );
};

export default React.memo(GlobalHelperItem);
