/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import urls from 'Source/urls';
import { Box, Flex, Heading, Text } from 'pouncejs';
import EmptyNotepadImg from 'Assets/illustrations/empty-notepad.svg';
import RoleRestrictedAccess from 'Components/utils/RoleRestrictedAccess';
import { Permission } from 'Generated/schema';
import LinkButton from 'Components/buttons/LinkButton';

const ListDetectionsPageEmptyDataFallback: React.FC = () => {
  return (
    <Flex justify="center" align="center" direction="column">
      <Box my={10}>
        <img alt="Empty Notepad illustration" src={EmptyNotepadImg} width="auto" height={300} />
      </Box>
      <Heading mb={6}>No detections found</Heading>
      <Text color="gray-300" textAlign="center" mb={8}>
        Writing detections will allow you to get alerts about suspicious activity or misconfigured
        resources in your system
      </Text>
      <RoleRestrictedAccess allowedPermissions={[Permission.RuleModify]}>
        <LinkButton to={urls.detections.create()}> Create New Rule</LinkButton>
      </RoleRestrictedAccess>
    </Flex>
  );
};

export default ListDetectionsPageEmptyDataFallback;
