/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import * as Types from '../../../__generated__/schema';

import { GraphQLError } from 'graphql';
import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';

export type InferSchemaVariables = {
  input: Types.InferSchemaInput;
};

export type InferSchema = {
  inferSchema?: Types.Maybe<
    Pick<Types.InferSchemaResponse, 'schema'> & {
      error?: Types.Maybe<Pick<Types.Error, 'message'>>;
    }
  >;
};

export const InferSchemaDocument = gql`
  mutation InferSchema($input: InferSchemaInput!) {
    inferSchema(input: $input) {
      schema
      error {
        message
      }
    }
  }
`;
export type InferSchemaMutationFn = ApolloReactCommon.MutationFunction<
  InferSchema,
  InferSchemaVariables
>;

/**
 * __useInferSchema__
 *
 * To run a mutation, you first call `useInferSchema` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInferSchema` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inferSchema, { data, loading, error }] = useInferSchema({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useInferSchema(
  baseOptions?: ApolloReactHooks.MutationHookOptions<InferSchema, InferSchemaVariables>
) {
  return ApolloReactHooks.useMutation<InferSchema, InferSchemaVariables>(
    InferSchemaDocument,
    baseOptions
  );
}
export type InferSchemaHookResult = ReturnType<typeof useInferSchema>;
export type InferSchemaMutationResult = ApolloReactCommon.MutationResult<InferSchema>;
export type InferSchemaMutationOptions = ApolloReactCommon.BaseMutationOptions<
  InferSchema,
  InferSchemaVariables
>;
export function mockInferSchema({
  data,
  variables,
  errors,
}: {
  data: InferSchema;
  variables?: InferSchemaVariables;
  errors?: GraphQLError[];
}) {
  return {
    request: { query: InferSchemaDocument, variables },
    result: { data, errors },
  };
}
