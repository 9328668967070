/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Link as RRLink } from 'react-router-dom';
import { Box, Card, Divider, Flex, Link, SimpleGrid } from 'pouncejs';

import SeverityBadge from 'Components/badges/SeverityBadge';
import StatusBadge from 'Components/badges/StatusBadge';
import BulletedValueList from 'Components/BulletedValueList';
import { ENTITIES_COLOR_MAP } from 'Source/constants';
import urls from 'Source/urls';
import RoleRestrictedAccess from 'Components/utils/RoleRestrictedAccess';
import { PolicySummary } from 'Source/graphql/fragments/PolicySummary.generated';
import { ComplianceStatusEnum, DetectionTypeEnum, Permission } from 'Generated/schema';
import { formatDatetime } from 'Helpers/utils';
import { SelectCheckbox } from 'Components/utils/SelectContext';
import useDetectionDestinations from 'Hooks/useDetectionDestinations';
import RelatedDestinations from 'Components/RelatedDestinations';
import FlatBadge from 'Components/badges/FlatBadge';
import { CardValue, CardMetaValue } from 'Components/cards/common';
import PolicyCardOptions from './PolicyCardOptions';
import { CardVariant, CardVariantConfig } from '../common/cardVariant';

interface PolicyCardProps {
  policy: PolicySummary;
  onDelete?: (id: string) => void;
  selectionEnabled?: boolean;
  isSelected?: boolean;
  variant?: CardVariant;
}

const PolicyCard: React.FC<PolicyCardProps> = ({
  policy,
  onDelete = () => {},
  selectionEnabled = false,
  isSelected = false,
  variant = 'dark',
}) => {
  const {
    detectionDestinations,
    loading: loadingDetectionDestinations,
  } = useDetectionDestinations({ detection: policy });

  const variantConfig = CardVariantConfig[variant];

  return (
    <Card
      as="section"
      variant={isSelected ? variantConfig.selectedCardVariant : variantConfig.defaultCardVariant}
      p={4}
    >
      <Flex>
        {selectionEnabled && (
          <Box transform="translate3d(-12px,-12px,0)">
            <SelectCheckbox data-tid="single-select-detection" selectionItem={policy} />
          </Box>
        )}
        <Flex direction="column" justify="space-between" width={1}>
          <Flex as="header" align="flex-start" mb={2}>
            <Box as="h4" fontWeight="medium" mr="auto" maxWidth="80%" wordBreak="break-word">
              <Link
                as={RRLink}
                aria-label="Link to Policy"
                to={urls.analysis.policies.details(policy.id)}
                data-tid="link-to-policy"
              >
                {policy.displayName || policy.id}
              </Link>
            </Box>
            <CardMetaValue label="Updated" value={formatDatetime(policy.lastModified)} />
            <RoleRestrictedAccess allowedPermissions={[Permission.PolicyModify]}>
              <Box mt={-1} ml={2}>
                <PolicyCardOptions policy={policy} onDelete={onDelete} />
              </Box>
            </RoleRestrictedAccess>
          </Flex>
          <Box mr="auto">
            <FlatBadge color={ENTITIES_COLOR_MAP[DetectionTypeEnum.Policy]}>POLICY</FlatBadge>
          </Box>
          <SimpleGrid gap={2} columns={2}>
            <CardValue
              label="Resource Types"
              value={<BulletedValueList values={policy.resourceTypes} limit={3} />}
            />
            <Flex align="flex-end">
              <Flex spacing={2} align="center" width="100%" justify="flex-end">
                <RelatedDestinations
                  destinations={detectionDestinations}
                  loading={loadingDetectionDestinations}
                  limit={3}
                />
                <Divider mx={0} alignSelf="stretch" orientation="vertical"></Divider>
                <StatusBadge status={policy.complianceStatus} />
                <StatusBadge
                  status={policy.enabled ? 'ENABLED' : ComplianceStatusEnum.Error}
                  disabled={!policy.enabled}
                />
                <SeverityBadge severity={policy.severity} />
              </Flex>
            </Flex>
          </SimpleGrid>
        </Flex>
      </Flex>
    </Card>
  );
};

export default React.memo(PolicyCard);
