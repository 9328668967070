/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import {
  Dropdown,
  DropdownButton,
  DropdownItem,
  DropdownMenu,
  DropdownLink,
  useSnackbar,
  Text,
} from 'pouncejs';
import { Link as RRLink } from 'react-router-dom';
import useModal from 'Hooks/useModal';
import DeleteDestinationModal from 'Components/modals/DeleteDestinationModal';
import RoleRestrictedAccess from 'Components/utils/RoleRestrictedAccess';
import OptionsButton from 'Components/buttons/OptionsButton';
import { Permission } from 'Generated/schema';
import useCopyToClipboard from 'Hooks/useCopyToClipboard';
import { DestinationDetails } from 'Source/graphql/fragments/DestinationDetails.generated';
import urls from 'Source/urls';
import { useSendTestAlertLazyQuery } from 'Source/graphql/queries';
import { extractErrorMessage } from 'Helpers/utils';

interface DestinationCardOptionsProps {
  destination: DestinationDetails;
}

const DestinationCardOptions: React.FC<DestinationCardOptionsProps> = ({ destination }) => {
  const { showModal } = useModal();
  const { pushSnackbar } = useSnackbar();
  const copyToClipboard = useCopyToClipboard();

  const [sendTestAlert] = useSendTestAlertLazyQuery({
    fetchPolicy: 'network-only', // Don't use cache
    variables: {
      input: {
        outputIds: [destination.outputId],
      },
    },
    // Failed deliveries will also trigger onCompleted as we don't return exceptions
    onCompleted: data => {
      data.sendTestAlert.forEach(sendTestAlertResp => {
        const { success, statusCode, message } = sendTestAlertResp;
        if (success === true) {
          pushSnackbar({
            variant: 'success',
            title: `Successfully sent test alert for: ${destination.displayName}`,
          });
        } else {
          pushSnackbar({
            variant: 'error',
            title: `Failed to send a test alert with status ${statusCode}: ${destination.displayName}`,
            description: [
              <Text key={destination.outputId} maxWidth={540} wordBreak="break-word">
                {message}
              </Text>,
            ],
          });
        }
      });
    },
    // This will be fired if there was a network issue or other unknown internal exception
    onError: error => {
      pushSnackbar({
        variant: 'error',
        title: extractErrorMessage(error) || `Failed to attempt sending a test alert.`,
      });
    },
  });

  const handleTestAlertClick = React.useCallback(() => {
    sendTestAlert();
    // FIXME: Look into missing effect dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const destinationDisplayName = destination.displayName || destination.outputId;
  return (
    <Dropdown>
      <DropdownButton as={OptionsButton} />
      <DropdownMenu minWidth="175px">
        <DropdownItem onSelect={() => copyToClipboard(destination.outputId)}>
          Copy Destination ID
        </DropdownItem>
        <RoleRestrictedAccess allowedPermissions={[Permission.DestinationModify]}>
          <DropdownItem onSelect={handleTestAlertClick}>Send Test Alert</DropdownItem>
          <DropdownLink as={RRLink} to={urls.integrations.destinations.edit(destination.outputId)}>
            Edit
          </DropdownLink>
          <DropdownItem
            onSelect={() =>
              showModal(<DeleteDestinationModal destination={destination} />, {
                title: `Delete ${destinationDisplayName}`,
              })
            }
          >
            Delete
          </DropdownItem>
        </RoleRestrictedAccess>
      </DropdownMenu>
    </Dropdown>
  );
};

export default React.memo(DestinationCardOptions);
