/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { GsuiteLogIntegrationDetails } from 'Source/graphql/fragments/GsuiteLogIntegrationDetails.generated';
import { Wizard } from 'Components/Wizard';
import { GoogleAppsEnum, LogTransportMethodsEnum } from 'Source/constants';
import { yupIntegrationLabelUniqueness } from 'Helpers/utils';
import useFindSourcesPerMethod from 'Hooks/useFindSourcesPerMethod';
import CancelIntegrationAction from 'Pages/Integrations/components/CancelIntegrationAction';
import GsuiteLogsSourceConfigurationPanel from './GsuiteLogsSourceConfigurationPanel';
import CredentialsPanel from './CredentialsPanel';
import ValidationPanel from './ValidationPanel';

interface GsuiteLogsWizardProps {
  initialValues: GsuiteLogsSourceWizardValues;
  onSubmit: (values: GsuiteLogsSourceWizardValues) => Promise<GsuiteLogIntegrationDetails | Error>;
}

export interface GsuiteLogsSourceWizardValues {
  integrationId?: string; // for updates
  integrationLabel: string;
  clientId: string;
  clientSecret: string;
  applications: string[];
}

const GsuiteLogsSourceWizard: React.FC<GsuiteLogsWizardProps> = ({ initialValues, onSubmit }) => {
  const existingPullerSources = useFindSourcesPerMethod(LogTransportMethodsEnum.logPulling);

  const validationSchema: Yup.SchemaOf<GsuiteLogsSourceWizardValues> = React.useMemo(
    () =>
      Yup.object().shape({
        integrationId: Yup.string(),
        integrationLabel: yupIntegrationLabelUniqueness({
          existingLabel: initialValues.integrationLabel,
          existingSources: existingPullerSources,
        }),
        clientId: Yup.string().required(),
        clientSecret: initialValues.integrationId ? Yup.string() : Yup.string().required(),
        applications: Yup.array()
          .of(Yup.string().oneOf(Object.keys(GoogleAppsEnum)))
          .required()
          .min(1),
      }),
    [initialValues.integrationId, initialValues.integrationLabel, existingPullerSources]
  );

  const editMode = !!initialValues.integrationId;

  return (
    <Formik<GsuiteLogsSourceWizardValues>
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      <Form>
        <Wizard headerTitle="Connecting a new Log Source" enableAllNavigationSteps={editMode}>
          <Wizard.Step
            title="Configure Source"
            description="Provide account information and set preferences to connect the log source to Panther"
          >
            <GsuiteLogsSourceConfigurationPanel />
          </Wizard.Step>
          <Wizard.Step
            title="Set Credentials"
            description="Provide account credentials to give Panther permissions to collect logs from the source"
          >
            <CredentialsPanel />
          </Wizard.Step>
          <Wizard.Step
            title="Verify Setup"
            description="Confirm that your log source has been successfully onboarded or troubleshoot any issues"
            navigationDisabled={editMode}
          >
            <ValidationPanel />
          </Wizard.Step>
        </Wizard>
        {!editMode && <CancelIntegrationAction />}
      </Form>
    </Formik>
  );
};

export default GsuiteLogsSourceWizard;
