/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Box, Card, useSnackbar } from 'pouncejs';
import ErrorBoundary from 'Components/ErrorBoundary';
import RoleForm, { RoleFormValues } from 'Components/forms/RoleForm';
import { useCreateRole } from 'Pages/CreateRole/graphql/createRole.generated';
import { extractErrorMessage } from 'Helpers/utils';
import urls from 'Source/urls';
import withRoleRestrictedAccess from 'Hoc/withRoleRestrictedAccess';
import { LogTypeAccessKind, Permission } from 'Generated/schema';
import Page403 from 'Pages/403';
import useRouter from 'Hooks/useRouter';
import { compose } from 'Helpers/compose';
import withSEO from 'Hoc/withSEO';
import { ListRolesDocument } from './graphql/listRoles.generated';

const initialValues: RoleFormValues = {
  name: '',
  permissions: [],
  logTypeAccessKind: LogTypeAccessKind.DenyAll,
  logTypeAccess: [],
};

const CreateRole: React.FC = () => {
  const { pushSnackbar } = useSnackbar();
  const { history } = useRouter();
  const [createRole] = useCreateRole({
    onError: error => {
      pushSnackbar({
        title: extractErrorMessage(error) || 'An unknown error occured',
        variant: 'error',
      });
    },
    onCompleted: ({
      createRole: {
        role: { name },
      },
    }) => {
      pushSnackbar({ title: `Successfully created ${name}`, variant: 'success' });
    },
  });

  const handleSubmit = React.useCallback(async (values: RoleFormValues) => {
    await createRole({
      variables: {
        input: {
          name: values.name,
          permissions: values.permissions,
          logTypeAccessKind: values.logTypeAccessKind,
          logTypeAccess:
            values.logTypeAccessKind === LogTypeAccessKind.DenyAll ||
            values.logTypeAccessKind === LogTypeAccessKind.AllowAll
              ? null
              : values.logTypeAccess,
        },
      },
      update: cache => {
        // Invalidate all cached values for `listRoles` because every time a user filters roles by some criteria
        // we generate a new listRoles query with different, therefore we need to remove all those queries
        // when a new role is added
        cache.evict({ fieldName: 'listRoles' });
        cache.gc();
      },
      refetchQueries: [{ query: ListRolesDocument, variables: { input: {} } }],
      awaitRefetchQueries: true,
    });

    history.push(urls.settings.roles.list());
    // FIXME: look into missing hook dependencies or using a ref
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box mb={6}>
      <ErrorBoundary>
        <Card p={6}>
          <RoleForm initialValues={initialValues} onSubmit={handleSubmit} />
        </Card>
      </ErrorBoundary>
    </Box>
  );
};

export default compose(
  withSEO({ title: 'Create Role' }),
  withRoleRestrictedAccess({
    allowedPermissions: [Permission.UserModify],
    fallback: <Page403 />,
  })
)(CreateRole);
