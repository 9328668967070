/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import * as Types from '../../../../../../../__generated__/schema';

import { GraphQLError } from 'graphql';
import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';

export type ListAvailableLogProvidersVariables = {};

export type ListAvailableLogProviders = {
  listAvailableLogProviders: {
    results?: Types.Maybe<
      Array<Pick<Types.AvailableLogProvider, 'id' | 'displayName' | 'popularity' | 'categories'>>
    >;
    error?: Types.Maybe<Pick<Types.Error, 'message'>>;
  };
};

export const ListAvailableLogProvidersDocument = gql`
  query ListAvailableLogProviders {
    listAvailableLogProviders {
      results {
        id
        displayName
        popularity
        categories
      }
      error {
        message
      }
    }
  }
`;

/**
 * __useListAvailableLogProviders__
 *
 * To run a query within a React component, call `useListAvailableLogProviders` and pass it any options that fit your needs.
 * When your component renders, `useListAvailableLogProviders` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListAvailableLogProviders({
 *   variables: {
 *   },
 * });
 */
export function useListAvailableLogProviders(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    ListAvailableLogProviders,
    ListAvailableLogProvidersVariables
  >
) {
  return ApolloReactHooks.useQuery<ListAvailableLogProviders, ListAvailableLogProvidersVariables>(
    ListAvailableLogProvidersDocument,
    baseOptions
  );
}
export function useListAvailableLogProvidersLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    ListAvailableLogProviders,
    ListAvailableLogProvidersVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    ListAvailableLogProviders,
    ListAvailableLogProvidersVariables
  >(ListAvailableLogProvidersDocument, baseOptions);
}
export type ListAvailableLogProvidersHookResult = ReturnType<typeof useListAvailableLogProviders>;
export type ListAvailableLogProvidersLazyQueryHookResult = ReturnType<
  typeof useListAvailableLogProvidersLazyQuery
>;
export type ListAvailableLogProvidersQueryResult = ApolloReactCommon.QueryResult<
  ListAvailableLogProviders,
  ListAvailableLogProvidersVariables
>;
export function mockListAvailableLogProviders({
  data,
  variables,
  errors,
}: {
  data: ListAvailableLogProviders;
  variables?: ListAvailableLogProvidersVariables;
  errors?: GraphQLError[];
}) {
  return {
    request: { query: ListAvailableLogProvidersDocument, variables },
    result: { data, errors },
  };
}
