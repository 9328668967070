/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import * as Types from '../../../__generated__/schema';

import { GraphQLError } from 'graphql';
import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';

export type GenerateEnrichedEventVariables = {
  input: Types.GenerateEnrichedEventInput;
};

export type GenerateEnrichedEvent = {
  generateEnrichedEvent: Pick<Types.GenerateEnrichedEventResponse, 'enrichedEvent'>;
};

export const GenerateEnrichedEventDocument = gql`
  query generateEnrichedEvent($input: GenerateEnrichedEventInput!) {
    generateEnrichedEvent(input: $input) {
      enrichedEvent
    }
  }
`;

/**
 * __useGenerateEnrichedEvent__
 *
 * To run a query within a React component, call `useGenerateEnrichedEvent` and pass it any options that fit your needs.
 * When your component renders, `useGenerateEnrichedEvent` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGenerateEnrichedEvent({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGenerateEnrichedEvent(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GenerateEnrichedEvent,
    GenerateEnrichedEventVariables
  >
) {
  return ApolloReactHooks.useQuery<GenerateEnrichedEvent, GenerateEnrichedEventVariables>(
    GenerateEnrichedEventDocument,
    baseOptions
  );
}
export function useGenerateEnrichedEventLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GenerateEnrichedEvent,
    GenerateEnrichedEventVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<GenerateEnrichedEvent, GenerateEnrichedEventVariables>(
    GenerateEnrichedEventDocument,
    baseOptions
  );
}
export type GenerateEnrichedEventHookResult = ReturnType<typeof useGenerateEnrichedEvent>;
export type GenerateEnrichedEventLazyQueryHookResult = ReturnType<
  typeof useGenerateEnrichedEventLazyQuery
>;
export type GenerateEnrichedEventQueryResult = ApolloReactCommon.QueryResult<
  GenerateEnrichedEvent,
  GenerateEnrichedEventVariables
>;
export function mockgenerateEnrichedEvent({
  data,
  variables,
  errors,
}: {
  data: GenerateEnrichedEvent;
  variables?: GenerateEnrichedEventVariables;
  errors?: GraphQLError[];
}) {
  return {
    request: { query: GenerateEnrichedEventDocument, variables },
    result: { data, errors },
  };
}
