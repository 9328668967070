/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import * as Types from '../../../__generated__/schema';

import { AlertSummaryFull } from '../fragments/AlertSummaryFull.generated';
import { GraphQLError } from 'graphql';
import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';

export type UpdateAlertStatusByIdVariables = {
  input: Types.UpdateAlertStatusByIdInput;
};

export type UpdateAlertStatusById = { updateAlertStatusById: { alerts: Array<AlertSummaryFull> } };

export const UpdateAlertStatusByIdDocument = gql`
  mutation UpdateAlertStatusById($input: UpdateAlertStatusByIdInput!) {
    updateAlertStatusById(input: $input) {
      alerts {
        ...AlertSummaryFull
      }
    }
  }
  ${AlertSummaryFull}
`;
export type UpdateAlertStatusByIdMutationFn = ApolloReactCommon.MutationFunction<
  UpdateAlertStatusById,
  UpdateAlertStatusByIdVariables
>;

/**
 * __useUpdateAlertStatusById__
 *
 * To run a mutation, you first call `useUpdateAlertStatusById` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAlertStatusById` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAlertStatusById, { data, loading, error }] = useUpdateAlertStatusById({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAlertStatusById(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateAlertStatusById,
    UpdateAlertStatusByIdVariables
  >
) {
  return ApolloReactHooks.useMutation<UpdateAlertStatusById, UpdateAlertStatusByIdVariables>(
    UpdateAlertStatusByIdDocument,
    baseOptions
  );
}
export type UpdateAlertStatusByIdHookResult = ReturnType<typeof useUpdateAlertStatusById>;
export type UpdateAlertStatusByIdMutationResult = ApolloReactCommon.MutationResult<
  UpdateAlertStatusById
>;
export type UpdateAlertStatusByIdMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateAlertStatusById,
  UpdateAlertStatusByIdVariables
>;
export function mockUpdateAlertStatusById({
  data,
  variables,
  errors,
}: {
  data: UpdateAlertStatusById;
  variables?: UpdateAlertStatusByIdVariables;
  errors?: GraphQLError[];
}) {
  return {
    request: { query: UpdateAlertStatusByIdDocument, variables },
    result: { data, errors },
  };
}
