/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { useSnackbar } from 'pouncejs';
import useAuth from 'Hooks/useAuth';
import { UserDetails } from 'Source/graphql/fragments/UserDetails.generated';
import { getActorDisplayName } from 'Helpers/utils';
import { useDeleteUser } from './graphql/deleteUser.generated';
import ConfirmModal from '../ConfirmModal';

export interface DeleteUserModalProps {
  user: UserDetails;
}

const DeleteUserModal: React.FC<DeleteUserModalProps> = ({ user, ...rest }) => {
  const { signOut, userInfo } = useAuth();
  const { pushSnackbar } = useSnackbar();

  const userDisplayName = getActorDisplayName(user);
  const [deleteUser] = useDeleteUser({
    variables: {
      id: user.id,
    },
    update: async cache => {
      cache.evict({ id: cache.identify(user) });
      cache.gc();
    },
    onCompleted: async () => {
      pushSnackbar({
        variant: 'success',
        title: `Successfully deleted user: ${userDisplayName}`,
      });
      // Checking if user deleted is the same as the user signed in
      if (userInfo.id === user.id) {
        await signOut({ global: true });
      }
    },
    onError: () => {
      pushSnackbar({
        variant: 'error',
        title: `Failed to delete user: ${userDisplayName}`,
      });
    },
  });

  return (
    <ConfirmModal
      variant="destructive"
      subtitle={`Are you sure you want to delete ${userDisplayName}?`}
      onConfirm={deleteUser}
      {...rest}
    />
  );
};

export default DeleteUserModal;
