/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Box, Button, Flex, Img, Text, Card } from 'pouncejs';
import animatedGears from 'Assets/gears-animated.svg';

interface LookupUploaderProcessingProps {
  onAbort: () => void;
}

const LookupUploaderProcessing: React.FC<LookupUploaderProcessingProps> = ({ onAbort }) => {
  return (
    <Card variant="dark" py={6}>
      <Box textAlign="center" minWidth={600}>
        <Flex direction="column" spacing={6}>
          <Text data-testid="processing-indicator" fontSize="large">
            Your file is being processed…
          </Text>
          <Flex justify="center" mt={3}>
            <Flex
              align="center"
              justify="center"
              borderRadius="circle"
              backgroundColor="violet-500"
              width={74}
              height={74}
            >
              <Img src={animatedGears} alt="Animated gears" nativeWidth={43} nativeHeight={40} />
            </Flex>
          </Flex>
          <Box mt={4}>
            <Button onClick={onAbort} size="medium" variant="outline" variantColor="navyblue-300">
              Cancel
            </Button>
          </Box>
        </Flex>
      </Box>
    </Card>
  );
};

export default LookupUploaderProcessing;
