/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import { Box, Text } from 'pouncejs';
import React from 'react';

interface StackDeletionNoticeProps {
  awsAccountId: string;
}

const StackDeletionNotice: React.FC<StackDeletionNoticeProps> = ({ awsAccountId }) => (
  <Box>
    <Text>
      Deleting this source will not delete any resources from the AWS Account{' '}
      <strong>{awsAccountId}</strong> .
    </Text>
  </Box>
);

export default React.memo(StackDeletionNotice);
