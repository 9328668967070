/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Box, SimpleGrid } from 'pouncejs';
import { DESTINATIONS } from 'Source/constants';
import { useWizardContext, WizardPanel } from 'Components/Wizard';
import DestinationCard from './DestinationCard';
import { WizardData } from '../CreateDestinationWizard';

const destinationConfigs = Object.values(DESTINATIONS);

export const ChooseDestinationPanel: React.FC = () => {
  const { goToNextStep, setData } = useWizardContext<WizardData>();
  return (
    <Box maxWidth={700} mx="auto">
      <WizardPanel.Heading
        title="Select an Alert Destination"
        subtitle="Add a new destination below to deliver alerts to a specific application for further triage"
      />
      <SimpleGrid columns={3} gap={5}>
        {destinationConfigs.map(destinationConfig => (
          <DestinationCard
            key={destinationConfig.title}
            logo={destinationConfig.logo}
            title={destinationConfig.title}
            onClick={() => {
              setData({ selectedDestinationType: destinationConfig.type });
              goToNextStep();
            }}
          />
        ))}
      </SimpleGrid>
    </Box>
  );
};

export default React.memo(ChooseDestinationPanel);
