/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import SecurityCheckImg from 'Assets/illustrations/security-check.svg';
import { Box, Flex, Heading, Text } from 'pouncejs';

const ListAlertsPageEmptyDataFallback: React.FC = () => {
  return (
    <Flex
      height="100%"
      width="100%"
      justify="center"
      align="center"
      direction="column"
      data-testid="list-alerts-empty-fallback"
    >
      <Box m={10}>
        <img
          alt="Shield with checkmark illustration"
          src={SecurityCheckImg}
          width="auto"
          height={350}
        />
      </Box>
      <Heading mb={6}>It{"'"}s quiet in here</Heading>
      <Text color="gray-300" textAlign="center" mb={8}>
        Any suspicious rule-based activity we detect will be listed here
      </Text>
    </Flex>
  );
};

export default ListAlertsPageEmptyDataFallback;
