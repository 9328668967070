/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Box, FadeIn } from 'pouncejs';
import { FileError, FileRejection } from 'react-dropzone';
import { useGenerateS3SampleDataUpload } from './graphql/generateS3SampleDataUpload.generated';
import UploadError from './Error';
import UploadDropzone from './UploadDropzone';
import Processing from './Processing';

enum UploadStateEnum {
  PENDING,
  PROCESSING,
  ERROR,
}

interface UploadPanelProps {
  header?: React.ReactNode;
  sessionId: string;
  acceptedFiles?: string;
  maxSizeInMB?: number;
  onSuccess: (session: string) => void;
}

const uploadData: ({
  data,
  uploadUrl,
}: {
  data: BodyInit;
  uploadUrl: string;
}) => Promise<Response> = async ({ data, uploadUrl }) => {
  return fetch(uploadUrl, {
    method: 'PUT',
    body: data,
  });
};

type UploadErrorMsgProps = Pick<UploadPanelProps, 'acceptedFiles' | 'maxSizeInMB'> & {
  fileErrorCode?: FileError['code'];
};

const uploadErrorMsg = ({
  fileErrorCode,
  acceptedFiles,
  maxSizeInMB,
}: UploadErrorMsgProps): string => {
  switch (fileErrorCode) {
    case 'file-too-large':
      return `File is too large, try a smaller one. Max file size ${maxSizeInMB}MB `;
    case 'file-invalid-type':
      return `Only ${acceptedFiles} files are accepted. Try again`;
    default:
      return 'Unknown error when attempted to read file, try again or try a different file';
  }
};

const S3UploadPanel: React.FC<UploadPanelProps> = ({
  header,
  sessionId,
  maxSizeInMB = 20,
  acceptedFiles,
  onSuccess,
}) => {
  const [uploadingState, setUploadingState] = React.useState<UploadStateEnum>(
    UploadStateEnum.PENDING
  );
  const [errorMsg, setErrorMsg] = React.useState('');

  const [generateS3SampleDataUploadUrl] = useGenerateS3SampleDataUpload();

  const onAbort = React.useCallback(() => {
    // Reset to pending state
    setUploadingState(UploadStateEnum.PENDING);
  }, []);

  const onFilesDropped = React.useCallback(
    async (validFiles: File[], rejectedFiles: FileRejection[]) => {
      if (!validFiles || validFiles?.length !== 1) {
        if (rejectedFiles.length) {
          const { errors } = rejectedFiles[0];
          setErrorMsg(
            uploadErrorMsg({ fileErrorCode: errors[0]?.code, acceptedFiles, maxSizeInMB })
          );
        } else {
          setErrorMsg(uploadErrorMsg({ acceptedFiles }));
        }
        setUploadingState(UploadStateEnum.ERROR);
        return;
      }
      const [file] = validFiles;

      setUploadingState(UploadStateEnum.PROCESSING);
      try {
        const uploadInfo = await generateS3SampleDataUploadUrl({
          variables: {
            input: { sessionId },
          },
        });
        const resp = await uploadData({
          data: file,
          uploadUrl: uploadInfo.data.generateS3SampleDataUpload.uploadURL,
        });
        if (resp.ok) {
          setErrorMsg('');
          onSuccess(uploadInfo.data.generateS3SampleDataUpload.sessionId);
        } else {
          setErrorMsg('An unknown error occurred during the upload');
          setUploadingState(UploadStateEnum.ERROR);
        }
        // and in case of an error, reset the file input. If we don't do that, then the user can't
        // re-upload the same file he had selected, since the field would never have been cleared.
        // This protects us against just that.
      } catch (err) {
        setErrorMsg('An unknown error occurred during the upload');
        setUploadingState(UploadStateEnum.ERROR);
      }
      // });
    },
    [
      setUploadingState,
      sessionId,
      generateS3SampleDataUploadUrl,
      onSuccess,
      maxSizeInMB,
      acceptedFiles,
    ]
  );

  const restartUploading = React.useCallback(() => {
    setUploadingState(UploadStateEnum.PENDING);
    setErrorMsg('');
  }, [setUploadingState, setErrorMsg]);

  return (
    <Box>
      <FadeIn>
        {header}
        {uploadingState === UploadStateEnum.PENDING && (
          <UploadDropzone
            maxSizeInMB={maxSizeInMB}
            acceptedFiles={acceptedFiles}
            onFilesDropped={onFilesDropped}
          />
        )}
        {uploadingState === UploadStateEnum.PROCESSING && <Processing onAbort={onAbort} />}
        {uploadingState === UploadStateEnum.ERROR && (
          <UploadError errorMsg={errorMsg} onRestartUploading={restartUploading} />
        )}
      </FadeIn>
    </Box>
  );
};

export default React.memo(S3UploadPanel);
