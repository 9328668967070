/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import * as Types from '../../../../../../__generated__/schema';

import { DestinationDetails } from '../../../../../graphql/fragments/DestinationDetails.generated';
import { GraphQLError } from 'graphql';
import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';

export type AddDestinationVariables = {
  input: Types.DestinationInput;
};

export type AddDestination = { addDestination?: Types.Maybe<DestinationDetails> };

export const AddDestinationDocument = gql`
  mutation AddDestination($input: DestinationInput!) {
    addDestination(input: $input) {
      ...DestinationDetails
    }
  }
  ${DestinationDetails}
`;
export type AddDestinationMutationFn = ApolloReactCommon.MutationFunction<
  AddDestination,
  AddDestinationVariables
>;

/**
 * __useAddDestination__
 *
 * To run a mutation, you first call `useAddDestination` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddDestination` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addDestination, { data, loading, error }] = useAddDestination({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddDestination(
  baseOptions?: ApolloReactHooks.MutationHookOptions<AddDestination, AddDestinationVariables>
) {
  return ApolloReactHooks.useMutation<AddDestination, AddDestinationVariables>(
    AddDestinationDocument,
    baseOptions
  );
}
export type AddDestinationHookResult = ReturnType<typeof useAddDestination>;
export type AddDestinationMutationResult = ApolloReactCommon.MutationResult<AddDestination>;
export type AddDestinationMutationOptions = ApolloReactCommon.BaseMutationOptions<
  AddDestination,
  AddDestinationVariables
>;
export function mockAddDestination({
  data,
  variables,
  errors,
}: {
  data: AddDestination;
  variables?: AddDestinationVariables;
  errors?: GraphQLError[];
}) {
  return {
    request: { query: AddDestinationDocument, variables },
    result: { data, errors },
  };
}
