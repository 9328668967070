/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Wizard } from 'Components/Wizard';
import { Form, Formik } from 'formik';
import useFindSourcesPerMethod from 'Hooks/useFindSourcesPerMethod';
import * as Yup from 'yup';
import { LogTransportMethodsEnum } from 'Source/constants';
import { yupIntegrationLabelUniqueness } from 'Helpers/utils';
import { AsanaLogIntegrationDetails } from 'Source/graphql/fragments/AsanaLogIntegrationDetails.generated';
import CancelIntegrationAction from 'Pages/Integrations/components/CancelIntegrationAction';
import ConfigurationPanel from './ConfigurationPanel';
import CredentialsPanel from './CredentialsPanel';
import ValidationPanel from './ValidationPanel';

interface AsanaLogSourceWizardProps {
  initialValues: AsanaLogsSourceWizardValues;
  onSubmit: (values: AsanaLogsSourceWizardValues) => Promise<AsanaLogIntegrationDetails | Error>;
}

export interface AsanaLogsSourceWizardValues {
  integrationId?: string;
  integrationLabel: string;
  logTypes: string[];
  organizationId: string;
  serviceAccountToken: string;
}

const AsanaWizard = ({ initialValues, onSubmit }: AsanaLogSourceWizardProps) => {
  const existingPullerSources = useFindSourcesPerMethod(LogTransportMethodsEnum.logPulling);

  const validationSchema: Yup.SchemaOf<AsanaLogsSourceWizardValues> = React.useMemo(
    () =>
      Yup.object().shape({
        integrationId: Yup.string(),
        logTypes: Yup.array().of(Yup.string().required()).required().min(1),
        integrationLabel: yupIntegrationLabelUniqueness({
          existingLabel: initialValues.integrationLabel,
          existingSources: existingPullerSources,
        }),
        organizationId: Yup.string().required(),
        serviceAccountToken: initialValues.integrationId ? Yup.string() : Yup.string().required(),
      }),
    [initialValues.integrationId, initialValues.integrationLabel, existingPullerSources]
  );

  const editMode = !!initialValues.integrationId;

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      <Form>
        <Wizard headerTitle="Connecting a new Log Source" enableAllNavigationSteps={editMode}>
          <Wizard.Step
            title="Configure Source"
            description="Provide account information and set preferences to connect the log source to Panther"
          >
            <ConfigurationPanel />
          </Wizard.Step>
          <Wizard.Step
            title="Set Credentials"
            description="Provide account credentials to give Panther permissions to collect logs from the source"
          >
            <CredentialsPanel />
          </Wizard.Step>
          <Wizard.Step
            title="Verify Setup"
            description="Confirm that your log source has been successfully onboarded or troubleshoot any issues"
            navigationDisabled={editMode}
          >
            <ValidationPanel />
          </Wizard.Step>
        </Wizard>
        {!editMode && <CancelIntegrationAction />}
      </Form>
    </Formik>
  );
};

export default AsanaWizard;
