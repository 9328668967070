/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import urls from 'Source/urls';
import ListLookupsPage from 'Pages/Enrichment/Lookups/ListLookups';
import CreateLookupPage from 'Pages/Enrichment/Lookups/CreateLookup';
import EditLookupPage from 'Pages/Enrichment/Lookups/EditLookup';
import ListEnrichmentSources from 'Pages/Enrichment/Sources/ListEnrichmentSources';
import EnrichmentSourceDetails from 'Pages/Enrichment/Sources/EnrichmentSourceDetails';

const EnrichmentRoutes = () => {
  return (
    <Switch>
      <Redirect exact from={urls.enrichment.home()} to={urls.enrichment.lookupTables.list()} />
      <Route exact path={urls.enrichment.lookupTables.list()} component={ListLookupsPage} />
      <Route exact path={urls.enrichment.lookupTables.create()} component={CreateLookupPage} />
      <Route exact path={urls.enrichment.lookupTables.edit(':id')} component={EditLookupPage} />
      <Route
        exact
        path={urls.enrichment.sources.details(':id')}
        component={EnrichmentSourceDetails}
      />

      <Route exact path={urls.enrichment.sources.list()} component={ListEnrichmentSources} />
    </Switch>
  );
};

export default EnrichmentRoutes;
