/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import type { monaco } from 'react-monaco-editor';
import React from 'react';
import { Theme, useTheme } from 'pouncejs';
import { remToPx } from 'Helpers/utils';

interface UseEditorConfigProps {
  ariaLabel?: string;
  readOnly?: boolean;
  fontSize?: keyof Theme['fontSizes'];
  hideLineNumbers?: boolean;
  hasLineErrors?: boolean;
}

const useEditorConfig = ({
  readOnly,
  ariaLabel,
  fontSize = 'medium',
  hideLineNumbers = false,
  hasLineErrors = false,
}: UseEditorConfigProps) => {
  const theme = useTheme();

  return React.useMemo<monaco.editor.IStandaloneEditorConstructionOptions>(
    () => ({
      codeLens: false,
      cursorBlinking: 'smooth',
      padding: {
        top: 8,
        bottom: 8,
      },
      lineNumbers: hideLineNumbers ? 'off' : 'on',
      folding: !hideLineNumbers,
      lineNumbersMinChars: 3,
      lineDecorationsWidth: 1,
      revealHorizontalRightPadding: 0,
      glyphMargin: hasLineErrors,
      ariaLabel,
      readOnly,
      domReadOnly: readOnly,
      renderLineHighlight: 'gutter',
      fontFamily: theme.fonts.mono,
      fontWeight: `${theme.fontWeights.medium}`,
      fontSize: remToPx(theme.fontSizes[fontSize]),
      scrollBeyondLastLine: false,
      scrollbar: {
        alwaysConsumeMouseWheel: false,
      },
      automaticLayout: true,
      wordWrap: 'on',
      renderIndentGuides: false,
      minimap: {
        enabled: false,
      },
      overviewRulerLanes: 0,
    }),
    // FIXME: look into hook dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [theme, readOnly, hideLineNumbers]
  );
};

export default useEditorConfig;
