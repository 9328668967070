/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import useAvailableLogTypesForProvider from 'Hooks/useAvailableLogTypesForProvider';
import { compose } from 'Helpers/compose';
import withSEO from 'Hoc/withSEO';
import withRoleRestrictedAccess from 'Hoc/withRoleRestrictedAccess';
import { Permission } from 'Generated/schema';
import Page403 from 'Pages/403';
import useUrlParams from 'Hooks/useUrlParams';
import { GcsLogSourceWizardValues } from '../GcsLogSourceWizard/GcsLogSourceWizard';
import { useAddGcsLogIntegration } from '../graphql/addGcsLogIntegration.generated';
import GcsLogSourceWizard from '../GcsLogSourceWizard';

const CreateGcsLogSource = () => {
  const { urlParams: { logProviderId } } = useUrlParams<{ logProviderId?: string }>(); // prettier-ignore
  const { availableLogTypes, defaultLogTypes } = useAvailableLogTypesForProvider(logProviderId);
  const initialValues: GcsLogSourceWizardValues = React.useMemo(
    () => ({
      integrationId: '',
      integrationLabel: '',
      projectId: '',
      subscriptionId: '',
      gcsBucket: '',
      credentials: '',
      userEmail: '',
      logTypes: defaultLogTypes ?? [],
      jsonKeyfile: null,
    }),
    [defaultLogTypes]
  );

  const [addGcsLogIntegration] = useAddGcsLogIntegration({
    update: (cache, { data }) => {
      cache.modify({
        fields: {
          logSources: (queryData, { toReference }) => {
            const addedIntegrationCacheRef = toReference(data.addGcsLogIntegration);
            return queryData
              ? [addedIntegrationCacheRef, ...queryData]
              : [addedIntegrationCacheRef];
          },
        },
      });
    },
  });

  const handleSubmit = React.useCallback(
    async (values: GcsLogSourceWizardValues) => {
      try {
        const { data } = await addGcsLogIntegration({
          variables: {
            input: {
              logProviderId: null,
              integrationLabel: values.integrationLabel,
              gcsConfig: {
                projectId: values.projectId,
                subscriptionId: values.subscriptionId,
                gcsBucket: values.gcsBucket,
                credentials: values.credentials,
                userEmail: values.userEmail,
                logTypes: values.logTypes,
              },
            },
          },
        });

        return data.addGcsLogIntegration;
      } catch (error) {
        return error;
      }
    },
    [addGcsLogIntegration]
  );

  return (
    <GcsLogSourceWizard
      initialValues={initialValues}
      availableLogTypes={availableLogTypes}
      onSubmit={handleSubmit}
    />
  );
};

export default compose(
  withSEO({ title: 'New Google Cloud Storage Source' }),
  withRoleRestrictedAccess({
    allowedPermissions: [Permission.LogSourceModify],
    fallback: <Page403 />,
  })
)(CreateGcsLogSource);
