/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Text } from 'pouncejs';
import { WizardPanel } from 'Components/Wizard';

interface LookupPanelHeadingProps {
  title: string;
  subtitle?: string;
}

const LookupPanelHeading: React.FC<LookupPanelHeadingProps> = ({ title, subtitle }) => {
  return (
    <WizardPanel.Heading
      title={title}
      subtitle={
        <Text fontWeight="normal" fontSize="small" color="white-100">
          {subtitle}
        </Text>
      }
      divider={null}
    />
  );
};

export default LookupPanelHeading;
