/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import * as Types from '../../../../../__generated__/schema';

import { GraphQLError } from 'graphql';
import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';

export type GetMitreMatrixVariables = {
  id: Types.Scalars['ID'];
};

export type GetMitreMatrix = {
  mitreMatrix?: Types.Maybe<
    Pick<Types.MitreMatrix, 'id' | 'name'> & {
      mappings: Array<Pick<Types.MitreMatrixTacticMapping, 'tacticId' | 'techniqueIds'>>;
      content: {
        tactics: Array<Pick<Types.MitreTactic, 'id' | 'url' | 'name'>>;
        techniques: Array<Pick<Types.MitreTechnique, 'id' | 'url' | 'name'>>;
      };
      reportSettings: Array<
        Pick<
          Types.ReportSetting,
          'id' | 'note' | 'override' | 'reportType' | 'matchingLogTypes' | 'matchingDetectionIds'
        > & {
          association: {
            mitre: Pick<Types.ReportSettingAssociationMitre, 'tacticId' | 'techniqueId'>;
          };
        }
      >;
    }
  >;
};

export const GetMitreMatrixDocument = gql`
  query GetMitreMatrix($id: ID!) {
    mitreMatrix(id: $id) {
      id
      name
      mappings {
        tacticId
        techniqueIds
      }
      content {
        tactics {
          id
          url
          name
        }
        techniques {
          id
          url
          name
        }
      }
      reportSettings {
        id
        note
        override
        reportType
        matchingLogTypes
        matchingDetectionIds
        association {
          mitre {
            tacticId
            techniqueId
          }
        }
      }
    }
  }
`;

/**
 * __useGetMitreMatrix__
 *
 * To run a query within a React component, call `useGetMitreMatrix` and pass it any options that fit your needs.
 * When your component renders, `useGetMitreMatrix` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMitreMatrix({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetMitreMatrix(
  baseOptions?: ApolloReactHooks.QueryHookOptions<GetMitreMatrix, GetMitreMatrixVariables>
) {
  return ApolloReactHooks.useQuery<GetMitreMatrix, GetMitreMatrixVariables>(
    GetMitreMatrixDocument,
    baseOptions
  );
}
export function useGetMitreMatrixLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetMitreMatrix, GetMitreMatrixVariables>
) {
  return ApolloReactHooks.useLazyQuery<GetMitreMatrix, GetMitreMatrixVariables>(
    GetMitreMatrixDocument,
    baseOptions
  );
}
export type GetMitreMatrixHookResult = ReturnType<typeof useGetMitreMatrix>;
export type GetMitreMatrixLazyQueryHookResult = ReturnType<typeof useGetMitreMatrixLazyQuery>;
export type GetMitreMatrixQueryResult = ApolloReactCommon.QueryResult<
  GetMitreMatrix,
  GetMitreMatrixVariables
>;
export function mockGetMitreMatrix({
  data,
  variables,
  errors,
}: {
  data: GetMitreMatrix;
  variables?: GetMitreMatrixVariables;
  errors?: GraphQLError[];
}) {
  return {
    request: { query: GetMitreMatrixDocument, variables },
    result: { data, errors },
  };
}
