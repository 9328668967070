/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import * as Types from '../../../../../../../__generated__/schema';

import { SalesforceLogIntegrationDetails } from '../../../../../../graphql/fragments/SalesforceLogIntegrationDetails.generated';
import { GraphQLError } from 'graphql';
import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';

export type UpdateSalesforceLogSourceVariables = {
  input: Types.UpdateLogPullingIntegrationInput;
};

export type UpdateSalesforceLogSource = {
  updateLogPullingIntegration: SalesforceLogIntegrationDetails;
};

export const UpdateSalesforceLogSourceDocument = gql`
  mutation UpdateSalesforceLogSource($input: UpdateLogPullingIntegrationInput!) {
    updateLogPullingIntegration(input: $input) {
      ...SalesforceLogIntegrationDetails
    }
  }
  ${SalesforceLogIntegrationDetails}
`;
export type UpdateSalesforceLogSourceMutationFn = ApolloReactCommon.MutationFunction<
  UpdateSalesforceLogSource,
  UpdateSalesforceLogSourceVariables
>;

/**
 * __useUpdateSalesforceLogSource__
 *
 * To run a mutation, you first call `useUpdateSalesforceLogSource` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSalesforceLogSource` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSalesforceLogSource, { data, loading, error }] = useUpdateSalesforceLogSource({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSalesforceLogSource(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateSalesforceLogSource,
    UpdateSalesforceLogSourceVariables
  >
) {
  return ApolloReactHooks.useMutation<
    UpdateSalesforceLogSource,
    UpdateSalesforceLogSourceVariables
  >(UpdateSalesforceLogSourceDocument, baseOptions);
}
export type UpdateSalesforceLogSourceHookResult = ReturnType<typeof useUpdateSalesforceLogSource>;
export type UpdateSalesforceLogSourceMutationResult = ApolloReactCommon.MutationResult<
  UpdateSalesforceLogSource
>;
export type UpdateSalesforceLogSourceMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateSalesforceLogSource,
  UpdateSalesforceLogSourceVariables
>;
export function mockUpdateSalesforceLogSource({
  data,
  variables,
  errors,
}: {
  data: UpdateSalesforceLogSource;
  variables?: UpdateSalesforceLogSourceVariables;
  errors?: GraphQLError[];
}) {
  return {
    request: { query: UpdateSalesforceLogSourceDocument, variables },
    result: { data, errors },
  };
}
