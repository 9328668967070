/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { useFormikContext } from 'formik';
import { Box, Button, Card, Flex, Img, Text, useSnackbar } from 'pouncejs';
import folderIllustration from 'Assets/illustrations/folder.svg';
import { extractErrorMessage } from 'Helpers/utils';
import { useUpdateLookup } from 'Source/graphql/queries/updateLookup.generated';
import { LookupFormValues, getLookupUpdatePayload } from '../../lookupWizardHelpers';

interface LookupUploaderDisabledProps {
  onEnabledCompleted: () => void;
}

const LookupUploaderDisabled: React.FC<LookupUploaderDisabledProps> = ({ onEnabledCompleted }) => {
  const { pushSnackbar } = useSnackbar();
  const { values, setFieldValue } = useFormikContext<LookupFormValues>();

  const [updateLookup, { loading }] = useUpdateLookup({
    onCompleted: data => {
      setFieldValue('enabled', data.updateLookup.enabled);
      onEnabledCompleted();
    },
    onError: error => {
      pushSnackbar({
        variant: 'error',
        title: "Couldn't update lookup",
        description: extractErrorMessage(error) || 'Some unknown error occurred',
      });
    },
  });

  const handleEnableLookup = React.useCallback(() => {
    const input = getLookupUpdatePayload({ ...values, enabled: true });
    updateLookup({ variables: { input } });
  }, [updateLookup, values]);

  return (
    <Card variant="dark" p={4} py={6}>
      <Flex direction="column" align="center">
        <Text>You need to enable this Lookup Table to import data</Text>
        <Box p={6}>
          <Img src={folderIllustration} alt="Folder" nativeWidth={75} nativeHeight={64} />
        </Box>
        <Box mt={2}>
          <Button size="medium" loading={loading} disabled={loading} onClick={handleEnableLookup}>
            Enable
          </Button>
        </Box>
      </Flex>
    </Card>
  );
};

export default LookupUploaderDisabled;
