/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import * as Types from '../../../../../../../__generated__/schema';

import { BoxLogIntegrationDetails } from '../../../../../../graphql/fragments/BoxLogIntegrationDetails.generated';
import { GraphQLError } from 'graphql';
import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';

export type UpdateBoxLogSourceVariables = {
  input: Types.UpdateLogPullingIntegrationInput;
};

export type UpdateBoxLogSource = { updateLogPullingIntegration: BoxLogIntegrationDetails };

export const UpdateBoxLogSourceDocument = gql`
  mutation UpdateBoxLogSource($input: UpdateLogPullingIntegrationInput!) {
    updateLogPullingIntegration(input: $input) {
      ...BoxLogIntegrationDetails
    }
  }
  ${BoxLogIntegrationDetails}
`;
export type UpdateBoxLogSourceMutationFn = ApolloReactCommon.MutationFunction<
  UpdateBoxLogSource,
  UpdateBoxLogSourceVariables
>;

/**
 * __useUpdateBoxLogSource__
 *
 * To run a mutation, you first call `useUpdateBoxLogSource` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBoxLogSource` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBoxLogSource, { data, loading, error }] = useUpdateBoxLogSource({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateBoxLogSource(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateBoxLogSource,
    UpdateBoxLogSourceVariables
  >
) {
  return ApolloReactHooks.useMutation<UpdateBoxLogSource, UpdateBoxLogSourceVariables>(
    UpdateBoxLogSourceDocument,
    baseOptions
  );
}
export type UpdateBoxLogSourceHookResult = ReturnType<typeof useUpdateBoxLogSource>;
export type UpdateBoxLogSourceMutationResult = ApolloReactCommon.MutationResult<UpdateBoxLogSource>;
export type UpdateBoxLogSourceMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateBoxLogSource,
  UpdateBoxLogSourceVariables
>;
export function mockUpdateBoxLogSource({
  data,
  variables,
  errors,
}: {
  data: UpdateBoxLogSource;
  variables?: UpdateBoxLogSourceVariables;
  errors?: GraphQLError[];
}) {
  return {
    request: { query: UpdateBoxLogSourceDocument, variables },
    result: { data, errors },
  };
}
