/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Box, FormError, MultiCombobox, MultiComboboxProps } from 'pouncejs';
import { FieldConfig } from 'formik';
import useFastField from 'Hooks/useFastField';

function FormikMultiCombobox<T>(
  props: MultiComboboxProps<T> & Required<Pick<FieldConfig, 'name'>>
): React.ReactNode {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [field, meta, { setValue }] = useFastField(props.name);

  const isInvalid = meta.touched && !!meta.error;
  const errorElementId = isInvalid ? `${props.name}-error` : undefined;

  return (
    <Box>
      <MultiCombobox
        {...props}
        invalid={isInvalid}
        aria-describedby={isInvalid ? errorElementId : undefined}
        onChange={setValue}
      />
      {isInvalid && (
        <FormError mt={2} id={errorElementId}>
          {meta.error}
        </FormError>
      )}
    </Box>
  );
}

export default FormikMultiCombobox;
