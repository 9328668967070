/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { SAAS_ONBOARDING_1PASS_DOC_URL } from 'Source/constants';
import { Box, Flex, Heading, Link, FormHelperText } from 'pouncejs';
import { Field, useFormikContext } from 'formik';
import FormikRadio from 'Components/fields/Radio';
import SensitiveTextInput from 'Components/fields/SensitiveTextInput';
import { WizardPanel } from 'Components/Wizard';
import { OnePasswordHost } from 'Generated/schema';
import EditIntegrationActions from 'Pages/Integrations/components/EditIntegrationActions';
import { OnePasswordLogSourceWizardValues } from '../OnePasswordLogSourceWizard';

const hostLabels: Record<OnePasswordHost, string> = {
  Business: 'Business - 1Password.com',
  Enterprise: 'Enterprise - 1Password.com',
  Canada: 'Canada - 1Password.ca',
  Europe: 'Europe - 1Password.eu',
};

const OnePasswordLogSourceCredentialsPanel: React.FC = () => {
  const { initialValues, dirty, isValid } = useFormikContext<OnePasswordLogSourceWizardValues>();

  const editMode = !!initialValues.integrationId;
  return (
    <WizardPanel>
      <Box width={580} m="auto">
        <WizardPanel.Heading title="Fill in the form below with your credentials" />
        <Flex flexDirection="column" spacing={4}>
          <Heading as="h2" color="teal-200" fontSize="x-large" fontWeight="medium">
            1. Create an Access Token
          </Heading>
          <FormHelperText id="one-password-description" fontSize="medium-large" color="white">
            Check{' '}
            <Link external href={SAAS_ONBOARDING_1PASS_DOC_URL}>
              our documentation
            </Link>{' '}
            to create an 1Password token and fill in the field below
          </FormHelperText>
          <Field
            name="token"
            aria-describedby="one-password-description"
            as={SensitiveTextInput}
            shouldMask={editMode}
            label="Access Token"
            placeholder={
              editMode
                ? 'Information is hidden. New values will override the existing'
                : 'Your 1Password access token'
            }
            required={!editMode}
            type="password"
          />
          <Flex flexDirection="column">
            <Heading as="h2" color="teal-200" mt={4} mb={1} fontSize="x-large" fontWeight="medium">
              2. Select the region & plan of your 1Password Account
            </Heading>
            {Object.keys(OnePasswordHost).map(host => (
              <Field
                key={host}
                as={FormikRadio}
                name="host"
                value={host}
                label={hostLabels[host]}
              />
            ))}
          </Flex>
        </Flex>
      </Box>
      {editMode ? (
        <EditIntegrationActions />
      ) : (
        <WizardPanel.Actions>
          <WizardPanel.ActionNext disabled={!dirty || !isValid}>
            Continue Setup
          </WizardPanel.ActionNext>
        </WizardPanel.Actions>
      )}
    </WizardPanel>
  );
};

export default OnePasswordLogSourceCredentialsPanel;
