/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Box, Card, useSnackbar } from 'pouncejs';
import ErrorBoundary from 'Components/ErrorBoundary';
import RoleForm, { RoleFormValues } from 'Components/forms/RoleForm';
import { extractErrorMessage } from 'Helpers/utils';
import urls from 'Source/urls';
import useRouter from 'Hooks/useRouter';
import useAuth from 'Hooks/useAuth';
import withRoleRestrictedAccess from 'Hoc/withRoleRestrictedAccess';
import { LogTypeAccessKind, Permission } from 'Generated/schema';
import { compose } from 'Helpers/compose';
import withSEO from 'Hoc/withSEO';
import Page403 from 'Pages/403';
import Page404 from 'Pages/404';
import { useGetRole } from './graphql/getRole.generated';
import { useUpdateRole } from './graphql/updateRole.generated';

const EditRole: React.FC = () => {
  const { refetchUserInfo } = useAuth();
  const { pushSnackbar } = useSnackbar();
  const { match, history } = useRouter<{ id: string }>();
  const { data, error: getError } = useGetRole({
    variables: { id: match.params.id },
    onError: error => {
      pushSnackbar({
        title: extractErrorMessage(error) || 'An unknown error occured',
        variant: 'error',
      });
    },
  });

  const [updateRole] = useUpdateRole({
    onCompleted: () => {
      history.push(urls.settings.roles.details(match.params.id));

      // refetch our info to update our permissions in case they have changed from this role update
      refetchUserInfo();
    },
    onError: error => {
      pushSnackbar({
        title: extractErrorMessage(error) || 'An unknown error occured',
        variant: 'error',
      });
    },
  });

  const handleSubmit = React.useCallback(
    async (values: RoleFormValues) => {
      await updateRole({
        variables: {
          input: {
            id: match.params.id,
            name: values.name,
            permissions: values.permissions,
            logTypeAccessKind: values.logTypeAccessKind,
            logTypeAccess:
              values.logTypeAccessKind === LogTypeAccessKind.AllowAll ||
              values.logTypeAccessKind === LogTypeAccessKind.DenyAll
                ? null
                : values.logTypeAccess,
          },
        },
      });
    },
    [updateRole, match]
  );

  const initialValues = React.useMemo(
    () => ({
      id: data?.roleById.id,
      name: data?.roleById.name || 'Loading...',
      permissions: data?.roleById.permissions || [],
      logTypeAccess: data?.roleById.logTypeAccess,
      logTypeAccessKind: data?.roleById.logTypeAccessKind || LogTypeAccessKind.DenyAll,
    }),
    [data]
  );

  // we optimistically assume that an error in "get" is a 404. We don't have any other info
  if (getError) {
    return <Page404 />;
  }

  return (
    <Box mb={6}>
      <ErrorBoundary>
        <Card p={6}>
          <RoleForm initialValues={initialValues} onSubmit={handleSubmit} />
        </Card>
      </ErrorBoundary>
    </Box>
  );
};

export default compose(
  withSEO({ title: 'Edit Role' }),
  withRoleRestrictedAccess({
    allowedPermissions: [Permission.UserModify],
    fallback: <Page403 />,
  })
)(EditRole);
