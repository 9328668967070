/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { useLoadAllSchemaEntities } from 'Pages/DataExplorer/SQLEditor/graphql/loadAllSchemaEntities.generated';
import { shouldSaveData } from 'Helpers/connection';
import { extractErrorMessage } from 'Helpers/utils';
import { Completion } from 'Components/Editor';
import uniqBy from 'lodash/uniqBy';
import { useSnackbar } from 'pouncejs';
import userAddedCompletions from './userAddedCompletions.json';

const useSQLEditorAutocompletion = () => {
  const { pushSnackbar } = useSnackbar();

  // Fetch Autocomplete suggestions
  const { data: schemaData } = useLoadAllSchemaEntities({
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    skip: shouldSaveData(),
    onError: error =>
      pushSnackbar({
        variant: 'warning',
        title: 'SQL autocomplete is disabled',
        description: extractErrorMessage(error),
      }),
  });

  // Create proper completion data
  return React.useMemo(() => {
    const acc = new Set<Completion>();
    if (schemaData) {
      userAddedCompletions.forEach(completion => acc.add(completion));
      schemaData.dataLakeDatabases.forEach(database => {
        acc.add({ value: database.name, type: 'database' });
        database.tables.forEach(table => {
          acc.add({ value: table.name, type: 'table' });
          table.columns.forEach(column => {
            acc.add({ value: column.name, type: column.type });
          });
        });
      });
    }

    return uniqBy([...acc], ac => `${ac.value}${ac.type}`);
  }, [schemaData]);
};

export default useSQLEditorAutocompletion;
