/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import * as Types from '../../../../../../../__generated__/schema';

import { SqsLogSourceIntegrationDetails } from '../../../../../../graphql/fragments/SqsLogSourceIntegrationDetails.generated';
import { GraphQLError } from 'graphql';
import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';

export type GetSqsLogSourceVariables = {
  id: Types.Scalars['ID'];
};

export type GetSqsLogSource = { getSqsLogIntegration: SqsLogSourceIntegrationDetails };

export const GetSqsLogSourceDocument = gql`
  query GetSqsLogSource($id: ID!) {
    getSqsLogIntegration(id: $id) {
      ...SqsLogSourceIntegrationDetails
    }
  }
  ${SqsLogSourceIntegrationDetails}
`;

/**
 * __useGetSqsLogSource__
 *
 * To run a query within a React component, call `useGetSqsLogSource` and pass it any options that fit your needs.
 * When your component renders, `useGetSqsLogSource` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSqsLogSource({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetSqsLogSource(
  baseOptions?: ApolloReactHooks.QueryHookOptions<GetSqsLogSource, GetSqsLogSourceVariables>
) {
  return ApolloReactHooks.useQuery<GetSqsLogSource, GetSqsLogSourceVariables>(
    GetSqsLogSourceDocument,
    baseOptions
  );
}
export function useGetSqsLogSourceLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSqsLogSource, GetSqsLogSourceVariables>
) {
  return ApolloReactHooks.useLazyQuery<GetSqsLogSource, GetSqsLogSourceVariables>(
    GetSqsLogSourceDocument,
    baseOptions
  );
}
export type GetSqsLogSourceHookResult = ReturnType<typeof useGetSqsLogSource>;
export type GetSqsLogSourceLazyQueryHookResult = ReturnType<typeof useGetSqsLogSourceLazyQuery>;
export type GetSqsLogSourceQueryResult = ApolloReactCommon.QueryResult<
  GetSqsLogSource,
  GetSqsLogSourceVariables
>;
export function mockGetSqsLogSource({
  data,
  variables,
  errors,
}: {
  data: GetSqsLogSource;
  variables?: GetSqsLogSourceVariables;
  errors?: GraphQLError[];
}) {
  return {
    request: { query: GetSqsLogSourceDocument, variables },
    result: { data, errors },
  };
}
