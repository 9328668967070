/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import withSEO from 'Hoc/withSEO';
import { compose } from 'Helpers/compose';
import withRoleRestrictedAccess from 'Hoc/withRoleRestrictedAccess';
import { Permission } from 'Generated/schema';
import Page403 from 'Pages/403';
import DetectionPackSourceForm from 'Components/forms/DetectionPackSourceForm';
import { useSnackbar } from 'pouncejs';
import { extractErrorMessage } from 'Helpers/utils';
import useRouter from 'Hooks/useRouter';
import Page404 from 'Pages/404/404';
import { useGetPackSource } from 'Pages/EditPackSource/graphql/getPackSource.generated';
import { useUpdatePackSource } from 'Pages/EditPackSource/graphql/updatePackSource.generated';

const EditPackSource: React.FC = () => {
  const { pushSnackbar } = useSnackbar();
  const { match } = useRouter<{ id: string }>();
  const { data, error: getError } = useGetPackSource({
    variables: { id: match.params.id },
    onError: error => {
      pushSnackbar({
        title: extractErrorMessage(error) || 'An unknown error occurred',
        variant: 'error',
      });
    },
  });

  const [updatePackSource] = useUpdatePackSource({
    onCompleted: updateData => {
      pushSnackbar({
        variant: 'success',
        title: `Successfully updated Pack Source: ${updateData?.updateAnalysisPackSource.id}`,
      });
    },
    onError: err => {
      pushSnackbar({
        title: 'Failed to update Pack Source',
        description: extractErrorMessage(err) || 'An unknown error occurred',
        variant: 'error',
      });
    },
  });

  const initialValues = React.useMemo(
    () => ({
      id: match.params.id,
      owner: data?.getAnalysisPackSource?.owner ?? 'Loading...',
      repository: data?.getAnalysisPackSource?.repository ?? 'Loading...',
      githubAccessToken: data?.getAnalysisPackSource?.githubAccessToken ?? '',
      kmsKey: data?.getAnalysisPackSource?.kmsKey ?? '',
    }),
    [data, match.params.id]
  );

  // we optimistically assume that an error in "get" is a 404. We don't have any other info
  if (getError) {
    return <Page404 />;
  }

  return (
    <DetectionPackSourceForm
      initialValues={initialValues}
      onSubmit={values =>
        updatePackSource({
          variables: {
            input: {
              id: values.id,
              githubAccessToken: values.githubAccessToken,
              kmsKey: values.kmsKey,
            },
          },
        })
      }
    />
  );
};

export default compose(
  withSEO({ title: 'Edit Pack Source' }),
  withRoleRestrictedAccess({
    allowedPermissions: [Permission.PolicyModify, Permission.RuleModify],
    fallback: <Page403 />,
  })
)(EditPackSource);
