/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Box, Flex, Heading, Text } from 'pouncejs';
import useAuth from 'Hooks/useAuth';
import AccessDeniedImg from 'Assets/illustrations/authentication.svg';
import withSEO from 'Hoc/withSEO';
import LinkButton from 'Components/buttons/LinkButton';
import { Permission } from 'Generated/schema';
import useCheckPermissions from 'Hooks/useCheckPermissions';

const Page403: React.FC = () => {
  const { userInfo } = useAuth();
  const canUserAccessSummary = useCheckPermissions(Permission.SummaryRead);

  return (
    <Flex justify="center" align="center" direction="column">
      <Box mb={10}>
        <img alt="Access denied illustration" src={AccessDeniedImg} width="auto" height={400} />
      </Box>
      <Heading mb={2}>
        You have no power here, {userInfo ? userInfo.givenName : 'Anonymous'} the Grey
      </Heading>
      <Text fontSize="medium" color="gray-300" mb={10}>
        ( Sarum... Your administrator has restricted your powers )
      </Text>
      {canUserAccessSummary && <LinkButton to="/">Back to Shire</LinkButton>}
    </Flex>
  );
};

export default withSEO({ title: 'Permission Denied' })(Page403);
