/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { SAAS_ONBOARDING_ZENDESK_DOC_URL } from 'Source/constants';
import { Box, Button, Card, Flex, FormHelperText, Link } from 'pouncejs';
import ErrorBoundary from 'Components/ErrorBoundary';
import { Field, useFormikContext, FastField } from 'formik';
import FormikRadio from 'Components/fields/Radio';
import FormikTextInput from 'Components/fields/TextInput';
import SensitiveTextInput from 'Components/fields/SensitiveTextInput';
import { useWizardContext, WizardPanel } from 'Components/Wizard';
import { ZendeskLogsSourceWizardValues } from 'Pages/Integrations/LogSources/Zendesk/ZendeskLogsSourceWizard';
import { ZendeskAuthorizationMethodEnum } from 'Generated/schema';
import EditIntegrationActions from 'Pages/Integrations/components/EditIntegrationActions';
import OAuthPullerCredentialsFields from 'Pages/Integrations/components/OAuthPullerCredentialsFields';

const CredentialsPanel: React.FC = () => {
  const { goToNextStep } = useWizardContext();
  const { values, initialValues, isValid } = useFormikContext<ZendeskLogsSourceWizardValues>();

  const isCredentialOAuth2 = values.authorizationMethod === ZendeskAuthorizationMethodEnum.Oauth2;
  const isCredentialBasic = values.authorizationMethod === ZendeskAuthorizationMethodEnum.Basic;
  const isCredentialApi = values.authorizationMethod === ZendeskAuthorizationMethodEnum.ApiToken;

  const clientSecretExists = Boolean(
    initialValues.integrationId &&
      initialValues.authorizationMethod === ZendeskAuthorizationMethodEnum.Oauth2
  );

  const passwordExists = Boolean(
    initialValues.integrationId &&
      initialValues.authorizationMethod === ZendeskAuthorizationMethodEnum.Basic
  );

  const tokenExists = Boolean(
    initialValues.integrationId &&
      initialValues.authorizationMethod === ZendeskAuthorizationMethodEnum.ApiToken
  );

  const editMode = !!initialValues.integrationId;

  return (
    <WizardPanel>
      <Box width={660} m="auto">
        <WizardPanel.Heading title="Set up the source's credentials" divider={false} />
        <ErrorBoundary>
          <Flex spacing={4} direction="column">
            <Card variant="dark" p={4}>
              <FastField
                as={FormikRadio}
                name="authorizationMethod"
                value={ZendeskAuthorizationMethodEnum.Oauth2}
                label="Use OAuth2 Authorization Flow"
                aria-describedby="oauth2-helper"
              />
              {isCredentialOAuth2 && (
                <Box mx={44}>
                  <OAuthPullerCredentialsFields shouldMaskSecret={clientSecretExists} />
                </Box>
              )}
            </Card>
            <Card variant="dark" p={4}>
              <FastField
                as={FormikRadio}
                name="authorizationMethod"
                value={ZendeskAuthorizationMethodEnum.Basic}
                label="Use Email and Password"
              />
              {isCredentialBasic && (
                <Box my={4} mx={44}>
                  <Flex direction="column" spacing={4} my={4}>
                    <Field
                      label="Email"
                      name="email"
                      as={FormikTextInput}
                      placeholder="Your Zendesk email"
                      required
                    />
                    <Field
                      label="Password"
                      name="password"
                      shouldMask={passwordExists}
                      as={SensitiveTextInput}
                      placeholder="Your Zendesk user's password"
                      type="password"
                      required
                      aria-describedby="credentials-helper"
                    />
                  </Flex>
                  <FormHelperText
                    id="credentials-helper"
                    color="white-100"
                    textAlign="center"
                    mt={6}
                  >
                    Need help? Check
                    <Link external href={SAAS_ONBOARDING_ZENDESK_DOC_URL} ml={1}>
                      our documentation
                    </Link>
                  </FormHelperText>
                </Box>
              )}
            </Card>
            <Card variant="dark" p={4}>
              <FastField
                as={FormikRadio}
                name="authorizationMethod"
                value={ZendeskAuthorizationMethodEnum.ApiToken}
                label="Use API authentication"
              />
              {isCredentialApi && (
                <Box my={4} mx={44}>
                  <Flex direction="column" spacing={4} my={4}>
                    <Field
                      label="Email"
                      name="email"
                      as={FormikTextInput}
                      placeholder="Your Zendesk email"
                      required
                    />
                    <Field
                      label="API Token"
                      name="apiToken"
                      shouldMask={tokenExists}
                      as={SensitiveTextInput}
                      placeholder="Your Zendesk API token"
                      type="password"
                      required
                      aria-describedby="credentials-helper"
                    />
                  </Flex>
                  <FormHelperText
                    id="credentials-helper"
                    color="white-100"
                    textAlign="center"
                    mt={6}
                  >
                    Need help? Check
                    <Link external href={SAAS_ONBOARDING_ZENDESK_DOC_URL} ml={1}>
                      our documentation
                    </Link>
                  </FormHelperText>
                </Box>
              )}
            </Card>
          </Flex>
        </ErrorBoundary>
      </Box>
      {editMode ? (
        <EditIntegrationActions />
      ) : (
        <WizardPanel.Actions>
          <Button onClick={goToNextStep} disabled={!isValid}>
            Continue Setup
          </Button>
        </WizardPanel.Actions>
      )}
    </WizardPanel>
  );
};

export default CredentialsPanel;
