/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import * as Types from '../../../../__generated__/schema';

import { DataSchemaFull } from '../../../graphql/fragments/DataSchemaFull.generated';
import { GraphQLError } from 'graphql';
import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';

export type GetSchemaDetailsVariables = {
  input: Types.GetSchemaInput;
};

export type GetSchemaDetails = {
  getSchema: {
    error?: Types.Maybe<Pick<Types.Error, 'code' | 'message'>>;
    record?: Types.Maybe<DataSchemaFull>;
  };
};

export const GetSchemaDetailsDocument = gql`
  query GetSchemaDetails($input: GetSchemaInput!) {
    getSchema(input: $input) {
      error {
        code
        message
      }
      record {
        ...DataSchemaFull
      }
    }
  }
  ${DataSchemaFull}
`;

/**
 * __useGetSchemaDetails__
 *
 * To run a query within a React component, call `useGetSchemaDetails` and pass it any options that fit your needs.
 * When your component renders, `useGetSchemaDetails` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSchemaDetails({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetSchemaDetails(
  baseOptions?: ApolloReactHooks.QueryHookOptions<GetSchemaDetails, GetSchemaDetailsVariables>
) {
  return ApolloReactHooks.useQuery<GetSchemaDetails, GetSchemaDetailsVariables>(
    GetSchemaDetailsDocument,
    baseOptions
  );
}
export function useGetSchemaDetailsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSchemaDetails, GetSchemaDetailsVariables>
) {
  return ApolloReactHooks.useLazyQuery<GetSchemaDetails, GetSchemaDetailsVariables>(
    GetSchemaDetailsDocument,
    baseOptions
  );
}
export type GetSchemaDetailsHookResult = ReturnType<typeof useGetSchemaDetails>;
export type GetSchemaDetailsLazyQueryHookResult = ReturnType<typeof useGetSchemaDetailsLazyQuery>;
export type GetSchemaDetailsQueryResult = ApolloReactCommon.QueryResult<
  GetSchemaDetails,
  GetSchemaDetailsVariables
>;
export function mockGetSchemaDetails({
  data,
  variables,
  errors,
}: {
  data: GetSchemaDetails;
  variables?: GetSchemaDetailsVariables;
  errors?: GraphQLError[];
}) {
  return {
    request: { query: GetSchemaDetailsDocument, variables },
    result: { data, errors },
  };
}
