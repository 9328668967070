/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import * as Types from '../../../../../../../__generated__/schema';

import { ZendeskLogIntegrationDetails } from '../../../../../../graphql/fragments/ZendeskLogIntegrationDetails.generated';
import { GraphQLError } from 'graphql';
import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';

export type UpdateZendeskLogSourceVariables = {
  input: Types.UpdateLogPullingIntegrationInput;
};

export type UpdateZendeskLogSource = { updateLogPullingIntegration: ZendeskLogIntegrationDetails };

export const UpdateZendeskLogSourceDocument = gql`
  mutation UpdateZendeskLogSource($input: UpdateLogPullingIntegrationInput!) {
    updateLogPullingIntegration(input: $input) {
      ...ZendeskLogIntegrationDetails
    }
  }
  ${ZendeskLogIntegrationDetails}
`;
export type UpdateZendeskLogSourceMutationFn = ApolloReactCommon.MutationFunction<
  UpdateZendeskLogSource,
  UpdateZendeskLogSourceVariables
>;

/**
 * __useUpdateZendeskLogSource__
 *
 * To run a mutation, you first call `useUpdateZendeskLogSource` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateZendeskLogSource` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateZendeskLogSource, { data, loading, error }] = useUpdateZendeskLogSource({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateZendeskLogSource(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateZendeskLogSource,
    UpdateZendeskLogSourceVariables
  >
) {
  return ApolloReactHooks.useMutation<UpdateZendeskLogSource, UpdateZendeskLogSourceVariables>(
    UpdateZendeskLogSourceDocument,
    baseOptions
  );
}
export type UpdateZendeskLogSourceHookResult = ReturnType<typeof useUpdateZendeskLogSource>;
export type UpdateZendeskLogSourceMutationResult = ApolloReactCommon.MutationResult<
  UpdateZendeskLogSource
>;
export type UpdateZendeskLogSourceMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateZendeskLogSource,
  UpdateZendeskLogSourceVariables
>;
export function mockUpdateZendeskLogSource({
  data,
  variables,
  errors,
}: {
  data: UpdateZendeskLogSource;
  variables?: UpdateZendeskLogSourceVariables;
  errors?: GraphQLError[];
}) {
  return {
    request: { query: UpdateZendeskLogSourceDocument, variables },
    result: { data, errors },
  };
}
