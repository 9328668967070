/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import useFormSessionRestoration, {
  UseFormSessionRestorationProps,
} from 'Hooks/useFormSessionRestoration';

type FormSessionRestorationProps = UseFormSessionRestorationProps;

const FormSessionRestoration: React.FC<FormSessionRestorationProps> = ({ children, ...rest }) => {
  const { clearFormSession } = useFormSessionRestoration(rest);

  return typeof children === 'function'
    ? children({ clearFormSession })
    : (children as React.ReactElement);
};

export default FormSessionRestoration;
