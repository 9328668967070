/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import { Permission } from 'Generated/schema';
import useAuth from 'Hooks/useAuth';
import { expandPermissions } from 'Helpers/utils';

const useCheckPermissions = (
  permissions: Permission | Permission[],
  logic: 'AND' | 'OR' = 'OR'
) => {
  const { userInfo } = useAuth();
  if (!userInfo) {
    return false;
  }

  const permissionsArray = Array.isArray(permissions) ? permissions : [permissions];
  if (!permissionsArray.length) {
    return false;
  }

  // If a user has xxxModify permissions, they implicitly have xxxRead permissions for a given
  // permission-attribute. Here, we add the xxxRead permission to a temporary list if they have an
  // associated xxxModify permission.
  const implicitUserPermissions = expandPermissions(userInfo.permissions ?? []);

  if (logic === 'OR') {
    return permissionsArray.some(permission => implicitUserPermissions.includes(permission));
  }

  if (logic === 'AND') {
    return permissionsArray.every(permission => implicitUserPermissions.includes(permission));
  }

  return false;
};

export default useCheckPermissions;
