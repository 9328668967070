/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import * as Types from '../../../../../../../__generated__/schema';

import { GraphQLError } from 'graphql';
import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';

export type ViewRawDataVariables = {
  input: Types.HoldingTankRawDataInput;
};

export type ViewRawData = {
  holdingTankRawData: {
    data: Array<Pick<Types.HoldingTankRawEvent, 's3Key' | 'event'>>;
    pageInfo: Pick<Types.PageInfo, 'hasNextPage' | 'paginationToken'>;
  };
};

export const ViewRawDataDocument = gql`
  query ViewRawData($input: HoldingTankRawDataInput!) {
    holdingTankRawData(input: $input) {
      data {
        s3Key
        event
      }
      pageInfo {
        hasNextPage
        paginationToken
      }
    }
  }
`;

/**
 * __useViewRawData__
 *
 * To run a query within a React component, call `useViewRawData` and pass it any options that fit your needs.
 * When your component renders, `useViewRawData` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useViewRawData({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useViewRawData(
  baseOptions?: ApolloReactHooks.QueryHookOptions<ViewRawData, ViewRawDataVariables>
) {
  return ApolloReactHooks.useQuery<ViewRawData, ViewRawDataVariables>(
    ViewRawDataDocument,
    baseOptions
  );
}
export function useViewRawDataLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ViewRawData, ViewRawDataVariables>
) {
  return ApolloReactHooks.useLazyQuery<ViewRawData, ViewRawDataVariables>(
    ViewRawDataDocument,
    baseOptions
  );
}
export type ViewRawDataHookResult = ReturnType<typeof useViewRawData>;
export type ViewRawDataLazyQueryHookResult = ReturnType<typeof useViewRawDataLazyQuery>;
export type ViewRawDataQueryResult = ApolloReactCommon.QueryResult<
  ViewRawData,
  ViewRawDataVariables
>;
export function mockViewRawData({
  data,
  variables,
  errors,
}: {
  data: ViewRawData;
  variables?: ViewRawDataVariables;
  errors?: GraphQLError[];
}) {
  return {
    request: { query: ViewRawDataDocument, variables },
    result: { data, errors },
  };
}
