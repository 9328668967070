/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import * as Types from '../../../../../__generated__/schema';

import { GraphQLError } from 'graphql';
import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';

export type RunQueryVariables = {
  input: Types.ExecuteDataLakeQueryInput;
};

export type RunQuery = { executeDataLakeQuery: Pick<Types.ExecuteDataLakeQueryOutput, 'id'> };

export const RunQueryDocument = gql`
  mutation RunQuery($input: ExecuteDataLakeQueryInput!) {
    executeDataLakeQuery(input: $input) {
      id
    }
  }
`;
export type RunQueryMutationFn = ApolloReactCommon.MutationFunction<RunQuery, RunQueryVariables>;

/**
 * __useRunQuery__
 *
 * To run a mutation, you first call `useRunQuery` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRunQuery` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [runQuery, { data, loading, error }] = useRunQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRunQuery(
  baseOptions?: ApolloReactHooks.MutationHookOptions<RunQuery, RunQueryVariables>
) {
  return ApolloReactHooks.useMutation<RunQuery, RunQueryVariables>(RunQueryDocument, baseOptions);
}
export type RunQueryHookResult = ReturnType<typeof useRunQuery>;
export type RunQueryMutationResult = ApolloReactCommon.MutationResult<RunQuery>;
export type RunQueryMutationOptions = ApolloReactCommon.BaseMutationOptions<
  RunQuery,
  RunQueryVariables
>;
export function mockRunQuery({
  data,
  variables,
  errors,
}: {
  data: RunQuery;
  variables?: RunQueryVariables;
  errors?: GraphQLError[];
}) {
  return {
    request: { query: RunQueryDocument, variables },
    result: { data, errors },
  };
}
