/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import TablePlaceholder from 'Components/TablePlaceholder';
import Breadcrumbs from 'Components/Breadcrumbs';
import { Flex, Box, FadeIn } from 'pouncejs';
import Panel from 'Components/Panel';

const MitreMappingSkeleton = () => {
  return (
    <FadeIn from="bottom">
      <Breadcrumbs.Actions>
        <Flex spacing={2}>
          <Box
            width={82}
            height={46}
            borderColor="navyblue-400"
            borderStyle="solid"
            borderWidth={1}
            borderRadius="medium"
          />
          <Box
            width={128}
            height={46}
            borderColor="navyblue-400"
            borderStyle="solid"
            borderWidth={1}
            borderRadius="medium"
          />
        </Flex>
      </Breadcrumbs.Actions>

      <Panel title="Map New Detections">
        <Box backgroundColor="navyblue-500" pt={6} px={6}>
          <TablePlaceholder rowHeight={30} />
        </Box>
      </Panel>
    </FadeIn>
  );
};

export default MitreMappingSkeleton;
