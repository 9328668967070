/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Flex, Box, FadeIn, AbstractButton, Icon } from 'pouncejs';
import useHover from 'Hooks/useHover';
import useCopyToClipboard from 'Hooks/useCopyToClipboard';

interface SnowflakeEntryProps {
  label: string;
  value: string;
}

const SettingEntry: React.FC<SnowflakeEntryProps> = ({ label, value }) => {
  const { isHovering, handlers: hoverHandlers } = useHover();
  const copyToClipboard = useCopyToClipboard();

  if (!value) {
    return (
      <Box as="section" p={2}>
        <Box as="dt" fontSize="small-medium" color="navyblue-100" mb={1}>
          {label}
        </Box>
        <Box as="dd" fontSize="small" fontWeight="medium">
          N/A
        </Box>
      </Box>
    );
  }

  return (
    <AbstractButton
      {...hoverHandlers}
      outline="none"
      textAlign="left"
      position="relative"
      borderRadius="medium"
      py={2}
      pl={2}
      pr={50}
      backgroundColor={isHovering ? 'navyblue-500' : 'transparent'}
      transition="background-color 0.1s ease-in-out"
      onClick={() => copyToClipboard(value)}
    >
      <Box as="dt" fontSize="small-medium" color="navyblue-100" mb={1}>
        {label}
      </Box>
      <Box as="dd" fontSize="small" fontWeight="medium" wordBreak="break-word">
        {value}
      </Box>
      {isHovering && (
        <Flex align="center" position="absolute" top={0} bottom={0} my="auto" right={4}>
          <FadeIn from="left" offset={5} duration={300}>
            <Icon size="small" type="copy" aria-label="Copy" />
          </FadeIn>
        </Flex>
      )}
    </AbstractButton>
  );
};

export default React.memo(SettingEntry);
