/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import * as Types from '../../../__generated__/schema';

import { ActorTeaser_User_, ActorTeaser_APIToken_ } from './ActorTeaser.generated';
import { GraphQLError } from 'graphql';
import gql from 'graphql-tag';
import { ActorTeaser } from './ActorTeaser.generated';

export type HoldingTankTestJobDetails = Pick<
  Types.HoldingTankTestJob,
  'id' | 'status' | 'message' | 'createdAt' | 'completedAt'
> & {
  createdBy?: Types.Maybe<ActorTeaser_User_ | ActorTeaser_APIToken_>;
  testParameters: Pick<Types.HoldingTankTestJobParams, 'streamType'> & {
    s3PrefixLogTypes: Array<
      Pick<Types.S3PrefixLogTypes, 'prefix' | 'excludedPrefixes' | 'logTypes'>
    >;
    newSchemas?: Types.Maybe<
      Array<Pick<Types.DraftSchema, 'name' | 'spec' | 'description' | 'referenceURL'>>
    >;
    filters: Pick<Types.DateRange, 'from' | 'to'>;
  };
  stats?: Types.Maybe<Pick<Types.HoldingTankJobStats, 'totalSizeBytes' | 'elapsedTimeSeconds'>>;
  testResults?: Types.Maybe<
    Array<
      Pick<Types.HoldingTankTestJobPrefixStats, 'prefix' | 'unmatched' | 'errored'> & {
        logTypeMatches: Array<Pick<Types.HoldingTankLogTypeTestStats, 'logType' | 'matched'>>;
      }
    >
  >;
};

export const HoldingTankTestJobDetails = gql`
  fragment HoldingTankTestJobDetails on HoldingTankTestJob {
    id
    status
    message
    createdBy {
      ...ActorTeaser
    }
    createdAt
    completedAt
    testParameters {
      streamType
      s3PrefixLogTypes {
        prefix
        excludedPrefixes
        logTypes
      }
      newSchemas {
        name
        spec
        description
        referenceURL
      }
      filters {
        from
        to
      }
    }
    stats {
      totalSizeBytes
      elapsedTimeSeconds
    }
    testResults {
      prefix
      unmatched
      errored
      logTypeMatches {
        logType
        matched
      }
    }
  }
  ${ActorTeaser}
`;
