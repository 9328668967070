/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import * as Yup from 'yup';
import Auth from '@aws-amplify/auth';
import { initializeErrorTracking } from 'Helpers/errors';
import { PANTHER_CONFIG } from 'Source/constants';
import dayjs from 'dayjs';
import relativeTimePlugin from 'dayjs/plugin/relativeTime';
import utc from 'dayjs/plugin/utc';

// Initialize our error tracking library
initializeErrorTracking();

// Extend dayjs to allow to display relative times (i.e. "5 seconds ago")
dayjs.extend(relativeTimePlugin);

// Extend dayjs to allow for better conversions between UTC and local time
dayjs.extend(utc);

// Initialize the Cognito client to the correct user pool
Auth.configure({
  userPoolId: PANTHER_CONFIG.WEB_APPLICATION_USER_POOL_ID,
  userPoolWebClientId: PANTHER_CONFIG.WEB_APPLICATION_USER_POOL_CLIENT_ID,
  region: PANTHER_CONFIG.AWS_REGION,
});

// Set the defaults for some of the pre-implemented yup funcs
Yup.setLocale({
  mixed: {
    required: 'This field is required',
    notType: 'Must have a value',
  },
});

// Add a custom `unique` method on Yup that's gonna validate that an array of items doesn't contain
// duplicates. The duplicates can be entire items or only a certain field (based on the `mapper` param
// that's passed.
Yup.addMethod(Yup.array, 'unique', function method(this, message = 'No duplicates allowed', key) {
  return this.test('unique', message, function testFunc(items) {
    const hasUniqueIntegrity = items.length === new Set(items.map(i => (key ? i[key] : i))).size;
    if (!hasUniqueIntegrity) {
      // if there is a duplicate, create an error on the last item in the array
      return this.createError({ path: `${this.path}[${items.length - 1}].${key}`, message });
    }
    return true;
  });
});

// Add a custom 'integer' method that validates a string is actually a digits only string
Yup.addMethod(Yup.string, 'integer', function method(
  this,
  message = 'The field should have digits only'
) {
  return this.matches(/^\d+$/, message);
});

// Overriding requestIdleCallback because Safari is not supporting it
window.requestIdleCallback = window.requestIdleCallback || (cb => cb());
