/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { RuleSummary } from 'Source/graphql/fragments/RuleSummary.generated';
import { PolicySummary } from 'Source/graphql/fragments/PolicySummary.generated';
import ConfirmModal from 'Components/modals/ConfirmModal';
import { useSnackbar } from 'pouncejs';
import useRouter from 'Hooks/useRouter';
import urls from 'Source/urls';
import { extractErrorMessage, toPlural } from 'Helpers/utils';
import { useDeleteDetections } from './graphql/deleteDetections.generated';

export interface DeleteDetectionsModalProps {
  detections: (RuleSummary | PolicySummary)[];
  onSuccess?: () => void;
}

const DeleteDetectionsModal: React.FC<DeleteDetectionsModalProps> = ({
  detections,
  onSuccess = () => {},
}) => {
  const { location, history } = useRouter<{ id?: string }>();
  const { pushSnackbar } = useSnackbar();

  const isMultiDelete = detections.length > 1;
  const modalDescription = `Are you sure you want to delete ${
    isMultiDelete
      ? `${detections.length} detections`
      : detections[0].displayName || detections[0].id
  }?`;

  const [deleteDetections] = useDeleteDetections({
    variables: {
      input: {
        detections: detections.map(detection => ({ id: detection.id })),
      },
    },
    update: async cache => {
      detections.forEach(detection => {
        cache.evict({ id: cache.identify(detection) });
      });
      cache.gc();
    },
    onCompleted: () => {
      onSuccess();

      pushSnackbar({
        variant: 'success',
        title: `Successfully deleted ${toPlural(
          'detection',
          `${detections.length} detections`,
          detections.length
        )}`,
      });

      if (!isMultiDelete && location.pathname.includes(detections[0].id)) {
        // if we were on the particular detection's details page or edit page --> redirect on delete
        history.push(urls.detections.list());
      }
    },
    onError: error => {
      pushSnackbar({
        variant: 'error',
        title: `Failed to delete ${toPlural(
          'detection',
          `${detections.length} detectioons`,
          detections.length
        )}`,
        description: extractErrorMessage(error),
      });
    },
  });

  return (
    <ConfirmModal variant="destructive" subtitle={modalDescription} onConfirm={deleteDetections} />
  );
};

export default DeleteDetectionsModal;
