/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Dropdown, DropdownButton, DropdownMenu, DropdownItem, DropdownLink } from 'pouncejs';
import useModal from 'Hooks/useModal';
import { ComplianceIntegrationDetails } from 'Source/graphql/fragments/ComplianceIntegrationDetails.generated';
import DeleteComplianceSourceModal from 'Components/modals/DeleteComplianceSourceModal';
import OptionsButton from 'Components/buttons/OptionsButton';
import urls from 'Source/urls';
import { Link as RRLink } from 'react-router-dom';

interface ComplianceSourceCardOptionsProps {
  source: ComplianceIntegrationDetails;
}

const ComplianceSourceCardOptions: React.FC<ComplianceSourceCardOptionsProps> = ({ source }) => {
  const { showModal } = useModal();

  return (
    <Dropdown>
      <DropdownButton as={OptionsButton} />
      <DropdownMenu>
        <DropdownLink as={RRLink} to={urls.integrations.cloudAccounts.edit(source.integrationId)}>
          Edit
        </DropdownLink>
        <DropdownItem
          onSelect={() =>
            showModal(<DeleteComplianceSourceModal source={source} />, {
              title: `Delete ${source?.integrationLabel}`,
            })
          }
        >
          Delete
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  );
};

export default React.memo(ComplianceSourceCardOptions);
