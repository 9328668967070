/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { useSnackbar } from 'pouncejs';
import DataModelForm from 'Components/forms/DataModelForm';
import { extractErrorMessage } from 'Helpers/utils';
import withSEO from 'Hoc/withSEO';
import useModal from 'Hooks/useModal';
import useRouter from 'Hooks/useRouter';
import { compose } from 'Helpers/compose';
import withRoleRestrictedAccess from 'Hoc/withRoleRestrictedAccess';
import { Permission } from 'Generated/schema';
import Page403 from 'Pages/403/403';
import Page404 from 'Pages/404/404';
import DataModelWarningModal from 'Components/modals/DataModelWarningModal';
import { useGetDataModel } from './graphql/getDataModel.generated';
import { useUpdateDataModel } from './graphql/updateDataModel.generated';

const EditDataModel: React.FC = () => {
  const { match } = useRouter<{ id: string }>(); // prettier-ignore
  const { pushSnackbar } = useSnackbar();
  const { showModal } = useModal();
  const { data, error: getError } = useGetDataModel({ variables: { id: match.params.id } });

  const [updateDataModel] = useUpdateDataModel({
    onCompleted: () => {
      showModal(
        <DataModelWarningModal subtitle="The changes you've made may take up to 5 minutes to be accessible in the rules engine." />,
        {
          title: 'Data Model successfully updated',
        }
      );
    },
    onError: error => {
      pushSnackbar({
        variant: 'error',
        title: extractErrorMessage(error),
      });
    },
  });

  const initialValues = React.useMemo(
    () => ({
      id: match.params.id,
      displayName: data?.getDataModel.displayName ?? '',
      logType: data?.getDataModel.logTypes?.[0] ?? null,
      enabled: data?.getDataModel.enabled ?? true,
      mappings: data?.getDataModel.mappings ?? [{ name: '', method: '', path: '' }],
      body: data?.getDataModel.body ?? '',
      managed: data?.getDataModel.managed ?? false,
      parentId: data?.getDataModel.parentId ?? '',
    }),
    [data?.getDataModel, match.params.id]
  );

  // we optimistically assume that an error in "get" is a 404. We don't have any other info
  if (getError) {
    return <Page404 />;
  }
  return (
    <DataModelForm
      initialValues={initialValues}
      onSubmit={values =>
        updateDataModel({
          variables: {
            input: {
              id: values.id,
              displayName: values.displayName,
              logTypes: [values.logType],
              enabled: values.enabled,
              mappings: values.mappings,
              body: values.body,
              managed: values.managed,
              parentId: values.parentId,
            },
          },
        })
      }
    />
  );
};

export default compose(
  withSEO({ title: ({ match }) => `Update ${match.params.id}` }),
  withRoleRestrictedAccess({
    allowedPermissions: [Permission.LogSourceModify],
    fallback: <Page403 />,
  })
)(EditDataModel);
