/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import * as Types from '../../../__generated__/schema';

import { AlertDeliveryFull } from '../fragments/AlertDeliveryFull.generated';
import { GraphQLError } from 'graphql';
import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';

export type SendTestAlertVariables = {
  input: Types.SendTestAlertInput;
};

export type SendTestAlert = { sendTestAlert: Array<Types.Maybe<AlertDeliveryFull>> };

export const SendTestAlertDocument = gql`
  query SendTestAlert($input: SendTestAlertInput!) {
    sendTestAlert(input: $input) {
      ...AlertDeliveryFull
    }
  }
  ${AlertDeliveryFull}
`;

/**
 * __useSendTestAlert__
 *
 * To run a query within a React component, call `useSendTestAlert` and pass it any options that fit your needs.
 * When your component renders, `useSendTestAlert` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSendTestAlert({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSendTestAlert(
  baseOptions?: ApolloReactHooks.QueryHookOptions<SendTestAlert, SendTestAlertVariables>
) {
  return ApolloReactHooks.useQuery<SendTestAlert, SendTestAlertVariables>(
    SendTestAlertDocument,
    baseOptions
  );
}
export function useSendTestAlertLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SendTestAlert, SendTestAlertVariables>
) {
  return ApolloReactHooks.useLazyQuery<SendTestAlert, SendTestAlertVariables>(
    SendTestAlertDocument,
    baseOptions
  );
}
export type SendTestAlertHookResult = ReturnType<typeof useSendTestAlert>;
export type SendTestAlertLazyQueryHookResult = ReturnType<typeof useSendTestAlertLazyQuery>;
export type SendTestAlertQueryResult = ApolloReactCommon.QueryResult<
  SendTestAlert,
  SendTestAlertVariables
>;
export function mockSendTestAlert({
  data,
  variables,
  errors,
}: {
  data: SendTestAlert;
  variables?: SendTestAlertVariables;
  errors?: GraphQLError[];
}) {
  return {
    request: { query: SendTestAlertDocument, variables },
    result: { data, errors },
  };
}
