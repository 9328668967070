/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Box, Card, Flex, Link, SimpleGrid, Text } from 'pouncejs';
import Linkify from 'Components/Linkify';
import { Link as RRLink } from 'react-router-dom';
import urls from 'Source/urls';
import { AlertOriginRule } from 'Generated/schema';
import { formatDatetime, formatNumber, minutesToString } from 'Helpers/utils';
import { AlertDetails } from 'Pages/AlertDetails';
import AlertDeliverySection from 'Pages/AlertDetails/common/AlertDeliverySection';
import RelatedDestinations from 'Components/RelatedDestinations';
import useAlertDestinations from 'Hooks/useAlertDestinations';
import { GetRuleSummary } from '../graphql/getRuleSummary.generated';

interface RuleAlertDetailsInfoProps {
  alert: AlertDetails['alert'];
  rule?: GetRuleSummary['rule'];
}

const RuleAlertDetailsInfo: React.FC<RuleAlertDetailsInfoProps> = ({ alert, rule }) => {
  const { alertDestinations, loading: loadingDestinations } = useAlertDestinations({ alert });

  const originData = alert.origin as AlertOriginRule;
  return (
    <Flex direction="column" spacing={4}>
      <Card variant="dark" as="section" p={4}>
        {alert.description && <Text mb={6}>{alert.description}</Text>}
        <SimpleGrid columns={2} spacing={5}>
          <Flex direction="column" spacing={2}>
            <Box
              color="navyblue-100"
              fontSize="small-medium"
              aria-describedby="runbook-description"
            >
              Runbook
            </Box>
            {alert.runbook ? (
              <Linkify id="runbook-description">{alert.runbook}</Linkify>
            ) : (
              <Box fontStyle="italic" color="navyblue-100" id="runbook-description">
                No runbook specified
              </Box>
            )}
          </Flex>
          <Flex direction="column" spacing={2}>
            <Box
              color="navyblue-100"
              fontSize="small-medium"
              aria-describedby="reference-description"
            >
              Reference
            </Box>
            {alert.reference ? (
              <Linkify id="reference-description">{alert.reference}</Linkify>
            ) : (
              <Box fontStyle="italic" color="navyblue-100" id="reference-description">
                No reference specified
              </Box>
            )}
          </Flex>
        </SimpleGrid>
      </Card>
      <Card variant="dark" as="section" p={4}>
        <SimpleGrid columns={2} spacing={5} fontSize="small-medium">
          <Box>
            <SimpleGrid gap={2} columns={8} spacing={2}>
              {rule ? (
                <>
                  <Box color="navyblue-100" gridColumn="1/3" aria-describedby="rule-link">
                    Rule
                  </Box>

                  <Link
                    id="rule-link"
                    gridColumn="3/8"
                    as={RRLink}
                    data-tid="link-to-rule-from-alert-details"
                    to={urls.analysis.rules.details(rule.id)}
                  >
                    {rule.displayName || rule.id}
                  </Link>

                  <Box color="navyblue-100" gridColumn="1/3" aria-describedby="threshold">
                    Rule Threshold
                  </Box>

                  <Box id="threshold" gridColumn="3/8">
                    {formatNumber(rule.threshold)}
                  </Box>

                  <Box
                    color="navyblue-100"
                    gridColumn="1/3"
                    aria-describedby="deduplication-period"
                  >
                    Deduplication Period
                  </Box>

                  <Box id="deduplication-period" gridColumn="3/8">
                    {rule.dedupPeriodMinutes
                      ? minutesToString(rule.dedupPeriodMinutes)
                      : 'Not specified'}
                  </Box>

                  <Box
                    color="navyblue-100"
                    gridColumn="1/3"
                    aria-describedby="deduplication-string"
                  >
                    Deduplication String
                  </Box>

                  <Box id="deduplication-string" gridColumn="3/8">
                    {originData.dedupString}
                  </Box>

                  <Box color="navyblue-100" gridColumn="1/3" aria-describedby="tags-list">
                    Tags
                  </Box>

                  {rule.tags.length > 0 ? (
                    <Box id="tags-list" gridColumn="3/8">
                      {rule.tags.map((tag, index) => (
                        <Link
                          key={tag}
                          as={RRLink}
                          to={`${urls.detections.list()}?page=1&tags[]=${tag}`}
                          data-tid="link-to-detection-tag"
                        >
                          {tag}
                          {index !== rule.tags.length - 1 ? ', ' : null}
                        </Link>
                      ))}
                    </Box>
                  ) : (
                    <Box fontStyle="italic" color="navyblue-100" id="tags-list" gridColumn="3/8">
                      This rule has no tags
                    </Box>
                  )}
                </>
              ) : (
                <>
                  <Box color="navyblue-100" gridColumn="1/3" aria-describedby="rule-link">
                    Rule
                  </Box>
                  <Box gridColumn="3/8" color="red-300">
                    The associated rule has been deleted
                  </Box>

                  <Box
                    color="navyblue-100"
                    gridColumn="1/3"
                    aria-describedby="deduplication-string"
                  >
                    Deduplication String
                  </Box>
                  <Box gridColumn="3/8" id="deduplication-string">
                    {originData.dedupString}
                  </Box>
                </>
              )}
            </SimpleGrid>
          </Box>

          <Box>
            <SimpleGrid gap={2} columns={8} spacing={2}>
              <Box color="navyblue-100" gridColumn="1/3" aria-describedby="created-at">
                Created
              </Box>

              <Box id="created-at" gridColumn="3/8">
                {formatDatetime(alert.createdAt)}
              </Box>

              <Box color="navyblue-100" gridColumn="1/3" aria-describedby="last-matched-at">
                Last Matched
              </Box>
              <Box gridColumn="3/8" id="last-matched-at">
                {formatDatetime(alert.lastReceivedEventAt)}
              </Box>

              <Box color="navyblue-100" gridColumn="1/3" aria-describedby="destinations">
                Destinations
              </Box>

              <Box id="destinations" gridColumn="3/8">
                <RelatedDestinations
                  destinations={alertDestinations}
                  loading={loadingDestinations}
                  limit={5}
                  verbose
                />
              </Box>
            </SimpleGrid>
          </Box>
        </SimpleGrid>
      </Card>
      {alert.deliveryOverflow && (
        <Text
          p={4}
          mb={4}
          borderRadius="large"
          textAlign="center"
          backgroundColor="pink-700"
          fontSize="medium"
        >
          Delivery response information is no longer being recorded for this alert due to size
          limitations
        </Text>
      )}
      <Card variant="dark" as="section" p={4}>
        <AlertDeliverySection alert={alert} />
      </Card>
    </Flex>
  );
};

export default RuleAlertDetailsInfo;
