/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import { ReplayFull } from 'Source/graphql/fragments/ReplayFull.generated';
import React from 'react';
import { useGetReplayPreviewLazyQuery } from 'Source/graphql/queries/getReplayPreview.generated';
import { Box } from 'pouncejs';
import {
  ReplayDataFormValues,
  formValuesValidation,
} from 'Components/forms/ReplayForm/ReplayEditor/ReplayEditor';
import ReplayEditorPreview from '../ReplayEditorPreview';
import ReplayEditorAction from '../ReplayEditorAction';

type ReplayEditorPreviewAndActionProps = {
  onStop: () => void;
  values: ReplayDataFormValues;
  isValid: boolean;
  onCreate: () => void;
  currentReplay: ReplayFull;
  globalDisabled: boolean;
};

const replaySizeLimitBytes = 20 * 1024 * 1024 * 1024; // 20 GB

const ReplayEditorPreviewAndAction: React.FC<ReplayEditorPreviewAndActionProps> = ({
  onStop,
  values,
  isValid,
  onCreate,
  currentReplay,
  globalDisabled,
}) => {
  const [
    getReplayPreview,
    { data: previewData, loading: previewLoading, error: previewError },
  ] = useGetReplayPreviewLazyQuery();

  React.useEffect(() => {
    const previewValid = formValuesValidation.isValidSync(values);
    if (previewValid) {
      getReplayPreview({
        variables: {
          endsAt: values.endsAt,
          startsAt: values.startsAt,
          logTypes: values.logTypes,
        },
      });
    }
  }, [getReplayPreview, values, values.endsAt, values.startsAt, values.logTypes]);

  const preview = previewData?.replayPreview;
  const previewDataAboveZero = (previewData?.replayPreview.logDataSizeEstimate ?? 0) > 0;
  const previewDataAtOrBelowLimit =
    (previewData?.replayPreview.logDataSizeEstimate ?? 0) <= replaySizeLimitBytes;
  const validPreviewDataEstimate = previewDataAboveZero && previewDataAtOrBelowLimit;

  return (
    <>
      <Box pl={2} ml={'auto'}>
        <ReplayEditorPreview
          preview={preview}
          loading={previewLoading}
          hasError={Boolean(previewError)}
        />
      </Box>
      <ReplayEditorAction
        onStop={onStop}
        isValid={isValid}
        onCreate={onCreate}
        disabled={globalDisabled || !validPreviewDataEstimate}
        currentReplay={currentReplay}
      />
    </>
  );
};

export default ReplayEditorPreviewAndAction;
