/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Link as RRLink } from 'react-router-dom';
import { Dropdown, DropdownButton, DropdownItem, DropdownLink, DropdownMenu } from 'pouncejs';

import OptionsButton from 'Components/buttons/OptionsButton';
import { PolicySummary } from 'Source/graphql/fragments/PolicySummary.generated';
import urls from 'Source/urls';
import useDeleteDetectionModal from 'Hooks/useDeleteDetectionModal';
import useClonePolicy from 'Hooks/useClonePolicy';

interface PolicyCardOptionsProps {
  policy: PolicySummary;
  onDelete?: (id: string) => void;
}

const PolicyCardOptions: React.FC<PolicyCardOptionsProps> = ({ policy, onDelete = () => {} }) => {
  const showDeleteDetectionsModal = useDeleteDetectionModal();
  const { clonePolicy, isCloning } = useClonePolicy();

  return (
    <Dropdown>
      <DropdownButton
        as={OptionsButton}
        data-tid="detection-options-dropdown"
        aria-label="Toggle Policy Options"
      />
      <DropdownMenu>
        {policy.managed && (
          <DropdownItem onSelect={() => clonePolicy(policy.id)}>
            {isCloning ? 'Cloning...' : 'Clone and edit'}
          </DropdownItem>
        )}
        <DropdownLink as={RRLink} to={urls.analysis.policies.edit(policy.id)}>
          Edit
        </DropdownLink>
        <DropdownItem
          onSelect={() => showDeleteDetectionsModal([policy], () => onDelete(policy.id))}
        >
          Delete
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  );
};

export default React.memo(PolicyCardOptions);
