/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { AbstractButton, Box, Flex, Text, Theme, Tooltip, useSnackbar } from 'pouncejs';
import { MitreMatrixGridCharm } from 'Pages/Reports/MitreMatrix/MitreMatrixGrid';
import { ReportOverrideSetting } from 'Generated/schema';
import { extractErrorMessage } from 'Helpers/utils';
import useAuth from 'Hooks/useAuth';
import { useUpdateMitreReportSettings } from '../graphql/updateMitreReportSettings.generated';
import {
  MitreContextValue,
  MitreMatrixCoverageStatus,
  pickTacticTechniqueStatus,
  reportSettingHasRelations,
  useMitreContext,
} from '../MitreContext';

const getOverrideForTransition = (
  setting: MitreContextValue['activeReportSetting'],
  target: MitreMatrixCoverageStatus
): ReportOverrideSetting => {
  switch (target) {
    case 'covered':
      return ReportOverrideSetting.Covered;
    case 'notCovered':
      // if the "natural" state is not covered, remove the override
      return reportSettingHasRelations(setting)
        ? ReportOverrideSetting.NotCovered
        : ReportOverrideSetting.None;
    case 'notRelevant':
      return ReportOverrideSetting.Ignored;
    case 'partiallyCovered':
      return ReportOverrideSetting.None;
    case 'error':
    default:
      return setting.override; // default to echoing back current
  }
};

const StatusDisplayText: { [key in MitreMatrixCoverageStatus]: string } = {
  error: 'Error',
  covered: 'Covered',
  notCovered: 'Not Covered',
  notRelevant: 'Not Relevant',
  partiallyCovered: 'Partially Covered',
};

const MitreRelationsStatusControl = () => {
  const { userInfo } = useAuth();
  const { pushSnackbar } = useSnackbar();
  const { activeReportSetting: setting } = useMitreContext();
  const [updateSettings] = useUpdateMitreReportSettings();

  const hasRelations = React.useMemo(() => reportSettingHasRelations(setting), [setting]);
  const currentStatus = React.useMemo(() => pickTacticTechniqueStatus(setting), [setting]);

  const handleChange = React.useCallback(
    (status: MitreMatrixCoverageStatus) => {
      const targetOverride = getOverrideForTransition(setting, status);
      if (targetOverride === setting.override) {
        return;
      }

      updateSettings({
        variables: {
          input: {
            ...setting.association.mitre,
            note: '',
            override: targetOverride,
          },
        },
        optimisticResponse: {
          updateMitreReportSettings: {
            id: setting.id,
            note: '',
            override: targetOverride,
            updatedAt: new Date().toISOString(),
            updatedBy: {
              id: userInfo.id,
              email: userInfo.email,
              givenName: userInfo.givenName,
              familyName: userInfo.familyName,
            },
          },
        },
        onError: error => {
          pushSnackbar({
            variant: 'error',
            title: `Failed to report settings`,
            description: extractErrorMessage(error),
          });
        },
      });
    },
    [updateSettings, setting, pushSnackbar, userInfo]
  );

  return (
    <Flex alignItems="center">
      <Box pr={6}>
        <Text color="navyblue-100" fontSize="small">
          Status
        </Text>
        <Text fontSize="medium">{StatusDisplayText[currentStatus]}</Text>
      </Box>
      <Flex py={1}>
        <MitreRelationsStatusControlItem
          active={currentStatus === 'covered'}
          status="covered"
          onSelect={handleChange}
          disabled={!hasRelations}
        />
        <MitreRelationsStatusControlItem
          active={currentStatus === 'partiallyCovered'}
          status="partiallyCovered"
          onSelect={handleChange}
          disabled={!hasRelations}
        />
        <MitreRelationsStatusControlItem
          active={currentStatus === 'notRelevant'}
          status="notRelevant"
          onSelect={handleChange}
        />
        <MitreRelationsStatusControlItem
          active={currentStatus === 'notCovered'}
          status="notCovered"
          onSelect={handleChange}
        />
      </Flex>
    </Flex>
  );
};

type ButtonDynamicProps = {
  cursor: 'default' | 'pointer';
  onClick?: () => void;
  opacity: number;
  borderColor: keyof Theme['colors'];
};

const popoverPosition = (triggerRect, tooltipRect) => {
  const triggerCenter = triggerRect.left + triggerRect.width / 2;
  const left = triggerCenter - tooltipRect.width / 2;
  const maxLeft = window.innerWidth - tooltipRect.width - 2;
  return {
    left: Math.min(Math.max(2, left), maxLeft) + window.scrollX,
    top: triggerRect.top - tooltipRect.height,
  };
};

const MitreRelationsStatusControlItem = ({
  active,
  status,
  onSelect,
  disabled = false,
}: {
  active: boolean;
  status: MitreMatrixCoverageStatus;
  onSelect: (status: MitreMatrixCoverageStatus) => void;
  disabled?: boolean;
}) => {
  const buttonProps = React.useMemo<ButtonDynamicProps>(() => {
    if (disabled) {
      return {
        cursor: 'default',
        opacity: 0.6,
        borderColor: 'transparent',
      };
    }
    if (active) {
      return {
        cursor: 'pointer',
        opacity: 1,
        borderColor: 'blue-300',
      };
    }
    return {
      cursor: 'pointer',
      opacity: 1,
      onClick: () => onSelect(status),
      borderColor: 'transparent',
    };
  }, [disabled, active, onSelect, status]);

  const tooltipContent = React.useMemo<string>(() => {
    if (disabled) {
      switch (status) {
        case 'covered':
          return 'Map at least 1 detection to mark as covered.';
        case 'partiallyCovered':
          return 'Map at least 1 detection to mark as partially covered.';
        default:
          return 'Unexpected error';
      }
    }

    return StatusDisplayText[status];
  }, [disabled, status]);

  return (
    <Tooltip content={<Text fontSize="medium">{tooltipContent}</Text>} position={popoverPosition}>
      <AbstractButton
        {...buttonProps}
        padding={1}
        borderStyle="solid"
        borderWidth={3}
        borderRadius="large"
      >
        <MitreMatrixGridCharm status={status} />
      </AbstractButton>
    </Tooltip>
  );
};

export default React.memo(MitreRelationsStatusControl);
