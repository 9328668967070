/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Box, HeadingProps, Tab } from 'pouncejs';

/**
 * These props are automatically passed by `TabList` and not by the developer. At the export level
 * of this component, we "hide" them from the developer by exporting this component `as React.FC`
 */
interface PrivateButtonTabProps {
  /** Whether the tab is selected */
  isSelected: boolean;

  /** Whether the tab is focused */
  isFocused: boolean;
}

const selectedColor = 'navyblue-100';
const focusedColor = 'navyblue-300';

const ButtonTab: React.FC<PrivateButtonTabProps> = ({
  isSelected,
  isFocused,
  children,
  ...rest
}) => {
  let borderColor: HeadingProps['borderColor'];
  let backgroundColor: HeadingProps['backgroundColor'];
  if (isSelected) {
    backgroundColor = selectedColor;
    borderColor = 'transparent';
  } else {
    borderColor = focusedColor;
  }

  return (
    <Tab {...rest}>
      <Box
        fontSize="small"
        as="p"
        border="1px solid"
        zIndex={5}
        px={4}
        py={1}
        transition="border-color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms"
        borderColor={borderColor}
        backgroundColor={backgroundColor}
        _hover={{
          borderColor: !isSelected ? selectedColor : undefined,
        }}
      >
        {children}
      </Box>
    </Tab>
  );
};

export default React.memo(ButtonTab) as React.FC;
