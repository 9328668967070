/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { useSnackbar } from 'pouncejs';
import { compose } from 'Helpers/compose';
import Page404 from 'Pages/404';
import useRouter from 'Hooks/useRouter';
import withRoleRestrictedAccess from 'Hoc/withRoleRestrictedAccess';
import { Permission } from 'Generated/schema';
import Page403 from 'Pages/403';
import withSEO from 'Hoc/withSEO';
import { extractErrorMessage } from 'Helpers/utils';
import urls from 'Source/urls';
import ComplianceSourceWizard from '../ComplianceSourceWizard';
import { useGetComplianceSource } from './graphql/getComplianceSource.generated';
import { useUpdateComplianceSource } from './graphql/updateComplianceSource.generated';
import { ComplianceSourceWizardValues } from '../ComplianceSourceWizard/ComplianceSourceWizard';

const EditComplianceSource: React.FC = () => {
  const { pushSnackbar } = useSnackbar();
  const { match, history } = useRouter<{ id: string }>();

  const { data, error: getError } = useGetComplianceSource({
    variables: { id: match.params.id },
    onError: error => {
      pushSnackbar({
        title: extractErrorMessage(error) || 'An unknown error occurred',
        variant: 'error',
      });
    },
  });

  // If the source is not editable fallback to the source details page
  React.useEffect(() => {
    if (data?.getComplianceIntegration && !data.getComplianceIntegration.isEditable) {
      // Todo update the url to the cloud accounts details page once it is ready
      history.push(urls.integrations.cloudAccounts.list());
    }
  }, [data, history, match]);

  const [updateComplianceSource] = useUpdateComplianceSource();

  const initialValues = React.useMemo(
    () => ({
      integrationId: match.params.id,
      iamRoleExists: true,
      integrationLabel: data?.getComplianceIntegration.integrationLabel ?? 'Loading...',
      awsAccountId: data?.getComplianceIntegration.awsAccountId ?? 'Loading...',
      regionIgnoreList: data?.getComplianceIntegration.regionIgnoreList ?? [],
      resourceTypeIgnoreList: data?.getComplianceIntegration.resourceTypeIgnoreList ?? [],
      resourceRegexIgnoreList: data?.getComplianceIntegration.resourceRegexIgnoreList ?? [],
      auditRole: data?.getComplianceIntegration?.awsScanConfig?.auditRole ?? '',
    }),
    // FIXME: look into missing hook dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data]
  );

  const handleSubmit = React.useCallback(
    async (values: ComplianceSourceWizardValues) => {
      try {
        const resp = await updateComplianceSource({
          variables: {
            input: {
              integrationId: values.integrationId,
              integrationLabel: values.integrationLabel,
              regionIgnoreList: values.regionIgnoreList,
              resourceTypeIgnoreList: values.resourceTypeIgnoreList,
              resourceRegexIgnoreList: values.resourceRegexIgnoreList,
              awsScanConfig: { auditRole: values.auditRole },
            },
          },
        });
        return resp.data.updateComplianceIntegration;
      } catch (err) {
        return err as Error;
      }
    },
    [updateComplianceSource]
  );

  // we optimistically assume that an error in "get" is a 404. We don't have any other info
  if (getError) {
    return <Page404 />;
  }

  return <ComplianceSourceWizard initialValues={initialValues} onSubmit={handleSubmit} />;
};

export default compose(
  withSEO({ title: 'Edit Cloud Account' }),
  withRoleRestrictedAccess({
    allowedPermissions: [Permission.CloudsecSourceModify],
    fallback: <Page403 />,
  })
)(EditComplianceSource);
