/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Box, Card, Heading, Text } from 'pouncejs';
import withRoleRestrictedAccess from 'Hoc/withRoleRestrictedAccess';
import { Permission, IndicatorType } from 'Generated/schema';
import Page403 from 'Pages/403';
import { compose } from 'Helpers/compose';
import withSEO from 'Hoc/withSEO';
import dayjs from 'dayjs';
import withAsyncQueryContext from 'Hoc/withAsyncQueryContext';
import useAsyncQueryContext from 'Hooks/useAsyncQueryContext';
import useAsyncQueryExecution from 'Hooks/useAsyncQueryExecution';
import {
  RunIndicatorTimelineQuery,
  RunIndicatorTimelineQueryVariables,
  useRunIndicatorTimelineQuery,
} from 'Source/graphql/queries/runIndicatorTimelineQuery.generated';

import useUrlParams from 'Hooks/useUrlParams';
import IndicatorInputForm, { IndicatorInputFormValues } from './IndicatorInputForm';
import Results from './Results';
import { useGetMetadataForQuery } from './graphql/getMetadataForQuery.generated';
import { validationSchema } from './IndicatorInputForm/IndicatorInputForm';

const initialValues = {
  indicators: [],
  indicatorName: IndicatorType.AutoDetectType,
  startTime: dayjs().utc().startOf('date').format(),
  endTime: dayjs().utc().endOf('date').format(),
};

const IndicatorSearchPage: React.FC = () => {
  const { urlParams } = useUrlParams<IndicatorInputFormValues>();
  const { state: { queryId } } = useAsyncQueryContext(); // prettier-ignore
  const { data: queryMetadata } = useGetMetadataForQuery({
    skip: !queryId,
    variables: { id: queryId },
  });

  const { executeQuery } = useAsyncQueryExecution<
    RunIndicatorTimelineQuery,
    RunIndicatorTimelineQueryVariables
  >({
    mutation: useRunIndicatorTimelineQuery,
    getResponse: data => data.executeIndicatorSearchQuery,
  });

  React.useEffect(() => {
    // If query params are present and valid
    // we want to run the query immediately on mount time
    if (!queryId && validationSchema.isValidSync(urlParams)) {
      executeQuery({ input: urlParams });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = React.useCallback(
    (values: IndicatorInputFormValues) => {
      const queryData = {
        indicatorName: values.indicatorName,
        indicators: values.indicators,
        startTime: dayjs.utc(values.startTime).toISOString(),
        endTime: dayjs.utc(values.endTime).toISOString(),
      };
      return executeQuery({
        input: queryData,
      });
    },
    [executeQuery]
  );

  const indicatorQueryMeta = React.useMemo(() => {
    const values = { ...initialValues, ...urlParams };
    const metadata = queryMetadata?.dataLakeQuery?.metadata;
    return {
      indicators: metadata?.indicators ?? values.indicators,
      startTime: metadata?.startTime ?? values.startTime,
      endTime: metadata?.endTime ?? values.endTime,
      indicatorName: metadata?.indicatorName ?? values.indicatorName,
    };
  }, [queryMetadata, urlParams]);

  return (
    <Card width="100%" mx="auto" p={48} mb={6}>
      <Box width={460} m="auto" textAlign="center">
        <Heading as="h2" fontWeight="bold" mb={6}>
          Quickly Search Indicators
        </Heading>
        <Text fontSize="medium" color="gray-300">
          Enter search indicators of a given type (IP address, domain, etc) to quickly identify hits
          across your stored logs.
        </Text>
      </Box>
      <Box width={900} m="auto">
        <IndicatorInputForm onSubmit={onSubmit} initialValues={indicatorQueryMeta} />
        <Box mt={10}>
          <Results key={queryId} indicatorQueryMeta={indicatorQueryMeta} />
        </Box>
      </Box>
    </Card>
  );
};

export default compose(
  withSEO({ title: 'Indicator Search' }),
  withRoleRestrictedAccess({
    allowedPermissions: [Permission.DataAnalyticsRead],
    fallback: <Page403 />,
  }),
  withAsyncQueryContext({ storage: 'browserHistory' })
)(IndicatorSearchPage);
