/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { AbstractButton, Box, Text } from 'pouncejs';
import useModal from 'Hooks/useModal';
import RequestLogSourceModal from 'Components/modals/RequestLogSourceModal';

const RequestLogSource: React.FC = () => {
  const { showModal } = useModal();
  const handleShowModal = () => {
    showModal(<RequestLogSourceModal />, {
      title: 'Request Log Source',
      showCloseButton: true,
    });
  };

  return (
    <Box width={1} mt={10}>
      <Text color="gray-300" textAlign="center">
        {"Don't see the log source you're looking for?"}
        <AbstractButton color="blue-200" ml={1} onClick={handleShowModal}>
          Request it here
        </AbstractButton>
      </Text>
    </Box>
  );
};

export default RequestLogSource;
