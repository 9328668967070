/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Form, Formik } from 'formik';
import { Flex, IconButton, Tooltip } from 'pouncejs';
import * as Yup from 'yup';
import { FetchResult } from '@apollo/client';
import useAsyncQueryContext from 'Hooks/useAsyncQueryContext';
import useAsyncQueryCancellation from 'Hooks/useAsyncQueryCancellation';
import FormikDateRangeInput from 'Components/fields/DateRangeInput';

const DATE_FORMAT = 'YYYY-MM-DD HH:mm';

export interface IndicatorInputFormValues {
  startTime: string;
  endTime: string;
}

interface IndicatorInputFormProps {
  initialValues: IndicatorInputFormValues;
  onSubmit: (values: IndicatorInputFormValues) => Promise<FetchResult<any>>;
}

const validationSchema: Yup.SchemaOf<IndicatorInputFormValues> = Yup.object().shape({
  startTime: Yup.string().required(),
  endTime: Yup.string().required(),
});

const IndicatorInputForm: React.FC<IndicatorInputFormProps> = ({ initialValues, onSubmit }) => {
  const { state: { queryStatus } } = useAsyncQueryContext(); // prettier-ignore
  const { cancelQuery, isCancelingQuery } = useAsyncQueryCancellation();

  return (
    <Formik<IndicatorInputFormValues>
      validationSchema={validationSchema}
      initialValues={initialValues}
      onSubmit={onSubmit}
    >
      {({ isValid }) => {
        return (
          <Form>
            <Flex spacing={4}>
              <FormikDateRangeInput
                alignment="right"
                withPresets
                withTime
                format={DATE_FORMAT}
                labelStart="Time From (UTC)"
                labelEnd="Time To (UTC)"
                placeholderStart="YYYY-MM-DD HH:mm"
                placeholderEnd="YYYY-MM-DD HH:mm"
                nameStart="startTime"
                nameEnd="endTime"
                useUTC
                disableReset
              />
              {queryStatus === 'running' ? (
                <Tooltip content="Cancel Query">
                  <IconButton
                    aria-label="Cancel Query"
                    disabled={isCancelingQuery}
                    icon="delete"
                    variantColor="pink-700"
                    onClick={cancelQuery}
                    data-tid="indicator-search-modal-input-form-cancel-query"
                  />
                </Tooltip>
              ) : (
                <IconButton
                  type="submit"
                  aria-label="Search"
                  disabled={!isValid || queryStatus === 'provisioning'}
                  icon="search"
                  data-tid="indicator-search-modal-input-form-submit-query"
                />
              )}
            </Flex>
          </Form>
        );
      }}
    </Formik>
  );
};

export default IndicatorInputForm;
