/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import * as Types from '../../../__generated__/schema';

import { GraphQLError } from 'graphql';
import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';

export type GetTerraformTemplateVariables = {
  input: Types.GetTerraformTemplateInput;
};

export type GetTerraformTemplate = {
  getTerraformTemplate: Pick<Types.TerraformTemplateResponse, 'data' | 'stackName'>;
};

export const GetTerraformTemplateDocument = gql`
  query GetTerraformTemplate($input: GetTerraformTemplateInput!) {
    getTerraformTemplate(input: $input) {
      data
      stackName
    }
  }
`;

/**
 * __useGetTerraformTemplate__
 *
 * To run a query within a React component, call `useGetTerraformTemplate` and pass it any options that fit your needs.
 * When your component renders, `useGetTerraformTemplate` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTerraformTemplate({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetTerraformTemplate(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetTerraformTemplate,
    GetTerraformTemplateVariables
  >
) {
  return ApolloReactHooks.useQuery<GetTerraformTemplate, GetTerraformTemplateVariables>(
    GetTerraformTemplateDocument,
    baseOptions
  );
}
export function useGetTerraformTemplateLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetTerraformTemplate,
    GetTerraformTemplateVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<GetTerraformTemplate, GetTerraformTemplateVariables>(
    GetTerraformTemplateDocument,
    baseOptions
  );
}
export type GetTerraformTemplateHookResult = ReturnType<typeof useGetTerraformTemplate>;
export type GetTerraformTemplateLazyQueryHookResult = ReturnType<
  typeof useGetTerraformTemplateLazyQuery
>;
export type GetTerraformTemplateQueryResult = ApolloReactCommon.QueryResult<
  GetTerraformTemplate,
  GetTerraformTemplateVariables
>;
export function mockGetTerraformTemplate({
  data,
  variables,
  errors,
}: {
  data: GetTerraformTemplate;
  variables?: GetTerraformTemplateVariables;
  errors?: GraphQLError[];
}) {
  return {
    request: { query: GetTerraformTemplateDocument, variables },
    result: { data, errors },
  };
}
