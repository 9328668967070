/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import * as Yup from 'yup';
import { AssociatedLogType } from 'Generated/schema';

export interface EnrichedLogTypeFormValues {
  associatedLogTypes: Omit<AssociatedLogType, '__typename'>[];
}

export const lookupFormValidationSchema: Yup.SchemaOf<EnrichedLogTypeFormValues> = Yup.object().shape(
  {
    associatedLogTypes: Yup.array<EnrichedLogTypeFormValues['associatedLogTypes']>()
      .of(
        Yup.object().shape(
          {
            logType: Yup.string().required('You must select a log type'),
            selectors: Yup.array().when('logType', {
              is: logType => !!logType,
              then: Yup.array()
                .of(Yup.string().required())
                .required()
                .min(1, 'You need to add at least 1 selector'),
              otherwise: Yup.array(),
            }),
          },
          [['logType', 'selectors']]
        )
      )
      .required(),
  }
);
