/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Form, Formik, FastField } from 'formik';
import { SortDirEnum } from 'Generated/schema';
import { Box, Flex } from 'pouncejs';
import pick from 'lodash/pick';
import useRequestParamsWithoutPagination from 'Hooks/useRequestParamsWithoutPagination';
import FormikAutosave from 'Components/utils/Autosave';
import FormikCombobox from 'Components/fields/ComboBox';
import FormikTextInput from 'Components/fields/TextInput';
import {
  extractSortingOptions,
  SortingOption,
  WrapSortingFormValues,
  wrapSortingOptions,
} from 'Helpers/utils';
import DropdownFilters from './DropdownFilters';

export enum ListLogSourceSortFieldsEnum {
  dateCreated = 'dateCreated',
  health = 'health',
}

export type ListLogSourceInlineFilterValues = {
  sortDir?: SortDirEnum;
  sortBy?: ListLogSourceSortFieldsEnum;
  contains?: string;
};

const sortingOpts: SortingOption<ListLogSourceInlineFilterValues>[] = [
  {
    opt: 'Most Recent',
    resolution: {
      sortBy: ListLogSourceSortFieldsEnum.dateCreated,
      sortDir: SortDirEnum.Descending,
    },
  },
  {
    opt: 'Oldest',
    resolution: {
      sortBy: ListLogSourceSortFieldsEnum.dateCreated,
      sortDir: SortDirEnum.Ascending,
    },
  },
  {
    opt: 'Health',
    resolution: {
      sortBy: ListLogSourceSortFieldsEnum.health,
      sortDir: SortDirEnum.Descending,
    },
  },
];

const filters: (keyof ListLogSourceInlineFilterValues)[] = ['sortDir', 'contains', 'sortBy'];

const defaultValues = {
  contains: '',
  sortDir: SortDirEnum.Ascending,
  sortBy: ListLogSourceSortFieldsEnum.health,
};

const ListLogSourceFilters: React.FC = () => {
  const { requestParams, updateRequestParams } = useRequestParamsWithoutPagination<
    ListLogSourceInlineFilterValues
  >();
  const initialFilterValues = React.useMemo(
    () => ({
      ...defaultValues,
      ...wrapSortingOptions(pick(requestParams, filters), sortingOpts),
    }),
    [requestParams]
  );

  return (
    <Flex align="center">
      <Formik<WrapSortingFormValues<ListLogSourceInlineFilterValues>>
        enableReinitialize
        initialValues={initialFilterValues}
        onSubmit={values => updateRequestParams(extractSortingOptions(values, sortingOpts))}
      >
        <Form>
          <FormikAutosave threshold={200} />
          <Flex spacing={4} align="center" pr={4}>
            <Box minWidth={425}>
              <FastField
                name="contains"
                icon="search"
                iconAlignment="left"
                as={FormikTextInput}
                label="Filter Log Sources by text"
                placeholder="Search for a Log Source..."
              />
            </Box>
            <Box minWidth={220}>
              <FastField
                name="sorting"
                as={FormikCombobox}
                items={sortingOpts.map(sortingOption => sortingOption.opt)}
                label="Sort By"
                placeholder="Select a sorting option"
              />
            </Box>
          </Flex>
        </Form>
      </Formik>
      <Box pr={4}>
        <DropdownFilters />
      </Box>
    </Flex>
  );
};

export default React.memo(ListLogSourceFilters);
