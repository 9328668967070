/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import * as Types from '../../../../../../__generated__/schema';

import { AsanaLogIntegrationDetails } from '../../../../../graphql/fragments/AsanaLogIntegrationDetails.generated';
import { GraphQLError } from 'graphql';
import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';

export type AddAsanaLogSourceVariables = {
  input: Types.AddLogPullingIntegrationInput;
};

export type AddAsanaLogSource = { addLogPullingIntegration: AsanaLogIntegrationDetails };

export const AddAsanaLogSourceDocument = gql`
  mutation AddAsanaLogSource($input: AddLogPullingIntegrationInput!) {
    addLogPullingIntegration(input: $input) {
      ...AsanaLogIntegrationDetails
    }
  }
  ${AsanaLogIntegrationDetails}
`;
export type AddAsanaLogSourceMutationFn = ApolloReactCommon.MutationFunction<
  AddAsanaLogSource,
  AddAsanaLogSourceVariables
>;

/**
 * __useAddAsanaLogSource__
 *
 * To run a mutation, you first call `useAddAsanaLogSource` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddAsanaLogSource` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addAsanaLogSource, { data, loading, error }] = useAddAsanaLogSource({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddAsanaLogSource(
  baseOptions?: ApolloReactHooks.MutationHookOptions<AddAsanaLogSource, AddAsanaLogSourceVariables>
) {
  return ApolloReactHooks.useMutation<AddAsanaLogSource, AddAsanaLogSourceVariables>(
    AddAsanaLogSourceDocument,
    baseOptions
  );
}
export type AddAsanaLogSourceHookResult = ReturnType<typeof useAddAsanaLogSource>;
export type AddAsanaLogSourceMutationResult = ApolloReactCommon.MutationResult<AddAsanaLogSource>;
export type AddAsanaLogSourceMutationOptions = ApolloReactCommon.BaseMutationOptions<
  AddAsanaLogSource,
  AddAsanaLogSourceVariables
>;
export function mockAddAsanaLogSource({
  data,
  variables,
  errors,
}: {
  data: AddAsanaLogSource;
  variables?: AddAsanaLogSourceVariables;
  errors?: GraphQLError[];
}) {
  return {
    request: { query: AddAsanaLogSourceDocument, variables },
    result: { data, errors },
  };
}
