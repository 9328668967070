/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Permission } from 'Generated/schema';
import { compose } from 'Helpers/compose';
import Page403 from 'Pages/403';
import { SelectProvider } from 'Components/utils/SelectContext';
import withSEO from 'Hoc/withSEO';
import withRoleRestrictedAccess from 'Hoc/withRoleRestrictedAccess';
import useUrlParams from 'Hooks/useUrlParams';
import { Box, Alert } from 'pouncejs';
import { extractErrorMessage } from 'Helpers/utils';
import MitreMappingDetections from './MitreMappingDetections';
import { useGetReportSetting } from './graphql/getReportSetting.generated';
import MitreMappingSkeleton from './MitreMappingSkeleton';

export interface MitreMappedRelation {
  id: string;
}

type MitreMappingUrlParams = {
  tactic: string;
  technique: string;
};

const MitreMappingPage = () => {
  const {
    urlParams: { technique: techniqueId, tactic: tacticId },
  } = useUrlParams<MitreMappingUrlParams>();

  const { error, loading, data } = useGetReportSetting({ variables: { tacticId, techniqueId } });

  if (error && !loading) {
    return (
      <Alert
        title="Failed to load configuration for tactic and technique"
        variant="error"
        description={extractErrorMessage(error) || 'Unknown Error'}
      />
    );
  }

  if (loading || !data) {
    return <MitreMappingSkeleton />;
  }

  return (
    <Box>
      <SelectProvider<MitreMappedRelation>
        getItemKey={d => d.id}
        initialSelection={data.reportSetting.matchingDetectionIds.map(id => ({ id }))}
      >
        <MitreMappingDetections reportSetting={data.reportSetting} />
      </SelectProvider>
    </Box>
  );
};

export default compose(
  withSEO({ title: 'MITRE ATT&CK Mapping' }),
  withRoleRestrictedAccess({
    allowedPermissions: [Permission.RuleRead, Permission.RuleModify],
    fallback: <Page403 />,
  })
)(MitreMappingPage);
