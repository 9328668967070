/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import * as Yup from 'yup';
import { Field, Form, Formik } from 'formik';
import { Link as RRLink } from 'react-router-dom';
import React from 'react';
import FormikTextInput from 'Components/fields/TextInput';
import SubmitButton from 'Components/buttons/SubmitButton';
import useAuth from 'Hooks/useAuth';
import { Link, Flex, Box } from 'pouncejs';
import urls from 'Source/urls';

interface SignInFormValues {
  username: string;
  password: string;
}

const initialValues = {
  username: '',
  password: '',
};

const validationSchema = Yup.object().shape({
  username: Yup.string().email('Needs to be a valid email').required(),
  password: Yup.string().required(),
});

const SignInForm: React.FC = () => {
  const { signIn } = useAuth();

  return (
    <Formik<SignInFormValues>
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={async ({ username, password }, { setErrors }) =>
        signIn({
          email: username,
          password,
          onError: ({ message }) => {
            // FIXME: There is weird issue returning wrong error message on submit
            // correlated heavily on this https://github.com/aws-amplify/amplify-js/pull/4427
            return setErrors({
              password:
                message === 'Only radix 2, 4, 8, 16, 32 are supported'
                  ? 'Incorrect username or password.'
                  : message,
            });
          },
        })
      }
    >
      <Form>
        <Flex direction="column" spacing={4}>
          <Field
            as={FormikTextInput}
            label="Email"
            placeholder="Enter your company email..."
            type="email"
            name="username"
            required
          />
          <Field
            as={FormikTextInput}
            label="Password"
            placeholder="The name of your cat"
            name="password"
            type="password"
            required
          />
          <Flex ml="auto">
            <Link as={RRLink} to={urls.account.auth.forgotPassword()} fontSize="medium">
              Forgot your password?
            </Link>
          </Flex>
          <Box mt={4}>
            <SubmitButton fullWidth>Sign in</SubmitButton>
          </Box>
        </Flex>
      </Form>
    </Formik>
  );
};

export default SignInForm;
