/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

// Side-effect import for one-off initializations
import 'Source/config';

import React from 'react';
import ReactDOM from 'react-dom';
import history from './history';
import App from './app';

ReactDOM.render(<App history={history} />, document.getElementById('root'));
