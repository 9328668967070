/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import * as Types from '../../../../__generated__/schema';

import { PackSourceDetails } from '../../../graphql/fragments/PackSourceDetails.generated';
import { GraphQLError } from 'graphql';
import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';

export type UpdatePackSourceVariables = {
  input: Types.UpdateAnalysisPackSourceInput;
};

export type UpdatePackSource = { updateAnalysisPackSource: PackSourceDetails };

export const UpdatePackSourceDocument = gql`
  mutation UpdatePackSource($input: UpdateAnalysisPackSourceInput!) {
    updateAnalysisPackSource(input: $input) {
      ...PackSourceDetails
    }
  }
  ${PackSourceDetails}
`;
export type UpdatePackSourceMutationFn = ApolloReactCommon.MutationFunction<
  UpdatePackSource,
  UpdatePackSourceVariables
>;

/**
 * __useUpdatePackSource__
 *
 * To run a mutation, you first call `useUpdatePackSource` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePackSource` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePackSource, { data, loading, error }] = useUpdatePackSource({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePackSource(
  baseOptions?: ApolloReactHooks.MutationHookOptions<UpdatePackSource, UpdatePackSourceVariables>
) {
  return ApolloReactHooks.useMutation<UpdatePackSource, UpdatePackSourceVariables>(
    UpdatePackSourceDocument,
    baseOptions
  );
}
export type UpdatePackSourceHookResult = ReturnType<typeof useUpdatePackSource>;
export type UpdatePackSourceMutationResult = ApolloReactCommon.MutationResult<UpdatePackSource>;
export type UpdatePackSourceMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdatePackSource,
  UpdatePackSourceVariables
>;
export function mockUpdatePackSource({
  data,
  variables,
  errors,
}: {
  data: UpdatePackSource;
  variables?: UpdatePackSourceVariables;
  errors?: GraphQLError[];
}) {
  return {
    request: { query: UpdatePackSourceDocument, variables },
    result: { data, errors },
  };
}
