/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Box, Card, Flex } from 'pouncejs';
import Skeleton from 'Pages/AlertDetails/Skeleton';
import ErrorBoundary from 'Components/ErrorBoundary';
import { AlertOriginPolicy } from 'Generated/schema';
import { AlertDetails } from 'Pages/AlertDetails';
import { useGetPolicySummary } from './graphql/getPolicySummary.generated';
import PolicyAlertDetailsBanner from './PolicyAlertDetailsBanner';
import PolicyAlertDetailsInfo from './PolicyAlertDetailsInfo';

interface PolicyAlertDetailsProps {
  alert: AlertDetails['alert'];
}

const PolicyAlertDetails: React.FC<PolicyAlertDetailsProps> = ({ alert }) => {
  const alertOriginInfo = alert.origin as AlertOriginPolicy;
  const { data, loading } = useGetPolicySummary({
    variables: { input: { id: alertOriginInfo.policyId } },
    context: {
      failSilently: true,
    },
  });

  if (loading && !data) {
    return <Skeleton />;
  }

  return (
    <Box as="article" mb={6}>
      <Flex direction="column" spacing={6}>
        <PolicyAlertDetailsBanner alert={alert} />
        <Card position="relative" p={6}>
          <ErrorBoundary>
            <PolicyAlertDetailsInfo alert={alert} policy={data?.policy} />
          </ErrorBoundary>
        </Card>
      </Flex>
    </Box>
  );
};

export default PolicyAlertDetails;
