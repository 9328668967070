/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Flex, Card, Text, AbstractButton } from 'pouncejs';

interface ListAlertsSelectSearch {
  selectSearch: boolean;
  handleChange: (v: boolean) => void;
  itemsOnPage: number;
  collectionLabel: string;
}

const ListAlertsSelectSearch: React.FC<ListAlertsSelectSearch> = ({
  handleChange,
  selectSearch,
  collectionLabel,
  itemsOnPage,
}) => {
  const stateMessage = selectSearch
    ? `All ${collectionLabel} in this search are selected.`
    : `All ${itemsOnPage} ${collectionLabel} on this page are selected.`;

  const actionMessage = selectSearch
    ? 'Clear selection'
    : `Select all ${collectionLabel} that match this search`;

  const onClick = React.useCallback(() => {
    handleChange(!selectSearch);
  }, [handleChange, selectSearch]);

  return (
    <Card variant="dark" mx={4} textAlign="center">
      <Flex justify="center" align="center" py={4}>
        <Text fontSize="medium">{stateMessage}</Text>
        <AbstractButton ml={4} onClick={onClick}>
          <Text color="blue-200" fontSize="medium">
            {actionMessage}
          </Text>
        </AbstractButton>
      </Flex>
    </Card>
  );
};

export default React.memo(ListAlertsSelectSearch);
