/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Box, Button, Flex } from 'pouncejs';
import Editor from 'Components/Editor';
import useAsyncQueryContext from 'Hooks/useAsyncQueryContext';
import useAsyncQueryCancellation from 'Hooks/useAsyncQueryCancellation';
import useAsyncQueryExecution from 'Hooks/useAsyncQueryExecution';
import {
  EditorHelpers,
  EditorSavedQueriesActions,
  EditorTitle,
} from 'Pages/DataExplorer/SQLEditor/components';
import RoleRestrictedAccess from 'Components/utils/RoleRestrictedAccess';
import { Permission } from 'Generated/schema';
import useUrlParams from 'Hooks/useUrlParams';
import { useDataExplorerContext } from '../DataExplorerContext';
import useSQLAutocompletion from './useSQLEditorAutocompletion';
import useSQLEditorCommands from './useSQLEditorCommands';
import useGeneratedSQLEditorValue from './useGeneratedSQLEditorValue';
import { RunQuery, RunQueryVariables, useRunQuery } from './graphql/runQuery.generated';
import { DataExplorerUrlParams } from '../constants';

const SQLEditor = () => {
  const { state: { selectedDatabase, sql: value }, dispatch } = useDataExplorerContext(); // prettier-ignore
  const { state: { queryStatus } } = useAsyncQueryContext(); // prettier-ignore
  const { urlParams } = useUrlParams<DataExplorerUrlParams>();
  const {
    placeholder: generatedSQLPlaceholder,
    activeQuery,
    isLoadingSQL,
  } = useGeneratedSQLEditorValue();
  const { cancelQuery, isCancelingQuery } = useAsyncQueryCancellation();

  // If SQL is getting generated, use the generated placeholder, otherwise use the default.
  const placeholder =
    generatedSQLPlaceholder ??
    'Run any SQL query. For example: select * from panther_logs.aws_alb limit 10;';

  const completions = useSQLAutocompletion();
  const { executeQuery, isProvisioningQuery } = useAsyncQueryExecution<RunQuery, RunQueryVariables>(
    {
      mutation: useRunQuery,
      getResponse: data => data.executeDataLakeQuery,
    }
  );

  React.useEffect(() => {
    if (activeQuery?.defaultDatabase) {
      dispatch({
        type: 'SELECT_DATABASE',
        payload: { database: activeQuery.defaultDatabase },
      });
    }
    // FIXME: look into missing hook dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeQuery?.defaultDatabase]);

  const executeQueryWithEditorValue = React.useCallback(() => {
    return executeQuery({
      input: {
        databaseName: selectedDatabase,
        sql: value,
        queryName: activeQuery?.name,
      },
    });
  }, [executeQuery, value, selectedDatabase, activeQuery?.name]);

  React.useEffect(() => {
    // Since this is a URL param, invalid values will get parsed as strings, only valid
    // boolean values will be transformed into booleans
    const shouldExecute = typeof urlParams.execute === 'boolean' && urlParams.execute;

    // If the `execute` query parameter exists in the URL, automatically execute
    // the query once the SQL in the editor has loaded.
    //
    // We need to include the `value` / `selectedDatabase` properties to avoid race condition where this
    // call can be fired before the state updates take place
    if (!isLoadingSQL && shouldExecute && value && selectedDatabase) {
      executeQueryWithEditorValue();
    }
  }, [isLoadingSQL, urlParams.execute, executeQueryWithEditorValue, value, selectedDatabase]);

  const commands = useSQLEditorCommands({ onCmdEnter: executeQueryWithEditorValue });

  const setEditorValue = React.useCallback(
    (sql: string) => dispatch({ type: 'UPDATE_SQL', payload: { sql } }),
    [dispatch]
  );

  return (
    <Box>
      <EditorTitle
        queryName={activeQuery?.name}
        isDirty={
          activeQuery?.sqlQuery !== value ||
          (activeQuery?.defaultDatabase && activeQuery?.defaultDatabase !== selectedDatabase)
        }
      />
      <Editor
        data-testid="sql-editor-input"
        placeholder={placeholder}
        minHeight={475}
        language="sql"
        completions={completions}
        onChange={setEditorValue}
        value={value}
        commands={commands}
      />
      <Box mt={6}>
        {queryStatus === 'running' ? (
          <Button variantColor="pink-700" disabled={isCancelingQuery} onClick={cancelQuery}>
            Cancel
          </Button>
        ) : (
          <Flex spacing={2}>
            <Button
              variantColor="yellow-600"
              disabled={!value || !selectedDatabase || isProvisioningQuery}
              onClick={executeQueryWithEditorValue}
              aria-describedby="run-query-helper"
            >
              Run Query
            </Button>
            <RoleRestrictedAccess allowedPermissions={[Permission.DataAnalyticsModify]}>
              <EditorSavedQueriesActions activeQuery={activeQuery} value={value} />
            </RoleRestrictedAccess>
          </Flex>
        )}
      </Box>
      <EditorHelpers />
    </Box>
  );
};

export default React.memo(SQLEditor);
