/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import type { monaco } from 'react-monaco-editor';
import React from 'react';
import { EditorLineError } from './Editor';

interface UseEditorDecorationsProps {
  editor?: monaco.editor.IStandaloneCodeEditor;
  lineErrors: EditorLineError[];
}

type DeltaDecoration = monaco.editor.IModelDeltaDecoration;
type LineRangeFunc = (line: number) => monaco.IRange;
type DecorationFunc = (acc: DeltaDecoration[], current: EditorLineError) => DeltaDecoration[];
/**
 * Build a valid range for highlighting
 * a specific line inside the editor
 */
const buildInlineRange: LineRangeFunc = line => {
  return {
    startLineNumber: line,
    startColumn: 1,
    endLineNumber: line + 1,
    endColumn: 1,
  };
};

/**
 * Build a valid range for highlighting
 * a specific line on the left side of the editor
 */
const buildLineRange: LineRangeFunc = line => {
  return {
    startLineNumber: line,
    startColumn: 1,
    endLineNumber: line,
    endColumn: 1,
  };
};

/*
 * A helper function to build editor decoration for a specific line
 * Some helpful hints:
 * Glyph: refers to the icon before line number
 * Line: refers to space after the line number and before the editor body
 * Inline: refers to the actual line in the editor.
 */
const buildDeltaDecoration: DecorationFunc = (acc, { line, reason }) => {
  /*
   * NOTE: Sadly monaco only allows a direct className to render the decorations
   * You can find their definition on Editor.tsx SX property
   */
  return [
    ...acc,
    {
      range: buildLineRange(line),
      options: {
        glyphMarginClassName: 'monaco-glyph-error',
        glyphMarginHoverMessage: {
          value: reason,
        },
      },
    },
    {
      range: buildLineRange(line),
      options: {
        isWholeLine: true,
        linesDecorationsClassName: 'monaco-line-decoration',
        hoverMessage: {
          value: reason,
        },
      },
    },
    {
      range: buildInlineRange(line),
      options: { inlineClassName: 'monaco-inline-decoration' },
    },
  ];
};

/*
 * useEditorDecorations add line decoration to specific lines in the editor for visual feedback
 */
const useEditorErrorDecorations = ({ editor, lineErrors }: UseEditorDecorationsProps) => {
  const activeDecorations = React.useRef([]);
  React.useEffect(() => {
    if (!editor) {
      return;
    }
    if (!lineErrors?.length) {
      // This will remove existing decorations
      activeDecorations.current = editor.deltaDecorations(activeDecorations.current, []);
      return;
    }
    // Build editor line decorations
    const errorDecoration = lineErrors.reduce(buildDeltaDecoration, []);
    // Pass decorations to editor -- This approach will override existing decorations
    activeDecorations.current = editor.deltaDecorations(activeDecorations.current, errorDecoration);
  }, [lineErrors, editor]);
};

export default useEditorErrorDecorations;
