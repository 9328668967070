/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import * as Types from '../../../../__generated__/schema';

import { RuleDetails } from '../../../graphql/fragments/RuleDetails.generated';
import { GraphQLError } from 'graphql';
import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';

export type UpdateRuleVariables = {
  input: Types.UpdateRuleInput;
};

export type UpdateRule = { updateRule?: Types.Maybe<RuleDetails> };

export const UpdateRuleDocument = gql`
  mutation UpdateRule($input: UpdateRuleInput!) {
    updateRule(input: $input) {
      ...RuleDetails
    }
  }
  ${RuleDetails}
`;
export type UpdateRuleMutationFn = ApolloReactCommon.MutationFunction<
  UpdateRule,
  UpdateRuleVariables
>;

/**
 * __useUpdateRule__
 *
 * To run a mutation, you first call `useUpdateRule` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRule` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRule, { data, loading, error }] = useUpdateRule({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateRule(
  baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateRule, UpdateRuleVariables>
) {
  return ApolloReactHooks.useMutation<UpdateRule, UpdateRuleVariables>(
    UpdateRuleDocument,
    baseOptions
  );
}
export type UpdateRuleHookResult = ReturnType<typeof useUpdateRule>;
export type UpdateRuleMutationResult = ApolloReactCommon.MutationResult<UpdateRule>;
export type UpdateRuleMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateRule,
  UpdateRuleVariables
>;
export function mockUpdateRule({
  data,
  variables,
  errors,
}: {
  data: UpdateRule;
  variables?: UpdateRuleVariables;
  errors?: GraphQLError[];
}) {
  return {
    request: { query: UpdateRuleDocument, variables },
    result: { data, errors },
  };
}
