/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Alert, SimpleGrid } from 'pouncejs';
import urls from 'Source/urls';
import ErrorBoundary from 'Components/ErrorBoundary';
import { extractErrorMessage } from 'Helpers/utils';
import withSEO from 'Hoc/withSEO';
import Panel from 'Components/Panel';
import LinkButton from 'Components/buttons/LinkButton';
import { compose } from 'Helpers/compose';
import withRoleRestrictedAccess from 'Hoc/withRoleRestrictedAccess';
import { Permission } from 'Generated/schema';
import Page403 from 'Pages/403/403';
import RoleRestrictedAccess from 'Components/utils/RoleRestrictedAccess/RoleRestrictedAccess';
import { useListGlobalHelpers } from 'Source/graphql/queries';
import EmptyDataFallback from './EmptyDataFallback';
import GlobalHelperItem from './GlobalHelperItem';
import Skeleton from './Skeleton';

const ListGlobalHelpers = () => {
  const { loading, error, data } = useListGlobalHelpers({
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    variables: {
      input: {},
    },
  });

  if (loading && !data) {
    return <Skeleton />;
  }

  if (error) {
    return (
      <Alert
        variant="error"
        title="Couldn't load your sources"
        description={
          extractErrorMessage(error) ||
          'There was an error when performing your request, please contact support@runpanther.io'
        }
      />
    );
  }

  if (!data.listGlobalHelpers) {
    return <EmptyDataFallback />;
  }

  return (
    <Panel
      data-tracking-page="global-helpers"
      title="Global Helpers"
      actions={
        <RoleRestrictedAccess allowedPermissions={[Permission.RuleModify, Permission.PolicyModify]}>
          <LinkButton
            title="Create new  Global Helper"
            data-tid="create-new-helper"
            to={urls.data.globalHelpers.create()}
          >
            Create New
          </LinkButton>
        </RoleRestrictedAccess>
      }
    >
      <ErrorBoundary>
        <SimpleGrid columns={2} spacing={3}>
          {data.listGlobalHelpers.globals.map(globalHelper => (
            <GlobalHelperItem key={globalHelper.id} globalHelper={globalHelper} />
          ))}
        </SimpleGrid>
      </ErrorBoundary>
    </Panel>
  );
};

export default compose(
  withSEO({ title: 'Global Helpers' }),
  withRoleRestrictedAccess({
    allowedPermissions: [
      Permission.RuleRead,
      Permission.PolicyRead,
      Permission.RuleModify,
      Permission.PolicyModify,
    ],
    fallback: <Page403 />,
  })
)(ListGlobalHelpers);
