/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Box, Card, Flex } from 'pouncejs';
import { useWizardContext, WizardProvider } from './WizardContext';

import {
  WizardProps,
  WizardStepProps,
  WizardStepGroupProps,
  Step as StepType,
  NavigationEntry,
  Entry,
} from './types';

import { WizardNavigation } from './WizardNavigation';

const WizardStep: React.FC<WizardStepProps> = ({ children }) => children as React.ReactElement;
const WizardGroupStep: React.FC<WizardStepGroupProps> = ({ children }) =>
  children as React.ReactElement;

const Wizard = <WizardData,>({
  children,
  header = true,
  headerTitle,
  headerWidth = 260,
  ...props
}: WizardProps<WizardData>): React.ReactElement => {
  return (
    <WizardProvider {...props}>
      <Flex spacing={5}>
        {header && (
          <Box as="section">
            <Card p={4} width={headerWidth}>
              <WizardNavigation title={headerTitle} />
            </Card>
          </Box>
        )}
        <Card p={6} mb={6} as="section" position="relative" width={1}>
          <WizardStepsRegistrar>{children}</WizardStepsRegistrar>
        </Card>
      </Flex>
    </WizardProvider>
  );
};

// Component that handles the steps registration and displaying the current step page AKA currentStep
const WizardStepsRegistrar: React.FC = ({ children }) => {
  const { currentStep, setSteps, setNavigationEntries } = useWizardContext();

  React.useEffect(() => {
    const steps: Array<StepType> = [];
    const navigationEntries: Array<NavigationEntry> = [];
    let indexKey = 0;
    React.Children.forEach(children as React.ReactElement[], child => {
      if (!child) {
        // remove undefined or null children
        return;
      }

      switch (child.type) {
        case WizardGroupStep: {
          const groupId = `group-${indexKey}`;
          const groupEntry: NavigationEntry = {
            title: child.props.title,
            description: child.props.description,
            id: groupId,
            activeIndex: indexKey,
            navigationDisabled: child.props.navigationDisabled,
            entries: [],
          };
          React.Children.forEach(child.props.children, nestedChild => {
            if (!nestedChild) {
              return;
            }

            const newStep = {
              ...nestedChild.props,
              id: indexKey,
              groupId,
            };

            groupEntry.entries.push({
              title: nestedChild.props.title,
              description: nestedChild.props.description,
              navigationDisabled: nestedChild.props.navigationDisabled,
              id: indexKey,
            });
            steps.push(newStep);
            indexKey += 1;
          });
          navigationEntries.push(groupEntry);
          break;
        }
        case WizardStep: {
          steps.push({ ...child.props, id: indexKey, groupId: null });
          navigationEntries.push({
            title: child.props.title,
            description: child.props.description,
            navigationDisabled: child.props.navigationDisabled,
            id: indexKey,
          } as Entry);
          indexKey += 1;
          break;
        }
        default:
          throw new Error(
            '`Wizard` children should be of type `Wizard.StepGroup` or `Wizard.Step`.'
          );
      }
    });
    setSteps(steps);
    setNavigationEntries(navigationEntries);
  }, [setSteps, setNavigationEntries, children]);

  return currentStep?.children ?? null;
};

Wizard.StepGroup = WizardGroupStep;
Wizard.Step = WizardStep;

export default Wizard;
