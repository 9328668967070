/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import * as Types from '../../../../__generated__/schema';

import { DataSchemaFull } from '../../../graphql/fragments/DataSchemaFull.generated';
import { GraphQLError } from 'graphql';
import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';

export type UpdateDataSchemaVariables = {
  input: Types.PutUserSchemaInput;
};

export type UpdateDataSchema = {
  putUserSchema: {
    error?: Types.Maybe<Pick<Types.Error, 'message'>>;
    record?: Types.Maybe<DataSchemaFull>;
  };
};

export const UpdateDataSchemaDocument = gql`
  mutation UpdateDataSchema($input: PutUserSchemaInput!) {
    putUserSchema(input: $input) {
      error {
        message
      }
      record {
        ...DataSchemaFull
      }
    }
  }
  ${DataSchemaFull}
`;
export type UpdateDataSchemaMutationFn = ApolloReactCommon.MutationFunction<
  UpdateDataSchema,
  UpdateDataSchemaVariables
>;

/**
 * __useUpdateDataSchema__
 *
 * To run a mutation, you first call `useUpdateDataSchema` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDataSchema` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDataSchema, { data, loading, error }] = useUpdateDataSchema({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateDataSchema(
  baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateDataSchema, UpdateDataSchemaVariables>
) {
  return ApolloReactHooks.useMutation<UpdateDataSchema, UpdateDataSchemaVariables>(
    UpdateDataSchemaDocument,
    baseOptions
  );
}
export type UpdateDataSchemaHookResult = ReturnType<typeof useUpdateDataSchema>;
export type UpdateDataSchemaMutationResult = ApolloReactCommon.MutationResult<UpdateDataSchema>;
export type UpdateDataSchemaMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateDataSchema,
  UpdateDataSchemaVariables
>;
export function mockUpdateDataSchema({
  data,
  variables,
  errors,
}: {
  data: UpdateDataSchema;
  variables?: UpdateDataSchemaVariables;
  errors?: GraphQLError[];
}) {
  return {
    request: { query: UpdateDataSchemaDocument, variables },
    result: { data, errors },
  };
}
