/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import * as Types from '../../../../../__generated__/schema';

import { APITokenDetails } from '../../../../graphql/fragments/APITokenDetails.generated';
import { GraphQLError } from 'graphql';
import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';

export type GetAPITokenVariables = {
  id: Types.Scalars['ID'];
};

export type GetAPIToken = {
  getAPIToken: {
    token?: Types.Maybe<APITokenDetails>;
    error?: Types.Maybe<Pick<Types.Error, 'code' | 'message'>>;
  };
};

export const GetAPITokenDocument = gql`
  query GetAPIToken($id: ID!) {
    getAPIToken(id: $id) {
      token {
        ...APITokenDetails
      }
      error {
        code
        message
      }
    }
  }
  ${APITokenDetails}
`;

/**
 * __useGetAPIToken__
 *
 * To run a query within a React component, call `useGetAPIToken` and pass it any options that fit your needs.
 * When your component renders, `useGetAPIToken` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAPIToken({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetAPIToken(
  baseOptions?: ApolloReactHooks.QueryHookOptions<GetAPIToken, GetAPITokenVariables>
) {
  return ApolloReactHooks.useQuery<GetAPIToken, GetAPITokenVariables>(
    GetAPITokenDocument,
    baseOptions
  );
}
export function useGetAPITokenLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAPIToken, GetAPITokenVariables>
) {
  return ApolloReactHooks.useLazyQuery<GetAPIToken, GetAPITokenVariables>(
    GetAPITokenDocument,
    baseOptions
  );
}
export type GetAPITokenHookResult = ReturnType<typeof useGetAPIToken>;
export type GetAPITokenLazyQueryHookResult = ReturnType<typeof useGetAPITokenLazyQuery>;
export type GetAPITokenQueryResult = ApolloReactCommon.QueryResult<
  GetAPIToken,
  GetAPITokenVariables
>;
export function mockGetAPIToken({
  data,
  variables,
  errors,
}: {
  data: GetAPIToken;
  variables?: GetAPITokenVariables;
  errors?: GraphQLError[];
}) {
  return {
    request: { query: GetAPITokenDocument, variables },
    result: { data, errors },
  };
}
