/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Link as RRLink } from 'react-router-dom';
import { Dropdown, DropdownButton, DropdownItem, DropdownLink, DropdownMenu } from 'pouncejs';

import urls from 'Source/urls';
import useModal from 'Hooks/useModal';
import { PackSourceDetails } from 'Source/graphql/fragments/PackSourceDetails.generated';
import DeletePackSourcesModal from 'Components/modals/DeletePackSourcesModal';
import OptionsButton from 'Components/buttons/OptionsButton';

interface PackSourceCardOptionsProps {
  packSource: PackSourceDetails;
}

const PackSourceCardOptions: React.FC<PackSourceCardOptionsProps> = ({ packSource }) => {
  const { showModal } = useModal();

  return (
    <Dropdown>
      <DropdownButton as={OptionsButton} />
      <DropdownMenu>
        <DropdownLink as={RRLink} to={urls.analysis.packs.packSources.edit(packSource.id)}>
          Edit
        </DropdownLink>
        <DropdownItem
          onSelect={() =>
            showModal(<DeletePackSourcesModal packSources={[packSource]} />, {
              title: `Delete Pack Source`,
            })
          }
        >
          Delete
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  );
};

export default React.memo(PackSourceCardOptions);
