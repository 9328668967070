/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Permission } from 'Generated/schema';
import useCheckPermissions from 'Hooks/useCheckPermissions';

export interface RoleRestrictedAccessProps {
  allowedPermissions: Permission[];
  logic?: 'OR' | 'AND';
  fallback?: React.ReactElement | null;
  children: React.ReactNode; // we need to specify it due to React.memo(..) down below
}

const RoleRestrictedAccess: React.FC<RoleRestrictedAccessProps> = ({
  allowedPermissions,
  logic,
  fallback = null,
  children,
}) => {
  const isAllowedToProceed = useCheckPermissions(allowedPermissions, logic);

  if (!isAllowedToProceed) {
    return fallback;
  }

  return children as React.ReactElement;
};

export default React.memo(RoleRestrictedAccess);
