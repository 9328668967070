/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import LinkButton from 'Components/buttons/LinkButton';
import FormikTextInput from 'Components/fields/TextInput';
import { WizardPanel } from 'Components/Wizard';
import { FastField, useFormikContext } from 'formik';
import { toStackNameFormat } from 'Helpers/utils';
import { Box, Flex, Heading, SimpleGrid, Text, useSnackbar } from 'pouncejs';
import { PANTHER_CONFIG } from 'Source/constants';
import EditIntegrationActions from 'Pages/Integrations/components/EditIntegrationActions';
import { S3LogSourceWizardValues } from '../S3LogSourceWizard';
import { useGetLogCfnTemplate } from './graphql/getLogCfnTemplate.generated';

const ConsoleMethod: React.FC = () => {
  const { pushSnackbar } = useSnackbar();

  const { values, isValid } = useFormikContext<S3LogSourceWizardValues>();
  const { data, loading, error } = useGetLogCfnTemplate({
    variables: {
      input: {
        integrationLabel: values.integrationLabel,
        s3Bucket: values.s3Bucket,
        kmsKey: values.kmsKey || null,
        managedBucketNotifications: true,
      },
    },
    onError: () => pushSnackbar({ variant: 'error', title: 'Failed to generate CFN template' }),
  });

  const { stackName } = data?.getS3LogIntegrationTemplate ?? {};
  const cfnConsoleLink =
    `https://console.aws.amazon.com/cloudformation/home#/stacks/create/review` +
    `?templateURL=https://panther-public-cloudformation-templates.s3-us-west-2.amazonaws.com/panther-log-analysis-iam/v${PANTHER_CONFIG.PANTHER_VERSION}/template.yml` +
    `&stackName=${stackName}` +
    `&param_MasterAccountId=${PANTHER_CONFIG.AWS_ACCOUNT_ID}` +
    `&param_RoleSuffix=${toStackNameFormat(values.integrationLabel)}` +
    `&param_S3Bucket=${values.s3Bucket}` +
    `&param_ManagedBucketNotifications=true` +
    `&param_KmsKey=${values.kmsKey}`;

  const editMode = !!values.integrationId;

  return (
    <WizardPanel>
      <WizardPanel.Heading title="Use AWS UI to setup your role" />
      <SimpleGrid gap={5} width={0.8} mx="auto">
        <Box as="section">
          <Heading as="h2" color="teal-200" fontSize="x-large" fontWeight="medium" mb={1}>
            1. Deploy CloudFormation Template
          </Heading>

          <Text color="white-100" fontSize="medium" lineHeight="loose">
            Deploy this autogenerated CloudFormation template in AWS Account
            <b> {values.awsAccountId}</b> to create the IAM role that Panther will use to read data
            from your S3 bucket.
          </Text>
        </Box>
        <Flex as="section" justifyContent="center" mb={2}>
          <LinkButton
            external
            loading={loading}
            disabled={!!error || loading}
            to={cfnConsoleLink}
            variantColor="teal-500"
            icon="spacecraft"
          >
            Launch Console UI
          </LinkButton>
        </Flex>
        <Box as="section">
          <Heading as="h2" color="teal-200" fontSize="x-large" fontWeight="medium">
            2. Enter Role ARN
          </Heading>
          <Text color="white-100" fontSize="medium" lineHeight="loose" my={1}>
            Go to the {'"'}Outputs{'"'} tab of the CloudFormation stack that you just deployed and
            copy the value for RoleARN into the box below.
          </Text>

          <FastField
            name="logProcessingRole"
            as={FormikTextInput}
            label="Role ARN"
            trimPastedText
            required
          />
        </Box>
      </SimpleGrid>

      <WizardPanel.Actions>
        <WizardPanel.ActionPrev />
        {editMode ? (
          <EditIntegrationActions />
        ) : (
          <Flex spacing={4} direction="column" align="center">
            <Text fontSize="small" fontWeight="medium">
              After deploying the stack and entering the Role ARN, click below to proceed
            </Text>
            <WizardPanel.ActionNext disabled={!isValid}>Continue Setup</WizardPanel.ActionNext>
          </Flex>
        )}
      </WizardPanel.Actions>
    </WizardPanel>
  );
};

export default ConsoleMethod;
