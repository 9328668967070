/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Text, Flex, Img } from 'pouncejs';
import NothingFound from 'Assets/illustrations/nothing-found.svg';

interface NoResultsFoundProps {
  title?: string;
}

const NoResultsFound: React.FC<NoResultsFoundProps> = ({ title = 'No Results' }) => {
  return (
    <Flex justify="center">
      <Flex
        direction="column"
        align="center"
        justify="center"
        backgroundColor="navyblue-500"
        borderRadius="circle"
        width={260}
        height={260}
      >
        <Img
          ml={6}
          nativeWidth={95}
          nativeHeight={90}
          alt="Document and magnifying glass"
          src={NothingFound}
        />
        <Text color="navyblue-100" fontWeight="bold" mt={2}>
          {title}
        </Text>
      </Flex>
    </Flex>
  );
};

export default NoResultsFound;
