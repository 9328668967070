/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import WaitingStatus from 'Assets/statuses/illustration-waiting.svg';
import { WizardPanel } from 'Components/Wizard';
import { Flex, Img } from 'pouncejs';

const PendingPanel: React.FC = () => {
  return (
    <WizardPanel>
      <Flex align="center" direction="column" mx="auto">
        <WizardPanel.Heading
          title="Almost There!"
          subtitle={
            <>
              We are just making sure that everything is setup correctly. <br /> Hold on tight...
            </>
          }
          divider={null}
          subtitleProps={{ color: 'gray-300', fontWeight: 'normal' }}
        />
        <Img
          nativeWidth={166}
          nativeHeight={118}
          alt="Validating source health..."
          src={WaitingStatus}
        />
      </Flex>
    </WizardPanel>
  );
};

export default PendingPanel;
