/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import { HttpLink } from '@apollo/client';
import { PANTHER_CONFIG } from 'Source/constants';

/**
 * An HTTP link that chooses a GraphQL API based on a condition
 */
const httpLink = new HttpLink({ uri: PANTHER_CONFIG.WEB_APPLICATION_GRAPHQL_API_ENDPOINT });

export default httpLink;
