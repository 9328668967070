/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Alert, Box } from 'pouncejs';
import urls from 'Source/urls';
import { AddGlobalHelperInput } from 'Generated/schema';
import withSEO from 'Hoc/withSEO';
import { extractErrorMessage } from 'Helpers/utils';
import useRouter from 'Hooks/useRouter';
import storage from 'Helpers/storage';
import GlobalHelperForm from 'Components/forms/GlobalHelperForm';
import { useCreateGlobalHelper } from './graphql/createGlobalHelper.generated';

const GLOBAL_HELPER_INFOBOX_STORAGE_KEY = 'panther.globalHelper.isInfoboxSeen';

const initialValues: Required<AddGlobalHelperInput> = {
  id: '',
  description: '',
  body: '',
  managed: false,
  parentId: null,
};

const CreateGlobalHelperPage: React.FC = () => {
  const { history, location } = useRouter();
  const [createGlobalHelper, { error }] = useCreateGlobalHelper({
    onCompleted: data => history.push(urls.data.globalHelpers.edit(data.addGlobalHelper.id)),
    onError: () => null,
  });

  const clonedValues = location.state as Required<AddGlobalHelperInput>;

  const handleSubmit = React.useCallback(
    values => createGlobalHelper({ variables: { input: values } }),
    [createGlobalHelper]
  );

  const handleInformationBoxDiscard = React.useCallback(() => {
    storage.local.write(GLOBAL_HELPER_INFOBOX_STORAGE_KEY, true);
  }, []);

  const shouldShowInfobox = storage.local.read<boolean>(GLOBAL_HELPER_INFOBOX_STORAGE_KEY);
  return (
    <Box as="article" mb={6}>
      {!shouldShowInfobox && (
        <Box as="section" pb={5}>
          <Alert
            title="What is this?"
            description="Global helpers allow you to define reusable functions, variables and classes
            which can later be used within any Rule or Policy body definition. Anything defined below
            can be directly imported as a normal Python module using the helper name you defined below."
            variant="default"
            discardable
            onClose={handleInformationBoxDiscard}
          />
        </Box>
      )}
      {error && (
        <Box mb={6}>
          <Alert
            variant="error"
            title={
              extractErrorMessage(error) ||
              'An unknown error occurred as we were trying to create your rule'
            }
          />
        </Box>
      )}
      <GlobalHelperForm initialValues={clonedValues || initialValues} onSubmit={handleSubmit} />
    </Box>
  );
};

export default withSEO({ title: 'New Global Helper' })(CreateGlobalHelperPage);
