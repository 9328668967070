/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import withSEO from 'Hoc/withSEO';
import { compose } from 'Helpers/compose';
import withRoleRestrictedAccess from 'Hoc/withRoleRestrictedAccess';
import { Permission } from 'Generated/schema';
import Page403 from 'Pages/403';
import CrowdstrikeLogsSourceWizard, {
  CrowdstrikeLogsSourceWizardValues,
} from '../CrowdstrikeLogsSourceWizard';
import { useAddCrowdstrikeLogSource } from './graphql/addCrowdstrikeLogSource.generated';

const initialValues = {
  integrationLabel: '',
  awsAccessKey: '',
  awsAccessSecret: '',
  queueUrl: '',
};

const CreateCrowdstrikeLogSource: React.FC = () => {
  const [addCrowdstrikeLogSource] = useAddCrowdstrikeLogSource({
    update: (cache, { data }) => {
      cache.modify({
        fields: {
          logSources: (queryData, { toReference }) => {
            const addedIntegrationCacheRef = toReference(data.addLogPullingIntegration);
            return queryData
              ? [addedIntegrationCacheRef, ...queryData]
              : [addedIntegrationCacheRef];
          },
        },
      });
    },
  });

  const handleSubmit = React.useCallback(async (values: CrowdstrikeLogsSourceWizardValues) => {
    try {
      const { data } = await addCrowdstrikeLogSource({
        variables: {
          input: {
            integrationLabel: values.integrationLabel,
            pullerConfig: {
              crowdstrike: {
                awsAccessKey: values.awsAccessKey,
                awsAccessSecret: values.awsAccessSecret,
                queueUrl: values.queueUrl,
              },
            },
          },
        },
      });

      return data.addLogPullingIntegration;
    } catch (err) {
      return err as Error;
    }
    // FIXME: look into missing hook dependencies or using a ref
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <CrowdstrikeLogsSourceWizard initialValues={initialValues} onSubmit={handleSubmit} />;
};

export default compose(
  withSEO({ title: 'New Crowdstrike Log Source' }),
  withRoleRestrictedAccess({
    allowedPermissions: [Permission.LogSourceModify],
    fallback: <Page403 />,
  })
)(CreateCrowdstrikeLogSource);
