/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { FastField, useFormikContext, Field } from 'formik';
import {
  Box,
  Flex,
  FormHelperText,
  Link,
  FormError,
  Grid,
  SimpleGrid,
  Heading,
  Card,
  Text,
} from 'pouncejs';
import { formatDatetime, getActorDisplayName, minutesToString } from 'Helpers/utils';
import FormikTextArea from 'Components/fields/TextArea';
import FormikCombobox from 'Components/fields/ComboBox';
import FormikNumberInput from 'Components/fields/NumberInput';
import FormikMultiCombobox from 'Components/fields/MultiComboBox';
import { RuleFormValues } from 'Components/forms/RuleForm';
import urls from 'Source/urls';
import { Link as RRLink } from 'react-router-dom';
import useListAvailableDestinations from 'Hooks/useListAvailableDestinations';
import { ActorTeaser } from 'Source/graphql/fragments/ActorTeaser.generated';

const dedupPeriodMinutesOptions = [15, 30, 60, 180, 720, 1440];

type RuleFormCoreSectionProps = {
  createdAt?: string;
  createdBy?: ActorTeaser;
  lastModified?: string;
  lastModifiedBy?: ActorTeaser;
};

const RuleFormCoreSection: React.FC<RuleFormCoreSectionProps> = ({
  createdBy = null,
  createdAt = null,
  lastModifiedBy = null,
  lastModified = null,
}) => {
  // Read the values from the "parent" form. We expect a formik to be declared in the upper scope
  // since this is a "partial" form. If no Formik context is found this will error out intentionally
  const { values, initialValues } = useFormikContext<RuleFormValues>();
  const { managed: isManaged } = initialValues;

  const tagAdditionValidation = React.useMemo(() => (tag: string) => !values.tags.includes(tag), [
    values.tags,
  ]);

  const {
    loading: destinationsLoading,
    destinationOutputIds: availableOutputIds,
    destinationIdToDisplayName: destIdToDisplayName,
    validOutputIds: listValidOutputIds,
    disabled: disableDestinationField,
    error: destinationsError,
  } = useListAvailableDestinations({
    outputIds: values.outputIds,
  });

  const generateDestinationHelperText = React.useCallback(() => {
    if (destinationsError) {
      return (
        <FormError id="outputIds-description" mt={2}>
          There was a problem loading your destinations!
        </FormError>
      );
    }
    if (!availableOutputIds.length && !destinationsLoading) {
      return (
        <FormHelperText id="outputIds-description" mt={2} mr={1}>
          You have not configured any destinations.
          <Link ml={1} as={RRLink} to={urls.integrations.destinations.create()}>
            Create one
          </Link>
        </FormHelperText>
      );
    }
    if (destinationsLoading) {
      return (
        <FormHelperText id="outputIds-description" mt={2}>
          Loading your destinations...
        </FormHelperText>
      );
    }
    return (
      <FormHelperText id="outputIds-description" mt={2}>
        Send alerts to these destinations regardless of their severity level settings
      </FormHelperText>
    );
  }, [destinationsError, destinationsLoading, availableOutputIds]);

  // FIXME: look into hook dependencies
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const destinationHelperText = React.useMemo(() => generateDestinationHelperText(), [
    destinationsError,
    destinationsLoading,
    availableOutputIds,
  ]);

  return (
    <React.Fragment>
      <Flex alignItems="baseline">
        <Heading wordBreak="break-word" flexShrink={1} mb={8} mr={2}>
          Additional
        </Heading>
        <Text color="navyblue-100">(Optional)</Text>
      </Flex>
      <Card variant="dark" p={4}>
        <SimpleGrid columns={2} spacing={5}>
          <Flex as="section" direction="column" spacing={5}>
            <FastField
              resize="vertical"
              as={FormikTextArea}
              label="Description"
              placeholder="Additional context about this Rule"
              name="description"
              disabled={isManaged}
            />
            <FastField
              resize="vertical"
              as={FormikTextArea}
              label="Runbook"
              placeholder={`Procedures and operations related to this Rule`}
              name="runbook"
              disabled={isManaged}
            />
            <FastField
              resize="vertical"
              as={FormikTextArea}
              label="Reference"
              placeholder={`An external link to why this Rule exists`}
              name="reference"
              disabled={isManaged}
            />
            <FastField
              as={FormikMultiCombobox}
              searchable
              name="tags"
              label="Custom Tags"
              items={values.tags}
              allowAdditions
              validateAddition={tagAdditionValidation}
              placeholder="i.e. HIPAA (separate with <Enter>)"
              disabled={isManaged}
            />
          </Flex>
          <Flex as="section" direction="column" spacing={5}>
            <Grid templateColumns="5fr 2fr" columnGap={5}>
              <FastField
                as={FormikCombobox}
                label="Deduplication Period"
                name="dedupPeriodMinutes"
                items={dedupPeriodMinutesOptions}
                itemToString={minutesToString}
              />
              <Field
                as={FormikNumberInput}
                label="Events Threshold"
                min={1}
                name="threshold"
                placeholder="Send an alert only after # events"
              />
            </Grid>
            <Box as="fieldset">
              {/* FIXME: We have an issue with FastField here. We shouldn't be setting props like that on FastField or Field elements */}
              <Field
                as={FormikMultiCombobox}
                disabled={disableDestinationField}
                searchable
                label="Destination Overrides"
                name="outputIds"
                value={listValidOutputIds}
                items={availableOutputIds}
                itemToString={destIdToDisplayName}
                placeholder="Select destinations"
                aria-describedby="outputIds-description"
              />
              <Box ml={2}>{destinationHelperText}</Box>
            </Box>
            <Field
              as={FormikMultiCombobox}
              label="Summary Attributes"
              name="summaryAttributes"
              items={(values as RuleFormValues).summaryAttributes}
              searchable
              allowAdditions
              placeholder="What should we showcase in alerts?"
              disabled={isManaged}
            />
          </Flex>
        </SimpleGrid>
      </Card>
      {createdAt && (
        <Card variant="dark" p={4} mt={3}>
          <SimpleGrid as="dl" gap={2} columns={8} spacing={2}>
            <Box as="dt" color="navyblue-100" gridColumn="1/3" aria-describedby="created-at">
              Created
            </Box>
            <Box as="dd" gridColumn="3/8" id="created-at">
              {formatDatetime(createdAt)}
            </Box>

            <Box as="dt" color="navyblue-100" gridColumn="1/3" aria-describedby="created-at">
              Created By
            </Box>
            <Box as="dd" gridColumn="3/8" id="created-by">
              {getActorDisplayName(createdBy)}
            </Box>

            <Box as="dt" color="navyblue-100" gridColumn="1/3" aria-describedby="updated-at">
              Modified
            </Box>
            <Box as="dd" gridColumn="3/8" id="updated-at">
              {formatDatetime(lastModified)}
            </Box>

            <Box as="dt" color="navyblue-100" gridColumn="1/3" aria-describedby="created-at">
              Modified By
            </Box>
            <Box as="dd" gridColumn="3/8" id="updated-by">
              {getActorDisplayName(lastModifiedBy)}
            </Box>
          </SimpleGrid>
        </Card>
      )}
    </React.Fragment>
  );
};

export default React.memo(RuleFormCoreSection);
