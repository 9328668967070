/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import * as Types from '../../../../../../../__generated__/schema';

import { AlertSummaryFull } from '../../../../../../graphql/fragments/AlertSummaryFull.generated';
import { GraphQLError } from 'graphql';
import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';

export type ListAlertsVariables = {
  input: Types.AlertsInput;
};

export type ListAlerts = {
  alerts: {
    edges: Array<{ node: AlertSummaryFull }>;
    pageInfo: Pick<Types.AlertsOutputPageInfo, 'hasNextPage' | 'endCursor'>;
  };
};

export const ListAlertsDocument = gql`
  query ListAlerts($input: AlertsInput!) {
    alerts(input: $input) {
      edges {
        node {
          ...AlertSummaryFull
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
  ${AlertSummaryFull}
`;

/**
 * __useListAlerts__
 *
 * To run a query within a React component, call `useListAlerts` and pass it any options that fit your needs.
 * When your component renders, `useListAlerts` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListAlerts({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useListAlerts(
  baseOptions?: ApolloReactHooks.QueryHookOptions<ListAlerts, ListAlertsVariables>
) {
  return ApolloReactHooks.useQuery<ListAlerts, ListAlertsVariables>(
    ListAlertsDocument,
    baseOptions
  );
}
export function useListAlertsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ListAlerts, ListAlertsVariables>
) {
  return ApolloReactHooks.useLazyQuery<ListAlerts, ListAlertsVariables>(
    ListAlertsDocument,
    baseOptions
  );
}
export type ListAlertsHookResult = ReturnType<typeof useListAlerts>;
export type ListAlertsLazyQueryHookResult = ReturnType<typeof useListAlertsLazyQuery>;
export type ListAlertsQueryResult = ApolloReactCommon.QueryResult<ListAlerts, ListAlertsVariables>;
export function mockListAlerts({
  data,
  variables,
  errors,
}: {
  data: ListAlerts;
  variables?: ListAlertsVariables;
  errors?: GraphQLError[];
}) {
  return {
    request: { query: ListAlertsDocument, variables },
    result: { data, errors },
  };
}
