/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Box, Flex } from 'pouncejs';
import ErrorBoundary from 'Components/ErrorBoundary';
import { Field, useFormikContext } from 'formik';
import gsuiteMinimalLogo from 'Assets/gsuite-minimal-logo.svg';
import FormikTextInput from 'Components/fields/TextInput';
import FormikMultiCombobox from 'Components/fields/MultiComboBox';
import { WizardPanel } from 'Components/Wizard';
import { GoogleAppsEnum } from 'Source/constants';
import EditIntegrationActions from 'Pages/Integrations/components/EditIntegrationActions';
import { GsuiteLogsSourceWizardValues } from '../GsuiteLogsSourceWizard';

const GsuiteLogsSourceConfigurationPanel: React.FC = () => {
  const { initialValues, errors, values } = useFormikContext<GsuiteLogsSourceWizardValues>();

  const shouldDisableNextStep =
    !!errors.integrationLabel ||
    !values.integrationLabel ||
    !!errors.applications ||
    !values.applications.length;

  const editMode = !!initialValues.integrationId;

  return (
    <WizardPanel>
      <Box width={500} m="auto">
        <WizardPanel.Heading
          title={editMode ? 'Update Google Workspace Logs source' : "Let's start with the basics"}
          subtitle="Specify which activities you want Panther to monitor"
          logo={gsuiteMinimalLogo}
        />
        <ErrorBoundary>
          <Flex direction="column" spacing={4}>
            <Field
              name="integrationLabel"
              as={FormikTextInput}
              label="Name *"
              placeholder="A nickname for your Google Workspace log source"
              required
            />
            <Field
              as={FormikMultiCombobox}
              searchable
              label="Applications to monitor *"
              name="applications"
              items={Object.keys(GoogleAppsEnum)}
              itemToString={item => GoogleAppsEnum[item]}
              required
            />
          </Flex>
        </ErrorBoundary>
      </Box>
      {editMode ? (
        <EditIntegrationActions />
      ) : (
        <WizardPanel.Actions>
          <WizardPanel.ActionNext disabled={shouldDisableNextStep}>
            Continue Setup
          </WizardPanel.ActionNext>
        </WizardPanel.Actions>
      )}
    </WizardPanel>
  );
};

export default GsuiteLogsSourceConfigurationPanel;
