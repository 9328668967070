/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';

export interface UseInfiniteScrollProps {
  /**
   * Whether a request is currently loading or not.
   */
  loading: boolean;
  /**
   * The callback function to execute when the threshold is exceeded.
   */
  onLoadMore: () => void;
  /**
   * Maximum distance to bottom of the window/parent to trigger the callback. Default is 150.
   */
  threshold?: number;
  /**
   * May be `"window"` or `"parent"`. Default is `"window"`. If you want to use a scrollable
   * parent for the infinite list, use `"parent"`.
   */
  scrollContainer?: 'window' | 'parent';
}

function useInfiniteScroll<T extends Element>({
  loading,
  onLoadMore,
  threshold = 0,
  scrollContainer = 'window',
}: UseInfiniteScrollProps) {
  const sentinelRef = React.useRef<T>(null);
  const prevY = React.useRef(10000000);

  const callback = React.useCallback(
    entries => {
      entries.forEach(entry => {
        if (
          // is coming into viewport (i.e. it's not in the "leaving" phase)
          entry.isIntersecting &&
          // we approached it while scrolling downwards (not upwards)
          prevY.current >= entry.boundingClientRect.y &&
          // we are not already loading more
          !loading
        ) {
          onLoadMore();
        }

        // Only update the prevY when the sentinel is not in the viewport (since if it's in the
        // viewport, we want to keep loading & loading until it gets out of the viewport)
        if (!entry.intersectionRatio) {
          prevY.current = entry.boundingClientRect.y;
        }
      });
    },
    [loading, onLoadMore]
  );

  // eslint-disable-next-line consistent-return
  React.useEffect(() => {
    const sentinelNode = sentinelRef.current;
    if (sentinelNode) {
      const observer = new IntersectionObserver(callback, {
        root: scrollContainer === 'window' ? null : sentinelNode.parentElement,
        threshold: 0,
        rootMargin: `0px 0px ${threshold}px 0px`,
      });
      observer.observe(sentinelNode);

      return () => observer.disconnect();
    }
    // FIXME: look into hook dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sentinelRef.current, threshold, scrollContainer, callback]);

  return { sentinelRef };
}

export default useInfiniteScroll;
