/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Box } from 'pouncejs';
import { useFormikContext } from 'formik';
import { WizardPanel } from 'Components/Wizard';
import EditIntegrationActions from 'Pages/Integrations/components/EditIntegrationActions';
import OAuthPullerCredentialsFields from 'Pages/Integrations/components/OAuthPullerCredentialsFields';
import { BoxLogsSourceWizardValues } from '../BoxLogsSourceWizard';

const GsuiteLogsSourceConfigurationPanel: React.FC = () => {
  const { initialValues, dirty, isValid } = useFormikContext<BoxLogsSourceWizardValues>();

  const editMode = !!initialValues.integrationId;
  return (
    <WizardPanel>
      <Box width={500} m="auto">
        <WizardPanel.Heading title="Set the credentials of your Box Enterprise App" />
        <OAuthPullerCredentialsFields shouldMaskSecret={editMode} />
      </Box>
      <WizardPanel.Actions>
        {editMode ? (
          <EditIntegrationActions />
        ) : (
          <WizardPanel.ActionNext disabled={!dirty || !isValid}>
            Continue Setup
          </WizardPanel.ActionNext>
        )}
      </WizardPanel.Actions>
    </WizardPanel>
  );
};

export default GsuiteLogsSourceConfigurationPanel;
