/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Flex, Heading, Text, Box } from 'pouncejs';
import NotFoundImg from 'Assets/illustrations/not-found.svg';
import withSEO from 'Hoc/withSEO';
import LinkButton from 'Components/buttons/LinkButton';
import { Permission } from 'Generated/schema';
import useCheckPermissions from 'Hooks/useCheckPermissions';

const Page404: React.FC = () => {
  const canUserAccessSummary = useCheckPermissions(Permission.SummaryRead);

  return (
    <Flex justify="center" align="center" direction="column" height="100%" width="100%">
      <Box mb={10}>
        <img alt="Page not found illustration" src={NotFoundImg} width="auto" height={400} />
      </Box>
      <Heading mb={2}>Not all who wander are lost...</Heading>
      <Text color="gray-300" mb={10}>
        ( You definitely are though )
      </Text>
      {canUserAccessSummary && <LinkButton to="/">Back to Home</LinkButton>}
    </Flex>
  );
};

export default withSEO({ title: 'Not Found' })(Page404);
