/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import * as Types from '../../../../__generated__/schema';

import { PackSourceDetails } from '../../../graphql/fragments/PackSourceDetails.generated';
import { GraphQLError } from 'graphql';
import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';

export type ListAnalysisPackSourcesVariables = {
  input?: Types.Maybe<Types.ListAnalysisPackSourcesInput>;
};

export type ListAnalysisPackSources = {
  listAnalysisPackSources: {
    packs: Array<PackSourceDetails>;
    paging: Pick<Types.PagingData, 'thisPage' | 'totalPages' | 'totalItems'>;
  };
};

export const ListAnalysisPackSourcesDocument = gql`
  query ListAnalysisPackSources($input: ListAnalysisPackSourcesInput) {
    listAnalysisPackSources(input: $input) {
      packs {
        ...PackSourceDetails
      }
      paging {
        thisPage
        totalPages
        totalItems
      }
    }
  }
  ${PackSourceDetails}
`;

/**
 * __useListAnalysisPackSources__
 *
 * To run a query within a React component, call `useListAnalysisPackSources` and pass it any options that fit your needs.
 * When your component renders, `useListAnalysisPackSources` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListAnalysisPackSources({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useListAnalysisPackSources(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    ListAnalysisPackSources,
    ListAnalysisPackSourcesVariables
  >
) {
  return ApolloReactHooks.useQuery<ListAnalysisPackSources, ListAnalysisPackSourcesVariables>(
    ListAnalysisPackSourcesDocument,
    baseOptions
  );
}
export function useListAnalysisPackSourcesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    ListAnalysisPackSources,
    ListAnalysisPackSourcesVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<ListAnalysisPackSources, ListAnalysisPackSourcesVariables>(
    ListAnalysisPackSourcesDocument,
    baseOptions
  );
}
export type ListAnalysisPackSourcesHookResult = ReturnType<typeof useListAnalysisPackSources>;
export type ListAnalysisPackSourcesLazyQueryHookResult = ReturnType<
  typeof useListAnalysisPackSourcesLazyQuery
>;
export type ListAnalysisPackSourcesQueryResult = ApolloReactCommon.QueryResult<
  ListAnalysisPackSources,
  ListAnalysisPackSourcesVariables
>;
export function mockListAnalysisPackSources({
  data,
  variables,
  errors,
}: {
  data: ListAnalysisPackSources;
  variables?: ListAnalysisPackSourcesVariables;
  errors?: GraphQLError[];
}) {
  return {
    request: { query: ListAnalysisPackSourcesDocument, variables },
    result: { data, errors },
  };
}
