/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import * as Types from '../../../../../../__generated__/schema';

import { ComplianceIntegrationDetails } from '../../../../../graphql/fragments/ComplianceIntegrationDetails.generated';
import { GraphQLError } from 'graphql';
import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';

export type ListComplianceSourcesVariables = {};

export type ListComplianceSources = {
  listComplianceIntegrations: Array<ComplianceIntegrationDetails>;
};

export const ListComplianceSourcesDocument = gql`
  query ListComplianceSources {
    listComplianceIntegrations {
      ...ComplianceIntegrationDetails
    }
  }
  ${ComplianceIntegrationDetails}
`;

/**
 * __useListComplianceSources__
 *
 * To run a query within a React component, call `useListComplianceSources` and pass it any options that fit your needs.
 * When your component renders, `useListComplianceSources` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListComplianceSources({
 *   variables: {
 *   },
 * });
 */
export function useListComplianceSources(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    ListComplianceSources,
    ListComplianceSourcesVariables
  >
) {
  return ApolloReactHooks.useQuery<ListComplianceSources, ListComplianceSourcesVariables>(
    ListComplianceSourcesDocument,
    baseOptions
  );
}
export function useListComplianceSourcesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    ListComplianceSources,
    ListComplianceSourcesVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<ListComplianceSources, ListComplianceSourcesVariables>(
    ListComplianceSourcesDocument,
    baseOptions
  );
}
export type ListComplianceSourcesHookResult = ReturnType<typeof useListComplianceSources>;
export type ListComplianceSourcesLazyQueryHookResult = ReturnType<
  typeof useListComplianceSourcesLazyQuery
>;
export type ListComplianceSourcesQueryResult = ApolloReactCommon.QueryResult<
  ListComplianceSources,
  ListComplianceSourcesVariables
>;
export function mockListComplianceSources({
  data,
  variables,
  errors,
}: {
  data: ListComplianceSources;
  variables?: ListComplianceSourcesVariables;
  errors?: GraphQLError[];
}) {
  return {
    request: { query: ListComplianceSourcesDocument, variables },
    result: { data, errors },
  };
}
