/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Flex, Spinner } from 'pouncejs';

export const LazyAPITokenPlayground = React.lazy(() =>
  import(/* webpackChunkName: 'api-token-playground' */ './APITokenPlayground')
);

const LazyAPITokenPlaygroundWithSuspense: React.FC = () => (
  <React.Suspense
    fallback={
      <Flex align="center" justify="center" width="100%" height={400}>
        <Spinner />
      </Flex>
    }
  >
    <LazyAPITokenPlayground />
  </React.Suspense>
);

export default LazyAPITokenPlaygroundWithSuspense;
