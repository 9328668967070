/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import * as Types from '../../../../../../__generated__/schema';

import { GraphQLError } from 'graphql';
import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';

export type ListDataSchemaOptionsVariables = {
  input?: Types.Maybe<Types.ListSchemasInput>;
};

export type ListDataSchemaOptions = {
  listSchemas: { results?: Types.Maybe<Array<Pick<Types.SchemaRecord, 'name'>>> };
};

export const ListDataSchemaOptionsDocument = gql`
  query ListDataSchemaOptions($input: ListSchemasInput) {
    listSchemas(input: $input) {
      results {
        name
      }
    }
  }
`;

/**
 * __useListDataSchemaOptions__
 *
 * To run a query within a React component, call `useListDataSchemaOptions` and pass it any options that fit your needs.
 * When your component renders, `useListDataSchemaOptions` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListDataSchemaOptions({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useListDataSchemaOptions(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    ListDataSchemaOptions,
    ListDataSchemaOptionsVariables
  >
) {
  return ApolloReactHooks.useQuery<ListDataSchemaOptions, ListDataSchemaOptionsVariables>(
    ListDataSchemaOptionsDocument,
    baseOptions
  );
}
export function useListDataSchemaOptionsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    ListDataSchemaOptions,
    ListDataSchemaOptionsVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<ListDataSchemaOptions, ListDataSchemaOptionsVariables>(
    ListDataSchemaOptionsDocument,
    baseOptions
  );
}
export type ListDataSchemaOptionsHookResult = ReturnType<typeof useListDataSchemaOptions>;
export type ListDataSchemaOptionsLazyQueryHookResult = ReturnType<
  typeof useListDataSchemaOptionsLazyQuery
>;
export type ListDataSchemaOptionsQueryResult = ApolloReactCommon.QueryResult<
  ListDataSchemaOptions,
  ListDataSchemaOptionsVariables
>;
export function mockListDataSchemaOptions({
  data,
  variables,
  errors,
}: {
  data: ListDataSchemaOptions;
  variables?: ListDataSchemaOptionsVariables;
  errors?: GraphQLError[];
}) {
  return {
    request: { query: ListDataSchemaOptionsDocument, variables },
    result: { data, errors },
  };
}
