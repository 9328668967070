/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import * as Types from '../../../../../__generated__/schema';

import {
  ActorTeaser_User_,
  ActorTeaser_APIToken_,
} from '../../../../graphql/fragments/ActorTeaser.generated';
import { GraphQLError } from 'graphql';
import gql from 'graphql-tag';
import { ActorTeaser } from '../../../../graphql/fragments/ActorTeaser.generated';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';

export type GetReportSettingAuditInfoVariables = {
  tacticId: Types.Scalars['ID'];
  techniqueId: Types.Scalars['ID'];
};

export type GetReportSettingAuditInfo = {
  reportSetting: Pick<Types.ReportSetting, 'id' | 'updatedAt'> & {
    updatedBy?: Types.Maybe<ActorTeaser_User_ | ActorTeaser_APIToken_>;
  };
};

export const GetReportSettingAuditInfoDocument = gql`
  query GetReportSettingAuditInfo($tacticId: ID!, $techniqueId: ID!) {
    reportSetting(tacticId: $tacticId, techniqueId: $techniqueId) {
      id
      updatedAt
      updatedBy {
        ...ActorTeaser
      }
    }
  }
  ${ActorTeaser}
`;

/**
 * __useGetReportSettingAuditInfo__
 *
 * To run a query within a React component, call `useGetReportSettingAuditInfo` and pass it any options that fit your needs.
 * When your component renders, `useGetReportSettingAuditInfo` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReportSettingAuditInfo({
 *   variables: {
 *      tacticId: // value for 'tacticId'
 *      techniqueId: // value for 'techniqueId'
 *   },
 * });
 */
export function useGetReportSettingAuditInfo(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetReportSettingAuditInfo,
    GetReportSettingAuditInfoVariables
  >
) {
  return ApolloReactHooks.useQuery<GetReportSettingAuditInfo, GetReportSettingAuditInfoVariables>(
    GetReportSettingAuditInfoDocument,
    baseOptions
  );
}
export function useGetReportSettingAuditInfoLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetReportSettingAuditInfo,
    GetReportSettingAuditInfoVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    GetReportSettingAuditInfo,
    GetReportSettingAuditInfoVariables
  >(GetReportSettingAuditInfoDocument, baseOptions);
}
export type GetReportSettingAuditInfoHookResult = ReturnType<typeof useGetReportSettingAuditInfo>;
export type GetReportSettingAuditInfoLazyQueryHookResult = ReturnType<
  typeof useGetReportSettingAuditInfoLazyQuery
>;
export type GetReportSettingAuditInfoQueryResult = ApolloReactCommon.QueryResult<
  GetReportSettingAuditInfo,
  GetReportSettingAuditInfoVariables
>;
export function mockGetReportSettingAuditInfo({
  data,
  variables,
  errors,
}: {
  data: GetReportSettingAuditInfo;
  variables?: GetReportSettingAuditInfoVariables;
  errors?: GraphQLError[];
}) {
  return {
    request: { query: GetReportSettingAuditInfoDocument, variables },
    result: { data, errors },
  };
}
