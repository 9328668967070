/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import * as Types from '../../../../../__generated__/schema';

import { GraphQLError } from 'graphql';
import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';

export type RequestLogTypeVariables = {
  input: Types.RequestLogTypeInput;
};

export type RequestLogType = {
  requestLogType?: Types.Maybe<Pick<Types.RequestLogTypeResponse, 'id' | 'url'>>;
};

export const RequestLogTypeDocument = gql`
  mutation RequestLogType($input: RequestLogTypeInput!) {
    requestLogType(input: $input) {
      id
      url
    }
  }
`;
export type RequestLogTypeMutationFn = ApolloReactCommon.MutationFunction<
  RequestLogType,
  RequestLogTypeVariables
>;

/**
 * __useRequestLogType__
 *
 * To run a mutation, you first call `useRequestLogType` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestLogType` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestLogType, { data, loading, error }] = useRequestLogType({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRequestLogType(
  baseOptions?: ApolloReactHooks.MutationHookOptions<RequestLogType, RequestLogTypeVariables>
) {
  return ApolloReactHooks.useMutation<RequestLogType, RequestLogTypeVariables>(
    RequestLogTypeDocument,
    baseOptions
  );
}
export type RequestLogTypeHookResult = ReturnType<typeof useRequestLogType>;
export type RequestLogTypeMutationResult = ApolloReactCommon.MutationResult<RequestLogType>;
export type RequestLogTypeMutationOptions = ApolloReactCommon.BaseMutationOptions<
  RequestLogType,
  RequestLogTypeVariables
>;
export function mockRequestLogType({
  data,
  variables,
  errors,
}: {
  data: RequestLogType;
  variables?: RequestLogTypeVariables;
  errors?: GraphQLError[];
}) {
  return {
    request: { query: RequestLogTypeDocument, variables },
    result: { data, errors },
  };
}
