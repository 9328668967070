/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Box, Card, Flex, Button, Grid, Heading, Text, IconButton, useSnackbar } from 'pouncejs';
import * as Yup from 'yup';
import { Formik, Form, Field, FieldArray, FastField } from 'formik';
import { DataModelMapping } from 'Generated/schema';
import Breadcrumbs from 'Components/Breadcrumbs';
import LinkButton from 'Components/buttons/LinkButton';
import SaveButton from 'Components/buttons/SaveButton';
import urls from 'Source/urls';
import Panel from 'Components/Panel';
import FormikTextInput from 'Components/fields/TextInput';
import FormikSwitch from 'Components/fields/Switch';
import FormikCombobox from 'Components/fields/ComboBox';
import { useListAvailableLogTypes } from 'Source/graphql/queries';
import FormikEditor from 'Components/fields/Editor';
import useBulkDownload from 'Hooks/useBulkDownload';

export interface DataModelFormValues {
  displayName: string;
  id: string;
  enabled: boolean;
  logType: string;
  mappings: Omit<DataModelMapping, '__typename'>[];
  body?: string;
  parentId?: string;
  managed?: boolean;
}

export interface DataModelFormProps {
  initialValues: DataModelFormValues;
  onSubmit: (values: DataModelFormValues) => Promise<any>;
}

const validationSchema: Yup.SchemaOf<DataModelFormValues> = Yup.object().shape({
  displayName: Yup.string(),
  id: Yup.string().required(),
  enabled: Yup.boolean().required(),
  logType: Yup.string().required(),
  mappings: Yup.array<Omit<DataModelMapping, '__typename'>>()
    .of(
      Yup.object().shape(
        {
          name: Yup.string().required(),
          method: Yup.string()
            // eslint-disable-next-line func-names
            .test('mutex', "You shouldn't provide both a path & method", function (method) {
              return !this.parent.path || !method;
            })
            .when('path', {
              is: path => !path,
              then: Yup.string().required('Either a path or a method must be specified'),
              otherwise: Yup.string(),
            }),
          path: Yup.string()
            // eslint-disable-next-line func-names
            .test('mutex', "You shouldn't provide both a path & method", function (path) {
              return !this.parent.method || !path;
            })
            .when('method', {
              is: method => !method,
              then: Yup.string().required('Either a path or a method must be specified'),
              otherwise: Yup.string(),
            }),
        },
        [['method', 'path']]
      )
    )
    .required()
    .min(1),
  body: Yup.string(),
  parentId: Yup.string(),
  managed: Yup.boolean(),
});

const DataModelForm: React.FC<DataModelFormProps> = ({ initialValues, onSubmit }) => {
  const [isPythonEditorOpen, setPythonEditorVisibility] = React.useState(false);
  const { loading, download } = useBulkDownload({ fileName: initialValues.id });
  const { pushSnackbar } = useSnackbar();
  const { data } = useListAvailableLogTypes({
    onError: () => pushSnackbar({ title: "Couldn't fetch your available log types" }),
  });

  React.useEffect(() => {
    setPythonEditorVisibility(!!initialValues.body);
  }, [initialValues.body]);

  const Actions = React.useMemo(() => {
    return initialValues.id ? (
      <Button disabled={loading} onClick={() => download({ dataModelIds: [initialValues.id] })}>
        Download Data Model
      </Button>
    ) : null;
    // FIXME: look into hook dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValues.id?.length, loading]);

  return (
    <Panel title={initialValues.id?.length ? initialValues.id : 'New Data Model'} actions={Actions}>
      <Formik<DataModelFormValues>
        enableReinitialize
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        {({ values }) => (
          <Form>
            <Breadcrumbs.Actions>
              <Flex justify="flex-end" spacing={4}>
                <LinkButton
                  data-tid="data-model-form-cancel"
                  icon="close-circle"
                  variantColor="gray-600"
                  to={urls.data.dataModels.list()}
                >
                  Cancel
                </LinkButton>
                {initialValues.managed && initialValues.enabled === values.enabled ? (
                  <LinkButton
                    to={{
                      pathname: urls.data.dataModels.create(),
                      state: {
                        ...initialValues,
                        id: '',
                        managed: false,
                        parentId: initialValues.id,
                      },
                    }}
                  >
                    Clone and Edit
                  </LinkButton>
                ) : (
                  <SaveButton data-tid="data-model-form-save">Save</SaveButton>
                )}
              </Flex>
            </Breadcrumbs.Actions>
            <Box as="section" mb={8}>
              <Text color="navyblue-100" mb={6}>
                Settings
              </Text>

              <Grid templateColumns="7fr 7fr 5fr 2fr" gap={5} mb={4}>
                <Field
                  data-tid="data-model-form-name"
                  as={FormikTextInput}
                  label="Display Name"
                  placeholder="A nickname for this data model"
                  name="displayName"
                  required
                  disabled={initialValues.managed}
                />
                <Field
                  data-tid="data-model-form-id"
                  as={FormikTextInput}
                  label="ID"
                  placeholder="An identifier for this data model"
                  name="id"
                  required
                  disabled={initialValues.managed}
                />
                <Field
                  data-tid="data-model-form-log-type"
                  as={FormikCombobox}
                  searchable
                  label="Log Type"
                  name="logType"
                  items={data?.listAvailableLogTypes.logTypes ?? []}
                  placeholder="Where should the rule apply?"
                  showClearSelectionControl={false}
                  disabled={initialValues.managed}
                />
                <Box mt={3}>
                  <Field
                    data-tid="data-model-form-log-enabled"
                    as={FormikSwitch}
                    name="enabled"
                    label="Enabled"
                    placeholder="Toggle Enabled"
                  />
                </Box>
              </Grid>
            </Box>
            <Box as="section">
              <Text color="navyblue-100" mb={6}>
                Data Model Mappings
              </Text>
              <Card as="section" variant="dark" p={4} mb={5}>
                <FieldArray
                  name="mappings"
                  render={arrayHelpers => {
                    return (
                      <Flex direction="column" spacing={4}>
                        {values?.mappings?.map((mapping, index) => (
                          <Grid key={index} templateColumns="9fr 9fr 9fr 2fr" gap={4}>
                            <Field
                              as={FormikTextInput}
                              label="Name"
                              placeholder="The name of the unified data model field"
                              name={`mappings[${index}].name`}
                              required
                              disabled={initialValues.managed}
                            />
                            <Field
                              as={FormikTextInput}
                              label="Field Path"
                              placeholder="The path to the log type field to map to"
                              name={`mappings[${index}].path`}
                              disabled={initialValues.managed}
                            />
                            <Field
                              as={FormikTextInput}
                              label="Field Method"
                              placeholder="A log type method to map to"
                              name={`mappings[${index}].method`}
                              disabled={initialValues.managed}
                            />
                            <Flex spacing={2} mt={2}>
                              {index > 0 && (
                                <IconButton
                                  data-tid="data-model-form-remove-mapping"
                                  variantColor="navyblue-300"
                                  variantBorderStyle="circle"
                                  size="medium"
                                  icon="trash"
                                  aria-label="Remove mapping"
                                  onClick={() => arrayHelpers.remove(index)}
                                  disabled={initialValues.managed}
                                />
                              )}
                              {index === values.mappings.length - 1 && (
                                <IconButton
                                  data-tid="data-model-form-new-mapping"
                                  variantColor="navyblue-300"
                                  variantBorderStyle="circle"
                                  size="medium"
                                  icon="add"
                                  aria-label="Add a new mapping"
                                  onClick={() =>
                                    arrayHelpers.push({ name: '', method: '', path: '' })
                                  }
                                  disabled={initialValues.managed}
                                />
                              )}
                            </Flex>
                          </Grid>
                        ))}
                      </Flex>
                    );
                  }}
                />
              </Card>
              <Card as="section" variant="dark" p={4}>
                <Flex align="center" spacing={4}>
                  <IconButton
                    data-tid="data-model-form-toggle-python"
                    variant="ghost"
                    active={isPythonEditorOpen}
                    variantColor="navyblue-300"
                    variantBorderStyle="circle"
                    icon={isPythonEditorOpen ? 'caret-up' : 'caret-down'}
                    onClick={() => setPythonEditorVisibility(v => !v)}
                    size="medium"
                    aria-label="Toggle Python Editor visibility"
                  />
                  <Heading as="h2" size="x-small">
                    Python Module <i>(optional)</i>
                  </Heading>
                </Flex>
                {isPythonEditorOpen && (
                  <Box mt={4}>
                    <FastField
                      data-tid="data-model-form-edit-python"
                      as={FormikEditor}
                      placeholder="# Enter the body of this mapping..."
                      name="body"
                      width="100%"
                      minHeight={170}
                      language="python"
                      readOnly={initialValues.managed}
                    />
                  </Box>
                )}
              </Card>
            </Box>
          </Form>
        )}
      </Formik>
    </Panel>
  );
};

export default DataModelForm;
