/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import * as Types from '../../../../../../../__generated__/schema';

import { SqsLogSourceIntegrationDetails } from '../../../../../../graphql/fragments/SqsLogSourceIntegrationDetails.generated';
import { GraphQLError } from 'graphql';
import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';

export type AddSqsLogSourceVariables = {
  input: Types.AddSqsLogIntegrationInput;
};

export type AddSqsLogSource = { addSqsLogIntegration: SqsLogSourceIntegrationDetails };

export const AddSqsLogSourceDocument = gql`
  mutation AddSqsLogSource($input: AddSqsLogIntegrationInput!) {
    addSqsLogIntegration(input: $input) {
      ...SqsLogSourceIntegrationDetails
    }
  }
  ${SqsLogSourceIntegrationDetails}
`;
export type AddSqsLogSourceMutationFn = ApolloReactCommon.MutationFunction<
  AddSqsLogSource,
  AddSqsLogSourceVariables
>;

/**
 * __useAddSqsLogSource__
 *
 * To run a mutation, you first call `useAddSqsLogSource` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddSqsLogSource` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addSqsLogSource, { data, loading, error }] = useAddSqsLogSource({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddSqsLogSource(
  baseOptions?: ApolloReactHooks.MutationHookOptions<AddSqsLogSource, AddSqsLogSourceVariables>
) {
  return ApolloReactHooks.useMutation<AddSqsLogSource, AddSqsLogSourceVariables>(
    AddSqsLogSourceDocument,
    baseOptions
  );
}
export type AddSqsLogSourceHookResult = ReturnType<typeof useAddSqsLogSource>;
export type AddSqsLogSourceMutationResult = ApolloReactCommon.MutationResult<AddSqsLogSource>;
export type AddSqsLogSourceMutationOptions = ApolloReactCommon.BaseMutationOptions<
  AddSqsLogSource,
  AddSqsLogSourceVariables
>;
export function mockAddSqsLogSource({
  data,
  variables,
  errors,
}: {
  data: AddSqsLogSource;
  variables?: AddSqsLogSourceVariables;
  errors?: GraphQLError[];
}) {
  return {
    request: { query: AddSqsLogSourceDocument, variables },
    result: { data, errors },
  };
}
