/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Flex } from 'pouncejs';
import FadeInTrail from 'Components/utils/FadeInTrail';
import urls from 'Source/urls';
import { Permission } from 'Generated/schema';
import RoleRestrictedAccess from 'Components/utils/RoleRestrictedAccess';
import { NavigationLink } from 'Components/Navigation/Navigation';
import NavLink from '../NavLink';

export const integrationNavigationsLinks: NavigationLink[] = [
  {
    to: urls.integrations.destinations.list(),
    icon: 'output',
    label: 'Alert Destinations',
    permissions: [Permission.DestinationRead, Permission.DestinationModify],
  },
  {
    to: urls.integrations.logSources.list({ disableDefaultParams: false }),
    icon: 'log-source',
    label: 'Log Sources',
    permissions: [Permission.LogSourceRead, Permission.LogSourceModify],
  },
  {
    to: urls.integrations.cloudAccounts.list(),
    icon: 'cloud-security',
    label: 'Cloud Accounts',
    permissions: [Permission.CloudsecSourceRead, Permission.CloudsecSourceModify],
  },
];

const IntegrationsNavigation: React.FC = () => {
  return (
    <Flex direction="column" as="ul">
      <FadeInTrail as="li">
        {integrationNavigationsLinks.map(({ to, icon, label, permissions }) => {
          return permissions?.length ? (
            <RoleRestrictedAccess key={label} allowedPermissions={permissions}>
              <NavLink icon={icon} label={label} to={to} isSecondary />
            </RoleRestrictedAccess>
          ) : (
            <NavLink key={label} icon={icon} label={label} to={to} isSecondary />
          );
        })}
      </FadeInTrail>
    </Flex>
  );
};

export default React.memo(IntegrationsNavigation);
