/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import * as Types from '../../../__generated__/schema';

import { ActorTeaser_User_, ActorTeaser_APIToken_ } from './ActorTeaser.generated';
import { IntegrationItemHealthDetails } from './IntegrationItemHealthDetails.generated';
import { GraphQLError } from 'graphql';
import gql from 'graphql-tag';
import { ActorTeaser } from './ActorTeaser.generated';

export type CloudWatchLogIntegrationDetails = Pick<
  Types.CloudWatchLogIntegration,
  | 'integrationId'
  | 'integrationLabel'
  | 'integrationType'
  | 'awsAccountId'
  | 'createdAtTime'
  | 'logTypes'
  | 'lastEventReceived'
  | 'lastModified'
  | 'logProviderId'
  | 's3Bucket'
  | 'managedBucketNotifications'
  | 'notificationsConfigurationSucceeded'
  | 'logProcessingRole'
  | 'logGroup'
  | 'stackName'
  | 'filterPattern'
  | 'isHealthy'
  | 'isEditable'
  | 'isPantherManaged'
> & {
  createdBy?: Types.Maybe<ActorTeaser_User_ | ActorTeaser_APIToken_>;
  health: {
    processingRoleStatus: IntegrationItemHealthDetails;
    s3BucketStatus?: Types.Maybe<IntegrationItemHealthDetails>;
    bucketNotificationsStatus?: Types.Maybe<IntegrationItemHealthDetails>;
    systemAlarmStatus?: Types.Maybe<IntegrationItemHealthDetails>;
    logProcessingErrorsStatus?: Types.Maybe<IntegrationItemHealthDetails>;
    classificationFailuresStatus?: Types.Maybe<IntegrationItemHealthDetails>;
  };
  alarms: { eventThreshold?: Types.Maybe<Pick<Types.EventThresholdAlarm, 'minutesThreshold'>> };
};

export const CloudWatchLogIntegrationDetails = gql`
  fragment CloudWatchLogIntegrationDetails on CloudWatchLogIntegration {
    integrationId
    integrationLabel
    integrationType
    awsAccountId
    createdAtTime
    createdBy {
      ...ActorTeaser
    }
    logTypes
    lastEventReceived
    lastModified
    logProviderId
    s3Bucket
    managedBucketNotifications
    notificationsConfigurationSucceeded
    logProcessingRole
    logGroup
    stackName
    filterPattern
    health {
      processingRoleStatus {
        ...IntegrationItemHealthDetails
      }
      s3BucketStatus {
        ...IntegrationItemHealthDetails
      }
      bucketNotificationsStatus {
        ...IntegrationItemHealthDetails
      }
      systemAlarmStatus {
        ...IntegrationItemHealthDetails
      }
      logProcessingErrorsStatus {
        ...IntegrationItemHealthDetails
      }
      classificationFailuresStatus {
        ...IntegrationItemHealthDetails
      }
    }
    isHealthy
    alarms {
      eventThreshold {
        minutesThreshold
      }
    }
    isEditable
    isPantherManaged
  }
  ${ActorTeaser}
  ${IntegrationItemHealthDetails}
`;
