/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';

import { Form, Formik, FastField } from 'formik';
import { SortDirEnum, ListDetectionsInput, ListDetectionsSortFieldsEnum } from 'Generated/schema';
import { Box, Flex } from 'pouncejs';
import pick from 'lodash/pick';
import {
  extractSortingOptions,
  SortingOption,
  WrapSortingFormValues,
  wrapSortingOptions,
} from 'Helpers/utils';
import useRequestParamsWithPagination from 'Hooks/useRequestParamsWithPagination';
import FormikAutosave from 'Components/utils/Autosave';
import FormikCombobox from 'Components/fields/ComboBox';
import FormikTextInput from 'Components/fields/TextInput';

import DropdownFilters from './DropdownFilters';

export type ListDetectionsInlineFiltersValues = Pick<
  ListDetectionsInput,
  'nameContains' | 'sortBy' | 'sortDir'
>;

const filters: (keyof ListDetectionsInput)[] = ['nameContains', 'sortBy', 'sortDir'];

const defaultValues = {
  nameContains: '',
  sorting: null,
};

const sortingOpts: SortingOption<ListDetectionsInlineFiltersValues>[] = [
  {
    opt: 'Display name (A-Z)',
    resolution: {
      sortBy: ListDetectionsSortFieldsEnum.DisplayName,
      sortDir: SortDirEnum.Ascending,
    },
  },
  {
    opt: 'Display name (Z-A)',
    resolution: {
      sortBy: ListDetectionsSortFieldsEnum.DisplayName,
      sortDir: SortDirEnum.Descending,
    },
  },
  {
    opt: 'Most Recently Modified',
    resolution: {
      sortBy: ListDetectionsSortFieldsEnum.LastModified,
      sortDir: SortDirEnum.Descending,
    },
  },
  {
    opt: 'Oldest Modified',
    resolution: {
      sortBy: ListDetectionsSortFieldsEnum.LastModified,
      sortDir: SortDirEnum.Ascending,
    },
  },
  {
    opt: 'Info to Critical',
    resolution: {
      sortBy: ListDetectionsSortFieldsEnum.Severity,
      sortDir: SortDirEnum.Ascending,
    },
  },
  {
    opt: 'Critical to Info',
    resolution: {
      sortBy: ListDetectionsSortFieldsEnum.Severity,
      sortDir: SortDirEnum.Descending,
    },
  },
  {
    opt: 'Enabled to Disabled',
    resolution: {
      sortBy: ListDetectionsSortFieldsEnum.Enabled,
      sortDir: SortDirEnum.Ascending,
    },
  },
  {
    opt: 'Disabled to Enabled',
    resolution: {
      sortBy: ListDetectionsSortFieldsEnum.Enabled,
      sortDir: SortDirEnum.Descending,
    },
  },
];

const sortingItems = sortingOpts.map(sortingOption => sortingOption.opt);

const ListDetectionsFilters: React.FC = () => {
  const { requestParams, updateRequestParamsAndResetPaging } = useRequestParamsWithPagination<
    ListDetectionsInput
  >();
  const initialFilterValues = React.useMemo(
    () => ({
      ...defaultValues,
      ...wrapSortingOptions(pick(requestParams, filters), sortingOpts),
    }),
    [requestParams]
  );
  return (
    <>
      <Formik<WrapSortingFormValues<ListDetectionsInlineFiltersValues>>
        enableReinitialize
        initialValues={initialFilterValues}
        onSubmit={values => {
          updateRequestParamsAndResetPaging(extractSortingOptions(values, sortingOpts));
        }}
      >
        <Form>
          <FormikAutosave threshold={200} />
          <Flex spacing={4} align="center">
            <Box minWidth={405}>
              <FastField
                data-tid="detections-nameContains-filter"
                name="nameContains"
                icon="search"
                iconAlignment="left"
                as={FormikTextInput}
                label="Filter detections by text"
                placeholder="Search for a detection..."
              />
            </Box>
            <Box minWidth={225}>
              <FastField
                data-tid="detections-sorting-options-combobox"
                name="sorting"
                as={FormikCombobox}
                items={sortingItems}
                label="Sort By"
                placeholder="Select a sorting option"
              />
            </Box>
          </Flex>
        </Form>
      </Formik>
      <DropdownFilters />
    </>
  );
};

export default React.memo(ListDetectionsFilters);
