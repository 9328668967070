/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Flex } from 'pouncejs';
import urls from 'Source/urls';
import { Permission } from 'Generated/schema';
import RoleRestrictedAccess from 'Components/utils/RoleRestrictedAccess';
import FadeInTrail from 'Components/utils/FadeInTrail';
import { NavigationLink } from 'Components/Navigation';
import { ENABLE_REPORTS } from 'Source/constants';
import NavLink from '../NavLink';

const allDetectionsLink: NavigationLink = {
  to: urls.detections.list(),
  icon: 'lookups',
  label: 'All Detections',
  permissions: [
    Permission.RuleRead,
    Permission.PolicyRead,
    Permission.RuleModify,
    Permission.PolicyModify,
  ],
};

const packLink: NavigationLink = {
  to: `${urls.analysis.packs.list({ disableDefaultParams: false })}`,
  icon: 'packs',
  label: 'Packs',
  permissions: [
    Permission.RuleRead,
    Permission.PolicyRead,
    Permission.RuleModify,
    Permission.PolicyModify,
  ],
};

const mitreReportLink: NavigationLink = {
  to: urls.reports.mitreMatrix(),
  icon: 'organization',
  label: 'MITRE ATT&CK®',
  permissions: [
    Permission.RuleRead,
    Permission.PolicyRead,
    Permission.RuleModify,
    Permission.PolicyModify,
  ],
};

export const detectionsNavigationsLinks: NavigationLink[] = ENABLE_REPORTS
  ? [allDetectionsLink, mitreReportLink, packLink]
  : [allDetectionsLink, packLink];

const AnalysisNavigation: React.FC = () => {
  return (
    <Flex direction="column" as="ul" spacing={1}>
      <FadeInTrail as="li">
        {detectionsNavigationsLinks.map(({ to, icon, label, permissions }) => {
          return permissions?.length ? (
            <RoleRestrictedAccess key={label} allowedPermissions={permissions}>
              <NavLink icon={icon} label={label} to={to} isSecondary />
            </RoleRestrictedAccess>
          ) : (
            <NavLink key={label} icon={icon} label={label} to={to} isSecondary />
          );
        })}
      </FadeInTrail>
    </Flex>
  );
};

export default React.memo(AnalysisNavigation);
