/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { GithubLogIntegrationDetails } from 'Source/graphql/fragments/GithubLogIntegrationDetails.generated';
import { Wizard } from 'Components/Wizard';
import { GithubAuthorizationMethodEnum } from 'Generated/schema';
import { yupIntegrationLabelUniqueness } from 'Helpers/utils';
import useFindSourcesPerMethod from 'Hooks/useFindSourcesPerMethod';
import { LogTransportMethodsEnum } from 'Source/constants';
import CancelIntegrationAction from 'Pages/Integrations/components/CancelIntegrationAction';
import ValidationPanel from './ValidationPanel';
import ConfigurationPanel from './ConfigurationPanel';
import CredentialsPanel from './CredentialsPanel';

interface GithubLogsWizardProps {
  initialValues: GithubLogsSourceWizardValues;
  onSubmit: (values: GithubLogsSourceWizardValues) => Promise<GithubLogIntegrationDetails | Error>;
}

export interface GithubLogsSourceWizardValues {
  integrationId?: string;
  integrationLabel: string;
  organization: string;
  clientId: string;
  clientSecret: string;
  personalAccessToken: string;
  authorizationMethod: GithubAuthorizationMethodEnum;
}

const GithubLogsSourceWizard: React.FC<GithubLogsWizardProps> = ({ initialValues, onSubmit }) => {
  const existingPullerSources = useFindSourcesPerMethod(LogTransportMethodsEnum.logPulling);

  const validationSchema: Yup.SchemaOf<GithubLogsSourceWizardValues> = React.useMemo(() => {
    const isUpdateFromOAuth2 = Boolean(
      initialValues.integrationId &&
        initialValues.authorizationMethod === GithubAuthorizationMethodEnum.Oauth2
    );
    const isUpdateFromPat = Boolean(
      initialValues.integrationId &&
        initialValues.authorizationMethod === GithubAuthorizationMethodEnum.Pat
    );

    return Yup.object().shape({
      integrationId: Yup.string(),
      integrationLabel: yupIntegrationLabelUniqueness({
        existingLabel: initialValues.integrationLabel,
        existingSources: existingPullerSources,
      }),
      organization: Yup.string().required(),
      authorizationMethod: Yup.string()
        .oneOf(Object.values(GithubAuthorizationMethodEnum))
        .required(),
      clientId: Yup.string().when('authorizationMethod', {
        is: authorizationMethod => authorizationMethod === GithubAuthorizationMethodEnum.Pat,
        then: Yup.string(),
        otherwise: Yup.string().required(),
      }),
      clientSecret: Yup.string().when('authorizationMethod', {
        is: authorizationMethod =>
          authorizationMethod === GithubAuthorizationMethodEnum.Pat || isUpdateFromOAuth2,
        then: Yup.string(),
        otherwise: Yup.string().required(),
      }),
      personalAccessToken: Yup.string().when('authorizationMethod', {
        is: authorizationMethod =>
          authorizationMethod === GithubAuthorizationMethodEnum.Oauth2 || isUpdateFromPat,
        then: Yup.string(),
        otherwise: Yup.string().required(),
      }),
    });
  }, [
    initialValues.integrationId,
    initialValues.integrationLabel,
    initialValues.authorizationMethod,
    existingPullerSources,
  ]);

  const editMode = !!initialValues.integrationId;
  return (
    <Formik<GithubLogsSourceWizardValues>
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      <Form>
        <Wizard headerTitle="Connecting a new Log Source" enableAllNavigationSteps={editMode}>
          <Wizard.Step
            title="Configure Source"
            description="Provide account information and set preferences to connect the log source to Panther"
          >
            <ConfigurationPanel />
          </Wizard.Step>
          <Wizard.Step
            title="Set Credentials"
            description="Provide account credentials to give Panther permissions to collect logs from the source"
          >
            <CredentialsPanel />
          </Wizard.Step>
          <Wizard.Step
            title="Verify Setup"
            description="Confirm that your log source has been successfully onboarded or troubleshoot any issues"
            navigationDisabled={editMode}
          >
            <ValidationPanel />
          </Wizard.Step>
        </Wizard>
        {!editMode && <CancelIntegrationAction />}
      </Form>
    </Formik>
  );
};

export default GithubLogsSourceWizard;
