/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';

export interface UseTimerProps {
  lazy?: boolean;
  from?: number;
  backwards?: boolean;
  interval?: number;
}

const useTimer = ({
  lazy = false,
  from = 0,
  backwards = false,
  interval = 1000,
}: UseTimerProps = {}) => {
  const [isCounting, setCounting] = React.useState(!lazy);
  const [timeElapsed, setTimeElapsed] = React.useState(from);

  const start = React.useCallback(() => {
    setCounting(true);
  }, [setCounting]);

  const stop = React.useCallback(() => {
    setCounting(false);
  }, [setCounting]);

  const reset = React.useCallback(() => {
    setTimeElapsed(from);
  }, [from, setTimeElapsed]);

  // eslint-disable-next-line consistent-return
  React.useEffect(() => {
    if (isCounting) {
      const intervalInstance = setInterval(() => {
        setTimeElapsed(timeElapsed + interval * (backwards ? -1 : 1));
      }, interval);

      return () => clearInterval(intervalInstance);
    }
    // FIXME: look into hook dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCounting, timeElapsed]);

  React.useEffect(() => {
    if (backwards && timeElapsed <= 0) {
      setTimeElapsed(0);
      stop();
    }
  }, [timeElapsed, backwards, stop]);

  return { start, stop, reset, timeElapsed };
};

export default useTimer;
