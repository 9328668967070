/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import dayjs from 'dayjs';
import { Alert, Box, useSnackbar } from 'pouncejs';
import { useGetGeneralSettings } from 'Pages/GeneralSettings/graphql/getGeneralSettings.generated';
import { extractErrorMessage } from 'Helpers/utils';

const DEFAULT_DATA_HORIZON_DAYS = 30;
const NEW_DATA_AVAILABILITY_HOURS = 24;

type ReplayEditorTimeWarningProps = {
  endsAt: string;
  startsAt: string;
};

const ReplayEditorTimeWarning: React.FC<ReplayEditorTimeWarningProps> = ({ endsAt, startsAt }) => {
  const now = dayjs();

  const nowBeforeStart = now.clone().isBefore(dayjs(startsAt));
  const nowBeforeEnd = now.clone().isBefore(dayjs(endsAt));

  const { pushSnackbar } = useSnackbar();
  const { data, loading } = useGetGeneralSettings({
    onError: err =>
      pushSnackbar({
        title: 'Failed to load settings',
        variant: 'error',
        description: extractErrorMessage(err),
      }),
  });

  const dataHorizonDays =
    data?.generalSettings?.infrastructureInfo?.storage?.processedDataRetentionDays ||
    DEFAULT_DATA_HORIZON_DAYS;

  if (nowBeforeStart || nowBeforeEnd) {
    return (
      <Box pt={4}>
        <Alert
          title="Your time range includes dates that are after the current date."
          variant="error"
        />
      </Box>
    );
  }

  const timeSinceStart = now.diff(dayjs(startsAt), 'days');
  const startOverHorizon = timeSinceStart > dataHorizonDays;

  const availabilityHorizon = now.clone().subtract(NEW_DATA_AVAILABILITY_HOURS, 'hours');
  const endIsAfterAvailabilityHorizon = dayjs(endsAt).isAfter(availabilityHorizon);

  const alerts = [];

  if (!loading && startOverHorizon) {
    alerts.push(
      <Alert
        title={`Your time range includes dates that are over ${dataHorizonDays} days ago. Historical log data may not be available for replays past this point.`}
        variant="warning"
      />
    );
  }

  if (endIsAfterAvailabilityHorizon) {
    alerts.push(
      <Alert
        title={`Your time range includes dates that are within the last ${NEW_DATA_AVAILABILITY_HOURS} hours. Recent log data may not yet be available for replays.`}
        variant="warning"
      />
    );
  }

  if (alerts.length > 0) {
    return (
      <Box>
        {alerts.map((alert, idx) => (
          <Box pt={4} key={idx}>
            {alert}
          </Box>
        ))}
      </Box>
    );
  }

  return null;
};

export default ReplayEditorTimeWarning;
