/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import { generateRandomColor } from 'Helpers/utils';
import storage from 'Helpers/storage';
import React from 'react';

export interface UseCloneDataProps {
  /** Unique identifier for the "sessioned" values */
  prefix: string;
}

/**
 * Helps save & restore values from the local storage
 */
function useCloneData({ prefix = '' }: UseCloneDataProps) {
  const setCloneData = React.useCallback(
    (data: any): string => {
      const key = `${prefix}${generateRandomColor()}`;
      storage.local.write(key, data);
      return key;
    },
    [prefix]
  );

  const readCloneData = React.useCallback((cloneId: string): any => {
    const data = storage.local.read(cloneId);
    return data;
  }, []);

  const deleteCloneData = React.useCallback((cloneId: string): void => {
    storage.local.delete(cloneId);
  }, []);

  return { setCloneData, readCloneData, deleteCloneData };
}

export default useCloneData;
