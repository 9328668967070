/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Box, Link, Table } from 'pouncejs';
import urls from 'Source/urls';
import { Link as RRLink } from 'react-router-dom';
import { Permission } from 'Generated/schema';
import RoleRestrictedAccess from 'Components/utils/RoleRestrictedAccess';
import SeverityBadge from 'Components/badges/SeverityBadge';
import StatusBadge from 'Components/badges/StatusBadge';
import { ResourceDetails } from '../graphql/resourceDetails.generated';
import ResourceDetailsTableRowOptions from './ResourceDetailsTableRowOptions';

export type ResourceDetailsTableItem = ResourceDetails['policiesForResource']['items'][0];

interface ResourcesDetailsTableProps {
  policies?: ResourceDetailsTableItem[];
}

const ResourcesDetailsTable: React.FC<ResourcesDetailsTableProps> = ({ policies }) => {
  return (
    <Table>
      <Table.Head>
        <Table.Row>
          <Table.HeaderCell>Policy</Table.HeaderCell>
          <Table.HeaderCell align="center">Status</Table.HeaderCell>
          <Table.HeaderCell align="center">Severity</Table.HeaderCell>
          <RoleRestrictedAccess
            allowedPermissions={[Permission.PolicyModify, Permission.ResourceModify]}
          >
            <Table.HeaderCell />
          </RoleRestrictedAccess>
        </Table.Row>
      </Table.Head>
      <Table.Body>
        {policies.map(policy => (
          <Table.Row key={policy.policyId}>
            <Table.Cell truncated title={policy.policyId}>
              <Link as={RRLink} to={urls.analysis.policies.details(policy.policyId)} py={4} pr={4}>
                {policy.policyId}
              </Link>
            </Table.Cell>
            <Table.Cell align="center">
              <Box my={-1} display="inline-block">
                <StatusBadge
                  status={policy.status}
                  disabled={policy.suppressed}
                  errorMessage={policy.errorMessage}
                  disabledLabel="IGNORED"
                />
              </Box>
            </Table.Cell>
            <Table.Cell align="center">
              <Box my={-1} display="inline-block">
                <SeverityBadge severity={policy.policySeverity} />
              </Box>
            </Table.Cell>
            <RoleRestrictedAccess
              allowedPermissions={[Permission.PolicyModify, Permission.ResourceModify]}
            >
              <Table.Cell align="right">
                <Box my={-2}>
                  <ResourceDetailsTableRowOptions complianceItem={policy} />
                </Box>
              </Table.Cell>
            </RoleRestrictedAccess>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  );
};

export default React.memo(ResourcesDetailsTable);
