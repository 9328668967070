/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Box, theme, Theme } from 'pouncejs';

interface RadialProgressProps {
  size?: number;
  color?: keyof Theme['colors'];
  progress: number;
  strokeWidth?: number;
  outlineColor?: keyof Theme['colors'];
}

const fullCircle = 2 * Math.PI * 0.9999999;

export function makeCircleArcPath(radius: number, percent: number): string {
  const end = -Math.min(fullCircle * Math.abs(percent), fullCircle);

  const start = 0;
  const cx = radius;
  const cy = radius;

  const length = end - start;
  if (length === 0) {
    return '';
  }

  const fromX = radius * Math.cos(start) + cx;
  const fromY = radius * Math.sin(start) + cy;
  const toX = radius * Math.cos(end) + cx;
  const toY = radius * Math.sin(end) + cy;
  const large = Math.abs(length) <= Math.PI ? '0' : '1';
  const sweep = length < 0 ? '0' : '1';

  return `M ${fromX} ${fromY} A ${radius} ${radius} 0 ${large} ${sweep} ${toX} ${toY}`;
}

const RadialProgress = ({
  progress,
  size = 50,
  strokeWidth = 4,
  color = 'teal-200',
  outlineColor = 'navyblue-200',
}: RadialProgressProps) => {
  const radius = size / 2;
  const innerSize = size - strokeWidth;
  const innerRadius = innerSize / 2;
  const translateXY = strokeWidth / 2;

  return (
    <Box
      role="progressbar"
      aria-label="Progress Indicator"
      aria-valuenow={progress * 100}
      aria-valuemin="0"
      aria-valuemax="100"
    >
      <svg width={size} height={size} xmlns="http://www.w3.org/2000/svg">
        <g transform={`rotate(-90,${radius},${radius}) translate(${translateXY},${translateXY}) `}>
          <path
            d={makeCircleArcPath(innerRadius, 1)}
            fill="transparent"
            stroke={theme.colors[outlineColor]}
            strokeWidth={strokeWidth}
          />
          <path
            d={makeCircleArcPath(innerRadius, progress)}
            fill="transparent"
            stroke={theme.colors[color]}
            strokeWidth={strokeWidth}
          />
        </g>
      </svg>
    </Box>
  );
};

export default React.memo(RadialProgress);
