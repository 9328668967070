/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { FadeIn, Box, Flex } from 'pouncejs';
import TablePlaceholder from 'Components/TablePlaceholder';
import Panel from 'Components/Panel';

const DetailsPageSkeleton = () => {
  return (
    <FadeIn from="bottom">
      <Flex spacing={5}>
        <Box as="section">
          <Box width={269}>
            <Panel title="Source">
              <TablePlaceholder rowGap={5} rowCount={4} rowHeight={50} />
            </Panel>
          </Box>
          <Box width={269} mt={5}>
            <Panel title="Basic Info">
              <TablePlaceholder rowGap={5} rowCount={4} rowHeight={50} />
            </Panel>
          </Box>
        </Box>
        <Panel title="Overview">
          <TablePlaceholder />
        </Panel>
      </Flex>
    </FadeIn>
  );
};

export default DetailsPageSkeleton;
