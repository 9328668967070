/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Box, IconButton, Table } from 'pouncejs';
import { formatJSON, countLineBreaks } from 'Helpers/utils';
import { ENABLE_DATA_EXPLORER_TRUNCATION } from 'Source/constants';

export interface ResultsTableRowProps {
  value: any;
  onClick: (data: unknown) => void;
}

export const getCellAlignment = (value: any) => {
  return ['number', 'bigint'].includes(typeof value) ? 'right' : 'left';
};

const toDisplayValue = (value: any) => (typeof value !== 'string' ? formatJSON(value) : value);

const TRUNCATE_THRESHOLD = 5;

const ResultsTableColumn: React.FC<ResultsTableRowProps> = ({ value, onClick }) => {
  const alignment = getCellAlignment(value);
  const cellValue = toDisplayValue(value);
  const lineBreakCount = countLineBreaks(cellValue);

  const shouldTruncateResult = lineBreakCount > TRUNCATE_THRESHOLD;
  return (
    <Table.Cell
      wrapText="nowrap"
      // @ts-ignore
      cursor="pointer"
      sx={{ whiteSpace: 'pre' }}
      _hover={{
        backgroundColor: 'navyblue-700',
        '[data-copy-to-clipboard]': {
          opacity: 1,
          visibility: 'visible',
        },
      }}
      onClick={() => onClick(toDisplayValue(value))}
      align={alignment}
      mono={alignment === 'right'}
      position="relative"
    >
      <Box
        position="absolute"
        opacity={0}
        visibility="hidden"
        zIndex={1110}
        top={-10}
        right={0}
        data-copy-to-clipboard
      >
        <IconButton
          size="small"
          variantBorderStyle="circle"
          aria-label="Copy to clipboard"
          icon="copy"
          variantColor="navyblue-700"
        />
      </Box>
      <Box zIndex={10} position="relative">
        {ENABLE_DATA_EXPLORER_TRUNCATION && shouldTruncateResult ? (
          <Box>
            {cellValue
              .split(/\r?\n/)
              .slice(0, TRUNCATE_THRESHOLD + 1)
              .join('\r\n')}{' '}
            <span style={{ fontWeight: 'bold' }}>...</span>
          </Box>
        ) : (
          cellValue
        )}
      </Box>
    </Table.Cell>
  );
};

export default React.memo(ResultsTableColumn);
