/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import TablePlaceholder from 'Components/TablePlaceholder';
import { Card, FadeIn } from 'pouncejs';

const SchemasManagementSkeleton: React.FC = () => {
  return (
    <FadeIn from="bottom">
      <TablePlaceholder rowCount={1} rowHeight={15} />
      <Card p={9} mt={5}>
        <TablePlaceholder />
      </Card>
    </FadeIn>
  );
};

export default SchemasManagementSkeleton;
