/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */
import React from 'react';
import { Link as RRLink } from 'react-router-dom';
import { Flex, Link, Text } from 'pouncejs';

interface CardMetaValueProps {
  label?: string;
  labelColor?: 'gray' | 'blue';
  value: string | number;
  link?: string;
}

const CardMetaValue: React.FC<CardMetaValueProps> = ({
  label,
  value,
  labelColor = 'blue',
  link,
  ...rest
}) => {
  return (
    <Flex fontSize="small">
      {label && (
        <Text mr={1} color={labelColor === 'blue' ? 'navyblue-100' : 'gray-300'}>
          {label}
        </Text>
      )}
      {link ? (
        <Link as={RRLink} to={link} {...rest}>
          <Text textDecoration="underline" color="white-200" truncated>
            {value}
          </Text>
        </Link>
      ) : (
        <Text truncated {...rest}>
          {value}
        </Text>
      )}
    </Flex>
  );
};

export default CardMetaValue;
