/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Form, Formik, FastField } from 'formik';
import { SortDirEnum } from 'Generated/schema';
import { Box, Flex } from 'pouncejs';
import pick from 'lodash/pick';
import useRequestParamsWithoutPagination from 'Hooks/useRequestParamsWithoutPagination';
import FormikAutosave from 'Components/utils/Autosave';
import FormikCombobox from 'Components/fields/ComboBox';
import FormikTextInput from 'Components/fields/TextInput';
import DropdownFilters from './DropdownFilters';

export type ListComplianceSourceInlineFilterValues = {
  sortDir?: SortDirEnum;
  contains?: string;
};

const sortDirOpts = {
  [SortDirEnum.Descending]: 'Most Recent',
  [SortDirEnum.Ascending]: 'Oldest',
};

const sortingOpts = Object.keys(sortDirOpts);
const sortDirToString = (sortDir: SortDirEnum) => sortDirOpts[sortDir];

export const filters = ['sortDir', 'contains'] as (keyof ListComplianceSourceInlineFilterValues)[];

const defaultValues = {
  contains: '',
  sortDir: null,
};

const ListComplianceSourceFilters: React.FC = () => {
  const { requestParams, updateRequestParams } = useRequestParamsWithoutPagination<
    ListComplianceSourceInlineFilterValues
  >();
  const initialFilterValues = React.useMemo(
    () => ({
      ...defaultValues,
      ...pick(requestParams, filters),
    }),
    [requestParams]
  );

  return (
    <Flex align="center">
      <Formik<ListComplianceSourceInlineFilterValues>
        enableReinitialize
        initialValues={initialFilterValues}
        onSubmit={(values: ListComplianceSourceInlineFilterValues) => {
          updateRequestParams(values);
        }}
      >
        <Form>
          <FormikAutosave threshold={200} />
          <Flex spacing={4} align="center" pr={4}>
            <Box minWidth={365}>
              <FastField
                name="contains"
                icon="search"
                iconAlignment="left"
                as={FormikTextInput}
                label="Filter Cloud Accounts by text"
                placeholder="Search for a Cloud Account..."
              />
            </Box>
            <Box minWidth={200}>
              <FastField
                name="sortDir"
                as={FormikCombobox}
                items={sortingOpts}
                itemToString={sortDirToString}
                label="Sort By"
                placeholder="Select a sorting option"
              />
            </Box>
          </Flex>
        </Form>
      </Formik>
      <Box pr={4}>
        <DropdownFilters />
      </Box>
    </Flex>
  );
};

export default React.memo(ListComplianceSourceFilters);
