/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */
import React from 'react';
import urls from 'Source/urls';
import { useSnackbar } from 'pouncejs';
import { PolicyDetails } from 'Source/graphql/fragments/PolicyDetails.generated';
import { extractErrorMessage } from 'Helpers/utils';
import useRouter from 'Hooks/useRouter';
import useCloneData from './useCloneData';
import { useGetPolicyDetailsLazyQuery } from './graphql/getPolicyDetails.generated';

/**
 * Helps retrieve and create a new instance for policy creation
 */
function useClonePolicy() {
  const { history } = useRouter();
  const { setCloneData, readCloneData } = useCloneData({ prefix: 'policy-clone-' });
  const { pushSnackbar } = useSnackbar();

  const retrieveClonedPolicy = React.useCallback(
    (cloneId: string): PolicyDetails => {
      return readCloneData(cloneId) as PolicyDetails;
    },
    [readCloneData]
  );

  const [clone, { loading: isCloning, error }] = useGetPolicyDetailsLazyQuery({
    fetchPolicy: 'network-only', // Don't use cache
    onCompleted: data => {
      const { policy } = data;
      if (!policy) {
        pushSnackbar({
          variant: 'error',
          title: 'Policy not found',
          description: 'The original policy used for cloning might have been deleted',
        });
      } else {
        const cloneId = setCloneData({
          ...policy,
          id: `${policy.id}_copy`,
          displayName: `${policy.displayName} (Copy)`,
          managed: false,
          parentId: policy.id,
        });
        history.push(`${urls.analysis.policies.create()}&cloneId=${cloneId}`);
      }
    },
    onError: err => {
      pushSnackbar({
        variant: 'error',
        title: 'Failed to retrieve policy',
        description: extractErrorMessage(err),
      });
    },
  });

  // FIXME: variables can ONLY be declared on invocation, due to a bug with Apollo and useLazyQuery
  // https://github.com/apollographql/react-apollo/issues/3729#issuecomment-584242862
  const clonePolicy = React.useCallback(
    (id: string, versionId?: string) => clone({ variables: { input: { id, versionId } } }),
    [clone]
  );

  return { isCloning, error, clonePolicy, retrieveClonedPolicy };
}

export default useClonePolicy;
