/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import * as Types from '../../../../../../__generated__/schema';

import { DestinationDetails } from '../../../../../graphql/fragments/DestinationDetails.generated';
import { GraphQLError } from 'graphql';
import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';

export type UpdateDestinationVariables = {
  input: Types.DestinationInput;
};

export type UpdateDestination = { updateDestination?: Types.Maybe<DestinationDetails> };

export const UpdateDestinationDocument = gql`
  mutation UpdateDestination($input: DestinationInput!) {
    updateDestination(input: $input) {
      ...DestinationDetails
    }
  }
  ${DestinationDetails}
`;
export type UpdateDestinationMutationFn = ApolloReactCommon.MutationFunction<
  UpdateDestination,
  UpdateDestinationVariables
>;

/**
 * __useUpdateDestination__
 *
 * To run a mutation, you first call `useUpdateDestination` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDestination` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDestination, { data, loading, error }] = useUpdateDestination({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateDestination(
  baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateDestination, UpdateDestinationVariables>
) {
  return ApolloReactHooks.useMutation<UpdateDestination, UpdateDestinationVariables>(
    UpdateDestinationDocument,
    baseOptions
  );
}
export type UpdateDestinationHookResult = ReturnType<typeof useUpdateDestination>;
export type UpdateDestinationMutationResult = ApolloReactCommon.MutationResult<UpdateDestination>;
export type UpdateDestinationMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateDestination,
  UpdateDestinationVariables
>;
export function mockUpdateDestination({
  data,
  variables,
  errors,
}: {
  data: UpdateDestination;
  variables?: UpdateDestinationVariables;
  errors?: GraphQLError[];
}) {
  return {
    request: { query: UpdateDestinationDocument, variables },
    result: { data, errors },
  };
}
