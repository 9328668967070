/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Field } from 'formik';
import { Box, FormHelperText, Link } from 'pouncejs';
import { PRIVACY_DOC_URL } from 'Source/constants';
import FormikSwitch from 'Components/fields/Switch';

interface AnalyticsConsentSectionProps {
  isDisabled: boolean;
}

const AnalyticsConsentSection: React.FC<AnalyticsConsentSectionProps> = ({ isDisabled }) => {
  return (
    <Box as="fieldset" fontWeight="bold">
      <Box mb={4}>
        <Field
          as={FormikSwitch}
          name="analyticsConsent"
          label="Send Product Analytics"
          aria-describedby="product-analytics-section-helper"
          disabled={isDisabled}
        />
        <FormHelperText
          mt={0}
          id="product-analytics-section-helper-text"
          fontWeight="normal"
          fontSize="medium"
        >
          Send anonymized product analytics to help us <br />
          improve Panther.
        </FormHelperText>
      </Box>
      <FormHelperText mt={4} id="analytics-consent-link-helper-text" fontWeight="normal">
        You can read more info about our security privacy
        <Link external variant="neutral" ml={1} href={PRIVACY_DOC_URL}>
          here
        </Link>
        .
      </FormHelperText>
    </Box>
  );
};

export default AnalyticsConsentSection;
