/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import * as Types from '../../../../../../__generated__/schema';

import { GraphQLError } from 'graphql';
import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';

export type GetDataSchemaFieldsVariables = {
  input: Types.GetSchemaInput;
};

export type GetDataSchemaFields = {
  getSchema: {
    record?: Types.Maybe<
      Pick<Types.SchemaRecord, 'name'> & { fields: Array<Pick<Types.SchemaField, 'name'>> }
    >;
  };
};

export const GetDataSchemaFieldsDocument = gql`
  query GetDataSchemaFields($input: GetSchemaInput!) {
    getSchema(input: $input) {
      record {
        name
        fields {
          name
        }
      }
    }
  }
`;

/**
 * __useGetDataSchemaFields__
 *
 * To run a query within a React component, call `useGetDataSchemaFields` and pass it any options that fit your needs.
 * When your component renders, `useGetDataSchemaFields` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDataSchemaFields({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetDataSchemaFields(
  baseOptions?: ApolloReactHooks.QueryHookOptions<GetDataSchemaFields, GetDataSchemaFieldsVariables>
) {
  return ApolloReactHooks.useQuery<GetDataSchemaFields, GetDataSchemaFieldsVariables>(
    GetDataSchemaFieldsDocument,
    baseOptions
  );
}
export function useGetDataSchemaFieldsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetDataSchemaFields,
    GetDataSchemaFieldsVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<GetDataSchemaFields, GetDataSchemaFieldsVariables>(
    GetDataSchemaFieldsDocument,
    baseOptions
  );
}
export type GetDataSchemaFieldsHookResult = ReturnType<typeof useGetDataSchemaFields>;
export type GetDataSchemaFieldsLazyQueryHookResult = ReturnType<
  typeof useGetDataSchemaFieldsLazyQuery
>;
export type GetDataSchemaFieldsQueryResult = ApolloReactCommon.QueryResult<
  GetDataSchemaFields,
  GetDataSchemaFieldsVariables
>;
export function mockGetDataSchemaFields({
  data,
  variables,
  errors,
}: {
  data: GetDataSchemaFields;
  variables?: GetDataSchemaFieldsVariables;
  errors?: GraphQLError[];
}) {
  return {
    request: { query: GetDataSchemaFieldsDocument, variables },
    result: { data, errors },
  };
}
