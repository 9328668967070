/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import * as Types from '../../../../__generated__/schema';

import { PackSourceDetails } from '../../../graphql/fragments/PackSourceDetails.generated';
import { GraphQLError } from 'graphql';
import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';

export type GetPackSourceVariables = {
  id: Types.Scalars['ID'];
};

export type GetPackSource = { getAnalysisPackSource: PackSourceDetails };

export const GetPackSourceDocument = gql`
  query GetPackSource($id: ID!) {
    getAnalysisPackSource(id: $id) {
      ...PackSourceDetails
    }
  }
  ${PackSourceDetails}
`;

/**
 * __useGetPackSource__
 *
 * To run a query within a React component, call `useGetPackSource` and pass it any options that fit your needs.
 * When your component renders, `useGetPackSource` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPackSource({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPackSource(
  baseOptions?: ApolloReactHooks.QueryHookOptions<GetPackSource, GetPackSourceVariables>
) {
  return ApolloReactHooks.useQuery<GetPackSource, GetPackSourceVariables>(
    GetPackSourceDocument,
    baseOptions
  );
}
export function useGetPackSourceLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetPackSource, GetPackSourceVariables>
) {
  return ApolloReactHooks.useLazyQuery<GetPackSource, GetPackSourceVariables>(
    GetPackSourceDocument,
    baseOptions
  );
}
export type GetPackSourceHookResult = ReturnType<typeof useGetPackSource>;
export type GetPackSourceLazyQueryHookResult = ReturnType<typeof useGetPackSourceLazyQuery>;
export type GetPackSourceQueryResult = ApolloReactCommon.QueryResult<
  GetPackSource,
  GetPackSourceVariables
>;
export function mockGetPackSource({
  data,
  variables,
  errors,
}: {
  data: GetPackSource;
  variables?: GetPackSourceVariables;
  errors?: GraphQLError[];
}) {
  return {
    request: { query: GetPackSourceDocument, variables },
    result: { data, errors },
  };
}
