/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { IndicatorType } from 'Generated/schema';
import { FetchResult } from '@apollo/client';
import { Field, Form, Formik } from 'formik';
import { Box, Flex, IconButton, Tooltip, Card, AbstractButton } from 'pouncejs';
import FormikCombobox from 'Components/fields/ComboBox';
import * as Yup from 'yup';
import { capitalize } from 'Helpers/utils';
import FormikMultiCombobox from 'Components/fields/MultiComboBox';
import useAsyncQueryContext from 'Hooks/useAsyncQueryContext';
import useAsyncQueryCancellation from 'Hooks/useAsyncQueryCancellation';
import FormikDateRangeInput from 'Components/fields/DateRangeInput';

const EMPTY_ARRAY = [];
const DATE_FORMAT = 'YYYY-MM-DD HH:mm';
const INDICATOR_TYPE_VALUES = Object.values(IndicatorType);

export const indicatorTypeToString = (type: string) => {
  if (type.includes('p_any')) {
    return type.replace('p_any_', '').split('_').map(capitalize).join(' ');
  }

  return type.match(/[A-Z][a-z]+/g).join(' ');
};

const indicatorAdditionValidation = (incoming: string, existing: string[]) =>
  !existing.includes(incoming);

export interface IndicatorInputFormValues {
  indicators: string[];
  indicatorName: IndicatorType;
  startTime: string;
  endTime: string;
}

interface IndicatorInputFormProps {
  initialValues: IndicatorInputFormValues;
  onSubmit: (values: IndicatorInputFormValues) => Promise<FetchResult<any>>;
}

export const validationSchema: Yup.SchemaOf<IndicatorInputFormValues> = Yup.object().shape({
  indicators: Yup.array().of(Yup.string()).required().min(1),
  indicatorName: Yup.string().oneOf(Object.values(IndicatorType)).required(),
  startTime: Yup.string().required(),
  endTime: Yup.string().required(),
});

const IndicatorInputForm: React.FC<IndicatorInputFormProps> = ({ initialValues, onSubmit }) => {
  const { state: { queryStatus, queryId } } = useAsyncQueryContext(); // prettier-ignore

  const { cancelQuery, isCancelingQuery } = useAsyncQueryCancellation();

  return (
    <Formik<IndicatorInputFormValues>
      enableReinitialize={!!queryId}
      validationSchema={validationSchema}
      initialValues={initialValues}
      onSubmit={onSubmit}
    >
      {({ isValid, dirty, values, setFieldValue }) => {
        const shouldShowClearAll = values.indicators.length >= 5;

        return (
          <Form>
            <Card variant="dark" as="section" p={6} my={6}>
              <Field
                as={FormikMultiCombobox}
                label="IPs, domains, hashes..."
                name="indicators"
                searchable
                allowAdditions
                items={EMPTY_ARRAY}
                placeholder="Enter the values you want to search for"
                data-testid="indicator-values-input"
                canClearAllAfter={5}
                validateAddition={indicatorAdditionValidation}
              />
              {shouldShowClearAll && (
                <AbstractButton
                  width="100%"
                  py={1}
                  backgroundColor="blue-400"
                  fontSize="2x-small"
                  onClick={() => setFieldValue('indicators', EMPTY_ARRAY)}
                >
                  Clear All
                </AbstractButton>
              )}

              <Flex mt={4} spacing={4}>
                <Box flexGrow={1}>
                  <Field
                    placeholder="Choose a field you want to search"
                    as={FormikCombobox}
                    label="Field"
                    name="indicatorName"
                    data-testid="indicator-field-input"
                    items={INDICATOR_TYPE_VALUES}
                    itemToString={indicatorTypeToString}
                    showClearSelectionControl={false}
                    searchable
                    required
                  />
                </Box>
                <FormikDateRangeInput
                  alignment="right"
                  withPresets
                  withTime
                  disableReset
                  format={DATE_FORMAT}
                  labelStart="Time From (UTC)"
                  labelEnd="Time To (UTC)"
                  placeholderStart="YYYY-MM-DD HH:mm"
                  placeholderEnd="YYYY-MM-DD HH:mm"
                  nameStart="startTime"
                  nameEnd="endTime"
                  useUTC
                />
                {queryStatus === 'running' ? (
                  <Tooltip content="Cancel Query">
                    <IconButton
                      aria-label="Cancel Query"
                      disabled={isCancelingQuery}
                      icon="delete"
                      variantColor="pink-700"
                      onClick={cancelQuery}
                    />
                  </Tooltip>
                ) : (
                  <IconButton
                    type="submit"
                    aria-label="Search"
                    disabled={!isValid || !dirty || queryStatus === 'provisioning'}
                    icon="search"
                  />
                )}
              </Flex>
            </Card>
          </Form>
        );
      }}
    </Formik>
  );
};

export default IndicatorInputForm;
