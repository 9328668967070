/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { WizardContextValue, WizardProps, StepStatus } from './types';

const WizardContext = React.createContext(null);

function WizardProvider<WizardData = unknown>({
  startIndex = 0,
  initialData,
  children,
  enableAllNavigationSteps = false,
}: WizardProps<WizardData>) {
  const [currentStepIndex, setCurrentStepIndex] = React.useState(startIndex);
  const [steps, setSteps] = React.useState([]);
  const [navigationEntries, setNavigationEntries] = React.useState([]);

  const [isAllNavigationStepsEnabled, setIsAllNavigationStepsEnabled] = React.useState(
    enableAllNavigationSteps
  );
  const [isNavigationAvailable, setNavigationAvailability] = React.useState(true);
  const [currentStepStatus, setCurrentStepStatus] = React.useState<StepStatus>('PENDING');
  const [wizardData, setWizardData] = React.useState<WizardData>(initialData);

  /**
   * hasNextStep & hasPreviousStep are using useRef
   * to avoid the usage in any useCallback deps array e.g goToNextStep
   * this is handy usage of Passing by reference.
   */
  const hasNextStep = React.useRef(true);
  const hasPreviousStep = React.useRef(false);

  hasNextStep.current = currentStepIndex < steps.length - 1;
  hasPreviousStep.current = currentStepIndex > 0;

  const currentStep = steps[currentStepIndex];

  /**
   * Calculate last activeIndex for current steps group
   */
  React.useEffect(() => {
    const { groupId, id } = currentStep || {};
    if (groupId) {
      setNavigationEntries(prev =>
        prev.map(step => (groupId === step.id ? { ...step, activeIndex: id } : step))
      );
    }
  }, [currentStep]);

  /**
   * Reset the step status everytime the step changes
   */
  React.useEffect(() => {
    setCurrentStepStatus('PENDING');
  }, [currentStepIndex, setCurrentStepStatus]);

  /**
   * Goes to the next wizard step
   */
  const goToNextStep = React.useCallback(() => {
    if (hasNextStep.current) {
      setCurrentStepIndex(currStep => currStep + 1);
    }
  }, []);

  /**
   * Goes to the previous wizard step
   */
  const goToPrevStep = React.useCallback(() => {
    if (hasPreviousStep.current) {
      setCurrentStepIndex(currStep => currStep - 1);
    }
  }, []);
  /**
   * Goes to the last wizard step
   */
  const goToLastStep = React.useCallback(() => {
    if (hasNextStep.current) {
      setCurrentStepIndex(steps.length - 1);
    }
  }, [steps.length]);

  /**
   * Goes to the the chosen wizard step
   */
  const goToStep = React.useCallback(
    (stepIndex: number) => {
      if (stepIndex >= 0 && stepIndex < steps.length) {
        setCurrentStepIndex(stepIndex);
      } else {
        throw new Error(
          `Invalid step index [${stepIndex}] passed to 'goToStep'. Ensure the given stepIndex is not out of boundaries.`
        );
      }
    },
    [steps]
  );

  /*
   * Resets the data to  the original value
   */
  const resetWizardData = React.useCallback(() => {
    setWizardData(initialData);
  }, [initialData, setWizardData]);

  /*
   *  Merges new data with the existing wizard data
   */
  const updateWizardData = React.useCallback(
    (data: WizardData) => {
      setWizardData(prevData => ({ ...prevData, ...data }));
    },
    [setWizardData]
  );

  /**
   * Fully resets the wizard,  including data and current step
   */
  const resetWizard = React.useCallback(() => {
    resetWizardData();
    setCurrentStepIndex(startIndex);
    setCurrentStepStatus('PENDING');
    setNavigationAvailability(true);
  }, [resetWizardData, startIndex]);

  /*
   * Exposes handlers to any components below
   */
  const contextValue = React.useMemo(
    () => ({
      currentStep,
      goToNextStep,
      goToPrevStep,
      goToLastStep,
      goToStep,
      reset: resetWizard,
      currentStepIndex,
      isFirstStep: !hasPreviousStep.current,
      isLastStep: !hasNextStep.current,
      steps,
      setSteps,
      navigationEntries,
      setNavigationEntries,
      currentStepStatus,
      setCurrentStepStatus,
      isNavigationAvailable,
      setNavigationAvailability,
      data: wizardData,
      setData: setWizardData,
      updateData: updateWizardData,
      resetData: resetWizardData,
      isAllNavigationStepsEnabled,
      setIsAllNavigationStepsEnabled,
    }),
    [
      currentStepIndex,
      currentStepStatus,
      goToNextStep,
      goToPrevStep,
      goToLastStep,
      goToStep,
      isNavigationAvailable,
      navigationEntries,
      resetWizard,
      resetWizardData,
      currentStep,
      steps,
      updateWizardData,
      wizardData,
      isAllNavigationStepsEnabled,
      setIsAllNavigationStepsEnabled,
    ]
  );

  return <WizardContext.Provider value={contextValue}>{children}</WizardContext.Provider>;
}

function useWizardContext<WizardData = any>() {
  const context = React.useContext(WizardContext);
  if (context === undefined) {
    throw new Error('useWizardContext must be used within a WizardProvider');
  }
  return context as WizardContextValue<WizardData>;
}

export { WizardProvider, useWizardContext };
