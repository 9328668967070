/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Box, Flex, Heading, Link, Text } from 'pouncejs';
import WarningImg from 'Assets/illustrations/warning.svg';
import LinkButton from 'Components/buttons/LinkButton';
import withSEO from 'Hoc/withSEO';
import { Permission } from 'Generated/schema';
import useCheckPermissions from 'Hooks/useCheckPermissions';

const Page500: React.FC = () => {
  const canUserAccessSummary = useCheckPermissions(Permission.SummaryRead);

  return (
    <Flex justify="center" align="center" direction="column">
      <Box mb={10}>
        <img alt="Page crash illustration" src={WarningImg} width="auto" height={350} />
      </Box>
      <Heading mb={2}>Something went wrong</Heading>
      <Text fontSize="medium" textAlign="center" color="gray-300" mb={10} maxWidth={600}>
        An error has occurred and our team has been notified. If you would like to report additional
        details about this error, please email at
        <Link ml={1} external href="mailto:support@runpanther.io">
          support@runpanther.io
        </Link>
        .
      </Text>
      {canUserAccessSummary && <LinkButton to="/">Back to somewhere stable</LinkButton>}
    </Flex>
  );
};

export default withSEO({ title: 'Internal Server Error' })(Page500);
