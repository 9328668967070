/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Link as RRLink } from 'react-router-dom';
import { Dropdown, DropdownButton, DropdownItem, DropdownLink, DropdownMenu } from 'pouncejs';
import OptionsButton from 'Components/buttons/OptionsButton';
import urls from 'Source/urls';
import { APITokenDetails } from 'Source/graphql/fragments/APITokenDetails.generated';
import useModal from 'Hooks/useModal';
import DeleteAPITokenModal from 'Components/modals/DeleteAPITokenModal';

interface APITokenCardCardOptionsProps {
  token: APITokenDetails;
}

const APITokenCardCardOptions: React.FC<APITokenCardCardOptionsProps> = ({ token }) => {
  const { showModal } = useModal();

  return (
    <Dropdown>
      <DropdownButton
        as={OptionsButton}
        data-tid="api-token-options-dropdown"
        aria-label="Toggle API Token Options"
      />
      <DropdownMenu>
        <DropdownLink as={RRLink} to={urls.settings.apiTokens.edit(token.id)}>
          Edit
        </DropdownLink>
        <DropdownItem
          onSelect={() =>
            showModal(<DeleteAPITokenModal token={token} />, { title: `Delete ${token.name}` })
          }
        >
          Delete
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  );
};

export default React.memo(APITokenCardCardOptions);
