/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Flex, Text, Heading } from 'pouncejs';
import { SelectAllCheckbox, useSelect } from 'Components/utils/SelectContext';
import useCheckPermissions from 'Hooks/useCheckPermissions';
import { Permission } from 'Generated/schema';
import ListAlertsSelectSearch from './ListAlertsSelectSearch';
import ListAlertsActions from '../ListAlertsActions';
import ListAlertSearchUpdater from '../ListAlertsActions/ListAlertSearchUpdater';
import ListAlertDefaultUpdater from '../ListAlertsActions/ListAlertDefaultUpdater';

interface ListAlertControlsProps {
  alertIds: string[];
  allLoaded: boolean;
  collectionLabel: string;
}

const ListAlertControls: React.FC<ListAlertControlsProps> = ({
  allLoaded,
  alertIds,
  collectionLabel,
}) => {
  const canUserModifyAlerts = useCheckPermissions(Permission.AlertModify);
  const { allSelected, resetSelection } = useSelect();
  const [isSearchUpdater, setIsSearchUpdater] = React.useState(false);

  // when there are more
  const showSelectSearch = React.useMemo(() => {
    return !allLoaded && allSelected;
  }, [allLoaded, allSelected]);

  const handleSelectSearch = React.useCallback(
    newSelectSearch => {
      if (newSelectSearch !== isSearchUpdater && !newSelectSearch) {
        resetSelection();
      }
      setIsSearchUpdater(newSelectSearch);
    },
    [setIsSearchUpdater, resetSelection, isSearchUpdater]
  );

  // if allSelected is no longer true, isSearchUpdater must be false.
  React.useEffect(() => {
    if (!allSelected && isSearchUpdater) {
      setIsSearchUpdater(false);
    }
  }, [allSelected, isSearchUpdater, setIsSearchUpdater]);

  return (
    <>
      <Flex p={6} justify="space-between" align="center" maxHeight={80}>
        <Heading as="h4">
          {canUserModifyAlerts && (
            <Flex align="center" spacing={3} ml={1}>
              <SelectAllCheckbox data-tid="alerts-select-all" selectionItems={alertIds} />
              <Text fontSize="medium">Select all {collectionLabel}</Text>
            </Flex>
          )}
        </Heading>
        <ListAlertsActions
          updater={isSearchUpdater ? <ListAlertSearchUpdater /> : <ListAlertDefaultUpdater />}
        />
      </Flex>
      {showSelectSearch && (
        <ListAlertsSelectSearch
          itemsOnPage={alertIds.length}
          selectSearch={isSearchUpdater}
          handleChange={handleSelectSearch}
          collectionLabel={collectionLabel}
        />
      )}
    </>
  );
};

export default React.memo(ListAlertControls);
