/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { useSnackbar } from 'pouncejs';
import { copyTextToClipboard } from 'Helpers/utils';

const useCopyToClipboard = () => {
  const { pushSnackbar } = useSnackbar();

  return React.useCallback((value: any) => {
    pushSnackbar({
      variant: 'default',
      title: 'Copied to clipboard',
      duration: 1500,
    });
    copyTextToClipboard(value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export default useCopyToClipboard;
