/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import * as Types from '../../../../../../__generated__/schema';

import { GraphQLError } from 'graphql';
import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';

export type GetSystemErrorAlertEventsVariables = {
  id: Types.Scalars['ID'];
  eventsInput: Types.GetAlertEventsInput;
};

export type GetSystemErrorAlertEvents = {
  alert: Pick<Types.Alert, 'id'> & {
    origin: { events?: Types.Maybe<Pick<Types.AlertEvents, 'items' | 'lastEvaluatedKey'>> };
  };
};

export const GetSystemErrorAlertEventsDocument = gql`
  query GetSystemErrorAlertEvents($id: ID!, $eventsInput: GetAlertEventsInput!) {
    alert(id: $id) {
      id
      origin {
        ... on AlertOriginSystemError {
          events(input: $eventsInput) {
            items
            lastEvaluatedKey
          }
        }
      }
    }
  }
`;

/**
 * __useGetSystemErrorAlertEvents__
 *
 * To run a query within a React component, call `useGetSystemErrorAlertEvents` and pass it any options that fit your needs.
 * When your component renders, `useGetSystemErrorAlertEvents` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSystemErrorAlertEvents({
 *   variables: {
 *      id: // value for 'id'
 *      eventsInput: // value for 'eventsInput'
 *   },
 * });
 */
export function useGetSystemErrorAlertEvents(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetSystemErrorAlertEvents,
    GetSystemErrorAlertEventsVariables
  >
) {
  return ApolloReactHooks.useQuery<GetSystemErrorAlertEvents, GetSystemErrorAlertEventsVariables>(
    GetSystemErrorAlertEventsDocument,
    baseOptions
  );
}
export function useGetSystemErrorAlertEventsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetSystemErrorAlertEvents,
    GetSystemErrorAlertEventsVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    GetSystemErrorAlertEvents,
    GetSystemErrorAlertEventsVariables
  >(GetSystemErrorAlertEventsDocument, baseOptions);
}
export type GetSystemErrorAlertEventsHookResult = ReturnType<typeof useGetSystemErrorAlertEvents>;
export type GetSystemErrorAlertEventsLazyQueryHookResult = ReturnType<
  typeof useGetSystemErrorAlertEventsLazyQuery
>;
export type GetSystemErrorAlertEventsQueryResult = ApolloReactCommon.QueryResult<
  GetSystemErrorAlertEvents,
  GetSystemErrorAlertEventsVariables
>;
export function mockGetSystemErrorAlertEvents({
  data,
  variables,
  errors,
}: {
  data: GetSystemErrorAlertEvents;
  variables?: GetSystemErrorAlertEventsVariables;
  errors?: GraphQLError[];
}) {
  return {
    request: { query: GetSystemErrorAlertEventsDocument, variables },
    result: { data, errors },
  };
}
