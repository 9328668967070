/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Alert, Box, Card, Flex } from 'pouncejs';
import { extractErrorMessage } from 'Helpers/utils';
import { compose } from 'Helpers/compose';
import NoResultsFound from 'Components/NoResultsFound';
import ErrorBoundary from 'Components/ErrorBoundary';
import withSEO from 'Hoc/withSEO';
import Panel from 'Components/Panel';
import { DEFAULT_LARGE_PAGE_SIZE } from 'Source/constants';
import urls from 'Source/urls';
import TablePlaceholder from 'Components/TablePlaceholder';
import LookupCard from 'Pages/Enrichment/components/LookupCard';
import BetaTag from 'Components/BetaTag';
import useRequestParamsWithoutPagination from 'Hooks/useRequestParamsWithoutPagination';
import useInfiniteScroll from 'Hooks/useInfiniteScroll';
import Page403 from 'Pages/403';
import { Permission } from 'Generated/schema';
import withRoleRestrictedAccess from 'Hoc/withRoleRestrictedAccess';
import RoleRestrictedAccess from 'Components/utils/RoleRestrictedAccess';
import LinkButton from 'Components/buttons/LinkButton';
import ListLookupsPageSkeleton from '../../components/ListLookupsSkeleton';
import { useListLookups } from '../../../../graphql/queries/listLookups.generated';
import LookupDropdownFilters from './LookupDropdownFilters';
import { ListLookupsDropdownFiltersValues } from './LookupDropdownFilters/LookupDropdownFilters';

const ListLookups = () => {
  const { requestParams } = useRequestParamsWithoutPagination<ListLookupsDropdownFiltersValues>();
  const { loading, previousData, data = previousData, error, fetchMore } = useListLookups({
    variables: {
      input: {
        ...requestParams,
        managed: false,
        pageSize: DEFAULT_LARGE_PAGE_SIZE,
      },
    },
  });

  const lookupsItems = data?.listLookups.lookups || [];
  const lastEvaluatedKey = data?.listLookups.lastEvaluatedKey || null;
  const hasNextPage = !!lastEvaluatedKey;

  const { sentinelRef } = useInfiniteScroll<HTMLDivElement>({
    loading,
    onLoadMore: () => {
      fetchMore({
        variables: {
          input: {
            ...requestParams,
            managed: false,
            pageSize: DEFAULT_LARGE_PAGE_SIZE,
            exclusiveStartKey: lastEvaluatedKey,
          },
        },
      });
    },
  });

  if (loading && !data) {
    return <ListLookupsPageSkeleton />;
  }

  const hasError = Boolean(error);

  return (
    <ErrorBoundary>
      {hasError && (
        <Box mb={6}>
          <Alert
            variant="error"
            title="Couldn't load your lookups"
            description={
              extractErrorMessage(error) ||
              'There was an error when performing your request, please contact support@runpanther.io'
            }
          />
        </Box>
      )}

      <Panel
        title={
          <Flex>
            Lookup Tables
            <Box ml="2">
              <BetaTag variant="border" />
            </Box>
          </Flex>
        }
        actions={
          <Flex spacing={4}>
            <LookupDropdownFilters />
            <RoleRestrictedAccess allowedPermissions={[Permission.LookupModify]}>
              <LinkButton
                title="Create new Lookup Table"
                to={urls.enrichment.lookupTables.create()}
                data-tid="create-new-lookup"
              >
                Create New
              </LinkButton>
            </RoleRestrictedAccess>
          </Flex>
        }
      >
        <Card as="section" position="relative">
          <Flex direction="column" spacing={2}>
            {lookupsItems.length ? (
              lookupsItems.map(lookup => <LookupCard key={lookup.id} lookup={lookup} />)
            ) : (
              <Box my={8}>
                <NoResultsFound title="No Lookups found" />
              </Box>
            )}

            {hasNextPage && (
              <Box ref={sentinelRef}>
                <TablePlaceholder rowCount={5} rowHeight={30} />
              </Box>
            )}
          </Flex>
        </Card>
      </Panel>
    </ErrorBoundary>
  );
};

export default compose(
  withSEO({ title: 'Lookups' }),
  withRoleRestrictedAccess({
    allowedPermissions: [Permission.LookupRead, Permission.LookupModify],
    fallback: <Page403 />,
  }),
  React.memo
)(ListLookups);
