/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Box, Button, Flex, Text, BoxProps } from 'pouncejs';
import useCopyToClipboard from 'Hooks/useCopyToClipboard';

interface CodeBlockProps extends BoxProps {
  title?: string;
  code: string;
}

const CodeBlock = ({ title, code, ...rest }: CodeBlockProps) => {
  const copyToClipboard = useCopyToClipboard();
  return (
    <Box {...rest}>
      {title && (
        <Text fontSize="small" color="blue-100">
          {title}
        </Text>
      )}

      <Flex align="center" justify="space-between" mt={1} p={4} backgroundColor="navyblue-600">
        <Box as="code" width={0.85}>
          {code}
        </Box>
        <Button size="medium" variantColor="navyblue-200" onClick={() => copyToClipboard(code)}>
          Copy
        </Button>
      </Flex>
    </Box>
  );
};

export default CodeBlock;
