/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */
import React from 'react';
import { Flex, FormHelperText, Text } from 'pouncejs';
import { isMac } from 'Helpers/utils';

const EditorHelpers: React.FC = () => {
  return (
    <Flex spacing={1}>
      <FormHelperText as="div" id="run-query-helper" mt={3} mb={-3}>
        <Flex spacing={1}>
          <Text as="span" color="white">
            {isMac ? '⌘' : 'Ctrl'} + Enter
          </Text>
          <Text as="span">to run query</Text>
        </Flex>
      </FormHelperText>
      <FormHelperText as="div" id="undo-query-helper" mt={3} mb={-3}>
        <Flex spacing={1}>
          <Text as="span" color="white">
            - {isMac ? '⌘' : 'Ctrl'} + Z
          </Text>
          <Text as="span">to undo</Text>
        </Flex>
      </FormHelperText>
    </Flex>
  );
};

export default React.memo(EditorHelpers);
