/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { WizardPanel } from 'Components/Wizard';
import { Box, Card, Flex, Link, Text } from 'pouncejs';
import { LOG_ONBOARDING_SNS_DOC_URL } from 'Source/constants';
import { useFormikContext } from 'formik';
import EditIntegrationActions from 'Pages/Integrations/components/EditIntegrationActions';
import { S3LogSourceWizardValues } from '../S3LogSourceWizard';

const ConfigureNotificationsPanel: React.FC = () => {
  const { values } = useFormikContext<S3LogSourceWizardValues>();
  const editMode = !!values.integrationId;
  return (
    <WizardPanel>
      <WizardPanel.Heading title="Configure Bucket Notifications Manually" />
      <Box width={0.8} mx="auto">
        <Card as="section" variant="dark" py={4} px={10}>
          <Text fontSize="x-large" textAlign="center">
            Go to
            <Link external mx={1} href={LOG_ONBOARDING_SNS_DOC_URL}>
              our documentation
            </Link>
            and read the instructions After configuring the notifications return to continue the set
            up process
          </Text>
        </Card>
      </Box>

      <WizardPanel.Actions>
        <WizardPanel.ActionPrev />
        {editMode ? (
          <EditIntegrationActions />
        ) : (
          <Flex spacing={4} direction="column" align="center">
            <Text fontSize="small">
              After configuring the notifications, click below to proceed
            </Text>
            <WizardPanel.ActionNext>Continue Setup</WizardPanel.ActionNext>
          </Flex>
        )}
      </WizardPanel.Actions>
    </WizardPanel>
  );
};

export default ConfigureNotificationsPanel;
