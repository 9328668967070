/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import JSONBig from 'json-bigint';
import useCopyToClipboard from 'Hooks/useCopyToClipboard';
import NodeAction from '../NodeAction';
import { ActionsProps } from '../JsonViewer';

const isPrimitive = (value: unknown) => Object(value) !== value;

const NodeCopyAction: React.FC<ActionsProps> = ({ data }) => {
  const copyToClipboard = useCopyToClipboard();

  return (
    <NodeAction
      icon="copy"
      label="Copy to clipboard"
      onClick={() =>
        copyToClipboard(isPrimitive(data) ? (data as string) : JSONBig.stringify(data, null, '\t'))
      }
    />
  );
};

export default React.memo(NodeCopyAction);
