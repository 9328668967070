/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import * as Types from '../../../../../../../__generated__/schema';

import { GithubLogIntegrationDetails } from '../../../../../../graphql/fragments/GithubLogIntegrationDetails.generated';
import { GraphQLError } from 'graphql';
import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';

export type GetGithubLogSourceVariables = {
  id: Types.Scalars['ID'];
};

export type GetGithubLogSource = { getLogPullingIntegration: GithubLogIntegrationDetails };

export const GetGithubLogSourceDocument = gql`
  query GetGithubLogSource($id: ID!) {
    getLogPullingIntegration(id: $id) {
      ...GithubLogIntegrationDetails
    }
  }
  ${GithubLogIntegrationDetails}
`;

/**
 * __useGetGithubLogSource__
 *
 * To run a query within a React component, call `useGetGithubLogSource` and pass it any options that fit your needs.
 * When your component renders, `useGetGithubLogSource` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGithubLogSource({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetGithubLogSource(
  baseOptions?: ApolloReactHooks.QueryHookOptions<GetGithubLogSource, GetGithubLogSourceVariables>
) {
  return ApolloReactHooks.useQuery<GetGithubLogSource, GetGithubLogSourceVariables>(
    GetGithubLogSourceDocument,
    baseOptions
  );
}
export function useGetGithubLogSourceLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetGithubLogSource,
    GetGithubLogSourceVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<GetGithubLogSource, GetGithubLogSourceVariables>(
    GetGithubLogSourceDocument,
    baseOptions
  );
}
export type GetGithubLogSourceHookResult = ReturnType<typeof useGetGithubLogSource>;
export type GetGithubLogSourceLazyQueryHookResult = ReturnType<
  typeof useGetGithubLogSourceLazyQuery
>;
export type GetGithubLogSourceQueryResult = ApolloReactCommon.QueryResult<
  GetGithubLogSource,
  GetGithubLogSourceVariables
>;
export function mockGetGithubLogSource({
  data,
  variables,
  errors,
}: {
  data: GetGithubLogSource;
  variables?: GetGithubLogSourceVariables;
  errors?: GraphQLError[];
}) {
  return {
    request: { query: GetGithubLogSourceDocument, variables },
    result: { data, errors },
  };
}
