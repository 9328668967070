/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import * as Types from '../../../__generated__/schema';

import { GraphQLError } from 'graphql';
import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';

export type ListAvailableScheduledQueriesVariables = {};

export type ListAvailableScheduledQueries = {
  listSavedQueries: { savedQueries: Array<Pick<Types.SavedQuery, 'name'>> };
};

export const ListAvailableScheduledQueriesDocument = gql`
  query ListAvailableScheduledQueries {
    listSavedQueries(input: { isScheduled: true, pageSize: 500 }) {
      savedQueries {
        name
      }
    }
  }
`;

/**
 * __useListAvailableScheduledQueries__
 *
 * To run a query within a React component, call `useListAvailableScheduledQueries` and pass it any options that fit your needs.
 * When your component renders, `useListAvailableScheduledQueries` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListAvailableScheduledQueries({
 *   variables: {
 *   },
 * });
 */
export function useListAvailableScheduledQueries(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    ListAvailableScheduledQueries,
    ListAvailableScheduledQueriesVariables
  >
) {
  return ApolloReactHooks.useQuery<
    ListAvailableScheduledQueries,
    ListAvailableScheduledQueriesVariables
  >(ListAvailableScheduledQueriesDocument, baseOptions);
}
export function useListAvailableScheduledQueriesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    ListAvailableScheduledQueries,
    ListAvailableScheduledQueriesVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    ListAvailableScheduledQueries,
    ListAvailableScheduledQueriesVariables
  >(ListAvailableScheduledQueriesDocument, baseOptions);
}
export type ListAvailableScheduledQueriesHookResult = ReturnType<
  typeof useListAvailableScheduledQueries
>;
export type ListAvailableScheduledQueriesLazyQueryHookResult = ReturnType<
  typeof useListAvailableScheduledQueriesLazyQuery
>;
export type ListAvailableScheduledQueriesQueryResult = ApolloReactCommon.QueryResult<
  ListAvailableScheduledQueries,
  ListAvailableScheduledQueriesVariables
>;
export function mockListAvailableScheduledQueries({
  data,
  variables,
  errors,
}: {
  data: ListAvailableScheduledQueries;
  variables?: ListAvailableScheduledQueriesVariables;
  errors?: GraphQLError[];
}) {
  return {
    request: { query: ListAvailableScheduledQueriesDocument, variables },
    result: { data, errors },
  };
}
