/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Text, AbstractButton, Collapse } from 'pouncejs';

interface SourceHealthRawErrorMessageProps {
  rawErrorMessage: string;
}

const SourceHealthRawErrorMessage: React.FC<SourceHealthRawErrorMessageProps> = ({
  rawErrorMessage,
}) => {
  const [showMessage, setShowMessage] = React.useState(false);

  return (
    <>
      <Text as="span"> - </Text>
      <AbstractButton
        color="blue-200"
        fontWeight="medium"
        _hover={{ color: 'blue-100' }}
        onClick={() => setShowMessage(prev => !prev)}
      >
        {showMessage ? 'Hide Error Message' : 'View Error Message'}
      </AbstractButton>
      <Collapse open={showMessage}>
        <Text color="gray-300">{rawErrorMessage}</Text>
      </Collapse>
    </>
  );
};

export default React.memo(SourceHealthRawErrorMessage);
