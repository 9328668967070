/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import * as Types from '../../../__generated__/schema';

import { GraphQLError } from 'graphql';
import gql from 'graphql-tag';

export type ActorId_User_ = Pick<Types.User, 'id'>;

export type ActorId_APIToken_ = Pick<Types.APIToken, 'id'>;

export type ActorId = ActorId_User_ | ActorId_APIToken_;

export const ActorId = gql`
  fragment ActorId on Actor {
    ... on User {
      id
    }
    ... on APIToken {
      id
    }
  }
`;
