/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { useHistory } from 'react-router-dom';
import {
  MitreMatrixTreeNode,
  MitreTactic,
  MitreTechnique,
  ReportOverrideSetting,
  ReportSetting,
} from 'Generated/schema';
import useUrlParams from 'Hooks/useUrlParams';
import urls from 'Source/urls';
import isEmpty from 'lodash/isEmpty';
import useSession from 'Hooks/useSession';
import { ApolloError } from '@apollo/client';
import { useGetMitreMatrixTree } from './graphql/getMitreMatrixTree.generated';
import { GetMitreMatrix, useGetMitreMatrix } from './graphql/getMitreMatrix.generated';

type MitreMatrixUrlParams = {
  matrix: string;
  tactic: string;
  technique: string;
};

const SESSION_DATA_KEY = 'MITRE_MATRIX_DATA';

type MitreMatrixSessionData = {
  lastViewedMatrixId: string;
  lastViewedTacticId: string;
  lastViewedTechniqueId: string;
};

interface MitreContextData {
  treeNodes: MitreMatrixTreeNode[];
  versionName: string;
  versionLink: string;
  activeMatrix?: GetMitreMatrix['mitreMatrix'];
  isTreeLoading: boolean;
  activeTactic?: MitreTactic;
  activeMatrixId: string;
  enabledLogTypes?: string[];
  activeTechnique?: MitreTechnique;
  activeMatrixLoading: boolean;
  activeReportSetting?: GetMitreMatrix['mitreMatrix']['reportSettings'][0];
  activeTacticTechnique?: MitreTacticTechnique;

  treeDataError?: ApolloError;
  activeMatrixError?: ApolloError;
}

interface MitreContextHooks {
  switchMatrix: (id: string) => void;
  selectTacticTechnique: (tt?: MitreTacticTechnique) => void;
}

export type MitreTacticTechnique = {
  tacticId: string;
  techniqueId: string;
};

export interface MitreContextValue extends MitreContextData, MitreContextHooks {}

export const MitreContext = React.createContext<MitreContextValue | null>(null);

export const useMitreContext = () => React.useContext(MitreContext);

interface MitreMatrixNode {
  id: MitreMatrixTreeNode['id'];
  name: MitreMatrixTreeNode['name'];
  submatrices?: MitreMatrixNode[];
}

export const flattenMatrices = (nodes: MitreMatrixNode[] = []): MitreMatrixNode[] => {
  return nodes.reduce((acc, matrix) => {
    acc.push(matrix);
    if (matrix.submatrices) {
      acc.push(...flattenMatrices(matrix.submatrices));
    }
    return acc;
  }, []);
};

export const reportSettingHasRelations = (
  reportSetting: Pick<ReportSetting, 'matchingDetectionIds' | 'matchingLogTypes'>
): boolean => {
  return !isEmpty([...reportSetting.matchingDetectionIds, ...reportSetting.matchingLogTypes]);
};

export type MitreMatrixCoverageStatus =
  | 'error'
  | 'covered'
  | 'notCovered'
  | 'notRelevant'
  | 'partiallyCovered';

export const pickTacticTechniqueStatus = (
  reportSetting: Pick<ReportSetting, 'matchingDetectionIds' | 'matchingLogTypes' | 'override'>
): MitreMatrixCoverageStatus => {
  // case group: does have 1 or more related detections or log types
  if (reportSettingHasRelations(reportSetting)) {
    switch (reportSetting.override) {
      case ReportOverrideSetting.Ignored:
        return 'notRelevant';
      case ReportOverrideSetting.Covered:
        return 'covered';
      case ReportOverrideSetting.NotCovered:
        return 'notCovered';
      case ReportOverrideSetting.None:
        return 'partiallyCovered';
      default:
        return 'error';
    }
  }

  // case group: does NOT have 1 or more related detections or log types
  switch (reportSetting.override) {
    case ReportOverrideSetting.Ignored:
      return 'notRelevant';
    case ReportOverrideSetting.None:
    case ReportOverrideSetting.Covered:
    case ReportOverrideSetting.NotCovered:
      return 'notCovered';
    default:
      return 'error';
  }
};

interface PickActiveMatrixIdInput {
  allMatrices?: MitreMatrixNode[];
  urlMatrixId?: string;
  defaultMatrixId?: string;
  lastViewedMatrixId?: string;
}

export const pickActiveMatrixId = ({
  allMatrices,
  urlMatrixId,
  defaultMatrixId,
  lastViewedMatrixId,
}: PickActiveMatrixIdInput): string | undefined => {
  // if there's a matrix selected in the URL, that always comes first.
  if (urlMatrixId) {
    return urlMatrixId;
  }

  // default to the last viewed matrix, unless it's no longer available.
  if (lastViewedMatrixId) {
    if (allMatrices.find(m => m.id === lastViewedMatrixId)) {
      return lastViewedMatrixId;
    }
  }

  // base case: use the server-defined default.
  return defaultMatrixId;
};

export const MitreContextProvider = ({ children }) => {
  const { session: sessionData, setSession: setSessionData } = useSession<MitreMatrixSessionData>({
    sessionKey: SESSION_DATA_KEY,
  });

  const history = useHistory();

  const { data: treeData, loading: isTreeLoading, error: treeDataError } = useGetMitreMatrixTree();

  const {
    urlParams,
    urlParams: { matrix: urlMatrixId, technique: urlTechniqueId, tactic: urlTacticId },
  } = useUrlParams<MitreMatrixUrlParams>();

  React.useEffect(() => {
    if (!isEmpty(urlParams)) {
      // if there's no matrix, remove params
      if (!urlParams.matrix) {
        history.replace(urls.reports.mitreMatrix());
        return;
      }
      // if there's a tactic but no technique or vice-versa, clear both
      if (!urlParams.technique && urlParams.tactic) {
        history.replace(urls.reports.mitreMatrix({ matrixId: urlParams.matrix }));
        return;
      }
      if (!urlParams.tactic && urlParams.technique) {
        history.replace(urls.reports.mitreMatrix({ matrixId: urlParams.matrix }));
        return;
      }
      // otherwise, do nothing.
      return;
    }

    // default to last view state, if it's available.
    if (sessionData?.lastViewedMatrixId) {
      history.replace(
        urls.reports.mitreMatrix({
          matrixId: sessionData?.lastViewedMatrixId,
          tacticId: sessionData?.lastViewedTacticId,
          techniqueId: sessionData?.lastViewedTechniqueId,
        })
      );
      return;
    }

    // default to server-defined default matrix once it loads.
    if (treeData?.mitreMatrixTree.defaultMatrixId) {
      history.replace(
        urls.reports.mitreMatrix({
          matrixId: treeData?.mitreMatrixTree.defaultMatrixId,
        })
      );
    }
  }, [history, urlParams, sessionData, treeData]);

  const enabledLogTypes = React.useMemo<string[]>(
    () => treeData?.mitreMatrixTree.enabledLogTypes || [],
    [treeData]
  );

  const activeMatrixId = urlMatrixId;

  const {
    data: activeMatrixData,
    error: activeMatrixError,
    loading: activeMatrixLoading,
  } = useGetMitreMatrix({
    variables: {
      id: activeMatrixId,
    },
    skip: !activeMatrixId,
  });

  const activeMatrix = React.useMemo(() => activeMatrixData?.mitreMatrix, [activeMatrixData]);

  const activeReportSetting = React.useMemo(
    () =>
      activeMatrix?.reportSettings.find(
        s =>
          s.association.mitre.tacticId === urlTacticId &&
          s.association.mitre.techniqueId === urlTechniqueId
      ),
    [activeMatrix, urlTacticId, urlTechniqueId]
  );

  const activeTacticTechnique = React.useMemo<MitreTacticTechnique | null>(() => {
    if (urlTacticId && urlTechniqueId) {
      return { tacticId: urlTacticId, techniqueId: urlTechniqueId };
    }
    return null;
  }, [urlTechniqueId, urlTacticId]);

  const activeTactic = React.useMemo<MitreTactic | null>(() => {
    if (!activeTacticTechnique) {
      return null;
    }
    return activeMatrix?.content.tactics.find(t => t.id === activeTacticTechnique.tacticId);
  }, [activeMatrix, activeTacticTechnique]);

  const activeTechnique = React.useMemo<MitreTechnique | null>(() => {
    if (!activeTacticTechnique) {
      return null;
    }
    return activeMatrix?.content.techniques.find(t => t.id === activeTacticTechnique.techniqueId);
  }, [activeMatrix, activeTacticTechnique]);

  const data = React.useMemo<MitreContextData>(
    () => ({
      activeMatrix,
      activeTactic,
      isTreeLoading,
      activeMatrixId,
      enabledLogTypes,
      activeTechnique,
      activeMatrixLoading,
      activeReportSetting,
      activeTacticTechnique,

      treeDataError,
      activeMatrixError,

      treeNodes: (treeData?.mitreMatrixTree.matrices as MitreMatrixTreeNode[]) || [],
      versionName: treeData?.mitreMatrixTree.mitreVersion || '',
      versionLink: treeData?.mitreMatrixTree.mitreVersionUrl || '',
    }),
    [
      treeData,
      activeTactic,
      activeMatrix,
      isTreeLoading,
      activeMatrixId,
      enabledLogTypes,
      activeTechnique,
      activeReportSetting,
      activeMatrixLoading,
      activeTacticTechnique,

      treeDataError,
      activeMatrixError,
    ]
  );

  const switchMatrix = React.useCallback(
    (id: string) => {
      history.push(urls.reports.mitreMatrix({ matrixId: id }));
      setSessionData({ ...sessionData, lastViewedMatrixId: id });
    },
    [history, setSessionData, sessionData]
  );

  const selectTacticTechnique = React.useCallback(
    tt => {
      if (tt) {
        history.push(
          urls.reports.mitreMatrix({
            matrixId: activeMatrixId,
            tacticId: tt.tacticId,
            techniqueId: tt.techniqueId,
          })
        );
        setSessionData({
          ...sessionData,
          lastViewedTacticId: tt.tacticId,
          lastViewedTechniqueId: tt.techniqueId,
        });
      } else {
        history.push(urls.reports.mitreMatrix({ matrixId: activeMatrixId }));
      }
    },
    [history, activeMatrixId, setSessionData, sessionData]
  );

  const value = React.useMemo<MitreContextValue>(
    (): MitreContextValue => ({ ...data, switchMatrix, selectTacticTechnique }),
    [data, switchMatrix, selectTacticTechnique]
  );

  return <MitreContext.Provider value={value}>{children}</MitreContext.Provider>;
};
