/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import { Lookup, UpdateLookupInput } from 'Generated/schema';

/**
 * Takes a `Lookup` as it returns from the GraphQL queries and transforms it
 * into the shape expected by the update mutations.
 */
export const transformLookupToUpdateInput = (lookup: Lookup) => {
  const { id, description, name, reference, refresh, enabled } = lookup;
  const updateLookupInput: UpdateLookupInput = {
    id,
    description,
    logTypeMap: {
      associatedLogTypes: lookup.activeVersion.associatedLogTypes,
      primaryKey: lookup.activeVersion.primaryKey,
    },
    lookupSchema: {
      logType: lookup.activeVersion.logType,
    },
    name,
    reference,
    refresh,
    enabled,
  };

  return updateLookupInput;
};
