/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import * as Types from '../../../../__generated__/schema';

import { GraphQLError } from 'graphql';
import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';

export type GetGeneralSettingsVariables = {};

export type GetGeneralSettings = {
  generalSettings: Pick<
    Types.GeneralSettingsConfig,
    'displayName' | 'email' | 'analyticsConsent'
  > & {
    infrastructureInfo?: Types.Maybe<{
      networking: Pick<Types.NetworkingInfo, 'publicIp'>;
      storage: Pick<Types.StorageInfo, 'processedDataRetentionDays'>;
    }>;
  };
  samlConfig: Pick<Types.SamlConfig, 'enabled' | 'defaultRoleId' | 'metadataUrl'>;
};

export const GetGeneralSettingsDocument = gql`
  query GetGeneralSettings {
    generalSettings {
      displayName
      email
      analyticsConsent
      infrastructureInfo {
        networking {
          publicIp
        }
        storage {
          processedDataRetentionDays
        }
      }
    }
    samlConfig {
      enabled
      defaultRoleId
      metadataUrl
    }
  }
`;

/**
 * __useGetGeneralSettings__
 *
 * To run a query within a React component, call `useGetGeneralSettings` and pass it any options that fit your needs.
 * When your component renders, `useGetGeneralSettings` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGeneralSettings({
 *   variables: {
 *   },
 * });
 */
export function useGetGeneralSettings(
  baseOptions?: ApolloReactHooks.QueryHookOptions<GetGeneralSettings, GetGeneralSettingsVariables>
) {
  return ApolloReactHooks.useQuery<GetGeneralSettings, GetGeneralSettingsVariables>(
    GetGeneralSettingsDocument,
    baseOptions
  );
}
export function useGetGeneralSettingsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetGeneralSettings,
    GetGeneralSettingsVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<GetGeneralSettings, GetGeneralSettingsVariables>(
    GetGeneralSettingsDocument,
    baseOptions
  );
}
export type GetGeneralSettingsHookResult = ReturnType<typeof useGetGeneralSettings>;
export type GetGeneralSettingsLazyQueryHookResult = ReturnType<
  typeof useGetGeneralSettingsLazyQuery
>;
export type GetGeneralSettingsQueryResult = ApolloReactCommon.QueryResult<
  GetGeneralSettings,
  GetGeneralSettingsVariables
>;
export function mockGetGeneralSettings({
  data,
  variables,
  errors,
}: {
  data: GetGeneralSettings;
  variables?: GetGeneralSettingsVariables;
  errors?: GraphQLError[];
}) {
  return {
    request: { query: GetGeneralSettingsDocument, variables },
    result: { data, errors },
  };
}
