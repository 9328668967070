/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Form, Formik, FastField } from 'formik';
import { SortDirEnum, ListResourcesInput, ListResourcesSortFieldsEnum } from 'Generated/schema';
import { Box, Flex } from 'pouncejs';
import pick from 'lodash/pick';
import {
  extractSortingOptions,
  SortingOption,
  WrapSortingFormValues,
  wrapSortingOptions,
} from 'Helpers/utils';
import useRequestParamsWithPagination from 'Hooks/useRequestParamsWithPagination';
import FormikAutosave from 'Components/utils/Autosave';
import FormikCombobox from 'Components/fields/ComboBox';
import FormikTextInput from 'Components/fields/TextInput';
import DropdownFilters from './DropdownFilters';

export type ListResourceInlineFiltersValues = Pick<
  ListResourcesInput,
  'idContains' | 'sortBy' | 'sortDir'
>;

const filters: (keyof ListResourcesInput)[] = ['idContains', 'sortBy', 'sortDir'];

const defaultValues = {
  idContains: '',
  sorting: null,
};

const sortingOpts: SortingOption<ListResourceInlineFiltersValues>[] = [
  {
    opt: 'Resource (A-Z)',
    resolution: {
      sortBy: ListResourcesSortFieldsEnum.Id,
      sortDir: SortDirEnum.Ascending,
    },
  },
  {
    opt: 'Resource (Z-A)',
    resolution: {
      sortBy: ListResourcesSortFieldsEnum.Id,
      sortDir: SortDirEnum.Descending,
    },
  },
  {
    opt: 'Most Recent',
    resolution: {
      sortBy: ListResourcesSortFieldsEnum.LastModified,
      sortDir: SortDirEnum.Descending,
    },
  },
  {
    opt: 'Oldest',
    resolution: {
      sortBy: ListResourcesSortFieldsEnum.LastModified,
      sortDir: SortDirEnum.Ascending,
    },
  },
  {
    opt: 'Passing First',
    resolution: {
      sortBy: ListResourcesSortFieldsEnum.ComplianceStatus,
      sortDir: SortDirEnum.Ascending,
    },
  },
  {
    opt: 'Errored First',
    resolution: {
      sortBy: ListResourcesSortFieldsEnum.ComplianceStatus,
      sortDir: SortDirEnum.Descending,
    },
  },
  {
    opt: 'Type (A-Z)',
    resolution: {
      sortBy: ListResourcesSortFieldsEnum.Type,
      sortDir: SortDirEnum.Ascending,
    },
  },
  {
    opt: 'Type (Z-A)',
    resolution: {
      sortBy: ListResourcesSortFieldsEnum.Type,
      sortDir: SortDirEnum.Descending,
    },
  },
];

const ListResourceFilters: React.FC = () => {
  const { requestParams, updateRequestParamsAndResetPaging } = useRequestParamsWithPagination<
    ListResourcesInput
  >();

  const initialFilterValues = React.useMemo(
    () => ({
      ...defaultValues,
      ...wrapSortingOptions(pick(requestParams, filters), sortingOpts),
    }),
    [requestParams]
  );

  return (
    <Flex align="center">
      <Formik<WrapSortingFormValues<ListResourceInlineFiltersValues>>
        enableReinitialize
        initialValues={initialFilterValues}
        onSubmit={values => {
          updateRequestParamsAndResetPaging(extractSortingOptions(values, sortingOpts));
        }}
      >
        <Form>
          <FormikAutosave threshold={200} />
          <Flex spacing={4} align="center" pr={4}>
            <Box minWidth={425}>
              <FastField
                name="idContains"
                icon="search"
                iconAlignment="left"
                as={FormikTextInput}
                label="Filter Resources by text"
                placeholder="Search for a resource..."
              />
            </Box>
            <Box minWidth={220}>
              <FastField
                name="sorting"
                as={FormikCombobox}
                items={sortingOpts.map(sortingOption => sortingOption.opt)}
                label="Sort By"
                placeholder="Select a sorting option"
              />
            </Box>
          </Flex>
        </Form>
      </Formik>
      <Box pr={4}>
        <DropdownFilters />
      </Box>
    </Flex>
  );
};

export default React.memo(ListResourceFilters);
