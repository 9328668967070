/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { compose } from 'Helpers/compose';
import { Alert, Box, FadeIn, Flex, Text, Heading } from 'pouncejs';
import withSEO from 'Hoc/withSEO';
import ErrorBoundary from 'Components/ErrorBoundary';
import { AlertType, Permission } from 'Generated/schema';
import { extractErrorMessage } from 'Helpers/utils';
import withRoleRestrictedAccess from 'Hoc/withRoleRestrictedAccess';
import Page403 from 'Pages/403';
import RoleRestrictedAccess from 'Components/utils/RoleRestrictedAccess';
import Panel from 'Components/Panel';
import LinkButton from 'Components/buttons/LinkButton';
import urls from 'Source/urls';
import { useListDestinationsAndDefaults } from './graphql/listDestinationsAndDefaults.generated';
import DestinationsPageSkeleton from './Skeleton';
import DestinationsPageEmptyDataFallback from './EmptyDataFallback';
import ListDestinationsCards from './ListDestinationsCards';

const ListDestinations = () => {
  const { loading, error, data } = useListDestinationsAndDefaults();
  const systemErrorDestinationExists = React.useMemo(() => {
    if (!data?.destinations) {
      return false;
    }

    return data.destinations.some(d => d.alertTypes.includes(AlertType.SystemError));
  }, [data]);

  if (loading && !data) {
    return <DestinationsPageSkeleton />;
  }

  if (error) {
    return (
      <Alert
        variant="error"
        title="Couldn't load your available destinations"
        description={
          extractErrorMessage(error) ||
          'There was an error while attempting to list your Destinations'
        }
      />
    );
  }

  if (!data.destinations.length) {
    return <DestinationsPageEmptyDataFallback />;
  }

  return (
    <Box mb={6}>
      <ErrorBoundary>
        <Panel
          title="Destinations"
          data-testid="destinations"
          actions={
            <RoleRestrictedAccess allowedPermissions={[Permission.DestinationModify]}>
              <LinkButton
                title="Create new Destination"
                to={urls.integrations.destinations.create()}
              >
                Create New
              </LinkButton>
            </RoleRestrictedAccess>
          }
        >
          <FadeIn>
            {!systemErrorDestinationExists && (
              <Flex
                align="center"
                direction="column"
                spacing={2}
                p={4}
                mb={3}
                border="1px dashed"
                borderColor="navyblue-100"
                borderRadius="small"
              >
                <Heading as="h3" size="x-small" fontWeight="bold">
                  You haven’t set up a destination for System Errors yet!
                </Heading>
                <Text color="navyblue-100" pb={2} textAlign="center" fontSize="medium">
                  Panther will not be able to send you alerts critical for the healthy status of
                  your system! <br />
                  You can create a new destination, or edit an existing one.
                </Text>
                <LinkButton to={urls.integrations.destinations.create()} size="medium">
                  Add Destination
                </LinkButton>
              </Flex>
            )}
            <ListDestinationsCards destinations={data.destinations} />
          </FadeIn>
        </Panel>
      </ErrorBoundary>
    </Box>
  );
};

export default compose(
  withSEO({ title: 'Destinations' }),
  withRoleRestrictedAccess({
    allowedPermissions: [Permission.DestinationRead, Permission.DestinationModify],
    fallback: <Page403 />,
  })
)(ListDestinations);
