/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Permission } from 'Generated/schema';
import withSEO from 'Hoc/withSEO';
import withRoleRestrictedAccess from 'Hoc/withRoleRestrictedAccess';
import Page403 from 'Pages/403';
import { compose } from 'Helpers/compose';
import Panel from 'Components/Panel';
import { Alert, Box, Text, Link } from 'pouncejs';
import Skeleton from './Skeleton/Skeleton';
import MitreMatrixPageContent from './MitreMatrixPageContent';
import MitreMatrixSwitcher from './MitreMatrixSwitcher';
import { MitreContextProvider, useMitreContext } from './MitreContext';

const MitreMatrixPage = () => {
  return (
    <MitreContextProvider>
      <MitreMatrixPagePanel />
    </MitreContextProvider>
  );
};

const MitreMatrixPagePanel = () => {
  const { isTreeLoading, treeDataError } = useMitreContext();

  if (isTreeLoading) {
    return <Skeleton.Page />;
  }

  if (treeDataError && !isTreeLoading) {
    return <Alert title="Failed to load matrices" variant="error" />;
  }

  return (
    <Box pb={8}>
      <Panel title={<MitreMatrixTitle />} actions={<MitreMatrixSwitcher />}>
        <MitreMatrixPageContent />
      </Panel>
    </Box>
  );
};

const MitreMatrixTitle = () => {
  const { versionName, versionLink } = useMitreContext();

  return (
    <Box>
      <Text fontSize="x-large" mb={1}>
        MITRE ATT&CK
      </Text>
      <Text fontSize="small" color="gray-300">
        Version {versionName} |&nbsp;
        <Link as="a" href={versionLink} external variant="neutral">
          View Changelog
        </Link>
      </Text>
    </Box>
  );
};

export default compose(
  withSEO({ title: 'MITRE ATT&CK Matrix' }),
  withRoleRestrictedAccess({
    allowedPermissions: [Permission.RuleRead, Permission.RuleModify],
    fallback: <Page403 />,
  })
)(MitreMatrixPage);
