/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Flex, Heading, Img, Link, Text } from 'pouncejs';
import LinkButton from 'Components/buttons/LinkButton';
import CloudSecurityImg from 'Assets/illustrations/cloud-security.svg';
import { PUBLIC_API_KEY_GENERATION_DOC_URL } from 'Source/constants';
import urls from 'Source/urls';
import Panel from 'Components/Panel';
import RoleRestrictedAccess from 'Components/utils/RoleRestrictedAccess';
import { Permission } from 'Generated/schema';

const EmptyDataFallback: React.FC = () => {
  return (
    <Panel title="API Tokens">
      <Flex direction="column" justify="center" align="center">
        <Img
          alt="Man with laptop sitting on DB entries"
          src={CloudSecurityImg}
          nativeWidth="auto"
          nativeHeight={200}
          mb={10}
        />
        <Heading as="h1" fontWeight="medium" mb={4}>
          You haven’t created any tokens yet
        </Heading>
        <Text color="gray-300" mb={10}>
          Use your API Token to make requests to the Panther API .
        </Text>
        <Link href={PUBLIC_API_KEY_GENERATION_DOC_URL} variant="neutral" external mb={10}>
          Get started with our API Docs
        </Link>
        <RoleRestrictedAccess allowedPermissions={[Permission.OrganizationApiTokenModify]}>
          <LinkButton to={urls.settings.apiTokens.create()}>Create an API Token</LinkButton>
        </RoleRestrictedAccess>
      </Flex>
    </Panel>
  );
};

export default EmptyDataFallback;
