/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

/* The component responsible for rendering the actual modals */
import React from 'react';
import useModal from 'Hooks/useModal';
import { Modal } from 'pouncejs';

const ModalManager: React.FC = () => {
  const { modalState, hideModal } = useModal();
  const { modalContent, modalProps, open } = modalState;
  const { onClose = () => {}, ...restModalProps } = modalProps;

  const onModalClose = React.useCallback(() => {
    onClose();
    hideModal();
  }, [onClose, hideModal]);

  if (!modalContent) {
    return null;
  }

  return (
    <Modal onClose={onModalClose} open={open} {...restModalProps}>
      {modalContent}
    </Modal>
  );
};

export default ModalManager;
