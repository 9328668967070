/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Box, Flex, FormHelperText } from 'pouncejs';
import ErrorBoundary from 'Components/ErrorBoundary';
import { FastField, Field, useFormikContext } from 'formik';
import FormikTextInput from 'Components/fields/TextInput';
import FormikMultiCombobox from 'Components/fields/MultiComboBox';
import { WizardPanel } from 'Components/Wizard';
import { PANTHER_CONFIG } from 'Source/constants';
import logo from 'Assets/sqs-minimal-logo.svg';
import EditIntegrationActions from 'Pages/Integrations/components/EditIntegrationActions';
import { SqsLogSourceWizardValues } from '../SqsSourceWizard';

const emptyArray = [];

const SqsSourceConfigurationPanel: React.FC = () => {
  const { initialValues, isValid, dirty, status } = useFormikContext<SqsLogSourceWizardValues>();
  const editMode = !!initialValues.integrationId;
  return (
    <WizardPanel>
      <Box width={500} m="auto">
        <WizardPanel.Heading
          title={editMode ? 'Update the SQS source' : 'Configure your source'}
          subtitle={
            editMode
              ? 'Feel free to make any changes to your SQS log source'
              : 'We need some information in order to create your queue'
          }
          logo={logo}
        />
        <ErrorBoundary>
          <Flex direction="column" spacing={5}>
            <Field
              name="integrationLabel"
              as={FormikTextInput}
              label="Name"
              placeholder="A nickname for this SQS log source"
              required
            />
            <Field
              as={FormikMultiCombobox}
              searchable
              label="Log Types"
              name="logTypes"
              items={status.availableLogTypes}
              itemToGroup={(item: string) => item.split('.')[0]}
              placeholder="Which log types should we monitor?"
            />
            <Box as="fieldset">
              <FastField
                as={FormikMultiCombobox}
                label="Allowed AWS Principal ARNs"
                name="allowedPrincipalArns"
                searchable
                allowAdditions
                items={emptyArray}
                placeholder="The allowed AWS Principals ARNs (separated with <Enter>)"
              />
              <FormHelperText id="aws-principals-arn-helper" mt={4}>
                <i>
                  The ARN of the AWS Principals that are allowed to send data to the queue,
                  separated with {'<'}Enter{'>'} (i.e. arn:aws:iam::{PANTHER_CONFIG.AWS_ACCOUNT_ID}
                  :root)
                </i>
              </FormHelperText>
            </Box>
            <Box as="fieldset">
              <FastField
                as={FormikMultiCombobox}
                label="Allowed Source ARNs"
                name="allowedSourceArns"
                searchable
                allowAdditions
                items={emptyArray}
                placeholder="The allowed AWS resources ARNs (separated with <Enter>)"
              />
              <FormHelperText id="aws-resources-arn-helper" mt={4}>
                <i>
                  The AWS resources (SNS topics, S3 buckets, etc) that are allowed to send data to
                  the queue, separated with {'<'}Enter{'>'} (i.e. arn:aws:sns:
                  {PANTHER_CONFIG.AWS_REGION}:{PANTHER_CONFIG.AWS_ACCOUNT_ID}
                  :my-topic).
                </i>
              </FormHelperText>
            </Box>
          </Flex>
        </ErrorBoundary>
      </Box>
      {editMode ? (
        <EditIntegrationActions />
      ) : (
        <WizardPanel.Actions>
          <WizardPanel.ActionNext disabled={!isValid || !dirty}>
            Continue Setup
          </WizardPanel.ActionNext>
        </WizardPanel.Actions>
      )}
    </WizardPanel>
  );
};

export default SqsSourceConfigurationPanel;
