/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Box, Flex, Heading, Text } from 'pouncejs';
import EmptyDataImg from 'Assets/illustrations/empty-box.svg';
import urls from 'Source/urls';
import LinkButton from 'Components/buttons/LinkButton';

const EmptyDataFallback: React.FC = () => (
  <Flex height="100%" width="100%" justify="center" align="center" direction="column">
    <Box m={10}>
      <img alt="Empty data illustration" src={EmptyDataImg} width="auto" height={400} />
    </Box>
    <Heading mb={6}>It{"'"}s empty in here</Heading>
    <Text color="gray-300" textAlign="center" mb={8}>
      You don{"'"}t seem to have any Data Models configured in our system. <br />
    </Text>
    <LinkButton to={urls.data.dataModels.create()}>Create a Data Model</LinkButton>
  </Flex>
);

export default EmptyDataFallback;
