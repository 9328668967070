/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Text, Button, Card, Flex, Img } from 'pouncejs';
import InfoStatus from 'Assets/statuses/info.svg';
import urls from 'Source/urls';
import LinkButton from 'Components/buttons/LinkButton';

const SuppressRTSWarning = () => {
  const [suppressRTSWarning, setSuppressRTSWarning] = React.useState(false);

  return suppressRTSWarning ? (
    <Card p={6} mt={10} as="section" position="absolute" left={0} width={1}>
      <Flex align="center" spacing={4}>
        <Img nativeWidth={32} nativeHeight={32} alt="Alert Info" src={InfoStatus} />

        <Text fontSize="medium" maxWidth="50%">
          You chose not to have Real-Time scanning enabled for this source. You can always come back
          and enable it
        </Text>

        <Button
          // @ts-ignore
          ml="auto"
          variantColor="teal-300"
          size="medium"
          onClick={() => setSuppressRTSWarning(false)}
        >
          Changed My Mind
        </Button>
      </Flex>
    </Card>
  ) : (
    <Card p={6} mt={10} as="section" position="absolute" left={0} width={1}>
      <Flex align="center" spacing={4}>
        <Img nativeWidth={32} nativeHeight={32} alt="Alert Info" src={InfoStatus} />
        <Text fontSize="medium">
          Panther will now scan your AWS resources daily for security issues and alert you when a
          policy is broken. If you would like{' '}
          <Text as="span" fontWeight="bold">
            real time
          </Text>{' '}
          scanning and alerting of your AWS resources,{' '}
          <Text as="span" fontWeight="bold">
            click here
          </Text>{' '}
          to set up CloudTrail logs as a log source.
        </Text>
      </Flex>
      <Flex justify="flex-end" spacing={2} mt={2}>
        <Button
          variantColor="navyblue-350"
          size="medium"
          onClick={() => setSuppressRTSWarning(true)}
        >
          Skip
        </Button>
        <LinkButton
          variantColor="teal-300"
          size="medium"
          to={`${urls.integrations.logSources.create()}?logProviderId=aws`}
        >
          Setup CloudTrail
        </LinkButton>
      </Flex>
    </Card>
  );
};

export default SuppressRTSWarning;
