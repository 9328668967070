/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { useFormikContext } from 'formik';
import { AbstractButton, Alert, Box, Card, Flex, Img, Link, Spinner } from 'pouncejs';

import SuccessStatus from 'Assets/statuses/success.svg';
import SuccessIllustration from 'Assets/illustrations/onboard-success.svg';
import LinkButton from 'Components/buttons/LinkButton';
import { useWizardContext, WizardPanel } from 'Components/Wizard';
import { UnhealthyPanel } from 'Components/SourceVerificationPanels';
import { extractErrorMessage } from 'Helpers/utils';
import urls from 'Source/urls';
import { useGetComplianceSource } from '../../EditComplianceSource/graphql/getComplianceSource.generated';
import { ComplianceSourceWizardValues } from '../ComplianceSourceWizard';
import SuppressRTSWarning from './SuppressRTSWarning';

const HealthCheckPanel: React.FC = () => {
  const { reset: resetWizard, data: wizardData } = useWizardContext<{ integrationId?: string }>();
  const { initialValues, resetForm } = useFormikContext<ComplianceSourceWizardValues>();

  const { data, loading, error, refetch } = useGetComplianceSource({
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
    variables: { id: wizardData.integrationId },
  });

  if (loading) {
    return (
      <Flex align="center" justify="center" height={380}>
        <Spinner />
      </Flex>
    );
  }

  if (error || !data) {
    return (
      <Flex align="center" justify="center" height={380}>
        <Alert
          variant="error"
          title="Couldn't fetch health metrics"
          description={
            extractErrorMessage(error) || 'Failed to get the Cloud Account health details'
          }
        />
      </Flex>
    );
  }

  if (!data.getComplianceIntegration.health.auditRoleStatus.healthy) {
    return (
      <UnhealthyPanel
        unhealthyMetrics={[data.getComplianceIntegration.health.auditRoleStatus]}
        onRetry={() => refetch()}
        sourceListingUrl={urls.integrations.cloudAccounts.list()}
      />
    );
  }

  const { realtimeScanningEnabled } = data.getComplianceIntegration;
  const editMode = Boolean(initialValues.integrationId);

  return (
    <WizardPanel>
      <Flex align="center" direction="column" mx="auto" width={550}>
        <WizardPanel.Heading title="Everything looks good!" divider={null} />
        <Img
          nativeWidth={157}
          nativeHeight={118}
          alt="Stack deployed successfully"
          src={SuccessIllustration}
        />
        <Card variant="dark" p={4} width={1} mt={6}>
          <Flex align="center" spacing={4}>
            <Img
              nativeWidth={32}
              nativeHeight={32}
              alt="Stack deployed successfully"
              src={SuccessStatus}
            />
            <Box>
              {editMode
                ? 'Your stack was successfully updated'
                : 'Your configured stack was deployed successfully and your source’s setup is now complete!'}
            </Box>
          </Flex>
        </Card>

        {realtimeScanningEnabled && (
          <Card variant="dark" p={4} width={1} mt={3}>
            <Flex align="center" spacing={4}>
              <Img
                nativeWidth={32}
                nativeHeight={32}
                alt="Real time scanning status"
                src={SuccessStatus}
              />
              <Box>Real-Time scanning is enabled (5-15 mins latency)</Box>
            </Flex>
          </Card>
        )}
        <WizardPanel.Actions>
          <Flex direction="column" spacing={4}>
            <LinkButton to={urls.integrations.cloudAccounts.list()}>Finish Setup</LinkButton>
            {!editMode && (
              <Link
                as={AbstractButton}
                variant="discreet"
                onClick={() => {
                  resetForm();
                  resetWizard();
                }}
              >
                Add Another
              </Link>
            )}
          </Flex>
        </WizardPanel.Actions>
      </Flex>
      {!realtimeScanningEnabled && <SuppressRTSWarning />}
    </WizardPanel>
  );
};

export default HealthCheckPanel;
