/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Flex, Text, SimpleGrid } from 'pouncejs';
import { FastField, Field } from 'formik';
import * as Yup from 'yup';
import FormikTextInput from 'Components/fields/TextInput';
import SensitiveTextInput from 'Components/fields/SensitiveTextInput';
import FormikRadio from 'Components/fields/Radio';
import { DestinationConfigInput, OpsgenieServiceRegionEnum } from 'Generated/schema';
import BaseDestinationForm, {
  BaseDestinationFormValues,
  defaultValidationSchema,
} from 'Components/forms/BaseDestinationForm';

type OpsgenieFieldValues = Pick<DestinationConfigInput, 'opsgenie'>;

interface OpsgenieDestinationFormProps {
  initialValues: BaseDestinationFormValues<OpsgenieFieldValues>;
  onSubmit: (values: BaseDestinationFormValues<OpsgenieFieldValues>) => void;
}

const OpsgenieDestinationForm: React.FC<OpsgenieDestinationFormProps> = ({
  onSubmit,
  initialValues,
}) => {
  const existing = initialValues.outputId;

  const opsgenieFieldsValidationSchema = Yup.object().shape({
    outputConfig: Yup.object().shape({
      opsgenie: Yup.object().shape({
        apiKey: existing ? Yup.string() : Yup.string().required(),
        serviceRegion: Yup.string().required(),
      }),
    }),
  });

  const mergedValidationSchema = defaultValidationSchema.concat(opsgenieFieldsValidationSchema);

  return (
    <BaseDestinationForm<OpsgenieFieldValues>
      initialValues={initialValues}
      validationSchema={mergedValidationSchema}
      onSubmit={onSubmit}
    >
      <SimpleGrid gap={5} columns={2}>
        <Field
          name="displayName"
          as={FormikTextInput}
          label="* Display Name"
          placeholder="How should we name this?"
          required
        />
        <Field
          as={SensitiveTextInput}
          shouldMask={!!existing}
          name="outputConfig.opsgenie.apiKey"
          label="Opsgenie API key"
          placeholder="What's your organization's Opsgenie API key?"
          required={!existing}
          autoComplete="new-password"
        />
        <Text fontSize="small-medium" id="serviceRegion-label-text" color="gray-300" my={1}>
          Change this selection to EU if you are registered to Opsgenie Europe
          (app.eu.opsgenie.com).
        </Text>
        <Flex align="flex-start" justify="space-between">
          <FastField
            as={FormikRadio}
            name="outputConfig.opsgenie.serviceRegion"
            value={OpsgenieServiceRegionEnum.Us}
            label="US Service Region"
            aria-describedby="serviceRegion-label-text"
          />
          <FastField
            as={FormikRadio}
            name="outputConfig.opsgenie.serviceRegion"
            value={OpsgenieServiceRegionEnum.Eu}
            label="EU Service Region"
            aria-describedby="serviceRegion-label-text"
          />
        </Flex>
      </SimpleGrid>
    </BaseDestinationForm>
  );
};

export default OpsgenieDestinationForm;
