/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Link as RRLink } from 'react-router-dom';
import { Box, Flex, Link, Img, Card, Icon, Button, Collapse, Badge } from 'pouncejs';
import urls from 'Source/urls';
import { CardValue, CardBulletDivider, CardMetaValue } from 'Components/cards/common';
import { getElapsedTime, formatDatetime } from 'Helpers/utils';
import LimitItemDisplay from 'Components/LimitItemDisplay';
import AdditionalBadgesJewel from 'Components/AdditionalBadgesJewel';
import SourceHealthWarning from 'Components/SourceHealthWarning';
import useGetLogSourceHealthMetrics from 'Hooks/useGetLogSourceHealthMetrics';
import {
  getSourceMeta,
  getLogSourceType,
  getLogSourceLogo,
  SourceIntegrationType,
} from 'Pages/Integrations/utils';
import AttachSchemaLink from './common/AttachSchemaLink';
import LogSourceCardOptions from './LogSourceCardOptions/LogSourceCardOptions';

export interface LogSourceCardProps {
  source: SourceIntegrationType;
}

const LogSourceCard: React.FC<LogSourceCardProps> = ({ source }) => {
  const [metadata, sourceType, logo] = React.useMemo(() => {
    const meta = getSourceMeta(source, false);
    const logSourceType = getLogSourceType(source);
    const logSourceLogo = getLogSourceLogo(source);
    return [meta, logSourceType, logSourceLogo];
  }, [source]);

  const [open, setOpen] = React.useState(false);
  const { unhealthyMetrics } = useGetLogSourceHealthMetrics(source);
  const lastReceivedMessage = React.useMemo(() => {
    return source.lastEventReceived
      ? `${getElapsedTime(new Date(source.lastEventReceived).getTime() / 1000)}`
      : 'No Data Received yet';
  }, [source.lastEventReceived]);

  return (
    <Card
      as="section"
      variant="dark"
      p={4}
      {...(!source.isHealthy && { borderLeft: '4px solid', borderLeftColor: 'red-300' })}
    >
      <Flex position="relative">
        <Img nativeWidth={20} nativeHeight={20} mr={5} alt="Logo" src={logo} />
        <Flex direction="column" justify="space-between" width={1}>
          <Flex as="header" align="flex-start" mb={2}>
            <Box as="h4" fontWeight="medium" mr="auto" maxWidth="50%" wordBreak="break-word">
              <Link
                as={RRLink}
                data-testid="log-source-card-label"
                to={urls.integrations.logSources.details(source.integrationId, sourceType)}
              >
                {source.integrationLabel}
              </Link>
            </Box>
            {!source.alarms.eventThreshold && (
              <Flex align="center">
                <Icon type="notification-snooze" size="x-small" mr={2} />
                <CardMetaValue value="Notifications snoozed" />
                <CardBulletDivider />
              </Flex>
            )}
            <CardMetaValue
              label={source.lastEventReceived ? 'Last Received Data' : null}
              value={lastReceivedMessage}
            />
            <Box mt={-1} ml={2}>
              <LogSourceCardOptions source={source} />
            </Box>
          </Flex>
          <Flex direction="column" spacing={2} mr={6}>
            {!source.isHealthy && (
              <SourceHealthWarning
                unhealthyMetrics={unhealthyMetrics}
                title="Source has turned Unhealthy"
                integrationId={source.integrationId}
                sourceType={sourceType}
                withActions
              />
            )}
            <Card variant="darker" overflow="hidden" p={4}>
              <Flex justify="space-between">
                <Flex spacing={1}>
                  <LimitItemDisplay limit={3} AdditionalItemsComponent={AdditionalBadgesJewel}>
                    {source.logTypes.length ? (
                      source.logTypes.map(logType => (
                        <Badge key={logType} color="navyblue-200">
                          {logType}
                        </Badge>
                      ))
                    ) : (
                      <AttachSchemaLink source={source} />
                    )}
                  </LimitItemDisplay>
                </Flex>
                <Button
                  onClick={() => setOpen(!open)}
                  size="small"
                  variantColor="navyblue-400"
                  icon={open ? 'chevron-up' : 'chevron-down'}
                  iconAlignment="right"
                >
                  {open ? 'Hide Details' : 'Show Details'}
                </Button>
              </Flex>
              <Collapse open={open}>
                <Flex wrap="wrap" spacing={8}>
                  {metadata?.map(({ label, value }) => (
                    <CardValue key={label} label={label} value={value} />
                  ))}
                  <CardValue label="Created" value={formatDatetime(source.createdAtTime)} />
                </Flex>
              </Collapse>
            </Card>
          </Flex>
        </Flex>
      </Flex>
    </Card>
  );
};

export default LogSourceCard;
