/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import * as Types from '../../../../../../../__generated__/schema';

import { S3LogIntegrationDetails } from '../../../../../../graphql/fragments/S3LogIntegrationDetails.generated';
import { GraphQLError } from 'graphql';
import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';

export type AddS3LogSourceVariables = {
  input: Types.AddS3LogIntegrationInput;
};

export type AddS3LogSource = { addS3LogIntegration: S3LogIntegrationDetails };

export const AddS3LogSourceDocument = gql`
  mutation AddS3LogSource($input: AddS3LogIntegrationInput!) {
    addS3LogIntegration(input: $input) {
      ...S3LogIntegrationDetails
    }
  }
  ${S3LogIntegrationDetails}
`;
export type AddS3LogSourceMutationFn = ApolloReactCommon.MutationFunction<
  AddS3LogSource,
  AddS3LogSourceVariables
>;

/**
 * __useAddS3LogSource__
 *
 * To run a mutation, you first call `useAddS3LogSource` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddS3LogSource` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addS3LogSource, { data, loading, error }] = useAddS3LogSource({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddS3LogSource(
  baseOptions?: ApolloReactHooks.MutationHookOptions<AddS3LogSource, AddS3LogSourceVariables>
) {
  return ApolloReactHooks.useMutation<AddS3LogSource, AddS3LogSourceVariables>(
    AddS3LogSourceDocument,
    baseOptions
  );
}
export type AddS3LogSourceHookResult = ReturnType<typeof useAddS3LogSource>;
export type AddS3LogSourceMutationResult = ApolloReactCommon.MutationResult<AddS3LogSource>;
export type AddS3LogSourceMutationOptions = ApolloReactCommon.BaseMutationOptions<
  AddS3LogSource,
  AddS3LogSourceVariables
>;
export function mockAddS3LogSource({
  data,
  variables,
  errors,
}: {
  data: AddS3LogSource;
  variables?: AddS3LogSourceVariables;
  errors?: GraphQLError[];
}) {
  return {
    request: { query: AddS3LogSourceDocument, variables },
    result: { data, errors },
  };
}
