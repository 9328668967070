/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { DetectionTestDefinition, DetectionTestDefinitionInput } from 'Generated/schema';
import ConfirmModal from '../ConfirmModal';

export interface DeleteTestModalProps {
  test: DetectionTestDefinition | DetectionTestDefinitionInput;
  onConfirm: () => void;
}

const DeleteTestModal: React.FC<DeleteTestModalProps> = ({ test, onConfirm, ...rest }) => {
  return (
    <ConfirmModal
      variant="destructive"
      subtitle={`Are you sure you want to delete ${test.name}?`}
      onConfirm={onConfirm}
      {...rest}
    />
  );
};

export default DeleteTestModal;
