/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Button, useSnackbar } from 'pouncejs';
import useAsyncQueryContext from 'Hooks/useAsyncQueryContext';
import { useGetAsyncQueryDownloadUrlLazyQuery } from './graphql/getAsyncQueryDownloadUrl.generated';

interface DownloadAllButtonProps {
  disabled: boolean;
}

const DownloadAllButton: React.FC<DownloadAllButtonProps> = ({ disabled }) => {
  const { state: { queryId } } = useAsyncQueryContext(); // prettier-ignore
  const { pushSnackbar } = useSnackbar();
  const [getDownloadUrl, { loading }] = useGetAsyncQueryDownloadUrlLazyQuery({
    // FIXME: If we don't add  `network-only, the `onCompleted` is only fired on the first click
    //  and not on any subsequent ones
    // https://github.com/apollographql/apollo-client/issues/6636
    // https://github.com/apollographql/react-apollo/issues/4000
    fetchPolicy: 'network-only',
    onCompleted: data => {
      if (data.getAsyncQueryDownloadUrl.error) {
        pushSnackbar({
          variant: 'error',
          title: `Failed to download`,
          description: data.getAsyncQueryDownloadUrl.error.message,
          duration: 5000,
        });
      } else {
        window.location.href = data.getAsyncQueryDownloadUrl.url;
      }
    },
    onError: () => {
      pushSnackbar({
        variant: 'error',
        title: `Failed to download`,
      });
    },
  });

  // FIXME: variables can ONLY be declared on invocation, due to a bug with Apollo and useLazyQuery
  // https://github.com/apollographql/react-apollo/issues/3729#issuecomment-584242862
  return (
    <Button
      onClick={() => getDownloadUrl({ variables: { input: { id: queryId } } })}
      disabled={disabled || loading}
    >
      {loading ? 'Fetching CSV...' : 'Download CSV'}
    </Button>
  );
};

export default React.memo(DownloadAllButton);

// sql: "SELECT p_rule_id, p_rule_tags, p_alert_id, p_event_time FROM panther_rule_matches.aws_alb t ↵WHERE t.p_alert_id='182434814a180249b20d6faf02894cd4' AND (t.year=2020 AND t.month=7 AND t.day=21 AND t.hour between 9 AND 10) ↵LIMIT 100↵ UNION ALL↵SELECT p_rule_id, p_rule_tags, p_alert_id, p_event_time FROM panther_rule_matches.aws_s3serveraccess t ↵WHERE t.p_alert_id='182434814a180249b20d6faf02894cd4' AND (t.year=2020 AND t.month=7 AND t.day=21 AND t.hour between 9 AND 10) ↵LIMIT 100↵ UNION ALL↵SELECT p_rule_id, p_rule_tags, p_alert_id, p_event_time FROM panther_rule_matches.aws_vpcflow t ↵WHERE t.p_alert_id='182434814a180249b20d6faf02894cd4' AND (t.year=2020 AND t.month=7 AND t.day=21 AND t.hour between 9 AND 10) ↵LIMIT 100"
