/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import withSEO from 'Hoc/withSEO';
import { useSnackbar } from 'pouncejs';
import useRouter from 'Hooks/useRouter';
import urls from 'Source/urls';
import DataSchemaForm from 'Components/forms/DataSchemaForm';
import { appendDataSchemaPrefix, extractErrorMessage } from 'Helpers/utils';
import withRoleRestrictedAccess from 'Hoc/withRoleRestrictedAccess';
import { Permission, SchemaRecord } from 'Generated/schema';
import { compose } from 'Helpers/compose';
import Page403 from 'Pages/403';
import useUrlParams from 'Hooks/useUrlParams';
import useCloneDataSchema from 'Hooks/useCloneDataSchema';
import { SchemaGenerationProvider } from 'Components/utils/SchemaGenerationContext';
import { useCreateDataSchema } from './graphql/createDataSchema.generated';

const EMPTY_OBJECT = {};

const CreateDataSchema: React.FC = () => {
  const { history } = useRouter();
  const { pushSnackbar } = useSnackbar();
  const {
    urlParams: { cloneId },
  } = useUrlParams();
  const { retrieveClonedSchema } = useCloneDataSchema();
  const clonedSchema = (retrieveClonedSchema(cloneId) || EMPTY_OBJECT) as SchemaRecord;

  const initialValues = React.useMemo(
    () => ({
      name: clonedSchema?.name || '',
      description: clonedSchema?.description || '',
      referenceURL: clonedSchema?.referenceURL || '',
      spec: clonedSchema?.spec || '',
    }),
    [clonedSchema]
  );

  const [createDataSchema] = useCreateDataSchema({
    update: (cache, { data: { putUserSchema } }) => {
      const { record } = putUserSchema;
      if (record) {
        cache.modify({
          fields: {
            listAvailableLogTypes: queryData => {
              return {
                ...queryData,
                logTypes: [...queryData.logTypes, record.name],
              };
            },
          },
        });
      }
    },
    onCompleted: ({ putUserSchema: { record, error: schemaError } }) => {
      if (schemaError) {
        pushSnackbar({
          variant: 'error',
          title: schemaError.message,
        });
      } else {
        pushSnackbar({
          variant: 'success',
          title: 'Successfully created data schema!',
        });
        history.push(urls.data.schemas.details(record.name));
      }
    },
    onError: error => {
      pushSnackbar({
        variant: 'error',
        title: extractErrorMessage(error),
      });
    },
  });

  return (
    <SchemaGenerationProvider initialSchema={initialValues.spec} sessionKey="NEW_SCHEMA_SESSION_ID">
      <DataSchemaForm
        cloneId={cloneId}
        initialValues={initialValues}
        onSubmit={values =>
          createDataSchema({
            variables: {
              input: {
                name: appendDataSchemaPrefix(values.name),
                description: values.description,
                referenceURL: values.referenceURL,
                spec: values.spec,
              },
            },
          })
        }
      />
    </SchemaGenerationProvider>
  );
};

export default compose(
  withSEO({ title: 'New Data Schema' }),
  withRoleRestrictedAccess({
    allowedPermissions: [Permission.LogSourceModify],
    fallback: <Page403 />,
  })
)(CreateDataSchema);
