/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import orderBy from 'lodash/orderBy';
import { SortDirEnum } from 'Generated/schema';
import useUrlParams from 'Hooks/useUrlParams';
import { ComplianceIntegrationDetails } from 'Source/graphql/fragments/ComplianceIntegrationDetails.generated';
import {
  ListComplianceSourceDropdownFilterValues,
  ListComplianceSourceInlineFilterValues,
} from './ListComplianceSourceActions/ListComplianceSourceFilters';
import { ListComplianceSources } from './graphql/listComplianceSources.generated';

type FilterUrlParams = ListComplianceSourceInlineFilterValues &
  ListComplianceSourceDropdownFilterValues;

const useLogSourceFiltering = (data: ListComplianceSources): ComplianceIntegrationDetails[] => {
  const { urlParams } = useUrlParams<FilterUrlParams>({
    parseNumbers: false,
    parseBooleans: false,
  });

  return React.useMemo(() => {
    if (!data?.listComplianceIntegrations?.length) {
      return [];
    }
    const { sortDir, contains, status } = urlParams;
    let { listComplianceIntegrations } = data;
    if (contains) {
      listComplianceIntegrations = listComplianceIntegrations.filter(
        ({ integrationLabel, awsAccountId }) =>
          integrationLabel.toLowerCase().includes(contains.toLowerCase()) ||
          awsAccountId.startsWith(contains)
      );
    }
    if (status) {
      listComplianceIntegrations = listComplianceIntegrations.filter(
        ({ health: { auditRoleStatus, realtimeStatus } }) =>
          (auditRoleStatus.healthy && realtimeStatus.healthy) === (status === 'Healthy')
      );
    }

    return orderBy(
      listComplianceIntegrations,
      l => l.createdAtTime,
      sortDir === SortDirEnum.Ascending ? 'asc' : 'desc'
    );
  }, [data, urlParams]);
};

export default useLogSourceFiltering;
