/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { SimpleGrid, Text } from 'pouncejs';

interface RowProps {
  newItems: number;
  modifiedItems: number;
  totalItems: number;
}

const Rows: React.FC<RowProps> = ({ newItems = 0, modifiedItems = 0, totalItems = 0 }) => (
  <>
    <SimpleGrid columns={2} mb={2}>
      <Text color="gray-300">New</Text>
      <Text fontWeight="bold" textAlign="right">
        {newItems}
      </Text>
    </SimpleGrid>
    <SimpleGrid columns={2} mb={2}>
      <Text color="gray-300">Modified</Text>
      <Text fontWeight="bold" textAlign="right">
        {modifiedItems}
      </Text>
    </SimpleGrid>
    <SimpleGrid columns={2}>
      <Text color="gray-300">Total</Text>
      <Text fontWeight="bold" textAlign="right">
        {totalItems}
      </Text>
    </SimpleGrid>
  </>
);

export default React.memo(Rows);
