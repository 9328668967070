/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { useSnackbar } from 'pouncejs';
import DataModelForm from 'Components/forms/DataModelForm';
import { DataModelFormValues } from 'Components/forms/DataModelForm/DataModelForm';
import { extractErrorMessage } from 'Helpers/utils';
import withSEO from 'Hoc/withSEO';
import useModal from 'Hooks/useModal';
import useRouter from 'Hooks/useRouter';
import withRoleRestrictedAccess from 'Hoc/withRoleRestrictedAccess';
import { Permission } from 'Generated/schema';
import Page403 from 'Pages/403/403';
import { compose } from 'Helpers/compose';
import urls from 'Source/urls';
import { ListDataModelsDocument } from 'Source/graphql/queries';
import DataModelWarningModal from 'Components/modals/DataModelWarningModal';
import { useCreateDataModel } from './graphql/createDataModel.generated';

const initialValues = {
  id: '',
  displayName: '',
  logType: null,
  enabled: true,
  mappings: [{ name: '', method: '', path: '' }],
  body: '',
  parentId: '',
  managed: false,
};

const CreateDataModel: React.FC = () => {
  const { history, location } = useRouter();
  const { pushSnackbar } = useSnackbar();
  const { showModal } = useModal();
  const cloneData = location?.state as Required<DataModelFormValues>;

  const [createDataModel] = useCreateDataModel({
    update: (cache, { data: { addDataModel: dataModel } }) => {
      cache.modify({
        fields: {
          listDataModels: (dataModels, { toReference }) => {
            const dataModelRef = toReference(dataModel);

            return {
              ...dataModels,
              models: dataModels.models ? [dataModelRef, ...dataModels.models] : [dataModelRef],
            };
          },
        },
      });
    },
    onCompleted: () => {
      history.push(urls.data.dataModels.list());
      showModal(
        <DataModelWarningModal subtitle="It may take up to 5 minutes before your data model is accessible in the rules engine." />,
        {
          title: 'Data Model successfully created',
        }
      );
    },
    onError: error => {
      pushSnackbar({
        variant: 'error',
        title: extractErrorMessage(error),
      });
    },
    refetchQueries: [{ query: ListDataModelsDocument, variables: { input: {} } }],
    awaitRefetchQueries: true,
  });

  return (
    <DataModelForm
      initialValues={cloneData || initialValues}
      onSubmit={values =>
        createDataModel({
          variables: {
            input: {
              id: values.id,
              displayName: values.displayName,
              logTypes: [values.logType],
              enabled: values.enabled,
              mappings: values.mappings,
              body: values.body,
              parentId: values.parentId,
              managed: values.managed,
            },
          },
        })
      }
    />
  );
};

export default compose(
  withSEO({ title: 'New Data Model' }),
  withRoleRestrictedAccess({
    allowedPermissions: [Permission.LogSourceModify],
    fallback: <Page403 />,
  })
)(CreateDataModel);
