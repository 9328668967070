/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import JSONBig from 'json-bigint';
import { Button } from 'pouncejs';
import useAsyncQueryContext from 'Hooks/useAsyncQueryContext';
import { downloadData } from 'Source/helpers/utils';

interface DownloadSingleButtonProps {
  // jsonData is generic as it can be anything coming from Athena
  jsonData: { [key: string]: any };
}

const DownloadSingleButton: React.FC<DownloadSingleButtonProps> = ({ jsonData }) => {
  const { state: { queryId } } = useAsyncQueryContext(); // prettier-ignore

  const filename = `row_${queryId}_${new Date().toISOString()}.json`;
  return (
    <Button onClick={() => downloadData(JSONBig.stringify(jsonData), filename)}>
      Download JSON
    </Button>
  );
};

export default React.memo(DownloadSingleButton);
