/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Box } from 'pouncejs';
import type { AvailableLogProviderLogoProps } from './AvailableLogProviderLogo';

const LazyAvailableLogProviderLogo = React.lazy(() =>
  import(/* webpackChunkName: "log-provider-icons" */ './AvailableLogProviderLogo')
);

const AvailableLogProviderLogo = (props: AvailableLogProviderLogoProps) => (
  <React.Suspense fallback={<Box width={props.size} height={props.size} />}>
    <LazyAvailableLogProviderLogo {...props} />
  </React.Suspense>
);

export default AvailableLogProviderLogo;
export type { AvailableLogProviderLogoProps };
