/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Box, Flex, Link, TextInput } from 'pouncejs';
import { SAAS_ONBOARDING_DUO_DOC_URL } from 'Source/constants';
import ErrorBoundary from 'Components/ErrorBoundary';
import { Field, useFormikContext } from 'formik';
import FormikTextInput from 'Components/fields/TextInput';
import SensitiveTextInput from 'Components/fields/SensitiveTextInput';
import { WizardPanel } from 'Components/Wizard';
import EditIntegrationActions from 'Pages/Integrations/components/EditIntegrationActions';
import { DuoLogsSourceWizardValues } from '../DuoLogsSourceWizard';

const DuoLogsSourceCredentialsPanel: React.FC = () => {
  const { initialValues, dirty, isValid, values } = useFormikContext<DuoLogsSourceWizardValues>();
  const editMode = !!initialValues.integrationId;
  return (
    <WizardPanel>
      <Box width={500} m="auto">
        <WizardPanel.Heading
          title="Fill in the form below with your credentials"
          subtitle={
            <React.Fragment>
              Check{' '}
              <Link external href={SAAS_ONBOARDING_DUO_DOC_URL}>
                our documentation
              </Link>{' '}
              in order to create a Duo application and fill in the next fields
            </React.Fragment>
          }
        />
        <ErrorBoundary>
          <Flex flexDirection="column" spacing={4}>
            <Field
              name="integrationKey"
              label="Integration Key"
              as={TextInput}
              placeholder={
                editMode
                  ? 'Information is hidden. New values will override the existing.'
                  : 'Your Duo integration key'
              }
              aria-describedby="integrationKey-helper"
              required={!editMode}
            />
            <Field
              name="secretKey"
              label="Secret Key"
              shouldMask={editMode}
              as={SensitiveTextInput}
              placeholder={
                editMode
                  ? 'Information is hidden. New values will override the existing.'
                  : 'Your Duo secret key'
              }
              aria-describedby="secretKey-helper"
              required={!editMode}
            />
            <Flex align="flex-start" spacing={4}>
              <Box
                as="span"
                position="absolute"
                transition="transform 0.1s ease-in-out"
                transform={
                  !values.apiHostname ? 'translate(-65px, 15px)' : 'translate(-65px, 21px)'
                }
                fontSize="medium"
                fontWeight="medium"
              >
                https://
              </Box>
              <Box flexGrow={1}>
                <Field
                  label="API hostname"
                  name="apiHostname"
                  as={FormikTextInput}
                  placeholder="Duo API hostname"
                  required
                />
              </Box>
            </Flex>
          </Flex>
        </ErrorBoundary>
      </Box>
      {editMode ? (
        <EditIntegrationActions />
      ) : (
        <WizardPanel.Actions>
          <WizardPanel.ActionNext disabled={!dirty || !isValid}>
            Continue Setup
          </WizardPanel.ActionNext>
        </WizardPanel.Actions>
      )}
    </WizardPanel>
  );
};

export default DuoLogsSourceCredentialsPanel;
