/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Alert, Box, useSnackbar } from 'pouncejs';
import useRouter from 'Hooks/useRouter';
import GlobalHelperForm from 'Components/forms/GlobalHelperForm';
import withSEO from 'Hoc/withSEO';
import withRoleRestrictedAccess from 'Hoc/withRoleRestrictedAccess';
import Page403 from 'Pages/403';
import { compose } from 'Helpers/compose';
import { GlobalHelper, Permission } from 'Generated/schema';
import { extractErrorMessage } from 'Helpers/utils';
import urls from 'Source/urls';
import { useGetGlobalHelper } from './graphql/getGlobalHelper.generated';
import { useUpdateGlobalHelper } from './graphql/updateGlobalHelper.generated';
import Skeleton from './Skeleton';

export const defaultInitialValues: Pick<
  GlobalHelper,
  'id' | 'description' | 'body' | 'parentId' | 'managed'
> = {
  description: '',
  id: '',
  body: '',
  parentId: null,
  managed: false,
};
const EditGlobalHelperPage: React.FC = () => {
  const { match, history } = useRouter<{ id: string }>();
  const { pushSnackbar } = useSnackbar();

  const {
    error: fetchPolicyError,
    data: queryData,
    loading: isFetchingGlobalHelper,
  } = useGetGlobalHelper({
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    variables: {
      input: {
        id: match.params.id,
      },
    },
  });

  const [updateGlobalHelper, { error: updateError }] = useUpdateGlobalHelper({
    onCompleted: () => {
      pushSnackbar({
        variant: 'success',
        title: 'Successfully updated global helper!',
      });
    },
    onError: () => null,
  });

  const handleSubmit = React.useCallback(
    values => {
      const { managed } = values;
      if (managed) {
        return history.push({
          pathname: urls.data.globalHelpers.create(),
          state: {
            ...values,
            managed: false,
            id: '',
          },
        });
      }
      return updateGlobalHelper({
        variables: { input: values },
      });
    },
    [history, updateGlobalHelper]
  );

  const initialValues = React.useMemo(() => {
    if (queryData) {
      const { id, body, description, parentId, managed } = queryData.getGlobalHelper;
      return { id, body, description, parentId, managed };
    }

    return defaultInitialValues;
  }, [queryData]);

  if (isFetchingGlobalHelper) {
    return <Skeleton />;
  }

  if (fetchPolicyError) {
    return (
      <Box mb={6}>
        <Alert
          variant="error"
          title="Couldn't load the policy details"
          description={
            extractErrorMessage(fetchPolicyError) ||
            'There was an error when performing your request, please contact support@runpanther.io'
          }
        />
      </Box>
    );
  }

  return (
    <Box mb={6} data-tracking-page="edit-global-helper">
      {updateError && (
        <Box mb={6}>
          <Alert
            variant="error"
            title={
              extractErrorMessage(updateError) ||
              'Unknown error occurred during update. Please contact support@runpanther.io'
            }
          />
        </Box>
      )}
      <GlobalHelperForm
        initialValues={initialValues}
        cacheSessionId={`global-helper-${queryData?.getGlobalHelper?.id}-${String(
          queryData?.getGlobalHelper?.lastModified
        )}`}
        onSubmit={handleSubmit}
      />
    </Box>
  );
};

export default compose(
  withSEO({ title: 'Edit Global Helper' }),
  withRoleRestrictedAccess({
    allowedPermissions: [Permission.RuleModify, Permission.PolicyModify],
    fallback: <Page403 />,
  })
)(EditGlobalHelperPage);
