/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { DestinationDetails } from 'Source/graphql/fragments/DestinationDetails.generated';
import { DESTINATIONS } from 'Source/constants';
import { DestinationTypeEnum, OpsgenieServiceRegionEnum } from 'Generated/schema';
import DestinationCard from './DestinationCard';

interface OpsGenieDestinationCardProps {
  destination: DestinationDetails;
}

const OpsGenieDestinationCard: React.FC<OpsGenieDestinationCardProps> = ({ destination }) => {
  const metadata = React.useMemo(
    () => [
      {
        key: 'Service Region',
        value:
          destination.outputConfig.opsgenie.serviceRegion === OpsgenieServiceRegionEnum.Eu
            ? 'European'
            : 'American (Default)',
      },
    ],
    [destination]
  );

  return (
    <DestinationCard
      logo={DESTINATIONS[DestinationTypeEnum.Opsgenie].logo}
      destination={destination}
      metadata={metadata}
    />
  );
};

export default React.memo(OpsGenieDestinationCard);
