/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Alert, Box } from 'pouncejs';
import urls from 'Source/urls';
import RuleForm, { RuleFormValues } from 'Components/forms/RuleForm';
import { AddRuleInput, Permission, DetectionTypeEnum } from 'Generated/schema';
import {
  DEFAULT_ALERT_CONTEXT_FUNCTION,
  DEFAULT_DEDUP_FUNCTION,
  DEFAULT_RULE_FUNCTION,
  DEFAULT_TITLE_FUNCTION,
  SCHEDULED_RULE_FUNCTION,
} from 'Source/constants';
import { cleanUpEmptyDetectionTestMocks, extractErrorMessage } from 'Helpers/utils';
import useRouter from 'Hooks/useRouter';
import withRoleRestrictedAccess from 'Hoc/withRoleRestrictedAccess';
import pick from 'lodash/pick';
import Page403 from 'Pages/403';
import useUrlParams from 'Hooks/useUrlParams';
import useCloneRule from 'Hooks/useCloneRule';
import { useCreateRule } from './graphql/createRule.generated';
import { CreateDetectionPresetValues } from '../CreateDetection';

interface CreateRuleProps {
  sessionId?: string;
  isScheduled?: boolean;
  presets?: CreateDetectionPresetValues;
}

export const createInitialValues = (
  isScheduled = false,
  presets: CreateDetectionPresetValues = {}
): Omit<Required<AddRuleInput>, 'analysisType'> => ({
  body: `${
    isScheduled ? SCHEDULED_RULE_FUNCTION : DEFAULT_RULE_FUNCTION
  }\n\n${DEFAULT_TITLE_FUNCTION}\n\n${DEFAULT_DEDUP_FUNCTION}\n\n${DEFAULT_ALERT_CONTEXT_FUNCTION}`,
  dedupPeriodMinutes: 60,
  threshold: 1,
  description: '',
  displayName: '',
  enabled: true,
  parentId: null,
  managed: false,
  id: '',
  logTypes: [],
  scheduledQueries: [],
  outputIds: [],
  reference: '',
  runbook: '',
  severity: null,
  summaryAttributes: [],
  tags: [],
  tests: [],
  reports: [],
  ...presets,
});

const CreateRule = ({ isScheduled = false, sessionId, presets = {} }: CreateRuleProps) => {
  const { history } = useRouter();
  const { retrieveClonedRule } = useCloneRule();
  const [createRule, { error }] = useCreateRule({
    onCompleted: data => history.push(urls.analysis.rules.details(data.addRule.id)),
    onError: () => null,
  });

  const {
    urlParams: { cloneId },
  } = useUrlParams();

  const handleSubmit = React.useCallback(
    ({ tests, ...rest }: RuleFormValues) =>
      createRule({
        variables: {
          input: {
            ...rest,
            tests: cleanUpEmptyDetectionTestMocks(tests),
            analysisType: isScheduled ? DetectionTypeEnum.ScheduledRule : DetectionTypeEnum.Rule,
          },
        },
      }),
    [isScheduled, createRule]
  );

  const initialValues = React.useMemo(() => {
    const defaultInitialValues = createInitialValues(isScheduled, presets);
    if (cloneId) {
      const clonedRule = retrieveClonedRule(cloneId);
      return pick(clonedRule, Object.keys(defaultInitialValues)) as RuleFormValues;
    }

    return defaultInitialValues;
  }, [isScheduled, retrieveClonedRule, cloneId, presets]);

  return (
    <Box mb={6}>
      {error && (
        <Box mb={6}>
          <Alert
            variant="error"
            title={
              extractErrorMessage(error) ||
              'An unknown error occurred as we were trying to create your rule'
            }
          />
        </Box>
      )}
      <RuleForm
        isScheduled={isScheduled}
        initialValues={initialValues}
        onSubmit={handleSubmit}
        cacheSessionId={sessionId}
      />
    </Box>
  );
};

export default withRoleRestrictedAccess({
  allowedPermissions: [Permission.RuleModify],
  fallback: <Page403 />,
})(CreateRule);
