/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Box, Card, Heading, Link, SimpleGrid, Text } from 'pouncejs';
import feedbackIcon from 'Assets/illustrations/feedback.svg';
import mailIcon from 'Assets/illustrations/mail.svg';
import withSEO from 'Hoc/withSEO';
import { PANTHER_DOCS_LINK } from 'Source/constants';
import SupportItemCard from './SupportItemCard';

export const supportLinks = {
  email: 'support@panther.io',
  demo: 'https://runpanther.io/request-a-demo/',
};

const SupportPage: React.FC = () => {
  return (
    <Card p={9} as="article">
      <Box as="header" mb={10} textAlign="center">
        <Heading size="large" fontWeight="medium">
          Get the support you need
        </Heading>
        <Text fontSize="large" mt={2} color="gray-300">
          You can also visit{' '}
          <Link external href={PANTHER_DOCS_LINK}>
            {' '}
            our documentation
          </Link>{' '}
          if you are facing any problems
        </Text>
      </Box>
      <SimpleGrid columns={1} spacing={6} width={597} m="auto">
        <SupportItemCard
          title="Technical Support"
          subtitle="Found a bug? Need technical support?"
          imgSrc={mailIcon}
          cta={
            <Link external href={`mailto:${supportLinks.email}`}>
              {supportLinks.email}
            </Link>
          }
        />
        <SupportItemCard
          title="Feature Requests"
          subtitle="Have a feature request? Want to see a small enhancement made? Let us know!"
          imgSrc={feedbackIcon}
          cta={
            <Link external href={`mailto:${supportLinks.email}`}>
              {supportLinks.email}
            </Link>
          }
        />
      </SimpleGrid>
    </Card>
  );
};

export default withSEO({ title: 'Support' })(SupportPage);
