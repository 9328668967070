/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import * as Types from '../../../../__generated__/schema';

import { AnalysisPackDetails } from '../../../graphql/fragments/AnalysisPackDetails.generated';
import { GraphQLError } from 'graphql';
import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';

export type GetAnalysisPackDetailsVariables = {
  id: Types.Scalars['ID'];
};

export type GetAnalysisPackDetails = { getAnalysisPack: AnalysisPackDetails };

export const GetAnalysisPackDetailsDocument = gql`
  query GetAnalysisPackDetails($id: ID!) {
    getAnalysisPack(id: $id) {
      ...AnalysisPackDetails
    }
  }
  ${AnalysisPackDetails}
`;

/**
 * __useGetAnalysisPackDetails__
 *
 * To run a query within a React component, call `useGetAnalysisPackDetails` and pass it any options that fit your needs.
 * When your component renders, `useGetAnalysisPackDetails` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAnalysisPackDetails({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetAnalysisPackDetails(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetAnalysisPackDetails,
    GetAnalysisPackDetailsVariables
  >
) {
  return ApolloReactHooks.useQuery<GetAnalysisPackDetails, GetAnalysisPackDetailsVariables>(
    GetAnalysisPackDetailsDocument,
    baseOptions
  );
}
export function useGetAnalysisPackDetailsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetAnalysisPackDetails,
    GetAnalysisPackDetailsVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<GetAnalysisPackDetails, GetAnalysisPackDetailsVariables>(
    GetAnalysisPackDetailsDocument,
    baseOptions
  );
}
export type GetAnalysisPackDetailsHookResult = ReturnType<typeof useGetAnalysisPackDetails>;
export type GetAnalysisPackDetailsLazyQueryHookResult = ReturnType<
  typeof useGetAnalysisPackDetailsLazyQuery
>;
export type GetAnalysisPackDetailsQueryResult = ApolloReactCommon.QueryResult<
  GetAnalysisPackDetails,
  GetAnalysisPackDetailsVariables
>;
export function mockGetAnalysisPackDetails({
  data,
  variables,
  errors,
}: {
  data: GetAnalysisPackDetails;
  variables?: GetAnalysisPackDetailsVariables;
  errors?: GraphQLError[];
}) {
  return {
    request: { query: GetAnalysisPackDetailsDocument, variables },
    result: { data, errors },
  };
}
