/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import ErrorBoundary from 'Components/ErrorBoundary';
import CompanyInformationForm from 'Components/forms/CompanyInformationForm';
import { Alert, useSnackbar } from 'pouncejs';
import { useGetGeneralSettings } from 'Pages/GeneralSettings/graphql/getGeneralSettings.generated';
import { useUpdateGeneralSettings } from 'Pages/GeneralSettings/graphql/updateGeneralSettings.generated';
import GeneralSettingsPageSkeleton from 'Pages/GeneralSettings/Skeleton';
import { extractErrorMessage } from 'Helpers/utils';

const CompanyInformationSection: React.FC = () => {
  const { pushSnackbar } = useSnackbar();

  const {
    loading: getGeneralSettingsLoading,
    error: getGeneralSettingsError,
    data: getGeneralSettingsData,
  } = useGetGeneralSettings();

  const [updateGeneralSettings] = useUpdateGeneralSettings({
    onCompleted: () => {
      pushSnackbar({ variant: 'success', title: `Your changes were saved successfully` });
    },
    onError: error => {
      pushSnackbar({
        variant: 'error',
        title: 'Failed to save your changes',
        description: extractErrorMessage(error),
      });
    },
  });

  if (getGeneralSettingsLoading) {
    return <GeneralSettingsPageSkeleton />;
  }

  if (getGeneralSettingsError) {
    return (
      <Alert
        variant="error"
        title="Failed to query company information"
        description={
          extractErrorMessage(getGeneralSettingsError) ||
          'Sorry, something went wrong, please reach out to support@runpanther.io if this problem persists'
        }
      />
    );
  }

  const { displayName, email, analyticsConsent } = getGeneralSettingsData.generalSettings;

  return (
    <ErrorBoundary>
      <CompanyInformationForm
        initialValues={{
          displayName,
          email,
          analyticsConsent,
        }}
        onSubmit={values => updateGeneralSettings({ variables: { input: values } })}
      />
    </ErrorBoundary>
  );
};

export default CompanyInformationSection;
