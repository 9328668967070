/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import FormikTextInput from 'Components/fields/TextInput';
import { FastField, useFormikContext } from 'formik';
import { Flex, Heading, SimpleGrid, Text, Button, useSnackbar } from 'pouncejs';
import React from 'react';
import { downloadData, toStackNameFormat } from 'Helpers/utils';
import { useGetLookupCloudFormationRoleTemplate } from '../graphql/getLookupCloudFormationRoleTemplate.generated';
import { LookupFormValues } from '../../lookupWizardHelpers';

const LookupIAMPolicyTemplate: React.FC = () => {
  const { pushSnackbar } = useSnackbar();
  const { values } = useFormikContext<LookupFormValues>();
  const { kmsKey, s3FileLocation, name } = values;
  const { data, loading, error } = useGetLookupCloudFormationRoleTemplate({
    variables: {
      input: {
        s3FileLocation,
        kmsKey,
        integrationLabel: toStackNameFormat(name),
      },
    },
    onError: () => pushSnackbar({ variant: 'error', title: 'Failed to generate CFN template' }),
  });

  const { stackName, body } = data?.getLookupCloudFormationRoleTemplate ?? {};
  return (
    <SimpleGrid gap={8} mx="auto">
      <Flex as="section" spacing={2} direction="column">
        <Heading as="h2" color="teal-200" fontSize="x-large" fontWeight="medium">
          1. Use a CloudFormation Template File to setup your role
        </Heading>
        <Text color="white-100" fontSize="medium" lineHeight="relaxed">
          Select and download one of the templates below and deploy it to create an IAM role that
          gives Panther read-only access to your S3 bucket.
        </Text>

        <Flex justifyContent="center">
          <Button
            icon="download"
            variantColor="teal-400"
            loading={loading}
            disabled={!!error || loading}
            onClick={() => downloadData(body, `${stackName}.yml`)}
            data-tid="lookup-iam-role-template-downloaded"
          >
            CloudFormation template
          </Button>
        </Flex>
      </Flex>

      <Flex as="section" spacing={2} direction="column">
        <Heading as="h2" color="teal-200" fontSize="x-large" fontWeight="medium">
          2. Enter Role ARN
        </Heading>
        <Text color="white-100" fontSize="medium" lineHeight="relaxed">
          Go to the {'"'}Outputs{'"'} tab of the CloudFormation stack that you just deployed and
          copy the value for RoleARN into the box below.
        </Text>

        <FastField
          name="iamRoleArn"
          as={FormikTextInput}
          label="Role ARN"
          placeholder="The ARN that Panther will use to read s3 objects."
          trimPastedText
          required
          data-tid="lookup-iam-role-arn-field"
        />
      </Flex>
    </SimpleGrid>
  );
};

export default LookupIAMPolicyTemplate;
