/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import {
  Text,
  Card,
  Flex,
  Link,
  Combobox,
  Heading,
  Spinner,
  Box,
  Tooltip,
  Icon,
  SimpleGrid,
} from 'pouncejs';
import { Link as RRLink } from 'react-router-dom';
import { AlertDetails } from 'Pages/AlertDetails';
import { extractErrorMessage } from 'Helpers/utils';
import { TableControlsPagination as PaginationControls } from 'Components/utils/TableControls';
import urls from 'Source/urls';
import useAsyncQueryContext from 'Hooks/useAsyncQueryContext';
import withAsyncQueryContext from 'Hoc/withAsyncQueryContext';
import { AlertOriginRule } from 'Generated/schema';
import {
  GenerateAlertSummaryQueries,
  useGenerateAlertSummaryQueries,
} from './graphql/generateAlertSummaryQueries.generated';
import AttributeChart from './AttributeChart';

type RuleAlertAttributeSummaryEntity = GenerateAlertSummaryQueries['generateAlertSummaryAttributesQueries'][0];

interface RuleAlertAttributesSummaryProps {
  alert: AlertDetails['alert'];
}

const summaryAttributeToString = (item: RuleAlertAttributeSummaryEntity) =>
  `${item.summaryAttribute} - ${item.logType}`;

const RuleAlertAttributesSummary: React.FC<RuleAlertAttributesSummaryProps> = ({ alert }) => {
  const { state: { queryId }, dispatch } = useAsyncQueryContext(); // prettier-ignore
  const [selectedAttribute, setSelectedAttribute] = React.useState<RuleAlertAttributeSummaryEntity>(null); // prettier-ignore
  const { data, loading, error } = useGenerateAlertSummaryQueries({
    fetchPolicy: 'network-only', // Don't use cache
    variables: {
      alertId: alert.id,
    },
  });

  const summaryAttributes = React.useMemo(() => data?.generateAlertSummaryAttributesQueries ?? [], [data]); // prettier-ignore

  const totalSummaryAttributes = summaryAttributes.length;

  const originData = alert.origin as AlertOriginRule;

  // We add `1` to account for 0-based indexing of Javascript (users want to see `1` not `0`)
  const selectedSummaryAttributeIndex = React.useMemo(
    () => summaryAttributes.findIndex(attr => attr === selectedAttribute) + 1,
    [selectedAttribute, summaryAttributes]
  );

  const showIndicatorSearch = selectedAttribute?.summaryAttribute?.startsWith('p_any_');

  // We subtract `1` to account for 0-based indexing
  const handleSummaryAttributeIndexChange = React.useCallback(
    (index: number) => {
      setSelectedAttribute(summaryAttributes[index - 1]);
    },
    [summaryAttributes, setSelectedAttribute]
  );

  // Make sure to set the first summary as the selection, if nothing is selected
  React.useEffect(() => {
    if (!selectedAttribute && summaryAttributes.length > 0 && !loading) {
      setSelectedAttribute(summaryAttributes[0]);
    }
  }, [selectedAttribute, summaryAttributes, loading, setSelectedAttribute]);

  // Make sure to change the polling `queryId` everytime the attribute is changed
  React.useEffect(() => {
    if (selectedAttribute && !selectedAttribute.error) {
      dispatch({ type: 'SWITCH_QUERY', payload: { queryId: selectedAttribute.query.id } });
    }
  }, [selectedAttribute, dispatch]);

  if (loading) {
    return (
      <Flex width="100%" height={100} align="center" justify="center">
        <Spinner />
      </Flex>
    );
  }

  if (error) {
    return (
      <Card bg="pink-700">
        <Flex align="center" justify="center" direction="column" p={6}>
          <Heading size="small" as="h4" color="pink-100" mb={2}>
            Something didn{"'"}t go as expected
          </Heading>
          <Text fontSize="medium" color="pink-100">
            {extractErrorMessage(error)}
          </Text>
        </Flex>
      </Card>
    );
  }

  return (
    <Flex spacing={6} direction="column">
      <SimpleGrid columns={3} align="center">
        <Heading as="h4" size="x-small">
          Alert Summary
          <Tooltip
            content={
              <Box maxWidth={150}>
                Showcases the most common values we found in the alert{"'"}s events, for the
                attributes you selected
              </Box>
            }
          >
            <Icon type="info" size="medium" ml={2} />
          </Tooltip>
        </Heading>
        <PaginationControls
          page={selectedSummaryAttributeIndex}
          totalPages={totalSummaryAttributes}
          onPageChange={handleSummaryAttributeIndexChange}
        />
        <Box flexBasis={350}>
          <Combobox<RuleAlertAttributeSummaryEntity>
            label="Attribute"
            items={summaryAttributes}
            itemToString={summaryAttributeToString}
            value={selectedAttribute}
            onChange={setSelectedAttribute}
            placeholder="Select a Summary Attribute"
            showClearSelectionControl={false}
          />
        </Box>
      </SimpleGrid>
      <Card variant="dark" p={6}>
        {!!selectedAttribute && (
          <Heading size="x-small" mb={6} textAlign="center" data-testid="attribute-header">
            Top{' '}
            <Box as="span" color="blue-100">
              {selectedAttribute.summaryAttribute}
            </Box>{' '}
            for{' '}
            <Box as="span" color="blue-100">
              {selectedAttribute.logType}
            </Box>
          </Heading>
        )}
        {selectedAttribute?.error ? (
          <Card bg="pink-700" p={6} maxWidth={450} mx="auto">
            <Text fontSize="medium" color="pink-100" textAlign="center">
              {selectedAttribute.error.message}
            </Text>
          </Card>
        ) : (
          <AttributeChart
            key={queryId}
            withIndicatorSearchPivotingAction={showIndicatorSearch}
            withCopyAction
          />
        )}
      </Card>
      <Text fontSize="small">
        Need to see more attributes?{' '}
        <Link as={RRLink} to={urls.analysis.rules.details(originData.ruleId)}>
          Edit the related rule
        </Link>
      </Text>
    </Flex>
  );
};

export default withAsyncQueryContext({ storage: 'memory' })(RuleAlertAttributesSummary);
