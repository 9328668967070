/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Link as RRLink } from 'react-router-dom';
import { Box, Flex, Divider, Link, SimpleGrid, Text, Card } from 'pouncejs';

import SeverityBadge from 'Components/badges/SeverityBadge';
import StatusBadge from 'Components/badges/StatusBadge';
import BulletedValueList from 'Components/BulletedValueList';
import { ENTITIES_COLOR_MAP } from 'Source/constants';
import urls from 'Source/urls';
import { ComplianceStatusEnum, DetectionTypeEnum, Permission } from 'Generated/schema';
import { SelectCheckbox } from 'Components/utils/SelectContext';
import { RuleSummary } from 'Source/graphql/fragments/RuleSummary.generated';
import { formatDatetime } from 'Helpers/utils';
import RoleRestrictedAccess from 'Components/utils/RoleRestrictedAccess';
import useDetectionDestinations from 'Hooks/useDetectionDestinations';
import RelatedDestinations from 'Components/RelatedDestinations';
import FlatBadge from 'Components/badges/FlatBadge';
import { CardValue, CardMetaValue } from 'Components/cards/common';
import LimitItemDisplay from 'Components/LimitItemDisplay';
import RuleCardOptions from './RuleCardOptions';
import { CardVariant, CardVariantConfig } from '../common/cardVariant';

interface RuleCardProps {
  rule: RuleSummary;
  onDelete?: (id: string) => void;
  selectionEnabled?: boolean;
  isSelected?: boolean;
  variant?: CardVariant;
}

const RuleCard: React.FC<RuleCardProps> = ({
  rule,
  onDelete = () => {},
  selectionEnabled = false,
  isSelected = false,
  variant = 'dark',
}) => {
  const {
    detectionDestinations,
    loading: loadingDetectionDestinations,
  } = useDetectionDestinations({ detection: rule });

  const variantConfig = CardVariantConfig[variant];

  const isScheduled = rule.analysisType === DetectionTypeEnum.ScheduledRule;
  return (
    <Card
      as="section"
      variant={isSelected ? variantConfig.selectedCardVariant : variantConfig.defaultCardVariant}
      p={4}
      data-testid={`detection-${rule.displayName || rule.id}`}
    >
      <Flex>
        {selectionEnabled && (
          <Box transform="translate3d(-12px,-12px,0)">
            <SelectCheckbox data-tid="single-select-detection" selectionItem={rule} />
          </Box>
        )}
        <Flex direction="column" justify="space-between" width={1}>
          <Flex as="header" align="flex-start" mb={2}>
            <Box as="h4" fontWeight="medium" mr="auto" maxWidth="80%" wordBreak="break-word">
              <Link
                as={RRLink}
                aria-label="Link to Rule"
                to={urls.analysis.rules.details(rule.id)}
                data-tid="link-to-detection"
              >
                {rule.displayName || rule.id}
              </Link>
            </Box>
            <CardMetaValue label="Updated" value={formatDatetime(rule.lastModified)} />
            <RoleRestrictedAccess allowedPermissions={[Permission.RuleModify]}>
              <Box mt={-1} ml={2}>
                <RuleCardOptions rule={rule} onDelete={onDelete} />
              </Box>
            </RoleRestrictedAccess>
          </Flex>
          <Box mr="auto">
            <FlatBadge color={ENTITIES_COLOR_MAP[rule.analysisType]}>
              {isScheduled ? 'SCHEDULED RULE' : 'RULE'}
            </FlatBadge>
          </Box>
          <SimpleGrid gap={2} columns={2}>
            {isScheduled ? (
              <CardValue
                label="Scheduled Queries"
                value={
                  <LimitItemDisplay limit={3}>
                    {rule.scheduledQueries.map(query => (
                      <Text key={query} mr={2}>
                        {query}
                      </Text>
                    ))}
                  </LimitItemDisplay>
                }
              />
            ) : (
              <CardValue
                label="Log Types"
                value={<BulletedValueList values={rule.logTypes} limit={3} />}
              />
            )}
            <Flex align="flex-end">
              <Flex spacing={2} align="center" width="100%" justify="flex-end">
                <RelatedDestinations
                  destinations={detectionDestinations}
                  loading={loadingDetectionDestinations}
                  limit={3}
                />
                <Divider mx={0} alignSelf="stretch" orientation="vertical" />
                <StatusBadge
                  status={rule.enabled ? 'ENABLED' : ComplianceStatusEnum.Error}
                  disabled={!rule.enabled}
                  testId={`status-${rule.id}`}
                />
                <SeverityBadge severity={rule.severity} />
              </Flex>
            </Flex>
          </SimpleGrid>
        </Flex>
      </Flex>
    </Card>
  );
};

export default React.memo(RuleCard);
