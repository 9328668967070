/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Text, Flex } from 'pouncejs';

interface QueryHistoryErroredProps {
  errorMessage: string;
}
const SQLErrorMessage: React.FC<QueryHistoryErroredProps> = ({ errorMessage }) => {
  return (
    <Flex direction="column" justify="center" align="center" spacing={4} maxWidth={700}>
      <Text
        p={4}
        borderRadius="large"
        textAlign="center"
        backgroundColor="pink-700"
        fontSize="medium"
      >
        {errorMessage}
      </Text>
    </Flex>
  );
};

export default React.memo(SQLErrorMessage);
