/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import * as Types from '../../../../../../../__generated__/schema';

import { EventbridgeLogIntegrationDetails } from '../../../../../../graphql/fragments/EventbridgeLogIntegrationDetails.generated';
import { GraphQLError } from 'graphql';
import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';

export type UpdateEventbridgeLogSourceVariables = {
  input: Types.UpdateEventbridgeIntegrationInput;
};

export type UpdateEventbridgeLogSource = {
  updateEventbridgeIntegration: EventbridgeLogIntegrationDetails;
};

export const UpdateEventbridgeLogSourceDocument = gql`
  mutation UpdateEventbridgeLogSource($input: UpdateEventbridgeIntegrationInput!) {
    updateEventbridgeIntegration(input: $input) {
      ...EventbridgeLogIntegrationDetails
    }
  }
  ${EventbridgeLogIntegrationDetails}
`;
export type UpdateEventbridgeLogSourceMutationFn = ApolloReactCommon.MutationFunction<
  UpdateEventbridgeLogSource,
  UpdateEventbridgeLogSourceVariables
>;

/**
 * __useUpdateEventbridgeLogSource__
 *
 * To run a mutation, you first call `useUpdateEventbridgeLogSource` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEventbridgeLogSource` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEventbridgeLogSource, { data, loading, error }] = useUpdateEventbridgeLogSource({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateEventbridgeLogSource(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateEventbridgeLogSource,
    UpdateEventbridgeLogSourceVariables
  >
) {
  return ApolloReactHooks.useMutation<
    UpdateEventbridgeLogSource,
    UpdateEventbridgeLogSourceVariables
  >(UpdateEventbridgeLogSourceDocument, baseOptions);
}
export type UpdateEventbridgeLogSourceHookResult = ReturnType<typeof useUpdateEventbridgeLogSource>;
export type UpdateEventbridgeLogSourceMutationResult = ApolloReactCommon.MutationResult<
  UpdateEventbridgeLogSource
>;
export type UpdateEventbridgeLogSourceMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateEventbridgeLogSource,
  UpdateEventbridgeLogSourceVariables
>;
export function mockUpdateEventbridgeLogSource({
  data,
  variables,
  errors,
}: {
  data: UpdateEventbridgeLogSource;
  variables?: UpdateEventbridgeLogSourceVariables;
  errors?: GraphQLError[];
}) {
  return {
    request: { query: UpdateEventbridgeLogSourceDocument, variables },
    result: { data, errors },
  };
}
