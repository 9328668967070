/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Wizard } from 'Components/Wizard';
import UploadPanel from './UploadPanel';
import SuccessfulUploadPanel from './SuccessfulUploadPanel';

const BulkUploaderWizard: React.FC = () => (
  <Wizard header={false}>
    <Wizard.Step>
      <UploadPanel />
    </Wizard.Step>
    <Wizard.Step>
      <SuccessfulUploadPanel />
    </Wizard.Step>
  </Wizard>
);

export default BulkUploaderWizard;
