/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import * as Types from '../../../__generated__/schema';

import { SavedQueryDetails } from '../fragments/SavedQueryDetails.generated';
import { GraphQLError } from 'graphql';
import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';

export type ListSavedQueriesVariables = {
  input: Types.ListSavedQueriesInput;
};

export type ListSavedQueries = {
  listSavedQueries: Pick<Types.ListSavedQueriesResponse, 'lastEvaluatedKey'> & {
    savedQueries: Array<SavedQueryDetails>;
  };
};

export const ListSavedQueriesDocument = gql`
  query ListSavedQueries($input: ListSavedQueriesInput!) {
    listSavedQueries(input: $input) {
      savedQueries {
        ...SavedQueryDetails
      }
      lastEvaluatedKey
    }
  }
  ${SavedQueryDetails}
`;

/**
 * __useListSavedQueries__
 *
 * To run a query within a React component, call `useListSavedQueries` and pass it any options that fit your needs.
 * When your component renders, `useListSavedQueries` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListSavedQueries({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useListSavedQueries(
  baseOptions?: ApolloReactHooks.QueryHookOptions<ListSavedQueries, ListSavedQueriesVariables>
) {
  return ApolloReactHooks.useQuery<ListSavedQueries, ListSavedQueriesVariables>(
    ListSavedQueriesDocument,
    baseOptions
  );
}
export function useListSavedQueriesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ListSavedQueries, ListSavedQueriesVariables>
) {
  return ApolloReactHooks.useLazyQuery<ListSavedQueries, ListSavedQueriesVariables>(
    ListSavedQueriesDocument,
    baseOptions
  );
}
export type ListSavedQueriesHookResult = ReturnType<typeof useListSavedQueries>;
export type ListSavedQueriesLazyQueryHookResult = ReturnType<typeof useListSavedQueriesLazyQuery>;
export type ListSavedQueriesQueryResult = ApolloReactCommon.QueryResult<
  ListSavedQueries,
  ListSavedQueriesVariables
>;
export function mockListSavedQueries({
  data,
  variables,
  errors,
}: {
  data: ListSavedQueries;
  variables?: ListSavedQueriesVariables;
  errors?: GraphQLError[];
}) {
  return {
    request: { query: ListSavedQueriesDocument, variables },
    result: { data, errors },
  };
}
