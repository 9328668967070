/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Flex, Link, Spinner } from 'pouncejs';
import { Link as RRLink } from 'react-router-dom';
import AuthPageContainer from 'Components/AuthPageContainer';
import queryString from 'query-string';
import useRouter from 'Hooks/useRouter';
import useAuth from 'Hooks/useAuth';
import urls from 'Source/urls';
import { safeBase64Decode } from 'Helpers/utils';

const SingleSignOnPage: React.FC = () => {
  const { singleSignOn } = useAuth();
  const [hasError, setError] = React.useState(false);
  const { location, history } = useRouter();
  const { code, state } = queryString.parse(location.search) as {
    code: string;
    state?: string;
  };

  React.useEffect(() => {
    if (state) {
      try {
        const referrer = safeBase64Decode(state);
        history.replace({
          ...location,
          state: { ...location.state, referrer },
        });
      } catch (err) {
        // noop - here to catch any parsing exception
      }
    }

    if (code) {
      singleSignOn({ code, onError: () => setError(true) });
    }
    // FIXME: Look into missing effect dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code, state]);

  if (!code) {
    return (
      <AuthPageContainer>
        <AuthPageContainer.Caption
          title="Something seems off..."
          subtitle="Are you sure that the URL you followed is valid?"
        />
        <Link as={RRLink} to={urls.account.auth.signIn()}>
          Sign in manually
        </Link>
      </AuthPageContainer>
    );
  }

  if (hasError) {
    return (
      <AuthPageContainer>
        <AuthPageContainer.Caption
          title="No can do"
          subtitle="The link you followed has an expired or misconfigured signature"
        />
        <Link as={RRLink} to={urls.account.auth.signIn()}>
          Sign in manually
        </Link>
      </AuthPageContainer>
    );
  }

  return (
    <AuthPageContainer>
      <AuthPageContainer.Caption title="Hold on tight" subtitle="We are signing you into Panther" />
      <Flex justify="center">
        <Spinner size="small" />
      </Flex>
    </AuthPageContainer>
  );
};

export default SingleSignOnPage;
