/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Field, Form, Formik } from 'formik';
import { SimpleGrid, Box, Flex, Heading, Divider } from 'pouncejs';
import * as Yup from 'yup';
import SubmitButton from 'Components/buttons/SubmitButton';
import FormikTextInput from 'Components/fields/TextInput';
import { Permission } from 'Generated/schema';
import { AnalyticsConsentSection } from 'Components/forms/ProductConsentForm';
import useCheckPermissions from 'Hooks/useCheckPermissions';

interface CompanyInformationFormValues {
  displayName: string;
  email: string;
  analyticsConsent: boolean;
}

interface CompanyInformationFormProps {
  initialValues: CompanyInformationFormValues;
  onSubmit: (values: CompanyInformationFormValues) => Promise<any>;
}

const validationSchema: Yup.SchemaOf<CompanyInformationFormValues> = Yup.object().shape({
  displayName: Yup.string().required(),
  email: Yup.string().email().required(),
  analyticsConsent: Yup.boolean().required(),
});

export const CompanyInformationForm: React.FC<CompanyInformationFormProps> = ({
  initialValues,
  onSubmit,
}) => {
  const userIsAllowedToModify = useCheckPermissions(Permission.GeneralSettingsModify);

  return (
    <Formik<CompanyInformationFormValues>
      enableReinitialize
      validationSchema={validationSchema}
      initialValues={initialValues}
      onSubmit={onSubmit}
    >
      <Form data-tid="general-settings-company-information">
        <SimpleGrid columns={2} spacing={8}>
          <Box as="section" mb={6}>
            <Heading as="h2" size="x-small" color="navyblue-100" mb={6}>
              Company Information
            </Heading>
            <Flex direction="column" spacing={4}>
              <Field
                data-tid="company-information-form-name"
                as={FormikTextInput}
                name="displayName"
                label="Company Name"
                disabled={!userIsAllowedToModify}
                placeholder="The name of the company"
                required
              />
              <Field
                data-tid="company-information-form-email"
                as={FormikTextInput}
                name="email"
                label="Email"
                disabled={!userIsAllowedToModify}
                placeholder="The company's email"
                required
              />
            </Flex>
          </Box>
          <Box as="section" mb={6}>
            <Heading as="h2" size="x-small" color="navyblue-100" mb={4}>
              Preferences
            </Heading>
            <AnalyticsConsentSection isDisabled={!userIsAllowedToModify} />
          </Box>
        </SimpleGrid>
        <Divider width="100%" color="navyblue-300" />
        {userIsAllowedToModify && (
          <Flex justify="center" pt={4}>
            <SubmitButton data-tid="company-information-form-save">Save Changes</SubmitButton>
          </Flex>
        )}
      </Form>
    </Formik>
  );
};

export default CompanyInformationForm;
