/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { SAAS_ONBOARDING_GITHUB_DOC_URL } from 'Source/constants';
import { Box, Card, Flex, FormHelperText, Link } from 'pouncejs';
import ErrorBoundary from 'Components/ErrorBoundary';
import { Field, useFormikContext, FastField } from 'formik';
import FormikRadio from 'Components/fields/Radio';
import SensitiveTextInput from 'Components/fields/SensitiveTextInput';
import { WizardPanel } from 'Components/Wizard';
import { GithubAuthorizationMethodEnum } from 'Generated/schema';
import EditIntegrationActions from 'Pages/Integrations/components/EditIntegrationActions';
import OAuthPullerCredentialsFields from 'Pages/Integrations/components/OAuthPullerCredentialsFields';
import { GithubLogsSourceWizardValues } from '../GithubLogsSourceWizard';

const CredentialsPanel: React.FC = () => {
  const { values, initialValues, isValid } = useFormikContext<GithubLogsSourceWizardValues>();

  const isCredentialOAuth2 = values.authorizationMethod === GithubAuthorizationMethodEnum.Oauth2;
  const isCredentialPAT = values.authorizationMethod === GithubAuthorizationMethodEnum.Pat;

  const clientSecretExists = Boolean(
    initialValues.integrationId &&
      initialValues.authorizationMethod === GithubAuthorizationMethodEnum.Oauth2
  );
  const personalAccessTokenExists = Boolean(
    initialValues.integrationId &&
      initialValues.authorizationMethod === GithubAuthorizationMethodEnum.Pat
  );

  const editMode = !!initialValues.integrationId;

  return (
    <WizardPanel>
      <Box width={660} m="auto">
        <WizardPanel.Heading title="Set up the source's credentials" divider={false} />
        <ErrorBoundary>
          <Flex spacing={4} direction="column">
            <Card variant="dark" p={4}>
              <FastField
                as={FormikRadio}
                name="authorizationMethod"
                value={GithubAuthorizationMethodEnum.Oauth2}
                label="Use OAuth2 Authorization Flow"
                aria-describedby="oauth2-helper"
              />
              {isCredentialOAuth2 && (
                <Box mx={44}>
                  <OAuthPullerCredentialsFields shouldMaskSecret={clientSecretExists} />
                </Box>
              )}
            </Card>
            <Card variant="dark" p={4}>
              <FastField
                as={FormikRadio}
                name="authorizationMethod"
                value={GithubAuthorizationMethodEnum.Pat}
                label="Use Personal Access Token"
              />
              {isCredentialPAT && (
                <Box my={4} mx={44}>
                  <Field
                    label="Personal Access Token"
                    name="personalAccessToken"
                    shouldMask={personalAccessTokenExists}
                    as={SensitiveTextInput}
                    placeholder="Your Github user's token"
                    type="password"
                    required
                    aria-describedby="credentials-helper"
                  />
                  <FormHelperText
                    id="credentials-helper"
                    color="white-100"
                    textAlign="center"
                    mt={6}
                  >
                    Need help creating a personal access token? Check
                    <Link external href={SAAS_ONBOARDING_GITHUB_DOC_URL} ml={1}>
                      our documentation
                    </Link>
                  </FormHelperText>
                </Box>
              )}
            </Card>
          </Flex>
        </ErrorBoundary>
      </Box>
      {editMode ? (
        <EditIntegrationActions />
      ) : (
        <WizardPanel.Actions>
          <WizardPanel.ActionNext disabled={!isValid}>Continue Setup</WizardPanel.ActionNext>
        </WizardPanel.Actions>
      )}
    </WizardPanel>
  );
};

export default CredentialsPanel;
