/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Box, Button, Flex, Text, Card } from 'pouncejs';
import { WizardPanel } from 'Components/Wizard';

interface LookupUploaderErrorProps {
  errorMsg: string;
  onRestartUploading: () => void;
}

const LookupUploaderError: React.FC<LookupUploaderErrorProps> = ({
  errorMsg,
  onRestartUploading,
}) => {
  return (
    <Card variant="dark" p={4} py={6}>
      <WizardPanel.Heading
        title="Something went wrong!"
        subtitle="Have a look at the error below and try again. If the problem continues please contact us"
      />
      <Flex justify="center">
        <Box backgroundColor="pink-700" textAlign="center" p={6} minWidth={500} maxWidth={580}>
          <Text size="medium" fontWeight="bold">
            Could not upload your lookup data
          </Text>

          {errorMsg && (
            <Text fontSize="small" mt={1} fontStyle="italic" wordBreak="break-word">
              {errorMsg}
            </Text>
          )}
        </Box>
      </Flex>
      <Flex justify="center" mt={8}>
        <Button onClick={onRestartUploading}>Try Again</Button>
      </Flex>
    </Card>
  );
};

export default LookupUploaderError;
