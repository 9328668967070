/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import FailureStatus from 'Assets/statuses/failure.svg';
import { WizardPanel } from 'Components/Wizard';
import { Flex, Img } from 'pouncejs';

interface FailingPanelProps {
  errorMessage?: string;
}

const FailingPanel: React.FC<FailingPanelProps> = ({ errorMessage }) => {
  return (
    <WizardPanel>
      <Flex align="center" direction="column" mx="auto" width={675}>
        <WizardPanel.Heading
          title="Something didn't go as planned"
          subtitle="Please try again. If the problem continues you can contact us"
          divider={null}
          subtitleProps={{ color: 'gray-300', fontWeight: 'normal' }}
        />
        <WizardPanel.ActionPrev />
        {errorMessage ? (
          <Flex backgroundColor="pink-700" fontSize="small" padding={4} align="center">
            {errorMessage}
          </Flex>
        ) : (
          <Img
            nativeWidth={120}
            nativeHeight={120}
            alt="Failed to verify source health"
            src={FailureStatus}
          />
        )}
        <WizardPanel.Actions>
          <WizardPanel.ActionStart />
        </WizardPanel.Actions>
      </Flex>
    </WizardPanel>
  );
};

export default FailingPanel;
