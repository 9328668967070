/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { AbstractButton, Box, Card, Flex, Grid, Heading, Text } from 'pouncejs';
import withRoleRestrictedAccess from 'Hoc/withRoleRestrictedAccess';
import { Permission } from 'Generated/schema';
import Page403 from 'Pages/403';
import { compose } from 'Helpers/compose';
import withSEO from 'Hoc/withSEO';
import useUrlParams from 'Hooks/useUrlParams';
import ListAvailableLogProviders from 'Pages/Integrations/LogSources/LogSourceOnboarding/ListAvailableLogProviders';
import AvailableLogProviderDetails from './AvailableLogProviderDetails';
import ListCustomOnboarding from './ListCustomOnboarding';
import RequestLogSource from './RequestLogSource/RequestLogSource';

interface OnboardingMethod {
  title: string;
  description: string;
  method: OnboardingMethods;
}

type OnboardingMethods = 'logSource' | 'transport';

export const onboardingMethods: OnboardingMethod[] = [
  {
    title: 'Services List',
    description: 'Choose log sources that Panther has built-in support for.',
    method: 'logSource',
  },
  {
    title: 'Custom Onboarding',
    description: `Onboard logs that Panther doesn't have built-in support for, using a Data Transport`,
    method: 'transport',
  },
];

const onboardingList: Record<OnboardingMethods, React.ElementType> = {
  logSource: ListAvailableLogProviders,
  transport: ListCustomOnboarding,
};

const LogSourceOnboarding: React.FC = () => {
  const { urlParams } = useUrlParams<{ logProviderId?: string; displayName?: string }>();
  const [selectedMethod, setSelectedMethod] = React.useState<OnboardingMethods>('logSource');

  if ('logProviderId' in urlParams) {
    const { logProviderId, displayName } = urlParams;
    return <AvailableLogProviderDetails id={logProviderId} displayName={displayName} />;
  }

  const OnboardingList = onboardingList[selectedMethod] || ListCustomOnboarding;

  return (
    <Card as="article" p={6} mb={20}>
      <Box as="header" pb={7}>
        <Heading color="white-200" size="small" mb={3}>
          Onboard your Log Source
        </Heading>
        <Text color="white-200" fontSize="medium">
          Choose from a list of log sources that Panther has built-in support for.
        </Text>
      </Box>

      <Grid width="100%" templateColumns="2fr 8fr">
        <Flex as="ul" direction="column" spacing={3}>
          {onboardingMethods.map(({ title, description, method }) => (
            <Box as="li" key={title}>
              <AbstractButton
                onClick={() => setSelectedMethod(method)}
                textAlign="left"
                borderLeft="3px solid"
                py={1}
                px={3}
                transform="translateX(-8px)"
                transition="border-color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms, color 0.1s linear"
                color="navyblue-100"
                borderColor={method === selectedMethod ? 'blue-400' : 'transparent'}
                _hover={{
                  color: 'white-200',
                }}
              >
                <Text color="white-100" fontSize="large" mb={1}>
                  {title}
                </Text>
                <Text color="gray-300" fontSize="small">
                  {description}
                </Text>
              </AbstractButton>
            </Box>
          ))}
        </Flex>
        <Card backgroundColor="navyblue-350" p={4}>
          <OnboardingList />
        </Card>
      </Grid>
      <RequestLogSource />
    </Card>
  );
};

export default compose(
  withSEO({ title: 'Onboard your Logs' }),
  withRoleRestrictedAccess({
    allowedPermissions: [Permission.LogSourceModify],
    fallback: <Page403 />,
  })
)(LogSourceOnboarding);
