/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Box, Flex, Text, Img } from 'pouncejs';
import WarningIcon from 'Assets/warning.svg';

interface WarningProps {
  title: string;
  description?: string;
}
const HealthCheckWarning: React.FC<WarningProps> = ({ title, description }) => {
  return (
    <Flex spacing={4} backgroundColor="navyblue-500" p={4}>
      <Img src={WarningIcon} alt="Warning" nativeWidth={24} nativeHeight={24} />
      <Box>
        <Text fontSize="medium" pb={2}>
          {title}
        </Text>
        <Text fontSize="small" color="gray-300">
          {description}
        </Text>
      </Box>
    </Flex>
  );
};

export default React.memo(HealthCheckWarning);
