/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import * as Types from '../../../../../__generated__/schema';

import { SavedQueryDetails } from '../../../../graphql/fragments/SavedQueryDetails.generated';
import { GraphQLError } from 'graphql';
import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';

export type UpdateSavedQueryVariables = {
  input: Types.UpdateSavedQueryInput;
};

export type UpdateSavedQuery = { updateSavedQuery: SavedQueryDetails };

export const UpdateSavedQueryDocument = gql`
  mutation UpdateSavedQuery($input: UpdateSavedQueryInput!) {
    updateSavedQuery(input: $input) {
      ...SavedQueryDetails
    }
  }
  ${SavedQueryDetails}
`;
export type UpdateSavedQueryMutationFn = ApolloReactCommon.MutationFunction<
  UpdateSavedQuery,
  UpdateSavedQueryVariables
>;

/**
 * __useUpdateSavedQuery__
 *
 * To run a mutation, you first call `useUpdateSavedQuery` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSavedQuery` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSavedQuery, { data, loading, error }] = useUpdateSavedQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSavedQuery(
  baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateSavedQuery, UpdateSavedQueryVariables>
) {
  return ApolloReactHooks.useMutation<UpdateSavedQuery, UpdateSavedQueryVariables>(
    UpdateSavedQueryDocument,
    baseOptions
  );
}
export type UpdateSavedQueryHookResult = ReturnType<typeof useUpdateSavedQuery>;
export type UpdateSavedQueryMutationResult = ApolloReactCommon.MutationResult<UpdateSavedQuery>;
export type UpdateSavedQueryMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateSavedQuery,
  UpdateSavedQueryVariables
>;
export function mockUpdateSavedQuery({
  data,
  variables,
  errors,
}: {
  data: UpdateSavedQuery;
  variables?: UpdateSavedQueryVariables;
  errors?: GraphQLError[];
}) {
  return {
    request: { query: UpdateSavedQueryDocument, variables },
    result: { data, errors },
  };
}
