/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { DataLakeQuery } from 'Generated/schema';
import { formatDatetime } from 'Helpers/utils';
import { Grid, Box, Badge, Flex } from 'pouncejs';

interface QueryIndicatorsProps {
  indicators: DataLakeQuery['metadata'];
}

interface IndicatorsProps {
  indicators: string[];
  indicatorName: string;
  logType: string;
  startTime: string;
  endTime: string;
}

const MAX_CONTAINER_HEIGHT = 150;

const QueryIndicators: React.FC<QueryIndicatorsProps> = ({ indicators }) => {
  if (!indicators) {
    return null;
  }

  const data = indicators as IndicatorsProps;
  return (
    <Box backgroundColor="navyblue-600" mt={4} p={5}>
      <Grid templateColumns="3fr 7fr" gap={4} spacing={4} maxHeight={MAX_CONTAINER_HEIGHT}>
        {data?.indicators && (
          <>
            <Box fontSize="small-medium" color="gray-300">
              IPs, Domains, Hashes
            </Box>
            <Flex spacing={2} flexWrap="wrap">
              {data.indicators.map(indicator => (
                <Box mb={2} key={indicator}>
                  <Badge color="navyblue-350">{indicator}</Badge>
                </Box>
              ))}
            </Flex>
          </>
        )}
        {data?.indicatorName && (
          <>
            <Box fontSize="small-medium" color="gray-300">
              Field
            </Box>
            <Box fontSize="small-medium">{data.indicatorName}</Box>
          </>
        )}
        {data?.logType && (
          <>
            <Box fontSize="small-medium" color="gray-300">
              Log Type
            </Box>
            <Box fontSize="small-medium">{data.logType}</Box>
          </>
        )}
        {data?.startTime && data?.endTime && (
          <>
            <Box fontSize="small-medium" color="gray-300">
              Date & Time Range
            </Box>
            <Box fontSize="small-medium">
              {formatDatetime(data.startTime)} - {formatDatetime(data.endTime)}
            </Box>
          </>
        )}
      </Grid>
    </Box>
  );
};
export default React.memo(QueryIndicators);
