/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Field, Form, Formik } from 'formik';
import pick from 'lodash/pick';
import {
  Box,
  Button,
  Card,
  Flex,
  Popover,
  PopoverContent,
  PopoverTrigger,
  useSnackbar,
} from 'pouncejs';
import { DataLakeQueryStatus, DataLakeQueriesInput, Permission } from 'Generated/schema';
import { getActorDisplayName, capitalize, extractErrorMessage } from 'Helpers/utils';
import useRequestParamsWithoutPagination from 'Hooks/useRequestParamsWithoutPagination';
import useCheckPermissions from 'Hooks/useCheckPermissions';
import FormikMultiCombobox from 'Components/fields/MultiComboBox';
import TextButton from 'Components/buttons/TextButton';
import { QueryKindEnum, stringifyQueryKind } from 'Components/cards/QueryHistoryCard';
import PopoverAutoSubmit from 'Components/PopoverAutoSubmit';
import { useListActors, ListActors } from './graphql/listActors.generated';

type ActorsDropdownValue = ListActors['users'] & ListActors['listAPITokens']['tokens'];
export type QueryHistoryDropdownFiltersValues = Pick<DataLakeQueriesInput, 'status' | 'kind'> & {
  issuedBy: ActorsDropdownValue;
};

const filterKeys: (keyof Partial<DataLakeQueriesInput>)[] = ['issuedBy', 'status', 'kind'];

const queryKindOptions = Object.values(QueryKindEnum).sort((a, b) => a.localeCompare(b));
const queryStatusOptions = Object.values(DataLakeQueryStatus).sort((a, b) => a.localeCompare(b));

const defaultValues: QueryHistoryDropdownFiltersValues = {
  issuedBy: [],
  status: [],
  kind: [],
};

const DropdownFilters: React.FC = () => {
  const { pushSnackbar } = useSnackbar();
  const hasAllActorPermissions = useCheckPermissions(
    [Permission.OrganizationApiTokenRead, Permission.UserRead],
    'AND'
  );

  const { data } = useListActors({
    skip: !hasAllActorPermissions,
    onError: error => {
      pushSnackbar({
        variant: 'error',
        title: 'Failed to retrieve user-related filter options',
        description: extractErrorMessage(error),
      });
    },
  });

  const actors = React.useMemo(() => {
    if (!data) {
      return [];
    }

    return [...data.users, ...data.listAPITokens.tokens];
  }, [data]);

  const actorToString = React.useCallback(
    (actor: typeof actors[0]) => getActorDisplayName(actor),
    []
  );

  const { requestParams, updateRequestParams } = useRequestParamsWithoutPagination<
    DataLakeQueriesInput
  >();

  const initialFilterValues = React.useMemo(() => {
    const userPopulatedRequestParams = {
      ...requestParams,
      issuedBy:
        requestParams.issuedBy?.map(userId => actors.find(actor => actor.id === userId)) || [],
    };
    return {
      ...defaultValues,
      ...pick(userPopulatedRequestParams, filterKeys),
    } as QueryHistoryDropdownFiltersValues;
  }, [requestParams, actors]);

  const handleSubmit = React.useCallback(
    values =>
      updateRequestParams({
        ...values,
        issuedBy: values.issuedBy.map(actor => actor.id),
      } as Partial<DataLakeQueriesInput>),
    [updateRequestParams]
  );

  const filtersCount = Object.keys(defaultValues).filter(key => key in requestParams).length;
  return (
    <Popover>
      {({ close: closePopover, isOpen }) => (
        <React.Fragment>
          <PopoverTrigger
            as={Button}
            iconAlignment="right"
            icon="filter-light"
            aria-label="Additional Filters"
          >
            Filters {filtersCount ? `(${filtersCount})` : ''}
          </PopoverTrigger>
          <PopoverContent alignment="bottom-left">
            <Card
              shadow="dark300"
              my={14}
              p={6}
              pb={4}
              width={425}
              data-testid="dropdown-query-history-filters"
            >
              <Formik<QueryHistoryDropdownFiltersValues>
                enableReinitialize
                onSubmit={handleSubmit}
                initialValues={initialFilterValues}
              >
                {({ setValues, values }) => (
                  <Form>
                    <PopoverAutoSubmit<QueryHistoryDropdownFiltersValues>
                      values={values}
                      onSubmit={handleSubmit}
                      isOpen={isOpen}
                    />
                    <Flex direction="column" spacing={4}>
                      {hasAllActorPermissions && (
                        <Field
                          as={FormikMultiCombobox}
                          label="Created by Name(s)"
                          name="issuedBy"
                          items={actors}
                          itemToString={actorToString}
                        />
                      )}
                      <Field
                        as={FormikMultiCombobox}
                        label="Type"
                        name="kind"
                        items={queryKindOptions}
                        itemToString={stringifyQueryKind}
                      />
                      <Field
                        as={FormikMultiCombobox}
                        label="Status"
                        name="status"
                        items={queryStatusOptions}
                        itemToString={capitalize}
                      />
                    </Flex>
                    <Flex direction="column" justify="center" align="center" mt={8} spacing={4}>
                      <Box>
                        <Button onClick={closePopover}>Apply Filters</Button>
                      </Box>
                      <TextButton role="button" onClick={() => setValues(defaultValues)}>
                        Clear Filters
                      </TextButton>
                    </Flex>
                  </Form>
                )}
              </Formik>
            </Card>
          </PopoverContent>
        </React.Fragment>
      )}
    </Popover>
  );
};

export default React.memo(DropdownFilters);
