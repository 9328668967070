/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Box, Flex, Card, Heading, Switch, Text } from 'pouncejs';
import UpdateVersion, { UpdateVersionFormValues } from 'Components/cards/PackCard/UpdateVersion';
import BulletedLoading from 'Components/BulletedLoading';
import { AnalysisPackDetails } from 'Source/graphql/fragments/AnalysisPackDetails.generated';
import { UpdateAnalysisPackInput } from 'Generated/schema';

interface AnalysisPackDetailsBannerProps {
  pack?: AnalysisPackDetails;
  onUpdate: (values: UpdateAnalysisPackInput) => void;
  isUpdating: boolean;
}

const AnalysisPackDetailsBanner: React.FC<AnalysisPackDetailsBannerProps> = ({
  pack,
  onUpdate,
  isUpdating,
}) => {
  const onPatch = React.useCallback(
    (values: UpdateVersionFormValues) => {
      return onUpdate({
        id: pack.id,
        versionId: values.packVersion.id,
      });
    },
    // FIXME: look into hook dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [pack]
  );

  const onStatusUpdate = React.useCallback(() => {
    return onUpdate({
      id: pack.id,
      enabled: !pack.enabled,
    });
    // FIXME: look into hook dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pack]);

  return (
    <Card as="article" position="relative">
      {isUpdating && (
        <Flex
          position="absolute"
          direction="column"
          spacing={2}
          backgroundColor="navyblue-700"
          height="100%"
          zIndex={2}
          align="center"
          opacity={0.9}
          justify="center"
          width={1}
        >
          <Text textAlign="center" opacity={1} fontWeight="bold">
            {pack.displayName || pack.id}
          </Text>
          <Text textAlign="center" opacity={1}>
            is being updated, please wait.
          </Text>
          <BulletedLoading />
        </Flex>
      )}
      <Flex p={6}>
        <Box>
          <Flex as="header" align="center">
            <Heading
              fontWeight="bold"
              wordBreak="break-word"
              aria-describedby="rule-description"
              flexShrink={1}
              display="flex"
              alignItems="center"
              mr={4}
            >
              {pack.displayName || pack.id}
            </Heading>
            {pack.updateAvailable && (
              <Box
                as="span"
                backgroundColor={pack.enabled ? 'red-500' : 'gray-500'}
                borderRadius="small"
                px={2}
                py={1}
                fontSize="small"
                fontWeight="bold"
              >
                UPDATE AVAILABLE
              </Box>
            )}
          </Flex>
          <Flex as="dl" fontSize="medium" pt={2} spacing={8} wrap="wrap">
            {pack.description}
          </Flex>
        </Box>
        <Flex align="center" spacing={8} flexShrink={0} ml="auto">
          <Flex ml="auto" mr={0} align="flex-end" data-testid="pack-enabled-switch">
            <Switch
              onChange={onStatusUpdate}
              label="Enabled"
              checked={pack.enabled}
              data-tid="pack-enabled-switch"
            />
          </Flex>
          <Box width="250px">
            <UpdateVersion pack={pack} onPatch={onPatch} />
          </Box>
        </Flex>
      </Flex>
    </Card>
  );
};

export default React.memo(AnalysisPackDetailsBanner);
