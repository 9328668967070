/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import * as Types from '../../../../__generated__/schema';

import { GraphQLError } from 'graphql';
import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';

export type UpdatePolicyVariables = {
  input: Types.UpdatePolicyInput;
};

export type UpdatePolicy = {
  updatePolicy?: Types.Maybe<
    Pick<
      Types.Policy,
      | 'body'
      | 'description'
      | 'displayName'
      | 'enabled'
      | 'id'
      | 'outputIds'
      | 'reference'
      | 'resourceTypes'
      | 'runbook'
      | 'severity'
      | 'suppressions'
      | 'tags'
    > & {
      reports: Array<Pick<Types.DetectionReportMapping, 'key' | 'values'>>;
      tests: Array<Pick<Types.DetectionTestDefinition, 'expectedResult' | 'name' | 'resource'>>;
    }
  >;
};

export const UpdatePolicyDocument = gql`
  mutation UpdatePolicy($input: UpdatePolicyInput!) {
    updatePolicy(input: $input) {
      body
      description
      displayName
      enabled
      id
      outputIds
      reference
      resourceTypes
      runbook
      severity
      suppressions
      tags
      reports {
        key
        values
      }
      tests {
        expectedResult
        name
        resource
      }
    }
  }
`;
export type UpdatePolicyMutationFn = ApolloReactCommon.MutationFunction<
  UpdatePolicy,
  UpdatePolicyVariables
>;

/**
 * __useUpdatePolicy__
 *
 * To run a mutation, you first call `useUpdatePolicy` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePolicy` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePolicy, { data, loading, error }] = useUpdatePolicy({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePolicy(
  baseOptions?: ApolloReactHooks.MutationHookOptions<UpdatePolicy, UpdatePolicyVariables>
) {
  return ApolloReactHooks.useMutation<UpdatePolicy, UpdatePolicyVariables>(
    UpdatePolicyDocument,
    baseOptions
  );
}
export type UpdatePolicyHookResult = ReturnType<typeof useUpdatePolicy>;
export type UpdatePolicyMutationResult = ApolloReactCommon.MutationResult<UpdatePolicy>;
export type UpdatePolicyMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdatePolicy,
  UpdatePolicyVariables
>;
export function mockUpdatePolicy({
  data,
  variables,
  errors,
}: {
  data: UpdatePolicy;
  variables?: UpdatePolicyVariables;
  errors?: GraphQLError[];
}) {
  return {
    request: { query: UpdatePolicyDocument, variables },
    result: { data, errors },
  };
}
