/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Button, Flex, Text } from 'pouncejs';

const NetworkErrorModal: React.FC = () => {
  return (
    <React.Fragment>
      <Text mb={10} textAlign="center">
        Somebody is watching cat videos and is preventing you from being online
        <br />
        That{"'"}s the most likely scenario anyway...
      </Text>
      <Flex justify="center">
        <Button variantColor="pink-700" loading disabled>
          Reconnecting
        </Button>
      </Flex>
    </React.Fragment>
  );
};

export default NetworkErrorModal;
