/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Box, Flex, IconButton } from 'pouncejs';

interface TableControlsPagination {
  page: number;
  onPageChange: (page: number) => void;
  totalPages: number;
  /**
   * Specifies whether the next arrow should be
   * disabled or not. Defaults to `page >= totalPages`.
   */
  disableNextPageNavigation?: boolean;
}

const TableControlsPagination: React.FC<TableControlsPagination> = ({
  page,
  onPageChange,
  totalPages,
  disableNextPageNavigation = page >= totalPages,
}) => {
  return (
    <Flex align="center" justify="center">
      <Flex align="center">
        <IconButton
          variantColor="navyblue-300"
          aria-label="Go to previous page"
          variant="ghost"
          variantBorderStyle="circle"
          icon="chevron-left"
          disabled={page <= 1}
          onClick={() => onPageChange(page - 1)}
        />
        <Box mx={2} fontSize="medium">
          {totalPages ? `${page} of ${totalPages}` : page}
        </Box>
        <IconButton
          variantColor="navyblue-300"
          aria-label="Go to next page"
          variant="ghost"
          variantBorderStyle="circle"
          icon="chevron-right"
          disabled={disableNextPageNavigation}
          onClick={() => onPageChange(page + 1)}
        />
      </Flex>
    </Flex>
  );
};

export default React.memo(TableControlsPagination);
