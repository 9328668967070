/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { BadgeProps, Box, Tooltip } from 'pouncejs';
import { ComplianceStatusEnum } from 'Generated/schema';
import FixedWidthBadge from 'Components/FixedWidthBadge';

export const STATUS_COLOR_MAP: { [key in StatusBadgeProps['status']]: BadgeProps['color'] } = {
  [ComplianceStatusEnum.Pass]: 'green-500' as const,
  [ComplianceStatusEnum.Fail]: 'red-200' as const,
  [ComplianceStatusEnum.Error]: 'orange-400' as const,
  ENABLED: 'cyan-400' as const,
};

interface StatusBadgeProps {
  status: ComplianceStatusEnum | 'ENABLED';
  disabled?: boolean;
  errorMessage?: string;
  disabledLabel?: string;
  testId?: string;
}

const StatusBadge: React.FC<StatusBadgeProps> = ({
  status,
  disabled,
  errorMessage = "An exception has been raised during a scheduled run. You'll find more information in the related details page",
  disabledLabel = 'DISABLED',
  testId,
}) => {
  if (disabled) {
    return (
      <Box opacity={0.5}>
        <FixedWidthBadge color="navyblue-300" data-testid={testId}>
          {disabledLabel}
        </FixedWidthBadge>
      </Box>
    );
  }

  if (status === ComplianceStatusEnum.Error) {
    return (
      <Tooltip content={errorMessage}>
        <Box>
          <FixedWidthBadge color={STATUS_COLOR_MAP[status]}>{status}</FixedWidthBadge>
        </Box>
      </Tooltip>
    );
  }
  return (
    <FixedWidthBadge color={STATUS_COLOR_MAP[status]} data-testid={testId}>
      {status}
    </FixedWidthBadge>
  );
};

export default StatusBadge;
