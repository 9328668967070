/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Box, Button, Flex, Text, Combobox, useSnackbar } from 'pouncejs';
import { Detection, Permission } from 'Generated/schema';
import { useSelect } from 'Components/utils/SelectContext';
import useDeleteDetectionModal from 'Hooks/useDeleteDetectionModal';
import useBulkDownload from 'Hooks/useBulkDownload';
import useCheckPermissions from 'Hooks/useCheckPermissions';
import { useBulkUpdateDetections } from '../graphql/bulkUpdateDetections.generated';

enum MassActionsEnum {
  'DOWNLOAD' = 'Download',
  'DELETE' = 'Delete',
  'ENABLE' = 'Enable',
  'DISABLE' = 'Disable',
}

const massActionValues = Object.values(MassActionsEnum);

const ListSavedQueriesSelection: React.FC = () => {
  const { pushSnackbar } = useSnackbar();

  const canModifyRulesAndPolicies = useCheckPermissions(
    [Permission.RuleModify, Permission.PolicyModify],
    'AND'
  );

  const disableActions = React.useCallback(
    actionName => {
      if (
        actionName === MassActionsEnum.DELETE ||
        actionName === MassActionsEnum.ENABLE ||
        actionName === MassActionsEnum.DISABLE
      ) {
        return !canModifyRulesAndPolicies;
      }

      return false;
    },
    [canModifyRulesAndPolicies]
  );

  const { selection, resetSelection } = useSelect<Detection>();
  const [selectedMassAction, setSelectedMassAction] = React.useState<MassActionsEnum>(
    MassActionsEnum.DOWNLOAD
  );

  const showDeleteDetectionsModal = useDeleteDetectionModal();

  const { download } = useBulkDownload({
    fileName: 'detections',
  });

  const [bulkUpdateDetections] = useBulkUpdateDetections({
    onError: () => pushSnackbar({ variant: 'error', title: 'Failed to update detections' }),
    onCompleted: ({ bulkUpdateDetections: result }) => {
      if (!result) {
        pushSnackbar({
          variant: 'error',
          title: 'Failed to update detections.',
        });
        return;
      }

      const failCount = result.errors.length;
      const totalCount = result.detections.length + failCount;
      if (failCount !== 0) {
        pushSnackbar({
          variant: 'error',
          title: `Failed to update ${failCount} of ${totalCount} detections.`,
        });
        return;
      }

      pushSnackbar({
        variant: 'success',
        title: `Updated ${totalCount} detections`,
      });

      resetSelection();
    },
  });

  const bulkUpdateDetectionsEnabled = React.useCallback(
    (detections: Detection[], enabled: boolean) =>
      bulkUpdateDetections({
        variables: {
          input: {
            detections: detections.map(s => ({ enabled, id: s.id })),
          },
        },
      }),
    [bulkUpdateDetections]
  );

  const handleActionApplication = React.useCallback(() => {
    if (selectedMassAction === MassActionsEnum.DELETE) {
      showDeleteDetectionsModal(selection, resetSelection);
    }
    if (selectedMassAction === MassActionsEnum.DOWNLOAD) {
      download({ detectionIds: selection.map(s => s.id) });
    }
    if (selectedMassAction === MassActionsEnum.ENABLE) {
      bulkUpdateDetectionsEnabled(selection, true);
    }
    if (selectedMassAction === MassActionsEnum.DISABLE) {
      bulkUpdateDetectionsEnabled(selection, false);
    }
  }, [
    bulkUpdateDetectionsEnabled,
    showDeleteDetectionsModal,
    selectedMassAction,
    selection,
    resetSelection,
    download,
  ]);

  return (
    <Flex justify="flex-end" align="center" spacing={4}>
      <Text>{selection.length} Selected</Text>
      <Box width={150}>
        <Combobox
          data-tid="mass-detection-action-combobox"
          disableItem={disableActions}
          onChange={setSelectedMassAction}
          items={massActionValues}
          value={selectedMassAction}
          label="Mass Action"
          showClearSelectionControl={false}
        />
      </Box>
      <Button
        data-tid="mass-detection-action-apply"
        variantColor="violet-400"
        onClick={handleActionApplication}
      >
        Apply
      </Button>
    </Flex>
  );
};

export default React.memo(ListSavedQueriesSelection);
