/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { useSnackbar } from 'pouncejs';
import { DestinationDetails } from 'Source/graphql/fragments/DestinationDetails.generated';
import { useDeleteOutput } from './graphql/deleteOutput.generated';
import ConfirmModal from '../ConfirmModal';

export interface DeleteDestinationModalProps {
  destination: DestinationDetails;
}

const DeleteDestinationModal: React.FC<DeleteDestinationModalProps> = ({
  destination,
  ...rest
}) => {
  const destinationDisplayName = destination.displayName || destination.outputId;
  const { pushSnackbar } = useSnackbar();
  const [deleteDestination] = useDeleteOutput({
    variables: {
      id: destination.outputId,
    },
    update: async cache => {
      cache.evict({ id: cache.identify(destination) });
      cache.gc();
    },
    onCompleted: () => {
      pushSnackbar({
        variant: 'success',
        title: `Successfully deleted destination: ${destinationDisplayName}`,
      });
    },
    onError: () => {
      pushSnackbar({
        variant: 'error',
        title: `Failed to delete destination: ${destinationDisplayName}`,
      });
    },
  });

  return (
    <ConfirmModal
      variant="destructive"
      subtitle={`Are you sure you want to delete ${destinationDisplayName}?`}
      onConfirm={deleteDestination}
      {...rest}
    />
  );
};

export default DeleteDestinationModal;
