/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import withSEO from 'Hoc/withSEO';
import { compose } from 'Helpers/compose';
import withRoleRestrictedAccess from 'Hoc/withRoleRestrictedAccess';
import { Permission } from 'Generated/schema';
import Page403 from 'Pages/403';
import ComplianceSourceWizard from '../ComplianceSourceWizard';
import { useAddComplianceSource } from './graphql/addComplianceSource.generated';
import { ComplianceSourceWizardValues } from '../ComplianceSourceWizard/ComplianceSourceWizard';

const initialValues = {
  awsAccountId: '',
  iamRoleExists: false,
  integrationLabel: '',
  regionIgnoreList: [],
  resourceTypeIgnoreList: [],
  resourceRegexIgnoreList: [],
  auditRole: '',
};

const CreateComplianceSource = () => {
  const [addComplianceSource] = useAddComplianceSource({
    update: (cache, { data: { addComplianceIntegration } }) => {
      cache.modify({
        fields: {
          listComplianceIntegrations: (queryData, { toReference }) => {
            const addedIntegrationCacheRef = toReference(addComplianceIntegration);
            return queryData
              ? [addedIntegrationCacheRef, ...queryData]
              : [addedIntegrationCacheRef];
          },
        },
      });
    },
  });

  const handleSubmit = React.useCallback(
    async (values: ComplianceSourceWizardValues) => {
      try {
        const { data } = await addComplianceSource({
          variables: {
            input: {
              integrationLabel: values.integrationLabel,
              awsAccountId: values.awsAccountId,
              regionIgnoreList: values.regionIgnoreList,
              resourceTypeIgnoreList: values.resourceTypeIgnoreList,
              resourceRegexIgnoreList: values.resourceRegexIgnoreList,
              awsScanConfig: { auditRole: values.auditRole },
            },
          },
        });

        return data.addComplianceIntegration;
      } catch (err) {
        return err as Error;
      }
    },
    [addComplianceSource]
  );

  return <ComplianceSourceWizard initialValues={initialValues} onSubmit={handleSubmit} />;
};

export default compose(
  withSEO({ title: 'New Cloud Account' }),
  withRoleRestrictedAccess({
    allowedPermissions: [Permission.CloudsecSourceModify],
    fallback: <Page403 />,
  })
)(CreateComplianceSource);
