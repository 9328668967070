/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Flex, Tab, Box, BoxProps } from 'pouncejs';

/**
 * These props are automatically passed by `TabList` and not by the developer. At the export level
 * of this component, we "hide" them from the developer by exporting this component `as React.FC`
 */
interface OverviewTabProps {
  /** Whether the tab is selected */
  isSelected: boolean;

  /** Whether the tab is focused */
  isFocused: boolean;
}

const OverviewTab: React.FC<OverviewTabProps> = ({ isSelected, isFocused, children, ...rest }) => {
  let backgroundColor: BoxProps['backgroundColor'];
  if (isSelected) {
    backgroundColor = 'blue-400';
  } else if (isFocused) {
    backgroundColor = 'navyblue-300';
  } else {
    backgroundColor = 'navyblue-400';
  }

  return (
    <Tab {...rest}>
      <Box
        mr={4}
        px={4}
        py={3}
        borderRadius="pill"
        transition="background-color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms"
        backgroundColor={backgroundColor}
        _hover={{
          backgroundColor: !isSelected ? 'navyblue-300' : undefined,
        }}
      >
        <Flex align="center" spacing={3}>
          {children}
        </Flex>
      </Box>
    </Tab>
  );
};

export default React.memo(OverviewTab) as React.FC;
