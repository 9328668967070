/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import * as Types from '../../../../../../../../__generated__/schema';

import { HoldingTankTestJobDetails } from '../../../../../../../graphql/fragments/HoldingTankTestJobDetails.generated';
import { GraphQLError } from 'graphql';
import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';

export type ListTestTasksVariables = {
  sourceId: Types.Scalars['String'];
};

export type ListTestTasks = { listTestTasks: { tasks: Array<HoldingTankTestJobDetails> } };

export const ListTestTasksDocument = gql`
  query ListTestTasks($sourceId: String!) {
    listTestTasks(sourceId: $sourceId) {
      tasks {
        ... on HoldingTankTestJob {
          ...HoldingTankTestJobDetails
        }
      }
    }
  }
  ${HoldingTankTestJobDetails}
`;

/**
 * __useListTestTasks__
 *
 * To run a query within a React component, call `useListTestTasks` and pass it any options that fit your needs.
 * When your component renders, `useListTestTasks` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListTestTasks({
 *   variables: {
 *      sourceId: // value for 'sourceId'
 *   },
 * });
 */
export function useListTestTasks(
  baseOptions?: ApolloReactHooks.QueryHookOptions<ListTestTasks, ListTestTasksVariables>
) {
  return ApolloReactHooks.useQuery<ListTestTasks, ListTestTasksVariables>(
    ListTestTasksDocument,
    baseOptions
  );
}
export function useListTestTasksLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ListTestTasks, ListTestTasksVariables>
) {
  return ApolloReactHooks.useLazyQuery<ListTestTasks, ListTestTasksVariables>(
    ListTestTasksDocument,
    baseOptions
  );
}
export type ListTestTasksHookResult = ReturnType<typeof useListTestTasks>;
export type ListTestTasksLazyQueryHookResult = ReturnType<typeof useListTestTasksLazyQuery>;
export type ListTestTasksQueryResult = ApolloReactCommon.QueryResult<
  ListTestTasks,
  ListTestTasksVariables
>;
export function mockListTestTasks({
  data,
  variables,
  errors,
}: {
  data: ListTestTasks;
  variables?: ListTestTasksVariables;
  errors?: GraphQLError[];
}) {
  return {
    request: { query: ListTestTasksDocument, variables },
    result: { data, errors },
  };
}
