/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

/* The component responsible for rendering the actual sidesheets */
import React from 'react';
import useSidesheet from 'Hooks/useSidesheet';
import { SIDESHEETS } from 'Components/utils/Sidesheet';
import EditUserSidesheet from 'Components/sidesheets/EditUserSidesheet';
import UserInvitationSidesheet from 'Components/sidesheets/UserInvitationSidesheet';

const SidesheetManager: React.FC = () => {
  const { state: sidesheetState, hideSidesheet } = useSidesheet();

  let Component;
  switch (sidesheetState.sidesheet) {
    case SIDESHEETS.EDIT_USER:
      Component = EditUserSidesheet;
      break;
    case SIDESHEETS.USER_INVITATION:
      Component = UserInvitationSidesheet;
      break;
    default:
      Component = null;
  }

  if (!Component) {
    return null;
  }

  return (
    <Component {...sidesheetState.props} open={sidesheetState.isVisible} onClose={hideSidesheet} />
  );
};

export default SidesheetManager;
