/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import * as Types from '../../../../../__generated__/schema';

import { LookupDetails } from '../../../../graphql/fragments/LookupDetails.generated';
import { GraphQLError } from 'graphql';
import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';

export type GetLookupVariables = {
  id: Types.Scalars['ID'];
};

export type GetLookup = { getLookup: LookupDetails };

export const GetLookupDocument = gql`
  query GetLookup($id: ID!) {
    getLookup(id: $id) {
      ...LookupDetails
    }
  }
  ${LookupDetails}
`;

/**
 * __useGetLookup__
 *
 * To run a query within a React component, call `useGetLookup` and pass it any options that fit your needs.
 * When your component renders, `useGetLookup` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLookup({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetLookup(
  baseOptions?: ApolloReactHooks.QueryHookOptions<GetLookup, GetLookupVariables>
) {
  return ApolloReactHooks.useQuery<GetLookup, GetLookupVariables>(GetLookupDocument, baseOptions);
}
export function useGetLookupLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetLookup, GetLookupVariables>
) {
  return ApolloReactHooks.useLazyQuery<GetLookup, GetLookupVariables>(
    GetLookupDocument,
    baseOptions
  );
}
export type GetLookupHookResult = ReturnType<typeof useGetLookup>;
export type GetLookupLazyQueryHookResult = ReturnType<typeof useGetLookupLazyQuery>;
export type GetLookupQueryResult = ApolloReactCommon.QueryResult<GetLookup, GetLookupVariables>;
export function mockGetLookup({
  data,
  variables,
  errors,
}: {
  data: GetLookup;
  variables?: GetLookupVariables;
  errors?: GraphQLError[];
}) {
  return {
    request: { query: GetLookupDocument, variables },
    result: { data, errors },
  };
}
