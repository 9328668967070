/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Field } from 'formik';
import * as Yup from 'yup';
import FormikTextInput from 'Components/fields/TextInput';
import SensitiveTextInput from 'Components/fields/SensitiveTextInput';
import { DestinationConfigInput } from 'Generated/schema';
import BaseDestinationForm, {
  BaseDestinationFormValues,
  defaultValidationSchema,
} from 'Components/forms/BaseDestinationForm';
import { yupWebhookValidation } from 'Helpers/utils';
import { SimpleGrid } from 'pouncejs';

type CustomWebhookFieldValues = Pick<DestinationConfigInput, 'customWebhook'>;

interface CustomWebhookDestinationFormProps {
  initialValues: BaseDestinationFormValues<CustomWebhookFieldValues>;
  onSubmit: (values: BaseDestinationFormValues<CustomWebhookFieldValues>) => void;
}

const CustomWebhookDestinationForm: React.FC<CustomWebhookDestinationFormProps> = ({
  onSubmit,
  initialValues,
}) => {
  const existing = initialValues.outputId;

  const customWebhookFieldsValidationSchema = Yup.object().shape({
    outputConfig: Yup.object().shape({
      customWebhook: Yup.object().shape({
        webhookURL: existing ? yupWebhookValidation : yupWebhookValidation.required(),
      }),
    }),
  });

  const mergedValidationSchema = defaultValidationSchema.concat(
    customWebhookFieldsValidationSchema
  );

  return (
    <BaseDestinationForm<CustomWebhookFieldValues>
      initialValues={initialValues}
      validationSchema={mergedValidationSchema}
      onSubmit={onSubmit}
    >
      <SimpleGrid gap={5} columns={2}>
        <Field
          name="displayName"
          as={FormikTextInput}
          shouldMask={!!existing}
          label="* Display Name"
          placeholder="How should we name this?"
          required
        />
        <Field
          as={SensitiveTextInput}
          name="outputConfig.customWebhook.webhookURL"
          label="Custom Webhook URL"
          placeholder="Where should we send a push notification to?"
          required={!existing}
        />
      </SimpleGrid>
    </BaseDestinationForm>
  );
};

export default CustomWebhookDestinationForm;
