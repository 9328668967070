/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Flex } from 'pouncejs';

const NotificationJewel: React.FC<{
  badge: string | React.ReactNode;
  // TODO: `default` Variant will be removed once UI components are updated
  variant?: 'default' | 'outline';
}> = ({ badge, variant = 'default' }) => {
  return (
    <Flex
      justify="center"
      align="center"
      width={variant === 'default' ? 18 : 26}
      height={variant === 'default' ? 18 : 26}
      backgroundColor={variant === 'default' ? 'navyblue-200' : 'rgba(79, 98, 124, 0.3)'}
      borderRadius="circle"
      border={variant === 'default' ? 'none' : '1px solid'}
      borderColor="navyblue-200"
      fontSize="2x-small"
      fontWeight="medium"
      cursor="default"
    >
      {badge}
    </Flex>
  );
};

export default React.memo(NotificationJewel);
