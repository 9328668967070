/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Alert, Box, Card } from 'pouncejs';

import { useMitreContext } from 'Pages/Reports/MitreMatrix/MitreContext';
import MitreRelations from './MitreRelations';
import MitreMatrixSummaryStats from './MitreMatrixSummary/MitreMatrixSummaryStats';
import MitreMatrixGrid from './MitreMatrixGrid';
import Skeleton from './Skeleton/Skeleton';

const MitreMatrixPageContent = () => {
  const {
    isTreeLoading,
    activeMatrixLoading,
    activeMatrixError,
    activeMatrix,
    treeNodes,
  } = useMitreContext();

  if (!treeNodes && isTreeLoading) {
    return <Skeleton.PageContent />;
  }

  if (!activeMatrix && activeMatrixLoading) {
    return <Skeleton.PageContent />;
  }

  if (activeMatrixError && !isTreeLoading) {
    return <Alert title="Failed to load matrix" variant="error" />;
  }

  return (
    <>
      <Box pb={2}>
        <Card variant="dark" p={8} mb={2}>
          <MitreMatrixSummaryStats />
        </Card>
        <Card variant="dark" pt={8} mb={2}>
          <Box pb={8}>
            <MitreMatrixGrid />
          </Box>
          <MitreRelations />
        </Card>
      </Box>
    </>
  );
};

export default React.memo(MitreMatrixPageContent);
