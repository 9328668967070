/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import * as Types from '../../../../../__generated__/schema';

import { APITokenDetails } from '../../../../graphql/fragments/APITokenDetails.generated';
import { GraphQLError } from 'graphql';
import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';

export type ListAPITokensVariables = {};

export type ListAPITokens = {
  listAPITokens: {
    tokens: Array<APITokenDetails>;
    error?: Types.Maybe<Pick<Types.Error, 'message'>>;
  };
};

export const ListAPITokensDocument = gql`
  query ListAPITokens {
    listAPITokens {
      tokens {
        ...APITokenDetails
      }
      error {
        message
      }
    }
  }
  ${APITokenDetails}
`;

/**
 * __useListAPITokens__
 *
 * To run a query within a React component, call `useListAPITokens` and pass it any options that fit your needs.
 * When your component renders, `useListAPITokens` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListAPITokens({
 *   variables: {
 *   },
 * });
 */
export function useListAPITokens(
  baseOptions?: ApolloReactHooks.QueryHookOptions<ListAPITokens, ListAPITokensVariables>
) {
  return ApolloReactHooks.useQuery<ListAPITokens, ListAPITokensVariables>(
    ListAPITokensDocument,
    baseOptions
  );
}
export function useListAPITokensLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ListAPITokens, ListAPITokensVariables>
) {
  return ApolloReactHooks.useLazyQuery<ListAPITokens, ListAPITokensVariables>(
    ListAPITokensDocument,
    baseOptions
  );
}
export type ListAPITokensHookResult = ReturnType<typeof useListAPITokens>;
export type ListAPITokensLazyQueryHookResult = ReturnType<typeof useListAPITokensLazyQuery>;
export type ListAPITokensQueryResult = ApolloReactCommon.QueryResult<
  ListAPITokens,
  ListAPITokensVariables
>;
export function mockListAPITokens({
  data,
  variables,
  errors,
}: {
  data: ListAPITokens;
  variables?: ListAPITokensVariables;
  errors?: GraphQLError[];
}) {
  return {
    request: { query: ListAPITokensDocument, variables },
    result: { data, errors },
  };
}
