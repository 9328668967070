/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Box, Card, Flex, Grid, IconButton, Link, Text } from 'pouncejs';
import { Link as RRLink } from 'react-router-dom';

import urls from 'Source/urls';
import { Permission } from 'Generated/schema';
import { formatDatetime, getActorDisplayName } from 'Helpers/utils';
import { SelectCheckbox } from 'Components/utils/SelectContext';
import Editor from 'Components/Editor';
import RoleRestrictedAccess from 'Components/utils/RoleRestrictedAccess';
import SavedQueryCardOptions from 'Components/cards/SavedQueryCard/SavedQueryCardOptions';
import { SavedQueryDetails } from 'Source/graphql/fragments/SavedQueryDetails.generated';
import ScheduleBadge from 'Components/cards/SavedQueryCard/ScheduleBadge';
import FixedWidthBadge from 'Components/FixedWidthBadge';
import { CardValue, CardBulletDivider, CardMetaValue } from 'Components/cards/common';
import ManagedBadge from 'Components/badges/ManagedBadge';
import { CardVariant, CardVariantConfig } from '../common/cardVariant';

interface SavedQueryCardProps {
  savedQuery: SavedQueryDetails;
  selectionEnabled?: boolean;
  isSelected?: boolean;
  variant?: CardVariant;
}

const SavedQueryCard: React.FC<SavedQueryCardProps> = ({
  savedQuery,
  selectionEnabled = false,
  isSelected = false,
  variant = 'dark',
}) => {
  const [showSQL, setShowSQL] = React.useState(false);

  const variantConfig = CardVariantConfig[variant];

  return (
    <Card
      as="section"
      variant={isSelected ? variantConfig.selectedCardVariant : variantConfig.defaultCardVariant}
      p={4}
      data-testid="saved-query-card"
    >
      <Flex>
        {selectionEnabled && (
          <Box transform="translate3d(-12px,-12px,0)">
            <SelectCheckbox selectionItem={savedQuery.id} />
          </Box>
        )}
        <Flex direction="column" justify="space-between" width={1}>
          <Flex as="header" align="flex-start" mb={2}>
            <Box as="h4" fontWeight="medium" mr="auto" maxWidth="60%" wordBreak="break-word">
              <Link
                as={RRLink}
                aria-label="Link to Saved Query"
                to={`${urls.data.dataExplorer()}?savedQueryId=${savedQuery.id}`}
              >
                {savedQuery.name}
              </Link>
            </Box>
            <CardMetaValue label="Updated" value={formatDatetime(savedQuery.lastModifiedAt)} />
            <RoleRestrictedAccess allowedPermissions={[Permission.UserRead]}>
              <CardBulletDivider />
              <CardMetaValue label="By" value={getActorDisplayName(savedQuery.lastModifiedBy)} />
            </RoleRestrictedAccess>
            <Box my={-1} ml={2}>
              <SavedQueryCardOptions savedQuery={savedQuery} />
            </Box>
          </Flex>
          {savedQuery?.description && (
            <Box mb={2}>
              <Text fontSize="medium" color="navyblue-100">
                {savedQuery.description}
              </Text>
            </Box>
          )}
          <Grid columnGap={5} templateColumns="2fr 1fr 1fr">
            <CardValue
              label="SQL Expression"
              value={
                <Flex
                  align="center"
                  onClick={() => setShowSQL(!showSQL)}
                  cursor="pointer"
                  overflow="hidden"
                >
                  <Text truncated>{savedQuery.sqlQuery}</Text>
                  <Box whiteSpace="nowrap" ml={3}>
                    <IconButton
                      icon="code"
                      size="small"
                      variantColor="navyblue-300"
                      variantBorderStyle="circle"
                      aria-label="Expand code"
                      data-testid="expand-query-button"
                    />
                  </Box>
                </Flex>
              }
            />
            <CardValue label="Tags" value={savedQuery.tags.toString()} />
            {savedQuery.schedule && (
              <Flex justify="flex-end" align="flex-end" spacing={4}>
                {savedQuery.managed && <ManagedBadge managed={savedQuery.managed} />}
                {savedQuery.schedule.disabled ? (
                  <FixedWidthBadge width={80} color="gray-700">
                    INACTIVE
                  </FixedWidthBadge>
                ) : (
                  <FixedWidthBadge width={80} color="blue-400">
                    ACTIVE
                  </FixedWidthBadge>
                )}
                <ScheduleBadge schedule={savedQuery.schedule} />
              </Flex>
            )}
          </Grid>
          {showSQL && (
            <Box mt={2}>
              <Editor
                data-testid="saved-query-sql-editor"
                readOnly
                minHeight={17}
                language="sql"
                value={savedQuery.sqlQuery}
              />
            </Box>
          )}
        </Flex>
      </Flex>
    </Card>
  );
};

export default React.memo(SavedQueryCard);
