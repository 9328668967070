/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import type { monaco } from 'react-monaco-editor';
import { EditorProps } from './Editor';
import registerPythonCompletions from './languages/python';
import registerSqlCompletions from './languages/sql';

const noop = () => {};

const getLanguageCompletionsRegistration = (language: EditorProps['language']) => {
  switch (language) {
    case 'sql':
      return registerSqlCompletions;
    case 'python':
      return registerPythonCompletions;
    default:
      return () => ({ dispose: noop });
  }
};

const getTextModelOptions = (
  language: EditorProps['language']
): monaco.editor.ITextModelUpdateOptions => {
  switch (language) {
    case 'python':
      return { tabSize: 4, insertSpaces: true };
    case 'json':
    case 'yaml':
    default:
      return { tabSize: 2, insertSpaces: true };
  }
};

interface UseEditorLanguageProps {
  monacoInstance?: typeof monaco;
  language: EditorProps['language'];
}

const useEditorLanguage = ({ monacoInstance, language }: UseEditorLanguageProps) => {
  React.useEffect(() => {
    if (!monacoInstance) {
      return noop;
    }

    const textModelOptions = getTextModelOptions(language);
    monacoInstance.editor
      .getModels()
      .find(model => model.getModeId() === language)
      .updateOptions(textModelOptions);

    const registration = getLanguageCompletionsRegistration(language)(monacoInstance);
    return () => {
      registration.dispose();
    };
  }, [monacoInstance, language]);
};

export default useEditorLanguage;
