/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { SimpleGrid } from 'pouncejs';
import { DestinationTypeEnum } from 'Generated/schema';
import { ListDestinationsAndDefaults } from '../graphql/listDestinationsAndDefaults.generated';
import {
  MsTeamsDestinationCard,
  AsanaDestinationCard,
  SlackDestinationCard,
  SnsDestinationCard,
  CustomWebhookDestinationCard,
  GithubDestinationCard,
  JiraDestinationCard,
  OpsGenieDestinationCard,
  PagerDutyDestinationCard,
  SqsDestinationCard,
} from '../DestinationCards';

type ListDestinationsTableProps = Pick<ListDestinationsAndDefaults, 'destinations'>;

const ListDestinationsCards: React.FC<ListDestinationsTableProps> = ({ destinations }) => {
  return (
    <SimpleGrid as="article" columns={1} gap={3} data-testid="alert-destinations">
      {destinations.map(destination => {
        const { outputId } = destination;
        switch (destination.outputType) {
          case DestinationTypeEnum.Slack:
            return <SlackDestinationCard destination={destination} key={outputId} />;
          case DestinationTypeEnum.Msteams:
            return <MsTeamsDestinationCard destination={destination} key={outputId} />;
          case DestinationTypeEnum.Asana:
            return <AsanaDestinationCard destination={destination} key={outputId} />;
          case DestinationTypeEnum.Sns:
            return <SnsDestinationCard destination={destination} key={outputId} />;
          case DestinationTypeEnum.Sqs:
            return <SqsDestinationCard destination={destination} key={outputId} />;
          case DestinationTypeEnum.Github:
            return <GithubDestinationCard destination={destination} key={outputId} />;
          case DestinationTypeEnum.Jira:
            return <JiraDestinationCard destination={destination} key={outputId} />;
          case DestinationTypeEnum.Opsgenie:
            return <OpsGenieDestinationCard destination={destination} key={outputId} />;
          case DestinationTypeEnum.Pagerduty:
            return <PagerDutyDestinationCard destination={destination} key={outputId} />;
          case DestinationTypeEnum.Customwebhook:
            return <CustomWebhookDestinationCard destination={destination} key={outputId} />;
          default:
            throw new Error(`No Card matching found for ${destination.outputType}`);
        }
      })}
    </SimpleGrid>
  );
};

export default React.memo(ListDestinationsCards);
