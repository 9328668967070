/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import * as Types from '../../../__generated__/schema';

import { RoleTeaser } from './RoleTeaser.generated';
import { ActorTeaser_User_, ActorTeaser_APIToken_ } from './ActorTeaser.generated';
import { GraphQLError } from 'graphql';
import gql from 'graphql-tag';
import { ActorTeaser } from './ActorTeaser.generated';

export type RoleDetails = Pick<
  Types.Role,
  'logTypeAccess' | 'logTypeAccessKind' | 'createdAt' | 'updatedAt'
> & { updatedBy?: Types.Maybe<ActorTeaser_User_ | ActorTeaser_APIToken_> } & RoleTeaser;

export const RoleDetails = gql`
  fragment RoleDetails on Role {
    ...RoleTeaser
    logTypeAccess
    logTypeAccessKind
    createdAt
    updatedAt
    updatedBy {
      ...ActorTeaser
    }
  }
  ${RoleTeaser}
  ${ActorTeaser}
`;
