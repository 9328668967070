/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import {
  AWS_ACCOUNT_ID_REGEX,
  LogTransportMethodsEnum,
  ROLE_ARN_REGEX,
  S3_BUCKET_NAME_REGEX,
} from 'Source/constants';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { Wizard } from 'Components/Wizard';
import { yupIntegrationLabelValidation } from 'Helpers/utils';
import { CloudWatchLogIntegrationDetails } from 'Source/graphql/fragments/CloudwatchLogIntegrationDetails.generated';
import useFindSourcesPerMethod from 'Hooks/useFindSourcesPerMethod';
import SelectIAMRoleCreationMethodPanel, {
  IAMRoleCreationMethods,
} from 'Components/wizards/common/SelectIAMRoleCreationMethodPanel';
import CancelIntegrationAction from 'Pages/Integrations/components/CancelIntegrationAction';
import SourceConfigurationPanel from './SourceConfigurationPanel';
import ValidationPanel from './ValidationPanel';
import { ConsoleMethod, CustomMethod, TemplateMethod } from './IamRoleCreationMethods';
import ConfigureNotificationsPanel from './ConfigureNotificationsPanel';

interface CloudSourceLogSourceWizardProps {
  initialValues: CloudWatchLogSourceWizardValues;
  availableLogTypes: string[];
  onSubmit: (
    values: CloudWatchLogSourceWizardValues
  ) => Promise<CloudWatchLogIntegrationDetails | Error>;
}

export interface CloudWatchLogSourceWizardValues {
  integrationId?: string; // for updates
  integrationLabel: string;
  awsAccountId: string;
  s3Bucket: string;
  managedBucketNotifications: boolean;
  logProcessingRole: string;
  logGroup: string;
  logTypes: string[];
  filterPattern: string;
}

const methodComponents: Record<IAMRoleCreationMethods, React.ElementType> = {
  console: ConsoleMethod,
  custom: CustomMethod,
  template: TemplateMethod,
};

const validationSchema: Yup.SchemaOf<CloudWatchLogSourceWizardValues> = Yup.object().shape({
  integrationId: Yup.string(),
  integrationLabel: yupIntegrationLabelValidation,
  awsAccountId: Yup.string()
    .matches(AWS_ACCOUNT_ID_REGEX, 'Must be a valid AWS Account ID')
    .required(),
  s3Bucket: Yup.string().matches(S3_BUCKET_NAME_REGEX, 'Must be valid S3 Bucket name').required(),
  managedBucketNotifications: Yup.boolean().required(),
  logProcessingRole: Yup.string()
    .matches(
      ROLE_ARN_REGEX,
      'Must be a valid IAM ARN i.e. arn:aws:iam::{ACCOUNT_ID}:role/{ROLENAME}'
    )
    .required('Please fill in the Role ARN to continue with the source setup'),
  logGroup: Yup.string().required(),
  logTypes: Yup.array().of(Yup.string()).required().min(1),
  filterPattern: Yup.string(),
});

const CloudWatchLogSourceWizard: React.FC<CloudSourceLogSourceWizardProps> = ({
  initialValues,
  onSubmit,
  availableLogTypes,
}) => {
  const editMode = !!initialValues.integrationId;

  const [selectedMethod, setSelectedMethod] = React.useState<IAMRoleCreationMethods>('template');

  const formStatus = React.useMemo(() => ({ availableLogTypes }), [availableLogTypes]);
  const existingCloudWatchSources = useFindSourcesPerMethod(
    LogTransportMethodsEnum.cloudwatch
  ) as CloudWatchLogIntegrationDetails[];

  const integrationLabelValidation = Yup.object().shape({
    integrationLabel: yupIntegrationLabelValidation.test(
      'mutex',
      'You already have a source with the same label for this AWS account id',
      // eslint-disable-next-line func-names
      function (newIntegrationLabel) {
        if (!this.parent.awsAccountId) {
          return true;
        }
        return !existingCloudWatchSources?.find(
          ({ awsAccountId, integrationLabel }) =>
            // We check if there another S3 with AWS accountId and integrationLabel
            awsAccountId === this.parent.awsAccountId &&
            // but checking if the new label is different from the initial value for the edit flow
            newIntegrationLabel !== initialValues.integrationLabel &&
            integrationLabel === newIntegrationLabel
        );
      }
    ),
  });

  const mergedValidationSchema = validationSchema.concat(integrationLabelValidation);
  const SelectedMethod = methodComponents[selectedMethod];

  return (
    <Formik<CloudWatchLogSourceWizardValues>
      enableReinitialize
      initialValues={initialValues}
      validationSchema={mergedValidationSchema}
      onSubmit={onSubmit}
      initialStatus={formStatus}
    >
      <Form>
        <Wizard headerTitle="Connecting a new Log Source" enableAllNavigationSteps={editMode}>
          <Wizard.Step
            title={editMode ? 'Edit Source Details' : 'Setup your Source'}
            description={
              editMode
                ? 'Edit account information and preferences for this log source'
                : 'Provide account information and set preferences to connect the log source to Panther'
            }
          >
            <SourceConfigurationPanel />
          </Wizard.Step>
          <Wizard.StepGroup
            title={editMode ? 'Edit IAM Role' : 'Setup IAM Role'}
            description={
              editMode
                ? 'Edit the IAM role in your account to give Panther the right permissions to collect logs from the source'
                : 'Create or use an existing IAM role in your account to give Panther the right permissions to collect logs from the source'
            }
          >
            <Wizard.Step title="Choose Method">
              <SelectIAMRoleCreationMethodPanel onSelectMethod={setSelectedMethod} />
            </Wizard.Step>
            <Wizard.Step title={editMode ? 'Edit Role' : 'Finalize Role Setup'}>
              <SelectedMethod />
            </Wizard.Step>
          </Wizard.StepGroup>
          {selectedMethod === 'custom' && (
            <Wizard.Step
              title="Configure Notifications"
              description="Grant Panther permissions to read from your S3 bucket"
            >
              <ConfigureNotificationsPanel />
            </Wizard.Step>
          )}
          <Wizard.Step
            title="Verify Setup"
            description="Confirm that your log source has been successfully onboarded or troubleshoot any issues"
            navigationDisabled={editMode}
          >
            <ValidationPanel />
          </Wizard.Step>
        </Wizard>
        {!editMode && <CancelIntegrationAction />}
      </Form>
    </Formik>
  );
};

export default CloudWatchLogSourceWizard;
