/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Box, Tabs, TabList } from 'pouncejs';
import { BorderedTab, BorderTabDivider } from 'Components/BorderedTab';
import isEmpty from 'lodash/isEmpty';
import useUrlParams from 'Hooks/useUrlParams';
import MitreRelationsLogSources from './MitreRelationsLogSources';
import MitreRelationsLogSourcesEmpty from './MitreRelationsLogSourcesEmpty';
import MitreRelationsHeader from './MitreRelationsHeader';
import MitreRelationsDetections from './MitreRelationsDetections';
import MitreRelationsDetectionsEmpty from './MitreRelationsDetectionsEmpty';
import { useMitreContext } from '../MitreContext';

type RelationTabString = 'detections' | 'logSources';

enum RelationTab {
  Detections = 0,
  LogSources = 1,
}

const RelationTabStrings: { [key in RelationTab]: RelationTabString } = {
  [RelationTab.Detections]: 'detections',
  [RelationTab.LogSources]: 'logSources',
};

const RelationTabValues: { [key in RelationTabString]: RelationTab } = {
  detections: RelationTab.Detections,
  logSources: RelationTab.LogSources,
};

enum ResultViewState {
  Unknown,
  DetectionsEmpty,
  DetectionList,
  LogSourcesEmpty,
  LogSourcesList,
}

type MitreRelationsUrlParams = {
  relations: string;
};

const MitreRelations = () => {
  const { urlParams, updateUrlParams } = useUrlParams<MitreRelationsUrlParams>();
  const activeTab = RelationTabValues[urlParams.relations] || RelationTab.Detections;

  const { activeReportSetting, activeTechnique, activeTactic } = useMitreContext();

  const handleTabChange = React.useCallback(
    idx => updateUrlParams({ relations: RelationTabStrings[idx] }),
    [updateUrlParams]
  );

  const resultView = React.useMemo<ResultViewState>(() => {
    switch (activeTab) {
      case RelationTab.Detections:
        return isEmpty(activeReportSetting?.matchingDetectionIds)
          ? ResultViewState.DetectionsEmpty
          : ResultViewState.DetectionList;
      case RelationTab.LogSources:
        return isEmpty(activeReportSetting?.matchingLogTypes)
          ? ResultViewState.LogSourcesEmpty
          : ResultViewState.LogSourcesList;
      default:
        return ResultViewState.Unknown;
    }
  }, [activeTab, activeReportSetting]);

  if (!activeReportSetting) {
    return null;
  }

  return (
    <Box p={8}>
      <MitreRelationsHeader tactic={activeTactic} technique={activeTechnique} />
      <Tabs index={activeTab} onChange={handleTabChange}>
        <Box position="relative">
          <TabList>
            <BorderedTab tabIndex={RelationTab.Detections}>Detections</BorderedTab>
            <BorderedTab tabIndex={RelationTab.LogSources}>Log Sources</BorderedTab>
          </TabList>
          <BorderTabDivider />
          <Box pt={2} minHeight="300px">
            {resultView === ResultViewState.DetectionsEmpty && (
              <MitreRelationsDetectionsEmpty
                tacticId={activeTactic.id}
                techniqueId={activeTechnique.id}
              />
            )}
            {resultView === ResultViewState.DetectionList && (
              <MitreRelationsDetections reportSetting={activeReportSetting} />
            )}
            {resultView === ResultViewState.LogSourcesEmpty && <MitreRelationsLogSourcesEmpty />}
            {resultView === ResultViewState.LogSourcesList && (
              <MitreRelationsLogSources matchingLogTypes={activeReportSetting.matchingLogTypes} />
            )}
          </Box>
        </Box>
      </Tabs>
    </Box>
  );
};

export default React.memo(MitreRelations);
