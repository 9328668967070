/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Box, Card, FadeIn, SimpleGrid } from 'pouncejs';
import TablePlaceholder from 'Components/TablePlaceholder';
import Panel from 'Components/Panel';
import { BorderedTab, BorderTabDivider } from 'Components/BorderedTab';

const DataOverviewSkeleton: React.FC = () => {
  return (
    <Box as="article">
      <FadeIn duration={400}>
        <SimpleGrid columns={1} spacingY={4}>
          <Card position="relative" px={2} pointerEvents="none">
            <BorderedTab>Number of Events by Log Type</BorderedTab>
            <BorderedTab>Volume of Events Processed</BorderedTab>
            <BorderTabDivider />
            <Box height={289} m={6}>
              <TablePlaceholder rowCount={7} rowHeight={10} />
            </Box>
          </Card>
          <Card position="relative" px={2} pointerEvents="none">
            <BorderedTab>Volume of Data Stored</BorderedTab>
            <BorderedTab>Volume of Data Queried</BorderedTab>
            <BorderTabDivider />
            <Box height={289} m={6}>
              <TablePlaceholder rowCount={7} rowHeight={10} />
            </Box>
          </Card>
          <Panel title="Average Data Latency by Log Type">
            <SimpleGrid columns={3} spacing={3}>
              <TablePlaceholder rowHeight={40} />
              <TablePlaceholder rowHeight={40} />
              <TablePlaceholder rowHeight={40} />
            </SimpleGrid>
          </Panel>
        </SimpleGrid>
      </FadeIn>
    </Box>
  );
};

export default DataOverviewSkeleton;
