/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import {
  useMetricsFiltersContext,
  withMetricsFiltersContext,
} from 'Components/utils/MetricsFiltersContext';
import { Box, Card, SimpleGrid, Heading, Flex, Text, Alert } from 'pouncejs';
import { formatBytes, toPlural, extractErrorMessage, getElapsedTime } from 'Helpers/utils';
import TablePlaceholder from 'Components/TablePlaceholder';
import TimeSeriesChart from 'Components/charts/TimeSeriesChart';
import NoDataFound from 'Components/NoDataFound';
import useCalculatedSourceMetrics from 'Hooks/useCalculatedSourceMetrics';
import OverviewFilters from './OverviewFilters';

const getLastReceivedMessage = (lastEventReceived, type) => {
  if (!lastEventReceived) {
    return type === 'data' ? 'No data processed yet' : 'No events received yet';
  }

  const elapsedTime = getElapsedTime(new Date(lastEventReceived).getTime() / 1000);
  return type === 'data' ? `Last processed ${elapsedTime}` : `Last received ${elapsedTime}`;
};

const OverviewPanel = ({ integrationId, lastEventReceived }) => {
  const { filters, resultProjectionStartDate } = useMetricsFiltersContext();
  const {
    totalBytes,
    totalEvents,
    bytesProcessed,
    eventsProcessed,
    hasPreviousData,
    loading,
    error,
  } = useCalculatedSourceMetrics({
    integrationId,
    filters,
  });

  const [dataLastProcessed, eventLastReceived] = React.useMemo(
    () => [
      getLastReceivedMessage(lastEventReceived, 'data'),
      getLastReceivedMessage(lastEventReceived, 'event'),
    ],
    [lastEventReceived]
  );

  if (error) {
    return (
      <Alert
        variant="error"
        title="We can't display this content right now"
        description={extractErrorMessage(error)}
      />
    );
  }
  if (loading && !hasPreviousData) {
    return <TablePlaceholder />;
  }

  const [bytes, suffix] = formatBytes(totalBytes).split(' ');

  return (
    <Box as="article" position="relative">
      <Flex align="center" justify="space-between" mb={4}>
        <Heading as="h2" fontWeight="bold" fontSize="x-large" color="navyblue-100">
          Data Metrics
        </Heading>
        <OverviewFilters />
      </Flex>
      <SimpleGrid gap={2} columns={2}>
        <Card variant="dark" p={6}>
          <Heading as="h2" fontWeight="bold" fontSize="large">
            Data Processed
          </Heading>
          <Text fontSize="medium" color="navyblue-100">
            {dataLastProcessed}
          </Text>

          <Flex align="baseline">
            <Heading as="h2" size="2x-large" color="green-300" fontWeight="bold">
              {bytes}
            </Heading>
            <Text as="b" ml={2}>
              {toPlural(suffix)}
            </Text>
          </Flex>
        </Card>
        <Card variant="dark" p={6}>
          <Heading as="h2" fontWeight="bold" fontSize="large">
            Events Processed
          </Heading>
          <Text fontSize="medium" color="navyblue-100">
            {eventLastReceived}
          </Text>

          <Flex align="baseline">
            <Heading as="h2" size="2x-large" color="orange-200" fontWeight="bold">
              {totalEvents.toLocaleString()}
            </Heading>
          </Flex>
        </Card>
      </SimpleGrid>
      <Box variant="dark" mt={5}>
        <Text fontWeight="bold" mb={5}>
          Data Processed by Log Type
        </Text>
        <Card variant="dark" height={289} py={5} pl={4}>
          {bytesProcessed.series.length > 0 ? (
            <TimeSeriesChart
              chartId="bytes-by-log-type"
              data={bytesProcessed}
              zoomable
              units="bytes"
              projectionStartDate={resultProjectionStartDate}
            />
          ) : (
            <NoDataFound title="Not enough data yet" />
          )}
        </Card>
      </Box>
      <Box variant="dark" mt={5}>
        <Text fontWeight="bold" mb={5}>
          Events by Log Type
        </Text>
        <Card variant="dark" height={289} py={5} pl={4}>
          {eventsProcessed.series.length > 0 ? (
            <TimeSeriesChart
              chartId="events-by-log-type"
              data={eventsProcessed}
              zoomable
              projectionStartDate={resultProjectionStartDate}
            />
          ) : (
            <NoDataFound title="Not enough data yet" />
          )}
        </Card>
      </Box>
    </Box>
  );
};

export default withMetricsFiltersContext(OverviewPanel);
