/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React, { ReactElement } from 'react';
import { Text } from 'pouncejs';
import { ReplayState } from 'Generated/schema';
import ReplayStageViewItem from 'Components/forms/ReplayForm/ReplayStatus/ReplayStageViewItem';
import {
  ReplayStageState,
  ReplayStageViewProps,
} from 'Components/forms/ReplayForm/ReplayStatus/ReplayStatus';

const ReplayComputationStageView: React.FC<ReplayStageViewProps> = ({ replay }) => {
  let state: ReplayStageState;
  let body: ReactElement;

  switch (!!replay) {
    case false:
      state = 'queued';
      body = <Text>Waiting for log evaluation.</Text>;
      break;
    default:
      switch (replay.state) {
        case ReplayState.ErrorEvaluation:
        case ReplayState.EvaluationInProgress:
          state = 'queued';
          body = <Text>Not started.</Text>;
          break;
        case ReplayState.Done:
          state = 'complete';
          body = <Text>Simulation completed.</Text>;
          break;
        case ReplayState.ComputationInProgress:
          state = 'running';
          body = (
            <Text>
              {replay.summary.computationProgress}% - Simulating alerts based on evaluation results.
            </Text>
          );
          break;
        case ReplayState.ErrorComputation:
          state = 'failed';
          body = <Text>An error occurred while simulating alerts.</Text>;
          break;
        case ReplayState.Canceled:
          state = 'failed';
          body = <Text>Replay was canceled.</Text>;
          break;
        case ReplayState.Unknown:
        default:
          state = 'failed';
          body = <Text>Replay in unknown state.</Text>;
      }
  }

  return (
    <ReplayStageViewItem title="Alert Simulation" state={state}>
      {body}
    </ReplayStageViewItem>
  );
};

export default ReplayComputationStageView;
