/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */
import React from 'react';
import { Box, Link, Flex, Card, Text, SimpleGrid } from 'pouncejs';
import { Link as RRLink } from 'react-router-dom';
import Linkify from 'Components/Linkify';
import urls from 'Source/urls';
import { formatDatetime, formatNumber, getActorDisplayName, minutesToString } from 'Helpers/utils';
import { RuleDetails } from 'Source/graphql/fragments/RuleDetails.generated';

interface RuleCardDetailsProps {
  rule?: RuleDetails;
}

const RuleDetailsInfo: React.FC<RuleCardDetailsProps> = ({ rule }) => {
  return (
    <Card as="article" p={6}>
      <Card variant="dark" as="section" p={4} mb={4}>
        <Text id="rule-description" fontStyle={!rule.description ? 'italic' : 'normal'} mb={6}>
          {rule.description || 'No description found for rule'}
        </Text>
        <SimpleGrid columns={2} spacing={5}>
          <Flex direction="column" spacing={2}>
            <Box
              color="navyblue-100"
              fontSize="small-medium"
              aria-describedby="runbook-description"
            >
              Runbook
            </Box>
            {rule.runbook ? (
              <Linkify id="runbook-description">{rule.runbook}</Linkify>
            ) : (
              <Box fontStyle="italic" color="navyblue-100" id="runbook-description">
                No runbook specified
              </Box>
            )}
          </Flex>
          <Flex direction="column" spacing={2}>
            <Box
              color="navyblue-100"
              fontSize="small-medium"
              aria-describedby="reference-description"
            >
              Reference
            </Box>
            {rule.reference ? (
              <Linkify id="reference-description">{rule.reference}</Linkify>
            ) : (
              <Box fontStyle="italic" color="navyblue-100" id="reference-description">
                No reference specified
              </Box>
            )}
          </Flex>
        </SimpleGrid>
      </Card>
      <Card variant="dark" as="section" p={4}>
        <Flex fontSize="small-medium" alignItems="flex-start">
          <SimpleGrid as="dl" width="50%" gap={2} columns={8} spacing={2}>
            <Box as="dt" color="navyblue-100" gridColumn="1/3" aria-describedby="created-at">
              Created
            </Box>
            <Box as="dd" gridColumn="3/8" id="created-at">
              {formatDatetime(rule.createdAt)}
            </Box>

            <Box as="dt" color="navyblue-100" gridColumn="1/3" aria-describedby="created-at">
              Created By
            </Box>
            <Box as="dd" gridColumn="3/8" id="created-by">
              {getActorDisplayName(rule.createdBy)}
            </Box>

            <Box as="dt" color="navyblue-100" gridColumn="1/3" aria-describedby="updated-at">
              Modified
            </Box>
            <Box as="dd" gridColumn="3/8" id="updated-at">
              {formatDatetime(rule.lastModified)}
            </Box>

            <Box as="dt" color="navyblue-100" gridColumn="1/3" aria-describedby="created-at">
              Modified By
            </Box>
            <Box as="dd" gridColumn="3/8" id="updated-by">
              {getActorDisplayName(rule.lastModifiedBy)}
            </Box>
          </SimpleGrid>
          <SimpleGrid as="dl" width="50%" gap={2} columns={8} spacing={2}>
            <Box as="dt" gridColumn="1/3" color="navyblue-100" aria-describedby="tags-list">
              Tags
            </Box>
            {rule.tags.length > 0 ? (
              <Box as="dd" id="tags-list" gridColumn="3/8">
                {rule.tags.map((tag, index) => (
                  <Link
                    key={tag}
                    as={RRLink}
                    to={`${urls.detections.list()}?page=1&tags[]=${tag}`}
                    data-tid="link-to-detection-tag"
                  >
                    {tag}
                    {index !== rule.tags.length - 1 ? ', ' : null}
                  </Link>
                ))}
              </Box>
            ) : (
              <Box as="dd" gridColumn="3/8" fontStyle="italic" color="navyblue-100" id="tags-list">
                This rule has no tags
              </Box>
            )}
            <Box
              as="dt"
              gridColumn="1/3"
              color="navyblue-100"
              aria-describedby="deduplication-period"
            >
              Deduplication Period
            </Box>
            <Box as="dd" gridColumn="3/8" id="deduplication-period">
              {minutesToString(rule.dedupPeriodMinutes)}
            </Box>

            <Box as="dt" gridColumn="1/3" color="navyblue-100" aria-describedby="threshold">
              Threshold
            </Box>
            <Box as="dd" gridColumn="3/8" id="threshold">
              {formatNumber(rule.threshold)}
            </Box>
            <Box
              as="dt"
              gridColumn="1/3"
              color="navyblue-100"
              aria-describedby="ignore-patterns-list"
            >
              Report Mappings
            </Box>
            {rule.reports.length > 0 ? (
              <Box as="dd" gridColumn="3/8" id="ignore-patterns-list">
                {rule.reports.map(({ key, values }) => (
                  <Text key={key}>
                    {key}: {values.join(', ')}
                  </Text>
                ))}
              </Box>
            ) : (
              <Box as="dd" gridColumn="3/8" id="ignore-patterns-list">
                No security reports have been mapped to this rule
              </Box>
            )}
            <Box
              as="dt"
              color="navyblue-100"
              gridColumn="1/3"
              aria-describedby="summary-attributes"
            >
              Summary Attributes
            </Box>
            {rule.summaryAttributes.length > 0 ? (
              <Box as="ul" gridColumn="3/8" id="summary-attributes">
                {rule.summaryAttributes.map(attr => (
                  <Box as="li" key={attr}>
                    {attr}
                  </Box>
                ))}
              </Box>
            ) : (
              <Box
                as="dd"
                gridColumn="3/8"
                fontStyle="italic"
                color="navyblue-100"
                id="summary-attributes"
              >
                Not configured
              </Box>
            )}
          </SimpleGrid>
        </Flex>
      </Card>
    </Card>
  );
};

export default RuleDetailsInfo;
