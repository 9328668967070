/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { SimpleGrid, Flex, Box, Text, Icon } from 'pouncejs';
import { WizardPanel, useWizardContext } from 'Components/Wizard';
import Rows from './Rows';
import { UploadDetections } from '../UploadPanel/graphql/uploadDetections.generated';

const BoxColumn: React.FC = props => <Box p={6} backgroundColor="navyblue-500" {...props} />;

const SuccessfulUpload: React.FC = () => {
  const { data } = useWizardContext<UploadDetections>();

  return (
    <WizardPanel>
      <WizardPanel.Heading
        title="Your file was successfully processed and the following changes were made"
        subtitle="You can visit the corresponding pages to view or edit your global helpers, rules, policies or saved queries"
      />
      <Flex justify="center" data-testid="success-indicator">
        <SimpleGrid gap={5} columns={5} mb={5}>
          <BoxColumn>
            <Flex mb={5} width={150} align="center">
              <Icon type="source-code" mr={3} />
              <Text fontWeight="bold">Global Helpers</Text>
            </Flex>
            <Rows
              newItems={data?.uploadDetections?.newGlobals}
              modifiedItems={data?.uploadDetections?.modifiedGlobals}
              totalItems={data?.uploadDetections?.totalGlobals}
            />
          </BoxColumn>
          <BoxColumn>
            <Flex mb={5} width={150} align="center">
              <Icon type="rule" mr={3} />
              <Text fontWeight="bold">Rules</Text>
            </Flex>
            <Rows
              newItems={data?.uploadDetections?.newRules}
              modifiedItems={data?.uploadDetections?.modifiedRules}
              totalItems={data?.uploadDetections?.totalRules}
            />
          </BoxColumn>
          <BoxColumn>
            <Flex mb={5} width={150} align="center">
              <Icon type="policy" mr={3} />
              <Text fontWeight="bold">Policies</Text>
            </Flex>
            <Rows
              newItems={data?.uploadDetections?.newPolicies}
              modifiedItems={data?.uploadDetections?.modifiedPolicies}
              totalItems={data?.uploadDetections?.totalPolicies}
            />
          </BoxColumn>
          <BoxColumn>
            <Flex mb={5} width={150} align="center">
              <Icon type="data" mr={3} />
              <Text fontWeight="bold">Data Models</Text>
            </Flex>
            <Rows
              newItems={data?.uploadDetections?.newDataModels}
              modifiedItems={data?.uploadDetections?.modifiedDataModels}
              totalItems={data?.uploadDetections?.totalDataModels}
            />
          </BoxColumn>
          <BoxColumn>
            <Flex mb={5} width={150} align="center">
              <Icon type="save" mr={3} />
              <Text fontWeight="bold">Saved Queries</Text>
            </Flex>
            <Rows
              newItems={data?.uploadDetections?.newQueries}
              modifiedItems={data?.uploadDetections?.modifiedQueries}
              totalItems={data?.uploadDetections?.totalQueries}
            />
          </BoxColumn>
        </SimpleGrid>
      </Flex>
      <WizardPanel.Actions>
        <WizardPanel.ActionStart>Upload another</WizardPanel.ActionStart>
      </WizardPanel.Actions>
    </WizardPanel>
  );
};

export default React.memo(SuccessfulUpload);
