/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import * as Types from '../../../../../../__generated__/schema';

import { TestFunctionResult } from '../../../../../graphql/fragments/TestFunctionResult.generated';
import { GraphQLError } from 'graphql';
import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';

export type TestRuleVariables = {
  input: Types.TestRuleInput;
};

export type TestRule = {
  testRule: {
    results: Array<
      Pick<Types.TestDetectionRecord, 'id' | 'name' | 'passed'> & {
        error?: Types.Maybe<Pick<Types.Error, 'message'>>;
        functions: {
          detectionFunction?: Types.Maybe<TestFunctionResult>;
          titleFunction?: Types.Maybe<TestFunctionResult>;
          dedupFunction?: Types.Maybe<TestFunctionResult>;
          alertContextFunction?: Types.Maybe<TestFunctionResult>;
          descriptionFunction?: Types.Maybe<TestFunctionResult>;
          destinationsFunction?: Types.Maybe<TestFunctionResult>;
          referenceFunction?: Types.Maybe<TestFunctionResult>;
          runbookFunction?: Types.Maybe<TestFunctionResult>;
          severityFunction?: Types.Maybe<TestFunctionResult>;
        };
      }
    >;
  };
};

export const TestRuleDocument = gql`
  mutation TestRule($input: TestRuleInput!) {
    testRule(input: $input) {
      results {
        id
        name
        passed
        error {
          message
        }
        functions {
          detectionFunction {
            ...TestFunctionResult
          }
          titleFunction {
            ...TestFunctionResult
          }
          dedupFunction {
            ...TestFunctionResult
          }
          alertContextFunction {
            ...TestFunctionResult
          }
          descriptionFunction {
            ...TestFunctionResult
          }
          destinationsFunction {
            ...TestFunctionResult
          }
          referenceFunction {
            ...TestFunctionResult
          }
          runbookFunction {
            ...TestFunctionResult
          }
          severityFunction {
            ...TestFunctionResult
          }
        }
      }
    }
  }
  ${TestFunctionResult}
`;
export type TestRuleMutationFn = ApolloReactCommon.MutationFunction<TestRule, TestRuleVariables>;

/**
 * __useTestRule__
 *
 * To run a mutation, you first call `useTestRule` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTestRule` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [testRule, { data, loading, error }] = useTestRule({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTestRule(
  baseOptions?: ApolloReactHooks.MutationHookOptions<TestRule, TestRuleVariables>
) {
  return ApolloReactHooks.useMutation<TestRule, TestRuleVariables>(TestRuleDocument, baseOptions);
}
export type TestRuleHookResult = ReturnType<typeof useTestRule>;
export type TestRuleMutationResult = ApolloReactCommon.MutationResult<TestRule>;
export type TestRuleMutationOptions = ApolloReactCommon.BaseMutationOptions<
  TestRule,
  TestRuleVariables
>;
export function mockTestRule({
  data,
  variables,
  errors,
}: {
  data: TestRule;
  variables?: TestRuleVariables;
  errors?: GraphQLError[];
}) {
  return {
    request: { query: TestRuleDocument, variables },
    result: { data, errors },
  };
}
