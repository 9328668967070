/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import storage from 'Helpers/storage';

interface UseSessionProps {
  sessionKey: string;
}

interface UseSessionResp<T> {
  session: T;
  setSession: (session: T) => void;
  clearSession: () => void;
}

/**
 * A hook that helps storing, fetching and clearing session data
 */
function useSession<T>({ sessionKey }: UseSessionProps): UseSessionResp<T> {
  const [session, setSession] = React.useState<T>(storage.session.read<T>(sessionKey));

  React.useEffect(() => {
    setSession(storage.session.read<T>(sessionKey));
  }, [sessionKey]);

  // Helper to clear session storage
  const clearSession = React.useCallback(() => {
    storage.session.delete(sessionKey);
    setSession(null);
  }, [sessionKey]);

  const setStorageSession = React.useCallback(
    (newSession: T) => {
      storage.session.write(sessionKey, newSession);
      setSession(newSession);
    },
    [sessionKey]
  );

  return React.useMemo(() => ({ session, clearSession, setSession: setStorageSession }), [
    session,
    clearSession,
    setStorageSession,
  ]);
}

export default useSession;
