/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Flex, AbstractButton, Text } from 'pouncejs';

interface TruncationControlProps {
  handleClick: () => void;
  isTruncated: boolean;
  listLength: number;
}
const TruncationControl: React.FC<TruncationControlProps> = ({
  handleClick,
  isTruncated,
  listLength,
}) => {
  // Since this component isn't part of the "Table" it is under, we do not get the free odd/even coloring so we need to manually handle it
  const backgroundColor = React.useMemo(() => {
    return listLength % 2 === 0 ? 'navyblue-500' : 'navyblue-400';
  }, [listLength]);
  return (
    <Flex pl={50} py={4} backgroundColor={backgroundColor} justifyContent="center">
      <AbstractButton onClick={handleClick} cursor="pointer" width="100%">
        <Text color="blue-200">{isTruncated ? 'View All' : 'View Less'}</Text>
      </AbstractButton>
    </Flex>
  );
};

export default TruncationControl;
