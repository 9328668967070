/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { useListLogSources } from 'Pages/Integrations/LogSources/ListLogSources';
import { LogTransportMethodsEnum } from 'Source/constants';

function useFindSourcesPerMethod(method: LogTransportMethodsEnum) {
  const { data } = useListLogSources();

  return React.useMemo(
    () => data?.logSources?.filter(source => source.integrationType === method),
    // FIXME: look into hook dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data]
  );
}

export default useFindSourcesPerMethod;
