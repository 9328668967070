/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { SlackLogIntegrationDetails } from 'Source/graphql/fragments/SlackLogIntegrationDetails.generated';
import { Wizard } from 'Components/Wizard';
import { yupIntegrationLabelUniqueness } from 'Helpers/utils';
import {
  LogTransportMethodsEnum,
  SlackLogTypesEnterprise,
  SlackLogTypesStandard,
  SlackPlansEnum,
} from 'Source/constants';
import useFindSourcesPerMethod from 'Hooks/useFindSourcesPerMethod';
import CancelIntegrationAction from 'Pages/Integrations/components/CancelIntegrationAction';
import ValidationPanel from './ValidationPanel';
import SlackLogSourceConfigurationPanel from './SlackLogSourceConfigurationPanel';
import CredentialsPanel from './CredentialsPanel';

interface SlackLogsWizardProps {
  initialValues: SlackLogsSourceWizardValues;
  onSubmit: (values: SlackLogsSourceWizardValues) => Promise<SlackLogIntegrationDetails | Error>;
}

export interface SlackLogsSourceWizardValues {
  integrationId?: string; // for updates
  integrationLabel: string;
  logTypes: string[];
  plan: string;
  clientId: string;
  clientSecret: string;
}

const SlackLogSourceWizard: React.FC<SlackLogsWizardProps> = ({ initialValues, onSubmit }) => {
  const existingPullerSources = useFindSourcesPerMethod(LogTransportMethodsEnum.logPulling);

  const validationSchema: Yup.SchemaOf<SlackLogsSourceWizardValues> = React.useMemo(
    () =>
      Yup.object().shape({
        integrationId: Yup.string(),
        integrationLabel: yupIntegrationLabelUniqueness({
          existingLabel: initialValues.integrationLabel,
          existingSources: existingPullerSources,
        }),
        plan: Yup.string().oneOf(Object.values(SlackPlansEnum)).required(),
        logTypes: Yup.array().when('plan', {
          is: SlackPlansEnum.enterprise,
          then: Yup.array().of(Yup.string().oneOf(SlackLogTypesEnterprise)).required().min(1),
          otherwise: Yup.array().of(Yup.string().oneOf(SlackLogTypesStandard)).required().min(1),
        }),
        clientId: Yup.string().required(),
        clientSecret: initialValues.integrationId ? Yup.string() : Yup.string().required(),
      }),
    [initialValues.integrationId, initialValues.integrationLabel, existingPullerSources]
  );

  const editMode = !!initialValues.integrationId;
  return (
    <Formik<SlackLogsSourceWizardValues>
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      <Form>
        <Wizard headerTitle="Connecting a new Log Source" enableAllNavigationSteps={editMode}>
          <Wizard.Step
            title="Configure Source"
            description="Provide account information and set preferences to connect the log source to Panther"
          >
            <SlackLogSourceConfigurationPanel />
          </Wizard.Step>
          <Wizard.Step
            title="Set Credentials"
            description="Provide account credentials to give Panther permissions to collect logs from the source"
          >
            <CredentialsPanel />
          </Wizard.Step>
          <Wizard.Step
            title="Verify Setup"
            description="Confirm that your log source has been successfully onboarded or troubleshoot any issues"
            navigationDisabled={editMode}
          >
            <ValidationPanel />
          </Wizard.Step>
        </Wizard>
        {!editMode && <CancelIntegrationAction />}
      </Form>
    </Formik>
  );
};

export default SlackLogSourceWizard;
