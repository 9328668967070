/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { SimpleGrid } from 'pouncejs';
import { ListComplianceSources } from '../graphql/listComplianceSources.generated';
import ComplianceSourceCard from '../ComplianceSourceCard';

type ListDestinationsTableProps = { sources: ListComplianceSources['listComplianceIntegrations'] };

const ListDestinationsCards: React.FC<ListDestinationsTableProps> = ({ sources }) => {
  return (
    <SimpleGrid as="article" columns={1} gap={5}>
      {sources.map(source => (
        <ComplianceSourceCard key={source.integrationId} source={source} />
      ))}
    </SimpleGrid>
  );
};

export default React.memo(ListDestinationsCards);
