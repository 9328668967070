/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { useFormikContext } from 'formik';
import debounce from 'lodash/debounce';
import storage from 'Helpers/storage';

export interface UseFormSessionRestorationProps {
  /** Unique identifier for the "sessioned" values */
  sessionId: string;
}

/**
 * Helps save & restore form sessions when the user navigates away or closes the tab while making
 * changes
 */
function useFormSessionRestoration<FormValues>({ sessionId }: UseFormSessionRestorationProps) {
  const { values, dirty, setValues, isSubmitting } = useFormikContext<FormValues>();

  React.useEffect(() => {
    const previousSessionValues = storage.session.read<FormValues>(sessionId);
    if (previousSessionValues) {
      setValues(previousSessionValues);
    }
  }, [sessionId, setValues]);

  // Helper to writes value to session storagee
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const syncStorage = React.useCallback(
    debounce(() => {
      if (dirty && !isSubmitting) {
        storage.session.write(sessionId, values);
      }
    }, 200),
    [dirty, isSubmitting, values]
  );

  // Helper to clear session storage
  const flushStorage = React.useCallback(() => {
    storage.session.delete(sessionId);
  }, [sessionId]);

  // Syncs to session storage
  // FIXME: look into hook dependencies
  // eslint-disable-next-line react-hooks/exhaustive-deps
  React.useEffect(syncStorage, [dirty, values]);

  React.useEffect(() => {
    if (isSubmitting) {
      flushStorage();
    }
  }, [isSubmitting, flushStorage]);

  return { clearFormSession: flushStorage };
}

export default useFormSessionRestoration;
