/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import withSEO from 'Hoc/withSEO';
import { compose } from 'Helpers/compose';
import withRoleRestrictedAccess from 'Hoc/withRoleRestrictedAccess';
import useAvailableLogTypesForLogPuller from 'Hooks/useAvailableLogTypesForLogPuller';
import { Permission } from 'Generated/schema';
import Page403 from 'Pages/403';
import SalesforceLogsSourceWizard, {
  SalesforceLogsSourceWizardValues,
  SalesforceFileIntervalsEnum,
} from '../SalesforceLogsSourceWizard';
import { useAddSalesforceLogSource } from './graphql/addSalesforceLogSource.generated';

const CreateSalesforceLogSource: React.FC = () => {
  const { defaultLogTypes } = useAvailableLogTypesForLogPuller();

  const initialValues = React.useMemo(
    () => ({
      integrationLabel: '',
      logTypes: defaultLogTypes,
      password: '',
      username: '',
      securityToken: '',
      fileInterval: SalesforceFileIntervalsEnum.hourly,
    }),
    [defaultLogTypes]
  );

  const [addSalesforceLogSource] = useAddSalesforceLogSource({
    update: (cache, { data }) => {
      cache.modify({
        fields: {
          logSources: (queryData, { toReference }) => {
            const addedIntegrationCacheRef = toReference(data.addLogPullingIntegration);
            return queryData
              ? [addedIntegrationCacheRef, ...queryData]
              : [addedIntegrationCacheRef];
          },
        },
      });
    },
  });

  const handleSubmit = React.useCallback(
    async (values: SalesforceLogsSourceWizardValues) => {
      try {
        const { data } = await addSalesforceLogSource({
          variables: {
            input: {
              integrationLabel: values.integrationLabel,
              pullerConfig: {
                logTypes: values.logTypes,
                salesforce: {
                  username: values.username,
                  password: values.password,
                  securityToken: values.securityToken,
                  fileInterval: values.fileInterval,
                },
              },
            },
          },
        });
        return data.addLogPullingIntegration;
      } catch (err) {
        return err as Error;
      }
    },
    [addSalesforceLogSource]
  );

  return <SalesforceLogsSourceWizard initialValues={initialValues} onSubmit={handleSubmit} />;
};

export default compose(
  withSEO({ title: 'New Salesforce Log Source' }),
  withRoleRestrictedAccess({
    allowedPermissions: [Permission.LogSourceModify],
    fallback: <Page403 />,
  })
)(CreateSalesforceLogSource);
