/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import useForceUpdate from 'Hooks/useForceUpdate';
import ReactDOM from 'react-dom';

interface PortalProps {
  selector: string;
}

const Portal: React.FC<PortalProps> = ({ selector, children }) => {
  const getMountPoint = () => document.querySelector(selector) as HTMLElement;

  const mountpoint = React.useRef<HTMLElement>(getMountPoint());
  const forceUpdate = useForceUpdate();

  // This may look extremely weird, I know. This is here to prevent race conditions. You see, when
  // a page initially loads the DOM isn't constructed yet by React.
  //
  // This component expects a `main-header` element to be present, which will only be, if React has
  // already rendered the app. Unfortunately, React synchronizes all flushes to the DOM, so in some
  // pages, this component, will be rendered at *the same time* as the entire app.
  //
  // This means  that the mountpoint is `null` since the code in this component executes before the
  // initial flushing to the DOM has happened. To handle  such scenarios, we return early if there
  // is no mountpoint available and "force a re-render" when the DOM has been constructed (we know that
  // since effects happen after the DOM is constructed) which will  guarantee that the
  // mountpoint exists.
  //
  // This behavior only happens when the `<Breadcrumb.Actions>` renders at the same time as the
  // entire app. Sometimes though the app is already loaded when a `<Breadcrumb.Actions>` renders,
  // so to avoid un-necessarily "forcing a re-render", we use the `mountpoint ref` below with
  // certain checks to see if that needs to be done or not
  React.useLayoutEffect(() => {
    // if we originally tried and failed to mount
    if (!mountpoint.current) {
      // calculate the mountpoint element and try once again
      mountpoint.current = getMountPoint();
      forceUpdate();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return mountpoint.current ? ReactDOM.createPortal(children, mountpoint.current) : null;
};

export default Portal;
