/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Form, Formik } from 'formik';
import { AlertsInput } from 'Generated/schema';
import { Flex } from 'pouncejs';
import useRequestParamsWithoutPagination from 'Hooks/useRequestParamsWithoutPagination';

import pick from 'lodash/pick';

import FormikDateRangeInput from 'Components/fields/DateRangeInput';
import FormikAutosave from 'Components/utils/Autosave';
import Breadcrumbs from 'Components/Breadcrumbs';

export type ListAlertsFiltersValues = {
  createdAtBefore: string;
  createdAtAfter: string;
};

const filterKeys: (keyof Partial<AlertsInput>)[] = ['createdAtAfter', 'createdAtBefore'];

const ListAlertBreadcrumbFilters: React.FC = () => {
  const { requestParams, updateRequestParams } = useRequestParamsWithoutPagination<AlertsInput>();

  const initialFilterValues = React.useMemo(() => {
    return pick(requestParams, filterKeys) as ListAlertsFiltersValues;
  }, [requestParams]);

  return (
    <Breadcrumbs.Actions>
      <Flex justify="flex-end">
        <Formik<ListAlertsFiltersValues>
          enableReinitialize
          initialValues={initialFilterValues}
          onSubmit={updateRequestParams}
        >
          <Form>
            <FormikAutosave threshold={50} />
            <FormikDateRangeInput
              alignment="right"
              withPresets
              withTime
              disableReset
              variant="solid"
              format="MM/DD/YYYY HH:mm"
              labelStart="Date Start"
              labelEnd="Date End"
              placeholderStart="MM/DD/YY HH:mm"
              placeholderEnd="MM/DD/YY HH:mm"
              nameStart="createdAtAfter"
              nameEnd="createdAtBefore"
            />
          </Form>
        </Formik>
      </Flex>
    </Breadcrumbs.Actions>
  );
};

export default React.memo(ListAlertBreadcrumbFilters);
