/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Box, Flex, Img, useSnackbar } from 'pouncejs';
import { DESTINATIONS } from 'Source/constants';
import { DestinationConfigInput, DestinationTypeEnum } from 'Generated/schema';
import { BaseDestinationFormValues } from 'Components/forms/BaseDestinationForm';
import { capitalize, extractErrorMessage } from 'Helpers/utils';
import { useWizardContext, WizardPanel } from 'Components/Wizard';
import DestinationFormSwitcher from 'Components/forms/DestinationFormSwitcher';
import useRouter from 'Hooks/useRouter';
import FailureStatus from 'Assets/statuses/failure.svg';
import LinkButton from 'Components/buttons/LinkButton';
import urls from 'Source/urls';
import { useUpdateDestination } from './graphql/updateDestination.generated';
import { useGetDestinationDetails } from './graphql/getDestinationDetails.generated';
import { WizardData } from '../EditDestination';
import Skeleton from './Skeleton';

const ConfigureDestinationPanel: React.FC = () => {
  const { pushSnackbar } = useSnackbar();
  const { match: { params: { id } } } = useRouter<{ id: string }>(); // prettier-ignore
  const { updateData, goToNextStep } = useWizardContext<WizardData>();

  // If destination object exist, handleSubmit should call updateDestination and use attributes from the destination object for form initial values
  const { data: destinationData, loading } = useGetDestinationDetails({
    variables: {
      id,
    },
    onError: error => {
      pushSnackbar({
        variant: 'error',
        title:
          extractErrorMessage(error) ||
          'An unknown error has occurred while trying to update your destination',
      });
    },
  });

  // If destination object exist, handleSubmit should call updateDestination and use attributes from the destination object for form initial values
  const [updateDestination] = useUpdateDestination({
    onCompleted: data => {
      updateData({ destination: data.updateDestination });
      goToNextStep();
    },
    onError: error => {
      pushSnackbar({
        variant: 'error',
        title:
          extractErrorMessage(error) ||
          'An unknown error has occurred while trying to update your destination',
      });
    },
  });

  const destination = destinationData?.destination;
  const handleSubmit = React.useCallback(
    async (values: BaseDestinationFormValues<Partial<DestinationConfigInput>>) => {
      const { displayName, defaultForSeverity, alertTypes, outputConfig } = values;

      await updateDestination({
        variables: {
          input: {
            // static form values that are present on all Destinations
            displayName,
            defaultForSeverity,
            alertTypes,

            // needed fields from the server in order to update the selected destination
            outputId: destination.outputId,
            outputType: destination.outputType,

            // dynamic form values that depend on the selected destination
            outputConfig,
          },
        },
      });
    },
    [updateDestination, destination]
  );

  if (loading) {
    return <Skeleton />;
  }

  if (!destination) {
    return (
      <Flex direction="column" justify="center" align="center" maxWidth={800} mx="auto">
        <WizardPanel.Heading
          title="Failed to find destination"
          subtitle="We couldn't find the destination you're trying to edit"
        />
        <Img nativeWidth={120} nativeHeight={120} alt="Warning" src={FailureStatus} />
        <WizardPanel.Actions>
          <LinkButton to={urls.integrations.destinations.list()}>Back to destinations</LinkButton>
        </WizardPanel.Actions>
      </Flex>
    );
  }

  const destinationDisplayName = capitalize(
    destination.outputType === DestinationTypeEnum.Customwebhook
      ? 'Webhook'
      : destination.outputType
  );

  return (
    <Box maxWidth={800} mx="auto">
      <WizardPanel.Heading
        title={`Update Your ${destinationDisplayName} Destination`}
        subtitle="Make changes to the form below in order to update your Destination"
        logo={DESTINATIONS[destination.outputType].logo}
      />
      <DestinationFormSwitcher initialValues={destination} onSubmit={handleSubmit} />
    </Box>
  );
};

export default React.memo(ConfigureDestinationPanel);
