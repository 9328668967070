/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import withSEO from 'Hoc/withSEO';
import { compose } from 'Helpers/compose';
import withRoleRestrictedAccess from 'Hoc/withRoleRestrictedAccess';
import { ZendeskAuthorizationMethodEnum, Permission } from 'Generated/schema';
import Page403 from 'Pages/403';
import useAvailableLogTypesForLogPuller from 'Hooks/useAvailableLogTypesForLogPuller';
import ZendeskLogsSourceWizard, { ZendeskLogsSourceWizardValues } from '../ZendeskLogsSourceWizard';
import { useAddZendeskLogSource } from './graphql/addZendeskLogSource.generated';

const initialValues = {
  integrationLabel: '',
  subdomain: '',
  email: '',
  clientId: '',
  apiToken: '',
  clientSecret: '',
  password: '',
  authorizationMethod: ZendeskAuthorizationMethodEnum.Oauth2,
};

const CreateZendeskLogSource: React.FC = () => {
  const { defaultLogTypes } = useAvailableLogTypesForLogPuller();
  const [addZendeskLogSource] = useAddZendeskLogSource({
    update: (cache, { data }) => {
      cache.modify({
        fields: {
          logSources: (queryData, { toReference }) => {
            const addedIntegrationCacheRef = toReference(data.addLogPullingIntegration);
            return queryData
              ? [addedIntegrationCacheRef, ...queryData]
              : [addedIntegrationCacheRef];
          },
        },
      });
    },
  });

  const handleSubmit = React.useCallback(
    async (values: ZendeskLogsSourceWizardValues) => {
      try {
        const { data } = await addZendeskLogSource({
          variables: {
            input: {
              integrationLabel: values.integrationLabel,
              pullerConfig: {
                logTypes: defaultLogTypes,
                zendesk: {
                  subdomain: values.subdomain,
                  ...(values.authorizationMethod === ZendeskAuthorizationMethodEnum.Basic && {
                    basicCredentials: {
                      email: values.email,
                      password: values.password,
                    },
                  }),
                  ...(values.authorizationMethod === ZendeskAuthorizationMethodEnum.Oauth2 && {
                    oauth2Credentials: {
                      clientId: values.clientId,
                      clientSecret: values.clientSecret,
                    },
                  }),
                  ...(values.authorizationMethod === ZendeskAuthorizationMethodEnum.ApiToken && {
                    apiCredentials: {
                      email: values.email,
                      apiToken: values.apiToken,
                    },
                  }),

                  authorizationMethod: values.authorizationMethod,
                },
              },
            },
          },
        });

        return data.addLogPullingIntegration;
      } catch (err) {
        return err as Error;
      }
    },
    [addZendeskLogSource, defaultLogTypes]
  );

  return <ZendeskLogsSourceWizard initialValues={initialValues} onSubmit={handleSubmit} />;
};

export default compose(
  withSEO({ title: 'New Zendesk Log Source' }),
  withRoleRestrictedAccess({
    allowedPermissions: [Permission.LogSourceModify],
    fallback: <Page403 />,
  })
)(CreateZendeskLogSource);
