/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import * as Types from '../../../__generated__/schema';

import { GraphQLError } from 'graphql';
import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';

export type ListAvailableParsersForProviderVariables = {
  id: Types.Scalars['ID'];
};

export type ListAvailableParsersForProvider = {
  getAvailableLogProvider: {
    result?: Types.Maybe<
      Pick<Types.AvailableLogProvider, 'id'> & {
        availableParsers: Array<Pick<Types.AvailableLogProviderParser, 'name'>>;
        details?: Types.Maybe<Pick<Types.AvailableLogProviderDetails, 'defaultParsers'>>;
      }
    >;
    error?: Types.Maybe<Pick<Types.Error, 'message'>>;
  };
};

export const ListAvailableParsersForProviderDocument = gql`
  query ListAvailableParsersForProvider($id: ID!) {
    getAvailableLogProvider(id: $id) {
      result {
        id
        availableParsers {
          name
        }
        details {
          defaultParsers
        }
      }
      error {
        message
      }
    }
  }
`;

/**
 * __useListAvailableParsersForProvider__
 *
 * To run a query within a React component, call `useListAvailableParsersForProvider` and pass it any options that fit your needs.
 * When your component renders, `useListAvailableParsersForProvider` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListAvailableParsersForProvider({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useListAvailableParsersForProvider(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    ListAvailableParsersForProvider,
    ListAvailableParsersForProviderVariables
  >
) {
  return ApolloReactHooks.useQuery<
    ListAvailableParsersForProvider,
    ListAvailableParsersForProviderVariables
  >(ListAvailableParsersForProviderDocument, baseOptions);
}
export function useListAvailableParsersForProviderLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    ListAvailableParsersForProvider,
    ListAvailableParsersForProviderVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    ListAvailableParsersForProvider,
    ListAvailableParsersForProviderVariables
  >(ListAvailableParsersForProviderDocument, baseOptions);
}
export type ListAvailableParsersForProviderHookResult = ReturnType<
  typeof useListAvailableParsersForProvider
>;
export type ListAvailableParsersForProviderLazyQueryHookResult = ReturnType<
  typeof useListAvailableParsersForProviderLazyQuery
>;
export type ListAvailableParsersForProviderQueryResult = ApolloReactCommon.QueryResult<
  ListAvailableParsersForProvider,
  ListAvailableParsersForProviderVariables
>;
export function mockListAvailableParsersForProvider({
  data,
  variables,
  errors,
}: {
  data: ListAvailableParsersForProvider;
  variables?: ListAvailableParsersForProviderVariables;
  errors?: GraphQLError[];
}) {
  return {
    request: { query: ListAvailableParsersForProviderDocument, variables },
    result: { data, errors },
  };
}
