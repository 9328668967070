/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import PopoverAutoSubmit from 'Components/PopoverAutoSubmit';
import React from 'react';
import { FastField, Form, Formik } from 'formik';
import { Box, Button, Card, Flex, Popover, PopoverContent, PopoverTrigger } from 'pouncejs';
import { ComplianceStatusEnum, ListResourcesInput, Permission } from 'Generated/schema';
import useRequestParamsWithPagination from 'Hooks/useRequestParamsWithPagination';
import isUndefined from 'lodash/isUndefined';
import { RESOURCE_TYPES } from 'Source/constants';
import { capitalize } from 'Helpers/utils';
import TextButton from 'Components/buttons/TextButton';
import FormikCombobox from 'Components/fields/ComboBox';
import FormikMultiCombobox from 'Components/fields/MultiComboBox';

import { useListComplianceSourceNames } from 'Source/graphql/queries';
import useCheckPermissions from 'Hooks/useCheckPermissions';

export type ListResourcesDropdownFiltersValues = Pick<
  ListResourcesInput,
  'types' | 'complianceStatus' | 'integrationId'
>;

const defaultValues = {
  types: [],
  complianceStatus: null,
  integrationId: null,
};

const statusFieldItems = Object.values(ComplianceStatusEnum);
const statusFieldItemToString = (status: ComplianceStatusEnum) => capitalize(status.toLowerCase());

const DropdownFilters: React.FC = () => {
  const hasCloudAccountReadPermissions = useCheckPermissions(Permission.CloudsecSourceRead);
  const { data } = useListComplianceSourceNames({ skip: !hasCloudAccountReadPermissions });

  const { requestParams, updateRequestParamsAndResetPaging } = useRequestParamsWithPagination<
    ListResourcesInput
  >();

  const initialDropdownFilters = React.useMemo(
    () =>
      ({
        ...defaultValues,
        ...requestParams,
      } as ListResourcesDropdownFiltersValues),
    [requestParams]
  );

  const integrationIds = React.useMemo(
    () => data?.listComplianceIntegrations?.map(i => i.integrationId) ?? [],
    [data]
  );

  const integrationFieldToString = React.useCallback(
    (integrationId: string) => {
      return (
        data?.listComplianceIntegrations?.find(d => d.integrationId === integrationId)
          ?.integrationLabel ?? ''
      );
    },
    [data]
  );

  const filtersCount = Object.keys(defaultValues).filter(key => !isUndefined(requestParams[key]))
    .length;

  return (
    <Popover>
      {({ close: closePopover, isOpen }) => (
        <React.Fragment>
          <PopoverTrigger
            as={Button}
            iconAlignment="right"
            icon="filter-light"
            size="large"
            aria-label="Resource Options"
          >
            Filters {filtersCount ? `(${filtersCount})` : ''}
          </PopoverTrigger>
          <PopoverContent>
            <Card
              shadow="dark300"
              my={14}
              p={6}
              pb={4}
              backgroundColor="navyblue-400"
              width={425}
              data-testid="dropdown-resource-listing-filters"
            >
              <Formik<ListResourcesDropdownFiltersValues>
                enableReinitialize
                onSubmit={values => {
                  updateRequestParamsAndResetPaging(values);
                }}
                initialValues={initialDropdownFilters}
              >
                {({ setValues, values }) => (
                  <Form>
                    <PopoverAutoSubmit<ListResourcesDropdownFiltersValues>
                      isOpen={isOpen}
                      values={values}
                      onSubmit={updateRequestParamsAndResetPaging}
                    />
                    <Box pb={4}>
                      <FastField
                        name="types"
                        as={FormikMultiCombobox}
                        items={RESOURCE_TYPES}
                        label="Resource Types"
                        placeholder="Search for resource types..."
                        searchable
                      />
                    </Box>
                    {hasCloudAccountReadPermissions && (
                      <Box pb={4}>
                        <FastField
                          name="integrationId"
                          as={FormikCombobox}
                          items={integrationIds}
                          itemToString={integrationFieldToString}
                          label="Cloud Account"
                          placeholder="Filter by Cloud Account"
                        />
                      </Box>
                    )}
                    <Box pb={4}>
                      <FastField
                        name="complianceStatus"
                        as={FormikCombobox}
                        items={statusFieldItems}
                        itemToString={statusFieldItemToString}
                        label="Status"
                        placeholder="Select resource status"
                      />
                    </Box>

                    <Flex direction="column" justify="center" align="center" spacing={4}>
                      <Box>
                        <Button onClick={closePopover}>Apply Filters</Button>
                      </Box>
                      <TextButton
                        role="button"
                        onClick={() =>
                          setValues(defaultValues as ListResourcesDropdownFiltersValues)
                        }
                      >
                        Clear Filters
                      </TextButton>
                    </Flex>
                  </Form>
                )}
              </Formik>
            </Card>
          </PopoverContent>
        </React.Fragment>
      )}
    </Popover>
  );
};

export default React.memo(DropdownFilters);
