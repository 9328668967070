/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { FastField, Form } from 'formik';
import { Box, Flex, Grid } from 'pouncejs';
import { SortDirEnum } from 'Generated/schema';
import FormikCombobox from 'Components/fields/ComboBox';
import FormikTextInput from 'Components/fields/TextInput';

const sortDirOptions = [SortDirEnum.Descending, SortDirEnum.Ascending];
const sortDirToString = order => (order === SortDirEnum.Descending ? 'Most Recent' : 'From Oldest');

const ListSavedQueriesFilters: React.FC = () => {
  return (
    <Flex justify="flex-end" align="center" width="100%">
      <Box flexGrow={3}>
        <Form>
          <Grid templateColumns="3fr 1fr" gap={2}>
            <FastField
              name="contains"
              icon="search"
              iconAlignment="left"
              as={FormikTextInput}
              label="Filter Saved Queries by text"
              placeholder="Search for a saved query..."
            />
            <FastField
              name="sortDir"
              data-testid="list-saved-queries-sorting"
              as={FormikCombobox}
              items={sortDirOptions}
              itemToString={sortDirToString}
              label="Sort By"
            />
          </Grid>
        </Form>
      </Box>
    </Flex>
  );
};

export default React.memo(ListSavedQueriesFilters);
