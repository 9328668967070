/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Dropdown, DropdownButton, DropdownItem, DropdownMenu } from 'pouncejs';
import usePolicySuppression from 'Hooks/usePolicySuppression';
import { Permission } from 'Generated/schema';
import RoleRestrictedAccess from 'Components/utils/RoleRestrictedAccess';
import OptionsButton from 'Components/buttons/OptionsButton';
import { getOperationName } from 'apollo-utilities';
import {
  GetPolicyResources,
  GetPolicyResourcesDocument,
} from '../graphql/getPolicyResources.generated';

interface PolicyDetailsTableRowOptionsProps {
  complianceItem: GetPolicyResources['resourcesForPolicy']['items'][0];
}

const PolicyDetailsTableRowOptions: React.FC<PolicyDetailsTableRowOptionsProps> = ({
  complianceItem,
}) => {
  const { suppressPolicies } = usePolicySuppression({
    policyId: complianceItem.policyId,
    resourceId: complianceItem.resourceId,
    refetchQueries: [getOperationName(GetPolicyResourcesDocument)],
  });

  return (
    <Dropdown>
      <DropdownButton data-tid="policy-resource-options-dropdown" as={OptionsButton} />
      <DropdownMenu>
        <RoleRestrictedAccess allowedPermissions={[Permission.PolicyModify]}>
          <DropdownItem disabled={complianceItem.suppressed} onSelect={suppressPolicies}>
            Ignore
          </DropdownItem>
        </RoleRestrictedAccess>
      </DropdownMenu>
    </Dropdown>
  );
};

export default PolicyDetailsTableRowOptions;
