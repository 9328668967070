/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Box, Badge, BadgeProps, BoxProps } from 'pouncejs';

interface FixedWidthBadgeProps {
  width?: BoxProps['width'];
  color: BadgeProps['color'];
  emphasized?: BadgeProps['emphasized'];
  /**
   * @ignore
   */
  children: React.ReactNode | React.ReactNode[];
}

const FixedWidthBadge: React.FC<FixedWidthBadgeProps> = ({
  width = 70,
  color,
  emphasized = true,
  children,
}) => {
  return (
    <Box width={width}>
      <Badge color={color} stretch emphasized={emphasized} data-testid="badge">
        {children}
      </Badge>
    </Box>
  );
};

export default React.memo(FixedWidthBadge);
