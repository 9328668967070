/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import TablePlaceholder from 'Components/TablePlaceholder';
import { Card, FadeIn, Divider, Flex, Box } from 'pouncejs';

const EditLookupPageSkeleton: React.FC = () => {
  return (
    <FadeIn from="bottom">
      <Flex spacing={6}>
        <Card p={6} minWidth={320}>
          <TablePlaceholder rowCount={1} rowHeight={36} rowGap={20} />

          <Divider mt={4} mb={6} color="navyblue-300" />

          <TablePlaceholder rowCount={4} rowHeight={36} rowGap={16} />
        </Card>
        <Card p={6} width="100%">
          <Box width="20%">
            <TablePlaceholder rowCount={1} rowHeight={48} rowGap={1} />
          </Box>

          <Box width="60%">
            <TablePlaceholder rowCount={1} rowHeight={10} rowGap={1} />
          </Box>
          <Box my={7} />
          <TablePlaceholder rowCount={4} rowHeight={10} rowGap={4} />
        </Card>
      </Flex>
    </FadeIn>
  );
};

export default EditLookupPageSkeleton;
