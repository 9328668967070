/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { TextInput } from 'pouncejs';
import { useDataExplorerContext } from '../../DataExplorerContext';

const Search: React.FC = () => {
  const {
    state: { searchValue, selectedDatabase, selectedTable },
    dispatch,
  } = useDataExplorerContext();

  return (
    <TextInput
      label="Filter"
      value={searchValue}
      placeholder={selectedTable ? 'Search for a column...' : 'Search for a table...'}
      disabled={!selectedDatabase}
      onChange={e =>
        dispatch({ type: 'SEARCH_DATABASE', payload: { searchValue: e.target.value } })
      }
    />
  );
};

export default Search;
