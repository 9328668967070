/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import * as Types from '../../../__generated__/schema';

import { LookupVersionDetails } from './LookupVersionDetails.generated';
import { LookupRefreshDetails } from './LookupRefreshDetails.generated';
import { GraphQLError } from 'graphql';
import gql from 'graphql-tag';

export type LookupDetails = Pick<
  Types.Lookup,
  | 'id'
  | 'name'
  | 'description'
  | 'reference'
  | 'enabled'
  | 'managed'
  | 'createdBy'
  | 'lastModifiedBy'
  | 'createdAt'
  | 'lastModifiedAt'
> & {
  activeVersion?: Types.Maybe<LookupVersionDetails>;
  refresh?: Types.Maybe<LookupRefreshDetails>;
};

export const LookupDetails = gql`
  fragment LookupDetails on Lookup {
    id
    name
    description
    reference
    enabled
    managed
    activeVersion {
      ...LookupVersionDetails
    }
    refresh {
      ...LookupRefreshDetails
    }
    createdBy
    lastModifiedBy
    createdAt
    lastModifiedAt
  }
  ${LookupVersionDetails}
  ${LookupRefreshDetails}
`;
