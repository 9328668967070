/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Link as RRLink } from 'react-router-dom';
import { Flex, Link, Box, Card, Badge } from 'pouncejs';

import { AlertOriginRule, AlertType } from 'Generated/schema';
import SeverityBadge from 'Components/badges/SeverityBadge';
import urls from 'Source/urls';
import { AlertSummaryFull } from 'Source/graphql/fragments/AlertSummaryFull.generated';
import { formatDatetime } from 'Helpers/utils';
import { SelectCheckbox } from 'Components/utils/SelectContext';
import UpdateAlertDropdown from 'Components/dropdowns/UpdateAlertDropdown';
import LimitItemDisplay from 'Components/LimitItemDisplay';
import AdditionalBadgesJewel from 'Components/AdditionalBadgesJewel';
import { CardMetaValue, CardBulletDivider } from 'Components/cards/common';
import DestinationDeliveryWarning from '../DestinationDeliveryWarning';
import { CardVariant, CardVariantConfig } from '../../common/cardVariant';

export interface RuleAlertCardProps {
  alert: AlertSummaryFull;
  hideRuleButton?: boolean;
  selectionEnabled?: boolean;
  isSelected?: boolean;
  hideUpdateAlert?: boolean;
  variant?: CardVariant;
}

const RuleAlertCard: React.FC<RuleAlertCardProps> = ({
  alert,
  hideRuleButton = false,
  selectionEnabled = false,
  isSelected = false,
  hideUpdateAlert = false,
  variant = 'dark',
}) => {
  const originData = alert.origin as AlertOriginRule;
  const isScheduled = [AlertType.ScheduledRule, AlertType.ScheduledRuleError].includes(alert.type);
  const listItems = isScheduled ? originData.scheduledQueries : originData.logTypes;

  const variantConfig = CardVariantConfig[variant];

  return (
    <Card
      as="section"
      variant={isSelected ? variantConfig.selectedCardVariant : variantConfig.defaultCardVariant}
      p={4}
    >
      <Flex>
        {selectionEnabled && (
          <Box transform="translate3d(-12px,-9px,0)">
            <SelectCheckbox data-tid="single-select-alert" selectionItem={alert.id} />
          </Box>
        )}
        <Box width="120px">
          <SeverityBadge severity={alert.severity} />
        </Box>
        <Flex direction="column" justify="space-between" width={1}>
          <Flex as="header" align="flex-start" mb={2}>
            <Box mr="auto" maxWidth="80%">
              <Box
                as="h4"
                data-testid="single-alert-value"
                fontWeight="medium"
                wordBreak="break-word"
              >
                <Link as={RRLink} aria-label="Link to Alert" to={urls.alerts.details(alert.id)}>
                  {alert.title}
                </Link>
              </Box>
              <Flex mt={2} wrap="wrap">
                {!hideRuleButton && (
                  <>
                    <CardMetaValue
                      label={isScheduled ? 'Scheduled Rule' : 'Rule'}
                      labelColor="gray"
                      value={originData.ruleId}
                      link={urls.analysis.rules.details(originData.ruleId)}
                      data-tid="link-to-rule-from-alert-card"
                    />
                    <CardBulletDivider />
                  </>
                )}
                <CardMetaValue
                  labelColor="gray"
                  label="Date & Time"
                  value={formatDatetime(alert.createdAt)}
                />
                <CardBulletDivider />
                <CardMetaValue
                  labelColor="gray"
                  label="Events"
                  value={
                    originData?.eventsMatched ? originData?.eventsMatched.toLocaleString() : '0'
                  }
                />
              </Flex>
            </Box>
            {!hideUpdateAlert && <UpdateAlertDropdown alert={alert} />}
          </Flex>
          <Flex justify="space-between" mt={4}>
            <Flex spacing={1}>
              <LimitItemDisplay limit={4} AdditionalItemsComponent={AdditionalBadgesJewel}>
                {listItems.map(item => (
                  <Badge key={item} color="navyblue-200">
                    {item}
                  </Badge>
                ))}
              </LimitItemDisplay>
            </Flex>
            <DestinationDeliveryWarning alert={alert} />
          </Flex>
        </Flex>
      </Flex>
    </Card>
  );
};

export default React.memo(RuleAlertCard);
