/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import Editor, { EditorProps } from 'Components/Editor';
import { FieldConfig } from 'formik';
import debounce from 'lodash/debounce';
import { Box, FormError } from 'pouncejs';
import useFastField from 'Hooks/useFastField';

const FormikEditor: React.FC<EditorProps & Required<Pick<FieldConfig, 'name'>>> = props => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [field, meta, { setValue }] = useFastField(props.name);

  const isInvalid = meta.touched && !!meta.error;
  const errorElementId = isInvalid ? `${props.name}-error` : undefined;

  // For performance enhancing reasons, we are debouncing the syncing of the editor value to
  // the formik controller. The editor is *not* a controlled component by nature, so we are
  // only syncing its internal state to formik with some delays.
  // It's worth noting that this is contradictory to all the other components in the `fields`
  // folder, since they are controlled
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const syncValueFromEditor = React.useCallback(
    debounce((value: string) => {
      setValue(value);
    }, 200),
    [props.name]
  );

  return (
    <Box>
      <Editor
        {...props}
        aria-describedby={isInvalid ? errorElementId : undefined}
        onChange={syncValueFromEditor}
      />
      {isInvalid && (
        <FormError mt={2} id={errorElementId}>
          {meta.error}
        </FormError>
      )}
    </Box>
  );
};

export default FormikEditor;
