/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Text, useSnackbar } from 'pouncejs';
import { ComplianceIntegrationDetails } from 'Source/graphql/fragments/ComplianceIntegrationDetails.generated';
import StackDeletionNotice from 'Pages/Integrations/LogSources/ListLogSources/LogSourceCards/LogSourceCardOptions/StackDeletionNotice';
import { useDeleteComplianceSource } from './graphql/deleteComplianceSource.generated';
import ConfirmModal from '../ConfirmModal';

export interface DeleteComplianceSourceModalProps {
  source: ComplianceIntegrationDetails;
}

const DeleteSourceModal: React.FC<DeleteComplianceSourceModalProps> = ({ source, ...rest }) => {
  const sourceDisplayName = source.integrationLabel;
  const { pushSnackbar } = useSnackbar();
  const [deleteComplianceSource] = useDeleteComplianceSource({
    variables: {
      id: source.integrationId,
    },
    update: cache => {
      cache.evict({ id: cache.identify(source) });
      cache.gc();
    },
    onCompleted: () => {
      pushSnackbar({
        variant: 'success',
        title: `Successfully deleted Compliance source: ${sourceDisplayName}`,
      });
    },
    onError: () => {
      pushSnackbar({
        variant: 'error',
        title: `Failed to delete Compliance source:: ${sourceDisplayName}`,
      });
    },
  });

  return (
    <ConfirmModal
      subtitle={[
        <Text key={0}>
          Are you sure you want to delete <b>{sourceDisplayName}</b>?
        </Text>,
        <Text fontSize="medium" color="gray-300" mt={3} key={1}>
          <StackDeletionNotice awsAccountId={source.awsAccountId} />
        </Text>,
      ]}
      onConfirm={deleteComplianceSource}
      variant="destructive"
      {...rest}
    />
  );
};

export default DeleteSourceModal;
