/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import { useFormikContext } from 'formik';
import React from 'react';
import { Button } from 'pouncejs';
import pick from 'lodash/pick';
import { LookupFormValues } from './lookupWizardHelpers';

interface LookupWizardContinueButtonProps {
  loading?: boolean;
  onClick: () => void;
  fields?: (keyof LookupFormValues)[];
  text?: React.ReactNode;
}

const LookupWizardContinueButton: React.FC<LookupWizardContinueButtonProps> = ({
  loading = false,
  onClick,
  fields = [],
  children = 'Continue',
  ...rest
}) => {
  const dataTid = rest['data-tid'] ?? 'lookup-wizard-continue-button';
  const { validateForm, values, errors } = useFormikContext<LookupFormValues>();

  // Formik has an open issue where validation does not properly run on initial
  // values. Since the initial values of each step in the lookup wizard _can_ be
  // valid, we want to force validation to run a single time when the form initializes.
  //
  // This guarantees that the button is enabled ONLY WHEN THE FORM IS VALID, regardless
  // of whether the user has interacted with the form or not.
  // https://github.com/formium/formik/issues/1950
  React.useEffect(() => {
    validateForm(values);
    // Since we only want this to run once, we don't need any deps in the deps array.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Since each panel only contains a subset of the entire form's fields, check whether
  // validation passed on those specific fields.
  const hasValidFields = React.useMemo(() => {
    const subErrors = pick(errors, fields);
    return Object.keys(subErrors).length === 0;
  }, [errors, fields]);

  return (
    <Button
      onClick={onClick}
      loading={loading}
      disabled={!hasValidFields || loading}
      data-tid={dataTid}
    >
      {children}
    </Button>
  );
};

export default LookupWizardContinueButton;
