/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Dropdown, DropdownButton, DropdownMenu, DropdownItem, DropdownLink } from 'pouncejs';
import useModal from 'Hooks/useModal';
import urls from 'Source/urls';
import { Link as RRLink } from 'react-router-dom';
import OptionsButton from 'Components/buttons/OptionsButton';
import { SavedQueryDetails } from 'Source/graphql/fragments/SavedQueryDetails.generated';
import DeleteSavedQueriesModal from 'Components/modals/DeleteSavedQueriesModal';
import UpdateSavedQueryModal from 'Components/modals/UpdateSavedQueryModal';
import useBulkDownload from 'Hooks/useBulkDownload';
import { Permission } from 'Generated/schema';
import RoleRestrictedAccess from 'Components/utils/RoleRestrictedAccess';
import CreateSavedQueryModal from 'Components/modals/CreateSavedQueryModal';

interface SavedQueryCardOptionsProps {
  savedQuery: SavedQueryDetails;
}

const SavedQueryCardOptions: React.FC<SavedQueryCardOptionsProps> = ({ savedQuery }) => {
  const { showModal } = useModal();
  const { download } = useBulkDownload({
    fileName: savedQuery.name,
  });
  return (
    <Dropdown>
      <DropdownButton as={OptionsButton} data-testid="saved-query-options" />
      <DropdownMenu minWidth="175px">
        <DropdownLink as={RRLink} to={`${urls.data.dataExplorer()}?savedQueryId=${savedQuery.id}`}>
          View in Data Explorer
        </DropdownLink>
        <RoleRestrictedAccess allowedPermissions={[Permission.DataAnalyticsModify]}>
          <DropdownItem
            onSelect={() => {
              return showModal(
                <UpdateSavedQueryModal
                  savedQueryId={savedQuery.id}
                  sqlQuery={savedQuery.sqlQuery}
                />,
                { title: 'Update Query', showCloseButton: true }
              );
            }}
          >
            Edit Query Metadata
          </DropdownItem>
        </RoleRestrictedAccess>
        {savedQuery.managed && (
          <DropdownItem
            onSelect={() => {
              return showModal(
                <CreateSavedQueryModal sqlQuery={savedQuery.sqlQuery} clonedQuery={savedQuery} />,
                {
                  title: 'Clone Query',
                  showCloseButton: true,
                }
              );
            }}
          >
            Clone and Edit
          </DropdownItem>
        )}
        <DropdownItem onSelect={() => download({ savedQueryIds: [savedQuery.id] })}>
          Download Query
        </DropdownItem>
        <RoleRestrictedAccess allowedPermissions={[Permission.DataAnalyticsModify]}>
          <DropdownItem
            onSelect={() => {
              return showModal(<DeleteSavedQueriesModal ids={[savedQuery.id]} />, {
                title: 'Attention!',
              });
            }}
          >
            Delete Query
          </DropdownItem>
        </RoleRestrictedAccess>
      </DropdownMenu>
    </Dropdown>
  );
};

export default React.memo(SavedQueryCardOptions);
