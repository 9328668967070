/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import * as Types from '../../../../../../../__generated__/schema';

import { SalesforceLogIntegrationDetails } from '../../../../../../graphql/fragments/SalesforceLogIntegrationDetails.generated';
import { GraphQLError } from 'graphql';
import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';

export type GetSalesforceLogSourceVariables = {
  id: Types.Scalars['ID'];
};

export type GetSalesforceLogSource = { getLogPullingIntegration: SalesforceLogIntegrationDetails };

export const GetSalesforceLogSourceDocument = gql`
  query GetSalesforceLogSource($id: ID!) {
    getLogPullingIntegration(id: $id) {
      ...SalesforceLogIntegrationDetails
    }
  }
  ${SalesforceLogIntegrationDetails}
`;

/**
 * __useGetSalesforceLogSource__
 *
 * To run a query within a React component, call `useGetSalesforceLogSource` and pass it any options that fit your needs.
 * When your component renders, `useGetSalesforceLogSource` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSalesforceLogSource({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetSalesforceLogSource(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetSalesforceLogSource,
    GetSalesforceLogSourceVariables
  >
) {
  return ApolloReactHooks.useQuery<GetSalesforceLogSource, GetSalesforceLogSourceVariables>(
    GetSalesforceLogSourceDocument,
    baseOptions
  );
}
export function useGetSalesforceLogSourceLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetSalesforceLogSource,
    GetSalesforceLogSourceVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<GetSalesforceLogSource, GetSalesforceLogSourceVariables>(
    GetSalesforceLogSourceDocument,
    baseOptions
  );
}
export type GetSalesforceLogSourceHookResult = ReturnType<typeof useGetSalesforceLogSource>;
export type GetSalesforceLogSourceLazyQueryHookResult = ReturnType<
  typeof useGetSalesforceLogSourceLazyQuery
>;
export type GetSalesforceLogSourceQueryResult = ApolloReactCommon.QueryResult<
  GetSalesforceLogSource,
  GetSalesforceLogSourceVariables
>;
export function mockGetSalesforceLogSource({
  data,
  variables,
  errors,
}: {
  data: GetSalesforceLogSource;
  variables?: GetSalesforceLogSourceVariables;
  errors?: GraphQLError[];
}) {
  return {
    request: { query: GetSalesforceLogSourceDocument, variables },
    result: { data, errors },
  };
}
