/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { useSnackbar } from 'pouncejs';
import { extractErrorMessage } from 'Helpers/utils';
import { Job, JobStatus } from 'Generated/schema';
import { usePollHoldingTankJob } from './graphql/pollHoldingTankJob.generated';

function useHoldingTankJobPolling<T extends Job>(jobId: string): T {
  const { pushSnackbar } = useSnackbar();
  const { data, error, stopPolling } = usePollHoldingTankJob({
    pollInterval: 5000,
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    skip: !jobId,
    variables: {
      id: jobId,
    },
    onError: pollingError => {
      pushSnackbar({
        variant: 'error',
        title: 'Something went wrong!',
        description: extractErrorMessage(pollingError),
      });
    },
  });

  const fetchedJob = data?.holdingTankJob;
  const shouldStopPolling = fetchedJob
    ? fetchedJob?.status !== JobStatus.Running || Boolean(error)
    : false;

  React.useEffect(() => {
    if (shouldStopPolling) {
      stopPolling();
    }
  }, [stopPolling, shouldStopPolling]);

  return fetchedJob as T;
}

export default useHoldingTankJobPolling;
