/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import useRouter from 'Hooks/useRouter';
import { Alert, Box, FadeIn } from 'pouncejs';
import withSEO from 'Hoc/withSEO';
import withRoleRestrictedAccess from 'Hoc/withRoleRestrictedAccess';
import { Permission, AlertType } from 'Generated/schema';
import Page403 from 'Pages/403';
import { compose } from 'Helpers/compose';
import { extractErrorMessage, shortenId } from 'Helpers/utils';
import { useListDestinations } from 'Source/graphql/queries';
import Skeleton from './Skeleton';
import RuleAlertDetails from './RuleAlertDetails';
import PolicyAlertDetails from './PolicyAlertDetails';
import SystemErrorAlertDetails from './SystemErrorAlertDetails';
import { useAlertDetails } from './graphql/alertDetails.generated';

const AlertDetailsPage = () => {
  const { match } = useRouter<{ id: string }>();

  const { data: alertData, loading: alertLoading, error: alertError } = useAlertDetails({
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    variables: { id: match.params.id },
  });

  // FIXME: The destination information should come directly from GraphQL, by executing another
  //  query in the Front-end and using the results of both to calculate it.
  const { data: destinationData, loading: destinationLoading } = useListDestinations();

  if ((alertLoading && !alertData) || (destinationLoading && !destinationData)) {
    return (
      <FadeIn from="bottom">
        <Skeleton />
      </FadeIn>
    );
  }

  if (alertError) {
    return (
      <Box mb={6}>
        <Alert
          variant="error"
          title="Couldn't load alert"
          description={
            extractErrorMessage(alertError) ||
            "An unknown error occurred and we couldn't load the alert details from the server"
          }
        />
      </Box>
    );
  }

  const { alert } = alertData;
  switch (alert.type) {
    case AlertType.Policy:
      return <PolicyAlertDetails alert={alert} />;

    case AlertType.SystemError:
      return <SystemErrorAlertDetails alert={alert} />;

    case AlertType.Rule:
    case AlertType.ScheduledRule:
    case AlertType.RuleError:
    case AlertType.ScheduledRuleError:
    default:
      return <RuleAlertDetails alert={alert} />;
  }
};

export default compose(
  withSEO({ title: ({ match }) => `Alert #${shortenId(match.params.id)}` }),
  withRoleRestrictedAccess({
    allowedPermissions: [Permission.AlertRead],
    fallback: <Page403 />,
  })
)(AlertDetailsPage);
