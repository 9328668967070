/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Box, Flex, Heading, Card, Text, Link } from 'pouncejs';
import { Lookup } from 'Generated/schema';

interface LookupDetailsHeadingProps {
  lookup: Lookup;
}

const EnrichmentSourceDetailsHeading: React.FC<LookupDetailsHeadingProps> = ({ lookup }) => {
  return (
    <Card as="article" p={6} position="relative">
      <Flex as="header" direction="column">
        <Heading fontWeight="bold" wordBreak="break-word" flexShrink={1} mr={100}>
          {lookup.name || lookup.id}
        </Heading>
        <Text>{lookup.description}</Text>
      </Flex>
      <Flex as="dl" fontSize="small-medium" pt={5} spacing={8} wrap="wrap">
        <Flex>
          <Box color="navyblue-100" as="dt" pr={2}>
            Primary Key
          </Box>
          <Box as="dd" fontWeight="bold">
            {lookup.activeVersion.primaryKey}
          </Box>
        </Flex>
        <Flex>
          <Box color="navyblue-100" as="dt" pr={2}>
            Schema
          </Box>
          <Box as="dd" fontWeight="bold">
            <Link
              href={`/data/data-schemas/${lookup.activeVersion.logType}`}
              data-testid="schema-link"
              data-tid="schema-link"
            >
              {lookup.activeVersion.logType}
            </Link>
          </Box>
        </Flex>
        <Flex>
          <Box color="navyblue-100" as="dt" pr={2}>
            Last Received Data
          </Box>
          <Flex as="dd" align="center" spacing={6}>
            {lookup.activeVersion.refreshedAt}
          </Flex>
        </Flex>
      </Flex>
    </Card>
  );
};

export default EnrichmentSourceDetailsHeading;
