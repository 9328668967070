import { Button } from 'pouncejs';
import React from 'react';
import { useSchemaGeneration } from 'Components/utils/SchemaGenerationContext';
import ConfirmModal from 'Components/modals/ConfirmModal';
import { useFormikContext } from 'formik';
import { DataSchemaFormValues } from 'Components/forms/DataSchemaForm/DataSchemaForm';
import useModal from 'Hooks/useModal';
import { extractErrorMessage } from 'Helpers/utils';
import useUrlParams from 'Hooks/useUrlParams';
import { SampleDataUrlParams } from 'Components/forms/DataSchemaForm/SampleDataSection/SampleDataMain';

/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

const InferButton: React.FC = () => {
  const { inferringSchema, inferSchema, testSchema, sessionId, setError } = useSchemaGeneration();
  const { updateUrlParams } = useUrlParams<SampleDataUrlParams>();
  const { setFieldValue, values } = useFormikContext<DataSchemaFormValues>();
  const { showModal, hideModal } = useModal();

  const onTestSchema = React.useCallback(
    async schema => {
      try {
        const { data } = await testSchema({
          variables: {
            input: {
              sessionId,
              schema,
            },
          },
        });
        if (data.testSchema?.error) {
          setError({
            title: 'Failed to test schema',
            description: data.testSchema.error.message || 'An unknown error occurred',
          });
        } else {
          updateUrlParams({
            section: data.testSchema?.result?.stats.unmatched === 0 ? 'matched' : 'unmatched',
          });
        }
      } catch (e) {
        setError({
          title: 'Failed to test schema',
          description: extractErrorMessage(e) || 'An unknown error occurred',
        });
      }
    },
    [updateUrlParams, sessionId, setError, testSchema]
  );

  const onInferSchema = React.useCallback(async () => {
    try {
      const { data } = await inferSchema({
        variables: {
          input: {
            sessionId,
          },
        },
      });
      if (data?.inferSchema.error) {
        setError({
          title: 'Failed to infer schema',
          description: data.inferSchema.error.message,
        });
      }
      if (data?.inferSchema.schema) {
        const { schema } = data.inferSchema;
        setFieldValue('spec', schema);
        setError(null);
        onTestSchema(schema);
      }
    } catch (e) {
      setError({
        title: 'Failed to infer schema',
        description: extractErrorMessage(e),
      });
    }
  }, [sessionId, inferSchema, setFieldValue, setError, onTestSchema]);

  const onInferClick = React.useCallback(() => {
    if (values.spec) {
      showModal(
        <ConfirmModal
          variant="destructive"
          subtitle="Inferring a schema from your Sample Data will override any schema changes you already made"
          onClose={hideModal}
          onConfirm={() => {
            onInferSchema();
            hideModal();
          }}
        />
      );
    } else {
      onInferSchema();
    }
  }, [hideModal, showModal, onInferSchema, values]);

  return (
    <Button loading={inferringSchema} onClick={onInferClick} size="medium">
      Infer Schema from All Logs
    </Button>
  );
};

export default InferButton;
