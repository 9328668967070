/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Alert, Box, Flex, Img } from 'pouncejs';
import { useGetGeneralSettings } from 'Pages/GeneralSettings/graphql/getGeneralSettings.generated';
import PantherIcon from 'Assets/panther-plain-logo.svg';
import TablePlaceholder from 'Components/TablePlaceholder';
import { extractErrorMessage } from 'Helpers/utils';
import { PANTHER_CONFIG } from 'Source/constants';
import Portal from 'Components/utils/Portal';

const SettingsFooter: React.FC = () => {
  const {
    loading: getInfrastructureInfoLoading,
    error: getInfrastructureInfoError,
    data: getInfrastructureInfoData,
  } = useGetGeneralSettings();

  if (getInfrastructureInfoError) {
    return (
      <Alert
        variant="error"
        title="Failed to query for the Public VPC IP"
        description={
          extractErrorMessage(getInfrastructureInfoError) ||
          'Sorry, something went wrong, please reach out to support@runpanther.io if this problem persists'
        }
      />
    );
  }

  const { publicIp } =
    getInfrastructureInfoData?.generalSettings?.infrastructureInfo?.networking || {};

  return (
    <Portal selector="#footer">
      <Box bg="navyblue-500">
        <Flex width={1214} mx="auto" minHeight="100%" direction="column" py={6}>
          <Flex spacing={170}>
            <Box>
              <Img
                src={PantherIcon}
                alt="Panther logo"
                nativeWidth={94}
                nativeHeight={20}
                mb={3}
                display="block"
              />
            </Box>
            <Flex as="dl" spacing={9} align="center">
              <Box as="section">
                <Box id="aws_account_id" as="dt" color="navyblue-100" fontSize="small" mb={1}>
                  AWS Account ID
                </Box>
                <Box aria-labelledby="aws_account_id" as="dd" fontSize="medium">
                  {PANTHER_CONFIG.AWS_ACCOUNT_ID}
                </Box>
              </Box>
              <Box as="section">
                <Box id="panther_version" as="dt" color="navyblue-100" fontSize="small" mb={1}>
                  Version
                </Box>
                <Box aria-labelledby="panther_version" as="dd" fontSize="medium">
                  {PANTHER_CONFIG.PANTHER_VERSION} ({PANTHER_CONFIG.PANTHER_COMMIT})
                </Box>
              </Box>
              <Box as="section">
                <Box id="aws_region" as="dt" color="navyblue-100" fontSize="small" mb={1}>
                  AWS Region
                </Box>
                <Box aria-labelledby="aws_region" as="dd" fontSize="medium">
                  {PANTHER_CONFIG.AWS_REGION}
                </Box>
              </Box>
              <Box as="section">
                <Box id="vpc_public_ip" as="dt" color="navyblue-100" fontSize="small" mb={1}>
                  Gateway Public IP
                </Box>
                <Box aria-labelledby="public_vpc_ip" as="dd" fontSize="medium">
                  {getInfrastructureInfoLoading ? (
                    <TablePlaceholder rowCount={1} rowHeight={30} />
                  ) : (
                    publicIp
                  )}
                </Box>
              </Box>
            </Flex>
          </Flex>
        </Flex>
      </Box>
    </Portal>
  );
};

export default SettingsFooter;
