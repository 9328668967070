/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */
import React from 'react';
import { ButtonProps, Button } from 'pouncejs';
import LinkButtonWrapper, { LinkButtonWrapperProps } from '../utils/LinkButtonWrapper';

export type LinkButtonProps = LinkButtonWrapperProps & Omit<ButtonProps, 'as'>;

const LinkButton: React.FC<LinkButtonProps> = ({ disabled, external, to, children, ...rest }) => {
  return (
    <LinkButtonWrapper disabled={disabled} external={external} to={to}>
      <Button as="span" aria-disabled={disabled} {...rest}>
        {children}
      </Button>
    </LinkButtonWrapper>
  );
};
export default LinkButton;
