/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import * as Types from '../../../../../../../__generated__/schema';

import { CloudWatchLogIntegrationDetails } from '../../../../../../graphql/fragments/CloudwatchLogIntegrationDetails.generated';
import { GraphQLError } from 'graphql';
import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';

export type AddCloudWatchLogSourceVariables = {
  input: Types.AddCloudWatchLogIntegrationInput;
};

export type AddCloudWatchLogSource = {
  addCloudWatchLogIntegration: CloudWatchLogIntegrationDetails;
};

export const AddCloudWatchLogSourceDocument = gql`
  mutation AddCloudWatchLogSource($input: AddCloudWatchLogIntegrationInput!) {
    addCloudWatchLogIntegration(input: $input) {
      ...CloudWatchLogIntegrationDetails
    }
  }
  ${CloudWatchLogIntegrationDetails}
`;
export type AddCloudWatchLogSourceMutationFn = ApolloReactCommon.MutationFunction<
  AddCloudWatchLogSource,
  AddCloudWatchLogSourceVariables
>;

/**
 * __useAddCloudWatchLogSource__
 *
 * To run a mutation, you first call `useAddCloudWatchLogSource` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddCloudWatchLogSource` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addCloudWatchLogSource, { data, loading, error }] = useAddCloudWatchLogSource({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddCloudWatchLogSource(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    AddCloudWatchLogSource,
    AddCloudWatchLogSourceVariables
  >
) {
  return ApolloReactHooks.useMutation<AddCloudWatchLogSource, AddCloudWatchLogSourceVariables>(
    AddCloudWatchLogSourceDocument,
    baseOptions
  );
}
export type AddCloudWatchLogSourceHookResult = ReturnType<typeof useAddCloudWatchLogSource>;
export type AddCloudWatchLogSourceMutationResult = ApolloReactCommon.MutationResult<
  AddCloudWatchLogSource
>;
export type AddCloudWatchLogSourceMutationOptions = ApolloReactCommon.BaseMutationOptions<
  AddCloudWatchLogSource,
  AddCloudWatchLogSourceVariables
>;
export function mockAddCloudWatchLogSource({
  data,
  variables,
  errors,
}: {
  data: AddCloudWatchLogSource;
  variables?: AddCloudWatchLogSourceVariables;
  errors?: GraphQLError[];
}) {
  return {
    request: { query: AddCloudWatchLogSourceDocument, variables },
    result: { data, errors },
  };
}
