/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { AbstractButton } from 'pouncejs';
import { MitreTacticTechnique } from '../MitreContext';

interface MitreMatrixGridCharmButtonProps {
  onClick: (tt: MitreTacticTechnique) => void;
  children: React.ReactNode;
  selected: boolean;
  tacticTechnique: MitreTacticTechnique;
}

const MitreMatrixGridCharmButton = ({
  children,
  selected,
  tacticTechnique,
  onClick,
}: MitreMatrixGridCharmButtonProps) => {
  const handleClick = React.useCallback(() => onClick(selected ? null : tacticTechnique), [
    onClick,
    selected,
    tacticTechnique,
  ]);

  return <AbstractButton onClick={handleClick}>{children}</AbstractButton>;
};

export default React.memo(MitreMatrixGridCharmButton);
