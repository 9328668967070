/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import BaseDetectionFormMappingsSection from 'Components/forms/BaseDetectionForm/BaseDetectionFormMappingsSection';
import React from 'react';
import {
  AddRuleInput,
  DetectionReportMapping,
  DetectionTestDefinition,
  UpdateRuleInput,
} from 'Generated/schema';
import * as Yup from 'yup';
import useUrlParams from 'Hooks/useUrlParams';
import {
  Button,
  Card,
  Flex,
  Box,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  IconButton,
  Alert,
} from 'pouncejs';
import { BorderedTab, BorderTabDivider } from 'Components/BorderedTab';
import invert from 'lodash/invert';
import ErrorBoundary from 'Components/ErrorBoundary';
import Breadcrumbs from 'Components/Breadcrumbs';
import SaveButton from 'Components/buttons/SaveButton';
import { BaseDetectionFormEditorSection } from 'Components/forms/BaseDetectionForm';
import { Form, Formik } from 'formik';
import FormSessionRestoration from 'Components/utils/FormSessionRestoration';
import useRouter from 'Hooks/useRouter';
import { ENABLE_REPLAYS } from 'Source/constants';
import ReplayForm from 'Components/forms/ReplayForm';
import { RuleDetails } from 'Source/graphql/fragments/RuleDetails.generated';
import useDeleteDetectionModal from 'Hooks/useDeleteDetectionModal';
import RuleFormTestSection from './RuleFormTestSection';
import RuleFormCoreSection from './RuleFormCoreSection';
import RuleFormRequiredSection from './RuleFormRequiredSection';

export interface RuleFormUrlParams {
  section?: 'settings' | 'functions' | 'mappings';
}

const sectionToTabIndex: Record<RuleFormUrlParams['section'], number> = {
  settings: 0,
  functions: 1,
  mappings: 2,
};

const tabIndexToSection = invert(sectionToTabIndex) as Record<number, RuleFormUrlParams['section']>;

export type RuleFormValues = Omit<
  Required<AddRuleInput> | Required<UpdateRuleInput>,
  'analysisType'
>;

export type RuleFormProps = {
  /** The initial values of the form */
  initialValues: RuleFormValues;

  /** callback for the submission of the form */
  onSubmit: (values: RuleFormValues) => void;

  /** Whether it's the form for a scheduled rule or not */
  isScheduled: boolean;

  /** marks whether we're updating a previously saved Policy or not */
  isUpdate?: boolean;

  cacheSessionId?: string;
  rule?: RuleDetails;
};

const RuleForm: React.FC<RuleFormProps> = ({
  initialValues,
  onSubmit,
  isScheduled,
  cacheSessionId = 'rule-form-create',
  isUpdate = false,
  rule = {
    createdAt: null,
    createdBy: null,
    lastModified: null,
    lastModifiedBy: null,
  } as RuleDetails,
}) => {
  const { history } = useRouter();
  const showDeleteDetectionsModal = useDeleteDetectionModal();
  const { urlParams, updateUrlParams } = useUrlParams<RuleFormUrlParams>();

  const isManaged = initialValues?.managed;
  const validationSchema = React.useMemo(
    () =>
      Yup.object().shape({
        id: Yup.string().required(),
        displayName: Yup.string().required(),
        body: Yup.string().required(),
        severity: Yup.string().required(),
        dedupPeriodMinutes: Yup.number().integer(),
        threshold: Yup.number().integer().moreThan(0).required(),
        logTypes: !isScheduled
          ? Yup.array().of(Yup.string().required()).required().min(1)
          : undefined,
        scheduledQueries: isScheduled
          ? Yup.array().of(Yup.string().required()).required().min(1)
          : undefined,
        tests: Yup.array<DetectionTestDefinition>().of(
          Yup.object().shape({
            name: Yup.string().required(),
            expectedResult: Yup.boolean().required(),
            resource: Yup.string().required(),
          })
        ),
        reports: Yup.array<DetectionReportMapping>().of(
          Yup.object().shape({
            key: Yup.string().required(),
            values: Yup.array()
              .of(Yup.string())
              .required()
              .min(1, 'A report must have at least 1 value'),
          })
        ),
      }),
    [isScheduled]
  );

  return (
    <Formik<RuleFormValues>
      initialValues={initialValues}
      onSubmit={onSubmit}
      enableReinitialize
      validationSchema={validationSchema}
      validateOnChange={false}
      validateOnBlur={false}
    >
      {({ isValid }) => (
        <FormSessionRestoration sessionId={cacheSessionId}>
          {({ clearFormSession }) => (
            <Form>
              <Breadcrumbs.Actions>
                <Flex spacing={4} justify="flex-end">
                  {isUpdate && (
                    <IconButton
                      variantColor="gray-600"
                      aria-label="delete-rule"
                      icon="trash"
                      variant="outline"
                      data-tid="delete-policy"
                      onClick={() => showDeleteDetectionsModal([rule])}
                    />
                  )}
                  <Button
                    variantColor="gray-600"
                    icon="close-outline"
                    variant="outline"
                    aria-label="Cancel Rule editing"
                    onClick={() => {
                      clearFormSession();
                      history.goBack();
                    }}
                  >
                    Cancel
                  </Button>
                  <SaveButton allowPristineSubmission={!isUpdate} allowInvalidSubmission>
                    {isUpdate ? 'Update' : 'Save'}
                  </SaveButton>
                </Flex>
              </Breadcrumbs.Actions>
              {!isValid && (
                <Box mb={2}>
                  <Alert
                    variant="error"
                    title="The required fields below must be filled before saving."
                  />
                </Box>
              )}
              <RuleFormRequiredSection isScheduled={isScheduled} />
              <Card position="relative">
                <Tabs
                  index={sectionToTabIndex[urlParams.section] || 0}
                  onChange={index => updateUrlParams({ section: tabIndexToSection[index] })}
                >
                  <Box px={2}>
                    <TabList>
                      <BorderedTab>Rule Settings</BorderedTab>
                      <BorderedTab>Functions & Tests</BorderedTab>
                      <BorderedTab>Report Mapping</BorderedTab>
                    </TabList>
                  </Box>

                  <BorderTabDivider />
                  <Box p={6}>
                    <TabPanels>
                      <TabPanel data-testid="rule-settings-tabpanel" lazy>
                        <ErrorBoundary>
                          <RuleFormCoreSection
                            createdBy={rule.createdBy}
                            createdAt={rule.createdAt}
                            lastModified={rule.lastModified}
                            lastModifiedBy={rule.lastModifiedBy}
                          />
                        </ErrorBoundary>
                      </TabPanel>
                      <TabPanel data-testid="function-settings-tabpanel" lazy>
                        <Flex spacing="6" direction="column">
                          <ErrorBoundary>
                            <BaseDetectionFormEditorSection type="rule" isManaged={isManaged} />
                          </ErrorBoundary>
                          <ErrorBoundary>
                            <RuleFormTestSection />
                          </ErrorBoundary>
                          {ENABLE_REPLAYS && isUpdate && (
                            <ErrorBoundary>
                              <ReplayForm
                                detectionId={initialValues.id}
                                detectionLogTypes={initialValues.logTypes}
                              />
                            </ErrorBoundary>
                          )}
                        </Flex>
                      </TabPanel>
                      <TabPanel data-testid="rule-mapping-tabpanel" lazy>
                        <ErrorBoundary>
                          <BaseDetectionFormMappingsSection />
                        </ErrorBoundary>
                      </TabPanel>
                    </TabPanels>
                  </Box>
                </Tabs>
              </Card>
            </Form>
          )}
        </FormSessionRestoration>
      )}
    </Formik>
  );
};

export default RuleForm;
