/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Alert, Box } from 'pouncejs';
import { Permission } from 'Generated/schema';
import { extractErrorMessage } from 'Helpers/utils';
import Panel from 'Components/Panel';
import { compose } from 'Helpers/compose';
import withRoleRestrictedAccess from 'Hoc/withRoleRestrictedAccess';
import Page403 from 'Pages/403';
import ErrorBoundary from 'Components/ErrorBoundary';
import NoResultsFound from 'Components/NoResultsFound';
import withSEO from 'Hoc/withSEO';
import { useListComplianceSources } from './graphql/listComplianceSources.generated';
import EmptyDataFallback from './EmptyDataFallback';
import Skeleton from './Skeleton';
import ListComplianceSourceCards from './ListComplianceSourceCards';
import useComplianceSourceFiltering from './useComplianceSourceFiltering';
import ListComplianceSourceActions from './ListComplianceSourceActions';

const ListComplianceSources = () => {
  const { loading, error, data } = useListComplianceSources({
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
  });
  const filteredComplianceSources = useComplianceSourceFiltering(data);

  if (loading && !data) {
    return <Skeleton />;
  }

  if (error) {
    return (
      <Alert
        variant="error"
        title="Couldn't load your sources"
        description={
          extractErrorMessage(error) ||
          'There was an error when performing your request, please contact support@runpanther.io'
        }
      />
    );
  }

  if (!data.listComplianceIntegrations.length) {
    return <EmptyDataFallback />;
  }

  return (
    <Box mb={6}>
      <Panel title="Connected Accounts" actions={<ListComplianceSourceActions />}>
        <ErrorBoundary>
          {filteredComplianceSources.length ? (
            <ListComplianceSourceCards sources={filteredComplianceSources} />
          ) : (
            <Box my={8}>
              <NoResultsFound />
            </Box>
          )}
        </ErrorBoundary>
      </Panel>
    </Box>
  );
};

export default compose(
  withSEO({ title: 'Cloud Accounts' }),
  withRoleRestrictedAccess({
    allowedPermissions: [Permission.CloudsecSourceRead, Permission.CloudsecSourceModify],
    fallback: <Page403 />,
  })
)(ListComplianceSources);
