/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { useSnackbar } from 'pouncejs';
import useModal from 'Hooks/useModal';
import { APITokenDetails } from 'Source/graphql/fragments/APITokenDetails.generated';
import ConfirmModal from '../ConfirmModal';
import { useDeleteAPIToken } from './graphql/deleteAPIToken.generated';

export interface DeleteAPITokenModalProps {
  token: APITokenDetails;
}

const DeleteAPITokenModal: React.FC<DeleteAPITokenModalProps> = ({ token, ...rest }) => {
  const { pushSnackbar } = useSnackbar();
  const { hideModal } = useModal();
  const [deleteAPIToken] = useDeleteAPIToken({
    variables: {
      id: token.id,
    },
    update: async (cache, { data }) => {
      if (data.deleteAPIToken.error) {
        return;
      }

      cache.evict({ id: cache.identify(token) });
      cache.gc();
    },
    onCompleted: data => {
      hideModal();
      if (data.deleteAPIToken.error) {
        pushSnackbar({
          variant: 'error',
          title: data.deleteAPIToken.error.message,
        });
      } else {
        pushSnackbar({
          variant: 'success',
          title: `Successfully deleted ${token.name}`,
        });
      }
    },
    onError: () => {
      hideModal();
      pushSnackbar({
        variant: 'error',
        title: `Failed to delete ${token.name}`,
      });
    },
  });

  return (
    <ConfirmModal
      subtitle={[`Are you sure you want to delete `, <b key={0}>{token.name}?</b>]}
      onConfirm={deleteAPIToken}
      onClose={hideModal}
      variant="destructive"
      {...rest}
    />
  );
};

export default DeleteAPITokenModal;
