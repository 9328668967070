/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { EditorProps } from 'Components/Editor';
import useAsyncQueryContext from 'Hooks/useAsyncQueryContext';

interface UseSQLEditorCommandsProps {
  onCmdEnter: () => void;
}

const useSQLEditorCommands = ({
  onCmdEnter,
}: UseSQLEditorCommandsProps): EditorProps['commands'] => {
  const provisionQueryRef = React.useRef<() => void>();
  const {
    state: { queryStatus },
  } = useAsyncQueryContext();

  // When CMD+Enter is pressed or the "Run Query" button is clicked, the following action pair
  // is fired. You might be wondering why we put the handler in a ref and not just inline it where
  // it's used. I'll tell you why. Because of this stupid react-ace that's abandoned and is so buggy
  // and old it makes my life miserable. If I don't add a ref, the code caches whatever function
  // it got passed during mount-timee. This means that if `queryStatus` was `running` during mount-
  // time, it will always be running. The props of `AceEditor` never get updated. The only way to
  // combat that is by giving it a mutable fixed reference like we do here
  // FIXME: Using ref shouldn't be needed but it's our only option until react-ace fixes the issue
  // https://github.com/securingsincity/react-ace/issues/684
  provisionQueryRef.current = () => {
    if (queryStatus !== 'running') {
      onCmdEnter();
    }
  };

  return ({ KeyMod, KeyCode }) => [
    {
      // eslint-disable-next-line no-bitwise
      keyBindings: KeyMod.CtrlCmd | KeyCode.Enter,
      handler: onCmdEnter,
    },
  ];
};

export default useSQLEditorCommands;
