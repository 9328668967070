/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import urls from 'Source/urls';
import { Form, Formik, FastField } from 'formik';
import { SortDirEnum, ListDataModelsInput, ListDataModelsSortFieldsEnum } from 'Generated/schema';
import { Box, Flex } from 'pouncejs';
import pick from 'lodash/pick';
import {
  extractSortingOptions,
  SortingOption,
  WrapSortingFormValues,
  wrapSortingOptions,
} from 'Helpers/utils';
import useRequestParamsWithoutPagination from 'Hooks/useRequestParamsWithoutPagination';
import FormikAutosave from 'Components/utils/Autosave';
import FormikCombobox from 'Components/fields/ComboBox';
import FormikTextInput from 'Components/fields/TextInput';
import LinkButton from 'Components/buttons/LinkButton';
import DropdownFilters from './DropdownFilters';

export type ListDataModelInlineFiltersValues = Pick<
  ListDataModelsInput,
  'nameContains' | 'sortBy' | 'sortDir'
>;

const filters: (keyof ListDataModelInlineFiltersValues)[] = ['nameContains', 'sortBy', 'sortDir'];

const defaultValues = {
  nameContains: '',
  sorting: null,
};

const sortingOpts: SortingOption<ListDataModelInlineFiltersValues>[] = [
  {
    opt: 'Id (A-Z)',
    resolution: {
      sortBy: ListDataModelsSortFieldsEnum.Id,
      sortDir: SortDirEnum.Ascending,
    },
  },
  {
    opt: 'Id (Z-A)',
    resolution: {
      sortBy: ListDataModelsSortFieldsEnum.Id,
      sortDir: SortDirEnum.Descending,
    },
  },
  {
    opt: 'Most Recent',
    resolution: {
      sortBy: ListDataModelsSortFieldsEnum.LastModified,
      sortDir: SortDirEnum.Descending,
    },
  },
  {
    opt: 'Oldest',
    resolution: {
      sortBy: ListDataModelsSortFieldsEnum.LastModified,
      sortDir: SortDirEnum.Ascending,
    },
  },
];

const ListDataModelFilters: React.FC = () => {
  const { requestParams, updateRequestParams } = useRequestParamsWithoutPagination<
    ListDataModelsInput
  >();

  const initialFilterValues = React.useMemo(
    () => ({
      ...defaultValues,
      ...wrapSortingOptions(pick(requestParams, filters), sortingOpts),
    }),
    [requestParams]
  );

  return (
    <Flex justify="flex-end" align="center">
      <Formik<WrapSortingFormValues<ListDataModelInlineFiltersValues>>
        enableReinitialize
        initialValues={initialFilterValues}
        onSubmit={values => {
          updateRequestParams(extractSortingOptions(values, sortingOpts));
        }}
      >
        <Form>
          <FormikAutosave threshold={200} />
          <Flex spacing={4} align="center" pr={4}>
            <Box minWidth={385}>
              <FastField
                data-tid="data-models-nameContains-filter"
                name="nameContains"
                icon="search"
                iconAlignment="left"
                as={FormikTextInput}
                label="Filter Data Models by text"
                placeholder="Search for a data model..."
              />
            </Box>
            <Box minWidth={220}>
              <FastField
                data-tid="data-models-sorting-options-combobox"
                name="sorting"
                as={FormikCombobox}
                items={sortingOpts.map(sortingOption => sortingOption.opt)}
                label="Sort By"
                placeholder="Select a sorting option"
              />
            </Box>
          </Flex>
        </Form>
      </Formik>
      <Box pr={4}>
        <DropdownFilters />
      </Box>
      <LinkButton
        data-tid="create-new-data-model"
        title="Create new Data Model"
        to={urls.data.dataModels.create()}
      >
        Create New
      </LinkButton>
    </Flex>
  );
};

export default React.memo(ListDataModelFilters);
