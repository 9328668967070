/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { useSnackbar } from 'pouncejs';
import Page404 from 'Pages/404';
import withSEO from 'Hoc/withSEO';
import useRouter from 'Hooks/useRouter';
import withRoleRestrictedAccess from 'Hoc/withRoleRestrictedAccess';
import { compose } from 'Helpers/compose';
import { Permission } from 'Generated/schema';
import Page403 from 'Pages/403';
import { extractErrorMessage } from 'Helpers/utils';
import useAvailableLogTypesForLogPuller from 'Hooks/useAvailableLogTypesForLogPuller';
import OktaLogsSourceWizard, {
  OktaDomainsEnum,
  OktaLogsSourceWizardValues,
} from '../OktaLogsSourceWizard';
import { useGetOktaLogSource } from './graphql/getOktaLogSource.generated';
import { useUpdateOktaLogSource } from './graphql/updateOktaLogSource.generated';

const EditOktaLogSource: React.FC = () => {
  const { defaultLogTypes } = useAvailableLogTypesForLogPuller();
  const { pushSnackbar } = useSnackbar();
  const { match } = useRouter<{ id: string }>();
  const { data, error: getError } = useGetOktaLogSource({
    variables: { id: match.params.id },
    onError: error => {
      pushSnackbar({
        title: extractErrorMessage(error) || 'An unknown error occurred',
        variant: 'error',
      });
    },
  });

  const [updateOktaLogSource] = useUpdateOktaLogSource();

  function getSubdomain(url: string) {
    return url.replace('https://', '').split('.')[0];
  }

  function getDomain(url: string) {
    if (url.includes(OktaDomainsEnum.sandbox)) {
      return OktaDomainsEnum.sandbox;
    }
    if (url.includes(OktaDomainsEnum.production)) {
      return OktaDomainsEnum.production;
    }
    return null;
  }

  const initialValues = React.useMemo(
    () => ({
      integrationId: match.params.id,
      integrationLabel: data?.getLogPullingIntegration.integrationLabel ?? 'Loading...',
      logTypes: defaultLogTypes,
      apiToken: data?.getLogPullingIntegration?.pullerConfig?.okta?.apiToken ?? 'Loading...',
      subdomain: data
        ? getSubdomain(data.getLogPullingIntegration.pullerConfig.okta.domain)
        : 'Loading...',
      domain: data
        ? getDomain(data.getLogPullingIntegration.pullerConfig.okta.domain)
        : 'Loading...',
    }),
    [data, defaultLogTypes, match.params.id]
  );

  const handleSubmit = React.useCallback(
    async (values: OktaLogsSourceWizardValues) => {
      try {
        const resp = await updateOktaLogSource({
          variables: {
            input: {
              integrationId: values.integrationId,
              integrationLabel: values.integrationLabel,
              pullerConfig: {
                logTypes: values.logTypes,
                okta: {
                  domain: `https://${values.subdomain}.${values.domain}`,
                  apiToken: values.apiToken,
                },
              },
            },
          },
        });
        return resp.data.updateLogPullingIntegration;
      } catch (err) {
        return err as Error;
      }
    },
    [updateOktaLogSource]
  );

  // we optimistically assume that an error in "get" is a 404. We don't have any other info
  if (getError) {
    return <Page404 />;
  }

  return <OktaLogsSourceWizard initialValues={initialValues} onSubmit={handleSubmit} />;
};

export default compose(
  withSEO({ title: 'Edit Okta Source' }),
  withRoleRestrictedAccess({
    allowedPermissions: [Permission.LogSourceModify],
    fallback: <Page403 />,
  })
)(EditOktaLogSource);
