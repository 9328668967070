/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Flex, Icon, Text } from 'pouncejs';

interface FieldPolicyCheckProps {
  passing: boolean;
}

const FieldPolicyCheck: React.FC<FieldPolicyCheckProps> = ({ passing, children }) => {
  return (
    <Flex align="center">
      <Icon
        type={passing ? 'check-circle' : 'close-circle'}
        color={passing ? 'green-400' : 'navyblue-100'}
        size="medium"
        mr={2}
        aria-label={passing ? 'Check is passing' : 'Check is failing'}
      />
      <Text fontSize="small-medium" color={passing ? undefined : 'navyblue-100'}>
        {children}
      </Text>
    </Flex>
  );
};

export default FieldPolicyCheck;
