/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { SnackbarProvider, ThemeProvider, theme } from 'pouncejs';
import customIcons from 'Helpers/customIcons';
import { SidesheetManager, SidesheetProvider } from 'Components/utils/Sidesheet';
import { ModalManager, ModalProvider } from 'Components/utils/Modal';

const customTheme = {
  ...theme,
  icons: {
    ...theme.icons,
    ...customIcons,
  },
};
// Helper that allows us to guarantee same core providers in production & testing environments
const UIProviders: React.FC = ({ children }) => (
  <ThemeProvider theme={customTheme}>
    <SidesheetProvider>
      <ModalProvider>
        <SnackbarProvider>
          {children}
          <ModalManager />
          <SidesheetManager />
        </SnackbarProvider>
      </ModalProvider>
    </SidesheetProvider>
  </ThemeProvider>
);

export default UIProviders;
