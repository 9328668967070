/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { ReplayState } from 'Generated/schema';
import { Box, Heading, Icon, Spinner, Text } from 'pouncejs';
import { ReplayStageViewProps } from 'Components/forms/ReplayForm/ReplayStatus/ReplayStatus';

const ReplayStatusTitle: React.FC<ReplayStageViewProps> = ({ replay }) => {
  let title: string;
  let showSpinner: boolean;
  let showFailed: boolean;

  switch (!!replay) {
    case false:
      title = 'Starting Replay';
      showSpinner = true;
      break;
    default:
    case true:
      switch (replay.state) {
        case ReplayState.ComputationInProgress:
        case ReplayState.EvaluationInProgress:
          title = 'Processing Replay';
          showSpinner = true;
          break;
        case ReplayState.Unknown:
        case ReplayState.ErrorEvaluation:
        case ReplayState.ErrorComputation:
          title = 'Replay Failed';
          showFailed = true;
          break;
        case ReplayState.Canceled:
          title = 'Replay Canceled';
          showFailed = true;
          break;
        case ReplayState.Done:
        default:
          title = 'Replay Complete';
      }
  }

  return (
    <>
      {showSpinner && (
        <Box mr={2}>
          <Spinner size="small" />
        </Box>
      )}
      {showFailed && (
        <Box mr={2}>
          <Icon type="close-circle" color="red-400" />
        </Box>
      )}
      <Heading size="x-small">{title}</Heading>
      {replay && (
        <Text ml={3} fontSize="medium" color="gray-500" title={replay?.id}>
          #{replay?.id.split('-')[0] || '00000000'}
        </Text>
      )}
    </>
  );
};

export default ReplayStatusTitle;
