/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Box } from 'pouncejs';

const BorderTabDivider: React.FC = () => {
  return (
    <Box
      position="absolute"
      width="100%"
      height={1}
      backgroundColor="navyblue-300"
      zIndex={0}
      left={0}
      marginTop="-2px"
    />
  );
};

export default BorderTabDivider;
