/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Box, Checkbox, FormHelperText } from 'pouncejs';
import { Permission } from 'Generated/schema';

const PermissionItem: React.FC<{
  id: Permission;
  title: string;
  description: string;
  checked: boolean;
  onSelect: (id: Permission) => void;
}> = ({ id, title, description, checked, onSelect, children }) => {
  return (
    <Box as="fieldset" key={id}>
      <Checkbox
        aria-describedby={`${id}-description`}
        aria-label={`Toggle ${title} permission`}
        label={title}
        checked={checked}
        onChange={() => onSelect(id)}
      />
      <FormHelperText id={`${id}-description`} ml={2}>
        {description}
      </FormHelperText>
      {children}
    </Box>
  );
};

export default PermissionItem;
