/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import * as Types from '../../../__generated__/schema';

import { ActorId_User_, ActorId_APIToken_ } from './ActorId.generated';
import { IntegrationItemHealthDetails } from './IntegrationItemHealthDetails.generated';
import { GraphQLError } from 'graphql';
import gql from 'graphql-tag';
import { ActorId } from './ActorId.generated';

export type ComplianceIntegrationDetails = Pick<
  Types.ComplianceIntegration,
  | 'integrationId'
  | 'integrationLabel'
  | 'awsAccountId'
  | 'createdAtTime'
  | 'stackName'
  | 'regionIgnoreList'
  | 'resourceTypeIgnoreList'
  | 'resourceRegexIgnoreList'
  | 'realtimeScanningEnabled'
  | 'isEditable'
  | 'isPantherManaged'
> & {
  createdBy?: Types.Maybe<ActorId_User_ | ActorId_APIToken_>;
  health: {
    auditRoleStatus: IntegrationItemHealthDetails;
    realtimeStatus: IntegrationItemHealthDetails;
  };
  awsScanConfig?: Types.Maybe<Pick<Types.AWSScanConfig, 'auditRole'>>;
};

export const ComplianceIntegrationDetails = gql`
  fragment ComplianceIntegrationDetails on ComplianceIntegration {
    integrationId
    integrationLabel
    awsAccountId
    createdAtTime
    createdBy {
      ...ActorId
    }
    stackName
    regionIgnoreList
    resourceTypeIgnoreList
    resourceRegexIgnoreList
    realtimeScanningEnabled
    health {
      auditRoleStatus {
        ...IntegrationItemHealthDetails
      }
      realtimeStatus {
        ...IntegrationItemHealthDetails
      }
    }
    awsScanConfig {
      auditRole
    }
    isEditable
    isPantherManaged
  }
  ${ActorId}
  ${IntegrationItemHealthDetails}
`;
