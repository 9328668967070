/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Alert, Box, Card, Flex } from 'pouncejs';
import { extractErrorMessage } from 'Helpers/utils';
import { Detection, DetectionTypeEnum, Permission } from 'Generated/schema';
import { TableControlsPagination } from 'Components/utils/TableControls';
import { compose } from 'Helpers/compose';
import withRoleRestrictedAccess from 'Hoc/withRoleRestrictedAccess';
import Page403 from 'Pages/403';
import ErrorBoundary from 'Components/ErrorBoundary';
import NoResultsFound from 'Components/NoResultsFound';
import withSEO from 'Hoc/withSEO';
import Panel from 'Components/Panel';
import RuleCard from 'Components/cards/RuleCard';
import PolicyCard from 'Components/cards/PolicyCard';
import { RuleSummary } from 'Source/graphql/fragments/RuleSummary.generated';
import { useSelect, withSelectContext, SelectAllCheckbox } from 'Components/utils/SelectContext';
import { PolicySummary } from 'Source/graphql/fragments/PolicySummary.generated';
import useOverrideShiftClickSelection from 'Hooks/useOverrideShiftClickSelection';
import useListDetectionsWithRequestParams from 'Hooks/useListDetectionsWithRequestParams';
import ListDetectionsPageSkeleton from './Skeleton';
import ListDetectionsPageEmptyDataFallback from './EmptyDataFallback';
import ListDetectionsActions from './ListDetectionsActions';

const ListDetections = () => {
  useOverrideShiftClickSelection();
  const { checkIfSelected, deselectItem, setAllSelectables } = useSelect<Detection>();

  const {
    loading,
    error,
    previousData,
    data = previousData,
    hasFiltersSet,
    updatePagingParams,
  } = useListDetectionsWithRequestParams({
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
  });

  // Shared callback function for deselecting detections, the added
  // complexity here avoids negating the benefits of memoized components.
  const deselectDetection = React.useCallback(
    (id: string) => {
      const targetDetection = data.detections.detections.find(x => x.id === id) as Detection;

      if (targetDetection) {
        deselectItem(targetDetection);
      }
    },
    // FIXME: Look into missing effect dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data]
  );

  React.useEffect(() => {
    const detections = data?.detections.detections || [];
    setAllSelectables(detections as Detection[]);
  }, [data, setAllSelectables]);

  if (loading && !data) {
    return <ListDetectionsPageSkeleton />;
  }

  if (error) {
    return (
      <Box mb={6}>
        <Alert
          variant="error"
          title="Couldn't load your detections"
          description={
            extractErrorMessage(error) ||
            'There was an error when performing your request, please contact support@runpanther.io'
          }
        />
      </Box>
    );
  }

  // Get query results while protecting against exceptions
  const detectionItems = data.detections.detections;
  const pagingData = data.detections.paging;

  if (!detectionItems.length && !hasFiltersSet) {
    return <ListDetectionsPageEmptyDataFallback />;
  }

  //  Check how many active filters exist by checking how many columns keys exist in the URL
  return (
    <ErrorBoundary>
      <Panel
        data-tracking-page="list-detections"
        title={
          <Flex align="center" spacing={3} ml={1}>
            <SelectAllCheckbox data-tid="detections-select-all" selectionItems={detectionItems} />
            <Box as="span">Detections</Box>
          </Flex>
        }
        actions={<ListDetectionsActions />}
      >
        <Card as="section" position="relative">
          <Box position="relative">
            <Flex direction="column" spacing={2}>
              {detectionItems.length ? (
                detectionItems.map(detection => {
                  switch (detection.analysisType) {
                    case DetectionTypeEnum.Rule:
                    case DetectionTypeEnum.ScheduledRule:
                      return (
                        <RuleCard
                          key={detection.id}
                          rule={detection as RuleSummary}
                          onDelete={deselectDetection}
                          selectionEnabled
                          isSelected={checkIfSelected(detection as Detection)}
                        />
                      );
                    case DetectionTypeEnum.Policy:
                      return (
                        <PolicyCard
                          key={detection.id}
                          policy={detection as PolicySummary}
                          onDelete={deselectDetection}
                          selectionEnabled
                          isSelected={checkIfSelected(detection as Detection)}
                        />
                      );
                    default:
                      return null;
                  }
                })
              ) : (
                <Box my={8}>
                  <NoResultsFound />
                </Box>
              )}
            </Flex>
          </Box>
        </Card>
      </Panel>
      <Box my={5}>
        <TableControlsPagination
          page={pagingData.thisPage}
          totalPages={pagingData.totalPages}
          onPageChange={updatePagingParams}
        />
      </Box>
    </ErrorBoundary>
  );
};

export default compose(
  withSEO({ title: 'Detections' }),
  withRoleRestrictedAccess({
    allowedPermissions: [
      Permission.RuleRead,
      Permission.RuleModify,
      Permission.PolicyRead,
      Permission.PolicyModify,
    ],
    fallback: <Page403 />,
  }),
  withSelectContext()
)(ListDetections);
