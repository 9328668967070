/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Lookup, UpdateLookupInput } from 'Generated/schema';
import { Box, Alert, Card, Flex, Heading, useTheme, Button, useSnackbar } from 'pouncejs';
import { extractErrorMessage } from 'Helpers/utils';
import { Formik, Form } from 'formik';
import { useUpdateLookup } from 'Source/graphql/queries/updateLookup.generated';
import useRouter from 'Hooks/useRouter';
import { useGetLookup } from '../../Lookups/graphql/getLookup.generated';
import Skeleton from './Skeleton';
import EnrichmentSourceDetailsHeading from './EnrichmentSourceDetailsHeading';
import ActionBar from './ActionBar';
import TruncationControl from './TruncationControl';
import EditEnrichedLogTypeForm from '../EditEnrichedLogTypeForm';
import DetailsTable from './DetailsTable';
import { lookupFormValidationSchema, EnrichedLogTypeFormValues } from '../utils';

export interface LookupDetailsUrlParams {
  id: Lookup['id'];
}

const TRUNCATED_LIMIT = 6;

const EnrichmentSourceDetails = () => {
  const theme = useTheme();
  const { pushSnackbar } = useSnackbar();
  const { match } = useRouter<LookupDetailsUrlParams>();

  const { data, loading: isFetchingLookup, error: fetchLookupError } = useGetLookup({
    variables: {
      id: match.params.id,
    },
  });

  const [isTruncated, setIsTruncated] = React.useState(true);
  const [isEditing, setIsEditing] = React.useState(false);
  const lookup = data?.getLookup;
  const associatedLogTypes = data?.getLookup.activeVersion.associatedLogTypes;
  const initialValues = React.useMemo(() => ({ associatedLogTypes }), [associatedLogTypes]);

  const enrichedLogTypes = React.useMemo(() => {
    const shouldTruncateAssociatedLogTypes =
      associatedLogTypes?.length >= TRUNCATED_LIMIT && isTruncated;

    return shouldTruncateAssociatedLogTypes
      ? associatedLogTypes.slice(0, TRUNCATED_LIMIT)
      : associatedLogTypes;
  }, [isTruncated, associatedLogTypes]);

  const [updateLookup, { loading }] = useUpdateLookup({
    onCompleted: response => {
      pushSnackbar({
        variant: 'success',
        title: `Updated lookup [${response.updateLookup.name}] successfully`,
      });
      setIsEditing(false);
    },
    onError: err => {
      pushSnackbar({
        variant: 'error',
        title: "Couldn't update lookup source",
        description: extractErrorMessage(err) || 'Some unknown error occurred',
      });
    },
  });

  const handleSubmit = React.useCallback(
    (values: EnrichedLogTypeFormValues) => {
      const { id, name, description, enabled, reference, refresh, activeVersion } = lookup;
      const updateLookupInput: UpdateLookupInput = {
        id,
        name,
        description,
        enabled,
        logTypeMap: {
          primaryKey: activeVersion.primaryKey,
          associatedLogTypes: values.associatedLogTypes,
        },
        lookupSchema: {
          logType: activeVersion.logType,
        },
        reference,
        refresh,
      };

      updateLookup({
        variables: {
          input: updateLookupInput,
        },
      });
    },
    [updateLookup, lookup]
  );

  if (isFetchingLookup) {
    return <Skeleton />;
  }

  if (fetchLookupError) {
    return (
      <Box mb={6}>
        <Alert
          variant="error"
          title="Couldn't load your lookup"
          description={
            extractErrorMessage(fetchLookupError) ||
            'There was an error when performing your request, please contact support@runpanther.io'
          }
        />
      </Box>
    );
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={lookupFormValidationSchema}
      onSubmit={handleSubmit}
    >
      <Form>
        <Flex direction="column" spacing={6} position="relative">
          <ActionBar lookup={lookup} />

          <EnrichmentSourceDetailsHeading lookup={lookup} />
          <Card>
            <Flex
              alignItems="center"
              p={6}
              borderBottom={`1px solid ${theme.colors['navyblue-200']}`}
            >
              <Heading size="x-small" as="h4">
                Enriched Log Types ({lookup.activeVersion.associatedLogTypes.length})
              </Heading>
              <Flex ml="auto">
                {isEditing ? (
                  <Flex>
                    <Box>
                      <Button
                        icon="close-outline"
                        data-testid="cancel-edit-source-details"
                        style={{ borderRadius: '4px' }}
                        variantColor="gray-600"
                        onClick={() => setIsEditing(false)}
                      >
                        Cancel
                      </Button>
                    </Box>
                    <Box ml="4">
                      <Button
                        data-testid="save-edit-source-details"
                        variantColor="green-400"
                        loading={loading}
                        icon="save"
                        aria-label="Save"
                        type="submit"
                      >
                        Save
                      </Button>
                    </Box>
                  </Flex>
                ) : (
                  <Button
                    data-testid="edit-source-details"
                    onClick={() => setIsEditing(!isEditing)}
                  >
                    Edit
                  </Button>
                )}
              </Flex>
            </Flex>
            <Box p={6} backgroundColor="navyblue-400">
              {isEditing ? (
                <EditEnrichedLogTypeForm />
              ) : (
                <DetailsTable enrichedLogTypes={enrichedLogTypes} />
              )}
              {!isEditing && associatedLogTypes.length > TRUNCATED_LIMIT && (
                <TruncationControl
                  listLength={enrichedLogTypes.length}
                  handleClick={() => setIsTruncated(!isTruncated)}
                  isTruncated={isTruncated}
                />
              )}
            </Box>
          </Card>
        </Flex>
      </Form>
    </Formik>
  );
};

export default EnrichmentSourceDetails;
