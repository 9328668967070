/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import { Box, TabPanel, TabPanels, Tabs, TabList, Text, Link, Flex } from 'pouncejs';
import EditProfileForm from 'Components/forms/EditProfileForm';
import ChangePasswordForm from 'Components/forms/ChangePasswordForm';
import React from 'react';
import useAuth from 'Hooks/useAuth';
import { getActorDisplayName } from 'Helpers/utils';
import { BorderedTab, BorderTabDivider } from 'Components/BorderedTab';
import useModal from 'Hooks/useModal';

const ProfileSettingsModal: React.FC = () => {
  const { userInfo } = useAuth();
  const { hideModal } = useModal();
  return (
    <Box width={450}>
      <Tabs>
        <Box mt={-8} mx={-6}>
          <TabList>
            <BorderedTab>Profile Settings</BorderedTab>
            <BorderedTab>Account Security</BorderedTab>
          </TabList>
          <BorderTabDivider />
        </Box>
        <Box px={8} mt={8}>
          <TabPanels>
            <TabPanel>
              <Flex as="section" direction="column" align="center" mb={6}>
                <Text fontSize="small" color="gray-300" mb={3}>
                  logged in as
                </Text>
                <Text fontSize="medium">{getActorDisplayName(userInfo)}</Text>
                {!!userInfo && (
                  <Link fontSize="medium" external href={`mailto:${userInfo.email}`}>
                    {userInfo.email}
                  </Link>
                )}
              </Flex>
              <EditProfileForm onSuccess={hideModal} />
            </TabPanel>
            <TabPanel>
              <ChangePasswordForm onSuccess={hideModal} />
            </TabPanel>
          </TabPanels>
        </Box>
      </Tabs>
    </Box>
  );
};

export default ProfileSettingsModal;
