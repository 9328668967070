/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Box } from 'pouncejs';
import TablePlaceholder from 'Components/TablePlaceholder';

const MitreRelationsDetectionsSkeleton = ({ count }: { count: number }) => {
  return (
    <Box minHeight={140} pt={8}>
      <TablePlaceholder rowCount={count} rowHeight={36} />
    </Box>
  );
};

export default MitreRelationsDetectionsSkeleton;
