/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { useSnackbar } from 'pouncejs';
import Page404 from 'Pages/404';
import withSEO from 'Hoc/withSEO';
import useRouter from 'Hooks/useRouter';
import withRoleRestrictedAccess from 'Hoc/withRoleRestrictedAccess';
import { compose } from 'Helpers/compose';
import { Permission } from 'Generated/schema';
import Page403 from 'Pages/403';
import { extractErrorMessage } from 'Helpers/utils';
import AtlassianLogSourceWizard, {
  AtlassianLogSourceWizardValues,
} from '../AtlassianLogSourceWizard';
import { useGetAtlassianLogSource } from './graphql/getAtlassianLogSource.generated';
import { useUpdateAtlassianLogSource } from './graphql/updateAtlassianLogSource.generated';

const EditAtlassianLogSource: React.FC = () => {
  const { pushSnackbar } = useSnackbar();
  const { match } = useRouter<{ id: string }>();
  const { data, error: getError } = useGetAtlassianLogSource({
    variables: { id: match.params.id },
    onError: error => {
      pushSnackbar({
        title: extractErrorMessage(error) || 'An unknown error occurred',
        variant: 'error',
      });
    },
  });

  const [updateAtlassianLogSource] = useUpdateAtlassianLogSource();

  const initialValues = React.useMemo(
    () => ({
      integrationId: match.params.id,
      logTypes: data?.getLogPullingIntegration?.logTypes ?? [],
      integrationLabel: data?.getLogPullingIntegration.integrationLabel ?? 'Loading...',
      apiKey: data?.getLogPullingIntegration?.pullerConfig?.atlassian?.apiKey ?? 'Loading...',
      organization:
        data?.getLogPullingIntegration?.pullerConfig?.atlassian?.organization ?? 'Loading...',
    }),
    [data, match]
  );

  const handleSubmit = React.useCallback(
    async (values: AtlassianLogSourceWizardValues) => {
      try {
        const res = await updateAtlassianLogSource({
          variables: {
            input: {
              integrationId: values.integrationId,
              integrationLabel: values.integrationLabel,
              pullerConfig: {
                logTypes: values.logTypes,
                atlassian: {
                  apiKey: values.apiKey,
                  organization: values.organization,
                },
              },
            },
          },
        });
        return res.data.updateLogPullingIntegration;
      } catch (err) {
        return err as Error;
      }
    },
    [updateAtlassianLogSource]
  );

  // we optimistically assume that an error in "get" is a 404. We don't have any other info
  if (getError) {
    return <Page404 />;
  }

  return <AtlassianLogSourceWizard initialValues={initialValues} onSubmit={handleSubmit} />;
};

export default compose(
  withSEO({ title: 'Edit Atlassian Source' }),
  withRoleRestrictedAccess({
    allowedPermissions: [Permission.LogSourceModify],
    fallback: <Page403 />,
  })
)(EditAtlassianLogSource);
