/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import * as Types from '../../../../__generated__/schema';

import { DataSchemaFull } from '../../../graphql/fragments/DataSchemaFull.generated';
import { GraphQLError } from 'graphql';
import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';

export type ToggleDataSchemaVariables = {
  input: Types.ToggleSchemaInput;
};

export type ToggleDataSchema = {
  toggleSchema: {
    error?: Types.Maybe<Pick<Types.Error, 'code' | 'message'>>;
    record?: Types.Maybe<DataSchemaFull>;
  };
};

export const ToggleDataSchemaDocument = gql`
  mutation ToggleDataSchema($input: ToggleSchemaInput!) {
    toggleSchema(input: $input) {
      error {
        code
        message
      }
      record {
        ...DataSchemaFull
      }
    }
  }
  ${DataSchemaFull}
`;
export type ToggleDataSchemaMutationFn = ApolloReactCommon.MutationFunction<
  ToggleDataSchema,
  ToggleDataSchemaVariables
>;

/**
 * __useToggleDataSchema__
 *
 * To run a mutation, you first call `useToggleDataSchema` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleDataSchema` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleDataSchema, { data, loading, error }] = useToggleDataSchema({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useToggleDataSchema(
  baseOptions?: ApolloReactHooks.MutationHookOptions<ToggleDataSchema, ToggleDataSchemaVariables>
) {
  return ApolloReactHooks.useMutation<ToggleDataSchema, ToggleDataSchemaVariables>(
    ToggleDataSchemaDocument,
    baseOptions
  );
}
export type ToggleDataSchemaHookResult = ReturnType<typeof useToggleDataSchema>;
export type ToggleDataSchemaMutationResult = ApolloReactCommon.MutationResult<ToggleDataSchema>;
export type ToggleDataSchemaMutationOptions = ApolloReactCommon.BaseMutationOptions<
  ToggleDataSchema,
  ToggleDataSchemaVariables
>;
export function mockToggleDataSchema({
  data,
  variables,
  errors,
}: {
  data: ToggleDataSchema;
  variables?: ToggleDataSchemaVariables;
  errors?: GraphQLError[];
}) {
  return {
    request: { query: ToggleDataSchemaDocument, variables },
    result: { data, errors },
  };
}
