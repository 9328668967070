/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import AlertCard from 'Components/cards/AlertCard';
import ErrorBoundary from 'Components/ErrorBoundary';
import NoResultsFound from 'Components/NoResultsFound';
import SourceHealthWarning from 'Components/SourceHealthWarning';
import TablePlaceholder from 'Components/TablePlaceholder';
import FormikAutosave from 'Components/utils/Autosave';
import { Form, Formik } from 'formik';
import {
  AlertListingType,
  AlertsInput,
  AlertsSortFieldsEnum,
  AlertStatus,
  AlertType,
  IntegrationItemHealthStatus,
  SortDirEnum,
} from 'Generated/schema';
import {
  extractErrorMessage,
  extractSortingOptions,
  getGraphqlSafeDateRange,
  WrapSortingFormValues,
  wrapSortingOptions,
} from 'Helpers/utils';
import useInfiniteScroll from 'Hooks/useInfiniteScroll';
import { Alert, Box, Flex } from 'pouncejs';
import React from 'react';
import { DEFAULT_LARGE_PAGE_SIZE, ENABLE_ALERTS_GSI_LIST } from 'Source/constants';
import ListSystemErrorFilters, {
  ListSystemErrorsFiltersValues,
  sortingOpts,
} from './ListSystemErrorFilters';
import { useListAlerts } from './graphql/listAlerts.generated';

const defaultValues: AlertsInput = {
  nameContains: '',
  sortBy: AlertsSortFieldsEnum.CreatedAt,
  sortDir: SortDirEnum.Descending,
  statuses: Object.values(AlertStatus),
};

interface HealthPanelProps {
  integrationId: string;
  unhealthyMetrics: IntegrationItemHealthStatus[];
}
const HealthPanel = ({ integrationId, unhealthyMetrics }: HealthPanelProps) => {
  const [utcDaysAgo, utcNow] = getGraphqlSafeDateRange({ days: 7 });
  const [filters, setFilters] = React.useState<AlertsInput>({
    ...defaultValues,
    createdAtAfter: utcDaysAgo,
    createdAtBefore: utcNow,
  });
  const { loading, error, previousData, data = previousData, fetchMore } = useListAlerts({
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
    variables: {
      input: {
        ...filters,
        logSources: [integrationId],
        type: ENABLE_ALERTS_GSI_LIST ? AlertListingType.SystemError : undefined,
        types: ENABLE_ALERTS_GSI_LIST ? undefined : [AlertType.SystemError],
        pageSize: DEFAULT_LARGE_PAGE_SIZE,
      },
    },
  });

  const { sentinelRef } = useInfiniteScroll<HTMLDivElement>({
    loading,
    threshold: 500,
    onLoadMore: () => {
      fetchMore({
        variables: {
          input: {
            ...filters,
            type: ENABLE_ALERTS_GSI_LIST ? AlertListingType.SystemError : undefined,
            types: ENABLE_ALERTS_GSI_LIST ? undefined : [AlertType.SystemError],
            logSources: [integrationId],
            pageSize: DEFAULT_LARGE_PAGE_SIZE,
            cursor: data?.alerts.pageInfo.endCursor ?? null,
          },
        },
      });
    },
  });

  const hasNextPage = data?.alerts?.pageInfo.hasNextPage;
  const hasError = Boolean(error);
  const alerts = React.useMemo(() => data?.alerts.edges.map(edge => edge.node) || [], [data]);

  return (
    <Formik<WrapSortingFormValues<ListSystemErrorsFiltersValues>>
      enableReinitialize
      initialValues={wrapSortingOptions(filters, sortingOpts)}
      onSubmit={values => {
        setFilters(extractSortingOptions(values, sortingOpts));
      }}
    >
      <Form>
        <Flex direction="column" spacing={4}>
          <ErrorBoundary>
            <FormikAutosave threshold={200} />
            {unhealthyMetrics.length > 0 ? (
              <Box>
                <SourceHealthWarning
                  unhealthyMetrics={unhealthyMetrics}
                  title="Source has turned Unhealthy"
                  integrationId={integrationId}
                />
              </Box>
            ) : (
              <Alert
                variant="success"
                title="Source is healthy"
                // TODO: modify Alert props in Pounce
                {...{ backgroundColor: 'navyblue-600', color: 'green-300' }}
              />
            )}
            <ListSystemErrorFilters />
            {hasError ? (
              <Box mb={6}>
                <Alert
                  variant="error"
                  title="Couldn't load System Errors"
                  description={
                    extractErrorMessage(error) ||
                    'There was an error when performing your request, please contact support@runpanther.io'
                  }
                />
              </Box>
            ) : (
              <Box>
                {loading && !alerts.length ? (
                  <TablePlaceholder rowCount={3} rowHeight={52} />
                ) : (
                  <Box position="relative">
                    <Flex direction="column" spacing={2}>
                      {alerts.length ? (
                        alerts.map(alert => <AlertCard key={alert.id} alert={alert} />)
                      ) : (
                        <Box my={8}>
                          <NoResultsFound />
                        </Box>
                      )}
                    </Flex>
                    {hasNextPage && (
                      <Box py={8} ref={sentinelRef}>
                        <TablePlaceholder rowCount={10} />
                      </Box>
                    )}
                  </Box>
                )}
              </Box>
            )}
          </ErrorBoundary>
        </Flex>
      </Form>
    </Formik>
  );
};

export default HealthPanel;
