/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Box, Combobox } from 'pouncejs';

import { useMitreContext } from 'Pages/Reports/MitreMatrix/MitreContext';

export type MatrixTreeNode = {
  id: string;
  name: string;
  submatrices?: MatrixTreeNode[];
};

type NodeItem = {
  id: string;
  name: string;
  parentNames: string[];
};

type SelectItem = {
  id: string;
  name: string;
  label: string;
  parentNames: string[];
};

export function parseTree(matrices: MatrixTreeNode[]): NodeItem[] {
  const output: NodeItem[] = [];

  function parseNode(node: MatrixTreeNode, parents: MatrixTreeNode[]) {
    output.push({
      id: node.id,
      name: node.name,
      parentNames: parents.map(o => o.name),
    });
    (node.submatrices || []).forEach(n => parseNode(n, [...parents, node]));
  }

  matrices.forEach(n => parseNode(n, []));

  return output;
}

const itemToString = (obj?: SelectItem) => obj?.label || '';

const MitreMatrixSwitcher: React.FC = () => {
  const { treeNodes: nodes, activeMatrixId, switchMatrix } = useMitreContext();

  // this formats each matrix with its parent names. eg: Parent Matrix / Child Matrix
  const items = React.useMemo<SelectItem[]>(
    () => parseTree(nodes).map(n => ({ ...n, label: [...n.parentNames, n.name].join(' / ') })),
    [nodes]
  );
  const selected = React.useMemo<SelectItem>(() => items.find(o => o.id === activeMatrixId), [
    items,
    activeMatrixId,
  ]);

  return (
    <Box width="475px">
      {items.length && selected && (
        <Combobox<SelectItem>
          value={selected}
          label="Matrix"
          items={items}
          maxHeight={600}
          onChange={({ id }) => switchMatrix(id)}
          itemToString={itemToString}
          showClearSelectionControl={false}
        />
      )}
    </Box>
  );
};

export default React.memo(MitreMatrixSwitcher);
