/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import * as Types from '../../../../../__generated__/schema';

import { GraphQLError } from 'graphql';
import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';

export type UpdateMitreMappingVariables = {
  input: Types.UpdateMitreReportMappingInput;
};

export type UpdateMitreMapping = {
  updateMitreReportMapping: {
    detections: Array<
      | (Pick<Types.Rule, 'id'> & {
          reports: Array<Pick<Types.DetectionReportMapping, 'key' | 'values'>>;
        })
      | (Pick<Types.Policy, 'id'> & {
          reports: Array<Pick<Types.DetectionReportMapping, 'key' | 'values'>>;
        })
    >;
  };
};

export const UpdateMitreMappingDocument = gql`
  mutation UpdateMitreMapping($input: UpdateMitreReportMappingInput!) {
    updateMitreReportMapping(input: $input) {
      detections {
        id
        reports {
          key
          values
        }
      }
    }
  }
`;
export type UpdateMitreMappingMutationFn = ApolloReactCommon.MutationFunction<
  UpdateMitreMapping,
  UpdateMitreMappingVariables
>;

/**
 * __useUpdateMitreMapping__
 *
 * To run a mutation, you first call `useUpdateMitreMapping` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMitreMapping` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMitreMapping, { data, loading, error }] = useUpdateMitreMapping({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateMitreMapping(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateMitreMapping,
    UpdateMitreMappingVariables
  >
) {
  return ApolloReactHooks.useMutation<UpdateMitreMapping, UpdateMitreMappingVariables>(
    UpdateMitreMappingDocument,
    baseOptions
  );
}
export type UpdateMitreMappingHookResult = ReturnType<typeof useUpdateMitreMapping>;
export type UpdateMitreMappingMutationResult = ApolloReactCommon.MutationResult<UpdateMitreMapping>;
export type UpdateMitreMappingMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateMitreMapping,
  UpdateMitreMappingVariables
>;
export function mockUpdateMitreMapping({
  data,
  variables,
  errors,
}: {
  data: UpdateMitreMapping;
  variables?: UpdateMitreMappingVariables;
  errors?: GraphQLError[];
}) {
  return {
    request: { query: UpdateMitreMappingDocument, variables },
    result: { data, errors },
  };
}
