/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { DestinationDetails } from 'Source/graphql/fragments/DestinationDetails.generated';
import { Wizard, WizardPanel } from 'Components/Wizard';
import ConfigureDestinationScreen from './ConfigureDestinationPanel';
import DestinationTestPanel from '../common/DestinationTestPanel';

export interface WizardData {
  destination?: DestinationDetails;
}

const EditDestination: React.FC = () => {
  return (
    <Wizard<WizardData> header={false}>
      <Wizard.Step>
        <WizardPanel>
          <ConfigureDestinationScreen />
        </WizardPanel>
      </Wizard.Step>
      <Wizard.Step>
        <WizardPanel>
          <DestinationTestPanel />
        </WizardPanel>
      </Wizard.Step>
    </Wizard>
  );
};

export default EditDestination;
