/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import { Box, Heading, Text, SideSheet, useSnackbar, SideSheetProps } from 'pouncejs';
import React from 'react';
import { extractErrorMessage } from 'Helpers/utils';
import UserForm from 'Components/forms/UserForm';
import { UserRoleInputKind } from 'Generated/schema';
import { useInviteUser } from './graphql/inviteUser.generated';

const initialValues = {
  email: '',
  familyName: '',
  givenName: '',
  role: null,
};

const UserInvitationSidesheet: React.FC<SideSheetProps> = props => {
  const { pushSnackbar } = useSnackbar();
  const [inviteUser] = useInviteUser({
    update: (cache, { data }) => {
      cache.modify({
        fields: {
          users(existingData, { toReference }) {
            return [toReference(data.inviteUser.user), ...existingData];
          },
        },
      });
    },
    onCompleted: () => {
      props.onClose();
      pushSnackbar({ variant: 'success', title: 'User invited successfully' });
    },
    onError: error => pushSnackbar({ variant: 'error', title: extractErrorMessage(error) }),
  });

  return (
    <SideSheet
      data-tracking-page="user-invitation"
      aria-labelledby="sidesheet-title"
      aria-describedby="sidesheet-description role-disclaimer"
      {...props}
    >
      <Box width={425} m="auto">
        <Heading mb={8} id="sidesheet-title">
          Invite User
        </Heading>
        <Text color="gray-300" mb={8} id="sidesheet-description">
          By inviting users to join your organization, they will receive an email with temporary
          credentials that they can use to sign in to the platform
        </Text>
        <UserForm
          initialValues={initialValues}
          onSubmit={({ role, ...rest }) =>
            inviteUser({
              variables: {
                input: {
                  ...rest,
                  role: { kind: UserRoleInputKind.Id, value: role.id },
                },
              },
            })
          }
        />
      </Box>
    </SideSheet>
  );
};

export default UserInvitationSidesheet;
