/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Box, Card, Flex, IconButton } from 'pouncejs';
import JsonViewer from 'Components/JsonViewer';
import Panel from 'Components/Panel';
import { ResourceDetails } from '../graphql/resourceDetails.generated';

interface ResourceDetailsAttributesProps {
  resource?: ResourceDetails['resource'];
}

const ResourceDetailsAttributes: React.FC<ResourceDetailsAttributesProps> = ({ resource }) => {
  const [open, setOpen] = React.useState(true);
  return (
    <Panel title="Attributes">
      <Card p={4} variant="dark">
        <Flex align={open ? 'flex-start' : 'center'} spacing={open ? 7 : 2}>
          <IconButton
            variant="ghost"
            variantBorderStyle="circle"
            size="medium"
            active={open}
            variantColor="navyblue-300"
            icon={open ? 'caret-up' : 'caret-down'}
            onClick={() => setOpen(!open)}
            aria-label="Toggle attributes visibility"
          />

          {open ? (
            <JsonViewer data={resource.attributes} />
          ) : (
            <Box as="span" fontSize="small" color="gray-300">
              Click to expand
            </Box>
          )}
        </Flex>
      </Card>
    </Panel>
  );
};

export default ResourceDetailsAttributes;
