/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import * as Types from '../../../../../../__generated__/schema';

import { GraphQLError } from 'graphql';
import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';

export type GetLookupCloudFormationRoleTemplateVariables = {
  input: Types.GetLookupCloudFormationRoleTemplateInput;
};

export type GetLookupCloudFormationRoleTemplate = {
  getLookupCloudFormationRoleTemplate: Pick<Types.IntegrationTemplate, 'body' | 'stackName'>;
};

export const GetLookupCloudFormationRoleTemplateDocument = gql`
  query GetLookupCloudFormationRoleTemplate($input: GetLookupCloudFormationRoleTemplateInput!) {
    getLookupCloudFormationRoleTemplate(input: $input) {
      body
      stackName
    }
  }
`;

/**
 * __useGetLookupCloudFormationRoleTemplate__
 *
 * To run a query within a React component, call `useGetLookupCloudFormationRoleTemplate` and pass it any options that fit your needs.
 * When your component renders, `useGetLookupCloudFormationRoleTemplate` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLookupCloudFormationRoleTemplate({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetLookupCloudFormationRoleTemplate(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetLookupCloudFormationRoleTemplate,
    GetLookupCloudFormationRoleTemplateVariables
  >
) {
  return ApolloReactHooks.useQuery<
    GetLookupCloudFormationRoleTemplate,
    GetLookupCloudFormationRoleTemplateVariables
  >(GetLookupCloudFormationRoleTemplateDocument, baseOptions);
}
export function useGetLookupCloudFormationRoleTemplateLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetLookupCloudFormationRoleTemplate,
    GetLookupCloudFormationRoleTemplateVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    GetLookupCloudFormationRoleTemplate,
    GetLookupCloudFormationRoleTemplateVariables
  >(GetLookupCloudFormationRoleTemplateDocument, baseOptions);
}
export type GetLookupCloudFormationRoleTemplateHookResult = ReturnType<
  typeof useGetLookupCloudFormationRoleTemplate
>;
export type GetLookupCloudFormationRoleTemplateLazyQueryHookResult = ReturnType<
  typeof useGetLookupCloudFormationRoleTemplateLazyQuery
>;
export type GetLookupCloudFormationRoleTemplateQueryResult = ApolloReactCommon.QueryResult<
  GetLookupCloudFormationRoleTemplate,
  GetLookupCloudFormationRoleTemplateVariables
>;
export function mockGetLookupCloudFormationRoleTemplate({
  data,
  variables,
  errors,
}: {
  data: GetLookupCloudFormationRoleTemplate;
  variables?: GetLookupCloudFormationRoleTemplateVariables;
  errors?: GraphQLError[];
}) {
  return {
    request: { query: GetLookupCloudFormationRoleTemplateDocument, variables },
    result: { data, errors },
  };
}
