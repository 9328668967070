/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import FormikTextInput from 'Components/fields/TextInput';
import {
  Box,
  Flex,
  FormHelperText,
  IconButton,
  Card,
  Heading,
  Link,
  useSnackbar,
  Tooltip,
  SimpleGrid,
  Grid,
  Text,
} from 'pouncejs';
import { FastField, Field, useFormikContext } from 'formik';
import { RuleFormValues } from 'Components/forms/RuleForm';
import FormikSwitch from 'Components/fields/Switch';
import FormikCombobox from 'Components/fields/ComboBox';
import FormikMultiCombobox from 'Components/fields/MultiComboBox';
import { Link as RRLink } from 'react-router-dom';
import urls from 'Source/urls';
import { useListAvailableLogTypes, useListAvailableScheduledQueries } from 'Source/graphql/queries';
import { Severity } from 'Generated/schema';
import { capitalize } from 'Helpers/utils';
import { ENTITIES_COLOR_MAP } from 'Source/constants';

const severityOptions = Object.values(Severity);
const severityItemToString = (severity: string) => capitalize(severity.toLowerCase());

interface RuleFormRequiredSectionProps {
  isScheduled: boolean;
}
const RuleFormRequiredSection: React.FC<RuleFormRequiredSectionProps> = ({ isScheduled }) => {
  const { values, initialValues, setFieldValue, touched } = useFormikContext<RuleFormValues>();
  const { managed: isManaged } = initialValues;
  const [isRuleIdEditable, setIsRuleIdEditable] = React.useState(false);
  const { pushSnackbar } = useSnackbar();
  const { data: logTypeData } = useListAvailableLogTypes({
    skip: isScheduled,
    onError: () => pushSnackbar({ title: "Couldn't fetch your available log types" }),
  });
  const {
    data: scheduledQueryData,
    loading: scheduledQueryDataLoading,
  } = useListAvailableScheduledQueries({
    skip: !isScheduled,
    onError: () => pushSnackbar({ title: "Couldn't fetch your available log types" }),
  });
  const logTypeNames = logTypeData?.listAvailableLogTypes.logTypes ?? [];
  const scheduledQueryNames = React.useMemo(
    () => scheduledQueryData?.listSavedQueries.savedQueries.map(q => q.name) ?? [],
    [scheduledQueryData]
  );
  const noScheduledQueriesFound = !scheduledQueryDataLoading && scheduledQueryNames.length === 0;
  React.useEffect(() => {
    if (values.displayName && !touched.id && !initialValues.id) {
      const autoId = values.displayName.trim().split(' ').join('.');
      setFieldValue('id', autoId);
    }
  }, [setFieldValue, values.displayName, touched, initialValues]);

  return (
    <Card
      mb={6}
      p={6}
      position="relative"
      borderLeft="4px solid"
      borderColor={isScheduled ? ENTITIES_COLOR_MAP.SCHEDULED_RULE : ENTITIES_COLOR_MAP.RULE}
    >
      <Flex direction="column" spacing={4}>
        <Flex justify="space-between">
          <Flex alignItems="baseline">
            <Heading wordBreak="break-word" flexShrink={1} mr={2}>
              Basic Info
            </Heading>
            <Text color="navyblue-100">(Required)</Text>
          </Flex>
          <FastField
            data-testid="enabled-toggle"
            as={FormikSwitch}
            name="enabled"
            label="Enabled"
          />
        </Flex>
        <Card variant="dark" p={4}>
          <SimpleGrid columns={2} spacing={2}>
            <Flex flexGrow={1} direction="column" spacing={3}>
              <FastField
                as={FormikTextInput}
                label="Name"
                placeholder="A human-friendly name for this Rule"
                name="displayName"
                disabled={isManaged}
                required
              />
              {isRuleIdEditable ? (
                <Grid templateColumns="11fr 1fr">
                  <FastField
                    as={FormikTextInput}
                    label={isScheduled ? 'Scheduled Rule ID' : 'Rule ID'}
                    placeholder={`The unique ID of this Rule`}
                    name="id"
                    disabled={!!initialValues.id}
                    required
                  />
                  <Box mx={2}>
                    <IconButton
                      onClick={() => setIsRuleIdEditable(false)}
                      aria-label="save-rule-id"
                      icon="check"
                      variant="ghost"
                    />
                  </Box>
                </Grid>
              ) : (
                <FormHelperText id="rule-id" ml={3}>
                  {initialValues.id ? `ID: ${initialValues.id}` : `ID: ${values.id}`}
                  {!initialValues.id && (
                    <Box as="span" mx={2}>
                      <Tooltip content="Edit Rule Id">
                        <IconButton
                          onClick={() => setIsRuleIdEditable(true)}
                          aria-label="edit-rule-id"
                          variant="ghost"
                          size="small"
                          icon="edit"
                        />
                      </Tooltip>
                    </Box>
                  )}
                </FormHelperText>
              )}
            </Flex>
            <Flex flexGrow={1} direction="column" spacing={3}>
              <FastField
                as={FormikCombobox}
                name="severity"
                items={severityOptions}
                itemToString={severityItemToString}
                label="Severity"
                showClearSelectionControl={false}
              />
              {isScheduled ? (
                <Box as="fieldset">
                  <Field
                    as={FormikMultiCombobox}
                    searchable
                    label="Scheduled Queries"
                    name="scheduledQueries"
                    items={scheduledQueryNames}
                    placeholder="Which scheduled queries should this rule apply to?"
                    aria-describedby={
                      noScheduledQueriesFound ? 'scheduled-queries-description' : undefined
                    }
                  />
                  {noScheduledQueriesFound && (
                    <FormHelperText id="scheduled-queries-description" mt={2}>
                      You don{"'"}t seem to have any scheduled queries in your account.
                      <Link ml={1} as={RRLink} to={urls.data.dataExplorer()}>
                        Create one
                      </Link>
                    </FormHelperText>
                  )}
                </Box>
              ) : (
                <Field
                  as={FormikMultiCombobox}
                  searchable
                  label="Log Types"
                  name="logTypes"
                  items={logTypeNames}
                  placeholder="Which log types should this rule apply to?"
                  disabled={isManaged}
                />
              )}
            </Flex>
          </SimpleGrid>
        </Card>
      </Flex>
    </Card>
  );
};

export default RuleFormRequiredSection;
