/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import * as Types from '../../../__generated__/schema';

import { GraphQLError } from 'graphql';
import gql from 'graphql-tag';

export type DestinationDetails = { __typename: 'Destination' } & Pick<
  Types.Destination,
  | 'displayName'
  | 'lastModifiedTime'
  | 'outputId'
  | 'outputType'
  | 'verificationStatus'
  | 'defaultForSeverity'
  | 'alertTypes'
> & {
    outputConfig: {
      slack?: Types.Maybe<Pick<Types.SlackConfig, 'webhookURL'>>;
      sns?: Types.Maybe<Pick<Types.SnsConfig, 'topicArn'>>;
      pagerDuty?: Types.Maybe<Pick<Types.PagerDutyConfig, 'integrationKey'>>;
      github?: Types.Maybe<Pick<Types.GithubConfig, 'repoName' | 'token'>>;
      jira?: Types.Maybe<
        Pick<
          Types.JiraConfig,
          'orgDomain' | 'projectKey' | 'userName' | 'apiKey' | 'assigneeId' | 'issueType' | 'labels'
        >
      >;
      opsgenie?: Types.Maybe<Pick<Types.OpsgenieConfig, 'apiKey' | 'serviceRegion'>>;
      msTeams?: Types.Maybe<Pick<Types.MsTeamsConfig, 'webhookURL'>>;
      sqs?: Types.Maybe<Pick<Types.SqsDestinationConfig, 'queueUrl'>>;
      asana?: Types.Maybe<Pick<Types.AsanaConfig, 'personalAccessToken' | 'projectGids'>>;
      customWebhook?: Types.Maybe<Pick<Types.CustomWebhookConfig, 'webhookURL'>>;
    };
  };

export const DestinationDetails = gql`
  fragment DestinationDetails on Destination {
    displayName
    lastModifiedTime
    outputId
    outputType
    outputConfig {
      slack {
        webhookURL
      }
      sns {
        topicArn
      }
      pagerDuty {
        integrationKey
      }
      github {
        repoName
        token
      }
      jira {
        orgDomain
        projectKey
        userName
        apiKey
        assigneeId
        issueType
        labels
      }
      opsgenie {
        apiKey
        serviceRegion
      }
      msTeams {
        webhookURL
      }
      sqs {
        queueUrl
      }
      asana {
        personalAccessToken
        projectGids
      }
      customWebhook {
        webhookURL
      }
    }
    verificationStatus
    defaultForSeverity
    alertTypes
    __typename
  }
`;
