/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import withSEO from 'Hoc/withSEO';
import { compose } from 'Helpers/compose';
import withRoleRestrictedAccess from 'Hoc/withRoleRestrictedAccess';
import { Permission } from 'Generated/schema';
import Page403 from 'Pages/403';
import DetectionPackSourceForm from 'Components/forms/DetectionPackSourceForm';
import { useSnackbar } from 'pouncejs';
import { extractErrorMessage } from 'Helpers/utils';
import urls from 'Source/urls';
import useRouter from 'Hooks/useRouter';
import { useAddPackSource } from './graphql/addPackSource.generated';

const initialValues = {
  id: '',
  owner: '',
  repository: '',
  githubAccessToken: null,
  kmsKey: '',
};

const CreatePackSource: React.FC = () => {
  const { pushSnackbar } = useSnackbar();
  const { history } = useRouter();
  const [addPackSource] = useAddPackSource({
    update: (cache, { data }) => {
      cache.modify({
        fields: {
          listPackSources: (queryData, { toReference }) => {
            const addAnalysisPackSourceCacheRef = toReference(data.addAnalysisPackSource);
            return queryData
              ? [addAnalysisPackSourceCacheRef, ...queryData]
              : [addAnalysisPackSourceCacheRef];
          },
        },
      });
    },
    onCompleted: () => {
      history.push(`${urls.analysis.packs.packSources.list()}`);
    },
    onError: err => {
      pushSnackbar({
        variant: 'error',
        title: "Couldn't create pack source",
        description: extractErrorMessage(err) || 'Some unknown error occurred',
      });
    },
  });

  return (
    <DetectionPackSourceForm
      initialValues={initialValues}
      onSubmit={values =>
        addPackSource({
          variables: {
            input: values,
          },
        })
      }
    />
  );
};

export default compose(
  withSEO({ title: 'New Pack Source' }),
  withRoleRestrictedAccess({
    allowedPermissions: [Permission.PolicyModify, Permission.RuleModify],
    fallback: <Page403 />,
  })
)(CreatePackSource);
