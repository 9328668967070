/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Box, Flex, Heading, theme } from 'pouncejs';
import { slugify } from 'Helpers/utils';

interface ChartSummaryProps {
  total: number;
  title: string;
  color?: keyof typeof theme['colors'];
}

const ChartSummary: React.FC<ChartSummaryProps> = ({ total, title, color }) => {
  return (
    <Flex direction="column" align="center" justify="center">
      <Heading
        as="h2"
        size="3x-large"
        color={color}
        fontWeight="bold"
        aria-describedby={slugify(title)}
      >
        {total}
      </Heading>
      <Box id={slugify(title)} fontSize="medium">
        {title}
      </Box>
    </Flex>
  );
};

export default ChartSummary;
