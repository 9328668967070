/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */
import React from 'react';
import { FetchResult } from '@apollo/client';
import isEmpty from 'lodash/isEmpty';
import { FastField, Formik, Form } from 'formik';
import { Card, SimpleGrid, Heading, Flex, Button, Box, Text, Link, Alert } from 'pouncejs';

import Breadcrumbs from 'Components/Breadcrumbs';
import FormikTextArea from 'Components/fields/TextArea';
import FormikTextInput from 'Components/fields/TextInput';
import FormikEditor from 'Components/fields/Editor';
import SaveButton from 'Components/buttons/SaveButton';
import FormSessionRestoration from 'Components/utils/FormSessionRestoration';
import Panel from 'Components/Panel';
import { DataSchemaErrors, dataSchemaValidation, DATA_SCHEMA_ID_PREFIX } from 'Helpers/utils';
import useRouter from 'Hooks/useRouter';
import useAvailableLogTypesForProvider from 'Hooks/useAvailableLogTypesForProvider';
import { DATA_SCHEMAS_DOC_URL } from 'Source/constants';
import { useSchemaGeneration } from 'Components/utils/SchemaGenerationContext';
import { CreateDataSchema } from 'Pages/CreateDataSchema/graphql/createDataSchema.generated';
import { UpdateDataSchema } from 'Pages/EditDataSchema/graphql/updateDataSchema.generated';
import LoadingOverlay from './LoadingOverlay';
import ValidateButton from './ValidateButton';
import SampleDataSection from './SampleDataSection';

export interface DataSchemaFormValues {
  name: string;
  description: string;
  revision?: number;
  referenceURL: string;
  spec: string;
}

export interface DataSchemaFormProps {
  cloneId?: string;
  initialValues: DataSchemaFormValues;
  onSubmit: (
    values: DataSchemaFormValues
  ) => Promise<
    FetchResult<CreateDataSchema | UpdateDataSchema, Record<string, any>, Record<string, any>>
  >;
}

const DataSchemaForm: React.FC<DataSchemaFormProps> = ({ initialValues, onSubmit, cloneId }) => {
  const { history } = useRouter();
  const [schemaErrors, setSchemaErrors] = React.useState<DataSchemaErrors>(null);
  const [dismissedValidationSuccess, setDismissedValidationSuccess] = React.useState(true);
  const { inferringSchema, reset: resetSchemaGeneration } = useSchemaGeneration();
  const hasSchemaErrors = schemaErrors && !isEmpty(schemaErrors);
  const isEditing = initialValues.revision && initialValues.revision > 0;

  const onValidation = React.useCallback(
    errors => {
      setDismissedValidationSuccess(false);
      setSchemaErrors(errors);
    },
    [setSchemaErrors, setDismissedValidationSuccess]
  );

  const { availableLogTypes } = useAvailableLogTypesForProvider();

  const formRestorationId = React.useMemo(() => {
    if (cloneId) {
      return cloneId;
    }
    if (initialValues.revision && initialValues.name) {
      return `${initialValues.name}-${initialValues.revision}`;
    }
    return 'create';
  }, [initialValues, cloneId]);

  const onSubmitClick = React.useCallback(
    values => {
      return onSubmit(values).then(({ data }) => {
        if (!data?.putUserSchema.error) {
          // if there is no error when putUserSchema, reset schema generation
          resetSchemaGeneration();
        }
      });
    },
    [resetSchemaGeneration, onSubmit]
  );

  const validationSchema = React.useMemo(
    () => dataSchemaValidation(isEditing ? [] : availableLogTypes),
    [availableLogTypes, isEditing]
  );

  return (
    <Formik<DataSchemaFormValues>
      enableReinitialize
      initialValues={initialValues}
      onSubmit={onSubmitClick}
      validationSchema={validationSchema}
    >
      <FormSessionRestoration sessionId={`data-schema-form-${formRestorationId}`}>
        {({ clearFormSession }) => (
          <Form data-tracking-page="data-schema-form">
            <Panel title="New Data Schema">
              <SimpleGrid columns={2} spacing={5} mb={4}>
                <FastField
                  as={FormikTextInput}
                  name="name"
                  label="Schema ID"
                  prefix={DATA_SCHEMA_ID_PREFIX}
                  placeholder={`Must start with \`${DATA_SCHEMA_ID_PREFIX}\` followed by a capital letter`}
                  disabled={isEditing}
                  required
                />
                <FastField
                  as={FormikTextInput}
                  name="referenceURL"
                  label="Reference URL"
                  placeholder="The URL to the log's schema documentation"
                />
              </SimpleGrid>
              <Box mb={6}>
                <FastField
                  as={FormikTextArea}
                  name="description"
                  label="Description"
                  placeholder="A verbose description of what this log type does"
                />
              </Box>
              <Card variant="dark" px={4} py={5} mb={5} as="section">
                <Heading size="x-small" mb={5}>
                  Event Schema
                </Heading>
                <LoadingOverlay
                  loading={inferringSchema}
                  title="Inferring Schema"
                  description="We are processing your data, please wait."
                >
                  <FastField
                    as={FormikEditor}
                    placeholder="# Write your schema in YAML here..."
                    name="spec"
                    width="100%"
                    minHeight={400}
                    maxHeight={500}
                    language="yaml"
                    aria-labelledby={hasSchemaErrors ? 'schema-errors' : undefined}
                    required
                  />
                </LoadingOverlay>
              </Card>
              {hasSchemaErrors && (
                <Box
                  my={5}
                  p={4}
                  borderRadius="medium"
                  backgroundColor="pink-700"
                  fontSize="medium"
                  data-testid="schema-errors"
                  id="schema-errors"
                >
                  <Flex direction="column" spacing={2}>
                    {schemaErrors &&
                      Object.keys(schemaErrors).map(fieldName => {
                        return (
                          <Box key={fieldName}>
                            <Box as="b">{fieldName}</Box>
                            {schemaErrors[fieldName].map((errors, index) => (
                              <Text key={index} fontStyle="italic" ml={4}>
                                {errors.message}
                              </Text>
                            ))}
                          </Box>
                        );
                      })}
                  </Flex>
                </Box>
              )}
              {!dismissedValidationSuccess && !hasSchemaErrors && (
                <Box mb={4}>
                  <Alert
                    aria-label="schema syntax success"
                    variant="success"
                    title="Schema syntax is looking good"
                    discardable
                    onClose={() => setDismissedValidationSuccess(true)}
                  />
                </Box>
              )}
              <ValidateButton data-tid="validate-data-schema" setSchemaErrors={onValidation} />
              <Text mt={5} fontSize="medium">
                Need to know more about how to write data schemas?{' '}
                <Link external href={DATA_SCHEMAS_DOC_URL}>
                  Read our documentation
                </Link>
              </Text>
            </Panel>

            <SampleDataSection />

            <Breadcrumbs.Actions>
              <Flex justify="flex-end" spacing={4}>
                <Button
                  data-tid="data-schema-form-cancel"
                  variantColor="gray-600"
                  icon="close-outline"
                  onClick={() => {
                    clearFormSession();
                    resetSchemaGeneration();
                    history.goBack();
                  }}
                >
                  Cancel
                </Button>
                <SaveButton
                  allowPristineSubmission={!!cloneId}
                  data-tid={`${isEditing ? 'update' : 'save'}-data-schema`}
                >
                  {isEditing ? 'Update' : 'Save'}
                </SaveButton>
              </Flex>
            </Breadcrumbs.Actions>
          </Form>
        )}
      </FormSessionRestoration>
    </Formik>
  );
};

export default DataSchemaForm;
