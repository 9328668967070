/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { FastField, Form, Formik } from 'formik';
import { Box, Flex } from 'pouncejs';
import { ListSavedQueriesInput, SortDirEnum } from 'Generated/schema';
import useRequestParamsWithoutPagination from 'Hooks/useRequestParamsWithoutPagination';
import pick from 'lodash/pick';
import FormikAutosave from 'Components/utils/Autosave';
import FormikCombobox from 'Components/fields/ComboBox';
import FormikTextInput from 'Components/fields/TextInput';
import DropdownFilters from './DropdownFilters';

export type ListSavedQueriesInlineFiltersValues = Pick<
  ListSavedQueriesInput,
  'contains' | 'sortDir'
>;

const filters: (keyof ListSavedQueriesInlineFiltersValues)[] = ['contains', 'sortDir'];

const defaultValues = {
  contains: '',
  sortDir: SortDirEnum.Descending,
};

const sortDirOptions = {
  [SortDirEnum.Descending]: 'Most Recent',
  [SortDirEnum.Ascending]: 'From Oldest',
};
const sortDirItems = Object.keys(sortDirOptions);
const sortDirToString = (sortDir: SortDirEnum) => sortDirOptions[sortDir];

const ListSavedQueriesFilters: React.FC = () => {
  const { requestParams, updateRequestParams } = useRequestParamsWithoutPagination<
    ListSavedQueriesInput
  >();

  const initialFilterValues = React.useMemo(
    () => ({
      ...defaultValues,
      ...pick(requestParams, filters),
    }),
    [requestParams]
  );

  return (
    <Flex justify="flex-end" align="center">
      <Box flexGrow={3}>
        <Formik<ListSavedQueriesInlineFiltersValues>
          enableReinitialize
          initialValues={initialFilterValues}
          onSubmit={values => {
            updateRequestParams(values);
          }}
        >
          <Form>
            <FormikAutosave threshold={200} />
            <Flex spacing={4} justify="flex-end" align="center" px={4}>
              <Box minWidth={425} maxWidth={490} flexGrow={3}>
                <FastField
                  name="contains"
                  icon="search"
                  iconAlignment="left"
                  as={FormikTextInput}
                  label="Filter Saved Queries by text"
                  placeholder="Search for a saved query..."
                />
              </Box>
              <Box>
                <FastField
                  name="sortDir"
                  data-testid="list-saved-query-sort-by"
                  as={FormikCombobox}
                  items={sortDirItems}
                  itemToString={sortDirToString}
                  placeholder="Select a sort option"
                  label="Sort Direction"
                />
              </Box>
            </Flex>
          </Form>
        </Formik>
      </Box>
      <DropdownFilters />
    </Flex>
  );
};

export default React.memo(ListSavedQueriesFilters);
