/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import * as Yup from 'yup';
import { Field, Form, Formik } from 'formik';
import React from 'react';
import { Box, Flex, Text, Heading } from 'pouncejs';
import FormikTextInput from 'Components/fields/TextInput';
import SubmitButton from 'Components/buttons/SubmitButton';
import { PANTHER_CONFIG, ROLE_PERMISSIONS } from 'Source/constants';
import { LogTypeAccessKind, Permission } from 'Generated/schema';
import PermissionGrid from 'Components/forms/common/PermissionGrid';

export interface RoleFormValues {
  id?: string;
  name: string;
  permissions: Permission[];
  logTypeAccess?: string[];
  logTypeAccessKind: LogTypeAccessKind;
}

interface RoleFormProps {
  initialValues: RoleFormValues;
  onSubmit: (values: RoleFormValues) => Promise<void>;
}

const validationSchema = Yup.object().shape({
  name: Yup.string().required(),
  logTypeAccess: Yup.array().when('logTypeAccessKind', {
    is: (logTypeAccessKind: LogTypeAccessKind) =>
      logTypeAccessKind === LogTypeAccessKind.Allow || logTypeAccessKind === LogTypeAccessKind.Deny,
    then: Yup.array().of(Yup.string()).min(1),
    otherwise: Yup.array().of(Yup.string()),
  }),
  logTypeAccessKind: Yup.string().oneOf(Object.values(LogTypeAccessKind)),
  permissions: Yup.array()
    .of(Yup.string().oneOf(Object.keys(ROLE_PERMISSIONS)))
    .required()
    .min(1),
});

const RoleForm: React.FC<RoleFormProps> = ({ initialValues, onSubmit }) => {
  return (
    <Formik<RoleFormValues>
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ values: { name } }) => {
        return (
          <React.Fragment>
            <Box my={10}>
              <Heading size="large" textAlign="center" mb={3}>
                {name || "Create Roles. It's what cool kids do."}
              </Heading>
              <Text color="gray-300" textAlign="center">
                Roles are an easy way to restrict access to people to certain features of the
                product
              </Text>
            </Box>
            <Box width={0.75} m="auto">
              <Form>
                <Field
                  as={FormikTextInput}
                  label="Name"
                  placeholder="Enter the name of the role..."
                  name="name"
                  required
                />
                <PermissionGrid
                  permissionMapping={ROLE_PERMISSIONS}
                  withLogTypeAccessPanel={PANTHER_CONFIG.ENABLE_DATALAKE_RBAC === 'true'}
                />
                <Flex justify="center" m="auto" width={0.5}>
                  <SubmitButton
                    fullWidth
                    data-tid={`${initialValues.id ? 'update' : 'create'}-role`}
                  >
                    {initialValues.id ? 'Update Role' : 'Create Role'}
                  </SubmitButton>
                </Flex>
              </Form>
            </Box>
          </React.Fragment>
        );
      }}
    </Formik>
  );
};

export default RoleForm;
