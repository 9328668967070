/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import useAuth from 'Hooks/useAuth';
import urls from 'Source/urls';
import useRouter from 'Hooks/useRouter';
import { Location } from 'history';
import { Button, useSnackbar } from 'pouncejs';
import { SAML_DOC_URL, PANTHER_CONFIG } from 'Source/constants';
import useMounted from 'Hooks/useMounted';
import LinkButton from 'Components/buttons/LinkButton';
import { safeBase64Encode } from 'Helpers/utils';

const SsoLink: React.FC = () => {
  const [enabled, setEnabled] = React.useState<boolean>(undefined);
  const { pushSnackbar } = useSnackbar();
  const { location } = useRouter<any, { referrer?: Location }>();

  const referrer = location.state?.referrer;
  const ssoLinkUrl =
    `https://${PANTHER_CONFIG.WEB_APPLICATION_USER_POOL_HOST}/oauth2/authorize` +
    `?identity_provider=PantherSSO` +
    `&redirect_uri=${window.location.origin}${urls.account.auth.singleSignOn()}` +
    `&response_type=CODE` +
    `&client_id=${PANTHER_CONFIG.WEB_APPLICATION_USER_POOL_CLIENT_ID}` +
    `${referrer ? `&state=${safeBase64Encode(referrer)}` : ''}`;
  const isMounted = useMounted();
  const { retrieveSsoSettings } = useAuth();
  React.useEffect(() => {
    retrieveSsoSettings({
      onSuccess: ({ enabled: isSsoEnabled }) => isMounted.current && setEnabled(isSsoEnabled),
      onError: () => isMounted.current && setEnabled(true),
    });
    // FIXME: Look into missing effect dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClick = React.useCallback(() => {
    // If it's enabled or it's `undefined` (meaning "we don't know yet if it's enabled" since the
    // API request is still pending), redirect  to  SSO
    if (enabled === true || enabled === undefined) {
      window.location.assign(ssoLinkUrl);
    }

    // If it's not enabled, help the users know to enable it
    if (enabled === false) {
      pushSnackbar({
        variant: 'default',
        title: 'SAML/SSO is not enabled',
        duration: 15000,
        description: [
          'Your Panther deployment is not configured to work with SSO.',
          <br key={0} />,
          'You can find step-by-step guides on how to enable SAML integration in our docs',
        ],
        actions: (
          <LinkButton external to={SAML_DOC_URL}>
            Read more
          </LinkButton>
        ),
      });
    }
    // FIXME: Look into missing effect dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enabled, pushSnackbar]);
  return (
    <Button onClick={handleClick} fullWidth variant="outline" variantColor="navyblue-300">
      Sign in with SSO
    </Button>
  );
};

export default React.memo(SsoLink);
