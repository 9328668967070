/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import LinkButton from 'Components/buttons/LinkButton';
import { WizardPanel } from 'Components/Wizard';
import { Button, Flex, SimpleGrid } from 'pouncejs';
import urls from 'Source/urls';
import { IntegrationItemHealthStatus, SortDirEnum } from 'Generated/schema';
import HealthCheckWarning from './HealthCheckWarning';

interface UnhealthyPanelProps {
  unhealthyMetrics: IntegrationItemHealthStatus[];
  sourceListingUrl?: string;
  onRetry: () => void;
}

const UnhealthyPanel: React.FC<UnhealthyPanelProps> = ({
  sourceListingUrl = `${urls.integrations.logSources.list()}?sortBy=dateCreated&sortDir=${
    SortDirEnum.Descending
  }`,
  unhealthyMetrics = [],
  onRetry,
}) => (
  <WizardPanel>
    <Flex align="center" direction="column" mx="auto" width={675}>
      <WizardPanel.Heading
        title="The source is saved, but there are some issues blocking log ingestion"
        subtitle="Have a look at the error(s) below and try again. If the problem continues, contact us."
        divider={null}
        subtitleProps={{ color: 'gray-300', fontWeight: 'normal' }}
      />
      <SimpleGrid column={1} spacing={2}>
        {unhealthyMetrics.map(({ message, rawErrorMessage }) => {
          return message?.length || rawErrorMessage ? (
            <HealthCheckWarning key={message} title={message} description={rawErrorMessage} />
          ) : null;
        })}
      </SimpleGrid>
      <WizardPanel.Actions>
        <Flex direction="column" align="center" spacing={4}>
          <Flex spacing={4}>
            <LinkButton variantColor="gray-400" to={sourceListingUrl}>
              Continue with errors
            </LinkButton>
            <Button onClick={onRetry}>Retry Healthcheck</Button>
          </Flex>
        </Flex>
      </WizardPanel.Actions>
    </Flex>
  </WizardPanel>
);

export default UnhealthyPanel;
