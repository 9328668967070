/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Flex, Text, Heading, Box, Alert } from 'pouncejs';
import { FastField, useField, useFormikContext } from 'formik';
import FormikTextInput from 'Components/fields/TextInput';
import { WizardPanel, useWizardContext } from 'Components/Wizard';
import { PANTHER_CONFIG } from 'Source/constants';
import IntervalPicker from 'Components/IntervalPicker';
import { IntervalUnitsEnum } from 'Components/IntervalPicker/IntervalPicker';
import LookupPanelHeading from '../LookupPanelHeading';
import LookupWizardContinueButton from '../LookupWizardContinueButton';
import EditLookupActions from '../EditLookupActions';
import { LookupFormValues, LOOKUP_WIZARD_WIDTH } from '../lookupWizardHelpers';

const UpdateRoleArnWarning = () => {
  return (
    <Box mb={4}>
      <Alert
        variant="warning"
        title="Editing your source details will require you to update IAM permissions and provide a new Role ARN."
      />
    </Box>
  );
};

const LookupS3SourcePanel = () => {
  const { goToNextStep } = useWizardContext();
  const { initialValues } = useFormikContext<LookupFormValues>();
  const [, { value: updatePeriod }, { setValue: setUpdatePeriod }] = useField('updatePeriod');

  const isEditing = Boolean(initialValues.id);
  return (
    <WizardPanel width={LOOKUP_WIZARD_WIDTH} margin="0 auto">
      <LookupPanelHeading
        title="Set up your S3 source"
        subtitle="We need to know where your lookup data is coming from."
      />

      {/**
       * If there is no role ARN in the initial values, that means the user didn't make it
       * to this page when creating the lookup table and we don't need to warn them.
       */}
      {isEditing && initialValues.iamRoleArn && <UpdateRoleArnWarning />}

      <Flex spacing={4} direction="column">
        {PANTHER_CONFIG.MAX_LOOKUP_TABLE_COMPRESSED_SIZE_IN_MB !== null && (
          <>
            <Heading as="h2" fontSize="small" fontWeight="bold">
              AWS S3 Source Details
            </Heading>
            <Text as="p" fontSize="small" mt={1}>
              The max size supported for your S3 source data is{' '}
              {PANTHER_CONFIG.MAX_LOOKUP_TABLE_COMPRESSED_SIZE_IN_MB}MB.
            </Text>
          </>
        )}
        <FastField
          name="awsAccountId"
          as={FormikTextInput}
          label="Account ID"
          placeholder="The ID of the AWS Account where the input S3 bucket is located"
          required
          data-tid="lookup-s3-upload-aws-id-field"
        />

        <FastField
          name="s3FileLocation"
          as={FormikTextInput}
          label="S3 URI"
          placeholder="The URI of the S3 object containing your lookup table data"
          required
          data-tid="lookup-s3-upload-s3-uri-field"
        />

        <FastField
          name="kmsKey"
          as={FormikTextInput}
          label="KMS Key ARN - Optional"
          placeholder="The ARN of the KMS key, needed when the bucket is configured with server side encryption"
          data-tid="lookup-s3-upload-s3-kms-key-field"
        />

        <Box>
          <Heading as="h2" fontSize="small" fontWeight="bold">
            Update Period
          </Heading>
          <Text as="p" fontSize="small" mt={1}>
            The frequency of expected changes to your S3 input data.
          </Text>

          <Flex
            spacing={5}
            mt={3}
            maxWidth={300}
            data-testid="lookup-s3-update-period"
            data-tid="lookup-s3-update-period-field"
          >
            <IntervalPicker
              value={updatePeriod}
              setValue={setUpdatePeriod}
              smallestUnit={IntervalUnitsEnum.HOURS}
            />
          </Flex>
        </Box>
      </Flex>

      <Flex justify="center" justifySelf="flex-end" mt={6}>
        {isEditing ? (
          <EditLookupActions />
        ) : (
          <LookupWizardContinueButton
            fields={['awsAccountId', 's3FileLocation', 'kmsKey', 'updatePeriod']}
            onClick={goToNextStep}
          />
        )}
      </Flex>
    </WizardPanel>
  );
};

export default LookupS3SourcePanel;
