/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';

interface JsonViewerContextValue {
  copyJson: () => void;
  toggleIsExpanded: () => void;
  isExpanded: boolean;
}

/**
 * This context should only be used internally by the JsonViewer component. This allows
 * extra components (like the header) to be exported & composed without having to explicitly
 * wire them up.
 */
const JsonViewerContext = React.createContext<JsonViewerContextValue | null>(null);

export default JsonViewerContext;
