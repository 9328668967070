/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Box, Flex, Text } from 'pouncejs';
import ErrorBoundary from 'Components/ErrorBoundary';

import urls from 'Source/urls';
import DetectionCard from 'Components/cards/DetectionCard';
import { toPlural } from 'Helpers/utils';
import LinkButton from 'Components/buttons/LinkButton';
import { MitreContextValue } from '../MitreContext';
import { useGetMitreDetections } from '../graphql/getMitreDetections.generated';
import MitreRelationsDetectionsSkeleton from './MitreRelationsDetectionsSkeleton';
import MitreRelationsDetectionWarnings from './MitreRelationsDetectionWarnings';

interface MitreRelationsDetectionsProps {
  reportSetting: MitreContextValue['activeReportSetting'];
}

const MitreRelationsDetections = ({ reportSetting }: MitreRelationsDetectionsProps) => {
  const { tacticId, techniqueId } = reportSetting?.association.mitre;

  const { previousData, data = previousData, loading } = useGetMitreDetections({
    variables: { tacticId, techniqueId },
  });

  if (loading && !data) {
    return (
      <MitreRelationsDetectionsSkeleton count={reportSetting?.matchingDetectionIds.length ?? 1} />
    );
  }

  const detectionCount = data.reportSetting.detections?.length || 0;

  return (
    <ErrorBoundary>
      <Flex alignItems="center" justifyContent="space-between" py={2} px={4}>
        <Text>
          {detectionCount} {toPlural('detection', detectionCount)}
        </Text>
        <LinkButton
          to={urls.reports.mitreMapping({ tacticId, techniqueId })}
          variant="outline"
          variantColor="navyblue-200"
        >
          Edit Mapping
        </LinkButton>
      </Flex>
      <Box transition="200ms opacity" opacity={loading ? 0.25 : 1}>
        {!loading && <MitreRelationsDetectionWarnings detections={data.reportSetting.detections} />}
        <Flex direction="column" spacing={4} pt={2} px={3}>
          {data.reportSetting.detections.map(detection => (
            <Box key={detection.id} mb={2}>
              <DetectionCard detection={detection} variant="light" />
            </Box>
          ))}
        </Flex>
      </Box>
    </ErrorBoundary>
  );
};

export default React.memo(MitreRelationsDetections);
