/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import useTimer, { UseTimerProps } from './useTimer';
import useAsyncQueryContext from './useAsyncQueryContext';

export type UseAsyncQueryTimerProps = Pick<UseTimerProps, 'interval'>;

/**
 * Upon mounting, this hook starts a timer to track how long the
 * async query has been running.
 *
 * If data is available in the query stats (after a query has finished execution)
 * returns the amount of elapsed time for the query.
 */
const useAsyncQueryTimer = ({ interval }: UseAsyncQueryTimerProps = {}) => {
  const { timeElapsed, start, stop, reset } = useTimer({ lazy: true, interval });
  const {
    state: { queryStatus },
  } = useAsyncQueryContext();

  const isProvisioning = queryStatus === 'provisioning';
  const isRunning = queryStatus === 'running';
  const hasErrored = queryStatus === 'errored';
  const hasSucceeded = queryStatus === 'succeeded';

  React.useEffect(() => {
    if (isRunning) {
      start();
    }
  }, [isRunning, start]);

  React.useEffect(() => {
    if (hasSucceeded || hasErrored) {
      stop();
    }
  }, [hasErrored, hasSucceeded, stop]);

  React.useEffect(() => {
    if (isProvisioning) {
      reset();
    }
  }, [isProvisioning, reset]);

  return { timeElapsed };
};

export default useAsyncQueryTimer;
