/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Wizard } from 'Components/Wizard';
import * as Yup from 'yup';
import { GcsLogSourceIntegrationDetails } from 'Source/graphql/fragments/GcsLogIntegrationDetails.generated';
import { Formik, Form } from 'formik';
import ErrorBoundary from 'Components/ErrorBoundary';
import CancelIntegrationAction from 'Pages/Integrations/components/CancelIntegrationAction';
import { yupIntegrationLabelUniqueness } from 'Helpers/utils';
import useFindSourcesPerMethod from 'Hooks/useFindSourcesPerMethod';
import { LogTransportMethodsEnum } from 'Source/constants';
import SetupSourcePanel from './SetupSourcePanel';
import SetupInfrastructure from './SetupInfrastructure';
import SetupVerification from './SetupVerification';

export interface GcsLogSourceWizardValues {
  integrationId?: string;
  integrationLabel: string;
  projectId: string;
  subscriptionId: string;
  gcsBucket: string;
  credentials: string;
  userEmail: string;
  logTypes: string[];
  jsonKeyfile: File;
}

interface GcsLogSourceWizardProps {
  initialValues?: GcsLogSourceWizardValues;
  availableLogTypes?: string[];
  onSubmit?: (values: GcsLogSourceWizardValues) => Promise<GcsLogSourceIntegrationDetails | Error>;
}

const GcsLogSourceWizard = ({
  initialValues,
  availableLogTypes,
  onSubmit,
}: GcsLogSourceWizardProps) => {
  const formStatus = React.useMemo(() => ({ availableLogTypes }), [availableLogTypes]);
  const existingGcsSources = useFindSourcesPerMethod(LogTransportMethodsEnum.gcs);
  const validationSchema: Yup.SchemaOf<any> = React.useMemo(() => {
    const editMode = !!initialValues.integrationId;

    return Yup.object().shape({
      integrationId: Yup.string(),
      integrationLabel: yupIntegrationLabelUniqueness({
        existingSources: existingGcsSources,
        methodName: 'GCS',
        existingLabel: initialValues.integrationLabel,
      }),
      projectId: Yup.string().required(),
      subscriptionId: Yup.string().required(),
      gcsBucket: Yup.string().required(),
      credentials: editMode ? Yup.string() : Yup.string().required(),
      userEmail: Yup.string().required(),
      logTypes: Yup.array().of(Yup.string()).required().min(1),
      jsonKeyfile: Yup.mixed()
        .test('fileFormat', 'Unsupported file type', value => {
          return value === null || (value && value.type) === 'application/json';
        })
        .nullable(),
    });
  }, [existingGcsSources, initialValues]);

  const editMode = !!initialValues.integrationId;
  // TODO: GCS change the the description during edit mode
  return (
    <ErrorBoundary>
      <Formik<GcsLogSourceWizardValues>
        enableReinitialize
        validationSchema={validationSchema}
        initialValues={initialValues}
        initialStatus={formStatus}
        onSubmit={onSubmit}
      >
        <Form>
          <Wizard headerTitle="Connecting a new Log Source" enableAllNavigationSteps={editMode}>
            <Wizard.Step
              title={editMode ? 'Edit Source Details' : 'Setup your Source'}
              description={
                editMode
                  ? 'Edit account information and preferences for this log source'
                  : 'Provide account information and set preferences to connect the log source to Panther'
              }
            >
              <SetupSourcePanel />
            </Wizard.Step>

            <Wizard.Step
              title="Infrastructure &amp; Configuration"
              description={
                editMode
                  ? 'Edit GCP Infrastructure and credentials information'
                  : 'Add GCP Infrastructure and credentials information'
              }
            >
              <SetupInfrastructure />
            </Wizard.Step>

            <Wizard.Step
              title="Setup Verification"
              description="Confirm that your log source has been successfully onboarded or troubleshoot any issues"
              navigationDisabled={editMode}
            >
              <SetupVerification />
            </Wizard.Step>
          </Wizard>
          {!editMode && <CancelIntegrationAction />}
        </Form>
      </Formik>
    </ErrorBoundary>
  );
};

export default GcsLogSourceWizard;
