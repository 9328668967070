/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { compose } from 'Helpers/compose';
import useRouter from 'Hooks/useRouter';
import { Alert, Box, Card, Flex, TabList, TabPanel, TabPanels, Tabs, useSnackbar } from 'pouncejs';
import { BorderedTab, BorderTabDivider } from 'Components/BorderedTab';
import { extractErrorMessage } from 'Helpers/utils';
import withSEO from 'Hoc/withSEO';
import invert from 'lodash/invert';
import useUrlParams from 'Hooks/useUrlParams';
import ErrorBoundary from 'Components/ErrorBoundary';
import { useUpdateAnalysisPack } from 'Source/graphql/queries';
import ItemSkeleton from 'Pages/AnalysisPackDetails/ItemsSkeleton';
import { UpdateAnalysisPackInput } from 'Generated/schema';
import ListPackDataModels from './ListPackDataModels';
import ListPackRules from './ListPackRules';
import ListPackHelpers from './ListPackHelpers';
import ListPackPolicies from './ListPackPolicies';
import ListPackScheduledRules from './ListPackScheduledRules';
import ListPackSavedQueries from './ListPackSavedQueries';
import { useGetAnalysisPackDetails } from './graphql/getAnalysisPackDetails.generated';
import PackDetailsPageSkeleton from './Skeleton';
import AnalysisPackDetailsBanner from './AnalysisPackDetailsBanner';

export interface PackDetailsPageUrlParams {
  section?: 'rules' | 'policies' | 'helpers' | 'models' | 'schedules-rules' | 'saved-queries';
}

const sectionToTabIndex: Record<PackDetailsPageUrlParams['section'], number> = {
  rules: 0,
  policies: 1,
  helpers: 2,
  models: 3,
  'schedules-rules': 4,
  'saved-queries': 5,
};

const tabIndexToSection = invert(sectionToTabIndex) as Record<
  number,
  PackDetailsPageUrlParams['section']
>;

const PackDetailsPage: React.FC = () => {
  const { match } = useRouter<{ id: string }>();
  const { pushSnackbar } = useSnackbar();
  const { urlParams, setUrlParams } = useUrlParams<PackDetailsPageUrlParams>();
  const { error, data, loading } = useGetAnalysisPackDetails({
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    variables: {
      id: match.params.id,
    },
  });

  const [updatePack, { loading: updatingPack }] = useUpdateAnalysisPack({
    onCompleted: () => {
      pushSnackbar({
        variant: 'success',
        title: `Updated Pack [${match.params.id}] successfully`,
      });
    },
    onError: err => {
      pushSnackbar({
        variant: 'error',
        title: `Failed to update Pack`,
        description: extractErrorMessage(err),
      });
    },
  });

  const onUpdate = React.useCallback(
    (values: UpdateAnalysisPackInput) =>
      updatePack({
        variables: {
          input: { ...values },
        },
      }),
    [updatePack]
  );

  const counters = React.useMemo(() => {
    if (!data?.getAnalysisPack) {
      return {};
    }
    return {
      rules: data.getAnalysisPack.packTypes.RULE || 0,
      policies: data.getAnalysisPack.packTypes.POLICY || 0,
      helpers: data.getAnalysisPack.packTypes.GLOBAL || 0,
      models: data.getAnalysisPack.packTypes.DATAMODEL || 0,
      scheduledRules: data.getAnalysisPack.packTypes.SCHEDULED_RULE || 0,
      savedQueries: data.getAnalysisPack.packTypes.SCHEDULED_QUERY || 0,
    };
  }, [data?.getAnalysisPack]);

  if (loading) {
    return <PackDetailsPageSkeleton />;
  }

  if (error) {
    return (
      <Box mb={6} data-testid={`pack-${match.params.id}`}>
        <Alert
          variant="error"
          title="Couldn't load Pack"
          description={
            extractErrorMessage(error) ||
            " An unknown error occured and we couldn't load the pack details from the server"
          }
        />
      </Box>
    );
  }

  return (
    <Box as="article" mb={6} data-tracking-page="pack-details">
      <Flex direction="column" spacing={6}>
        <ErrorBoundary>
          <AnalysisPackDetailsBanner
            pack={data.getAnalysisPack}
            onUpdate={onUpdate}
            isUpdating={updatingPack}
          />
        </ErrorBoundary>
        <Card position="relative">
          <Tabs
            index={sectionToTabIndex[urlParams.section] || 0}
            onChange={index => setUrlParams({ section: tabIndexToSection[index] })}
          >
            <Box px={2}>
              <TabList>
                <BorderedTab data-tid="pack-details-view-rules">
                  <Box data-testid="pack-rules">Rules ({counters.rules})</Box>
                </BorderedTab>
                <BorderedTab data-tid="pack-details-view-policies">
                  <Box data-testid="pack-policies">Policies ({counters.policies})</Box>
                </BorderedTab>
                <BorderedTab data-tid="pack-details-view-helpers">
                  <Box data-testid="pack-helpers">Helpers ({counters.helpers})</Box>
                </BorderedTab>
                <BorderedTab data-tid="pack-details-view-models">
                  <Box data-testid="pack-models">Data Models ({counters.models})</Box>
                </BorderedTab>
                <BorderedTab data-tid="pack-details-view-scheduled-rules">
                  <Box data-testid="pack-scheduled-rules">
                    Scheduled Rules ({counters.scheduledRules})
                  </Box>
                </BorderedTab>
                <BorderedTab data-tid="pack-details-view-saved-queries">
                  <Box data-testid="pack-saved-queries">
                    Saved Queries ({counters.savedQueries})
                  </Box>
                </BorderedTab>
              </TabList>
              <BorderTabDivider />
              {updatingPack ? (
                <ItemSkeleton />
              ) : (
                <TabPanels>
                  <TabPanel data-testid="pack-rules-tabpanel" lazy unmountWhenInactive>
                    <ListPackRules />
                  </TabPanel>
                  <TabPanel data-testid="pack-policies-tabpanel" lazy unmountWhenInactive>
                    <ListPackPolicies />
                  </TabPanel>
                  <TabPanel data-testid="pack-helpers-tabpanel" lazy unmountWhenInactive>
                    <ListPackHelpers />
                  </TabPanel>
                  <TabPanel data-testid="pack-models-tabpanel" lazy unmountWhenInactive>
                    <ListPackDataModels />
                  </TabPanel>
                  <TabPanel data-testid="pack-scheduled-rules-tabpanel" lazy unmountWhenInactive>
                    <ListPackScheduledRules />
                  </TabPanel>
                  <TabPanel data-testid="pack-saved-queries-tabpanel" lazy unmountWhenInactive>
                    <ListPackSavedQueries />
                  </TabPanel>
                </TabPanels>
              )}
            </Box>
          </Tabs>
        </Card>
      </Flex>
    </Box>
  );
};

export default compose(
  withSEO({ title: ({ match }) => match.params.id }),
  React.memo
)(PackDetailsPage);
