/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */
import React from 'react';
import urls from 'Source/urls';
import { useSnackbar } from 'pouncejs';
import { extractErrorMessage } from 'Helpers/utils';
import useRouter from 'Hooks/useRouter';
import { useGetSchemaDetailsLazyQuery } from './graphql/getSchemaDetails.generated';
import useCloneData from './useCloneData';

/**
 * Helps retrieve and create a new instance for data schema creation
 */
function useCloneDataSchema() {
  const { history } = useRouter();
  const { setCloneData, readCloneData } = useCloneData({ prefix: 'data-schemas-' });
  const { pushSnackbar } = useSnackbar();

  const retrieveClonedSchema = (cloneId: string): any => {
    return readCloneData(cloneId);
  };

  const [clone, { loading: isCloning, error }] = useGetSchemaDetailsLazyQuery({
    fetchPolicy: 'network-only', // Don't use cache
    // Failed deliveries will also trigger onCompleted as we don't return exceptions
    onCompleted: data => {
      const dataError = data?.getSchema?.error || null;
      if (dataError) {
        pushSnackbar({
          variant: 'error',
          title: 'Failed to retrieve schema data',
          description: dataError.message,
        });
      } else {
        const { name = '', ...rest } = data?.getSchema?.record || {};
        const cloneId = setCloneData({
          ...rest,
          name: `${name.replace('Custom.', '').replace(/\./g, '')}`,
        });
        history.push(`${urls.data.schemas.create()}?cloneId=${cloneId}`);
      }
    },
    // This will be fired if there was a network issue or other unknown internal exception
    onError: err => {
      pushSnackbar({
        variant: 'error',
        title: extractErrorMessage(err) || 'Failed to retrieve schema data',
      });
    },
  });

  // FIXME: variables can ONLY be declared on invocation, due to a bug with Apollo and useLazyQuery
  // https://github.com/apollographql/react-apollo/issues/3729#issuecomment-584242862
  const cloneSchema = React.useCallback(
    (name: string) => clone({ variables: { input: { name, clone: true } } }),
    [clone]
  );

  return { isCloning, error, cloneSchema, retrieveClonedSchema };
}

export default useCloneDataSchema;
