/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import isEmpty from 'lodash/isEmpty';
import { Button } from 'pouncejs';
import { useFormikContext } from 'formik';

import { useSchemaGeneration } from 'Components/utils/SchemaGenerationContext';
import useUrlParams from 'Hooks/useUrlParams';
import { extractErrorMessage, DataSchemaErrors, validateDataSchema } from 'Helpers/utils';
import { DataSchemaFormValues } from '../DataSchemaForm';
import { SampleDataUrlParams } from '../SampleDataSection/SampleDataMain';

interface ValidateButtonProps {
  setSchemaErrors: (errors: DataSchemaErrors) => void;
}

const ValidateButton: React.FC<ValidateButtonProps> = ({ setSchemaErrors }) => {
  const { values: { spec:schema } } = useFormikContext<DataSchemaFormValues>(); // prettier-ignore
  const { updateUrlParams } = useUrlParams<SampleDataUrlParams>();
  const { sessionId, setError, testSchema, testingSchema } = useSchemaGeneration();

  const onTestSchema = React.useCallback(
    async schemaToTest => {
      try {
        const { data } = await testSchema({
          variables: {
            input: {
              sessionId,
              schema: schemaToTest,
            },
          },
        });
        if (data.testSchema?.error) {
          setError({
            title: 'Failed to test schema',
            description: data.testSchema?.error?.message || 'An unknown error occurred',
          });
        } else {
          updateUrlParams({
            section: data.testSchema?.result?.stats.unmatched === 0 ? 'matched' : 'unmatched',
          });
        }
      } catch (e) {
        setError({
          title: 'Failed to test schema',
          description: extractErrorMessage(e) || 'An unknown error occurred',
        });
      }
    },
    [updateUrlParams, sessionId, setError, testSchema]
  );

  const handleClick = React.useCallback(async () => {
    const validationErrors = await validateDataSchema(schema);
    setSchemaErrors(validationErrors);
    if (isEmpty(validationErrors) && sessionId) {
      onTestSchema(schema);
    }
  }, [schema, sessionId, onTestSchema, setSchemaErrors]);

  return (
    <Button
      variantColor="teal-500"
      icon="play"
      disabled={!schema || testingSchema}
      loading={testingSchema}
      onClick={handleClick}
      data-tid="validate-custom-schema"
    >
      {sessionId ? 'Validate & Test Schema' : 'Validate Syntax'}
    </Button>
  );
};

export default ValidateButton;
