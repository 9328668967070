/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { AbstractButton } from 'pouncejs';

interface ScaleButtonProps {
  title: string;
  selected: boolean;
  onClick: () => void;
}

const ScaleButton: React.FC<ScaleButtonProps> = ({ title, selected, onClick, ...rest }) => {
  return (
    <AbstractButton
      {...rest}
      borderRadius="pill"
      py={1}
      px={4}
      fontSize="small"
      backgroundColor={selected ? 'blue-400' : 'transparent'}
      _hover={!selected && { backgroundColor: 'navyblue-300' }}
      onClick={onClick}
    >
      {title}
    </AbstractButton>
  );
};

export default ScaleButton;
