/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import { Box, Flex, Tab, Text, BoxProps } from 'pouncejs';
import React from 'react';

interface DetailsPageTabProps extends BoxProps {
  //  isSelected & isFocused props are automatically passed by `TabList` and not by the developer.
  isSelected?: boolean;
  isFocused?: boolean;
  title: string;
  description: string;
  icon?: React.ReactNode;
}

const DetailsPageTab = ({
  isSelected,
  isFocused,
  title,
  description,
  icon,
  ...rest
}: DetailsPageTabProps) => {
  const tabStyles = { width: 1 };

  return (
    <Box {...rest}>
      <Tab {...tabStyles}>
        <Flex>
          <Box
            borderRadius="pill"
            width={4}
            backgroundColor={isSelected ? 'blue-300' : 'transparent'}
          />
          <Box width={1} pl={2}>
            <Flex justify="space-between" align="center">
              <Text textAlign="left" fontWeight="bold">
                {title}
              </Text>
            </Flex>
            {description && isSelected && (
              <Text mt={1} textAlign="left" fontSize="medium" color="gray-300">
                {description}
              </Text>
            )}
          </Box>
          {icon}
        </Flex>
      </Tab>
    </Box>
  );
};

export default DetailsPageTab;
