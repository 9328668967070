/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import { formatNumber } from 'Helpers/utils';
import RuleReplayedAlertDetailsEvents from 'Pages/ReplayedAlertDetails/RuleReplayedAlertDetails/RuleReplayedAlertDetailsEvents';
import React from 'react';
import { Box, Card, Flex, TabList, TabPanel, TabPanels, Tabs, Alert } from 'pouncejs';
import ErrorBoundary from 'Components/ErrorBoundary';
import { BorderedTab, BorderTabDivider } from 'Components/BorderedTab';
import invert from 'lodash/invert';
import useUrlParams from 'Hooks/useUrlParams';
import { AlertOriginReplayedRule } from 'Generated/schema';
import { useGetReplay } from 'Source/graphql/queries/getReplay.generated';
import { GetReplayedAlert } from 'Source/graphql/queries/getReplayedAlert.generated';
import RuleAlertDetailsInfo from 'Pages/AlertDetails/RuleAlertDetails/RuleAlertDetailsInfo/RuleAlertDetailsInfo';
import Skeleton from 'Pages/AlertDetails/Skeleton';
import AlertDetailsBanner from 'Pages/AlertDetails/RuleAlertDetails/RuleAlertDetailsBanner/RuleAlertDetailsBanner';

import { useGetRuleSummary } from 'Pages/AlertDetails/RuleAlertDetails/graphql/getRuleSummary.generated';

export interface RuleReplayedAlertDetailsUrlParams {
  section?: 'details' | 'events';
}

const sectionToTabIndex: Record<RuleReplayedAlertDetailsUrlParams['section'], number> = {
  details: 0,
  events: 1,
};

const tabIndexToSection = invert(sectionToTabIndex) as Record<
  number,
  RuleReplayedAlertDetailsUrlParams['section']
>;

const ReplayedAlertEventsViewMaxMatches = 1000 * 1000;

interface RuleReplayedAlertDetailsProps {
  alert: GetReplayedAlert['replayedAlert'];
  replayId: string;
}

const RuleReplayedAlertDetails: React.FC<RuleReplayedAlertDetailsProps> = ({ replayId, alert }) => {
  const { urlParams, updateUrlParams } = useUrlParams<RuleReplayedAlertDetailsUrlParams>();

  const alertOrigin = alert.origin as AlertOriginReplayedRule;
  const { data: ruleData, loading: ruleLoading, error: ruleError } = useGetRuleSummary({
    variables: { input: { id: alertOrigin.ruleId } },
    context: {
      failSilently: true,
    },
  });

  const { data: replayData, loading: replayLoading, error: replayError } = useGetReplay({
    variables: {
      id: replayId,
    },
  });

  const loading = ruleLoading || replayLoading;

  if (loading && !replayData) {
    return <Skeleton />;
  }

  const showEventsViewer =
    replayData?.replay.summary.matchesProcessedCount <= ReplayedAlertEventsViewMaxMatches;

  return (
    <Box as="article" mb={6}>
      <Flex direction="column" spacing={6}>
        <AlertDetailsBanner alert={alert} disableUpdateAlert />
        {replayError && <Alert title="error loading replay data" variant="error" />}
        <Card position="relative">
          <Tabs
            index={sectionToTabIndex[urlParams.section] || 0}
            onChange={index => updateUrlParams({ section: tabIndexToSection[index] })}
          >
            <Box px={2}>
              <TabList>
                <BorderedTab>Details</BorderedTab>
                <BorderedTab>Events</BorderedTab>
              </TabList>
            </Box>
            <BorderTabDivider />
            <Box p={6}>
              <TabPanels>
                <TabPanel data-testid="alert-details-tabpanel">
                  <ErrorBoundary>
                    <RuleAlertDetailsInfo alert={alert} rule={ruleError ? null : ruleData?.rule} />
                  </ErrorBoundary>
                </TabPanel>
                <TabPanel data-testid="alert-events-tabpanel">
                  <ErrorBoundary>
                    {showEventsViewer ? (
                      <RuleReplayedAlertDetailsEvents
                        alertId={alert.id}
                        replayId={replayId}
                        totalEvents={alert.origin.eventsMatched}
                      />
                    ) : (
                      <Box>
                        <Alert
                          title={`Previewing events for alerts generated by replays with more than ${formatNumber(
                            ReplayedAlertEventsViewMaxMatches
                          )} matches is not currently supported.`}
                          variant="warning"
                        />
                      </Box>
                    )}
                  </ErrorBoundary>
                </TabPanel>
              </TabPanels>
            </Box>
          </Tabs>
        </Card>
      </Flex>
    </Box>
  );
};

export default RuleReplayedAlertDetails;
