/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Card } from 'pouncejs';
import ReplayedAlertList from 'Components/ReplayedAlertList';
import { ReplayFull } from 'Source/graphql/fragments/ReplayFull.generated';
import ReplayStats from '../ReplayStats';

export type ReplayCompletedViewProps = {
  replay: ReplayFull;
};

const ReplayComplete: React.FC<ReplayCompletedViewProps> = ({ replay }) => {
  return (
    <>
      <ReplayStats replay={replay} />
      <Card variant="dark" p={4} mb={4}>
        <ReplayedAlertList replayId={replay.id} detectionId={replay.detectionId} />
      </Card>
    </>
  );
};

export default React.memo(ReplayComplete);
