/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Box, Card, Flex, Link } from 'pouncejs';
import { Link as RRLink } from 'react-router-dom';
import urls from 'Source/urls';
import NoResultsFound from 'Components/NoResultsFound';
import { GetOrganizationStats } from '../graphql/getOrganizationStats.generated';

interface TopFailingResourcesProps {
  resources: GetOrganizationStats['organizationStats']['topFailingResources'];
}

const TopFailingResources: React.FC<TopFailingResourcesProps> = ({ resources }) => {
  if (!resources.length) {
    return (
      <Box my={8}>
        <NoResultsFound />
      </Box>
    );
  }

  return (
    <Flex direction="column" spacing={2} overflow="hidden" data-testid="top-failing-resources">
      {resources.map(resource => (
        <Card variant="dark" key={resource.id} p={4} overflow="hidden">
          <Flex>
            <Link
              as={RRLink}
              to={urls.data.resources.details(resource.id)}
              truncated
              lineHeight="relaxed"
            >
              {resource.id}
            </Link>
          </Flex>
        </Card>
      ))}
    </Flex>
  );
};

export default TopFailingResources;
