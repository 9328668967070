/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Alert, Button } from 'pouncejs';
import { extractErrorMessage } from 'Helpers/utils';
import Panel from 'Components/Panel';
import useSidesheet from 'Hooks/useSidesheet';
import { SIDESHEETS } from 'Components/utils/Sidesheet';
import { compose } from 'Helpers/compose';
import RoleRestrictedAccess from 'Components/utils/RoleRestrictedAccess';
import withRoleRestrictedAccess from 'Hoc/withRoleRestrictedAccess';
import { Permission } from 'Generated/schema';
import Page403 from 'Pages/403';
import withSEO from 'Hoc/withSEO';
import { useListUsersRolesSaml } from './graphql/listUsersRolesSaml.generated';
import ListUsersTable from './ListUsersTable';
import Skeleton from './Skeleton';

const ListUsersPage = () => {
  const { showSidesheet } = useSidesheet();
  const { loading, error, data } = useListUsersRolesSaml();

  if (loading && !data) {
    return <Skeleton />;
  }

  if (error) {
    return (
      <Alert
        variant="error"
        title="Couldn't load users"
        description={
          extractErrorMessage(error) ||
          'There was an error when performing your request, please contact support@runpanther.io'
        }
      />
    );
  }

  return (
    <Panel
      data-tracking-page="list-users"
      title="Users"
      actions={
        <RoleRestrictedAccess allowedPermissions={[Permission.UserModify]}>
          <Button
            data-tid="invite-new-user"
            icon="add-user"
            onClick={() => showSidesheet({ sidesheet: SIDESHEETS.USER_INVITATION })}
          >
            Invite User
          </Button>
        </RoleRestrictedAccess>
      }
    >
      <ListUsersTable users={data.users} />
    </Panel>
  );
};

export default compose(
  withSEO({ title: 'Users' }),
  withRoleRestrictedAccess({
    logic: 'AND',
    allowedPermissions: [Permission.UserRead, Permission.GeneralSettingsRead],
    fallback: <Page403 />,
  })
)(ListUsersPage);
