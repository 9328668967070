/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { FastField, useFormikContext } from 'formik';
import FormikRadio from 'Components/fields/Radio';
import { Box, Card, Grid, FormHelperText } from 'pouncejs';
import { SaveQueryFormValues } from './SavedQueryForm';
import ScheduledQueryFormFields from './ScheduledQueryFormFields';
import CronQueryFormFields from './CronQueryFormFields';

const ScheduledQueryForm: React.FC = () => {
  const { values } = useFormikContext<SaveQueryFormValues>();
  return values?.isScheduled ? (
    <>
      <Card variant="dark" p={4}>
        <Grid align="center" templateColumns="2fr 4fr" alignItems="center">
          <FastField
            as={FormikRadio}
            name="type"
            value="period"
            label="Period"
            aria-describedby="saved-type-period"
          />
          <FormHelperText id="saved-type-period" mt={1}>
            Select if your query should run in fixed time intervals.
          </FormHelperText>
        </Grid>
        {values.type === 'period' && (
          <Box mt={4}>
            <ScheduledQueryFormFields />
          </Box>
        )}
      </Card>
      <Card variant="dark" p={4}>
        <Grid align="center" templateColumns="2fr 4fr" alignItems="center">
          <FastField
            as={FormikRadio}
            name="type"
            value="cron"
            label="Cron Expression"
            aria-describedby="saved-type-cron"
          />
          <FormHelperText id="saved-type-cron" mt={1}>
            Select if your query should run repeatedly at specific dates.
          </FormHelperText>
        </Grid>
        {values.type === 'cron' && (
          <Box mt={4}>
            <CronQueryFormFields />
          </Box>
        )}
      </Card>
    </>
  ) : null;
};

export default ScheduledQueryForm;
