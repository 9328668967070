/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import * as Types from '../../../../__generated__/schema';

import { GraphQLError } from 'graphql';
import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';

export type GetReplayedAlertEventsVariables = {
  alertId: Types.Scalars['ID'];
  replayId: Types.Scalars['ID'];
  eventsInput: Types.GetAlertEventsInput;
};

export type GetReplayedAlertEvents = {
  replayedAlert: Pick<Types.Alert, 'id'> & {
    origin: { events?: Types.Maybe<Pick<Types.AlertEvents, 'items' | 'lastEvaluatedKey'>> };
  };
};

export const GetReplayedAlertEventsDocument = gql`
  query GetReplayedAlertEvents($alertId: ID!, $replayId: ID!, $eventsInput: GetAlertEventsInput!) {
    replayedAlert(replayId: $replayId, alertId: $alertId) {
      id
      origin {
        ... on AlertOriginReplayedRule {
          events(input: $eventsInput) {
            items
            lastEvaluatedKey
          }
        }
      }
    }
  }
`;

/**
 * __useGetReplayedAlertEvents__
 *
 * To run a query within a React component, call `useGetReplayedAlertEvents` and pass it any options that fit your needs.
 * When your component renders, `useGetReplayedAlertEvents` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReplayedAlertEvents({
 *   variables: {
 *      alertId: // value for 'alertId'
 *      replayId: // value for 'replayId'
 *      eventsInput: // value for 'eventsInput'
 *   },
 * });
 */
export function useGetReplayedAlertEvents(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetReplayedAlertEvents,
    GetReplayedAlertEventsVariables
  >
) {
  return ApolloReactHooks.useQuery<GetReplayedAlertEvents, GetReplayedAlertEventsVariables>(
    GetReplayedAlertEventsDocument,
    baseOptions
  );
}
export function useGetReplayedAlertEventsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetReplayedAlertEvents,
    GetReplayedAlertEventsVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<GetReplayedAlertEvents, GetReplayedAlertEventsVariables>(
    GetReplayedAlertEventsDocument,
    baseOptions
  );
}
export type GetReplayedAlertEventsHookResult = ReturnType<typeof useGetReplayedAlertEvents>;
export type GetReplayedAlertEventsLazyQueryHookResult = ReturnType<
  typeof useGetReplayedAlertEventsLazyQuery
>;
export type GetReplayedAlertEventsQueryResult = ApolloReactCommon.QueryResult<
  GetReplayedAlertEvents,
  GetReplayedAlertEventsVariables
>;
export function mockGetReplayedAlertEvents({
  data,
  variables,
  errors,
}: {
  data: GetReplayedAlertEvents;
  variables?: GetReplayedAlertEventsVariables;
  errors?: GraphQLError[];
}) {
  return {
    request: { query: GetReplayedAlertEventsDocument, variables },
    result: { data, errors },
  };
}
