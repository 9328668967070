/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { AbstractButton, Box, Text } from 'pouncejs';

interface ColumnListItemProps {
  isSelected: boolean;
  isPristine: boolean;
  name: string;
  type: string;
  description?: string;
  onClick: (name: string) => void;
}

const ColumnListItem: React.FC<ColumnListItemProps> = ({
  isSelected,
  isPristine,
  name,
  type,
  description,
  onClick,
}) => {
  return (
    <Box as="li" wordBreak="break-all" key={name} mx={6}>
      <AbstractButton
        p={2}
        outline="none"
        textAlign="left"
        opacity={isSelected || isPristine ? 1 : 0.3}
        onClick={() => onClick(name)}
        _hover={{
          opacity: 1,
        }}
      >
        {name}
        <Text as="i" fontWeight="bold" ml={1} title={type} color="navyblue-100">
          ({type})
        </Text>
        {isSelected && (
          <Text fontSize="x-small" mt={2} wordBreak="normal">
            {description || 'No description available'}
          </Text>
        )}
      </AbstractButton>
    </Box>
  );
};

export default React.memo(ColumnListItem);
