/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Box, Card, Flex, Icon, Link, Text } from 'pouncejs';
import { IntegrationItemHealthStatus, Permission, SourceHealthTypesEnum } from 'Generated/schema';
import { slugify } from 'Helpers/utils';
import urls from 'Source/urls';
import RoleRestrictedAccess from 'Components/utils/RoleRestrictedAccess';
import { LogSourceType } from 'Source/constants';
import SourceHealthRawErrorMessage from './SourceHealthRawErrorMessage';

interface SourceHealthWarningProps {
  unhealthyMetrics: IntegrationItemHealthStatus[];
  title: string;
  integrationId?: string;
  sourceType?: LogSourceType;
  withActions?: boolean;
}

const SourceHealthWarning: React.FC<SourceHealthWarningProps> = ({
  unhealthyMetrics,
  title,
  integrationId,
  withActions = false,
  sourceType,
}) => {
  return (
    <Card variant="darker" border="1px solid" borderColor="red-300" p={4}>
      <Flex justify="space-between" align="center">
        <Box maxWidth="85%">
          <Flex spacing={1} align="center" pb={2}>
            <Icon size="large" type="alert-circle-filled" color="red-300" />
            <Text fontWeight="bold" fontSize="medium" color="red-300">
              {title}
            </Text>
          </Flex>
          <Flex direction="column" spacing={1} pl={6} as="ul">
            {unhealthyMetrics.map(healthMetric => {
              const id = slugify(healthMetric.message);
              // In case the rawErrorMessage & the message returned from the BE are the same,
              // we don't want to display duplicate information.
              const showRawErrorMessage =
                !!healthMetric.rawErrorMessage &&
                healthMetric.rawErrorMessage !== healthMetric.message;

              // Redirect to health page only for the logProcessingErrorsStatus metric
              // have a look here: https://github.com/panther-labs/panther-enterprise/blob/7d80b80dd7cc6cb3dc00a8665b5d6e250baeb413/api/lambda/source/models/integration.go#L531
              // Why we only use logProcessingErrorsStatus
              const redirectToHealthPage =
                healthMetric.type === SourceHealthTypesEnum.LogProcessingErrorsStatus ||
                healthMetric.type === SourceHealthTypesEnum.ClassificationFailuresStatus;
              return (
                <Flex spacing={1} key={id} align="start" as="li">
                  <Icon
                    aria-labelledby={id}
                    size="medium"
                    type="close-outline"
                    color="red-300"
                    aria-label="Failing"
                  />
                  <Box mt="1px" fontSize="medium">
                    <Text
                      as="span"
                      fontWeight="bold"
                      id={id}
                      aria-describedby={`${id}-description`}
                    >
                      {healthMetric.message}
                    </Text>
                    {withActions && redirectToHealthPage && (
                      <RoleRestrictedAccess allowedPermissions={[Permission.AlertRead]}>
                        <Text as="span"> - </Text>
                        <Link
                          aria-label={`link to alerts & error page for source ${integrationId}`}
                          href={`${urls.integrations.logSources.details(
                            integrationId,
                            sourceType
                          )}?tab=health`}
                        >
                          View Alerts
                        </Link>
                      </RoleRestrictedAccess>
                    )}
                    {showRawErrorMessage && (
                      <SourceHealthRawErrorMessage rawErrorMessage={healthMetric.rawErrorMessage} />
                    )}
                  </Box>
                </Flex>
              );
            })}
          </Flex>
        </Box>
      </Flex>
    </Card>
  );
};

export default React.memo(SourceHealthWarning);
