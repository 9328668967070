/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import { AbstractButton, Box, Collapse, Flex, FormHelperText, Text } from 'pouncejs';
import { FastField, Field, useFormikContext } from 'formik';
import FormikTextInput from 'Components/fields/TextInput';
import React from 'react';
import logo from 'Assets/aws-minimal-logo.svg';
import { AWS_REGIONS, RESOURCE_TYPES } from 'Source/constants';
import { WizardPanel } from 'Components/Wizard';
import FormikMultiCombobox from 'Components/fields/MultiComboBox';
import EditIntegrationActions from 'Pages/Integrations/components/EditIntegrationActions';
import { ComplianceSourceWizardValues } from '../ComplianceSourceWizard';

const EMPTY_ARRAY = [];

const SourceConfigurationPanel = () => {
  const { initialValues, values, dirty, errors } = useFormikContext<ComplianceSourceWizardValues>();

  const [advancedOptionsVisible, setAdvancedOptionsVisibility] = React.useState(
    values.regionIgnoreList.length > 0 ||
      values.resourceRegexIgnoreList.length > 0 ||
      values.resourceRegexIgnoreList.length > 0
  );
  const editMode = !!initialValues.integrationId;

  const shouldContinue = Boolean(dirty && !errors.integrationLabel && !errors.awsAccountId);

  return (
    <WizardPanel width={655} mx="auto">
      <WizardPanel.Heading
        title={editMode ? 'Update your Cloud Account' : 'First things first'}
        subtitle={
          <Text as="span" color="gray-300" fontWeight="normal">
            {editMode
              ? 'Feel free to make any changes to your Cloud Account'
              : 'Let’s configure your Cloud Account'}
          </Text>
        }
        divider={null}
        logo={logo}
      />
      <Flex direction="column" spacing={4}>
        <Field
          name="integrationLabel"
          as={FormikTextInput}
          label="Name"
          placeholder="A nickname for the AWS account you're onboarding"
          required
        />
        <Field
          name="awsAccountId"
          as={FormikTextInput}
          label="AWS Account ID"
          placeholder="Your 12-digit AWS Account ID"
          required
          disabled={editMode}
        />
      </Flex>
      <Flex direction="column" align="center" mt={6}>
        <AbstractButton
          fontSize="medium"
          color="teal-400"
          _hover={{ color: 'teal-300' }}
          onClick={() => setAdvancedOptionsVisibility(!advancedOptionsVisible)}
        >
          {advancedOptionsVisible ? 'Hide Advanced Options' : 'Show Advanced Options'}
        </AbstractButton>
        <Collapse
          open={advancedOptionsVisible}
          // @ts-ignore - width property
          width={1}
        >
          <Flex direction="column" spacing={6} my={6}>
            <Box as="fieldset">
              <FastField
                as={FormikMultiCombobox}
                searchable
                label="Exclude AWS Regions"
                name="regionIgnoreList"
                items={AWS_REGIONS}
                aria-describedby="exclude-aws-regions-description"
              />
              <FormHelperText id="exclude-aws-regions-description" mt={2}>
                Disable Cloud Security Scanning for certain AWS regions
              </FormHelperText>
            </Box>
            <Box as="fieldset">
              <FastField
                as={FormikMultiCombobox}
                searchable
                label="Exclude Resource Types"
                name="resourceTypeIgnoreList"
                items={RESOURCE_TYPES}
                aria-describedby="exclude-resourceTypes-description"
              />
              <FormHelperText id="exclude-resourceTypes-description" mt={2}>
                Disable Cloud Security Scanning for certain Resource types
              </FormHelperText>
            </Box>
            <Box as="fieldset">
              <FastField
                as={FormikMultiCombobox}
                label="Exclude Resources by Regex"
                name="resourceRegexIgnoreList"
                allowAdditions
                searchable
                items={EMPTY_ARRAY}
                aria-describedby="exclude-resources-by-regex"
              />
              <FormHelperText id="exclude-resources-by-regex" mt={2}>
                Disable Cloud Security Scanning for resources matching the regex provided
              </FormHelperText>
            </Box>
          </Flex>
        </Collapse>
      </Flex>
      <WizardPanel.Actions>
        {editMode ? (
          <EditIntegrationActions />
        ) : (
          <WizardPanel.ActionNext disabled={!shouldContinue}>Continue Setup</WizardPanel.ActionNext>
        )}
      </WizardPanel.Actions>
    </WizardPanel>
  );
};

export default SourceConfigurationPanel;
