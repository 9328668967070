/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import withSEO from 'Hoc/withSEO';
import { compose } from 'Helpers/compose';
import withRoleRestrictedAccess from 'Hoc/withRoleRestrictedAccess';
import { Permission } from 'Generated/schema';
import Page403 from 'Pages/403';
import { SlackLogTypesEnterprise, SlackPlansEnum } from 'Source/constants';
import SlackLogSourceWizard, { SlackLogsSourceWizardValues } from '../SlackLogSourceWizard';
import { useAddSlackLogSource } from './graphql/addSlackLogSource.generated';

const initialValues = {
  integrationLabel: '',
  clientId: '',
  clientSecret: '',
  logTypes: SlackLogTypesEnterprise,
  plan: SlackPlansEnum.enterprise,
};

const CreateSlackLogSource: React.FC = () => {
  const [addSlackLogSource] = useAddSlackLogSource({
    update: (cache, { data }) => {
      cache.modify({
        fields: {
          logSources: (queryData, { toReference }) => {
            const addedIntegrationCacheRef = toReference(data.addLogPullingIntegration);
            return queryData
              ? [addedIntegrationCacheRef, ...queryData]
              : [addedIntegrationCacheRef];
          },
        },
      });
    },
  });

  const handleSubmit = React.useCallback(async (values: SlackLogsSourceWizardValues) => {
    try {
      const { data } = await addSlackLogSource({
        variables: {
          input: {
            integrationLabel: values.integrationLabel,
            pullerConfig: {
              logTypes: values.logTypes,
              slack: {
                clientId: values.clientId,
                clientSecret: values.clientSecret,
              },
            },
          },
        },
      });
      return data.addLogPullingIntegration;
    } catch (err) {
      return err as Error;
    }
    // FIXME: look into missing hook dependencies or using a ref
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <SlackLogSourceWizard initialValues={initialValues} onSubmit={handleSubmit} />;
};

export default compose(
  withSEO({ title: 'New Slack Source' }),
  withRoleRestrictedAccess({
    allowedPermissions: [Permission.LogSourceModify],
    fallback: <Page403 />,
  })
)(CreateSlackLogSource);
