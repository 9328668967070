/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import * as Types from '../../../__generated__/schema';

import { ReplayFull } from '../fragments/ReplayFull.generated';
import { GraphQLError } from 'graphql';
import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';

export type GetReplayVariables = {
  id: Types.Scalars['ID'];
};

export type GetReplay = { replay: ReplayFull };

export const GetReplayDocument = gql`
  query GetReplay($id: ID!) {
    replay(id: $id) {
      ...ReplayFull
    }
  }
  ${ReplayFull}
`;

/**
 * __useGetReplay__
 *
 * To run a query within a React component, call `useGetReplay` and pass it any options that fit your needs.
 * When your component renders, `useGetReplay` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReplay({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetReplay(
  baseOptions?: ApolloReactHooks.QueryHookOptions<GetReplay, GetReplayVariables>
) {
  return ApolloReactHooks.useQuery<GetReplay, GetReplayVariables>(GetReplayDocument, baseOptions);
}
export function useGetReplayLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetReplay, GetReplayVariables>
) {
  return ApolloReactHooks.useLazyQuery<GetReplay, GetReplayVariables>(
    GetReplayDocument,
    baseOptions
  );
}
export type GetReplayHookResult = ReturnType<typeof useGetReplay>;
export type GetReplayLazyQueryHookResult = ReturnType<typeof useGetReplayLazyQuery>;
export type GetReplayQueryResult = ApolloReactCommon.QueryResult<GetReplay, GetReplayVariables>;
export function mockGetReplay({
  data,
  variables,
  errors,
}: {
  data: GetReplay;
  variables?: GetReplayVariables;
  errors?: GraphQLError[];
}) {
  return {
    request: { query: GetReplayDocument, variables },
    result: { data, errors },
  };
}
