/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import { AlertStatus, AlertsInput } from 'Generated/schema';
import React from 'react';
import FormikCombobox from 'Components/fields/ComboBox';
import { FastField, Formik, Form } from 'formik';
import { Box, Flex, Text, useSnackbar } from 'pouncejs';
import SubmitButton from 'Components/buttons/SubmitButton';
import useRequestParamsWithoutPagination from 'Hooks/useRequestParamsWithoutPagination';
import { useSelect } from 'Components/utils/SelectContext';
import { extractErrorMessage } from 'Helpers/utils';
import { useUpdateAlertStatusByFilter } from 'Source/graphql/queries/updateAlertStatusByFilter.generated';
import { filterItemToString, alertStatusOptions } from './ListAlertDefaultUpdater';

const initialValues = {
  status: AlertStatus.Resolved,
};

interface ListAlertSearchUpdaterFormValues {
  status: AlertStatus;
}

const ListAlertSearchUpdater: React.FC = () => {
  const { resetSelection } = useSelect();
  const { requestParams } = useRequestParamsWithoutPagination<AlertsInput>();
  const { pushSnackbar } = useSnackbar();

  const [updateAlertStatusByFilter] = useUpdateAlertStatusByFilter({
    onCompleted: () => {
      resetSelection();
      pushSnackbar({
        variant: 'success',
        title: 'Status updates scheduled and will continue in the background',
      });
    },
    onError: error => {
      pushSnackbar({
        variant: 'error',
        title: 'Failed to update all alerts in search. Please refresh and try again.',
        description: extractErrorMessage(error),
      });
    },
  });

  const onSubmit = React.useCallback(
    (values: ListAlertSearchUpdaterFormValues) => {
      return updateAlertStatusByFilter({
        variables: {
          input: {
            filter: requestParams,
            status: values.status,
          },
        },
      });
    },
    [requestParams, updateAlertStatusByFilter]
  );

  return (
    <Flex justify="flex-end" align="center">
      <Formik<ListAlertSearchUpdaterFormValues> initialValues={initialValues} onSubmit={onSubmit}>
        <Form>
          <Flex spacing={4} align="center">
            <Text>All in search selected</Text>
            <Box width={150}>
              <FastField
                data-tid="mass-alert-status-update-with-filter-combobox"
                name="status"
                as={FormikCombobox}
                items={alertStatusOptions}
                itemToString={filterItemToString}
                label="Status"
                placeholder="Select statuses"
                showClearSelectionControl={false}
              />
            </Box>
            <SubmitButton
              data-tid="mass-alert-status-update-apply"
              variantColor="violet-400"
              allowPristineSubmission
            >
              Apply
            </SubmitButton>
          </Flex>
        </Form>
      </Formik>
    </Flex>
  );
};

export default React.memo(ListAlertSearchUpdater);
