/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import * as Types from '../../../../../__generated__/schema';

import { GraphQLError } from 'graphql';
import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';

export type DeletePackSourcesVariables = {
  input: Array<Types.DeleteEntry>;
};

export type DeletePackSources = Pick<Types.Mutation, 'deleteAnalysisPackSource'>;

export const DeletePackSourcesDocument = gql`
  mutation DeletePackSources($input: [DeleteEntry!]!) {
    deleteAnalysisPackSource(input: $input)
  }
`;
export type DeletePackSourcesMutationFn = ApolloReactCommon.MutationFunction<
  DeletePackSources,
  DeletePackSourcesVariables
>;

/**
 * __useDeletePackSources__
 *
 * To run a mutation, you first call `useDeletePackSources` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePackSources` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePackSources, { data, loading, error }] = useDeletePackSources({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeletePackSources(
  baseOptions?: ApolloReactHooks.MutationHookOptions<DeletePackSources, DeletePackSourcesVariables>
) {
  return ApolloReactHooks.useMutation<DeletePackSources, DeletePackSourcesVariables>(
    DeletePackSourcesDocument,
    baseOptions
  );
}
export type DeletePackSourcesHookResult = ReturnType<typeof useDeletePackSources>;
export type DeletePackSourcesMutationResult = ApolloReactCommon.MutationResult<DeletePackSources>;
export type DeletePackSourcesMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeletePackSources,
  DeletePackSourcesVariables
>;
export function mockDeletePackSources({
  data,
  variables,
  errors,
}: {
  data: DeletePackSources;
  variables?: DeletePackSourcesVariables;
  errors?: GraphQLError[];
}) {
  return {
    request: { query: DeletePackSourcesDocument, variables },
    result: { data, errors },
  };
}
