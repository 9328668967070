/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Box, FadeIn, SimpleGrid } from 'pouncejs';
import Panel from 'Components/Panel';
import TablePlaceholder from 'Components/TablePlaceholder';

const CloudSecurityPageSkeleton: React.FC = () => {
  return (
    <FadeIn duration={400}>
      <SimpleGrid as="article" columns={2} spacingX={5} spacingY={6} mb={6}>
        <Panel title="Resource Status">
          <Box height={314} px={1}>
            <TablePlaceholder rowHeight={24} rowCount={8} />
          </Box>
        </Panel>
        <Panel title="Policy Fail Breakdown">
          <Box height={314} px={1}>
            <TablePlaceholder rowHeight={24} rowCount={8} />
          </Box>
        </Panel>
        <Panel title="Top 5 Failing Policies">
          <TablePlaceholder rowHeight={42} />
        </Panel>
        <Panel title="Top 5 Failing Resources">
          <TablePlaceholder rowHeight={42} />
        </Panel>
      </SimpleGrid>
    </FadeIn>
  );
};

export default CloudSecurityPageSkeleton;
