/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import * as Types from '../../../__generated__/schema';

import { GlobalHelperTeaser } from '../fragments/GlobalHelperTeaser.generated';
import { GraphQLError } from 'graphql';
import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';

export type ListGlobalHelpersVariables = {
  input: Types.ListGlobalHelpersInput;
};

export type ListGlobalHelpers = {
  listGlobalHelpers: {
    globals?: Types.Maybe<Array<Types.Maybe<GlobalHelperTeaser>>>;
    paging?: Types.Maybe<Pick<Types.PagingData, 'totalPages' | 'thisPage' | 'totalItems'>>;
  };
};

export const ListGlobalHelpersDocument = gql`
  query ListGlobalHelpers($input: ListGlobalHelpersInput!) {
    listGlobalHelpers(input: $input) {
      globals {
        ...GlobalHelperTeaser
      }
      paging {
        totalPages
        thisPage
        totalItems
      }
    }
  }
  ${GlobalHelperTeaser}
`;

/**
 * __useListGlobalHelpers__
 *
 * To run a query within a React component, call `useListGlobalHelpers` and pass it any options that fit your needs.
 * When your component renders, `useListGlobalHelpers` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListGlobalHelpers({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useListGlobalHelpers(
  baseOptions?: ApolloReactHooks.QueryHookOptions<ListGlobalHelpers, ListGlobalHelpersVariables>
) {
  return ApolloReactHooks.useQuery<ListGlobalHelpers, ListGlobalHelpersVariables>(
    ListGlobalHelpersDocument,
    baseOptions
  );
}
export function useListGlobalHelpersLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ListGlobalHelpers, ListGlobalHelpersVariables>
) {
  return ApolloReactHooks.useLazyQuery<ListGlobalHelpers, ListGlobalHelpersVariables>(
    ListGlobalHelpersDocument,
    baseOptions
  );
}
export type ListGlobalHelpersHookResult = ReturnType<typeof useListGlobalHelpers>;
export type ListGlobalHelpersLazyQueryHookResult = ReturnType<typeof useListGlobalHelpersLazyQuery>;
export type ListGlobalHelpersQueryResult = ApolloReactCommon.QueryResult<
  ListGlobalHelpers,
  ListGlobalHelpersVariables
>;
export function mockListGlobalHelpers({
  data,
  variables,
  errors,
}: {
  data: ListGlobalHelpers;
  variables?: ListGlobalHelpersVariables;
  errors?: GraphQLError[];
}) {
  return {
    request: { query: ListGlobalHelpersDocument, variables },
    result: { data, errors },
  };
}
