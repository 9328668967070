/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Flex } from 'pouncejs';
import LimitItemDisplay from 'Components/LimitItemDisplay/LimitItemDisplay';
import BulletedValue from 'Components/BulletedValue';

interface BulletedValueListProps {
  values: string[];
  limit?: number;
}

const BulletedValueList: React.FC<BulletedValueListProps> = ({ values, limit = 1000 }) => {
  return (
    <Flex align="center" spacing={2} flexWrap="wrap">
      <LimitItemDisplay limit={limit}>
        {values.map(logType => (
          <BulletedValue key={logType} value={logType} />
        ))}
      </LimitItemDisplay>
    </Flex>
  );
};

export default BulletedValueList;
