/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import SelectSavedQueryForm, {
  SelectSavedQueryFormValues,
} from 'Components/forms/SelectSavedQueryForm';
import useUrlParams from 'Hooks/useUrlParams';
import { SortDirEnum } from 'Generated/schema';
import useModal from 'Hooks/useModal';

const initialValues: SelectSavedQueryFormValues = {
  contains: '',
  sortDir: SortDirEnum.Descending,
};

const SelectSavedQueryModal: React.FC = () => {
  const { setUrlParams } = useUrlParams();

  const { hideModal } = useModal();
  const onSubmit = React.useCallback(
    (selectedQueryId: string) => {
      setUrlParams({ savedQueryId: selectedQueryId });
      hideModal();
    },
    [setUrlParams, hideModal]
  );

  return <SelectSavedQueryForm onSubmit={onSubmit} initialValues={initialValues} />;
};

export default SelectSavedQueryModal;
