/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import Auth from '@aws-amplify/auth';
import dayjs from 'dayjs';
import { USER_INFO_STORAGE_KEY, PANTHER_CONFIG } from 'Source/constants';
import storage from 'Helpers/storage';
import { Permission } from 'Generated/schema';
import {
  CognitoUser,
  CognitoAccessToken,
  CognitoIdToken,
  CognitoRefreshToken,
  CognitoUserSession,
} from 'amazon-cognito-identity-js';
import urls from 'Source/urls';
import useEffectOnUpdates from 'Hooks/useEffectOnUpdates';

// Challenge names from Cognito from
// https://docs.aws.amazon.com/cognito-user-identity-pools/latest/APIReference/API_RespondToAuthChallenge.html#API_RespondToAuthChallenge_RequestSyntax
export enum CHALLENGE_NAMES {
  MFA_SETUP = 'MFA_SETUP',
  NEW_PASSWORD_REQUIRED = 'NEW_PASSWORD_REQUIRED',
  SOFTWARE_TOKEN_MFA = 'SOFTWARE_TOKEN_MFA',
}

interface AuthError {
  /** unique error code */
  code: string;

  /** verbose exception that happened */
  message: string;

  /** optional | name of the exception, usually just the code itself */
  name?: string;
}

interface UserInfoIdentities {
  userId?: string;
  providerName?: string;
  providerType?: string;
}

export interface EnhancedCognitoUser extends CognitoUser {
  username: string;
  challengeParam: {
    userAttributes: {
      /* eslint-disable  camelcase  */
      email: string;
      given_name?: string;
      family_name?: string;
      /* eslint-enable  camelcase  */
    };
  };
  challengeName?: CHALLENGE_NAMES;
  attributes: {
    /* eslint-disable  camelcase  */
    email: string;
    email_verified: boolean;
    family_name?: string;
    given_name?: string;
    sub: string;
    identities?: string;
    /* eslint-enable  camelcase  */
  };
  signInUserSession?: {
    accessToken?: {
      payload: {
        'cognito:users'?: string[];
        // eslint-disable-next-line camelcase
        auth_time: number;
      };
    };
  };
}

interface SingleSignOnSettings {
  enabled: boolean;
}

export type UserInfo = {
  id: string;
  email: string;
  emailVerified: boolean;
  givenName?: string;
  familyName?: string;
  permissions: Permission[];
  identities?: [UserInfoIdentities];
  // Unix timestamp representing the time when the original authentication occurred
  authenticationTime: number;
};

interface SignOutParams {
  global?: boolean;
  onSuccess?: () => void;
  onError?: (err: AuthError) => void;
}

interface SignInParams {
  email: string;
  password: string;
  onSuccess?: () => void;
  onError?: (err: AuthError) => void;
}

interface SingleSignOnParams {
  code: string;
  onSuccess?: () => void;
  onError?: (err: AuthError) => void;
}

interface SingleSignOnSettingRetrievalParams {
  onSuccess?: (data: SingleSignOnSettings) => void;
  onError?: (err: AuthError) => void;
}

interface ConfirmSignInParams {
  mfaCode: string;
  onSuccess?: () => void;
  onError?: (err: AuthError) => void;
}

interface VerifyTotpSetupParams {
  mfaCode: string;
  onSuccess?: () => void;
  onError?: (err: AuthError) => void;
}

interface SetNewPasswordParams {
  newPassword: string;
  onSuccess?: () => void;
  onError?: (err: AuthError) => void;
}

interface ChangePasswordParams {
  oldPassword: string;
  newPassword: string;
  onSuccess?: () => void;
  onError?: (err: AuthError) => void;
}

interface ResetPasswordParams {
  token: string;
  email: string;
  newPassword: string;
  onSuccess?: () => void;
  onError?: (err: AuthError) => void;
}

interface ForgotPasswordParams {
  email: string;
  onSuccess?: () => void;
  onError?: (err: AuthError) => void;
}

interface RefetchUserInfoParams {
  onSuccess?: () => void;
  onError?: (err: AuthError) => void;
}

/*
  We intentionaly use `undefined` and `null` in the interface below to showcase the possible values
 */
export interface AuthContextValue {
  isAuthenticated: boolean | undefined;
  currentAuthChallengeName: CHALLENGE_NAMES | null;
  userInfo: UserInfo | null;
  signIn: (params: SignInParams) => Promise<void>;
  singleSignOn: (params: SingleSignOnParams) => void;
  confirmSignIn: (params: ConfirmSignInParams) => Promise<void>;
  refetchUserInfo: (params?: RefetchUserInfoParams) => Promise<void>;
  setNewPassword: (params: SetNewPasswordParams) => Promise<void>;
  verifyTotpSetup: (params: VerifyTotpSetupParams) => Promise<void>;
  requestTotpSecretCode: () => Promise<string>;
  retrieveSsoSettings: (params: SingleSignOnSettingRetrievalParams) => Promise<void>;
  signOut: (params?: SignOutParams) => Promise<void>;
  changePassword: (params: ChangePasswordParams) => Promise<void>;
  resetPassword: (params: ResetPasswordParams) => Promise<void>;
  forgotPassword: (params: ForgotPasswordParams) => Promise<void>;
}

const AuthContext = React.createContext<AuthContextValue>(undefined);

// We check if there was a previous session for this user already present. We use that to
// decide whether the user should be considered authenticated on mount time.
const checkPreviousUserSessionExistence = () => {
  const userSessionExists = Boolean(
    storage.local.read(
      `CognitoIdentityServiceProvider.${PANTHER_CONFIG.WEB_APPLICATION_USER_POOL_CLIENT_ID}.LastAuthUser`
    )
  );
  if (!userSessionExists) {
    return false;
  }
  const userInfo = storage.local.read<UserInfo>(USER_INFO_STORAGE_KEY);
  if (!userInfo?.authenticationTime) {
    return false;
  }
  // Manually check if the refresh token has expired by the time the user revisits.
  return dayjs
    .unix(userInfo.authenticationTime)
    .add(PANTHER_CONFIG.REFRESH_TOKEN_EXPIRATION_HOURS, 'hours')
    .isAfter(dayjs(), 'minute');
};

const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  // Stores whether the system should consider the current user as logged-in or not. This can be
  // true without `authUser` being present, since `authUser` comes asynchronously from Cognito, thus
  // it's *always* initially `null`.
  const [isAuthenticated, setAuthenticated] = React.useState(checkPreviousUserSessionExistence());
  // Stores the currently authenticated user of the app
  const [authUser, setAuthUser] = React.useState<EnhancedCognitoUser | null>(null);

  /*
   * Isolate the userInfo from the user. This is an object that will persist in our storage so that
   * we can boot up the user's information (name, token, etc.) the next time he visits the app. The
   * value changes whenever the cognito session changes
   */
  const userInfo = React.useMemo<UserInfo>(() => {
    // if a user is present, derive the user info from him
    // Check if this is calculated
    if (authUser?.attributes) {
      // eslint-disable-next-line  camelcase
      const { family_name, given_name, email_verified, ...rest } = authUser.attributes;
      return {
        ...rest,
        id: authUser.username, // we want the ID to be the username for SSO support
        familyName: family_name,
        givenName: given_name,
        emailVerified: email_verified,
        identities: authUser?.attributes?.identities
          ? JSON.parse(authUser?.attributes?.identities)
          : [],
        permissions:
          authUser?.signInUserSession?.accessToken?.payload['cognito:groups'] ??
          ([] as Permission[]),
        authenticationTime: authUser?.signInUserSession?.accessToken?.payload['auth_time'],
      };
    }

    // if no user is present, attempt to return data from the stored session. This is true when
    // the request to get the cognito `authUser` is in flight and hasn't returned yet
    if (isAuthenticated) {
      return storage.local.read<UserInfo>(USER_INFO_STORAGE_KEY);
    }

    // if no prev session exists and the user is not logged-in, then there is no userInfo
    return null;
  }, [isAuthenticated, authUser]);

  /**
   * Every time the `userInfo` is updated, we want to store this value in our storage in order to
   * remember it for future logins. If we don't do that, then we don't have a way of knowing the
   * user on mount time.
   */
  React.useEffect(() => {
    if (userInfo) {
      storage.local.write(USER_INFO_STORAGE_KEY, userInfo);
    } else {
      storage.local.delete(USER_INFO_STORAGE_KEY);
    }
  }, [userInfo]);

  /**
   * @public
   * Check if the SSO is enabled for this account
   *
   */
  const retrieveSsoSettings = React.useCallback(
    async ({ onSuccess = () => {}, onError = () => {} }: SingleSignOnSettingRetrievalParams) => {
      try {
        const publicApiServer = `https://${PANTHER_CONFIG.PUBLIC_CORE_API_HOST}/saml`;

        // Default options are marked with *
        const response = await fetch(publicApiServer);

        const data = await response.json();
        if (data.error) {
          onError(data.error as AuthError);
        }

        onSuccess(data);
      } catch (err) {
        onError(err as AuthError);
      }
    },
    []
  );

  /**
   * @public
   * Signs the user in our system
   *
   */
  const signIn = React.useCallback(
    async ({ email, password, onSuccess = () => {}, onError = () => {} }: SignInParams) => {
      try {
        const signedInUser = await Auth.signIn(email, password);

        // We are forcing an attribute email, since Cognito doesn't return the email of the user
        // until they pass the MFA challenge.
        signedInUser.attributes = { email };
        setAuthUser(signedInUser);

        onSuccess();
      } catch (err) {
        onError(err as AuthError);
      }
    },
    []
  );

  /**
   * @public
   * Signs the user in using an external SSO provider
   *
   */
  const singleSignOn = React.useCallback(
    async ({ code, onSuccess = () => {}, onError = () => {} }: SingleSignOnParams) => {
      try {
        const authServer = `https://${PANTHER_CONFIG.WEB_APPLICATION_USER_POOL_HOST}/oauth2/token`;
        const payload =
          `grant_type=authorization_code` +
          `&client_id=${PANTHER_CONFIG.WEB_APPLICATION_USER_POOL_CLIENT_ID}` +
          `&code=${code}` +
          `&redirect_uri=${window.location.origin}${urls.account.auth.singleSignOn()}`;

        // Default options are marked with *
        const response = await fetch(authServer, {
          method: 'POST',
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
          cache: 'no-cache',
          credentials: 'omit',
          referrerPolicy: 'no-referrer',
          body: payload,
        });

        const data = await response.json();
        if (data.error) {
          onError(data.error as AuthError);
        }

        // Create a session instance using the tokens
        const session = new CognitoUserSession({
          IdToken: new CognitoIdToken({ IdToken: data.id_token }),
          RefreshToken: new CognitoRefreshToken({ RefreshToken: data.refresh_token }),
          AccessToken: new CognitoAccessToken({ AccessToken: data.access_token }),
        });

        const username = session.getIdToken().decodePayload()['cognito:username'];
        // Utilize the session instance and store them as a sign-in session in the localstorage
        // This is implemented by calling `cacheTokens()` in the Cognito SDK. This function is only
        // part of `setSignInUserSession`, which exists only as a method of a CognitoUser
        // @ts-ignore
        Auth.createCognitoUser(username).setSignInUserSession(session);

        // With the aforementioned session properly "stored' in our localstorage, fetch the user's
        // details from SDK
        const user = await Auth.currentAuthenticatedUser({ bypassCache: true });
        setAuthUser(user);

        onSuccess();
      } catch (err) {
        onError(err as AuthError);
      }
    },
    []
  );

  /**
   * @public
   * Signs the user out. Can be global sign out (all devices) or just local (this device only)
   *
   */
  const signOut = React.useCallback(
    ({ global = false, onSuccess = () => {}, onError = () => {} }: SignOutParams = {}) => {
      return Auth.signOut({ global })
        .then(onSuccess)
        .catch(onError)
        .finally(() => {
          setAuthUser(null);
        });
    },
    []
  );

  /**
   *
   * @public
   * Verifies that the user is not an imposter by verifying the TOTP challenge that the user was
   * presented with. This function verifies that the one-time password was indeed correct
   *
   */
  const confirmSignIn = React.useCallback(
    async ({ mfaCode, onSuccess = () => {}, onError = () => {} }: ConfirmSignInParams) => {
      try {
        await Auth.confirmSignIn(authUser, mfaCode, 'SOFTWARE_TOKEN_MFA');

        const confirmedUser = await Auth.currentAuthenticatedUser();
        setAuthUser(confirmedUser);

        onSuccess();
      } catch (err) {
        onError(err as AuthError);
      }
    },
    [authUser]
  );

  /**
   *
   * @public
   * Verifies that the user has correctly setup the TOTP
   *
   */
  const verifyTotpSetup = React.useCallback(
    async ({ mfaCode, onSuccess = () => {}, onError = () => {} }: VerifyTotpSetupParams) => {
      try {
        await Auth.verifyTotpToken(authUser, mfaCode);
        await Auth.setPreferredMFA(authUser, 'TOTP');
        // NOTE: User is confirmed at this point so we can go ahead and log in user here
        const confirmedUser = await Auth.currentAuthenticatedUser();
        setAuthUser(confirmedUser);

        onSuccess();
      } catch (err) {
        onError(err as AuthError);
      }
    },
    [authUser]
  );

  /**
   * @public
   * Sets up TOTP for the user by requesting a new secret code to be used as part of the oauth url
   */
  const requestTotpSecretCode = React.useCallback(() => Auth.setupTOTP(authUser), [authUser]);

  /**
   * @public
   * Sets a new password for the user when he has a temporary one
   *
   */
  const setNewPassword = React.useCallback(
    async ({ newPassword, onSuccess = () => {}, onError = () => {} }: SetNewPasswordParams) => {
      try {
        const userWithUpdatedPassword = await Auth.completeNewPassword(authUser, newPassword, {});

        // simply clone it (that's what this code does) so the ref changes in order to trigger
        // a React re-render (amplify mutates while react plays with immutable structures)
        setAuthUser(
          Object.assign(
            Object.create(Object.getPrototypeOf(userWithUpdatedPassword)),
            userWithUpdatedPassword
          )
        );

        onSuccess();
      } catch (err) {
        onError(err as AuthError);
      }
    },
    [authUser]
  );

  /**
   * @public
   * Changes the current password for the user. This is a different workflow than `setPassword`,
   * since the user doesn't have a temporary password here and he also needs to provide his old
   * password
   */
  const changePassword = React.useCallback(
    async ({
      oldPassword,
      newPassword,
      onSuccess = () => {},
      onError = () => {},
    }: ChangePasswordParams) => {
      try {
        await Auth.changePassword(authUser, oldPassword, newPassword);

        onSuccess();
      } catch (err) {
        onError(err as AuthError);
      }
    },
    [authUser]
  );

  /**
   * @public
   * Resets the current password for the user to the value he has given. This is a different
   * workflow than `setPassword` or `changePassword` since the user doesn't have knowledge of his
   * current password, except for a reset link that he received through an email. This link
   * contained the reset token used below
   */
  const resetPassword = React.useCallback(
    async ({
      email,
      token,
      newPassword,
      onSuccess = () => {},
      onError = () => {},
    }: ResetPasswordParams) => {
      try {
        await Auth.forgotPasswordSubmit(email, token, newPassword);

        onSuccess();
      } catch (err) {
        onError(err as AuthError);
      }
    },
    []
  );

  /**
   * @public
   * A method to initiate a forgot password request. This will send the user an email containing
   * a link to reset his password
   */
  const forgotPassword = React.useCallback(
    async ({ email, onSuccess = () => {}, onError = () => {} }: ForgotPasswordParams) => {
      try {
        await Auth.forgotPassword(email);

        onSuccess();
      } catch (err) {
        onError(err as AuthError);
      }
    },
    []
  );

  /**
   * @public
   * A method to refetch user info in order to update state when a user edits self
   */
  const refetchUserInfo = React.useCallback(
    async ({ onSuccess = () => {}, onError = () => {} }: RefetchUserInfoParams = {}) => {
      try {
        const currentUserInfo = await Auth.currentAuthenticatedUser({ bypassCache: true });
        setAuthUser(currentUserInfo);
        onSuccess();
      } catch (err) {
        onError(err as AuthError);
        signOut();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  /**
   * During mount time only, after having - possibly - set up the Auth configuration, attempt to
   * boot up the user from a previous session
   */
  React.useEffect(() => {
    if (isAuthenticated) {
      Auth.currentAuthenticatedUser({ bypassCache: true })
        .then(setAuthUser)
        .catch(() => signOut());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /*
   * Sign the user in when there are no more challenges present. This hook only runs during updates
   * since the hook above handles the mount-time.
   *
   * This is because, during mounts, the `authUser` is `null`, but we want to keep the initial value
   * of `isAuthenticated` since we want to "boot" from localStorage
   */
  useEffectOnUpdates(() => {
    if (!authUser || 'challengeName' in authUser) {
      setAuthenticated(false);
    } else {
      setAuthenticated(true);
    }
  }, [authUser]);

  /**
   * @public
   * The `isAuthenticated` has an undefined value whenever we haven't yet figured out if the user
   * is or isn't authenticated cause we are on the process of examining his token. It has a boolean
   * value in any other case
   */
  const contextValue = React.useMemo(
    () => ({
      isAuthenticated,
      currentAuthChallengeName: authUser?.challengeName || null,
      userInfo,
      refetchUserInfo,

      retrieveSsoSettings,

      signIn,
      confirmSignIn,
      singleSignOn,
      signOut,

      setNewPassword,
      changePassword,
      resetPassword,
      forgotPassword,

      requestTotpSecretCode,
      verifyTotpSetup,
    }),
    // FIXME: look into hook dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isAuthenticated, authUser]
  );

  return <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>;
};

const MemoizedAuthProvider = React.memo(AuthProvider);

export { AuthContext, MemoizedAuthProvider as AuthProvider };
