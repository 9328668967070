/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import ConfirmModal from 'Components/modals/ConfirmModal';
import { useSnackbar } from 'pouncejs';
import useRouter from 'Hooks/useRouter';
import urls from 'Source/urls';
import { extractErrorMessage, toPlural } from 'Helpers/utils';
import { PackSourceDetails } from 'Source/graphql/fragments/PackSourceDetails.generated';
import { useDeletePackSources } from './graphql/deletePackSources.generated';

export interface DeletePackSourcesModalProps {
  packSources: PackSourceDetails[];
  onSuccess?: () => void;
}

const DeletePackSourcesModal: React.FC<DeletePackSourcesModalProps> = ({
  packSources,
  onSuccess = () => {},
}) => {
  const { location, history } = useRouter<{ id?: string }>();
  const { pushSnackbar } = useSnackbar();

  const isMultiDelete = packSources.length > 1;

  const modalDescription = `Are you sure you want to delete ${
    isMultiDelete ? `${packSources.length} Pack Sources` : packSources[0].id
  }?`;

  const [deletePackSources] = useDeletePackSources({
    variables: {
      input: packSources.map(({ id }) => ({
        id,
      })),
    },
    update: cache => {
      packSources.forEach(packSource => {
        cache.evict({ id: cache.identify(packSource) });
      });
      cache.gc();
    },
    onCompleted: () => {
      onSuccess();

      pushSnackbar({
        variant: 'success',
        title: `Successfully deleted ${toPlural(
          'pack source',
          `${packSources.length} Pack Sources`,
          packSources.length
        )}`,
      });

      if (!isMultiDelete && location.pathname.includes(packSources[0].id)) {
        history.push(urls.analysis.packs.packSources.list());
      }
    },
    onError: error => {
      pushSnackbar({
        variant: 'error',
        title: `Failed to delete ${toPlural(
          'Pack source',
          `${packSources.length} Pack Sources`,
          packSources.length
        )}`,
        description: extractErrorMessage(error),
      });
    },
  });

  return (
    <ConfirmModal variant="destructive" subtitle={modalDescription} onConfirm={deletePackSources} />
  );
};

export default DeletePackSourcesModal;
