/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import SubmitButton, { SubmitButtonProps } from '../SubmitButton';

export type SaveButtonProps = Omit<SubmitButtonProps, 'variantColor' | 'icon'>;

const SaveButton: React.FC<SaveButtonProps> = ({ ...rest }) => {
  return <SubmitButton variantColor="green-400" icon="save" {...rest} />;
};

export default React.memo(SaveButton);
