/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import * as Types from '../../../__generated__/schema';

import { ActorTeaser_User_, ActorTeaser_APIToken_ } from './ActorTeaser.generated';
import { GraphQLError } from 'graphql';
import gql from 'graphql-tag';
import { ActorTeaser } from './ActorTeaser.generated';

export type RuleSummary = Pick<
  Types.Rule,
  | 'id'
  | 'description'
  | 'displayName'
  | 'logTypes'
  | 'scheduledQueries'
  | 'threshold'
  | 'outputIds'
  | 'managed'
  | 'parentId'
  | 'runbook'
  | 'reference'
  | 'severity'
  | 'tags'
  | 'dedupPeriodMinutes'
  | 'createdAt'
  | 'lastModified'
  | 'enabled'
  | 'summaryAttributes'
  | 'analysisType'
> & {
  createdBy?: Types.Maybe<ActorTeaser_User_ | ActorTeaser_APIToken_>;
  lastModifiedBy?: Types.Maybe<ActorTeaser_User_ | ActorTeaser_APIToken_>;
};

export const RuleSummary = gql`
  fragment RuleSummary on Rule {
    id
    description
    displayName
    logTypes
    scheduledQueries
    threshold
    outputIds
    managed
    parentId
    runbook
    reference
    severity
    tags
    dedupPeriodMinutes
    createdAt
    createdBy {
      ...ActorTeaser
    }
    lastModified
    lastModifiedBy {
      ...ActorTeaser
    }
    enabled
    summaryAttributes
    analysisType
  }
  ${ActorTeaser}
`;
