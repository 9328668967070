/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { FastField, useFormikContext } from 'formik';
import { Link, SimpleGrid, Box, Text, FormHelperText } from 'pouncejs';
import { SAVED_QUERIES_CRON_DOC_URL } from 'Source/constants';
import FormikTextInput from 'Components/fields/TextInput';
import FormikNumberInput from 'Components/fields/NumberInput';
import { SaveQueryFormValues } from './SavedQueryForm';

const CronExpression = React.lazy(() =>
  import(/* webpackChunkName: "cron-expression" */ './CronExpression')
);

const CronQueryFormFields: React.FC = () => {
  const { values } = useFormikContext<SaveQueryFormValues>();

  return (
    <Box>
      <React.Suspense fallback={null}>
        <CronExpression
          cronExpression={`${values.minutes} ${values.hours} ${values.days} ${values.months} ${values.weekDays}`}
        />
      </React.Suspense>
      <SimpleGrid columns={6} spacing={2}>
        <FastField as={FormikTextInput} label="Minutes" name="minutes" />
        <FastField as={FormikTextInput} label="Hours" name="hours" />
        <Box>
          <FastField as={FormikTextInput} label="Day" name="days" ria-describedby="cron-month" />
          <FormHelperText mt={1} id="cron-month">
            *Day of month
          </FormHelperText>
        </Box>
        <FastField as={FormikTextInput} label="Month" name="months" />
        <Box>
          <FastField as={FormikTextInput} label="Day" name="weekDays" />
          <FormHelperText mt={1} id="cron-week">
            *Day of week
          </FormHelperText>
        </Box>
        <FastField as={FormikNumberInput} label="Timeout(min)" name="timeoutMinutes" />
      </SimpleGrid>
      <Box mt={4}>
        <Text fontSize="medium">
          Read{' '}
          <Link external href={SAVED_QUERIES_CRON_DOC_URL}>
            our documentation
          </Link>{' '}
          to learn more about cron expressions
        </Text>
      </Box>
    </Box>
  );
};

export default CronQueryFormFields;
