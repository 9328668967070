/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import * as Types from '../../../../../__generated__/schema';

import { GraphQLError } from 'graphql';
import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';

export type DeleteDataModelVariables = {
  input: Types.DeleteDataModelInput;
};

export type DeleteDataModel = Pick<Types.Mutation, 'deleteDataModel'>;

export const DeleteDataModelDocument = gql`
  mutation DeleteDataModel($input: DeleteDataModelInput!) {
    deleteDataModel(input: $input)
  }
`;
export type DeleteDataModelMutationFn = ApolloReactCommon.MutationFunction<
  DeleteDataModel,
  DeleteDataModelVariables
>;

/**
 * __useDeleteDataModel__
 *
 * To run a mutation, you first call `useDeleteDataModel` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDataModel` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDataModel, { data, loading, error }] = useDeleteDataModel({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteDataModel(
  baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteDataModel, DeleteDataModelVariables>
) {
  return ApolloReactHooks.useMutation<DeleteDataModel, DeleteDataModelVariables>(
    DeleteDataModelDocument,
    baseOptions
  );
}
export type DeleteDataModelHookResult = ReturnType<typeof useDeleteDataModel>;
export type DeleteDataModelMutationResult = ApolloReactCommon.MutationResult<DeleteDataModel>;
export type DeleteDataModelMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeleteDataModel,
  DeleteDataModelVariables
>;
export function mockDeleteDataModel({
  data,
  variables,
  errors,
}: {
  data: DeleteDataModel;
  variables?: DeleteDataModelVariables;
  errors?: GraphQLError[];
}) {
  return {
    request: { query: DeleteDataModelDocument, variables },
    result: { data, errors },
  };
}
