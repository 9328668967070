/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import * as Types from '../../../../../__generated__/schema';

import { GraphQLError } from 'graphql';
import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';

export type GetMitreMatrixTreeVariables = {};

export type GetMitreMatrixTree = {
  mitreMatrixTree: Pick<
    Types.MitreMatrixTree,
    'mitreVersion' | 'mitreVersionUrl' | 'defaultMatrixId' | 'enabledLogTypes'
  > & {
    matrices: Array<
      Pick<Types.MitreMatrixTreeNode, 'id' | 'name'> & {
        submatrices: Array<
          Pick<Types.MitreMatrixTreeNode, 'id' | 'name'> & {
            submatrices: Array<Pick<Types.MitreMatrixTreeNode, 'id' | 'name'>>;
          }
        >;
      }
    >;
  };
};

export const GetMitreMatrixTreeDocument = gql`
  query GetMitreMatrixTree {
    mitreMatrixTree {
      mitreVersion
      mitreVersionUrl
      defaultMatrixId
      enabledLogTypes
      matrices {
        id
        name
        submatrices {
          id
          name
          submatrices {
            id
            name
          }
        }
      }
    }
  }
`;

/**
 * __useGetMitreMatrixTree__
 *
 * To run a query within a React component, call `useGetMitreMatrixTree` and pass it any options that fit your needs.
 * When your component renders, `useGetMitreMatrixTree` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMitreMatrixTree({
 *   variables: {
 *   },
 * });
 */
export function useGetMitreMatrixTree(
  baseOptions?: ApolloReactHooks.QueryHookOptions<GetMitreMatrixTree, GetMitreMatrixTreeVariables>
) {
  return ApolloReactHooks.useQuery<GetMitreMatrixTree, GetMitreMatrixTreeVariables>(
    GetMitreMatrixTreeDocument,
    baseOptions
  );
}
export function useGetMitreMatrixTreeLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetMitreMatrixTree,
    GetMitreMatrixTreeVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<GetMitreMatrixTree, GetMitreMatrixTreeVariables>(
    GetMitreMatrixTreeDocument,
    baseOptions
  );
}
export type GetMitreMatrixTreeHookResult = ReturnType<typeof useGetMitreMatrixTree>;
export type GetMitreMatrixTreeLazyQueryHookResult = ReturnType<
  typeof useGetMitreMatrixTreeLazyQuery
>;
export type GetMitreMatrixTreeQueryResult = ApolloReactCommon.QueryResult<
  GetMitreMatrixTree,
  GetMitreMatrixTreeVariables
>;
export function mockGetMitreMatrixTree({
  data,
  variables,
  errors,
}: {
  data: GetMitreMatrixTree;
  variables?: GetMitreMatrixTreeVariables;
  errors?: GraphQLError[];
}) {
  return {
    request: { query: GetMitreMatrixTreeDocument, variables },
    result: { data, errors },
  };
}
