/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { FormikHelpers } from 'formik';
import { useSnackbar } from 'pouncejs';

import { PutUserSchemaInput } from 'Generated/schema';
import { appendDataSchemaPrefix, extractErrorMessage } from 'Helpers/utils';
import { useCreateDataSchema } from 'Pages/CreateDataSchema/graphql/createDataSchema.generated';
import { GetS3LogSource } from 'Source/graphql/queries/getS3LogSource.generated';
import { useUpdateS3LogSource } from '../EditS3LogSource/graphql/updateS3LogSource.generated';
import { S3PrefixLogTypesFormValues } from './SchemasManagement';

const useSchemasManagementFormSubmit = (logSource: GetS3LogSource) => {
  const [loadingSchema, setLoadingSchema] = React.useState<string>(null);
  const { pushSnackbar } = useSnackbar();
  const [updateLogSource] = useUpdateS3LogSource({
    onCompleted: data => {
      pushSnackbar({
        title: `Source ${data.updateS3LogIntegration.integrationLabel} prefixes have been updated`,
        variant: 'success',
      });
    },
    onError: error => {
      pushSnackbar({
        title: 'Source failed to be updated',
        description: extractErrorMessage(error) || 'An unknown error occurred',
        variant: 'error',
      });
    },
  });

  const [createDataSchema] = useCreateDataSchema({
    update: (cache, { data: { putUserSchema } }) => {
      const { record } = putUserSchema;
      if (record) {
        cache.modify({
          fields: {
            listAvailableLogTypes: queryData => {
              return {
                ...queryData,
                logTypes: [...queryData.logTypes, record.name],
              };
            },
          },
        });
      }
    },
    onCompleted: ({ putUserSchema: { record, error: schemaError } }) => {
      if (!schemaError) {
        pushSnackbar({
          title: `Data schema ${record.name} successfully created`,
          variant: 'success',
        });
      }
    },
    onError: error => {
      pushSnackbar({
        variant: 'error',
        title: extractErrorMessage(error) || 'An unknown error occurred',
      });
    },
  });

  const handleSubmit = React.useCallback(
    async (
      values: S3PrefixLogTypesFormValues,
      formikHelpers: FormikHelpers<S3PrefixLogTypesFormValues>
    ) => {
      try {
        // Sequentially create all draft schemas 1 by 1
        for (let i = 0; i < values.draftSchemas.length; i += 1) {
          const input: PutUserSchemaInput = {
            name: appendDataSchemaPrefix(values.draftSchemas[i].name),
            spec: values.draftSchemas[i].spec,
            description: values.draftSchemas[i].description,
            referenceURL: values.draftSchemas[i].referenceURL,
            revision: values.draftSchemas[i].revision,
          };
          // Draft data schemas should be created only if they are included in any prefix
          if (values.s3PrefixLogTypes.some(prefix => prefix.logTypes.includes(input.name))) {
            setLoadingSchema(input.name);
            // eslint-disable-next-line no-await-in-loop
            const result = await createDataSchema({
              variables: {
                input,
              },
            });
            setLoadingSchema(null);
            if (result.errors || result.data.putUserSchema.error) {
              pushSnackbar({
                title: `Data schema ${input.name} could not be created`,
                description: result.data.putUserSchema.error
                  ? result.data.putUserSchema.error.message
                  : 'An unknown error occurred',
                variant: 'error',
              });
              return null;
            }
          }
          // If schema was created successfully, remove it from the draft schemas.
          formikHelpers.setFieldValue('draftSchemas', values.draftSchemas.slice(i + 1));
        }
        // After all draft schemas have been successfully created, update the log source
        const resp = await updateLogSource({
          variables: {
            input: {
              s3PrefixLogTypes: values.s3PrefixLogTypes,
              logStreamType: values.logStreamType,
              // Only s3PrefixLogTypes logStreamType can be modified
              integrationId: logSource.getS3LogIntegration.integrationId,
              integrationLabel: logSource.getS3LogIntegration.integrationLabel,
              s3Bucket: logSource.getS3LogIntegration.s3Bucket,
              kmsKey: logSource.getS3LogIntegration.kmsKey || null,
              logProcessingRole: logSource.getS3LogIntegration.logProcessingRole,
              managedBucketNotifications: logSource.getS3LogIntegration.managedBucketNotifications,
            },
          },
        });
        return resp.data.updateS3LogIntegration;
      } catch (err) {
        return err as Error;
      }
    },
    [updateLogSource, logSource, createDataSchema, pushSnackbar]
  );

  return React.useMemo(() => ({ handleSubmit, loadingSchema }), [handleSubmit, loadingSchema]);
};

export default useSchemasManagementFormSubmit;
