/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';

import FixedWidthBadge from 'Components/FixedWidthBadge';
import { Severity } from 'Generated/schema';
import { SEVERITY_COLOR_MAP } from 'Source/constants';

interface SeverityBadgeProps {
  severity: Severity;
}

const SeverityBadge: React.FC<SeverityBadgeProps> = ({ severity }) => {
  return <FixedWidthBadge color={SEVERITY_COLOR_MAP[severity]}>{severity}</FixedWidthBadge>;
};

export default SeverityBadge;
