/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Box, Flex, Img, Text, Theme } from 'pouncejs';
import type {
  EditorWillMount,
  EditorDidMount,
  monaco,
  MonacoEditorProps,
} from 'react-monaco-editor';
import logo from 'Assets/panther-minimal-logo.svg';
import useForceUpdate from 'Hooks/useForceUpdate';
import cobaltTheme from 'monaco-themes/themes/Cobalt.json';
import useEditorErrorDecorations from './useEditorErrorDecorations';
import useEditorConfig from './useEditorConfig';
import useEditorLanguage from './useEditorLanguage';
import useEditorCommands from './useEditorCommands';
import useEditorCompletions from './useEditorCompletions';
import useEditorResize from './useEditorResize';

// Lazy-load the Monaco editor.
const MonacoEditor = React.lazy(() =>
  import(
    /* webpackChunkName: "react-monaco-editor" */ /* webpackPrefetch: true */ 'react-monaco-editor'
  )
);

export type Completion = { value: string; type: string; detail?: string };

export type EditorCommand = {
  keyBindings: number;
  handler: monaco.editor.ICommandHandler;
};

export type EditorLineError = {
  line: number;
  reason: string;
};

export type EditorProps = Pick<MonacoEditorProps, 'value' | 'onChange'> & {
  ariaLabel?: string;
  completions?: Completion[];
  minHeight?: number;
  maxHeight?: number;
  placeholder?: string;
  readOnly?: boolean;
  language: 'yaml' | 'json' | 'sql' | 'python';
  fontSize?: keyof Theme['fontSizes'];
  hideLineNumbers?: boolean;
  commands?: (monacoInstance: Pick<typeof monaco, 'KeyCode' | 'KeyMod'>) => EditorCommand[];
  /**
   * **initialScrollLine** should be provided if we want the editor
   * to scroll on that line when starting
   * @default 1
   */
  initialScrollLine?: number;
  /**
   * **lineErrors** should be an array of EditorLineErrors to
   * display errors in specific lines editors
   */
  lineErrors?: EditorLineError[];
};

const Editor: React.FC<EditorProps> = ({
  completions = [],
  value,
  onChange,
  ariaLabel,
  readOnly = false,
  minHeight = 170,
  maxHeight,
  placeholder,
  language,
  fontSize = 'medium',
  hideLineNumbers = false,
  commands = () => [],
  initialScrollLine = 1,
  lineErrors,
}) => {
  const forceUpdate = useForceUpdate();
  const monacoInstanceRef = React.useRef<typeof monaco>(null);
  const monacoEditorRef = React.useRef<monaco.editor.IStandaloneCodeEditor>(null);

  const handleEditorWillMount = React.useCallback<EditorWillMount>(monacoInstance => {
    monacoInstance.editor.defineTheme('cobalt', cobaltTheme as monaco.editor.IStandaloneThemeData);
  }, []);

  const handleEditorMount = React.useCallback<EditorDidMount>((editor, monacoInstance) => {
    monacoInstanceRef.current = monacoInstance;
    monacoEditorRef.current = editor;

    // @ts-ignore
    if (window.Cypress) {
      // @ts-ignore
      window.cypress_monaco_editor = editor;
    }

    monacoEditorRef.current.revealLine(initialScrollLine);
    forceUpdate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const editorConfig = useEditorConfig({
    ariaLabel,
    readOnly,
    fontSize,
    hideLineNumbers,
    hasLineErrors: Boolean(lineErrors),
  });

  const monacoInstance = monacoInstanceRef.current;
  const editor = monacoEditorRef.current;

  useEditorLanguage({ monacoInstance, language });
  useEditorCompletions({ monacoInstance, language, completions });
  useEditorCommands({ monacoInstance, editor, commands });
  useEditorResize({ editor, minHeight, maxHeight });
  useEditorErrorDecorations({ editor, lineErrors });

  return (
    <React.Suspense
      fallback={
        <Flex justify="center" align="center" width="100%" bg="#002240" height={minHeight}>
          <Img src={logo} alt="Panther logo" nativeHeight="60" maxHeight="75%" />
        </Flex>
      }
    >
      <Box
        position="relative"
        width="100%"
        sx={{
          '.lines-content': {
            paddingLeft: '9px',
          },
          // Custom css classes for decoration
          '.monaco-inline-decoration': {
            cursor: 'pointer',
            textDecoration: 'underline dotted brown',
            fontWeight: 'bold',
            fontStyle: 'oblique',
          },
          '.monaco-line-decoration': {
            backgroundColor: 'red-300',
            width: '5px !important',
          },
          '.monaco-glyph-error': {
            backgroundColor: 'red-300',
            borderRadius: 'circle',
            marginLeft: 1,
          },
          '.monaco-glyph-error::after': {
            // @ts-ignore
            content: '"!"',
          },
        }}
      >
        <MonacoEditor
          options={editorConfig}
          value={value}
          onChange={onChange}
          theme="cobalt"
          language={language}
          editorWillMount={handleEditorWillMount}
          editorDidMount={handleEditorMount}
        />
        {placeholder && !value && (
          <Text
            top={2}
            fontFamily="mono"
            fontWeight="medium"
            fontSize="medium"
            left={55}
            position="absolute"
            pointerEvents="none"
            color="blue-400"
            fontStyle="italic"
          >
            {placeholder}
          </Text>
        )}
      </Box>
    </React.Suspense>
  );
};

export default React.memo(Editor);
