/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Box, Card, Flex, TabList, TabPanel, TabPanels, Tabs } from 'pouncejs';
import ErrorBoundary from 'Components/ErrorBoundary';
import { BorderedTab, BorderTabDivider } from 'Components/BorderedTab';
import { AlertDetails } from 'Pages/AlertDetails';
import invert from 'lodash/invert';
import { DEFAULT_LARGE_PAGE_SIZE } from 'Source/constants';
import useUrlParams from 'Hooks/useUrlParams';
import { AlertOriginRule } from 'Generated/schema';
import Skeleton from '../Skeleton';
import { useGetRuleSummary } from './graphql/getRuleSummary.generated';
import AlertDetailsBanner from './RuleAlertDetailsBanner';
import RuleAlertDetailsInfo from './RuleAlertDetailsInfo';
import AlertAttributesSummary, {
  useGenerateAlertSummaryQueries,
} from './RuleAlertAttributesSummary';
import RuleAlertEvents, { useGetRuleAlertEvents } from './RuleAlertEvents';

export interface RuleAlertDetailsUrlParams {
  section?: 'details' | 'summary' | 'events';
}

const sectionToTabIndex: Record<RuleAlertDetailsUrlParams['section'], number> = {
  details: 0,
  summary: 1,
  events: 2,
};

const tabIndexToSection = invert(sectionToTabIndex) as Record<
  number,
  RuleAlertDetailsUrlParams['section']
>;

interface RuleAlertDetailsProps {
  alert: AlertDetails['alert'];
}

const RuleAlertDetails: React.FC<RuleAlertDetailsProps> = ({ alert }) => {
  const { urlParams, updateUrlParams } = useUrlParams<RuleAlertDetailsUrlParams>();

  const alertOrigin = alert.origin as AlertOriginRule;
  const { data, loading, error } = useGetRuleSummary({
    variables: { input: { id: alertOrigin.ruleId } },
    context: {
      failSilently: true,
    },
  });

  // Preload the data for the summary and the events tab
  useGenerateAlertSummaryQueries({ variables: { alertId: alert.id } });
  useGetRuleAlertEvents({
    variables: { id: alert.id, eventsInput: { pageSize: DEFAULT_LARGE_PAGE_SIZE } },
  });

  if (loading && !data) {
    return <Skeleton />;
  }

  return (
    <Box as="article" mb={6}>
      <Flex direction="column" spacing={6}>
        <AlertDetailsBanner alert={alert} />
        <Card position="relative">
          <Tabs
            index={sectionToTabIndex[urlParams.section] || 0}
            onChange={index => updateUrlParams({ section: tabIndexToSection[index] })}
          >
            <Box px={2}>
              <TabList>
                <BorderedTab>Details</BorderedTab>
                <BorderedTab>Summary</BorderedTab>
                <BorderedTab>Events ({alertOrigin.eventsMatched})</BorderedTab>
              </TabList>
            </Box>
            <BorderTabDivider />
            <Box p={6}>
              <TabPanels>
                <TabPanel data-testid="alert-details-tabpanel">
                  <ErrorBoundary>
                    <RuleAlertDetailsInfo alert={alert} rule={error ? null : data.rule} />
                  </ErrorBoundary>
                </TabPanel>
                <TabPanel lazy data-testid="alert-summary-tabpanel">
                  <ErrorBoundary>
                    <AlertAttributesSummary alert={alert} />
                  </ErrorBoundary>
                </TabPanel>
                <TabPanel lazy data-testid="alert-events-tabpanel">
                  <ErrorBoundary>
                    <RuleAlertEvents alert={alert} />
                  </ErrorBoundary>
                </TabPanel>
              </TabPanels>
            </Box>
          </Tabs>
        </Card>
      </Flex>
    </Box>
  );
};

export default RuleAlertDetails;
