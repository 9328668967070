/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import dayjs from 'dayjs';
import transform from 'lodash/transform';

export interface IndicatorSearchResult {
  logType: string;
  matches: number;
  dbName: string;
  tableName: string;
  timestamp: string;
  timestampResolutionMinutes?: number;
}

const fieldMappers: Record<
  string,
  { mapKey: (key: string) => string; mapValue: (value: any) => any }
> = {
  databaseName: {
    mapKey: () => 'dbName',
    mapValue: value => value,
  },
  databasename: {
    mapKey: () => 'dbName',
    mapValue: value => value,
  },
  tablename: {
    mapKey: () => 'tableName',
    mapValue: value => value,
  },
  p_log_type: {
    mapKey: () => 'logType',
    mapValue: value => value,
  },
  nmatches: {
    mapKey: () => 'matches',
    mapValue: value => value,
  },
  timebin: {
    mapKey: () => 'timestamp',
    mapValue: value => dayjs.utc(value.replace(/ /g, 'T')).toISOString(),
  },
  timebin_resolution_minutes: {
    mapKey: () => 'timestampResolutionMinutes',
    mapValue: value => value,
  },
};

/**
 * Flattens the API results to something that the FE can more easily work with
 * @param results The results as they came from the API
 */
const useIndicatorSearchFlattenedResults = (
  results: Record<string, any>[]
): IndicatorSearchResult[] => {
  return React.useMemo(
    () =>
      results.map(result => {
        return transform(result, (acc, value, key) => {
          const fieldMapper = fieldMappers[key];
          if (fieldMapper) {
            acc[fieldMapper.mapKey(key)] = fieldMapper.mapValue(value);
          } else {
            acc[key] = value;
          }
        });
      }),
    [results]
  );
};

export default useIndicatorSearchFlattenedResults;
