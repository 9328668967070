/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Box, Alert, SimpleGrid } from 'pouncejs';
import ErrorBoundary from 'Components/ErrorBoundary';
import Panel from 'Components/Panel';
import { extractErrorMessage } from 'Helpers/utils';
import { useGetOrganizationCloudSecurityStats } from './graphql/getOrganizationCloudSecurityStats.generated';
import EmptyDataFallback from './EmptyDataFallback';
import PageSkeleton from './Skeleton';
import PoliciesByStatusChart from './PoliciesByStatusChart';
import ResourcesByStatusChart from './ResourcesByStatusChart';
import TopFailingPolicies from './TopFailingPolicies';
import TopFailingResources from './TopFailingResources';

const CloudSecurityOverview: React.FC = () => {
  const { data, loading, error } = useGetOrganizationCloudSecurityStats({
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
  });

  if (loading && !data) {
    return <PageSkeleton />;
  }

  if (error) {
    return (
      <Alert
        variant="error"
        title="We can't display this content right now"
        description={extractErrorMessage(error)}
      />
    );
  }

  if (!data.listComplianceIntegrations.length) {
    return <EmptyDataFallback />;
  }

  const topFailingPolicies = data?.organizationStats?.topFailingPolicies || [];
  const topFailingResources = data?.organizationStats?.topFailingResources || [];

  return (
    <ErrorBoundary>
      <SimpleGrid columns={2} spacingX={5} spacingY={6} as="article" mb={6}>
        <Panel title="Resource Status">
          <ResourcesByStatusChart resources={data.organizationStats.scannedResources} />
        </Panel>
        <Panel title="Policy Fail Breakdown">
          <PoliciesByStatusChart policies={data.organizationStats.appliedPolicies} />
        </Panel>
        <Box overflow="hidden">
          <Panel title="Top 5 Failing Policies">
            <ErrorBoundary>
              <TopFailingPolicies policies={topFailingPolicies} />
            </ErrorBoundary>
          </Panel>
        </Box>
        <Box overflow="hidden">
          <Panel title="Top 5 Failing Resources">
            <ErrorBoundary>
              <TopFailingResources resources={topFailingResources} />
            </ErrorBoundary>
          </Panel>
        </Box>
      </SimpleGrid>
    </ErrorBoundary>
  );
};

export default CloudSecurityOverview;
