/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

/*
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Flex } from 'pouncejs';
import RoleRestrictedAccess from 'Components/utils/RoleRestrictedAccess';
import { Permission } from 'Generated/schema';
import urls from 'Source/urls';
import LinkButton from 'Components/buttons/LinkButton';
import ListLogSourceFilters from './ListLogSourceFilters';

const ListLogSourceActions: React.FC = () => {
  return (
    <Flex justify="flex-end" align="center">
      <ListLogSourceFilters />
      <RoleRestrictedAccess allowedPermissions={[Permission.LogSourceModify]}>
        <LinkButton title="Create new Log Source" to={urls.integrations.logSources.create()}>
          Create New
        </LinkButton>
      </RoleRestrictedAccess>
    </Flex>
  );
};

export default React.memo(ListLogSourceActions);
