/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Button, useSnackbar } from 'pouncejs';
import { useGetLookupLazyQuery } from 'Pages/Enrichment/Lookups/graphql/getLookup.generated';
import { useCheckSyncLookupDataJobLazyQuery } from 'Pages/Enrichment/Lookups/graphql/checkSyncLookupDataJob.generated';
import { useSyncLookupData } from 'Pages/Enrichment/Lookups/graphql/syncLookupData.generated';
import { extractErrorMessage } from 'Helpers/utils';
import { JobStatus } from 'Generated/schema';
import { LookupDetails } from 'Source/graphql/fragments/LookupDetails.generated';

interface ResyncButtonProps {
  lookup: LookupDetails;
}

const ResyncButton: React.FC<ResyncButtonProps> = ({ lookup }) => {
  // We cannot use the loading properties in apollo because we are using 3 queries and treating them all as one.
  // The middle query is a polling query so we cannot have the loading state toggle on/off constantly while polling.
  // To the user, we want the idea of loading to be the entire operation until it is done.
  const [isSyncing, setIsSyncing] = React.useState(false);
  const { pushSnackbar } = useSnackbar();

  const [getLookup] = useGetLookupLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: () => setIsSyncing(false),
    onError: () => setIsSyncing(false),
  });

  const [
    checkSyncLookupData,
    { client, stopPolling, error: jobError, data: jobData, called },
  ] = useCheckSyncLookupDataJobLazyQuery({
    pollInterval: 1000,
    fetchPolicy: 'network-only',
  });

  const [syncLookupData] = useSyncLookupData({
    onCompleted: data => {
      // this is the polling query that checks the job status.  handling of the polling is all done within the useEffect
      checkSyncLookupData({
        variables: { id: data.syncLookupData.id },
      });
    },
    onError: error => {
      setIsSyncing(false);
      pushSnackbar({
        variant: 'error',
        title: `Syncing Lookup ${lookup.name} failed`,
        description: extractErrorMessage(error),
      });
    },
  });

  React.useEffect(() => {
    if (jobData) {
      const status = jobData?.checkSyncLookupDataJob.status;
      // If the step function is no longer running OR if the GraphQL API itself failed
      // we need to stop polling since the import job is "over"
      const shouldStopPolling = status !== JobStatus.Running || jobError;
      if (shouldStopPolling) {
        setIsSyncing(false);
        stopPolling();
      }

      if (status === JobStatus.Failed) {
        setIsSyncing(false);
        // generic error message here because we do not have specific errors being returned
        pushSnackbar({
          variant: 'error',
          title: `Syncing Lookup ${lookup.name} failed`,
          description: `Make sure the S3 object exists in the right path configured and try again.`,
        });
      }

      // If the import job succeeded, we need to refresh the lookup table with the new row count
      if (status === JobStatus.Succeeded) {
        getLookup({ variables: { id: lookup.id } });
        pushSnackbar({
          variant: 'success',
          title: `Lookup ${lookup.name} is up-to-date`,
          description: `No new data was found to sync. "Last Received Data" was the last time new data was synced.`,
        });
      }
    }
  }, [
    called,
    client,
    jobData,
    jobError,
    lookup.id,
    stopPolling,
    getLookup,
    pushSnackbar,
    lookup.name,
  ]);

  const handleRefresh = React.useCallback(() => {
    setIsSyncing(true);
    syncLookupData({
      variables: {
        input: { lookupId: lookup.id },
      },
    });
  }, [syncLookupData, lookup.id]);

  return (
    <Button
      onClick={handleRefresh}
      // @ts-ignore
      ml={2}
      size="small"
      icon="refresh"
      variant="outline"
      variantColor="navyblue-300"
      sx={
        isSyncing && {
          svg: {
            animation: 'spin 4s linear infinite',
            '@keyframes spin': {
              '100%': { transform: 'rotate(360deg)' },
            },
          },
        }
      }
    >
      {isSyncing ? 'Syncing...' : 'Sync'}
    </Button>
  );
};

export default ResyncButton;
