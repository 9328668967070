/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import * as Types from '../../../__generated__/schema';

import { DataSchemaTeaser } from './DataSchemaTeaser.generated';
import { GraphQLError } from 'graphql';
import gql from 'graphql-tag';

export type DataSchemaFull = Pick<Types.SchemaRecord, 'spec' | 'referenceURL' | 'revision'> &
  DataSchemaTeaser;

export const DataSchemaFull = gql`
  fragment DataSchemaFull on SchemaRecord {
    ...DataSchemaTeaser
    spec
    referenceURL
    revision
  }
  ${DataSchemaTeaser}
`;
