/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Alert, Flex, FormHelperText, SimpleGrid, Spinner, useSnackbar } from 'pouncejs';
import SavedQueryForm, {
  SaveQueryFormValues,
} from 'Components/forms/SavedQueryForm/SavedQueryForm';
import useModal from 'Hooks/useModal';
import { ListAvailableScheduledQueriesDocument, useGetSavedQuery } from 'Source/graphql/queries';
import { useUpdateSavedQuery } from 'Components/modals/UpdateSavedQueryModal/graphql/updateSavedQuery.generated';
import { extractErrorMessage, formatCronExpression, splitCronValues } from 'Helpers/utils';
import { DEFAULT_CRON_EXPRESSION } from 'Source/constants';
import { LoadAllSchemaEntitiesDocument } from 'Pages/DataExplorer/SQLEditor/graphql/loadAllSchemaEntities.generated';

export interface UpdateSavedQueryModalProps {
  savedQueryId: string;
  sqlQuery: string;
  selectedDatabase?: string;
}

const UpdateSavedQueryModal: React.FC<UpdateSavedQueryModalProps> = ({
  sqlQuery,
  savedQueryId,
  selectedDatabase,
}) => {
  const { hideModal } = useModal();
  const { pushSnackbar } = useSnackbar();

  const { loading, data: savedQueryData } = useGetSavedQuery({
    fetchPolicy: 'network-only', // Don't use cache
    variables: {
      id: savedQueryId,
    },
  });

  const [updateSavedQuery, { loading: updateInProgress, error: updateError }] = useUpdateSavedQuery(
    {
      refetchQueries: [
        { query: LoadAllSchemaEntitiesDocument },
        { query: ListAvailableScheduledQueriesDocument },
      ],
      onCompleted: () => {
        hideModal();
        pushSnackbar({
          variant: 'success',
          title: 'Saved Query updated successfully',
        });
      },
      onError: () => null,
    }
  );

  const onSubmit = React.useCallback(
    (formValues: SaveQueryFormValues) =>
      updateSavedQuery({
        variables: {
          input: {
            id: savedQueryId,
            sqlQuery,
            name: formValues.name,
            description: formValues.description,
            defaultDatabase: formValues.defaultDatabase || null,
            tags: formValues.tags,
            parentId: formValues.parentId,
            schedule: formValues.isScheduled
              ? {
                  disabled: !formValues.isEnabled,
                  timeout: formValues.timeoutMinutes,
                  ...(formValues.type === 'period'
                    ? {
                        rateMinutes:
                          (formValues.periodDays || 0) * 1440 + (formValues.periodMinutes || 0),
                      }
                    : {
                        cronExpression: formatCronExpression(formValues),
                      }),
                }
              : null,
          },
        },
      }),
    // FIXME: look into hook dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [savedQueryId, sqlQuery]
  );

  const savedQuery = savedQueryData?.getSavedQuery;

  const initialValues = React.useMemo(() => {
    const [minutes, hours, days, months, weekDays] = splitCronValues(
      savedQuery?.schedule?.cronExpression || DEFAULT_CRON_EXPRESSION
    );

    const type = savedQuery?.schedule?.cronExpression ? 'cron' : 'period';

    return {
      id: savedQuery?.id || '',
      name: savedQuery?.name || '',
      description: savedQuery?.description || '',
      tags: savedQuery?.tags || [],
      defaultDatabase: selectedDatabase || savedQuery?.defaultDatabase || '',
      isScheduled: !!savedQuery?.schedule,
      isEnabled: !savedQuery?.schedule?.disabled,
      rateMinutes: savedQuery?.schedule?.rateMinutes,
      parentId: savedQuery?.parent?.id,
      periodDays: Math.floor(savedQuery?.schedule?.rateMinutes / 1440) || 0,
      periodMinutes:
        savedQuery?.schedule?.rateMinutes -
          Math.floor(savedQuery?.schedule?.rateMinutes / 1440) * 1440 || 0,
      timeoutMinutes: savedQuery?.schedule?.timeout || 1,
      type,
      minutes,
      hours,
      days,
      months,
      weekDays,
    };
    // FIXME: look into hook dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [savedQuery]);

  if (loading && !savedQuery) {
    return (
      <Flex justify="center">
        <Spinner />
      </Flex>
    );
  }

  return (
    <SimpleGrid spacing={4} width={720}>
      {updateError && (
        <Alert
          variant="error"
          title="Unable to update Saved Query"
          description={extractErrorMessage(updateError) || 'An unknown error occurred'}
        />
      )}
      <SavedQueryForm onSubmit={onSubmit} initialValues={initialValues} />
      {updateInProgress && (
        <FormHelperText id="delay-explanation-helper" textAlign="center">
          This can take up to 20 seconds.
        </FormHelperText>
      )}
    </SimpleGrid>
  );
};

export default UpdateSavedQueryModal;
