import { Button } from 'pouncejs';
import React from 'react';
import { extractErrorMessage } from 'Helpers/utils';
import { useSchemaGeneration } from 'Components/utils/SchemaGenerationContext';
import useUrlParams from 'Hooks/useUrlParams';
import { SampleDataUrlParams } from 'Components/forms/DataSchemaForm/SampleDataSection/SampleDataMain';

/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

const TestSchemaButton: React.FC = () => {
  const { updateUrlParams } = useUrlParams<SampleDataUrlParams>();
  const {
    schema: initialSchema,
    testingSchema,
    testSchema,
    sessionId,
    setError,
  } = useSchemaGeneration();

  const onTestSchema = React.useCallback(
    async schema => {
      try {
        const { data } = await testSchema({
          variables: {
            input: {
              sessionId,
              schema,
            },
          },
        });
        if (data.testSchema?.error) {
          setError({
            title: 'Failed to test schema',
            description: data.testSchema?.error?.message || 'An unknown error occurred',
          });
        } else {
          updateUrlParams({
            section: data.testSchema?.result?.stats.unmatched === 0 ? 'matched' : 'unmatched',
          });
        }
      } catch (e) {
        setError({
          title: 'Failed to test schema',
          description: extractErrorMessage(e) || 'An unknown error occurred',
        });
      }
    },
    [updateUrlParams, sessionId, setError, testSchema]
  );

  return (
    <Button
      loading={testingSchema}
      disabled={testingSchema}
      onClick={() => onTestSchema(initialSchema)}
      size="medium"
    >
      Test Schema
    </Button>
  );
};

export default TestSchemaButton;
