/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { useField, FieldHookConfig, FieldHelperProps } from 'formik';

/**
 * The current `useField` from formik is not performance oriented. We have raised this in an issue
 * but there this is still not fixed. This is a temporary workaround which will solve perf issues in
 * big complex forms
 * FIXME: Remove and replace with `useField` when perf issues are gone (i.e. issue below is closed)
 * https://github.com/jaredpalmer/formik/issues/2268
 */
function useFastField<Val = any>(
  propsOrFieldName: string | FieldHookConfig<Val>
): ReturnType<typeof useField> {
  const [field, meta, helpers] = useField<Val>(propsOrFieldName);

  const memoizedHelpersRef = React.useRef<{
    memoizedHelpers?: FieldHelperProps<Val>;
    memoizedSetValue?: FieldHelperProps<Val>['setValue'];
    memoizedsetTouched?: FieldHelperProps<Val>['setTouched'];
    memoizedsetError?: FieldHelperProps<Val>['setError'];
  }>({});

  // On every render, we save the newest helpers to memoizedHelpersRef, overriding the "function"
  // assigned to `setValue`, `setTouched` and `setError` accordingly, without losing referential
  // integrity
  memoizedHelpersRef.current.memoizedSetValue = helpers.setValue;
  memoizedHelpersRef.current.memoizedsetTouched = helpers.setTouched;
  memoizedHelpersRef.current.memoizedsetError = helpers.setError;

  // On the first render (where `memoizedHelpers` is undefined), we create a "referentially stable"
  // copy of formik's helpers. From now on, `setValue`, `setTouched` and `setError` will have the
  // same reference, but the functionn assigned to them will change on every render.
  if (!memoizedHelpersRef.current.memoizedHelpers) {
    memoizedHelpersRef.current.memoizedHelpers = {
      setValue: (...args) => memoizedHelpersRef.current.memoizedSetValue(...args),
      setTouched: (...args) => memoizedHelpersRef.current.memoizedsetTouched(...args),
      setError: (...args) => memoizedHelpersRef.current.memoizedsetError(...args),
    };
  }

  return [field, meta, memoizedHelpersRef.current.memoizedHelpers];
}

export default useFastField;
