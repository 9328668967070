/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import * as Types from '../../../../../../../__generated__/schema';

import { AtlassianLogIntegrationDetails } from '../../../../../../graphql/fragments/AtlassianLogIntegrationDetails.generated';
import { GraphQLError } from 'graphql';
import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';

export type GetAtlassianLogSourceVariables = {
  id: Types.Scalars['ID'];
};

export type GetAtlassianLogSource = { getLogPullingIntegration: AtlassianLogIntegrationDetails };

export const GetAtlassianLogSourceDocument = gql`
  query GetAtlassianLogSource($id: ID!) {
    getLogPullingIntegration(id: $id) {
      ...AtlassianLogIntegrationDetails
    }
  }
  ${AtlassianLogIntegrationDetails}
`;

/**
 * __useGetAtlassianLogSource__
 *
 * To run a query within a React component, call `useGetAtlassianLogSource` and pass it any options that fit your needs.
 * When your component renders, `useGetAtlassianLogSource` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAtlassianLogSource({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetAtlassianLogSource(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetAtlassianLogSource,
    GetAtlassianLogSourceVariables
  >
) {
  return ApolloReactHooks.useQuery<GetAtlassianLogSource, GetAtlassianLogSourceVariables>(
    GetAtlassianLogSourceDocument,
    baseOptions
  );
}
export function useGetAtlassianLogSourceLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetAtlassianLogSource,
    GetAtlassianLogSourceVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<GetAtlassianLogSource, GetAtlassianLogSourceVariables>(
    GetAtlassianLogSourceDocument,
    baseOptions
  );
}
export type GetAtlassianLogSourceHookResult = ReturnType<typeof useGetAtlassianLogSource>;
export type GetAtlassianLogSourceLazyQueryHookResult = ReturnType<
  typeof useGetAtlassianLogSourceLazyQuery
>;
export type GetAtlassianLogSourceQueryResult = ApolloReactCommon.QueryResult<
  GetAtlassianLogSource,
  GetAtlassianLogSourceVariables
>;
export function mockGetAtlassianLogSource({
  data,
  variables,
  errors,
}: {
  data: GetAtlassianLogSource;
  variables?: GetAtlassianLogSourceVariables;
  errors?: GraphQLError[];
}) {
  return {
    request: { query: GetAtlassianLogSourceDocument, variables },
    result: { data, errors },
  };
}
