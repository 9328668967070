/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import * as Types from '../../../../../../__generated__/schema';

import { GraphQLError } from 'graphql';
import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';

export type TestSchemaVariables = {
  input: Types.TestSchemaInput;
};

export type TestSchema = {
  testSchema?: Types.Maybe<{
    result?: Types.Maybe<{
      stats: Pick<Types.TestSchemaStats, 'matched' | 'unmatched' | 'totalLogs'>;
      data: {
        matched: Array<Pick<Types.MatchedLog, 'raw' | 'event'>>;
        unmatched: Array<Pick<Types.UnmatchedLog, 'raw' | 'error'>>;
      };
    }>;
    error?: Types.Maybe<Pick<Types.Error, 'code' | 'message'>>;
  }>;
};

export const TestSchemaDocument = gql`
  mutation TestSchema($input: TestSchemaInput!) {
    testSchema(input: $input) {
      result {
        stats {
          matched
          unmatched
          totalLogs
        }
        data {
          matched {
            raw
            event
          }
          unmatched {
            raw
            error
          }
        }
      }
      error {
        code
        message
      }
    }
  }
`;
export type TestSchemaMutationFn = ApolloReactCommon.MutationFunction<
  TestSchema,
  TestSchemaVariables
>;

/**
 * __useTestSchema__
 *
 * To run a mutation, you first call `useTestSchema` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTestSchema` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [testSchema, { data, loading, error }] = useTestSchema({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTestSchema(
  baseOptions?: ApolloReactHooks.MutationHookOptions<TestSchema, TestSchemaVariables>
) {
  return ApolloReactHooks.useMutation<TestSchema, TestSchemaVariables>(
    TestSchemaDocument,
    baseOptions
  );
}
export type TestSchemaHookResult = ReturnType<typeof useTestSchema>;
export type TestSchemaMutationResult = ApolloReactCommon.MutationResult<TestSchema>;
export type TestSchemaMutationOptions = ApolloReactCommon.BaseMutationOptions<
  TestSchema,
  TestSchemaVariables
>;
export function mockTestSchema({
  data,
  variables,
  errors,
}: {
  data: TestSchema;
  variables?: TestSchemaVariables;
  errors?: GraphQLError[];
}) {
  return {
    request: { query: TestSchemaDocument, variables },
    result: { data, errors },
  };
}
