/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import FlatBadge from 'Components/badges/FlatBadge';
import { Box, Flex, Heading, Card } from 'pouncejs';

import { SchemaRecord } from 'Generated/schema';

interface DataSchemaDetailsBannerProps {
  schema: Omit<SchemaRecord, 'fields'>;
}

const DataSchemaDetailsBanner: React.FC<DataSchemaDetailsBannerProps> = ({ schema }) => {
  const borderColor = schema.managed ? 'blue-300' : 'navyblue-100';

  return (
    <Card as="article" p={6} overflow="hidden" borderLeft="4px solid" borderColor={borderColor}>
      <Box as="header">
        <Heading fontWeight="bold" wordBreak="break-word" flexShrink={1} mr={100}>
          {schema.name}
        </Heading>
      </Box>
      {schema.description && <Box>{schema.description}</Box>}
      <Flex spacing={1} mt={2}>
        {schema.managed ? (
          <FlatBadge backgroundColor="navyblue-700" color="blue-300">
            Panther Managed
          </FlatBadge>
        ) : (
          <FlatBadge backgroundColor="navyblue-700" color="navyblue-100">
            User Defined
          </FlatBadge>
        )}
        {schema.release && (
          <FlatBadge backgroundColor="navyblue-700" color="gray-200">
            {schema.release}
          </FlatBadge>
        )}
      </Flex>
    </Card>
  );
};
export default DataSchemaDetailsBanner;
