/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import FormikMultiCombobox from 'Components/fields/MultiComboBox';
import React from 'react';
import { Button, Flex, Box, IconButton, FormError } from 'pouncejs';
import { FastField, Field, FieldArray, useFormikContext, getIn } from 'formik';
import FormikTextInput from 'Components/fields/TextInput';
import { DetectionReportMapping } from 'Generated/schema';

interface DetectionReportsFormValues {
  reports: DetectionReportMapping[];
}

const BaseDetectionFormMappingsSection: React.FC = () => {
  const {
    values: { reports },
  } = useFormikContext<DetectionReportsFormValues>();

  return (
    <FieldArray
      name="reports"
      render={({ remove, push, form }) => {
        return (
          <>
            {reports.map((_, idx) => {
              const valuesErr = getIn(form.errors, `reports.${idx}.values`);
              const valuesErrAndTouched = !!(valuesErr && getIn(form.touched, `reports.${idx}`));

              return (
                <Flex key={idx} mb={4} spacing={2}>
                  <Box minWidth={300}>
                    <FastField
                      as={FormikTextInput}
                      label="Report Key"
                      placeholder="A key for the type of report"
                      name={`reports.${idx}.key`}
                    />
                  </Box>
                  <Box flexGrow={1}>
                    <Field
                      as={FormikMultiCombobox}
                      name={`reports.${idx}.values`}
                      items={[]}
                      label="Report Values"
                      placeholder="Tags for this report type"
                      invalid={valuesErrAndTouched}
                      searchable
                      allowAdditions
                    />
                    {valuesErrAndTouched && <FormError mt={2}>{valuesErr}</FormError>}
                  </Box>
                  <Box>
                    <IconButton
                      icon="trash"
                      size="large"
                      variantColor="transparent"
                      aria-label="Remove report entry"
                      onClick={() => remove(idx)}
                    />
                  </Box>
                </Flex>
              );
            })}
            <Box>
              <Button
                icon="add"
                size="medium"
                variantColor="blue-400"
                aria-label="Add report entry"
                onClick={() => push({ key: '', values: [] })}
              >
                Add Report
              </Button>
            </Box>
          </>
        );
      }}
    />
  );
};

export default React.memo(BaseDetectionFormMappingsSection);
