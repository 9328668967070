/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Text, Box, useSnackbar, Alert } from 'pouncejs';
import ProductConsentForm from 'Components/forms/ProductConsentForm';
import useModal from 'Hooks/useModal';
import { extractErrorMessage } from 'Helpers/utils';
import { useUpdateGeneralSettingsConsents } from './graphql/updateGeneralSettingsConsents.generated';

export interface ProductConsentModalProps {
  isLicenseAccepted: boolean;
}

const ProductConsentModal: React.FC<ProductConsentModalProps> = ({ isLicenseAccepted = false }) => {
  const { hideModal } = useModal();
  const { pushSnackbar } = useSnackbar();
  const [
    saveConsentPreferences,
    { error: updateGeneralPreferencesError },
  ] = useUpdateGeneralSettingsConsents({
    onCompleted: () => {
      hideModal();
      pushSnackbar({ variant: 'success', title: `Successfully updated your preferences` });
    },
    onError: error => {
      pushSnackbar({
        variant: 'error',
        title:
          extractErrorMessage(error) ||
          'Failed to update your preferences due to an unknown and unpredicted error',
      });
    },
  });

  return (
    <Box width={500} px={10}>
      <Text fontSize="medium" mb={8} id="modal-subtitle">
        There a couple of things that need your review before continuing.
      </Text>
      {updateGeneralPreferencesError ? (
        <Alert
          title="An error occurred"
          description={extractErrorMessage(updateGeneralPreferencesError)}
          variant="error"
        />
      ) : (
        <ProductConsentForm
          isLicenseAccepted={isLicenseAccepted}
          onSubmit={values =>
            saveConsentPreferences({
              variables: {
                input: values,
              },
            })
          }
        />
      )}
    </Box>
  );
};

export default ProductConsentModal;
