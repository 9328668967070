/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import {
  Box,
  Card,
  Flex,
  Heading,
  Icon,
  IconProps,
  Radio,
  Text,
  Theme,
  AbstractButton,
} from 'pouncejs';
import useUrlParams from 'Hooks/useUrlParams';
import { CreateDetectionUrlParams } from '../CreateDetection';

const noop = () => {};

interface DetectionSelectionCardProps {
  title: string | React.ReactNode;
  description: string;
  icon: IconProps['type'];
  iconColor: keyof Theme['colors'];
  type?: CreateDetectionUrlParams['type'];
}

const DetectionSelectionCard: React.FC<DetectionSelectionCardProps> = ({
  type,
  title,
  description,
  iconColor,
  icon,
}) => {
  const { urlParams, updateUrlParams } = useUrlParams<CreateDetectionUrlParams>();

  const isActive = urlParams.type === type;
  return (
    <AbstractButton aria-label={`Create ${title}`} onClick={() => updateUrlParams({ type })}>
      <Card p={4} variant={isActive ? 'light' : 'dark'}>
        <Flex>
          <Flex
            borderRadius="circle"
            height={32}
            width={32}
            justify="center"
            align="center"
            backgroundColor={iconColor}
            flexShrink={0}
            mr={4}
          >
            <Icon size="small" type={icon} />
          </Flex>
          <Box>
            <Flex align="center" justify="space-between" mt={-1} mr={-1}>
              <Heading as="h2" size="x-small">
                <Flex spacing={4} align="center">
                  <Box>{title}</Box>
                </Flex>
              </Heading>
              <Radio checked={isActive} onChange={noop} />
            </Flex>
            <Text fontSize="small" color="gray-300" textAlign="left">
              {description}
            </Text>
          </Box>
        </Flex>
      </Card>
    </AbstractButton>
  );
};

export default DetectionSelectionCard;
