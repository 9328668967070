/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Text, Box, Flex, IconProps, Card } from 'pouncejs';
import JSONBig from 'json-bigint';
import useCopyToClipboard from 'Hooks/useCopyToClipboard';
import { stripNullValuesFromJSON } from 'Helpers/utils';
import ErrorBoundary from 'Components/ErrorBoundary';
import JsonRootNode from './JsonRootNode';
import useJsonTreeTheme from './useJsonTreeTheme';
import NodeCopyAction from './NodeCopyAction';
import JsonViewerContext from './JsonViewerContext';
import JsonViewerHeader from './JsonViewerHeader/JsonViewerHeader';

const ReactJSONTree = React.lazy(() =>
  import(/* webpackChunkName: 'react-json-tree' */ 'react-json-tree')
);

export interface Action {
  icon: IconProps['type'];
  label: string;
  onClick: (data: unknown) => void;
}

export interface ActionsProps {
  data: unknown;
  keyPath?: (string | number)[];
}

interface JsonViewerProps {
  data: Record<string, unknown>;
  collapsed?: boolean;
  actions?: (props: ActionsProps) => React.ReactNode;
  header?: React.ReactNode;
}

const labelRenderer = (keyPath: (string | number)[]) => <Text as="span">{keyPath[0]}:</Text>;

const itemRenderer = (
  nodeType: string,
  nodeData: any,
  itemType: React.ReactNode,
  itemString: string
) => {
  return (
    <>
      {itemType} {nodeType === 'Array' ? itemString : ''}
      <NodeCopyAction data={nodeData} />
    </>
  );
};

const JsonViewer: React.FC<JsonViewerProps> = ({
  data,
  collapsed = true,
  actions = actionProps => <NodeCopyAction {...actionProps} />,
  header = <JsonViewerHeader />,
}) => {
  const [isExpanded, setIsExpanded] = React.useState(!collapsed);
  const theme = useJsonTreeTheme();
  const copyToClipboard = useCopyToClipboard();
  const toggleIsExpanded = React.useCallback(() => {
    setIsExpanded(prev => !prev);
  }, []);

  const formattedJson = React.useMemo(() => {
    return data != null ? stripNullValuesFromJSON(data) : null; // The `!=` is intentional to check both `null` and `undefined`
  }, [data]);

  const valueRenderer = React.useCallback(
    (valueAsString: string, value: unknown, ...keyPath: (string | number)[]) => {
      return (
        <>
          {/* Json-tree doesn't handle BigInt value types. If we used the valueAsString that would print the type <BigInt> instead of the actual value. */}
          <Text as="span">{typeof value === 'string' ? value : `${value}`}</Text>
          {actions({ data: value, keyPath })}
        </>
      );
    },
    [actions]
  );

  const shouldExpandNode = React.useCallback(() => isExpanded, [isExpanded]);

  const copyJson = React.useCallback(
    () => copyToClipboard(JSONBig.stringify(formattedJson, null, '\t')),
    [formattedJson, copyToClipboard]
  );

  const jsonViewerContextValue = React.useMemo(
    () => ({
      isExpanded,
      toggleIsExpanded,
      copyJson,
    }),
    [isExpanded, toggleIsExpanded, copyJson]
  );

  return (
    <ErrorBoundary fallbackStrategy="placeholder">
      <React.Suspense fallback={null}>
        <Card
          position="relative"
          width="100%"
          fontSize="medium"
          variant="dark"
          p={6}
          borderRadius="large"
          data-testid="json-viewer"
        >
          <JsonViewerContext.Provider value={jsonViewerContextValue}>
            {header}
          </JsonViewerContext.Provider>
          <Flex spacing={1}>
            <JsonRootNode />
            <Box py={2}>
              <ReactJSONTree
                collectionLimit={isExpanded ? 0 : 100}
                theme={theme}
                data={formattedJson}
                invertTheme={false}
                labelRenderer={labelRenderer}
                valueRenderer={valueRenderer}
                getItemString={itemRenderer}
                shouldExpandNode={shouldExpandNode}
                sortObjectKeys
                hideRoot
              />
            </Box>
          </Flex>
        </Card>
      </React.Suspense>
    </ErrorBoundary>
  );
};

export default React.memo(JsonViewer);
