/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import MitreMatrixGridCharm from './MitreMatrixGridCharm';
import { MitreContextValue, pickTacticTechniqueStatus } from '../MitreContext';

interface MitreMatrixGridCellProps {
  matrix: MitreContextValue['activeMatrix'];
  tacticId: string;
  techniqueId: string;
}

const MitreMatrixGridCell = ({ matrix, techniqueId, tacticId }: MitreMatrixGridCellProps) => {
  const reportSetting = React.useMemo(
    () =>
      matrix.reportSettings.find(
        o =>
          o.association.mitre.tacticId === tacticId &&
          o.association.mitre.techniqueId === techniqueId
      ),
    [matrix.reportSettings, tacticId, techniqueId]
  );

  const tacticTechniqueStatus = React.useMemo(() => pickTacticTechniqueStatus(reportSetting), [
    reportSetting,
  ]);

  return <MitreMatrixGridCharm status={tacticTechniqueStatus} />;
};

export default React.memo(MitreMatrixGridCell);
