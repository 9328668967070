/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import * as Types from '../../../../__generated__/schema';

import { DataSchemaTeaser } from '../../../graphql/fragments/DataSchemaTeaser.generated';
import { GraphQLError } from 'graphql';
import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';

export type ListSchemasVariables = {
  input?: Types.Maybe<Types.ListSchemasInput>;
};

export type ListSchemas = { listSchemas: { results?: Types.Maybe<Array<DataSchemaTeaser>> } };

export const ListSchemasDocument = gql`
  query ListSchemas($input: ListSchemasInput) {
    listSchemas(input: $input) {
      results {
        ...DataSchemaTeaser
      }
    }
  }
  ${DataSchemaTeaser}
`;

/**
 * __useListSchemas__
 *
 * To run a query within a React component, call `useListSchemas` and pass it any options that fit your needs.
 * When your component renders, `useListSchemas` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListSchemas({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useListSchemas(
  baseOptions?: ApolloReactHooks.QueryHookOptions<ListSchemas, ListSchemasVariables>
) {
  return ApolloReactHooks.useQuery<ListSchemas, ListSchemasVariables>(
    ListSchemasDocument,
    baseOptions
  );
}
export function useListSchemasLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ListSchemas, ListSchemasVariables>
) {
  return ApolloReactHooks.useLazyQuery<ListSchemas, ListSchemasVariables>(
    ListSchemasDocument,
    baseOptions
  );
}
export type ListSchemasHookResult = ReturnType<typeof useListSchemas>;
export type ListSchemasLazyQueryHookResult = ReturnType<typeof useListSchemasLazyQuery>;
export type ListSchemasQueryResult = ApolloReactCommon.QueryResult<
  ListSchemas,
  ListSchemasVariables
>;
export function mockListSchemas({
  data,
  variables,
  errors,
}: {
  data: ListSchemas;
  variables?: ListSchemasVariables;
  errors?: GraphQLError[];
}) {
  return {
    request: { query: ListSchemasDocument, variables },
    result: { data, errors },
  };
}
