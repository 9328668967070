/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Dropdown, DropdownLink, DropdownButton, DropdownItem, DropdownMenu } from 'pouncejs';
import DeleteRoleModal from 'Components/modals/DeleteRoleModal';
import OptionsButton from 'Components/buttons/OptionsButton';
import { Link as RRLink } from 'react-router-dom';
import useModal from 'Hooks/useModal';
import urls from 'Source/urls';
import { RoleDetails } from 'Source/graphql/fragments/RoleDetails.generated';

interface RoleItemOptionsProps {
  role: RoleDetails;
}

const RoleItemOptions: React.FC<RoleItemOptionsProps> = ({ role }) => {
  const { showModal } = useModal();

  return (
    <Dropdown>
      <DropdownButton data-tid="role-card-options-dropdown" as={OptionsButton} />
      <DropdownMenu>
        <DropdownLink as={RRLink} to={urls.settings.roles.edit(role.id)} data-tid="edit-role">
          Edit
        </DropdownLink>
        <DropdownItem
          data-tid="delete-role"
          onSelect={() => showModal(<DeleteRoleModal role={role} />, { title: 'Delete Role' })}
        >
          Delete
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  );
};

export default React.memo(RoleItemOptions);
