/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import useRouter from 'Hooks/useRouter';
import { ComplianceStatusEnum, PoliciesForResourceInput, Permission } from 'Generated/schema';
import EmptyBoxImg from 'Assets/illustrations/empty-box.svg';
import NoResultsFound from 'Components/NoResultsFound';
import Panel from 'Components/Panel';
import useRequestParamsWithPagination from 'Hooks/useRequestParamsWithPagination';
import { getComplianceItemsTotalCount, extractErrorMessage } from 'Helpers/utils';
import { compose } from 'Helpers/compose';
import { Alert, Box, Flex, Heading } from 'pouncejs';
import {
  TableControlsPagination,
  TableControlsComplianceFilter,
} from 'Components/utils/TableControls';
import pick from 'lodash/pick';
import ErrorBoundary from 'Components/ErrorBoundary';
import withRoleRestrictedAccess from 'Hoc/withRoleRestrictedAccess';
import Page403 from 'Pages/403';
import withSEO from 'Hoc/withSEO';
import { DEFAULT_SMALL_PAGE_SIZE } from 'Source/constants';
import ResourceDetailsAttributes from 'Pages/ResourceDetails/ResourceDetailsAttributes';
import ResourceDetailsTable from './ResourceDetailsTable';
import ResourceDetailsInfo from './ResourceDetailsInfo';
import ResourceDetailsPageSkeleton from './Skeleton';
import { useResourceDetails } from './graphql/resourceDetails.generated';

const acceptedRequestParams = ['page', 'status', 'pageSize', 'suppressed'] as const;

const ResourceDetailsPage = () => {
  const { match } = useRouter<{ id: string }>();
  const {
    requestParams,
    updatePagingParams,
    setRequestParamsAndResetPaging,
  } = useRequestParamsWithPagination<
    Pick<PoliciesForResourceInput, typeof acceptedRequestParams[number]>
  >();

  const { error, previousData, data = previousData, loading } = useResourceDetails({
    fetchPolicy: 'cache-and-network',
    variables: {
      resourceDetailsInput: {
        resourceId: match.params.id,
      },
      policiesForResourceInput: {
        ...pick(requestParams, acceptedRequestParams),
        resourceId: match.params.id,
        pageSize: DEFAULT_SMALL_PAGE_SIZE,
      },
    },
  });

  if (loading && !data) {
    return <ResourceDetailsPageSkeleton />;
  }

  if (error) {
    return (
      <Box mb={6}>
        <Alert
          variant="error"
          title="Couldn't load resource"
          description={
            extractErrorMessage(error) ||
            "An unknown error occured and we couldn't load the resource details from the server"
          }
        />
      </Box>
    );
  }

  const policies = data.policiesForResource.items;
  const totalCounts = data.policiesForResource.totals;
  const pagingData = data.policiesForResource.paging;

  const policyResultsExist = policies.length > 0;
  const arePoliciesFiltered = !!requestParams.status || !!requestParams.suppressed;
  const resourceHasPolicies = getComplianceItemsTotalCount(totalCounts) > 0;
  return (
    <article>
      <ErrorBoundary>
        <Box mb={5}>
          <ResourceDetailsInfo resource={data.resource} />
        </Box>
      </ErrorBoundary>
      <Box mb={5}>
        <ResourceDetailsAttributes resource={data.resource} />
      </Box>
      <Box mb={6}>
        <Panel title="Policies">
          <ErrorBoundary>
            <Box mb={6}>
              {resourceHasPolicies && (
                <Flex spacing={1}>
                  <TableControlsComplianceFilter
                    mr={1}
                    count={getComplianceItemsTotalCount(totalCounts)}
                    text="All"
                    isActive={!arePoliciesFiltered}
                    onClick={() => setRequestParamsAndResetPaging({})}
                  />
                  <TableControlsComplianceFilter
                    data-tid="resource-policies-view-failing"
                    mr={1}
                    count={totalCounts.active.fail}
                    text="Failing"
                    isActive={requestParams.status === ComplianceStatusEnum.Fail}
                    onClick={() =>
                      setRequestParamsAndResetPaging({
                        status: ComplianceStatusEnum.Fail,
                        suppressed: false,
                      })
                    }
                  />
                  <TableControlsComplianceFilter
                    data-tid="resource-policies-view-passing"
                    mr={1}
                    count={totalCounts.active.pass}
                    text="Passing"
                    isActive={requestParams.status === ComplianceStatusEnum.Pass}
                    onClick={() =>
                      setRequestParamsAndResetPaging({
                        status: ComplianceStatusEnum.Pass,
                        suppressed: false,
                      })
                    }
                  />
                  <TableControlsComplianceFilter
                    data-tid="resource-policies-view-ignored"
                    mr={1}
                    count={
                      totalCounts.suppressed.fail +
                      totalCounts.suppressed.pass +
                      totalCounts.suppressed.error
                    }
                    text="Ignored"
                    isActive={!requestParams.status && requestParams.suppressed}
                    onClick={() =>
                      setRequestParamsAndResetPaging({
                        status: null,
                        suppressed: true,
                      })
                    }
                  />
                  <TableControlsComplianceFilter
                    data-tid="resource-policies-view-errored"
                    count={totalCounts.active.error}
                    text="Errored"
                    isActive={requestParams.status === ComplianceStatusEnum.Error}
                    onClick={() =>
                      setRequestParamsAndResetPaging({
                        status: ComplianceStatusEnum.Error,
                        suppressed: false,
                      })
                    }
                  />
                </Flex>
              )}
            </Box>
            {!policyResultsExist && !arePoliciesFiltered && (
              <Flex justify="center" align="center" direction="column" my={8} spacing={8}>
                <img alt="Empty Box Illustration" src={EmptyBoxImg} width="auto" height={200} />
                <Heading size="small" color="navyblue-100">
                  This resource doesn{"'"}t have any policies applied to it
                </Heading>
              </Flex>
            )}
            {!policyResultsExist && arePoliciesFiltered && (
              <Box my={6}>
                <NoResultsFound />
              </Box>
            )}
            {policyResultsExist && (
              <Flex direction="column" spacing={6}>
                <ResourceDetailsTable policies={policies} />
                <TableControlsPagination
                  page={pagingData.thisPage}
                  totalPages={pagingData.totalPages}
                  onPageChange={updatePagingParams}
                />
              </Flex>
            )}
          </ErrorBoundary>
        </Panel>
      </Box>
    </article>
  );
};

export default compose(
  withSEO({ title: ({ match }) => match.params.id }),
  withRoleRestrictedAccess({
    allowedPermissions: [Permission.ResourceRead, Permission.ResourceModify],
    fallback: <Page403 />,
  })
)(ResourceDetailsPage);
