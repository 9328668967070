/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import {
  Box,
  Button,
  Divider,
  FadeIn,
  Flex,
  Heading,
  IconButton,
  Img,
  Text,
  TextProps,
  HeadingProps,
  BoxProps,
  ImgProps,
} from 'pouncejs';
import { useWizardContext } from './WizardContext';

interface WizardPanelAction {
  disabled?: boolean;
}

type WizardPanelActionGoToStep = WizardPanelAction & {
  stepIndex: number;
};

interface WizardPanelHeadingProps {
  title: string | React.ReactNode | React.ReactNode[];
  subtitle?: string | React.ReactNode | React.ReactNode[];
  logo?: string;
  divider?: React.ReactNode;
  rootProps?: BoxProps;
  titleProps?: HeadingProps;
  subtitleProps?: TextProps;
  logoProps?: Partial<ImgProps>;
}

interface WizardPanelComposition {
  Actions: React.FC;
  ActionNext: React.FC<WizardPanelAction>;
  ActionGoToStep: React.FC<WizardPanelActionGoToStep>;
  ActionStart: React.FC<WizardPanelAction>;
  ActionPrev: React.FC<WizardPanelAction>;
  Heading: React.FC<WizardPanelHeadingProps>;
}

const DefaultDivider = () => <Divider width={0.3} mx="auto" my={4} color="navyblue-300" />;

const WizardPanel: React.FC<BoxProps> & WizardPanelComposition = ({ children, ...rest }) => {
  return (
    <Box as="section" {...rest}>
      <FadeIn>{children}</FadeIn>
    </Box>
  );
};

const WizardPanelHeading: React.FC<WizardPanelHeadingProps> = ({
  title,
  subtitle,
  logo,
  divider = <DefaultDivider />,
  rootProps,
  titleProps,
  subtitleProps,
  logoProps,
}) => {
  return (
    <Box as="header" mb={10} textAlign="center" {...rootProps}>
      <Heading size="small" fontWeight="medium" {...titleProps}>
        {title}
      </Heading>
      {divider}
      {subtitle && (
        <Text
          as="h2"
          fontSize="large"
          mt={2}
          color="white-100"
          fontWeight="medium"
          {...subtitleProps}
        >
          {subtitle}
        </Text>
      )}
      {logo && (
        <Img
          alt="logo"
          src={logo}
          nativeHeight={60}
          nativeWidth={60}
          mt={5}
          mb={-5}
          {...logoProps}
        />
      )}
    </Box>
  );
};

const WizardPanelActions: React.FC = ({ children }) => {
  return (
    <Flex justify="center" mt={8}>
      {children}
    </Flex>
  );
};

const WizardPanelActionPrev: React.FC<WizardPanelAction> = ({ disabled }) => {
  const { goToPrevStep } = useWizardContext();
  return (
    <Box position="absolute" top={6} left={6}>
      <IconButton
        disabled={disabled}
        icon="arrow-back"
        variant="outline"
        variantColor="navyblue-300"
        aria-label="Go Back"
        onClick={goToPrevStep}
      />
    </Box>
  );
};

const WizardPanelActionStart: React.FC<WizardPanelAction> = ({ disabled, children }) => {
  const { reset } = useWizardContext();
  return (
    <Button onClick={reset} disabled={disabled}>
      {children || 'Start Over'}
    </Button>
  );
};

const WizardPanelActionNext: React.FC<WizardPanelAction> = ({ disabled, children }) => {
  const { goToNextStep } = useWizardContext();
  return (
    <Button onClick={goToNextStep} disabled={disabled}>
      {children || 'Next'}
    </Button>
  );
};

const WizardPanelActionGoToStep: React.FC<WizardPanelActionGoToStep> = ({
  disabled,
  children,
  stepIndex,
}) => {
  const { goToStep } = useWizardContext();
  return (
    <Button onClick={() => goToStep(stepIndex)} disabled={disabled}>
      {children || 'Continue'}
    </Button>
  );
};

WizardPanel.Actions = React.memo(WizardPanelActions);
WizardPanel.ActionPrev = React.memo(WizardPanelActionPrev);
WizardPanel.ActionNext = React.memo(WizardPanelActionNext);
WizardPanel.ActionGoToStep = React.memo(WizardPanelActionGoToStep);
WizardPanel.ActionStart = React.memo(WizardPanelActionStart);
WizardPanel.Heading = React.memo(WizardPanelHeading);

export default WizardPanel;
