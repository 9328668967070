/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */
import React from 'react';
import { Box, Flex } from 'pouncejs';

interface CardValueProps {
  label: string;
  value: React.ReactNode;
}

const CardValue: React.FC<CardValueProps> = ({ label, value, ...rest }) => {
  return (
    <Flex direction="column" overflow="hidden" data-testid="card-value">
      <Box mt={2} color="gray-300" fontSize="small" fontWeight="medium">
        {label}
      </Box>
      <Box
        display="inline-flex"
        flexGrow={1}
        fontSize="medium"
        fontWeight="medium"
        opacity={value ? 1 : 0.3}
        alignItems="center"
        minHeight={24}
        {...rest}
      >
        {value || 'Not Set'}
      </Box>
    </Flex>
  );
};

export default CardValue;
