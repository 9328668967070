/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Field } from 'formik';
import { useGetDataSchemaFieldsLazyQuery } from 'Components/wizards/LookupWizard/LookupTableSchemaPanel/graphql/getDataSchemaFields.generated';
import FormikMultiCombobox from 'Components/fields/MultiComboBox';
import { Box } from 'pouncejs';

interface LogTypeSelectorsFieldProps {
  name: string;
  logType?: string;
}

const LogTypeSelectorsField: React.FC<LogTypeSelectorsFieldProps> = ({ name, logType }) => {
  // This is a lazy query to avoid sending lots of concurrent queries to the server
  // when a lookup table has a high number of associated log types.
  const [loadSchemaFields, { data }] = useGetDataSchemaFieldsLazyQuery({
    variables: {
      input: {
        name: logType,
      },
    },
  });

  const selectors = React.useMemo(() => {
    return data?.getSchema?.record?.fields.map(field => field.name) || [];
  }, [data]);

  return (
    <Box>
      <Field
        data-tid="lookup-selectors-field"
        as={FormikMultiCombobox}
        name={name}
        label="Selectors"
        disabled={!logType}
        allowAdditions
        searchable
        items={selectors}
        placeholder="Columns or JSON path selectors"
        // MultiCombobox doesn't support a direct `onFocus` prop currently, so we need
        // to use `onFocusCapture` to catch any bubbled focus events and lazy load the data.
        onFocusCapture={loadSchemaFields}
      />
    </Box>
  );
};

export default LogTypeSelectorsField;
