/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';

import { DataLakeQueryStatus } from 'Generated/schema';
import FixedWidthBadge from 'Components/FixedWidthBadge';
import { QUERY_STATUS_COLOR_MAP } from 'Source/constants';

interface QueryStatusBadgeProps {
  status: DataLakeQueryStatus;
}

const QueryStatusBadge: React.FC<QueryStatusBadgeProps> = ({ status }) => {
  // Status should always be defined, but on certain extreme cases, we've observed
  // that `status` comes back as undefined so we have to protect against that
  const badgeText = status ? status.toUpperCase() : 'UNKNOWN';
  const badgeColor = status ? QUERY_STATUS_COLOR_MAP[status] : 'orange-600';

  return (
    <FixedWidthBadge width={90} color={badgeColor}>
      {badgeText}
    </FixedWidthBadge>
  );
};

export default QueryStatusBadge;
