/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import { Box, Flex, Link } from 'pouncejs';
import { Link as RRLink } from 'react-router-dom';
import urls from 'Source/urls';
import React from 'react';
import RoleRestrictedAccess from 'Components/utils/RoleRestrictedAccess';
import { Permission } from 'Generated/schema';

const LogTypeCard: React.FC<{ sourceId: string; logType: string }> = ({ sourceId, logType }) => {
  return (
    <Flex
      backgroundColor="navyblue-600"
      spacing={2}
      justify="space-between"
      p={4}
      key={logType}
      mb={2}
    >
      <Box as="h4" fontWeight="medium" maxWidth="60%" wordBreak="break-word">
        <Link
          as={RRLink}
          aria-label={`Link to ${logType} schema`}
          to={`${urls.data.schemas.details(logType)}`}
        >
          {logType}
        </Link>
      </Box>
      <RoleRestrictedAccess allowedPermissions={[Permission.DataAnalyticsRead]}>
        <Box
          as={RRLink}
          px={2}
          py={1}
          borderRadius="small"
          fontSize="small"
          backgroundColor="blue-300"
          aria-label={`link to data explorer`}
          to={`${urls.data.dataExplorer()}?snippedId=sourceLogtype&sourceId=${sourceId}&logType=${logType}`}
        >
          View Data
        </Box>
      </RoleRestrictedAccess>
    </Flex>
  );
};

export default LogTypeCard;
