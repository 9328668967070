/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import TablePlaceholder from 'Components/TablePlaceholder';
import { Card, FadeIn, Flex, Box } from 'pouncejs';

const Skeleton: React.FC = () => {
  return (
    <FadeIn from="bottom">
      <Flex spacing={6} flexDirection="column">
        <Card p={6}>
          <Box width="30%">
            <TablePlaceholder rowCount={1} rowHeight={48} rowGap={8} />
          </Box>
          <Box width="15%">
            <TablePlaceholder rowCount={1} rowHeight={48} rowGap={25} />
          </Box>
          <Box width="80%">
            <TablePlaceholder rowCount={1} rowHeight={10} rowGap={1} />
          </Box>
        </Card>
        <Card p={6}>
          <Box my={7} />
          <TablePlaceholder rowCount={6} rowHeight={12} rowGap={4} />
        </Card>
      </Flex>
    </FadeIn>
  );
};

export default Skeleton;
