/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { Wizard } from 'Components/Wizard';
import { SqsLogSourceIntegrationDetails } from 'Source/graphql/fragments/SqsLogSourceIntegrationDetails.generated';
import { yupIntegrationLabelUniqueness } from 'Helpers/utils';
import useFindSourcesPerMethod from 'Hooks/useFindSourcesPerMethod';
import { LogTransportMethodsEnum } from 'Source/constants';
import CancelIntegrationAction from 'Pages/Integrations/components/CancelIntegrationAction';
import SqsSourceConfigurationPanel from './SqsSourceConfigurationPanel';
import ValidationPanel from './ValidationPanel';

interface SqsLogSourceWizardProps {
  initialValues: SqsLogSourceWizardValues;
  availableLogTypes: string[];
  onSubmit: (values: SqsLogSourceWizardValues) => Promise<SqsLogSourceIntegrationDetails | Error>;
}

export interface SqsLogSourceWizardValues {
  // for updates
  integrationId?: string;
  integrationLabel: string;
  logTypes: string[];
  allowedPrincipalArns: string[];
  allowedSourceArns: string[];
  queueUrl?: string;
}

const SqsSourceWizard: React.FC<SqsLogSourceWizardProps> = ({
  initialValues,
  onSubmit,
  availableLogTypes,
}) => {
  const existingSqsSources = useFindSourcesPerMethod(LogTransportMethodsEnum.sqs);

  const validationSchema: Yup.SchemaOf<SqsLogSourceWizardValues> = React.useMemo(
    () =>
      Yup.object().shape({
        integrationLabel: yupIntegrationLabelUniqueness({
          existingLabel: initialValues.integrationLabel,
          existingSources: existingSqsSources,
          methodName: 'SQS',
        }),
        logTypes: Yup.array().of(Yup.string()).required().min(1),
        allowedPrincipalArns: Yup.array().of(Yup.string()),
        allowedSourceArns: Yup.array()
          .of(Yup.string())
          .test(
            'mutex',
            'Cannot have more than 20 AWS resources & Principals ARNs',
            // eslint-disable-next-line func-names
            function (value) {
              return this.parent.allowedPrincipalArns.length + value.length <= 20;
            }
          ),
        integrationId: Yup.string(),
        queueUrl: Yup.string(),
      }),
    [initialValues.integrationLabel, existingSqsSources]
  );

  const formStatus = React.useMemo(() => ({ availableLogTypes }), [availableLogTypes]);
  const editMode = !!initialValues.integrationId;
  return (
    <Formik<SqsLogSourceWizardValues>
      enableReinitialize
      initialValues={initialValues}
      initialStatus={formStatus}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      <Form>
        <Wizard headerTitle="Connecting a new Log Source" enableAllNavigationSteps={editMode}>
          <Wizard.Step
            title="Configure Source"
            description="Provide account information and set preferences to connect the log source to Panther"
          >
            <SqsSourceConfigurationPanel />
          </Wizard.Step>
          <Wizard.Step
            title="Verify Setup"
            description="Confirm that your log source has been successfully onboarded or troubleshoot any issues"
            navigationDisabled={editMode}
          >
            <ValidationPanel />
          </Wizard.Step>
        </Wizard>
        {!editMode && <CancelIntegrationAction />}
      </Form>
    </Formik>
  );
};

export default SqsSourceWizard;
