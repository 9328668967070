/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import withSEO from 'Hoc/withSEO';
import { compose } from 'Helpers/compose';
import withRoleRestrictedAccess from 'Hoc/withRoleRestrictedAccess';
import useAvailableLogTypesForLogPuller from 'Hooks/useAvailableLogTypesForLogPuller';
import { GithubAuthorizationMethodEnum, Permission } from 'Generated/schema';
import Page403 from 'Pages/403';
import GithubLogsSourceWizard, { GithubLogsSourceWizardValues } from '../GithubLogsSourceWizard';
import { useAddGithubLogSource } from './graphql/addGithubLogSource.generated';

const initialValues = {
  integrationLabel: '',
  organization: '',
  clientId: '',
  clientSecret: '',
  personalAccessToken: '',
  authorizationMethod: GithubAuthorizationMethodEnum.Oauth2,
};

const CreateGithubLogSource: React.FC = () => {
  const { defaultLogTypes } = useAvailableLogTypesForLogPuller();
  const [addGithubLogSource] = useAddGithubLogSource({
    update: (cache, { data }) => {
      cache.modify({
        fields: {
          logSources: (queryData, { toReference }) => {
            const addedIntegrationCacheRef = toReference(data.addLogPullingIntegration);
            return queryData
              ? [addedIntegrationCacheRef, ...queryData]
              : [addedIntegrationCacheRef];
          },
        },
      });
    },
  });

  const handleSubmit = React.useCallback(
    async (values: GithubLogsSourceWizardValues) => {
      try {
        const { data } = await addGithubLogSource({
          variables: {
            input: {
              integrationLabel: values.integrationLabel,
              pullerConfig: {
                logTypes: defaultLogTypes,
                github: {
                  organization: values.organization,
                  patCredentials: {
                    personalAccessToken: values.personalAccessToken,
                  },
                  oauth2Credentials: {
                    clientId: values.clientId,
                    clientSecret: values.clientSecret,
                  },
                  authorizationMethod: values.authorizationMethod,
                },
              },
            },
          },
        });

        return data.addLogPullingIntegration;
      } catch (err) {
        return err as Error;
      }
    },
    [addGithubLogSource, defaultLogTypes]
  );

  return <GithubLogsSourceWizard initialValues={initialValues} onSubmit={handleSubmit} />;
};

export default compose(
  withSEO({ title: 'New Github Log Source' }),
  withRoleRestrictedAccess({
    allowedPermissions: [Permission.LogSourceModify],
    fallback: <Page403 />,
  })
)(CreateGithubLogSource);
