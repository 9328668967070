/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import LinkButton from 'Components/buttons/LinkButton';
import { WizardPanel } from 'Components/Wizard';
import { useFormikContext, FastField } from 'formik';
import { Box, Flex, Text, useSnackbar, SimpleGrid, Heading } from 'pouncejs';
import { PANTHER_CONFIG } from 'Source/constants';
import EditIntegrationActions from 'Pages/Integrations/components/EditIntegrationActions';
import FormikTextInput from 'Components/fields/TextInput';
import { useGetComplianceCfnTemplate } from './graphql/getComplianceCfnTemplate.generated';
import { ComplianceSourceWizardValues } from '../ComplianceSourceWizard';

const ConsoleMethod: React.FC = () => {
  const { pushSnackbar } = useSnackbar();

  const { values, isValid } = useFormikContext<ComplianceSourceWizardValues>();
  const { data, loading, error } = useGetComplianceCfnTemplate({
    variables: {
      input: {
        integrationLabel: values.integrationLabel,
      },
    },
    onError: () => pushSnackbar({ variant: 'error', title: 'Failed to generate CFN template' }),
  });

  const { stackName } = data?.getComplianceIntegrationTemplate ?? {};
  const cfnConsoleLink =
    `https://console.aws.amazon.com/cloudformation/home#/stacks/create/review` +
    `?templateURL=https://s3-us-west-2.amazonaws.com/panther-public-cloudformation-templates/panther-cloudsec-iam/v${PANTHER_CONFIG.PANTHER_VERSION}/template.yml` +
    `&stackName=${stackName}` +
    `&param_MasterAccountRegion=${PANTHER_CONFIG.AWS_REGION}` +
    `&param_MasterAccountId=${PANTHER_CONFIG.AWS_ACCOUNT_ID}`;

  const editMode = !!values.integrationId;

  return (
    <WizardPanel>
      <WizardPanel.Heading title="Use AWS UI to setup your role" />

      <SimpleGrid gap={5} width={0.8} mx="auto">
        <Box as="section">
          <Heading as="h2" color="teal-200" fontSize="x-large" fontWeight="medium" mb={1}>
            1. Deploy CloudFormation Template
          </Heading>

          <Text color="white-100" fontSize="medium" lineHeight="loose">
            Deploy our autogenerated Cloudformation template to the AWS account
            <b> {values.awsAccountId}</b>, to generate the necessary ReadOnly IAM Role.
          </Text>
        </Box>
        <Flex as="section" justifyContent="center" mb={2}>
          <LinkButton
            external
            loading={loading}
            disabled={!!error || loading}
            to={cfnConsoleLink}
            variantColor="teal-500"
            icon="spacecraft"
          >
            Launch Console UI
          </LinkButton>
        </Flex>
        <Box as="section">
          <Heading as="h2" color="teal-200" fontSize="x-large" fontWeight="medium">
            2. Enter Role ARN
          </Heading>
          <Text color="white-100" fontSize="medium" lineHeight="loose" my={1}>
            Go to the {'"'}Outputs{'"'} tab of the CloudFormation stack that you just deployed and
            copy the value for RoleARN into the box below.
          </Text>

          <FastField
            name="auditRole"
            as={FormikTextInput}
            label="Role ARN"
            trimPastedText
            required
          />
        </Box>
      </SimpleGrid>

      <WizardPanel.Actions>
        <WizardPanel.ActionPrev />
        {editMode ? (
          <EditIntegrationActions />
        ) : (
          <Flex spacing={4} direction="column" align="center">
            <Text fontSize="small" fontWeight="medium">
              After deploying the stack and entering the Role ARN, click below to proceed
            </Text>
            <WizardPanel.ActionNext disabled={!isValid}>Continue Setup</WizardPanel.ActionNext>
          </Flex>
        )}
      </WizardPanel.Actions>
    </WizardPanel>
  );
};

export default ConsoleMethod;
