/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Badge, BadgeProps } from 'pouncejs';

interface BetaTagProps extends Omit<BadgeProps, 'color' | 'children'> {
  variant?: 'fill' | 'border';
  size?: BadgeProps['size'];
}

/**
 * Displays that a feature is currently "BETA" (not fully stable). Comes in two
 * variants, filled (solid background) and border (transparent background)
 */
export const BetaTag = ({ variant = 'fill', size = 'medium', ...props }: BetaTagProps) => {
  return (
    <Badge
      color="navyblue-200"
      size={size}
      {...props}
      // FIXME: we can remove this style override if / when the `Badge` component in
      // `pounce` is updated to allow solid backgrounds as well as translucent ones.
      // @ts-expect-error
      backgroundColor={variant === 'fill' ? 'navyblue-200' : undefined}
    >
      BETA
    </Badge>
  );
};

export default BetaTag;
