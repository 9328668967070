/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { ZendeskLogIntegrationDetails } from 'Source/graphql/fragments/ZendeskLogIntegrationDetails.generated';
import { Wizard } from 'Components/Wizard';
import { ZendeskAuthorizationMethodEnum } from 'Generated/schema';
import { yupIntegrationLabelUniqueness } from 'Helpers/utils';
import { LogTransportMethodsEnum, SUBDOMAIN_REGEX } from 'Source/constants';
import useFindSourcesPerMethod from 'Hooks/useFindSourcesPerMethod';
import CancelIntegrationAction from 'Pages/Integrations/components/CancelIntegrationAction';
import ValidationPanel from './ValidationPanel';
import ConfigurationPanel from './ConfigurationPanel';
import CredentialsPanel from './CredentialsPanel';

interface ZendeskLogsWizardProps {
  initialValues: ZendeskLogsSourceWizardValues;
  onSubmit: (
    values: ZendeskLogsSourceWizardValues
  ) => Promise<ZendeskLogIntegrationDetails | Error>;
}

export interface ZendeskLogsSourceWizardValues {
  integrationId?: string;
  integrationLabel: string;
  subdomain: string;
  clientId: string;
  clientSecret: string;
  email: string;
  password: string;
  apiToken: string;
  authorizationMethod: ZendeskAuthorizationMethodEnum;
}

const ZendeskLogsSourceWizard: React.FC<ZendeskLogsWizardProps> = ({ initialValues, onSubmit }) => {
  const existingPullerSources = useFindSourcesPerMethod(LogTransportMethodsEnum.logPulling);
  const validationSchema: Yup.SchemaOf<ZendeskLogsSourceWizardValues> = React.useMemo(() => {
    const isUpdateFromOAuth2 = Boolean(
      initialValues.integrationId &&
        initialValues.authorizationMethod === ZendeskAuthorizationMethodEnum.Oauth2
    );
    const isUpdateFromBasic = Boolean(
      initialValues.integrationId &&
        initialValues.authorizationMethod === ZendeskAuthorizationMethodEnum.Basic
    );

    const isUpdateFromApi = Boolean(
      initialValues.integrationId &&
        initialValues.authorizationMethod === ZendeskAuthorizationMethodEnum.ApiToken
    );

    return Yup.object().shape({
      integrationId: Yup.string(),
      integrationLabel: yupIntegrationLabelUniqueness({
        existingLabel: initialValues.integrationLabel,
        existingSources: existingPullerSources,
      }),
      subdomain: Yup.string()
        .matches(SUBDOMAIN_REGEX, 'Only lowercase letters, numbers and `-` is allowed')
        .required(),
      authorizationMethod: Yup.string()
        .oneOf(Object.values(ZendeskAuthorizationMethodEnum))
        .required(),
      // Oauth2
      clientId: Yup.string().when('authorizationMethod', {
        is: authorizationMethod =>
          authorizationMethod === ZendeskAuthorizationMethodEnum.Basic ||
          authorizationMethod === ZendeskAuthorizationMethodEnum.ApiToken,
        then: Yup.string(),
        otherwise: Yup.string().required(),
      }),
      clientSecret: Yup.string().when('authorizationMethod', {
        is: authorizationMethod =>
          authorizationMethod === ZendeskAuthorizationMethodEnum.Basic ||
          authorizationMethod === ZendeskAuthorizationMethodEnum.ApiToken ||
          isUpdateFromOAuth2,
        then: Yup.string(),
        otherwise: Yup.string().required(),
      }),

      // Basic & Api
      email: Yup.string().when('authorizationMethod', {
        is: authorizationMethod =>
          authorizationMethod === ZendeskAuthorizationMethodEnum.Oauth2 ||
          isUpdateFromBasic ||
          isUpdateFromApi,
        then: Yup.string().email(),
        otherwise: Yup.string().email().required(),
      }),

      // Basic
      password: Yup.string().when('authorizationMethod', {
        is: authorizationMethod =>
          authorizationMethod === ZendeskAuthorizationMethodEnum.Oauth2 ||
          authorizationMethod === ZendeskAuthorizationMethodEnum.ApiToken ||
          isUpdateFromBasic,
        then: Yup.string(),
        otherwise: Yup.string().required(),
      }),
      // API
      apiToken: Yup.string().when('authorizationMethod', {
        is: authorizationMethod =>
          authorizationMethod === ZendeskAuthorizationMethodEnum.Oauth2 ||
          authorizationMethod === ZendeskAuthorizationMethodEnum.Basic ||
          isUpdateFromApi,
        then: Yup.string(),
        otherwise: Yup.string().required(),
      }),
    });
    // FIXME: look into effect dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValues.integrationId, initialValues.authorizationMethod, existingPullerSources]);

  const editMode = !!initialValues.integrationId;
  return (
    <Formik<ZendeskLogsSourceWizardValues>
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      <Form>
        <Wizard headerTitle="Connecting a new Log Source" enableAllNavigationSteps={editMode}>
          <Wizard.Step
            title="Configure Source"
            description="Provide account information and set preferences to connect the log source to Panther"
          >
            <ConfigurationPanel />
          </Wizard.Step>
          <Wizard.Step
            title="Set Credentials"
            description="Provide account credentials to give Panther permissions to collect logs from the source"
          >
            <CredentialsPanel />
          </Wizard.Step>
          <Wizard.Step
            title="Verify Setup"
            description="Confirm that your log source has been successfully onboarded or troubleshoot any issues"
            navigationDisabled={editMode}
          >
            <ValidationPanel />
          </Wizard.Step>
        </Wizard>
        {!editMode && <CancelIntegrationAction />}
      </Form>
    </Formik>
  );
};

export default ZendeskLogsSourceWizard;
