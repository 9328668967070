/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */
import React from 'react';
import { Role } from 'Generated/schema';

import { useListRolesWithSaml } from 'Pages/ListRoles/graphql/listRolesWithSaml.generated';

type SimpleRole = Pick<Role, 'id' | 'name'>;

const useSamlRole = (): SimpleRole => {
  const { data } = useListRolesWithSaml();
  const samlRoleName = data?.roles.find(({ id }) => id === data.samlConfig?.defaultRoleId)?.name;

  // SAML role doesn't have a specific id
  return React.useMemo(
    () => ({ id: '', name: samlRoleName ? `${samlRoleName} (SAML)` : 'Default SAML' }),
    [samlRoleName]
  );
};

export default useSamlRole;
