/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import * as Yup from 'yup';
import { Severity, DestinationConfigInput, AlertType } from 'Generated/schema';
import { Box, SimpleGrid, Flex, Text } from 'pouncejs';
import { FastField, Form, Formik } from 'formik';
import urls from 'Source/urls';
import Breadcrumbs from 'Components/Breadcrumbs';
import SaveButton from 'Components/buttons/SaveButton';
import LinkButton from 'Components/buttons/LinkButton';
import SubmitButton from 'Components/buttons/SubmitButton';
import FormikMultiCombobox from 'Components/fields/MultiComboBox';
import { alertTypeToString, getEnumKeyByValue } from 'Helpers/utils';

export interface BaseDestinationFormValues<
  AdditionalValues extends Partial<DestinationConfigInput>
> {
  outputId?: string;
  displayName: string;
  outputConfig: AdditionalValues;
  defaultForSeverity: Severity[];
  alertTypes: AlertType[];
}

interface BaseDestinationFormProps<AdditionalValues extends Partial<DestinationConfigInput>> {
  /**
   * The initial values of the form. `DefaultForSeverity` is given as a list of severity values,
   * while internally the form will treat them as an object with the keys being the severities and
   * the values being true/false. This is a limitation on using a checkbox to control each severity
   * */
  initialValues: BaseDestinationFormValues<AdditionalValues>;

  /**
   * The validation schema for the form
   */
  validationSchema?: Yup.SchemaOf<Partial<BaseDestinationFormValues<AdditionalValues>>>;

  /** callback for the submission of the form */
  onSubmit: (values: BaseDestinationFormValues<AdditionalValues>) => void;
}

const AlertTypeValues = Object.values(AlertType);
const SeverityValues = Object.values(Severity);
const severityToString = value => getEnumKeyByValue(Severity, value);

// The validation checks that Formik will run
export const defaultValidationSchema = Yup.object().shape({
  outputId: Yup.string(),
  displayName: Yup.string().required(),
  defaultForSeverity: Yup.array().of(Yup.string().oneOf(Object.values(Severity)).required()),
  alertTypes: Yup.array()
    .min(1)
    .of(Yup.string().oneOf(Object.values(AlertType)).required()),
});

function BaseDestinationForm<AdditionalValues extends Partial<DestinationConfigInput>>({
  initialValues,
  validationSchema,
  onSubmit,
  children,
}: React.PropsWithChildren<BaseDestinationFormProps<AdditionalValues>>): React.ReactElement {
  return (
    <Formik<BaseDestinationFormValues<AdditionalValues>>
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      <Form autoComplete="off">
        {children}
        <SimpleGrid columns={2} gap={5} my={4} textAlign="left">
          <Box width={1}>
            Severity Levels
            <Text
              color="gray-300"
              fontSize="small-medium"
              id="severity-disclaimer"
              mt={1}
              fontWeight="medium"
            >
              We will only notify you on issues related to these severity types
            </Text>
          </Box>
          <FastField
            name="defaultForSeverity"
            as={FormikMultiCombobox}
            items={SeverityValues}
            itemToString={severityToString}
            label="Severity"
            placeholder="Select severities"
            aria-describedby="severity-disclaimer"
          />

          <Box>
            Default Alert Types
            <Text
              color="gray-300"
              fontSize="small-medium"
              id="alert-type-disclaimer"
              mt={1}
              fontWeight="medium"
            >
              The selected alert types will be default for this destination
            </Text>
          </Box>
          <FastField
            name="alertTypes"
            as={FormikMultiCombobox}
            items={AlertTypeValues}
            itemToString={alertTypeToString}
            label="Alert Types"
            placeholder="Select Alert Types"
            required
            aria-describedby="alert-type-disclaimer"
          />
        </SimpleGrid>
        {initialValues.outputId ? (
          <Breadcrumbs.Actions>
            <Flex spacing={4} justify="flex-end">
              <SaveButton aria-label="Update Destination">Update Destination</SaveButton>
              <LinkButton
                variantColor="gray-600"
                icon="close-outline"
                aria-label="Cancel destination editing"
                to={urls.integrations.destinations.list()}
              >
                Cancel
              </LinkButton>
            </Flex>
          </Breadcrumbs.Actions>
        ) : (
          <Flex justify="center" my={6}>
            <SubmitButton aria-label="Add destination">Add Destination</SubmitButton>
          </Flex>
        )}
      </Form>
    </Formik>
  );
}

export default BaseDestinationForm;
