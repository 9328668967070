/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Card, Flex, Text } from 'pouncejs';
import BulletedLoading from 'Components/BulletedLoading';
import ElapsedTime from 'Components/ElapsedTime';
import useTimer from 'Hooks/useTimer';
// import DataScanned from 'Components/DataScanned';
// import { useSchemasManagementContext } from '../SchemasManagementContext';

const InferJobOverlay = () => {
  const { timeElapsed } = useTimer();
  // const { inferSchemaJob } = useSchemasManagementContext();

  return (
    <Flex
      position="absolute"
      direction="column"
      spacing={3}
      backgroundColor="navyblue-700"
      height="100%"
      zIndex={2}
      align="center"
      opacity={0.9}
      justify="center"
      width={1}
    >
      <Text textAlign="center" opacity={1} fontWeight="bold">
        Creating new Schema and updating
      </Text>
      <BulletedLoading />
      <Flex spacing={2}>
        <Card variant="dark">
          <ElapsedTime ms={timeElapsed} label="Elapsed Time" />
        </Card>
        {/* <Card variant="dark">
          <DataScanned label="Data Scanned" bytes={inferSchemaJob?.stats?.totalSizeBytes} />
        </Card> */}
      </Flex>
    </Flex>
  );
};

export default InferJobOverlay;
