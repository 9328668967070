/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import { Heading, Flex, Card } from 'pouncejs';
import React from 'react';
import { ReplayFull } from 'Source/graphql/fragments/ReplayFull.generated';
import { formatNumber, formatBytes } from 'Helpers/utils';
import ReplayStatsBox from './ReplayStatsBox';

type ReplayStatsProps = {
  replay: ReplayFull;
};

const ReplayStats: React.FC<ReplayStatsProps> = ({ replay }) => {
  const [logDataSize, logDataSizeUnit] = formatBytes(replay.summary.logDataSizeEstimate).split(' ');

  return (
    <Card variant="dark" pl={4} py={4} mb={4}>
      <Heading size="x-small" pb={6} pt={2} px={1}>
        Replay Test Summary
      </Heading>
      <Flex>
        <ReplayStatsBox label="Alerts" metric={formatNumber(replay.summary.ruleMatchCount)} />
        <ReplayStatsBox label="Rule Errors" metric={formatNumber(replay.summary.ruleErrorCount)} />
        <ReplayStatsBox
          unit={logDataSizeUnit}
          label="Estimated Volume Processed"
          metric={logDataSize}
        />
        <ReplayStatsBox
          label="Matched Events"
          metric={formatNumber(replay.summary.matchesProcessedCount)}
        />
      </Flex>
    </Card>
  );
};

export default React.memo(ReplayStats);
