/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import uniqBy from 'lodash/uniqBy';
import intersectionBy from 'lodash/intersectionBy';
import { Destination } from 'Generated/schema';
import { AlertSummaryFull } from 'Source/graphql/fragments/AlertSummaryFull.generated';
import { useListDestinations } from 'Source/graphql/queries';

interface UseAlertDestinationsProps {
  alert: AlertSummaryFull;
}

const useAlertDestinations = ({
  alert,
}: UseAlertDestinationsProps): {
  alertDestinations: Pick<Destination, 'outputType' | 'outputId' | 'displayName'>[];
  loading: boolean;
} => {
  const { data: destinations, loading } = useListDestinations();

  const alertDestinations = React.useMemo(() => {
    if (!alert || !destinations?.destinations) {
      return [];
    }

    const uniqueDestinations = uniqBy(alert.deliveries, 'outputId');
    return intersectionBy(destinations.destinations, uniqueDestinations, d => d.outputId);
  }, [alert, destinations]);

  return React.useMemo(
    () => ({
      alertDestinations,
      loading,
    }),
    [alertDestinations, loading]
  );
};

export default useAlertDestinations;
