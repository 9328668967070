/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Box, Flex, Button, Text } from 'pouncejs';
import { WizardPanel } from 'Components/Wizard';

interface ErrorUploaderProps {
  errorMsg: string;
  onRestartUploading: () => void;
}

type BulkUploadIssue = {
  path: string;
  errorMessage: string;
};

type BulkUploadRichError = {
  issues: BulkUploadIssue[];
};

function getRichError(errorMsg: string): BulkUploadRichError | null {
  try {
    const richError = JSON.parse(errorMsg);
    if (richError && richError.issues) {
      return richError as BulkUploadRichError;
    }
    return null;
  } catch (err) {
    return null;
  }
}

const ErrorUploader: React.FC<ErrorUploaderProps> = ({ errorMsg, onRestartUploading }) => {
  const richError = React.useMemo(() => getRichError(errorMsg), [errorMsg]);

  return (
    <>
      <WizardPanel.Heading
        title="Something went wrong!"
        subtitle="Have a look at the error below and try again. If the problem continues please contact us"
      />
      <Flex justify="center">
        <Box backgroundColor="pink-700" textAlign="center" p={6} minWidth={600}>
          <Text size="medium" fontWeight="bold" mb={4}>
            Could not upload your rules
          </Text>
          {richError ? (
            richError.issues.map((issue, idx) => (
              <Box key={idx} mb={4}>
                <Text textAlign="left" fontSize="medium" mb={1}>
                  {issue.path}
                </Text>
                <Text textAlign="left" fontSize="medium" pl={1}>
                  ↳ {issue.errorMessage}
                </Text>
              </Box>
            ))
          ) : (
            <Text fontSize="small" mt={1} fontStyle="italic">
              {errorMsg}
            </Text>
          )}
        </Box>
      </Flex>
      <Flex justify="center" mt={8} mb={4}>
        <Button onClick={onRestartUploading}>Try Again</Button>
      </Flex>
    </>
  );
};

export default React.memo(ErrorUploader);
