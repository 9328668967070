/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';

import {
  CloudWatchLogIntegration,
  EventBridgeIntegration,
  IntegrationItemHealthStatus,
  LogPullingIntegration,
  S3LogIntegration,
  SqsLogSourceIntegration,
  GcsLogSourceIntegration,
  SourceHealthTypesEnum,
} from 'Generated/schema';

/**
 * @description We need to exclude systemAlarmStatus and logProcessingErrorsStatus metrics from the creation wizard.
 * During creation those metric types are always unhealthy.
 * Check the systemStatus api for more details: https://github.com/panther-labs/panther-enterprise/blob/master/api/lambda/system_status/models/api.go
 */
export const excludedMetrics: SourceHealthTypesEnum[] = [
  SourceHealthTypesEnum.SystemAlarmStatus,
  SourceHealthTypesEnum.LogProcessingErrorsStatus,
  SourceHealthTypesEnum.ClassificationFailuresStatus,
];

const useGetLogSourceHealthMetrics = (
  source: Pick<
    | S3LogIntegration
    | LogPullingIntegration
    | EventBridgeIntegration
    | CloudWatchLogIntegration
    | SqsLogSourceIntegration
    | GcsLogSourceIntegration,
    'health'
  >
) => {
  return React.useMemo(() => {
    if (!source) {
      return { totalHealthMetrics: [], healthyMetrics: [], unhealthyMetrics: [] };
    }
    const { __typename, ...healthMetrics } = source.health;
    const totalHealthMetrics = Object.entries(healthMetrics)
      .filter(([, value]) => Boolean(value))
      .reduce<IntegrationItemHealthStatus[]>(
        (prevMetrics, [metricKey, metricValue]) => [
          ...prevMetrics,
          { ...metricValue, type: metricKey },
        ],
        []
      );
    const healthyMetrics = totalHealthMetrics.filter(s => s.healthy);
    const unhealthyMetrics = totalHealthMetrics.filter(s => !s.healthy);

    return {
      totalHealthMetrics,
      healthyMetrics,
      unhealthyMetrics,
    };
  }, [source]);
};

export default useGetLogSourceHealthMetrics;
