/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */
import React from 'react';
import urls from 'Source/urls';
import { useSnackbar } from 'pouncejs';
import { RuleDetails } from 'Source/graphql/fragments/RuleDetails.generated';
import { extractErrorMessage } from 'Helpers/utils';
import useRouter from 'Hooks/useRouter';
import useCloneData from './useCloneData';
import { useGetRuleDetailsLazyQuery } from './graphql/getRuleDetails.generated';

/**
 * Helps retrieve and create a new instance for rule creation
 */
function useCloneRule() {
  const { history } = useRouter();
  const { setCloneData, readCloneData } = useCloneData({ prefix: 'rule-clone-' });
  const { pushSnackbar } = useSnackbar();

  const retrieveClonedRule = React.useCallback(
    (cloneId: string): RuleDetails => {
      return readCloneData(cloneId) as RuleDetails;
    },
    [readCloneData]
  );

  const [clone, { loading: isCloning, error }] = useGetRuleDetailsLazyQuery({
    fetchPolicy: 'network-only', // Don't use cache
    onCompleted: data => {
      const { rule } = data;
      if (!rule) {
        pushSnackbar({
          variant: 'error',
          title: 'Rule not found',
          description: 'The original rule used for cloning might have been deleted',
        });
      } else {
        const cloneId = setCloneData({
          ...rule,
          id: `${rule.id}_copy`,
          displayName: `${rule.displayName} (Copy)`,
          managed: false,
          parentId: rule.id,
        });
        history.push(`${urls.analysis.rules.create()}&cloneId=${cloneId}`);
      }
    },
    onError: err => {
      pushSnackbar({
        variant: 'error',
        title: 'Failed to retrieve rule',
        description: extractErrorMessage(err),
      });
    },
  });

  // FIXME: variables can ONLY be declared on invocation, due to a bug with Apollo and useLazyQuery
  // https://github.com/apollographql/react-apollo/issues/3729#issuecomment-584242862
  const cloneRule = React.useCallback(
    (id: string, versionId?: string) => clone({ variables: { input: { id, versionId } } }),
    [clone]
  );

  return { isCloning, error, cloneRule, retrieveClonedRule };
}

export default useCloneRule;
