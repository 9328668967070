/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Box, Icon, Text, Flex, Grid, Link, AbstractButton, Card, IconButton } from 'pouncejs';

import urls from 'Source/urls';
import { Permission, DataLakeQueryStatus } from 'Generated/schema';
import { DataLakeQueryDetails } from 'Source/graphql/fragments/DataLakeQueryDetails.generated';
import useModal from 'Hooks/useModal';
import { capitalize, formatDatetime, getActorDisplayName } from 'Helpers/utils';
import { Link as RRLink } from 'react-router-dom';
import QueryStatusBadge from 'Components/badges/QueryStatusBadge';
import Editor from 'Components/Editor';
import RoleRestrictedAccess from 'Components/utils/RoleRestrictedAccess';
import CardValue from 'Components/cards/common/CardValue';
import QueryHistoryCardOptions from './QueryHistoryCardOptions';
import QueryIndicators from './QueryIndicators';
import SQLErrorMessage from './SQLErrorMessage';
import { CardMetaValue } from '../common';

interface QueryHistoryCardProps {
  query: DataLakeQueryDetails;
}

export enum QueryKindEnum {
  AdHoc = 'adhoc',
  AlertDetail = 'alert detail',
  AlertEvents = 'alert events',
  AlertSummary = 'alert summary',
  Compaction = 'compaction',
  CTAS = 'create table',
  Indicator = 'indicator',
  IndicatorColumns = 'indicator columns',
  IndicatorTimeline = 'indicator timeline',
  IndicatorDetail = 'indicator detail',
  Scheduled = 'scheduled',
  SystemErrorEvents = 'system error events',
}

export const stringifyQueryKind = queryKind => {
  if (queryKind === QueryKindEnum.AdHoc) {
    return 'Ad Hoc';
  }

  if (queryKind === QueryKindEnum.Indicator) {
    return 'Indicator Search';
  }
  if (queryKind === QueryKindEnum.IndicatorColumns) {
    return 'Indicator Search Columns';
  }
  if (queryKind === QueryKindEnum.IndicatorDetail) {
    return 'Indicator Search Details';
  }
  if (queryKind === QueryKindEnum.IndicatorTimeline) {
    return 'Indicator Search Timeline';
  }

  return queryKind
    .split(' ')
    .map(part => capitalize(part))
    .join(' ');
};

const QueryHistoryCard: React.FC<QueryHistoryCardProps> = ({ query }) => {
  const [showSQL, setShowSQL] = React.useState(false);
  const { showModal } = useModal();

  return (
    <Card as="section" variant="dark" p={4} data-testid="query-history-card">
      <Flex direction="column" justify="space-between" width={1}>
        <Flex as="header" align="flex-start" mb={2}>
          <Box as="h4" fontWeight="medium" mr="auto" maxWidth="60%" wordBreak="break-word">
            <Link
              as={RRLink}
              disabled={query.kind === QueryKindEnum.Compaction}
              aria-label="Link to Data Explorer query"
              to={`${urls.data.dataExplorer()}?queryId=${query.id}`}
            >
              {query.name || query.id}
            </Link>
          </Box>
          <RoleRestrictedAccess allowedPermissions={[Permission.UserRead]}>
            <CardMetaValue
              label="By"
              value={getActorDisplayName(query.issuedBy)}
              data-testid="query-history-card-issued-by"
            />
          </RoleRestrictedAccess>
          <RoleRestrictedAccess allowedPermissions={[Permission.DataAnalyticsRead]}>
            <Box my={-1} ml={2}>
              <QueryHistoryCardOptions query={query} />
            </Box>
          </RoleRestrictedAccess>
        </Flex>

        <Grid templateColumns="2fr 1fr 1fr 1fr 1fr" gap={5}>
          <CardValue
            label="SQL Expression"
            value={
              <Flex
                align="center"
                onClick={() => setShowSQL(!showSQL)}
                cursor="pointer"
                overflow="hidden"
              >
                <Text truncated>{query.sql}</Text>
                <Box whiteSpace="nowrap" ml={3}>
                  <IconButton
                    icon="code"
                    size="small"
                    variantColor="navyblue-300"
                    variantBorderStyle="circle"
                    aria-label="Expand code"
                  />
                </Box>
              </Flex>
            }
          />
          <CardValue
            label="Type"
            value={stringifyQueryKind(query.kind)}
            data-testid="query-history-card-type"
          />
          <CardValue
            label="Started"
            value={query.startedAt ? formatDatetime(query.startedAt) : '-'}
          />
          <CardValue
            label="Completed"
            value={query.completedAt ? formatDatetime(query.completedAt) : '-'}
          />

          <Flex align="flex-end">
            <Box ml="auto">
              <QueryStatusBadge status={query.status} />
            </Box>
          </Flex>
        </Grid>
        {showSQL && (
          <Box mt={2}>
            <Editor
              data-testid="query-history-card-editor"
              readOnly
              minHeight={37}
              language="sql"
              value={query.sql}
            />
          </Box>
        )}
        {query.status === DataLakeQueryStatus.Failed && (
          <Flex mt={3} align="center">
            <Icon type="alert-circle-filled" size="medium" color="red-300" mr={2} />
            <Text fontSize="small" fontStyle="italic" color="red-300" mr={1}>
              An error occured while running this query.
            </Text>
            <AbstractButton
              fontSize="small"
              fontStyle="italic"
              color="red-300"
              textDecoration="underline"
              onClick={() =>
                showModal(<SQLErrorMessage errorMessage={query.message} />, {
                  title: 'SQL Error',
                  showCloseButton: true,
                })
              }
            >
              See details
            </AbstractButton>
          </Flex>
        )}
        <QueryIndicators indicators={query.metadata} />
      </Flex>
    </Card>
  );
};

export default React.memo(QueryHistoryCard);
