/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import useAvailableLogTypesForLogPuller from 'Hooks/useAvailableLogTypesForLogPuller';
import { compose } from 'Helpers/compose';
import withSEO from 'Hoc/withSEO';
import Page403 from 'Pages/403';
import withRoleRestrictedAccess from 'Hoc/withRoleRestrictedAccess';
import { AsanaLogsSourceWizardValues } from 'Pages/Integrations/LogSources/Asana/AsanaWizard/AsanaWizard';
import { Permission } from 'Generated/schema';
import { useAddAsanaLogSource } from '../graphql/addAsanaLogSource.generated';
import AsanaWizard from '../AsanaWizard';

const CreateAsanaLogSource = () => {
  const { defaultLogTypes } = useAvailableLogTypesForLogPuller();
  const initialValues = React.useMemo(
    () => ({
      integrationLabel: '',
      logTypes: defaultLogTypes,
      organizationId: '',
      serviceAccountToken: '',
    }),
    [defaultLogTypes]
  );

  const [addAsanaLogSource] = useAddAsanaLogSource({
    update: (cache, { data }) => {
      cache.modify({
        fields: {
          logSources: (queryData, { toReference }) => {
            const addedIntegrationCacheRef = toReference(data.addLogPullingIntegration);
            return queryData
              ? [addedIntegrationCacheRef, ...queryData]
              : [addedIntegrationCacheRef];
          },
        },
      });
    },
  });

  const handleSubmit = React.useCallback(
    async (values: AsanaLogsSourceWizardValues) => {
      try {
        const { data } = await addAsanaLogSource({
          variables: {
            input: {
              integrationLabel: values.integrationLabel,
              pullerConfig: {
                logTypes: values.logTypes,
                asana: {
                  organizationId: values.organizationId,
                  serviceAccountToken: values.serviceAccountToken,
                },
              },
            },
          },
        });
        return data.addLogPullingIntegration;
      } catch (error) {
        return error as Error;
      }
    },
    [addAsanaLogSource]
  );

  return <AsanaWizard initialValues={initialValues} onSubmit={handleSubmit} />;
};

export default compose(
  withSEO({ title: 'New Asana Source' }),
  withRoleRestrictedAccess({
    allowedPermissions: [Permission.LogSourceModify],
    fallback: <Page403 />,
  })
)(CreateAsanaLogSource);
