/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';

// Prevent text highlighting when using shift+click to select multiple list times.
const useOverrideShiftClickSelection = () => {
  React.useEffect(() => {
    const overrideShiftClickSelection = e => {
      document.onselectstart = () => !(e.key === 'Shift' && e.shiftKey);
    };

    window.addEventListener('keydown', overrideShiftClickSelection);
    window.addEventListener('keyup', overrideShiftClickSelection);

    return () => {
      window.removeEventListener('keydown', overrideShiftClickSelection);
      window.removeEventListener('keyup', overrideShiftClickSelection);
    };
  }, []);
};

export default useOverrideShiftClickSelection;
