/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { FastField } from 'formik';
import { SimpleGrid } from 'pouncejs';
import FormikNumberInput from 'Components/fields/NumberInput';

const ScheduledQueryFormFields: React.FC = () => {
  return (
    <SimpleGrid columns={3} spacing={2}>
      <FastField as={FormikNumberInput} label="Period (days)" min={0} name="periodDays" />
      <FastField as={FormikNumberInput} label="Period (min)" min={0} name="periodMinutes" />
      <FastField as={FormikNumberInput} label="Timeout (min)" min={1} name="timeoutMinutes" />
    </SimpleGrid>
  );
};

export default ScheduledQueryFormFields;
