/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Button, ButtonProps } from 'pouncejs';
import { useFormikContext } from 'formik';

export interface SubmitButtonProps extends Omit<ButtonProps, 'size' | 'disabled'> {
  allowPristineSubmission?: boolean;
  allowInvalidSubmission?: boolean;
}

const SubmitButton: React.FC<SubmitButtonProps> = ({
  allowPristineSubmission,
  allowInvalidSubmission,
  ...rest
}) => {
  const { isSubmitting, isValid, dirty, submitForm } = useFormikContext<any>();
  return (
    <Button
      type="submit"
      onClick={e => {
        // We force a submission instead of relying in native HTML (from `type="submit"`), in order
        // to cover cases of a remote form submission
        e.preventDefault();
        submitForm();
      }}
      loading={isSubmitting}
      disabled={
        isSubmitting ||
        (!isValid && !allowInvalidSubmission) ||
        (!dirty && !allowPristineSubmission)
      }
      {...rest}
    />
  );
};

export default React.memo(SubmitButton);
