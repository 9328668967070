/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { logError } from 'Helpers/errors';
import { Card, Text, Flex } from 'pouncejs';

interface ErrorBoundaryProps {
  fallbackStrategy?: 'passthrough' | 'invisible' | 'placeholder';
  fallbackComponent?: React.ReactNode;
}

interface ErrorBoundaryState {
  hasError: boolean;
}

const DefaultFallbackComponent: React.FC = () => (
  <Card bg="pink-700" width="100%" height="100%">
    <Flex align="center" justify="center" width="100%" height="100%">
      <Text py={5} px={4}>
        Something went wrong and we could not correctly display this content
      </Text>
    </Flex>
  </Card>
);

class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  static defaultProps: Partial<ErrorBoundaryProps>;

  state = { hasError: false };

  componentDidCatch(error: Error, { componentStack }: React.ErrorInfo) {
    // Display fallback UI
    this.setState({ hasError: true });

    // send this error to our logging service.
    logError(error, { extras: { react: componentStack } });
  }

  render() {
    const { fallbackStrategy, fallbackComponent, children } = this.props;

    // if no error occurs -> render as normal
    if (!this.state.hasError) {
      return children;
    }

    // else decide what to show based on the selected strategy
    switch (fallbackStrategy) {
      case 'invisible':
        return null;
      case 'placeholder':
        return fallbackComponent;
      case 'passthrough':
      default:
        return children;
    }
  }
}

ErrorBoundary.defaultProps = {
  fallbackStrategy: 'placeholder',
  fallbackComponent: <DefaultFallbackComponent />,
};

export default ErrorBoundary;
