/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Box, Flex, Button, Text } from 'pouncejs';

interface ErrorUploaderProps {
  errorMsg: string;
  onRestartUploading: () => void;
}

const ErrorUploader: React.FC<ErrorUploaderProps> = ({ errorMsg, onRestartUploading }) => {
  return (
    <>
      <Flex justify="center">
        <Box backgroundColor="pink-700" textAlign="center" p={6} minWidth={600}>
          <Text size="medium" fontWeight="bold">
            Could not upload your file
          </Text>
          {errorMsg && (
            <Text fontSize="small" mt={1} fontStyle="italic">
              {errorMsg}
            </Text>
          )}
        </Box>
      </Flex>
      <Flex justify="center" mt={8} mb={4}>
        <Button onClick={onRestartUploading}>Try Again</Button>
      </Flex>
    </>
  );
};

export default React.memo(ErrorUploader);
