/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Flex, Link, Box, SimpleGrid, Card } from 'pouncejs';
import { DataSchemaTeaser } from 'Source/graphql/fragments/DataSchemaTeaser.generated';
import FlatBadge from 'Components/badges/FlatBadge';
import StatusBadge from 'Components/badges/StatusBadge';
import { Link as RRLink } from 'react-router-dom';
import urls from 'Source/urls';
import DataSchemaCardOptions from './DataSchemaCardOptions';

interface DataSchemaCardProps {
  dataSchema: DataSchemaTeaser;
}

const DataSchemaCard: React.FC<DataSchemaCardProps> = ({ dataSchema }) => {
  return (
    <Card as="section" variant="dark" p={4}>
      <Flex direction="column" justify="space-between" width={1}>
        <Flex as="header" align="flex-start" mb={2}>
          <Box as="h4" fontWeight="medium" mr="auto" wordBreak="break-word">
            <Link
              data-testid="schema-card-name"
              as={RRLink}
              to={urls.data.schemas.details(dataSchema.name)}
              data-tid="link-to-data-schema"
            >
              {dataSchema.name}
            </Link>
            <Flex spacing={1} mt={2}>
              {dataSchema.managed ? (
                <FlatBadge backgroundColor="navyblue-700" color="blue-300">
                  Panther Managed
                </FlatBadge>
              ) : (
                <FlatBadge backgroundColor="navyblue-700" color="navyblue-100">
                  User Defined
                </FlatBadge>
              )}
              {dataSchema.release && (
                <FlatBadge backgroundColor="navyblue-700" color="gray-200">
                  {dataSchema.release}
                </FlatBadge>
              )}
            </Flex>
          </Box>
          <DataSchemaCardOptions dataSchema={dataSchema} />
        </Flex>
        <SimpleGrid gap={2} columns={2}>
          <Box>{dataSchema.description}</Box>
          <Flex align="flex-end">
            <Flex spacing={2} align="center" width="100%" justify="flex-end">
              <StatusBadge status="ENABLED" disabled={dataSchema.disabled} />
            </Flex>
          </Flex>
        </SimpleGrid>
      </Flex>
    </Card>
  );
};
export default React.memo(DataSchemaCard);
