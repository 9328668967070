/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Box, Flex, Icon, Text } from 'pouncejs';
import { FastField, useFormikContext } from 'formik';
import slackMinimalLogo from 'Assets/slack-minimal-logo.svg';
import FormikTextInput from 'Components/fields/TextInput';
import FormikMultiCombobox from 'Components/fields/MultiComboBox';
import { SlackLogTypesEnterprise, SlackLogTypesStandard, SlackPlansEnum } from 'Source/constants';
import { WizardPanel } from 'Components/Wizard';
import FormikCombobox from 'Components/fields/ComboBox';
import EditIntegrationActions from 'Pages/Integrations/components/EditIntegrationActions';
import { SlackLogsSourceWizardValues } from '../SlackLogSourceWizard';

const slackPlans = Object.values(SlackPlansEnum);

const SlackLogSourceConfigurationPanel: React.FC = () => {
  const { initialValues, errors, values, setFieldValue } = useFormikContext<
    SlackLogsSourceWizardValues
  >();

  React.useEffect(() => {
    if (values.plan === SlackPlansEnum.enterprise) {
      setFieldValue('logTypes', SlackLogTypesEnterprise);
    } else {
      setFieldValue('logTypes', SlackLogTypesStandard);
    }
  }, [values.plan, setFieldValue]);
  const editMode = !!initialValues.integrationId;
  return (
    <WizardPanel>
      <Box width={500} m="auto">
        <WizardPanel.Heading
          title={editMode ? 'Update your Slack source' : "Let's start with the basics"}
          subtitle="Give an appropriate name for this source"
          logo={slackMinimalLogo}
        />
        <Flex direction="column" spacing={4}>
          <FastField
            name="integrationLabel"
            as={FormikTextInput}
            label="Name *"
            placeholder="A nickname for your Slack Log source"
            required
          />
          <FastField
            as={FormikCombobox}
            placeholder="Select your Slack Plan"
            label="Select your Slack Plan *"
            name="plan"
            items={slackPlans}
            disabled={editMode}
            required
          />
          {values.plan === SlackPlansEnum.standard ? (
            <FastField
              as={FormikMultiCombobox}
              placeholder="The Slack Log Types, you want Panther to process"
              label="Select the Log types you want to monitor *"
              name="logTypes"
              items={SlackLogTypesStandard}
              required
            />
          ) : (
            <Flex
              as="section"
              align="center"
              spacing={1}
              mt={2}
              aria-label="Destination delivery failure"
              fontStyle="italic"
              fontSize="small"
            >
              <Icon type="alert-circle-filled" size="medium" />
              <Text color="gray-300" ml={1}>
                By selecting this plan, the Log Types that will be monitored are
              </Text>
              <Text>{values.logTypes.join(', ')}</Text>
            </Flex>
          )}
        </Flex>
      </Box>
      {editMode ? (
        <EditIntegrationActions />
      ) : (
        <WizardPanel.Actions>
          <WizardPanel.ActionNext
            disabled={!!errors.integrationLabel || !values.integrationLabel || !!errors.logTypes}
          >
            Continue Setup
          </WizardPanel.ActionNext>
        </WizardPanel.Actions>
      )}
    </WizardPanel>
  );
};

export default SlackLogSourceConfigurationPanel;
