/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { useSnackbar } from 'pouncejs';
import { ListUsersDocument } from 'Source/graphql/queries/listUsers.generated';
import { getOperationName } from 'apollo-utilities';
import { getActorDisplayName } from 'Helpers/utils';
import ConfirmModal from 'Components/modals/ConfirmModal';
import { UserDetails } from 'Source/graphql/fragments/UserDetails.generated';
import useModal from 'Hooks/useModal';
import { useResetUserPassword } from './graphql/resetUserPassword.generated';

export interface ResetUserPasswordProps {
  user: UserDetails;
}

const ResetUserPasswordModal: React.FC<ResetUserPasswordProps> = ({ user }) => {
  const { pushSnackbar } = useSnackbar();
  const { hideModal } = useModal();
  const userDisplayName = getActorDisplayName(user);
  const [resetUserPassword] = useResetUserPassword({
    variables: {
      id: user.id,
    },
    awaitRefetchQueries: true,
    refetchQueries: [getOperationName(ListUsersDocument)],
    onCompleted: () => {
      hideModal();
      pushSnackbar({
        variant: 'success',
        title: `Successfully forced a password change for ${userDisplayName}`,
      });
    },
    onError: () => {
      hideModal();
      pushSnackbar({ variant: 'error', title: `Failed to reset password for ${userDisplayName}` });
    },
  });

  return (
    <ConfirmModal
      variant="destructive"
      onConfirm={resetUserPassword}
      onClose={hideModal}
      subtitle={`Are you sure you want to reset password for ${userDisplayName}?`}
    />
  );
};

export default ResetUserPasswordModal;
