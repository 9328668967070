/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import * as Types from '../../../../../__generated__/schema';

import { GraphQLError } from 'graphql';
import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';

export type DeleteAPITokenVariables = {
  id: Types.Scalars['ID'];
};

export type DeleteAPIToken = {
  deleteAPIToken: { error?: Types.Maybe<Pick<Types.Error, 'code' | 'message'>> };
};

export const DeleteAPITokenDocument = gql`
  mutation DeleteAPIToken($id: ID!) {
    deleteAPIToken(id: $id) {
      error {
        code
        message
      }
    }
  }
`;
export type DeleteAPITokenMutationFn = ApolloReactCommon.MutationFunction<
  DeleteAPIToken,
  DeleteAPITokenVariables
>;

/**
 * __useDeleteAPIToken__
 *
 * To run a mutation, you first call `useDeleteAPIToken` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAPIToken` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteApiToken, { data, loading, error }] = useDeleteAPIToken({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteAPIToken(
  baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteAPIToken, DeleteAPITokenVariables>
) {
  return ApolloReactHooks.useMutation<DeleteAPIToken, DeleteAPITokenVariables>(
    DeleteAPITokenDocument,
    baseOptions
  );
}
export type DeleteAPITokenHookResult = ReturnType<typeof useDeleteAPIToken>;
export type DeleteAPITokenMutationResult = ApolloReactCommon.MutationResult<DeleteAPIToken>;
export type DeleteAPITokenMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeleteAPIToken,
  DeleteAPITokenVariables
>;
export function mockDeleteAPIToken({
  data,
  variables,
  errors,
}: {
  data: DeleteAPIToken;
  variables?: DeleteAPITokenVariables;
  errors?: GraphQLError[];
}) {
  return {
    request: { query: DeleteAPITokenDocument, variables },
    result: { data, errors },
  };
}
