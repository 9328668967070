/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import TablePlaceholder from 'Components/TablePlaceholder';
import { Box, Card, FadeIn } from 'pouncejs';

const PackDetailsPageSkeleton: React.FC = () => {
  return (
    <FadeIn from="bottom">
      <TablePlaceholder rowCount={1} rowHeight={15} />
      <Card p={6}>
        <TablePlaceholder rowCount={2} rowHeight={10} />
      </Card>
      <Box mt={5}>
        <Card p={6}>
          <TablePlaceholder rowCount={2} rowHeight={10} />
        </Card>
      </Box>
    </FadeIn>
  );
};

export default PackDetailsPageSkeleton;
