/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import * as Types from '../../../__generated__/schema';

import { GraphQLError } from 'graphql';
import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';

export type ListLogSourcesMinimalVariables = {};

export type ListLogSourcesMinimal = {
  logSources: Array<
    Types.Maybe<
      | Pick<Types.S3LogIntegration, 'integrationId' | 'integrationLabel'>
      | Pick<Types.CloudWatchLogIntegration, 'integrationId' | 'integrationLabel'>
      | Pick<Types.EventBridgeIntegration, 'integrationId' | 'integrationLabel'>
      | Pick<Types.SqsLogSourceIntegration, 'integrationId' | 'integrationLabel'>
      | Pick<Types.GcsLogSourceIntegration, 'integrationId' | 'integrationLabel'>
      | Pick<Types.LogPullingIntegration, 'integrationId' | 'integrationLabel'>
    >
  >;
};

export const ListLogSourcesMinimalDocument = gql`
  query ListLogSourcesMinimal {
    logSources {
      integrationId
      integrationLabel
    }
  }
`;

/**
 * __useListLogSourcesMinimal__
 *
 * To run a query within a React component, call `useListLogSourcesMinimal` and pass it any options that fit your needs.
 * When your component renders, `useListLogSourcesMinimal` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListLogSourcesMinimal({
 *   variables: {
 *   },
 * });
 */
export function useListLogSourcesMinimal(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    ListLogSourcesMinimal,
    ListLogSourcesMinimalVariables
  >
) {
  return ApolloReactHooks.useQuery<ListLogSourcesMinimal, ListLogSourcesMinimalVariables>(
    ListLogSourcesMinimalDocument,
    baseOptions
  );
}
export function useListLogSourcesMinimalLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    ListLogSourcesMinimal,
    ListLogSourcesMinimalVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<ListLogSourcesMinimal, ListLogSourcesMinimalVariables>(
    ListLogSourcesMinimalDocument,
    baseOptions
  );
}
export type ListLogSourcesMinimalHookResult = ReturnType<typeof useListLogSourcesMinimal>;
export type ListLogSourcesMinimalLazyQueryHookResult = ReturnType<
  typeof useListLogSourcesMinimalLazyQuery
>;
export type ListLogSourcesMinimalQueryResult = ApolloReactCommon.QueryResult<
  ListLogSourcesMinimal,
  ListLogSourcesMinimalVariables
>;
export function mockListLogSourcesMinimal({
  data,
  variables,
  errors,
}: {
  data: ListLogSourcesMinimal;
  variables?: ListLogSourcesMinimalVariables;
  errors?: GraphQLError[];
}) {
  return {
    request: { query: ListLogSourcesMinimalDocument, variables },
    result: { data, errors },
  };
}
