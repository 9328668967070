/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { CrowdstrikeLogIntegrationDetails } from 'Source/graphql/fragments/CrowdstrikeLogIntegrationDetails.generated';
import { Wizard } from 'Components/Wizard';
import { yupIntegrationLabelUniqueness } from 'Helpers/utils';
import useFindSourcesPerMethod from 'Hooks/useFindSourcesPerMethod';
import { LogTransportMethodsEnum } from 'Source/constants';
import CancelIntegrationAction from 'Pages/Integrations/components/CancelIntegrationAction';
import ValidationPanel from './ValidationPanel';
import CrowdstrikeLogsSourceConfigurationPanel from './CrowstrikeLogsSourceConfigurationPanel';

interface CrowdstrikeLogsWizardProps {
  initialValues: CrowdstrikeLogsSourceWizardValues;
  onSubmit: (
    values: CrowdstrikeLogsSourceWizardValues
  ) => Promise<CrowdstrikeLogIntegrationDetails | Error>;
}

export interface CrowdstrikeLogsSourceWizardValues {
  integrationId?: string;
  integrationLabel: string;
  awsAccessKey: string;
  awsAccessSecret: string;
  queueUrl: string;
}

const CrowdstrikeLogsSourceWizard: React.FC<CrowdstrikeLogsWizardProps> = ({
  initialValues,
  onSubmit,
}) => {
  const existingPullerSources = useFindSourcesPerMethod(LogTransportMethodsEnum.logPulling);

  const validationSchema: Yup.SchemaOf<CrowdstrikeLogsSourceWizardValues> = React.useMemo(
    () =>
      Yup.object().shape({
        integrationId: Yup.string(),
        integrationLabel: yupIntegrationLabelUniqueness({
          existingLabel: initialValues.integrationLabel,
          existingSources: existingPullerSources,
        }),
        queueUrl: Yup.string().url('Must be a valid url').required(),
        awsAccessKey: initialValues.integrationId ? Yup.string() : Yup.string().required(),
        awsAccessSecret: initialValues.integrationId ? Yup.string() : Yup.string().required(),
      }),
    [initialValues.integrationId, initialValues.integrationLabel, existingPullerSources]
  );

  const editMode = !!initialValues.integrationId;

  return (
    <Formik<CrowdstrikeLogsSourceWizardValues>
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      <Form>
        <Wizard headerTitle="Connecting a new Log Source" enableAllNavigationSteps={editMode}>
          <Wizard.Step
            title="Configure Source"
            description="Provide information and set preferences to connect the log source to Panther"
          >
            <CrowdstrikeLogsSourceConfigurationPanel />
          </Wizard.Step>
          <Wizard.Step
            title="Verify Setup"
            description="Confirm that your log source has been successfully onboarded or troubleshoot any issues"
            navigationDisabled={editMode}
          >
            <ValidationPanel />
          </Wizard.Step>
        </Wizard>
        {!editMode && <CancelIntegrationAction />}
      </Form>
    </Formik>
  );
};

export default CrowdstrikeLogsSourceWizard;
