/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Box, Button, Combobox, Flex, Text } from 'pouncejs';
import { useSelect } from 'Components/utils/SelectContext';
import useModal from 'Hooks/useModal';
import { toPlural } from 'Helpers/utils';
import { DataModel, Permission } from 'Generated/schema';
import DeleteDataModelModal from 'Components/modals/DeleteDataModelModal';
import useBulkDownload from 'Hooks/useBulkDownload';
import useCheckPermissions from 'Hooks/useCheckPermissions';

enum MassActionsEnum {
  'DOWNLOAD' = 'Download',
  'DELETE' = 'Delete',
}

const massActionValues = Object.values(MassActionsEnum);

const ListDataModelSelection: React.FC = () => {
  const { selection, resetSelection } = useSelect<DataModel>();
  const [selectedMassAction, setSelectedMassAction] = React.useState<MassActionsEnum>(
    MassActionsEnum.DOWNLOAD
  );
  const { showModal } = useModal();
  const dataModelToString = toPlural('Data Model', selection.length);

  const canDelete = useCheckPermissions(Permission.LogSourceModify);

  const { download } = useBulkDownload({
    fileName: 'data-models',
  });

  const disableActions = React.useCallback(
    actionName => actionName === MassActionsEnum.DELETE && !canDelete,
    [canDelete]
  );

  const handleActionApplication = React.useCallback(() => {
    if (selectedMassAction === MassActionsEnum.DELETE) {
      showModal(<DeleteDataModelModal dataModels={selection} onSuccess={resetSelection} />, {
        title: `Delete ${dataModelToString}`,
      });
    }
    if (selectedMassAction === MassActionsEnum.DOWNLOAD) {
      download({ dataModelIds: selection.map(s => s.id) });
    }
    // FIXME: Look into missing effect dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedMassAction, selection, resetSelection, download]);

  return (
    <Flex justify="flex-end" align="center" spacing={4}>
      <Text>{selection.length} Selected</Text>
      <Box width={150}>
        <Combobox
          data-tid="mass-data-models-action-combobox"
          disableItem={disableActions}
          onChange={setSelectedMassAction}
          items={massActionValues}
          value={selectedMassAction}
          label="Mass Action"
          showClearSelectionControl={false}
        />
      </Box>
      <Button
        data-tid="mass-data-models-action-apply"
        variantColor="violet-400"
        onClick={handleActionApplication}
      >
        Apply
      </Button>
    </Flex>
  );
};

export default React.memo(ListDataModelSelection);
