/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Field } from 'formik';
import { Box, FormHelperText, Link } from 'pouncejs';
import FormikSwitch from 'Components/fields/Switch';

interface LicenseAgreementSectionProps {
  isDisabled: boolean;
}

const LicenseAgreementSection: React.FC<LicenseAgreementSectionProps> = ({ isDisabled }) => {
  return (
    <Box as="fieldset">
      <Field
        as={FormikSwitch}
        checkedText="YES"
        uncheckedText="NO"
        name="licenseAccepted"
        id="licenseAccepted"
        label="Accept our License Agreement"
        disabled={isDisabled}
        aria-describedby="licenseAccepted-helper"
      />
      <FormHelperText id="licenseAccepted-helper" mt={2}>
        You consent to the terms set forth by Panther{"'"}s <br />
        <Link external variant="neutral" href="https://runpanther.io/esa">
          Enterprise License Agreement
        </Link>{' '}
        (or the terms set forth in the Enterprise License Agreement signed by your company, which
        take precedence)
      </FormHelperText>
    </Box>
  );
};

export default LicenseAgreementSection;
