/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import { useFormikContext } from 'formik';
import { ReplayState } from 'Generated/schema';
import { Alert, Box, Card, Flex, Heading, IconButton, useSnackbar } from 'pouncejs';
import React from 'react';
import { ReplayFull } from 'Source/graphql/fragments/ReplayFull.generated';
import { useCreateReplay } from 'Source/graphql/queries/createReplay.generated';
import { useGetReplay } from 'Source/graphql/queries/getReplay.generated';
import { useGetReplaysByDetectionId } from 'Source/graphql/queries/getReplaysByDetectionId.generated';
import { useStopReplay } from 'Source/graphql/queries/stopReplay.generated';
import { extractErrorMessage } from 'Helpers/utils';
import { BetaTag } from 'Components/BetaTag/BetaTag';
import { RuleFormValues } from 'Components/forms/RuleForm';
import ReplayCompleteView from './ReplayComplete/ReplayComplete';
import ReplayEditor from './ReplayEditor';
import ReplayIncompleteView from './ReplayStatus';

type ReplayViewProps = {
  detectionId: string;
  detectionLogTypes: string[];
};

const ReplayForm: React.FC<ReplayViewProps> = ({ detectionId, detectionLogTypes }) => {
  const { dirty: parentFormDirty } = useFormikContext<RuleFormValues>();
  const { pushSnackbar } = useSnackbar();

  const {
    data: replayData,
    loading: isLoadingDetectionReplays,
    refetch,
  } = useGetReplaysByDetectionId({
    variables: { detectionId },
    onError: error =>
      pushSnackbar({
        variant: 'error',
        title: `Failed to fetch replays`,
        description: extractErrorMessage(error),
      }),
  });

  // replay create handler
  const [
    createReplay,
    { data: createData, error: createError, loading: isCreating },
  ] = useCreateReplay({
    onError: error => {
      refetch();
      pushSnackbar({
        variant: 'error',
        title: `Failed to start replay`,
        description: extractErrorMessage(error),
      });
    },
  });

  const handleCreate = React.useCallback(
    async (startsAt, endsAt, logTypes) =>
      createReplay({
        variables: { input: { startsAt, endsAt, logTypes, detectionId } },
      }),
    [createReplay, detectionId]
  );

  // replay stop handler
  const [stopReplay, { error: stopError, loading: isStopping }] = useStopReplay();
  const handleStop = React.useCallback(
    async id => {
      await stopReplay({ variables: { input: { id } } });
    },
    [stopReplay]
  );

  const latestReplay = React.useMemo<ReplayFull | null>(() => {
    if (createData?.createReplay) {
      return createData.createReplay.replay;
    }
    if (replayData?.replays?.length > 0) {
      // get first replay in results (API returns sorted by createdAt desc)
      return replayData.replays[0];
    }
    return null;
  }, [createData, replayData]);

  const disableEditor = isStopping || isCreating || isLoadingDetectionReplays;

  return (
    <Box>
      {createError && (
        <Box mb={3}>
          <Alert discardable variant="error" title="Error creating replay" />
        </Box>
      )}
      {stopError && (
        <Box mb={3}>
          <Alert discardable variant="error" title="Error stopping replay" />
        </Box>
      )}
      {isCreating && <ReplayFormWithCreatingReplay logTypes={detectionLogTypes} />}
      {!isCreating && latestReplay && (
        <ReplayFormWithExistingReplay
          replay={latestReplay}
          onStop={handleStop}
          onCreate={handleCreate}
          logTypes={detectionLogTypes}
          disableEditor={disableEditor}
          dirtyDetection={parentFormDirty}
        />
      )}
      {!isCreating && !latestReplay && (
        <ReplayFormEmpty
          onCreate={handleCreate}
          logTypes={detectionLogTypes}
          disableEditor={disableEditor}
          dirtyDetection={parentFormDirty}
          detectionLogTypes={detectionLogTypes}
        />
      )}
    </Box>
  );
};

export default React.memo(ReplayForm);

interface ReplayFormEmptyProps {
  detectionLogTypes: string[];
  onCreate: (startAt: string, endAt: string, logTypes: string[]) => void;
  logTypes: string[];
  disableEditor: boolean;
  dirtyDetection: boolean;
}

const ReplayFormEmpty: React.FC<ReplayFormEmptyProps> = ({
  onCreate,
  logTypes,
  disableEditor,
  dirtyDetection,
  detectionLogTypes,
}) => {
  const [open, setOpen] = React.useState<boolean>(true);

  return (
    <Box>
      <Card variant="dark" p={4} mb={4}>
        <Flex align="center" spacing={4}>
          <IconButton
            variant="ghost"
            variantBorderStyle="circle"
            active={open}
            variantColor="navyblue-700"
            icon={open ? 'caret-up' : 'caret-down'}
            onClick={() => setOpen(!open)}
            size="medium"
            aria-label="Toggle Replay Visibility"
          />
          <DataReplayHeader />
        </Flex>
        {open && (
          <>
            <ReplayEditor
              disabled={disableEditor}
              onCreate={onCreate}
              logTypes={logTypes}
              initialLogTypes={detectionLogTypes}
            />
            {dirtyDetection && <DirtyDetectionWarning />}
          </>
        )}
      </Card>
    </Box>
  );
};

interface ReplayFormWithCreatingReplayProps {
  logTypes: string[];
}

const ReplayFormWithCreatingReplay: React.FC<ReplayFormWithCreatingReplayProps> = ({
  logTypes,
}) => {
  const [open, setOpen] = React.useState<boolean>(true);

  return (
    <Box>
      <Card variant="dark" p={4} mb={4}>
        <Flex align="center" spacing={4}>
          <IconButton
            variant="ghost"
            variantBorderStyle="circle"
            active={open}
            variantColor="navyblue-700"
            icon={open ? 'caret-up' : 'caret-down'}
            onClick={() => setOpen(!open)}
            size="medium"
            aria-label="Toggle Replay Visibility"
          />
          <DataReplayHeader />
        </Flex>
        {open && (
          <>
            <ReplayEditor
              onStop={() => {}}
              disabled={true}
              onCreate={() => {}}
              logTypes={logTypes}
            />
          </>
        )}
      </Card>
      {open && (
        <>
          <ReplayIncompleteView replay={null} />
        </>
      )}
    </Box>
  );
};

const REPLAY_IN_PROGRESS_POLL_INTERVAL = 1000;

interface ReplayFormWithExistingReplayProps {
  onStop: (id: string) => void;
  onCreate: (startAt: string, endAt: string, logTypes: string[]) => void;
  replay: ReplayFull;
  logTypes: string[];
  disableEditor: boolean;
  dirtyDetection: boolean;
}

const ReplayFormWithExistingReplay: React.FC<ReplayFormWithExistingReplayProps> = ({
  replay: originalReplay,
  onStop,
  onCreate,
  logTypes,
  disableEditor,
  dirtyDetection,
}) => {
  const [open, setOpen] = React.useState<boolean>(true);

  // get replay with polling for in progress states
  const {
    data: refreshedReplayData,
    startPolling,
    stopPolling,
    error: getReplayError,
  } = useGetReplay({
    variables: {
      id: originalReplay.id,
    },
  });

  React.useEffect(() => {
    // activate polling if replay is in progress
    if (
      refreshedReplayData?.replay.state === ReplayState.ComputationInProgress ||
      refreshedReplayData?.replay.state === ReplayState.EvaluationInProgress
    ) {
      startPolling(REPLAY_IN_PROGRESS_POLL_INTERVAL);
      return;
    }
    // otherwise, don't poll
    stopPolling();
  }, [refreshedReplayData, startPolling, stopPolling]);

  // set replay to the refreshed version, falling back to the original
  const replay = refreshedReplayData?.replay ?? originalReplay;

  return (
    <Box>
      {getReplayError && (
        <Box mb={3}>
          <Alert variant="error" title="Error fetching replay data" />
        </Box>
      )}
      <Card variant="dark" p={4} mb={4}>
        <Flex align="center" spacing={4}>
          <IconButton
            variant="ghost"
            variantBorderStyle="circle"
            active={open}
            variantColor="navyblue-700"
            icon={open ? 'caret-up' : 'caret-down'}
            onClick={() => setOpen(!open)}
            size="medium"
            aria-label="Toggle Replay Visibility"
          />
          <DataReplayHeader />
        </Flex>
        {open && (
          <>
            <ReplayEditor
              onStop={onStop}
              disabled={disableEditor || dirtyDetection}
              onCreate={onCreate}
              logTypes={logTypes}
              currentReplay={replay}
            />
            {dirtyDetection && <DirtyDetectionWarning />}
          </>
        )}
      </Card>
      {open && (
        <>
          <ReplayIncompleteView replay={replay} />
          {replay?.state === ReplayState.Done && <ReplayCompleteView replay={replay} />}
        </>
      )}
    </Box>
  );
};

const DirtyDetectionWarning: React.FC = () => {
  return (
    <Box pt={4}>
      <Alert
        variant="info"
        title="You can not start a replay test while there are pending changes to a detection. Please save your changes before running a replay."
      />
    </Box>
  );
};

const DataReplayHeader: React.FC = () => {
  return (
    <Flex alignItems="center">
      <Heading size="x-small">Data Replay</Heading>
      <Box ml={2}>
        <BetaTag variant="border" size="small" />
      </Box>
    </Flex>
  );
};
