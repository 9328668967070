/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import * as Types from '../../../../../__generated__/schema';

import { RuleSummary } from '../../../../graphql/fragments/RuleSummary.generated';
import { PolicySummary } from '../../../../graphql/fragments/PolicySummary.generated';
import { GraphQLError } from 'graphql';
import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';

export type GetMitreDetectionsVariables = {
  tacticId: Types.Scalars['ID'];
  techniqueId: Types.Scalars['ID'];
};

export type GetMitreDetections = {
  reportSetting: Pick<Types.ReportSetting, 'id'> & {
    detections: Array<RuleSummary | PolicySummary>;
  };
};

export const GetMitreDetectionsDocument = gql`
  query GetMitreDetections($tacticId: ID!, $techniqueId: ID!) {
    reportSetting(tacticId: $tacticId, techniqueId: $techniqueId) {
      id
      detections {
        ... on Rule {
          ...RuleSummary
        }
        ... on Policy {
          ...PolicySummary
        }
      }
    }
  }
  ${RuleSummary}
  ${PolicySummary}
`;

/**
 * __useGetMitreDetections__
 *
 * To run a query within a React component, call `useGetMitreDetections` and pass it any options that fit your needs.
 * When your component renders, `useGetMitreDetections` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMitreDetections({
 *   variables: {
 *      tacticId: // value for 'tacticId'
 *      techniqueId: // value for 'techniqueId'
 *   },
 * });
 */
export function useGetMitreDetections(
  baseOptions?: ApolloReactHooks.QueryHookOptions<GetMitreDetections, GetMitreDetectionsVariables>
) {
  return ApolloReactHooks.useQuery<GetMitreDetections, GetMitreDetectionsVariables>(
    GetMitreDetectionsDocument,
    baseOptions
  );
}
export function useGetMitreDetectionsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetMitreDetections,
    GetMitreDetectionsVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<GetMitreDetections, GetMitreDetectionsVariables>(
    GetMitreDetectionsDocument,
    baseOptions
  );
}
export type GetMitreDetectionsHookResult = ReturnType<typeof useGetMitreDetections>;
export type GetMitreDetectionsLazyQueryHookResult = ReturnType<
  typeof useGetMitreDetectionsLazyQuery
>;
export type GetMitreDetectionsQueryResult = ApolloReactCommon.QueryResult<
  GetMitreDetections,
  GetMitreDetectionsVariables
>;
export function mockGetMitreDetections({
  data,
  variables,
  errors,
}: {
  data: GetMitreDetections;
  variables?: GetMitreDetectionsVariables;
  errors?: GraphQLError[];
}) {
  return {
    request: { query: GetMitreDetectionsDocument, variables },
    result: { data, errors },
  };
}
