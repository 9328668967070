/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { FastField, Field, Form, Formik } from 'formik';
import { Box, Button, Card, Flex, Popover, PopoverContent, PopoverTrigger } from 'pouncejs';
import useRequestParamsWithoutPagination from 'Hooks/useRequestParamsWithoutPagination';
import isUndefined from 'lodash/isUndefined';
import PopoverAutoSubmit from 'Components/PopoverAutoSubmit';
import TextButton from 'Components/buttons/TextButton';
import FormikMultiCombobox from 'Components/fields/MultiComboBox';
import { useListAvailableLogTypes } from 'Source/graphql/queries';
import FormikCombobox from 'Components/fields/ComboBox';

export type ListLogSourceDropdownFilterValues = {
  logTypes?: string[];
  status?: 'Healthy' | 'Unhealthy';
};

const defaultValues: ListLogSourceDropdownFilterValues = {
  logTypes: [],
  status: null,
};

const statusFieldItems = ['Healthy', 'Unhealthy'];

const DropdownFilters: React.FC = () => {
  const { data } = useListAvailableLogTypes();
  const { requestParams, updateRequestParams } = useRequestParamsWithoutPagination<
    ListLogSourceDropdownFilterValues
  >();

  const initialDropdownFilters = React.useMemo(
    () =>
      ({
        ...defaultValues,
        ...requestParams,
      } as ListLogSourceDropdownFilterValues),
    [requestParams]
  );

  const filtersCount = Object.keys(defaultValues).filter(key => !isUndefined(requestParams[key]))
    .length;

  return (
    <Popover>
      {({ close: closePopover, isOpen }) => (
        <React.Fragment>
          <PopoverTrigger
            as={Button}
            iconAlignment="right"
            icon="filter-light"
            size="large"
            aria-label="Log Source Options"
          >
            Filters {filtersCount ? `(${filtersCount})` : ''}
          </PopoverTrigger>
          <PopoverContent>
            <Card
              shadow="dark300"
              my={14}
              p={6}
              pb={4}
              backgroundColor="navyblue-400"
              width={425}
              data-testid="dropdown-log-source-filters"
            >
              <Formik<ListLogSourceDropdownFilterValues>
                enableReinitialize
                onSubmit={(values: ListLogSourceDropdownFilterValues) => {
                  updateRequestParams(values);
                }}
                initialValues={initialDropdownFilters}
              >
                {({ setValues, values }) => (
                  <Form>
                    <PopoverAutoSubmit<ListLogSourceDropdownFilterValues>
                      isOpen={isOpen}
                      values={values}
                      onSubmit={updateRequestParams}
                    />
                    <Box pb={4}>
                      <Field
                        name="logTypes"
                        as={FormikMultiCombobox}
                        items={data?.listAvailableLogTypes?.logTypes ?? []}
                        label="Log Types"
                        placeholder="Select log types"
                      />
                    </Box>
                    <Box pb={4}>
                      <FastField
                        name="status"
                        as={FormikCombobox}
                        items={statusFieldItems}
                        label="Status"
                        placeholder="Select health status"
                      />
                    </Box>
                    <Flex direction="column" justify="center" align="center" spacing={4}>
                      <Box>
                        <Button onClick={closePopover}>Apply Filters</Button>
                      </Box>
                      <TextButton role="button" onClick={() => setValues(defaultValues)}>
                        Clear Filters
                      </TextButton>
                    </Flex>
                  </Form>
                )}
              </Formik>
            </Card>
          </PopoverContent>
        </React.Fragment>
      )}
    </Popover>
  );
};

export default React.memo(DropdownFilters);
