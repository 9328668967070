/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import * as Types from '../../../__generated__/schema';

import { AlertDetailsFull } from '../fragments/AlertDetailsFull.generated';
import { GraphQLError } from 'graphql';
import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';

export type GetReplayedAlertVariables = {
  alertId: Types.Scalars['ID'];
  replayId: Types.Scalars['ID'];
};

export type GetReplayedAlert = {
  replayedAlert: {
    origin: Pick<
      Types.AlertOriginReplayedRule,
      'ruleId' | 'logTypes' | 'replayId' | 'dedupString' | 'eventsMatched' | 'scheduledQueries'
    >;
  } & AlertDetailsFull;
};

export const GetReplayedAlertDocument = gql`
  query GetReplayedAlert($alertId: ID!, $replayId: ID!) {
    replayedAlert(alertId: $alertId, replayId: $replayId) {
      ...AlertDetailsFull
      origin {
        ... on AlertOriginReplayedRule {
          ruleId
          logTypes
          replayId
          dedupString
          eventsMatched
          scheduledQueries
        }
      }
    }
  }
  ${AlertDetailsFull}
`;

/**
 * __useGetReplayedAlert__
 *
 * To run a query within a React component, call `useGetReplayedAlert` and pass it any options that fit your needs.
 * When your component renders, `useGetReplayedAlert` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReplayedAlert({
 *   variables: {
 *      alertId: // value for 'alertId'
 *      replayId: // value for 'replayId'
 *   },
 * });
 */
export function useGetReplayedAlert(
  baseOptions?: ApolloReactHooks.QueryHookOptions<GetReplayedAlert, GetReplayedAlertVariables>
) {
  return ApolloReactHooks.useQuery<GetReplayedAlert, GetReplayedAlertVariables>(
    GetReplayedAlertDocument,
    baseOptions
  );
}
export function useGetReplayedAlertLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetReplayedAlert, GetReplayedAlertVariables>
) {
  return ApolloReactHooks.useLazyQuery<GetReplayedAlert, GetReplayedAlertVariables>(
    GetReplayedAlertDocument,
    baseOptions
  );
}
export type GetReplayedAlertHookResult = ReturnType<typeof useGetReplayedAlert>;
export type GetReplayedAlertLazyQueryHookResult = ReturnType<typeof useGetReplayedAlertLazyQuery>;
export type GetReplayedAlertQueryResult = ApolloReactCommon.QueryResult<
  GetReplayedAlert,
  GetReplayedAlertVariables
>;
export function mockGetReplayedAlert({
  data,
  variables,
  errors,
}: {
  data: GetReplayedAlert;
  variables?: GetReplayedAlertVariables;
  errors?: GraphQLError[];
}) {
  return {
    request: { query: GetReplayedAlertDocument, variables },
    result: { data, errors },
  };
}
