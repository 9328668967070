/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { useSnackbar } from 'pouncejs';
import useRouter from 'Hooks/useRouter';
import { extractErrorMessage } from 'Helpers/utils';
import useAvailableLogTypesForProvider from 'Hooks/useAvailableLogTypesForProvider';
import Page404 from 'Pages/404';
import Page403 from 'Pages/403';
import withSEO from 'Hoc/withSEO';
import { Permission } from 'Generated/schema';
import { compose } from 'Helpers/compose';
import withRoleRestrictedAccess from 'Hoc/withRoleRestrictedAccess';
import { useGetGcsLogIntegration } from '../graphql/getGcsLogIntegration.generated';
import GcsLogSourceWizard from '../GcsLogSourceWizard';
import { useUpdateGcsLogIntegration } from '../graphql/updateGcsLogIntegration.generated';
import { GcsLogSourceWizardValues } from '../GcsLogSourceWizard/GcsLogSourceWizard';

function EditGcsLogSource() {
  const { pushSnackbar } = useSnackbar();
  const { match } = useRouter<{ id: string }>();
  const { data, error: getError } = useGetGcsLogIntegration({
    variables: { id: match.params.id },
    onError: error => {
      pushSnackbar({
        title: extractErrorMessage(error) || 'An unknown error occurred',
        variant: 'error',
      });
    },
  });
  // TODO: GCS check logProvider - always is empty
  const { availableLogTypes } = useAvailableLogTypesForProvider(
    data?.getGcsLogIntegration?.logProviderId
  );

  const [updateLogSource] = useUpdateGcsLogIntegration();

  const initialValues: GcsLogSourceWizardValues = React.useMemo(
    () => ({
      integrationId: match.params.id,
      integrationLabel: data?.getGcsLogIntegration?.integrationLabel ?? 'Loading...',
      projectId: data?.getGcsLogIntegration?.gcsConfig.projectId ?? 'Loading...',
      subscriptionId: data?.getGcsLogIntegration?.gcsConfig.subscriptionId ?? 'Loading...',
      gcsBucket: data?.getGcsLogIntegration?.gcsConfig.gcsBucket ?? 'Loading...',
      credentials: data?.getGcsLogIntegration?.gcsConfig.credentials ?? 'Loading...',
      userEmail: data?.getGcsLogIntegration?.gcsConfig.userEmail ?? 'Loading...',
      logTypes: data?.getGcsLogIntegration?.logTypes ?? [],
      jsonKeyfile: null,
    }),
    [data, match.params.id]
  );

  const handleSubmit = React.useCallback(
    async (values: GcsLogSourceWizardValues) => {
      try {
        const response = await updateLogSource({
          variables: {
            input: {
              integrationId: values.integrationId,
              integrationLabel: values.integrationLabel,
              gcsConfig: {
                projectId: values.projectId,
                subscriptionId: values.subscriptionId,
                gcsBucket: values.gcsBucket,
                credentials: values.credentials,
                userEmail: values.userEmail,
                logTypes: values.logTypes,
              },
            },
          },
        });

        return response.data.updateGcsLogIntegration;
      } catch (error) {
        return error;
      }
    },
    [updateLogSource]
  );

  // we optimistically assume that an error in "get" is a 404. We don't have any other info
  if (getError) {
    return <Page404 />;
  }

  return (
    <GcsLogSourceWizard
      initialValues={initialValues}
      availableLogTypes={availableLogTypes}
      onSubmit={handleSubmit}
    />
  );
}

export default compose(
  withSEO({ title: 'Edit Google Cloud Storage Log Source' }),
  withRoleRestrictedAccess({
    allowedPermissions: [Permission.LogSourceModify],
    fallback: <Page403 />,
  })
)(EditGcsLogSource);
