/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import * as Types from '../../../../../__generated__/schema';

import { RuleSummary } from '../../../../graphql/fragments/RuleSummary.generated';
import { GraphQLError } from 'graphql';
import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';

export type GetRuleSummaryVariables = {
  input: Types.GetRuleInput;
};

export type GetRuleSummary = { rule?: Types.Maybe<RuleSummary> };

export const GetRuleSummaryDocument = gql`
  query GetRuleSummary($input: GetRuleInput!) {
    rule(input: $input) {
      ...RuleSummary
    }
  }
  ${RuleSummary}
`;

/**
 * __useGetRuleSummary__
 *
 * To run a query within a React component, call `useGetRuleSummary` and pass it any options that fit your needs.
 * When your component renders, `useGetRuleSummary` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRuleSummary({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetRuleSummary(
  baseOptions?: ApolloReactHooks.QueryHookOptions<GetRuleSummary, GetRuleSummaryVariables>
) {
  return ApolloReactHooks.useQuery<GetRuleSummary, GetRuleSummaryVariables>(
    GetRuleSummaryDocument,
    baseOptions
  );
}
export function useGetRuleSummaryLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetRuleSummary, GetRuleSummaryVariables>
) {
  return ApolloReactHooks.useLazyQuery<GetRuleSummary, GetRuleSummaryVariables>(
    GetRuleSummaryDocument,
    baseOptions
  );
}
export type GetRuleSummaryHookResult = ReturnType<typeof useGetRuleSummary>;
export type GetRuleSummaryLazyQueryHookResult = ReturnType<typeof useGetRuleSummaryLazyQuery>;
export type GetRuleSummaryQueryResult = ApolloReactCommon.QueryResult<
  GetRuleSummary,
  GetRuleSummaryVariables
>;
export function mockGetRuleSummary({
  data,
  variables,
  errors,
}: {
  data: GetRuleSummary;
  variables?: GetRuleSummaryVariables;
  errors?: GraphQLError[];
}) {
  return {
    request: { query: GetRuleSummaryDocument, variables },
    result: { data, errors },
  };
}
