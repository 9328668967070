/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Alert, Box, Flex, Heading, TabList, TabPanel, TabPanels, Tabs } from 'pouncejs';
import useUrlParams from 'Hooks/useUrlParams';
import invert from 'lodash/invert';
import { ButtonTab } from 'Components/ButtonTab';
import SampleDataUnmatchedResults from 'Components/forms/DataSchemaForm/SampleDataSection/SampleDataUnmatchedResults';
import SampleDataMatchedResults from 'Components/forms/DataSchemaForm/SampleDataSection/SampleDataMatchedResults';
import { useSchemaGeneration } from 'Components/utils/SchemaGenerationContext';
import SampleDataHeaderActions from '../SampleDataHeaderActions';
import SampleDataRawResults from '../SampleDataRawResults';
import LoadingOverlay from '../../LoadingOverlay';

export interface SampleDataUrlParams {
  section?: 'raw' | 'unmatched' | 'matched';
}

const sectionToTabIndex: Record<SampleDataUrlParams['section'], number> = {
  raw: 0,
  unmatched: 1,
  matched: 2,
};

const tabIndexToSection = invert(sectionToTabIndex) as Record<
  number,
  SampleDataUrlParams['section']
>;

const SampleDataMain = () => {
  const { urlParams, updateUrlParams } = useUrlParams<SampleDataUrlParams>();
  const { results, error, testingSchema } = useSchemaGeneration();

  const { totalLogs, matched, unmatched } = results?.stats ?? {};

  return (
    <Box mb={6}>
      <Tabs
        index={sectionToTabIndex[urlParams.section] || 0}
        onChange={index => updateUrlParams({ section: tabIndexToSection[index] })}
      >
        <Flex justify="space-between" align="center" p={4}>
          {totalLogs ? (
            <Heading
              size="x-small"
              // TODO: Fix this when the be can return the correct raw event count.
              // CSV files contain an additional header metadata row in the raw events result
              color={matched + unmatched === matched ? 'green-400' : 'red-100'}
            >
              {matched}/{matched + unmatched} Logs Matched
            </Heading>
          ) : (
            <Heading size="x-small" color="navyblue-100">
              Nothing tested yet
            </Heading>
          )}
          <TabList>
            <ButtonTab>Raw {totalLogs && `(${totalLogs})`}</ButtonTab>
            <ButtonTab>Unmatched {unmatched && `(${unmatched})`}</ButtonTab>
            <ButtonTab>Matched {matched && `(${matched})`}</ButtonTab>
          </TabList>
          <SampleDataHeaderActions />
        </Flex>
        <Box>
          <LoadingOverlay
            loading={testingSchema}
            title="Testing your Sample Data against the schema"
          >
            <>
              {error && (
                <Box mb={4}>
                  <Alert variant="error" discardable {...error} />
                </Box>
              )}
              <TabPanels>
                <TabPanel data-testid="samplee-data-raw-results-tabpanel">
                  <SampleDataRawResults />
                </TabPanel>
                <TabPanel lazy data-testid="sample-data-unmatched-tabpanel">
                  <SampleDataUnmatchedResults />
                </TabPanel>
                <TabPanel lazy data-testid="sample-data-matched-tabpanel">
                  <SampleDataMatchedResults />
                </TabPanel>
              </TabPanels>
            </>
          </LoadingOverlay>
        </Box>
      </Tabs>
    </Box>
  );
};

export default SampleDataMain;
