/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Flex, Heading } from 'pouncejs';
import EmptyBoxImg from 'Assets/illustrations/empty-box.svg';

const EmptyDataFallback: React.FC<{ message: string }> = ({ message }) => {
  return (
    <Flex justify="center" align="center" direction="column" my={8} spacing={8}>
      <img alt="Empty Box Illustration" src={EmptyBoxImg} width="auto" height={200} />
      <Heading size="small" color="navyblue-100">
        {message}
      </Heading>
    </Flex>
  );
};

export default React.memo(EmptyDataFallback);
