/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import * as Types from '../../../../../__generated__/schema';

import { GraphQLError } from 'graphql';
import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';

export type GetGeneralSettingsConsentsVariables = {};

export type GetGeneralSettingsConsents = {
  generalSettings: Pick<
    Types.GeneralSettingsConfig,
    'displayName' | 'email' | 'analyticsConsent' | 'licenseAccepted'
  >;
};

export const GetGeneralSettingsConsentsDocument = gql`
  query GetGeneralSettingsConsents {
    generalSettings {
      displayName
      email
      analyticsConsent
      licenseAccepted
    }
  }
`;

/**
 * __useGetGeneralSettingsConsents__
 *
 * To run a query within a React component, call `useGetGeneralSettingsConsents` and pass it any options that fit your needs.
 * When your component renders, `useGetGeneralSettingsConsents` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGeneralSettingsConsents({
 *   variables: {
 *   },
 * });
 */
export function useGetGeneralSettingsConsents(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetGeneralSettingsConsents,
    GetGeneralSettingsConsentsVariables
  >
) {
  return ApolloReactHooks.useQuery<GetGeneralSettingsConsents, GetGeneralSettingsConsentsVariables>(
    GetGeneralSettingsConsentsDocument,
    baseOptions
  );
}
export function useGetGeneralSettingsConsentsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetGeneralSettingsConsents,
    GetGeneralSettingsConsentsVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    GetGeneralSettingsConsents,
    GetGeneralSettingsConsentsVariables
  >(GetGeneralSettingsConsentsDocument, baseOptions);
}
export type GetGeneralSettingsConsentsHookResult = ReturnType<typeof useGetGeneralSettingsConsents>;
export type GetGeneralSettingsConsentsLazyQueryHookResult = ReturnType<
  typeof useGetGeneralSettingsConsentsLazyQuery
>;
export type GetGeneralSettingsConsentsQueryResult = ApolloReactCommon.QueryResult<
  GetGeneralSettingsConsents,
  GetGeneralSettingsConsentsVariables
>;
export function mockGetGeneralSettingsConsents({
  data,
  variables,
  errors,
}: {
  data: GetGeneralSettingsConsents;
  variables?: GetGeneralSettingsConsentsVariables;
  errors?: GraphQLError[];
}) {
  return {
    request: { query: GetGeneralSettingsConsentsDocument, variables },
    result: { data, errors },
  };
}
