/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { FastField } from 'formik';
import { Link, IconButton, FormHelperText, Card, Box, Flex, Heading } from 'pouncejs';
import FormikEditor from 'Components/fields/Editor';
import { Link as RRLink } from 'react-router-dom';
import urls from 'Source/urls';

interface BaseDetectionFormEditorSectionProps {
  type: 'rule' | 'policy';
  isManaged: boolean;
}

const BaseDetectionFormEditorSection: React.FC<BaseDetectionFormEditorSectionProps> = ({
  type,
  isManaged,
}) => {
  const [open, setOpen] = React.useState(true);
  const isPolicy = type === 'policy';

  return (
    <Box>
      <Card variant="dark" p={4}>
        <Flex align="center" spacing={4}>
          <IconButton
            variant="ghost"
            variantBorderStyle="circle"
            active={open}
            variantColor="navyblue-700"
            icon={open ? 'caret-up' : 'caret-down'}
            onClick={() => setOpen(!open)}
            size="medium"
            aria-label="Toggle Editor visibility"
          />
          <Heading size="x-small">{isPolicy ? 'Policy Function' : 'Rule Function'} </Heading>
        </Flex>
        {open && (
          <Box mt={5} data-testid="function-textarea">
            <FastField
              as={FormikEditor}
              placeholder={`# Enter the body of the ${type} here...`}
              name="body"
              language="python"
              required
              aria-describedby="editor-helper-text"
              readOnly={isManaged}
            />
          </Box>
        )}
      </Card>
      <FormHelperText id="editor-helper-text" mt={3}>
        Need to define re-usable functions? Define them in a
        <Link ml={1} as={RRLink} to={urls.data.globalHelpers.list()}>
          global helper
        </Link>
      </FormHelperText>
    </Box>
  );
};

export default React.memo(BaseDetectionFormEditorSection);
