/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import * as Types from '../../../../../__generated__/schema';

import { GraphQLError } from 'graphql';
import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';

export type DeleteDetectionsVariables = {
  input: Types.DeleteDetectionInput;
};

export type DeleteDetections = Pick<Types.Mutation, 'deleteDetections'>;

export const DeleteDetectionsDocument = gql`
  mutation DeleteDetections($input: DeleteDetectionInput!) {
    deleteDetections(input: $input)
  }
`;
export type DeleteDetectionsMutationFn = ApolloReactCommon.MutationFunction<
  DeleteDetections,
  DeleteDetectionsVariables
>;

/**
 * __useDeleteDetections__
 *
 * To run a mutation, you first call `useDeleteDetections` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDetections` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDetections, { data, loading, error }] = useDeleteDetections({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteDetections(
  baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteDetections, DeleteDetectionsVariables>
) {
  return ApolloReactHooks.useMutation<DeleteDetections, DeleteDetectionsVariables>(
    DeleteDetectionsDocument,
    baseOptions
  );
}
export type DeleteDetectionsHookResult = ReturnType<typeof useDeleteDetections>;
export type DeleteDetectionsMutationResult = ApolloReactCommon.MutationResult<DeleteDetections>;
export type DeleteDetectionsMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeleteDetections,
  DeleteDetectionsVariables
>;
export function mockDeleteDetections({
  data,
  variables,
  errors,
}: {
  data: DeleteDetections;
  variables?: DeleteDetectionsVariables;
  errors?: GraphQLError[];
}) {
  return {
    request: { query: DeleteDetectionsDocument, variables },
    result: { data, errors },
  };
}
