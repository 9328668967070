/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Form, Formik, FastField } from 'formik';
import { ListAnalysisPacksInput, SortDirEnum } from 'Generated/schema';
import { Box, Flex } from 'pouncejs';
import pick from 'lodash/pick';
import useRequestParamsWithoutPagination from 'Hooks/useRequestParamsWithoutPagination';
import {
  extractSortingOptions,
  SortingOption,
  WrapSortingFormValues,
  wrapSortingOptions,
} from 'Helpers/utils';
import FormikAutosave from 'Components/utils/Autosave';
import FormikTextInput from 'Components/fields/TextInput';
import FormikCombobox from 'Components/fields/ComboBox';
import DropdownFilters from './DropdownFilters';

export enum ListPacksSortFieldsEnum {
  enabled = 'enabled',
}

export type ListPackInlineFiltersValues = {
  nameContains: Pick<ListAnalysisPacksInput, 'nameContains'>;
  sortBy?: ListPacksSortFieldsEnum;
  sortDir?: SortDirEnum;
};

const filters: (keyof ListAnalysisPacksInput)[] = ['nameContains', 'sortDir', 'sortBy'];

const sortingOpts: SortingOption<ListPackInlineFiltersValues>[] = [
  {
    opt: 'Enabled first',
    resolution: {
      sortBy: ListPacksSortFieldsEnum.enabled,
      sortDir: SortDirEnum.Ascending,
    },
  },
  {
    opt: 'Disabled first',
    resolution: {
      sortBy: ListPacksSortFieldsEnum.enabled,
      sortDir: SortDirEnum.Descending,
    },
  },
];

const defaultValues = {
  nameContains: '',
  sorting: null,
};

const ListPacksFilters: React.FC = () => {
  const { requestParams, updateRequestParams } = useRequestParamsWithoutPagination<
    ListPackInlineFiltersValues
  >();

  const initialFilterValues = React.useMemo(
    () => ({
      ...defaultValues,
      ...wrapSortingOptions(pick(requestParams, filters), sortingOpts),
    }),
    [requestParams]
  );

  return (
    <Flex justify="flex-end" align="center">
      <Formik<WrapSortingFormValues<ListPackInlineFiltersValues>>
        enableReinitialize
        initialValues={initialFilterValues}
        onSubmit={values => {
          updateRequestParams(extractSortingOptions(values, sortingOpts));
        }}
      >
        <Box flexGrow={1}>
          <Form>
            <FormikAutosave threshold={200} />
            <Flex spacing={4} align="center" pr={4}>
              <Box minWidth={425} flexGrow={1}>
                <FastField
                  data-tid="packs-nameContains-filter"
                  name="nameContains"
                  icon="search"
                  iconAlignment="left"
                  as={FormikTextInput}
                  label="Filter Pack by text"
                  placeholder="Search for a pack..."
                />
              </Box>
              <Box minWidth={220}>
                <FastField
                  data-tid="packs-sorting-options-combobox"
                  name="sorting"
                  as={FormikCombobox}
                  items={sortingOpts.map(sortingOption => sortingOption.opt)}
                  label="Sort By"
                  placeholder="Select a sorting option"
                />
              </Box>
            </Flex>
          </Form>
        </Box>
      </Formik>
      <Box pr={4}>
        <DropdownFilters />
      </Box>
    </Flex>
  );
};

export default React.memo(ListPacksFilters);
