/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import urls from 'Source/urls';
import s3Icon from 'Assets/logProviders/s3.svg';
import sqsIcon from 'Assets/logProviders/sqs.svg';
import cloudwatchIcon from 'Assets/logProviders/cloudwatch.svg';
import eventbridgeIcon from 'Assets/logProviders/eventbridge.svg';
import gcsIcon from 'Assets/logProviders/gcp.svg';
import { LogTransportMethodsEnum } from 'Source/constants';
import SimpleTransportCard from './SimpleTransportCard';
import ExtendedTransportCard from './ExtendedTransportCard';

type AbstractLogTransportMethodsEnum = Exclude<
  LogTransportMethodsEnum,
  LogTransportMethodsEnum.logPulling
>;

interface TransportMethod {
  type: AbstractLogTransportMethodsEnum;
  title: string;
  description: string;
  logo: string;
  beta: boolean;
}

interface AvailableTransportMethodProps {
  method: LogTransportMethodsEnum;
  logProviderId?: string;
  extended?: boolean;
}

const transportMethods: Record<AbstractLogTransportMethodsEnum, TransportMethod> = {
  [LogTransportMethodsEnum.s3]: {
    logo: s3Icon,
    title: 'AWS S3 Bucket',
    description: 'Get data from your S3 bucket into Panther.',
    type: LogTransportMethodsEnum.s3,
    beta: false,
  },
  [LogTransportMethodsEnum.sqs]: {
    logo: sqsIcon,
    title: 'AWS SQS Queue',
    description: 'Send data to a Panther-managed SQS queue.',
    type: LogTransportMethodsEnum.sqs,
    beta: false,
  },
  [LogTransportMethodsEnum.eventbridge]: {
    logo: eventbridgeIcon,
    title: 'AWS EventBridge',
    description: '',
    type: LogTransportMethodsEnum.eventbridge,
    beta: false,
  },
  [LogTransportMethodsEnum.cloudwatch]: {
    logo: cloudwatchIcon,
    title: 'AWS CloudWatch Logs',
    description: 'Get data from your CloudWatch Logs into Panther.',
    type: LogTransportMethodsEnum.cloudwatch,
    beta: false,
  },
  [LogTransportMethodsEnum.gcs]: {
    logo: gcsIcon,
    title: 'Google Cloud Storage',
    description: 'Get data from your Google Cloud Storage bucket into Panther.',
    type: LogTransportMethodsEnum.gcs,
    beta: false,
  },
};

const AvailableTransportMethod: React.FC<AvailableTransportMethodProps> = ({
  method,
  logProviderId,
  extended,
}) => {
  const transportMethod = transportMethods[method];
  if (!transportMethod) {
    return null;
  }

  const url = `${urls.integrations.logSources.create(transportMethod.type)}${
    logProviderId ? `?logProviderId=${logProviderId}` : ''
  }`;

  const TransportCard = extended ? ExtendedTransportCard : SimpleTransportCard;
  return <TransportCard transportMethod={transportMethod} url={url} />;
};

export default AvailableTransportMethod;
