/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { compose } from 'Helpers/compose';
import { Permission } from 'Generated/schema';
import withRoleRestrictedAccess from 'Hoc/withRoleRestrictedAccess';
import Page403 from 'Pages/403';
import { Alert, Box, useSnackbar } from 'pouncejs';
import PolicyForm, { PolicyFormValues } from 'Components/forms/PolicyForm';
import useRouter from 'Hooks/useRouter';
import withSEO from 'Hoc/withSEO';
import {
  cleanUpEmptyDetectionTestMocks,
  extractErrorMessage,
  formatStringifiedJSONSafe,
} from 'Helpers/utils';
import { useGetPolicyDetails } from './graphql/getPolicyDetails.generated';
import { useUpdatePolicy } from './graphql/updatePolicy.generated';
import Skeleton from './Skeleton';

const EditPolicyPage: React.FC = () => {
  const { match } = useRouter<{ id: string }>();
  const { pushSnackbar } = useSnackbar();

  const {
    error: fetchPolicyError,
    data: queryData,
    loading: isFetchingPolicy,
  } = useGetPolicyDetails({
    variables: {
      input: {
        id: match.params.id,
      },
    },
  });

  const [updatePolicy, { error: updateError }] = useUpdatePolicy({
    onCompleted: () =>
      pushSnackbar({
        variant: 'success',
        title: 'Successfully updated policy!',
      }),
    onError: () => null,
  });

  const handleSubmit = React.useCallback(
    ({ tests, ...rest }: PolicyFormValues) =>
      updatePolicy({
        variables: {
          input: {
            ...rest,
            tests: cleanUpEmptyDetectionTestMocks(tests),
          },
        },
      }),
    [updatePolicy]
  );

  if (isFetchingPolicy) {
    return <Skeleton />;
  }

  if (fetchPolicyError) {
    return (
      <Box mb={6}>
        <Alert
          variant="error"
          title="Couldn't load the policy details"
          discardable
          description={
            extractErrorMessage(fetchPolicyError) ||
            'There was an error when performing your request, please contact support@runpanther.io'
          }
        />
      </Box>
    );
  }

  // format any JSON returned from the server simply because we are going to display it
  // within an online web editor. To do that we parse the JSON and re-stringify it using proper
  // spacings that make it pretty (The server of course doesn't store these spacings when
  // it stores JSON, that's why we are making those here in the front-end)
  const { policy } = queryData;
  const initialValues = {
    body: policy.body,
    description: policy.description,
    displayName: policy.displayName,
    enabled: policy.enabled,
    managed: policy.managed,
    parentId: policy.parentId,
    id: policy.id,
    outputIds: policy.outputIds,
    reference: policy.reference,
    resourceTypes: policy.resourceTypes,
    reports: policy.reports,
    runbook: policy.runbook,
    severity: policy.severity,
    suppressions: policy.suppressions,
    tags: policy.tags,
    tests: queryData.policy.tests.map(({ resource, ...restTestData }) => ({
      ...restTestData,
      resource: formatStringifiedJSONSafe(resource),
    })),
  };

  return (
    <Box mb={6}>
      {updateError && (
        <Box mb={6}>
          <Alert
            variant="error"
            title="Couldn't update your policy"
            description={
              extractErrorMessage(updateError) ||
              'Unknown error occured during update. Please contact support@runpanther.io'
            }
          />
        </Box>
      )}
      <PolicyForm
        initialValues={initialValues}
        onSubmit={handleSubmit}
        cacheSessionId={`policy-form-${policy.id}-${String(policy.lastModified)}`}
        policy={policy}
        isUpdate
      />
    </Box>
  );
};

export default compose(
  withSEO({ title: ({ match }) => `Edit ${match.params.id}` }),
  withRoleRestrictedAccess({
    allowedPermissions: [Permission.PolicyModify],
    fallback: <Page403 />,
  })
)(EditPolicyPage);
