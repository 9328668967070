/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Box, Flex, Text } from 'pouncejs';
import { formatBytes, formatDatetime } from 'Helpers/utils';

export type TooltipOptions = {
  hideZeros?: boolean;
};

export interface ChartTooltipProps {
  params: any[];
  units?: string;
  options?: TooltipOptions;
}

const ChartTooltip: React.FC<ChartTooltipProps> = ({ params, units, options = {} }) => {
  const { hideZeros = true } = options;
  if (!params.length) {
    return null;
  }
  return (
    <Box
      font="primary"
      backgroundColor="navyblue-300"
      minWidth={200}
      boxShadow="dark250"
      p={4}
      borderRadius="medium"
    >
      <Text fontSize="small-medium" mb={3}>
        {formatDatetime(params[0].value[0], true)}
      </Text>
      <Flex direction="column" spacing={2} fontSize="x-small">
        {params.map((seriesInfo, i) => {
          if (hideZeros && (seriesInfo.value[1] === 0 || seriesInfo.value[1] === 0.0001)) {
            return null;
          }
          return (
            <Flex key={`chart-tooltip ${i}`} direction="column" spacing={2} fontSize="x-small">
              <Flex key={seriesInfo.seriesName} justify="space-between">
                <Box as="dt">
                  <span dangerouslySetInnerHTML={{ __html: seriesInfo.marker }} />
                  {seriesInfo.seriesName}
                </Box>
                <Box as="dd" font="mono" fontWeight="bold">
                  {units === 'bytes'
                    ? formatBytes(seriesInfo.value[1])
                    : `${seriesInfo.value[1].toLocaleString('en')}${units ? ` ${units}` : ''}`}
                </Box>
              </Flex>
            </Flex>
          );
        })}
      </Flex>
    </Box>
  );
};

export default ChartTooltip;
