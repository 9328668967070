/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { WizardPanel } from 'Components/Wizard';
import { useFormikContext, FastField } from 'formik';
import { Card, Flex, Link, Text, SimpleGrid, Box, Heading } from 'pouncejs';
import { CLOUD_ACCOUNTS_DOC_URL } from 'Source/constants';
import EditIntegrationActions from 'Pages/Integrations/components/EditIntegrationActions';
import FormikTextInput from 'Components/fields/TextInput';
import { ComplianceSourceWizardValues } from '../ComplianceSourceWizard';

const CustomMethod: React.FC = () => {
  const { values, isValid } = useFormikContext<ComplianceSourceWizardValues>();

  const editMode = !!values.integrationId;
  return (
    <WizardPanel>
      <WizardPanel.Heading title="Set up role manually" />

      <SimpleGrid gap={8} width={0.8} mx="auto">
        <Card as="section" variant="dark" py={4} px={10}>
          <Text fontSize="x-large" textAlign="center">
            Go to
            <Link external mx={1} href={CLOUD_ACCOUNTS_DOC_URL}>
              our documentation
            </Link>
            and read the instructions. After setting up the role, return here to continue the set up
            process
          </Text>
        </Card>
        <Box as="section">
          <Heading as="h2" color="teal-200" fontSize="x-large" fontWeight="medium">
            Enter Role ARN
          </Heading>
          <Text color="white-100" fontSize="medium" lineHeight="loose" my={1}>
            Create an IAM Role that Panther will assume to scan an account and copy the ARN in the
            box below.
          </Text>

          <FastField
            name="auditRole"
            as={FormikTextInput}
            label="Role ARN"
            trimPastedText
            required
          />
        </Box>
      </SimpleGrid>

      <WizardPanel.Actions>
        <WizardPanel.ActionPrev />
        {editMode ? (
          <EditIntegrationActions />
        ) : (
          <Flex spacing={4} direction="column" align="center">
            <Text fontSize="small" fontWeight="medium">
              After creating the role, click below to proceed
            </Text>
            <WizardPanel.ActionNext disabled={!isValid}>Continue Setup</WizardPanel.ActionNext>
          </Flex>
        )}
      </WizardPanel.Actions>
    </WizardPanel>
  );
};

export default CustomMethod;
