/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import withSEO from 'Hoc/withSEO';
import AuthPageContainer from 'Components/AuthPageContainer';
import ForgotPasswordForm from 'Components/forms/ForgotPasswordForm';
import { FadeIn, Link } from 'pouncejs';
import urls from 'Source/urls';
import { Link as RRLink } from 'react-router-dom';

const ForgotPasswordPage: React.FC = () => {
  return (
    <AuthPageContainer>
      <AuthPageContainer.Content>
        <FadeIn delay={100}>
          <AuthPageContainer.Caption
            title="Forgot your password?"
            subtitle="We'll help you reset your password and get back on track."
          />
          <ForgotPasswordForm />
        </FadeIn>
      </AuthPageContainer.Content>

      <AuthPageContainer.AltOptions>
        Remembered it all of a sudden?
        <Link as={RRLink} to={urls.account.auth.signIn()} ml={2}>
          Sign in
        </Link>
      </AuthPageContainer.AltOptions>
    </AuthPageContainer>
  );
};

export default withSEO({ title: 'Forgot Password' })(ForgotPasswordPage);
