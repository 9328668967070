/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { typedMemo } from 'Helpers/utils';

interface PopoverAutoSubmitProps<T> {
  values: T;
  isOpen: boolean;
  onSubmit: (values: T) => void;
}

function PopoverAutoSubmit<T>({ isOpen, values, onSubmit }: PopoverAutoSubmitProps<T>) {
  const isInitialMount = React.useRef(true);

  const handleSubmit = React.useCallback(
    currValues => {
      onSubmit(currValues);
    },
    [onSubmit]
  );

  React.useEffect(
    () => {
      if (!isOpen && !isInitialMount.current) {
        handleSubmit(values);
      }

      if (isInitialMount.current) {
        isInitialMount.current = false;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isOpen]
  );

  return null;
}

export default typedMemo(PopoverAutoSubmit);
