/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Box, Flex, Text, Theme } from 'pouncejs';
import { slugify } from 'Helpers/utils';

export interface MetricsBarProps {
  /** The label of the bar */
  label: string;

  /** Whether the bar label is visible */
  showLabel?: boolean;

  /** The value displayed next to the bar */
  value: number;

  /** A number between [0,1] specifying the width percentage that the bar will occupy relative to
   * its container
   */
  percentage: number;

  /** The color of the bar */
  color?: keyof Theme['colors'];

  /** The height of the bar */
  height?: number;

  /** The vertical margin of the bar */
  gap?: number;

  /** Callback called when clicking the bar */
  onClick?: () => void;

  /** Horizontal alignment of the value */
  justifyValue?: 'start' | 'end';

  /** Formats the provided value */
  formatValue?: (value: number) => string;

  /** Optional prefix for data-testid attribute */
  dataTestIdPrefix?: string;
}

const MetricsBar: React.FC<MetricsBarProps> = ({
  label,
  showLabel,
  value,
  percentage,
  color = 'pink-700',
  height = 32,
  onClick,
  gap = 3,
  justifyValue = 'end',
  formatValue = v => String(v),
  dataTestIdPrefix,
}) => {
  const id = slugify(label);
  const testId = `${dataTestIdPrefix || ''}${id}`;

  return (
    <Box py={gap} overflow="hidden">
      {showLabel && (
        <Text id={id} fontSize="small-medium" mx={2} mb={1} truncated>
          {label}
        </Text>
      )}
      <Flex align="center" spacing={2} data-testid={testId}>
        <Box
          as={!onClick ? 'span' : 'button'}
          id={id}
          {...(onClick && {
            onClick,
            transition: 'opacity 0.15s ease-in-out',
            opacity: 0.9,
            cursor: 'pointer',
            _hover: { backgroundColor: color, opacity: 1 },
          })}
          backgroundColor={color}
          borderRadius="pill"
          height={height}
          width={percentage}
          minWidth={10}
          flexShrink={1}
          aria-valuenow={value}
          aria-labelledby={id}
        />
        <Box
          data-testid="attribute-values"
          {...(justifyValue === 'end' && { mr: 0, ml: 'auto' })}
          as="span"
          fontSize="medium"
          fontWeight="bold"
          flexShrink={0}
        >
          {formatValue(value)}
        </Box>
      </Flex>
    </Box>
  );
};

export default React.memo(MetricsBar);
