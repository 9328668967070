/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Box, Card, Flex, Heading, Link, SimpleGrid } from 'pouncejs';
import urls from 'Source/urls';
import { Link as RRLink } from 'react-router-dom';
import { formatDatetime } from 'Helpers/utils';
import StatusBadge from 'Components/badges/StatusBadge';
import { ResourceDetails } from '../graphql/resourceDetails.generated';

interface ResourceDetailsInfoProps {
  resource?: ResourceDetails['resource'];
}

const ResourceDetailsInfo: React.FC<ResourceDetailsInfoProps> = ({ resource }) => {
  return (
    <Card as="article" p={6}>
      <Flex as="header" align="center" mb={4} spacing={4}>
        <Heading fontWeight="bold" wordBreak="break-word">
          {resource.id}
        </Heading>
      </Flex>
      <Flex spacing={4} as="ul" mb={6}>
        <Box as="li">
          <StatusBadge status={resource.complianceStatus} />
        </Box>
      </Flex>
      <Card variant="dark" as="section" p={4}>
        <SimpleGrid columns={3} fontSize="small-medium">
          <Flex spacing={5}>
            <Box id="type-label" color="navyblue-100">
              Type
            </Box>
            <Link
              aria-labelledby="type-label"
              as={RRLink}
              to={`${urls.data.resources.list()}?types[]=${resource.type}`}
            >
              {resource.type}
            </Link>
          </Flex>
          <Flex spacing={5}>
            {resource.integration && (
              <>
                <Box id="source-label" color="navyblue-100">
                  Source
                </Box>
                <Link
                  aria-labelledby="source-label"
                  as={RRLink}
                  to={`${urls.data.resources.list()}?integrationId=${
                    resource.integration.integrationId
                  }`}
                >
                  {resource.integration.integrationLabel}
                </Link>
              </>
            )}
          </Flex>
          <Flex spacing={5}>
            <Box id="updated-at" color="navyblue-100">
              Modified
            </Box>
            <Box id="updated-at">{formatDatetime(resource.lastModified)}</Box>
          </Flex>
        </SimpleGrid>
      </Card>
    </Card>
  );
};

export default React.memo(ResourceDetailsInfo);
