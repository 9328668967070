/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Alert, Box, Card, Flex } from 'pouncejs';
import { extractErrorMessage } from 'Helpers/utils';
import ErrorBoundary from 'Components/ErrorBoundary';
import { DEFAULT_LARGE_PAGE_SIZE } from 'Source/constants';
import useRouter from 'Hooks/useRouter';
import { useListSavedQueries } from 'Source/graphql/queries';
import useInfiniteScroll from 'Hooks/useInfiniteScroll';
import TablePlaceholder from 'Components/TablePlaceholder';
import SavedQueryCard from 'Components/cards/SavedQueryCard/SavedQueryCard';
import ItemsSkeleton from '../ItemsSkeleton';
import EmptyDataFallback from '../EmptyDataFallback';

const ListPackSavedQueries: React.FC = () => {
  const { match } = useRouter<{ id: string }>();

  const { loading, error, previousData, data = previousData, fetchMore } = useListSavedQueries({
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    variables: {
      input: {
        packIds: [match.params.id],
        pageSize: DEFAULT_LARGE_PAGE_SIZE,
      },
    },
  });

  const savedQueriesItems = data?.listSavedQueries.savedQueries || [];
  const lastEvaluatedKey = data?.listSavedQueries.lastEvaluatedKey || null;
  const hasNextPage = !!lastEvaluatedKey;

  const { sentinelRef } = useInfiniteScroll<HTMLDivElement>({
    loading,
    threshold: 500,
    onLoadMore: () => {
      fetchMore({
        variables: {
          input: {
            pageSize: DEFAULT_LARGE_PAGE_SIZE,
            exclusiveStartKey: lastEvaluatedKey,
          },
        },
      });
    },
  });

  if (loading && !data) {
    return <ItemsSkeleton />;
  }

  if (error) {
    return (
      <Box mb={6}>
        <Alert
          variant="error"
          title="Couldn't load your saved queries"
          description={
            extractErrorMessage(error) ||
            'There was an error when performing your request, please contact support@runpanther.io'
          }
        />
      </Box>
    );
  }

  if (!savedQueriesItems.length) {
    return <EmptyDataFallback message="No Saved Queries on Pack" />;
  }

  //  Check how many active filters exist by checking how many columns keys exist in the URL
  return (
    <ErrorBoundary>
      <Card as="section" position="relative" p={4}>
        <Box position="relative">
          <Flex direction="column" spacing={2}>
            {savedQueriesItems.map(savedQuery => (
              <SavedQueryCard key={savedQuery.id} savedQuery={savedQuery} />
            ))}
          </Flex>
          {hasNextPage && (
            <Box py={8} ref={sentinelRef}>
              <TablePlaceholder rowCount={10} />
            </Box>
          )}
        </Box>
      </Card>
    </ErrorBoundary>
  );
};

export default ListPackSavedQueries;
