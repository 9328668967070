/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Box, Card, Flex, TabList, TabPanel, TabPanels, Tabs } from 'pouncejs';
import { AlertOriginSystemError, SystemErrorTypeEnum } from 'Generated/schema';
import LinkButton from 'Components/buttons/LinkButton';
import { AlertDetails } from 'Pages/AlertDetails';
import ErrorBoundary from 'Components/ErrorBoundary';
import { BorderedTab, BorderTabDivider } from 'Components/BorderedTab';
import invert from 'lodash/invert';
import useUrlParams from 'Hooks/useUrlParams';
import useSystemErrorRelatedComponent from 'Hooks/useSystemErrorRelatedComponent';
import { DEFAULT_LARGE_PAGE_SIZE } from 'Source/constants';
import AlertDetailsEvents, { useGetSystemErrorAlertEvents } from './SystemErrorAlertEvents';
import SystemErrorAlertDetailsInfo from './SystemErrorAlertDetailsInfo';
import SystemErrorAlertDetailsBanner from './SystemErrorAlertDetailsBanner';
import SystemErrorAlertNonDeliveredAlerts from './SystemErrorAlertNonDeliveredAlerts';

interface SystemErrorAlertDetailsUrlParams {
  section?: 'details' | 'nondelivered' | 'events';
}

const sectionToTabIndex: Record<SystemErrorAlertDetailsUrlParams['section'], number> = {
  details: 0,
  nondelivered: 1,
  events: 2,
};

const tabIndexToSection = invert(sectionToTabIndex) as Record<
  number,
  SystemErrorAlertDetailsUrlParams['section']
>;

interface SystemAlertDetailsProps {
  alert: AlertDetails['alert'];
}

const SystemErrorAlertDetails: React.FC<SystemAlertDetailsProps> = ({ alert }) => {
  const { urlParams, updateUrlParams } = useUrlParams<SystemErrorAlertDetailsUrlParams>();

  const alertOrigin = alert.origin as AlertOriginSystemError;
  const relatedComponent = useSystemErrorRelatedComponent(alertOrigin);
  const showNonDeliveredAlerts = alertOrigin.type === SystemErrorTypeEnum.AlertDelivery;

  const systemErrorHasEvents = [
    SystemErrorTypeEnum.SourceLogProcessingErrors,
    SystemErrorTypeEnum.SourceClassificationFailures,
    SystemErrorTypeEnum.SourceScanningErrors,
  ].includes(alertOrigin.type);

  // optimistically prefetch the events that the `Events` tab will show
  useGetSystemErrorAlertEvents({
    skip: !systemErrorHasEvents,
    variables: { id: alert.id, eventsInput: { pageSize: DEFAULT_LARGE_PAGE_SIZE } },
  });

  return (
    <Box as="article" mb={6}>
      <Flex direction="column" spacing={6}>
        <SystemErrorAlertDetailsBanner alert={alert} />
        <Card position="relative">
          <Tabs
            index={sectionToTabIndex[urlParams.section] || 0}
            onChange={index => updateUrlParams({ section: tabIndexToSection[index] })}
          >
            <Box
              pl={2}
              pr={6}
              sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
            >
              <TabList>
                <BorderedTab>Details</BorderedTab>
                {showNonDeliveredAlerts && <BorderedTab>Non-Delivered Alerts</BorderedTab>}
                {systemErrorHasEvents && <BorderedTab>Events</BorderedTab>}
              </TabList>
              {!systemErrorHasEvents && (
                <LinkButton to={relatedComponent.to}>Go to {relatedComponent.label}</LinkButton>
              )}
            </Box>
            <BorderTabDivider />
            <Box p={6}>
              <TabPanels>
                <TabPanel data-testid="alert-details-tabpanel">
                  <ErrorBoundary>
                    <SystemErrorAlertDetailsInfo alert={alert} />
                  </ErrorBoundary>
                </TabPanel>
                {showNonDeliveredAlerts && (
                  <TabPanel lazy data-testid="alert-nondelivered-tabpanel">
                    <ErrorBoundary>
                      <SystemErrorAlertNonDeliveredAlerts alert={alert} />
                    </ErrorBoundary>
                  </TabPanel>
                )}
                {systemErrorHasEvents && (
                  <TabPanel lazy data-testid="alert-events-tabpanel">
                    <ErrorBoundary>
                      <AlertDetailsEvents alert={alert} />
                    </ErrorBoundary>
                  </TabPanel>
                )}
              </TabPanels>
            </Box>
          </Tabs>
        </Card>
      </Flex>
    </Box>
  );
};

export default SystemErrorAlertDetails;
