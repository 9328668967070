/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import type { monaco } from 'react-monaco-editor';
import { EditorProps } from 'Components/Editor/Editor';

interface UseEditorCompletionsProps {
  monacoInstance?: typeof monaco;
  completions: EditorProps['completions'];
  language: EditorProps['language'];
}

const useEditorCompletions = ({
  monacoInstance,
  completions,
  language,
}: UseEditorCompletionsProps) => {
  React.useEffect(() => {
    // wait until the instance ref exists
    if (!monacoInstance) {
      return;
    }

    const registration = monacoInstance.languages.registerCompletionItemProvider(language, {
      provideCompletionItems(model, position) {
        const wordModel = model.getWordAtPosition(position);
        const columnBeforeWord =
          !!wordModel && wordModel.word.length ? position.column - wordModel.word.length : 0;

        const suggestions = completions.map(completion => ({
          insertText: completion.value,
          label: completion.value,
          kind: monacoInstance.languages.CompletionItemKind.Constant,
          detail: completion.detail || completion.type,
        }));

        const textUntilPosition = model.getValueInRange({
          startLineNumber: 1,
          startColumn: 1,
          endLineNumber: position.lineNumber,
          endColumn: Math.abs(columnBeforeWord),
        });

        // @ts-ignore
        let tokens = [...textUntilPosition.matchAll(/\w+/gi)].map(x => x[0]);
        if (!tokens) {
          return {
            suggestions,
          } as monaco.languages.CompletionList;
        }
        const labels = suggestions.map(s => (s.label as string).toLowerCase());
        tokens = [...new Set(tokens.filter(w => labels.every(l => l !== w.toLowerCase())))];

        const merged = [
          ...suggestions,
          ...tokens.map(word => {
            return {
              kind: monacoInstance.languages.CompletionItemKind.Text,
              label: word,
              insertText: word,
            };
          }),
        ];
        return { suggestions: merged } as monaco.languages.CompletionList;
      },
    });

    // eslint-disable-next-line consistent-return
    return () => {
      registration.dispose();
    };
  }, [monacoInstance, completions, language]);
};

export default useEditorCompletions;
