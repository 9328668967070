/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import * as Types from '../../../../../../__generated__/schema';

import { LookupDetails } from '../../../../../graphql/fragments/LookupDetails.generated';
import { GraphQLError } from 'graphql';
import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';

export type AddLookupVariables = {
  input: Types.AddLookupInput;
};

export type AddLookup = { addLookup: LookupDetails };

export const AddLookupDocument = gql`
  mutation AddLookup($input: AddLookupInput!) {
    addLookup(input: $input) {
      ...LookupDetails
    }
  }
  ${LookupDetails}
`;
export type AddLookupMutationFn = ApolloReactCommon.MutationFunction<AddLookup, AddLookupVariables>;

/**
 * __useAddLookup__
 *
 * To run a mutation, you first call `useAddLookup` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddLookup` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addLookup, { data, loading, error }] = useAddLookup({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddLookup(
  baseOptions?: ApolloReactHooks.MutationHookOptions<AddLookup, AddLookupVariables>
) {
  return ApolloReactHooks.useMutation<AddLookup, AddLookupVariables>(
    AddLookupDocument,
    baseOptions
  );
}
export type AddLookupHookResult = ReturnType<typeof useAddLookup>;
export type AddLookupMutationResult = ApolloReactCommon.MutationResult<AddLookup>;
export type AddLookupMutationOptions = ApolloReactCommon.BaseMutationOptions<
  AddLookup,
  AddLookupVariables
>;
export function mockAddLookup({
  data,
  variables,
  errors,
}: {
  data: AddLookup;
  variables?: AddLookupVariables;
  errors?: GraphQLError[];
}) {
  return {
    request: { query: AddLookupDocument, variables },
    result: { data, errors },
  };
}
