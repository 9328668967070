/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Box, Flex, Img, Text } from 'pouncejs';
import LinkButton from 'Components/buttons/LinkButton';
import BetaTag from 'Components/BetaTag';

const ExtendedTransportCard = ({ transportMethod, url }) => (
  <Flex
    data-testid={`${transportMethod.type}-extended-card`}
    align="center"
    alignItems="center"
    spacing={2}
    px={6}
    py={7}
    backgroundColor="navyblue-400"
    transition="background-color 0.2s ease-in-out"
    borderRadius="large"
  >
    <Img
      alt={transportMethod.title}
      src={transportMethod.logo}
      nativeWidth={40}
      nativeHeight={40}
    />

    <Box as="span" ml={1}>
      <Flex align="center">
        <Text color="white-100" fontSize="x-large">
          {transportMethod.title}
        </Text>
        {transportMethod.beta && (
          <Box ml={2}>
            <BetaTag />
          </Box>
        )}
      </Flex>
      <Text color="gray-300" fontSize="medium">
        {transportMethod.description}
      </Text>
    </Box>
    <Box ml="auto" width={126}>
      <LinkButton fullWidth to={url}>
        Select
      </LinkButton>
    </Box>
  </Flex>
);

export default ExtendedTransportCard;
