/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { formatDatetime, getActorDisplayName } from 'Helpers/utils';
import { Text, Table } from 'pouncejs';
import { ListSavedQueries } from 'Source/graphql/queries';
import RoleRestrictedAccess from 'Components/utils/RoleRestrictedAccess';
import { Permission } from 'Generated/schema';

interface ListSavedQueriesTableProps {
  items?: ListSavedQueries['listSavedQueries']['savedQueries'];
  onSelect: (string) => void;
  selectedId: string;
}

const ListSavedQueriesTable: React.FC<ListSavedQueriesTableProps> = ({
  items,
  selectedId,
  onSelect,
}) => {
  return (
    <Table stickyHeader>
      <Table.Head>
        <Table.Row>
          <Table.HeaderCell>Name</Table.HeaderCell>
          <Table.HeaderCell>Description</Table.HeaderCell>
          <Table.HeaderCell>Last Update</Table.HeaderCell>
          <RoleRestrictedAccess allowedPermissions={[Permission.UserRead]}>
            <Table.HeaderCell>Updated By</Table.HeaderCell>
          </RoleRestrictedAccess>
        </Table.Row>
      </Table.Head>
      <Table.Body>
        {items.map(savedQuery => (
          <Table.Row
            key={savedQuery.id}
            onClick={() => onSelect(savedQuery.id)}
            selected={savedQuery.id === selectedId}
            // @ts-ignore
            cursor="pointer"
          >
            <Table.Cell width={250} wrapText="wrap">
              <Text overflowWrap="anywhere">{savedQuery.name}</Text>
            </Table.Cell>
            <Table.Cell width={250} wrapText="wrap">
              <Text overflowWrap="anywhere">{savedQuery.description}</Text>
            </Table.Cell>
            <Table.Cell maxWidth={200}>{formatDatetime(savedQuery.lastModifiedAt)}</Table.Cell>
            <RoleRestrictedAccess allowedPermissions={[Permission.UserRead]}>
              <Table.Cell width={150}>
                <Text overflowWrap="anywhere">
                  {getActorDisplayName(savedQuery.lastModifiedBy)}
                </Text>
              </Table.Cell>
            </RoleRestrictedAccess>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  );
};

export default React.memo(ListSavedQueriesTable);
