/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import withSEO from 'Hoc/withSEO';
import { compose } from 'Helpers/compose';
import { LookupDetails } from 'Source/graphql/fragments/LookupDetails.generated';
import { Alert, Box, useSnackbar } from 'pouncejs';
import useRouter from 'Hooks/useRouter';
import { extractErrorMessage } from 'Helpers/utils';
import Page403 from 'Pages/403';
import { Permission } from 'Generated/schema';
import withRoleRestrictedAccess from 'Hoc/withRoleRestrictedAccess';
import {
  LookupFormValues,
  lookupFormValidationSchema,
  getLookupUpdatePayload,
} from 'Components/wizards/LookupWizard/lookupWizardHelpers';
import { Formik, Form, FormikHelpers } from 'formik';
import LookupWizard from 'Components/wizards/LookupWizard';
import { useUpdateLookup } from 'Source/graphql/queries/updateLookup.generated';
import EditLookupPageSkeleton from './Skeleton';
import { useGetLookup } from '../graphql/getLookup.generated';

interface EditLookupFormProps {
  lookup: LookupDetails;
}

/**
 * Renders the form for editing a lookup. This component is responsible for:
 *
 * - Wrapping the form UI.
 * - Determining the form's initial state based on the lookup that was loaded (outside this component)
 * - Updating the lookup whenever the form is submitted.
 *
 * This component is NOT responsible for:
 * - Loading the lookup table from the server. By taking the lookup as a prop, this creates
 *   simpler logic inside of this component.
 */
const EditLookupForm = ({ lookup }: EditLookupFormProps) => {
  const { pushSnackbar } = useSnackbar();
  const [updateLookup] = useUpdateLookup({
    onError: err => {
      pushSnackbar({
        variant: 'error',
        title: "Couldn't update lookup",
        description: extractErrorMessage(err) || 'Some unknown error occurred',
      });
    },
  });

  const initialValues = React.useMemo<LookupFormValues | undefined>(() => {
    let associatedLogTypes = lookup.activeVersion?.associatedLogTypes ?? [];

    // Creating a lookup on the server defaults to an empty list of associated
    // log types, so create an empty entry to display the fields
    if (associatedLogTypes.length === 0) {
      associatedLogTypes = [{ logType: null, selectors: [] }];
    }

    return {
      id: lookup.id,
      name: lookup.name ?? 'Loading...',
      description: lookup.description ?? 'Loading...',
      enabled: lookup.enabled ?? true,
      reference: lookup.reference ?? 'Loading...',
      associatedLogTypes,
      activeTable: lookup.activeVersion,
      schemaName: lookup.activeVersion?.logType,
      primaryKey: lookup.activeVersion?.primaryKey,
      uploadMethod: lookup.refresh === null ? 'file' : 's3',
      ...(lookup.refresh === null
        ? {
            updatePeriod: 60,
          }
        : {
            // role arns are in the format - arn:{partition}:iam::{account_id}:etc
            // so we can extract the aws account since that's not directly saved
            awsAccountId: lookup.refresh?.roleARN.split(':')[4],
            iamRoleArn: lookup.refresh.roleARN,
            updatePeriod: lookup.refresh.periodMinutes,
            alarmThreshold: lookup.refresh.alarmPeriodMinutes ?? 0,
            s3FileLocation: lookup.refresh.objectPath,
            kmsKey: lookup.refresh.objectKMSKey,
          }),
    };
  }, [lookup]);

  const handleSubmit = React.useCallback(
    async (values: LookupFormValues, actions: FormikHelpers<LookupFormValues>) => {
      const input = getLookupUpdatePayload(values);
      const { errors } = await updateLookup({ variables: { input } });

      // Only reset the form to a valid state if the save submission passed.
      if (!errors) {
        actions.resetForm();
      }
    },
    [updateLookup]
  );

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={lookupFormValidationSchema}
      onSubmit={handleSubmit}
    >
      <Form>
        <LookupWizard isEditing title="Edit Lookup Table" />
      </Form>
    </Formik>
  );
};

/**
 * Wrapper for the entire lookup editing flow. This component is responsible for
 *
 * - Loading the correct lookup based on URL parameters.
 * - Handling loading / error states while the lookup data is loading.
 * - Rendering the form component if the lookup data has finished loading.
 */
const EditLookup = () => {
  const { match } = useRouter<{ id: string }>();
  const { error: fetchLookupError, data: queryData, loading: isFetchingLookup } = useGetLookup({
    variables: {
      id: match.params.id,
    },
  });

  if (isFetchingLookup) {
    return <EditLookupPageSkeleton />;
  }

  if (fetchLookupError) {
    return (
      <Box mb={6}>
        <Alert
          variant="error"
          title="Couldn't load your lookup"
          description={
            extractErrorMessage(fetchLookupError) ||
            'There was an error when performing your request, please contact support@runpanther.io'
          }
        />
      </Box>
    );
  }

  return <EditLookupForm lookup={queryData.getLookup} />;
};

export default compose(
  withSEO({ title: 'Edit Lookup' }),
  withRoleRestrictedAccess({
    allowedPermissions: [Permission.LookupModify],
    fallback: <Page403 />,
  })
)(EditLookup);
