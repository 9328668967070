/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import urls from 'Source/urls';
import LinkButton from 'Components/buttons/LinkButton';
import TelescopeSVG from 'Assets/telescope.svg';
import { Img, Text, Box, Flex } from 'pouncejs';

const MitreRelationsDetectionsEmpty = ({
  tacticId,
  techniqueId,
}: {
  tacticId: string;
  techniqueId: string;
}) => {
  return (
    <Box p={8} textAlign="center">
      <Box py={8}>
        <Img src={TelescopeSVG} nativeWidth={65} nativeHeight={65} alt="Telescope" />
      </Box>
      <Box pb={6}>
        <Text>No detections are currently mapped to this technique.</Text>
      </Box>
      <Flex justifyContent="center" spacing={4}>
        <LinkButton to={urls.detections.create()}>Create New</LinkButton>
        <LinkButton
          to={urls.reports.mitreMapping({
            tacticId,
            techniqueId,
          })}
          variant="outline"
          variantColor="navyblue-200"
        >
          Map Existing
        </LinkButton>
      </Flex>
    </Box>
  );
};

export default MitreRelationsDetectionsEmpty;
