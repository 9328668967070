/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Text } from 'pouncejs';

const BulletDivider: React.FC = () => {
  return (
    <Text px={2} fontSize="small" as="span" color="navyblue-100">
      &bull;
    </Text>
  );
};

export default BulletDivider;
