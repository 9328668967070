/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import { MutationBaseOptions } from '@apollo/client/core/watchQueryOptions';
import React from 'react';
import { Policy, ResourceDetails } from 'Generated/schema';
import { useSnackbar } from 'pouncejs';
import { extractErrorMessage } from 'Helpers/utils';
import { useSuppressPolicy } from './graphql/suppressPolicy.generated';

interface UsePolicySuppressionProps {
  /** Corresponding policy */
  policyId: Policy['id'];

  /** Corresponding resource */
  resourceId: ResourceDetails['id'];

  /** Optional refetchQueries options */
  refetchQueries?: MutationBaseOptions['refetchQueries'];
}

const usePolicySuppression = ({
  policyId,
  resourceId,
  refetchQueries,
}: UsePolicySuppressionProps) => {
  const { pushSnackbar } = useSnackbar();

  const [suppressPolicies, { loading }] = useSuppressPolicy({
    refetchQueries,
    optimisticResponse: {
      suppressPolicies: true,
    },
    update: cache => {
      cache.modify({
        id: cache.identify({ __typename: 'Policy', id: policyId }),
        fields: {
          suppressions: value => [...value, resourceId],
        },
      });
      cache.modify({
        id: cache.identify({ __typename: 'ComplianceItem', policyId, resourceId }),
        fields: {
          suppressed: () => true,
        },
      });
    },
    variables: {
      input: { policyIds: [policyId], resourcePatterns: [resourceId] },
    },
    onCompleted: () => {
      pushSnackbar({ variant: 'success', title: 'Suppression applied successfully' });
    },
    onError: error => {
      pushSnackbar({
        variant: 'error',
        title:
          extractErrorMessage(error) ||
          'Failed to apply suppression due to an unknown and unpredicted error',
      });
    },
  });

  return React.useMemo(() => ({ suppressPolicies, loading }), [suppressPolicies, loading]);
};

export default usePolicySuppression;
