/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Card } from 'pouncejs';

export type CardVariant = 'light' | 'dark';

type PounceCardVariant = React.ComponentProps<typeof Card>['variant'];

type CardVariantConfigValues = {
  [key in CardVariant]: {
    defaultCardVariant: PounceCardVariant;
    selectedCardVariant: PounceCardVariant;
  };
};

export const CardVariantConfig: CardVariantConfigValues = {
  dark: {
    defaultCardVariant: 'dark',
    selectedCardVariant: 'darker',
  },
  light: {
    defaultCardVariant: 'light',
    selectedCardVariant: 'lighter',
  },
};
