/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Box, Card, useSnackbar, Heading, Text, Flex, IconButton, Img, Link, Icon } from 'pouncejs';
import ErrorBoundary from 'Components/ErrorBoundary';
import { extractErrorMessage } from 'Helpers/utils';
import withRoleRestrictedAccess from 'Hoc/withRoleRestrictedAccess';
import { Permission } from 'Generated/schema';
import Page403 from 'Pages/403';
import { compose } from 'Helpers/compose';
import withSEO from 'Hoc/withSEO';
import SuccessImg from 'Assets/illustrations/success.svg';
import useCopyToClipboard from 'Hooks/useCopyToClipboard';
import APITokenForm from 'Components/forms/APITokenForm';
import { APITokenFormValues } from 'Components/forms/APITokenForm/APITokenForm';
import { PUBLIC_API_USAGE_DOC_URL } from 'Source/constants';
import LinkButton from 'Components/buttons/LinkButton';
import { Link as RRLink } from 'react-router-dom';
import urls from 'Source/urls';
import { useAddAPIToken } from './graphql/addAPIToken.generated';

export const initialValues = {
  name: '',
  permissions: [Permission.AlertRead, Permission.UserRead, Permission.OrganizationApiTokenRead],
};

const CreateAPIToken: React.FC = () => {
  const { pushSnackbar } = useSnackbar();
  const copyToClipboard = useCopyToClipboard();

  const [addAPIToken, { data }] = useAddAPIToken({
    update: (cache, { data: { createAPIToken } }) => {
      if (createAPIToken.token) {
        cache.modify({
          fields: {
            listAPITokens: (queryData, { toReference }) => {
              return {
                ...queryData,
                tokens: [toReference(createAPIToken.token), ...queryData.tokens],
              };
            },
          },
        });
      }
    },
    onCompleted: ({ createAPIToken }) => {
      if (createAPIToken.error) {
        pushSnackbar({ title: createAPIToken.error.message, variant: 'error' });
      }
    },
    onError: error => {
      pushSnackbar({
        title: extractErrorMessage(error) || 'An unknown error occurred',
        variant: 'error',
      });
    },
  });

  const handleSubmit = React.useCallback(
    (values: APITokenFormValues) => addAPIToken({ variables: { input: values } }),
    [addAPIToken]
  );

  const apiTokenValue = data?.createAPIToken.token?.value ?? null;
  if (apiTokenValue) {
    return (
      <Card p={8}>
        <Flex width={550} direction="column" justify="center" align="center" mx="auto">
          <Heading fontWeight="medium" mb={8}>
            API Token Created
          </Heading>
          <Img
            alt="Men holding a checkmark sign"
            src={SuccessImg}
            nativeWidth="auto"
            nativeHeight={200}
            mb={10}
          />
          <Flex
            fontSize="medium"
            color="yellow-500"
            justify="center"
            align="center"
            spacing={2}
            mb={8}
          >
            <Icon type="warning" size="small" />
            <Text>API Tokens should be treated like passwords & stored in a secure location</Text>
          </Flex>
          <Text fontSize="medium" color="gray-300" textAlign="center" mb={8}>
            Your token is currently being activated and will be available for use in few seconds.{' '}
            <br />
            Please copy it since we won{"'"}t show it to you again
          </Text>
          <Box width="100%" px={8} mb={8}>
            <Card width="100%" variant="dark" px={5} py={4}>
              <Flex align="center" justify="space-between">
                {apiTokenValue}
                <IconButton
                  variant="ghost"
                  variantBorderStyle="circle"
                  variantColor="gray-300"
                  icon="copy"
                  size="small"
                  aria-label="Copy API Token value"
                  onClick={() => copyToClipboard(apiTokenValue)}
                />
              </Flex>
            </Card>
          </Box>
          <Link fontSize="medium" href={PUBLIC_API_USAGE_DOC_URL} variant="neutral" external mb={3}>
            Get started with our API Docs
          </Link>
          <Link
            as={RRLink}
            fontSize="medium"
            to={urls.settings.apiTokens.playground()}
            variant="neutral"
            mb={10}
          >
            Give it a go on our Playground
          </Link>
          <Box width={0.5}>
            <LinkButton fullWidth to={urls.settings.apiTokens.list()}>
              Done
            </LinkButton>
          </Box>
        </Flex>
      </Card>
    );
  }

  return (
    <Box mb={6}>
      <ErrorBoundary>
        <Card p={6}>
          <APITokenForm initialValues={initialValues} onSubmit={handleSubmit} />
        </Card>
      </ErrorBoundary>
    </Box>
  );
};

export default compose(
  withSEO({ title: 'Create API Token' }),
  withRoleRestrictedAccess({
    allowedPermissions: [Permission.OrganizationApiTokenModify],
    fallback: <Page403 />,
  })
)(CreateAPIToken);
