/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import isEqual from 'lodash/isEqual';
import React from 'react';
import { Text, SimpleGrid, Box } from 'pouncejs';
import MitreMatrixGridLayout from './MitreMatrixGridLayout';
import { useMitreContext } from '../MitreContext';
import MitreMatrixGridRow from './MitreMatrixGridRow';
import MitreMatrixGridCharmButton from './MitreMatrixGridCharmButton';
import MitreMatrixGridCell from './MitreMatrixGridCell';

const MitreMatrixGrid = () => {
  const { activeMatrix: matrix, selectTacticTechnique, activeTacticTechnique } = useMitreContext();

  return (
    <MitreMatrixGridLayout>
      <SimpleGrid as="figure" columns={36} gap={3} fontSize="medium">
        <Box gridColumn="1/8" pb={3} textAlign="right" fontWeight="bold">
          <Text>Tactics</Text>
        </Box>
        <Box gridColumn="10/36" pb={3} fontWeight="bold">
          <Text>Techniques</Text>
        </Box>
        {matrix?.mappings.map((mapping, idx) => (
          <MitreMatrixGridRow key={`${mapping.tacticId}-${idx}`} matrix={matrix} mapping={mapping}>
            {mapping.techniqueIds.map((techniqueId, idx2) => (
              <MitreMatrixGridCharmButton
                key={`${techniqueId}:${idx2}`}
                onClick={selectTacticTechnique}
                selected={isEqual(activeTacticTechnique, {
                  tacticId: mapping.tacticId,
                  techniqueId,
                })}
                tacticTechnique={{ tacticId: mapping.tacticId, techniqueId }}
              >
                <MitreMatrixGridCell
                  matrix={matrix}
                  tacticId={mapping.tacticId}
                  techniqueId={techniqueId}
                />
              </MitreMatrixGridCharmButton>
            ))}
          </MitreMatrixGridRow>
        ))}
      </SimpleGrid>
    </MitreMatrixGridLayout>
  );
};

export default React.memo(MitreMatrixGrid);
