/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import * as Types from '../../../../__generated__/schema';

import { GraphQLError } from 'graphql';
import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';

export type GetMetadataForQueryVariables = {
  id: Types.Scalars['ID'];
};

export type GetMetadataForQuery = { dataLakeQuery: Pick<Types.DataLakeQuery, 'id' | 'metadata'> };

export const GetMetadataForQueryDocument = gql`
  query GetMetadataForQuery($id: ID!) {
    dataLakeQuery(id: $id) {
      id
      metadata
    }
  }
`;

/**
 * __useGetMetadataForQuery__
 *
 * To run a query within a React component, call `useGetMetadataForQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMetadataForQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMetadataForQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetMetadataForQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<GetMetadataForQuery, GetMetadataForQueryVariables>
) {
  return ApolloReactHooks.useQuery<GetMetadataForQuery, GetMetadataForQueryVariables>(
    GetMetadataForQueryDocument,
    baseOptions
  );
}
export function useGetMetadataForQueryLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetMetadataForQuery,
    GetMetadataForQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<GetMetadataForQuery, GetMetadataForQueryVariables>(
    GetMetadataForQueryDocument,
    baseOptions
  );
}
export type GetMetadataForQueryHookResult = ReturnType<typeof useGetMetadataForQuery>;
export type GetMetadataForQueryLazyQueryHookResult = ReturnType<
  typeof useGetMetadataForQueryLazyQuery
>;
export type GetMetadataForQueryQueryResult = ApolloReactCommon.QueryResult<
  GetMetadataForQuery,
  GetMetadataForQueryVariables
>;
export function mockGetMetadataForQuery({
  data,
  variables,
  errors,
}: {
  data: GetMetadataForQuery;
  variables?: GetMetadataForQueryVariables;
  errors?: GraphQLError[];
}) {
  return {
    request: { query: GetMetadataForQueryDocument, variables },
    result: { data, errors },
  };
}
