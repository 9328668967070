/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import * as Types from '../../../../../../__generated__/schema';

import { DataLakeDatabaseSummary } from '../../../../../graphql/fragments/DataLakeDatabaseSummary.generated';
import { DataLakeDatabaseTableSummary } from '../../../../../graphql/fragments/DataLakeDatabaseTableSummary.generated';
import { DataLakeDatabaseTableColumnDetails } from '../../../../../graphql/fragments/DataLakeDatabaseTableColumnDetails.generated';
import { GraphQLError } from 'graphql';
import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';

export type ListTablesForDatabaseVariables = {
  name: Types.Scalars['String'];
};

export type ListTablesForDatabase = {
  dataLakeDatabase: {
    tables: Array<
      Pick<Types.DataLakeDatabaseTable, 'description'> & {
        columns: Array<DataLakeDatabaseTableColumnDetails>;
      } & DataLakeDatabaseTableSummary
    >;
  } & DataLakeDatabaseSummary;
};

export const ListTablesForDatabaseDocument = gql`
  query ListTablesForDatabase($name: String!) {
    dataLakeDatabase(name: $name) {
      ...DataLakeDatabaseSummary
      tables {
        ...DataLakeDatabaseTableSummary
        description
        columns {
          ...DataLakeDatabaseTableColumnDetails
        }
      }
    }
  }
  ${DataLakeDatabaseSummary}
  ${DataLakeDatabaseTableSummary}
  ${DataLakeDatabaseTableColumnDetails}
`;

/**
 * __useListTablesForDatabase__
 *
 * To run a query within a React component, call `useListTablesForDatabase` and pass it any options that fit your needs.
 * When your component renders, `useListTablesForDatabase` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListTablesForDatabase({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useListTablesForDatabase(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    ListTablesForDatabase,
    ListTablesForDatabaseVariables
  >
) {
  return ApolloReactHooks.useQuery<ListTablesForDatabase, ListTablesForDatabaseVariables>(
    ListTablesForDatabaseDocument,
    baseOptions
  );
}
export function useListTablesForDatabaseLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    ListTablesForDatabase,
    ListTablesForDatabaseVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<ListTablesForDatabase, ListTablesForDatabaseVariables>(
    ListTablesForDatabaseDocument,
    baseOptions
  );
}
export type ListTablesForDatabaseHookResult = ReturnType<typeof useListTablesForDatabase>;
export type ListTablesForDatabaseLazyQueryHookResult = ReturnType<
  typeof useListTablesForDatabaseLazyQuery
>;
export type ListTablesForDatabaseQueryResult = ApolloReactCommon.QueryResult<
  ListTablesForDatabase,
  ListTablesForDatabaseVariables
>;
export function mockListTablesForDatabase({
  data,
  variables,
  errors,
}: {
  data: ListTablesForDatabase;
  variables?: ListTablesForDatabaseVariables;
  errors?: GraphQLError[];
}) {
  return {
    request: { query: ListTablesForDatabaseDocument, variables },
    result: { data, errors },
  };
}
