/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { AsyncQueryContext } from 'Components/utils/AsyncQueryContext';

const useAsyncQueryContext = () => {
  const context = React.useContext(AsyncQueryContext);
  if (!context) {
    throw new Error(`useAsyncQueryContext must be used within a AsyncQueryContextProvider`);
  }
  return context;
};

export default useAsyncQueryContext;
