/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Box, Button, Flex, Card, Heading, Link, Text, Badge } from 'pouncejs';
import { DetectionTypeEnum, Permission } from 'Generated/schema';
import { PolicyDetails } from 'Source/graphql/fragments/PolicyDetails.generated';
import urls from 'Source/urls';
import { ENTITIES_COLOR_MAP } from 'Source/constants';
import Breadcrumbs from 'Components/Breadcrumbs';
import SeverityBadge from 'Components/badges/SeverityBadge';
import StatusBadge from 'Components/badges/StatusBadge';
import LinkButton from 'Components/buttons/LinkButton';
import useDetectionDestinations from 'Hooks/useDetectionDestinations';
import BulletedValue from 'Components/BulletedValue';
import RelatedDestinations from 'Components/RelatedDestinations';
import RoleRestrictedAccess from 'Components/utils/RoleRestrictedAccess';
import LimitItemDisplay from 'Components/LimitItemDisplay/LimitItemDisplay';
import ManagedBadge from 'Components/badges/ManagedBadge';
import BulletDivider from 'Components/BulletDivider';
import AdditionalBadgesJewel from 'Components/AdditionalBadgesJewel';
import useClonePolicy from 'Hooks/useClonePolicy';
import { Link as RRLink } from 'react-router-dom';

interface PolicyDetailsBannerProps {
  policy?: PolicyDetails;
}

const PolicyDetailsBanner: React.FC<PolicyDetailsBannerProps> = ({ policy }) => {
  const {
    detectionDestinations,
    loading: loadingDetectionDestinations,
  } = useDetectionDestinations({ detection: policy });
  const { clonePolicy, isCloning } = useClonePolicy();

  return (
    <React.Fragment>
      <RoleRestrictedAccess allowedPermissions={[Permission.PolicyModify]}>
        <Breadcrumbs.Actions>
          <Flex spacing={4} justify="flex-end">
            <LinkButton
              icon="pencil"
              variant="outline"
              data-tid="edit-policy"
              variantColor="navyblue-200"
              to={urls.analysis.policies.edit(policy.id)}
            >
              Edit
            </LinkButton>
            {/* Hide delete button for managed detections to prevent orphaned clones */}
            {policy.managed && (
              <Button
                icon="copy"
                onClick={() => clonePolicy(policy.id)}
                variant="outline"
                data-tid="clone-policy"
                aria-label="Clone and Edit"
                variantColor="navyblue-200"
              >
                {isCloning ? 'Cloning...' : 'Clone & edit'}
              </Button>
            )}
          </Flex>
        </Breadcrumbs.Actions>
      </RoleRestrictedAccess>
      <Card
        as="article"
        p={6}
        borderLeft="4px solid"
        borderColor={ENTITIES_COLOR_MAP[DetectionTypeEnum.Policy]}
      >
        <Flex as="header" align="center">
          <Heading
            fontWeight="bold"
            wordBreak="break-word"
            aria-describedby="policy-description"
            flexShrink={1}
            display="flex"
            alignItems="center"
            mr={100}
          >
            {policy.displayName || policy.id}
          </Heading>

          <Flex flexShrink={0} ml="auto" alignItems="center">
            <RelatedDestinations
              destinations={detectionDestinations}
              loading={loadingDetectionDestinations}
              limit={5}
            />
            <BulletDivider />
            <Flex spacing={2} as="ul">
              <Box as="li">
                <ManagedBadge managed={policy.managed} />
              </Box>
              <Box as="li">
                <StatusBadge status={policy.complianceStatus} disabled={!policy.enabled} />
              </Box>
              <Box as="li">
                <SeverityBadge severity={policy.severity} />
              </Box>
            </Flex>
          </Flex>
        </Flex>

        <Flex as="dl" fontSize="small-medium" pt={1} wrap="wrap" alignItems="center">
          <Box>
            <BulletedValue
              size="small"
              color={ENTITIES_COLOR_MAP[DetectionTypeEnum.Policy]}
              value="Policy"
              emphasized
            />
          </Box>
          <BulletDivider />
          <Box>
            <Text as="dt" fontWeight="bold" display="inline" mr={1}>
              ID
            </Text>
            <Text as="dd" display="inline" color="gray-300">
              {policy.id}
            </Text>
          </Box>
          {policy.parentId && (
            <>
              <BulletDivider />
              <Flex>
                <Text as="dt" fontWeight="bold" mr={1}>
                  Parent ID
                </Text>
                <Box as="dd">
                  <Link as={RRLink} to={urls.analysis.policies.details(policy.parentId)}>
                    {policy.parentId}
                  </Link>
                </Box>
              </Flex>
            </>
          )}
        </Flex>
        <Flex pt={3} spacing={1}>
          <LimitItemDisplay limit={3} AdditionalItemsComponent={AdditionalBadgesJewel}>
            {policy.resourceTypes.map(item => (
              <Badge key={item} color="navyblue-200">
                {item}
              </Badge>
            ))}
          </LimitItemDisplay>
        </Flex>
      </Card>
    </React.Fragment>
  );
};

export default React.memo(PolicyDetailsBanner);
