/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Alert, Box, useSnackbar } from 'pouncejs';
import RuleForm, { RuleFormValues } from 'Components/forms/RuleForm';
import { compose } from 'Helpers/compose';

import { Permission, DetectionTypeEnum } from 'Generated/schema';
import useRouter from 'Hooks/useRouter';
import {
  cleanUpEmptyDetectionTestMocks,
  extractErrorMessage,
  formatStringifiedJSONSafe,
} from 'Helpers/utils';
import withRoleRestrictedAccess from 'Hoc/withRoleRestrictedAccess';
import Page403 from 'Pages/403';
import withSEO from 'Hoc/withSEO';
import Skeleton from './Skeleton';
import { useGetRuleDetails } from './graphql/getRuleDetails.generated';
import { useUpdateRule } from './graphql/updateRule.generated';

const EditRulePage: React.FC = () => {
  const { match } = useRouter<{ id: string }>();
  const { pushSnackbar } = useSnackbar();

  const { error: fetchRuleError, data: queryData, loading: isFetchingRule } = useGetRuleDetails({
    variables: {
      input: {
        id: match.params.id,
      },
    },
  });

  const rule = queryData?.rule;
  const [updateRule, { error: updateError }] = useUpdateRule({
    onCompleted: () =>
      pushSnackbar({
        variant: 'success',
        title: 'Successfully updated rule!',
      }),
    onError: () => null,
  });

  const handleSubmit = React.useCallback(
    ({ tests, ...rest }: RuleFormValues) =>
      updateRule({
        variables: {
          input: {
            ...rest,
            tests: cleanUpEmptyDetectionTestMocks(tests),
            analysisType: rule.analysisType,
          },
        },
      }),
    [rule, updateRule]
  );

  if (isFetchingRule) {
    return <Skeleton />;
  }

  if (fetchRuleError) {
    return (
      <Box mb={6}>
        <Alert
          variant="error"
          title="Couldn't load the rule details"
          description={
            extractErrorMessage(fetchRuleError) ||
            'There was an error when performing your request, please contact support@runpanther.io'
          }
        />
      </Box>
    );
  }

  if (!rule) {
    return (
      <Box mb={6}>
        <Alert
          variant="warning"
          title="Couldn't load the rule details"
          description="Unable to load the rule. It may have been deleted."
        />
      </Box>
    );
  }

  // format any JSON returned from the server simply because we are going to display it
  // within an online web editor. To do that we parse the JSON and re-stringify it using proper
  // spacings that make it pretty (The server of course doesn't store these spacings when
  // it stores JSON, that's why we are making those here in the front-end)
  const initialValues = {
    body: rule.body,
    dedupPeriodMinutes: rule.dedupPeriodMinutes,
    threshold: rule.threshold,
    description: rule.description,
    displayName: rule.displayName,
    enabled: rule.enabled,
    id: rule.id,
    managed: rule.managed,
    parentId: rule.parentId,
    logTypes: rule.logTypes,
    scheduledQueries: rule.scheduledQueries,
    outputIds: rule.outputIds,
    reference: rule.reference,
    reports: rule.reports,
    runbook: rule.runbook,
    severity: rule.severity,
    summaryAttributes: rule.summaryAttributes,
    tags: rule.tags,
    tests: rule.tests.map(({ resource, ...restTestData }) => ({
      ...restTestData,
      resource: formatStringifiedJSONSafe(resource),
    })),
  };

  const isScheduled = rule.analysisType === DetectionTypeEnum.ScheduledRule;
  return (
    <Box mb={6}>
      {updateError && (
        <Box mb={6}>
          <Alert
            variant="error"
            title={
              extractErrorMessage(updateError) ||
              'An unknown error occured as were trying to update your rule'
            }
          />
        </Box>
      )}
      <RuleForm
        initialValues={initialValues}
        onSubmit={handleSubmit}
        isScheduled={isScheduled}
        cacheSessionId={`edit-rule-${rule.id}-${rule.lastModified}`}
        rule={rule}
        isUpdate
      />
    </Box>
  );
};

export default compose(
  withSEO({ title: ({ match }) => `Edit ${match.params.id}` }),
  withRoleRestrictedAccess({
    allowedPermissions: [Permission.RuleModify],
    fallback: <Page403 />,
  })
)(EditRulePage);
