/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

/* A hook for getting access to the context value */
import React from 'react';
import { ModalContext } from 'Components/utils/Modal';

const useModal = () => React.useContext(ModalContext);

export default useModal;
