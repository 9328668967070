/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Field, Form, Formik } from 'formik';
import SubmitButton from 'Components/buttons/SubmitButton';
import { Flex, SimpleGrid, Box, useSnackbar } from 'pouncejs';
import FormikTextInput from 'Components/fields/TextInput';
import * as Yup from 'yup';
import { isSamlUser } from 'Helpers/utils';
import FormikCombobox from 'Components/fields/ComboBox';
import { useListUsersRolesSaml } from 'Pages/Users';
import useSamlRole from 'Hooks/useSamlRole';

export interface UserFormValues {
  id?: string; // optional value
  email: string;
  familyName: string;
  givenName: string;
  role?: {
    id: string | null;
    name: string;
  };
}
export interface UserFormProps {
  /** The initial values of the form */
  initialValues: UserFormValues;
  /** callback for the submission of the form */
  onSubmit: (values: UserFormValues) => void;
}

const UserForm: React.FC<UserFormProps> = ({ initialValues, onSubmit }) => {
  const { pushSnackbar } = useSnackbar();

  const { data } = useListUsersRolesSaml({
    onError: () => pushSnackbar({ variant: 'error', title: "Couldn't fetch needed data" }),
  });
  const samlRole = useSamlRole();
  // When we edit a user we want to exclude its email from the validation schema
  // When we create a user, initialValues.id is undefined and no user is filtered
  // We shall also remove any emails related with SAML as users with SAML email can be twice in the list
  const existingUsers = data.users.filter(
    user => user.id !== initialValues.id && !isSamlUser(user)
  );

  const usersEmails = existingUsers.map(u => u.email);

  const isComingFromSaml = isSamlUser(initialValues);

  const emailValidation = Yup.string().email('Must be a valid email').required('Email is required');

  // For SAML users we don't need to validate the email since users can't edit them
  // This one causes the
  if (!isComingFromSaml) {
    emailValidation.notOneOf(usersEmails, 'Email already in use');
  }

  const validationSchema = Yup.object().shape({
    email: emailValidation,
    familyName: isComingFromSaml ? Yup.string() : Yup.string().required('Last name is required'),
    givenName: isComingFromSaml ? Yup.string() : Yup.string().required('First name is required'),
    role: Yup.object().shape({
      id: isComingFromSaml ? Yup.string().nullable() : Yup.string().required(),
      name: Yup.string().required(),
    }),
  });

  const availableRoles = React.useMemo(() => {
    const roles = data.roles || [];
    // Add SAML option for SAML related users.
    // An empty related string identifies SAML user's roleId
    return isComingFromSaml
      ? [...roles, samlRole].sort((prev, curr) => {
          return prev.name.localeCompare(curr.name);
        })
      : roles;
  }, [data, isComingFromSaml, samlRole]);

  return (
    <Formik<UserFormValues>
      initialValues={initialValues}
      onSubmit={onSubmit}
      enableReinitialize
      validationSchema={validationSchema}
    >
      <Form data-tid="user-form">
        <Flex direction="column" spacing={4}>
          <Field
            data-tid="user-form-email"
            as={FormikTextInput}
            label="Email address"
            placeholder="john@doe.com"
            disabled={isComingFromSaml}
            name="email"
            required
          />
          <SimpleGrid columns={2} spacing={4}>
            <Field
              data-tid="user-form-first-name"
              as={FormikTextInput}
              label="First Name"
              disabled={isComingFromSaml}
              placeholder="John"
              name="givenName"
              required
            />
            <Field
              data-tid="user-form-last-name"
              as={FormikTextInput}
              label="Last Name"
              disabled={isComingFromSaml}
              placeholder="Doe"
              name="familyName"
              required
            />
          </SimpleGrid>
          <Box as="fieldset">
            <Field
              data-tid="user-form-role"
              name="role"
              as={FormikCombobox}
              label="Role"
              itemToString={role => role.name}
              items={availableRoles || []}
              placeholder="Select a role for this user..."
              aria-describedby="role-helper-text"
              showClearSelectionControl={false}
            />
          </Box>
          <SubmitButton fullWidth data-tid={`${initialValues.id ? 'updated' : 'invited'}-user`}>
            {initialValues.id ? 'Update' : 'Invite'}
          </SubmitButton>
        </Flex>
      </Form>
    </Formik>
  );
};

export default UserForm;
