/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { compose } from 'Helpers/compose';
import withSEO from 'Hoc/withSEO';
import withRoleRestrictedAccess from 'Hoc/withRoleRestrictedAccess';
import Page403 from 'Pages/403';
import { Permission } from 'Generated/schema';
import { useListLookups } from 'Source/graphql/queries/listLookups.generated';
import { DEFAULT_LARGE_PAGE_SIZE } from 'Source/constants';
import useInfiniteScroll from 'Hooks/useInfiniteScroll';
import ListLookupsPageSkeleton from 'Pages/Enrichment/components/ListLookupsSkeleton';
import ErrorBoundary from 'Components/ErrorBoundary';
import { Box, Alert, Flex, Card } from 'pouncejs';
import { extractErrorMessage } from 'Helpers/utils';
import Panel from 'Components/Panel';
import LookupCard from 'Pages/Enrichment/components/LookupCard';
import NoResultsFound from 'Components/NoResultsFound';
import TablePlaceholder from 'Components/TablePlaceholder';
import BetaTag from 'Components/BetaTag';

/**
 * Lists all enrichment sources that are available out-of-the-box with Panther.
 *
 * Under the hood, an "enrichment source" is the same thing as a lookup table. The key
 * distinction is that what we call a "lookup table" in the UI is configured entirely by
 * the customer and an "enrichment source" is set up by Panther.
 */
const ListEnrichmentSources = () => {
  const { loading, previousData, data = previousData, error, fetchMore } = useListLookups({
    variables: {
      input: {
        pageSize: DEFAULT_LARGE_PAGE_SIZE,
        managed: true,
      },
    },
  });

  const lookupsItems = data?.listLookups.lookups || [];
  const lastEvaluatedKey = data?.listLookups.lastEvaluatedKey || null;
  const hasNextPage = !!lastEvaluatedKey;

  const { sentinelRef } = useInfiniteScroll<HTMLDivElement>({
    loading,
    onLoadMore: () => {
      fetchMore({
        variables: {
          input: {
            managed: true,
            pageSize: DEFAULT_LARGE_PAGE_SIZE,
            exclusiveStartKey: lastEvaluatedKey,
          },
        },
      });
    },
  });

  if (loading && !data) {
    return <ListLookupsPageSkeleton title="Enrichment Sources" />;
  }

  const hasError = Boolean(error);

  return (
    <ErrorBoundary>
      {hasError && (
        <Box mb={6}>
          <Alert
            variant="error"
            title="Couldn't load enrichment sources"
            description={
              extractErrorMessage(error) ||
              'There was an error when performing your request, please contact support@runpanther.io'
            }
          />
        </Box>
      )}

      <Panel
        title={
          <Flex>
            Enrichment Sources
            <Box ml="2">
              <BetaTag variant="border" />
            </Box>
          </Flex>
        }
      >
        <Card as="section" position="relative">
          <Flex direction="column" spacing={2}>
            {lookupsItems.length ? (
              lookupsItems.map(lookup => <LookupCard key={lookup.id} lookup={lookup} />)
            ) : (
              <Box my={8}>
                <NoResultsFound title="No Lookups found" />
              </Box>
            )}

            {hasNextPage && (
              <Box ref={sentinelRef}>
                <TablePlaceholder rowCount={5} rowHeight={30} />
              </Box>
            )}
          </Flex>
        </Card>
      </Panel>
    </ErrorBoundary>
  );
};

export default compose(
  withSEO({ title: 'Enrichment Sources' }),
  withRoleRestrictedAccess({
    allowedPermissions: [Permission.LookupRead, Permission.LookupModify],
    fallback: <Page403 />,
  }),
  React.memo
)(ListEnrichmentSources);
