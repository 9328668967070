/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { useSelect } from 'Components/utils/SelectContext/SelectContext';
import { Checkbox } from 'pouncejs';

interface SelectAllCheckboxProps<T> {
  selectionItems: T[];
}

function SelectAllCheckboxComponent<T>({ selectionItems, ...rest }: SelectAllCheckboxProps<T>) {
  const { selection, resetSelection, selectAll } = useSelect<T>();
  return (
    <Checkbox
      checked={!!selection.length}
      onChange={() => {
        if (selection.length) {
          resetSelection();
        } else {
          selectAll(selectionItems);
        }
      }}
      aria-label={selection.length ? 'unselect all' : 'select all'}
      {...rest}
    />
  );
}

export default React.memo(SelectAllCheckboxComponent);
