/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import * as Types from '../../../../__generated__/schema';

import { PolicyDetails } from '../../../graphql/fragments/PolicyDetails.generated';
import { GraphQLError } from 'graphql';
import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';

export type GetPolicyDetailsVariables = {
  input: Types.GetPolicyInput;
};

export type GetPolicyDetails = { policy?: Types.Maybe<PolicyDetails> };

export const GetPolicyDetailsDocument = gql`
  query GetPolicyDetails($input: GetPolicyInput!) {
    policy(input: $input) {
      ...PolicyDetails
    }
  }
  ${PolicyDetails}
`;

/**
 * __useGetPolicyDetails__
 *
 * To run a query within a React component, call `useGetPolicyDetails` and pass it any options that fit your needs.
 * When your component renders, `useGetPolicyDetails` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPolicyDetails({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetPolicyDetails(
  baseOptions?: ApolloReactHooks.QueryHookOptions<GetPolicyDetails, GetPolicyDetailsVariables>
) {
  return ApolloReactHooks.useQuery<GetPolicyDetails, GetPolicyDetailsVariables>(
    GetPolicyDetailsDocument,
    baseOptions
  );
}
export function useGetPolicyDetailsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetPolicyDetails, GetPolicyDetailsVariables>
) {
  return ApolloReactHooks.useLazyQuery<GetPolicyDetails, GetPolicyDetailsVariables>(
    GetPolicyDetailsDocument,
    baseOptions
  );
}
export type GetPolicyDetailsHookResult = ReturnType<typeof useGetPolicyDetails>;
export type GetPolicyDetailsLazyQueryHookResult = ReturnType<typeof useGetPolicyDetailsLazyQuery>;
export type GetPolicyDetailsQueryResult = ApolloReactCommon.QueryResult<
  GetPolicyDetails,
  GetPolicyDetailsVariables
>;
export function mockGetPolicyDetails({
  data,
  variables,
  errors,
}: {
  data: GetPolicyDetails;
  variables?: GetPolicyDetailsVariables;
  errors?: GraphQLError[];
}) {
  return {
    request: { query: GetPolicyDetailsDocument, variables },
    result: { data, errors },
  };
}
