/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { useSnackbar } from 'pouncejs';
import ConfirmModal from 'Components/modals/ConfirmModal';
import { RoleDetails } from 'Source/graphql/fragments/RoleDetails.generated';
import { useDeleteRole } from './graphql/deleteRole.generated';

export interface DeleteRoleModalProps {
  role: RoleDetails;
}

const DeleteRoleModal: React.FC<DeleteRoleModalProps> = ({ role }) => {
  const { pushSnackbar } = useSnackbar();
  const [deleteRole] = useDeleteRole({
    variables: {
      id: role.id,
    },
    update: cache => {
      cache.evict({ id: cache.identify(role) });
      cache.gc();
    },
    onCompleted: async () => {
      pushSnackbar({
        variant: 'success',
        title: `Successfully deleted ${role.name}`,
      });
    },
    onError: () => {
      pushSnackbar({
        variant: 'error',
        title: 'Failed to delete role',
      });
    },
  });

  return (
    <ConfirmModal
      onConfirm={deleteRole}
      variant="destructive"
      subtitle={[`Are you sure you want to delete role `, <b key={0}>{role.name}?</b>]}
    />
  );
};

export default DeleteRoleModal;
