/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { FadeIn, FadeInProps } from 'pouncejs';

type FadeInTrailProps = Omit<FadeInProps, 'delay'>;

const FadeInTrail: React.FC<FadeInTrailProps> = ({ children, ...rest }) => {
  return (
    <React.Fragment>
      {React.Children.map(children, (child, index) => (
        <FadeIn delay={30 * index} {...rest}>
          {child}
        </FadeIn>
      ))}
    </React.Fragment>
  );
};

export default FadeInTrail;
