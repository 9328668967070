/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import * as Types from '../../../__generated__/schema';

import { GraphQLError } from 'graphql';
import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';

export type ListAvailablePacksVariables = {};

export type ListAvailablePacks = {
  listAnalysisPacks: { packs: Array<Pick<Types.AnalysisPack, 'id'>> };
};

export const ListAvailablePacksDocument = gql`
  query ListAvailablePacks {
    listAnalysisPacks(input: { pageSize: 999 }) {
      packs {
        id
      }
    }
  }
`;

/**
 * __useListAvailablePacks__
 *
 * To run a query within a React component, call `useListAvailablePacks` and pass it any options that fit your needs.
 * When your component renders, `useListAvailablePacks` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListAvailablePacks({
 *   variables: {
 *   },
 * });
 */
export function useListAvailablePacks(
  baseOptions?: ApolloReactHooks.QueryHookOptions<ListAvailablePacks, ListAvailablePacksVariables>
) {
  return ApolloReactHooks.useQuery<ListAvailablePacks, ListAvailablePacksVariables>(
    ListAvailablePacksDocument,
    baseOptions
  );
}
export function useListAvailablePacksLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    ListAvailablePacks,
    ListAvailablePacksVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<ListAvailablePacks, ListAvailablePacksVariables>(
    ListAvailablePacksDocument,
    baseOptions
  );
}
export type ListAvailablePacksHookResult = ReturnType<typeof useListAvailablePacks>;
export type ListAvailablePacksLazyQueryHookResult = ReturnType<
  typeof useListAvailablePacksLazyQuery
>;
export type ListAvailablePacksQueryResult = ApolloReactCommon.QueryResult<
  ListAvailablePacks,
  ListAvailablePacksVariables
>;
export function mockListAvailablePacks({
  data,
  variables,
  errors,
}: {
  data: ListAvailablePacks;
  variables?: ListAvailablePacksVariables;
  errors?: GraphQLError[];
}) {
  return {
    request: { query: ListAvailablePacksDocument, variables },
    result: { data, errors },
  };
}
