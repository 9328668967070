/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { FadeIn, Flex, Grid, IconButton, Tooltip } from 'pouncejs';
import useHover from 'Hooks/useHover';
import useCopyToClipboard from 'Hooks/useCopyToClipboard';
import MetricsBar from 'Components/MetricsBar';
import useIndicatorSearchModal from 'Hooks/useIndicatorSearchModal';

interface AttributeBarProps {
  /** The title of each bar */
  title: string;

  /** The value displayed next to the bar */
  value: number;

  /** A number between [0,1] specifying the width percentage that the bar will occupy relative to
   * its container
   */
  percentage: number;
  /**
   * Flag indicating whether the copy action is displayed
   */
  withCopyAction?: boolean;
  /**
   * Flag that controls the indicator search pivoting action
   */
  withIndicatorSearchPivotingAction?: boolean;
}

const AttributeBar: React.FC<AttributeBarProps> = ({
  title,
  value,
  percentage,
  withCopyAction,
  withIndicatorSearchPivotingAction,
}) => {
  const { isHovering, handlers: hoverHandlers } = useHover();
  const copyToClipboard = useCopyToClipboard();
  const showIndicatorSearchModal = useIndicatorSearchModal();

  return (
    <Grid
      templateColumns="3fr 1fr"
      data-testid="attribute-bar"
      px={4}
      transition="background-color 0.2s ease-in-out"
      backgroundColor={isHovering ? 'navyblue-400' : undefined}
      {...hoverHandlers}
    >
      <MetricsBar label={title} value={value} percentage={percentage} showLabel />
      <Flex align="center" ml="auto" mr={0}>
        {isHovering && (
          <FadeIn from="left" offset={5} duration={300}>
            <Flex spacing={1}>
              {withCopyAction && (
                <Tooltip content="Copy">
                  <IconButton
                    variant="ghost"
                    variantColor="navyblue-300"
                    size="medium"
                    icon="copy"
                    onClick={() => copyToClipboard(title)}
                    aria-label="Copy attribute value"
                  />
                </Tooltip>
              )}
              {withIndicatorSearchPivotingAction && (
                <Tooltip content="Indicator Search">
                  <IconButton
                    variant="ghost"
                    variantColor="navyblue-300"
                    size="medium"
                    icon="search"
                    onClick={() => showIndicatorSearchModal({ indicator: title })}
                    aria-label="Indicator Search"
                  />
                </Tooltip>
              )}
            </Flex>
          </FadeIn>
        )}
      </Flex>
    </Grid>
  );
};

export default React.memo(AttributeBar);
