/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { WizardPanel } from 'Components/Wizard';
import { useFormikContext, Field } from 'formik';
import { Box, Heading, SimpleGrid, Text, Link, Button, Flex, useSnackbar } from 'pouncejs';
import { fileToText, base64ZipToBlob, downloadData } from 'Helpers/utils';
import FormikTextInput from 'Components/fields/TextInput';
import EditIntegrationActions from 'Pages/Integrations/components/EditIntegrationActions';
import { GCS_DATA_ONBOARDING_DOC_URL, LogTransportMethodsEnum } from 'Source/constants';
import CodeBlock from 'Components/CodeBlock';
import { useGetTerraformTemplate } from 'Source/graphql/queries/getTerraformTemplate.generated';
import { GcsLogSourceWizardValues } from './GcsLogSourceWizard';
import SubscriptionInformation from './SubscriptionInformation';
import UploadKeyfile from './UploadKeyfile';

const SetupInfrastructure = () => {
  const { initialValues, values, isValid, setFieldValue } = useFormikContext<
    GcsLogSourceWizardValues
  >();
  const { pushSnackbar } = useSnackbar();
  const { data: tfData, loading: tfLoading, error: tfError } = useGetTerraformTemplate({
    variables: {
      input: {
        integrationType: LogTransportMethodsEnum.gcs,
        integrationLabel: values.integrationLabel,
      },
    },
    onError: () =>
      pushSnackbar({ variant: 'error', title: 'Failed to generate Terraform template' }),
  });

  const handleUploadFile = React.useCallback(
    async file => {
      try {
        setFieldValue('jsonKeyfile', file);
        const content = await fileToText(file);
        const data = JSON.parse(content);

        setFieldValue('credentials', content);
        setFieldValue('projectId', data.project_id ?? '');
        setFieldValue('userEmail', data.client_email ?? '');

        if (!data.project_id || !data.client_email) {
          throw new Error('Unsupported file properties');
        }
      } catch (error) {
        // when a file is broken let's reset our file properties
        setFieldValue('credentials', '');
        setFieldValue('projectId', '');
        setFieldValue('userEmail', '');
        throw new Error(error);
      }
    },
    [setFieldValue]
  );

  const editMode = !!initialValues.integrationId;
  const { stackName: tfStackName, data } = tfData?.getTerraformTemplate ?? {};

  return (
    <WizardPanel width={665} mx="auto">
      <WizardPanel.Heading title="Infrastructure &amp; Credentials" />

      <Box as="section" mb={8}>
        <Heading as="h2" color="teal-200" fontSize="x-large" fontWeight="medium" mb={1}>
          1. Create Required Infrastructure Component
        </Heading>

        <Text color="white-100" fontSize="large" lineHeight="relaxed">
          Panther requires some infrastructure on your side, to be able to pull the log data. Please
          perform the following actions:
        </Text>
        <Flex as="ul" mt={5} ml={6} direction="column" spacing={2} sx={{ listStyle: 'disc' }}>
          <Box as="li">Download the terraform template below</Box>
          <Box as="li">
            Fill out the fields in the production.tfvars file with your configuration
          </Box>
          <Box as="li">Initialize a working directory containing Terraform configuration files</Box>
          <Box as="li">Run the provided terraform command</Box>
          <Box as="li">
            Generate a JSON key file by replacing the value for your service account email <br /> in
            the gcloud command below. You can find it in the output of the Terraform run.
          </Box>
        </Flex>
        <Flex align="center" justify="center" mt={5}>
          <Button
            variantColor="teal-500"
            icon="download"
            loading={tfLoading}
            disabled={!!tfError || tfLoading}
            onClick={() => downloadData(base64ZipToBlob(data), `${tfStackName}.zip`)}
          >
            Terraform Template
          </Button>
        </Flex>

        <Text fontSize="small" color="blue-100" mt={5} mb={1}>
          Terraform Command
        </Text>
        <Text color="white-100" fontSize="small" lineHeight="loose">
          Run the
          <Box as="code" mx={1} p={1} backgroundColor="navyblue-600">
            terraform init
          </Box>
          command to initialize a working directory that contains the Terraform configuration files.
        </Text>

        <CodeBlock code='terraform apply -var-file="production.tfvars"' />

        <CodeBlock
          title="gcloud Command"
          code="gcloud iam service-accounts keys create keyfile.json --iam-account=$SERVICE_ACCOUNT_EMAIL"
          my={5}
        />

        <Text color="white-100" fontSize="large" lineHeight="relaxed">
          Alternatively you can read{' '}
          <Link external href={GCS_DATA_ONBOARDING_DOC_URL}>
            our documentation
          </Link>{' '}
          to discover how to do this manually
        </Text>
      </Box>

      <Box as="section" mb={2}>
        <Heading as="h2" color="teal-200" fontSize="x-large" fontWeight="medium">
          2. Provide pulling configuration &amp; JSON Keyfile
        </Heading>
        <Text color="white-100" fontSize="large" lineHeight="relaxed" my={1}>
          Panther will need to be able to authenticate to both Pubsub and Cloud Storage. The JSON
          Keyfile contains the credentials for a given user with all the required permissions.
        </Text>
        <UploadKeyfile uploadedFile={values.jsonKeyfile} onUploadFile={handleUploadFile} />
      </Box>

      {editMode && <SubscriptionInformation data={values} withTitle />}

      <SimpleGrid gap={2} columns={2}>
        <Field
          name="gcsBucket"
          as={FormikTextInput}
          label="GCS Bucket Name"
          placeholder="GCS Bucket Name"
          required
        />

        <Field
          name="subscriptionId"
          as={FormikTextInput}
          label="Pub/Sub Subscription ID"
          placeholder="Pub/Sub Subscription ID"
          required
        />
      </SimpleGrid>
      <WizardPanel.Actions>
        <WizardPanel.ActionPrev />
        {editMode ? (
          <EditIntegrationActions />
        ) : (
          <WizardPanel.ActionNext disabled={!isValid}>Continue Setup</WizardPanel.ActionNext>
        )}
      </WizardPanel.Actions>
    </WizardPanel>
  );
};

export default SetupInfrastructure;
