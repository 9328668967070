/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import * as Types from '../../../../__generated__/schema';

import { GraphQLError } from 'graphql';
import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';

export type GenerateS3SampleDataUploadVariables = {
  input?: Types.Maybe<Types.GenerateS3SampleUploadInput>;
};

export type GenerateS3SampleDataUpload = {
  generateS3SampleDataUpload: Pick<Types.GenerateS3SampleUploadResponse, 'uploadURL' | 'sessionId'>;
};

export const GenerateS3SampleDataUploadDocument = gql`
  mutation GenerateS3SampleDataUpload($input: GenerateS3SampleUploadInput) {
    generateS3SampleDataUpload(input: $input) {
      uploadURL
      sessionId
    }
  }
`;
export type GenerateS3SampleDataUploadMutationFn = ApolloReactCommon.MutationFunction<
  GenerateS3SampleDataUpload,
  GenerateS3SampleDataUploadVariables
>;

/**
 * __useGenerateS3SampleDataUpload__
 *
 * To run a mutation, you first call `useGenerateS3SampleDataUpload` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateS3SampleDataUpload` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateS3SampleDataUpload, { data, loading, error }] = useGenerateS3SampleDataUpload({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGenerateS3SampleDataUpload(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    GenerateS3SampleDataUpload,
    GenerateS3SampleDataUploadVariables
  >
) {
  return ApolloReactHooks.useMutation<
    GenerateS3SampleDataUpload,
    GenerateS3SampleDataUploadVariables
  >(GenerateS3SampleDataUploadDocument, baseOptions);
}
export type GenerateS3SampleDataUploadHookResult = ReturnType<typeof useGenerateS3SampleDataUpload>;
export type GenerateS3SampleDataUploadMutationResult = ApolloReactCommon.MutationResult<
  GenerateS3SampleDataUpload
>;
export type GenerateS3SampleDataUploadMutationOptions = ApolloReactCommon.BaseMutationOptions<
  GenerateS3SampleDataUpload,
  GenerateS3SampleDataUploadVariables
>;
export function mockGenerateS3SampleDataUpload({
  data,
  variables,
  errors,
}: {
  data: GenerateS3SampleDataUpload;
  variables?: GenerateS3SampleDataUploadVariables;
  errors?: GraphQLError[];
}) {
  return {
    request: { query: GenerateS3SampleDataUploadDocument, variables },
    result: { data, errors },
  };
}
