/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Box, Card, Flex, Link } from 'pouncejs';
import { Link as RRLink } from 'react-router-dom';
import urls from 'Source/urls';
import SeverityBadge from 'Components/badges/SeverityBadge';
import NoResultsFound from 'Components/NoResultsFound';
import { GetOrganizationStats } from '../graphql/getOrganizationStats.generated';

interface TopFailingPoliciesProps {
  policies: GetOrganizationStats['organizationStats']['topFailingPolicies'];
}

const TopFailingPolicies: React.FC<TopFailingPoliciesProps> = ({ policies }) => {
  if (!policies.length) {
    return (
      <Box my={8}>
        <NoResultsFound />
      </Box>
    );
  }

  return (
    <Flex direction="column" spacing={2} data-testid="top-failing-policies">
      {policies.map(policy => (
        <Card variant="dark" key={policy.id}>
          <Flex justify="space-between" spacing={6} p={4}>
            <Link as={RRLink} to={urls.analysis.policies.details(policy.id)} truncated>
              {policy.id}
            </Link>
            <SeverityBadge severity={policy.severity} />
          </Flex>
        </Card>
      ))}
    </Flex>
  );
};

export default TopFailingPolicies;
