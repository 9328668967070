/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Link as RRLink } from 'react-router-dom';
import { Flex, Link, Box, Card } from 'pouncejs';

import { CardValue, CardBulletDivider, CardMetaValue } from 'Components/cards/common';
import BulletedValue from 'Components/BulletedValue';
import { SelectCheckbox } from 'Components/utils/SelectContext';
import FixedWidthBadge from 'Components/FixedWidthBadge';
import { DataModel } from 'Generated/schema';
import { formatDatetime } from 'Helpers/utils';
import urls from 'Source/urls';
import DataModelCardOptions from './DataModelCardOptions';

interface DataModelCardProps {
  dataModel: DataModel;
  selectionEnabled?: boolean;
  isSelected?: boolean;
}

const DataModelCard: React.FC<DataModelCardProps> = ({
  dataModel,
  isSelected = false,
  selectionEnabled = false,
}) => {
  return (
    <Card as="section" variant={isSelected ? 'darker' : 'dark'} p={4}>
      <Flex>
        {selectionEnabled && (
          <Box transform="translate3d(-12px,-12px,0)">
            <SelectCheckbox data-tid="single-select-data-model" selectionItem={dataModel} />
          </Box>
        )}
        <Flex direction="column" justify="space-between" width={1}>
          <Flex as="header" align="flex-start" mb={2}>
            <Box as="h4" fontWeight="medium" mr="auto" maxWidth="70%" wordBreak="break-word">
              <Link
                as={RRLink}
                data-tid="link-to-data-model"
                to={urls.data.dataModels.details(dataModel.id)}
              >
                {dataModel.displayName || dataModel.id}
              </Link>
            </Box>
            <CardMetaValue label="ID" value={dataModel.id} />
            <CardBulletDivider />
            <CardMetaValue label="Updated" value={formatDatetime(dataModel.lastModified)} />
            <Box mt={-1} ml={2}>
              <DataModelCardOptions dataModel={dataModel} />
            </Box>
          </Flex>

          <Flex justify="space-between">
            <CardValue label="Log Type" value={<BulletedValue value={dataModel.logTypes[0]} />} />
            <Flex align="flex-end" spacing={4}>
              <FixedWidthBadge width={75} color={dataModel.enabled ? 'cyan-400' : 'navyblue-300'}>
                {dataModel.enabled ? 'ENABLED' : 'DISABLED'}
              </FixedWidthBadge>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Card>
  );
};

export default React.memo(DataModelCard);
