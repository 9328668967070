/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import { AssociatedLogType, UpdateLookupInput } from 'Generated/schema';
import * as Yup from 'yup';
import {
  AWS_ACCOUNT_ID_REGEX,
  S3_OBJECT_PATH_REGEX,
  ROLE_ARN_REGEX,
  NON_HTML_ENTITY_REGEX,
} from 'Source/constants';
import { getArnRegexForService } from 'Helpers/utils';

export interface LookupFormValues {
  id?: string;
  name: string;
  description: string;
  reference: string;
  enabled: boolean;
  associatedLogTypes: Omit<AssociatedLogType, '__typename'>[];
  schemaName: string;
  primaryKey: string;
  uploadMethod?: 's3' | 'file';
  // s3-specific fields
  awsAccountId?: string;
  s3FileLocation?: string;
  updatePeriod?: number;
  kmsKey?: string;
  alarmThreshold?: number;
  iamRoleArn?: string;
}

export const LOOKUP_WIZARD_WIDTH = '80%';
export const EMPTY_LOG_TYPE_MAPPING = { logType: null, selectors: [] };

export const lookupFormValidationSchema: Yup.SchemaOf<LookupFormValues> = Yup.object().shape({
  // Basic info tab
  id: Yup.string(),
  name: Yup.string()
    .required()
    .min(1, 'Lookup Table names must contain at least 1 character')
    .max(100, 'Lookup Table name exceeds 100 characters')
    .matches(
      NON_HTML_ENTITY_REGEX,
      'Lookup Table names cannot contain any of these characters: <>&"'
    ),
  description: Yup.string(),
  reference: Yup.string(),
  enabled: Yup.boolean().required(),
  // Associated log types tab
  associatedLogTypes: Yup.array<LookupFormValues['associatedLogTypes']>()
    .of(
      Yup.object().shape(
        {
          logType: Yup.string().required('You must select a log type'),
          selectors: Yup.array().when('logType', {
            is: logType => !!logType,
            then: Yup.array()
              .of(Yup.string().required())
              .required()
              .min(1, 'You need to add at least 1 selector'),
            otherwise: Yup.array(),
          }),
        },
        [['logType', 'selectors']]
      )
    )
    .required(),
  // Table schema tab
  schemaName: Yup.string().required('You need to assign a schema'),
  primaryKey: Yup.string().required('You need to designate the primary key of the schema'),
  uploadMethod: Yup.string().required().oneOf(['s3', 'file']),
  awsAccountId: Yup.string().when('uploadMethod', {
    is: 's3',
    then: Yup.string()
      .matches(AWS_ACCOUNT_ID_REGEX, 'Must be a valid AWS Account ID')
      .required('You need to specify an AWS Account ID'),
    otherwise: Yup.string(),
  }),
  s3FileLocation: Yup.string().when('uploadMethod', {
    is: 's3',
    then: Yup.string()
      .matches(S3_OBJECT_PATH_REGEX, 'Must be a valid S3 object path')
      .required('You need to specify a file location'),
    otherwise: Yup.string(),
  }),
  kmsKey: Yup.string().matches(getArnRegexForService('KMS'), 'Must be a valid KMS ARN'),
  updatePeriod: Yup.number().when('uploadMethod', {
    is: 's3',
    then: Yup.number().required('You need to specify an update period'),
    otherwise: Yup.number(),
  }),
  iamRoleArn: Yup.string().when('uploadMethod', {
    is: 's3',
    then: Yup.string()
      .matches(
        ROLE_ARN_REGEX,
        'Must be a valid IAM ARN i.e. arn:aws:iam::{ACCOUNT_ID}:role/{ROLENAME}'
      )
      .required('You need to specify an IAM Role ARN'),
    otherwise: Yup.string(),
  }),
  alarmThreshold: Yup.number().min(0, 'Alarm threshold cannot be negative'),
});

export const getLookupUpdatePayload = (values: LookupFormValues) => {
  // If the lookup receives data via manual file uploads, the refresh value
  // is sent up to GraphQL as `null` to remove existing refresh settings.
  let refresh: UpdateLookupInput['refresh'] = null;
  if (values.uploadMethod === 's3') {
    refresh = {
      periodMinutes: values.updatePeriod,
      alarmPeriodMinutes: values.alarmThreshold,
      objectPath: values.s3FileLocation,
      objectKMSKey: values.kmsKey,
      roleARN: values.iamRoleArn,
    };
  }

  const payload: Required<UpdateLookupInput> = {
    id: values.id,
    name: values.name,
    description: values.description,
    enabled: values.enabled,
    reference: values.reference,
    logTypeMap: {
      primaryKey: values.primaryKey,
      associatedLogTypes: values.associatedLogTypes,
    },
    lookupSchema: {
      logType: values.schemaName,
    },
    refresh,
  };

  return payload;
};
