/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import AuthPageContainer from 'Components/AuthPageContainer';
import queryString from 'query-string';
import ForgotPasswordConfirmForm from 'Components/forms/ForgotPasswordConfirmForm';
import useRouter from 'Hooks/useRouter';
import withSEO from 'Hoc/withSEO';
import { FadeIn } from 'pouncejs';

const ForgotPasswordConfirmPage: React.FC = () => {
  const { location } = useRouter();

  // protect against not having the proper parameters in place
  const { email, token } = queryString.parse(location.search) as { email: string; token: string };
  if (!token || !email) {
    return (
      <AuthPageContainer>
        <AuthPageContainer.Content>
          <AuthPageContainer.Caption
            title="Something seems off..."
            subtitle="Are you sure that the URL you followed is valid?"
          />
        </AuthPageContainer.Content>
      </AuthPageContainer>
    );
  }

  return (
    <AuthPageContainer>
      <FadeIn delay={100}>
        <AuthPageContainer.Content>
          <AuthPageContainer.Caption
            title="Alrighty then.."
            subtitle="Let's set you up with a new password."
          />
          <ForgotPasswordConfirmForm email={email} token={token} />
        </AuthPageContainer.Content>
      </FadeIn>
    </AuthPageContainer>
  );
};

export default withSEO({ title: 'Reset Password' })(ForgotPasswordConfirmPage);
