/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Field, Form, Formik } from 'formik';
import { Box, Flex, SimpleGrid, Text, Link, Divider } from 'pouncejs';
import * as Yup from 'yup';
import SubmitButton from 'Components/buttons/SubmitButton';
import FormikTextInput from 'Components/fields/TextInput';
import FormikCombobox from 'Components/fields/ComboBox';
import FormikSwitch from 'Components/fields/Switch';
import { SAML_DOC_URL, PANTHER_CONFIG } from 'Source/constants';
import { Permission } from 'Generated/schema';
import { useListRoles } from 'Components/forms/UserForm/graphql/listRoles.generated';
import DisabledContainer from 'Components/DisabledContainer';
import SettingEntry from 'Components/SettingEntry';
import useCheckPermissions from 'Hooks/useCheckPermissions';

interface CompanySamlSettingsGenericValues {
  metadataUrl?: string;
  enabled: boolean;
}

interface CompanySamlSettingsDefaultValues extends CompanySamlSettingsGenericValues {
  defaultRoleId?: string;
}

interface CompanySamlSettingsFormValues extends CompanySamlSettingsGenericValues {
  role?: {
    id: string | null;
    name: string;
  };
}

interface CompanySamlSettingsFormProps {
  initialValues: CompanySamlSettingsDefaultValues;
  onSubmit: (values: CompanySamlSettingsFormValues) => Promise<any>;
}

const validationSchema = Yup.object({
  defaultRoleId: Yup.string(),
  metadataUrl: Yup.string().url(),
  enabled: Yup.boolean().required(),
});

const roleItemToString = role => {
  if (!role) {
    return '';
  }
  return role.name;
};

export const CompanySamlSettingsForm: React.FC<CompanySamlSettingsFormProps> = ({
  initialValues,
  onSubmit,
}) => {
  const { defaultRoleId, ...rest } = initialValues;

  // Consume the same permissions as the General settings page
  const userIsAllowedToModify = useCheckPermissions(Permission.GeneralSettingsModify);

  const { data: roleData } = useListRoles();

  const activeRole = roleData?.roles.find(({ id }) => id === defaultRoleId) || null;

  const parseOnSubmit = React.useCallback(values => {
    const { role, ...restForSumbission } = values;
    return onSubmit({ defaultRoleId: role?.id, ...restForSumbission });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const formValues = { ...rest, role: activeRole };

  return (
    <Formik<CompanySamlSettingsFormValues>
      enableReinitialize
      validationSchema={validationSchema}
      initialValues={formValues}
      onSubmit={parseOnSubmit}
    >
      {({ values }) => (
        <Form data-tid="saml-form">
          <Box as="section">
            <Box width={0.5} mb={6} px={2}>
              <Field
                data-tid="saml-form-enabled"
                as={FormikSwitch}
                aria-label="Enable SAML"
                name="enabled"
                label="Enable SAML (Security Assertion Markup Language)"
                disabled={!userIsAllowedToModify}
              />
            </Box>
            <DisabledContainer disabled={!values.enabled}>
              <SimpleGrid columns={2} spacing={4}>
                <Flex direction="column" spacing={4}>
                  <Text as="p" color="navyblue-100">
                    Define Role and Identity Provider
                  </Text>
                  <Field
                    data-tid="saml-form-role"
                    name="role"
                    as={FormikCombobox}
                    label="Default Role"
                    itemToString={roleItemToString}
                    items={roleData?.roles || []}
                    placeholder="Select a default role for SAML users..."
                    aria-describedby="role-helper-text"
                    disabled={!userIsAllowedToModify}
                    showClearSelectionControl={false}
                  />
                  <Field
                    data-tid="saml-form-metadata-url"
                    as={FormikTextInput}
                    name="metadataUrl"
                    label="Identity provider URL"
                    disabled={!userIsAllowedToModify}
                    placeholder="Issuer/Metadata URL"
                    required
                  />
                </Flex>
                <Flex direction="column">
                  <Text mb={2} color="navyblue-100">
                    Copy these two values and paste them into your identity provider:
                  </Text>
                  <Divider width="100%" color="navyblue-300" />
                  <SettingEntry
                    label={'Audience'}
                    value={`urn:amazon:cognito:sp:${PANTHER_CONFIG.WEB_APPLICATION_USER_POOL_ID}`}
                  />
                  <Divider width="100%" color="navyblue-300" />
                  <SettingEntry
                    label={'ACS Consumer URL'}
                    value={`https://${PANTHER_CONFIG.WEB_APPLICATION_USER_POOL_HOST}/saml2/idpresponse`}
                  />
                </Flex>
              </SimpleGrid>
            </DisabledContainer>
          </Box>
          <Divider width="100%" color="navyblue-300" />
          {userIsAllowedToModify && (
            <Flex pt={4} align="center">
              <Text fontSize="small-medium">
                Follow{' '}
                <Link mt={2} external textDecoration="underline" href={SAML_DOC_URL}>
                  our guide
                </Link>{' '}
                to learn more.
              </Text>
              <Box m="auto" ml="25%">
                <SubmitButton data-tid="saml-form-save">Save Changes</SubmitButton>
              </Box>
            </Flex>
          )}
        </Form>
      )}
    </Formik>
  );
};

export default CompanySamlSettingsForm;
