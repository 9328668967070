/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Box, Flex, Icon, FadeIn } from 'pouncejs';
import { Link as RRLink } from 'react-router-dom';
import { slugify } from 'Helpers/utils';
import useHover from 'Hooks/useHover';
import urls from 'Source/urls';
import AvailableLogProviderLogo, {
  AvailableLogProviderLogoProps,
} from '../../AvailableLogProviderLogo';

interface AvailableLogProviderCardProps {
  title: string;
  id: string;
}

const AvailableLogProviderCard = ({ title, id }: AvailableLogProviderCardProps) => {
  const { isHovering, handlers: hoverHandlers } = useHover();
  const titleId = slugify(title);

  return (
    <RRLink
      to={`${urls.integrations.logSources.create()}?logProviderId=${id}&displayName=${title}`}
      aria-label={`Onboard your ${title} logs`}
    >
      <Flex
        {...hoverHandlers}
        alignItems="center"
        py={3}
        px={3}
        border="1px solid"
        borderRadius="medium"
        transition="all 0.15s ease-in-out"
        backgroundColor={isHovering ? 'navyblue-500' : 'navyblue-400'}
        borderColor={isHovering ? 'navyblue-500' : 'navyblue-400'}
        _focus={{ backgroundColor: 'navyblue-500', borderColor: 'navyblue-500' }}
        overflow="hidden"
        position="relative"
      >
        <AvailableLogProviderLogo
          aria-labelledby={titleId}
          id={id as AvailableLogProviderLogoProps['id']}
        />
        <Box id={titleId} px={3} textAlign="left">
          {title}
        </Box>
        <Box ml="auto" mr={2}>
          {isHovering && (
            <FadeIn from="left" offset={3}>
              <Icon size="medium" type="arrow-forward" />
            </FadeIn>
          )}
        </Box>
      </Flex>
    </RRLink>
  );
};

export default React.memo(AvailableLogProviderCard);
