/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Badge, Box, Table } from 'pouncejs';
import dayjs from 'dayjs';
import RoleRestrictedAccess from 'Components/utils/RoleRestrictedAccess';
import { Permission } from 'Generated/schema';
import useUserRole from 'Hooks/useUserRole';
import { getActorDisplayName } from 'Helpers/utils';
import ListUsersTableRowOptions from './ListUsersTableRowOptions';
import { ListUsersRolesSaml } from '../graphql/listUsersRolesSaml.generated';

type ListUsersTableProps = Pick<ListUsersRolesSaml, 'users'>;

const ListUsersTable: React.FC<ListUsersTableProps> = ({ users }) => {
  const { getRole } = useUserRole();
  return (
    <Table>
      <Table.Head>
        <Table.Row>
          <Table.HeaderCell>Name</Table.HeaderCell>
          <Table.HeaderCell>Email</Table.HeaderCell>
          <Table.HeaderCell>Role</Table.HeaderCell>
          <Table.HeaderCell>Invited At</Table.HeaderCell>
          <Table.HeaderCell align="center">Status</Table.HeaderCell>
          <RoleRestrictedAccess allowedPermissions={[Permission.UserModify]}>
            <Table.HeaderCell />
          </RoleRestrictedAccess>
        </Table.Row>
      </Table.Head>
      <Table.Body>
        {users.map(user => (
          <Table.Row key={user.id}>
            <Table.Cell>{getActorDisplayName(user)}</Table.Cell>
            <Table.Cell>{user.email}</Table.Cell>
            <Table.Cell>{getRole(user).name}</Table.Cell>
            <Table.Cell>
              {dayjs(user.createdAt * 1000).format('MM/DD/YYYY, HH:mm G[M]TZZ')}
            </Table.Cell>
            <Table.Cell align="center">
              <Box my={-1} display="inline-block">
                <Badge color="blue-300">{user.status.replace(/_/g, ' ')}</Badge>
              </Box>
            </Table.Cell>
            <RoleRestrictedAccess allowedPermissions={[Permission.UserModify]}>
              <Table.Cell>
                <Box my={-2}>
                  <ListUsersTableRowOptions user={user} />
                </Box>
              </Table.Cell>
            </RoleRestrictedAccess>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  );
};

export default React.memo(ListUsersTable);
