/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { formatBytes } from 'Helpers/utils';
import { Box, Flex } from 'pouncejs';

interface DataScannedProps {
  bytes: number;
  label?: string;
}

const defaultProps = {
  label: 'Data Scanned',
};

const DataScanned: React.FC<DataScannedProps> = ({ bytes: rawBytes, label }) => {
  const [value, suffix] = formatBytes(rawBytes).split(' ');

  const defaultedLabel = label || defaultProps.label;

  return (
    <Flex as="dl" align="center" fontSize="small" px={4} py={2} spacing={2}>
      <Box as="dt">{defaultedLabel}</Box>
      <Flex as="dd" align="center">
        <Box as="b" fontSize="2x-large" color="magenta-200" mr="2px">
          {value}
        </Box>
        <Box>{suffix}</Box>
      </Flex>
    </Flex>
  );
};

export default DataScanned;
