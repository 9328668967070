/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Alert, Box, FormHelperText, SimpleGrid, useSnackbar } from 'pouncejs';
import SavedQueryForm, {
  SaveQueryFormValues,
} from 'Components/forms/SavedQueryForm/SavedQueryForm';
import useModal from 'Hooks/useModal';
import useUrlParams from 'Hooks/useUrlParams';
import { ListAvailableScheduledQueriesDocument } from 'Source/graphql/queries';
import { extractErrorMessage, formatCronExpression, splitCronValues } from 'Helpers/utils';
import { LoadAllSchemaEntitiesDocument } from 'Pages/DataExplorer/SQLEditor/graphql/loadAllSchemaEntities.generated';
import { SavedQueryDetails } from 'Source/graphql/fragments/SavedQueryDetails.generated';
import { useAddSavedQuery } from './graphql/addSavedQuery.generated';

export interface CreateSavedQueryModalProps {
  sqlQuery: string;
  selectedDatabase?: string;
  clonedQuery?: SavedQueryDetails;
}

const [minutes, hours, days, months, weekDays] = splitCronValues();

const defaultInitialValues: SaveQueryFormValues = {
  name: '',
  description: '',
  tags: [],
  isScheduled: false,
  isEnabled: true,
  rateMinutes: 0,
  periodDays: 0,
  periodMinutes: 0,
  timeoutMinutes: 1,
  type: 'period',
  defaultDatabase: null,
  minutes,
  hours,
  days,
  months,
  weekDays,
  schedule: {},
};

const CreateSavedQueryModal: React.FC<CreateSavedQueryModalProps> = ({
  sqlQuery,
  selectedDatabase,
  clonedQuery,
}) => {
  const { updateUrlParams } = useUrlParams();
  const { hideModal } = useModal();
  const { pushSnackbar } = useSnackbar();

  const [addSavedQuery, { loading, error: createError }] = useAddSavedQuery({
    refetchQueries: [
      { query: LoadAllSchemaEntitiesDocument },
      { query: ListAvailableScheduledQueriesDocument },
    ],
    update: (cache, { data }) => {
      cache.modify({
        fields: {
          listSavedQueries: (queryData, { toReference }) => {
            const addedSavedQueryCacheRef = toReference(data.addSavedQuery);
            return queryData?.savedQueries
              ? [addedSavedQueryCacheRef, ...queryData.savedQueries]
              : [addedSavedQueryCacheRef];
          },
        },
      });
    },
    onCompleted: data => {
      updateUrlParams({ savedQueryId: data.addSavedQuery.id });
      hideModal();
      pushSnackbar({
        variant: 'success',
        title: 'Query was saved successfully',
      });
    },
    onError: () => null,
  });

  const initialValues = React.useMemo(() => {
    if (clonedQuery) {
      const { name, description, tags, defaultDatabase, schedule, id } = clonedQuery;
      return {
        ...defaultInitialValues,
        name,
        description,
        sqlQuery,
        tags,
        defaultDatabase,
        schedule,
        parentId: id,
      };
    }
    return { ...defaultInitialValues, defaultDatabase: selectedDatabase || '' };
  }, [clonedQuery, selectedDatabase, sqlQuery]);

  const onSubmit = React.useCallback(
    (formValues: SaveQueryFormValues) => {
      return addSavedQuery({
        variables: {
          input: {
            name: formValues.name,
            description: formValues.description,
            tags: formValues.tags,
            sqlQuery,
            defaultDatabase: formValues.defaultDatabase || null,
            parentId: formValues.parentId,
            schedule: formValues.isScheduled
              ? {
                  timeout: formValues.timeoutMinutes,
                  disabled: !formValues.isEnabled,
                  ...(formValues.type === 'period'
                    ? {
                        rateMinutes:
                          (formValues.periodDays || 0) * 1440 + (formValues.periodMinutes || 0),
                      }
                    : {
                        cronExpression: formatCronExpression(formValues),
                      }),
                }
              : null,
          },
        },
      });
    },
    // FIXME: look into hook dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [sqlQuery]
  );
  return (
    <SimpleGrid spacing={4} width={720}>
      {createError && (
        <Box mb={4}>
          <Alert
            variant="error"
            title="Unable to create Saved Query"
            description={extractErrorMessage(createError) || 'An unknown error occurred'}
          />
        </Box>
      )}
      <SavedQueryForm onSubmit={onSubmit} initialValues={initialValues} />
      {loading && (
        <FormHelperText id="delay-explanation-helper" textAlign="center">
          This can take up to 20 seconds.
        </FormHelperText>
      )}
    </SimpleGrid>
  );
};

export default CreateSavedQueryModal;
