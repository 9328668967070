/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Box, Text } from 'pouncejs';

type DetectionFormListItemProps = {
  title: string;
  text?: string;
  errorMessage: string;
};

const DetectionFormListItem: React.FC<DetectionFormListItemProps> = ({
  title,
  text,
  errorMessage,
}) => {
  return (
    <>
      <DetectionFormListTitle>{title}</DetectionFormListTitle>
      {text ? (
        <DetectionFormListMessage>{text}</DetectionFormListMessage>
      ) : (
        <DetectionFormListErrorMessage>{errorMessage}</DetectionFormListErrorMessage>
      )}
    </>
  );
};

const DetectionFormListTitle: React.FC = ({ children }) => (
  <Box as="dt" color="navyblue-100">
    {children}
  </Box>
);
const DetectionFormListMessage: React.FC = ({ children }) => <Text as="dd">{children}</Text>;

const DetectionFormListErrorMessage: React.FC = ({ children }) => (
  <Text as="dd" color="red-200">
    {children}
  </Text>
);

export default DetectionFormListItem;
