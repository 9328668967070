/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Dropdown, DropdownButton, DropdownItem, DropdownLink, DropdownMenu } from 'pouncejs';
import OptionsButton from 'Components/buttons/OptionsButton';
import { DataSchemaTeaser } from 'Source/graphql/fragments/DataSchemaTeaser.generated';
import urls from 'Source/urls';
import { Link as RRLink } from 'react-router-dom';
import useCloneDataSchema from 'Hooks/useCloneDataSchema';

interface DataSchemaCardOptionsProps {
  dataSchema: DataSchemaTeaser;
}

const DataSchemaCardOptions: React.FC<DataSchemaCardOptionsProps> = ({ dataSchema }) => {
  const { cloneSchema, isCloning } = useCloneDataSchema();

  const isUserDefined = !dataSchema?.managed;
  return (
    <Dropdown>
      <DropdownButton as={OptionsButton} data-tid="data-schema-options-dropdown" />
      <DropdownMenu minWidth="150px">
        <DropdownItem onSelect={() => cloneSchema(dataSchema.name)}>
          {isCloning ? 'Cloning...' : 'Clone and edit'}
        </DropdownItem>
        {isUserDefined && (
          <React.Fragment>
            <DropdownLink as={RRLink} to={urls.data.schemas.edit(dataSchema.name)}>
              Edit
            </DropdownLink>
          </React.Fragment>
        )}
      </DropdownMenu>
    </Dropdown>
  );
};

export default React.memo(DataSchemaCardOptions);
