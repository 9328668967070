/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Box, Flex } from 'pouncejs';
import m365Logo from 'Assets/m365-minimal-logo.svg';
import ErrorBoundary from 'Components/ErrorBoundary';
import { FastField, Field, useFormikContext } from 'formik';
import FormikTextInput from 'Components/fields/TextInput';
import { WizardPanel } from 'Components/Wizard';
import FormikMultiCombobox from 'Components/fields/MultiComboBox';
import useAvailableLogTypesForLogPuller from 'Hooks/useAvailableLogTypesForLogPuller';
import EditIntegrationActions from 'Pages/Integrations/components/EditIntegrationActions';
import { M365LogsSourceWizardValues } from '../M365LogsSourceWizard';

const ConfigurationPanel: React.FC = () => {
  const { initialValues, errors, values } = useFormikContext<M365LogsSourceWizardValues>();
  const { availableLogTypes } = useAvailableLogTypesForLogPuller();
  const editMode = !!initialValues.integrationId;
  const shouldDisableNextStep =
    !!errors.integrationLabel ||
    !values.integrationLabel ||
    !!errors.logTypes ||
    !values.logTypes.length;

  return (
    <WizardPanel>
      <Box width={500} m="auto">
        <WizardPanel.Heading
          title={editMode ? 'Update Microsoft365 Logs source' : 'Let’s start with the basics'}
          subtitle={
            editMode
              ? 'Feel free to make any changes to your Microsoft365 log source'
              : 'We need to know where to get your logs from'
          }
          logo={m365Logo}
        />
        <ErrorBoundary>
          <Flex direction="column" spacing={4} width={1}>
            <Field
              name="integrationLabel"
              as={FormikTextInput}
              label="Name"
              placeholder="A nickname for your M365 log source"
              required
            />
            <FastField
              as={FormikMultiCombobox}
              searchable
              placeholder="The M365 Log Types you want Panther to process"
              label="Log Types"
              name="logTypes"
              items={availableLogTypes}
              required
              disabled={availableLogTypes.length <= 1}
            />
          </Flex>
        </ErrorBoundary>
      </Box>
      {editMode ? (
        <EditIntegrationActions />
      ) : (
        <WizardPanel.Actions>
          <WizardPanel.ActionNext disabled={shouldDisableNextStep}>
            Continue Setup
          </WizardPanel.ActionNext>
        </WizardPanel.Actions>
      )}
    </WizardPanel>
  );
};

export default ConfigurationPanel;
