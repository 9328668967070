/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import * as Types from '../../../../../../__generated__/schema';

import { LookupImportDataJobDetails } from '../../../../../graphql/fragments/LookupImportDataJobDetails.generated';
import { GraphQLError } from 'graphql';
import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';

export type CheckLookupImportDataJobVariables = {
  jobId: Types.Scalars['ID'];
};

export type CheckLookupImportDataJob = { checkLookupImportDataJob: LookupImportDataJobDetails };

export const CheckLookupImportDataJobDocument = gql`
  query CheckLookupImportDataJob($jobId: ID!) {
    checkLookupImportDataJob(jobId: $jobId) {
      ...LookupImportDataJobDetails
    }
  }
  ${LookupImportDataJobDetails}
`;

/**
 * __useCheckLookupImportDataJob__
 *
 * To run a query within a React component, call `useCheckLookupImportDataJob` and pass it any options that fit your needs.
 * When your component renders, `useCheckLookupImportDataJob` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckLookupImportDataJob({
 *   variables: {
 *      jobId: // value for 'jobId'
 *   },
 * });
 */
export function useCheckLookupImportDataJob(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    CheckLookupImportDataJob,
    CheckLookupImportDataJobVariables
  >
) {
  return ApolloReactHooks.useQuery<CheckLookupImportDataJob, CheckLookupImportDataJobVariables>(
    CheckLookupImportDataJobDocument,
    baseOptions
  );
}
export function useCheckLookupImportDataJobLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    CheckLookupImportDataJob,
    CheckLookupImportDataJobVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<CheckLookupImportDataJob, CheckLookupImportDataJobVariables>(
    CheckLookupImportDataJobDocument,
    baseOptions
  );
}
export type CheckLookupImportDataJobHookResult = ReturnType<typeof useCheckLookupImportDataJob>;
export type CheckLookupImportDataJobLazyQueryHookResult = ReturnType<
  typeof useCheckLookupImportDataJobLazyQuery
>;
export type CheckLookupImportDataJobQueryResult = ApolloReactCommon.QueryResult<
  CheckLookupImportDataJob,
  CheckLookupImportDataJobVariables
>;
export function mockCheckLookupImportDataJob({
  data,
  variables,
  errors,
}: {
  data: CheckLookupImportDataJob;
  variables?: CheckLookupImportDataJobVariables;
  errors?: GraphQLError[];
}) {
  return {
    request: { query: CheckLookupImportDataJobDocument, variables },
    result: { data, errors },
  };
}
