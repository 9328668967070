/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Button, Card, Flex, Img, Link, Heading, Box } from 'pouncejs';
import { WizardPanel } from 'Components/Wizard';
import AuthorizationStatus from 'Assets/statuses/authorization.svg';
import {
  capitalize,
  checkIfSourceIsAuthorized,
  extractErrorMessage,
  generateIntegrationOAuthLink,
} from 'Helpers/utils';
import SuccessStatus from 'Assets/statuses/illustration-success.svg';
import LinkButton from 'Components/buttons/LinkButton';
import urls from 'Source/urls';
import { useGetSourceHealthAndAlarms } from 'Pages/Integrations/LogSources/OAuthAuthorization/graphql/getSourceHealthAndAlarms.generated';
import FailureStatus from 'Assets/statuses/failure.svg';
import { LogPullingIntegration, Permission } from 'Generated/schema';
import { Link as RRLink } from 'react-router-dom';
import useRouter from 'Hooks/useRouter';
import { compose } from 'Helpers/compose';
import withSEO from 'Hoc/withSEO';
import withRoleRestrictedAccess from 'Hoc/withRoleRestrictedAccess';
import Page403 from 'Pages/403/403';
import EventThresholdAlarmConfigurator from 'Components/EventThresholdAlarmConfigurator';
import { LogSourceType } from 'Source/constants';

const OAuthAuthorization: React.FC = () => {
  const {
    location,
    match: {
      params: { id, type },
    },
  } = useRouter<{ id: string; type: LogSourceType }>();
  const { data, loading, error } = useGetSourceHealthAndAlarms({ variables: { id } });

  const renderContent = () => {
    if (loading) {
      return (
        <Flex align="center" direction="column" mx="auto" my={140}>
          <Heading color="navyblue-100">Loading...</Heading>
        </Flex>
      );
    }

    if (error) {
      return (
        <Flex align="center" direction="column" mx="auto">
          <WizardPanel.Heading
            title="Houston we have a problem"
            subtitle={extractErrorMessage(error)}
          />
          <Img nativeWidth={120} nativeHeight={120} alt="Warning" src={FailureStatus} />
          <WizardPanel.Actions>
            <LinkButton to={urls.integrations.logSources.list({ disableDefaultParams: false })}>
              Back to sources
            </LinkButton>
          </WizardPanel.Actions>
        </Flex>
      );
    }

    const source = data.getLogPullingIntegration as LogPullingIntegration;
    const sourceTypeDisplayName = capitalize(type);
    const isSourceAuthorized = checkIfSourceIsAuthorized(source);
    if (isSourceAuthorized) {
      return (
        <Flex align="center" direction="column" mx="auto" width={620}>
          <Box width={400}>
            <WizardPanel.Heading
              title="Successfully Authorized!"
              subtitle={`Panther will now automatically pull & process logs from your ${sourceTypeDisplayName} account`}
            />
          </Box>
          <Img nativeWidth={157} nativeHeight={118} mb={8} alt="Checkmark" src={SuccessStatus} />
          <EventThresholdAlarmConfigurator logSource={source} />
          <WizardPanel.Actions>
            <LinkButton to={urls.integrations.logSources.details(source.integrationId, type)}>
              View Log Source
            </LinkButton>
          </WizardPanel.Actions>
        </Flex>
      );
    }

    return (
      <Flex align="center" direction="column" mx="auto" width={400}>
        <WizardPanel.Heading
          title={`Grant Panther Access to your ${sourceTypeDisplayName}`}
          subtitle={`Panther cannot pull & process logs from your ${sourceTypeDisplayName} account unless you authorize it`}
        />
        <Img
          nativeWidth={120}
          nativeHeight={120}
          alt="Shield with checkmark"
          src={AuthorizationStatus}
        />
        <WizardPanel.Actions>
          <Flex direction="column" spacing={4}>
            <Button
              onClick={() => {
                window.location.assign(
                  generateIntegrationOAuthLink(source.integrationId, location.pathname)
                );
              }}
            >
              Authorize on {sourceTypeDisplayName}
            </Button>
            <Link
              p={1}
              as={RRLink}
              variant="discreet"
              textAlign="center"
              to={urls.integrations.logSources.details(source.integrationId, type)}
            >
              View Log Source
            </Link>
          </Flex>
        </WizardPanel.Actions>
      </Flex>
    );
  };

  return (
    <Card p={6} mb={6} py={40}>
      <WizardPanel>{renderContent()}</WizardPanel>
    </Card>
  );
};

export default compose(
  withSEO({ title: ({ match }) => `Authorize ${capitalize(match.params.type)}` }),
  withRoleRestrictedAccess({
    allowedPermissions: [Permission.LogSourceModify],
    fallback: <Page403 />,
  })
)(OAuthAuthorization);
