/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Box, Card, TabList, TabPanel, TabPanels, Tabs } from 'pouncejs';
import withRoleRestrictedAccess from 'Hoc/withRoleRestrictedAccess';
import { Permission } from 'Generated/schema';
import Page403 from 'Pages/403';
import { compose } from 'Helpers/compose';
import withSEO from 'Hoc/withSEO';
import { BorderedTab, BorderTabDivider } from 'Components/BorderedTab';
import invert from 'lodash/invert';
import useUrlParams from 'Hooks/useUrlParams';
import SettingsFooter from './SettingsFooter';
import CompanyInformationSection from './CompanyInformationSection';
import CompanySamlSettingsSection from './CompanySamlSettingsSection';
import SnowflakeSection from './SnowflakeSection';

interface GeneralSettingsUrlParams {
  section?: 'main' | 'saml' | 'snowflake';
}
const sectionToTabIndex: Record<GeneralSettingsUrlParams['section'], number> = {
  main: 0,
  saml: 1,
  snowflake: 2,
};

// Parent container for the general settings section
const GeneralSettingsPage: React.FC = () => {
  const { urlParams, updateUrlParams } = useUrlParams<GeneralSettingsUrlParams>();

  const tabIndexToSection = invert(sectionToTabIndex) as Record<
    number,
    GeneralSettingsUrlParams['section']
  >;
  return (
    <>
      {/* Need mb={100} cause on small screens the footer goes over the Cards */}
      <Box mb={100}>
        <Card as="article" position="relative">
          <Tabs
            index={sectionToTabIndex[urlParams.section] || 0}
            onChange={index => updateUrlParams({ section: tabIndexToSection[index] })}
          >
            <Box px={2}>
              <TabList>
                <BorderedTab data-tid="general-settings-main-view">Main Information</BorderedTab>
                <BorderedTab data-tid="general-settings-saml-view">SAML Configuration</BorderedTab>
                <BorderedTab data-tid="general-settings-snowflake-view">
                  Snowflake Settings
                </BorderedTab>
              </TabList>
            </Box>
            <BorderTabDivider />
            <Box p={6}>
              <TabPanels>
                <TabPanel unmountWhenInactive>
                  <CompanyInformationSection />
                </TabPanel>

                <TabPanel unmountWhenInactive>
                  <CompanySamlSettingsSection />
                </TabPanel>
                <TabPanel unmountWhenInactive>
                  <SnowflakeSection />
                </TabPanel>
              </TabPanels>
            </Box>
          </Tabs>
        </Card>
      </Box>
      <SettingsFooter />
    </>
  );
};

export default compose(
  withSEO({ title: 'General Settings' }),
  withRoleRestrictedAccess({
    allowedPermissions: [Permission.GeneralSettingsRead, Permission.GeneralSettingsModify],
    fallback: <Page403 />,
  })
)(GeneralSettingsPage);
