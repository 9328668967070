/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Flex } from 'pouncejs';
import urls from 'Source/urls';
import FadeInTrail from 'Components/utils/FadeInTrail';
import { NavigationLink } from 'Components/Navigation/Navigation';
import RoleRestrictedAccess from 'Components/utils/RoleRestrictedAccess/RoleRestrictedAccess';
import { Permission } from 'Generated/schema';
import NavLink from '../NavLink';

export const dataNavLinks: NavigationLink[] = [
  {
    to: urls.data.dataExplorer(),
    icon: 'data-explorer',
    label: 'Data Explorer',
    permissions: [Permission.DataAnalyticsRead],
  },
  {
    to: urls.data.indicatorSearch(),
    icon: 'search',
    label: 'Indicator Search',
    permissions: [Permission.DataAnalyticsRead],
  },
  {
    to: urls.data.queryHistory(),
    icon: 'history',
    label: 'Query History',
    permissions: [Permission.DataAnalyticsRead],
  },
  {
    to: urls.data.savedQueries.list(),
    icon: 'save',
    label: 'Saved Queries',
    permissions: [Permission.DataAnalyticsRead],
  },
  {
    to: urls.data.resources.list(),
    icon: 'resource',
    label: 'Cloud Resources',
    permissions: [Permission.ResourceRead, Permission.ResourceModify],
  },
  {
    to: urls.data.dataModels.list(),
    icon: 'data',
    label: 'Data Models',
    permissions: [Permission.LogSourceRead, Permission.LogSourceModify],
  },
  {
    to: urls.data.schemas.list(),
    icon: 'schemas',
    label: 'Schemas',
    permissions: [Permission.LogSourceRead, Permission.LogSourceModify],
  },
];

const DataNavigation: React.FC = () => {
  return (
    <Flex direction="column" as="ul">
      <FadeInTrail as="li">
        {dataNavLinks.map(({ to, icon, label, permissions }) => {
          return permissions?.length ? (
            <RoleRestrictedAccess key={label} allowedPermissions={permissions}>
              <NavLink icon={icon} label={label} to={to} isSecondary />
            </RoleRestrictedAccess>
          ) : (
            <NavLink key={label} icon={icon} label={label} to={to} isSecondary />
          );
        })}
      </FadeInTrail>
    </Flex>
  );
};

export default React.memo(DataNavigation);
