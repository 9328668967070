/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Box, Flex, FormHelperText } from 'pouncejs';
import ErrorBoundary from 'Components/ErrorBoundary';
import { Field, useFormikContext } from 'formik';
import FormikTextInput from 'Components/fields/TextInput';
import logo from 'Assets/eventbridge-minimal-logo.svg';
import { PANTHER_CONFIG } from 'Source/constants';
import { WizardPanel } from 'Components/Wizard';
import FormikCombobox from 'Components/fields/ComboBox';
import EditIntegrationActions from 'Pages/Integrations/components/EditIntegrationActions';
import { EventBridgeSourceWizardValues } from '../EventBridgeSourceWizard';

const EventBridgeSourceConfigurationPanel: React.FC = () => {
  const { initialValues, dirty, isValid, status } = useFormikContext<
    EventBridgeSourceWizardValues
  >();
  const editMode = !!initialValues.integrationId;
  return (
    <WizardPanel>
      <Box width={500} m="auto">
        <WizardPanel.Heading
          title={editMode ? 'Update Eventbridge source' : 'Configure your Eventbridge source'}
          subtitle={
            editMode
              ? 'Feel free to make any changes to your Eventbridge log source'
              : 'We need to know where to listen for events'
          }
          logo={logo}
        />
        <ErrorBoundary>
          <Flex direction="column" spacing={4}>
            <Field
              name="integrationLabel"
              as={FormikTextInput}
              label="Name"
              placeholder="A nickname for this Eventbridge log source"
              required
            />
            <Field
              label="Log Types"
              name="logType"
              as={FormikCombobox}
              items={status.availableLogTypes}
              showClearSelectionControl={false}
              required
            />
            <Box as="fieldset">
              <Field
                name="busName"
                as={FormikTextInput}
                label="Bus Name"
                placeholder="The bus name for this Eventbridge log source"
                aria-describedby="busName-helper"
                required
              />
              <FormHelperText id="busName-helper" mt={2}>
                The bus name must be in {PANTHER_CONFIG.AWS_REGION}
              </FormHelperText>
            </Box>
          </Flex>
        </ErrorBoundary>
      </Box>
      <WizardPanel.Actions>
        {editMode ? (
          <EditIntegrationActions />
        ) : (
          <WizardPanel.ActionNext disabled={!dirty || !isValid}>
            Continue Setup
          </WizardPanel.ActionNext>
        )}
      </WizardPanel.Actions>
    </WizardPanel>
  );
};

export default EventBridgeSourceConfigurationPanel;
