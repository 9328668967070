/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import * as Types from '../../../__generated__/schema';

import { AlertSummaryFull } from '../fragments/AlertSummaryFull.generated';
import { GraphQLError } from 'graphql';
import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';

export type GetReplayedAlertsVariables = {
  input: Types.ReplayedAlertsInput;
};

export type GetReplayedAlerts = {
  replayedAlerts: Pick<Types.ReplayedAlertsOutput, 'cursor'> & { alerts: Array<AlertSummaryFull> };
};

export const GetReplayedAlertsDocument = gql`
  query GetReplayedAlerts($input: ReplayedAlertsInput!) {
    replayedAlerts(input: $input) {
      alerts {
        ...AlertSummaryFull
      }
      cursor
    }
  }
  ${AlertSummaryFull}
`;

/**
 * __useGetReplayedAlerts__
 *
 * To run a query within a React component, call `useGetReplayedAlerts` and pass it any options that fit your needs.
 * When your component renders, `useGetReplayedAlerts` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReplayedAlerts({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetReplayedAlerts(
  baseOptions?: ApolloReactHooks.QueryHookOptions<GetReplayedAlerts, GetReplayedAlertsVariables>
) {
  return ApolloReactHooks.useQuery<GetReplayedAlerts, GetReplayedAlertsVariables>(
    GetReplayedAlertsDocument,
    baseOptions
  );
}
export function useGetReplayedAlertsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetReplayedAlerts, GetReplayedAlertsVariables>
) {
  return ApolloReactHooks.useLazyQuery<GetReplayedAlerts, GetReplayedAlertsVariables>(
    GetReplayedAlertsDocument,
    baseOptions
  );
}
export type GetReplayedAlertsHookResult = ReturnType<typeof useGetReplayedAlerts>;
export type GetReplayedAlertsLazyQueryHookResult = ReturnType<typeof useGetReplayedAlertsLazyQuery>;
export type GetReplayedAlertsQueryResult = ApolloReactCommon.QueryResult<
  GetReplayedAlerts,
  GetReplayedAlertsVariables
>;
export function mockGetReplayedAlerts({
  data,
  variables,
  errors,
}: {
  data: GetReplayedAlerts;
  variables?: GetReplayedAlertsVariables;
  errors?: GraphQLError[];
}) {
  return {
    request: { query: GetReplayedAlertsDocument, variables },
    result: { data, errors },
  };
}
