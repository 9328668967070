/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Box, Card, Flex, Grid, Img, Switch, Text } from 'pouncejs';
import { useFormikContext } from 'formik';
import SuccessSVG from 'Assets/statuses/illustration-success.svg';
import S3LogoSVG from 'Assets/logProviders/s3.svg';
import EventThresholdAlarmDestinationInfo from 'Components/forms/EventThresholdAlarmForm/EventThresholdAlarmDestinationInfo';
import IntervalPicker, { IntervalUnitsEnum } from 'Components/IntervalPicker';
import { WizardPanel } from 'Components/Wizard';
import useRouter from 'Hooks/useRouter';
import { useUpdateLookup } from 'Source/graphql/queries/updateLookup.generated';
import urls from 'Source/urls';
import LookupWizardContinueButton from '../LookupWizardContinueButton';
import {
  LookupFormValues,
  LOOKUP_WIZARD_WIDTH,
  getLookupUpdatePayload,
} from '../lookupWizardHelpers';
import EditLookupActions from '../EditLookupActions';

// One day, in minutes
const DAY = 60 * 24;

const LookupVerifyS3SourcePanel = () => {
  const { history } = useRouter();
  const { values, submitForm, setFieldValue, setFieldTouched, initialValues } = useFormikContext<
    LookupFormValues
  >();
  const [displayAlarm, setDisplayAlarm] = React.useState(values.alarmThreshold > 0);
  const isEditing = Boolean(initialValues.id);

  const setAlarmThreshold = React.useCallback(
    (value: number) => {
      setFieldTouched('alarmThreshold', true);
      setFieldValue('alarmThreshold', value);
    },
    [setFieldValue, setFieldTouched]
  );

  const [updateLookup, { loading }] = useUpdateLookup({
    onCompleted: () => {
      submitForm();
      history.push(urls.enrichment.lookupTables.list());
    },
  });

  const handleFinishSetup = React.useCallback(() => {
    const input = getLookupUpdatePayload(values);
    updateLookup({
      variables: { input },
    });
  }, [values, updateLookup]);

  const fileName = React.useMemo(() => {
    const sections = values.s3FileLocation?.split('/');
    return sections?.[sections.length - 1];
  }, [values.s3FileLocation]);

  return (
    <WizardPanel width={LOOKUP_WIZARD_WIDTH} margin="0 auto">
      <WizardPanel.Heading title="Your source setup looks good! " divider={null} />

      <Flex direction="column" alignItems="center" maxWidth="600px" margin="0 auto">
        <Img
          src={SuccessSVG}
          nativeWidth={157}
          nativeHeight={118}
          alt="Checkmark in the foreground, with two people celebrating"
        />

        {fileName && (
          <Card variant="dark" width="100%" p={5} mt={4}>
            <Flex justifyContent="space-between" alignItems="center">
              <Text>Lookup Table created from</Text>
              <Flex
                backgroundColor="navyblue-300"
                py={2}
                px={2}
                alignItems="center"
                spacing={1}
                borderRadius="small"
              >
                <Img src={S3LogoSVG} nativeWidth={20} nativeHeight={20} alt="S3 logo" />
                <Text fontStyle="italic" lineHeight="none">
                  {fileName}
                </Text>
              </Flex>
            </Flex>
          </Card>
        )}

        <Card variant="dark" p={5} mt={2} width="100%">
          <Flex alignItems="center" justifyContent="space-between">
            <Text id="alarm-description">
              {`Set an alarm in case this lookup table doesn't receive any data?`}
            </Text>

            <Flex alignItems="flex-end">
              <Switch
                aria-labelledby="alarm-description"
                checkedText="Yes"
                uncheckedText="No"
                onChange={event => {
                  const isChecked = event.target.checked;
                  setAlarmThreshold(isChecked ? DAY : 0);
                  setDisplayAlarm(isChecked);
                }}
                checked={displayAlarm}
              />
            </Flex>
          </Flex>

          {displayAlarm && (
            <Flex spacing={10} mt={4} alignItems="center">
              <Text fontSize="small-medium">
                How long should Panther wait to send you an alert that no data has been imported?
              </Text>

              <Grid gap={3} flexShrink={0} width={'230px'} templateColumns="4fr 5fr">
                <IntervalPicker
                  value={values.alarmThreshold}
                  setValue={setAlarmThreshold}
                  smallestUnit={IntervalUnitsEnum.HOURS}
                />
              </Grid>
            </Flex>
          )}
        </Card>

        {displayAlarm && (
          <Box mt={2}>
            <EventThresholdAlarmDestinationInfo />
          </Box>
        )}

        <Text color="teal-200" fontWeight="medium" fontSize="medium-large" mt={3}>
          It’s going to take a few minutes for data to be imported to your lookup table
        </Text>
      </Flex>

      {isEditing ? (
        <EditLookupActions />
      ) : (
        <Flex justify="center" mt={6}>
          <LookupWizardContinueButton onClick={handleFinishSetup} loading={loading}>
            Finish Setup
          </LookupWizardContinueButton>
        </Flex>
      )}
    </WizardPanel>
  );
};

export default LookupVerifyS3SourcePanel;
