/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Box, IconButton, IconProps } from 'pouncejs';

export interface NodeActionProps {
  icon: IconProps['type'];
  label: string;
  onClick: () => void;
}

const NodeAction: React.FC<NodeActionProps> = ({ label, icon, onClick }) => {
  const handleClick = React.useCallback(
    e => {
      onClick();
      e.stopPropagation();
    },
    [onClick]
  );

  return (
    <Box
      opacity={0}
      pointerEvents="none"
      transition="opacity 0.1s linear"
      sx={{ 'li:hover > span &': { opacity: 1, pointerEvents: 'auto' } }}
      as="span"
      ml={2}
      key={label}
      title={label}
    >
      <IconButton
        icon={icon}
        onClick={handleClick}
        aria-label={label}
        variant="ghost"
        variantColor="gray-500"
        variantBorderStyle="circle"
        size="small"
      />
    </Box>
  );
};

export default React.memo(NodeAction);
