/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Flex, Box, Text, Icon } from 'pouncejs';
import { MitreTactic, MitreTechnique } from 'Generated/schema';

export interface MitreTacticTechniqueIndicatorProps {
  tactic: Pick<MitreTactic, 'id' | 'name'>;
  technique: Pick<MitreTechnique, 'id' | 'name'>;
}

const MitreTacticTechniqueIndicator = ({
  tactic,
  technique,
}: MitreTacticTechniqueIndicatorProps) => {
  return (
    <Flex alignItems="flex-end">
      <Box>
        <Text color="navyblue-100" fontSize="small" fontFamily="monospace">
          {tactic.id}
        </Text>
        <Text as="h4" fontSize="large" fontWeight="normal">
          {tactic.name}
        </Text>
      </Box>
      <Icon type="chevron-right" mx={3} color="blue-300" size="large" role="presentation" />
      <Box>
        <Text color="navyblue-100" fontSize="small" fontFamily="monospace">
          {technique.id}
        </Text>
        <Text as="h4" fontSize="large" fontWeight="normal">
          {technique.name}
        </Text>
      </Box>
    </Flex>
  );
};

export default MitreTacticTechniqueIndicator;
