/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import type { monaco } from 'react-monaco-editor';

const builtinConstants = 'True|False|None|NotImplemented|Ellipsis|__debug__';

const builtinFunctions =
  'abs|divmod|input|open|staticmethod|all|enumerate|int|ord|str|any|' +
  'eval|isinstance|pow|sum|basestring|execfile|issubclass|print|super|' +
  'binfile|iter|property|tuple|bool|filter|len|range|type|bytearray|' +
  'float|list|raw_input|unichr|callable|format|locals|reduce|unicode|' +
  'chr|frozenset|long|reload|vars|classmethod|getattr|map|repr|xrange|' +
  'cmp|globals|max|reversed|zip|compile|hasattr|memoryview|round|' +
  '__import__|complex|hash|min|set|apply|delattr|help|next|setattr|' +
  'buffer|dict|hex|object|slice|coerce|dir|id|oct|sorted|intern';

const keywords =
  'and|as|assert|break|class|continue|def|del|elif|else|except|exec|' +
  'finally|for|from|global|if|import|in|is|lambda|not|or|pass|print|' +
  'raise|return|try|while|with|yield|async|await';

const registerCompletions = (monacoInstance: typeof monaco) => {
  const registration = monacoInstance.languages.registerCompletionItemProvider('python', {
    provideCompletionItems() {
      return {
        suggestions: [
          ...builtinConstants.split('|').map(constant => ({
            label: constant,
            detail: 'constant',
            kind: monacoInstance.languages.CompletionItemKind.Constant,
            insertText: constant,
          })),
          ...keywords.split('|').map(keyword => ({
            label: keyword,
            detail: 'keyword',
            kind: monacoInstance.languages.CompletionItemKind.Keyword,
            insertText: keyword,
          })),

          ...builtinFunctions.split('|').map(func => ({
            label: func,
            detail: 'function',
            kind: monacoInstance.languages.CompletionItemKind.Function,
            insertText: `${func}()`,
          })),
        ] as monaco.languages.CompletionItem[],
      };
    },
  });

  return registration;
};

export default registerCompletions;
