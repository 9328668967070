/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Flex, Text } from 'pouncejs';

type ReplayStatsBoxProps = {
  unit?: string;
  label: string;
  metric: string;
};

const ReplayStatsBox: React.FC<ReplayStatsBoxProps> = ({ label, metric, unit }) => {
  return (
    <Flex
      flex="1"
      px={4}
      pb={4}
      mr={4}
      flexDirection="column"
      backgroundColor="navyblue-400"
      textAlign="center"
      fontWeight="bold"
    >
      <Text py={4} fontSize="large">
        {label}
      </Text>
      <Flex
        py={8}
        height="100%"
        backgroundColor="navyblue-600"
        flexGrow={1}
        flexDirection="column"
        justifyContent="center"
      >
        <Text fontSize="7x-large" color="blue-400">
          {metric}
        </Text>
        {unit && <Text>{unit}</Text>}
      </Flex>
    </Flex>
  );
};

export default ReplayStatsBox;
