/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import * as Types from '../../../../../../../__generated__/schema';

import { OnePasswordLogIntegrationDetails } from '../../../../../../graphql/fragments/OnePasswordLogIntegrationDetails.generated';
import { GraphQLError } from 'graphql';
import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';

export type GetOnePasswordLogSourceVariables = {
  id: Types.Scalars['ID'];
};

export type GetOnePasswordLogSource = {
  getLogPullingIntegration: OnePasswordLogIntegrationDetails;
};

export const GetOnePasswordLogSourceDocument = gql`
  query GetOnePasswordLogSource($id: ID!) {
    getLogPullingIntegration(id: $id) {
      ...OnePasswordLogIntegrationDetails
    }
  }
  ${OnePasswordLogIntegrationDetails}
`;

/**
 * __useGetOnePasswordLogSource__
 *
 * To run a query within a React component, call `useGetOnePasswordLogSource` and pass it any options that fit your needs.
 * When your component renders, `useGetOnePasswordLogSource` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOnePasswordLogSource({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetOnePasswordLogSource(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetOnePasswordLogSource,
    GetOnePasswordLogSourceVariables
  >
) {
  return ApolloReactHooks.useQuery<GetOnePasswordLogSource, GetOnePasswordLogSourceVariables>(
    GetOnePasswordLogSourceDocument,
    baseOptions
  );
}
export function useGetOnePasswordLogSourceLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetOnePasswordLogSource,
    GetOnePasswordLogSourceVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<GetOnePasswordLogSource, GetOnePasswordLogSourceVariables>(
    GetOnePasswordLogSourceDocument,
    baseOptions
  );
}
export type GetOnePasswordLogSourceHookResult = ReturnType<typeof useGetOnePasswordLogSource>;
export type GetOnePasswordLogSourceLazyQueryHookResult = ReturnType<
  typeof useGetOnePasswordLogSourceLazyQuery
>;
export type GetOnePasswordLogSourceQueryResult = ApolloReactCommon.QueryResult<
  GetOnePasswordLogSource,
  GetOnePasswordLogSourceVariables
>;
export function mockGetOnePasswordLogSource({
  data,
  variables,
  errors,
}: {
  data: GetOnePasswordLogSource;
  variables?: GetOnePasswordLogSourceVariables;
  errors?: GraphQLError[];
}) {
  return {
    request: { query: GetOnePasswordLogSourceDocument, variables },
    result: { data, errors },
  };
}
