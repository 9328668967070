/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Card, Box, Heading, Flex, Text, Link, Tooltip } from 'pouncejs';
import { getActorDisplayName, getElapsedTime } from 'Helpers/utils';
import { Link as RRLink } from 'react-router-dom';
import { RoleDetails } from 'Source/graphql/fragments/RoleDetails.generated';
import RoleRestrictedAccess from 'Components/utils/RoleRestrictedAccess';
import { Permission } from 'Generated/schema';
import { ROLE_PERMISSIONS, NON_EDITABLE_ROLE_NAMES } from 'Source/constants';
import urls from 'Source/urls';
import DisabledLink from 'Components/DisabledLink';
import RoleItemOptions from './RoleItemOptions';

interface RoleItemProps {
  role: RoleDetails;
}

const RoleItem: React.FC<RoleItemProps> = ({ role }) => {
  const isEditable = !NON_EDITABLE_ROLE_NAMES.includes(role.name);

  return (
    <Card variant="dark" p={6} key={role.id}>
      <Flex
        align="flex-start"
        justify="space-between"
        borderBottom="1px solid"
        borderColor="navyblue-300"
        pb={4}
        mb={4}
      >
        <Box>
          <Heading size="small" as="h4">
            {isEditable ? (
              <RoleRestrictedAccess
                allowedPermissions={[Permission.UserModify]}
                fallback={<DisabledLink>{role.name}</DisabledLink>}
              >
                <Link as={RRLink} data-tid="link-to-role" to={urls.settings.roles.edit(role.id)}>
                  {role.name}
                </Link>
              </RoleRestrictedAccess>
            ) : (
              <Tooltip content="This is a protected role that can't be edited">
                <DisabledLink data-tid="link-to-role">{role.name}</DisabledLink>
              </Tooltip>
            )}
          </Heading>
          <Text fontSize="small" color="gray-300">
            Last updated {getElapsedTime(role.updatedAt)} by{' '}
            <b>{getActorDisplayName(role.updatedBy)}</b>
          </Text>
        </Box>
        {isEditable && (
          <RoleRestrictedAccess allowedPermissions={[Permission.UserModify]}>
            <RoleItemOptions role={role} />
          </RoleRestrictedAccess>
        )}
      </Flex>
      <Flex flexWrap="wrap">
        {role.permissions
          // safeguard for permissions not exposed to users
          .filter(p => p in ROLE_PERMISSIONS && !ROLE_PERMISSIONS[p].hidden)
          .map(permission => (
            <Box
              mb={2}
              mr={2}
              key={permission}
              borderRadius="pill"
              backgroundColor="navyblue-400"
              py={1}
              px={3}
              fontSize="medium"
            >
              {ROLE_PERMISSIONS[permission].title}
            </Box>
          ))}
      </Flex>
    </Card>
  );
};

export default React.memo(RoleItem);
