/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Box, Heading, SideSheet, SideSheetProps, useSnackbar } from 'pouncejs';
import { extractErrorMessage } from 'Helpers/utils';
import UserForm, { UserFormValues } from 'Components/forms/UserForm';
import useAuth from 'Hooks/useAuth';
import { UserDetails } from 'Source/graphql/fragments/UserDetails.generated';
import useUserRole from 'Hooks/useUserRole';
import { UserRoleInputKind } from 'Generated/schema';
import { useEditUser } from './graphql/editUser.generated';

export interface EditUserSidesheetProps extends SideSheetProps {
  user: UserDetails;
}

const EditUserSidesheet: React.FC<EditUserSidesheetProps> = ({ user, onClose, ...rest }) => {
  const { pushSnackbar } = useSnackbar();
  const { refetchUserInfo, userInfo } = useAuth();
  const [editUser] = useEditUser({
    onError: error => pushSnackbar({ variant: 'error', title: extractErrorMessage(error) }),
    onCompleted: () => {
      onClose();

      // Refetch user info if editing self
      if (user.id === userInfo.id) {
        refetchUserInfo();
      }

      pushSnackbar({ variant: 'success', title: 'User updated successfully!' });
    },
  });
  const { getRole } = useUserRole();

  const initialValues = {
    id: user.id,
    email: user.email,
    familyName: user.familyName || '',
    givenName: user.givenName || '',
    role: getRole(user),
  };

  const submitToServer = async (values: UserFormValues) => {
    await editUser({
      variables: {
        input: {
          id: values.id,
          email: values.email,
          familyName: values.familyName || null,
          givenName: values.givenName || null,
          role: { kind: UserRoleInputKind.Id, value: values.role.id },
        },
      },
    });
  };

  return (
    <SideSheet aria-labelledby="sidesheet-title" onClose={onClose} {...rest}>
      <Box width={425} m="auto">
        <Heading pt={1} pb={8} id="sidesheet-title">
          Edit Profile
        </Heading>
        <UserForm initialValues={initialValues} onSubmit={submitToServer} />
      </Box>
    </SideSheet>
  );
};

export default EditUserSidesheet;
