/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import queryString from 'query-string';
import LinkButton, { LinkButtonProps } from 'Components/buttons/LinkButton';
import urls from 'Source/urls';

interface ViewWithDataExplorerButtonProps extends LinkButtonProps {
  snippedId: 'alertId' | 'alertIdError' | 'ruleId';
  snippetParams: Record<string, string>;
}

const ViewWithDataExplorerButton: React.FC<ViewWithDataExplorerButtonProps> = ({
  snippedId,
  snippetParams,
  ...rest
}) => {
  return (
    <LinkButton
      {...rest}
      icon="search"
      to={{
        pathname: urls.data.dataExplorer(),
        search: queryString.stringify({ snippedId, ...snippetParams }),
      }}
    >
      View with Data Explorer
    </LinkButton>
  );
};

export default React.memo(ViewWithDataExplorerButton);
