/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { useSnackbar } from 'pouncejs';
import Page404 from 'Pages/404';
import withSEO from 'Hoc/withSEO';
import useRouter from 'Hooks/useRouter';
import withRoleRestrictedAccess from 'Hoc/withRoleRestrictedAccess';
import { compose } from 'Helpers/compose';
import { Permission } from 'Generated/schema';
import Page403 from 'Pages/403';
import { extractErrorMessage } from 'Helpers/utils';
import DuoLogsSourceWizard, { DuoLogsSourceWizardValues } from '../DuoLogsSourceWizard';
import { useGetDuoLogSource } from './graphql/getDuoLogSource.generated';
import { useUpdateDuoLogSource } from './graphql/updateDuoLogSource.generated';

const EditDuoLogSource: React.FC = () => {
  const { pushSnackbar } = useSnackbar();
  const { match } = useRouter<{ id: string }>();
  const { data, error: getError } = useGetDuoLogSource({
    variables: { id: match.params.id },
    onError: error => {
      pushSnackbar({
        title: extractErrorMessage(error) || 'An unknown error occurred',
        variant: 'error',
      });
    },
  });

  const [updateDuoLogSource] = useUpdateDuoLogSource();

  const initialValues = React.useMemo(
    () => ({
      integrationId: match.params.id,
      logTypes: data?.getLogPullingIntegration?.logTypes ?? [],
      integrationLabel: data?.getLogPullingIntegration.integrationLabel ?? 'Loading...',
      apiHostname: data?.getLogPullingIntegration?.pullerConfig?.duo?.apiHostname ?? 'Loading...',
      integrationKey:
        data?.getLogPullingIntegration?.pullerConfig?.duo?.integrationKey ?? 'Loading...',
      secretKey: data?.getLogPullingIntegration?.pullerConfig?.duo?.secretKey ?? 'Loading...',
    }),
    // FIXME: look into missing hook dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data]
  );

  const handleSubmit = React.useCallback(async (values: DuoLogsSourceWizardValues) => {
    try {
      const res = await updateDuoLogSource({
        variables: {
          input: {
            integrationId: values.integrationId,
            integrationLabel: values.integrationLabel,
            pullerConfig: {
              logTypes: values.logTypes,
              duo: {
                apiHostname: values.apiHostname,
                integrationKey: values.integrationKey,
                secretKey: values.secretKey,
              },
            },
          },
        },
      });
      return res.data.updateLogPullingIntegration;
    } catch (err) {
      return err as Error;
    }
    // FIXME: look into missing hook dependencies or using a ref
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // we optimistically assume that an error in "get" is a 404. We don't have any other info
  if (getError) {
    return <Page404 />;
  }

  return <DuoLogsSourceWizard initialValues={initialValues} onSubmit={handleSubmit} />;
};

export default compose(
  withSEO({ title: 'Edit Duo Source' }),
  withRoleRestrictedAccess({
    allowedPermissions: [Permission.LogSourceModify],
    fallback: <Page403 />,
  })
)(EditDuoLogSource);
