/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import * as Types from '../../../../../../__generated__/schema';

import { AsanaLogIntegrationDetails } from '../../../../../graphql/fragments/AsanaLogIntegrationDetails.generated';
import { GraphQLError } from 'graphql';
import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';

export type UpdateAsanaLogSourceVariables = {
  input: Types.UpdateLogPullingIntegrationInput;
};

export type UpdateAsanaLogSource = { updateLogPullingIntegration: AsanaLogIntegrationDetails };

export const UpdateAsanaLogSourceDocument = gql`
  mutation UpdateAsanaLogSource($input: UpdateLogPullingIntegrationInput!) {
    updateLogPullingIntegration(input: $input) {
      ...AsanaLogIntegrationDetails
    }
  }
  ${AsanaLogIntegrationDetails}
`;
export type UpdateAsanaLogSourceMutationFn = ApolloReactCommon.MutationFunction<
  UpdateAsanaLogSource,
  UpdateAsanaLogSourceVariables
>;

/**
 * __useUpdateAsanaLogSource__
 *
 * To run a mutation, you first call `useUpdateAsanaLogSource` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAsanaLogSource` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAsanaLogSource, { data, loading, error }] = useUpdateAsanaLogSource({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAsanaLogSource(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateAsanaLogSource,
    UpdateAsanaLogSourceVariables
  >
) {
  return ApolloReactHooks.useMutation<UpdateAsanaLogSource, UpdateAsanaLogSourceVariables>(
    UpdateAsanaLogSourceDocument,
    baseOptions
  );
}
export type UpdateAsanaLogSourceHookResult = ReturnType<typeof useUpdateAsanaLogSource>;
export type UpdateAsanaLogSourceMutationResult = ApolloReactCommon.MutationResult<
  UpdateAsanaLogSource
>;
export type UpdateAsanaLogSourceMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateAsanaLogSource,
  UpdateAsanaLogSourceVariables
>;
export function mockUpdateAsanaLogSource({
  data,
  variables,
  errors,
}: {
  data: UpdateAsanaLogSource;
  variables?: UpdateAsanaLogSourceVariables;
  errors?: GraphQLError[];
}) {
  return {
    request: { query: UpdateAsanaLogSourceDocument, variables },
    result: { data, errors },
  };
}
