/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Form, Formik } from 'formik';
import { Box } from 'pouncejs';
import * as Yup from 'yup';
import useCheckPermissions from 'Hooks/useCheckPermissions';
import { Permission } from 'Generated/schema';
import SubmitButton from 'Components/buttons/SubmitButton';
import LicenseAgreementSection from './LicenseAgreementSection';
import AnalyticsConsentSection from './AnalyticsConsentSection';

interface ProductConsentFormValues {
  analyticsConsent?: boolean;
  licenseAccepted?: boolean;
}

interface ProductConsentFormProps {
  isLicenseAccepted: boolean;
  onSubmit: (values: ProductConsentFormValues) => Promise<any>;
}

const ProductConsentForm: React.FC<ProductConsentFormProps> = ({ isLicenseAccepted, onSubmit }) => {
  const userIsAllowedToModify = useCheckPermissions(Permission.GeneralSettingsModify);

  const validationSchema = Yup.object().shape({
    analyticsConsent: Yup.boolean().required(),
    licenseAccepted: isLicenseAccepted
      ? null
      : Yup.boolean().oneOf([true], 'The License Agreement terms must be accepted.').required(),
  });

  // We initialize values conditionally based on if we give users
  // the ability to change them
  const initialValues = React.useMemo(() => {
    const val = { analyticsConsent: true } as ProductConsentFormValues;
    if (isLicenseAccepted !== true) {
      val.licenseAccepted = false;
    }

    return val;
  }, [isLicenseAccepted]);

  return (
    <Formik<ProductConsentFormValues>
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      validateOnMount
    >
      <Form>
        <Box mb={10}>
          <AnalyticsConsentSection isDisabled={!userIsAllowedToModify} />
        </Box>
        <Box mb={10}>
          <LicenseAgreementSection isDisabled={!userIsAllowedToModify} />
        </Box>
        <SubmitButton fullWidth allowPristineSubmission={isLicenseAccepted}>
          Save
        </SubmitButton>
      </Form>
    </Formik>
  );
};

export default ProductConsentForm;
