/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Link, LinkProps } from 'pouncejs';
import { LinkifyProps } from 'linkifyjs/react';

const OriginalReactLinkify = React.lazy(() =>
  import(/* webpackChunkName: "linkify" */ 'linkifyjs/react.js')
) as React.FC<LinkifyProps>;

const linkifyOptions = {
  defaultProtocol: 'https',
  tagName: () => Link,
  attributes: {
    external: true,
    wordBreak: 'break-word',
  } as LinkProps,
};

const Linkify: React.FC<LinkProps> = ({ children, ...rest }) => {
  return (
    <React.Suspense fallback={<p>{children}</p>}>
      <OriginalReactLinkify tagName="p" options={linkifyOptions as any} {...rest}>
        {children}
      </OriginalReactLinkify>
    </React.Suspense>
  );
};

export default Linkify;
