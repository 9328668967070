/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

/* A hook for getting access to the context value */
import React from 'react';
import { Box } from 'pouncejs';
import useModal from 'Hooks/useModal';
import IndicatorSearchModal from 'Components/modals/IndicatorSearchModal';

// Used this hook in order to avoid duplicate entries of the IndicatorSearchModal along with
// recalculating the title prop multiple times.
const useIndicatorSearchModal = () => {
  const { showModal } = useModal();

  const showIndicatorSearchModal = React.useCallback(
    ({
      indicator,
      dateCreated = undefined,
      dateEnded = undefined,
    }: {
      indicator: string;
      dateCreated?: string;
      dateEnded?: string;
    }) => {
      const modalTitle = (
        <Box display="inline-block" truncated maxWidth={400}>
          {indicator}
        </Box>
      );

      return showModal(
        <IndicatorSearchModal
          indicator={indicator}
          dateCreated={dateCreated}
          dateEnded={dateEnded}
        />,
        {
          title: modalTitle,
          showCloseButton: true,
        }
      );
    },
    [showModal]
  );

  return showIndicatorSearchModal;
};

export default useIndicatorSearchModal;
