/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';

export type State = {
  globalErrorMessage: string;
  queryId?: string | null;
  queryStatus: 'provisioning' | 'errored' | 'running' | 'succeeded' | 'cancelled' | null;
};

type Action<T, P = never> = {
  type: T;
  payload?: P;
};

type SwitchQuery = Action<'SWITCH_QUERY', { queryId: string }>;
type MarkQueryAsProvisioning = Action<'QUERY_PROVISIONING'>;
type MarkQueryAsErrored = Action<'QUERY_ERRORED', { queryId?: string; message: string }>;
type MarkQueryAsRunning = Action<'QUERY_RUNNING', { queryId: string }>;
type MarkQueryAsCancelled = Action<'QUERY_CANCELLED'>;
type MarkQueryAsSuccessful = Action<'QUERY_SUCCEEDED', { queryId?: string }>;

export type Actions =
  | MarkQueryAsErrored
  | MarkQueryAsCancelled
  | MarkQueryAsProvisioning
  | MarkQueryAsRunning
  | MarkQueryAsSuccessful
  | SwitchQuery;

export type Reducer = React.Reducer<State, Actions>;

export const initialState: State = {
  queryId: null,
  globalErrorMessage: '',
  queryStatus: null,
};

export function reducer(state: State = initialState, action: Actions): State {
  switch (action.type) {
    case 'SWITCH_QUERY':
      return {
        ...state,
        queryId: action.payload.queryId ?? null,
        globalErrorMessage: '',
        queryStatus: null,
      };
    case 'QUERY_ERRORED':
      return {
        ...state,
        globalErrorMessage: action.payload.message,
        queryStatus: 'errored' as const,
        queryId: action.payload.queryId || state.queryId,
      };
    case 'QUERY_PROVISIONING':
      return { ...state, queryStatus: 'provisioning' as const };
    case 'QUERY_RUNNING':
      return {
        ...state,
        queryStatus: 'running' as const,
        queryId: action.payload.queryId,
        globalErrorMessage: '',
      };
    case 'QUERY_CANCELLED':
      return { ...state, queryId: null, queryStatus: null };
    case 'QUERY_SUCCEEDED':
      return {
        ...state,
        globalErrorMessage: '',
        queryStatus: 'succeeded' as const,
        queryId: action.payload?.queryId || state.queryId,
      };
    default:
      throw new Error();
  }
}

export interface AsyncQueryContextValue {
  state: State;
  dispatch: (action: Actions) => void;
}

export const AsyncQueryContext = React.createContext<AsyncQueryContextValue>(undefined);
