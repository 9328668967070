/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

interface StorageShape {
  getItem: (key: string) => string | null;
  removeItem: (key: string) => void;
  setItem: (key: string, item: string) => void;
  clear: () => void;
}

class Storage {
  storageInstance: StorageShape;

  constructor(storageInstance: StorageShape) {
    this.storageInstance = storageInstance;
  }

  /**
   * Stores data in the storage
   *
   * @param key The key to store the data under
   * @param data The data to store
   */
  write(key: string, data: any) {
    const storedShape = data instanceof Object ? JSON.stringify(data) : data;
    try {
      this.storageInstance.setItem(key, storedShape);
    } catch (e) {
      let errMessage = (e as Error).message;
      if (errMessage.includes('QuotaExceededError')) {
        errMessage = `Failed to store a value in your browser's ${this.storageInstance}. You either have insufficient space or you have explicitly disabled this feature`;
      }

      alert(errMessage); // eslint-disable-line no-alert
    }
  }

  /**
   * Retrieves data from the storage
   *
   * @param key The key to read
   * @returns the data matching this key
   */
  read<T = string>(key: string): T {
    const data = this.storageInstance.getItem(key);
    if (data === null) {
      return null;
    }

    try {
      return JSON.parse(this.storageInstance.getItem(key)) as T;
    } catch (e) {
      return (data as unknown) as T;
    }
  }

  /**
   *
   * @param key The key to delete
   * @returns void
   */
  delete(key: string) {
    this.storageInstance.removeItem(key);
  }

  /**
   * Clears the storage from all of its keys
   */
  clear() {
    this.storageInstance.clear();
  }
}

const local = new Storage(localStorage);
const session = new Storage(sessionStorage);

const storage = { local, session };

export default storage;
