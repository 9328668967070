/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import TablePlaceholder from 'Components/TablePlaceholder';
import { FadeIn, Box, Card, TabList, TabPanel, TabPanels, Tabs } from 'pouncejs';
import { BorderedTab, BorderTabDivider } from 'Components/BorderedTab';

const EditPolicyPageSkeleton: React.FC = () => {
  return (
    <FadeIn from="bottom">
      <Card position="relative" data-testid="policy-edit-loading">
        <Tabs>
          <Box px={2}>
            <TabList>
              <BorderedTab>Policy Settings</BorderedTab>
              <BorderedTab>Functions & Tests</BorderedTab>
              <BorderedTab>Report Mapping</BorderedTab>
            </TabList>
          </Box>
          <BorderTabDivider />
          <TabPanels>
            <TabPanel data-testid="policy-settings-tabpanel">
              <Box p={6}>
                <TablePlaceholder rowCount={5} />
              </Box>
            </TabPanel>
            <TabPanel data-testid="policy-functions-tabpanel">
              <Box p={6}>
                <TablePlaceholder rowCount={5} />
              </Box>
            </TabPanel>
            <TabPanel data-testid="policy-mapping-tabpanel">
              <Box p={6}>
                <TablePlaceholder rowCount={5} />
              </Box>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Card>
    </FadeIn>
  );
};

export default EditPolicyPageSkeleton;
