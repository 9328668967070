/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { M365LogIntegrationDetails } from 'Source/graphql/fragments/M365LogIntegrationDetails.generated';
import { LogTransportMethodsEnum } from 'Source/constants';
import { Wizard } from 'Components/Wizard';
import { yupIntegrationLabelUniqueness } from 'Helpers/utils';
import useFindSourcesPerMethod from 'Hooks/useFindSourcesPerMethod';
import CancelIntegrationAction from 'Pages/Integrations/components/CancelIntegrationAction';
import ConfigurationPanel from './ConfigurationPanel';
import CredentialsPanel from './CredentialsPanel';
import ValidationPanel from './ValidationPanel';

interface M365LogsWizardProps {
  initialValues: M365LogsSourceWizardValues;
  onSubmit: (values: M365LogsSourceWizardValues) => Promise<M365LogIntegrationDetails | Error>;
}

export interface M365LogsSourceWizardValues {
  logTypes: string[];
  integrationId?: string;
  integrationLabel: string;
  tenantId: string;
  clientId: string;
  clientSecret: string;
}

const M365LogsSourceWizard: React.FC<M365LogsWizardProps> = ({ initialValues, onSubmit }) => {
  const existingPullerSources = useFindSourcesPerMethod(LogTransportMethodsEnum.logPulling);

  const validationSchema: Yup.SchemaOf<M365LogsSourceWizardValues> = React.useMemo(
    () =>
      Yup.object().shape({
        integrationId: Yup.string(),
        logTypes: Yup.array().of(Yup.string().required()).required().min(1),
        integrationLabel: yupIntegrationLabelUniqueness({
          existingLabel: initialValues.integrationLabel,
          existingSources: existingPullerSources,
        }),
        tenantId: Yup.string().required(),
        clientId: Yup.string().required(),
        clientSecret: initialValues.integrationId ? Yup.string() : Yup.string().required(),
      }),
    [initialValues.integrationId, initialValues.integrationLabel, existingPullerSources]
  );

  const editMode = !!initialValues.integrationId;

  return (
    <Formik<M365LogsSourceWizardValues>
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      <Form>
        <Wizard headerTitle="Connecting a new Log Source" enableAllNavigationSteps={editMode}>
          <Wizard.Step
            title="Configure Source"
            description="Provide account information and set preferences to connect the log source to Panther"
          >
            <ConfigurationPanel />
          </Wizard.Step>
          <Wizard.Step
            title="Set Credentials"
            description="Provide account credentials to give Panther permissions to collect logs from the source"
          >
            <CredentialsPanel />
          </Wizard.Step>
          <Wizard.Step
            title="Verify Setup"
            description="Confirm that your log source has been successfully onboarded or troubleshoot any issues"
            navigationDisabled={editMode}
          >
            <ValidationPanel />
          </Wizard.Step>
        </Wizard>
        {!editMode && <CancelIntegrationAction />}
      </Form>
    </Formik>
  );
};

export default M365LogsSourceWizard;
