/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Box, Link, Text, Flex, Img, Card } from 'pouncejs';
import urls from 'Source/urls';
import { AlertType } from 'Generated/schema';
import { useListDestinations } from 'Source/graphql/queries';
import { DESTINATIONS } from 'Source/constants';

const EventThresholdAlarmDestinationInfo: React.FC = () => {
  const { data } = useListDestinations();
  if (!data) {
    return null;
  }

  const alarmDestinationReceivers =
    data?.destinations.filter(destination =>
      destination.alertTypes.includes(AlertType.SystemError)
    ) ?? [];

  return !alarmDestinationReceivers.length ? (
    <Card variant="dark" p={4}>
      <Text
        textAlign="center"
        fontSize="medium"
        color="red-200"
        data-testid="alarm-destination-warning"
      >
        You have not set an alert destination for this alarm’s alerts to be delivered. Please visit{' '}
        <Link external href={urls.integrations.destinations.list()}>
          Alert Destinations
        </Link>{' '}
        and add{' '}
        <Box as="b" color="white">
          System Errors
        </Box>{' '}
        in the Alert Types field, to at least one of your destinations.
      </Text>
    </Card>
  ) : (
    <Card variant="dark" fontSize="medium" color="gray-200" p={4}>
      <Text textAlign="center">
        The alerts from this alarm will be delivered to the following destination(s):
      </Text>
      <Flex justify="center" spacing={5} wrap="wrap">
        {alarmDestinationReceivers.map(destination => (
          <Flex key={destination.outputId} align="center" spacing={3} mt={3}>
            <Img
              src={DESTINATIONS[destination.outputType].logo}
              nativeHeight={18}
              alt={destination.outputType}
            />
            <Box as="span">{destination.displayName}</Box>
          </Flex>
        ))}
      </Flex>
    </Card>
  );
};

export default EventThresholdAlarmDestinationInfo;
