/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { DestinationDetails } from 'Source/graphql/fragments/DestinationDetails.generated';
import { DESTINATIONS } from 'Source/constants';
import { DestinationTypeEnum } from 'Generated/schema';
import DestinationCard from './DestinationCard';

interface JiraDestinationCardProps {
  destination: DestinationDetails;
}

const JiraDestinationCard: React.FC<JiraDestinationCardProps> = ({ destination }) => {
  const metadata = React.useMemo(
    () => [
      {
        key: 'Organization Domain',
        value: destination.outputConfig.jira.orgDomain,
      },
      {
        key: 'OAssignee ID',
        value: destination.outputConfig.jira.assigneeId,
      },
      {
        key: 'Issue Type',
        value: destination.outputConfig.jira.issueType,
      },
    ],
    [destination]
  );

  return (
    <DestinationCard
      logo={DESTINATIONS[DestinationTypeEnum.Jira].logo}
      destination={destination}
      metadata={metadata}
    />
  );
};

export default React.memo(JiraDestinationCard);
