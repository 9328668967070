/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */
import React from 'react';
import { useSnackbar } from 'pouncejs';
import { downloadData, extractErrorMessage, base64ZipToBlob } from 'Helpers/utils';
import { useBulkDownloadLazyQuery } from 'Source/graphql/queries/bulkDownload.generated';
import { BulkDownloadInput } from 'Generated/schema';

export interface UseBulkDownloadProps {
  /** The file name for the zip to be downloaded, suffix is not required is always a zip. */
  fileName: string;
}

/**
 * Helps download detections, data models, globals and saved queries
 */
function useBulkDownload({ fileName }: UseBulkDownloadProps) {
  const { pushSnackbar } = useSnackbar();
  const [bulkDownloadLazy, { loading, error }] = useBulkDownloadLazyQuery({
    onCompleted: data => {
      downloadData(base64ZipToBlob(data.bulkDownload.data), `${fileName}.zip`);
    },
    onError: err => {
      pushSnackbar({
        variant: 'error',
        title: extractErrorMessage(err) || 'Failed to download selected entities',
      });
    },
  });
  const download = React.useCallback(
    ({ detectionIds, dataModelIds, savedQueryIds, globalHelperIds }: BulkDownloadInput) =>
      /* FIXME: There an issue with Apollo that triggers the onComplete function
       * on every re-render and therefore the need to use callback fo this function
       * issue here: https://github.com/apollographql/react-apollo/issues/3505
       */
      bulkDownloadLazy({
        variables: { input: { detectionIds, dataModelIds, savedQueryIds, globalHelperIds } },
      }),
    [bulkDownloadLazy]
  );

  return React.useMemo(() => ({ loading, error, download }), [loading, error, download]);
}

export default useBulkDownload;
