/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Heading, HeadingProps, Tab, TabProps } from 'pouncejs';

/**
 * These props are automatically passed by `TabList` and not by the developer. At the export level
 * of this component, we "hide" them from the developer by exporting this component `as React.FC`
 */
interface PrivateBorderedTabProps {
  /** Whether the tab is selected */
  isSelected: boolean;

  /** Whether the tab is focused */
  isFocused: boolean;
}

const BorderedTab: React.FC<PrivateBorderedTabProps> = ({
  isSelected,
  isFocused,
  children,
  ...rest
}) => {
  const selectedColor = 'blue-400';
  const focusedColor = 'navyblue-300';

  let borderColor: HeadingProps['borderColor'];
  if (isSelected) {
    borderColor = selectedColor;
  } else if (isFocused) {
    borderColor = focusedColor;
  } else {
    borderColor = 'transparent';
  }

  return (
    <Tab {...rest}>
      <Heading
        size="x-small"
        as="h4"
        borderBottom="3px solid"
        zIndex={5}
        mx={4}
        py={6}
        transition="border-color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms"
        borderColor={borderColor}
        _hover={{
          borderColor: !isSelected ? focusedColor : undefined,
        }}
      >
        {children}
      </Heading>
    </Tab>
  );
};

export default (React.memo(BorderedTab) as unknown) as React.FC<TabProps>;
