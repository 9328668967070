/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import ErrorBoundary from 'Components/ErrorBoundary';
import { Alert, useSnackbar } from 'pouncejs';
import { useGetGeneralSettings } from 'Pages/GeneralSettings/graphql/getGeneralSettings.generated';
import GeneralSettingsPageSkeleton from 'Pages/GeneralSettings/Skeleton';
import { extractErrorMessage } from 'Helpers/utils';
import CompanySamlSettingsForm from 'Components/forms/CompanySamlSettingsForm';
import { useUpdateSamlConfig } from 'Pages/GeneralSettings/graphql/updateSamlConfig.generated';

const CompanySamlSettingsSection: React.FC = () => {
  const { pushSnackbar } = useSnackbar();

  const {
    loading: getGeneralSettingsLoading,
    error: getGeneralSettingsError,
    data: getGeneralSettingsData,
  } = useGetGeneralSettings();

  const [updatedSamlConfig] = useUpdateSamlConfig({
    onCompleted: () => {
      pushSnackbar({ variant: 'success', title: `Successfully updated SAML configuration` });
    },
    onError: error => {
      pushSnackbar({
        variant: 'error',
        title: 'Failed to update company SAML configuration',
        description: extractErrorMessage(error),
      });
    },
  });

  if (getGeneralSettingsLoading) {
    return <GeneralSettingsPageSkeleton />;
  }

  if (getGeneralSettingsError) {
    return (
      <Alert
        variant="error"
        title="Failed to query company information"
        description={
          extractErrorMessage(getGeneralSettingsError) ||
          'Sorry, something went wrong, please reach out to support@runpanther.io if this problem persists'
        }
      />
    );
  }

  const {
    defaultRoleId = '',
    enabled = false,
    metadataUrl = '',
  } = getGeneralSettingsData.samlConfig;

  return (
    <ErrorBoundary>
      <CompanySamlSettingsForm
        initialValues={{
          defaultRoleId,
          enabled,
          metadataUrl,
        }}
        onSubmit={values => updatedSamlConfig({ variables: { input: values } })}
      />
    </ErrorBoundary>
  );
};

export default CompanySamlSettingsSection;
