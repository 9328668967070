/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { SimpleGrid, Badge, Text, Tooltip } from 'pouncejs';
import { Schedule } from 'Generated/schema';
import { secondsToString } from 'Helpers/utils';

interface ScheduleBadgeProps {
  schedule: Pick<Schedule, 'rateMinutes' | 'timeout' | 'cronExpression'>;
}

const CronInfo = React.lazy(() => import(/* webpackChunkName: "cron-info" */ './CronInfo'));

const ScheduleBadge: React.FC<ScheduleBadgeProps> = ({ schedule }) => {
  const tooltipContent = (
    <SimpleGrid as="dl" columns={2} spacing={2}>
      <Text as="dt">Timeout:</Text>
      <Text as="dd">{secondsToString(schedule.timeout * 60)}</Text>

      <React.Suspense fallback={null}>
        <CronInfo schedule={schedule} />
      </React.Suspense>

      {schedule.rateMinutes && (
        <React.Fragment>
          <Text as="dt">Every </Text>
          <Text as="dd">{secondsToString(schedule.rateMinutes * 60)}</Text>
        </React.Fragment>
      )}
    </SimpleGrid>
  );
  return (
    <Tooltip content={tooltipContent}>
      <Badge emphasized color="navyblue-300">
        SCHEDULED
      </Badge>
    </Tooltip>
  );
};

export default ScheduleBadge;
