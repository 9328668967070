/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import * as Types from '../../../../../../../__generated__/schema';

import { GsuiteLogIntegrationDetails } from '../../../../../../graphql/fragments/GsuiteLogIntegrationDetails.generated';
import { GraphQLError } from 'graphql';
import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';

export type GetGsuiteLogSourceVariables = {
  id: Types.Scalars['ID'];
};

export type GetGsuiteLogSource = { getLogPullingIntegration: GsuiteLogIntegrationDetails };

export const GetGsuiteLogSourceDocument = gql`
  query GetGsuiteLogSource($id: ID!) {
    getLogPullingIntegration(id: $id) {
      ...GsuiteLogIntegrationDetails
    }
  }
  ${GsuiteLogIntegrationDetails}
`;

/**
 * __useGetGsuiteLogSource__
 *
 * To run a query within a React component, call `useGetGsuiteLogSource` and pass it any options that fit your needs.
 * When your component renders, `useGetGsuiteLogSource` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGsuiteLogSource({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetGsuiteLogSource(
  baseOptions?: ApolloReactHooks.QueryHookOptions<GetGsuiteLogSource, GetGsuiteLogSourceVariables>
) {
  return ApolloReactHooks.useQuery<GetGsuiteLogSource, GetGsuiteLogSourceVariables>(
    GetGsuiteLogSourceDocument,
    baseOptions
  );
}
export function useGetGsuiteLogSourceLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetGsuiteLogSource,
    GetGsuiteLogSourceVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<GetGsuiteLogSource, GetGsuiteLogSourceVariables>(
    GetGsuiteLogSourceDocument,
    baseOptions
  );
}
export type GetGsuiteLogSourceHookResult = ReturnType<typeof useGetGsuiteLogSource>;
export type GetGsuiteLogSourceLazyQueryHookResult = ReturnType<
  typeof useGetGsuiteLogSourceLazyQuery
>;
export type GetGsuiteLogSourceQueryResult = ApolloReactCommon.QueryResult<
  GetGsuiteLogSource,
  GetGsuiteLogSourceVariables
>;
export function mockGetGsuiteLogSource({
  data,
  variables,
  errors,
}: {
  data: GetGsuiteLogSource;
  variables?: GetGsuiteLogSourceVariables;
  errors?: GraphQLError[];
}) {
  return {
    request: { query: GetGsuiteLogSourceDocument, variables },
    result: { data, errors },
  };
}
