/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */
import React from 'react';
import { Button } from 'pouncejs';
import { SavedQueryDetails } from 'Source/graphql/fragments/SavedQueryDetails.generated';
import useModal from 'Hooks/useModal';
import UpdateSavedQueryModal from 'Components/modals/UpdateSavedQueryModal';
import CreateSavedQueryModal from 'Components/modals/CreateSavedQueryModal';
import { useDataExplorerContext } from 'Pages/DataExplorer/DataExplorerContext';

interface EditorActionButtonProps {
  activeQuery: SavedQueryDetails;
  value: string;
}

const EditorSavedQueriesActions: React.FC<EditorActionButtonProps> = ({ activeQuery, value }) => {
  const { showModal } = useModal();
  const {
    state: { selectedDatabase },
  } = useDataExplorerContext();
  if (activeQuery) {
    return (
      <Button
        variantColor="green-400"
        onClick={() =>
          showModal(
            <UpdateSavedQueryModal
              savedQueryId={activeQuery.id}
              // NOTE: if query has not a defaultDatabase already, don't pass the selected one
              sqlQuery={value}
              selectedDatabase={activeQuery.defaultDatabase ? selectedDatabase : null}
            />,
            { title: 'Update Query', showCloseButton: true }
          )
        }
        aria-describedby="save-query-helper"
      >
        Update
      </Button>
    );
  }

  return (
    <Button
      variantColor="green-400"
      disabled={!value}
      onClick={() =>
        showModal(<CreateSavedQueryModal sqlQuery={value} selectedDatabase={selectedDatabase} />, {
          showCloseButton: true,
          title: 'Save Query',
        })
      }
    >
      Save as
    </Button>
  );
};

export default React.memo(EditorSavedQueriesActions);
