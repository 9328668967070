/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { useSnackbar } from 'pouncejs';
import { DataModel } from 'Generated/schema';
import { extractErrorMessage, toPlural } from 'Helpers/utils';
import ConfirmModal from 'Components/modals/ConfirmModal';
import { useDeleteDataModel } from './graphql/deleteDataModel.generated';

export interface DeleteDataModelModalProps {
  dataModels: DataModel[];
  onSuccess?: () => void;
}

const DeleteDataModelModal: React.FC<DeleteDataModelModalProps> = ({
  dataModels,
  onSuccess = () => {},
  ...rest
}) => {
  const dataModelToString = toPlural('Data Model', dataModels.length);
  const { pushSnackbar } = useSnackbar();
  const [deleteDataModel] = useDeleteDataModel({
    variables: { input: { dataModels: dataModels.map(m => ({ id: m.id })) } },
    update: cache => {
      dataModels.forEach(dataModel => {
        cache.evict({ id: cache.identify(dataModel) });
      });
      cache.gc();
    },
    onCompleted: () => {
      onSuccess();
    },
    onError: error => {
      pushSnackbar({
        variant: 'error',
        title: `Failed to delete ${dataModelToString}`,
        description: extractErrorMessage(error),
      });
    },
  });

  return (
    <ConfirmModal
      onConfirm={deleteDataModel}
      variant="destructive"
      subtitle={[
        `Are you sure you want to delete `,
        <b key={0}>
          {dataModels.length === 1
            ? dataModels[0].displayName || dataModels[0].id
            : `${dataModels.length} Data Models`}
        </b>,
        '?',
      ]}
      {...rest}
    />
  );
};

export default DeleteDataModelModal;
