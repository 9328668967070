/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import * as Types from '../../../__generated__/schema';

import { LogPullingIntegrationMain } from './LogPullingIntegrationMain.generated';
import { GraphQLError } from 'graphql';
import gql from 'graphql-tag';

export type ZendeskLogIntegrationDetails = {
  pullerConfig: {
    zendesk?: Types.Maybe<
      Pick<Types.ZendeskLogSourceConfig, 'subdomain' | 'authorizationMethod'> & {
        credentials:
          | Pick<Types.ZendeskLogSourceOAuth2Config, 'clientId' | 'clientSecret'>
          | Pick<Types.ZendeskLogSourceBasicConfig, 'email' | 'password'>
          | Pick<Types.ZendeskLogSourceApiConfig, 'email' | 'apiToken'>;
      }
    >;
  };
} & LogPullingIntegrationMain;

export const ZendeskLogIntegrationDetails = gql`
  fragment ZendeskLogIntegrationDetails on LogPullingIntegration {
    ...LogPullingIntegrationMain
    pullerConfig {
      zendesk {
        subdomain
        authorizationMethod
        credentials {
          ... on ZendeskLogSourceOAuth2Config {
            clientId
            clientSecret
          }
          ... on ZendeskLogSourceBasicConfig {
            email
            password
          }
          ... on ZendeskLogSourceApiConfig {
            email
            apiToken
          }
        }
      }
    }
  }
  ${LogPullingIntegrationMain}
`;
