/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import { Box, Flex, Text } from 'pouncejs';
import BulletedLoading from 'Components/BulletedLoading';
import React from 'react';

interface LoadingOverlay {
  loading: boolean;
  title: string;
  description?: string;
  children: React.ReactNode | React.ReactNode[];
}

const LoadingOverlay: React.FC<LoadingOverlay> = ({ loading, title, description, children }) => {
  return (
    <Box position="relative">
      {loading && (
        <Flex
          position="absolute"
          direction="column"
          spacing={2}
          backgroundColor="navyblue-700"
          zIndex={100000}
          align="center"
          opacity={0.9}
          justify="center"
          width={1}
          height="100%"
        >
          <Text textAlign="center" opacity={1} fontWeight="bold">
            {title}
          </Text>
          <Text textAlign="center" opacity={1}>
            {description}
          </Text>
          <BulletedLoading />
        </Flex>
      )}
      {children}
    </Box>
  );
};

export default React.memo(LoadingOverlay);
