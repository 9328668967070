/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Box, LinkProps } from 'pouncejs';
import { Link as RRLink } from 'react-router-dom';

export type LinkButtonWrapperProps = Pick<LinkProps, 'external' | 'disabled' | 'to'>;

const LinkButton: React.FC<LinkButtonWrapperProps> = ({ disabled, external, to, children }) => {
  let linkProps: LinkProps;
  if (disabled) {
    linkProps = { as: 'span' as React.ElementType };
  } else if (!external) {
    linkProps = { to, as: RRLink };
  } else {
    linkProps = {
      target: '_blank',
      rel: 'noopener noreferrer',
      href: to as string,
      as: 'a' as React.ElementType,
    };
  }
  return (
    <Box
      {...linkProps}
      sx={{
        '& > span': {
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        },
      }}
    >
      {children}
    </Box>
  );
};
export default LinkButton;
