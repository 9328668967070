/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import FormikTextInput from 'Components/fields/TextInput';
import { WizardPanel } from 'Components/Wizard';
import { FastField, useFormikContext } from 'formik';
import { downloadData, base64ZipToBlob } from 'Helpers/utils';
import { Box, Button, Flex, Grid, Heading, SimpleGrid, Text, useSnackbar } from 'pouncejs';
import EditIntegrationActions from 'Pages/Integrations/components/EditIntegrationActions';
import { useGetTerraformTemplate } from 'Source/graphql/queries/getTerraformTemplate.generated';
import { LogTransportMethodsEnum, PANTHER_CONFIG } from 'Source/constants';
import CodeBlock from 'Components/CodeBlock';
import { CloudWatchLogSourceWizardValues } from '../CloudWatchLogSourceWizard';
import { useGetLogCfnTemplate } from './graphql/getLogCfnTemplate.generated';

const TemplateMethod: React.FC = () => {
  const { pushSnackbar } = useSnackbar();

  const { values, isValid } = useFormikContext<CloudWatchLogSourceWizardValues>();
  const { data: cfnData, loading: cfnLoading, error: cfnError } = useGetLogCfnTemplate({
    variables: {
      input: {
        integrationLabel: values.integrationLabel,
        cloudwatchLogGroup: values.logGroup,
        cloudwatchFilter: values.filterPattern,
        managedBucketNotifications: values.managedBucketNotifications,
      },
    },
    onError: () => pushSnackbar({ variant: 'error', title: 'Failed to generate CFN template' }),
  });

  const { data: tfData, loading: tfLoading, error: tfError } = useGetTerraformTemplate({
    variables: {
      input: {
        integrationType: LogTransportMethodsEnum.cloudwatch,
        integrationLabel: values.integrationLabel,
      },
    },
    onError: () =>
      pushSnackbar({ variant: 'error', title: 'Failed to generate Terraform template' }),
  });

  const editMode = !!values.integrationId;
  const { stackName: cfnStackName, body } = cfnData?.getCloudWatchLogIntegrationTemplate ?? {};
  const { stackName: tfStackName, data } = tfData?.getTerraformTemplate ?? {};

  return (
    <WizardPanel>
      <WizardPanel.Heading title="CloudFormation or Terraform Template File" />
      <SimpleGrid gap={5} width={0.8} mx="auto">
        <Box as="section">
          <Heading as="h2" color="teal-200" fontSize="x-large" fontWeight="medium" mb={1}>
            1. Use a CloudFormation or Terraform Template File to setup your role
          </Heading>

          <Text color="white-100" fontSize="medium" lineHeight="loose">
            Select and download one of the templates below and deploy it to create an IAM role that
            gives Panther read-only access to your CloudWatch Logs.
          </Text>
        </Box>
        <SimpleGrid as="section" gap={2} columns={2} mx="auto">
          <Button
            variantColor="teal-500"
            icon="download"
            loading={cfnLoading}
            disabled={!!cfnError || cfnLoading}
            onClick={() => downloadData(body, `${cfnStackName}.yml`)}
          >
            CloudFormation Template
          </Button>
          <Button
            variantColor="teal-500"
            icon="download"
            loading={tfLoading}
            disabled={!!tfError || tfLoading}
            onClick={() => downloadData(base64ZipToBlob(data), `${tfStackName}.zip`)}
          >
            Terraform Template
          </Button>
        </SimpleGrid>

        <Box as="section">
          <Heading as="h2" color="teal-200" fontSize="x-large" fontWeight="medium" mb={1}>
            2. Run command below in your CLI
          </Heading>

          <Text color="white-100" fontSize="medium" lineHeight="loose">
            After you download the template file, select the corresponding command and run it in
            your CLI
          </Text>

          <CodeBlock
            title="CloudFormation Command"
            code={`aws cloudformation deploy --template ./${cfnStackName}.yml --stack-name ${cfnStackName} --capabilities CAPABILITY_NAMED_IAM`}
            mt={5}
          />
          <Text fontSize="small" color="blue-100" mt={3} mb={1}>
            Terraform Command
          </Text>
          <Text color="white-100" fontSize="small" lineHeight="loose">
            Run the
            <Box as="code" mx={1} p={1} backgroundColor="navyblue-600">
              terraform init
            </Box>
            command to initialize a working directory that contains the Terraform configuration
            files.
          </Text>
          <CodeBlock
            code={`terraform apply -var panther_aws_account_id=${
              PANTHER_CONFIG.AWS_ACCOUNT_ID
            } -var stack_name=${values.integrationLabel
              .split(' ')
              .join('-')
              .toLowerCase()} -var cloudwatch_log_group_name=${values.logGroup}`}
          />
        </Box>

        <Box as="section">
          <Heading as="h2" color="teal-200" fontSize="x-large" fontWeight="medium">
            3. Enter Bucket Name {'&'} Role ARN
          </Heading>
          <Text color="white-100" fontSize="medium" lineHeight="loose" my={1}>
            Finally, paste the value for Bucket Name and RoleARN into the box below. You can find it
            either in the “Outputs” tab of your CloudFormation stack you just deployed, or in the
            CLI of your TerraForm.
          </Text>

          <Grid templateColumns="2fr 3fr" gap={4}>
            <FastField
              name="s3Bucket"
              as={FormikTextInput}
              label="Bucket Name - Required"
              placeholder="The Bucket name that holds the logs"
              trimPastedText
              required
            />
            <FastField
              name="logProcessingRole"
              as={FormikTextInput}
              label="Role ARN - Required"
              placeholder="The ARN that Panther will use to read s3 objects."
              trimPastedText
              required
            />
          </Grid>
        </Box>
      </SimpleGrid>

      <WizardPanel.Actions>
        <WizardPanel.ActionPrev />
        {editMode ? (
          <EditIntegrationActions />
        ) : (
          <Flex spacing={4} direction="column" align="center">
            <Text fontSize="small" fontWeight="medium">
              After deploying the stack and entering the Role ARN, click below to proceed
            </Text>
            <WizardPanel.ActionNext disabled={!isValid}>Continue Setup</WizardPanel.ActionNext>
          </Flex>
        )}
      </WizardPanel.Actions>
    </WizardPanel>
  );
};

export default TemplateMethod;
