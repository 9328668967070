/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import withSEO from 'Hoc/withSEO';
import { compose } from 'Helpers/compose';
import withRoleRestrictedAccess from 'Hoc/withRoleRestrictedAccess';
import useAvailableLogTypesForLogPuller from 'Hooks/useAvailableLogTypesForLogPuller';
import { Permission } from 'Generated/schema';
import Page403 from 'Pages/403';
import OktaLogsSourceWizard, {
  OktaDomainsEnum,
  OktaLogsSourceWizardValues,
} from '../OktaLogsSourceWizard';
import { useAddOktaLogSource } from './graphql/addOktaLogSource.generated';

const CreateOktaLogSource: React.FC = () => {
  const { defaultLogTypes } = useAvailableLogTypesForLogPuller();
  const initialValues = React.useMemo(
    () => ({
      integrationLabel: '',
      logTypes: defaultLogTypes,
      apiToken: '',
      subdomain: '',
      domain: OktaDomainsEnum.production,
    }),
    [defaultLogTypes]
  );
  const [addOktaLogSource] = useAddOktaLogSource({
    update: (cache, { data }) => {
      cache.modify({
        fields: {
          logSources: (queryData, { toReference }) => {
            const addedIntegrationCacheRef = toReference(data.addLogPullingIntegration);
            return queryData
              ? [addedIntegrationCacheRef, ...queryData]
              : [addedIntegrationCacheRef];
          },
        },
      });
    },
  });

  const handleSubmit = React.useCallback(
    async (values: OktaLogsSourceWizardValues) => {
      try {
        const { data } = await addOktaLogSource({
          variables: {
            input: {
              integrationLabel: values.integrationLabel,
              pullerConfig: {
                logTypes: values.logTypes,
                okta: {
                  domain: `https://${values.subdomain}.${values.domain}`,
                  apiToken: values.apiToken,
                },
              },
            },
          },
        });
        return data.addLogPullingIntegration;
      } catch (err) {
        return err as Error;
      }
    },
    [addOktaLogSource]
  );

  return <OktaLogsSourceWizard initialValues={initialValues} onSubmit={handleSubmit} />;
};

export default compose(
  withSEO({ title: 'New Okta Logs Source' }),
  withRoleRestrictedAccess({
    allowedPermissions: [Permission.LogSourceModify],
    fallback: <Page403 />,
  })
)(CreateOktaLogSource);
