/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import * as Types from '../../../../__generated__/schema';

import { AlertSummaryFull } from '../../../graphql/fragments/AlertSummaryFull.generated';
import { GraphQLError } from 'graphql';
import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';

export type ListAlertsForPolicyVariables = {
  input: Types.AlertsInput;
};

export type ListAlertsForPolicy = {
  alerts: {
    edges: Array<{ node: AlertSummaryFull }>;
    pageInfo: Pick<Types.AlertsOutputPageInfo, 'endCursor' | 'hasNextPage'>;
  };
};

export const ListAlertsForPolicyDocument = gql`
  query ListAlertsForPolicy($input: AlertsInput!) {
    alerts(input: $input) {
      edges {
        node {
          ...AlertSummaryFull
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
  ${AlertSummaryFull}
`;

/**
 * __useListAlertsForPolicy__
 *
 * To run a query within a React component, call `useListAlertsForPolicy` and pass it any options that fit your needs.
 * When your component renders, `useListAlertsForPolicy` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListAlertsForPolicy({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useListAlertsForPolicy(
  baseOptions?: ApolloReactHooks.QueryHookOptions<ListAlertsForPolicy, ListAlertsForPolicyVariables>
) {
  return ApolloReactHooks.useQuery<ListAlertsForPolicy, ListAlertsForPolicyVariables>(
    ListAlertsForPolicyDocument,
    baseOptions
  );
}
export function useListAlertsForPolicyLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    ListAlertsForPolicy,
    ListAlertsForPolicyVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<ListAlertsForPolicy, ListAlertsForPolicyVariables>(
    ListAlertsForPolicyDocument,
    baseOptions
  );
}
export type ListAlertsForPolicyHookResult = ReturnType<typeof useListAlertsForPolicy>;
export type ListAlertsForPolicyLazyQueryHookResult = ReturnType<
  typeof useListAlertsForPolicyLazyQuery
>;
export type ListAlertsForPolicyQueryResult = ApolloReactCommon.QueryResult<
  ListAlertsForPolicy,
  ListAlertsForPolicyVariables
>;
export function mockListAlertsForPolicy({
  data,
  variables,
  errors,
}: {
  data: ListAlertsForPolicy;
  variables?: ListAlertsForPolicyVariables;
  errors?: GraphQLError[];
}) {
  return {
    request: { query: ListAlertsForPolicyDocument, variables },
    result: { data, errors },
  };
}
