/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Badge } from 'pouncejs';

interface ManagedBadgeProps {
  managed: boolean;
}
const ManagedBadge: React.FC<ManagedBadgeProps> = ({ managed }) => {
  let badgeText;
  let badgeColor;
  if (managed) {
    badgeColor = 'green-100';
    badgeText = 'Managed';
  } else {
    badgeColor = 'red-100';
    badgeText = 'Unmanaged';
  }
  return <Badge color={badgeColor}>{badgeText.toUpperCase()}</Badge>;
};

export default ManagedBadge;
