/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { AnalysisPack, AnalysisPackVersion } from 'Generated/schema';
import { Button, Flex, Box, Combobox } from 'pouncejs';
import { compareSemanticVersion } from 'Helpers/utils';

interface UpdateVersionProps {
  pack: Pick<AnalysisPack, 'availableVersions' | 'packVersion' | 'enabled'>;
  onPatch: (values: UpdateVersionFormValues) => void;
}

export interface UpdateVersionFormValues {
  packVersion: {
    id: number;
    semVer: string;
  };
}

const versionToString = v => v.semVer;

const UpdateVersion: React.FC<UpdateVersionProps> = ({
  pack: { enabled, availableVersions, packVersion: current },
  onPatch,
}) => {
  const sortedVersions = [...availableVersions];
  sortedVersions.sort((a, b) => compareSemanticVersion(b.semVer, a.semVer));
  const [selectedVersion, setSelectedVersion] = React.useState<AnalysisPackVersion>(
    sortedVersions[0]
  );

  return (
    <Flex spacing={4}>
      <Box width={100}>
        <Combobox
          data-tid="pack-version-update-combobox"
          label="Version"
          value={selectedVersion}
          disabled={!enabled}
          onChange={setSelectedVersion}
          items={sortedVersions}
          itemToString={versionToString}
          showClearSelectionControl={false}
        />
      </Box>
      <Box width={130}>
        {compareSemanticVersion(selectedVersion.semVer, current.semVer) >= 0 ? (
          <Button
            disabled={!enabled || selectedVersion.semVer === current.semVer}
            onClick={() => onPatch({ packVersion: selectedVersion })}
            data-tid="pack-version-update-action"
          >
            Update Pack
          </Button>
        ) : (
          <Button
            variantColor="violet-400"
            disabled={!enabled || selectedVersion.semVer === current.semVer}
            onClick={() => onPatch({ packVersion: selectedVersion })}
            data-tid="pack-version-revert-action"
          >
            Revert Pack
          </Button>
        )}
      </Box>
    </Flex>
  );
};

export default React.memo(UpdateVersion);
