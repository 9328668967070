/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Box } from 'pouncejs';

interface DisabledContainerProps {
  disabled: boolean;
  children: React.ReactElement;
}

const DisabledContainer: React.FC<DisabledContainerProps> = ({ children, disabled }) => {
  if (!disabled) {
    return children;
  }

  return (
    <Box position="relative">
      <Box
        position="absolute"
        backgroundColor="navyblue-400"
        zIndex={10}
        opacity={0.8}
        height="100%"
        width="100%"
      />
      <Box pointerEvents="none">{children}</Box>
    </Box>
  );
};

export default DisabledContainer;
