/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import { Box, AbstractButton, Icon, IconProps, Collapse } from 'pouncejs';
import React from 'react';

type NavGroupProps = {
  icon: IconProps['type'];
  label: string | React.ReactNode;
  active?: boolean;
  onSelect: () => void;
  children?: React.ReactNode;
};

const NavGroup: React.FC<NavGroupProps> = ({ icon, label, active, onSelect, children }) => {
  return (
    <Box
      fontSize="medium"
      borderRadius="small"
      backgroundColor={active ? 'navyblue-500' : 'transparent'}
    >
      <AbstractButton
        py={3}
        display="flex"
        onClick={onSelect}
        alignItems="center"
        width="100%"
        _hover={{
          color: 'white-200',
          backgroundColor: 'navyblue-500',
        }}
        pl={2}
      >
        <Icon
          transition="transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 80ms"
          transform={active ? 'rotate(90deg)' : 'rotate(0deg)'}
          type="caret-right"
          size="medium"
        />
        <Icon type={icon} size="medium" mr={3} />
        <Box>{label}</Box>
      </AbstractButton>
      <Collapse open={active}>
        <Box px={3} pb={2}>
          {children}
        </Box>
      </Collapse>
    </Box>
  );
};

export default NavGroup;
