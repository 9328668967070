/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import FormikDateRangeInput from 'Components/fields/DateRangeInput';
import FormikMultiCombobox from 'Components/fields/MultiComboBox';
import FormikAutosave from 'Components/utils/Autosave';
import { Field, Formik } from 'formik';
import { ReplayState } from 'Generated/schema';
import { Box, Flex } from 'pouncejs';
import React from 'react';
import { ReplayFull } from 'Source/graphql/fragments/ReplayFull.generated';
import * as Yup from 'yup';
import dayjs from 'dayjs';
import ReplayEditorPreviewAndAction from './ReplayEditorPreviewAndAction';
import ReplayEditorTimeWarning from './ReplayEditorTimeWarning';

export type ReplayDataFormProps = {
  onStop?: (id: string) => void;
  disabled: boolean;
  logTypes: string[];
  onCreate: (startAt: string, endAt: string, logTypes: string[]) => void;
  initialLogTypes?: string[];
  currentReplay?: ReplayFull;
};

export interface ReplayDataFormValues {
  logTypes: string[];
  startsAt: string;
  endsAt: string;
}

export const formValuesValidation: Yup.SchemaOf<ReplayDataFormValues> = Yup.object()
  .shape({
    endsAt: Yup.string()
      .required()
      .test({
        name: 'end-after-start',
        test: v => dayjs(v).isBefore(dayjs()),
        message: 'end date must be after start date',
      }),
    startsAt: Yup.string()
      .required()
      .test({
        name: 'start-after-end',
        test: v => dayjs(v).isBefore(dayjs()),
        message: 'start date must be before end date',
      }),
    logTypes: Yup.array(Yup.string()).min(1).required(),
  })
  .test({
    name: 'start-and-end-different',
    test: ({ startsAt, endsAt }) => startsAt !== endsAt,
    message: 'start and end date must be different',
  });

const ReplayEditor: React.FC<ReplayDataFormProps> = ({
  onStop,
  logTypes,
  onCreate,
  initialLogTypes,
  disabled: globalDisabled,
  currentReplay,
}) => {
  const initialValues: ReplayDataFormValues = {
    logTypes: initialLogTypes || currentReplay?.scope.logTypes || [],
    endsAt: currentReplay?.scope.endsAt || '',
    startsAt: currentReplay?.scope.startsAt || '',
  };

  const [formValues, setFormValues] = React.useState<ReplayDataFormValues>({
    ...initialValues,
  });

  const onSubmit = (newFormValues: ReplayDataFormValues) => {
    setFormValues({ ...formValues, ...newFormValues });
  };

  const handleCreate = () => {
    onCreate(formValues.startsAt, formValues.endsAt, formValues.logTypes);
  };

  const handleStop = () => {
    onStop(currentReplay?.id);
  };

  const disabled =
    globalDisabled ||
    currentReplay?.state === ReplayState.EvaluationInProgress ||
    currentReplay?.state === ReplayState.ComputationInProgress;

  return (
    <Formik<ReplayDataFormValues>
      onSubmit={onSubmit}
      initialValues={initialValues}
      validateOnMount={true}
      validationSchema={formValuesValidation}
      enableReinitialize={false}
    >
      {({ values, isValid }) => {
        return (
          <Box>
            <FormikAutosave threshold={50} />
            <Box pt={4}>
              <Field
                as={FormikMultiCombobox}
                searchable
                label="Log Types"
                name="logTypes"
                items={logTypes ?? []}
                disabled={disabled}
                placeholder="Which log types should this rule apply to?"
              />
            </Box>
            <Box pt={4}>
              <Flex alignItems="center">
                <FormikDateRangeInput
                  withTime
                  // @ts-ignore
                  disabled={disabled}
                  format="MM/DD/YYYY HH:mm"
                  labelStart="Date Start"
                  labelEnd="Date End"
                  placeholderStart="MM/DD/YY HH:mm"
                  placeholderEnd="MM/DD/YY HH:mm"
                  nameStart="startsAt"
                  nameEnd="endsAt"
                />
                <ReplayEditorPreviewAndAction
                  onStop={handleStop}
                  values={values}
                  isValid={isValid}
                  onCreate={handleCreate}
                  currentReplay={currentReplay}
                  globalDisabled={globalDisabled}
                />
              </Flex>
            </Box>
            <ReplayEditorTimeWarning startsAt={values.startsAt} endsAt={values.endsAt} />
          </Box>
        );
      }}
    </Formik>
  );
};

export default React.memo(ReplayEditor);
