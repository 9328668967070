/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Field } from 'formik';
import * as Yup from 'yup';
import FormikTextInput from 'Components/fields/TextInput';
import SensitiveTextInput from 'Components/fields/SensitiveTextInput';
import { DestinationConfigInput } from 'Generated/schema';
import BaseDestinationForm, {
  BaseDestinationFormValues,
  defaultValidationSchema,
} from 'Components/forms/BaseDestinationForm';
import { SimpleGrid } from 'pouncejs';

type PagerDutyFieldValues = Pick<DestinationConfigInput, 'pagerDuty'>;

interface PagerDutyDestinationFormProps {
  initialValues: BaseDestinationFormValues<PagerDutyFieldValues>;
  onSubmit: (values: BaseDestinationFormValues<PagerDutyFieldValues>) => void;
}

const PagerDutyDestinationForm: React.FC<PagerDutyDestinationFormProps> = ({
  onSubmit,
  initialValues,
}) => {
  const existing = initialValues.outputId;
  const pagerDutyKey = Yup.string().length(32, 'Must be exactly 32 characters');
  const pagerDutyFieldsValidationSchema = Yup.object().shape({
    outputConfig: Yup.object().shape({
      pagerDuty: Yup.object().shape({
        integrationKey: existing ? pagerDutyKey : pagerDutyKey.required(),
      }),
    }),
  });

  const mergedValidationSchema = defaultValidationSchema.concat(pagerDutyFieldsValidationSchema);

  return (
    <BaseDestinationForm<PagerDutyFieldValues>
      initialValues={initialValues}
      validationSchema={mergedValidationSchema}
      onSubmit={onSubmit}
    >
      <SimpleGrid gap={5} columns={2}>
        <Field
          name="displayName"
          as={FormikTextInput}
          label="* Display Name"
          placeholder="How should we name this?"
          required
        />
        <Field
          as={SensitiveTextInput}
          shouldMask={!!existing}
          name="outputConfig.pagerDuty.integrationKey"
          label="Integration Key"
          placeholder="What's your PagerDuty Integration Key?"
          required={!existing}
          autoComplete="new-password"
        />
      </SimpleGrid>
    </BaseDestinationForm>
  );
};

export default PagerDutyDestinationForm;
