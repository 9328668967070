/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import * as Types from '../../../../../__generated__/schema';

import { LookupSyncJobDetails } from '../../../../graphql/fragments/LookupSyncJobDetails.generated';
import { GraphQLError } from 'graphql';
import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';

export type SyncLookupDataVariables = {
  input: Types.SyncLookupDataInput;
};

export type SyncLookupData = { syncLookupData: LookupSyncJobDetails };

export const SyncLookupDataDocument = gql`
  mutation SyncLookupData($input: SyncLookupDataInput!) {
    syncLookupData(input: $input) {
      ...LookupSyncJobDetails
    }
  }
  ${LookupSyncJobDetails}
`;
export type SyncLookupDataMutationFn = ApolloReactCommon.MutationFunction<
  SyncLookupData,
  SyncLookupDataVariables
>;

/**
 * __useSyncLookupData__
 *
 * To run a mutation, you first call `useSyncLookupData` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSyncLookupData` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [syncLookupData, { data, loading, error }] = useSyncLookupData({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSyncLookupData(
  baseOptions?: ApolloReactHooks.MutationHookOptions<SyncLookupData, SyncLookupDataVariables>
) {
  return ApolloReactHooks.useMutation<SyncLookupData, SyncLookupDataVariables>(
    SyncLookupDataDocument,
    baseOptions
  );
}
export type SyncLookupDataHookResult = ReturnType<typeof useSyncLookupData>;
export type SyncLookupDataMutationResult = ApolloReactCommon.MutationResult<SyncLookupData>;
export type SyncLookupDataMutationOptions = ApolloReactCommon.BaseMutationOptions<
  SyncLookupData,
  SyncLookupDataVariables
>;
export function mockSyncLookupData({
  data,
  variables,
  errors,
}: {
  data: SyncLookupData;
  variables?: SyncLookupDataVariables;
  errors?: GraphQLError[];
}) {
  return {
    request: { query: SyncLookupDataDocument, variables },
    result: { data, errors },
  };
}
