/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import * as Types from '../../../../../__generated__/schema';

import { GraphQLError } from 'graphql';
import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';

export type GetSQLForSnippetVariables = {
  input: Types.GenerateSqlQuerySnippetInput;
};

export type GetSQLForSnippet = {
  generateSqlQuerySnippet: { sql: Types.GenerateSqlQuerySnippetOutput['sqlQuery'] };
};

export const GetSQLForSnippetDocument = gql`
  query GetSQLForSnippet($input: GenerateSqlQuerySnippetInput!) {
    generateSqlQuerySnippet(input: $input) {
      sql: sqlQuery
    }
  }
`;

/**
 * __useGetSQLForSnippet__
 *
 * To run a query within a React component, call `useGetSQLForSnippet` and pass it any options that fit your needs.
 * When your component renders, `useGetSQLForSnippet` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSQLForSnippet({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetSQLForSnippet(
  baseOptions?: ApolloReactHooks.QueryHookOptions<GetSQLForSnippet, GetSQLForSnippetVariables>
) {
  return ApolloReactHooks.useQuery<GetSQLForSnippet, GetSQLForSnippetVariables>(
    GetSQLForSnippetDocument,
    baseOptions
  );
}
export function useGetSQLForSnippetLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSQLForSnippet, GetSQLForSnippetVariables>
) {
  return ApolloReactHooks.useLazyQuery<GetSQLForSnippet, GetSQLForSnippetVariables>(
    GetSQLForSnippetDocument,
    baseOptions
  );
}
export type GetSQLForSnippetHookResult = ReturnType<typeof useGetSQLForSnippet>;
export type GetSQLForSnippetLazyQueryHookResult = ReturnType<typeof useGetSQLForSnippetLazyQuery>;
export type GetSQLForSnippetQueryResult = ApolloReactCommon.QueryResult<
  GetSQLForSnippet,
  GetSQLForSnippetVariables
>;
export function mockGetSQLForSnippet({
  data,
  variables,
  errors,
}: {
  data: GetSQLForSnippet;
  variables?: GetSQLForSnippetVariables;
  errors?: GraphQLError[];
}) {
  return {
    request: { query: GetSQLForSnippetDocument, variables },
    result: { data, errors },
  };
}
