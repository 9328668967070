/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import * as Types from '../../../../../../../__generated__/schema';

import { M365LogIntegrationDetails } from '../../../../../../graphql/fragments/M365LogIntegrationDetails.generated';
import { GraphQLError } from 'graphql';
import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';

export type UpdateM365LogSourceVariables = {
  input: Types.UpdateLogPullingIntegrationInput;
};

export type UpdateM365LogSource = { updateLogPullingIntegration: M365LogIntegrationDetails };

export const UpdateM365LogSourceDocument = gql`
  mutation UpdateM365LogSource($input: UpdateLogPullingIntegrationInput!) {
    updateLogPullingIntegration(input: $input) {
      ...M365LogIntegrationDetails
    }
  }
  ${M365LogIntegrationDetails}
`;
export type UpdateM365LogSourceMutationFn = ApolloReactCommon.MutationFunction<
  UpdateM365LogSource,
  UpdateM365LogSourceVariables
>;

/**
 * __useUpdateM365LogSource__
 *
 * To run a mutation, you first call `useUpdateM365LogSource` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateM365LogSource` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateM365LogSource, { data, loading, error }] = useUpdateM365LogSource({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateM365LogSource(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateM365LogSource,
    UpdateM365LogSourceVariables
  >
) {
  return ApolloReactHooks.useMutation<UpdateM365LogSource, UpdateM365LogSourceVariables>(
    UpdateM365LogSourceDocument,
    baseOptions
  );
}
export type UpdateM365LogSourceHookResult = ReturnType<typeof useUpdateM365LogSource>;
export type UpdateM365LogSourceMutationResult = ApolloReactCommon.MutationResult<
  UpdateM365LogSource
>;
export type UpdateM365LogSourceMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateM365LogSource,
  UpdateM365LogSourceVariables
>;
export function mockUpdateM365LogSource({
  data,
  variables,
  errors,
}: {
  data: UpdateM365LogSource;
  variables?: UpdateM365LogSourceVariables;
  errors?: GraphQLError[];
}) {
  return {
    request: { query: UpdateM365LogSourceDocument, variables },
    result: { data, errors },
  };
}
