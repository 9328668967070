/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { ModalProps } from 'pouncejs';

export interface InlineModalProps extends Omit<ModalProps, 'open' | 'onClose'> {
  onClose?: () => void;
}
interface ModalStateShape {
  modalContent: React.ReactElement | null;
  modalProps: InlineModalProps;
  open: boolean;
}

/* initial state of the reducer */
const initialState: ModalStateShape = {
  modalContent: null,
  modalProps: {},
  open: false,
};

interface ModalContextValue {
  modalState: ModalStateShape;
  showModal: (content: React.ReactElement, modalProps?: InlineModalProps) => void;
  hideModal: () => void;
}

export const ModalContext = React.createContext<ModalContextValue>(undefined);

/* A enhanced version of the context provider */
export const ModalProvider: React.FC = ({ children }) => {
  const [modalState, setModalState] = React.useState<ModalStateShape>(initialState);

  const showModal = React.useCallback((content, modalProps = {}) => {
    setModalState({
      modalContent: content,
      modalProps,
      open: true,
    });
  }, []);

  const hideModal = React.useCallback(() => {
    setModalState(prev => ({ ...prev, open: false }));
  }, []);

  const contextValue = React.useMemo(() => ({ modalState, hideModal, showModal }), [
    modalState,
    showModal,
    hideModal,
  ]);

  // make the `state` and `dispatch` available to the components
  return <ModalContext.Provider value={contextValue}>{children}</ModalContext.Provider>;
};
