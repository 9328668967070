/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Field, FieldArray, useFormikContext } from 'formik';
import { Flex, Grid, IconButton, useSnackbar, AbstractButton } from 'pouncejs';
import { useListAvailableLogTypes } from 'Source/graphql/queries';
import FormikCombobox from 'Components/fields/ComboBox';
import { WizardPanel, useWizardContext } from 'Components/Wizard';
import LogTypeSelectorsField from 'Pages/Enrichment/components/LogTypeSelectorsField';
import LookupWizardContinueButton from '../LookupWizardContinueButton';
import {
  LookupFormValues,
  EMPTY_LOG_TYPE_MAPPING,
  LOOKUP_WIZARD_WIDTH,
} from '../lookupWizardHelpers';
import LookupPanelHeading from '../LookupPanelHeading';
import EditLookupActions from '../EditLookupActions';

const associatedLogTypesFields: (keyof LookupFormValues)[] = ['associatedLogTypes'];

const LookupAssociatedLogTypesPanel: React.FC = () => {
  const { pushSnackbar } = useSnackbar();
  const { values, initialValues } = useFormikContext<LookupFormValues>();
  const { goToNextStep } = useWizardContext<LookupFormValues>();

  const { data: availableLogTypes } = useListAvailableLogTypes({
    onError: () => pushSnackbar({ title: "Couldn't fetch your available log types" }),
  });

  const isEditing = Boolean(initialValues.id);

  return (
    <WizardPanel width={LOOKUP_WIZARD_WIDTH} margin="0 auto">
      <LookupPanelHeading
        title="Associated Log Types"
        subtitle="Tell us which logs you want to enrich with this lookup table as well as which columns match your lookup table."
      />

      <FieldArray
        name="associatedLogTypes"
        render={arrayHelpers => {
          return (
            <Flex as="ul" direction="column" spacing={2}>
              {values.associatedLogTypes.map((mapping, index) => (
                <Grid
                  as="li"
                  data-testid="associated-logtypes-listitem"
                  key={index}
                  templateColumns="5fr 9fr 1fr"
                  gap={4}
                  backgroundColor="navyblue-500"
                  p={4}
                  borderRadius="medium"
                >
                  <Field
                    data-tid="lookup-logtype-field"
                    as={FormikCombobox}
                    searchable
                    label="Log Type"
                    name={`associatedLogTypes[${index}].logType`}
                    items={availableLogTypes?.listAvailableLogTypes.logTypes ?? []}
                    placeholder="Which Log type should we check for?"
                  />
                  <LogTypeSelectorsField
                    name={`associatedLogTypes[${index}].selectors`}
                    logType={values.associatedLogTypes[index]?.logType}
                  />
                  <Flex spacing={2} mt={2}>
                    <IconButton
                      data-tid="lookup-remove-logtype"
                      variantColor="navyblue-300"
                      variantBorderStyle="circle"
                      size="medium"
                      icon="trash"
                      disabled={values.associatedLogTypes.length === 1}
                      aria-label="Remove mapping"
                      onClick={() => arrayHelpers.remove(index)}
                    />
                  </Flex>
                </Grid>
              ))}
              <Flex justifyContent="center" mt={2} width="100%">
                <AbstractButton
                  width="100%"
                  onClick={() => arrayHelpers.push(EMPTY_LOG_TYPE_MAPPING)}
                  height={48}
                  borderRadius="medium"
                  borderStyle="dashed"
                  borderColor="navyblue-300"
                  borderWidth="2px"
                  fontWeight="medium"
                  data-tid="lookup-add-logtype-field"
                >
                  Add Log Type
                </AbstractButton>
              </Flex>
            </Flex>
          );
        }}
      />

      {isEditing ? (
        <EditLookupActions />
      ) : (
        <Flex justify="center" mt={6}>
          <LookupWizardContinueButton onClick={goToNextStep} fields={associatedLogTypesFields} />
        </Flex>
      )}
    </WizardPanel>
  );
};

export default LookupAssociatedLogTypesPanel;
