/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { BUS_NAME_REGEX, LogTransportMethodsEnum } from 'Source/constants';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { EventbridgeLogIntegrationDetails } from 'Source/graphql/fragments/EventbridgeLogIntegrationDetails.generated';
import { Wizard, WizardPanel } from 'Components/Wizard';
import { yupIntegrationLabelUniqueness } from 'Helpers/utils';
import useFindSourcesPerMethod from 'Hooks/useFindSourcesPerMethod';
import CancelIntegrationAction from 'Pages/Integrations/components/CancelIntegrationAction';
import EventBridgeSourceConfigurationPanel from './EventBridgeSourceConfigurationPanel';
import ValidationPanel from './ValidationPanel';

interface EventbridgeWizardProps {
  initialValues: EventBridgeSourceWizardValues;
  onSubmit: (
    values: EventBridgeSourceWizardValues
  ) => Promise<EventbridgeLogIntegrationDetails | Error>;
  availableLogTypes: string[];
}

export interface EventBridgeSourceWizardValues {
  integrationId?: string; // for updates
  logType: string;
  integrationLabel: string;
  busName: string;
}

const EventBridgeSourceWizard: React.FC<EventbridgeWizardProps> = ({
  initialValues,
  onSubmit,
  availableLogTypes,
}) => {
  const existingEventbridgeSources = useFindSourcesPerMethod(LogTransportMethodsEnum.eventbridge);

  const validationSchema: Yup.SchemaOf<EventBridgeSourceWizardValues> = React.useMemo(
    () =>
      Yup.object().shape({
        integrationId: Yup.string(),
        integrationLabel: yupIntegrationLabelUniqueness({
          existingLabel: initialValues.integrationLabel,
          existingSources: existingEventbridgeSources,
          methodName: 'Eventbridge',
        }),
        logType: Yup.string().required(),
        busName: Yup.string()
          .matches(BUS_NAME_REGEX, 'Can only include numbers, lower/upper case letters, .,-_/')
          .max(256, 'Must be up to 256 characters')
          .required(),
      }),
    [initialValues.integrationLabel, existingEventbridgeSources]
  );

  const formStatus = React.useMemo(() => ({ availableLogTypes }), [availableLogTypes]);
  const editMode = !!initialValues.integrationLabel;
  return (
    <Formik<EventBridgeSourceWizardValues>
      enableReinitialize
      initialValues={initialValues}
      initialStatus={formStatus}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      <Form>
        <Wizard headerTitle="Connecting a new Log Source" enableAllNavigationSteps={editMode}>
          <Wizard.Step
            title="Configure Source"
            description="Provide account information and set preferences to connect the log source to Panther"
          >
            <WizardPanel>
              <EventBridgeSourceConfigurationPanel />
            </WizardPanel>
          </Wizard.Step>
          <Wizard.Step
            title="Verify Setup"
            description="Confirm that your log source has been successfully onboarded or troubleshoot any issues"
            navigationDisabled={editMode}
          >
            <ValidationPanel />
          </Wizard.Step>
        </Wizard>
        {!editMode && <CancelIntegrationAction />}
      </Form>
    </Formik>
  );
};

export default EventBridgeSourceWizard;
