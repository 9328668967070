/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import { History } from 'history';
import { GraphQLError } from 'graphql';
import { LocationErrorState } from 'Components/utils/ApiErrorFallback';
import { ErrorResponse, onError } from 'apollo-link-error';
import { logError } from 'Helpers/errors';
import { ApolloLink } from '@apollo/client';
import { redactSensitiveData, updatePageStatus } from './utils';

const ACCESS_DENIED_MESSAGE = 'access denied';

const isNestedAccessDeniedError = (error: Readonly<GraphQLError>) =>
  // Check if the error path length is > 1 to ensure that
  // the error was generated from a nested field and not from the root query
  error.path?.length > 1 && error.message === ACCESS_DENIED_MESSAGE;

/**
 * A link to react to GraphQL and/or network errors
 */
const createErrorLink = (history: History<LocationErrorState>) => {
  return (onError(({ graphQLErrors, networkError, operation, response }: ErrorResponse) => {
    // If the error is not considered a fail, then don't log it to sentry
    if (operation.getContext()?.failSilently) {
      return;
    }

    if (graphQLErrors) {
      graphQLErrors.forEach(error => {
        const statusCode = error.extensions?.statusCode;
        if (statusCode) {
          updatePageStatus(history, statusCode);
        }
      });
    }

    if (networkError) {
      const statusCode = 'statusCode' in networkError ? networkError.statusCode : null;
      if (statusCode >= 500) {
        redactSensitiveData(operation.variables);
        logError(new Error(networkError.message), { extras: { ...operation, ...networkError } });
      }

      if (statusCode) {
        updatePageStatus(history, statusCode);
      }
    }

    // If the response contains partial data and errors omit nested permission errors
    if (response?.data && response?.errors) {
      response.errors = response.errors.filter(e => !isNestedAccessDeniedError(e));
    }
  }) as unknown) as ApolloLink;
};

export default createErrorLink;
