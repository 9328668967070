/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import type { monaco } from 'react-monaco-editor';
import { EditorProps } from './Editor';

interface UseEditorCommandsProps {
  monacoInstance?: typeof monaco;
  editor?: monaco.editor.IStandaloneCodeEditor;
  commands: EditorProps['commands'];
}

const useEditorCommands = ({ monacoInstance, editor, commands }: UseEditorCommandsProps) => {
  React.useEffect(() => {
    if (!monacoInstance || !editor) {
      return;
    }

    const { KeyCode, KeyMod } = monacoInstance;
    const evaluatedCommands = commands({ KeyMod, KeyCode });

    evaluatedCommands.forEach(command => editor.addCommand(command.keyBindings, command.handler));
  }, [monacoInstance, editor, commands]);
};

export default useEditorCommands;
