/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import * as Types from '../../../../../../__generated__/schema';

import { GraphQLError } from 'graphql';
import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';

export type GetRuleAlertEventsVariables = {
  id: Types.Scalars['ID'];
  eventsInput: Types.GetAlertEventsInput;
};

export type GetRuleAlertEvents = {
  alert: Pick<Types.Alert, 'id'> & {
    origin: Pick<Types.AlertOriginRule, 'eventsMatched'> & {
      events?: Types.Maybe<Pick<Types.AlertEvents, 'items' | 'lastEvaluatedKey'>>;
    };
  };
};

export const GetRuleAlertEventsDocument = gql`
  query GetRuleAlertEvents($id: ID!, $eventsInput: GetAlertEventsInput!) {
    alert(id: $id) {
      id
      origin {
        ... on AlertOriginRule {
          eventsMatched
          events(input: $eventsInput) {
            items
            lastEvaluatedKey
          }
        }
      }
    }
  }
`;

/**
 * __useGetRuleAlertEvents__
 *
 * To run a query within a React component, call `useGetRuleAlertEvents` and pass it any options that fit your needs.
 * When your component renders, `useGetRuleAlertEvents` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRuleAlertEvents({
 *   variables: {
 *      id: // value for 'id'
 *      eventsInput: // value for 'eventsInput'
 *   },
 * });
 */
export function useGetRuleAlertEvents(
  baseOptions?: ApolloReactHooks.QueryHookOptions<GetRuleAlertEvents, GetRuleAlertEventsVariables>
) {
  return ApolloReactHooks.useQuery<GetRuleAlertEvents, GetRuleAlertEventsVariables>(
    GetRuleAlertEventsDocument,
    baseOptions
  );
}
export function useGetRuleAlertEventsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetRuleAlertEvents,
    GetRuleAlertEventsVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<GetRuleAlertEvents, GetRuleAlertEventsVariables>(
    GetRuleAlertEventsDocument,
    baseOptions
  );
}
export type GetRuleAlertEventsHookResult = ReturnType<typeof useGetRuleAlertEvents>;
export type GetRuleAlertEventsLazyQueryHookResult = ReturnType<
  typeof useGetRuleAlertEventsLazyQuery
>;
export type GetRuleAlertEventsQueryResult = ApolloReactCommon.QueryResult<
  GetRuleAlertEvents,
  GetRuleAlertEventsVariables
>;
export function mockGetRuleAlertEvents({
  data,
  variables,
  errors,
}: {
  data: GetRuleAlertEvents;
  variables?: GetRuleAlertEventsVariables;
  errors?: GraphQLError[];
}) {
  return {
    request: { query: GetRuleAlertEventsDocument, variables },
    result: { data, errors },
  };
}
