/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import urls from 'Source/urls';
import LinkButton from 'Components/buttons/LinkButton';
import TelescopeSVG from 'Assets/telescope.svg';
import { Box, Flex, Img, Text } from 'pouncejs';

import { LogPullerTypeEnum } from 'Generated/schema';
import { isLogSourceType, SourceIntegrationType } from 'Pages/Integrations/utils';
import TablePlaceholder from 'Components/TablePlaceholder';
import { useGetMitreLogSources } from '../graphql/getMitreLogSources.generated';

const MitreRelationsLogSourcesEmpty = () => {
  const { data, loading } = useGetMitreLogSources({
    errorPolicy: 'ignore',
  });

  if (loading && !data) {
    return (
      <Box pt={8}>
        <TablePlaceholder rowCount={2} rowHeight={40} />
      </Box>
    );
  }

  const hasCrowdstrikeOnboarded = (data?.logSources || []).find(s =>
    isLogSourceType(s as SourceIntegrationType, 'log-pulling', LogPullerTypeEnum.Crowdstrike)
  );

  return (
    <Box p={8} textAlign="center">
      <Box py={8}>
        <Img src={TelescopeSVG} nativeWidth={65} nativeHeight={65} alt="Telescope" />
      </Box>
      <Box pb={6}>
        {hasCrowdstrikeOnboarded ? (
          <Text>No log sources are mapped to this technique.</Text>
        ) : (
          <Text>
            The ATT&CK matrix currently supports Crowdstrike. Onboard this log source to receive
            partial coverage for qualifying techniques.
          </Text>
        )}
      </Box>
      <Flex justifyContent="center" spacing={4}>
        {!hasCrowdstrikeOnboarded && (
          <LinkButton to={urls.integrations.logSources.create(LogPullerTypeEnum.Crowdstrike)}>
            Onboard Crowdstrike
          </LinkButton>
        )}
        <LinkButton
          to={urls.integrations.logSources.list()}
          variant="outline"
          variantColor="navyblue-200"
        >
          View Log Sources
        </LinkButton>
      </Flex>
    </Box>
  );
};

export default MitreRelationsLogSourcesEmpty;
