/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import * as Types from '../../../../../../__generated__/schema';

import { GcsLogSourceIntegrationDetails } from '../../../../../graphql/fragments/GcsLogIntegrationDetails.generated';
import { GraphQLError } from 'graphql';
import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';

export type AddGcsLogIntegrationVariables = {
  input: Types.AddGcsLogIntegrationInput;
};

export type AddGcsLogIntegration = { addGcsLogIntegration: GcsLogSourceIntegrationDetails };

export const AddGcsLogIntegrationDocument = gql`
  mutation AddGcsLogIntegration($input: AddGcsLogIntegrationInput!) {
    addGcsLogIntegration(input: $input) {
      ...GcsLogSourceIntegrationDetails
    }
  }
  ${GcsLogSourceIntegrationDetails}
`;
export type AddGcsLogIntegrationMutationFn = ApolloReactCommon.MutationFunction<
  AddGcsLogIntegration,
  AddGcsLogIntegrationVariables
>;

/**
 * __useAddGcsLogIntegration__
 *
 * To run a mutation, you first call `useAddGcsLogIntegration` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddGcsLogIntegration` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addGcsLogIntegration, { data, loading, error }] = useAddGcsLogIntegration({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddGcsLogIntegration(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    AddGcsLogIntegration,
    AddGcsLogIntegrationVariables
  >
) {
  return ApolloReactHooks.useMutation<AddGcsLogIntegration, AddGcsLogIntegrationVariables>(
    AddGcsLogIntegrationDocument,
    baseOptions
  );
}
export type AddGcsLogIntegrationHookResult = ReturnType<typeof useAddGcsLogIntegration>;
export type AddGcsLogIntegrationMutationResult = ApolloReactCommon.MutationResult<
  AddGcsLogIntegration
>;
export type AddGcsLogIntegrationMutationOptions = ApolloReactCommon.BaseMutationOptions<
  AddGcsLogIntegration,
  AddGcsLogIntegrationVariables
>;
export function mockAddGcsLogIntegration({
  data,
  variables,
  errors,
}: {
  data: AddGcsLogIntegration;
  variables?: AddGcsLogIntegrationVariables;
  errors?: GraphQLError[];
}) {
  return {
    request: { query: AddGcsLogIntegrationDocument, variables },
    result: { data, errors },
  };
}
