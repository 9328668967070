/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Box, Flex, Button, Img, Text } from 'pouncejs';
import Dropzone, { FileRejection } from 'react-dropzone';
import folderIllustration from 'Assets/illustrations/folder.svg';

interface UploadDropzoneProps {
  onFilesDropped: (acceptedFiles: File[], rejectedFiles?: FileRejection[]) => void;
  acceptedFiles?: string;
  maxSizeInMB: number;
}

const MB_SIZE = 1048576;

const UploadDropzone: React.FC<UploadDropzoneProps> = ({
  onFilesDropped,
  acceptedFiles,
  maxSizeInMB,
}) => {
  const [isDragged, setDrag] = React.useState(false);
  return (
    <Flex justify="center">
      <Dropzone
        multiple={false}
        onDragOver={() => setDrag(true)}
        onDragLeave={() => setDrag(false)}
        onDrop={onFilesDropped}
        accept={acceptedFiles}
        maxSize={maxSizeInMB * MB_SIZE}
      >
        {({ getRootProps, getInputProps }) => (
          // @ts-ignore
          <Box
            data-testid="Drop files"
            borderWidth="1px"
            borderStyle="dashed"
            borderColor={isDragged ? 'navyblue-100' : 'navyblue-300'}
            textAlign="center"
            p={6}
            minWidth={600}
            {...getRootProps()}
          >
            <Text>Drag & drop your file here</Text>
            <Box p={6}>
              <Img src={folderIllustration} alt="File uploads" nativeWidth={75} nativeHeight={64} />
            </Box>
            <Text fontSize="small">or</Text>
            <input data-testid="input-upload" {...getInputProps()} />
            <Box mt={2}>
              <Button size="medium" data-tid="select-file-to-upload">
                Select file
              </Button>
            </Box>
          </Box>
        )}
      </Dropzone>
    </Flex>
  );
};

export default UploadDropzone;
