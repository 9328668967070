/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

type Func<T extends any[], R> = (...a: T) => R;

// TODO: fix Function types
// Don't use `Function` as a type. The `Function` type accepts any function-like value.
// It provides no type safety when calling the function, which can be a common source of bugs.
// It also accepts things like class declarations, which will throw at runtime as they will not be called with `new`.
// If you are expecting the function to accept certain arguments, you should explicitly define the function shape
//
/* eslint-disable @typescript-eslint/ban-types */

export function compose<A, B, C, T extends any[], R>(
  f1: (c: C) => R,
  f2: (b: B) => C,
  f3: (a: A) => B,
  f4: Func<T, A>
): Func<T, R>;

export function compose<R>(f1: (a: any) => R, ...funcs: Function[]): (...args: any[]) => R;
export function compose<R>(...funcs: Function[]): (...args: any[]) => R;

export function compose(...funcs: Function[]) {
  if (funcs.length === 0) {
    // infer the argument type so it is usable in inference down the line
    return <T>(arg: T) => arg;
  }

  if (funcs.length === 1) {
    return funcs[0];
  }

  return funcs.reduce((a, b) => (...args: any) => a(b(...args)));
}
