/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import * as Types from '../../../../__generated__/schema';

import { GeneralSettingsConfigFull } from '../../../graphql/fragments/GeneralSettingsConfigFull.generated';
import { GraphQLError } from 'graphql';
import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';

export type UpdateGeneralSettingsVariables = {
  input: Types.UpdateGeneralSettingsConfigInput;
};

export type UpdateGeneralSettings = { updateGeneralSettings: GeneralSettingsConfigFull };

export const UpdateGeneralSettingsDocument = gql`
  mutation UpdateGeneralSettings($input: UpdateGeneralSettingsConfigInput!) {
    updateGeneralSettings(input: $input) {
      ...GeneralSettingsConfigFull
    }
  }
  ${GeneralSettingsConfigFull}
`;
export type UpdateGeneralSettingsMutationFn = ApolloReactCommon.MutationFunction<
  UpdateGeneralSettings,
  UpdateGeneralSettingsVariables
>;

/**
 * __useUpdateGeneralSettings__
 *
 * To run a mutation, you first call `useUpdateGeneralSettings` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateGeneralSettings` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateGeneralSettings, { data, loading, error }] = useUpdateGeneralSettings({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateGeneralSettings(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateGeneralSettings,
    UpdateGeneralSettingsVariables
  >
) {
  return ApolloReactHooks.useMutation<UpdateGeneralSettings, UpdateGeneralSettingsVariables>(
    UpdateGeneralSettingsDocument,
    baseOptions
  );
}
export type UpdateGeneralSettingsHookResult = ReturnType<typeof useUpdateGeneralSettings>;
export type UpdateGeneralSettingsMutationResult = ApolloReactCommon.MutationResult<
  UpdateGeneralSettings
>;
export type UpdateGeneralSettingsMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateGeneralSettings,
  UpdateGeneralSettingsVariables
>;
export function mockUpdateGeneralSettings({
  data,
  variables,
  errors,
}: {
  data: UpdateGeneralSettings;
  variables?: UpdateGeneralSettingsVariables;
  errors?: GraphQLError[];
}) {
  return {
    request: { query: UpdateGeneralSettingsDocument, variables },
    result: { data, errors },
  };
}
