/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import { ReplayPreview } from 'Generated/schema';
import React from 'react';
import { Flex, Icon, Spinner, Text } from 'pouncejs';
import { formatBytes } from 'Helpers/utils';
import ElapsedTime from 'Components/ElapsedTime';
import DataScanned from 'Components/DataScanned';

export type ReplayEditorPreviewProps = {
  hasError: boolean;
  loading: boolean;
  preview?: ReplayPreview;
};

const replaySizeLimitBytes = 20 * 1024 * 1024 * 1024; // 20 GB

const ReplayEditorPreview: React.FC<ReplayEditorPreviewProps> = ({
  preview,
  hasError,
  loading,
}) => {
  if (loading) {
    return (
      <Flex alignItems="center" pr={4}>
        <Spinner size="small" mr={2} />
        <Text size="x-small">Estimating...</Text>
      </Flex>
    );
  }

  if (hasError) {
    return (
      <Flex alignItems="center" pr={4}>
        <Icon size="medium" type="close-outline" mr={2} color="red-200" />
        <Text size="x-small" color="red-200">
          Error scoping replay. Please try adjusting your time range.
        </Text>
      </Flex>
    );
  }

  if (!preview) {
    return null;
  }

  if (preview.logDataSizeEstimate > replaySizeLimitBytes) {
    return (
      <Flex alignItems="center" pr={4}>
        <Icon size="medium" type="close-outline" mr={2} color="red-200" />
        <Text fontSize="medium" color="red-200">
          Data in the selected range ({formatBytes(preview.logDataSizeEstimate)}) exceeds the replay
          max of {formatBytes(replaySizeLimitBytes)}.
        </Text>
      </Flex>
    );
  }

  if (preview.logDataSizeEstimate === 0) {
    return (
      <Flex alignItems="center" pr={4}>
        <Icon size="medium" type="close-outline" mr={2} color="red-200" />
        <Text fontSize="medium" color="red-200">
          No data in time range for the selected log types.
        </Text>
      </Flex>
    );
  }

  // don't estimate below 60 seconds in the UI
  const runTimeSeconds = preview.timeEstimate < 60 ? 60 : preview.timeEstimate;

  return (
    <Flex>
      <ElapsedTime label="Est. Run Time with 5% Alert Rate" ms={runTimeSeconds * 1000} />
      <DataScanned label="Est. Data Volume" bytes={preview.logDataSizeEstimate} />
    </Flex>
  );
};

export default ReplayEditorPreview;
