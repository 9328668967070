/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import { ReplayState } from 'Generated/schema';
import { Box, Card, Flex, IconButton } from 'pouncejs';
import React from 'react';
import { ReplayFull } from 'Source/graphql/fragments/ReplayFull.generated';
import ReplayStatusTitle from './ReplayStatusTitle';
import ReplayStatusSummary from './ReplayStatusSummary';
import ReplaySetupStageView from './ReplaySetupStageView';
import ReplayEvaluationStageView from './ReplayEvaluationStageView';
import ReplayComputationStageView from './ReplayComputationStageView';

type ReplayStatusViewProps = {
  replay?: ReplayFull;
};

const ReplayStatus: React.FC<ReplayStatusViewProps> = ({ replay }) => {
  const replayDone = replay?.state === ReplayState.Done;
  const [open, setOpen] = React.useState<boolean>(!replayDone);

  // auto-hide once complete
  React.useEffect(() => {
    if (replayDone) {
      setOpen(false);
    }
  }, [replayDone]);

  const showSummary = !replayDone || open;

  return (
    <Card variant="dark" p={4} mb={4}>
      <Flex>
        <Box width="55%" pr={8}>
          <Flex alignItems="center">
            {replayDone && (
              <Box mr={4}>
                <IconButton
                  variant="ghost"
                  variantBorderStyle="circle"
                  active={open}
                  variantColor="navyblue-700"
                  icon={open ? 'caret-up' : 'caret-down'}
                  onClick={() => setOpen(!open)}
                  size="medium"
                  aria-label="Toggle Replay Status Visibility"
                />
              </Box>
            )}
            <ReplayStatusTitle replay={replay} />
          </Flex>
          {showSummary && <ReplayStatusSummary replay={replay} />}
        </Box>
        {showSummary && (
          <Flex as="ul" spacing={0} direction="column" style={{ flexGrow: 1 }}>
            <ReplaySetupStageView replay={replay} />
            <ReplayEvaluationStageView replay={replay} />
            <ReplayComputationStageView replay={replay} />
          </Flex>
        )}
      </Flex>
    </Card>
  );
};

export default React.memo(ReplayStatus);

export type ReplayStageState = 'complete' | 'failed' | 'running' | 'queued';

export type ReplayStageViewProps = {
  replay?: ReplayFull;
};
