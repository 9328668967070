/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { SavedQuery } from 'Generated/schema';
import ConfirmModal from 'Components/modals/ConfirmModal';
import { useSnackbar } from 'pouncejs';
import { useDeleteSavedQueries } from 'Source/graphql/queries';
import { extractErrorMessage } from 'Helpers/utils';

export interface DeleteSavedQueriesModalProps {
  ids: SavedQuery['id'][];
  onConfirm?: () => void;
}

const DeleteSavedQueriesModal: React.FC<DeleteSavedQueriesModalProps> = ({
  ids,
  onConfirm = () => {},
  ...rest
}) => {
  const { pushSnackbar } = useSnackbar();
  const [deleteSavedQueries] = useDeleteSavedQueries({
    update: cache => {
      ids.forEach(id => {
        cache.evict({ id: cache.identify({ __typename: 'SavedQuery', id }) });
      });
      cache.gc();
    },
    onCompleted: () => {
      onConfirm();
      pushSnackbar({
        variant: 'success',
        title: `${
          ids.length === 1 ? 'Saved Query ' : `${ids.length} Saved queries`
        } deleted successfully`,
      });
    },
    onError: error => {
      pushSnackbar({
        variant: 'error',
        title: `Failed delete saved queries`,
        description: extractErrorMessage(error),
      });
    },
  });

  const onDelete = React.useCallback(
    () =>
      deleteSavedQueries({
        variables: { input: { ids } },
      }),
    // FIXME: look into hook dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [ids]
  );

  return (
    <ConfirmModal
      variant="destructive"
      subtitle={`Are you sure you want to delete ${
        ids.length === 1
          ? 'the selected Saved Query?'
          : `these (${ids.length}) selected Saved Queries`
      }`}
      onConfirm={onDelete}
      {...rest}
    />
  );
};

export default DeleteSavedQueriesModal;
