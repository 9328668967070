/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Field, FormikErrors, useFormikContext } from 'formik';
import { Box, Flex, FormHelperText, Link } from 'pouncejs';

import logo from 'Assets/logProviders/cloudwatch.svg';
import ErrorBoundary from 'Components/ErrorBoundary';
import FormikTextInput from 'Components/fields/TextInput';
import FormikMultiCombobox from 'Components/fields/MultiComboBox';
import { WizardPanel } from 'Components/Wizard';
import EditIntegrationActions from 'Pages/Integrations/components/EditIntegrationActions';
import { CloudWatchLogSourceWizardValues } from '../CloudWatchLogSourceWizard';

const canContinueWizard = (errors: FormikErrors<CloudWatchLogSourceWizardValues>) => {
  if (
    errors.integrationLabel ||
    errors.awsAccountId ||
    errors.filterPattern ||
    errors.logGroup ||
    errors.logTypes
  ) {
    return false;
  }
  return true;
};

const SourceConfigurationPanel: React.FC = () => {
  const { initialValues, dirty, errors, status } = useFormikContext<
    CloudWatchLogSourceWizardValues
  >();
  const editMode = !!initialValues.integrationId;

  return (
    <WizardPanel>
      <Box width={655} m="auto">
        <WizardPanel.Heading
          title={editMode ? 'Update your source' : 'Configure your source'}
          subtitle={
            editMode
              ? 'Feel free to make any changes to your log source'
              : 'We need to know where to get your logs from'
          }
          logo={logo}
          divider={null}
          subtitleProps={{ color: 'gray-300', fontWeight: 'normal' }}
        />

        <ErrorBoundary>
          <Flex direction="column" spacing={4}>
            <Field
              name="integrationLabel"
              as={FormikTextInput}
              label="Name"
              placeholder="A nickname for this log source"
              required
            />
            <Field
              name="logGroup"
              as={FormikTextInput}
              label="Log Group Name"
              placeholder="The unique name of the CloudWatch Log group"
              required
            />
            <Field
              name="awsAccountId"
              as={FormikTextInput}
              label="AWS Account ID"
              placeholder="The AWS Account ID that the CloudWatch log group lives in"
              disabled={editMode}
              required
            />
            <Box>
              <Field
                name="filterPattern"
                as={FormikTextInput}
                label="Pattern Filter (optional)"
                placeholder="Filter the log data received from CloudWatch Logs"
                aria-describedby="filter-pattern-helper"
              />
              <FormHelperText pt={2} id="filter-pattern-helper">
                Read more on
                <Link
                  external
                  href="https://docs.aws.amazon.com/AmazonCloudWatch/latest/logs/FilterAndPatternSyntax.html"
                  ml={1}
                >
                  AWS pattern filter documentation
                </Link>
              </FormHelperText>
            </Box>

            <Field
              as={FormikMultiCombobox}
              searchable
              placeholder="The types of logs that are collected"
              label="Log Types"
              name="logTypes"
              items={status.availableLogTypes}
              itemToGroup={(item: string) => item.split('.')[0]}
              required
            />
          </Flex>
        </ErrorBoundary>
      </Box>
      {editMode ? (
        <EditIntegrationActions />
      ) : (
        <WizardPanel.Actions>
          <WizardPanel.ActionNext disabled={!(editMode || dirty) || !canContinueWizard(errors)}>
            Continue Setup
          </WizardPanel.ActionNext>
        </WizardPanel.Actions>
      )}
    </WizardPanel>
  );
};

export default SourceConfigurationPanel;
