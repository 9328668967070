/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Box, Flex } from 'pouncejs';

const minTwoDigits = (n: number) => n.toString().padStart(2, '0');

const ElapsedTimePartialValue: React.FC<{ units: string; children: React.ReactNode }> = ({
  units,
  children,
}) => {
  return (
    <>
      <Box as="b" ml={2} fontSize="2x-large" color="blue-400">
        {children}
      </Box>
      {units}
    </>
  );
};

interface ElapsedTimeProps {
  /** The raw amount of time elapsed, in milliseconds. */
  ms: number;
  /** The displayed label. Defaults to "Query Time"  */
  label?: string;
}

const defaultProps = {
  label: 'Query Time',
};

/**
 * Take an amount of time, in milliseconds, and format it to a human-readable
 * display of how long a query took to run.
 */
const ElapsedTime: React.FC<ElapsedTimeProps> = ({ ms, label }) => {
  const seconds = Math.floor(ms / 1000);
  const minutesDisplay = minTwoDigits(Math.floor(seconds / 60));
  const secondsDisplay = minTwoDigits(seconds % 60);

  const defaultedLabel = label || defaultProps.label;

  return (
    <Flex
      as="dl"
      align="center"
      justifyContent="center"
      fontSize="small"
      px={4}
      py={2}
      minWidth={195}
    >
      <dt>{defaultedLabel}</dt>
      <Flex as="dd" align="center">
        {/**
         * If the query resolves in less than a second, show the time as milliseconds
         *
         * If the API sends a negative value for `ms`, fails loudly by showing the
         * value (in milliseconds regardless of amount) instead of suppressing it.
         */}
        {ms !== 0 && ms < 1000 ? (
          <ElapsedTimePartialValue units="ms">
            {ms.toString().padStart(3, '0')}
          </ElapsedTimePartialValue>
        ) : (
          <Flex as="time" align="center">
            <ElapsedTimePartialValue units="m">{minutesDisplay}</ElapsedTimePartialValue>
            <ElapsedTimePartialValue units="s">{secondsDisplay}</ElapsedTimePartialValue>
          </Flex>
        )}
      </Flex>
    </Flex>
  );
};

export default ElapsedTime;
