/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Field, Formik } from 'formik';
import * as Yup from 'yup';
import { SortDirEnum } from 'Generated/schema';
import FormikTextInput from 'Components/fields/TextInput';
import FormikCombobox from 'Components/fields/ComboBox';
import debounce from 'lodash/debounce';
import { Flex } from 'pouncejs';

export interface RoleFilterValues {
  sortDir?: SortDirEnum;
  nameContains?: string;
}

interface RoleFiltersProps {
  initialValues: RoleFilterValues;
  onSubmit: (values: RoleFilterValues) => void;
}

const sortDirOptions = Object.values(SortDirEnum);
const sortDirToString = (dir: SortDirEnum | null) => (dir ? `Name ${dir}` : '');

const validationSchema = Yup.object({
  sortDir: Yup.string().nullable(),
  nameContains: Yup.string(),
});

const RoleFilters: React.FC<RoleFiltersProps> = ({ initialValues, onSubmit }) => {
  return (
    <Formik<RoleFilterValues>
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ values, submitForm, handleSubmit }) => {
        // FIXME: look into rules of hooks error
        /* eslint-disable react-hooks/exhaustive-deps, react-hooks/rules-of-hooks */
        const debouncedSubmitForm = React.useCallback(
          debounce(() => {
            submitForm();
          }, 150),
          [submitForm]
        );
        /* eslint-enable react-hooks/exhaustive-deps, react-hooks/rules-of-hooks */

        // FIXME: Look into rules of hooks and effect dep linter violations
        /* eslint-disable react-hooks/exhaustive-deps, react-hooks/rules-of-hooks */
        React.useEffect(debouncedSubmitForm, [values, submitForm]);
        /* eslint-enable react-hooks/exhaustive-deps, react-hooks/rules-of-hooks */

        return (
          <Flex as="form" onSubmit={handleSubmit}>
            <Flex spacing={4}>
              <Field
                data-tid="roles-nameContains-filter"
                as={FormikTextInput}
                name="nameContains"
                label="Name Contains"
                placeholder="Search for roles..."
              />
              <Field
                data-tid="roles-sorting-options-combobox"
                name="sortDir"
                as={FormikCombobox}
                label="Sort By"
                itemToString={sortDirToString}
                items={sortDirOptions}
                placeholder="Select an option..."
              />
            </Flex>
          </Flex>
        );
      }}
    </Formik>
  );
};

export default RoleFilters;
