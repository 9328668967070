/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import { FieldFunctionOptions } from '@apollo/client';
import walk from 'Helpers/walk';
import sensitiveFields from 'Generated/sensitiveFields.generated.json';
import set from 'lodash/set';
import get from 'lodash/get';
import { History } from 'history';
import { DEFAULT_SENSITIVE_VALUE } from 'Source/constants';

export const endlessPaginationDefaultMerge = <T extends Record<any, any>>(
  field: keyof T,
  getPaginationKey = (variables: Record<string, any>) => variables.input?.exclusiveStartKey
) => (existing: T, incoming: T, { variables }: FieldFunctionOptions): T => {
  // if no data exists, fetch it from the server
  if (!existing) {
    return incoming;
  }

  // if data exists, but we are refetching the first page, then clear everything and just
  // keep this first page
  if (!getPaginationKey(variables)) {
    return incoming;
  }

  // if data exists and we are fetching some page, append its results to the existing set
  const existingPaginatedItems = existing[field] as Array<any>;
  const incomingPaginatedItems = incoming[field] as Array<any>;
  return {
    ...incoming,
    [field]: [...existingPaginatedItems, ...incomingPaginatedItems],
  };
};

export const redactSensitiveData = (
  data: Record<string, unknown>,
  redactValue = DEFAULT_SENSITIVE_VALUE
) => {
  const payloadPaths: string[] = [];
  const sensitiveFieldNameSet = new Set<string>();

  walk(data, path => payloadPaths.push(path));
  walk(sensitiveFields, path => sensitiveFieldNameSet.add(get(sensitiveFields, path)));

  // In order to know which data to redact we compare the value of leaf keys between the actual
  // payload and the sensitive paths which are in the style of `{type}.{field}`. This guarantees
  // that sensitive fields will be redacted, but it will mean that some non-sensitive fields with
  // the same name as some sensitive fields may be redacted as well.
  // For now, we are ok with this since handling this scenario would be too much effort (it's not
  // easy to get the necessary data for this)
  const redactedFieldNameList = [...sensitiveFieldNameSet];
  const pathsToRedact = payloadPaths.filter(payloadPath => {
    return redactedFieldNameList.some(redactedFieldName => payloadPath.includes(redactedFieldName));
  });

  pathsToRedact.forEach(pathToRedact => {
    set(data, pathToRedact, redactValue);
  });

  return data;
};

export const updatePageStatus = (history: History, statusCode: number) => {
  history.replace({
    ...history.location,
    state: { ...history.location.state, statusCode },
  });
};
