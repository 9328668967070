/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { SAAS_ONBOARDING_CROWDSTRIKE_DOC_URL } from 'Source/constants';
import { Box, Flex, FormHelperText, Link, Text } from 'pouncejs';
import crowdstrikeLogo from 'Assets/crowdstrike-minimal-logo.svg';
import ErrorBoundary from 'Components/ErrorBoundary';
import { Field, useFormikContext } from 'formik';
import FormikTextInput from 'Components/fields/TextInput';
import { WizardPanel } from 'Components/Wizard';
import SensitiveTextInput from 'Components/fields/SensitiveTextInput';
import EditIntegrationActions from 'Pages/Integrations/components/EditIntegrationActions';
import { CrowdstrikeLogsSourceWizardValues } from '../CrowdstrikeLogsSourceWizard';

const CrowdstrikeLogsSourceConfigurationPanel: React.FC = () => {
  const { initialValues, dirty, isValid } = useFormikContext<CrowdstrikeLogsSourceWizardValues>();

  const editMode = !!initialValues.integrationId;
  return (
    <WizardPanel>
      <Box width={500} m="auto" aria-describedby="crowdstrike form helper">
        <WizardPanel.Heading
          title={editMode ? 'Update Crowdstrike Logs source' : 'Configure your source below'}
          subtitle={
            editMode
              ? 'Feel free to make any changes to your Crowdstrike log source'
              : 'We need to know where to get your logs from'
          }
          logo={crowdstrikeLogo}
        />
        <ErrorBoundary>
          <Flex direction="column" spacing={4} width={1}>
            <Field
              name="integrationLabel"
              as={FormikTextInput}
              label="Name"
              placeholder="A nickname for your Crowdstrike log source"
              required
            />
            <Field
              label="SQS Url"
              name="queueUrl"
              as={FormikTextInput}
              placeholder="Your SQS Url"
              required
            />
            <Field
              name="awsAccessKey"
              as={SensitiveTextInput}
              shouldMask={!!editMode}
              label="AWS Access Key"
              placeholder="What's your AWS Access key?"
              required={!editMode}
            />
            <Field
              name="awsAccessSecret"
              as={SensitiveTextInput}
              shouldMask={!!editMode}
              label="AWS Access Secret"
              placeholder="What's your AWS Access secret?"
              required={!editMode}
            />
            <FormHelperText id="crowdstrike form helper" textAlign="center" mt={2}>
              Need help to setup Crowdstrike? Check
              <Link external href={SAAS_ONBOARDING_CROWDSTRIKE_DOC_URL} ml={1}>
                our documentation
              </Link>
            </FormHelperText>
          </Flex>
        </ErrorBoundary>
      </Box>
      {editMode ? (
        <EditIntegrationActions />
      ) : (
        <WizardPanel.Actions>
          <WizardPanel.ActionNext disabled={!dirty || !isValid}>
            Continue Setup
          </WizardPanel.ActionNext>
        </WizardPanel.Actions>
      )}
      <Text mt={4} fontSize="medium" textAlign="center" color="gray-300">
        Panther uses the Crowdstrike Falcon Data Replicator (FDR) to pull all telemetry for
        analysis.
      </Text>
    </WizardPanel>
  );
};

export default CrowdstrikeLogsSourceConfigurationPanel;
