/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { __RouterContext as RouterContext, RouteComponentProps } from 'react-router';

function useRouter<
  Params = Record<string, string | number>,
  StateShape = Record<string, unknown>
>() {
  return React.useContext(RouterContext) as RouteComponentProps<Params, undefined, StateShape>;
}

export default useRouter;
