/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { FastField, useFormikContext } from 'formik';
import { Flex, SimpleGrid, Box } from 'pouncejs';
import FormikTextInput from 'Components/fields/TextInput';
import FormikSwitch from 'Components/fields/Switch';
import FormikTextArea from 'Components/fields/TextArea';
import { WizardPanel, useWizardContext } from 'Components/Wizard';
import LookupWizardContinueButton from '../LookupWizardContinueButton';
import { LookupFormValues, LOOKUP_WIZARD_WIDTH } from '../lookupWizardHelpers';
import LookupPanelHeading from '../LookupPanelHeading';
import EditLookupActions from '../EditLookupActions';

const basicInfoFields: (keyof LookupFormValues)[] = ['name', 'description', 'reference', 'enabled'];

const LookupBasicInfoPanel: React.FC = () => {
  const { goToNextStep } = useWizardContext<LookupFormValues>();
  const { initialValues } = useFormikContext<LookupFormValues>();
  const isEditing = Boolean(initialValues.id);

  return (
    <WizardPanel width={LOOKUP_WIZARD_WIDTH} margin="0 auto">
      <LookupPanelHeading
        title="Lookup Table Basic Information"
        subtitle="A Lookup Table allows you to enrich log events, detections, and alerts with contextual data you import via a CSV file. Get started by filling in some basic information about your table. "
      />

      <Flex spacing={6}>
        <Box flexGrow={1}>
          <FastField
            data-tid="lookup-name-field"
            name="name"
            as={FormikTextInput}
            label="Lookup Name"
            placeholder="A nickname for the lookup"
            required
            // Editing the name of the lookup will force an expensive operation to update the
            // tables in the datalake. For now, disable users from editing the name until
            // the save operation has been properly async-ified.
            disabled={isEditing}
          />
        </Box>

        <Box mt="11px">
          <FastField
            data-tid="lookup-enabled-field"
            as={FormikSwitch}
            label="Enabled?"
            name="enabled"
            checkedText="YES"
            uncheckedText="NO"
          />
        </Box>
      </Flex>
      <SimpleGrid columns={2} spacing={4} mt={4}>
        <FastField
          data-tid="lookup-description-field"
          name="description"
          as={FormikTextArea}
          minRows={4}
          label="Description - Optional"
          placeholder="A description for the lookup"
        />
        <FastField
          data-tid="lookup-reference-field"
          name="reference"
          as={FormikTextArea}
          minRows={4}
          label="Reference - Optional"
          placeholder="A link to external docs on this lookup table"
        />
      </SimpleGrid>

      {isEditing ? (
        <EditLookupActions />
      ) : (
        <Flex justify="center" mt={6}>
          <LookupWizardContinueButton fields={basicInfoFields} onClick={goToNextStep} />
        </Flex>
      )}
    </WizardPanel>
  );
};

export default LookupBasicInfoPanel;
