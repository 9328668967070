/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import * as Types from '../../../__generated__/schema';

import { AnalysisPackDetails } from '../fragments/AnalysisPackDetails.generated';
import { GraphQLError } from 'graphql';
import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';

export type UpdateAnalysisPackVariables = {
  input: Types.UpdateAnalysisPackInput;
};

export type UpdateAnalysisPack = { updateAnalysisPack: AnalysisPackDetails };

export const UpdateAnalysisPackDocument = gql`
  mutation UpdateAnalysisPack($input: UpdateAnalysisPackInput!) {
    updateAnalysisPack(input: $input) {
      ...AnalysisPackDetails
    }
  }
  ${AnalysisPackDetails}
`;
export type UpdateAnalysisPackMutationFn = ApolloReactCommon.MutationFunction<
  UpdateAnalysisPack,
  UpdateAnalysisPackVariables
>;

/**
 * __useUpdateAnalysisPack__
 *
 * To run a mutation, you first call `useUpdateAnalysisPack` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAnalysisPack` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAnalysisPack, { data, loading, error }] = useUpdateAnalysisPack({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAnalysisPack(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateAnalysisPack,
    UpdateAnalysisPackVariables
  >
) {
  return ApolloReactHooks.useMutation<UpdateAnalysisPack, UpdateAnalysisPackVariables>(
    UpdateAnalysisPackDocument,
    baseOptions
  );
}
export type UpdateAnalysisPackHookResult = ReturnType<typeof useUpdateAnalysisPack>;
export type UpdateAnalysisPackMutationResult = ApolloReactCommon.MutationResult<UpdateAnalysisPack>;
export type UpdateAnalysisPackMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateAnalysisPack,
  UpdateAnalysisPackVariables
>;
export function mockUpdateAnalysisPack({
  data,
  variables,
  errors,
}: {
  data: UpdateAnalysisPack;
  variables?: UpdateAnalysisPackVariables;
  errors?: GraphQLError[];
}) {
  return {
    request: { query: UpdateAnalysisPackDocument, variables },
    result: { data, errors },
  };
}
