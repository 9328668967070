/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Flex, Button } from 'pouncejs';
import { useFormikContext } from 'formik';
import useRouter from 'Hooks/useRouter';
import urls from 'Source/urls';
import { WizardPanel } from 'Components/Wizard';
import { extractErrorMessage } from 'Helpers/utils';
import { JobStatus } from 'Generated/schema';
import { LookupFormValues, LOOKUP_WIZARD_WIDTH } from '../lookupWizardHelpers';
import LookupUploaderError from './LookupUploaderError';
import LookupUploaderInput from './LookupUploaderInput';
import LookupUploaderProcessing from './LookupUploaderProcessing';
import LookupUploaderSuccess from './LookupUploaderSuccess';
import LookupUploaderDisabled from './LookupUploaderDisabled';
import LookupPanelHeading from '../LookupPanelHeading';
import { useImportLookupData } from './graphql/importLookupData.generated';
import { useCheckLookupImportDataJob } from './graphql/checkLookupImportDataJob.generated';
import { useGenerateLookupImportUrl } from './graphql/generateLookupImportUrl.generated';
import EditLookupActions from '../EditLookupActions';

enum UploadState {
  DISABLED = 'DISABLED',
  PENDING = 'PENDING',
  PROCESSING = 'PROCESSING',
  ERROR = 'ERROR',
  SUCCESS = 'SUCCESS',
}

interface LookupImportDataPanelProps {
  onSuccess?: () => void;
}

const uploadStatusToJobStatusMap: Record<JobStatus, UploadState> = {
  [JobStatus.Running]: UploadState.PROCESSING,
  [JobStatus.Failed]: UploadState.ERROR,
  [JobStatus.Succeeded]: UploadState.SUCCESS,
  [JobStatus.Cancelled]: UploadState.PENDING,
};

const LookupImportDataPanel: React.FC<LookupImportDataPanelProps> = () => {
  const { values, initialValues } = useFormikContext<LookupFormValues>();
  const { history } = useRouter();

  const isEditing = Boolean(initialValues.id);
  const lookupId = values.id;

  const [uploadingState, setUploadingState] = React.useState<UploadState>(
    values.enabled ? UploadState.PENDING : UploadState.DISABLED
  );
  const [errorMsg, setErrorMsg] = React.useState('');

  const [generateS3UploadURL] = useGenerateLookupImportUrl();

  const [importLookupData, { data: importDataJob }] = useImportLookupData({
    onCompleted: () => {
      setErrorMsg('');
      setUploadingState(UploadState.PROCESSING);
    },
    onError: error => {
      setErrorMsg(extractErrorMessage(error) || 'An unknown error occurred during the upload');
      setUploadingState(UploadState.ERROR);
    },
  });
  const { data: jobData, error: jobError, stopPolling, client } = useCheckLookupImportDataJob({
    skip: !importDataJob?.importLookupData?.id,
    variables: {
      jobId: importDataJob?.importLookupData?.id,
    },
    pollInterval: 1000,
    // We always want the latest job execution details, so don't use the cache at all.
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });

  React.useEffect(() => {
    const status = jobData?.checkLookupImportDataJob?.status;

    // If the step function is no longer running OR if the GraphQL API itself failed
    // we need to stop polling since the import job is "over"
    if (status !== JobStatus.Running || jobError) {
      stopPolling();
    }

    // If the import job succeeded, we need to refresh the lookup table with the new row count
    if (status === JobStatus.Succeeded) {
      client.cache.evict({ id: client.cache.identify({ __typename: 'Lookup', id: lookupId }) });
    }

    // If the lambda itself returned an error, make sure that error is displayed to the user.
    if (status === JobStatus.Failed) {
      setErrorMsg(jobData?.checkLookupImportDataJob?.message);
    }

    // If the GraphQL API itself returned an error, make sure that error is also displayed to the user.
    // In addition, we'll need to set the uploading state manually since in this case we failed to get
    // a job status update from the GraphQL API.
    if (jobError) {
      setErrorMsg(extractErrorMessage(jobError) || 'An unknown error occurred');
      setUploadingState(UploadState.ERROR);
    }

    // Finally, if we got a status successfully from the GraphQL API, we can update the uploading state accordingly.
    if (!jobError && status) {
      setUploadingState(uploadStatusToJobStatusMap[status]);
    }
  }, [jobData, jobError, stopPolling, client, lookupId]);

  const onAbort = React.useCallback(() => {
    // Stop polling to avoid memory leaks
    stopPolling();

    // Reset to pending state
    setUploadingState(UploadState.PENDING);
  }, [stopPolling]);

  const onFilesDropped = React.useCallback(
    acceptedFiles => {
      if (!acceptedFiles || acceptedFiles?.length !== 1) {
        return;
      }

      const [file] = acceptedFiles;

      const reader = new FileReader();
      reader.readAsText(file);

      // When the read has finished we do a few API calls in sequence:
      // 1. Obtain a presigned S3 URL where we can upload the lookup table's data
      // 2. Upload the file's data to S3 directly. It's important that we do this directly to S3 to step around the 6MB
      //     limit imposed by AWS lambda.
      // 3. Tell the lookup table about the S3 URL so it can begin importing data.
      reader.addEventListener('load', async () => {
        setUploadingState(UploadState.PROCESSING);

        try {
          const response = await generateS3UploadURL({
            variables: { input: { lookupId } },
          });
          const s3UploadUrl = response?.data?.generateLookupImportUrl.url;
          await fetch(s3UploadUrl, {
            method: 'PUT',
            body: reader.result as string,
          });
          await importLookupData({
            awaitRefetchQueries: true,
            variables: {
              input: {
                lookupId,
                s3UploadUrl,
              },
            },
          });

          // and in case of an error, reset the file input. If we don't do that, then the user can't
          // re-upload the same file he had selected, since the field would never have been cleared.
          // This protects us against just that.
        } catch (err) {
          setErrorMsg('An unknown error occurred during the upload');
          setUploadingState(UploadState.ERROR);
        }
      });
    },
    [setUploadingState, importLookupData, lookupId, generateS3UploadURL]
  );

  const restartUploading = React.useCallback(() => {
    setUploadingState(UploadState.PENDING);
    setErrorMsg('');
  }, [setUploadingState, setErrorMsg]);

  const isDisabled = uploadingState === UploadState.DISABLED;
  const isPending = uploadingState === UploadState.PENDING;
  const isProcessing = uploadingState === UploadState.PROCESSING;
  const isError = uploadingState === UploadState.ERROR;
  const isSuccess = uploadingState === UploadState.SUCCESS;

  return (
    <WizardPanel width={LOOKUP_WIZARD_WIDTH} margin="0 auto">
      <LookupPanelHeading title="Upload File" subtitle="Import your CSV or JSONL file below" />

      {isDisabled && (
        <LookupUploaderDisabled onEnabledCompleted={() => setUploadingState(UploadState.PENDING)} />
      )}

      {isPending && <LookupUploaderInput onFilesDropped={onFilesDropped} />}
      {isProcessing && <LookupUploaderProcessing onAbort={onAbort} />}
      {isError && <LookupUploaderError errorMsg={errorMsg} onRestartUploading={restartUploading} />}
      {isSuccess && (
        <LookupUploaderSuccess onReset={() => setUploadingState(UploadState.PENDING)} />
      )}

      {isEditing ? (
        <EditLookupActions />
      ) : (
        <Flex justify="center" mt={6}>
          <Button
            data-tid="lookup-finish-setup-csv-upload"
            disabled={uploadingState !== UploadState.SUCCESS}
            onClick={() => history.push(urls.enrichment.lookupTables.home())}
          >
            Finish Setup
          </Button>
        </Flex>
      )}
    </WizardPanel>
  );
};

export default LookupImportDataPanel;
