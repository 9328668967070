/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import * as Types from '../../../../../__generated__/schema';

import { UserDetails } from '../../../../graphql/fragments/UserDetails.generated';
import { GraphQLError } from 'graphql';
import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';

export type ResetUserPasswordVariables = {
  id: Types.Scalars['ID'];
};

export type ResetUserPassword = { resetUserPassword: { user: UserDetails } };

export const ResetUserPasswordDocument = gql`
  mutation ResetUserPassword($id: ID!) {
    resetUserPassword(input: { id: $id }) {
      user {
        ...UserDetails
      }
    }
  }
  ${UserDetails}
`;
export type ResetUserPasswordMutationFn = ApolloReactCommon.MutationFunction<
  ResetUserPassword,
  ResetUserPasswordVariables
>;

/**
 * __useResetUserPassword__
 *
 * To run a mutation, you first call `useResetUserPassword` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetUserPassword` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetUserPassword, { data, loading, error }] = useResetUserPassword({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useResetUserPassword(
  baseOptions?: ApolloReactHooks.MutationHookOptions<ResetUserPassword, ResetUserPasswordVariables>
) {
  return ApolloReactHooks.useMutation<ResetUserPassword, ResetUserPasswordVariables>(
    ResetUserPasswordDocument,
    baseOptions
  );
}
export type ResetUserPasswordHookResult = ReturnType<typeof useResetUserPassword>;
export type ResetUserPasswordMutationResult = ApolloReactCommon.MutationResult<ResetUserPassword>;
export type ResetUserPasswordMutationOptions = ApolloReactCommon.BaseMutationOptions<
  ResetUserPassword,
  ResetUserPasswordVariables
>;
export function mockResetUserPassword({
  data,
  variables,
  errors,
}: {
  data: ResetUserPassword;
  variables?: ResetUserPasswordVariables;
  errors?: GraphQLError[];
}) {
  return {
    request: { query: ResetUserPasswordDocument, variables },
    result: { data, errors },
  };
}
