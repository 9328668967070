/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import DestinationImg from 'Assets/illustrations/destination.svg';
import RoleRestrictedAccess from 'Components/utils/RoleRestrictedAccess';
import { Permission } from 'Generated/schema';
import { Box, Flex, Heading, Text } from 'pouncejs';
import urls from 'Source/urls';
import LinkButton from 'Components/buttons/LinkButton';

const DestinationsPageEmptyDataFallback: React.FC = () => {
  return (
    <Flex
      height="100%"
      width="100%"
      justify="center"
      align="center"
      direction="column"
      data-testid="destinations"
    >
      <Box m={10}>
        <img alt="Mobile & Envelope illustration" src={DestinationImg} width="auto" height={350} />
      </Box>
      <Heading mb={6}>Help us reach you</Heading>
      <Text color="gray-300" textAlign="center" mb={8}>
        You don{"'"}t seem to have any destinations setup yet. <br />
        Adding destinations will help you get notified when irregularities occur.
      </Text>
      <RoleRestrictedAccess allowedPermissions={[Permission.DestinationModify]}>
        <LinkButton to={urls.integrations.destinations.create()} icon="add">
          Add your first Destination
        </LinkButton>
      </RoleRestrictedAccess>
    </Flex>
  );
};

export default DestinationsPageEmptyDataFallback;
