/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import * as Types from '../../../../../../../__generated__/schema';

import { SlackLogIntegrationDetails } from '../../../../../../graphql/fragments/SlackLogIntegrationDetails.generated';
import { GraphQLError } from 'graphql';
import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';

export type AddSlackLogSourceVariables = {
  input: Types.AddLogPullingIntegrationInput;
};

export type AddSlackLogSource = { addLogPullingIntegration: SlackLogIntegrationDetails };

export const AddSlackLogSourceDocument = gql`
  mutation AddSlackLogSource($input: AddLogPullingIntegrationInput!) {
    addLogPullingIntegration(input: $input) {
      ...SlackLogIntegrationDetails
    }
  }
  ${SlackLogIntegrationDetails}
`;
export type AddSlackLogSourceMutationFn = ApolloReactCommon.MutationFunction<
  AddSlackLogSource,
  AddSlackLogSourceVariables
>;

/**
 * __useAddSlackLogSource__
 *
 * To run a mutation, you first call `useAddSlackLogSource` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddSlackLogSource` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addSlackLogSource, { data, loading, error }] = useAddSlackLogSource({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddSlackLogSource(
  baseOptions?: ApolloReactHooks.MutationHookOptions<AddSlackLogSource, AddSlackLogSourceVariables>
) {
  return ApolloReactHooks.useMutation<AddSlackLogSource, AddSlackLogSourceVariables>(
    AddSlackLogSourceDocument,
    baseOptions
  );
}
export type AddSlackLogSourceHookResult = ReturnType<typeof useAddSlackLogSource>;
export type AddSlackLogSourceMutationResult = ApolloReactCommon.MutationResult<AddSlackLogSource>;
export type AddSlackLogSourceMutationOptions = ApolloReactCommon.BaseMutationOptions<
  AddSlackLogSource,
  AddSlackLogSourceVariables
>;
export function mockAddSlackLogSource({
  data,
  variables,
  errors,
}: {
  data: AddSlackLogSource;
  variables?: AddSlackLogSourceVariables;
  errors?: GraphQLError[];
}) {
  return {
    request: { query: AddSlackLogSourceDocument, variables },
    result: { data, errors },
  };
}
