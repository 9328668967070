/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import urls from 'Source/urls';
import { Form, Formik, FastField } from 'formik';
import { SortDirEnum } from 'Generated/schema';
import { Box, Flex } from 'pouncejs';
import pick from 'lodash/pick';
import {
  wrapSortingOptions,
  extractSortingOptions,
  SortingOption,
  WrapSortingFormValues,
} from 'Helpers/utils';
import useRequestParamsWithoutPagination from 'Hooks/useRequestParamsWithoutPagination';
import FormikAutosave from 'Components/utils/Autosave';
import FormikCombobox from 'Components/fields/ComboBox';
import FormikTextInput from 'Components/fields/TextInput';
import LinkButton from 'Components/buttons/LinkButton';
import DropdownFilters from './DropdownFilters';

export enum ListDataSchemasSortFieldsEnum {
  name = 'name',
  createdAt = 'createdAt',
}

export type ListDataModelInlineFiltersValues = {
  contains: string;
  sortBy?: ListDataSchemasSortFieldsEnum;
  sortDir?: SortDirEnum;
};

export const filters: (keyof ListDataModelInlineFiltersValues)[] = [
  'contains',
  'sortBy',
  'sortDir',
];

const defaultValues = {
  contains: '',
  sorting: null,
};

const sortingOpts: SortingOption<ListDataModelInlineFiltersValues>[] = [
  {
    opt: 'Schema ID (A-Z)',
    resolution: {
      sortBy: ListDataSchemasSortFieldsEnum.name,
      sortDir: SortDirEnum.Ascending,
    },
  },
  {
    opt: 'Schema ID (Z-A)',
    resolution: {
      sortBy: ListDataSchemasSortFieldsEnum.name,
      sortDir: SortDirEnum.Descending,
    },
  },
  {
    opt: 'Most Recent',
    resolution: {
      sortBy: ListDataSchemasSortFieldsEnum.createdAt,
      sortDir: SortDirEnum.Descending,
    },
  },
  {
    opt: 'Oldest',
    resolution: {
      sortBy: ListDataSchemasSortFieldsEnum.createdAt,
      sortDir: SortDirEnum.Ascending,
    },
  },
];

const ListDataSchemasFilters: React.FC = () => {
  const { requestParams, updateRequestParams } = useRequestParamsWithoutPagination<
    ListDataModelInlineFiltersValues
  >();

  const initialFilterValues = React.useMemo(
    () => ({
      ...defaultValues,
      ...wrapSortingOptions(pick(requestParams, filters), sortingOpts),
    }),
    [requestParams]
  );

  return (
    <Flex justify="flex-end" align="center">
      <Formik<WrapSortingFormValues<ListDataModelInlineFiltersValues>>
        enableReinitialize
        initialValues={initialFilterValues}
        onSubmit={values => {
          updateRequestParams(extractSortingOptions(values, sortingOpts));
        }}
      >
        <Form>
          <FormikAutosave threshold={200} />
          <Flex spacing={4} align="center" pr={4}>
            <Box minWidth={425}>
              <FastField
                name="contains"
                icon="search"
                iconAlignment="left"
                as={FormikTextInput}
                label="Search for Schemas"
                placeholder="Search for a data schema..."
              />
            </Box>
            <Box minWidth={220}>
              <FastField
                name="sorting"
                as={FormikCombobox}
                items={sortingOpts.map(sortingOption => sortingOption.opt)}
                label="Sort By"
                placeholder="Select a sorting option"
              />
            </Box>
          </Flex>
        </Form>
      </Formik>
      <Box pr={4}>
        <DropdownFilters />
      </Box>
      <LinkButton
        data-tid="create-new-data-schema"
        title="Create new Data Schema"
        to={urls.data.schemas.create()}
      >
        Create New
      </LinkButton>
    </Flex>
  );
};

export default React.memo(ListDataSchemasFilters);
