/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import * as Types from '../../../__generated__/schema';

import { ActorTeaser_User_, ActorTeaser_APIToken_ } from './ActorTeaser.generated';
import { GraphQLError } from 'graphql';
import gql from 'graphql-tag';
import { ActorTeaser } from './ActorTeaser.generated';

export type SavedQueryDetails = Pick<
  Types.SavedQuery,
  | 'id'
  | 'name'
  | 'description'
  | 'sqlQuery'
  | 'tags'
  | 'defaultDatabase'
  | 'managed'
  | 'lastModifiedAt'
> & {
  parent?: Types.Maybe<Pick<Types.SavedQuery, 'id'>>;
  schedule?: Types.Maybe<
    Pick<Types.Schedule, 'disabled' | 'cronExpression' | 'rateMinutes' | 'timeout'>
  >;
  lastModifiedBy?: Types.Maybe<ActorTeaser_User_ | ActorTeaser_APIToken_>;
};

export const SavedQueryDetails = gql`
  fragment SavedQueryDetails on SavedQuery {
    id
    name
    description
    sqlQuery
    tags
    defaultDatabase
    managed
    parent {
      id
    }
    schedule {
      disabled
      cronExpression
      rateMinutes
      timeout
    }
    lastModifiedAt
    lastModifiedBy {
      ...ActorTeaser
    }
  }
  ${ActorTeaser}
`;
