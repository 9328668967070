/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Box, Grid, Icon, Flex, Table } from 'pouncejs';
import { useSchemaGeneration } from 'Components/utils/SchemaGenerationContext';
import NoResultsFound from 'Components/NoResultsFound';
import { CollapsableCell, DisplayLimit } from '../common';

const SampleDataUnmatchedResults: React.FC = () => {
  const { results } = useSchemaGeneration();

  const unmatchedLogsCount = results?.stats?.unmatched;
  const unmatchedLogs = results?.data?.unmatched;

  if (!unmatchedLogsCount) {
    return (
      <Box py={4}>
        <NoResultsFound title="No Unmatched Logs found" />
      </Box>
    );
  }

  return (
    <Grid overflow="auto" maxHeight="max(calc(100vh - 950px),600px)" willChange="scroll">
      <Table size="small" stickyHeader data-testid="sample-data-table">
        <Table.Head>
          <Table.Row>
            <Box as={Table.HeaderCell} backgroundColor="navyblue-300" />
            <Box as={Table.HeaderCell} width="50%" backgroundColor="navyblue-300">
              Error
            </Box>
            <Box as={Table.HeaderCell} width="50%" backgroundColor="navyblue-300">
              Raw
            </Box>
          </Table.Row>
        </Table.Head>
        <Table.Body>
          {unmatchedLogs.map((log, i) => {
            return (
              <Table.Row key={`unmatched-log-${i}`}>
                <Table.Cell>
                  <Flex justify="center">
                    <Icon
                      type={'close-circle'}
                      color={'red-300'}
                      size="medium"
                      mr={2}
                      aria-label={'Log failed to match'}
                    />
                  </Flex>
                </Table.Cell>
                <Box as={Table.Cell}>
                  <CollapsableCell
                    text={log.error}
                    hideText="Hide Error"
                    showText="Show Error"
                    sliceOn={200}
                  />
                </Box>
                <Table.Cell>
                  <CollapsableCell
                    text={log.raw}
                    hideText="Hide Log"
                    showText="Show Log"
                    sliceOn={280}
                  />
                </Table.Cell>
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
      {unmatchedLogs.length < unmatchedLogsCount && <DisplayLimit />}
    </Grid>
  );
};

export default SampleDataUnmatchedResults;
