/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { useSnackbar } from 'pouncejs';
import Page404 from 'Pages/404';
import withSEO from 'Hoc/withSEO';
import useRouter from 'Hooks/useRouter';
import withRoleRestrictedAccess from 'Hoc/withRoleRestrictedAccess';
import { compose } from 'Helpers/compose';
import {
  GithubLogSourceOAuth2Config,
  GithubLogSourcePatConfig,
  Permission,
} from 'Generated/schema';
import Page403 from 'Pages/403';
import { extractErrorMessage } from 'Helpers/utils';
import useAvailableLogTypesForLogPuller from 'Hooks/useAvailableLogTypesForLogPuller';
import GithubLogsSourceWizard, { GithubLogsSourceWizardValues } from '../GithubLogsSourceWizard';
import { useGetGithubLogSource } from './graphql/getGithubLogSource.generated';
import { useUpdateGithubLogSource } from './graphql/updateGithubLogSource.generated';

const EditGithubLogSource: React.FC = () => {
  const { pushSnackbar } = useSnackbar();
  const { defaultLogTypes } = useAvailableLogTypesForLogPuller();
  const { match } = useRouter<{ id: string }>();
  const { data, error: getError } = useGetGithubLogSource({
    variables: { id: match.params.id },
    onError: error => {
      pushSnackbar({
        title: extractErrorMessage(error) || 'An unknown error occurred',
        variant: 'error',
      });
    },
  });

  const [updateGithubLogSource] = useUpdateGithubLogSource();

  const initialValues = React.useMemo<GithubLogsSourceWizardValues>(
    () => ({
      integrationId: match.params.id,
      integrationLabel: data?.getLogPullingIntegration.integrationLabel ?? 'Loading...',
      organization: data?.getLogPullingIntegration.pullerConfig.github.organization ?? 'Loading...',
      authorizationMethod:
        data?.getLogPullingIntegration.pullerConfig.github.authorizationMethod ?? null,
      personalAccessToken:
        (data?.getLogPullingIntegration.pullerConfig.github.credentials as GithubLogSourcePatConfig)
          ?.personalAccessToken ?? '',
      clientId:
        (data?.getLogPullingIntegration.pullerConfig.github
          .credentials as GithubLogSourceOAuth2Config)?.clientId ?? '',
      clientSecret:
        (data?.getLogPullingIntegration.pullerConfig.github
          .credentials as GithubLogSourceOAuth2Config)?.clientSecret ?? '',
    }),
    // FIXME: look into missing hook dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data]
  );

  const handleSubmit = React.useCallback(
    async (values: GithubLogsSourceWizardValues) => {
      try {
        const resp = await updateGithubLogSource({
          variables: {
            input: {
              integrationId: values.integrationId,
              integrationLabel: values.integrationLabel,
              pullerConfig: {
                logTypes: defaultLogTypes,
                github: {
                  organization: values.organization,
                  patCredentials: {
                    personalAccessToken: values.personalAccessToken,
                  },
                  oauth2Credentials: {
                    clientId: values.clientId,
                    clientSecret: values.clientSecret,
                  },
                  authorizationMethod: values.authorizationMethod,
                },
              },
            },
          },
        });
        return resp.data.updateLogPullingIntegration;
      } catch (err) {
        return err as Error;
      }
    },
    [defaultLogTypes, updateGithubLogSource]
  );

  // we optimistically assume that an error in "get" is a 404. We don't have any other info
  if (getError) {
    return <Page404 />;
  }

  return <GithubLogsSourceWizard initialValues={initialValues} onSubmit={handleSubmit} />;
};

export default compose(
  withSEO({ title: 'Edit Github Source' }),
  withRoleRestrictedAccess({
    allowedPermissions: [Permission.LogSourceModify],
    fallback: <Page403 />,
  })
)(EditGithubLogSource);
