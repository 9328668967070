/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { typedMemo } from 'Helpers/utils';

const identity = (x: any) => x;

export interface SelectContextValue<T> {
  selection: T[];
  allSelected: boolean;
  selectItem: (item: T) => void;
  deselectItem: (item: T) => void;
  resetSelection: () => void;
  selectAll: (items: T[]) => void;
  checkIfSelected: (item: T) => boolean;
  toggleItem: (item: T) => void;
  setAllSelectables: (items: T[]) => void;
  bulkSelect: (item: T) => void;
}

const SelectContext = React.createContext<SelectContextValue<any>>(undefined);

interface SelectProviderProps<T> {
  children: React.ReactNode;
  initialSelection?: T[];
  getItemKey?: (item: T) => string;
}

function SelectProvider<T>({
  initialSelection = [],
  getItemKey = identity,
  children,
}: SelectProviderProps<T>) {
  const [selection, setSelected] = React.useState<Array<T>>(initialSelection);
  const [selectables, setSelectables] = React.useState<Array<T>>([]);
  const [lastSelectedId, setLastSelectedId] = React.useState<string>('');
  /**
   * @public
   * Add an item to the selection
   *
   */
  const selectItem = React.useCallback(
    item => {
      setLastSelectedId(getItemKey(item));
      return setSelected(existing => [...existing, item]);
    },
    [setLastSelectedId, getItemKey, setSelected]
  );

  /**
   * @public
   * Deselects an item from the selection
   *
   */
  const deselectItem = React.useCallback(
    item => {
      return setSelected(existing => existing.filter(i => getItemKey(i) !== getItemKey(item)));
    },
    [getItemKey]
  );

  /**
   * @public
   * Reset selection to an empty array
   *
   */
  const resetSelection = React.useCallback(() => {
    setSelected([]);
    setLastSelectedId('');
  }, []);

  const selectAll = React.useCallback((items: T[]) => {
    return setSelected(items);
  }, []);

  /**
   * @public
   * Simple function that checks whether an item is selected
   *
   */
  const checkIfSelected = React.useCallback(
    (item: T) => {
      return !!selection.find(i => getItemKey(i) === getItemKey(item));
    },
    [selection, getItemKey]
  );

  /**
   * @public
   * This function check whether an item is selected
   * and change its status to the opposite
   */
  const toggleItem = React.useCallback(
    (item: T) => {
      return checkIfSelected(item) ? deselectItem(item) : selectItem(item);
    },
    [checkIfSelected, deselectItem, selectItem]
  );

  /**
   * @public
   * This function sets all possible selections
   * for bulk selection purposes
   */
  const setAllSelectables = React.useCallback(
    (items: T[]) => {
      return setSelectables(items);
    },
    [setSelectables]
  );

  /**
   * @public
   * This function handle Shift+click when selecting in a list
   *
   */
  const bulkSelect = React.useCallback(
    (item: T) => {
      const startIndex = selectables.findIndex(i => getItemKey(i) === lastSelectedId);
      const endIndex = selectables.findIndex(i => getItemKey(i) === getItemKey(item));

      const bulkSelection: T[] =
        startIndex < endIndex
          ? selectables.slice(startIndex + 1, endIndex + 1)
          : selectables.slice(endIndex, startIndex + 1);
      setLastSelectedId(getItemKey(item));
      setSelected(existing => [...existing, ...bulkSelection]);
    },
    [getItemKey, selectables, lastSelectedId]
  );

  const allSelected = React.useMemo(() => {
    return selectables.length !== 0 && selectables.every(selectable => checkIfSelected(selectable));
  }, [selectables, checkIfSelected]);

  const contextValue = React.useMemo(
    () => ({
      selection,
      allSelected,
      selectAll,
      deselectItem,
      selectItem,
      resetSelection,
      checkIfSelected,
      toggleItem,
      setAllSelectables,
      bulkSelect,
    }),
    [
      selection,
      allSelected,
      resetSelection,
      selectAll,
      selectItem,
      deselectItem,
      checkIfSelected,
      toggleItem,
      setAllSelectables,
      bulkSelect,
    ]
  );

  return <SelectContext.Provider value={contextValue}>{children}</SelectContext.Provider>;
}

const MemoizedSelectProvider = typedMemo(SelectProvider);

const withSelectContext = <T,>(config?: Omit<SelectProviderProps<T>, 'children'>) => (
  Component: React.FC
) => props => (
  <SelectProvider<T> {...config}>
    <Component {...props} />
  </SelectProvider>
);

const useSelect = <T extends unknown = string>() =>
  React.useContext<SelectContextValue<T>>(SelectContext);

/** A shortcut for the consumer component */

const SelectConsumer = <T extends unknown = string>(
  props: React.ConsumerProps<SelectContextValue<T>>
) => {
  const Consumer = SelectContext.Consumer as React.Consumer<SelectContextValue<T>>;
  return <Consumer {...props} />;
};

export {
  SelectContext,
  SelectConsumer,
  MemoizedSelectProvider as SelectProvider,
  withSelectContext,
  useSelect,
};
