/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { useFormikContext } from 'formik';
import { useWizardContext } from 'Components/Wizard';
import { CloudWatchLogIntegrationDetails } from 'Source/graphql/fragments/CloudwatchLogIntegrationDetails.generated';
import { FailingPanel, PendingPanel } from 'Components/SourceVerificationPanels';
import { CloudWatchLogSourceWizardValues } from '../CloudWatchLogSourceWizard';
import HealthCheckPanel from './HealthCheckPanel';

const ValidationPanel: React.FC = () => {
  const [errorMessage, setErrorMessage] = React.useState('');
  const {
    currentStepStatus,
    setCurrentStepStatus,
    updateData,
    setNavigationAvailability,
  } = useWizardContext<{ integrationId?: string }>();
  const { submitForm } = useFormikContext<CloudWatchLogSourceWizardValues>();
  const result = React.useRef<CloudWatchLogIntegrationDetails | Error>(null);

  React.useEffect(() => {
    (async () => {
      setNavigationAvailability(false);
      result.current = await (submitForm() as Promise<any>);

      if (result.current instanceof Error) {
        setErrorMessage(result.current.message);
        setCurrentStepStatus('FAILING');
        setNavigationAvailability(true);
      } else {
        const {
          integrationId: newIntegrationId,
        } = result.current as CloudWatchLogIntegrationDetails;
        updateData({ integrationId: newIntegrationId });
        setErrorMessage('');
        setCurrentStepStatus('PASSING');
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (currentStepStatus === 'PENDING') {
    return <PendingPanel />;
  }

  if (currentStepStatus === 'FAILING') {
    return <FailingPanel errorMessage={errorMessage} />;
  }

  return <HealthCheckPanel />;
};

export default ValidationPanel;
