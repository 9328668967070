/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import * as Types from '../../../../../../../__generated__/schema';

import { ZendeskLogIntegrationDetails } from '../../../../../../graphql/fragments/ZendeskLogIntegrationDetails.generated';
import { GraphQLError } from 'graphql';
import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';

export type GetZendeskbLogSourceVariables = {
  id: Types.Scalars['ID'];
};

export type GetZendeskbLogSource = { getLogPullingIntegration: ZendeskLogIntegrationDetails };

export const GetZendeskbLogSourceDocument = gql`
  query GetZendeskbLogSource($id: ID!) {
    getLogPullingIntegration(id: $id) {
      ...ZendeskLogIntegrationDetails
    }
  }
  ${ZendeskLogIntegrationDetails}
`;

/**
 * __useGetZendeskbLogSource__
 *
 * To run a query within a React component, call `useGetZendeskbLogSource` and pass it any options that fit your needs.
 * When your component renders, `useGetZendeskbLogSource` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetZendeskbLogSource({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetZendeskbLogSource(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetZendeskbLogSource,
    GetZendeskbLogSourceVariables
  >
) {
  return ApolloReactHooks.useQuery<GetZendeskbLogSource, GetZendeskbLogSourceVariables>(
    GetZendeskbLogSourceDocument,
    baseOptions
  );
}
export function useGetZendeskbLogSourceLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetZendeskbLogSource,
    GetZendeskbLogSourceVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<GetZendeskbLogSource, GetZendeskbLogSourceVariables>(
    GetZendeskbLogSourceDocument,
    baseOptions
  );
}
export type GetZendeskbLogSourceHookResult = ReturnType<typeof useGetZendeskbLogSource>;
export type GetZendeskbLogSourceLazyQueryHookResult = ReturnType<
  typeof useGetZendeskbLogSourceLazyQuery
>;
export type GetZendeskbLogSourceQueryResult = ApolloReactCommon.QueryResult<
  GetZendeskbLogSource,
  GetZendeskbLogSourceVariables
>;
export function mockGetZendeskbLogSource({
  data,
  variables,
  errors,
}: {
  data: GetZendeskbLogSource;
  variables?: GetZendeskbLogSourceVariables;
  errors?: GraphQLError[];
}) {
  return {
    request: { query: GetZendeskbLogSourceDocument, variables },
    result: { data, errors },
  };
}
