/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import * as Types from '../../../../../__generated__/schema';

import { PolicyDetails } from '../../../../graphql/fragments/PolicyDetails.generated';
import { GraphQLError } from 'graphql';
import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';

export type CreatePolicyVariables = {
  input: Types.AddPolicyInput;
};

export type CreatePolicy = { addPolicy?: Types.Maybe<PolicyDetails> };

export const CreatePolicyDocument = gql`
  mutation CreatePolicy($input: AddPolicyInput!) {
    addPolicy(input: $input) {
      ...PolicyDetails
    }
  }
  ${PolicyDetails}
`;
export type CreatePolicyMutationFn = ApolloReactCommon.MutationFunction<
  CreatePolicy,
  CreatePolicyVariables
>;

/**
 * __useCreatePolicy__
 *
 * To run a mutation, you first call `useCreatePolicy` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePolicy` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPolicy, { data, loading, error }] = useCreatePolicy({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePolicy(
  baseOptions?: ApolloReactHooks.MutationHookOptions<CreatePolicy, CreatePolicyVariables>
) {
  return ApolloReactHooks.useMutation<CreatePolicy, CreatePolicyVariables>(
    CreatePolicyDocument,
    baseOptions
  );
}
export type CreatePolicyHookResult = ReturnType<typeof useCreatePolicy>;
export type CreatePolicyMutationResult = ApolloReactCommon.MutationResult<CreatePolicy>;
export type CreatePolicyMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreatePolicy,
  CreatePolicyVariables
>;
export function mockCreatePolicy({
  data,
  variables,
  errors,
}: {
  data: CreatePolicy;
  variables?: CreatePolicyVariables;
  errors?: GraphQLError[];
}) {
  return {
    request: { query: CreatePolicyDocument, variables },
    result: { data, errors },
  };
}
