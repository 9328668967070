/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { useTheme } from 'pouncejs';

const useJsonTreeTheme = () => {
  const theme = useTheme();
  // Json tree component theme.
  // See https://github.com/alexkuz/react-json-tree/blob/feature-refactor-styling/src/createStylingFromTheme.js
  // for the list of styling object keys
  return React.useMemo(
    () => ({
      base00: 'transparent', // Background
      base03: theme.colors['gray-300'], // Item string expanded
      base07: theme.colors.white, // Text color
      base08: theme.colors.white, // Symbol, Null, Undefined, Function
      base09: theme.colors.white, // Number, Boolean
      base0B: theme.colors.white, // Item string
      base0D: theme.colors.white, // Label, Arrow
      valueLabel: {
        color: theme.colors['purple-300'],
      },
      nestedNodeLabel: {
        color: theme.colors['green-400'],
      },
    }),
    [theme]
  );
};

export default useJsonTreeTheme;
