/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import * as Types from '../../../../../__generated__/schema';

import { APITokenDetails } from '../../../../graphql/fragments/APITokenDetails.generated';
import { GraphQLError } from 'graphql';
import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';

export type AddAPITokenVariables = {
  input: Types.CreateAPITokenInput;
};

export type AddAPIToken = {
  createAPIToken: {
    token?: Types.Maybe<Pick<Types.APIToken, 'value'> & APITokenDetails>;
    error?: Types.Maybe<Pick<Types.Error, 'message'>>;
  };
};

export const AddAPITokenDocument = gql`
  mutation AddAPIToken($input: CreateAPITokenInput!) {
    createAPIToken(input: $input) {
      token {
        ...APITokenDetails
        value
      }
      error {
        message
      }
    }
  }
  ${APITokenDetails}
`;
export type AddAPITokenMutationFn = ApolloReactCommon.MutationFunction<
  AddAPIToken,
  AddAPITokenVariables
>;

/**
 * __useAddAPIToken__
 *
 * To run a mutation, you first call `useAddAPIToken` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddAPIToken` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addApiToken, { data, loading, error }] = useAddAPIToken({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddAPIToken(
  baseOptions?: ApolloReactHooks.MutationHookOptions<AddAPIToken, AddAPITokenVariables>
) {
  return ApolloReactHooks.useMutation<AddAPIToken, AddAPITokenVariables>(
    AddAPITokenDocument,
    baseOptions
  );
}
export type AddAPITokenHookResult = ReturnType<typeof useAddAPIToken>;
export type AddAPITokenMutationResult = ApolloReactCommon.MutationResult<AddAPIToken>;
export type AddAPITokenMutationOptions = ApolloReactCommon.BaseMutationOptions<
  AddAPIToken,
  AddAPITokenVariables
>;
export function mockAddAPIToken({
  data,
  variables,
  errors,
}: {
  data: AddAPIToken;
  variables?: AddAPITokenVariables;
  errors?: GraphQLError[];
}) {
  return {
    request: { query: AddAPITokenDocument, variables },
    result: { data, errors },
  };
}
