/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import {
  AsyncQueryContext,
  initialState as defaultInitialState,
  Reducer,
  reducer,
  State,
} from './AsyncQueryContext';

interface MemoryAsyncQueryContextProviderProps {
  initialState?: State;
}

const MemoryAsyncQueryContextProvider: React.FC<MemoryAsyncQueryContextProviderProps> = ({
  children,
  initialState = defaultInitialState,
}) => {
  const [state, dispatch] = React.useReducer<Reducer>(reducer, initialState);

  const contextValue = React.useMemo(() => ({ state, dispatch }), [state, dispatch]);
  return <AsyncQueryContext.Provider value={contextValue}>{children}</AsyncQueryContext.Provider>;
};

export default MemoryAsyncQueryContextProvider;
