/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Box, FormError, DateRangeInput, DateRangeInputProps } from 'pouncejs';
import { useField } from 'formik';

export interface FieldDateRangeInputProps
  extends Omit<DateRangeInputProps, 'name' | 'iconAlignment' | 'iconProps' | 'value' | 'onChange'> {
  nameStart: string;
  nameEnd: string;
  useUTC?: boolean;
}

const FormikDateRangeInput: React.FC<FieldDateRangeInputProps> = ({
  nameStart,
  nameEnd,
  useUTC = false,
  ...rest
}) => {
  const [, metaStart, helpersStart] = useField<string>(nameStart);
  const [, metaEnd, helpersEnd] = useField<string>(nameEnd);

  const { touched: touchedStart, error: errorStart, value: valueStart } = metaStart;
  const { setValue: setValueStart } = helpersStart;

  const { touched: touchedEnd, error: errorEnd, value: valueEnd } = metaEnd;
  const { setValue: setValueEnd } = helpersEnd;

  const touched = touchedStart || touchedEnd;
  const error = errorStart || errorEnd;

  const isInvalid = touched && !!error;
  const errorElementId = isInvalid ? `${nameStart}-${nameEnd}-error` : undefined;

  const value = React.useMemo(
    (): [Date?, Date?] => [
      valueStart ? new Date(valueStart) : null,
      valueEnd ? new Date(valueEnd) : null,
    ],
    // FIXME: look into hook dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [valueStart, valueEnd, useUTC]
  );

  const onRangeChange = React.useCallback<DateRangeInputProps['onChange']>(
    ([start, end]) => {
      // When the range is not defined we shall nullify the outcome
      // undefined values are not reflected on the URL params
      setValueStart(start ? start.toISOString() : null);
      setValueEnd(end ? end.toISOString() : null);
    },
    // FIXME: look into hook dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setValueStart, setValueEnd, useUTC]
  );

  return (
    <Box>
      <DateRangeInput
        {...rest}
        timezone={useUTC ? 'utc' : 'local'}
        name={`${nameStart}-${nameEnd}`}
        invalid={isInvalid}
        value={value}
        onChange={onRangeChange}
      />
      {isInvalid && (
        <FormError mt={2} id={errorElementId}>
          {error}
        </FormError>
      )}
    </Box>
  );
};

export default FormikDateRangeInput;
