/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import * as Types from '../../../../../../../__generated__/schema';

import { GithubLogIntegrationDetails } from '../../../../../../graphql/fragments/GithubLogIntegrationDetails.generated';
import { GraphQLError } from 'graphql';
import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';

export type UpdateGithubLogSourceVariables = {
  input: Types.UpdateLogPullingIntegrationInput;
};

export type UpdateGithubLogSource = { updateLogPullingIntegration: GithubLogIntegrationDetails };

export const UpdateGithubLogSourceDocument = gql`
  mutation UpdateGithubLogSource($input: UpdateLogPullingIntegrationInput!) {
    updateLogPullingIntegration(input: $input) {
      ...GithubLogIntegrationDetails
    }
  }
  ${GithubLogIntegrationDetails}
`;
export type UpdateGithubLogSourceMutationFn = ApolloReactCommon.MutationFunction<
  UpdateGithubLogSource,
  UpdateGithubLogSourceVariables
>;

/**
 * __useUpdateGithubLogSource__
 *
 * To run a mutation, you first call `useUpdateGithubLogSource` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateGithubLogSource` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateGithubLogSource, { data, loading, error }] = useUpdateGithubLogSource({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateGithubLogSource(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateGithubLogSource,
    UpdateGithubLogSourceVariables
  >
) {
  return ApolloReactHooks.useMutation<UpdateGithubLogSource, UpdateGithubLogSourceVariables>(
    UpdateGithubLogSourceDocument,
    baseOptions
  );
}
export type UpdateGithubLogSourceHookResult = ReturnType<typeof useUpdateGithubLogSource>;
export type UpdateGithubLogSourceMutationResult = ApolloReactCommon.MutationResult<
  UpdateGithubLogSource
>;
export type UpdateGithubLogSourceMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateGithubLogSource,
  UpdateGithubLogSourceVariables
>;
export function mockUpdateGithubLogSource({
  data,
  variables,
  errors,
}: {
  data: UpdateGithubLogSource;
  variables?: UpdateGithubLogSourceVariables;
  errors?: GraphQLError[];
}) {
  return {
    request: { query: UpdateGithubLogSourceDocument, variables },
    result: { data, errors },
  };
}
