/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import Panel from 'Components/Panel';
import { Link as RRLink } from 'react-router-dom';
import RoleRestrictedAccess from 'Components/utils/RoleRestrictedAccess';
import {
  AbstractButton,
  Alert,
  Flex,
  Icon,
  DropdownButton,
  DropdownMenu,
  DropdownLink,
  Dropdown,
} from 'pouncejs';
import { Permission } from 'Generated/schema';
import LinkButton from 'Components/buttons/LinkButton';
import { extractErrorMessage } from 'Helpers/utils';
import urls from 'Source/urls';
import { PUBLIC_API_DOC_URL } from 'Source/constants';
import EmptyDataFallback from './EmptyDataFallback';
import Skeleton from './Skeleton';
import APITokenCard from './APITokenCard';
import { useListAPITokens } from './graphql/listAPITokens.generated';

const ListAPITokens: React.FC = () => {
  const { data, loading, error } = useListAPITokens();

  if (loading) {
    return <Skeleton />;
  }

  if (error || data?.listAPITokens.error) {
    return (
      <Alert
        variant="error"
        title="Couldn't load your API Tokens"
        description={extractErrorMessage(error)}
      />
    );
  }

  if (!data.listAPITokens.tokens.length) {
    return <EmptyDataFallback />;
  }

  return (
    <Panel
      title="API Tokens"
      actions={
        <Flex align="center" spacing={7}>
          <Dropdown>
            <DropdownButton as={AbstractButton} fontSize="medium-large">
              Resources
              <Icon size="medium" type="chevron-down" verticalAlign="middle" ml={2} />
            </DropdownButton>
            <DropdownMenu>
              <DropdownLink href={PUBLIC_API_DOC_URL} target="_blank" rel="noopener noreferrer">
                API Documentation
              </DropdownLink>
              <DropdownLink as={RRLink} to={urls.settings.apiTokens.playground()}>
                API Playground
              </DropdownLink>
            </DropdownMenu>
          </Dropdown>
          <RoleRestrictedAccess allowedPermissions={[Permission.OrganizationApiTokenModify]}>
            <LinkButton to={urls.settings.apiTokens.create()}>Create New Token</LinkButton>
          </RoleRestrictedAccess>
        </Flex>
      }
    >
      <Flex direction="column" spacing={5}>
        {data.listAPITokens.tokens.map(token => (
          <APITokenCard token={token} key={token.id} />
        ))}
      </Flex>
    </Panel>
  );
};

export default ListAPITokens;
