/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import * as Types from '../../../../__generated__/schema';

import { GraphQLError } from 'graphql';
import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';

export type SetEventThresholdAlarmVariables = {
  input: Types.EventThresholdAlarmInput;
};

export type SetEventThresholdAlarm = {
  setLogIntegrationEventThresholdAlarm: Pick<Types.EventThresholdAlarm, 'minutesThreshold'>;
};

export const SetEventThresholdAlarmDocument = gql`
  mutation SetEventThresholdAlarm($input: EventThresholdAlarmInput!) {
    setLogIntegrationEventThresholdAlarm(input: $input) {
      minutesThreshold
    }
  }
`;
export type SetEventThresholdAlarmMutationFn = ApolloReactCommon.MutationFunction<
  SetEventThresholdAlarm,
  SetEventThresholdAlarmVariables
>;

/**
 * __useSetEventThresholdAlarm__
 *
 * To run a mutation, you first call `useSetEventThresholdAlarm` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetEventThresholdAlarm` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setEventThresholdAlarm, { data, loading, error }] = useSetEventThresholdAlarm({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetEventThresholdAlarm(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    SetEventThresholdAlarm,
    SetEventThresholdAlarmVariables
  >
) {
  return ApolloReactHooks.useMutation<SetEventThresholdAlarm, SetEventThresholdAlarmVariables>(
    SetEventThresholdAlarmDocument,
    baseOptions
  );
}
export type SetEventThresholdAlarmHookResult = ReturnType<typeof useSetEventThresholdAlarm>;
export type SetEventThresholdAlarmMutationResult = ApolloReactCommon.MutationResult<
  SetEventThresholdAlarm
>;
export type SetEventThresholdAlarmMutationOptions = ApolloReactCommon.BaseMutationOptions<
  SetEventThresholdAlarm,
  SetEventThresholdAlarmVariables
>;
export function mockSetEventThresholdAlarm({
  data,
  variables,
  errors,
}: {
  data: SetEventThresholdAlarm;
  variables?: SetEventThresholdAlarmVariables;
  errors?: GraphQLError[];
}) {
  return {
    request: { query: SetEventThresholdAlarmDocument, variables },
    result: { data, errors },
  };
}
