/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import { MitreMatrixTacticMapping, ReportOverrideSetting } from 'Generated/schema';
import React from 'react';
import { Flex, Text } from 'pouncejs';
import { MitreContextValue } from '../MitreContext';

interface MitreMatrixGridRowProps {
  matrix: MitreContextValue['activeMatrix'];
  mapping: MitreMatrixTacticMapping;
  children: React.ReactNode;
}

const MitreMatrixGridRow = ({ mapping, matrix, children }: MitreMatrixGridRowProps) => {
  const tactic = React.useMemo(() => matrix.content.tactics.find(o => o.id === mapping.tacticId), [
    matrix.content.tactics,
    mapping.tacticId,
  ]);

  const reportSettingsWithNoCoverage = React.useMemo(
    () =>
      matrix.reportSettings.filter(
        s =>
          s.association.mitre.tacticId === mapping.tacticId &&
          mapping.techniqueIds.includes(s.association.mitre.techniqueId) &&
          s.override !== ReportOverrideSetting.Covered &&
          s.override !== ReportOverrideSetting.Ignored &&
          [...s.matchingDetectionIds, ...s.matchingLogTypes].length === 0
      ).length,
    [matrix.reportSettings, mapping.tacticId, mapping.techniqueIds]
  );

  return (
    <>
      <Flex gridColumn="1/8" alignItems="center" justifyContent="flex-end">
        <Text fontSize="large">{tactic.name}</Text>
      </Flex>
      <Flex gridColumn="9/11" alignItems="center" justifyContent="flex-end">
        <Text color="navyblue-100" fontSize="medium" fontFamily="mono">
          {`${mapping.techniqueIds.length - reportSettingsWithNoCoverage}/${
            mapping.techniqueIds.length
          }`}
        </Text>
      </Flex>
      <Flex gridColumn="11/24" spacing={2}>
        {children}
      </Flex>
    </>
  );
};

export default React.memo(MitreMatrixGridRow);
