/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Box, Card, Flex, Link, Divider } from 'pouncejs';
import { APITokenDetails } from 'Source/graphql/fragments/APITokenDetails.generated';
import RoleRestrictedAccess from 'Components/utils/RoleRestrictedAccess';
import { Permission } from 'Generated/schema';
import DisabledLink from 'Components/DisabledLink';
import { Link as RRLink } from 'react-router-dom';
import urls from 'Source/urls';
import { API_TOKEN_PERMISSIONS } from 'Source/constants';
import { CardMetaValue } from 'Components/cards/common';
import { formatDatetime, getActorDisplayName } from 'Helpers/utils';
import APITokenCardOptions from './APITokenCardOptions';

interface ApiTokenCardProps {
  token: APITokenDetails;
}

const ApiTokenCard: React.FC<ApiTokenCardProps> = ({ token }) => {
  return (
    <Card as="section" variant="dark" p={4}>
      <Flex as="header" justify="space-between">
        <Box>
          <Box as="h4" fontWeight="medium" mr="auto" maxWidth="80%" wordBreak="break-word">
            <RoleRestrictedAccess
              allowedPermissions={[Permission.OrganizationApiTokenModify]}
              fallback={<DisabledLink>{token.name}</DisabledLink>}
            >
              <Link as={RRLink} to={urls.settings.apiTokens.edit(token.id)}>
                {token.name}
              </Link>
            </RoleRestrictedAccess>
          </Box>
          <Flex spacing={10} mt={2}>
            <CardMetaValue
              label="Created by"
              value={`${getActorDisplayName(token.createdBy)}, ${formatDatetime(token.createdAt)}`}
            />
            <CardMetaValue
              label="Last Updated by"
              value={
                token.updatedAt
                  ? `${getActorDisplayName(token.updatedBy)}, ${formatDatetime(token.updatedAt)}`
                  : 'Never Updated'
              }
            />
            <CardMetaValue label="Expires" value="Never Expires" />
          </Flex>
        </Box>
        <RoleRestrictedAccess allowedPermissions={[Permission.OrganizationApiTokenModify]}>
          <Box mt={-1} ml={2}>
            <APITokenCardOptions token={token} />
          </Box>
        </RoleRestrictedAccess>
      </Flex>
      <Divider color="navyblue-300" my={4} />
      <Flex flexWrap="wrap">
        {token.permissions.map(permission => (
          <Box
            key={permission}
            mb={2}
            mr={2}
            borderRadius="pill"
            backgroundColor="navyblue-400"
            py={1}
            px={3}
            fontSize="medium"
            fontWeight="medium"
          >
            {API_TOKEN_PERMISSIONS[permission].title}
          </Box>
        ))}
      </Flex>
    </Card>
  );
};

export default ApiTokenCard;
