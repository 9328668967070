/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import * as Types from '../../../../../../__generated__/schema';

import { SnykLogIntegrationDetails } from '../../../../../graphql/fragments/SnykLogIntegrationDetails.generated';
import { GraphQLError } from 'graphql';
import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';

export type GetSnykLogSourceVariables = {
  id: Types.Scalars['ID'];
};

export type GetSnykLogSource = { getLogPullingIntegration: SnykLogIntegrationDetails };

export const GetSnykLogSourceDocument = gql`
  query GetSnykLogSource($id: ID!) {
    getLogPullingIntegration(id: $id) {
      ...SnykLogIntegrationDetails
    }
  }
  ${SnykLogIntegrationDetails}
`;

/**
 * __useGetSnykLogSource__
 *
 * To run a query within a React component, call `useGetSnykLogSource` and pass it any options that fit your needs.
 * When your component renders, `useGetSnykLogSource` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSnykLogSource({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetSnykLogSource(
  baseOptions?: ApolloReactHooks.QueryHookOptions<GetSnykLogSource, GetSnykLogSourceVariables>
) {
  return ApolloReactHooks.useQuery<GetSnykLogSource, GetSnykLogSourceVariables>(
    GetSnykLogSourceDocument,
    baseOptions
  );
}
export function useGetSnykLogSourceLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSnykLogSource, GetSnykLogSourceVariables>
) {
  return ApolloReactHooks.useLazyQuery<GetSnykLogSource, GetSnykLogSourceVariables>(
    GetSnykLogSourceDocument,
    baseOptions
  );
}
export type GetSnykLogSourceHookResult = ReturnType<typeof useGetSnykLogSource>;
export type GetSnykLogSourceLazyQueryHookResult = ReturnType<typeof useGetSnykLogSourceLazyQuery>;
export type GetSnykLogSourceQueryResult = ApolloReactCommon.QueryResult<
  GetSnykLogSource,
  GetSnykLogSourceVariables
>;
export function mockGetSnykLogSource({
  data,
  variables,
  errors,
}: {
  data: GetSnykLogSource;
  variables?: GetSnykLogSourceVariables;
  errors?: GraphQLError[];
}) {
  return {
    request: { query: GetSnykLogSourceDocument, variables },
    result: { data, errors },
  };
}
