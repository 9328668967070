/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import * as Yup from 'yup';
import { Field, Form, Formik } from 'formik';
import React from 'react';
import { Box, Flex, Text, Heading } from 'pouncejs';
import FormikTextInput from 'Components/fields/TextInput';
import SubmitButton from 'Components/buttons/SubmitButton';
import { API_TOKEN_PERMISSIONS } from 'Source/constants';
import { Permission } from 'Generated/schema';
import PermissionGrid from 'Components/forms/common/PermissionGrid';

export interface APITokenFormValues {
  id?: string;
  name: string;
  permissions: Permission[];
}

interface APITokenFormProps {
  initialValues: APITokenFormValues;
  onSubmit: (values: APITokenFormValues) => Promise<any>;
}
const validationSchema = Yup.object().shape({
  name: Yup.string().min(3).required(),
  permissions: Yup.array()
    .of(Yup.string().oneOf(Object.keys(API_TOKEN_PERMISSIONS)))
    .required()
    .min(1),
});

const APITokenForm: React.FC<APITokenFormProps> = ({ initialValues, onSubmit }) => {
  return (
    <Formik<APITokenFormValues>
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ values: { name } }) => {
        return (
          <React.Fragment>
            <Box my={10}>
              <Heading size="large" textAlign="center" mb={3}>
                {name || 'Have a break, create an API Token.'}
              </Heading>
              <Text color="gray-300" textAlign="center">
                API Tokens allow you to interact with Panther and perform operations without the
                need for a GUI
              </Text>
            </Box>
            <Box width={0.75} m="auto">
              <Form>
                <Field
                  as={FormikTextInput}
                  label="Name"
                  placeholder="Enter the name of the API token..."
                  name="name"
                  required
                />
                <PermissionGrid permissionMapping={API_TOKEN_PERMISSIONS} />
                <Flex justify="center" m="auto" width={0.5}>
                  <SubmitButton
                    fullWidth
                    data-tid={`${initialValues.id ? 'update' : 'create'}-api-token`}
                  >
                    {initialValues.id ? 'Update API Token' : 'Create API Token'}
                  </SubmitButton>
                </Flex>
              </Form>
            </Box>
          </React.Fragment>
        );
      }}
    </Formik>
  );
};

export default APITokenForm;
