/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

/* A hook for getting access to the context value */
import React from 'react';
import { RuleSummary } from 'Source/graphql/fragments/RuleSummary.generated';
import { PolicySummary } from 'Source/graphql/fragments/PolicySummary.generated';

import useModal from 'Hooks/useModal';
import DeleteDetectionsModal from 'Components/modals/DeleteDetectionsModal';

// Used this hook in order to avoid duplicate entries of the DeleteDetectionsModal along with
// recalculating the title prop multiple times.
const useDeleteDetectionModal = () => {
  const { showModal } = useModal();
  return React.useCallback(
    (detections: (RuleSummary | PolicySummary)[], onSuccess = () => {}) => {
      const isMultiDelete = detections.length > 1;
      const modalTitle = isMultiDelete
        ? `Delete ${detections.length} Detections`
        : `Delete Detection`;

      return showModal(<DeleteDetectionsModal detections={detections} onSuccess={onSuccess} />, {
        title: modalTitle,
      });
    },
    [showModal]
  );
};

export default useDeleteDetectionModal;
