/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Badge, Box, Button, Card, Collapse, Flex, Grid, Img, Link, Text, Tooltip } from 'pouncejs';
import { Link as RRLink } from 'react-router-dom';

import DisabledLink from 'Components/DisabledLink';
import { CardValue, CardMetaValue } from 'Components/cards/common';
import RoleRestrictedAccess from 'Components/utils/RoleRestrictedAccess';
import { Permission, Severity } from 'Generated/schema';
import { alertTypeToString, formatDatetime } from 'Helpers/utils';
import { DestinationDetails } from 'Source/graphql/fragments/DestinationDetails.generated';
import urls from 'Source/urls';
import LimitItemDisplay from 'Components/LimitItemDisplay';
import AdditionalBadgesJewel from 'Components/AdditionalBadgesJewel';
import { SEVERITY_COLOR_MAP } from 'Source/constants';
import DestinationCardOptions from './DestinationCardOptions';

interface DestinationCardProps {
  destination: DestinationDetails;
  logo: string;
  metadata?: {
    key: string;
    value: string;
  }[];
}

const DestinationCard: React.FC<DestinationCardProps> = ({ destination, logo, metadata }) => {
  const [open, setOpen] = React.useState(false);
  const availableSeverities = React.useMemo(() => Object.values(Severity).reverse(), []);

  return (
    <Card as="section" variant="dark" p={4}>
      <Flex position="relative">
        <Img nativeWidth={20} nativeHeight={20} mr={5} alt="Logo" src={logo} />
        <Flex direction="column" justify="space-between" width={1}>
          <Flex as="header" align="flex-start" mb={2}>
            <Box as="h4" fontWeight="medium" mr="auto" maxWidth="80%" wordBreak="break-word">
              <RoleRestrictedAccess
                allowedPermissions={[Permission.DestinationModify]}
                fallback={<DisabledLink>{destination.displayName}</DisabledLink>}
              >
                <Link as={RRLink} to={urls.integrations.destinations.edit(destination.outputId)}>
                  {destination.displayName}
                </Link>
              </RoleRestrictedAccess>
            </Box>
            <CardMetaValue label="Updated" value={formatDatetime(destination.lastModifiedTime)} />
            <RoleRestrictedAccess allowedPermissions={[Permission.DestinationModify]}>
              <Box mt={-1} ml={2}>
                <DestinationCardOptions destination={destination} />
              </Box>
            </RoleRestrictedAccess>
          </Flex>
          <Card variant="darker" overflow="hidden" p={4}>
            <Grid templateColumns="5fr auto 1fr" columnGap={2}>
              <Flex align="center" spacing={4}>
                <Text fontSize="small" fontWeight="bold">
                  Alert Types
                </Text>
                <Flex spacing={1}>
                  <LimitItemDisplay limit={4} AdditionalItemsComponent={AdditionalBadgesJewel}>
                    {destination.alertTypes.map(alertType => (
                      <Badge key={alertType} color="navyblue-200">
                        {alertTypeToString(alertType)}
                      </Badge>
                    ))}
                  </LimitItemDisplay>
                </Flex>
              </Flex>
              <Flex align="center" spacing={4}>
                <Text fontSize="small" fontWeight="bold">
                  Alert Severities
                </Text>
                <Flex spacing={1}>
                  {availableSeverities.map(severity => (
                    <Tooltip key={severity} content={severity}>
                      <Flex
                        width={18}
                        height={18}
                        alignItems="center"
                        justifyContent="center"
                        overflow="hidden"
                        position="relative"
                        borderRadius="small"
                        border="1px solid"
                        borderColor={SEVERITY_COLOR_MAP[severity]}
                        opacity={destination.defaultForSeverity.includes(severity) ? 1 : 0.2}
                      >
                        {/**
                         * Keeping the bg color separate and absolutely positioned
                         * allows us to more granularly control opacity without
                         * affecting the text contrast
                         */}
                        <Box
                          backgroundColor={SEVERITY_COLOR_MAP[severity]}
                          position="absolute"
                          width="100%"
                          height="100%"
                          opacity={0.8}
                        ></Box>
                        <Text
                          fontSize="x-small"
                          as="span"
                          fontWeight="bold"
                          opacity={1}
                          position="relative"
                        >
                          {severity.charAt(0).toUpperCase()}
                        </Text>
                      </Flex>
                    </Tooltip>
                  ))}
                </Flex>
              </Flex>
              {metadata && (
                <Box mr={0} ml="auto">
                  <Button
                    onClick={() => setOpen(!open)}
                    size="small"
                    variantColor="navyblue-400"
                    icon={open ? 'chevron-up' : 'chevron-down'}
                    iconAlignment="right"
                  >
                    {open ? 'Hide Details' : 'Show Details'}
                  </Button>
                </Box>
              )}
            </Grid>
            {metadata && (
              <Collapse open={open}>
                <Flex wrap="wrap" spacing={8}>
                  {metadata.map(({ key, value }) => (
                    <CardValue key={key} label={key} value={value} />
                  ))}
                </Flex>
              </Collapse>
            )}
          </Card>
        </Flex>
      </Flex>
    </Card>
  );
};

export default React.memo(DestinationCard);
