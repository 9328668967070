/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import useNavigationKeys from 'Hooks/useNavigationKeys';
import useUrlParams from 'Hooks/useUrlParams';
import { useGetReplayedAlertEvents } from 'Pages/ReplayedAlertDetails/graphql/getReplayedAlertEvents.generated';
import React from 'react';
import { TableControlsPagination as PaginationControls } from 'Components/utils/TableControls';
import { toPlural, downloadData } from 'Helpers/utils';
import { Flex, Heading, Box, Tooltip, Button, Card, Icon, Alert } from 'pouncejs';
import JsonViewer from 'Components/JsonViewer';
import { RuleReplayedAlertDetailsUrlParams } from './RuleReplayedAlertDetails';

type RuleReplayedAlertDetailsEventsProps = {
  alertId: string;
  replayId: string;
  totalEvents: number;
};

const RuleReplayedAlertDetailsEvents: React.FC<RuleReplayedAlertDetailsEventsProps> = ({
  alertId,
  replayId,
  totalEvents,
}) => {
  const pageSize = 10;
  const [currentEventIndex, setCurrentEventIndex] = React.useState<number>(0);

  const { urlParams } = useUrlParams<RuleReplayedAlertDetailsUrlParams>();

  const { data, error, fetchMore } = useGetReplayedAlertEvents({
    variables: { alertId, replayId, eventsInput: { pageSize } },
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
  });

  const events = React.useMemo(() => {
    return data?.replayedAlert.origin.events.items || [];
  }, [data]);

  const lastEvaluatedKey = React.useMemo(() => {
    return data?.replayedAlert.origin.events.lastEvaluatedKey || '';
  }, [data]);

  React.useEffect(() => {
    if (events.length - currentEventIndex <= 6) {
      const exclusiveStartKey = lastEvaluatedKey;
      fetchMore({
        variables: { alertId, replayId, eventsInput: { pageSize, exclusiveStartKey } },
      });
    }
  }, [data, alertId, replayId, fetchMore, events, currentEventIndex, lastEvaluatedKey]);

  const incrementIndex = React.useCallback(val => setCurrentEventIndex(currentEventIndex + val), [
    setCurrentEventIndex,
    currentEventIndex,
  ]);

  useNavigationKeys({
    disabled: urlParams.section !== 'events', // disabled when not on this tab
    nextCallback: () => incrementIndex(1),
    previousCallback: () => incrementIndex(-1),
  });

  const currentEvent = events[currentEventIndex];
  const currentEventIndexDisplay = currentEventIndex + 1;

  return (
    <Flex direction="column" spacing={6}>
      <Flex justify="space-between" align="center">
        <Flex align="center" spacing={2}>
          <Heading size="x-small">
            <b>{totalEvents}</b> Triggered {toPlural('Event', totalEvents)}
          </Heading>
          <Tooltip
            content={
              <Box maxWidth={300}>
                Each triggered event contains a set of extended metadata for a log entry that was
                matched against your rule
              </Box>
            }
          >
            <Icon type="info" size="medium" />
          </Tooltip>
        </Flex>
        <Box position="relative">
          <PaginationControls
            page={currentEventIndexDisplay}
            totalPages={totalEvents}
            onPageChange={x => setCurrentEventIndex(x - 1)}
          />
        </Box>
        <Flex spacing={4}>
          <Button
            onClick={() =>
              downloadData(
                JSON.stringify(currentEvent),
                `${alertId}-${currentEventIndexDisplay}.json`
              )
            }
          >
            Download JSON
          </Button>
        </Flex>
      </Flex>
      {error && <Alert title="error loading events" variant="error" discardable />}
      {currentEvent && (
        <Card variant="dark" p={6}>
          <JsonViewer data={currentEvent} />
        </Card>
      )}
    </Flex>
  );
};

export default React.memo(RuleReplayedAlertDetailsEvents);
