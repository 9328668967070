/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import ProductConsentModal from 'Components/modals/ProductConsentModal';
import shootFireworks from 'Helpers/fireworks';
import React from 'react';
import useModal from 'Hooks/useModal';
import useCheckPermissions from 'Hooks/useCheckPermissions';
import { Permission } from 'Generated/schema';
import { useGetGeneralSettingsConsents } from './graphql/getGeneralSettingsConsents.generated';

const useShowProductConsentModal = () => {
  const { showModal } = useModal();

  const isUserAllowedToChangeSettings = useCheckPermissions(Permission.GeneralSettingsModify);

  // We are intentionally over-fetching, in order to proactively add this data to the cache
  useGetGeneralSettingsConsents({
    onCompleted: data => {
      // The `shouldShowProductAnalyticsConsent` should only be true for users upgrading to v1.10
      const shouldShowProductAnalyticsConsent = data.generalSettings.analyticsConsent === null;

      const isLicenseAccepted = data.generalSettings.licenseAccepted;

      if (shouldShowProductAnalyticsConsent && isUserAllowedToChangeSettings) {
        // Show analytics consent modal
        showModal(<ProductConsentModal isLicenseAccepted={isLicenseAccepted} />, {
          title: 'License Agreements',
          'aria-describedby': 'modal-subtitle',
        });

        // Welcome the first user while singing Katy Perry
        if (shouldShowProductAnalyticsConsent) {
          shootFireworks();
        }
      }
    },
  });
};

export default useShowProductConsentModal;
