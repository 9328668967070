/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import * as Types from '../../../../../../../__generated__/schema';

import { ZoomLogIntegrationDetails } from '../../../../../../graphql/fragments/ZoomLogIntegrationDetails.generated';
import { GraphQLError } from 'graphql';
import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';

export type UpdateZoomLogSourceVariables = {
  input: Types.UpdateLogPullingIntegrationInput;
};

export type UpdateZoomLogSource = { updateLogPullingIntegration: ZoomLogIntegrationDetails };

export const UpdateZoomLogSourceDocument = gql`
  mutation UpdateZoomLogSource($input: UpdateLogPullingIntegrationInput!) {
    updateLogPullingIntegration(input: $input) {
      ...ZoomLogIntegrationDetails
    }
  }
  ${ZoomLogIntegrationDetails}
`;
export type UpdateZoomLogSourceMutationFn = ApolloReactCommon.MutationFunction<
  UpdateZoomLogSource,
  UpdateZoomLogSourceVariables
>;

/**
 * __useUpdateZoomLogSource__
 *
 * To run a mutation, you first call `useUpdateZoomLogSource` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateZoomLogSource` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateZoomLogSource, { data, loading, error }] = useUpdateZoomLogSource({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateZoomLogSource(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateZoomLogSource,
    UpdateZoomLogSourceVariables
  >
) {
  return ApolloReactHooks.useMutation<UpdateZoomLogSource, UpdateZoomLogSourceVariables>(
    UpdateZoomLogSourceDocument,
    baseOptions
  );
}
export type UpdateZoomLogSourceHookResult = ReturnType<typeof useUpdateZoomLogSource>;
export type UpdateZoomLogSourceMutationResult = ApolloReactCommon.MutationResult<
  UpdateZoomLogSource
>;
export type UpdateZoomLogSourceMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateZoomLogSource,
  UpdateZoomLogSourceVariables
>;
export function mockUpdateZoomLogSource({
  data,
  variables,
  errors,
}: {
  data: UpdateZoomLogSource;
  variables?: UpdateZoomLogSourceVariables;
  errors?: GraphQLError[];
}) {
  return {
    request: { query: UpdateZoomLogSourceDocument, variables },
    result: { data, errors },
  };
}
