/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import * as Types from '../../../../../__generated__/schema';

import { UserDetails } from '../../../../graphql/fragments/UserDetails.generated';
import { GraphQLError } from 'graphql';
import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';

export type InviteUserVariables = {
  input: Types.InviteUserInput;
};

export type InviteUser = { inviteUser: { user: UserDetails } };

export const InviteUserDocument = gql`
  mutation InviteUser($input: InviteUserInput!) {
    inviteUser(input: $input) {
      user {
        ...UserDetails
      }
    }
  }
  ${UserDetails}
`;
export type InviteUserMutationFn = ApolloReactCommon.MutationFunction<
  InviteUser,
  InviteUserVariables
>;

/**
 * __useInviteUser__
 *
 * To run a mutation, you first call `useInviteUser` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInviteUser` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inviteUser, { data, loading, error }] = useInviteUser({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useInviteUser(
  baseOptions?: ApolloReactHooks.MutationHookOptions<InviteUser, InviteUserVariables>
) {
  return ApolloReactHooks.useMutation<InviteUser, InviteUserVariables>(
    InviteUserDocument,
    baseOptions
  );
}
export type InviteUserHookResult = ReturnType<typeof useInviteUser>;
export type InviteUserMutationResult = ApolloReactCommon.MutationResult<InviteUser>;
export type InviteUserMutationOptions = ApolloReactCommon.BaseMutationOptions<
  InviteUser,
  InviteUserVariables
>;
export function mockInviteUser({
  data,
  variables,
  errors,
}: {
  data: InviteUser;
  variables?: InviteUserVariables;
  errors?: GraphQLError[];
}) {
  return {
    request: { query: InviteUserDocument, variables },
    result: { data, errors },
  };
}
