/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import useAvailableLogTypesForProvider from './useAvailableLogTypesForProvider';
import useRouter from './useRouter';

/**
 * Hook used to return all available log types from Log Puller create & edit pages.
 */
const useAvailableLogTypesForLogPuller = () => {
  const {
    match: {
      params: { type },
    },
  } = useRouter<{ type: string }>();

  if (!type) {
    throw new Error(`Unknown source type`);
  }

  const availableLogTypesForProvider = useAvailableLogTypesForProvider(type);

  return React.useMemo(() => availableLogTypesForProvider, [availableLogTypesForProvider]);
};

export default useAvailableLogTypesForLogPuller;
