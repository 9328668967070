/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import * as Types from '../../../../../../../__generated__/schema';

import { M365LogIntegrationDetails } from '../../../../../../graphql/fragments/M365LogIntegrationDetails.generated';
import { GraphQLError } from 'graphql';
import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';

export type AddM365LogSourceVariables = {
  input: Types.AddLogPullingIntegrationInput;
};

export type AddM365LogSource = { addLogPullingIntegration: M365LogIntegrationDetails };

export const AddM365LogSourceDocument = gql`
  mutation AddM365LogSource($input: AddLogPullingIntegrationInput!) {
    addLogPullingIntegration(input: $input) {
      ...M365LogIntegrationDetails
    }
  }
  ${M365LogIntegrationDetails}
`;
export type AddM365LogSourceMutationFn = ApolloReactCommon.MutationFunction<
  AddM365LogSource,
  AddM365LogSourceVariables
>;

/**
 * __useAddM365LogSource__
 *
 * To run a mutation, you first call `useAddM365LogSource` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddM365LogSource` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addM365LogSource, { data, loading, error }] = useAddM365LogSource({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddM365LogSource(
  baseOptions?: ApolloReactHooks.MutationHookOptions<AddM365LogSource, AddM365LogSourceVariables>
) {
  return ApolloReactHooks.useMutation<AddM365LogSource, AddM365LogSourceVariables>(
    AddM365LogSourceDocument,
    baseOptions
  );
}
export type AddM365LogSourceHookResult = ReturnType<typeof useAddM365LogSource>;
export type AddM365LogSourceMutationResult = ApolloReactCommon.MutationResult<AddM365LogSource>;
export type AddM365LogSourceMutationOptions = ApolloReactCommon.BaseMutationOptions<
  AddM365LogSource,
  AddM365LogSourceVariables
>;
export function mockAddM365LogSource({
  data,
  variables,
  errors,
}: {
  data: AddM365LogSource;
  variables?: AddM365LogSourceVariables;
  errors?: GraphQLError[];
}) {
  return {
    request: { query: AddM365LogSourceDocument, variables },
    result: { data, errors },
  };
}
