/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import { useFormikContext } from 'formik';
import useRouter from 'Hooks/useRouter';
import React from 'react';
import { LookupFormValues } from 'Components/wizards/LookupWizard/lookupWizardHelpers';
import { WizardEditActions } from 'Components/Wizard';

const EditLookupActions = () => {
  const { history } = useRouter();
  const { submitForm, isSubmitting, dirty, isValid } = useFormikContext<LookupFormValues>();
  return (
    <WizardEditActions>
      <WizardEditActions.Cancel aria-label="Cancel editing" onClick={history.goBack} />
      <WizardEditActions.Save
        aria-live="polite"
        aria-label="Save editing"
        aria-busy={isSubmitting}
        onClick={submitForm}
        loading={isSubmitting}
        disabled={isSubmitting || !dirty || !isValid}
        data-tid="lookup-edit-save"
      />
    </WizardEditActions>
  );
};

export default EditLookupActions;
