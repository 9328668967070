/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Flex, Tooltip, Box } from 'pouncejs';
import NotificationJewel from 'Components/NotificationJewel';

interface LimitItemDisplayProps {
  /**
   * How many items should we show before we limit them
   */
  limit: number;
  /**
   * The Component to render in place of the hidden items
   */
  AdditionalItemsComponent?: React.FC<{ additionalItems: LimitItemDisplayProps['children'][] }>;
  /**
   * @ignore
   */
  children: React.ReactNode | React.ReactNode[];
}

const LimitItemDisplay: React.FC<LimitItemDisplayProps> = ({
  limit,
  AdditionalItemsComponent = ({ additionalItems }) => (
    <NotificationJewel badge={`+${additionalItems.length}`} />
  ),
  children,
}) => {
  const childrenCount = React.Children.count(children);
  if (childrenCount <= limit) {
    return children as any;
  }

  const childrenList = React.Children.toArray(children);
  const displayedChildren = childrenList.slice(0, limit);
  const hiddenChildren = childrenList.slice(limit);

  return (
    <React.Fragment>
      {displayedChildren}
      <Tooltip
        content={
          <Flex direction="column" spacing={1}>
            {hiddenChildren}
          </Flex>
        }
      >
        <Box>
          <AdditionalItemsComponent additionalItems={hiddenChildren} />
        </Box>
      </Tooltip>
    </React.Fragment>
  );
};

export default React.memo(LimitItemDisplay);
