/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { useGetMitreLogSources } from 'Pages/Reports/MitreMatrix/graphql/getMitreLogSources.generated';
import MitreLogSourceCard, {
  MitreLogSourceCardProps,
} from 'Pages/Reports/Mitre/MitreLogSourceCard';
import { Flex, Box, Text, Alert } from 'pouncejs';
import without from 'lodash/without';
import intersection from 'lodash/intersection';
import { toPlural } from 'Helpers/utils';
import ErrorBoundary from 'Components/ErrorBoundary';
import { SourceIntegrationType } from 'Pages/Integrations/utils';
import TablePlaceholder from 'Components/TablePlaceholder';

interface MitreRelationsLogSourcesProps {
  matchingLogTypes: string[];
}

const MitreRelationsLogSources = ({
  matchingLogTypes: allMatchingLogTypes,
}: MitreRelationsLogSourcesProps) => {
  const { loading, data, error } = useGetMitreLogSources();

  const logSourceCardProps: MitreLogSourceCardProps[] = (data?.logSources ?? [])
    .map(logSource => ({
      logSource: logSource as SourceIntegrationType,
      otherLogTypes: without(logSource.logTypes, ...allMatchingLogTypes),
      matchingLogTypes: intersection(allMatchingLogTypes, logSource.logTypes),
    }))
    .filter(({ matchingLogTypes }) => matchingLogTypes.length !== 0);

  if (loading) {
    return (
      <Box pt={8}>
        <TablePlaceholder rowCount={2} rowHeight={40} />
      </Box>
    );
  }

  if (error) {
    return <Alert variant="error" title="failed to load log sources" />;
  }

  return (
    <ErrorBoundary>
      <Box>
        <Flex py={2} px={4} alignItems="center">
          <Text>
            {logSourceCardProps.length} {toPlural('log source', logSourceCardProps.length)}
          </Text>
          <Box minHeight={46} />
        </Flex>
        <Flex direction="column" spacing={4} pt={2} px={3}>
          {logSourceCardProps.map(props => (
            <MitreLogSourceCard key={props.logSource.integrationId} {...props} />
          ))}
        </Flex>
      </Box>
    </ErrorBoundary>
  );
};

export default MitreRelationsLogSources;
