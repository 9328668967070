/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import * as Types from '../../../../__generated__/schema';

import { DataSchemaFull } from '../../../graphql/fragments/DataSchemaFull.generated';
import { GraphQLError } from 'graphql';
import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';

export type CreateDataSchemaVariables = {
  input: Types.PutUserSchemaInput;
};

export type CreateDataSchema = {
  putUserSchema: {
    error?: Types.Maybe<Pick<Types.Error, 'message'>>;
    record?: Types.Maybe<DataSchemaFull>;
  };
};

export const CreateDataSchemaDocument = gql`
  mutation CreateDataSchema($input: PutUserSchemaInput!) {
    putUserSchema(input: $input) {
      error {
        message
      }
      record {
        ...DataSchemaFull
      }
    }
  }
  ${DataSchemaFull}
`;
export type CreateDataSchemaMutationFn = ApolloReactCommon.MutationFunction<
  CreateDataSchema,
  CreateDataSchemaVariables
>;

/**
 * __useCreateDataSchema__
 *
 * To run a mutation, you first call `useCreateDataSchema` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDataSchema` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDataSchema, { data, loading, error }] = useCreateDataSchema({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateDataSchema(
  baseOptions?: ApolloReactHooks.MutationHookOptions<CreateDataSchema, CreateDataSchemaVariables>
) {
  return ApolloReactHooks.useMutation<CreateDataSchema, CreateDataSchemaVariables>(
    CreateDataSchemaDocument,
    baseOptions
  );
}
export type CreateDataSchemaHookResult = ReturnType<typeof useCreateDataSchema>;
export type CreateDataSchemaMutationResult = ApolloReactCommon.MutationResult<CreateDataSchema>;
export type CreateDataSchemaMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateDataSchema,
  CreateDataSchemaVariables
>;
export function mockCreateDataSchema({
  data,
  variables,
  errors,
}: {
  data: CreateDataSchema;
  variables?: CreateDataSchemaVariables;
  errors?: GraphQLError[];
}) {
  return {
    request: { query: CreateDataSchemaDocument, variables },
    result: { data, errors },
  };
}
