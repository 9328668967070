/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import useAsyncQueryContext from 'Hooks/useAsyncQueryContext';
import useUrlParams from 'Hooks/useUrlParams';
import { IndicatorUrlParams } from 'Helpers/utils';
import { useGetSavedQuery } from 'Source/graphql/queries';
import { useGetSqlForQuery } from './graphql/getSqlForQuery.generated';
import { useGetSQLForSnippet } from './graphql/getSqlForSnippet.generated';
import { useDataExplorerContext } from '../DataExplorerContext';
import { DataExplorerUrlParams, SNIPPET_TYPES } from '../constants';

type SqlGenerationType = 'executedQuery' | 'savedQuery' | 'snippet';

/**
 * Responsible for generated SQL values in the SQL editor. This component will load
 * the appropriate query and prepopulate the editor based on URL parameters.
 */
const useGeneratedSQLEditorValue = () => {
  const { dispatch } = useDataExplorerContext();
  const { state: { queryId } } = useAsyncQueryContext(); // prettier-ignore
  const {
    urlParams: { snippedId, alertId, ruleId, savedQueryId, lookupId, sourceId },
  } = useUrlParams<DataExplorerUrlParams>();

  const {
    urlParams: {
      databaseName,
      startTime,
      endTime,
      indicatorName,
      i: indicators,
      tableName,
      logType,
    },
  } = useUrlParams<IndicatorUrlParams>({ parseNumbers: false, parseBooleans: false });

  const setValue = React.useCallback(
    (newValue: string) => dispatch({ type: 'UPDATE_SQL', payload: { sql: newValue } }),
    [dispatch]
  );

  const shouldGetSavedQuery = Boolean(savedQueryId);

  const sqlGenerationType: SqlGenerationType | null = React.useMemo(() => {
    if (queryId) {
      return 'executedQuery';
    }

    if (savedQueryId) {
      return 'savedQuery';
    }

    if (SNIPPET_TYPES.includes(snippedId)) {
      return 'snippet';
    }

    return null;
  }, [snippedId, queryId, savedQueryId]);

  const generateQueryParameters = () => {
    const snippedIdMapping: Record<typeof snippedId, unknown> = {
      indicatorDetail: {
        databaseName,
        tableName,
        startTime,
        endTime,
        indicatorName,
        indicators,
        logType,
      },
      alertId: { alertId },
      alertIdError: { alertId },
      ruleId: { ruleId },
      sourceLogtype: { sourceId, logType },
      lookupTable: { lookupId },
      previewTable: { databaseName, tableName },
    };

    return snippedIdMapping[snippedId] ?? {};
  };

  // Restore SQL for query if it already existed
  const { loading: loadingSQLForExecutedQuery } = useGetSqlForQuery({
    skip: sqlGenerationType !== 'executedQuery',
    variables: { id: queryId },
    onCompleted: data => setValue(data.dataLakeQuery.sql),
  });

  const { data: savedQueryData, loading: loadingSQLForSavedQuery } = useGetSavedQuery({
    // It's possible to have both a saved query and a query execution in the URL parameters,
    // so we can't use the `sqlGenerationType` to determine whether to skip the query.
    skip: !shouldGetSavedQuery,
    variables: {
      id: savedQueryId,
    },
    onCompleted: data => {
      // Since this query still runs when fetching query execution results, this needs an explicit check.
      // Otherwise this will cause a race condition — whichever query finishes last (this one or `useGetSqlForQuery`)
      // will be the SQL that gets set in the editor
      if (sqlGenerationType === 'savedQuery') {
        setValue(data.getSavedQuery.sqlQuery);
      }
    },
  });

  const { loading: loadingSQLForSnippet } = useGetSQLForSnippet({
    skip: sqlGenerationType !== 'snippet',
    // Skip caching, which causes DE to get into weird states during long sessions, since
    // `onCompleted` only fires after network calls.
    //
    // We may be able to reintroduce caching once we properly handle cache hits in the
    // query generation (by not using `onCompleted`)
    fetchPolicy: 'network-only',
    variables: {
      input: {
        queryType: snippedId,
        queryParameters: generateQueryParameters(),
      },
    },
    onCompleted: data => setValue(data.generateSqlQuerySnippet.sql),
  });

  const isLoadingSQL =
    loadingSQLForSnippet || loadingSQLForExecutedQuery || loadingSQLForSavedQuery;

  const placeholder = React.useMemo(() => {
    // If we're not loading SQL from the API, use the default placeholder in case the
    // user deletes the generated SQL.
    if (!isLoadingSQL || sqlGenerationType === null) {
      return null;
    }

    const placeholderMapping: Record<SqlGenerationType, string> = {
      executedQuery: 'Loading SQL for query...',
      savedQuery: 'Loading SQL from saved query...',
      snippet: 'Generating SQL snippet...',
    };

    return placeholderMapping[sqlGenerationType];
  }, [isLoadingSQL, sqlGenerationType]);

  return React.useMemo(
    () => ({
      placeholder,
      activeQuery: savedQueryData?.getSavedQuery ?? null,
      isLoadingSQL,
    }),
    [placeholder, savedQueryData, isLoadingSQL]
  );
};

export default useGeneratedSQLEditorValue;
