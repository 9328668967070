/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import { BorderedTab, BorderTabDivider } from 'Components/BorderedTab';
import ViewWithDataExplorerButton from 'Components/buttons/ViewWithDataExplorerButton';
import ErrorBoundary from 'Components/ErrorBoundary';
import {
  AlertsInput,
  AlertStatus,
  AlertType,
  DetectionTypeEnum,
  Permission,
} from 'Generated/schema';

import { compose } from 'Helpers/compose';
import { alertTypeToString, extractErrorMessage } from 'Helpers/utils';
import withRoleRestrictedAccess from 'Hoc/withRoleRestrictedAccess';

import withSEO from 'Hoc/withSEO';

import useRouter from 'Hooks/useRouter';
import useUrlParams from 'Hooks/useUrlParams';
import invert from 'lodash/invert';
import Page403 from 'Pages/403';
import DetectionDetailsFunctionsTab from 'Pages/common/DetectionDetailsFunctionsTab';
import { Alert, Box, Card, Flex, TabList, TabPanel, TabPanels, Tabs } from 'pouncejs';
import React from 'react';
import { DEFAULT_LARGE_PAGE_SIZE } from 'Source/constants';
import { useGetRuleDetails } from './graphql/getRuleDetails.generated';
import { useListAlertsForRule } from './graphql/listAlertsForRule.generated';
import ListRuleAlerts from './ListRuleAlerts';
import RuleDetailsBanner from './RuleDetailsBanner';
import RuleDetailsInfo from './RuleDetailsInfo';
import RuleDetailsPageSkeleton from './Skeleton';

export const ALERT_MATCH_TYPES = [AlertType.Rule, AlertType.ScheduledRule];
export const ALERT_ERROR_TYPES = [AlertType.RuleError, AlertType.ScheduledRuleError];

export interface RuleDetailsPageUrlParams {
  section?: 'details' | 'matches' | 'errors' | 'functions';
}

const sectionToTabIndex: Record<RuleDetailsPageUrlParams['section'], number> = {
  details: 0,
  matches: 1,
  errors: 2,
  functions: 3,
};

const tabIndexToSection = invert(sectionToTabIndex) as Record<
  number,
  RuleDetailsPageUrlParams['section']
>;

interface RuleDetailsTabDefaultUrlParams {
  statuses?: AlertsInput['statuses'];
}

export const tabDefaultParams: Record<
  RuleDetailsPageUrlParams['section'],
  RuleDetailsTabDefaultUrlParams
> = {
  errors: { statuses: [AlertStatus.Open, AlertStatus.Triaged] },
  matches: { statuses: [AlertStatus.Open, AlertStatus.Triaged] },
  details: {},
  functions: {},
};

const RuleDetailsPage: React.FC = () => {
  const { match } = useRouter<{ id: string }>();
  const { urlParams, setUrlParams } = useUrlParams<RuleDetailsPageUrlParams>();
  const { error, data, loading } = useGetRuleDetails({
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    variables: {
      input: {
        id: match.params.id,
      },
    },
  });

  const handleTabChange = React.useCallback(
    (idx: number) => {
      const section = tabIndexToSection[idx];
      const defaultParams = tabDefaultParams[section];
      setUrlParams({ ...defaultParams, section });
    },
    [setUrlParams]
  );

  const { data: matchesData } = useListAlertsForRule({
    // FIXME: Restore cache-and-network when we properly handle duplicates in endless pagination
    // fetchPolicy: 'cache-and-network',
    // nextFetchPolicy: 'cache-first',
    variables: {
      input: {
        types: ALERT_MATCH_TYPES,
        detectionId: match.params.id,
        pageSize: DEFAULT_LARGE_PAGE_SIZE,
      },
    },
  });

  const { data: errorData } = useListAlertsForRule({
    // FIXME: Restore cache-and-network when we properly handle duplicates in endless pagination
    // fetchPolicy: 'cache-and-network',
    // nextFetchPolicy: 'cache-first',
    variables: {
      input: {
        types: ALERT_ERROR_TYPES,
        detectionId: match.params.id,
        pageSize: DEFAULT_LARGE_PAGE_SIZE,
      },
    },
  });

  if (loading && !data) {
    return <RuleDetailsPageSkeleton />;
  }

  if (error) {
    return (
      <Box mb={6} data-testid={`rule-${match.params.id}`}>
        <Alert
          variant="error"
          title="Couldn't load rule"
          description={
            extractErrorMessage(error) ||
            " An unknown error occured and we couldn't load the rule details from the server"
          }
        />
      </Box>
    );
  }

  const hasAnyAlerts = matchesData?.alerts?.edges.length > 0 || errorData?.alerts?.edges.length > 0;
  const isScheduled = data?.rule?.analysisType === DetectionTypeEnum.ScheduledRule;

  return (
    <Box as="article" mb={6} data-tracking-page="rule-details">
      <Flex direction="column" spacing={6}>
        <ErrorBoundary>
          <RuleDetailsBanner rule={data.rule} />
        </ErrorBoundary>
        <Card position="relative">
          <Tabs index={sectionToTabIndex[urlParams.section] || 0} onChange={handleTabChange}>
            <Box px={2}>
              <Flex align="center" justify="space-between" pr={2}>
                <TabList>
                  <BorderedTab>Details</BorderedTab>
                  <BorderedTab>
                    <Box data-testid="rule-matches">
                      {alertTypeToString(isScheduled ? AlertType.ScheduledRule : AlertType.Rule)}
                    </Box>
                  </BorderedTab>
                  <BorderedTab>
                    <Box data-testid="rule-errors">
                      {alertTypeToString(
                        isScheduled ? AlertType.ScheduledRuleError : AlertType.RuleError
                      )}
                    </Box>
                  </BorderedTab>
                  <BorderedTab>Functions & Tests</BorderedTab>
                </TabList>
                {hasAnyAlerts && (
                  <ViewWithDataExplorerButton
                    snippedId="ruleId"
                    snippetParams={{ ruleId: data.rule.id }}
                  />
                )}
              </Flex>
              <BorderTabDivider />
              <TabPanels>
                <TabPanel data-testid="rule-details-tabpanel" data-tid="rule-details-info-tab">
                  <RuleDetailsInfo rule={data.rule} />
                </TabPanel>
                <TabPanel
                  data-testid="rule-matches-tabpanel"
                  data-tid="rule-matches-tab"
                  lazy
                  unmountWhenInactive
                >
                  <ListRuleAlerts ruleId={match.params.id} types={ALERT_MATCH_TYPES} />
                </TabPanel>
                <TabPanel
                  data-testid="rule-errors-tabpanel"
                  data-tid="rule-errors-tab"
                  lazy
                  unmountWhenInactive
                >
                  <ListRuleAlerts ruleId={match.params.id} types={ALERT_ERROR_TYPES} />
                </TabPanel>
                <TabPanel
                  data-tid="rule-functions-tab"
                  data-testid="function-settings-tabpanel"
                  lazy
                  unmountWhenInactive
                >
                  <DetectionDetailsFunctionsTab detection={data.rule} />
                </TabPanel>
              </TabPanels>
            </Box>
          </Tabs>
        </Card>
      </Flex>
    </Box>
  );
};

export default compose(
  withSEO({ title: ({ match }) => match.params.id }),
  withRoleRestrictedAccess({
    allowedPermissions: [Permission.RuleRead, Permission.RuleModify],
    fallback: <Page403 />,
  })
)(RuleDetailsPage);
