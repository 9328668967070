/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Dropdown, DropdownButton, DropdownItem, DropdownMenu } from 'pouncejs';
import useModal from 'Hooks/useModal';
import DeleteUserModal from 'Components/modals/DeleteUserModal';
import ResetUserPasswordModal from 'Components/modals/ResetUserPasswordModal';
import ReinviteUserModal from 'Components/modals/ReinviteUserModal';
import useAuth from 'Hooks/useAuth';
import { getActorDisplayName, isSamlUser } from 'Helpers/utils';
import { SIDESHEETS } from 'Components/utils/Sidesheet';
import OptionsButton from 'Components/buttons/OptionsButton';
import useSidesheet from 'Hooks/useSidesheet';

import { UserDetails } from 'Source/graphql/fragments/UserDetails.generated';

interface ListUsersTableRowOptionsProps {
  user: UserDetails;
}

const ListUsersTableRowOptions: React.FC<ListUsersTableRowOptionsProps> = ({ user }) => {
  const { showModal } = useModal();
  const { userInfo } = useAuth();
  const { showSidesheet } = useSidesheet();
  const userDisplayName = getActorDisplayName(user);
  return (
    <Dropdown>
      <DropdownButton data-tid="user-options-dropdown" as={OptionsButton} />
      <DropdownMenu minWidth="200px">
        <DropdownItem
          data-tid="edit-user"
          onSelect={() => showSidesheet({ sidesheet: SIDESHEETS.EDIT_USER, props: { user } })}
        >
          Edit
        </DropdownItem>
        {!isSamlUser(user) &&
          user.id !== userInfo.id &&
          (user.status !== 'FORCE_CHANGE_PASSWORD' ? (
            <DropdownItem
              data-tid="reset-user-password"
              onSelect={() =>
                showModal(<ResetUserPasswordModal user={user} />, {
                  title: `Force a password change for ${userDisplayName}`,
                })
              }
            >
              Force password reset
            </DropdownItem>
          ) : (
            <DropdownItem
              data-tid="re-invite-user"
              onSelect={() =>
                showModal(<ReinviteUserModal user={user} />, {
                  title: `Reinvite user ${userDisplayName}`,
                })
              }
            >
              Reinvite user
            </DropdownItem>
          ))}
        <DropdownItem
          data-tid="delete-user"
          onSelect={() =>
            showModal(<DeleteUserModal user={user} />, {
              title: `Delete ${userDisplayName}`,
            })
          }
        >
          Delete
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  );
};

export default React.memo(ListUsersTableRowOptions);
