/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */
import React from 'react';
import { Text } from 'pouncejs';

interface EditorTitleProps {
  queryName: string;
  isDirty: boolean;
}

const EditorTitle: React.FC<EditorTitleProps> = ({ queryName, isDirty }) => {
  if (!queryName) {
    return <Text pb={4}>New Query</Text>;
  }
  return <Text pb={4}>{isDirty ? `${queryName} *` : queryName}</Text>;
};

export default React.memo(EditorTitle);
