/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';

export interface FeatureFlagProps {
  featureFlags: boolean[];
  fallback?: React.ReactNode;
}

const withFeatureFlag = ({ featureFlags, fallback }: FeatureFlagProps) => {
  return Component => {
    const ComponentWithFeatureFlag = props => {
      const isAllowedToProceed = featureFlags.every(Boolean);

      if (!isAllowedToProceed) {
        return fallback;
      }
      return <Component {...props} />;
    };
    return ComponentWithFeatureFlag;
  };
};

export default withFeatureFlag;
