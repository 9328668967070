/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Alert, Box, Button, Card, Flex, Heading, Spinner } from 'pouncejs';
import JsonViewer, { EventNodeActions } from 'Components/JsonViewer';
import { TableControlsPagination as PaginationControls } from 'Components/utils/TableControls';
import RoleRestrictedAccess from 'Components/utils/RoleRestrictedAccess';
import { Permission, SystemErrorTypeEnum } from 'Generated/schema';
import { downloadData, extractErrorMessage, toPlural } from 'Helpers/utils';
import { DEFAULT_LARGE_PAGE_SIZE } from 'Source/constants';
import ViewWithDataExplorerButton from 'Components/buttons/ViewWithDataExplorerButton';
import { AlertDetails } from 'Pages/AlertDetails';
import { useGetSystemErrorAlertEvents } from './graphql/getSystemErrorAlertEvents.generated';

interface SystemErrorAlertEventsProps {
  alert: AlertDetails['alert'];
}

const SystemErrorAlertEvents: React.FC<SystemErrorAlertEventsProps> = ({ alert }) => {
  // because we are going to use that in PaginationControls we are starting an indexing starting
  // from 1 instead of 0. That's why we are using `eventDisplayIndex - 1` when selecting the proper event.
  // Normally the `PaginationControls` are used for displaying pages so they are built with a
  // 1-based indexing in mind
  const [eventDisplayIndex, setEventDisplayIndex] = React.useState(1);

  const { data, loading, error, fetchMore } = useGetSystemErrorAlertEvents({
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    variables: {
      id: alert.id,
      eventsInput: {
        pageSize: DEFAULT_LARGE_PAGE_SIZE,
      },
    },
  });

  const events = data?.alert.origin.events ?? null;
  React.useEffect(() => {
    if (!events) {
      return;
    }

    // We are pre-fetching the next page of events to avoid showing a spinner when users reach the end of current page
    if (eventDisplayIndex - 1 === events.items.length - DEFAULT_LARGE_PAGE_SIZE) {
      fetchMore({
        variables: {
          id: alert.id,
          eventsInput: {
            pageSize: DEFAULT_LARGE_PAGE_SIZE,
            exclusiveStartKey: events.lastEvaluatedKey,
          },
        },
      });
    }
    // FIXME: look into effect deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventDisplayIndex, events]);

  const showActionButtons = React.useMemo(() => {
    return alert?.origin['type'] !== SystemErrorTypeEnum.SourceScanningErrors;
  }, [alert?.origin]);

  if (!data && loading) {
    return (
      <Flex my={50} justify="center">
        <Spinner size="medium" />
      </Flex>
    );
  }

  if (error) {
    return (
      <Alert
        variant="error"
        title="Couldn't load events"
        description={
          extractErrorMessage(error) ||
          "An unknown error occurred and we couldn't load the alert events from the server"
        }
        actions={
          <ViewWithDataExplorerButton
            size="medium"
            variantColor="pink-600"
            snippedId="alertId"
            snippetParams={{ alertId: alert.id }}
          />
        }
      />
    );
  }

  // We don't have knowledge of how many events exist and we won't have ccause it's hard and
  // complicated for the BE to implement. As a quick hack, we show total count if there is no
  // pagination present (items < DEFAULT_LARGE_PAGE_SIZE). Else we show nothing.
  const currentCount = events.items.length;
  const totalCount = currentCount < DEFAULT_LARGE_PAGE_SIZE ? currentCount : null;
  const isLastItem = eventDisplayIndex === currentCount;
  const alertEvent = events.items[eventDisplayIndex - 1];

  return (
    <Flex direction="column" spacing={6}>
      <Flex justify="space-between" align="center">
        <Flex align="center" spacing={2}>
          <Heading size="x-small">
            <b>{totalCount}</b> Related {toPlural('Event', totalCount)}
          </Heading>
        </Flex>
        <Box position="relative">
          <PaginationControls
            page={eventDisplayIndex}
            totalPages={totalCount}
            onPageChange={setEventDisplayIndex}
            disableNextPageNavigation={isLastItem}
          />
          {loading && isLastItem && totalCount === null && (
            <Spinner
              position="absolute"
              right={-25}
              top="14px"
              transform="translateY(-50%)"
              size="small"
            />
          )}
        </Box>
        {showActionButtons && (
          <Flex spacing={4}>
            <ViewWithDataExplorerButton snippedId="alertId" snippetParams={{ alertId: alert.id }} />
            <Button
              onClick={() =>
                downloadData(JSON.stringify(alertEvent), `${alert.id}-${eventDisplayIndex}.json`)
              }
            >
              Download JSON
            </Button>
          </Flex>
        )}
      </Flex>
      <Card variant="dark" p={6}>
        <RoleRestrictedAccess
          allowedPermissions={[Permission.DataAnalyticsRead]}
          fallback={<JsonViewer data={alertEvent} />}
        >
          <JsonViewer
            data={alertEvent}
            actions={actionProps => (
              <EventNodeActions {...actionProps} dateCreated={alertEvent.p_event_time} />
            )}
          />
        </RoleRestrictedAccess>
      </Card>
    </Flex>
  );
};

export default React.memo(SystemErrorAlertEvents);
