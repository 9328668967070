/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Flex, Img, Text, Box } from 'pouncejs';
import { Link as RRLink } from 'react-router-dom';
import BetaTag from 'Components/BetaTag';

const SimpleTransportCard = ({ transportMethod, url }) => (
  <RRLink to={url}>
    <Flex
      as="span"
      align="center"
      spacing={2}
      px={5}
      py={4}
      backgroundColor="navyblue-350"
      transition="background-color 0.2s ease-in-out"
      borderRadius="large"
      _hover={{
        backgroundColor: 'navyblue-500',
      }}
    >
      <Img
        alt={transportMethod.title}
        src={transportMethod.logo}
        nativeWidth={40}
        nativeHeight={40}
      />
      <Text as="b" fontSize="medium">
        {transportMethod.title}
      </Text>
      {transportMethod.beta && (
        <Box ml="auto">
          <BetaTag />
        </Box>
      )}
    </Flex>
  </RRLink>
);

export default SimpleTransportCard;
