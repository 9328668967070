/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import * as Types from '../../../../../../__generated__/schema';

import { LookupImportDataJobDetails } from '../../../../../graphql/fragments/LookupImportDataJobDetails.generated';
import { GraphQLError } from 'graphql';
import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';

export type ImportLookupDataVariables = {
  input: Types.ImportLookupDataInput;
};

export type ImportLookupData = { importLookupData: LookupImportDataJobDetails };

export const ImportLookupDataDocument = gql`
  mutation ImportLookupData($input: ImportLookupDataInput!) {
    importLookupData(input: $input) {
      ...LookupImportDataJobDetails
    }
  }
  ${LookupImportDataJobDetails}
`;
export type ImportLookupDataMutationFn = ApolloReactCommon.MutationFunction<
  ImportLookupData,
  ImportLookupDataVariables
>;

/**
 * __useImportLookupData__
 *
 * To run a mutation, you first call `useImportLookupData` within a React component and pass it any options that fit your needs.
 * When your component renders, `useImportLookupData` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [importLookupData, { data, loading, error }] = useImportLookupData({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useImportLookupData(
  baseOptions?: ApolloReactHooks.MutationHookOptions<ImportLookupData, ImportLookupDataVariables>
) {
  return ApolloReactHooks.useMutation<ImportLookupData, ImportLookupDataVariables>(
    ImportLookupDataDocument,
    baseOptions
  );
}
export type ImportLookupDataHookResult = ReturnType<typeof useImportLookupData>;
export type ImportLookupDataMutationResult = ApolloReactCommon.MutationResult<ImportLookupData>;
export type ImportLookupDataMutationOptions = ApolloReactCommon.BaseMutationOptions<
  ImportLookupData,
  ImportLookupDataVariables
>;
export function mockImportLookupData({
  data,
  variables,
  errors,
}: {
  data: ImportLookupData;
  variables?: ImportLookupDataVariables;
  errors?: GraphQLError[];
}) {
  return {
    request: { query: ImportLookupDataDocument, variables },
    result: { data, errors },
  };
}
