/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import * as Types from '../../../../../../../__generated__/schema';

import { GithubLogIntegrationDetails } from '../../../../../../graphql/fragments/GithubLogIntegrationDetails.generated';
import { GraphQLError } from 'graphql';
import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';

export type AddGithubLogSourceVariables = {
  input: Types.AddLogPullingIntegrationInput;
};

export type AddGithubLogSource = { addLogPullingIntegration: GithubLogIntegrationDetails };

export const AddGithubLogSourceDocument = gql`
  mutation AddGithubLogSource($input: AddLogPullingIntegrationInput!) {
    addLogPullingIntegration(input: $input) {
      ...GithubLogIntegrationDetails
    }
  }
  ${GithubLogIntegrationDetails}
`;
export type AddGithubLogSourceMutationFn = ApolloReactCommon.MutationFunction<
  AddGithubLogSource,
  AddGithubLogSourceVariables
>;

/**
 * __useAddGithubLogSource__
 *
 * To run a mutation, you first call `useAddGithubLogSource` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddGithubLogSource` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addGithubLogSource, { data, loading, error }] = useAddGithubLogSource({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddGithubLogSource(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    AddGithubLogSource,
    AddGithubLogSourceVariables
  >
) {
  return ApolloReactHooks.useMutation<AddGithubLogSource, AddGithubLogSourceVariables>(
    AddGithubLogSourceDocument,
    baseOptions
  );
}
export type AddGithubLogSourceHookResult = ReturnType<typeof useAddGithubLogSource>;
export type AddGithubLogSourceMutationResult = ApolloReactCommon.MutationResult<AddGithubLogSource>;
export type AddGithubLogSourceMutationOptions = ApolloReactCommon.BaseMutationOptions<
  AddGithubLogSource,
  AddGithubLogSourceVariables
>;
export function mockAddGithubLogSource({
  data,
  variables,
  errors,
}: {
  data: AddGithubLogSource;
  variables?: AddGithubLogSourceVariables;
  errors?: GraphQLError[];
}) {
  return {
    request: { query: AddGithubLogSourceDocument, variables },
    result: { data, errors },
  };
}
