/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Box, Flex } from 'pouncejs';
import { FastField, useFormikContext, Field } from 'formik';
import asanaLogo from 'Assets/logProviders/asana.svg';
import { WizardPanel } from 'Components/Wizard';
import FormikTextInput from 'Components/fields/TextInput';
import FormikMultiCombobox from 'Components/fields/MultiComboBox';
import { AsanaLogsSourceWizardValues } from 'Pages/Integrations/LogSources/Asana/AsanaWizard/AsanaWizard';
import useAvailableLogTypesForLogPuller from 'Hooks/useAvailableLogTypesForLogPuller';
import EditIntegrationActions from 'Pages/Integrations/components/EditIntegrationActions';

const ConfigurationPanel = () => {
  const { initialValues, values, errors } = useFormikContext<AsanaLogsSourceWizardValues>();
  const { availableLogTypes } = useAvailableLogTypesForLogPuller();
  const editMode = !!initialValues.integrationId;
  const shouldDisableNextStep = Boolean(
    !values.integrationLabel || errors.integrationLabel || errors.logTypes
  );

  return (
    <WizardPanel>
      <Box width={500} m="auto">
        <WizardPanel.Heading
          title={editMode ? 'Update your Asana source' : "Let's start with the basics"}
          subtitle="Give an appropriate name for this source"
          logo={asanaLogo}
        />
        <Flex direction="column" spacing={4}>
          <FastField
            name="integrationLabel"
            as={FormikTextInput}
            label="Name *"
            placeholder="A nickname for your Asana Log source"
            required
          />
          <Field
            as={FormikMultiCombobox}
            searchable
            placeholder="The Asana Log Types you want Panther to process"
            label="Select the Log types you want to monitor *"
            name="logTypes"
            items={availableLogTypes}
            required
          />
        </Flex>
      </Box>
      <WizardPanel.Actions>
        {editMode ? (
          // TODO: ASANA check disabled
          <EditIntegrationActions disabled={shouldDisableNextStep} />
        ) : (
          <WizardPanel.ActionNext disabled={shouldDisableNextStep}>
            Continue Setup
          </WizardPanel.ActionNext>
        )}
      </WizardPanel.Actions>
    </WizardPanel>
  );
};

export default ConfigurationPanel;
