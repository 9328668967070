/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import ContentLoader from 'react-content-loader';
import { useTheme } from 'pouncejs';

interface TablePlaceholderProps {
  /** The number of rows that the placeholder component should render. Defaults to 5 */
  rowCount?: number;

  /** The height of each row. Defaults to 10px */
  rowHeight?: number;

  /** The vertical gap between each row. Defaults to 5px */
  rowGap?: number;

  /** Additional styles to be applied to the SVG element */
  style?: React.CSSProperties;
}

const TablePlaceholder: React.FC<TablePlaceholderProps> = ({
  rowCount = 5,
  rowHeight = 10,
  rowGap = 5,
  ...rest
}) => {
  const theme = useTheme();
  return (
    <ContentLoader
      height={rowCount * (rowHeight + rowGap)}
      primaryColor={theme.colors['navyblue-500']}
      secondaryColor={theme.colors['navyblue-600']}
      {...rest}
    >
      {[...Array(rowCount)].map((__, index) => (
        <rect
          key={index}
          x="0"
          y={index * (rowHeight + rowGap)}
          rx="1"
          ry="1"
          width="100%"
          height={rowHeight}
        />
      ))}
    </ContentLoader>
  );
};

export default TablePlaceholder;
