/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import * as Types from '../../../__generated__/schema';

import { GraphQLError } from 'graphql';
import gql from 'graphql-tag';

export type AnalysisPackDetails = Pick<
  Types.AnalysisPack,
  'id' | 'displayName' | 'description' | 'enabled' | 'updateAvailable'
> & {
  packVersion: Pick<Types.AnalysisPackVersion, 'id' | 'semVer'>;
  availableVersions: Array<Pick<Types.AnalysisPackVersion, 'id' | 'semVer'>>;
  packTypes: Pick<
    Types.AnalysisPackTypes,
    'GLOBAL' | 'POLICY' | 'RULE' | 'DATAMODEL' | 'SCHEDULED_QUERY' | 'SCHEDULED_RULE'
  >;
  packDefinition: Pick<Types.AnalysisPackDefinition, 'IDs'>;
};

export const AnalysisPackDetails = gql`
  fragment AnalysisPackDetails on AnalysisPack {
    id
    displayName
    description
    enabled
    updateAvailable
    packVersion {
      id
      semVer
    }
    availableVersions {
      id
      semVer
    }
    packTypes {
      GLOBAL
      POLICY
      RULE
      DATAMODEL
      SCHEDULED_QUERY
      SCHEDULED_RULE
    }
    packDefinition {
      IDs
    }
  }
`;
