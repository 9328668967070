/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Box, Flex, Card, Text, Icon, Heading } from 'pouncejs';
import { isResultRulesMatch } from 'Hooks/useIndicatorSearchDbPartitionedResults';
import { IndicatorSearchResult } from 'Hooks/useIndicatorSearchFlattenedResults';
import BulletedValue from 'Components/BulletedValue';

interface IndicatorSearchResultCardProps {
  data: Pick<IndicatorSearchResult, 'tableName' | 'logType' | 'matches' | 'dbName'>;
  variant?: 'dark' | 'light';
}

const IndicatorSearchResultCard: React.FC<IndicatorSearchResultCardProps> = ({
  data,
  data: { tableName, logType, matches },
  variant = 'light',
}) => {
  const isRuleMatch = isResultRulesMatch(data);

  return (
    <Card
      height={92}
      variant={variant}
      borderLeft="5px solid"
      borderLeftColor={isRuleMatch ? 'teal-500' : 'purple-400'}
      _hover={{ transform: 'scale(1.01, 1.01)' }}
      transition="transform 0.3s ease-in-out"
    >
      <Flex justify="space-between" height="100%" align="center" m="auto" px={4}>
        <Box>
          {/**
           * Falling back to `logType` is temporary while indicator search results
           * in query history may not return the `tableName`. Once everything in query
           * history returns the table name (30 days after this deploys), we can safely
           * remove the fallback to `dbName`.
           */}
          <BulletedValue value={tableName || logType || ''} />
          <Flex pt={1} color="white" fontSize="medium">
            in
            <Text pl="1" fontWeight="bold">
              {isRuleMatch ? 'Rule Matches' : 'Logs'}
            </Text>
          </Flex>
        </Box>
        <Box>
          <Flex spacing={2} align="center">
            <Box>
              <Heading as="h2" size="2x-large" fontWeight="bold" color="blue-300" lineHeight="none">
                {Number(matches).toLocaleString()}
              </Heading>
              <Text textAlign="end" color="white" fontSize="medium">
                {matches === 1 ? 'hit' : 'hits'}
              </Text>
            </Box>
            <Icon type="external-link" color="white" size="medium" ml={2} />
          </Flex>
        </Box>
      </Flex>
    </Card>
  );
};

export default React.memo(IndicatorSearchResultCard);
