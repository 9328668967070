/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import * as Types from '../../../../__generated__/schema';

import { AlertDetailsFull } from '../../../graphql/fragments/AlertDetailsFull.generated';
import { GraphQLError } from 'graphql';
import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';

export type AlertDetailsVariables = {
  id: Types.Scalars['ID'];
};

export type AlertDetails = {
  alert: {
    origin:
      | Pick<
          Types.AlertOriginRule,
          'ruleId' | 'logTypes' | 'scheduledQueries' | 'eventsMatched' | 'dedupString'
        >
      | Pick<
          Types.AlertOriginPolicy,
          'policyId' | 'resourceTypes' | 'resourceId' | 'policySourceId' | 'dedupString'
        >
      | (Pick<Types.AlertOriginSystemError, 'type' | 'relatedComponent'> & {
          relatedEntity?: Types.Maybe<
            | (Pick<Types.S3LogIntegration, 'integrationId'> & {
                alarms: {
                  eventThreshold?: Types.Maybe<Pick<Types.EventThresholdAlarm, 'minutesThreshold'>>;
                };
              })
            | (Pick<Types.EventBridgeIntegration, 'integrationId'> & {
                alarms: {
                  eventThreshold?: Types.Maybe<Pick<Types.EventThresholdAlarm, 'minutesThreshold'>>;
                };
              })
            | (Pick<Types.LogPullingIntegration, 'integrationId'> & {
                alarms: {
                  eventThreshold?: Types.Maybe<Pick<Types.EventThresholdAlarm, 'minutesThreshold'>>;
                };
              })
            | (Pick<Types.SqsLogSourceIntegration, 'integrationId'> & {
                alarms: {
                  eventThreshold?: Types.Maybe<Pick<Types.EventThresholdAlarm, 'minutesThreshold'>>;
                };
              })
            | (Pick<Types.CloudWatchLogIntegration, 'integrationId'> & {
                alarms: {
                  eventThreshold?: Types.Maybe<Pick<Types.EventThresholdAlarm, 'minutesThreshold'>>;
                };
              })
            | (Pick<Types.GcsLogSourceIntegration, 'integrationId'> & {
                alarms: {
                  eventThreshold?: Types.Maybe<Pick<Types.EventThresholdAlarm, 'minutesThreshold'>>;
                };
              })
            | Pick<Types.Destination, 'outputId' | 'displayName'>
          >;
        })
      | Pick<
          Types.AlertOriginReplayedRule,
          'ruleId' | 'logTypes' | 'replayId' | 'dedupString' | 'eventsMatched' | 'scheduledQueries'
        >;
  } & AlertDetailsFull;
};

export const AlertDetailsDocument = gql`
  query AlertDetails($id: ID!) {
    alert(id: $id) {
      ...AlertDetailsFull
      origin {
        ... on AlertOriginPolicy {
          policyId
          resourceTypes
          resourceId
          policySourceId
          dedupString
        }
        ... on AlertOriginRule {
          ruleId
          logTypes
          scheduledQueries
          eventsMatched
          dedupString
        }
        ... on AlertOriginReplayedRule {
          ruleId
          logTypes
          replayId
          dedupString
          eventsMatched
          scheduledQueries
        }
        ... on AlertOriginSystemError {
          type
          relatedComponent
          relatedEntity {
            ... on S3LogIntegration {
              integrationId
              alarms {
                eventThreshold {
                  minutesThreshold
                }
              }
            }
            ... on EventBridgeIntegration {
              integrationId
              alarms {
                eventThreshold {
                  minutesThreshold
                }
              }
            }
            ... on LogPullingIntegration {
              integrationId
              alarms {
                eventThreshold {
                  minutesThreshold
                }
              }
            }
            ... on SqsLogSourceIntegration {
              integrationId
              alarms {
                eventThreshold {
                  minutesThreshold
                }
              }
            }
            ... on CloudWatchLogIntegration {
              integrationId
              alarms {
                eventThreshold {
                  minutesThreshold
                }
              }
            }
            ... on GcsLogSourceIntegration {
              integrationId
              alarms {
                eventThreshold {
                  minutesThreshold
                }
              }
            }
            ... on Destination {
              outputId
              displayName
            }
          }
        }
      }
    }
  }
  ${AlertDetailsFull}
`;

/**
 * __useAlertDetails__
 *
 * To run a query within a React component, call `useAlertDetails` and pass it any options that fit your needs.
 * When your component renders, `useAlertDetails` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAlertDetails({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAlertDetails(
  baseOptions?: ApolloReactHooks.QueryHookOptions<AlertDetails, AlertDetailsVariables>
) {
  return ApolloReactHooks.useQuery<AlertDetails, AlertDetailsVariables>(
    AlertDetailsDocument,
    baseOptions
  );
}
export function useAlertDetailsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AlertDetails, AlertDetailsVariables>
) {
  return ApolloReactHooks.useLazyQuery<AlertDetails, AlertDetailsVariables>(
    AlertDetailsDocument,
    baseOptions
  );
}
export type AlertDetailsHookResult = ReturnType<typeof useAlertDetails>;
export type AlertDetailsLazyQueryHookResult = ReturnType<typeof useAlertDetailsLazyQuery>;
export type AlertDetailsQueryResult = ApolloReactCommon.QueryResult<
  AlertDetails,
  AlertDetailsVariables
>;
export function mockAlertDetails({
  data,
  variables,
  errors,
}: {
  data: AlertDetails;
  variables?: AlertDetailsVariables;
  errors?: GraphQLError[];
}) {
  return {
    request: { query: AlertDetailsDocument, variables },
    result: { data, errors },
  };
}
