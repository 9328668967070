/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Box, Icon, Flex, Table } from 'pouncejs';
import NoResultsFound from 'Components/NoResultsFound';
import { CollapsableCell } from 'Components/forms/DataSchemaForm/SampleDataSection/common';
import { DEFAULT_SMALL_PAGE_SIZE } from 'Source/constants';
import TablePlaceholder from 'Components/TablePlaceholder';
import { HoldingTankResults } from 'Generated/schema';
import { transformEventToJson } from 'Pages/Integrations/LogSources/S3/SchemasManagement/TestReport/LogTypeTestResults';
import useInfiniteScroll from 'Hooks/useInfiniteScroll';
import { useGetTestSchemasResults } from './graphql/getTestSchemasResults.generated';
import { useSchemasManagementContext } from '../SchemasManagementContext';

const CLASSIFICATION_FAILURES_LOG_TYPE = 'Classification.Failures';

function sanitizeUnmatchedLog(log: HoldingTankResults) {
  const json = transformEventToJson(log);
  const { payload, errors: rawErrors } = json.event;
  if (json.error) {
    return { event: payload, error: json.error };
  }
  return { event: payload, error: JSON.stringify(rawErrors) };
}

const PrefixUnmatchedResults: React.FC<{ prefix: string }> = ({ prefix }) => {
  const { schemaTestJob } = useSchemasManagementContext();
  const { loading, previousData, data = previousData, fetchMore } = useGetTestSchemasResults({
    variables: {
      input: {
        taskId: schemaTestJob.id,
        prefix,
        logType: CLASSIFICATION_FAILURES_LOG_TYPE,
        pageSize: DEFAULT_SMALL_PAGE_SIZE,
      },
    },
  });
  const unmatchedLogs = data?.holdingTankTestSchemasResult.results;
  const hasMoreResults = data?.holdingTankTestSchemasResult.pageInfo.hasNextPage;
  const paginationToken = data?.holdingTankTestSchemasResult.pageInfo.paginationToken;

  const { sentinelRef } = useInfiniteScroll<HTMLDivElement>({
    loading,
    onLoadMore: () =>
      fetchMore({
        variables: {
          input: {
            taskId: schemaTestJob.id,
            prefix,
            logType: CLASSIFICATION_FAILURES_LOG_TYPE,
            pageSize: DEFAULT_SMALL_PAGE_SIZE,
            paginationToken,
          },
        },
      }),
  });

  if (loading) {
    return (
      <Box px={4}>
        <TablePlaceholder rowCount={6} rowHeight={20} />
      </Box>
    );
  }

  if (!unmatchedLogs?.length) {
    return (
      <Box px={4}>
        <NoResultsFound title="No Unmatched Logs found" />
      </Box>
    );
  }

  const transformedUnmatchedLogs = unmatchedLogs.map(sanitizeUnmatchedLog);
  return (
    <Box
      overflow="auto"
      maxHeight="max(calc(100vh - 950px),400px)"
      willChange="scroll"
      backgroundColor="navyblue-400"
      mx={6}
      mb={4}
    >
      <Table size="small" stickyHeader data-testid="sample-data-table">
        <Table.Head>
          <Table.Row>
            <Box as={Table.HeaderCell} backgroundColor="navyblue-300" />
            <Box as={Table.HeaderCell} width="50%" backgroundColor="navyblue-300">
              Error
            </Box>
            <Box as={Table.HeaderCell} width="50%" backgroundColor="navyblue-300">
              Raw
            </Box>
          </Table.Row>
        </Table.Head>
        <Table.Body>
          {transformedUnmatchedLogs.map((log, i) => {
            return (
              <Table.Row key={`unmatched-log-${i}`}>
                <Table.Cell>
                  <Flex justify="center">
                    <Icon
                      type={'close-circle'}
                      color={'red-300'}
                      size="medium"
                      mr={2}
                      aria-label={'Log failed to match'}
                    />
                  </Flex>
                </Table.Cell>
                <Box as={Table.Cell} fontFamily="mono">
                  <CollapsableCell
                    text={log.error}
                    hideText="Hide Error"
                    showText="Show Error"
                    sliceOn={200}
                  />
                </Box>
                <Box as={Table.Cell} fontFamily="mono">
                  <CollapsableCell
                    text={log.event}
                    hideText="Hide Log"
                    showText="Show Log"
                    sliceOn={280}
                  />
                </Box>
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
      {hasMoreResults && (
        <Box mt={8} ref={sentinelRef}>
          <TablePlaceholder rowCount={10} rowHeight={6} />
        </Box>
      )}
    </Box>
  );
};

export default PrefixUnmatchedResults;
