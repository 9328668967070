/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { LogIntegration } from 'Generated/schema';
import EventThresholdAlarmForm, {
  EventThresholdAlarmFormValues,
} from 'Components/forms/EventThresholdAlarmForm';
import { useSnackbar } from 'pouncejs';
import { extractErrorMessage } from 'Helpers/utils';
import { useSetEventThresholdAlarm } from './graphql/setEventThresholdAlarm.generated';
import { useDeleteEventThresholdAlarm } from './graphql/deleteEventThresholdAlarm.generated';

interface EventThresholdAlarmConfiguratorProps {
  logSource: Pick<LogIntegration, 'integrationId' | 'alarms'>;
  autoSave?: boolean;
  onSuccess?: () => void;
}

const EventThresholdAlarmConfigurator: React.FC<EventThresholdAlarmConfiguratorProps> = ({
  logSource,
  onSuccess = () => {},
  autoSave = true,
}) => {
  const { pushSnackbar } = useSnackbar();
  const [setEventThresholdAlarm] = useSetEventThresholdAlarm({
    optimisticResponse: variables => ({
      setLogIntegrationEventThresholdAlarm: {
        minutesThreshold: variables.input.minutesThreshold,
      },
    }),
    update: (cache, { data }) => {
      cache.modify({
        id: cache.identify(logSource),
        fields: {
          alarms: value => ({
            ...value,
            eventThreshold: data.setLogIntegrationEventThresholdAlarm,
          }),
        },
      });
    },
    onError: error =>
      pushSnackbar({
        variant: 'error',
        title: 'Failed to set an event threshold alarm',
        description: extractErrorMessage(error),
      }),
  });

  const [deleteEventThresholdAlarm] = useDeleteEventThresholdAlarm({
    optimisticResponse: {
      deleteLogIntegrationEventThresholdAlarm: true,
    },
    update: cache => {
      cache.modify({
        id: cache.identify(logSource),
        fields: {
          alarms: value => ({ ...value, eventThreshold: null }),
        },
      });
    },
    onError: error => {
      pushSnackbar({
        variant: 'error',
        title: 'Failed to configure alarm',
        description: extractErrorMessage(error),
      });
    },
  });

  const onSubmit = React.useCallback(
    async ({ enabled, minutesThreshold }: EventThresholdAlarmFormValues) => {
      if (enabled) {
        setEventThresholdAlarm({
          variables: {
            input: {
              minutesThreshold,
              integrationId: logSource.integrationId,
            },
          },
        });
      } else {
        deleteEventThresholdAlarm({
          variables: { integrationId: logSource.integrationId },
        });
      }
      onSuccess();
    },
    // FIXME: look into hook dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [logSource.integrationId, onSuccess]
  );

  const initialValues = React.useMemo<EventThresholdAlarmFormValues>(
    () => ({
      enabled: Boolean(logSource.alarms?.eventThreshold?.minutesThreshold),
      minutesThreshold: logSource.alarms?.eventThreshold?.minutesThreshold
        ? logSource.alarms.eventThreshold.minutesThreshold
        : 24 * 60,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [logSource?.integrationId]
  );

  return (
    <EventThresholdAlarmForm
      initialValues={initialValues}
      onSubmit={onSubmit}
      autoSave={autoSave}
    />
  );
};

export default EventThresholdAlarmConfigurator;
