/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Flex, Box, Heading } from 'pouncejs';
import MetricsBar from 'Components/MetricsBar';
import { MetricsBarProps } from 'Components/MetricsBar/MetricsBar';
import { Link as RRLink } from 'react-router-dom';
import BulletedValue from 'Components/BulletedValue';

type BarProps = Pick<
  MetricsBarProps,
  'justifyValue' | 'gap' | 'height' | 'showLabel' | 'formatValue' | 'color'
>;

export interface Data {
  /** The title of each bar */
  label: string;

  /** The value displayed next to the bar */
  value: number;

  /** Optional router path for a link associated with value */
  legendLinkTo?: string;

  /** Callback triggered when clicking a bar */
  onClick?: () => void;
  config: BarProps;
}

interface BarChartProps {
  data: Data[];

  /** Whether the bars in the chart are separated by dividers  */
  withDividers?: boolean;

  /** The title of the legend */
  title?: string;

  /** Whether the chart should contain a legend */
  showLegend?: boolean;

  /** Custom max value for bar chart */
  referenceValue?: number;

  /** Add a data-testid to the chart */
  testId?: string;
}

const BarChart: React.FC<BarChartProps> = ({
  data,
  withDividers,
  title,
  showLegend,
  referenceValue,
  testId,
}) => {
  const maxValue = React.useMemo(() => {
    if (referenceValue) {
      return referenceValue;
    }

    return Math.max(...data.map(d => d.value));
  }, [data, referenceValue]);

  return (
    <Box>
      {title && !showLegend && (
        <Heading as="h4" my={2} fontSize="large" fontWeight="bold">
          {title}
        </Heading>
      )}
      <Flex>
        {showLegend && (
          <Box width="25%">
            {title && (
              <Heading as="h4" my={2} fontSize="large" fontWeight="bold">
                {title}
              </Heading>
            )}
            <Box as="ol">
              {data.map(d => (
                <Flex as="li" my={1} key={d.label}>
                  {d.legendLinkTo ? (
                    <RRLink to={d.legendLinkTo}>
                      <BulletedValue size="small" value={d.label} color={d.config.color} />
                    </RRLink>
                  ) : (
                    <BulletedValue size="small" value={d.label} color={d.config.color} />
                  )}
                </Flex>
              ))}
            </Box>
          </Box>
        )}
        <Box
          flexGrow={1}
          as="ol"
          {...(testId && { 'data-testid': testId })}
          {...(withDividers && { borderTop: '1px solid', borderColor: 'navyblue-300' })}
        >
          {data.map(d => {
            const { config, ...rest } = d;
            return (
              <Box
                as="li"
                key={d.label}
                {...(withDividers && { borderBottom: '1px solid', borderColor: 'navyblue-300' })}
              >
                <MetricsBar {...config} {...rest} percentage={d.value / maxValue} />
              </Box>
            );
          })}
        </Box>
      </Flex>
    </Box>
  );
};

export default React.memo(BarChart);
