/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

/**
 * Types of SQL snippets supported by the Data Explorer for auto-generation
 * via URL parameters.
 */
export const SNIPPET_TYPES = [
  'alertId',
  'alertIdError',
  'ruleId',
  'sourceLogtype',
  'indicatorDetail',
  'lookupTable',
  'previewTable',
] as const;

/**
 * All of the URL parameters that are used within the Data Explorer.
 */
export interface DataExplorerUrlParams {
  alertId?: string;
  snippedId?: typeof SNIPPET_TYPES[number];
  ruleId?: string;
  lookupId?: string;
  savedQueryId?: string;
  sourceId?: string;
  tableName?: string;
  /** Controls which database is initially selected in the panel */
  selectedDatabase?: string;
  /** If present, automatically executes the generated SQL query */
  execute?: string;
}
