/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Box, Grid, Text } from 'pouncejs';
import { useField } from 'formik';
import IntervalPicker from 'Components/IntervalPicker';

const MinutesThresholdPicker: React.FC = () => {
  const [, { value }, { setValue }] = useField('minutesThreshold');

  return (
    <Box mt={6}>
      <Grid templateColumns="5fr 3fr" gap={2} data-testid="interval-picker">
        <Text fontSize="medium">
          How long should Panther wait before it sends you an alert that no events have been
          processed?
        </Text>
        <Grid templateColumns="2fr 3fr" gap={2}>
          <IntervalPicker value={value} setValue={setValue} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default MinutesThresholdPicker;
