/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import * as Types from '../../../__generated__/schema';

import { GraphQLError } from 'graphql';
import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';

export type CancelAsyncQueryVariables = {
  input: Types.CancelDataLakeQueryInput;
};

export type CancelAsyncQuery = { cancelDataLakeQuery: Pick<Types.CancelDataLakeQueryOutput, 'id'> };

export const CancelAsyncQueryDocument = gql`
  mutation CancelAsyncQuery($input: CancelDataLakeQueryInput!) {
    cancelDataLakeQuery(input: $input) {
      id
    }
  }
`;
export type CancelAsyncQueryMutationFn = ApolloReactCommon.MutationFunction<
  CancelAsyncQuery,
  CancelAsyncQueryVariables
>;

/**
 * __useCancelAsyncQuery__
 *
 * To run a mutation, you first call `useCancelAsyncQuery` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelAsyncQuery` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelAsyncQuery, { data, loading, error }] = useCancelAsyncQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCancelAsyncQuery(
  baseOptions?: ApolloReactHooks.MutationHookOptions<CancelAsyncQuery, CancelAsyncQueryVariables>
) {
  return ApolloReactHooks.useMutation<CancelAsyncQuery, CancelAsyncQueryVariables>(
    CancelAsyncQueryDocument,
    baseOptions
  );
}
export type CancelAsyncQueryHookResult = ReturnType<typeof useCancelAsyncQuery>;
export type CancelAsyncQueryMutationResult = ApolloReactCommon.MutationResult<CancelAsyncQuery>;
export type CancelAsyncQueryMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CancelAsyncQuery,
  CancelAsyncQueryVariables
>;
export function mockCancelAsyncQuery({
  data,
  variables,
  errors,
}: {
  data: CancelAsyncQuery;
  variables?: CancelAsyncQueryVariables;
  errors?: GraphQLError[];
}) {
  return {
    request: { query: CancelAsyncQueryDocument, variables },
    result: { data, errors },
  };
}
