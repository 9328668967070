/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { FetchResult } from '@apollo/client';
import ErrorBoundary from 'Components/ErrorBoundary';
import { Flex, Box, SimpleGrid, Text, FormHelperText, Grid } from 'pouncejs';
import FormikTextInput from 'Components/fields/TextInput';
import SensitiveTextInput from 'Components/fields/SensitiveTextInput/SensitiveTextInput';
import Panel from 'Components/Panel';
import Breadcrumbs from 'Components/Breadcrumbs';
import SaveButton from 'Components/buttons/SaveButton/SaveButton';
import LinkButton from 'Components/buttons/LinkButton';
import urls from 'Source/urls';
import FormikTextArea from 'Components/fields/TextArea';

interface DetectionPackSourceFormProps {
  initialValues: DetectionPackSourceFormValues;
  onSubmit: (values: DetectionPackSourceFormValues) => Promise<FetchResult<any>>;
}

export interface DetectionPackSourceFormValues {
  id: string;
  owner: string;
  repository: string;
  kmsKey: string;
  githubAccessToken: string;
}

const validationSchema: Yup.SchemaOf<DetectionPackSourceFormValues> = Yup.object().shape({
  id: Yup.string().required(),
  owner: Yup.string().required(),
  repository: Yup.string().required(),
  githubAccessToken: Yup.string().nullable(),
  kmsKey: Yup.string().nullable(),
});

const DetectionPackSourceForm: React.FC<DetectionPackSourceFormProps> = ({
  initialValues,
  onSubmit,
}) => {
  return (
    <Panel title={initialValues.id ? 'Edit Pack Source' : 'New Detection Pack Source'}>
      <Formik<DetectionPackSourceFormValues>
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        <Form>
          <ErrorBoundary>
            <Text as="p" color="navyblue-100" pb={4}>
              Required
            </Text>
            <SimpleGrid columns={3} spacing={4}>
              <Field
                name="id"
                as={FormikTextInput}
                label="Source ID"
                placeholder="A nickname for detection pack source"
                disabled={!!initialValues.id}
                required
              />
              <Field
                name="owner"
                as={FormikTextInput}
                label="Github Owner"
                placeholder="The owner of github repository"
                disabled={!!initialValues.id}
                required
              />
              <Field
                name="repository"
                as={FormikTextInput}
                label="Github Repo"
                placeholder="The name of the github repository"
                disabled={!!initialValues.id}
                required
              />
            </SimpleGrid>
            <Text as="p" color="navyblue-100" py={4}>
              Optional
            </Text>
            <Grid templateColumns="1fr 2fr" gap={2}>
              <Box width={0.7}>
                <FormHelperText id="some" fontSize="medium" color="white">
                  If this is a private Repo please fill in the Access Token needed
                </FormHelperText>
              </Box>
              <Field
                name="githubAccessToken"
                as={SensitiveTextInput}
                label="Access Token"
                placeholder="For private repos provide an access token"
              />
              <Box width={0.9}>
                <FormHelperText id="some" fontSize="medium" color="white">
                  If you want to use signed releases you can either fill in the public key or an ARN
                  to do a KMS verification
                </FormHelperText>
              </Box>
              <Field
                name="kmsKey"
                as={FormikTextArea}
                label="Public Key or ARN"
                placeholder="For signed releases add public key or a KMS ARN for verification"
              />
            </Grid>
          </ErrorBoundary>
          <Breadcrumbs.Actions>
            <Flex spacing={4} justify="flex-end">
              <LinkButton
                variantColor="gray-600"
                icon="close-outline"
                aria-label="Cancel pack source save"
                to={urls.analysis.packs.packSources.list()}
              >
                Cancel
              </LinkButton>
              <SaveButton aria-label="Save Pack Source">Save</SaveButton>
            </Flex>
          </Breadcrumbs.Actions>
        </Form>
      </Formik>
    </Panel>
  );
};

export default DetectionPackSourceForm;
