/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import withSEO from 'Hoc/withSEO';
import Page403 from 'Pages/403/403';
import useUrlParams from 'Hooks/useUrlParams';
import useAvailableLogTypesForProvider from 'Hooks/useAvailableLogTypesForProvider';
import { compose } from 'Helpers/compose';
import withRoleRestrictedAccess from 'Hoc/withRoleRestrictedAccess';
import { Permission } from 'Generated/schema';
import { useAddSqsLogSource } from './graphql/addSqsLogSource.generated';
import SqsSourceWizard, { SqsLogSourceWizardValues } from '../SqsSourceWizard';

const CreateSqsLogSource: React.FC = () => {
  const { urlParams: { logProviderId } } = useUrlParams<{ logProviderId?: string }>(); // prettier-ignore
  const { availableLogTypes, defaultLogTypes } = useAvailableLogTypesForProvider(logProviderId);

  const initialValues = React.useMemo(
    () => ({
      integrationLabel: '',
      logTypes: defaultLogTypes ?? [],
      allowedPrincipalArns: [],
      allowedSourceArns: [],
    }),
    [defaultLogTypes]
  );

  const [addSqsLogSource] = useAddSqsLogSource({
    update: (cache, { data }) => {
      cache.modify({
        fields: {
          logSources: (queryData, { toReference }) => {
            const addedIntegrationCacheRef = toReference(data.addSqsLogIntegration);
            return queryData
              ? [addedIntegrationCacheRef, ...queryData]
              : [addedIntegrationCacheRef];
          },
        },
      });
    },
  });

  const handleSubmit = React.useCallback(
    async (values: SqsLogSourceWizardValues) => {
      try {
        const { data } = await addSqsLogSource({
          variables: {
            input: {
              logProviderId: logProviderId ?? null,
              integrationLabel: values.integrationLabel,
              sqsConfig: {
                logTypes: values.logTypes,
                allowedPrincipalArns: values.allowedPrincipalArns,
                allowedSourceArns: values.allowedSourceArns,
              },
            },
          },
        });
        return data.addSqsLogIntegration;
      } catch (err) {
        return err as Error;
      }
    },
    // FIXME: look into missing hook dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [logProviderId]
  );

  return (
    <SqsSourceWizard
      initialValues={initialValues}
      availableLogTypes={availableLogTypes}
      onSubmit={handleSubmit}
    />
  );
};

export default compose(
  withSEO({ title: 'New SQS Source' }),
  withRoleRestrictedAccess({
    allowedPermissions: [Permission.LogSourceModify],
    fallback: <Page403 />,
  })
)(CreateSqsLogSource);
