/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Box, Flex, Spinner, Text, Link, Heading } from 'pouncejs';

const ReactMarkdown = React.lazy(() =>
  import(/* webpackChunkName: "react-markdown" */ 'react-markdown')
);

/* eslint-disable react/display-name */
const renderers = {
  paragraph: props => <Text mb={6} {...props} />,
  heading: props => <Heading size="x-small" color="gray-100" mb={6} {...props} />,
  link: props => <Link external {...props} />,
  list: props => (
    <Flex as="ul" ml={6} direction="column" spacing={2} sx={{ listStyle: 'unset' }} {...props} />
  ),
  listItem: props => <Box as="li" {...props} />,
};
/* eslint-enable react/display-name */

const MarkdownParser: React.FC = ({ children }) => {
  return (
    <React.Suspense
      fallback={
        <Flex w="100%" justify="center" my={10}>
          <Spinner />
        </Flex>
      }
    >
      <Box fontSize="medium" color="gray-300">
        <ReactMarkdown renderers={renderers}>{children as string}</ReactMarkdown>
      </Box>
    </React.Suspense>
  );
};

export default MarkdownParser;
