/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { SAAS_ONBOARDING_OKTA_DOC_URL } from 'Source/constants';
import { Box, Flex, FormHelperText, Link } from 'pouncejs';
import ErrorBoundary from 'Components/ErrorBoundary';
import { Field, useFormikContext } from 'formik';
import oktaMinimalLogo from 'Assets/okta-minimal-logo.svg';
import FormikTextInput from 'Components/fields/TextInput';
import FormikCombobox from 'Components/fields/ComboBox';
import FormikMultiCombobox from 'Components/fields/MultiComboBox';
import SensitiveTextInput from 'Components/fields/SensitiveTextInput';
import { WizardPanel } from 'Components/Wizard';
import useAvailableLogTypesForLogPuller from 'Hooks/useAvailableLogTypesForLogPuller';
import EditIntegrationActions from 'Pages/Integrations/components/EditIntegrationActions';
import { OktaDomainsEnum, OktaLogsSourceWizardValues } from '../OktaLogsSourceWizard';

const OktaLogsSourceConfigurationPanel: React.FC = () => {
  const { availableLogTypes } = useAvailableLogTypesForLogPuller();
  const { initialValues, dirty, isValid, values } = useFormikContext<OktaLogsSourceWizardValues>();
  const editMode = !!initialValues.integrationId;
  return (
    <WizardPanel>
      <Box width={500} m="auto">
        <WizardPanel.Heading
          title={editMode ? 'Update Okta Logs source' : 'Configure your Okta source'}
          subtitle={
            editMode
              ? 'Feel free to make any changes to your Okta log source'
              : 'We need some info before we can pull your logs'
          }
          logo={oktaMinimalLogo}
        />
        <ErrorBoundary>
          <Flex direction="column" spacing={4}>
            <Field
              name="integrationLabel"
              as={FormikTextInput}
              label="Name"
              placeholder="A nickname for your Okta log source"
              required
            />
            <Flex align="flex-start" spacing={4}>
              <Box
                as="span"
                position="absolute"
                transition="transform 0.1s ease-in-out"
                transform={!values.subdomain ? 'translate(-65px, 15px)' : 'translate(-65px, 21px)'}
                fontSize="medium"
                fontWeight="medium"
              >
                https://
              </Box>
              <Box flexGrow={1}>
                <Field
                  label="Okta subdomain"
                  name="subdomain"
                  as={FormikTextInput}
                  placeholder="Okta subdomain"
                  required
                />
              </Box>
              <Box flexGrow={1}>
                <Field
                  label="Okta domain"
                  name="domain"
                  as={FormikCombobox}
                  items={Object.values(OktaDomainsEnum)}
                  showClearSelectionControl={false}
                  required
                />
              </Box>
            </Flex>
            <Field
              name="apiToken"
              as={SensitiveTextInput}
              shouldMask={editMode}
              label="API Token"
              type="password"
              placeholder={
                editMode
                  ? 'Information is hidden. New values will override the existing.'
                  : 'Your Okta API token'
              }
              aria-describedby="apiToken-helper"
              required={!editMode}
            />
            <Field
              as={FormikMultiCombobox}
              searchable
              placeholder="The Okta Log Types you want Panther to process"
              label="Select the Log types you want to monitor *"
              name="logTypes"
              items={availableLogTypes}
              required
              disabled={availableLogTypes.length <= 1}
            />
            <FormHelperText id="apiToken-helper" textAlign="center" mt={2}>
              Need help generating an Okta token? Check
              <Link external href={SAAS_ONBOARDING_OKTA_DOC_URL} ml={1}>
                our documentation
              </Link>
            </FormHelperText>
          </Flex>
        </ErrorBoundary>
      </Box>
      {editMode ? (
        <EditIntegrationActions />
      ) : (
        <WizardPanel.Actions>
          <WizardPanel.ActionNext disabled={!dirty || !isValid}>
            Continue Setup
          </WizardPanel.ActionNext>
        </WizardPanel.Actions>
      )}
    </WizardPanel>
  );
};

export default OktaLogsSourceConfigurationPanel;
