/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import dayjs from 'dayjs';
import debounce from 'lodash/debounce';
import { Box, Flex, Heading, Icon, SimpleGrid, Text, Card, Divider } from 'pouncejs';

import { ExecuteIndicatorSearchQueryInput } from 'Generated/schema';
import useAsyncQueryResults from 'Hooks/useAsyncQueryResults';
import useAsyncQueryContext from 'Hooks/useAsyncQueryContext';
import useIndicatorSearchFlattenedResults, {
  IndicatorSearchResult,
} from 'Hooks/useIndicatorSearchFlattenedResults';
import useIndicatorSearchDbPartitionedResults from 'Hooks/useIndicatorSearchDbPartitionedResults';
import AsyncQueryStatus from 'Components/AsyncQueryStatus';
import TimeSeriesChart, { DateRange, TimeSeriesPointData } from 'Components/charts/TimeSeriesChart';
import ElapsedTime from 'Components/ElapsedTime';
import { IndicatorUrlParams } from 'Helpers/utils';
import useAsyncQueryTimer from 'Hooks/useAsyncQueryTimer';
import useUrlParams from 'Hooks/useUrlParams';
import LinkButton from 'Components/buttons/LinkButton';
import urls from 'Source/urls';
import IndicatorSearchResultCard from 'Components/IndicatorSearchResultCard';
import DataScanned from 'Components/DataScanned';
import useTimelineSeriesData from './useTimelineSeriesData';
import ChartTooltip from './ChartTooltip';
import useIndicatorSearchResultFiltering from './useIndicatorSearchResultsFiltering';
import { IndicatorInputFormValues } from '../IndicatorInputForm';

const CHART_CONTAINER_HEIGHT = 326;
export const PAGE_SIZE = 999;

interface ResultsProps {
  indicatorQueryMeta: ExecuteIndicatorSearchQueryInput;
}

const Results: React.FC<ResultsProps> = ({
  indicatorQueryMeta: { startTime, endTime, indicatorName, indicators },
}) => {
  const { timeElapsed: timer } = useAsyncQueryTimer();
  const [dateRangeFilter, setDateRangeFilter] = React.useState<DateRange>();
  const { results, stats } = useAsyncQueryResults({ pageSize: PAGE_SIZE }); // fetch all at once
  const flattenedResults = useIndicatorSearchFlattenedResults(results ?? []);
  const timelineSeriesData = useTimelineSeriesData(flattenedResults);
  const filteredResults = useIndicatorSearchResultFiltering(flattenedResults, dateRangeFilter);
  const [logMatches, ruleMatches] = useIndicatorSearchDbPartitionedResults(filteredResults);
  const { state: { queryId, queryStatus, globalErrorMessage } } = useAsyncQueryContext(); // prettier-ignore
  const { stringifyUrlParams } = useUrlParams<IndicatorUrlParams>({
    parseNumbers: false,
    parseBooleans: false,
  });
  const { stringifyUrlParams: stringifyIndicatorFormUrlParams } = useUrlParams<
    IndicatorInputFormValues
  >({ parseNumbers: false, parseBooleans: false });

  const getDataExplorerUrl = React.useCallback(
    ({ logType, dbName, tableName }) => {
      const params = stringifyUrlParams({
        snippedId: 'indicatorDetail',
        logType,
        i: indicators,
        indicatorName,
        startTime: dateRangeFilter
          ? dayjs.utc(dateRangeFilter.startDateTimestamp).format('YYYY-MM-DDTHH:mm:ss[Z]')
          : startTime,
        endTime: dateRangeFilter
          ? dayjs.utc(dateRangeFilter.endDateTimestamp).format('YYYY-MM-DDTHH:mm:ss[Z]')
          : endTime,
        databaseName: dbName,
        // The BE supports passing `logType` as `tableName` to stay backwards-compatible with
        //  indicator search results in query history that don't return the `tableName`.
        // Once everything in query history returns the table name (30 days after this
        // deploys), we can safely remove the fallback to `dbName`.
        // FIXME: remove after Feb 2021 when all the old data has aged out
        tableName: tableName || logType,
      });
      return `${urls.data.dataExplorer()}?${params}`;
    },
    [stringifyUrlParams, startTime, endTime, indicatorName, indicators, dateRangeFilter]
  );

  // Debounce the update of the filters for performance reasons, since TimeSeries chart
  // can emit hundreds of events while users interact with the zoom controls.
  // FIXME: Look into passing `debounce` in a way where React can understand the dependencies.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const updateDateRange = React.useCallback(
    debounce((dateRange: DateRange) => {
      setDateRangeFilter(dateRange);
    }, 100),
    []
  );

  const handleSeriesDrillDownClick = React.useCallback(
    (seriesData: TimeSeriesPointData) => {
      const metadata = seriesData.metadata as IndicatorSearchResult[];
      // To ensure backward compatibility we have to check if timestamp resolution minutes
      // are present, past queries might be missing this column.
      if (!(metadata[0].timestampResolutionMinutes > 1)) {
        return;
      }

      const timestampStartTime = dayjs.utc(seriesData.timestamp);
      const timestampEndTime = timestampStartTime.add(metadata[0].timestampResolutionMinutes, 'minute'); // prettier-ignore
      const minTime = dayjs.utc(startTime);
      const maxTime = dayjs.utc(endTime);
      // Restrict drill down period to make sure that first & last periods are clipped between start & end time
      const drillDownStartTime = timestampStartTime.isBefore(minTime) ? minTime : timestampStartTime; // prettier-ignore
      const drillDownEndTime = timestampEndTime.isAfter(maxTime) ? maxTime : timestampEndTime;

      const params = stringifyIndicatorFormUrlParams({
        indicators,
        indicatorName,
        startTime: drillDownStartTime.format('YYYY-MM-DDTHH:mm:ss[Z]'),
        // We should subtract 0.01 of a second, otherwise
        // we will pick up the next minutes data when we "bin" the query
        endTime: drillDownEndTime.subtract(0.01, 'second').format('YYYY-MM-DDTHH:mm:ss[Z]'),
      });

      window.open(`${urls.data.indicatorSearch()}?${params}`, '_blank');
    },
    [indicators, startTime, endTime, indicatorName, stringifyIndicatorFormUrlParams]
  );

  const isPristine = queryStatus === null;
  const hasErrored = queryStatus === 'errored';
  const isProvisioning = queryStatus === 'provisioning';
  const isRunning = queryStatus === 'running';
  const bytesScanned = stats?.bytesScanned ?? null;
  const timeElapsed = stats?.executionTime ?? timer;

  if (isPristine) {
    return (
      <Card variant="dark" data-testid="indicator-search-results-pristine">
        <AsyncQueryStatus.Pristine />
      </Card>
    );
  }

  if (hasErrored) {
    return (
      <Card variant="dark" data-testid="indicator-search-results-errored">
        <AsyncQueryStatus.Errored errorMessage={globalErrorMessage} />
      </Card>
    );
  }

  if (isProvisioning) {
    return (
      <Card variant="dark" data-testid="indicator-search-results-provisioning">
        <AsyncQueryStatus.Provisioning />
      </Card>
    );
  }

  if (isRunning) {
    return (
      <Card variant="dark" data-testid="indicator-search-results-running">
        <AsyncQueryStatus.Running timeElapsed={timeElapsed} />
      </Card>
    );
  }

  if (!results?.length) {
    return (
      <Card data-testid="indicator-search-results-empty">
        <AsyncQueryStatus.Empty timeElapsed={timeElapsed} bytesScanned={bytesScanned} />
      </Card>
    );
  }

  const totalLogMatches = logMatches.reduce((acc, item) => acc + item.matches, 0);
  const totalRuleMatches = ruleMatches.reduce((acc, item) => acc + item.matches, 0);

  return (
    <React.Fragment>
      <Card
        as="article"
        variant="dark"
        px={5}
        py={6}
        mb={8}
        data-testid="indicator-search-results-complete"
      >
        <Flex as="section" justify="center" mb={8} spacing={2}>
          <Card>
            <ElapsedTime ms={timeElapsed} />
          </Card>
          {bytesScanned >= 0 && (
            <Card>
              <DataScanned bytes={bytesScanned} />
            </Card>
          )}
        </Flex>
        <Box as="section" height={CHART_CONTAINER_HEIGHT}>
          <TimeSeriesChart
            title="Hits per Log Type"
            chartId="hits-per-log-type"
            units="hits"
            data={timelineSeriesData}
            chartType="bar"
            hideLegend
            scaleControls={false}
            hideSeriesLabels={false}
            tooltipComponent={ChartTooltip}
            zoomable
            maxZoomPeriod={1000 * 60}
            onZoomChange={updateDateRange}
            onSeriesClick={handleSeriesDrillDownClick}
            useUTC
          />
        </Box>
        <Divider my={8} color="navyblue-300" />
        {ruleMatches.length > 0 && (
          <Box as="section" mb={8}>
            <Flex align="center" mb={8}>
              <Flex
                borderRadius="circle"
                height={46}
                width={46}
                justify="center"
                align="center"
                backgroundColor="teal-500"
              >
                <Icon type="rule" />
              </Flex>
              <Box ml={3}>
                <Heading size="large" fontWeight="bold">
                  {totalRuleMatches.toLocaleString()}
                </Heading>
                <Text fontSize="medium">Total Hits in Rule Matches</Text>
              </Box>
            </Flex>
            <SimpleGrid columns={2} gap={5}>
              {ruleMatches.map((result, i) => (
                <a
                  key={i}
                  target="_blank"
                  rel="noopener noreferrer"
                  href={getDataExplorerUrl(result)}
                >
                  <IndicatorSearchResultCard data={result} />
                </a>
              ))}
            </SimpleGrid>
          </Box>
        )}
        {logMatches.length > 0 && (
          <Box as="section" mb={8}>
            <Flex align="center" mb={8}>
              <Flex
                borderRadius="circle"
                height={46}
                width={46}
                justify="center"
                align="center"
                backgroundColor="purple-400"
              >
                <Icon type="log-source" />
              </Flex>
              <Box ml={3}>
                <Heading size="large" fontWeight="bold">
                  {totalLogMatches.toLocaleString()}
                </Heading>
                <Text fontSize="medium">Total Hits in Logs</Text>
              </Box>
            </Flex>
            <SimpleGrid columns={2} gap={5}>
              {logMatches.map((result, i) => (
                <a
                  key={i}
                  target="_blank"
                  rel="noopener noreferrer"
                  href={getDataExplorerUrl(result)}
                  data-testid="indicator-search-result-log-match-link"
                >
                  <IndicatorSearchResultCard data={result} />
                </a>
              ))}
            </SimpleGrid>
          </Box>
        )}
      </Card>
      <Flex as="section" direction="column" align="center">
        <Text fontSize="medium" color="gray-300" mb={4}>
          You can explore this Query further with Data Explorer
        </Text>
        <LinkButton
          external
          icon="external-link"
          iconAlignment="right"
          to={`${urls.data.dataExplorer()}?queryId=${queryId}`}
        >
          Open in Data Explorer
        </LinkButton>
      </Flex>
    </React.Fragment>
  );
};

export default Results;
