/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { useWizardContext } from 'Components/Wizard';
import { useFormikContext } from 'formik';
import { FailingPanel, PendingPanel } from 'Components/SourceVerificationPanels';
import { ComplianceIntegrationDetails } from 'Source/graphql/fragments/ComplianceIntegrationDetails.generated';
import HealthCheckPanel from './HealthCheckPanel';
import { ComplianceSourceWizardValues } from '../ComplianceSourceWizard';

const ValidationPanel: React.FC = () => {
  const {
    currentStepStatus,
    setCurrentStepStatus,
    updateData,
    setNavigationAvailability,
  } = useWizardContext<{ integrationId?: string }>();
  const result = React.useRef<ComplianceIntegrationDetails>(null);

  const { submitForm } = useFormikContext<ComplianceSourceWizardValues>();
  const [errorMessage, setErrorMessage] = React.useState('');

  React.useEffect(() => {
    (async () => {
      setNavigationAvailability(false);
      result.current = await (submitForm() as Promise<any>);

      if (result.current instanceof Error) {
        setErrorMessage(result.current.message);
        setCurrentStepStatus('FAILING');
        setNavigationAvailability(true);
      } else {
        const { integrationId } = result.current as ComplianceIntegrationDetails;
        updateData({ integrationId });
        setErrorMessage('');
        setCurrentStepStatus('PASSING');
      }
    })();
  }, [setCurrentStepStatus, setNavigationAvailability, submitForm, updateData]);

  if (currentStepStatus === 'PENDING') {
    return <PendingPanel />;
  }

  if (currentStepStatus === 'FAILING') {
    return <FailingPanel errorMessage={errorMessage} />;
  }

  return <HealthCheckPanel />;
};

export default ValidationPanel;
