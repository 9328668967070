/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import * as Types from '../../../__generated__/schema';

import { GraphQLError } from 'graphql';
import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';

export type GetReplayPreviewVariables = {
  endsAt: Types.Scalars['DateTime'];
  startsAt: Types.Scalars['DateTime'];
  logTypes: Array<Types.Scalars['String']>;
};

export type GetReplayPreview = {
  replayPreview: Pick<Types.ReplayPreview, 'logDataSizeEstimate' | 'timeEstimate'>;
};

export const GetReplayPreviewDocument = gql`
  query GetReplayPreview($endsAt: DateTime!, $startsAt: DateTime!, $logTypes: [String!]!) {
    replayPreview(startsAt: $startsAt, endsAt: $endsAt, logTypes: $logTypes) {
      logDataSizeEstimate
      timeEstimate
    }
  }
`;

/**
 * __useGetReplayPreview__
 *
 * To run a query within a React component, call `useGetReplayPreview` and pass it any options that fit your needs.
 * When your component renders, `useGetReplayPreview` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReplayPreview({
 *   variables: {
 *      endsAt: // value for 'endsAt'
 *      startsAt: // value for 'startsAt'
 *      logTypes: // value for 'logTypes'
 *   },
 * });
 */
export function useGetReplayPreview(
  baseOptions?: ApolloReactHooks.QueryHookOptions<GetReplayPreview, GetReplayPreviewVariables>
) {
  return ApolloReactHooks.useQuery<GetReplayPreview, GetReplayPreviewVariables>(
    GetReplayPreviewDocument,
    baseOptions
  );
}
export function useGetReplayPreviewLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetReplayPreview, GetReplayPreviewVariables>
) {
  return ApolloReactHooks.useLazyQuery<GetReplayPreview, GetReplayPreviewVariables>(
    GetReplayPreviewDocument,
    baseOptions
  );
}
export type GetReplayPreviewHookResult = ReturnType<typeof useGetReplayPreview>;
export type GetReplayPreviewLazyQueryHookResult = ReturnType<typeof useGetReplayPreviewLazyQuery>;
export type GetReplayPreviewQueryResult = ApolloReactCommon.QueryResult<
  GetReplayPreview,
  GetReplayPreviewVariables
>;
export function mockGetReplayPreview({
  data,
  variables,
  errors,
}: {
  data: GetReplayPreview;
  variables?: GetReplayPreviewVariables;
  errors?: GraphQLError[];
}) {
  return {
    request: { query: GetReplayPreviewDocument, variables },
    result: { data, errors },
  };
}
