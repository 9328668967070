/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import * as Types from '../../../../../../../__generated__/schema';

import { EventbridgeLogIntegrationDetails } from '../../../../../../graphql/fragments/EventbridgeLogIntegrationDetails.generated';
import { GraphQLError } from 'graphql';
import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';

export type GetEventbridgeLogSourceVariables = {
  id: Types.Scalars['ID'];
};

export type GetEventbridgeLogSource = {
  getEventBridgeLogIntegration: EventbridgeLogIntegrationDetails;
};

export const GetEventbridgeLogSourceDocument = gql`
  query GetEventbridgeLogSource($id: ID!) {
    getEventBridgeLogIntegration(id: $id) {
      ...EventbridgeLogIntegrationDetails
    }
  }
  ${EventbridgeLogIntegrationDetails}
`;

/**
 * __useGetEventbridgeLogSource__
 *
 * To run a query within a React component, call `useGetEventbridgeLogSource` and pass it any options that fit your needs.
 * When your component renders, `useGetEventbridgeLogSource` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEventbridgeLogSource({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetEventbridgeLogSource(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetEventbridgeLogSource,
    GetEventbridgeLogSourceVariables
  >
) {
  return ApolloReactHooks.useQuery<GetEventbridgeLogSource, GetEventbridgeLogSourceVariables>(
    GetEventbridgeLogSourceDocument,
    baseOptions
  );
}
export function useGetEventbridgeLogSourceLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetEventbridgeLogSource,
    GetEventbridgeLogSourceVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<GetEventbridgeLogSource, GetEventbridgeLogSourceVariables>(
    GetEventbridgeLogSourceDocument,
    baseOptions
  );
}
export type GetEventbridgeLogSourceHookResult = ReturnType<typeof useGetEventbridgeLogSource>;
export type GetEventbridgeLogSourceLazyQueryHookResult = ReturnType<
  typeof useGetEventbridgeLogSourceLazyQuery
>;
export type GetEventbridgeLogSourceQueryResult = ApolloReactCommon.QueryResult<
  GetEventbridgeLogSource,
  GetEventbridgeLogSourceVariables
>;
export function mockGetEventbridgeLogSource({
  data,
  variables,
  errors,
}: {
  data: GetEventbridgeLogSource;
  variables?: GetEventbridgeLogSourceVariables;
  errors?: GraphQLError[];
}) {
  return {
    request: { query: GetEventbridgeLogSourceDocument, variables },
    result: { data, errors },
  };
}
