/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Flex, Button, Text, Icon } from 'pouncejs';
import { useDropzone } from 'react-dropzone';

const UploadKeyfile = ({ uploadedFile, onUploadFile }) => {
  const { getRootProps, getInputProps, open, acceptedFiles, isDragActive } = useDropzone({
    multiple: false,
    accept: '.json',
    // Disable click and keydown behavior
    noClick: true,
    noKeyboard: true,
  });
  const [fileError, setFileError] = React.useState<Error>(null);

  React.useEffect(() => {
    (async () => {
      setFileError(null);
      const [file] = acceptedFiles;
      if (!file) {
        return;
      }
      try {
        await onUploadFile(file);
      } catch (error) {
        setFileError(error);
      }
    })();
  }, [acceptedFiles, onUploadFile]);

  const styleProps = React.useMemo(
    () => ({
      justifyContent: 'center',
      alignItems: 'center',
      p: 2,
      mt: 6,
      minWidth: 600,
      transition: 'all 0.8s linear',
      ...(!acceptedFiles.length
        ? {
            borderWidth: '1px',
            borderStyle: 'dashed',
            borderRadius: 'medium',
            borderColor: isDragActive ? 'navyblue-100' : 'navyblue-300',
          }
        : { bg: 'navyblue-500' }),
    }),
    [isDragActive, acceptedFiles]
  );

  return (
    <>
      {/* @ts-ignore */}
      <Flex as="section" {...styleProps} {...getRootProps()}>
        <input data-testid="input-upload" {...getInputProps()} />
        {!uploadedFile ? (
          <>
            <Text mr={3}>Drag &amp; drop your file here, or </Text>{' '}
            <Button size="medium" onClick={open}>
              Select file
            </Button>
          </>
        ) : (
          <Flex
            justifyContent="space-between"
            alignItems="center"
            bg="navyblue-300"
            p={1}
            minWidth={250}
          >
            <Flex ml={2} mr={3} alignItems="center">
              <Icon mr={2} type="file" size="medium" />
              <Text>{uploadedFile.name}</Text>
            </Flex>
            <Button size="medium" variantColor="navyblue-200" onClick={open}>
              Change
            </Button>
          </Flex>
        )}
      </Flex>
      {fileError && (
        <Flex
          mt={2}
          align="center"
          spacing={2}
          aria-label="upload failure"
          fontStyle="italic"
          color="red-100"
          fontSize="small"
        >
          <Icon type="alert-circle-filled" size="medium" />
          <Text>{`${fileError?.name}: ${fileError?.message}`}</Text>
        </Flex>
      )}
    </>
  );
};

export default UploadKeyfile;
