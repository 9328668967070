/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Box, Flex, Text, Button, Img } from 'pouncejs';
import animatedGears from 'Assets/gears-animated.svg';

interface ProcessingProps {
  onAbort: () => void;
}

const Processing: React.FC<ProcessingProps> = ({ onAbort }) => {
  return (
    <Flex justify="center">
      <Box backgroundColor="navyblue-500" textAlign="center" p={6} minWidth={600}>
        <Text data-testid="processing-indicator" fontSize="medium">
          Your file is being uploaded…
        </Text>
        <Flex justify="center" mt={3}>
          <Flex
            align="center"
            justify="center"
            borderRadius="circle"
            backgroundColor="violet-500"
            width={74}
            height={74}
          >
            <Img src={animatedGears} alt="Animated gears" nativeWidth={43} nativeHeight={40} />
          </Flex>
        </Flex>
        <Box mt={4}>
          <Button onClick={onAbort} size="medium" variant="outline" variantColor="navyblue-300">
            Cancel
          </Button>
        </Box>
      </Box>
    </Flex>
  );
};

export default Processing;
