/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import {
  Alert,
  Box,
  Card,
  Flex,
  Heading,
  Spinner,
  Tabs,
  SimpleGrid,
  TabList,
  TabPanels,
  TabPanel,
  Text,
  Grid,
  Link,
} from 'pouncejs';
import { extractErrorMessage } from 'Helpers/utils';
import urls from 'Source/urls';
import { BorderedTab, BorderTabDivider } from 'Components/BorderedTab';
import LinkButton from 'Components/buttons/LinkButton';
import LinkIconButton from 'Components/buttons/LinkIconButton';
import { LogTransportMethodsEnum, DATA_TRANSPORTS_DOC_URL } from 'Source/constants';
import { LogPullerTypeEnum } from 'Generated/schema';
import MarkdownParser from './MarkdownParser';
import { useGetAvailableLogProviderDetails } from './graphql/getAvailableLogProviderDetails.generated';
import AvailableLogProviderLogo, {
  AvailableLogProviderLogoProps,
} from '../AvailableLogProviderLogo';
import AvailableTransportMethod from '../AvailableTransportMethod';

interface AvailableLogProviderDetailsProps {
  id: string;
  displayName: string;
}

const AvailableLogProviderDetails: React.FC<AvailableLogProviderDetailsProps> = ({
  id,
  displayName,
}) => {
  const { data, loading, error } = useGetAvailableLogProviderDetails({
    variables: { id },
  });
  const availableLogProviderDetails = data?.getAvailableLogProvider?.result;
  const availableTransportMethods = availableLogProviderDetails?.details?.transports ?? [];
  const hasLogPuller = Object.values<string>(LogPullerTypeEnum).includes(id);
  const serviceTitle = availableLogProviderDetails?.displayName || displayName;

  return (
    <Card as="article" px={6} pt={8} pb={48}>
      <Flex align="center" spacing={10}>
        <LinkIconButton
          to={urls.integrations.logSources.create()}
          aria-label="Back to listing screen"
          variantColor="navyblue-300"
          icon="arrow-back"
        />
        <AvailableLogProviderLogo
          aria-describedby="service-title"
          id={id as AvailableLogProviderLogoProps['id']}
          size={48}
        />
        <Heading id="service-title" fontWeight="medium" ml={-7}>
          {serviceTitle}
        </Heading>
      </Flex>
      <SimpleGrid columns={hasLogPuller ? 1 : 2} spacingX={100} mx={150} mr={100}>
        {!hasLogPuller && (
          <Box as="section" position="relative">
            <Heading size="x-small" my={6}>
              Select a Transport Method
            </Heading>
            <BorderTabDivider />
            <Text fontSize="medium" color="gray-300" py={6}>
              The transport mechanism determines how logs will flow from its source into Panther.
              <Link external href={DATA_TRANSPORTS_DOC_URL} ml={1}>
                Check out our documentation to learn more about data transports.
              </Link>
            </Text>
            <Grid
              templateColumns={availableTransportMethods.length > 2 ? '1fr' : '1fr 1fr'}
              gap={4}
            >
              {availableTransportMethods.map(transportMethodType => (
                <AvailableTransportMethod
                  key={transportMethodType}
                  method={transportMethodType as LogTransportMethodsEnum}
                  logProviderId={id}
                />
              ))}
            </Grid>
          </Box>
        )}
        <Box as="section" position="relative">
          <Tabs>
            <Box mx={-4}>
              <TabList>
                <BorderedTab>App Info</BorderedTab>
                <BorderedTab>Use Cases</BorderedTab>
                <BorderedTab>How It Works</BorderedTab>
              </TabList>
            </Box>
            <BorderTabDivider />
            <Box py={6}>
              {loading && (
                <Flex w="100%" justify="center" my={10}>
                  <Spinner />
                </Flex>
              )}
              {(error || data?.getAvailableLogProvider?.error) && (
                <Alert
                  variant="error"
                  title="Couldn't load the details of the selected log provider"
                  description={
                    error ? extractErrorMessage(error) : data.getAvailableLogProvider.error.message
                  }
                />
              )}
              {availableLogProviderDetails && (
                <TabPanels>
                  <TabPanel>
                    <MarkdownParser>
                      {availableLogProviderDetails.details.appInfo ||
                        "There isn't any info about this log source"}
                    </MarkdownParser>
                  </TabPanel>
                  <TabPanel>
                    <MarkdownParser>
                      {availableLogProviderDetails.details.useCases ||
                        "We couldn't find any common use cases related to this source"}
                    </MarkdownParser>
                  </TabPanel>
                  <TabPanel>
                    <MarkdownParser>
                      {availableLogProviderDetails.details.howItWorks ||
                        'No information exists regarding this source'}
                    </MarkdownParser>
                  </TabPanel>
                </TabPanels>
              )}
            </Box>
          </Tabs>
        </Box>
        {hasLogPuller && !loading && (
          <Box as="section">
            <Flex justify="flex-end">
              <LinkButton to={urls.integrations.logSources.create(id as LogPullerTypeEnum)}>
                Start Source Setup
              </LinkButton>
            </Flex>
          </Box>
        )}
      </SimpleGrid>
    </Card>
  );
};

export default AvailableLogProviderDetails;
