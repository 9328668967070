/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Box, Button, Combobox, Flex, Text } from 'pouncejs';
import { useSelect } from 'Components/utils/SelectContext';
import useModal from 'Hooks/useModal';
import DeleteSavedQueriesModal from 'Components/modals/DeleteSavedQueriesModal';
import useBulkDownload from 'Hooks/useBulkDownload';

enum MassActionsEnum {
  'DOWNLOAD' = 'Download',
  'DELETE' = 'Delete',
}

const massActionValues = Object.values(MassActionsEnum);

const ListSavedQueriesSelection: React.FC = () => {
  const { selection, resetSelection } = useSelect();
  const { showModal } = useModal();
  const [selectedMassAction, setSelectedMassAction] = React.useState<MassActionsEnum>(
    MassActionsEnum.DOWNLOAD
  );
  const { download } = useBulkDownload({
    fileName: 'saved_queries',
  });

  const handleActionApplication = React.useCallback(() => {
    if (selectedMassAction === MassActionsEnum.DELETE) {
      showModal(<DeleteSavedQueriesModal ids={selection} onConfirm={resetSelection} />, {
        title: 'Attention!',
      });
    }

    if (selectedMassAction === MassActionsEnum.DOWNLOAD) {
      download({
        savedQueryIds: selection,
      });
    }

    // FIXME: Look into missing effect dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedMassAction, selection, resetSelection, download]);

  return (
    <Flex justify="flex-end" align="center" spacing={4}>
      <Text>{selection.length} Selected</Text>
      <Box width={150}>
        <Combobox
          data-tid="mass-saved-queries-action-combobox"
          onChange={setSelectedMassAction}
          items={massActionValues}
          value={selectedMassAction}
          label="Mass Action"
          showClearSelectionControl={false}
        />
      </Box>
      <Button
        data-tid="mass-saved-queries-action-apply"
        variantColor="violet-400"
        onClick={handleActionApplication}
      >
        Apply
      </Button>
    </Flex>
  );
};

export default React.memo(ListSavedQueriesSelection);
