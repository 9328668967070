/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import * as Types from '../../../../../../__generated__/schema';

import { WorkdayLogIntegrationDetails } from '../../../../../graphql/fragments/WorkdayLogIntegrationDetails.generated';
import { GraphQLError } from 'graphql';
import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';

export type UpdateWorkdayLogSourceVariables = {
  input: Types.UpdateLogPullingIntegrationInput;
};

export type UpdateWorkdayLogSource = { updateLogPullingIntegration: WorkdayLogIntegrationDetails };

export const UpdateWorkdayLogSourceDocument = gql`
  mutation UpdateWorkdayLogSource($input: UpdateLogPullingIntegrationInput!) {
    updateLogPullingIntegration(input: $input) {
      ...WorkdayLogIntegrationDetails
    }
  }
  ${WorkdayLogIntegrationDetails}
`;
export type UpdateWorkdayLogSourceMutationFn = ApolloReactCommon.MutationFunction<
  UpdateWorkdayLogSource,
  UpdateWorkdayLogSourceVariables
>;

/**
 * __useUpdateWorkdayLogSource__
 *
 * To run a mutation, you first call `useUpdateWorkdayLogSource` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWorkdayLogSource` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWorkdayLogSource, { data, loading, error }] = useUpdateWorkdayLogSource({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateWorkdayLogSource(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateWorkdayLogSource,
    UpdateWorkdayLogSourceVariables
  >
) {
  return ApolloReactHooks.useMutation<UpdateWorkdayLogSource, UpdateWorkdayLogSourceVariables>(
    UpdateWorkdayLogSourceDocument,
    baseOptions
  );
}
export type UpdateWorkdayLogSourceHookResult = ReturnType<typeof useUpdateWorkdayLogSource>;
export type UpdateWorkdayLogSourceMutationResult = ApolloReactCommon.MutationResult<
  UpdateWorkdayLogSource
>;
export type UpdateWorkdayLogSourceMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateWorkdayLogSource,
  UpdateWorkdayLogSourceVariables
>;
export function mockUpdateWorkdayLogSource({
  data,
  variables,
  errors,
}: {
  data: UpdateWorkdayLogSource;
  variables?: UpdateWorkdayLogSourceVariables;
  errors?: GraphQLError[];
}) {
  return {
    request: { query: UpdateWorkdayLogSourceDocument, variables },
    result: { data, errors },
  };
}
