/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import querystring from 'querystring';
import { Link as RRLink } from 'react-router-dom';
import { useFormikContext } from 'formik';
import { Button, Card, Flex, Img, Text } from 'pouncejs';
import SuccessStatus from 'Assets/statuses/success.svg';
import { WizardPanel } from 'Components/Wizard';
import urls from 'Source/urls';
import { LookupFormValues } from '../../lookupWizardHelpers';

interface LookupUploaderSuccessProps {
  onReset: () => void;
}

const LookupUploaderSuccess: React.FC<LookupUploaderSuccessProps> = ({ onReset }) => {
  const { values } = useFormikContext<LookupFormValues>();
  return (
    <Card variant="dark" p={4} py={6}>
      <Flex direction="column" spacing={6} align="center">
        <WizardPanel.Heading
          title="Data uploaded!"
          subtitle="Your data was processed successfully"
        />

        <Img nativeWidth={76} nativeHeight={76} alt="Checkmark" src={SuccessStatus} />
        <Flex spacing={3} align="center">
          <Button variant="outline" variantColor="navyblue-300" onClick={onReset}>
            Upload another file
          </Button>
          <Text>or</Text>
          <Button
            as={RRLink}
            to={{
              pathname: urls.data.dataExplorer(),
              search: querystring.stringify({
                snippedId: 'lookupTable',
                lookupId: values.id,
              }),
            }}
          >
            <Flex height="100%" align="center">
              View in Data Explorer
            </Flex>
          </Button>
        </Flex>
      </Flex>
    </Card>
  );
};

export default LookupUploaderSuccess;
