/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Box, FormError, Radio, RadioProps } from 'pouncejs';
import { FieldConfig, useField } from 'formik';

const FormikRadio: React.FC<RadioProps & Required<Pick<FieldConfig, 'name' | 'value'>>> = props => {
  const [field, meta] = useField(props.name);

  const isInvalid = meta.touched && !!meta.error;
  const errorElementId = isInvalid ? `${props.name}-error` : undefined;

  return (
    <Box>
      <Radio
        {...props}
        checked={String(field.value) === String(props.value)}
        invalid={isInvalid}
        aria-describedby={isInvalid ? errorElementId : undefined}
      />
      {isInvalid && <FormError id={errorElementId}>{meta.error}</FormError>}
    </Box>
  );
};

export default FormikRadio;
