/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { UserDetails } from 'Source/graphql/fragments/UserDetails.generated';
import { Role } from 'Generated/schema';
import { isSamlUser } from 'Helpers/utils';
import useSamlRole from 'Hooks/useSamlRole';

const hasRole = role => {
  return role !== null && role?.id !== null && role?.id !== '';
};

type SimpleRole = Pick<Role, 'id' | 'name'>;

type GetRole = (user: UserDetails) => SimpleRole;

type UseUserRoleResponse = {
  getRole: GetRole;
};

const useUserRole = (): UseUserRoleResponse => {
  const samlRole = useSamlRole();
  const getRole: GetRole = React.useCallback(
    user => {
      if (isSamlUser(user) && !hasRole(user.role)) {
        return samlRole;
      }
      return { id: user.role?.id, name: user.role?.name };
    },
    [samlRole]
  );

  return {
    getRole,
  };
};

export default useUserRole;
