/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Box, Flex, Heading, Icon } from 'pouncejs';
import { ReplayStageState } from '../ReplayStatus';

type ReplayStageViewItemProps = {
  title: string;
  state: ReplayStageState;
};

const ReplayStageViewItem: React.FC<ReplayStageViewItemProps> = ({ children, state, title }) => {
  const focused = state === 'running' || state === 'failed';
  return (
    <Flex
      as="li"
      alignItems="top"
      py={3}
      backgroundColor={focused ? 'navyblue-400' : 'transparent'}
    >
      <Flex width={45} justifyContent="center">
        <ReplayStageViewIcon state={state} />
      </Flex>
      <Box fontSize="medium">
        <Heading size="x-small" opacity={state === 'queued' ? 0.3 : 1} pb={2}>
          {title}
        </Heading>
        {children}
      </Box>
    </Flex>
  );
};

export default ReplayStageViewItem;

type ReplayStageViewIconProps = {
  state: ReplayStageState;
};

const ReplayStageViewIcon: React.FC<ReplayStageViewIconProps> = ({ state }) => {
  if (state === 'complete') {
    return <Icon type="check-circle" size="large" color="green-400" />;
  }
  if (state === 'failed') {
    return <Icon type="close-circle" size="large" color="red-400" />;
  }
  if (state === 'running') {
    return <Icon type="radio" size="large" color="yellow-400" />;
  }
  if (state === 'queued') {
    return <Icon type="radio" size="large" color="gray-400" />;
  }

  return null;
};
