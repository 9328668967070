/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { AlertType } from 'Generated/schema';
import PolicyAlertCard, { PolicyAlertCardProps } from './PolicyAlertCard';
import RuleAlertCard, { RuleAlertCardProps } from './RuleAlertCard';
import SystemErrorAlertCard, { SystemAlertCardProps } from './SystemErrorAlertCard';

type AlertCardProps = PolicyAlertCardProps | RuleAlertCardProps | SystemAlertCardProps;

const AlertCard: React.FC<AlertCardProps> = props => {
  switch (props.alert.type) {
    case AlertType.Policy:
      return <PolicyAlertCard {...props} />;

    case AlertType.SystemError:
      return <SystemErrorAlertCard {...props} />;

    case AlertType.Rule:
    case AlertType.ScheduledRule:
    case AlertType.RuleError:
    case AlertType.ScheduledRuleError:
    default:
      return <RuleAlertCard {...props} />;
  }
};

export default AlertCard;
