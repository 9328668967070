/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Box, AbstractButton, Img, Flex } from 'pouncejs';

interface DestinationCardProps {
  logo: string;
  title: string;
  onClick?: () => void;
}

const DestinationCard: React.FunctionComponent<DestinationCardProps> = ({
  logo,
  title,
  onClick,
}) => (
  <AbstractButton
    p={3}
    width={1}
    onClick={onClick}
    outline="none"
    border="1px solid"
    borderRadius="medium"
    borderColor="navyblue-300"
    transition="all 0.15s ease-in-out"
    _hover={{ backgroundColor: 'navyblue-500', borderColor: 'navyblue-500' }}
    _focus={{ backgroundColor: 'navyblue-500', borderColor: 'navyblue-500' }}
  >
    <Flex align="center">
      <Img
        aria-labelledby={title}
        src={logo}
        alt={title}
        objectFit="contain"
        nativeWidth={30}
        nativeHeight={30}
        mr={2}
      />
      <Box id={title} as="span">
        {title}
      </Box>
    </Flex>
  </AbstractButton>
);

export default DestinationCard;
