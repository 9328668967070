/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import * as Types from '../../../../../../../__generated__/schema';

import { GsuiteLogIntegrationDetails } from '../../../../../../graphql/fragments/GsuiteLogIntegrationDetails.generated';
import { GraphQLError } from 'graphql';
import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';

export type UpdateGsuiteLogSourceVariables = {
  input: Types.UpdateLogPullingIntegrationInput;
};

export type UpdateGsuiteLogSource = { updateLogPullingIntegration: GsuiteLogIntegrationDetails };

export const UpdateGsuiteLogSourceDocument = gql`
  mutation UpdateGsuiteLogSource($input: UpdateLogPullingIntegrationInput!) {
    updateLogPullingIntegration(input: $input) {
      ...GsuiteLogIntegrationDetails
    }
  }
  ${GsuiteLogIntegrationDetails}
`;
export type UpdateGsuiteLogSourceMutationFn = ApolloReactCommon.MutationFunction<
  UpdateGsuiteLogSource,
  UpdateGsuiteLogSourceVariables
>;

/**
 * __useUpdateGsuiteLogSource__
 *
 * To run a mutation, you first call `useUpdateGsuiteLogSource` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateGsuiteLogSource` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateGsuiteLogSource, { data, loading, error }] = useUpdateGsuiteLogSource({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateGsuiteLogSource(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateGsuiteLogSource,
    UpdateGsuiteLogSourceVariables
  >
) {
  return ApolloReactHooks.useMutation<UpdateGsuiteLogSource, UpdateGsuiteLogSourceVariables>(
    UpdateGsuiteLogSourceDocument,
    baseOptions
  );
}
export type UpdateGsuiteLogSourceHookResult = ReturnType<typeof useUpdateGsuiteLogSource>;
export type UpdateGsuiteLogSourceMutationResult = ApolloReactCommon.MutationResult<
  UpdateGsuiteLogSource
>;
export type UpdateGsuiteLogSourceMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateGsuiteLogSource,
  UpdateGsuiteLogSourceVariables
>;
export function mockUpdateGsuiteLogSource({
  data,
  variables,
  errors,
}: {
  data: UpdateGsuiteLogSource;
  variables?: UpdateGsuiteLogSourceVariables;
  errors?: GraphQLError[];
}) {
  return {
    request: { query: UpdateGsuiteLogSourceDocument, variables },
    result: { data, errors },
  };
}
