/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Combobox, NumberInput } from 'pouncejs';

const HOUR = 60;
const DAY = 24 * HOUR;

export enum IntervalUnitsEnum {
  DAYS = 'Day(s)',
  HOURS = 'Hour(s)',
  MINUTES = 'Minute(s)',
}

const intervalUnitMultiplier: Record<IntervalUnitsEnum, number> = {
  [IntervalUnitsEnum.MINUTES]: 1,
  [IntervalUnitsEnum.HOURS]: HOUR,
  [IntervalUnitsEnum.DAYS]: DAY,
};
const INTERVAL_OPTIONS = Object.values(IntervalUnitsEnum);

interface IntervalPickerProps {
  /** Called whenever a value is changed with the number of _minutes_ the unit equals */
  setValue: (value: number) => void;
  /** The value of the field, in minutes */
  value: number;
  /** The smallest unit to display as an option in the UI picker */
  smallestUnit?: IntervalUnitsEnum;
}

/**
 * Provides a UI for selecting minute intervals via larger units of time (hours, days). The
 * emitted `value` will always be in minutes, regardless of the selected unit.
 */
const IntervalPicker: React.FC<IntervalPickerProps> = ({
  value,
  setValue,
  smallestUnit = IntervalUnitsEnum.MINUTES,
}) => {
  const [intervalPeriod, setIntervalPeriod] = React.useState(IntervalUnitsEnum.DAYS);
  const [intervalNumber, setIntervalNumber] = React.useState(1);

  React.useEffect(() => {
    let period = IntervalUnitsEnum.MINUTES;
    let number = value;
    if (value && value % DAY === 0) {
      period = IntervalUnitsEnum.DAYS;
      number = value / DAY;
    } else if (value && value % HOUR === 0) {
      period = IntervalUnitsEnum.HOURS;
      number = value / HOUR;
    }
    setIntervalPeriod(period);
    setIntervalNumber(number);
  }, [value]);

  const onChangeUnit = React.useCallback(
    (metricSelected: IntervalUnitsEnum) => {
      setValue(intervalUnitMultiplier[metricSelected] * intervalNumber);
    },
    [setValue, intervalNumber]
  );

  const onChangeValue = React.useCallback(
    (metricValueSelected: number) => {
      setValue(metricValueSelected * intervalUnitMultiplier[intervalPeriod]);
    },
    [setValue, intervalPeriod]
  );

  // Filter out any units that are smaller than the threshold. This assumes that
  // the options are ordered from largest to smallest.
  const unitOptions = React.useMemo(() => {
    const smallestUnitIndex = INTERVAL_OPTIONS.indexOf(smallestUnit);
    return INTERVAL_OPTIONS.slice(0, smallestUnitIndex + 1);
  }, [smallestUnit]);

  return (
    <>
      <NumberInput
        value={intervalNumber}
        label="Number"
        onInputNumberChange={onChangeValue}
        min={intervalPeriod === IntervalUnitsEnum.MINUTES ? 15 : 1}
      />
      <Combobox
        value={intervalPeriod}
        label="Period"
        items={unitOptions}
        onChange={onChangeUnit}
        showClearSelectionControl={false}
      />
    </>
  );
};

export default IntervalPicker;
