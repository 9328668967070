/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import * as Types from '../../../../../../../__generated__/schema';

import { EventbridgeLogIntegrationDetails } from '../../../../../../graphql/fragments/EventbridgeLogIntegrationDetails.generated';
import { GraphQLError } from 'graphql';
import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';

export type AddEventbridgeSourceVariables = {
  input: Types.AddEventBridgeIntegrationInput;
};

export type AddEventbridgeSource = { addEventBridgeIntegration: EventbridgeLogIntegrationDetails };

export const AddEventbridgeSourceDocument = gql`
  mutation AddEventbridgeSource($input: AddEventBridgeIntegrationInput!) {
    addEventBridgeIntegration(input: $input) {
      ...EventbridgeLogIntegrationDetails
    }
  }
  ${EventbridgeLogIntegrationDetails}
`;
export type AddEventbridgeSourceMutationFn = ApolloReactCommon.MutationFunction<
  AddEventbridgeSource,
  AddEventbridgeSourceVariables
>;

/**
 * __useAddEventbridgeSource__
 *
 * To run a mutation, you first call `useAddEventbridgeSource` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddEventbridgeSource` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addEventbridgeSource, { data, loading, error }] = useAddEventbridgeSource({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddEventbridgeSource(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    AddEventbridgeSource,
    AddEventbridgeSourceVariables
  >
) {
  return ApolloReactHooks.useMutation<AddEventbridgeSource, AddEventbridgeSourceVariables>(
    AddEventbridgeSourceDocument,
    baseOptions
  );
}
export type AddEventbridgeSourceHookResult = ReturnType<typeof useAddEventbridgeSource>;
export type AddEventbridgeSourceMutationResult = ApolloReactCommon.MutationResult<
  AddEventbridgeSource
>;
export type AddEventbridgeSourceMutationOptions = ApolloReactCommon.BaseMutationOptions<
  AddEventbridgeSource,
  AddEventbridgeSourceVariables
>;
export function mockAddEventbridgeSource({
  data,
  variables,
  errors,
}: {
  data: AddEventbridgeSource;
  variables?: AddEventbridgeSourceVariables;
  errors?: GraphQLError[];
}) {
  return {
    request: { query: AddEventbridgeSourceDocument, variables },
    result: { data, errors },
  };
}
