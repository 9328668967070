/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { WizardPanel, useWizardContext } from 'Components/Wizard';
import { Flex, SimpleGrid, Link, Alert, Box } from 'pouncejs';
import { Field, useFormikContext } from 'formik';
import { Link as RRLink } from 'react-router-dom';
import FormikCombobox from 'Components/fields/ComboBox';
import urls from 'Source/urls';
import ErrorBoundary from 'Components/ErrorBoundary';
import { useGetLookup } from 'Pages/Enrichment/Lookups/graphql/getLookup.generated';
import LookupWizardContinueButton from '../LookupWizardContinueButton';
import { LookupFormValues, LOOKUP_WIZARD_WIDTH } from '../lookupWizardHelpers';
import { useListDataSchemaOptions } from './graphql/listDataSchemaOptions.generated';
import { useGetDataSchemaFields } from './graphql/getDataSchemaFields.generated';
import LookupPanelHeading from '../LookupPanelHeading';
import EditLookupActions from '../EditLookupActions';

const tableSchemaFields: (keyof LookupFormValues)[] = ['primaryKey', 'schemaName'];

const LookupTableSchemaFields = () => {
  const { values } = useFormikContext<LookupFormValues>();

  const { data: existingSchemas, refetch: refetchSchemas } = useListDataSchemaOptions({
    variables: {
      input: {
        isDisabled: false,
      },
    },
  });

  const existingSchemaOptions = React.useMemo(
    () => existingSchemas?.listSchemas?.results?.map(result => result.name) ?? [],
    [existingSchemas]
  );

  const { data: schemaFields } = useGetDataSchemaFields({
    variables: {
      input: {
        name: values.schemaName,
      },
    },
    // Wait for the user to choose a schema before we fetch its fields
    skip: !values.schemaName,
  });

  // When users switch tabs to create a new schema, the Apollo cache in the _existing_ tab
  // isn't get updated. So, when they return to this tab after creating a new schema
  // we want to refresh the list of schemas so they see their new schema.
  React.useEffect(() => {
    const refetchQueries = () => {
      refetchSchemas();
    };

    window.addEventListener('focus', refetchQueries);
    return () => window.removeEventListener('focus', refetchQueries);
  }, [refetchSchemas]);

  const primaryKeyOptions = React.useMemo(
    () => schemaFields?.getSchema?.record?.fields?.map(field => field.name) ?? [],
    [schemaFields]
  );

  return (
    <ErrorBoundary>
      <Flex direction="column" spacing={5}>
        <SimpleGrid columns={3} gap={5}>
          <Flex gridColumn="1 / span 2" direction="column" spacing={1}>
            <Field
              data-tid="lookup-schema-name-field"
              name="schemaName"
              as={FormikCombobox}
              searchable
              items={existingSchemaOptions}
              label="Schema Name"
              placeholder="A name for the custom schema"
              required
            />

            <Flex justify="flex-end">
              <Link
                as={RRLink}
                to={urls.data.schemas.create()}
                external
                data-tid="lookup-create-new-schema-button"
              >
                Create a new schema
              </Link>
            </Flex>
          </Flex>

          <Field
            data-tid="lookup-primary-key-field"
            as={FormikCombobox}
            disabled={!values.schemaName}
            searchable
            items={primaryKeyOptions}
            name="primaryKey"
            label="Primary Key Name"
            placeholder="The name of the primary key column"
          />
        </SimpleGrid>
      </Flex>
    </ErrorBoundary>
  );
};

const ReImportDataWarning = () => {
  const { initialValues } = useFormikContext<LookupFormValues>();

  const { data } = useGetLookup({
    skip: !initialValues.id,
    variables: {
      id: initialValues.id,
    },
  });

  const hasUploadedData = data?.getLookup?.activeVersion?.rowCount > 0;

  if (initialValues.schemaName && initialValues.primaryKey && hasUploadedData) {
    return (
      <Box mb={4}>
        <Alert
          variant="warning"
          title='Editing the "Schema Name" or "Primary Key Name" will require you to import your data again.'
        />
      </Box>
    );
  }
  return null;
};

const LookupTableSchemaForm: React.FC = () => {
  const { goToNextStep } = useWizardContext<LookupFormValues>();
  const { initialValues } = useFormikContext<LookupFormValues>();
  const isEditing = Boolean(initialValues.id);

  const handleSubmit = React.useCallback(() => {
    goToNextStep();
  }, [goToNextStep]);

  return (
    <WizardPanel width={LOOKUP_WIZARD_WIDTH} margin="0 auto">
      <LookupPanelHeading
        title="Table Schema"
        subtitle="Tell us about the shape of your lookup table’s data including the primary key. If you haven’t already created a schema for your table, you can create a new one in a separate tab and you’ll find it in the list to select."
      />

      {isEditing && <ReImportDataWarning />}

      <LookupTableSchemaFields />

      {isEditing ? (
        <EditLookupActions />
      ) : (
        <Flex justify="center" mt={6}>
          <LookupWizardContinueButton onClick={handleSubmit} fields={tableSchemaFields} />
        </Flex>
      )}
    </WizardPanel>
  );
};

export default LookupTableSchemaForm;
