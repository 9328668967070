/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Heading, Flex, Img } from 'pouncejs';
import NoDataFoundIllustration from 'Assets/illustrations/charts.svg';

interface NoDataFoundProps {
  title?: string;
  children?: never;
}

const NoDataFound: React.FC<NoDataFoundProps> = ({ title = "You don't have any data" }) => {
  return (
    <Flex height="100%" direction="column" align="center" justify="center">
      <Img nativeWidth={80} nativeHeight={90} alt="Charts" src={NoDataFoundIllustration} />
      <Heading size="x-small" color="navyblue-100" mt={6}>
        {title}
      </Heading>
    </Flex>
  );
};

export default NoDataFound;
