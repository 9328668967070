/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import * as Types from '../../../../__generated__/schema';

import { GlobalHelperFull } from '../../../graphql/fragments/GlobalHelperFull.generated';
import { GraphQLError } from 'graphql';
import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';

export type CreateGlobalHelperVariables = {
  input: Types.AddGlobalHelperInput;
};

export type CreateGlobalHelper = { addGlobalHelper: GlobalHelperFull };

export const CreateGlobalHelperDocument = gql`
  mutation CreateGlobalHelper($input: AddGlobalHelperInput!) {
    addGlobalHelper(input: $input) {
      ...GlobalHelperFull
    }
  }
  ${GlobalHelperFull}
`;
export type CreateGlobalHelperMutationFn = ApolloReactCommon.MutationFunction<
  CreateGlobalHelper,
  CreateGlobalHelperVariables
>;

/**
 * __useCreateGlobalHelper__
 *
 * To run a mutation, you first call `useCreateGlobalHelper` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateGlobalHelper` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createGlobalHelper, { data, loading, error }] = useCreateGlobalHelper({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateGlobalHelper(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateGlobalHelper,
    CreateGlobalHelperVariables
  >
) {
  return ApolloReactHooks.useMutation<CreateGlobalHelper, CreateGlobalHelperVariables>(
    CreateGlobalHelperDocument,
    baseOptions
  );
}
export type CreateGlobalHelperHookResult = ReturnType<typeof useCreateGlobalHelper>;
export type CreateGlobalHelperMutationResult = ApolloReactCommon.MutationResult<CreateGlobalHelper>;
export type CreateGlobalHelperMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateGlobalHelper,
  CreateGlobalHelperVariables
>;
export function mockCreateGlobalHelper({
  data,
  variables,
  errors,
}: {
  data: CreateGlobalHelper;
  variables?: CreateGlobalHelperVariables;
  errors?: GraphQLError[];
}) {
  return {
    request: { query: CreateGlobalHelperDocument, variables },
    result: { data, errors },
  };
}
