/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */
import React from 'react';
import { IconButtonProps, IconButton } from 'pouncejs';
import LinkButtonWrapper, { LinkButtonWrapperProps } from '../utils/LinkButtonWrapper';

type LinkIconButtonProps = LinkButtonWrapperProps & Omit<IconButtonProps, 'as'>;

const LinkIconButton: React.FC<LinkIconButtonProps> = ({ disabled, external, to, ...rest }) => {
  return (
    <LinkButtonWrapper disabled={disabled} external={external} to={to}>
      <IconButton as="span" aria-disabled={disabled} {...rest} />
    </LinkButtonWrapper>
  );
};
export default LinkIconButton;
