/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import * as Types from '../../../../../../../../__generated__/schema';

import { HoldingTankTestJobDetails } from '../../../../../../../graphql/fragments/HoldingTankTestJobDetails.generated';
import { HoldingTankInferJobDetails } from '../../../../../../../graphql/fragments/HoldingTankInferJobDetails.generated';
import { GraphQLError } from 'graphql';
import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';

export type PollHoldingTankJobVariables = {
  id: Types.Scalars['String'];
};

export type PollHoldingTankJob = {
  holdingTankJob: HoldingTankTestJobDetails | HoldingTankInferJobDetails;
};

export const PollHoldingTankJobDocument = gql`
  query PollHoldingTankJob($id: String!) {
    holdingTankJob(id: $id) {
      ... on HoldingTankTestJob {
        ...HoldingTankTestJobDetails
      }
      ... on HoldingTankInferJob {
        ...HoldingTankInferJobDetails
      }
    }
  }
  ${HoldingTankTestJobDetails}
  ${HoldingTankInferJobDetails}
`;

/**
 * __usePollHoldingTankJob__
 *
 * To run a query within a React component, call `usePollHoldingTankJob` and pass it any options that fit your needs.
 * When your component renders, `usePollHoldingTankJob` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePollHoldingTankJob({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePollHoldingTankJob(
  baseOptions?: ApolloReactHooks.QueryHookOptions<PollHoldingTankJob, PollHoldingTankJobVariables>
) {
  return ApolloReactHooks.useQuery<PollHoldingTankJob, PollHoldingTankJobVariables>(
    PollHoldingTankJobDocument,
    baseOptions
  );
}
export function usePollHoldingTankJobLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    PollHoldingTankJob,
    PollHoldingTankJobVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<PollHoldingTankJob, PollHoldingTankJobVariables>(
    PollHoldingTankJobDocument,
    baseOptions
  );
}
export type PollHoldingTankJobHookResult = ReturnType<typeof usePollHoldingTankJob>;
export type PollHoldingTankJobLazyQueryHookResult = ReturnType<
  typeof usePollHoldingTankJobLazyQuery
>;
export type PollHoldingTankJobQueryResult = ApolloReactCommon.QueryResult<
  PollHoldingTankJob,
  PollHoldingTankJobVariables
>;
export function mockPollHoldingTankJob({
  data,
  variables,
  errors,
}: {
  data: PollHoldingTankJob;
  variables?: PollHoldingTankJobVariables;
  errors?: GraphQLError[];
}) {
  return {
    request: { query: PollHoldingTankJobDocument, variables },
    result: { data, errors },
  };
}
