/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import { UserTeaser } from './UserTeaser.generated';
import { APITokenTeaser } from './APITokenTeaser.generated';
import { GraphQLError } from 'graphql';
import gql from 'graphql-tag';
export type ActorTeaser_User_ = UserTeaser;

export type ActorTeaser_APIToken_ = APITokenTeaser;

export type ActorTeaser = ActorTeaser_User_ | ActorTeaser_APIToken_;

export const ActorTeaser = gql`
  fragment ActorTeaser on Actor {
    ... on User {
      ...UserTeaser
    }
    ... on APIToken {
      ...APITokenTeaser
    }
  }
  ${UserTeaser}
  ${APITokenTeaser}
`;
