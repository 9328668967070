/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { EditUserSidesheetProps } from 'Components/sidesheets/EditUserSidesheet';

const SHOW_SIDESHEET = 'SHOW_SIDESHEET';
const HIDE_SIDESHEET = 'HIDE_SIDESHEET';

/* The available list of sidesheets to dispatch */
export enum SIDESHEETS {
  UPDATE_DESTINATION = 'UPDATE_DESTINATION',
  EDIT_USER = 'EDIT_USER',
  USER_INVITATION = 'USER_INVITATION',
}

type OmitControlledProps<T> = Omit<T, 'open' | 'onClose'>;

/* The shape of the reducer state */
interface SidesheetStateShape {
  sidesheet: keyof typeof SIDESHEETS | null;
  props?: { [key: string]: any };
  isVisible: boolean;
}

interface HideSidesheetAction {
  type: typeof HIDE_SIDESHEET;
}

interface EditUserSideSheetAction {
  type: typeof SHOW_SIDESHEET;
  payload: {
    sidesheet: SIDESHEETS.EDIT_USER;
    props: OmitControlledProps<EditUserSidesheetProps>;
  };
}

interface UserInvitationSideSheetAction {
  type: typeof SHOW_SIDESHEET;
  payload: {
    sidesheet: SIDESHEETS.USER_INVITATION;
  };
}

/* The available actions that can be dispatched */
type SidesheetStateAction =
  | EditUserSideSheetAction
  | UserInvitationSideSheetAction
  | HideSidesheetAction;

/* initial state of the reducer */
const initialState: SidesheetStateShape = {
  sidesheet: null,
  props: {},
  isVisible: false,
};

const sidesheetReducer = (state: SidesheetStateShape, action: SidesheetStateAction) => {
  switch (action.type) {
    case SHOW_SIDESHEET:
      return {
        sidesheet: action.payload.sidesheet,
        props: 'props' in action.payload ? action.payload.props : {},
        isVisible: true,
      };
    case HIDE_SIDESHEET:
      return { ...state, isVisible: false };
    default:
      return state;
  }
};

interface SidesheetContextValue {
  state: SidesheetStateShape;
  showSidesheet: (input: Exclude<SidesheetStateAction, HideSidesheetAction>['payload']) => void;
  hideSidesheet: () => void;
}

/* Context that will hold the `state` and `dispatch` */
export const SidesheetContext = React.createContext<SidesheetContextValue>(undefined);

/* A enhanced version of the context provider */
export const SidesheetProvider: React.FC = ({ children }) => {
  const [state, dispatch] = React.useReducer<
    React.Reducer<SidesheetStateShape, SidesheetStateAction>
  >(sidesheetReducer, initialState);

  const showSidesheet = React.useCallback(({ sidesheet, props }) => {
    dispatch({ type: 'SHOW_SIDESHEET', payload: { sidesheet, props } });
  }, []);

  const hideSidesheet = React.useCallback(() => {
    dispatch({ type: 'HIDE_SIDESHEET' });
  }, []);

  // for perf reasons we only want to re-render on state updates
  const contextValue = React.useMemo(() => ({ state, showSidesheet, hideSidesheet }), [
    state,
    showSidesheet,
    hideSidesheet,
  ]);

  // make the `state` and `dispatch` available to the components
  return <SidesheetContext.Provider value={contextValue}>{children}</SidesheetContext.Provider>;
};
