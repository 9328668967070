/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import * as Types from '../../../../../../../__generated__/schema';

import { SalesforceLogIntegrationDetails } from '../../../../../../graphql/fragments/SalesforceLogIntegrationDetails.generated';
import { GraphQLError } from 'graphql';
import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';

export type AddSalesforceLogSourceVariables = {
  input: Types.AddLogPullingIntegrationInput;
};

export type AddSalesforceLogSource = { addLogPullingIntegration: SalesforceLogIntegrationDetails };

export const AddSalesforceLogSourceDocument = gql`
  mutation AddSalesforceLogSource($input: AddLogPullingIntegrationInput!) {
    addLogPullingIntegration(input: $input) {
      ...SalesforceLogIntegrationDetails
    }
  }
  ${SalesforceLogIntegrationDetails}
`;
export type AddSalesforceLogSourceMutationFn = ApolloReactCommon.MutationFunction<
  AddSalesforceLogSource,
  AddSalesforceLogSourceVariables
>;

/**
 * __useAddSalesforceLogSource__
 *
 * To run a mutation, you first call `useAddSalesforceLogSource` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddSalesforceLogSource` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addSalesforceLogSource, { data, loading, error }] = useAddSalesforceLogSource({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddSalesforceLogSource(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    AddSalesforceLogSource,
    AddSalesforceLogSourceVariables
  >
) {
  return ApolloReactHooks.useMutation<AddSalesforceLogSource, AddSalesforceLogSourceVariables>(
    AddSalesforceLogSourceDocument,
    baseOptions
  );
}
export type AddSalesforceLogSourceHookResult = ReturnType<typeof useAddSalesforceLogSource>;
export type AddSalesforceLogSourceMutationResult = ApolloReactCommon.MutationResult<
  AddSalesforceLogSource
>;
export type AddSalesforceLogSourceMutationOptions = ApolloReactCommon.BaseMutationOptions<
  AddSalesforceLogSource,
  AddSalesforceLogSourceVariables
>;
export function mockAddSalesforceLogSource({
  data,
  variables,
  errors,
}: {
  data: AddSalesforceLogSource;
  variables?: AddSalesforceLogSourceVariables;
  errors?: GraphQLError[];
}) {
  return {
    request: { query: AddSalesforceLogSourceDocument, variables },
    result: { data, errors },
  };
}
