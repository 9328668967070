/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Link as RRLink } from 'react-router-dom';
import urls from 'Source/urls';
import { Box, Link, Flex, Card, Badge, Img } from 'pouncejs';
import LimitItemDisplay from 'Components/LimitItemDisplay';
import AdditionalBadgesJewel from 'Components/AdditionalBadgesJewel';
import {
  SourceIntegrationType,
  getLogSourceLogo,
  getLogSourceType,
} from 'Pages/Integrations/utils';

export interface MitreLogSourceCardProps {
  logSource: SourceIntegrationType;
  otherLogTypes: string[];
  matchingLogTypes: string[];
}

const MitreLogSourceCard = ({
  logSource,
  otherLogTypes,
  matchingLogTypes,
}: MitreLogSourceCardProps) => {
  const logo = getLogSourceLogo(logSource);
  const sourceType = getLogSourceType(logSource);

  return (
    <Card as="section" variant="light" p={4}>
      <Flex>
        <Img nativeWidth={20} nativeHeight={20} mr={5} alt="Logo" src={logo} />
        <Flex direction="column" justify="space-between" width={1}>
          <Box as="h4" fontWeight="medium" mr="auto" maxWidth="50%" wordBreak="break-word" pb={3}>
            <Link
              as={RRLink}
              to={urls.integrations.logSources.details(logSource.integrationId, sourceType)}
            >
              {logSource.integrationLabel}
            </Link>
          </Box>
          <Card variant="darker" p={4}>
            <Flex justify="space-between">
              <Flex flexWrap="wrap">
                {matchingLogTypes.map(logType => (
                  <Box m={1} key={logType}>
                    <Badge color="navyblue-200">{logType}</Badge>
                  </Box>
                ))}
                <LimitItemDisplay limit={3} AdditionalItemsComponent={AdditionalBadgesJewel}>
                  {otherLogTypes.map(logType => (
                    <Box key={logType} opacity={0.5} m={1}>
                      <Badge color="navyblue-200">{logType}</Badge>
                    </Box>
                  ))}
                </LimitItemDisplay>
              </Flex>
            </Flex>
          </Card>
        </Flex>
      </Flex>
    </Card>
  );
};

export default MitreLogSourceCard;
