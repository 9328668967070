/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import useRouter from 'Hooks/useRouter';
import { Permission } from 'Generated/schema';
import { compose } from 'Helpers/compose';
import withRoleRestrictedAccess from 'Hoc/withRoleRestrictedAccess';
import Page403 from 'Pages/403';
import { Alert, Box, Flex, Card, TabList, TabPanel, TabPanels, Tabs } from 'pouncejs';
import { BorderedTab, BorderTabDivider } from 'Components/BorderedTab';
import { getComplianceItemsTotalCount, extractErrorMessage } from 'Helpers/utils';
import invert from 'lodash/invert';
import withSEO from 'Hoc/withSEO';
import useUrlParams from 'Hooks/useUrlParams';
import ErrorBoundary from 'Components/ErrorBoundary';
import { DEFAULT_LARGE_PAGE_SIZE } from 'Source/constants';
import DetectionDetailsFunctionsTab from 'Pages/common/DetectionDetailsFunctionsTab';
import PolicyDetailsInfo from './PolicyDetailsInfo';
import PolicyDetailsBanner from './PolicyDetailsBanner';
import PolicyDetailsResources from './PolicyDetailsResources';
import PolicyDetailsPageSkeleton from './Skeleton';
import ListPolicyAlerts from './ListPolicyAlerts';
import { useGetPolicyDetails } from './graphql/getPolicyDetails.generated';
import { useListAlertsForPolicy } from './graphql/listAlertsForPolicy.generated';

export interface PolicyDetailsPageUrlParams {
  section?: 'details' | 'resources' | 'failures' | 'functions';
}

const sectionToTabIndex: Record<PolicyDetailsPageUrlParams['section'], number> = {
  details: 0,
  resources: 1,
  failures: 2,
  functions: 3,
};

const tabIndexToSection = invert(sectionToTabIndex) as Record<
  number,
  PolicyDetailsPageUrlParams['section']
>;

const PolicyDetailsPage = () => {
  const { match } = useRouter<{ id: string }>();
  const { urlParams, setUrlParams } = useUrlParams<PolicyDetailsPageUrlParams>();

  const { error, data, loading } = useGetPolicyDetails({
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    variables: {
      policyDetailsInput: {
        id: match.params.id,
      },
      resourcesForPolicyInput: {
        policyId: match.params.id,
      },
    },
  });

  const { data: policyFailureData } = useListAlertsForPolicy({
    // FIXME: Restore cache-and-network when we properly handle duplicates in endless pagination
    // fetchPolicy: 'cache-and-network',
    // nextFetchPolicy: 'cache-first',
    variables: {
      input: {
        detectionId: match.params.id,
        pageSize: DEFAULT_LARGE_PAGE_SIZE,
      },
    },
  });

  if (loading && !data) {
    return <PolicyDetailsPageSkeleton />;
  }

  if (error) {
    return (
      <Box mb={6}>
        <Alert
          variant="error"
          title="Couldn't load policy"
          description={
            extractErrorMessage(error) ||
            "An unknown error occured and we couldn't load the policy details from the server"
          }
        />
      </Box>
    );
  }

  const totalCounts = data.resourcesForPolicy.totals;

  const totals = getComplianceItemsTotalCount(totalCounts);
  const policyHasResources = totals > 0;
  const policyHasFailures = policyFailureData?.alerts?.edges.length > 0;

  return (
    <Box as="article" data-tracking-page="policy-details">
      <Flex direction="column" spacing={6} mb={6}>
        <ErrorBoundary>
          <PolicyDetailsBanner policy={data.policy} />
        </ErrorBoundary>
        <Card position="relative">
          <Tabs
            index={sectionToTabIndex[urlParams.section] || 0}
            onChange={index => setUrlParams({ section: tabIndexToSection[index] })}
          >
            <Box px={2}>
              <TabList>
                <BorderedTab>Details</BorderedTab>
                <BorderedTab data-testid="resource-tab">
                  Resources {policyHasResources ? `(${totals})` : ''}
                </BorderedTab>
                <BorderedTab>
                  <Box opacity={policyHasFailures ? 1 : 0.5}>Failures</Box>
                </BorderedTab>
                <BorderedTab>Functions & Tests</BorderedTab>
              </TabList>
              <BorderTabDivider />
              <TabPanels>
                <TabPanel
                  data-testid="policy-details-tabpanel"
                  data-tid="policy-details-tab"
                  lazy
                  unmountWhenInactive
                >
                  <PolicyDetailsInfo policy={data.policy} />
                </TabPanel>
                <TabPanel
                  data-testid="policy-resources-tabpanel"
                  data-tid="policy-resources-tab"
                  lazy
                  unmountWhenInactive
                >
                  <PolicyDetailsResources />
                </TabPanel>
                <TabPanel data-testid="policy-failures-tabpanel" lazy unmountWhenInactive>
                  <ListPolicyAlerts policyId={match.params.id} />
                </TabPanel>
                <TabPanel
                  data-tid="policy-functions-tab"
                  data-testid="function-settings-tabpanel"
                  lazy
                  unmountWhenInactive
                >
                  <DetectionDetailsFunctionsTab detection={data.policy} />
                </TabPanel>
              </TabPanels>
            </Box>
          </Tabs>
        </Card>
      </Flex>
    </Box>
  );
};

export default compose(
  withSEO({ title: ({ match }) => match.params.id }),
  withRoleRestrictedAccess({
    allowedPermissions: [Permission.PolicyRead, Permission.PolicyModify],
    fallback: <Page403 />,
  })
)(PolicyDetailsPage);
