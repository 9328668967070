/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Alert, Box, Flex, Heading } from 'pouncejs';
import useRouter from 'Hooks/useRouter';
import useRequestParamsWithPagination from 'Hooks/useRequestParamsWithPagination';
import { ComplianceStatusEnum, ResourcesForPolicyInput } from 'Generated/schema';
import NoResultsFound from 'Components/NoResultsFound';
import EmptyBoxImg from 'Assets/illustrations/empty-box.svg';
import {
  TableControlsPagination,
  TableControlsComplianceFilter,
} from 'Components/utils/TableControls';
import { getComplianceItemsTotalCount, extractErrorMessage } from 'Helpers/utils';
import ErrorBoundary from 'Components/ErrorBoundary';
import { DEFAULT_SMALL_PAGE_SIZE } from 'Source/constants';
import pick from 'lodash/pick';
import { useGetPolicyResources } from './graphql/getPolicyResources.generated';
import PolicyDetailsTable from './PolicyDetailsTable';
import PolicyResourcesSkeleton from './Skeleton';

const acceptedRequestParams = ['page', 'status', 'pageSize', 'suppressed'] as const;

const PolicyDetailsResources: React.FC = () => {
  const { match } = useRouter<{ id: string }>();

  const {
    requestParams,
    updatePagingParams,
    updateRequestParamsAndResetPaging,
  } = useRequestParamsWithPagination<
    Pick<ResourcesForPolicyInput, typeof acceptedRequestParams[number]>
  >();

  const { error, previousData, data = previousData, loading } = useGetPolicyResources({
    fetchPolicy: 'cache-and-network',
    variables: {
      resourcesForPolicyInput: {
        ...pick(requestParams, acceptedRequestParams),
        policyId: match.params.id,
        pageSize: DEFAULT_SMALL_PAGE_SIZE,
      },
    },
  });

  if (loading && !data) {
    return <PolicyResourcesSkeleton />;
  }

  if (error) {
    return (
      <Box mb={6}>
        <Alert
          variant="error"
          title="Couldn't load policy"
          description={
            extractErrorMessage(error) ||
            "An unknown error occured and we couldn't load the policy details from the server"
          }
        />
      </Box>
    );
  }
  const resources = data.resourcesForPolicy.items;
  const totalCounts = data.resourcesForPolicy.totals;
  const pagingData = data.resourcesForPolicy.paging;

  const resourceResultsExist = resources.length > 0;
  const areResourcesFiltered = !!requestParams.status || !!requestParams.suppressed;
  const totals = getComplianceItemsTotalCount(totalCounts);
  const policyHasResources = totals > 0;
  return (
    <Box p={6}>
      <ErrorBoundary>
        <Box mb={6}>
          {policyHasResources && (
            <Flex spacing={1}>
              <TableControlsComplianceFilter
                data-tid="policy-resources-view-all"
                count={getComplianceItemsTotalCount(totalCounts)}
                text="All"
                isActive={!areResourcesFiltered}
                onClick={() =>
                  updateRequestParamsAndResetPaging({
                    status: null,
                    suppressed: null,
                  })
                }
              />
              <TableControlsComplianceFilter
                data-tid="policy-resources-view-failing"
                count={totalCounts.active.fail}
                text="Failing"
                isActive={requestParams.status === ComplianceStatusEnum.Fail}
                onClick={() =>
                  updateRequestParamsAndResetPaging({
                    status: ComplianceStatusEnum.Fail,
                    suppressed: false,
                  })
                }
              />
              <TableControlsComplianceFilter
                data-tid="policy-resources-view-passing"
                count={totalCounts.active.pass}
                text="Passing"
                isActive={requestParams.status === ComplianceStatusEnum.Pass}
                onClick={() =>
                  updateRequestParamsAndResetPaging({
                    status: ComplianceStatusEnum.Pass,
                    suppressed: false,
                  })
                }
              />
              <TableControlsComplianceFilter
                data-tid="policy-resources-view-ignored "
                count={
                  totalCounts.suppressed.fail +
                  totalCounts.suppressed.pass +
                  totalCounts.suppressed.error
                }
                text="Ignored"
                isActive={!requestParams.status && requestParams.suppressed}
                onClick={() =>
                  updateRequestParamsAndResetPaging({
                    status: null,
                    suppressed: true,
                  })
                }
              />
              <TableControlsComplianceFilter
                data-tid="policy-resources-view-errored"
                count={totalCounts.active.error}
                text="Errored"
                isActive={requestParams.status === ComplianceStatusEnum.Error}
                onClick={() =>
                  updateRequestParamsAndResetPaging({
                    status: ComplianceStatusEnum.Error,
                    suppressed: false,
                  })
                }
              />
            </Flex>
          )}
        </Box>
        <Box>
          {!resourceResultsExist && !areResourcesFiltered && (
            <Flex justify="center" align="center" direction="column" my={8} spacing={8}>
              <img alt="Empty Box Illustration" src={EmptyBoxImg} width="auto" height={200} />
              <Heading size="small" color="navyblue-100">
                This policy isn{"'"}t applied to any resources
              </Heading>
            </Flex>
          )}
          {!resourceResultsExist && areResourcesFiltered && (
            <Box my={6}>
              <NoResultsFound />
            </Box>
          )}
          {resourceResultsExist && (
            <Flex direction="column" spacing={6}>
              <PolicyDetailsTable items={resources} />
              <TableControlsPagination
                page={pagingData.thisPage}
                totalPages={pagingData.totalPages}
                onPageChange={updatePagingParams}
              />
            </Flex>
          )}
        </Box>
      </ErrorBoundary>
    </Box>
  );
};

export default React.memo(PolicyDetailsResources);
