/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import * as Types from '../../../../__generated__/schema';

import { GraphQLError } from 'graphql';
import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';

export type DeleteEventThresholdAlarmVariables = {
  integrationId: Types.Scalars['ID'];
};

export type DeleteEventThresholdAlarm = Pick<
  Types.Mutation,
  'deleteLogIntegrationEventThresholdAlarm'
>;

export const DeleteEventThresholdAlarmDocument = gql`
  mutation DeleteEventThresholdAlarm($integrationId: ID!) {
    deleteLogIntegrationEventThresholdAlarm(integrationId: $integrationId)
  }
`;
export type DeleteEventThresholdAlarmMutationFn = ApolloReactCommon.MutationFunction<
  DeleteEventThresholdAlarm,
  DeleteEventThresholdAlarmVariables
>;

/**
 * __useDeleteEventThresholdAlarm__
 *
 * To run a mutation, you first call `useDeleteEventThresholdAlarm` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteEventThresholdAlarm` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteEventThresholdAlarm, { data, loading, error }] = useDeleteEventThresholdAlarm({
 *   variables: {
 *      integrationId: // value for 'integrationId'
 *   },
 * });
 */
export function useDeleteEventThresholdAlarm(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteEventThresholdAlarm,
    DeleteEventThresholdAlarmVariables
  >
) {
  return ApolloReactHooks.useMutation<
    DeleteEventThresholdAlarm,
    DeleteEventThresholdAlarmVariables
  >(DeleteEventThresholdAlarmDocument, baseOptions);
}
export type DeleteEventThresholdAlarmHookResult = ReturnType<typeof useDeleteEventThresholdAlarm>;
export type DeleteEventThresholdAlarmMutationResult = ApolloReactCommon.MutationResult<
  DeleteEventThresholdAlarm
>;
export type DeleteEventThresholdAlarmMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeleteEventThresholdAlarm,
  DeleteEventThresholdAlarmVariables
>;
export function mockDeleteEventThresholdAlarm({
  data,
  variables,
  errors,
}: {
  data: DeleteEventThresholdAlarm;
  variables?: DeleteEventThresholdAlarmVariables;
  errors?: GraphQLError[];
}) {
  return {
    request: { query: DeleteEventThresholdAlarmDocument, variables },
    result: { data, errors },
  };
}
