/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import RoleRestrictedAccess, {
  RoleRestrictedAccessProps,
} from 'Components/utils/RoleRestrictedAccess';

function withRoleRestrictedAccess(config: Omit<RoleRestrictedAccessProps, 'children'>) {
  return <P,>(Component: React.FC<P>) => {
    const RoleRestrictedComponent: React.FC<P> = props => (
      <RoleRestrictedAccess {...config}>
        <Component {...props} />
      </RoleRestrictedAccess>
    );

    return RoleRestrictedComponent;
  };
}

export default withRoleRestrictedAccess;
