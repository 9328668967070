/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Box, Button, Flex, Img, Text, ButtonProps, Card } from 'pouncejs';
import Dropzone from 'react-dropzone';
import folderIllustration from 'Assets/illustrations/folder.svg';

interface LookupUploaderInputProps {
  onFilesDropped: (acceptedFiles: File[]) => void;
}

const InlineFlex: React.FC<ButtonProps> = props => {
  return <Flex inline align="center" {...props} />;
};

const LookupUploaderInput: React.FC<LookupUploaderInputProps> = ({ onFilesDropped }) => {
  const [isDragged, setDrag] = React.useState(false);
  return (
    <Flex>
      <Dropzone
        multiple={false}
        onDragOver={() => setDrag(true)}
        onDragLeave={() => setDrag(false)}
        onDrop={onFilesDropped}
      >
        {({ getRootProps, getInputProps }) => (
          // @ts-ignore
          <Card
            variant="dark"
            data-testid="Drop files"
            borderWidth="3px"
            borderStyle="dashed"
            borderColor={isDragged ? 'navyblue-100' : 'transparent'}
            textAlign="center"
            p={6}
            width={1}
            {...getRootProps()}
            aria-label="Select file to upload"
            role="button"
          >
            <Text>Drag & Drop your .csv or .jsonl file here</Text>
            <Box p={2}>
              <Img src={folderIllustration} alt="File uploads" nativeWidth={75} nativeHeight={64} />
            </Box>
            <Text fontSize="small">or</Text>
            <input data-tid="lookup-input-upload" data-testid="input-upload" {...getInputProps()} />
            <Box mt={2}>
              <Button
                size="medium"
                type={null}
                // This UI should _look_ like a button, but since it's not interactive
                // we need different markup under the hood. The UI that's actually
                // interactive is the `input` element, clicking anywhere in the
                // dropzone should open the UI.
                as={InlineFlex}
              >
                Select file
              </Button>
            </Box>
          </Card>
        )}
      </Dropzone>
    </Flex>
  );
};

export default LookupUploaderInput;
