/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */
import React from 'react';
import { Button, Flex } from 'pouncejs';
import { useSchemaGeneration } from 'Components/utils/SchemaGenerationContext';
import TestSchemaButton from './TestSchemaButton';
import InferButton from './InferButton';

const SampleDataHeaderActions: React.FC = () => {
  const { reset, readOnly } = useSchemaGeneration();

  return (
    <Flex spacing={4}>
      <Button onClick={reset} size="medium" variant="outline" variantColor="navyblue-300">
        Add New Sample
      </Button>
      {readOnly ? <TestSchemaButton /> : <InferButton />}
    </Flex>
  );
};

export default SampleDataHeaderActions;
