/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import EditDestinationWizard from 'Components/wizards/EditDestinationWizard';
import { compose } from 'Helpers/compose';
import withSEO from 'Hoc/withSEO';
import withRoleRestrictedAccess from 'Hoc/withRoleRestrictedAccess';
import { Permission } from 'Generated/schema';
import Page403 from 'Pages/403';

export default compose(
  withSEO({ title: 'Edit Destination' }),
  withRoleRestrictedAccess({
    allowedPermissions: [Permission.DestinationModify],
    fallback: <Page403 />,
  })
)(EditDestinationWizard);
