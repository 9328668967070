/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import * as Types from '../../../__generated__/schema';

import { ActorTeaser_User_, ActorTeaser_APIToken_ } from './ActorTeaser.generated';
import { IntegrationItemHealthDetails } from './IntegrationItemHealthDetails.generated';
import { GraphQLError } from 'graphql';
import gql from 'graphql-tag';
import { ActorTeaser } from './ActorTeaser.generated';

export type GcsLogSourceIntegrationDetails = Pick<
  Types.GcsLogSourceIntegration,
  | 'createdAtTime'
  | 'isHealthy'
  | 'integrationId'
  | 'integrationLabel'
  | 'integrationType'
  | 'logProviderId'
  | 'lastEventReceived'
  | 'lastModified'
  | 'logTypes'
  | 'isEditable'
  | 'isPantherManaged'
> & {
  alarms: { eventThreshold?: Types.Maybe<Pick<Types.EventThresholdAlarm, 'minutesThreshold'>> };
  createdBy?: Types.Maybe<ActorTeaser_User_ | ActorTeaser_APIToken_>;
  gcsConfig: Pick<
    Types.GcsConfig,
    'projectId' | 'subscriptionId' | 'gcsBucket' | 'credentials' | 'userEmail' | 'logTypes'
  >;
  health: {
    systemAlarmStatus?: Types.Maybe<IntegrationItemHealthDetails>;
    logProcessingErrorsStatus?: Types.Maybe<IntegrationItemHealthDetails>;
    classificationFailuresStatus?: Types.Maybe<IntegrationItemHealthDetails>;
    gcsStatus?: Types.Maybe<IntegrationItemHealthDetails>;
  };
};

export const GcsLogSourceIntegrationDetails = gql`
  fragment GcsLogSourceIntegrationDetails on GcsLogSourceIntegration {
    createdAtTime
    isHealthy
    alarms {
      eventThreshold {
        minutesThreshold
      }
    }
    createdBy {
      ...ActorTeaser
    }
    integrationId
    integrationLabel
    integrationType
    logProviderId
    lastEventReceived
    lastModified
    logTypes
    gcsConfig {
      projectId
      subscriptionId
      gcsBucket
      credentials
      userEmail
      logTypes
    }
    health {
      systemAlarmStatus {
        ...IntegrationItemHealthDetails
      }
      logProcessingErrorsStatus {
        ...IntegrationItemHealthDetails
      }
      classificationFailuresStatus {
        ...IntegrationItemHealthDetails
      }
      gcsStatus {
        ...IntegrationItemHealthDetails
      }
    }
    isEditable
    isPantherManaged
  }
  ${ActorTeaser}
  ${IntegrationItemHealthDetails}
`;
