/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Box, Theme } from 'pouncejs';

interface FlatBadgeProps {
  children: React.ReactNode;
  backgroundColor?: keyof Theme['colors'];
  color?: keyof Theme['colors'];
}

const FlatBadge: React.FC<FlatBadgeProps> = ({
  backgroundColor = 'navyblue-700',
  color = 'white',
  children,
}) => {
  return (
    <Box
      backgroundColor={backgroundColor}
      borderRadius="small"
      px="6px"
      py={1}
      textTransform="uppercase"
      fontWeight="bold"
      fontSize="x-small"
      color={color}
    >
      {children}
    </Box>
  );
};

export default FlatBadge;
