/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { useWizardContext, WizardPanel } from 'Components/Wizard';
import { AbstractButton, Flex } from 'pouncejs';
import { useFormikContext } from 'formik';
import MethodCard from './MethodCard';

export type IAMRoleCreationMethods = 'console' | 'custom' | 'template';

interface SelectIAMRoleCreationMethodPanelProps {
  onSelectMethod: (values: IAMRoleCreationMethods) => void;
}

const SelectIAMRoleCreationMethodPanel: React.FC<SelectIAMRoleCreationMethodPanelProps> = ({
  onSelectMethod,
}) => {
  const { goToNextStep } = useWizardContext();
  const { initialValues, setFieldValue } = useFormikContext<{ integrationId: string }>();
  const editMode = !!initialValues.integrationId;

  const handleSelect = (method: IAMRoleCreationMethods) => {
    onSelectMethod(method);
    // Set managed Bucket Notifications only on console & template methods
    setFieldValue('managedBucketNotifications', ['console', 'template'].includes(method));
    goToNextStep();
  };

  return (
    <WizardPanel>
      <WizardPanel.ActionPrev />
      <WizardPanel.Heading
        title="Setup an IAM role"
        subtitle="Select how you want to set up your IAM role"
      />
      <Flex spacing={4} direction="column" mx="auto" maxWidth={800}>
        {!editMode && (
          <MethodCard
            title="Using the AWS Console UI"
            subtitle="Launch a CloudFormation stack using the AWS console"
            onSelect={() => handleSelect('console')}
            id="console"
          />
        )}
        <MethodCard
          title="CloudFormation or Terraform Template File"
          subtitle="Download an infrastructure-as-code file to deploy in your environment"
          onSelect={() => handleSelect('template')}
          id="template"
        />
        <AbstractButton color="blue-200" fontSize="medium" onClick={() => handleSelect('custom')}>
          I want to set up everything on my own
        </AbstractButton>
      </Flex>
      <WizardPanel.ActionPrev />
    </WizardPanel>
  );
};

export default SelectIAMRoleCreationMethodPanel;
