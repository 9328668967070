/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Card, Grid, Text } from 'pouncejs';
import { GcsLogSourceWizardValues } from './GcsLogSourceWizard';

const dataGrid = [
  { key: 'projectId', value: 'GCP Project ID' },
  { key: 'userEmail', value: 'Service Account Email' },
];

const SubscriptionInformation = ({
  data,
  withTitle = false,
}: {
  data: GcsLogSourceWizardValues;
  withTitle?: boolean;
}) => {
  return (
    <Card variant="dark" p={4} width={1} mt={6} mb={4}>
      {withTitle && (
        <Text fontSize="medium" ml={2}>
          Subscription Information
        </Text>
      )}
      {dataGrid.map(({ key, value }) => (
        <Grid key={key} gap={2} gridTemplateColumns="1fr 2fr" m={2}>
          <Text color="gray-300" fontSize="small-medium">
            {value}
          </Text>
          <Text fontSize="small">{data[key]}</Text>
        </Grid>
      ))}
    </Card>
  );
};

export default SubscriptionInformation;
