/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Checkbox } from 'pouncejs';
import { useSelect } from './SelectContext';

interface SelectCheckboxProps<T> {
  selectionItem: T;
}

function SelectCheckboxComponent<T>({ selectionItem, ...rest }: SelectCheckboxProps<T>) {
  const { checkIfSelected, toggleItem, bulkSelect } = useSelect<T>();
  const isSelected = checkIfSelected(selectionItem);

  const onClick = React.useCallback(
    event => {
      const isShiftKey: boolean = event?.nativeEvent.shiftKey;
      if (isShiftKey) {
        bulkSelect(selectionItem);
      } else {
        toggleItem(selectionItem);
      }
    },
    [bulkSelect, toggleItem, selectionItem]
  );

  return (
    <Checkbox
      checked={isSelected}
      aria-label={isSelected ? 'unselect item' : 'select item'}
      onChange={onClick}
      {...rest}
    />
  );
}

export default React.memo(SelectCheckboxComponent);
