/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Box, Flex, Link } from 'pouncejs';
import { Field, useFormikContext } from 'formik';
import FormikTextInput from 'Components/fields/TextInput';
import SensitiveTextInput from 'Components/fields/SensitiveTextInput';
import { WizardPanel } from 'Components/Wizard';
import { SAAS_ONBOARDING_ATLASSIAN_DOC_URL } from 'Source/constants';
import EditIntegrationActions from 'Pages/Integrations/components/EditIntegrationActions';
import { AtlassianLogSourceWizardValues } from '../AtlassianLogSourceWizard';

const AtlassianLogSourceCredentialsPanel: React.FC = () => {
  const { initialValues, dirty, isValid } = useFormikContext<AtlassianLogSourceWizardValues>();
  const editMode = !!initialValues.integrationId;

  return (
    <WizardPanel>
      <Box width={500} m="auto">
        <WizardPanel.Heading
          title="Fill in the form below with your credentials"
          subtitle={
            <React.Fragment>
              Check{' '}
              <Link external href={SAAS_ONBOARDING_ATLASSIAN_DOC_URL}>
                our documentation
              </Link>{' '}
              in order to create an Atlassian api key and fill in the next fields
            </React.Fragment>
          }
        />
        <Flex flexDirection="column" spacing={4}>
          <Field
            name="organization"
            label="Organization"
            as={FormikTextInput}
            placeholder="Your Atlassian organization id"
            required
          />
          <Field
            name="apiKey"
            as={SensitiveTextInput}
            shouldMask={editMode}
            label="API Key"
            placeholder={
              editMode
                ? 'Information is hidden. New values will override the existing'
                : 'Your Atlassian API Key'
            }
            required={!editMode}
            type="password"
          />
        </Flex>
      </Box>
      {editMode ? (
        <EditIntegrationActions />
      ) : (
        <WizardPanel.Actions>
          <WizardPanel.ActionNext disabled={!dirty || !isValid}>
            Continue Setup
          </WizardPanel.ActionNext>
        </WizardPanel.Actions>
      )}
    </WizardPanel>
  );
};

export default AtlassianLogSourceCredentialsPanel;
