/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Box, Flex, Heading, Text } from 'pouncejs';
import EmptyNotepadImg from 'Assets/illustrations/authentication.svg';
import LinkButton from 'Components/buttons/LinkButton';
import urls from 'Source/urls';
import RoleRestrictedAccess from 'Components/utils/RoleRestrictedAccess';
import { Permission } from 'Generated/schema';

const ListRolesPageEmptyDataFallback: React.FC = () => {
  return (
    <Flex justify="center" align="center" direction="column">
      <Box my={10}>
        <img alt="Empty Notepad Illustration" src={EmptyNotepadImg} width="auto" height={300} />
      </Box>
      <Heading mb={6}>No roles found</Heading>
      <Text color="gray-300" textAlign="center" mb={8}>
        Creating roles is the only way to protect your installation <br /> against people that
        always want to mess things up
      </Text>
      <RoleRestrictedAccess allowedPermissions={[Permission.UserModify]}>
        <LinkButton to={urls.settings.roles.create()}>Create a role</LinkButton>
      </RoleRestrictedAccess>
    </Flex>
  );
};

export default ListRolesPageEmptyDataFallback;
