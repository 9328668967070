/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import * as Types from '../../../../__generated__/schema';

import { DataModelFull } from '../../../graphql/fragments/DataModelFull.generated';
import { GraphQLError } from 'graphql';
import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';

export type UpdateDataModelVariables = {
  input: Types.AddOrUpdateDataModelInput;
};

export type UpdateDataModel = { updateDataModel: DataModelFull };

export const UpdateDataModelDocument = gql`
  mutation UpdateDataModel($input: AddOrUpdateDataModelInput!) {
    updateDataModel(input: $input) {
      ...DataModelFull
    }
  }
  ${DataModelFull}
`;
export type UpdateDataModelMutationFn = ApolloReactCommon.MutationFunction<
  UpdateDataModel,
  UpdateDataModelVariables
>;

/**
 * __useUpdateDataModel__
 *
 * To run a mutation, you first call `useUpdateDataModel` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDataModel` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDataModel, { data, loading, error }] = useUpdateDataModel({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateDataModel(
  baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateDataModel, UpdateDataModelVariables>
) {
  return ApolloReactHooks.useMutation<UpdateDataModel, UpdateDataModelVariables>(
    UpdateDataModelDocument,
    baseOptions
  );
}
export type UpdateDataModelHookResult = ReturnType<typeof useUpdateDataModel>;
export type UpdateDataModelMutationResult = ApolloReactCommon.MutationResult<UpdateDataModel>;
export type UpdateDataModelMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateDataModel,
  UpdateDataModelVariables
>;
export function mockUpdateDataModel({
  data,
  variables,
  errors,
}: {
  data: UpdateDataModel;
  variables?: UpdateDataModelVariables;
  errors?: GraphQLError[];
}) {
  return {
    request: { query: UpdateDataModelDocument, variables },
    result: { data, errors },
  };
}
