/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import { Flex, Text } from 'pouncejs';
import React from 'react';

const DisplayLimit: React.FC = () => {
  return (
    <Flex justify="start" my={4} ml={6}>
      <Text color="gray-300">Displaying only first 100 events</Text>
    </Flex>
  );
};

export default DisplayLimit;
