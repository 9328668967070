/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import groupBy from 'lodash/groupBy';
import mapValues from 'lodash/mapValues';
import map from 'lodash/map';
import sumBy from 'lodash/sumBy';
import partition from 'lodash/partition';
import { IndicatorSearchResult } from 'Hooks/useIndicatorSearchFlattenedResults';

export const isResultRulesMatch = (result: Pick<IndicatorSearchResult, 'dbName'>) =>
  result.dbName?.startsWith('panther_rule_matches');

// FIXME: remove conditional after Feb 2021 when all the old data has aged out
const marshalKey = ({ logType, tableName, dbName }: IndicatorSearchResult) =>
  tableName ? JSON.stringify({ tableName, dbName }) : JSON.stringify({ logType, dbName });

const unmarshalKey = (marshaledKey: string) =>
  JSON.parse(marshaledKey) as Pick<IndicatorSearchResult, 'tableName' | 'dbName' | 'logType'>;

/**
 * Splits the results into 2 partitions, based on which Database (Rules vs Logs) the results were
 * found in
 */
const useIndicatorSearchDbPartitionedResults = (flattenedResults: IndicatorSearchResult[]) => {
  return React.useMemo(() => {
    // Group together items from the same database AND the same table name
    // NOTE: for older results from query history, will group by logType + database
    const resultGroups = groupBy(flattenedResults, marshalKey);

    // Calculate the sum of items of each (tableName + dbName) combo
    const summedResultGroups = mapValues(resultGroups, items => sumBy(items, i => i.matches));

    // Convert the single object into an array of results + matches
    const aggregatedItems = map(summedResultGroups, (matches, tableNameAndDB) => {
      const { tableName, dbName, logType } = unmarshalKey(tableNameAndDB);
      return { tableName, dbName, logType, matches };
    });

    // Separate tuples into 2 groups: rule matches & log matches
    const [ruleMatches, logMatches] = partition<
      Pick<IndicatorSearchResult, 'tableName' | 'dbName' | 'logType' | 'matches'>
    >(aggregatedItems, isResultRulesMatch);

    // Return the items for each database
    return [logMatches, ruleMatches];
  }, [flattenedResults]);
};

export default useIndicatorSearchDbPartitionedResults;
