/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Box, Card, Flex, Heading, Spinner, Text } from 'pouncejs';
import useAsyncQueryResults from 'Hooks/useAsyncQueryResults';
import useAsyncQueryContext from 'Hooks/useAsyncQueryContext';
import map from 'lodash/map';
import AttributeBar from './AttributeBar';

interface AttributeChartProps {
  /**
   * Flag indicating whether the copy action is displayed
   */
  withCopyAction?: boolean;
  /**
   * Flag that controls the indicator search pivoting action
   */
  withIndicatorSearchPivotingAction?: boolean;
}

const AttributeChart: React.FC<AttributeChartProps> = ({
  withCopyAction,
  withIndicatorSearchPivotingAction,
}) => {
  const { state: { queryStatus, globalErrorMessage } } = useAsyncQueryContext(); // prettier-ignore
  const { results } = useAsyncQueryResults({
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
  });

  /**
   * An attribute has a key and a value. For example for IPs, it should  be `"127.0.0.1": 5`,
   * meaning that this IP was found 5 times.
   *
   * The API responds in a very generic way and for re-usability purposes, it's not in our
   * advantage to change it (so that our re-usable hooks can still work). The original response is:
   *
   * [
   *   [{ summaryAttribute: '127.0.0.1', ... , nmatches: 15, ... }],
   *   [{ summaryAttribute: '245.24.2.1', ..., nmatches: 31, ... }]
   * ]
   *
   * so we have to turn them to:
   *
   * {
   *   '127.0.0.1': 15,
   *   '245.24.2.1': 31
   * }
   */
  const keyValueMap = React.useMemo<Record<string, number>>(() => {
    const returnVal = {};
    if (results) {
      results.forEach(event => {
        // use toLowerCase() because the column name casing varies by database
        const key = event[Object.keys(event).find(k => k.toLowerCase() === 'summaryattribute')];
        const value = event[Object.keys(event).find(k => k.toLowerCase() === 'nmatches')];
        returnVal[key] = value;
      });
    }

    return returnVal;
  }, [results]);

  const maxValue = React.useMemo(() => Math.max(...Object.values(keyValueMap)), [keyValueMap]);

  if (queryStatus === 'running') {
    return (
      <Flex width="100%" height={100} align="center" justify="center">
        <Spinner />
      </Flex>
    );
  }

  if (queryStatus === 'errored') {
    return (
      <Card bg="pink-700">
        <Flex align="center" justify="center" direction="column" p={6}>
          <Heading size="small" as="h4" color="pink-100" mb={2}>
            Event summary could not be displayed
          </Heading>
          <Text fontSize="medium" color="pink-100">
            {globalErrorMessage}
          </Text>
        </Flex>
      </Card>
    );
  }

  if (!results?.length) {
    return (
      <Heading color="navyblue-100" textAlign="center" size="x-small" my={10}>
        No occurrences for this attribute
      </Heading>
    );
  }

  return (
    <Box as="ol" borderTop="1px solid" borderColor="navyblue-300">
      {map(keyValueMap, (value, key) => (
        <Box as="li" key={key} borderBottom="1px solid" borderColor="navyblue-300">
          <AttributeBar
            title={key}
            value={value}
            percentage={value / maxValue}
            withCopyAction={withCopyAction}
            withIndicatorSearchPivotingAction={withIndicatorSearchPivotingAction}
          />
        </Box>
      ))}
    </Box>
  );
};

export default AttributeChart;
