/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Box, useSnackbar } from 'pouncejs';
import { extractErrorMessage } from 'Helpers/utils';
import TablePlaceholder from 'Components/TablePlaceholder';
import { useDataExplorerContext } from '../../DataExplorerContext';
import { useListTablesForDatabase } from './graphql/listTablesForDatabase.generated';
import TableListItem from './TableListItem';

const TableList: React.FC = () => {
  const { pushSnackbar } = useSnackbar();
  const {
    state: { selectedDatabase, searchValue },
    dispatch,
  } = useDataExplorerContext();
  const { data, loading } = useListTablesForDatabase({
    variables: {
      name: selectedDatabase,
    },
    onError: error =>
      pushSnackbar({
        variant: 'error',
        title: "Couldn't fetch your databases",
        description: extractErrorMessage(error),
      }),
  });

  const selectTable = React.useCallback(
    table => dispatch({ type: 'SELECT_TABLE', payload: { table } }),
    [dispatch]
  );

  if (loading) {
    return (
      <Box m={6}>
        <TablePlaceholder rowCount={8} rowHeight={30} rowGap={15} />
      </Box>
    );
  }

  return (
    <Box overflowY="auto" overflowX="hidden" as="ul" height={370} data-testid="database-table-list">
      {data?.dataLakeDatabase.tables
        .filter(({ name }) => name.includes(searchValue))
        .map(({ name }) => (
          <TableListItem key={name} name={name} onClick={selectTable} />
        ))}
    </Box>
  );
};

export default TableList;
