/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Dropdown, DropdownButton, DropdownItem, DropdownLink, DropdownMenu } from 'pouncejs';
import { DataModel } from 'Generated/schema';
import urls from 'Source/urls';
import { Link as RRLink } from 'react-router-dom';
import useModal from 'Hooks/useModal';
import DeleteDataModelModal from 'Components/modals/DeleteDataModelModal';
import useBulkDownload from 'Hooks/useBulkDownload';
import OptionsButton from 'Components/buttons/OptionsButton';

interface DataModelCardOptionsProps {
  dataModel: DataModel;
}

const DataModelCardOptions: React.FC<DataModelCardOptionsProps> = ({ dataModel }) => {
  const { showModal } = useModal();
  const { download } = useBulkDownload({
    fileName: dataModel.id,
  });

  return (
    <Dropdown>
      <DropdownButton as={OptionsButton} />
      <DropdownMenu minWidth="100px">
        <DropdownLink as={RRLink} to={urls.data.dataModels.edit(dataModel.id)}>
          Edit
        </DropdownLink>
        <DropdownItem onSelect={() => download({ dataModelIds: [dataModel.id] })}>
          Download
        </DropdownItem>
        <DropdownItem
          onSelect={() => {
            return showModal(<DeleteDataModelModal dataModels={[dataModel]} />, {
              title: `Delete Data Model`,
            });
          }}
        >
          Delete
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  );
};

export default React.memo(DataModelCardOptions);
