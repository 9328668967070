/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import groupBy from 'lodash/groupBy';
import sumBy from 'lodash/sumBy';
import { TimeSeriesData } from 'Components/charts/TimeSeriesChart';
import { IndicatorSearchResult } from 'Hooks/useIndicatorSearchFlattenedResults';

const useTimelineSeriesData = (results: IndicatorSearchResult[]) => {
  return React.useMemo(() => {
    // Group together items from the same timestamp
    const groupByTimestamp = groupBy(results, r => r.timestamp);

    // A list of timestamps returned from the API
    const availableTimestamps = Object.keys(groupByTimestamp);

    // a list of result items for each timestamp
    const resultsForEachTimestamp = Object.values(groupByTimestamp);

    // A list of matches per timestamp
    const matchesPerTimestamp = resultsForEachTimestamp.map(resultsForSingleTimestamp =>
      sumBy(resultsForSingleTimestamp, r => r.matches)
    );

    const seriesData: TimeSeriesData = {
      timestamps: availableTimestamps,
      series: [{ label: 'Matches', values: matchesPerTimestamp, color: 'indigo-600' }],
      metadata: resultsForEachTimestamp,
    };

    return seriesData;
  }, [results]);
};

export default useTimelineSeriesData;
