/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import * as Types from '../../../__generated__/schema';

import { GraphQLError } from 'graphql';
import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';

export type DeleteSavedQueriesVariables = {
  input: Types.DeleteSavedQueryInput;
};

export type DeleteSavedQueries = Pick<Types.Mutation, 'deleteSavedQueries'>;

export const DeleteSavedQueriesDocument = gql`
  mutation DeleteSavedQueries($input: DeleteSavedQueryInput!) {
    deleteSavedQueries(input: $input)
  }
`;
export type DeleteSavedQueriesMutationFn = ApolloReactCommon.MutationFunction<
  DeleteSavedQueries,
  DeleteSavedQueriesVariables
>;

/**
 * __useDeleteSavedQueries__
 *
 * To run a mutation, you first call `useDeleteSavedQueries` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSavedQueries` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSavedQueries, { data, loading, error }] = useDeleteSavedQueries({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteSavedQueries(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteSavedQueries,
    DeleteSavedQueriesVariables
  >
) {
  return ApolloReactHooks.useMutation<DeleteSavedQueries, DeleteSavedQueriesVariables>(
    DeleteSavedQueriesDocument,
    baseOptions
  );
}
export type DeleteSavedQueriesHookResult = ReturnType<typeof useDeleteSavedQueries>;
export type DeleteSavedQueriesMutationResult = ApolloReactCommon.MutationResult<DeleteSavedQueries>;
export type DeleteSavedQueriesMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeleteSavedQueries,
  DeleteSavedQueriesVariables
>;
export function mockDeleteSavedQueries({
  data,
  variables,
  errors,
}: {
  data: DeleteSavedQueries;
  variables?: DeleteSavedQueriesVariables;
  errors?: GraphQLError[];
}) {
  return {
    request: { query: DeleteSavedQueriesDocument, variables },
    result: { data, errors },
  };
}
