/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Box, Flex, FlexProps, Theme } from 'pouncejs';
import { stringToPaleColor } from 'Helpers/colors';

interface BulletedValueProps extends FlexProps {
  value: string;
  size?: 'x-small' | 'small' | 'medium';
  color?: keyof Theme['colors'];
  emphasized?: boolean;
}

const BulletedValue: React.FC<BulletedValueProps> = ({
  value,
  color,
  size = 'small',
  emphasized = false,
  ...rest
}) => {
  let bulletSize: number;
  switch (size) {
    case 'x-small':
      bulletSize = 10;
      break;
    case 'small':
      bulletSize = 12;
      break;
    case 'medium':
    default:
      bulletSize = 14;
  }

  return (
    <Flex spacing={2} align="center" {...rest}>
      <Box
        as="span"
        width={bulletSize}
        height={bulletSize}
        backgroundColor={color || (stringToPaleColor(value) as any)}
        borderRadius="circle"
      />
      <Box
        as="span"
        fontSize={size}
        fontWeight={emphasized ? 'bold' : 'normal'}
        lineHeight="typical"
      >
        {value}
      </Box>
    </Flex>
  );
};

export default BulletedValue;
