/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import * as Types from '../../../../../__generated__/schema';

import { GraphQLError } from 'graphql';
import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';

export type GetPolicyResourcesVariables = {
  resourcesForPolicyInput: Types.ResourcesForPolicyInput;
};

export type GetPolicyResources = {
  resourcesForPolicy?: Types.Maybe<{
    items?: Types.Maybe<
      Array<
        Types.Maybe<
          Pick<
            Types.ComplianceItem,
            'errorMessage' | 'lastUpdated' | 'policyId' | 'resourceId' | 'status' | 'suppressed'
          > & {
            integration?: Types.Maybe<
              Pick<Types.ComplianceIntegration, 'integrationId' | 'integrationLabel'>
            >;
          }
        >
      >
    >;
    paging?: Types.Maybe<Pick<Types.PagingData, 'totalItems' | 'totalPages' | 'thisPage'>>;
    totals?: Types.Maybe<{
      active?: Types.Maybe<Pick<Types.ComplianceStatusCounts, 'fail' | 'pass' | 'error'>>;
      suppressed?: Types.Maybe<Pick<Types.ComplianceStatusCounts, 'fail' | 'pass' | 'error'>>;
    }>;
  }>;
};

export const GetPolicyResourcesDocument = gql`
  query GetPolicyResources($resourcesForPolicyInput: ResourcesForPolicyInput!) {
    resourcesForPolicy(input: $resourcesForPolicyInput) {
      items {
        errorMessage
        integration {
          integrationId
          integrationLabel
        }
        lastUpdated
        policyId
        resourceId
        status
        suppressed
      }
      paging {
        totalItems
        totalPages
        thisPage
      }
      totals {
        active {
          fail
          pass
          error
        }
        suppressed {
          fail
          pass
          error
        }
      }
    }
  }
`;

/**
 * __useGetPolicyResources__
 *
 * To run a query within a React component, call `useGetPolicyResources` and pass it any options that fit your needs.
 * When your component renders, `useGetPolicyResources` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPolicyResources({
 *   variables: {
 *      resourcesForPolicyInput: // value for 'resourcesForPolicyInput'
 *   },
 * });
 */
export function useGetPolicyResources(
  baseOptions?: ApolloReactHooks.QueryHookOptions<GetPolicyResources, GetPolicyResourcesVariables>
) {
  return ApolloReactHooks.useQuery<GetPolicyResources, GetPolicyResourcesVariables>(
    GetPolicyResourcesDocument,
    baseOptions
  );
}
export function useGetPolicyResourcesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetPolicyResources,
    GetPolicyResourcesVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<GetPolicyResources, GetPolicyResourcesVariables>(
    GetPolicyResourcesDocument,
    baseOptions
  );
}
export type GetPolicyResourcesHookResult = ReturnType<typeof useGetPolicyResources>;
export type GetPolicyResourcesLazyQueryHookResult = ReturnType<
  typeof useGetPolicyResourcesLazyQuery
>;
export type GetPolicyResourcesQueryResult = ApolloReactCommon.QueryResult<
  GetPolicyResources,
  GetPolicyResourcesVariables
>;
export function mockGetPolicyResources({
  data,
  variables,
  errors,
}: {
  data: GetPolicyResources;
  variables?: GetPolicyResourcesVariables;
  errors?: GraphQLError[];
}) {
  return {
    request: { query: GetPolicyResourcesDocument, variables },
    result: { data, errors },
  };
}
