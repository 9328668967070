/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import Auth from '@aws-amplify/auth';
import { setContext } from '@apollo/client/link/context';

const authLink = setContext(async (_, { headers }) => {
  const jwtToken = await Auth.currentSession()
    .then(session => session.getIdToken().getJwtToken())
    .catch(() => '');

  return {
    headers: {
      ...headers,
      Authorization: jwtToken,
    },
  };
});

export default authLink;
