/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { useSnackbar } from 'pouncejs';
import useRouter from 'Hooks/useRouter';
import urls from 'Source/urls';
import { GlobalHelperTeaser } from 'Source/graphql/fragments/GlobalHelperTeaser.generated';
import { GlobalHelperFull } from 'Source/graphql/fragments/GlobalHelperFull.generated';
import { useDeleteGlobalHelpers } from './graphql/deleteGlobalHelpers.generated';
import ConfirmModal from '../ConfirmModal';

export interface DeleteGlobalHelpersProps {
  globalHelper: GlobalHelperTeaser | GlobalHelperFull;
}

const DeleteGlobalModal: React.FC<DeleteGlobalHelpersProps> = ({ globalHelper }) => {
  const { location, history } = useRouter<{ id?: string }>();
  const { pushSnackbar } = useSnackbar();
  const globalName = globalHelper.id;

  const [deleteGlobalHelpers] = useDeleteGlobalHelpers({
    variables: {
      input: {
        globals: [
          {
            id: globalHelper.id,
          },
        ],
      },
    },
    update: async cache => {
      cache.evict({ id: cache.identify(globalHelper) });
      cache.gc();
    },
    onCompleted: () => {
      pushSnackbar({
        variant: 'success',
        title: `Successfully deleted global helper: ${globalName}`,
      });

      if (location.pathname.includes(globalHelper.id)) {
        // if we were on the particular policy's details page or edit page --> redirect on delete
        history.push(urls.data.globalHelpers.list());
      }
    },
    onError: () => {
      pushSnackbar({
        variant: 'error',
        title: `Failed to delete global helper: ${globalName}`,
      });
    },
  });

  return (
    <ConfirmModal
      variant="destructive"
      subtitle={`Are you sure you want to delete ${globalName}?`}
      onConfirm={deleteGlobalHelpers}
    />
  );
};

export default DeleteGlobalModal;
