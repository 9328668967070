/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import useCopyToClipboard from 'Hooks/useCopyToClipboard';
import map from 'lodash/map';
import { AbstractButton, Box, IconButton, Table } from 'pouncejs';
import ResultsTableColumn from './ResultsTableColumn';

interface ResultsTableRowProps {
  record: Record<string, any>;
  even: boolean;
  onClick: (record: Record<string, any>) => void;
}

const ResultsTableRow: React.FC<ResultsTableRowProps> = ({ record, onClick, even }) => {
  const copyToClipboard = useCopyToClipboard();

  const handleColumnClick = React.useCallback(
    (selectedValue: unknown) => {
      copyToClipboard(selectedValue);
    },
    [copyToClipboard]
  );
  return (
    <Table.Row>
      <Box
        as={Table.Cell}
        position="sticky"
        userSelect="none"
        left={0}
        backgroundColor={even ? 'navyblue-500' : 'navyblue-400'}
        boxShadow="0px 2px 14px 0px rgba(0, 0, 0, 0.5)"
        zIndex={999}
        width={150}
        p={4}
      >
        <AbstractButton
          onClick={() => onClick(record)}
          display="flex"
          justifyItems="center"
          alignItems="center"
        >
          <Box mx={2}>View JSON</Box>
          <IconButton
            as="span"
            variant="outline"
            variantBorderStyle="circle"
            size="small"
            icon="arrow-forward"
            aria-label="Switch to JSON view"
            variantColor="navyblue-300"
            // @ts-ignore
            sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexShrink: 0 }}
          />
        </AbstractButton>
      </Box>

      {map(record, (value, key) => (
        <ResultsTableColumn key={key} value={value} onClick={handleColumnClick} />
      ))}
    </Table.Row>
  );
};

export default React.memo(ResultsTableRow);
