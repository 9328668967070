/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import type { monaco } from 'react-monaco-editor';

const builtinConstants = 'true|false';

const builtinFunctions =
  'avg|count|first|last|max|min|sum|ucase|lcase|mid|len|round|rank|now|format|' +
  'coalesce|ifnull|isnull|nvl';

const keywords =
  'select|insert|update|delete|from|where|and|or|group|by|order|limit|offset|having|as|case|' +
  'when|else|end|type|left|right|join|on|outer|desc|asc|union|create|table|primary|key|if|' +
  'foreign|not|references|default|null|inner|cross|natural|database|drop|grant';

const dataTypes =
  'int|numeric|decimal|date|varchar|char|bigint|float|double|bit|binary|text|set|timestamp|' +
  'money|real|number|integer';

const registerCompletions = (monacoInstance: typeof monaco) => {
  const registration = monacoInstance.languages.registerCompletionItemProvider('sql', {
    provideCompletionItems() {
      return {
        suggestions: [
          ...builtinConstants.split('|').map(constant => ({
            label: constant,
            detail: 'constant',
            kind: monacoInstance.languages.CompletionItemKind.Constant,
            insertText: constant,
          })),
          ...keywords.split('|').map(keyword => ({
            label: keyword,
            detail: 'keyword',
            kind: monacoInstance.languages.CompletionItemKind.Keyword,
            insertText: keyword,
          })),
          ...dataTypes.split('|').map(datatype => ({
            label: datatype,
            detail: 'datatype',
            kind: monacoInstance.languages.CompletionItemKind.Text,
            insertText: datatype,
          })),

          ...builtinFunctions.split('|').map(func => ({
            label: func,
            detail: 'function',
            kind: monacoInstance.languages.CompletionItemKind.Function,
            insertText: `${func}()`,
          })),
        ] as monaco.languages.CompletionItem[],
      };
    },
  });

  return registration;
};

export default registerCompletions;
