/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Flex } from 'pouncejs';
import { EChartOption } from 'echarts';
import ScaleButton from './ScaleButton';

interface ScaleControlsProps {
  scaleType: string;
  onSelect: (option: EChartOption.BasicComponents.CartesianAxis.Type) => void;
  chartId: string;
}

const ScaleControls: React.FC<ScaleControlsProps> = ({
  scaleType = 'value',
  onSelect,
  chartId,
}) => {
  return (
    <Flex spacing={2} zIndex={5}>
      <ScaleButton
        data-tid={`scale-button-linear-${chartId}`}
        title="Linear"
        selected={scaleType === 'value'}
        onClick={() => onSelect('value')}
      />
      <ScaleButton
        data-tid={`scale-button-log-${chartId}`}
        title="Logarithmic"
        selected={scaleType === 'log'}
        onClick={() => onSelect('log')}
      />
    </Flex>
  );
};

export default ScaleControls;
