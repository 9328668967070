/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Link as RRLink } from 'react-router-dom';
import { Box, SimpleGrid, Text, Link, Flex, Card } from 'pouncejs';
import { formatDatetime, getActorDisplayName } from 'Helpers/utils';
import Linkify from 'Components/Linkify';
import { PolicyDetails } from 'Source/graphql/fragments/PolicyDetails.generated';
import urls from 'Source/urls';

interface ResourceDetailsInfoProps {
  policy?: PolicyDetails;
}

const PolicyDetailsInfo: React.FC<ResourceDetailsInfoProps> = ({ policy }) => {
  return (
    <Card as="article" p={6}>
      <Card variant="dark" as="section" p={4} mb={4}>
        <Text id="policy-description" fontStyle={!policy.description ? 'italic' : 'normal'} mb={6}>
          {policy.description || 'No description found for policy'}
        </Text>
        <SimpleGrid columns={2} spacing={5}>
          <Flex direction="column" spacing={2}>
            <Box
              color="navyblue-100"
              fontSize="small-medium"
              aria-describedby="runbook-description"
            >
              Runbook
            </Box>
            {policy.runbook ? (
              <Linkify id="runbook-description">{policy.runbook}</Linkify>
            ) : (
              <Box fontStyle="italic" color="navyblue-100" id="runbook-description">
                No runbook specified
              </Box>
            )}
          </Flex>
          <Flex direction="column" spacing={2}>
            <Box
              color="navyblue-100"
              fontSize="small-medium"
              aria-describedby="reference-description"
            >
              Reference
            </Box>
            {policy.reference ? (
              <Linkify id="reference-description">{policy.reference}</Linkify>
            ) : (
              <Box fontStyle="italic" color="navyblue-100" id="reference-description">
                No reference specified
              </Box>
            )}
          </Flex>
        </SimpleGrid>
      </Card>
      <Card variant="dark" as="section" p={4}>
        <Flex fontSize="small-medium" alignItems="flex-start">
          <SimpleGrid as="dl" width="50%" gap={2} columns={8} spacing={2}>
            <Box as="dt" gridColumn="1/3" color="navyblue-100" aria-describedby="created-at">
              Created
            </Box>
            <Box as="dd" gridColumn="3/8" id="created-at">
              {formatDatetime(policy.createdAt)}
            </Box>

            <Box as="dt" gridColumn="1/3" color="navyblue-100" aria-describedby="created-at">
              Created By
            </Box>
            <Box as="dd" gridColumn="3/8" id="created-by">
              {getActorDisplayName(policy.createdBy)}
            </Box>

            <Box as="dt" gridColumn="1/3" color="navyblue-100" aria-describedby="updated-at">
              Modified
            </Box>
            <Box as="dd" gridColumn="3/8" id="updated-at">
              {formatDatetime(policy.lastModified)}
            </Box>

            <Box as="dt" gridColumn="1/3" color="navyblue-100" aria-describedby="created-at">
              Modified By
            </Box>
            <Box as="dd" gridColumn="3/8" id="updated-by">
              {getActorDisplayName(policy.lastModifiedBy)}
            </Box>
          </SimpleGrid>
          <SimpleGrid as="dl" width="50%" gap={2} columns={8} spacing={2}>
            <Box as="dt" gridColumn="1/3" color="navyblue-100" aria-describedby="tags-list">
              Tags
            </Box>
            {policy.tags.length > 0 ? (
              <Box as="dd" gridColumn="3/8" id="tags-list">
                {policy.tags.map((tag, index) => (
                  <Link
                    key={tag}
                    as={RRLink}
                    to={`${urls.detections.list()}?page=1&tags[]=${tag}`}
                    data-tid="link-to-detection-tag"
                  >
                    {tag}
                    {index !== policy.tags.length - 1 ? ', ' : null}
                  </Link>
                ))}
              </Box>
            ) : (
              <Box as="dd" fontStyle="italic" color="navyblue-100" gridColumn="3/8" id="tags-list">
                This policy has no tags
              </Box>
            )}

            <Box
              as="dt"
              gridColumn="1/3"
              color="navyblue-100"
              aria-describedby="ignore-patterns-list"
            >
              Ignore Patterns
            </Box>
            {policy.suppressions.length > 0 ? (
              <Box as="dd" gridColumn="3/8" id="ignore-patterns-list">
                {policy.suppressions?.length > 0 ? policy.suppressions.join(', ') : null}
              </Box>
            ) : (
              <Box as="dd" gridColumn="3/8" id="ignore-patterns-list">
                No particular resource is ignored for this policy
              </Box>
            )}

            <Box
              as="dt"
              gridColumn="1/3"
              color="navyblue-100"
              aria-describedby="ignore-patterns-list"
            >
              Report Mappings
            </Box>
            {policy.reports.length > 0 ? (
              <Box as="dd" gridColumn="3/8" id="ignore-patterns-list">
                {policy.reports.map(({ key, values }) => (
                  <Text key={key}>
                    {key}: {values.join(', ')}
                  </Text>
                ))}
              </Box>
            ) : (
              <Box as="dd" gridColumn="3/8" id="ignore-patterns-list">
                No security reports have been mapped to this policy
              </Box>
            )}
          </SimpleGrid>
        </Flex>
      </Card>
    </Card>
  );
};

export default React.memo(PolicyDetailsInfo);
