/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Box, Flex, Link, TextInput } from 'pouncejs';
import { SAAS_ONBOARDING_SALESFORCE_DOC_URL } from 'Source/constants';
import ErrorBoundary from 'Components/ErrorBoundary';
import { Field, useFormikContext } from 'formik';
import SensitiveTextInput from 'Components/fields/SensitiveTextInput';
import { WizardPanel } from 'Components/Wizard';
import EditIntegrationActions from 'Pages/Integrations/components/EditIntegrationActions';
import { SalesforceLogsSourceWizardValues } from '../SalesforceLogsSourceWizard';

const SalesforceLogsSourceCredentialsPanel: React.FC = () => {
  const { initialValues, dirty, isValid } = useFormikContext<SalesforceLogsSourceWizardValues>();
  const editMode = !!initialValues.integrationId;
  return (
    <WizardPanel>
      <Box width={500} m="auto">
        <WizardPanel.Heading
          title="Fill in the form below with your credentials"
          subtitle={
            <React.Fragment>
              Follow{' '}
              <Link external href={SAAS_ONBOARDING_SALESFORCE_DOC_URL}>
                the steps found here
              </Link>{' '}
              to give Panther access to your Salesforce Account, and also fill in the fields below
            </React.Fragment>
          }
        />
        <ErrorBoundary>
          <Flex flexDirection="column" spacing={4}>
            <Field
              name="username"
              label="Account Username"
              as={TextInput}
              placeholder="Your Salesforce username"
            />
            <Field
              name="password"
              label="Account Password"
              shouldMask={editMode}
              as={SensitiveTextInput}
              placeholder={
                editMode
                  ? 'Information is hidden. New values will override the existing.'
                  : 'Your Salesforce account password'
              }
              required={!editMode}
            />
            <Field
              name="securityToken"
              label="Security Token"
              shouldMask={editMode}
              as={SensitiveTextInput}
              placeholder={
                editMode
                  ? 'Information is hidden. New values will override the existing.'
                  : 'Your Salesforce security token'
              }
              required={!editMode}
            />
          </Flex>
        </ErrorBoundary>
      </Box>
      {editMode ? (
        <EditIntegrationActions />
      ) : (
        <WizardPanel.Actions>
          <WizardPanel.ActionNext disabled={!dirty || !isValid}>
            Continue Setup
          </WizardPanel.ActionNext>
        </WizardPanel.Actions>
      )}
    </WizardPanel>
  );
};

export default SalesforceLogsSourceCredentialsPanel;
