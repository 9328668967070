/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Box } from 'pouncejs';
import MitreMatrixGridOverlay from './MitreMatrixGridOverlay';
import MitreMatrixGridUnderlay from './MitreMatrixGridUnderlay';

const MitreMatrixGridLayout = ({ children }) => {
  return (
    <Box position="relative" overflowX="auto" overflowY="hidden" pb={4}>
      <MitreMatrixGridUnderlay />
      <Box zIndex={1} position="relative">
        {children}
      </Box>
      <MitreMatrixGridOverlay />
    </Box>
  );
};

export default React.memo(MitreMatrixGridLayout);
