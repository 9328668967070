/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import { ReplayFull } from 'Source/graphql/fragments/ReplayFull.generated';
import React from 'react';
import { ReplayState } from 'Generated/schema';
import { Button } from 'pouncejs';

type ReplayEditorActionProps = {
  onStop: () => void;
  isValid: boolean;
  disabled: boolean;
  onCreate: () => void;
  currentReplay: ReplayFull;
};

const ReplayEditorAction: React.FC<ReplayEditorActionProps> = ({
  onStop,
  isValid,
  disabled,
  onCreate,
  currentReplay,
}) => {
  switch (currentReplay?.state) {
    case ReplayState.Unknown:
    case ReplayState.ErrorComputation:
    case ReplayState.ErrorEvaluation:
    case ReplayState.Canceled:
    case ReplayState.Done:
      return (
        <Button
          icon="play"
          onClick={onCreate}
          disabled={disabled || !isValid}
          variantColor="yellow-600"
        >
          Run Replay
        </Button>
      );
    case ReplayState.ComputationInProgress:
    case ReplayState.EvaluationInProgress:
      return (
        <Button icon="stop" onClick={onStop} disabled={disabled} variantColor="red-600">
          Stop Replay
        </Button>
      );
    default:
      return (
        <Button
          icon="play"
          onClick={onCreate}
          disabled={disabled || !isValid}
          variantColor="yellow-600"
        >
          Create Replay
        </Button>
      );
  }
};

export default ReplayEditorAction;
