/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import Breadcrumbs from 'Components/Breadcrumbs';
import Panel from 'Components/Panel';
import { Box, Flex } from 'pouncejs';
import TablePlaceholder from 'Components/TablePlaceholder';
import MitreTacticTechniqueIndicator from '../../Mitre/MitreTacticTechniqueIndicator';

type MitreMappingDetectionSkeletonProps = React.ComponentProps<
  typeof MitreTacticTechniqueIndicator
>;

const MitreMappingDetectionSkeleton = ({
  tactic,
  technique,
}: MitreMappingDetectionSkeletonProps) => {
  return (
    <>
      <Breadcrumbs.Actions>
        <Flex spacing={2}>
          <Box
            width={82}
            height={46}
            borderColor="navyblue-400"
            borderStyle="solid"
            borderWidth={1}
            borderRadius="medium"
          />
          <Box
            width={128}
            height={46}
            borderColor="navyblue-400"
            borderStyle="solid"
            borderWidth={1}
            borderRadius="medium"
          />
        </Flex>
      </Breadcrumbs.Actions>
      <Panel
        title="Map New Detections"
        actions={
          <Flex spacing={3}>
            <Box
              width={403}
              height={48}
              borderColor="navyblue-300"
              borderStyle="solid"
              borderWidth={1}
              borderRadius="medium"
            />
            <Box
              width={225}
              height={48}
              borderColor="navyblue-300"
              borderStyle="solid"
              borderWidth={1}
              borderRadius="medium"
            />
            <Box
              width={104}
              height={48}
              backgroundColor="navyblue-300"
              borderColor="navyblue-300"
              borderStyle="solid"
              borderWidth={1}
              borderRadius="medium"
            />
          </Flex>
        }
      >
        <Box backgroundColor="navyblue-500" py={6} px={8}>
          <Box pb={6} mb={8} borderBottom="1px solid" borderColor="navyblue-300">
            <MitreTacticTechniqueIndicator tactic={tactic} technique={technique} />
          </Box>
          <TablePlaceholder rowHeight={30} />
        </Box>
      </Panel>
    </>
  );
};

export default MitreMappingDetectionSkeleton;
