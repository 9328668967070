/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import * as Types from '../../../../../../../__generated__/schema';

import { DuoLogIntegrationDetails } from '../../../../../../graphql/fragments/DuoLogIntegrationDetails.generated';
import { GraphQLError } from 'graphql';
import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';

export type AddDuoLogSourceVariables = {
  input: Types.AddLogPullingIntegrationInput;
};

export type AddDuoLogSource = { addLogPullingIntegration: DuoLogIntegrationDetails };

export const AddDuoLogSourceDocument = gql`
  mutation AddDuoLogSource($input: AddLogPullingIntegrationInput!) {
    addLogPullingIntegration(input: $input) {
      ...DuoLogIntegrationDetails
    }
  }
  ${DuoLogIntegrationDetails}
`;
export type AddDuoLogSourceMutationFn = ApolloReactCommon.MutationFunction<
  AddDuoLogSource,
  AddDuoLogSourceVariables
>;

/**
 * __useAddDuoLogSource__
 *
 * To run a mutation, you first call `useAddDuoLogSource` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddDuoLogSource` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addDuoLogSource, { data, loading, error }] = useAddDuoLogSource({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddDuoLogSource(
  baseOptions?: ApolloReactHooks.MutationHookOptions<AddDuoLogSource, AddDuoLogSourceVariables>
) {
  return ApolloReactHooks.useMutation<AddDuoLogSource, AddDuoLogSourceVariables>(
    AddDuoLogSourceDocument,
    baseOptions
  );
}
export type AddDuoLogSourceHookResult = ReturnType<typeof useAddDuoLogSource>;
export type AddDuoLogSourceMutationResult = ApolloReactCommon.MutationResult<AddDuoLogSource>;
export type AddDuoLogSourceMutationOptions = ApolloReactCommon.BaseMutationOptions<
  AddDuoLogSource,
  AddDuoLogSourceVariables
>;
export function mockAddDuoLogSource({
  data,
  variables,
  errors,
}: {
  data: AddDuoLogSource;
  variables?: AddDuoLogSourceVariables;
  errors?: GraphQLError[];
}) {
  return {
    request: { query: AddDuoLogSourceDocument, variables },
    result: { data, errors },
  };
}
