/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { AbstractButton, Box, Card, Flex, Link, SimpleGrid, Text } from 'pouncejs';
import { Link as RRLink } from 'react-router-dom';
import { AlertOriginSystemError, LogIntegration, SystemErrorTypeEnum } from 'Generated/schema';
import { formatDatetime } from 'Helpers/utils';
import AlertDeliverySection from 'Pages/AlertDetails/common/AlertDeliverySection';
import RelatedDestinations from 'Components/RelatedDestinations';
import useAlertDestinations from 'Hooks/useAlertDestinations';
import useSystemErrorRelatedComponent from 'Hooks/useSystemErrorRelatedComponent';
import useModal from 'Hooks/useModal';
import SetEventThresholdAlarmModal from 'Components/modals/SetEventThresholdAlarmModal';
import { AlertDetails } from 'Pages/AlertDetails';

interface SystemErrorAlertDetailsInfoProps {
  alert: AlertDetails['alert'];
}

const SystemErrorAlertDetailsInfo: React.FC<SystemErrorAlertDetailsInfoProps> = ({ alert }) => {
  const { alertDestinations, loading: loadingDestinations } = useAlertDestinations({ alert });
  const alertOrigin = alert.origin as AlertOriginSystemError;
  const relatedComponent = useSystemErrorRelatedComponent(alertOrigin);
  const { showModal } = useModal();
  const relatedEntity = alertOrigin.relatedEntity as LogIntegration;
  // Only LogSource SystemAlerts have integrationId as for now
  const isLogSource = Boolean(relatedEntity?.integrationId);
  const isNoDataReceivedAlert =
    isLogSource && alertOrigin.type === SystemErrorTypeEnum.SourceNoData;

  return (
    <Flex direction="column" spacing={4}>
      {alert.description && (
        <Card variant="dark" as="section" p={4}>
          <Text>{alert.description}</Text>
        </Card>
      )}
      <Card variant="dark" as="section" p={4}>
        <SimpleGrid columns={2} spacing={5} fontSize="small-medium">
          <SimpleGrid gap={2} columns={8} spacing={2}>
            <Box color="navyblue-100" gridColumn="1/3" aria-describedby="related-component-link">
              Related Component
            </Box>
            <Box gridColumn="3/8">
              <Link id="related-component-link" as={RRLink} to={relatedComponent.to}>
                {relatedComponent.label}
              </Link>
              {isNoDataReceivedAlert && (
                <>
                  <Text as="span"> - </Text>
                  <AbstractButton
                    fontSize="medium"
                    color="blue-200"
                    _hover={{ color: 'blue-100' }}
                    onClick={() => {
                      return showModal(<SetEventThresholdAlarmModal logSource={relatedEntity} />, {
                        title: 'Configure Event Threshold Alarm',
                        showCloseButton: true,
                      });
                    }}
                  >
                    Configure Event
                  </AbstractButton>
                </>
              )}
            </Box>

            <Box color="navyblue-100" gridColumn="1/3" aria-describedby="created-at">
              Created
            </Box>
            <Box id="created-at" gridColumn="3/8">
              {formatDatetime(alert.createdAt)}
            </Box>
          </SimpleGrid>
          <SimpleGrid gap={2} columns={8} spacing={2}>
            <Box color="navyblue-100" gridColumn="1/3" aria-describedby="destinations">
              Destinations
            </Box>
            <Box id="destinations" gridColumn="3/8">
              <RelatedDestinations
                destinations={alertDestinations}
                loading={loadingDestinations}
                limit={5}
                verbose
              />
            </Box>
          </SimpleGrid>
        </SimpleGrid>
      </Card>
      <Card variant="dark" as="section" p={4}>
        <AlertDeliverySection alert={alert} />
      </Card>
    </Flex>
  );
};

export default SystemErrorAlertDetailsInfo;
