/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { useSnackbar } from 'pouncejs';
import Page404 from 'Pages/404';
import withSEO from 'Hoc/withSEO';
import useRouter from 'Hooks/useRouter';
import withRoleRestrictedAccess from 'Hoc/withRoleRestrictedAccess';
import { compose } from 'Helpers/compose';
import {
  ZendeskLogSourceApiConfig,
  ZendeskLogSourceBasicConfig,
  ZendeskLogSourceOAuth2Config,
  ZendeskAuthorizationMethodEnum,
  Permission,
} from 'Generated/schema';
import Page403 from 'Pages/403';
import { extractErrorMessage } from 'Helpers/utils';
import useAvailableLogTypesForLogPuller from 'Hooks/useAvailableLogTypesForLogPuller';
import ZendeskLogsSourceWizard, { ZendeskLogsSourceWizardValues } from '../ZendeskLogsSourceWizard';
import { useGetZendeskbLogSource } from './graphql/getZendeskLogSource.generated';
import { useUpdateZendeskLogSource } from './graphql/updateZendeskLogSource.generated';

const EditZendeskLogSource: React.FC = () => {
  const { pushSnackbar } = useSnackbar();
  const { defaultLogTypes } = useAvailableLogTypesForLogPuller();
  const { match } = useRouter<{ id: string }>();
  const { data, error: getError } = useGetZendeskbLogSource({
    variables: { id: match.params.id },
    onError: error => {
      pushSnackbar({
        title: extractErrorMessage(error) || 'An unknown error occurred',
        variant: 'error',
      });
    },
  });

  const [updateZendeskLogSource] = useUpdateZendeskLogSource();

  const initialValues = React.useMemo<ZendeskLogsSourceWizardValues>(
    () => ({
      integrationId: match.params.id,
      integrationLabel: data?.getLogPullingIntegration.integrationLabel ?? 'Loading...',
      subdomain: data?.getLogPullingIntegration.pullerConfig.zendesk.subdomain ?? 'Loading...',
      authorizationMethod:
        data?.getLogPullingIntegration.pullerConfig.zendesk.authorizationMethod ?? null,
      clientId:
        (data?.getLogPullingIntegration.pullerConfig.zendesk
          .credentials as ZendeskLogSourceOAuth2Config)?.clientId ?? '',
      clientSecret:
        (data?.getLogPullingIntegration.pullerConfig.zendesk
          .credentials as ZendeskLogSourceOAuth2Config)?.clientSecret ?? '',
      email:
        (data?.getLogPullingIntegration.pullerConfig.zendesk
          .credentials as ZendeskLogSourceBasicConfig)?.email ?? '',
      password:
        (data?.getLogPullingIntegration.pullerConfig.zendesk
          .credentials as ZendeskLogSourceBasicConfig)?.password ?? '',
      apiToken:
        (data?.getLogPullingIntegration.pullerConfig.zendesk
          .credentials as ZendeskLogSourceApiConfig)?.apiToken ?? '',
    }),
    [data, match.params.id]
  );

  const handleSubmit = React.useCallback(
    async (values: ZendeskLogsSourceWizardValues) => {
      try {
        const resp = await updateZendeskLogSource({
          variables: {
            input: {
              integrationId: values.integrationId,
              integrationLabel: values.integrationLabel,
              pullerConfig: {
                logTypes: defaultLogTypes,
                zendesk: {
                  subdomain: values.subdomain,
                  ...(values.authorizationMethod === ZendeskAuthorizationMethodEnum.Basic && {
                    basicCredentials: {
                      email: values.email,
                      password: values.password,
                    },
                  }),
                  ...(values.authorizationMethod === ZendeskAuthorizationMethodEnum.Oauth2 && {
                    oauth2Credentials: {
                      clientId: values.clientId,
                      clientSecret: values.clientSecret,
                    },
                  }),
                  ...(values.authorizationMethod === ZendeskAuthorizationMethodEnum.ApiToken && {
                    apiCredentials: {
                      email: values.email,
                      apiToken: values.apiToken,
                    },
                  }),
                  authorizationMethod: values.authorizationMethod,
                },
              },
            },
          },
        });
        return resp.data.updateLogPullingIntegration;
      } catch (err) {
        return err as Error;
      }
    },
    [updateZendeskLogSource, defaultLogTypes]
  );

  // we optimistically assume that an error in "get" is a 404. We don't have any other info
  if (getError) {
    return <Page404 />;
  }

  return <ZendeskLogsSourceWizard initialValues={initialValues} onSubmit={handleSubmit} />;
};

export default compose(
  withSEO({ title: 'Edit Zendesk Source' }),
  withRoleRestrictedAccess({
    allowedPermissions: [Permission.LogSourceModify],
    fallback: <Page403 />,
  })
)(EditZendeskLogSource);
