/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import * as Types from '../../../../../../../__generated__/schema';

import { GraphQLError } from 'graphql';
import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';

export type GetComplianceCfnTemplateVariables = {
  input: Types.GetComplianceIntegrationTemplateInput;
};

export type GetComplianceCfnTemplate = {
  getComplianceIntegrationTemplate: Pick<Types.IntegrationTemplate, 'body' | 'stackName'>;
};

export const GetComplianceCfnTemplateDocument = gql`
  query GetComplianceCfnTemplate($input: GetComplianceIntegrationTemplateInput!) {
    getComplianceIntegrationTemplate(input: $input) {
      body
      stackName
    }
  }
`;

/**
 * __useGetComplianceCfnTemplate__
 *
 * To run a query within a React component, call `useGetComplianceCfnTemplate` and pass it any options that fit your needs.
 * When your component renders, `useGetComplianceCfnTemplate` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetComplianceCfnTemplate({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetComplianceCfnTemplate(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetComplianceCfnTemplate,
    GetComplianceCfnTemplateVariables
  >
) {
  return ApolloReactHooks.useQuery<GetComplianceCfnTemplate, GetComplianceCfnTemplateVariables>(
    GetComplianceCfnTemplateDocument,
    baseOptions
  );
}
export function useGetComplianceCfnTemplateLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetComplianceCfnTemplate,
    GetComplianceCfnTemplateVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<GetComplianceCfnTemplate, GetComplianceCfnTemplateVariables>(
    GetComplianceCfnTemplateDocument,
    baseOptions
  );
}
export type GetComplianceCfnTemplateHookResult = ReturnType<typeof useGetComplianceCfnTemplate>;
export type GetComplianceCfnTemplateLazyQueryHookResult = ReturnType<
  typeof useGetComplianceCfnTemplateLazyQuery
>;
export type GetComplianceCfnTemplateQueryResult = ApolloReactCommon.QueryResult<
  GetComplianceCfnTemplate,
  GetComplianceCfnTemplateVariables
>;
export function mockGetComplianceCfnTemplate({
  data,
  variables,
  errors,
}: {
  data: GetComplianceCfnTemplate;
  variables?: GetComplianceCfnTemplateVariables;
  errors?: GraphQLError[];
}) {
  return {
    request: { query: GetComplianceCfnTemplateDocument, variables },
    result: { data, errors },
  };
}
