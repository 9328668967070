/* eslint-disable no-nested-ternary */
/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import {
  AWS_ACCOUNT_ID_REGEX,
  AWS_REGIONS,
  RESOURCE_TYPES,
  ROLE_ARN_REGEX,
} from 'Source/constants';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { Wizard } from 'Components/Wizard';
import { yupIntegrationLabelValidation } from 'Helpers/utils';
import CancelCloudAcountAction from 'Pages/Integrations/components/CancelCloudAcountAction';
import SelectIAMRoleCreationMethodPanel, {
  IAMRoleCreationMethods,
} from 'Components/wizards/common/SelectIAMRoleCreationMethodPanel';
import { ComplianceIntegrationDetails } from 'Source/graphql/fragments/ComplianceIntegrationDetails.generated';
import ValidationPanel from './ValidationPanel';
import SourceConfigurationPanel from './SourceConfigurationPanel';
import { CustomMethod, ConsoleMethod, TemplateMethod } from './IamRoleCreationMethods';

interface ComplianceSourceWizardProps {
  initialValues: ComplianceSourceWizardValues;
  onSubmit: (values: ComplianceSourceWizardValues) => Promise<ComplianceIntegrationDetails | Error>;
}

export interface ComplianceSourceWizardValues {
  integrationId?: string;
  awsAccountId: string;
  integrationLabel: string;
  regionIgnoreList: string[];
  resourceTypeIgnoreList: string[];
  resourceRegexIgnoreList: string[];
  iamRoleExists?: boolean;
  auditRole: string;
}

const methodComponents: Record<IAMRoleCreationMethods, React.ElementType> = {
  console: ConsoleMethod,
  custom: CustomMethod,
  template: TemplateMethod,
};

const validationSchema: Yup.SchemaOf<ComplianceSourceWizardValues> = Yup.object().shape({
  integrationId: Yup.string(),
  iamRoleExists: Yup.boolean(),
  integrationLabel: yupIntegrationLabelValidation,
  awsAccountId: Yup.string()
    .matches(AWS_ACCOUNT_ID_REGEX, 'Must be a valid AWS Account ID')
    .required(),
  regionIgnoreList: Yup.array().of(
    Yup.string()
      .oneOf((AWS_REGIONS as unknown) as string[])
      .required()
  ),
  resourceTypeIgnoreList: Yup.array().of(
    Yup.string()
      .oneOf((RESOURCE_TYPES as unknown) as string[])
      .required()
  ),
  resourceRegexIgnoreList: Yup.array().of(Yup.string().required()),
  auditRole: Yup.string()
    .matches(ROLE_ARN_REGEX, 'Must be a valid IAM ARN i.e. arn:aws:iam::ACCOUNT_ID:role/ROLENAME')
    .required('Please fill in the Role ARN to continue with the source setup'),
});

const ComplianceSourceWizard = ({ initialValues, onSubmit }: ComplianceSourceWizardProps) => {
  const [selectedMethod, setSelectedMethod] = React.useState<IAMRoleCreationMethods>('template');
  const SelectedMethod = methodComponents[selectedMethod] || TemplateMethod;
  const editMode = !!initialValues.integrationId;

  return (
    <Formik<ComplianceSourceWizardValues>
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      <Form>
        <Wizard headerTitle="Connecting a new Cloud Account" enableAllNavigationSteps={editMode}>
          <Wizard.Step
            title="Configure"
            description="Provide account information and set preferences to connect the cloud account to Panther"
          >
            <SourceConfigurationPanel />
          </Wizard.Step>

          <Wizard.StepGroup
            title={editMode ? 'Edit IAM Role' : 'Setup IAM Role'}
            description={
              editMode
                ? 'Edit the IAM role in your account to give Panther the right permissions to receive data from the source'
                : 'Create or use an existing IAM role in your account to give Panther the right permissions to receive data from the source'
            }
          >
            <Wizard.Step title="Choose Method">
              <SelectIAMRoleCreationMethodPanel onSelectMethod={setSelectedMethod} />
            </Wizard.Step>
            <Wizard.Step title={editMode ? 'Edit Role' : 'Finalize Role Setup'}>
              <SelectedMethod />
            </Wizard.Step>
          </Wizard.StepGroup>

          <Wizard.Step
            title="Setup Verification"
            description="Confirm that your cloud account has been successfully onboarded or troubleshoot any issues"
            navigationDisabled={editMode}
          >
            <ValidationPanel />
          </Wizard.Step>
        </Wizard>
        {!editMode && <CancelCloudAcountAction />}
      </Form>
    </Formik>
  );
};

export default ComplianceSourceWizard;
