/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import * as Types from '../../../../../../__generated__/schema';

import { GraphQLError } from 'graphql';
import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';

export type GenerateLookupImportUrlVariables = {
  input: Types.LookupS3ImportConfigInput;
};

export type GenerateLookupImportUrl = {
  generateLookupImportUrl?: Types.Maybe<Pick<Types.LookupS3ImportConfig, 'url'>>;
};

export const GenerateLookupImportUrlDocument = gql`
  mutation GenerateLookupImportUrl($input: LookupS3ImportConfigInput!) {
    generateLookupImportUrl(input: $input) {
      url
    }
  }
`;
export type GenerateLookupImportUrlMutationFn = ApolloReactCommon.MutationFunction<
  GenerateLookupImportUrl,
  GenerateLookupImportUrlVariables
>;

/**
 * __useGenerateLookupImportUrl__
 *
 * To run a mutation, you first call `useGenerateLookupImportUrl` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateLookupImportUrl` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateLookupImportUrl, { data, loading, error }] = useGenerateLookupImportUrl({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGenerateLookupImportUrl(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    GenerateLookupImportUrl,
    GenerateLookupImportUrlVariables
  >
) {
  return ApolloReactHooks.useMutation<GenerateLookupImportUrl, GenerateLookupImportUrlVariables>(
    GenerateLookupImportUrlDocument,
    baseOptions
  );
}
export type GenerateLookupImportUrlHookResult = ReturnType<typeof useGenerateLookupImportUrl>;
export type GenerateLookupImportUrlMutationResult = ApolloReactCommon.MutationResult<
  GenerateLookupImportUrl
>;
export type GenerateLookupImportUrlMutationOptions = ApolloReactCommon.BaseMutationOptions<
  GenerateLookupImportUrl,
  GenerateLookupImportUrlVariables
>;
export function mockGenerateLookupImportUrl({
  data,
  variables,
  errors,
}: {
  data: GenerateLookupImportUrl;
  variables?: GenerateLookupImportUrlVariables;
  errors?: GraphQLError[];
}) {
  return {
    request: { query: GenerateLookupImportUrlDocument, variables },
    result: { data, errors },
  };
}
