/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Dropdown, DropdownButton, DropdownItem, DropdownLink, DropdownMenu } from 'pouncejs';
import querystring from 'querystring';
import { Link as RRLink } from 'react-router-dom';
import omit from 'lodash/omit';
import OptionsButton from 'Components/buttons/OptionsButton';
import RoleRestrictedAccess from 'Components/utils/RoleRestrictedAccess';
import { Permission } from 'Generated/schema';
import useModal from 'Hooks/useModal';
import useCheckPermissions from 'Hooks/useCheckPermissions';
import { LookupDetails } from 'Source/graphql/fragments/LookupDetails.generated';
import { useUpdateLookup } from 'Source/graphql/queries/updateLookup.generated';
import urls from 'Source/urls';
import DeleteLookupModal from './DeleteLookupModal';

interface LookupCardOptionsProps {
  lookup: LookupDetails;
}

const LookupCardOptions: React.FC<LookupCardOptionsProps> = ({ lookup }) => {
  const [updateLookup] = useUpdateLookup();
  const { showModal } = useModal();
  const updateLookupCallback = () =>
    updateLookup({
      variables: {
        input: {
          id: lookup.id,
          name: lookup.name,
          description: lookup.description,
          reference: lookup.reference,
          enabled: !lookup.enabled,
          logTypeMap: {
            primaryKey: lookup.activeVersion.primaryKey,
            associatedLogTypes: lookup.activeVersion.associatedLogTypes.map(logType =>
              omit(logType, '__typename')
            ),
          },
          lookupSchema: {
            logType: lookup.activeVersion.logType,
          },
          refresh: lookup.refresh ? omit(lookup.refresh, '__typename') : null,
        },
      },
    });

  const hasRows = lookup.activeVersion?.rowCount > 0;

  const hasReadPermissions = useCheckPermissions(Permission.LookupRead);
  const hasModifyPermissions = useCheckPermissions(Permission.LookupModify);

  const isReadOnlyPermissions = hasReadPermissions && !hasModifyPermissions;

  // The only item available to read-only users is "View in Data Explorer". However,
  // this option is only visible if the lookup has rows. If the option doesn't exist,
  // hide the entire dropdown from read-only users.
  if (isReadOnlyPermissions && !hasRows) {
    return null;
  }

  return (
    <Dropdown>
      <DropdownButton as={OptionsButton} data-tid="lookup-options-dropdown" />

      <DropdownMenu>
        {/**
         * If there aren't any rows in the lookup table (for example, user has not
         * finished setting it up or the lookup table is disabled) don't even provide
         * the option to view it in data explorer since generating the SQL would
         * error.
         */}
        {hasRows && (
          <RoleRestrictedAccess
            allowedPermissions={[Permission.LookupRead, Permission.LookupModify]}
          >
            <DropdownItem
              data-tid="view-lookup-in-data-explorer"
              as={RRLink}
              to={{
                pathname: urls.data.dataExplorer(),
                search: querystring.stringify({
                  snippedId: 'lookupTable',
                  lookupId: lookup.id,
                }),
              }}
            >
              View In Data Explorer
            </DropdownItem>
          </RoleRestrictedAccess>
        )}

        <RoleRestrictedAccess allowedPermissions={[Permission.LookupModify]}>
          {!lookup.managed && (
            <DropdownLink as={RRLink} to={urls.enrichment.lookupTables.edit(lookup.id)}>
              Edit Lookup Table
            </DropdownLink>
          )}

          <DropdownItem data-tid="disable-lookup" onSelect={updateLookupCallback}>
            {lookup.enabled ? 'Disable' : 'Enable'} Lookup Table
          </DropdownItem>

          {!lookup.managed && (
            <DropdownItem
              data-tid="delete-lookup"
              onSelect={() =>
                showModal(<DeleteLookupModal id={lookup.id} />, {
                  title: 'Delete Lookup Table',
                })
              }
            >
              Delete Lookup Table
            </DropdownItem>
          )}
        </RoleRestrictedAccess>
      </DropdownMenu>
    </Dropdown>
  );
};

export default React.memo(LookupCardOptions);
