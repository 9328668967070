/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import * as Types from '../../../../../../__generated__/schema';

import { WorkdayLogIntegrationDetails } from '../../../../../graphql/fragments/WorkdayLogIntegrationDetails.generated';
import { GraphQLError } from 'graphql';
import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';

export type AddWorkdayLogSourceVariables = {
  input: Types.AddLogPullingIntegrationInput;
};

export type AddWorkdayLogSource = { addLogPullingIntegration: WorkdayLogIntegrationDetails };

export const AddWorkdayLogSourceDocument = gql`
  mutation AddWorkdayLogSource($input: AddLogPullingIntegrationInput!) {
    addLogPullingIntegration(input: $input) {
      ...WorkdayLogIntegrationDetails
    }
  }
  ${WorkdayLogIntegrationDetails}
`;
export type AddWorkdayLogSourceMutationFn = ApolloReactCommon.MutationFunction<
  AddWorkdayLogSource,
  AddWorkdayLogSourceVariables
>;

/**
 * __useAddWorkdayLogSource__
 *
 * To run a mutation, you first call `useAddWorkdayLogSource` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddWorkdayLogSource` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addWorkdayLogSource, { data, loading, error }] = useAddWorkdayLogSource({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddWorkdayLogSource(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    AddWorkdayLogSource,
    AddWorkdayLogSourceVariables
  >
) {
  return ApolloReactHooks.useMutation<AddWorkdayLogSource, AddWorkdayLogSourceVariables>(
    AddWorkdayLogSourceDocument,
    baseOptions
  );
}
export type AddWorkdayLogSourceHookResult = ReturnType<typeof useAddWorkdayLogSource>;
export type AddWorkdayLogSourceMutationResult = ApolloReactCommon.MutationResult<
  AddWorkdayLogSource
>;
export type AddWorkdayLogSourceMutationOptions = ApolloReactCommon.BaseMutationOptions<
  AddWorkdayLogSource,
  AddWorkdayLogSourceVariables
>;
export function mockAddWorkdayLogSource({
  data,
  variables,
  errors,
}: {
  data: AddWorkdayLogSource;
  variables?: AddWorkdayLogSourceVariables;
  errors?: GraphQLError[];
}) {
  return {
    request: { query: AddWorkdayLogSourceDocument, variables },
    result: { data, errors },
  };
}
