/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Link as RRLink } from 'react-router-dom';
import { Box, Card, Flex, Icon, Img, Link, Tooltip } from 'pouncejs';
import { Permission } from 'Generated/schema';
import { ComplianceIntegrationDetails } from 'Source/graphql/fragments/ComplianceIntegrationDetails.generated';
import { formatDatetime } from 'Helpers/utils';
import urls from 'Source/urls';
import logo from 'Assets/aws-minimal-logo.svg';
import SourceHealthWarning from 'Components/SourceHealthWarning';
import RoleRestrictedAccess from 'Components/utils/RoleRestrictedAccess';
import DisabledLink from 'Components/DisabledLink';
import { CardMetaValue, CardValue } from 'Components/cards/common';
import ComplianceSourceCardOptions from './ComplianceSourceCardOptions';

interface ComplianceSourceCardProps {
  source: ComplianceIntegrationDetails;
}

const ComplianceSourceCard: React.FC<ComplianceSourceCardProps> = ({ source }) => {
  const unhealthyMetrics = React.useMemo(
    () => [source.health.auditRoleStatus, source.health.realtimeStatus].filter(s => !s.healthy),
    [source]
  );
  const isHealthy = unhealthyMetrics.length === 0;

  return (
    <Card as="section" variant="dark" p={4}>
      <Flex position="relative">
        <Img nativeWidth={20} nativeHeight={20} mr={5} alt="Logo" src={logo} />
        <Flex direction="column" justify="space-between" width={1}>
          <Flex as="header" align="flex-start" mb={2}>
            <Box as="h4" fontWeight="medium" mr="auto" maxWidth="60%" wordBreak="break-word">
              {source.isEditable ? (
                <RoleRestrictedAccess
                  allowedPermissions={[Permission.CloudsecSourceModify]}
                  fallback={<DisabledLink>{source.integrationLabel}</DisabledLink>}
                >
                  <Link
                    data-testid="compliance-source-card-label"
                    as={RRLink}
                    to={urls.integrations.cloudAccounts.edit(source.integrationId)}
                  >
                    {source.integrationLabel}
                  </Link>
                </RoleRestrictedAccess>
              ) : (
                <Tooltip content="This is a compliance source we created for you.">
                  <DisabledLink>{source.integrationLabel}</DisabledLink>
                </Tooltip>
              )}
            </Box>
            {source.realtimeScanningEnabled ? (
              <Flex align="center">
                <Icon type="check-circle" color="blue-500" size="small" mr={2} />
                <CardMetaValue value="Real Time Scanning Enabled" />
              </Flex>
            ) : (
              <Flex align="center">
                <Icon type="close-circle" color="pink-700" size="small" mr={2} />
                <CardMetaValue value="Real Time Scanning Not Enabled" />
              </Flex>
            )}
            {source.isEditable && (
              <RoleRestrictedAccess allowedPermissions={[Permission.CloudsecSourceModify]}>
                <Box mt={-1} ml={2}>
                  <ComplianceSourceCardOptions source={source} />
                </Box>
              </RoleRestrictedAccess>
            )}
          </Flex>
          <Flex direction="column" spacing={2} mr={6}>
            {!isHealthy && (
              <SourceHealthWarning
                unhealthyMetrics={unhealthyMetrics}
                title="Account has turned Unhealthy"
              />
            )}
            <Card variant="darker" p={4}>
              <Flex wrap="wrap" spacing={8}>
                <CardValue label="AWS Account ID" value={source.awsAccountId} />
                <CardValue label="AWS Stack Name" value={source.stackName} />
                <CardValue label="Created" value={formatDatetime(source.createdAtTime)} />
              </Flex>
            </Card>
          </Flex>
        </Flex>
      </Flex>
    </Card>
  );
};

export default ComplianceSourceCard;
