/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import { Field, Form, Formik } from 'formik';
import React from 'react';
import * as Yup from 'yup';
import SubmitButton from 'Components/buttons/SubmitButton';
import FormikTextInput from 'Components/fields/TextInput';
import useAuth from 'Hooks/useAuth';
import { Box } from 'pouncejs';

interface MfaFormValues {
  mfaCode: string;
}

const initialValues = {
  mfaCode: '',
};

const validationSchema = Yup.object().shape({
  mfaCode: Yup.string()
    .matches(/\b\d{6}\b/, 'Code should contain exactly six digits.')
    .required(),
});

const MfaForm: React.FC = () => {
  const { confirmSignIn } = useAuth();

  return (
    <Formik<MfaFormValues>
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={async ({ mfaCode }, { setErrors }) =>
        confirmSignIn({
          mfaCode,
          onError: ({ message }) =>
            setErrors({
              mfaCode: message,
            }),
        })
      }
    >
      <Form>
        <Box mb={4}>
          <Field
            autoFocus
            as={FormikTextInput}
            maxLength="6"
            placeholder="The 6-digit MFA code"
            name="mfaCode"
            label="Code"
            autoComplete="off"
            required
          />
        </Box>

        <SubmitButton fullWidth>Sign in</SubmitButton>
      </Form>
    </Formik>
  );
};

export default MfaForm;
