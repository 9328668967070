/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Box, Card, Flex, Link, Switch, Text, useSnackbar } from 'pouncejs';
import { Link as RRLink } from 'react-router-dom';

import urls from 'Source/urls';
import UpdateVersion, { UpdateVersionFormValues } from 'Components/cards/PackCard/UpdateVersion';
import { useUpdateAnalysisPack } from 'Source/graphql/queries';
import { extractErrorMessage, toPlural } from 'Helpers/utils';
import BulletedLoading from 'Components/BulletedLoading';
import { ENTITIES_COLOR_MAP } from 'Source/constants';
import FlatBadge from 'Components/badges/FlatBadge';
import CardValue from 'Components/cards/common/CardValue';
import { AnalysisPackDetails } from 'Source/graphql/fragments/AnalysisPackDetails.generated';
import { DetectionTypeEnum } from 'Generated/schema';
import useModal from 'Hooks/useModal';
import ConfirmModal from 'Components/modals/ConfirmModal';

interface PackCardProps {
  pack: AnalysisPackDetails;
}

const PackCard: React.FC<PackCardProps> = ({ pack }) => {
  const { pushSnackbar } = useSnackbar();
  const { showModal } = useModal();

  const [updatePack, { loading }] = useUpdateAnalysisPack({
    onCompleted: data => {
      pushSnackbar({
        variant: 'success',
        title: `Updated Pack [${data.updateAnalysisPack.id}] successfully`,
      });
    },
    onError: error => {
      pushSnackbar({
        variant: 'error',
        title: `Failed to update Pack`,
        description: extractErrorMessage(error),
      });
    },
  });

  const onPatch = (values: UpdateVersionFormValues) => {
    return updatePack({
      variables: {
        input: {
          id: pack.id,
          versionId: values.packVersion.id,
        },
      },
    });
  };

  const onEnablePackConfirm = () => {
    return updatePack({
      variables: {
        input: {
          id: pack.id,
          enabled: true,
        },
      },
    });
  };

  const onStatusUpdate = () => {
    if (pack.enabled) {
      return updatePack({
        variables: {
          input: {
            id: pack.id,
            enabled: false,
          },
        },
      });
    }
    return showModal(
      <ConfirmModal
        subtitle={
          'Enabling this detection pack may affect existing custom detections created with CI/CD.'
        }
        onConfirm={onEnablePackConfirm}
      />,
      {
        title: 'Enable Detection Pack?',
      }
    );
  };

  return (
    <Card as="section" variant="dark" position="relative" data-testid={pack.id}>
      {loading && (
        <Flex
          position="absolute"
          direction="column"
          spacing={2}
          backgroundColor="navyblue-700"
          height="100%"
          zIndex={2}
          alignItems="center"
          opacity={0.9}
          justify="center"
          width={1}
        >
          <Text textAlign="center" opacity={1} fontWeight="bold">
            {pack.displayName || pack.id}
          </Text>
          <Text textAlign="center" opacity={1}>
            is being updated, please wait.
          </Text>
          <BulletedLoading />
        </Flex>
      )}
      <Flex position="relative" height="100%" p={4}>
        <Flex direction="column" justify="space-between" width={1}>
          <Flex as="header" align="flex-start" mb={2}>
            <Box as="h4" fontWeight="medium" mr="auto" maxWidth="80%" wordBreak="break-word">
              <Link
                as={RRLink}
                aria-label="Link to Pack"
                to={urls.analysis.packs.details(pack.id)}
                data-tid="link-to-pack-details"
              >
                {pack.displayName || pack.id}
              </Link>
            </Box>
            <Flex spacing={2} fontSize="small" alignItems="center">
              {pack.updateAvailable && (
                <Box
                  as="span"
                  backgroundColor={pack.enabled ? 'indigo-600' : 'gray-500'}
                  borderRadius="small"
                  px={2}
                  py={1}
                  fontWeight="bold"
                >
                  UPDATE AVAILABLE
                </Box>
              )}
            </Flex>
          </Flex>
          <Flex spacing={2}>
            {pack.packTypes.RULE && (
              <FlatBadge color={ENTITIES_COLOR_MAP[DetectionTypeEnum.Rule]}>
                {pack.packTypes.RULE} {toPlural('RULE', pack.packTypes.RULE)}
              </FlatBadge>
            )}
            {pack.packTypes.POLICY && (
              <FlatBadge color={ENTITIES_COLOR_MAP[DetectionTypeEnum.Policy]}>
                {pack.packTypes.POLICY} {toPlural('POLICY', 'POLICIES', pack.packTypes.POLICY)}
              </FlatBadge>
            )}
            {pack.packTypes.GLOBAL && (
              <FlatBadge color={ENTITIES_COLOR_MAP.GLOBAL}>
                {pack.packTypes.GLOBAL} {toPlural('HELPER', pack.packTypes.GLOBAL)}
              </FlatBadge>
            )}
            {pack.packTypes.DATAMODEL && (
              <FlatBadge color={ENTITIES_COLOR_MAP.DATA_MODEL}>
                {pack.packTypes.DATAMODEL} {toPlural('DATA MODEL', pack.packTypes.DATAMODEL)}
              </FlatBadge>
            )}
            {pack.packTypes.SCHEDULED_QUERY && (
              <FlatBadge color={ENTITIES_COLOR_MAP.SAVED_QUERY}>
                {pack.packTypes.SCHEDULED_QUERY}{' '}
                {toPlural('SAVED QUERY', 'SAVED QUERIES', pack.packTypes.DATAMODEL)}
              </FlatBadge>
            )}
            {pack.packTypes.SCHEDULED_RULE && (
              <FlatBadge color={ENTITIES_COLOR_MAP.SCHEDULED_RULE}>
                {pack.packTypes.SCHEDULED_RULE}{' '}
                {toPlural('SCHEDULED RULE', pack.packTypes.SCHEDULED_RULE)}
              </FlatBadge>
            )}
          </Flex>
          <Flex width={1} mt={3}>
            <Box width={0.6}>
              <CardValue label="Pack Description" value={pack.description} />
            </Box>
            <Box width="250px">
              <UpdateVersion pack={pack} onPatch={onPatch} />
            </Box>
            <Flex ml="auto" mr={0} align="flex-end">
              <Switch
                onChange={onStatusUpdate}
                label="Enabled"
                checked={pack.enabled}
                data-tid="pack-enabled-switch"
                data-testid="pack-enabled-switch"
              />
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Card>
  );
};

export default React.memo(PackCard);
