/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Card, Box } from 'pouncejs';
import Panel from 'Components/Panel';
import TablePlaceholder from 'Components/TablePlaceholder';

const SkeletonPage = () => {
  return (
    <Panel title={<SkeletonTitle />} aria-label="Loading page...">
      <SkeletonPageContent />
    </Panel>
  );
};

const SkeletonPageContent = () => {
  return (
    <>
      <Box pb={4}>
        <Card variant="dark" mb={2}>
          <Box height="133px" />
        </Card>
        <Card variant="dark" mb={2}>
          <Box height="400px" />
        </Card>
      </Box>
    </>
  );
};

const SkeletonTitle = () => {
  return (
    <Box height="44px" width="240px">
      <TablePlaceholder rowCount={1} rowHeight={48} rowGap={0} />
    </Box>
  );
};

export default {
  Page: SkeletonPage,
  PageContent: SkeletonPageContent,
};
