/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { AbstractButton } from 'pouncejs';

interface ResetButtonProps {
  onReset: () => void;
}

const ResetButton: React.FC<ResetButtonProps> = ({ onReset, ...rest }) => {
  return (
    <AbstractButton
      {...rest}
      borderRadius="pill"
      py={1}
      px={4}
      fontSize="small"
      color="navyblue-500"
      fontWeight="bold"
      backgroundColor="navyblue-200"
      _hover={{ backgroundColor: 'blue-400' }}
      onClick={onReset}
    >
      RESET
    </AbstractButton>
  );
};

export default React.memo(ResetButton);
