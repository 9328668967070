/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import * as Types from '../../../../../../../__generated__/schema';

import { HoldingTankTestJobDetails } from '../../../../../../graphql/fragments/HoldingTankTestJobDetails.generated';
import { HoldingTankInferJobDetails } from '../../../../../../graphql/fragments/HoldingTankInferJobDetails.generated';
import { GraphQLError } from 'graphql';
import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';

export type CancelHoldingTankJobVariables = {
  id: Types.Scalars['String'];
};

export type CancelHoldingTankJob = {
  cancelHoldingTankJob: HoldingTankTestJobDetails | HoldingTankInferJobDetails;
};

export const CancelHoldingTankJobDocument = gql`
  mutation CancelHoldingTankJob($id: String!) {
    cancelHoldingTankJob(id: $id) {
      ... on HoldingTankTestJob {
        ...HoldingTankTestJobDetails
      }
      ... on HoldingTankInferJob {
        ...HoldingTankInferJobDetails
      }
    }
  }
  ${HoldingTankTestJobDetails}
  ${HoldingTankInferJobDetails}
`;
export type CancelHoldingTankJobMutationFn = ApolloReactCommon.MutationFunction<
  CancelHoldingTankJob,
  CancelHoldingTankJobVariables
>;

/**
 * __useCancelHoldingTankJob__
 *
 * To run a mutation, you first call `useCancelHoldingTankJob` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelHoldingTankJob` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelHoldingTankJob, { data, loading, error }] = useCancelHoldingTankJob({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCancelHoldingTankJob(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CancelHoldingTankJob,
    CancelHoldingTankJobVariables
  >
) {
  return ApolloReactHooks.useMutation<CancelHoldingTankJob, CancelHoldingTankJobVariables>(
    CancelHoldingTankJobDocument,
    baseOptions
  );
}
export type CancelHoldingTankJobHookResult = ReturnType<typeof useCancelHoldingTankJob>;
export type CancelHoldingTankJobMutationResult = ApolloReactCommon.MutationResult<
  CancelHoldingTankJob
>;
export type CancelHoldingTankJobMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CancelHoldingTankJob,
  CancelHoldingTankJobVariables
>;
export function mockCancelHoldingTankJob({
  data,
  variables,
  errors,
}: {
  data: CancelHoldingTankJob;
  variables?: CancelHoldingTankJobVariables;
  errors?: GraphQLError[];
}) {
  return {
    request: { query: CancelHoldingTankJobDocument, variables },
    result: { data, errors },
  };
}
