/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import {
  Box,
  AbstractButton,
  Text,
  Flex,
  IconButton,
  Button,
  Popover,
  PopoverTrigger,
  PopoverContent,
  Card,
  Tooltip,
  IconButtonProps,
} from 'pouncejs';
import React from 'react';
import { useDataExplorerContext } from 'Pages/DataExplorer/DataExplorerContext';
import useRouter from 'Hooks/useRouter';
import urls from 'Source/urls';
import queryString from 'query-string';

interface TableListItemProps {
  name: string;
  onClick: (name: string) => void;
}

/**
 * Allows a user to quickly preview the contents of a SQL table.
 *
 * - If there is no SQL in the editor, will populate the editor with the query ran
 * - If there is SQL in the editor, will first prompt the user and then will
 *   replace their current SQL
 */
const PreviewTable = ({ name }: { name: string }) => {
  const {
    state: { sql, selectedDatabase },
    dispatch,
  } = useDataExplorerContext();
  const { history } = useRouter();

  const previewTable = React.useCallback(() => {
    dispatch({ type: 'UPDATE_SQL', payload: { sql: '' } });
    history.push({
      pathname: urls.data.dataExplorer(),
      search: queryString.stringify({
        databaseName: selectedDatabase,
        tableName: name,
        snippedId: 'previewTable',
        execute: true,
      }),
      // Without this the app can get into a state where the query id is carried over
      // and a new query is not triggered. This forces the AsyncQueryContext to remove
      // the query id that was previously in the history state.
      state: {
        queryId: null,
        globalErrorMessage: '',
        queryStatus: null,
      },
    });
  }, [dispatch, history, selectedDatabase, name]);

  const iconButtonProps: IconButtonProps = React.useMemo(
    () => ({
      'aria-label': `Preview ${name}`,
      icon: 'eye-open',
      size: 'medium',
      variant: 'ghost',
      variantColor: 'navyblue-600',
      _active: {
        backgroundColor: 'navyblue-400',
      },
    }),
    [name]
  );

  if (sql === '') {
    return (
      <Tooltip content={<Box>Preview Table</Box>}>
        <IconButton {...iconButtonProps} onClick={previewTable} />
      </Tooltip>
    );
  }

  return (
    <Popover>
      {({ close }) => (
        <>
          <PopoverTrigger as={IconButton} {...iconButtonProps} />
          <PopoverContent alignment="bottom-right">
            <Card p={4} variant="lighter">
              <Text display="block" mb={4} fontSize="small-medium" maxWidth="200px">
                Preview{' '}
                <Text
                  fontFamily="monospace"
                  as="span"
                  backgroundColor="navyblue-350"
                  borderRadius="small"
                  p="1px 4px"
                >
                  {name}
                </Text>{' '}
                and replace your current query?
              </Text>

              <Flex justifyContent="center" spacing={2}>
                <Button
                  variant="outline"
                  variantColor="gray-400"
                  size="medium"
                  fullWidth
                  onClick={close}
                >
                  Cancel
                </Button>
                <Button
                  size="medium"
                  fullWidth
                  onClick={() => {
                    previewTable();
                    close();
                  }}
                >
                  Replace
                </Button>
              </Flex>
            </Card>
          </PopoverContent>
        </>
      )}
    </Popover>
  );
};

const TableListItem = ({ name, onClick }: TableListItemProps) => {
  return (
    <Box
      as="li"
      key={name}
      mx={6}
      borderTop="1px solid"
      borderColor="navyblue-300"
      _first={{ borderTop: 'none' }}
    >
      <Flex justify="space-between" align="center">
        <AbstractButton
          p={2}
          truncated
          width="100%"
          outline="none"
          textAlign="left"
          onClick={() => onClick(name)}
          _hover={{
            backgroundColor: 'navyblue-400',
            borderRadius: 'medium',
          }}
          _focus={{
            backgroundColor: 'navyblue-400',
            borderRadius: 'medium',
          }}
        >
          {name}
        </AbstractButton>
        <PreviewTable name={name} />
      </Flex>
    </Box>
  );
};

export default React.memo(TableListItem);
