/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { AbstractButton, AbstractButtonProps, Box } from 'pouncejs';

interface TableControlsComplianceFilterProps extends AbstractButtonProps {
  text: string;
  isActive: boolean;
  count?: number;
}

const TableControlsComplianceFilter: React.FC<TableControlsComplianceFilterProps> = ({
  text,
  count,
  isActive,
  ...rest
}) => {
  return (
    <AbstractButton
      {...rest}
      py={2}
      px={3}
      color={isActive ? 'inherit' : 'gray-300'}
      borderRadius="medium"
      outline="none"
      backgroundColor={isActive ? 'navyblue-300' : 'transparent'}
      _hover={{
        backgroundColor: isActive ? 'navyblue-300' : 'navyblue-500',
      }}
      _focus={{
        backgroundColor: isActive ? 'navyblue-300' : 'navyblue-500',
      }}
    >
      {text}{' '}
      <Box fontSize="medium" color="navyblue-100" as="span">
        ({count})
      </Box>
    </AbstractButton>
  );
};

export default TableControlsComplianceFilter;
