/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Box, Text, Flex } from 'pouncejs';

const JsonRootNode: React.FC = () => (
  <Flex spacing={2}>
    <Flex my="5px" direction="column" justify="space-between" align="center">
      <Box width={8} height={8} backgroundColor="navyblue-300" borderRadius="circle" />
      <Box flexGrow={1} width={2} backgroundColor="navyblue-300"></Box>
      <Box width={8} height={8} backgroundColor="navyblue-300" borderRadius="circle" />
    </Flex>
    <Flex direction="column" justify="space-between" align="center">
      <Text as="span">{'{'}</Text>
      <Text as="span">{'}'}</Text>
    </Flex>
  </Flex>
);

export default React.memo(JsonRootNode);
