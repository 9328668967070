/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import FormikCombobox from 'Components/fields/ComboBox';
import FormikDateRangeInput from 'Components/fields/DateRangeInput';
import FormikTextInput from 'Components/fields/TextInput';
import { FastField } from 'formik';
import { AlertsInput, AlertsSortFieldsEnum, AlertStatus, SortDirEnum } from 'Generated/schema';
import { SortingOption, capitalize } from 'Helpers/utils';
import { Box, Flex } from 'pouncejs';
import React from 'react';

export type ListSystemErrorsFiltersValues = Pick<
  AlertsInput,
  'sortBy' | 'sortDir' | 'nameContains' | 'statuses' | 'createdAtAfter' | 'createdAtBefore'
>;

export const sortingOpts: SortingOption<ListSystemErrorsFiltersValues>[] = [
  {
    opt: 'Most Recent',
    resolution: {
      sortBy: AlertsSortFieldsEnum.CreatedAt,
      sortDir: SortDirEnum.Descending,
    },
  },
  {
    opt: 'Oldest',
    resolution: {
      sortBy: AlertsSortFieldsEnum.CreatedAt,
      sortDir: SortDirEnum.Ascending,
    },
  },
];

const statusOptions = Object.values(AlertStatus);
// The Api accepts an array of statuses as an input. Since we want to display a combobox instead of
// a multi combobox in the filters we have to wrap the values inside an array.
const extendedStatusOptions = [statusOptions, ...statusOptions.map(status => [status])];

const filterItemToString = (item: AlertStatus[]) => {
  if (item.length > 1) {
    return 'All';
  }
  return item[0] === AlertStatus.Closed ? 'Invalid' : capitalize(item[0].toLowerCase());
};

const ListSystemErrorFilters = () => {
  return (
    <Flex spacing={4} justify="flex-end" align="center">
      <Box minWidth={200}>
        <FastField
          data-tid="list-system-errors-nameContains-filter"
          name="nameContains"
          icon="search"
          iconAlignment="left"
          as={FormikTextInput}
          label="Search"
          placeholder="Search for an error..."
        />
      </Box>
      <Box>
        <FastField
          name="statuses"
          as={FormikCombobox}
          items={extendedStatusOptions}
          itemToString={filterItemToString}
          showClearSelectionControl={false}
          data-tid="list-system-errors-status-filter"
          placeholder="Select status"
          label="Status"
        />
      </Box>
      <Box minWidth={140}>
        <FastField
          name="sorting"
          data-tid="list-system-errors-sorting"
          as={FormikCombobox}
          showClearSelectionControl={false}
          items={sortingOpts.map(sortingOption => sortingOption.opt)}
          placeholder="Select a sort option"
          label="Sort By"
        />
      </Box>
      <Box minWidth={360}>
        <FormikDateRangeInput
          alignment="right"
          withPresets
          withTime
          disableReset
          format="MM/DD/YYYY HH:mm"
          labelStart="Date Start"
          labelEnd="Date End"
          placeholderStart="MM/DD/YY HH:mm"
          placeholderEnd="MM/DD/YY HH:mm"
          nameStart="createdAtAfter"
          nameEnd="createdAtBefore"
        />
      </Box>
    </Flex>
  );
};

export default ListSystemErrorFilters;
