/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { useSnackbar } from 'pouncejs';
import Page404 from 'Pages/404';
import withSEO from 'Hoc/withSEO';
import useRouter from 'Hooks/useRouter';
import withRoleRestrictedAccess from 'Hoc/withRoleRestrictedAccess';
import { compose } from 'Helpers/compose';
import { Permission } from 'Generated/schema';
import Page403 from 'Pages/403';
import { extractErrorMessage } from 'Helpers/utils';
import SalesforceLogsSourceWizard, {
  SalesforceLogsSourceWizardValues,
} from '../SalesforceLogsSourceWizard';
import { useGetSalesforceLogSource } from './graphql/getSalesforceLogSource.generated';
import { useUpdateSalesforceLogSource } from './graphql/updateSalesforceLogSource.generated';

const EditSalesforceLogSource: React.FC = () => {
  const { pushSnackbar } = useSnackbar();
  const { match } = useRouter<{ id: string }>();
  const { data, error: getError } = useGetSalesforceLogSource({
    variables: { id: match.params.id },
    onError: error => {
      pushSnackbar({
        title: extractErrorMessage(error) || 'An unknown error occurred',
        variant: 'error',
      });
    },
  });

  const [updateSalesforceLogSource] = useUpdateSalesforceLogSource();

  const initialValues = React.useMemo(
    () => ({
      integrationId: match.params.id,
      logTypes: data?.getLogPullingIntegration?.logTypes ?? [],
      integrationLabel: data?.getLogPullingIntegration.integrationLabel ?? 'Loading...',
      username: data?.getLogPullingIntegration?.pullerConfig?.salesforce?.username ?? 'Loading...',
      password: data?.getLogPullingIntegration?.pullerConfig?.salesforce?.password ?? 'Loading...',
      securityToken:
        data?.getLogPullingIntegration?.pullerConfig?.salesforce?.securityToken ?? 'Loading...',
      fileInterval:
        data?.getLogPullingIntegration?.pullerConfig?.salesforce?.fileInterval ?? 'Loading...',
    }),
    // FIXME: look into missing hook dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data]
  );

  const handleSubmit = React.useCallback(async (values: SalesforceLogsSourceWizardValues) => {
    try {
      const resp = await updateSalesforceLogSource({
        variables: {
          input: {
            integrationId: values.integrationId,
            integrationLabel: values.integrationLabel,
            pullerConfig: {
              logTypes: values.logTypes,
              salesforce: {
                username: values.username,
                password: values.password,
                securityToken: values.securityToken,
                fileInterval: values.fileInterval,
              },
            },
          },
        },
      });
      return resp.data.updateLogPullingIntegration;
    } catch (err) {
      return err as Error;
    }
    // FIXME: look into missing hook dependencies or using a ref
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // we optimistically assume that an error in "get" is a 404. We don't have any other info
  if (getError) {
    return <Page404 />;
  }

  return <SalesforceLogsSourceWizard initialValues={initialValues} onSubmit={handleSubmit} />;
};

export default compose(
  withSEO({ title: 'Edit Salesforce Source' }),
  withRoleRestrictedAccess({
    allowedPermissions: [Permission.LogSourceModify],
    fallback: <Page403 />,
  })
)(EditSalesforceLogSource);
