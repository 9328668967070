/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Box, Button, Flex, Grid } from 'pouncejs';
import JsonViewerContext from '../JsonViewerContext';

/** Contains all the actions available for display in the JsonViewerHeader */
type JsonViewerHeaderActions = 'copy' | 'toggleExpansion';

interface JsonViewerHeaderProps {
  children?: React.ReactNode;
  actions?: JsonViewerHeaderActions[];
}

const JsonViewerHeader: React.FC<JsonViewerHeaderProps> = ({
  children = null,
  actions = ['copy', 'toggleExpansion'],
}) => {
  const { copyJson, toggleIsExpanded, isExpanded } = React.useContext(JsonViewerContext);

  return (
    <Grid
      position={children ? 'static' : 'absolute'}
      templateColumns="1fr 1fr 1fr"
      top="0"
      right="0"
      zIndex={10}
      spacing={2}
    >
      {!!children && <Box gridColumn={2}>{children}</Box>}
      <Flex gridColumn={3} justify="flex-end" spacing={2}>
        {actions.includes('copy') && (
          <Button
            data-testid="copy-json"
            size="medium"
            variantColor="navyblue-300"
            onClick={copyJson}
          >
            Copy JSON
          </Button>
        )}

        {actions.includes('toggleExpansion') && (
          <Button
            data-testid="toggle-json"
            size="medium"
            variantColor="navyblue-300"
            onClick={toggleIsExpanded}
          >
            {isExpanded ? 'Collapse All' : 'Expand All'}
          </Button>
        )}
      </Flex>
    </Grid>
  );
};

export default JsonViewerHeader;
