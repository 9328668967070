/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import groupBy from 'lodash/groupBy';
import { AlertDeliveryFull } from 'Source/graphql/fragments/AlertDeliveryFull.generated';
import orderBy from 'lodash/orderBy';
import { AlertSummaryFull } from 'Source/graphql/fragments/AlertSummaryFull.generated';
import useAlertDestinations from 'Hooks/useAlertDestinations';

interface UseAlertDestinationsDeliverySuccessProps {
  alert: AlertSummaryFull;
}

interface UseAlertDestinationsDeliverySuccessResponse {
  allDestinationDeliveredSuccessfully: boolean;
  enhancedAndSortedAlertDeliveries: any[];
  loading: boolean;
}

const useAlertDestinationsDeliverySuccess = ({
  alert,
}: UseAlertDestinationsDeliverySuccessProps): UseAlertDestinationsDeliverySuccessResponse => {
  const { deliveries } = alert;
  // FIXME: `alertDestinations` should be part of Alert & coming directly from GraphQL
  //  Someday...
  const { alertDestinations, loading } = useAlertDestinations({ alert });
  const enhancedAndSortedAlertDeliveries = React.useMemo(() => {
    return deliveries
      .reduce((acc, dr) => {
        const dest = alertDestinations.find(d => d.outputId === dr.outputId);
        if (dest) {
          acc.push({
            ...dr,
            ...dest,
          });
        }
        return acc;
      }, [])
      .reverse();
  }, [deliveries, alertDestinations]);

  const allDestinationDeliveredSuccessfully = React.useMemo(() => {
    // Need to determine success for each destination (group by destination).
    const deliveryStatusByDestination = groupBy(
      enhancedAndSortedAlertDeliveries,
      (d: AlertDeliveryFull) => d.outputId
    );

    // Next, we sort each status inside each group by dispatchedAt and determine if it was successful
    // This is all or nothing. The most recent status for ALL destinations should be successful, otherwise
    // notify the user of a failure.
    return Object.values(deliveryStatusByDestination).every((dest: Array<AlertDeliveryFull>) => {
      // We cant convert to date and compare because it would truncate
      // dispatchedAt to milliseconds, but they're often dispatched within
      // a few nano seconds. Therefore, we compare on strings.
      const sorted = orderBy(dest, ['dispatchedAt'], ['desc']);
      // Now that we've sorted the statues, the most recent status
      // should indicate success or failure to the user.
      return sorted[0].success;
    });
  }, [enhancedAndSortedAlertDeliveries]);

  return React.useMemo(
    () => ({
      allDestinationDeliveredSuccessfully,
      enhancedAndSortedAlertDeliveries,
      loading,
    }),
    [allDestinationDeliveredSuccessfully, enhancedAndSortedAlertDeliveries, loading]
  );
};

export default useAlertDestinationsDeliverySuccess;
