/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { FadeIn } from 'pouncejs';
import Panel from 'Components/Panel';
import TablePlaceholder from 'Components/TablePlaceholder';

const DestinationsPageSkeleton: React.FC = () => {
  return (
    <FadeIn from="bottom">
      <Panel title="Destinations">
        <TablePlaceholder />
      </Panel>
    </FadeIn>
  );
};

export default DestinationsPageSkeleton;
