/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import { AlertType, Permission } from 'Generated/schema';
import { compose } from 'Helpers/compose';
import { extractErrorMessage, shortenId } from 'Helpers/utils';
import withRoleRestrictedAccess from 'Hoc/withRoleRestrictedAccess';
import withSEO from 'Hoc/withSEO';
import useRouter from 'Hooks/useRouter';
import Page403 from 'Pages/403';
import { Alert, Box, FadeIn } from 'pouncejs';
import React from 'react';
import { useListDestinations } from 'Source/graphql/queries';
import { useGetReplayedAlert } from 'Source/graphql/queries/getReplayedAlert.generated';
import Skeleton from 'Pages/AlertDetails/Skeleton';
import RuleReplayedAlertDetails from './RuleReplayedAlertDetails';

const ReplayedAlertDetailsPage = () => {
  const { match } = useRouter<{ alertId: string; replayId: string }>();

  const { data: alertData, loading: alertLoading, error: alertError } = useGetReplayedAlert({
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    variables: { alertId: match.params.alertId, replayId: match.params.replayId },
  });

  // FIXME: The destination information should come directly from GraphQL, by executing another
  //  query in the Front-end and using the results of both to calculate it.
  const { data: destinationData, loading: destinationLoading } = useListDestinations();

  if ((alertLoading && !alertData) || (destinationLoading && !destinationData)) {
    return (
      <FadeIn from="bottom">
        <Skeleton />
      </FadeIn>
    );
  }

  if (alertError) {
    return (
      <Box mb={6}>
        <Alert
          variant="error"
          title="Couldn't load alert"
          description={
            extractErrorMessage(alertError) ||
            "An unknown error occurred and we couldn't load the alert details from the server"
          }
        />
      </Box>
    );
  }

  const { replayedAlert } = alertData;
  switch (replayedAlert.type) {
    case AlertType.Rule:
    case AlertType.RuleError:
    default:
      return <RuleReplayedAlertDetails alert={replayedAlert} replayId={match.params.replayId} />;
  }
};

export default compose(
  withSEO({ title: ({ match }) => `Replayed Alert #${shortenId(match.params.alertId)}` }),
  withRoleRestrictedAccess({
    allowedPermissions: [Permission.RuleRead, Permission.RuleModify],
    fallback: <Page403 />,
  })
)(ReplayedAlertDetailsPage);
