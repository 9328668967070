/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { useSnackbar } from 'pouncejs';
import { ListUsersDocument } from 'Source/graphql/queries/listUsers.generated';
import { getOperationName } from 'apollo-utilities';
import ConfirmModal from 'Components/modals/ConfirmModal';
import { UserDetails } from 'Source/graphql/fragments/UserDetails.generated';
import { MessageActionEnum, UserRoleInputKind } from 'Generated/schema';
import useUserRole from 'Hooks/useUserRole';
import useModal from 'Hooks/useModal';
import { useInviteUser } from './graphql/inviteUser.generated';

export interface ReinviteUserProps {
  user: UserDetails;
}

const ReinviteUserModal: React.FC<ReinviteUserProps> = ({ user }) => {
  const { pushSnackbar } = useSnackbar();
  const { getRole } = useUserRole();
  const { hideModal } = useModal();
  const userDisplayName = `${user.givenName} ${user.familyName}` || user.id;
  const [inviteUser] = useInviteUser({
    variables: {
      input: {
        email: user.email,
        familyName: user.familyName,
        role: { kind: UserRoleInputKind.Id, value: getRole(user).id },
        givenName: user.givenName,
        messageAction: MessageActionEnum.Resend,
      },
    },
    awaitRefetchQueries: true,
    refetchQueries: [getOperationName(ListUsersDocument)],
    onCompleted: () => {
      hideModal();
      pushSnackbar({
        variant: 'success',
        title: `Successfully reinvited user ${userDisplayName}`,
      });
    },
    onError: () => {
      hideModal();
      pushSnackbar({ variant: 'error', title: `Failed to reinvite user ${userDisplayName}` });
    },
  });

  return (
    <ConfirmModal
      variant="destructive"
      onConfirm={inviteUser}
      onClose={hideModal}
      subtitle={`Are you sure you want to reinvite user ${userDisplayName}?`}
    />
  );
};

export default ReinviteUserModal;
