/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Box, FormError, NumberInput, NumberInputProps } from 'pouncejs';
import { FieldConfig, useField } from 'formik';

const FormikNumberInput: React.FC<
  NumberInputProps & Required<Pick<FieldConfig, 'name'>>
> = props => {
  const [, meta, helpers] = useField(props.name);

  const { touched, error, value } = meta;
  const { setValue } = helpers;

  const isInvalid = touched && !!error;
  const errorElementId = isInvalid ? `${props.name}-error` : undefined;

  return (
    <Box>
      <NumberInput
        invalid={isInvalid}
        aria-describedby={isInvalid ? errorElementId : undefined}
        {...props}
        value={value}
        onInputNumberChange={setValue}
      />
      {isInvalid && (
        <FormError mt={2} id={errorElementId}>
          {error}
        </FormError>
      )}
    </Box>
  );
};

export default FormikNumberInput;
