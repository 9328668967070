/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import useAsyncQueryContext from 'Hooks/useAsyncQueryContext';
import { DEFAULT_LARGE_PAGE_SIZE } from 'Source/constants';
import useInfiniteScroll, { UseInfiniteScrollProps } from 'Hooks/useInfiniteScroll';
import { DataLakeQueryStatus } from 'Generated/schema';
import { extractErrorMessage } from 'Helpers/utils';
import { QueryFunctionOptions } from '@apollo/client';
import { useGetAsyncQueryResults } from './graphql/getAsyncQueryResults.generated';

const POLL_INTERVAL_MS = 400;

export interface UseAsyncQueryResultsProps extends QueryFunctionOptions {
  pageSize?: number;
  paginationContainer?: UseInfiniteScrollProps['scrollContainer'];
}

const useAsyncQueryResults = ({
  fetchPolicy,
  skip,
  pageSize = DEFAULT_LARGE_PAGE_SIZE,
  paginationContainer = 'parent' as const,
  pollInterval = POLL_INTERVAL_MS,
}: UseAsyncQueryResultsProps = {}) => {
  const {
    state: { queryId },
    dispatch,
  } = useAsyncQueryContext();

  const {
    previousData,
    data = previousData,
    loading,
    error,
    fetchMore,
    variables,
    startPolling,
    stopPolling,
  } = useGetAsyncQueryResults({
    fetchPolicy,
    // FIXME: We should add `fetchPolicy: 'no-cache'` since cache read&write take too long
    // Unfortunately, the "fetch more" doesn't currently work when specifying `no-cache` and thus
    // we are bound by slow read/writes when the results are "big". There is pending ticket to allow
    // to bypass cache, thus increasing performance
    // https://github.com/apollographql/apollo-client/issues/5239

    // FIXME: `data` contents are referentially  different after every `fetchMore` invocation
    // There is bug where when running fetchMore and `updateQuery`, all the items in a list get
    // different references. This forces React to re-render all the query results every time new
    // paginated items get fetched from the server
    // https://github.com/apollographql/apollo-client/issues/6202
    skip: !queryId || skip,
    // FIXME: `notifyOnNetworkStatusChange: true` is hack to fix an issue that exists with Apollo.
    // When polling, apollo won't update the "error" value. By setting this value to `true`,
    // we get more re-renders but at least the value gets updated correctly
    // https://github.com/apollographql/apollo-client/issues/5531
    notifyOnNetworkStatusChange: true,
    variables: { id: queryId, pageSize },
  });

  const fetchNextPage = React.useCallback(() => {
    return fetchMore({
      variables: {
        ...variables,
        cursor: data.dataLakeQuery.results.pageInfo.endCursor,
      },
      // FIXME: The `updateQuery` option will be soon deprecated so we need to remove it
      // in favor of typePolicies provided they work as intended
      updateQuery: (previousResult, { fetchMoreResult }): typeof previousResult => {
        return {
          dataLakeQuery: {
            ...previousResult.dataLakeQuery,
            ...fetchMoreResult.dataLakeQuery,
            results: {
              ...previousResult.dataLakeQuery.results,
              ...fetchMoreResult.dataLakeQuery.results,
              edges: [
                ...previousResult.dataLakeQuery.results.edges,
                ...fetchMoreResult.dataLakeQuery.results.edges,
              ],
            },
          },
        };
      },
    });
    // FIXME: look into hook dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [variables, data]);

  // Hook to handle the fetching of more items when we scroll close to the end
  const { sentinelRef } = useInfiniteScroll<HTMLElement>({
    loading,
    scrollContainer: paginationContainer,
    threshold: 500,
    onLoadMore: () => {
      fetchNextPage();
    },
  });

  const status = data?.dataLakeQuery.status;
  const queryIsProvisioned = data === undefined && queryId !== null;
  const queryIsRunning = status === DataLakeQueryStatus.Running;
  const queryIsCancelled = status === DataLakeQueryStatus.Cancelled;
  const queryHasFailed = !!error || status === DataLakeQueryStatus.Failed;
  const queryIsSuccessful = status === DataLakeQueryStatus.Succeeded;

  React.useEffect(() => {
    if (queryHasFailed) {
      const message = error ? extractErrorMessage(error) : data?.dataLakeQuery.message;
      dispatch({ type: 'QUERY_ERRORED', payload: { message, queryId } });
    } else if (queryIsSuccessful) {
      dispatch({ type: 'QUERY_SUCCEEDED', payload: { queryId } });
    } else if (queryIsProvisioned || queryIsRunning) {
      dispatch({ type: 'QUERY_RUNNING', payload: { queryId } });
    }
    // FIXME: look into hook dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryIsProvisioned, queryIsRunning, queryHasFailed, queryIsSuccessful, queryId]);

  React.useEffect(() => {
    if (queryIsRunning) {
      startPolling(pollInterval);
    } else if (queryIsSuccessful || queryHasFailed || queryIsCancelled) {
      stopPolling();
    }
    // FIXME: look into hook dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryIsRunning, queryIsCancelled, queryHasFailed, queryIsSuccessful, pollInterval]);

  return {
    loading,
    fetchNextPage,
    stats: data?.dataLakeQuery.results?.stats,
    results: data?.dataLakeQuery.results?.edges.map(edge => edge.node),
    columnOrder: data?.dataLakeQuery.results?.columnInfo.order,
    hasNextPage: data?.dataLakeQuery.results?.pageInfo?.hasNextPage ?? false,
    infiniteScrollRef: sentinelRef,
  };
};

export default useAsyncQueryResults;
