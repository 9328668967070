/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Table, Box, Text, Flex, Badge } from 'pouncejs';
import { AssociatedLogType } from 'Generated/schema';

interface DetailsTableProps {
  enrichedLogTypes: AssociatedLogType[];
}
const DetailsTable: React.FC<DetailsTableProps> = ({ enrichedLogTypes }) => {
  return (
    <Table size="small" stickyHeader>
      <Table.Head>
        <Table.Row>
          <Box as={Table.HeaderCell} textTransform="none" align="center" pl={50}>
            <Text fontWeight="bold">Log Type</Text>
          </Box>
          <Box as={Table.HeaderCell} textTransform="none" align="center" py={4}>
            <Text fontWeight="bold">Log Attribute</Text>
          </Box>
        </Table.Row>
      </Table.Head>
      <Table.Body>
        {enrichedLogTypes.map(associatedLog => {
          return (
            <Table.Row key={associatedLog.logType}>
              <Box as={Table.Cell} wrapText="nowrap" pl={50} py={4}>
                <Text fontSize="medium">{associatedLog.logType}</Text>
              </Box>
              <Box as={Table.Cell}>
                <Flex>
                  {associatedLog.selectors.map(logAttribute => (
                    <Box key={logAttribute} mr="2">
                      <Badge color="navyblue-200" size="medium">
                        <Text fontSize="small">{logAttribute}</Text>
                      </Badge>
                    </Box>
                  ))}
                </Flex>
              </Box>
            </Table.Row>
          );
        })}
      </Table.Body>
    </Table>
  );
};

export default React.memo(DetailsTable);
