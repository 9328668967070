/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Box, Button, Flex, Heading, Text } from 'pouncejs';

const MethodCard = ({ title, subtitle, onSelect, id }) => (
  <Flex
    as="section"
    backgroundColor="navyblue-350"
    align="center"
    alignItems="center"
    spacing={2}
    px={7}
    py={5}
  >
    <Box>
      <Heading as="h2" color="white-100" fontSize="x-large" mb={1}>
        {title}
      </Heading>
      <Text color="gray-300" fontSize="medium">
        {subtitle}
      </Text>
    </Box>
    <Box ml="auto">
      <Button data-testid={`${id}-button`} onClick={onSelect}>
        Select
      </Button>
    </Box>
  </Flex>
);

export default MethodCard;
