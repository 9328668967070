/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { useFormikContext } from 'formik';
import debounce from 'lodash/debounce';

export interface AutosaveProps {
  threshold?: number;
}

const FormikAutosave: React.FC<AutosaveProps> = ({ threshold = 0 }) => {
  const { submitForm, values } = useFormikContext();
  const isInitialMount = React.useRef(true);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSubmit = React.useCallback(
    debounce(() => {
      submitForm();
    }, threshold),
    [threshold, submitForm]
  );

  React.useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      debouncedSubmit();
    }
  }, [debouncedSubmit, values]);

  return null;
};

export default FormikAutosave;
