/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import {
  AbstractButton,
  Box,
  BoxProps,
  Divider,
  Flex,
  Heading,
  Icon,
  IconProps,
  Text,
} from 'pouncejs';
import { useWizardContext } from './WizardContext';

export const WizardNavigation = ({ title }) => {
  const { navigationEntries } = useWizardContext();

  return (
    <>
      {title && (
        <Box as="header">
          <Heading size="small" fontWeight="medium">
            {title}
          </Heading>
          <Divider my={6} color="navyblue-300" />
        </Box>
      )}
      <Flex as="ul" spacing={6} direction="column">
        {navigationEntries.map(entry => (
          <WizardNavigationEntry key={entry.id} entry={entry} />
        ))}
      </Flex>
    </>
  );
};

export const WizardNavigationEntry = ({ entry }) => {
  const {
    currentStepIndex,
    currentStepStatus,
    isNavigationAvailable,
    isAllNavigationStepsEnabled,
    goToStep,
  } = useWizardContext();

  const isNested = entry.entries;
  const isComplete = isNested
    ? entry.entries.every(({ id: stepIndex }) => currentStepIndex > stepIndex)
    : currentStepIndex > entry.id;

  const isCurrent = isNested
    ? entry.entries.some(({ id: stepIndex }) => stepIndex === currentStepIndex)
    : currentStepIndex === entry.id;

  // Based on status
  const isPassing = currentStepStatus === 'PASSING';
  const isFailing = currentStepStatus === 'FAILING';

  let backgroundColor: BoxProps['backgroundColor'];
  let svgIcon: IconProps['type'];
  if (isComplete || isPassing) {
    backgroundColor = 'green-400';
    svgIcon = 'check-circle';
  } else if (isFailing) {
    backgroundColor = 'pink-600';
    svgIcon = 'alert-circle-filled';
  } else {
    svgIcon = null;
  }

  const isButtonDisabled = Boolean(
    ((!isComplete || !isNavigationAvailable) && !isAllNavigationStepsEnabled) ||
      entry.navigationDisabled
  );
  const fullOpacity = !isButtonDisabled || isCurrent;

  return (
    <Flex as="li" key={entry.title} opacity={fullOpacity ? 1 : 0.3}>
      <Box borderRadius="pill" width={4} backgroundColor={isCurrent ? 'blue-300' : 'transparent'} />
      <Box width={1} pl={2}>
        <Flex justify="space-between" align="center">
          <AbstractButton
            textAlign="left"
            opacity={1}
            fontWeight="bold"
            disabled={isButtonDisabled}
            onClick={() => {
              goToStep(isNested ? entry.activeIndex : entry.id);
            }}
          >
            {entry.title}
          </AbstractButton>
          {!isAllNavigationStepsEnabled && svgIcon && (
            <Icon size="large" type={svgIcon} color={backgroundColor} />
          )}
        </Flex>
        {entry.description && isCurrent && (
          <Text mt={1} fontSize="medium" color="gray-300">
            {entry.description}
          </Text>
        )}

        {isNested && isCurrent && (
          <Flex as="ul" direction="column" spacing={0} pt={2}>
            {entry.entries.map((nestedEntry, index) => (
              <WizardNavigationNestedEntry
                key={nestedEntry.id}
                entry={nestedEntry}
                last={index === entry.entries.length - 1}
              />
            ))}
          </Flex>
        )}
      </Box>
    </Flex>
  );
};

export const WizardNavigationNestedEntry = ({ entry, last }) => {
  const { currentStepIndex, currentStepStatus, goToStep } = useWizardContext();
  const isComplete = currentStepIndex > entry.id;
  const isCurrent = currentStepIndex === entry.id;
  const isPassing = currentStepStatus === 'PASSING';
  const isFailing = currentStepStatus === 'FAILING';

  let backgroundColor: BoxProps['backgroundColor'];
  let borderColor: BoxProps['borderColor'];

  if (isComplete || isPassing) {
    backgroundColor = 'green-400';
    borderColor = 'green-400';
  } else if (isFailing) {
    backgroundColor = 'pink-600';
    borderColor = 'pink-600';
  } else if (isCurrent) {
    backgroundColor = 'blue-300';
    borderColor = 'blue-300';
  } else {
    backgroundColor = 'transparent';
    borderColor = 'navyblue-100';
  }

  const hasEnableOverride = entry.navigationDisabled !== undefined;

  // By default, nested steps are enabled linearly, you still have to step thru the nested
  // steps. If there's an explicit `navigationDisabled` setting on a nested step, respect
  // its configuration to completely override, regardless of the state of the wizard.
  const isNestedStepDisabled = hasEnableOverride
    ? entry.navigationDisabled
    : !isComplete && !isCurrent;

  return (
    <Box as="li" key={entry.id}>
      <Box display="flex" alignItems="center">
        <Box
          as="span"
          borderRadius="pill"
          opacity={isCurrent || isComplete ? 1 : 0.5}
          width={8}
          height={8}
          mr={2}
          backgroundColor={backgroundColor}
          borderStyle="solid"
          borderWidth="1px"
          borderColor={borderColor}
        />
        <AbstractButton
          fontSize="medium"
          disabled={isNestedStepDisabled}
          onClick={() => goToStep(entry.id)}
        >
          {entry.title}
        </AbstractButton>
      </Box>
      {!last && (
        <Box
          opacity={0.5}
          ml="3px"
          height={24}
          borderLeftStyle="solid"
          borderLeftWidth="1px"
          borderColor="navyblue-100"
        />
      )}
    </Box>
  );
};
