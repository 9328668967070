/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { useListDestinationsAndDefaults } from 'Pages/Integrations/Destinations/ListDestinations';
import { extractErrorMessage } from 'Helpers/utils';
import { useSnackbar } from 'pouncejs';

const useListAvailableDestinations = ({ outputIds }) => {
  const { pushSnackbar } = useSnackbar();

  const { error, data, loading } = useListDestinationsAndDefaults({
    onError: err =>
      pushSnackbar({
        variant: 'error',
        title: 'Could not fetch your destinations',
        description: extractErrorMessage(err),
      }),
  });

  // All destinations
  // FIXME: look into hook dependencies
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const destinations = data?.destinations || [];

  // Create a Map from id => display name
  const mapIdsToDisplayNames = new Map<string, string>();
  destinations.forEach(({ outputId, displayName }) =>
    mapIdsToDisplayNames.set(outputId, displayName)
  );

  // Lookup an ID and get the corresponding display name
  // FIXME: look into hook dependencies
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const destinationIdToDisplayName = (outputId: string) =>
    mapIdsToDisplayNames.has(outputId) ? mapIdsToDisplayNames.get(outputId) : outputId;

  // Create an array of just the Ids
  const destinationOutputIds = destinations.map(({ outputId }) => outputId);

  // Computes the intersection of two arrays O(m + n)
  const findIntersection = (listA: string[], listB: string[]) =>
    listA.filter((set => (outputId: string) => set.has(outputId))(new Set(listB)));

  // Create an array of valid outputIds
  // The user could have deleted a destination that was assigned to a policy/rule
  // which needs to be filtered out and not shown to the user. The data is not
  // overwritten (cleaned) until the user updates the respective field; however, there is
  // no side-effects of having an invalid ID stored in the backend as it is simply
  // ignored.
  const validOutputIds = findIntersection(destinationOutputIds, outputIds);

  // Flag to inform that the field should be disabled
  const disabled = error || (!loading && !destinationOutputIds.length);

  return React.useMemo(
    () => ({
      error,
      destinations,
      destinationOutputIds,
      validOutputIds,
      loading,
      destinationIdToDisplayName,
      disabled,
    }),
    [
      error,
      destinations,
      destinationOutputIds,
      validOutputIds,
      loading,
      destinationIdToDisplayName,
      disabled,
    ]
  );
};

export default useListAvailableDestinations;
