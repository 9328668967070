/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import RadialProgress from 'Components/RadialProgress';
import React from 'react';
import { Flex, Box, Text } from 'pouncejs';
import { useMitreContext } from '../MitreContext';
import { MitreMatrixGridCharm } from '../MitreMatrixGrid';

const MitreMatrixSummaryStats = () => {
  const { activeMatrix: matrix } = useMitreContext();
  const reportSettingsWithCoverage = React.useMemo(
    () =>
      matrix?.reportSettings.filter(
        s => [...s.matchingDetectionIds, ...s.matchingLogTypes].length > 0
      ),
    [matrix?.reportSettings]
  );

  const totalTechniques = React.useMemo(
    () => matrix?.mappings.map(o => o.techniqueIds.length).reduce((a, b) => a + b) ?? 0,
    [matrix?.mappings]
  );

  const activeDetections = React.useMemo(
    () =>
      reportSettingsWithCoverage
        ?.map(s => [...s.matchingDetectionIds, ...s.matchingLogTypes].length)
        .reduce((a, b) => a + b, 0),
    [reportSettingsWithCoverage]
  );

  const progress = React.useMemo<number>(() => {
    // short circuit to zero for no array or false-y totalTechniques.
    if (!reportSettingsWithCoverage || !totalTechniques) {
      return 0;
    }
    return reportSettingsWithCoverage.length / totalTechniques;
  }, [reportSettingsWithCoverage, totalTechniques]);

  return (
    <Flex fontSize="small" px={4} py={2}>
      <Flex width="25%">
        <Box mr={6}>
          <RadialProgress progress={progress} />
        </Box>
        <Box>
          <Text fontSize="3x-large" fontWeight="bold" fontFamily="mono">
            {reportSettingsWithCoverage?.length ?? 0}/{totalTechniques}
          </Text>
          <Text>Techniques Covered</Text>
        </Box>
      </Flex>
      <Box width="25%">
        <Text fontSize="3x-large" fontWeight="bold" fontFamily="mono">
          {activeDetections}
        </Text>
        <Text>Detections Mapped</Text>
      </Box>
      <Flex width="50%" justifyContent="space-between" fontSize="medium">
        <Flex alignItems="center">
          <MitreMatrixGridCharm status="covered" />
          <Text ml={2}>Covered</Text>
        </Flex>
        <Flex alignItems="center">
          <MitreMatrixGridCharm status="partiallyCovered" />
          <Text ml={2}>Partially Covered</Text>
        </Flex>
        <Flex alignItems="center">
          <MitreMatrixGridCharm status="notRelevant" />
          <Text ml={2}>Not Relevant</Text>
        </Flex>
        <Flex alignItems="center">
          <MitreMatrixGridCharm status="notCovered" />
          <Text ml={2}>Not Covered</Text>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default React.memo(MitreMatrixSummaryStats);
