/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Box, Icon, Flex, Table, Text, AbstractButton, IconButton } from 'pouncejs';
import JsonViewer, { JsonViewerHeader } from 'Components/JsonViewer';
import useNavigationKeys from 'Hooks/useNavigationKeys';
import { HoldingTankResults } from 'Generated/schema';
import NoResultsFound from 'Components/NoResultsFound';
import { DEFAULT_SMALL_PAGE_SIZE } from 'Source/constants';
import { useSchemasManagementContext } from 'Pages/Integrations/LogSources/S3/SchemasManagement/SchemasManagementContext';
import TablePlaceholder from 'Components/TablePlaceholder';
import useInfiniteScroll from 'Hooks/useInfiniteScroll';
import { useGetTestSchemasResults } from './graphql/getTestSchemasResults.generated';

// We have chosen not to display the keys below to the table as they don't provide value
const IGNORED_KEYS = ['p_log_type', 'p_row_id', 'p_parse_time', 'p_source_label', 'p_source_id'];

// Function to transform JSON events to an array of key-values
function transformToKeyValueLog(data): { key: string; value }[][] {
  const allUniqueKeys = [];
  data.forEach(log => {
    Object.keys(log.event).forEach(key => {
      // Checking if key is not in then ignore list and that is unique
      if (!IGNORED_KEYS.includes(key) && !allUniqueKeys.includes(key)) {
        allUniqueKeys.push(key);
      }
    });
  });

  return data.map(log => {
    return allUniqueKeys.map(key => {
      return { key, value: log.event[key] };
    });
  });
}

// function to parse events from string to JSON
export function transformEventToJson(m: HoldingTankResults) {
  let event;
  try {
    event = JSON.parse(m.event);
  } catch {
    event = {};
  }
  return {
    ...m,
    event,
  };
}

// Handled display of data in a cell
export function renderCell(cellData) {
  if (cellData == null) {
    return 'NULL';
  }
  switch (typeof cellData) {
    case 'boolean':
      return String(cellData);
    case 'object':
      return JSON.stringify(cellData);
    default:
      return cellData;
  }
}

interface LogTypeTestResults {
  logType: string;
  prefix: string;
}
const LogTypeTestResults: React.FC<LogTypeTestResults> = ({ logType, prefix }) => {
  const { schemaTestJob } = useSchemasManagementContext();
  const { loading, previousData, data = previousData, fetchMore } = useGetTestSchemasResults({
    variables: {
      input: {
        taskId: schemaTestJob.id,
        logType,
        prefix,
        pageSize: DEFAULT_SMALL_PAGE_SIZE,
      },
    },
  });
  const [view, setView] = React.useState<'tabular' | 'json'>('tabular');
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const results = data?.holdingTankTestSchemasResult.results;
  const resultsLength = data?.holdingTankTestSchemasResult.results?.length ?? 0;
  const hasMoreResults = data?.holdingTankTestSchemasResult.pageInfo.hasNextPage;
  const paginationToken = data?.holdingTankTestSchemasResult.pageInfo.paginationToken;

  React.useEffect(() => {
    // Ensure that we return to the tabular page every time the results are
    // updated because the selected index might not point to the correct row
    setView('tabular');
    setSelectedIndex(0);
  }, [data]);

  const { sentinelRef } = useInfiniteScroll<HTMLDivElement>({
    loading,
    onLoadMore: () =>
      fetchMore({
        variables: {
          input: {
            taskId: schemaTestJob.id,
            logType,
            prefix,
            pageSize: DEFAULT_SMALL_PAGE_SIZE,
            paginationToken,
          },
        },
      }),
  });

  const goToPrevPage = () => {
    const newIndex = selectedIndex === 0 ? resultsLength - 1 : selectedIndex - 1;
    setSelectedIndex(newIndex);
  };

  const goToNextPage = () => {
    const newIndex = resultsLength - 1 === selectedIndex ? 0 : selectedIndex + 1;
    setSelectedIndex(newIndex);
  };

  const handleRowClick = React.useCallback(
    index => {
      setView('json');
      setSelectedIndex(index);
    },
    [setView, setSelectedIndex]
  );

  useNavigationKeys({
    disabled: view !== 'json',
    previousCallback: goToPrevPage,
    nextCallback: goToNextPage,
  });

  const [matchedLogsCount, matchedData] = React.useMemo(
    () => [results?.length, results?.map(transformEventToJson)],
    [results]
  );

  if (loading) {
    return <TablePlaceholder rowCount={6} rowHeight={20} />;
  }

  if (!matchedLogsCount) {
    return (
      <Box py={4}>
        <NoResultsFound title="No Matched Logs found" />
      </Box>
    );
  }

  const selectedData = matchedData[selectedIndex]?.event;

  const matchedLogs = transformToKeyValueLog(matchedData);
  return (
    <React.Fragment>
      <Box
        visuallyHidden={view !== 'tabular'}
        overflow="auto"
        maxHeight="max(calc(100vh - 950px),600px)"
        willChange="scroll"
      >
        <Table size="small" stickyHeader>
          <Table.Head>
            <Table.Row>
              <Box as={Table.HeaderCell} backgroundColor="navyblue-300" textTransform="none">
                Matched Events
              </Box>
              {matchedLogs[0].map(log => (
                <Box
                  backgroundColor="navyblue-300"
                  sx={{ zIndex: 1000 }}
                  as={Table.HeaderCell}
                  key={log.key}
                  textTransform="none"
                >
                  {log.key}
                </Box>
              ))}
            </Table.Row>
          </Table.Head>
          <Table.Body>
            {matchedData.map((log, i) => {
              return (
                <Table.Row key={`matched-log-${i}`}>
                  <Table.Cell>
                    <Flex align="center" spacing={2} width={170}>
                      <Icon
                        type="check-circle"
                        color={'green-400'}
                        size="medium"
                        aria-label={'Log is matched'}
                      />
                      <AbstractButton
                        onClick={() => handleRowClick(i)}
                        display="flex"
                        justifyItems="center"
                        alignItems="center"
                      >
                        <Box mx={2}>View JSON</Box>
                        <IconButton
                          as="span"
                          variant="outline"
                          variantBorderStyle="circle"
                          size="small"
                          icon="arrow-forward"
                          aria-label="Switch to JSON view"
                          variantColor="navyblue-300"
                          // @ts-ignore
                          sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flexShrink: 0,
                          }}
                        />
                      </AbstractButton>
                    </Flex>
                  </Table.Cell>
                  {matchedLogs[i].map(logField => {
                    return (
                      <Text fontFamily="mono" key={logField.key} as={Table.Cell}>
                        {renderCell(logField.value)}
                      </Text>
                    );
                  })}
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>
        {hasMoreResults && (
          <Box mt={8} ref={sentinelRef}>
            <TablePlaceholder rowCount={10} rowHeight={6} />
          </Box>
        )}
      </Box>
      <Box hidden={view === 'tabular'}>
        <Flex justify="flex-start" align="center" m={2} spacing={2}>
          <IconButton
            active
            onClick={() => setView('tabular')}
            variant="ghost"
            variantBorderStyle="circle"
            size="medium"
            variantColor="blue-400"
            icon="arrow-back"
            aria-label="Switch to tabular view"
          />
          <Text>Go Back</Text>
        </Flex>
        <JsonViewer
          data={selectedData}
          header={
            <JsonViewerHeader>
              <Flex justify="center" align="center" spacing={2}>
                <IconButton
                  onClick={goToPrevPage}
                  variant="ghost"
                  variantBorderStyle="circle"
                  size="medium"
                  variantColor="blue-400"
                  icon="arrow-back"
                  aria-label="Go to previous"
                />
                <Box>
                  {selectedIndex + 1} of {resultsLength}
                </Box>
                <IconButton
                  onClick={goToNextPage}
                  variant="ghost"
                  variantBorderStyle="circle"
                  size="medium"
                  variantColor="blue-400"
                  icon="arrow-forward"
                  aria-label="Go to next"
                />
              </Flex>
            </JsonViewerHeader>
          }
        />
      </Box>
    </React.Fragment>
  );
};

export default LogTypeTestResults;
