/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import RuleCard from 'Components/cards/RuleCard';
import PolicyCard from 'Components/cards/PolicyCard';
import { RuleSummary } from 'Source/graphql/fragments/RuleSummary.generated';
import { PolicySummary } from 'Source/graphql/fragments/PolicySummary.generated';
import { DetectionTypeEnum } from 'Generated/schema';
import { CardVariant } from '../common/cardVariant';

export type DetectionCardProps = {
  variant?: CardVariant;
  onDelete?: (id: string) => void;
  detection: RuleSummary | PolicySummary;
  isSelected?: boolean;
  selectionEnabled?: boolean;
};

/**
 * A simple wrapper mapping the detection type to the corresponding card UI.
 */
const DetectionCard = ({ detection, ...rest }: DetectionCardProps) => {
  switch (detection.analysisType) {
    case DetectionTypeEnum.Rule:
    case DetectionTypeEnum.ScheduledRule:
      return <RuleCard key={detection.id} rule={detection as RuleSummary} {...rest} />;
    case DetectionTypeEnum.Policy:
      return <PolicyCard key={detection.id} policy={detection as PolicySummary} {...rest} />;
    default:
      return null;
  }
};

export default DetectionCard;
