/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import Panel from 'Components/Panel';
import { Box, Flex, Alert } from 'pouncejs';
import { extractErrorMessage } from 'Helpers/utils';
import DetectionCard from 'Components/cards/DetectionCard';
import Breadcrumbs from 'Components/Breadcrumbs';
import { useSelect } from 'Components/utils/SelectContext';
import useListDetectionsWithRequestParams from 'Hooks/useListDetectionsWithRequestParams';
import ListDetectionsFilters from 'Pages/ListDetections/ListDetectionsActions/ListDetectionsFilters';
import LinkButton from 'Components/buttons/LinkButton';
import urls from 'Source/urls';
import { TableControlsPagination } from 'Components/utils/TableControls';
import MitreTacticTechniqueIndicator from 'Pages/Reports/Mitre/MitreTacticTechniqueIndicator';
import NoResultsFound from 'Components/NoResultsFound';
import MitreMappingDetectionSkeleton from './MitreMappingDetectionSkeleton';
import { MitreMappedRelation } from '../MitreMappingPage';
import { GetReportSetting } from '../graphql/getReportSetting.generated';
import MitreMappingDetectionActions from './MitreMappingDetectionActions';

const MitreMappingDetections = ({
  reportSetting,
}: {
  reportSetting: GetReportSetting['reportSetting'];
}) => {
  const {
    association: {
      mitre: { tactic, technique },
    },
  } = reportSetting;

  const { checkIfSelected } = useSelect<MitreMappedRelation>();
  const [changesSaved, setChangesSaved] = React.useState(false);

  const { loading, error, data, updatePagingParams } = useListDetectionsWithRequestParams();

  if (error && !loading) {
    return (
      <Alert
        title="Failed to load detections"
        variant="error"
        description={extractErrorMessage(error) || 'Unknown Error'}
      />
    );
  }

  if (loading && !data) {
    return <MitreMappingDetectionSkeleton tactic={tactic} technique={technique} />;
  }

  const {
    detections: { detections, paging },
  } = data;

  return (
    <>
      <Breadcrumbs.Actions>
        <MitreMappingDetectionActions
          tacticId={tactic.id}
          techniqueId={technique.id}
          onChangesSaved={() => setChangesSaved(true)}
          reportSettingId={reportSetting.id}
          mappedDetectionIds={reportSetting.matchingDetectionIds}
        />
      </Breadcrumbs.Actions>
      {changesSaved && (
        <Box pb={6}>
          <Alert
            title="Mapping changes saved"
            variant="success"
            actions={
              <LinkButton
                to={urls.reports.mitreMatrix()}
                size="medium"
                icon="arrow-back"
                variant="solid"
                variantColor="green-500"
              >
                Back to Matrix
              </LinkButton>
            }
          />
        </Box>
      )}
      <Panel
        title="Map New Detections"
        actions={
          <Flex justify="flex-end" align="center" spacing={4}>
            <ListDetectionsFilters />
          </Flex>
        }
      >
        <Box backgroundColor="navyblue-500" py={6} px={8}>
          <Box pb={6} mb={8} borderBottom="1px solid" borderColor="navyblue-300">
            <MitreTacticTechniqueIndicator tactic={tactic} technique={technique} />
          </Box>
          <Flex
            spacing={3}
            opacity={loading ? 0.25 : 1}
            direction="column"
            transition="300ms opacity"
          >
            {detections.map(detection => (
              <DetectionCard
                key={detection.id}
                variant="light"
                detection={detection}
                isSelected={checkIfSelected(detection)}
                selectionEnabled
              />
            ))}
            {detections.length === 0 && <NoResultsFound />}
          </Flex>
          <Box mt={4}>
            <TableControlsPagination
              page={paging.thisPage}
              totalPages={paging.totalPages}
              onPageChange={updatePagingParams}
            />
          </Box>
        </Box>
      </Panel>
    </>
  );
};

export default MitreMappingDetections;
