/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Button, Flex } from 'pouncejs';
import { SortDirEnum } from 'Generated/schema';
import { Formik } from 'formik';
import SavedQueriesTable from './SavedQueriesTable';
import ListSavedQueriesFilters from './ListSavedQueriesFilters';

export interface SelectSavedQueryFormValues {
  contains: string;
  sortDir: SortDirEnum;
}

interface SelectSavedQueryFormProps {
  onSubmit: (string) => void;
  initialValues: SelectSavedQueryFormValues;
}
const noSubmit = () => null;

const SelectSavedQueryForm: React.FC<SelectSavedQueryFormProps> = ({ onSubmit, initialValues }) => {
  const [selectedSavedQueryId, selectSavedQuery] = React.useState('');
  return (
    <Formik<SelectSavedQueryFormValues> initialValues={initialValues} onSubmit={noSubmit}>
      <Flex spacing={4} align="center" direction="column">
        <ListSavedQueriesFilters />
        <SavedQueriesTable onSelect={selectSavedQuery} selectedId={selectedSavedQueryId} />
        <Button
          variantColor="violet-400"
          disabled={!selectedSavedQueryId}
          onClick={() => onSubmit(selectedSavedQueryId)}
        >
          Open Query
        </Button>
      </Flex>
    </Formik>
  );
};

export default SelectSavedQueryForm;
