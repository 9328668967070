/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import * as Types from '../../../../../../../__generated__/schema';

import { AtlassianLogIntegrationDetails } from '../../../../../../graphql/fragments/AtlassianLogIntegrationDetails.generated';
import { GraphQLError } from 'graphql';
import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';

export type AddAtlassianLogSourceVariables = {
  input: Types.AddLogPullingIntegrationInput;
};

export type AddAtlassianLogSource = { addLogPullingIntegration: AtlassianLogIntegrationDetails };

export const AddAtlassianLogSourceDocument = gql`
  mutation AddAtlassianLogSource($input: AddLogPullingIntegrationInput!) {
    addLogPullingIntegration(input: $input) {
      ...AtlassianLogIntegrationDetails
    }
  }
  ${AtlassianLogIntegrationDetails}
`;
export type AddAtlassianLogSourceMutationFn = ApolloReactCommon.MutationFunction<
  AddAtlassianLogSource,
  AddAtlassianLogSourceVariables
>;

/**
 * __useAddAtlassianLogSource__
 *
 * To run a mutation, you first call `useAddAtlassianLogSource` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddAtlassianLogSource` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addAtlassianLogSource, { data, loading, error }] = useAddAtlassianLogSource({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddAtlassianLogSource(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    AddAtlassianLogSource,
    AddAtlassianLogSourceVariables
  >
) {
  return ApolloReactHooks.useMutation<AddAtlassianLogSource, AddAtlassianLogSourceVariables>(
    AddAtlassianLogSourceDocument,
    baseOptions
  );
}
export type AddAtlassianLogSourceHookResult = ReturnType<typeof useAddAtlassianLogSource>;
export type AddAtlassianLogSourceMutationResult = ApolloReactCommon.MutationResult<
  AddAtlassianLogSource
>;
export type AddAtlassianLogSourceMutationOptions = ApolloReactCommon.BaseMutationOptions<
  AddAtlassianLogSource,
  AddAtlassianLogSourceVariables
>;
export function mockAddAtlassianLogSource({
  data,
  variables,
  errors,
}: {
  data: AddAtlassianLogSource;
  variables?: AddAtlassianLogSourceVariables;
  errors?: GraphQLError[];
}) {
  return {
    request: { query: AddAtlassianLogSourceDocument, variables },
    result: { data, errors },
  };
}
