/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { DetectionTestDefinition, DetectionTestDefinitionInput } from 'Generated/schema';
import { FieldArray, FastField as Field, useFormikContext } from 'formik';
import {
  Heading,
  Button,
  Flex,
  Icon,
  Link,
  Grid,
  Box,
  AbstractButton,
  Card,
  IconButton,
  Text,
  Radio,
} from 'pouncejs';
import { formatJSON, generateRandomColor } from 'Helpers/utils';
import FormikTextInput from 'Components/fields/TextInput';
import FormikEditor from 'Components/fields/Editor';
import { PolicyFormValues } from 'Components/forms/PolicyForm';
import { RuleFormValues } from 'Components/forms/RuleForm';
import useModal from 'Hooks/useModal';
import DeleteTestModal from 'Components/modals/DeleteTestModal';
import { MOCKED_FUNCTIONS_DOC_URL } from 'Source/constants';

const EMPTY_MOCK = { objectName: '', returnValue: '' };

interface BaseDetectionFormTestSectionProps {
  type: 'rule' | 'policy';
  runTests: (testsToRun: DetectionTestDefinition[]) => any;
  renderTestResults: React.ReactElement;
  renderActions?: (renderActionsProps: { activeTabIndex: number }) => React.ReactNode;
  isManaged: boolean;
}

const BaseDetectionFormTestSection: React.FC<BaseDetectionFormTestSectionProps> = ({
  type,
  runTests,
  renderTestResults,
  renderActions,
  isManaged,
}) => {
  // Read the values from the "parent" form. We expect a formik to be declared in the upper scope
  // since this is a "partial" form. If no Formik context is found this will error out intentionally
  const {
    values: { tests },
    validateForm,
    setFieldValue,
  } = useFormikContext<RuleFormValues | PolicyFormValues>();

  const { showModal } = useModal();
  const [open, setOpen] = React.useState(false);
  const [activeTabIndex, setActiveTabIndex] = React.useState(0);

  const testsCount = tests.length;
  const testsExist = testsCount > 0;

  React.useLayoutEffect(() => {
    setOpen(testsExist);
  }, [testsExist]);

  const mocks = React.useMemo(
    () => (tests[activeTabIndex]?.mocks.length > 0 ? tests[activeTabIndex]?.mocks : [EMPTY_MOCK]),
    // FIXME: look into hook dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [tests[activeTabIndex]]
  );

  const emptyMockExists = React.useMemo(() => {
    return mocks.some(({ objectName }) => !objectName);
  }, [mocks]);

  // The field array below gets registered to the upper formik
  return (
    <FieldArray
      name="tests"
      render={arrayHelpers => {
        /**
         *
         * handler for when the user clicks to add a new test
         *
         */

        const handleTestAddition = () => {
          const newTest: DetectionTestDefinitionInput = {
            name: `Test-${generateRandomColor()}`,
            expectedResult: true,
            resource: formatJSON({}),
            mocks: [EMPTY_MOCK],
          };

          // adds a test
          arrayHelpers.push(newTest);

          // focuses on the newly created test
          setActiveTabIndex(testsCount);
        };

        /**
         *
         * handler for when the user clicks to remove an existing test
         *
         */
        const handleTestRemoval = (e: React.MouseEvent, index: number) => {
          // the button is part of the "Tab" so we don't want to "navigate" to this tab
          // but only close it. Thus, we can't let the click event propagate.
          e.stopPropagation();

          showModal(
            <DeleteTestModal
              test={tests[index]}
              onConfirm={() => {
                // If we are removing an item that's to the "left" of the currently active one,
                // we will need to also move the `activeIndex` to the "left" by 1 tab
                if (index <= activeTabIndex) {
                  setActiveTabIndex(index > 0 ? index - 1 : 0);
                }

                // removes the test
                arrayHelpers.remove(index);

                // There is currently a bug with Formik v2 and removing an item causes a wrong
                // `errors` state to be present. We manually kick in validation to fix that.
                // https://github.com/jaredpalmer/formik/issues/1616
                setTimeout(validateForm, 200);
              }}
            />,
            { title: 'Delete Test' }
          );
        };

        return (
          <Card variant="dark" p={4}>
            <Flex spacing={4}>
              <Box mt={2}>
                <IconButton
                  variant="ghost"
                  variantColor="navyblue-700"
                  variantBorderStyle="circle"
                  active={open}
                  icon={open ? 'caret-up' : 'caret-down'}
                  onClick={() => setOpen(prev => !prev)}
                  size="medium"
                  aria-label="Toggle Tests visibility"
                />
              </Box>
              {testsExist ? (
                <Flex
                  as="ul"
                  wrap="wrap"
                  spacing={4}
                  flexGrow={1}
                  flexBasis={0}
                  mt={1}
                  mb={open ? 0 : -4}
                >
                  {tests.map((test, index) => (
                    <Box as="li" mb={4} key={index}>
                      <AbstractButton
                        borderRadius="pill"
                        px={4}
                        py={2}
                        backgroundColor={activeTabIndex === index ? 'blue-400' : 'navyblue-300'}
                        onClick={() => setActiveTabIndex(index)}
                      >
                        <Flex align="center">
                          {test.name}
                          <Icon
                            type="close"
                            size="x-small"
                            ml={6}
                            onClick={e => handleTestRemoval(e, index)}
                            aria-label="delete test"
                          />
                        </Flex>
                      </AbstractButton>
                    </Box>
                  ))}
                </Flex>
              ) : (
                <Heading as="h2" size="x-small" alignSelf="center" flexGrow={1} ml={0}>
                  Test
                </Heading>
              )}
              <Button icon="brackets" aria-label="Create Test" onClick={handleTestAddition}>
                Create Test
              </Button>
            </Flex>
            {open && testsExist && (
              <React.Fragment>
                <Grid columnGap={5} templateColumns="1fr 2fr" mt={2} mb={6}>
                  <Field
                    as={FormikTextInput}
                    name={`tests[${activeTabIndex}].name`}
                    placeholder="The name of your test"
                    label="Name"
                  />
                  <Flex align="center" spacing={5}>
                    <Box fontSize="medium" fontWeight="medium" flexGrow={1} textAlign="right">
                      {type === 'policy'
                        ? 'Should this test resource be compliant?'
                        : 'Should this test event trigger an alert?'}
                    </Box>
                    <Radio
                      label="Yes"
                      checked={tests[activeTabIndex].expectedResult === true}
                      onChange={() =>
                        setFieldValue(`tests[${activeTabIndex}].expectedResult`, true)
                      }
                    />
                    <Radio
                      label="No"
                      checked={tests[activeTabIndex].expectedResult === false}
                      onChange={() =>
                        setFieldValue(`tests[${activeTabIndex}].expectedResult`, false)
                      }
                    />
                  </Flex>
                </Grid>
                <Box mb={5} data-testid="test-textarea" position="relative">
                  <Flex justifyContent="flex-end" position="absolute" zIndex={1} top={4} right={4}>
                    {Boolean(renderActions) && renderActions({ activeTabIndex })}
                  </Flex>
                  <Field
                    as={FormikEditor}
                    placeholder="# Enter a JSON object describing the resource to test against"
                    name={`tests[${activeTabIndex}].resource`}
                    width="100%"
                    minHeight={340}
                    language="json"
                    readOnly={isManaged}
                  />
                </Box>

                <Box as="section" backgroundColor="navyblue-600" p={4} mb={4}>
                  <Box mb={5}>
                    <Text>Mock Testing</Text>
                  </Box>
                  <FieldArray
                    name={`tests[${activeTabIndex}].mocks`}
                    render={mockArrayHelpers => {
                      return (
                        <Flex direction="column" spacing={4}>
                          {mocks.map((mock, mockIndex) => (
                            <Grid
                              key={`test-${activeTabIndex}-test-${mockIndex}`}
                              templateColumns="7fr 7fr 1fr"
                              gap={4}
                            >
                              <Field
                                as={FormikTextInput}
                                label="Mock Name"
                                placeholder="The name of the function to mock"
                                name={`tests[${activeTabIndex}].mocks[${mockIndex}].objectName`}
                              />
                              <Field
                                as={FormikTextInput}
                                label="Return Value"
                                placeholder="The returned mocked value"
                                name={`tests[${activeTabIndex}].mocks[${mockIndex}].returnValue`}
                              />
                              <Flex spacing={2} mt={2}>
                                {mockIndex > 0 && (
                                  <IconButton
                                    variantColor="navyblue-300"
                                    variantBorderStyle="circle"
                                    size="medium"
                                    icon="trash"
                                    aria-label="Remove mapping"
                                    onClick={() => mockArrayHelpers.remove(mockIndex)}
                                  />
                                )}
                                {mockIndex === mocks.length - 1 && (
                                  <IconButton
                                    disabled={emptyMockExists}
                                    variantColor="navyblue-300"
                                    variantBorderStyle="circle"
                                    size="medium"
                                    icon="add"
                                    aria-label="Add a new mapping"
                                    onClick={() => mockArrayHelpers.push(EMPTY_MOCK)}
                                  />
                                )}
                              </Flex>
                            </Grid>
                          ))}
                          <Text fontSize="small-medium" pt={4}>
                            Follow{' '}
                            <Link
                              mt={2}
                              external
                              textDecoration="underline"
                              href={MOCKED_FUNCTIONS_DOC_URL}
                            >
                              our guide
                            </Link>{' '}
                            to learn more.
                          </Text>
                        </Flex>
                      );
                    }}
                  />
                </Box>

                {renderTestResults}

                <Flex mt={5} spacing={4}>
                  <Button
                    variantColor="yellow-600"
                    icon="play"
                    disabled={!tests[activeTabIndex].resource}
                    onClick={() => runTests([tests[activeTabIndex]])}
                  >
                    Run Test
                  </Button>
                  <Button
                    variantColor="yellow-600"
                    icon="play-all"
                    onClick={() => runTests(tests.filter(test => !!test.resource))}
                  >
                    Run All
                  </Button>
                </Flex>
              </React.Fragment>
            )}
          </Card>
        );
      }}
    />
  );
};

export default React.memo(BaseDetectionFormTestSection);
