/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Card, Link, Text, Flex } from 'pouncejs';
import BulkUploaderWizard from 'Components/wizards/BulkUploaderWizard';
import withRoleRestrictedAccess from 'Hoc/withRoleRestrictedAccess';
import { ANALYSIS_UPLOAD_DOC_URL } from 'Source/constants';
import withSEO from 'Hoc/withSEO';
import { compose } from 'Helpers/compose';
import { Permission } from 'Generated/schema';
import Page403 from 'Pages/403/403';
import BulkDownload from './BulkDownload';

const BulkUploader = () => {
  return (
    <>
      <Card as="section" width={1} mb={6} data-tracking-page="bulk-uploader">
        <BulkUploaderWizard />
      </Card>
      <Flex justify="space-between" align="center">
        <Text fontSize="medium">
          You can find a detailed description of the process in our{' '}
          <Link external href={ANALYSIS_UPLOAD_DOC_URL}>
            designated docs page
          </Link>
          .
        </Text>
        <BulkDownload />
      </Flex>
    </>
  );
};

export default compose(
  withSEO({ title: 'Bulk Uploader' }),
  withRoleRestrictedAccess({
    allowedPermissions: [Permission.PolicyModify, Permission.RuleModify],
    fallback: <Page403 />,
  })
)(BulkUploader);
