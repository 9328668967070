/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import Breadcrumbs from 'Components/Breadcrumbs';
import { Button, Flex, ButtonProps, FlexProps } from 'pouncejs';

type WizardActionButtonProps = ButtonProps & { text?: string };

const WizardEditActions = ({ children, ...flexProps }: React.PropsWithChildren<FlexProps>) => {
  return (
    <Breadcrumbs.Actions>
      <Flex spacing={4} justify="flex-end" {...flexProps}>
        {children}
      </Flex>
    </Breadcrumbs.Actions>
  );
};

const SaveButton = ({ text = 'Save', ...buttonProps }: WizardActionButtonProps) => {
  return (
    <Button variantColor="green-400" icon="save" {...buttonProps}>
      {text}
    </Button>
  );
};

const CancelButton = ({ text = 'Cancel', ...buttonProps }: WizardActionButtonProps) => {
  return (
    <Button variantColor="gray-600" icon="close-outline" {...buttonProps}>
      {text}
    </Button>
  );
};

WizardEditActions.Save = React.memo(SaveButton);
WizardEditActions.Cancel = React.memo(CancelButton);

export default WizardEditActions;
