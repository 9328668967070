/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import useModal from 'Hooks/useModal';
import useRouter from 'Hooks/useRouter';
import Page404 from 'Pages/404';
import Page403 from 'Pages/403';
import Page500 from 'Pages/500';
import useAuth from 'Hooks/useAuth';
import NetworkErrorModal from 'Components/modals/NetworkErrorModal';

export interface LocationErrorState {
  statusCode?: number | string;
  errorId?: string;
}

const ApiErrorFallback: React.FC = ({ children }) => {
  const { location } = useRouter<Record<string, string | number>, LocationErrorState>();
  const { showModal, hideModal } = useModal();
  const { isAuthenticated, signOut } = useAuth();

  const errorCode = location.state?.statusCode;

  const showNetworkErrorModal = React.useCallback(() => {
    showModal(<NetworkErrorModal />, { title: 'No Internet Connection' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const hideNetworkErrorModal = React.useCallback(() => {
    hideModal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    window.addEventListener('offline', showNetworkErrorModal);
    window.addEventListener('online', hideNetworkErrorModal);

    return () => {
      window.removeEventListener('offline', showNetworkErrorModal);
      window.removeEventListener('online', hideNetworkErrorModal);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Protect against case where the token has expired during the time the user was navigating
  // and a page refresh hasn't occured
  React.useEffect(() => {
    if (errorCode === 401 && isAuthenticated) {
      signOut();
    }
    // FIXME: look into hook dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorCode]);

  switch (errorCode) {
    case 404:
      return <Page404 />;
    case 403:
      return <Page403 />;
    case 500:
      return <Page500 />;
    default:
      return children as React.ReactElement;
  }
};

export default ApiErrorFallback;
