/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import * as Types from '../../../../../__generated__/schema';

import { GraphQLError } from 'graphql';
import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';

export type DeleteUserVariables = {
  id: Types.Scalars['ID'];
};

export type DeleteUser = { deleteUser: Pick<Types.DeleteUserOutput, 'id'> };

export const DeleteUserDocument = gql`
  mutation DeleteUser($id: ID!) {
    deleteUser(input: { id: $id }) {
      id
    }
  }
`;
export type DeleteUserMutationFn = ApolloReactCommon.MutationFunction<
  DeleteUser,
  DeleteUserVariables
>;

/**
 * __useDeleteUser__
 *
 * To run a mutation, you first call `useDeleteUser` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUser` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUser, { data, loading, error }] = useDeleteUser({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteUser(
  baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteUser, DeleteUserVariables>
) {
  return ApolloReactHooks.useMutation<DeleteUser, DeleteUserVariables>(
    DeleteUserDocument,
    baseOptions
  );
}
export type DeleteUserHookResult = ReturnType<typeof useDeleteUser>;
export type DeleteUserMutationResult = ApolloReactCommon.MutationResult<DeleteUser>;
export type DeleteUserMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeleteUser,
  DeleteUserVariables
>;
export function mockDeleteUser({
  data,
  variables,
  errors,
}: {
  data: DeleteUser;
  variables?: DeleteUserVariables;
  errors?: GraphQLError[];
}) {
  return {
    request: { query: DeleteUserDocument, variables },
    result: { data, errors },
  };
}
