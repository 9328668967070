/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Box, Flex, Text } from 'pouncejs';
import MitreTacticTechniqueIndicator from 'Pages/Reports/Mitre/MitreTacticTechniqueIndicator';
import TablePlaceholder from 'Components/TablePlaceholder';
import { getActorDisplayName, formatDatetime } from 'Helpers/utils';
import MitreRelationsStatusControl from './MitreRelationsStatusControl';
import {
  GetReportSettingAuditInfo,
  useGetReportSettingAuditInfo,
} from '../graphql/getReportSettingAuditInfo.generated';

interface MitreRelationsHeaderProps {
  tactic: {
    id: string;
    name: string;
  };
  technique: {
    id: string;
    name: string;
  };
}

enum AuditViewState {
  Error,
  Loading,
  Available,
  Unavailable,
}

const lastUpdatedMessage = (reportSetting: GetReportSettingAuditInfo['reportSetting']): string => {
  let msg = `Status last updated by ${getActorDisplayName(reportSetting.updatedBy)}`;
  if (reportSetting.updatedAt) {
    msg += ` at ${formatDatetime(reportSetting.updatedAt)}`;
  }
  return msg;
};

const MitreRelationsHeader = ({ tactic, technique }: MitreRelationsHeaderProps) => {
  const { previousData, data = previousData, loading, error } = useGetReportSettingAuditInfo({
    variables: { tacticId: tactic.id, techniqueId: technique.id },
  });

  const auditView = React.useMemo<AuditViewState>(() => {
    if (error && !loading) {
      return AuditViewState.Error;
    }
    if (!error && !data?.reportSetting.updatedBy) {
      return AuditViewState.Unavailable;
    }
    if (data) {
      return AuditViewState.Available;
    }

    return AuditViewState.Loading;
  }, [error, loading, data]);

  return (
    <Box px={4} py={2} mb={4} backgroundColor="navyblue-600">
      <Flex alignItems="center" justifyContent="space-between">
        <MitreTacticTechniqueIndicator tactic={tactic} technique={technique} />
        <MitreRelationsStatusControl />
      </Flex>
      <Box pb={1} fontSize="medium">
        {auditView === AuditViewState.Loading && (
          <Box width={400}>
            <TablePlaceholder rowHeight={12} rowCount={1} rowGap={0} />
          </Box>
        )}
        {auditView === AuditViewState.Available && (
          <Text
            color="navyblue-100"
            opacity={loading ? 0.5 : 1}
            transition="200ms opacity ease-in-out"
          >
            {lastUpdatedMessage(data.reportSetting)}
          </Text>
        )}
        {auditView === AuditViewState.Unavailable && (
          <Text color="navyblue-100">Status has not yet been updated.</Text>
        )}
        {auditView === AuditViewState.Error && (
          <Text color="red-200">Failed to retrieve last updated information.</Text>
        )}
      </Box>
    </Box>
  );
};

export default React.memo(MitreRelationsHeader);
