/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Box, Flex } from 'pouncejs';
import { LogTransportMethodsEnum } from 'Source/constants';
import AvailableTransportMethod from 'Pages/Integrations/LogSources/LogSourceOnboarding/AvailableTransportMethod';

const LogTransportMethodsList = [
  LogTransportMethodsEnum.s3,
  LogTransportMethodsEnum.sqs,
  LogTransportMethodsEnum.cloudwatch,
  LogTransportMethodsEnum.gcs,
];

const ListCustomOnboarding = () => {
  return (
    <Box margin="auto" maxWidth={700} p={4}>
      <Flex flexDirection="column" width="100%" justify="center" spacing={4}>
        {LogTransportMethodsList.map(method => (
          <AvailableTransportMethod key={method} method={method} extended />
        ))}
      </Flex>
    </Box>
  );
};

export default ListCustomOnboarding;
