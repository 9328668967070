/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { DestinationDetails } from 'Source/graphql/fragments/DestinationDetails.generated';
import { DESTINATIONS } from 'Source/constants';
import { DestinationTypeEnum } from 'Generated/schema';
import DestinationCard from './DestinationCard';

interface SlackDestinationCardProps {
  destination: DestinationDetails;
}

const SlackDestinationCard: React.FC<SlackDestinationCardProps> = ({ destination }) => {
  return (
    <DestinationCard
      logo={DESTINATIONS[DestinationTypeEnum.Slack].logo}
      destination={destination}
    />
  );
};

export default React.memo(SlackDestinationCard);
