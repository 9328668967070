/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { theme, Box } from 'pouncejs';
import { MitreMatrixCoverageStatus } from '../MitreContext';

interface MitreMatrixGridCharmProps {
  status: MitreMatrixCoverageStatus;
}

const MitreMatrixGridCharm = ({ status }: MitreMatrixGridCharmProps) => {
  let svg: React.ReactNode;

  switch (status) {
    case 'covered':
      svg = <Covered />;
      break;
    case 'partiallyCovered':
      svg = <PartiallyCovered />;
      break;
    case 'notRelevant':
      svg = <NotRelevant />;
      break;
    case 'notCovered':
      svg = <NotCovered />;
      break;
    case 'error':
      svg = <Error />;
      break;
    default:
      svg = <Error />;
      break;
  }

  return (
    <Box width="24px" height="24px">
      {svg}
    </Box>
  );
};

const Covered = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="24" height="24" rx="4" fill={theme.colors['teal-200']} />
    </svg>
  );
};

const PartiallyCovered = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="24" height="24" rx="4" fill={theme.colors['teal-200']} />
      <circle cx="12" cy="12" r="5" fill={theme.colors['navyblue-400']} />
    </svg>
  );
};

const NotRelevant = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="24" height="24" rx="4" fill={theme.colors['navyblue-300']} />
    </svg>
  );
};

const NotCovered = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect opacity="0.69" width="24" height="24" rx="4" fill={theme.colors['red-300']} />
      <line
        x1="1.06066"
        y1="1.93934"
        x2="24"
        y2="23"
        stroke={theme.colors['navyblue-400']}
        strokeWidth="3"
      />
    </svg>
  );
};

const Error = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect opacity="0.69" width="24" height="24" rx="4" fill="#FFA500" />
      <text x="0" y="12">
        ERR
      </text>
    </svg>
  );
};

export default React.memo(MitreMatrixGridCharm);
