/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import * as Types from '../../../__generated__/schema';

import { LogPullingIntegrationMain } from './LogPullingIntegrationMain.generated';
import { GraphQLError } from 'graphql';
import gql from 'graphql-tag';

export type GithubLogIntegrationDetails = {
  pullerConfig: {
    github?: Types.Maybe<
      Pick<Types.GithubLogSourceConfig, 'organization' | 'authorizationMethod'> & {
        credentials:
          | Pick<Types.GithubLogSourceOAuth2Config, 'clientId' | 'clientSecret'>
          | Pick<Types.GithubLogSourcePatConfig, 'personalAccessToken'>;
      }
    >;
  };
} & LogPullingIntegrationMain;

export const GithubLogIntegrationDetails = gql`
  fragment GithubLogIntegrationDetails on LogPullingIntegration {
    ...LogPullingIntegrationMain
    pullerConfig {
      github {
        organization
        authorizationMethod
        credentials {
          ... on GithubLogSourceOAuth2Config {
            clientId
            clientSecret
          }
          ... on GithubLogSourcePatConfig {
            personalAccessToken
          }
        }
      }
    }
  }
  ${LogPullingIntegrationMain}
`;
