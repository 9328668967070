/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Alert, Box, Flex, useSnackbar } from 'pouncejs';
import useModal from 'Hooks/useModal';
import { Field, Form, Formik } from 'formik';
import FormikTextInput from 'Components/fields/TextInput';
import SubmitButton from 'Components/buttons/SubmitButton';
import FormikTextArea from 'Components/fields/TextArea';
import { useRequestLogType } from 'Components/modals/RequestLogSourceModal/graphql/requestLogType.generated';
import { extractErrorMessage } from 'Helpers/utils';

interface FormValues {
  name: string;
  description: string;
}

const initialValues = {
  name: '',
  description: '',
};

const RequestLogSourceModal: React.FC = () => {
  const { hideModal } = useModal();
  const { pushSnackbar } = useSnackbar();
  const [requestLogType, { error }] = useRequestLogType({
    onCompleted: () => {
      hideModal();
      pushSnackbar({
        variant: 'success',
        title: 'Your request was submitted successfully',
      });
    },
    onError: () => null,
  });

  const handleSubmit = async (values: FormValues) => {
    requestLogType({
      variables: {
        input: {
          name: values.name,
          description: values.description,
        },
      },
    });
  };

  return (
    <Box width={450}>
      {error && (
        <Box mb={4}>
          <Alert
            variant="error"
            title="Unable to request a new log source"
            description={extractErrorMessage(error) || 'An unknown error occurred'}
          />
        </Box>
      )}
      <Formik<FormValues> initialValues={initialValues} onSubmit={handleSubmit}>
        <Form>
          <Flex direction="column" spacing={5}>
            <Field
              name="name"
              label="Log Source *"
              as={FormikTextInput}
              placeholder="Your requested Log Source"
              required
            />
            <Field
              name="description"
              label="What use cases will this address?"
              as={FormikTextArea}
            />
            <SubmitButton fullWidth>Create Request</SubmitButton>
          </Flex>
        </Form>
      </Formik>
    </Box>
  );
};

export default RequestLogSourceModal;
