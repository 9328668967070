/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Box, Combobox, ComboboxProps, FormError } from 'pouncejs';
import { FieldConfig } from 'formik';
import useFastField from 'Hooks/useFastField';

function FormikCombobox<T>(
  props: ComboboxProps<T> & Required<Pick<FieldConfig, 'name'>>
): React.ReactNode {
  const { name, ...rest } = props;

  const [, meta, { setValue }] = useFastField(name);

  const isInvalid = meta.touched && !!meta.error;
  const errorElementId = isInvalid ? `${name}-error` : undefined;

  return (
    <Box>
      <Combobox
        {...rest}
        onChange={setValue}
        invalid={isInvalid}
        aria-describedby={isInvalid ? errorElementId : undefined}
      />
      {isInvalid && (
        <FormError mt={2} id={errorElementId}>
          {meta.error}
        </FormError>
      )}
    </Box>
  );
}

export default FormikCombobox;
