/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { Alert, Box, Flex, TextInput, useSnackbar } from 'pouncejs';
import SubmitButton from 'Components/buttons/SubmitButton';
import FormikTextInput from 'Components/fields/TextInput';
import useRouter from 'Hooks/useRouter';
import useAuth from 'Hooks/useAuth';
import urls from 'Source/urls';
import { yupPasswordValidationSchema } from 'Helpers/utils';
import FieldPolicyChecker from 'Components/FieldPolicyChecker/FieldPolicyChecker';

interface ForgotPasswordConfirmFormProps {
  email: string;
  token: string;
}

interface ForgotPasswordConfirmFormValues {
  newPassword: string;
  confirmNewPassword: string;
}

const validationSchema = Yup.object().shape({
  newPassword: yupPasswordValidationSchema,
  confirmNewPassword: yupPasswordValidationSchema.oneOf(
    [Yup.ref('newPassword')],
    'Passwords must match'
  ),
});

const ForgotPasswordConfirmForm: React.FC<ForgotPasswordConfirmFormProps> = ({ email, token }) => {
  const { history } = useRouter();
  const { resetPassword } = useAuth();
  const { pushSnackbar } = useSnackbar();

  const initialValues = {
    newPassword: '',
    confirmNewPassword: '',
  };

  return (
    <Formik<ForgotPasswordConfirmFormValues>
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={({ newPassword }, { setStatus }) =>
        resetPassword({
          email,
          token,
          newPassword,
          onSuccess: () => {
            pushSnackbar({ variant: 'info', title: 'Password changed successfully!' });
            history.replace(urls.account.auth.signIn());
          },
          onError: ({ message }) =>
            setStatus({
              title: 'Houston, we have a problem',
              message,
            }),
        })
      }
    >
      {({ status, values }) => (
        <Form>
          <Flex direction="column" spacing={4}>
            {status && <Alert variant="error" title={status.title} description={status.message} />}
            <Field
              as={TextInput}
              label="New Password"
              placeholder="Type your new password..."
              type="password"
              name="newPassword"
              required
              autoComplete="new-password"
            />
            <Field
              as={FormikTextInput}
              label="Confirm New Password"
              placeholder="Type your new password again..."
              type="password"
              name="confirmNewPassword"
              required
              autoComplete="new-password"
            />
            <Box py={3}>
              <FieldPolicyChecker schema={yupPasswordValidationSchema} value={values.newPassword} />
            </Box>
            <SubmitButton fullWidth>Update password</SubmitButton>
          </Flex>
        </Form>
      )}
    </Formik>
  );
};

export default ForgotPasswordConfirmForm;
