/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import FormikTextInput from 'Components/fields/TextInput';
import { FastField } from 'formik';
import { Card, Flex, Heading, Link, SimpleGrid, Text } from 'pouncejs';
import React from 'react';
import { LUTS_S3_ROLE_DOC_URL } from 'Source/constants';

const LookupIAMPolicyManual: React.FC = () => {
  return (
    <SimpleGrid gap={8} mx="auto">
      <Card as="section" variant="dark" py={4} px={10}>
        <Text fontSize="x-large" textAlign="center">
          Go to
          <Link external mx={1} href={LUTS_S3_ROLE_DOC_URL} data-tid="lookup-iam-role-manual-docs">
            our documentation
          </Link>
          and read the instructions. After setting up the role, return here to continue the set up
          process
        </Text>
      </Card>
      <Flex as="section" spacing={2} direction="column">
        <Heading as="h2" color="teal-200" fontSize="x-large" fontWeight="medium">
          Enter Role ARN
        </Heading>
        <Text color="white-100" fontSize="medium" lineHeight="relaxed">
          Go to the {'"'}Outputs{'"'} tab of the CloudFormation stack that you just deployed and
          copy the value for Bucket Name and RoleARN into the box below.
        </Text>

        <FastField
          name="iamRoleArn"
          as={FormikTextInput}
          label="Role ARN"
          placeholder="The ARN that Panther will use to read s3 objects."
          trimPastedText
          required
          data-tid="lookup-iam-role-arn-field"
        />
      </Flex>
    </SimpleGrid>
  );
};

export default LookupIAMPolicyManual;
