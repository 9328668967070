/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import isString from 'lodash/isString';
import useIndicatorSearchModal from 'Hooks/useIndicatorSearchModal';
import NodeAction from 'Components/JsonViewer/NodeAction';
import NodeCopyAction from 'Components/JsonViewer/NodeCopyAction';
import { ActionsProps } from 'Components/JsonViewer/JsonViewer';

type EventNodeActionsProps = ActionsProps & { dateCreated?: string; dateEnded?: string };

const EventNodeActions: React.FC<EventNodeActionsProps> = ({
  data,
  keyPath,
  dateCreated,
  dateEnded,
}) => {
  const showIndicatorSearchModal = useIndicatorSearchModal();

  // As of now there is no other way to detect if a field can be used in the indicator search.
  // As a workaround and since a field might be arbitrarily nested in the json tree we
  // have to check the entire tree path to determine if any path label starts with the
  // panther defined keyword 'p_any_'.
  //
  // Even if the key is correct, we also need to validate that the value isn't null to
  // prevent the indicator search modal from throwing exceptions.
  const isValidIndicatorSearchInput =
    data !== null && keyPath.some(key => isString(key) && key.startsWith('p_any_'));

  return (
    <>
      <NodeCopyAction data={data} />

      {isValidIndicatorSearchInput && (
        <NodeAction
          icon="search"
          label="Indicator Search"
          data-tid="open-indicator-search-modal"
          onClick={() => {
            showIndicatorSearchModal({ indicator: data as string, dateCreated, dateEnded });
          }}
        />
      )}
    </>
  );
};

export default React.memo(EventNodeActions);
