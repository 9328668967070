/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import SubmitButton from 'Components/buttons/SubmitButton';
import FormikTextInput from 'Components/fields/TextInput';
import useAuth from 'Hooks/useAuth';
import { Card, Flex, FormHelperText } from 'pouncejs';

interface ForgotPasswordFormValues {
  email: string;
}

const initialValues = {
  email: '',
};

const validationSchema = Yup.object().shape({
  email: Yup.string().email('Needs to be a valid email').required(),
});

const ForgotPasswordForm: React.FC = () => {
  const { forgotPassword } = useAuth();

  return (
    <Formik<ForgotPasswordFormValues>
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={async ({ email }, { setErrors, setStatus }) =>
        forgotPassword({
          email,
          onSuccess: () => setStatus('SENT'),
          onError: ({ message }) => setErrors({ email: message }),
        })
      }
    >
      {({ status, values }) => {
        if (status === 'SENT') {
          return (
            <Card bg="teal-500" p={5} mb={8} boxShadow="none" fontSize="medium">
              We have successfully sent you an email with reset instructions at{' '}
              <b>{values.email}</b>
            </Card>
          );
        }

        return (
          <Form>
            <Flex direction="column" spacing={4}>
              <Field
                as={FormikTextInput}
                label="Email"
                placeholder="Enter your company email..."
                type="email"
                name="email"
                required
              />
              <SubmitButton fullWidth aria-describedby="forgot-password-description">
                Reset Password
              </SubmitButton>
              <FormHelperText id="forgot-password-description" textAlign="center">
                By submitting a request, you will receive an email with instructions on how to reset
                your password
              </FormHelperText>
            </Flex>
          </Form>
        );
      }}
    </Formik>
  );
};

export default ForgotPasswordForm;
