/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { FastField, Form, Formik } from 'formik';
import {
  Box,
  Button,
  Card,
  Flex,
  Popover,
  PopoverContent,
  PopoverTrigger,
  SimpleGrid,
} from 'pouncejs';
import { ListSchemasInput } from 'Generated/schema';
import useRequestParamsWithoutPagination from 'Hooks/useRequestParamsWithoutPagination';
import isUndefined from 'lodash/isUndefined';
import TextButton from 'Components/buttons/TextButton';
import FormikCombobox from 'Components/fields/ComboBox';
import PopoverAutoSubmit from 'Components/PopoverAutoSubmit';

const defaultValues = {
  isDisabled: null,
  isManaged: null,
  isActive: null,
};

const enabledFilterToString = (item: boolean | null) => {
  if (item === null) {
    return '';
  }

  return item ? 'Disabled' : 'Enabled';
};

const enabledUsageFilterToString = (item: boolean | null) => {
  if (item === null) {
    return '';
  }

  return item ? 'In use' : 'Not in use';
};

const originFilterToString = (item: boolean | null) => {
  if (item === null) {
    return '';
  }

  return item ? 'Panther Managed' : 'User Defined';
};

const comboboxOpts = [true, false];
const disabledComboOpts = [false, true];

const DropdownFilters: React.FC = () => {
  const { requestParams, updateRequestParams } = useRequestParamsWithoutPagination<
    ListSchemasInput
  >();

  const initialDropdownFilters = React.useMemo(
    () =>
      ({
        ...defaultValues,
        ...requestParams,
      } as ListSchemasInput),
    [requestParams]
  );

  const filtersCount = Object.keys(defaultValues).filter(key => !isUndefined(requestParams[key]))
    .length;

  return (
    <Popover>
      {({ close: closePopover, isOpen }) => (
        <React.Fragment>
          <PopoverTrigger
            as={Button}
            iconAlignment="right"
            icon="filter-light"
            size="large"
            aria-label="Schemas Options"
          >
            Filters {filtersCount ? `(${filtersCount})` : ''}
          </PopoverTrigger>
          <PopoverContent>
            <Card
              shadow="dark300"
              my={14}
              p={6}
              pb={4}
              backgroundColor="navyblue-400"
              width={425}
              data-testid="dropdown-data-schema-listing-filters"
            >
              <Formik<ListSchemasInput>
                enableReinitialize
                onSubmit={(values: ListSchemasInput) => {
                  updateRequestParams(values);
                }}
                initialValues={initialDropdownFilters}
              >
                {({ setValues, values }) => (
                  <Form>
                    <PopoverAutoSubmit<ListSchemasInput>
                      isOpen={isOpen}
                      values={values}
                      onSubmit={updateRequestParams}
                    />
                    <SimpleGrid pb={4} columns={2} gap={2}>
                      <FastField
                        name="isActive"
                        as={FormikCombobox}
                        items={comboboxOpts}
                        itemToString={enabledUsageFilterToString}
                        label="Usage Status"
                      />

                      <FastField
                        name="isDisabled"
                        as={FormikCombobox}
                        items={disabledComboOpts}
                        itemToString={enabledFilterToString}
                        label="Enabled"
                      />
                    </SimpleGrid>
                    <Box pb={4}>
                      <FastField
                        name="isManaged"
                        as={FormikCombobox}
                        items={comboboxOpts}
                        itemToString={originFilterToString}
                        label="Schema Origin"
                      />
                    </Box>

                    <Flex direction="column" justify="center" align="center" spacing={4}>
                      <Box>
                        <Button onClick={closePopover}>Apply Filters</Button>
                      </Box>
                      <TextButton role="button" onClick={() => setValues(defaultValues)}>
                        Clear Filters
                      </TextButton>
                    </Flex>
                  </Form>
                )}
              </Formik>
            </Card>
          </PopoverContent>
        </React.Fragment>
      )}
    </Popover>
  );
};

export default React.memo(DropdownFilters);
