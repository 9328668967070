/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Box, Card, Flex, Link, Text } from 'pouncejs';
import { Link as RRLink } from 'react-router-dom';
import urls from 'Source/urls';
import { Permission } from 'Generated/schema';
import { PackSourceDetails } from 'Source/graphql/fragments/PackSourceDetails.generated';
import { getElapsedTime } from 'Helpers/utils';
import RoleRestrictedAccess from 'Components/utils/RoleRestrictedAccess/RoleRestrictedAccess';
import DisabledLink from 'Components/DisabledLink';
import CardValue from 'Components/cards/common/CardValue';
import PackSourceCardOptions from './PackSourceCardOptions';

interface PackSourceCardProps {
  packSource: PackSourceDetails;
}

const PackSourceCard: React.FC<PackSourceCardProps> = ({ packSource }) => {
  const lastModifiedTime = React.useMemo(
    () => Math.floor(new Date(packSource.lastModified).getTime() / 1000),
    [packSource.lastModified]
  );
  return (
    <Card as="section" variant="dark" p={4} data-testid="pack-source">
      <Flex direction="column" justify="space-between" width={1}>
        <Flex as="header" align="flex-start" mb={2}>
          <Box as="h4" fontWeight="medium" wordBreak="break-word">
            <RoleRestrictedAccess
              allowedPermissions={[Permission.RuleModify, Permission.PolicyModify]}
              fallback={<DisabledLink>{packSource.id}</DisabledLink>}
            >
              <Link
                as={RRLink}
                aria-label="Edit Pack Source"
                to={urls.analysis.packs.packSources.edit(packSource.id)}
              >
                {packSource.id}
              </Link>
            </RoleRestrictedAccess>
          </Box>
          <Box mr={0} ml="auto" fontSize="small">
            <Text mr={1} color="navyblue-100" as="span">
              Last updated
            </Text>
            <Text as="time">{getElapsedTime(lastModifiedTime)}</Text>
          </Box>
          <RoleRestrictedAccess
            allowedPermissions={[Permission.PolicyModify, Permission.RuleModify]}
          >
            <Box mt={-1} ml={2}>
              <PackSourceCardOptions packSource={packSource} />
            </Box>
          </RoleRestrictedAccess>
        </Flex>
        <Flex width={1} justify="space-between" mt={3}>
          <CardValue label="Github Owner" value={packSource.owner} />
          <CardValue label="Github Repo" value={packSource.repository} />
          <CardValue
            label="Private Repo"
            // if githubAccessToken is an empty string that means that there is an access token and the repo is private
            value={typeof packSource.githubAccessToken === 'string' ? 'Yes' : 'No'}
          />
        </Flex>
      </Flex>
    </Card>
  );
};

export default React.memo(PackSourceCard);
