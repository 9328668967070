/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import AdditionalBadgesJewel from 'Components/AdditionalBadgesJewel';
import BulletDivider from 'Components/BulletDivider';
import React from 'react';
import { Box, Button, Card, Flex, Heading, Text, Link, Badge } from 'pouncejs';
import { Permission, DetectionTypeEnum } from 'Generated/schema';
import { Link as RRLink } from 'react-router-dom';
import urls from 'Source/urls';
import { ENTITIES_COLOR_MAP } from 'Source/constants';
import SeverityBadge from 'Components/badges/SeverityBadge';
import StatusBadge from 'Components/badges/StatusBadge';
import LinkButton from 'Components/buttons/LinkButton';
import Breadcrumbs from 'Components/Breadcrumbs';
import BulletedValue from 'Components/BulletedValue';
import RelatedDestinations from 'Components/RelatedDestinations/RelatedDestinations';
import useDetectionDestinations from 'Hooks/useDetectionDestinations';
import RoleRestrictedAccess from 'Components/utils/RoleRestrictedAccess';
import LimitItemDisplay from 'Components/LimitItemDisplay';
import ManagedBadge from 'Components/badges/ManagedBadge';
import { RuleDetails } from 'Source/graphql/fragments/RuleDetails.generated';
import useCloneRule from 'Hooks/useCloneRule';

interface ResourceDetailsInfoProps {
  rule?: RuleDetails;
}

const RuleDetailsBanner: React.FC<ResourceDetailsInfoProps> = ({ rule }) => {
  const {
    detectionDestinations,
    loading: loadingDetectionDestinations,
  } = useDetectionDestinations({ detection: rule });
  const { cloneRule, isCloning } = useCloneRule();

  const isScheduledRule = rule.analysisType === DetectionTypeEnum.ScheduledRule;
  const ruleTypeAccentColor = ENTITIES_COLOR_MAP[rule.analysisType];

  return (
    <React.Fragment>
      <RoleRestrictedAccess allowedPermissions={[Permission.RuleModify]}>
        <Breadcrumbs.Actions>
          <Flex spacing={4} justify="flex-end">
            <LinkButton
              icon="pencil"
              variant="outline"
              variantColor="navyblue-200"
              data-tid="edit-rule"
              aria-label="Edit Rule"
              to={urls.analysis.rules.edit(rule.id)}
            >
              Edit
            </LinkButton>
            {/* Hide delete button for managed detections to prevent orphaned clones */}
            {rule.managed && (
              <Button
                icon="copy"
                onClick={() => cloneRule(rule.id)}
                variant="outline"
                data-tid="clone-rule"
                variantColor="navyblue-200"
              >
                {isCloning ? 'Cloning...' : 'Clone & edit'}
              </Button>
            )}
          </Flex>
        </Breadcrumbs.Actions>
      </RoleRestrictedAccess>
      <Card as="article" p={6} borderLeft="4px solid" borderColor={ruleTypeAccentColor}>
        <Flex as="header" align="center">
          <Heading
            fontWeight="bold"
            wordBreak="break-word"
            aria-describedby="rule-description"
            flexShrink={1}
            display="flex"
            alignItems="center"
            mr={100}
          >
            {rule.displayName || rule.id}
          </Heading>

          <Flex flexShrink={0} ml="auto" alignItems="center">
            <RelatedDestinations
              destinations={detectionDestinations}
              loading={loadingDetectionDestinations}
              limit={5}
            />
            <BulletDivider />
            <Flex spacing={2} as="ul">
              <Box as="li">
                <ManagedBadge managed={rule.managed} />
              </Box>
              <Box as="li">
                <StatusBadge status="ENABLED" disabled={!rule.enabled} />
              </Box>
              <Box as="li">
                <SeverityBadge severity={rule.severity} />
              </Box>
            </Flex>
          </Flex>
        </Flex>

        <Flex as="dl" fontSize="small-medium" pt={1} wrap="wrap" alignItems="center">
          <Box>
            <BulletedValue
              size="small"
              color={ruleTypeAccentColor}
              value={isScheduledRule ? 'Scheduled Rule' : 'Rule'}
              emphasized
            />
          </Box>
          <BulletDivider />
          <Box>
            <Text as="dt" fontWeight="bold" display="inline" mr={1}>
              ID
            </Text>
            <Text as="dd" display="inline" color="gray-300">
              {rule.id}
            </Text>
          </Box>
          {rule.parentId && (
            <>
              <BulletDivider />
              <Flex>
                <Text as="dt" fontWeight="bold" mr={1}>
                  Parent ID
                </Text>
                <Box as="dd">
                  <Link as={RRLink} to={urls.analysis.rules.details(rule.parentId)}>
                    {rule.parentId}
                  </Link>
                </Box>
              </Flex>
            </>
          )}
        </Flex>
        <Flex pt={3} spacing={1}>
          <LimitItemDisplay limit={3} AdditionalItemsComponent={AdditionalBadgesJewel}>
            {isScheduledRule
              ? rule.scheduledQueries.map(item => (
                  <Badge key={item} color="navyblue-200">
                    {item}
                  </Badge>
                ))
              : rule.logTypes.map(item => (
                  <Badge key={item} color="navyblue-200">
                    {item}
                  </Badge>
                ))}
          </LimitItemDisplay>
        </Flex>
      </Card>
    </React.Fragment>
  );
};

export default React.memo(RuleDetailsBanner);
