/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { ReplayState } from 'Generated/schema';
import { Box, SimpleGrid, Text } from 'pouncejs';
import { formatDatetime } from 'Helpers/utils';
import { ReplayStageViewProps } from 'Components/forms/ReplayForm/ReplayStatus/ReplayStatus';
import dayjs from 'dayjs';
import 'dayjs/plugin/duration';

const ReplayStatusSummary: React.FC<ReplayStageViewProps> = ({ replay }) => {
  let description: string;

  switch (!!replay) {
    case false:
      break;
    default:
    case true:
      switch (replay.state) {
        case ReplayState.EvaluationInProgress:
          description = 'Panther is running your detection against historical data.';
          break;
        case ReplayState.ComputationInProgress:
          description =
            'Panther is simulating alerts using historical data. Detections with high alert rates will take longer in the simulation phase.';
          break;
        case ReplayState.Unknown:
        case ReplayState.ErrorEvaluation:
          description =
            'Last replay failed during evaluation. You can retry by resubmitting the form above.';
          break;
        case ReplayState.ErrorComputation:
          description =
            'Last replay failed during simulation. This can happen when the "alert rate" is high. Double check the definition of an alert or try using a shorter time frame.';
          break;
        case ReplayState.Canceled:
          description = 'Last replay canceled. You can restart by resubmitting the form above.';
          break;
        case ReplayState.Done:
        default:
          description = 'Replay processing complete. Results are shown below.';
      }
  }

  return (
    <>
      <Box mb={6}>{description && <Text mt={4}>{description}</Text>}</Box>
      {replay && (
        <SimpleGrid as="dl" gap={2} columns={8} spacing={2} fontSize="small">
          <Box as="dt" color="navyblue-100" gridColumn="1/3" aria-describedby="created-at">
            Created at
          </Box>
          <Box as="dd" gridColumn="3/8" id="created-at">
            {formatDatetime(replay.createdAt)}
          </Box>
          <Box as="dt" color="navyblue-100" gridColumn="1/3" aria-describedby="created-at">
            Completed at
          </Box>
          <Box as="dd" gridColumn="3/8" id="created-at">
            <ReplayCompletedAtDisplay replay={replay} />
          </Box>
          <Box as="dt" mt={2} color="navyblue-100" gridColumn="1/3" aria-describedby="log-types">
            Log Types
          </Box>
          <Box as="dd" mt={2} gridColumn="3/8" id="log-types">
            {replay.scope.logTypes.join(', ')}
          </Box>
          <Box as="dt" color="navyblue-100" gridColumn="1/3" aria-describedby="date-start">
            Log Date Start
          </Box>
          <Box as="dd" gridColumn="3/8" id="date-start">
            {formatDatetime(replay.scope.startsAt)}
          </Box>
          <Box as="dt" color="navyblue-100" gridColumn="1/3" aria-describedby="date-end">
            Log Date End
          </Box>
          <Box as="dd" gridColumn="3/8" id="date-end">
            {formatDatetime(replay.scope.endsAt)}
          </Box>
        </SimpleGrid>
      )}
    </>
  );
};

const ReplayCompletedAtDisplay = ({ replay }: ReplayStageViewProps) => {
  if (!replay) {
    return null;
  }

  if (
    replay.state === ReplayState.ErrorComputation ||
    replay.state === ReplayState.EvaluationInProgress
  ) {
    return (
      <Text fontStyle="italic" color="navyblue-100">
        failed to complete
      </Text>
    );
  }

  if (replay.state !== ReplayState.Done) {
    return (
      <Text fontStyle="italic" color="navyblue-100">
        pending
      </Text>
    );
  }

  // backwards compatibility for replays created before computation complete was recorded (<= v1.33)
  if (!replay.summary.completedAt) {
    return (
      <Text
        fontStyle="italic"
        color="navyblue-100"
        title="This replay did not record a completed at timestamp."
      >
        not available
      </Text>
    );
  }

  const diffMinutes = dayjs(replay.summary.completedAt).diff(
    dayjs(replay.createdAt),
    'minute',
    true
  );
  const diffDisplay =
    diffMinutes < 1
      ? 'finished in less than a minute'
      : `finished in about ${Math.ceil(diffMinutes)} minutes`;

  return (
    <Text>
      {formatDatetime(replay.summary.completedAt)} ({diffDisplay})
    </Text>
  );
};

export default ReplayStatusSummary;
