/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import { FastField, useFormikContext } from 'formik';
import FormikTextInput from 'Components/fields/TextInput';
import {
  Box,
  Flex,
  FormHelperText,
  Heading,
  IconButton,
  Card,
  Tooltip,
  SimpleGrid,
  Grid,
  Text,
} from 'pouncejs';
import React from 'react';
import { RuleFormValues } from 'Components/forms/RuleForm';
import { PolicyFormValues } from 'Components/forms/PolicyForm';
import FormikSwitch from 'Components/fields/Switch';
import FormikCombobox from 'Components/fields/ComboBox';
import FormikMultiCombobox from 'Components/fields/MultiComboBox';
import { ENTITIES_COLOR_MAP, RESOURCE_TYPES } from 'Source/constants';
import { Severity } from 'Generated/schema';
import { capitalize } from 'Helpers/utils';

const severityOptions = Object.values(Severity);
const severityItemToString = (severity: string) => capitalize(severity.toLowerCase());

const PolicyFormRequiredSection = () => {
  const [isPolicyIdEditable, setIsPolicyIdEditable] = React.useState(false);
  const { values, initialValues, setFieldValue, touched } = useFormikContext<
    RuleFormValues | PolicyFormValues
  >();
  const { managed: isManaged } = initialValues;

  React.useEffect(() => {
    if (values.displayName && !touched.id && !initialValues.id) {
      const autoId = values.displayName.trim().split(' ').join('.');
      setFieldValue('id', autoId);
    }
  }, [setFieldValue, values.displayName, touched, initialValues]);

  return (
    <Card
      mb={6}
      p={6}
      position="relative"
      borderLeft="4px solid"
      borderColor={ENTITIES_COLOR_MAP.POLICY}
    >
      <Flex direction="column" spacing={4}>
        <Flex justify="space-between">
          <Flex alignItems="baseline">
            <Heading wordBreak="break-word" flexShrink={1} mr={2}>
              Basic Info
            </Heading>
            <Text color="navyblue-100">(Required)</Text>
          </Flex>
          <FastField
            data-testid="enabled-toggle"
            as={FormikSwitch}
            name="enabled"
            label="Enabled"
          />
        </Flex>
        <Card variant="dark" p={4}>
          <SimpleGrid columns={2} spacing={2}>
            <Flex flexGrow={1} direction="column" spacing={3}>
              <FastField
                as={FormikTextInput}
                label="Name"
                placeholder="A human-friendly name for this Policy"
                name="displayName"
                disabled={isManaged}
                required
              />
              {isPolicyIdEditable ? (
                <Grid templateColumns="11fr 1fr">
                  <FastField
                    as={FormikTextInput}
                    label="Policy ID"
                    placeholder="The unique ID of this Policy"
                    name="id"
                    disabled={!!initialValues.id || isManaged}
                    required
                  />
                  <Box mx={2}>
                    <IconButton
                      onClick={() => setIsPolicyIdEditable(false)}
                      aria-label="save-policy-id"
                      icon="check"
                      variant="ghost"
                    />
                  </Box>
                </Grid>
              ) : (
                <FormHelperText id="policy-id" data-testid="policy-id" ml={3}>
                  {initialValues.id ? `ID: ${initialValues.id}` : `ID: ${values.id}`}
                  {!initialValues.id && (
                    <Box as="span" mx={2}>
                      <Tooltip content="Edit Policy ID">
                        <IconButton
                          onClick={() => setIsPolicyIdEditable(true)}
                          aria-label="edit-policy-id"
                          variant="ghost"
                          size="small"
                          icon="edit"
                        />
                      </Tooltip>
                    </Box>
                  )}
                </FormHelperText>
              )}
            </Flex>
            <Flex flexGrow={1} direction="column" spacing={3}>
              <FastField
                as={FormikCombobox}
                name="severity"
                items={severityOptions}
                itemToString={severityItemToString}
                label="Severity"
                showClearSelectionControl={false}
              />
              <Box as="fieldset">
                <FastField
                  as={FormikMultiCombobox}
                  searchable
                  label="Resource Types"
                  name="resourceTypes"
                  items={RESOURCE_TYPES}
                  placeholder="Where should the policy apply?"
                  aria-describedby="resourceTypes-description"
                  disabled={isManaged}
                />
                <FormHelperText id="resourceTypes-description" mt={2}>
                  Leave empty to apply to all resources
                </FormHelperText>
              </Box>
            </Flex>
          </SimpleGrid>
        </Card>
      </Flex>
    </Card>
  );
};

export default PolicyFormRequiredSection;
