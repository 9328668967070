/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

const walk = (obj: Record<string, any>, callback: (leafPath: string) => void, path = '') => {
  Object.keys(obj).forEach(key => {
    const value = obj[key];
    if (value === Object(value) || Array.isArray(value)) {
      walk(value, callback, path ? `${path}.${key}` : key);
    } else {
      callback(path ? `${path}.${key}` : key);
    }
  });
};

export default walk;
