/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import { useFormikContext } from 'formik';
import { formatJSON } from 'Helpers/utils';
import { Button, useSnackbar } from 'pouncejs';
import React from 'react';
import { useGenerateEnrichedEventLazyQuery } from 'Source/graphql/queries/generateEnrichedEvent.generated';
import { RuleFormValues } from '../..';

interface EnrichButtonProps {
  activeTabIndex: number;
}

const EnrichButton: React.FC<EnrichButtonProps> = ({ activeTabIndex }) => {
  const { pushSnackbar } = useSnackbar();
  const {
    values: { tests },
    setFieldValue,
  } = useFormikContext<RuleFormValues>();
  const testEvent = tests[activeTabIndex].resource;

  const [generateEnrichedEvent, { loading }] = useGenerateEnrichedEventLazyQuery({
    fetchPolicy: 'network-only', // no cache because it avoids messy logic that would be required with cache... user replacing the current test with a new one for example.  It is inexpensive to force a refetch each time, as well
    onCompleted: data => {
      const enrichedTestEventField = data.generateEnrichedEvent.enrichedEvent;
      setFieldValue(`tests[${activeTabIndex}].resource`, formatJSON(enrichedTestEventField));
      pushSnackbar({
        variant: 'info',
        title: 'p_enrichment data pasted in test',
      });
    },
    onError: () =>
      pushSnackbar({
        variant: 'error',
        title: `Couldn't fetch enriched event`,
      }),
  });

  return (
    <Button
      loading={loading}
      onClick={() => {
        let event;
        try {
          event = JSON.parse(testEvent);
        } catch (err) {
          return pushSnackbar({ title: 'Please provide valid JSON', variant: 'error' });
        }
        return generateEnrichedEvent({
          variables: {
            input: {
              event,
            },
          },
        });
      }}
      variantColor="navyblue-300"
      size="medium"
    >
      Enrich Test Data
    </Button>
  );
};

export default React.memo(EnrichButton);
