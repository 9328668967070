/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import * as Types from '../../../../../__generated__/schema';

import { GraphQLError } from 'graphql';
import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';

export type BulkUpdateDetectionsVariables = {
  input: Types.BulkUpdateDetectionsInput;
};

export type BulkUpdateDetections = {
  bulkUpdateDetections?: Types.Maybe<{
    errors: Array<Types.Maybe<Pick<Types.DetectionUpdateError, 'detectionId'>>>;
    detections: Array<
      | ({ __typename: 'Rule' } & Pick<Types.Rule, 'id' | 'enabled'>)
      | ({ __typename: 'Policy' } & Pick<Types.Policy, 'id' | 'enabled'>)
    >;
  }>;
};

export const BulkUpdateDetectionsDocument = gql`
  mutation BulkUpdateDetections($input: BulkUpdateDetectionsInput!) {
    bulkUpdateDetections(input: $input) {
      errors {
        detectionId
      }
      detections {
        __typename
        id
        enabled
      }
    }
  }
`;
export type BulkUpdateDetectionsMutationFn = ApolloReactCommon.MutationFunction<
  BulkUpdateDetections,
  BulkUpdateDetectionsVariables
>;

/**
 * __useBulkUpdateDetections__
 *
 * To run a mutation, you first call `useBulkUpdateDetections` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBulkUpdateDetections` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bulkUpdateDetections, { data, loading, error }] = useBulkUpdateDetections({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBulkUpdateDetections(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    BulkUpdateDetections,
    BulkUpdateDetectionsVariables
  >
) {
  return ApolloReactHooks.useMutation<BulkUpdateDetections, BulkUpdateDetectionsVariables>(
    BulkUpdateDetectionsDocument,
    baseOptions
  );
}
export type BulkUpdateDetectionsHookResult = ReturnType<typeof useBulkUpdateDetections>;
export type BulkUpdateDetectionsMutationResult = ApolloReactCommon.MutationResult<
  BulkUpdateDetections
>;
export type BulkUpdateDetectionsMutationOptions = ApolloReactCommon.BaseMutationOptions<
  BulkUpdateDetections,
  BulkUpdateDetectionsVariables
>;
export function mockBulkUpdateDetections({
  data,
  variables,
  errors,
}: {
  data: BulkUpdateDetections;
  variables?: BulkUpdateDetectionsVariables;
  errors?: GraphQLError[];
}) {
  return {
    request: { query: BulkUpdateDetectionsDocument, variables },
    result: { data, errors },
  };
}
