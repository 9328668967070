/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { useSnackbar } from 'pouncejs';
import Page404 from 'Pages/404';
import withSEO from 'Hoc/withSEO';
import useRouter from 'Hooks/useRouter';
import withRoleRestrictedAccess from 'Hoc/withRoleRestrictedAccess';
import { compose } from 'Helpers/compose';
import { Permission } from 'Generated/schema';
import Page403 from 'Pages/403';
import { extractErrorMessage } from 'Helpers/utils';
import CrowdstrikeLogsSourceWizard, {
  CrowdstrikeLogsSourceWizardValues,
} from '../CrowdstrikeLogsSourceWizard';
import { useGetCrowdstrikeLogSource } from './graphql/getCrowdstrikeLogSource.generated';
import { useUpdateCrowdstrikeLogSource } from './graphql/updateCrowdstrikeLogSource.generated';

const EditCrowdstrikeLogSource: React.FC = () => {
  const { pushSnackbar } = useSnackbar();
  const { match } = useRouter<{ id: string }>();
  const { data, error: getError } = useGetCrowdstrikeLogSource({
    variables: { id: match.params.id },
    onError: error => {
      pushSnackbar({
        title: extractErrorMessage(error) || 'An unknown error occurred',
        variant: 'error',
      });
    },
  });

  const [updateCrowdstrikeLogSource] = useUpdateCrowdstrikeLogSource();

  const initialValues = React.useMemo(
    () => ({
      integrationId: match.params.id,
      integrationLabel: data?.getLogPullingIntegration.integrationLabel ?? 'Loading...',
      queueUrl: data?.getLogPullingIntegration?.pullerConfig?.crowdstrike?.queueUrl ?? 'Loading...',
      awsAccessKey:
        data?.getLogPullingIntegration?.pullerConfig?.crowdstrike?.awsAccessKey ?? 'Loading...',
      awsAccessSecret:
        data?.getLogPullingIntegration?.pullerConfig?.crowdstrike?.awsAccessSecret ?? 'Loading...',
    }),
    // FIXME: look into missing hook dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data]
  );

  const handleSubmit = React.useCallback(async (values: CrowdstrikeLogsSourceWizardValues) => {
    try {
      const res = await updateCrowdstrikeLogSource({
        variables: {
          input: {
            integrationId: values.integrationId,
            integrationLabel: values.integrationLabel,
            pullerConfig: {
              crowdstrike: {
                awsAccessKey: values.awsAccessKey,
                awsAccessSecret: values.awsAccessSecret,
                queueUrl: values.queueUrl,
              },
            },
          },
        },
      });
      return res.data.updateLogPullingIntegration;
    } catch (err) {
      return err as Error;
    }
    // FIXME: look into missing hook dependencies or using a ref
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // we optimistically assume that an error in "get" is a 404. We don't have any other info
  if (getError) {
    return <Page404 />;
  }

  return <CrowdstrikeLogsSourceWizard initialValues={initialValues} onSubmit={handleSubmit} />;
};

export default compose(
  withSEO({ title: 'Edit Crowdstrike Source' }),
  withRoleRestrictedAccess({
    allowedPermissions: [Permission.LogSourceModify],
    fallback: <Page403 />,
  })
)(EditCrowdstrikeLogSource);
