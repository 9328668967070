/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import * as Types from '../../../../../../__generated__/schema';

import { SnykLogIntegrationDetails } from '../../../../../graphql/fragments/SnykLogIntegrationDetails.generated';
import { GraphQLError } from 'graphql';
import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';

export type UpdateSnykLogSourceVariables = {
  input: Types.UpdateLogPullingIntegrationInput;
};

export type UpdateSnykLogSource = { updateLogPullingIntegration: SnykLogIntegrationDetails };

export const UpdateSnykLogSourceDocument = gql`
  mutation UpdateSnykLogSource($input: UpdateLogPullingIntegrationInput!) {
    updateLogPullingIntegration(input: $input) {
      ...SnykLogIntegrationDetails
    }
  }
  ${SnykLogIntegrationDetails}
`;
export type UpdateSnykLogSourceMutationFn = ApolloReactCommon.MutationFunction<
  UpdateSnykLogSource,
  UpdateSnykLogSourceVariables
>;

/**
 * __useUpdateSnykLogSource__
 *
 * To run a mutation, you first call `useUpdateSnykLogSource` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSnykLogSource` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSnykLogSource, { data, loading, error }] = useUpdateSnykLogSource({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSnykLogSource(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateSnykLogSource,
    UpdateSnykLogSourceVariables
  >
) {
  return ApolloReactHooks.useMutation<UpdateSnykLogSource, UpdateSnykLogSourceVariables>(
    UpdateSnykLogSourceDocument,
    baseOptions
  );
}
export type UpdateSnykLogSourceHookResult = ReturnType<typeof useUpdateSnykLogSource>;
export type UpdateSnykLogSourceMutationResult = ApolloReactCommon.MutationResult<
  UpdateSnykLogSource
>;
export type UpdateSnykLogSourceMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateSnykLogSource,
  UpdateSnykLogSourceVariables
>;
export function mockUpdateSnykLogSource({
  data,
  variables,
  errors,
}: {
  data: UpdateSnykLogSource;
  variables?: UpdateSnykLogSourceVariables;
  errors?: GraphQLError[];
}) {
  return {
    request: { query: UpdateSnykLogSourceDocument, variables },
    result: { data, errors },
  };
}
