/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Box, Flex, Heading, Text } from 'pouncejs';
import BlankCanvasImg from 'Assets/illustrations/blank-canvas.svg';
import urls from 'Source/urls';
import RoleRestrictedAccess from 'Components/utils/RoleRestrictedAccess';
import { Permission } from 'Generated/schema';
import LinkButton from 'Components/buttons/LinkButton';

const ListResourcesPageEmptyDataFallback: React.FC = () => {
  return (
    <Flex justify="center" align="center" direction="column">
      <Box my={10}>
        <img alt="Black Canvas Illustration" src={BlankCanvasImg} width="auto" height={300} />
      </Box>
      <Heading mb={6}>No resources found</Heading>
      <Text color="gray-300" textAlign="center" mb={8}>
        You don{"'"}t have any resources connected to your Panther account
      </Text>
      <RoleRestrictedAccess allowedPermissions={[Permission.CloudsecSourceModify]}>
        <LinkButton to={urls.integrations.cloudAccounts.create()}>Get started</LinkButton>
      </RoleRestrictedAccess>
    </Flex>
  );
};

export default ListResourcesPageEmptyDataFallback;
