/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import * as Types from '../../../../../__generated__/schema';

import { GraphQLError } from 'graphql';
import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';

export type DeleteGlobalHelpersVariables = {
  input: Types.DeleteGlobalHelpersInput;
};

export type DeleteGlobalHelpers = Pick<Types.Mutation, 'deleteGlobalHelpers'>;

export const DeleteGlobalHelpersDocument = gql`
  mutation DeleteGlobalHelpers($input: DeleteGlobalHelpersInput!) {
    deleteGlobalHelpers(input: $input)
  }
`;
export type DeleteGlobalHelpersMutationFn = ApolloReactCommon.MutationFunction<
  DeleteGlobalHelpers,
  DeleteGlobalHelpersVariables
>;

/**
 * __useDeleteGlobalHelpers__
 *
 * To run a mutation, you first call `useDeleteGlobalHelpers` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteGlobalHelpers` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteGlobalHelpers, { data, loading, error }] = useDeleteGlobalHelpers({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteGlobalHelpers(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteGlobalHelpers,
    DeleteGlobalHelpersVariables
  >
) {
  return ApolloReactHooks.useMutation<DeleteGlobalHelpers, DeleteGlobalHelpersVariables>(
    DeleteGlobalHelpersDocument,
    baseOptions
  );
}
export type DeleteGlobalHelpersHookResult = ReturnType<typeof useDeleteGlobalHelpers>;
export type DeleteGlobalHelpersMutationResult = ApolloReactCommon.MutationResult<
  DeleteGlobalHelpers
>;
export type DeleteGlobalHelpersMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeleteGlobalHelpers,
  DeleteGlobalHelpersVariables
>;
export function mockDeleteGlobalHelpers({
  data,
  variables,
  errors,
}: {
  data: DeleteGlobalHelpers;
  variables?: DeleteGlobalHelpersVariables;
  errors?: GraphQLError[];
}) {
  return {
    request: { query: DeleteGlobalHelpersDocument, variables },
    result: { data, errors },
  };
}
