/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Flex, Text, Box } from 'pouncejs';
import { Field, Formik } from 'formik';
import FormikSwitch from 'Components/fields/Switch';
import FormikAutosave from 'Components/utils/Autosave';
import SubmitButton from 'Components/buttons/SubmitButton';
import * as Yup from 'yup';
import EventThresholdAlarmDestinationInfo from './EventThresholdAlarmDestinationInfo';
import MinutesThresholdPicker from './MinutesThresholdPicker';

export interface EventThresholdAlarmFormValues {
  enabled: boolean;
  minutesThreshold?: number;
}

export interface EventThresholdAlarmFormProps {
  initialValues: EventThresholdAlarmFormValues;
  onSubmit: (values: EventThresholdAlarmFormValues) => Promise<any>;
  autoSave: boolean;
}

const validationSchema: Yup.SchemaOf<EventThresholdAlarmFormValues> = Yup.object().shape({
  enabled: Yup.boolean().required(),
  minutesThreshold: Yup.number().positive().integer().min(15),
});

const EventThresholdAlarmForm: React.FC<EventThresholdAlarmFormProps> = ({
  initialValues,
  onSubmit,
  autoSave,
}) => {
  return (
    <Formik<EventThresholdAlarmFormValues>
      initialValues={initialValues}
      onSubmit={onSubmit}
      enableReinitialize
      validationSchema={validationSchema}
    >
      {({ values, submitForm }) => (
        <React.Fragment>
          <Flex direction="column" align="stretch" spacing={4}>
            <Box backgroundColor="navyblue-500" p={4} borderRadius="large">
              <Flex align="center" justify="space-between" spacing={3}>
                <Text id="alarm-description">
                  Set an alarm in case this source does not process any events?
                </Text>
                <Field
                  as={FormikSwitch}
                  name="enabled"
                  checkedText="YES"
                  uncheckedText="NO"
                  aria-labelledby="alarm-description"
                />
              </Flex>
              {values.enabled && <MinutesThresholdPicker />}
            </Box>

            {initialValues.enabled && !values.enabled && (
              <Text
                p={4}
                borderRadius="large"
                textAlign="center"
                backgroundColor="pink-700"
                fontSize="medium"
              >
                You have disabled the alarm for this source. By applying this change, you will not
                receive alerts if events fail to be processed.
              </Text>
            )}
            {values.enabled && <EventThresholdAlarmDestinationInfo />}
            {!autoSave ? (
              <Box alignSelf="center">
                <SubmitButton onClick={submitForm}>Apply Changes</SubmitButton>
              </Box>
            ) : (
              <FormikAutosave threshold={50} />
            )}
          </Flex>
        </React.Fragment>
      )}
    </Formik>
  );
};

export default EventThresholdAlarmForm;
