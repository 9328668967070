/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import type { monaco } from 'react-monaco-editor';
import { EditorProps } from './Editor';

interface UseEditorResizeProps {
  editor?: monaco.editor.IStandaloneCodeEditor;
  minHeight: EditorProps['minHeight'];
  maxHeight: EditorProps['maxHeight'];
}

const clampHeight = (height: number, minHeight: number, maxHeight: number) =>
  Math.max(minHeight ?? 0, Math.min(height, maxHeight ?? Infinity));

const useEditorResize = ({ editor, minHeight, maxHeight }: UseEditorResizeProps) => {
  React.useLayoutEffect(() => {
    if (!editor) {
      return;
    }

    const resizeEditor = () => {
      const height = clampHeight(editor.getContentHeight(), minHeight, maxHeight);
      const editorDomElement = editor.getDomNode();
      editorDomElement.style.height = `${height}px`;

      editor.layout();
    };

    resizeEditor();
    editor.onDidContentSizeChange(resizeEditor);
  }, [editor, minHeight, maxHeight]);
};

export default useEditorResize;
