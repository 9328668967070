/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Box, Flex, Text, SimpleGrid } from 'pouncejs';
import BulletedValue from 'Components/BulletedValue';
import groupBy from 'lodash/groupBy';
import map from 'lodash/map';
import sumBy from 'lodash/sumBy';
import { formatDatetime } from 'Helpers/utils';
import { IndicatorSearchResult } from 'Hooks/useIndicatorSearchFlattenedResults';

export interface ChartTooltipProps {
  params: [{ value: [string, number, IndicatorSearchResult[]] }];
  units?: string;
}

const ChartTooltip: React.FC<ChartTooltipProps> = ({ params, units }) => {
  const series = params[0];
  const [timestamp, , matches] = series.value;
  const isDrillDownEnabled = matches[0]?.timestampResolutionMinutes > 1;
  const matchesByLogType = groupBy(matches, r => r.logType);

  return (
    <Flex
      direction="column"
      spacing={3}
      backgroundColor="navyblue-300"
      minWidth={200}
      boxShadow="dark250"
      p={4}
      borderRadius="medium"
      data-testid="chart-tooltip"
    >
      <Text fontSize="small-medium">{formatDatetime(timestamp, true, true)}</Text>
      <SimpleGrid columns={matches.length > 5 ? 2 : 1} gap={2} fontSize="x-small">
        {map(matchesByLogType, (logTypeMatches, logType) => {
          const hits = sumBy(logTypeMatches, i => i.matches);
          return (
            <Flex key={logType} justify="space-between">
              <Box as="dt">
                <BulletedValue value={logType} size="x-small" />
              </Box>
              <Box as="dd" font="mono" fontWeight="bold">
                {`${hits.toLocaleString('en')} ${units}`}
              </Box>
            </Flex>
          );
        })}
      </SimpleGrid>
      {isDrillDownEnabled && (
        <Text fontSize="small-medium" color="gray-100">
          Click on the bar to search in that time frame
        </Text>
      )}
    </Flex>
  );
};

export default React.memo(ChartTooltip);
