/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { useSnackbar } from 'pouncejs';
import { useListAvailableLogTypes } from 'Source/graphql/queries';
import { useListAvailableParsersForProvider } from './graphql/ListAvailableParsersForProvider.generated';

const useAvailableLogTypesForProvider = (logProviderId?: string) => {
  const { pushSnackbar } = useSnackbar();

  const { data: listAllLogTypesData } = useListAvailableLogTypes({
    skip: !!logProviderId,
    onError: () => pushSnackbar({ title: "Couldn't fetch your available log types" }),
  });

  const { data: listLogTypesForProviderData } = useListAvailableParsersForProvider({
    variables: {
      id: logProviderId,
    },
    skip: !logProviderId,
    onError: () => pushSnackbar({ title: "Couldn't fetch the log types for your selected source" }),
  });

  return React.useMemo(() => {
    if (logProviderId) {
      const data = listLogTypesForProviderData?.getAvailableLogProvider?.result;
      return {
        defaultLogTypes: data?.details?.defaultParsers ?? [],
        availableLogTypes: data?.availableParsers.map(parser => parser.name) ?? [],
      };
    }

    return {
      defaultLogTypes: [],
      availableLogTypes: listAllLogTypesData?.listAvailableLogTypes?.logTypes ?? [],
    };
  }, [logProviderId, listAllLogTypesData, listLogTypesForProviderData]);
};

export default useAvailableLogTypesForProvider;
