/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import PopoverAutoSubmit from 'Components/PopoverAutoSubmit';
import React from 'react';
import { FastField, Form, Formik } from 'formik';
import { Box, Button, Card, Flex, Popover, PopoverContent, PopoverTrigger } from 'pouncejs';
import useRequestParamsWithoutPagination from 'Hooks/useRequestParamsWithoutPagination';
import isUndefined from 'lodash/isUndefined';
import TextButton from 'Components/buttons/TextButton';
import FormikCombobox from 'Components/fields/ComboBox';

export type ListComplianceSourceDropdownFilterValues = {
  status?: 'Healthy' | 'Unhealthy';
};

const defaultValues: ListComplianceSourceDropdownFilterValues = {
  status: null,
};

const statusFieldItems = ['Healthy', 'Unhealthy'];

const DropdownFilters: React.FC = () => {
  const { requestParams, updateRequestParams } = useRequestParamsWithoutPagination<
    ListComplianceSourceDropdownFilterValues
  >();

  const initialDropdownFilters = React.useMemo(
    () =>
      ({
        ...defaultValues,
        ...requestParams,
      } as ListComplianceSourceDropdownFilterValues),
    [requestParams]
  );

  const filtersCount = Object.keys(defaultValues).filter(key => !isUndefined(requestParams[key]))
    .length;

  return (
    <Popover>
      {({ close: closePopover, isOpen }) => (
        <React.Fragment>
          <PopoverTrigger
            as={Button}
            iconAlignment="right"
            icon="filter-light"
            size="large"
            aria-label="Cloud Account Options"
          >
            Filters {filtersCount ? `(${filtersCount})` : ''}
          </PopoverTrigger>
          <PopoverContent>
            <Card
              shadow="dark300"
              my={14}
              p={6}
              pb={4}
              backgroundColor="navyblue-400"
              width={425}
              data-testid="dropdown-cloud-account-listing-filters"
            >
              <Formik<ListComplianceSourceDropdownFilterValues>
                enableReinitialize
                onSubmit={(values: ListComplianceSourceDropdownFilterValues) => {
                  updateRequestParams(values);
                }}
                initialValues={initialDropdownFilters}
              >
                {({ setValues, values }) => (
                  <Form>
                    <PopoverAutoSubmit<ListComplianceSourceDropdownFilterValues>
                      isOpen={isOpen}
                      values={values}
                      onSubmit={updateRequestParams}
                    />
                    <Box pb={4}>
                      <FastField
                        name="status"
                        as={FormikCombobox}
                        items={statusFieldItems}
                        label="Status"
                        placeholder="Select health status"
                      />
                    </Box>
                    <Flex direction="column" justify="center" align="center" spacing={4}>
                      <Box>
                        <Button onClick={closePopover}>Apply Filters</Button>
                      </Box>
                      <TextButton role="button" onClick={() => setValues(defaultValues)}>
                        Clear Filters
                      </TextButton>
                    </Flex>
                  </Form>
                )}
              </Formik>
            </Card>
          </PopoverContent>
        </React.Fragment>
      )}
    </Popover>
  );
};

export default React.memo(DropdownFilters);
