/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { AlertOriginSystemError, RelatedComponentEnum } from 'Generated/schema';
import urls from 'Source/urls';

const useSystemErrorRelatedComponent = (alertOrigin: AlertOriginSystemError) => {
  return React.useMemo(() => {
    switch (alertOrigin.relatedComponent) {
      case RelatedComponentEnum.Destination:
        return {
          label: 'Destinations',
          to: urls.integrations.destinations.list(),
        };
      case RelatedComponentEnum.CloudAccount:
        return {
          label: 'Cloud Accounts',
          to: urls.integrations.cloudAccounts.list(),
        };
      case RelatedComponentEnum.LookupTable:
        return {
          label: 'Lookup Tables',
          to: urls.enrichment.lookupTables.list(),
        };
      case RelatedComponentEnum.LogSource:
      default:
        return {
          label: 'Log Sources',
          to: urls.integrations.logSources.list(),
        };
    }
  }, [alertOrigin]);
};

export default useSystemErrorRelatedComponent;
