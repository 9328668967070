/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Box, SimpleGrid, IconButton, Flex } from 'pouncejs';
import { CardValue } from 'Components/cards/common';
import { getSourceMeta, SourceMeta } from 'Pages/Integrations/utils';
import SetEventThresholdAlarmModal from 'Components/modals/SetEventThresholdAlarmModal';
import useModal from 'Hooks/useModal';
import useCopyToClipboard from 'Hooks/useCopyToClipboard';
import { SourceIntegration } from './InformationPanel';

const BasicInfoMeta = ({ source }: { source: SourceIntegration }) => {
  const { showModal } = useModal();
  const copyToClipboard = useCopyToClipboard();
  const meta = getSourceMeta(source);

  const getAction = React.useCallback(
    (m: SourceMeta) => {
      switch (m.label) {
        case 'Alarm Configuration':
          return (
            <Box whiteSpace="nowrap">
              <IconButton
                variantColor="navyblue-300"
                icon="pencil"
                size="small"
                onClick={() => {
                  return showModal(<SetEventThresholdAlarmModal logSource={source} />, {
                    title: 'Configure Event Threshold Alarm',
                    showCloseButton: true,
                  });
                }}
                aria-label="Configure Event"
              />
            </Box>
          );
        case 'Source ID':
          return (
            <Box whiteSpace="nowrap">
              <IconButton
                variantColor="navyblue-300"
                icon="copy"
                size="small"
                onClick={() => copyToClipboard(source.integrationId)}
                aria-label="Copy Source ID"
              />
            </Box>
          );

        default:
          return null;
      }
    },
    [copyToClipboard, showModal, source]
  );

  return (
    <SimpleGrid gap={2} columns={1}>
      {meta.map(m => {
        return (
          <Flex key={m.label} justify="space-between" width="100%" wordBreak="break-word">
            <CardValue label={m.label} value={m.value} />
            {getAction(m)}
          </Flex>
        );
      })}
    </SimpleGrid>
  );
};

export default React.memo(BasicInfoMeta);
