/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import * as Types from '../../../../../__generated__/schema';

import { APITokenDetails } from '../../../../graphql/fragments/APITokenDetails.generated';
import { GraphQLError } from 'graphql';
import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';

export type UpdateAPITokenVariables = {
  input: Types.UpdateAPITokenInput;
};

export type UpdateAPIToken = {
  updateAPIToken: {
    token?: Types.Maybe<APITokenDetails>;
    error?: Types.Maybe<Pick<Types.Error, 'message'>>;
  };
};

export const UpdateAPITokenDocument = gql`
  mutation UpdateAPIToken($input: UpdateAPITokenInput!) {
    updateAPIToken(input: $input) {
      token {
        ...APITokenDetails
      }
      error {
        message
      }
    }
  }
  ${APITokenDetails}
`;
export type UpdateAPITokenMutationFn = ApolloReactCommon.MutationFunction<
  UpdateAPIToken,
  UpdateAPITokenVariables
>;

/**
 * __useUpdateAPIToken__
 *
 * To run a mutation, you first call `useUpdateAPIToken` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAPIToken` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateApiToken, { data, loading, error }] = useUpdateAPIToken({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAPIToken(
  baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateAPIToken, UpdateAPITokenVariables>
) {
  return ApolloReactHooks.useMutation<UpdateAPIToken, UpdateAPITokenVariables>(
    UpdateAPITokenDocument,
    baseOptions
  );
}
export type UpdateAPITokenHookResult = ReturnType<typeof useUpdateAPIToken>;
export type UpdateAPITokenMutationResult = ApolloReactCommon.MutationResult<UpdateAPIToken>;
export type UpdateAPITokenMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateAPIToken,
  UpdateAPITokenVariables
>;
export function mockUpdateAPIToken({
  data,
  variables,
  errors,
}: {
  data: UpdateAPIToken;
  variables?: UpdateAPITokenVariables;
  errors?: GraphQLError[];
}) {
  return {
    request: { query: UpdateAPITokenDocument, variables },
    result: { data, errors },
  };
}
