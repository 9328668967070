/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Box, Flex } from 'pouncejs';
import { Field, useFormikContext } from 'formik';
import FormikTextInput from 'Components/fields/TextInput';
import FormikMultiCombobox from 'Components/fields/MultiComboBox';
import { WizardPanel } from 'Components/Wizard';
import { ZoomLogSourceWizardValues } from 'Pages/Integrations/LogSources/Zoom/ZoomLogSourceWizard';
import zoomLogo from 'Assets/logProviders/zoom.svg';
import useAvailableLogTypesForLogPuller from 'Hooks/useAvailableLogTypesForLogPuller';
import EditIntegrationActions from 'Pages/Integrations/components/EditIntegrationActions';

const ZoomConfigurationPanel: React.FC = () => {
  const { initialValues, errors, values } = useFormikContext<ZoomLogSourceWizardValues>();
  const { availableLogTypes } = useAvailableLogTypesForLogPuller();
  const editMode = !!initialValues.integrationId;

  return (
    <WizardPanel>
      <Box width={500} m="auto">
        <WizardPanel.Heading
          title={editMode ? 'Update your Zoom source' : "Let's start with the basics"}
          subtitle="Give an appropriate name for this integration"
          logo={zoomLogo}
        />
        <Flex direction="column" spacing={4}>
          <Field
            name="integrationLabel"
            as={FormikTextInput}
            label="Name *"
            placeholder="A nickname for your Zoom log source"
            required
          />
          <Field
            as={FormikMultiCombobox}
            searchable
            placeholder="The Zoom Log Types you want Panther to process"
            label="Select the Log types you want to monitor *"
            name="logTypes"
            items={availableLogTypes}
            required
            disabled={availableLogTypes.length <= 1}
          />
        </Flex>
      </Box>
      {editMode ? (
        <EditIntegrationActions />
      ) : (
        <WizardPanel.Actions>
          <WizardPanel.ActionNext disabled={!!errors.integrationLabel || !values.integrationLabel}>
            Continue Setup
          </WizardPanel.ActionNext>
        </WizardPanel.Actions>
      )}
    </WizardPanel>
  );
};

export default ZoomConfigurationPanel;
