/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Box, Flex, Text, SimpleGrid } from 'pouncejs';
import BulletedValue from 'Components/BulletedValue';
import { formatBytes, formatDatetime } from 'Helpers/utils';

export interface ChartTooltipProps {
  params: [{ value: [string, number, { label: string; value: number }[]] }];
}

const ChartTooltip: React.FC<ChartTooltipProps> = ({ params }) => {
  const series = params[0];
  const [timestamp, totalBytes, bytesByLogType] = series.value;

  return (
    <Box
      backgroundColor="navyblue-300"
      minWidth={400}
      boxShadow="dark250"
      p={4}
      borderRadius="medium"
    >
      <Flex justify="space-between" fontSize="small-medium" mb={3}>
        <Text>{formatDatetime(timestamp, true)}</Text>
        <Box>
          <Text as="span">Total</Text>
          <Text as="span" fontWeight="bold" ml={1}>
            {formatBytes(totalBytes)}
          </Text>
        </Box>
      </Flex>
      <SimpleGrid as="dl" columns={2} spacingX={6} spacingY={2} fontSize="x-small">
        {bytesByLogType.map(({ label, value }) => {
          return (
            <Flex key={label} justify="space-between">
              <Box as="dt">
                <BulletedValue value={label} size="x-small" />
              </Box>
              <Box pl={4} as="dd" font="mono" fontWeight="bold">
                {formatBytes(value)}
              </Box>
            </Flex>
          );
        })}
      </SimpleGrid>
    </Box>
  );
};

export default React.memo(ChartTooltip);
