/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Link as RRLink } from 'react-router-dom';
import { Flex, Icon, Text } from 'pouncejs';

import useAlertDestinationsDeliverySuccess from 'Hooks/useAlertDestinationsDeliverySuccess';
import urls from 'Source/urls';
import { AlertSummaryFull } from 'Source/graphql/fragments/AlertSummaryFull.generated';

export interface DestinationDeliveryWarningProps {
  alert: AlertSummaryFull;
}

const DestinationDeliveryWarning: React.FC<DestinationDeliveryWarningProps> = ({ alert }) => {
  const { allDestinationDeliveredSuccessfully, loading } = useAlertDestinationsDeliverySuccess({
    alert,
  });

  return !loading && !allDestinationDeliveredSuccessfully ? (
    <Flex
      as="section"
      align="center"
      spacing={1}
      aria-label="Destination delivery failure"
      fontStyle="italic"
      color="red-100"
      fontSize="small"
    >
      <Icon type="alert-circle-filled" size="medium" />
      <Text>Destination Delivery Failed.</Text>
      <RRLink to={urls.alerts.details(alert.id)}>
        <Text textDecoration="underline">See details</Text>
      </RRLink>
    </Flex>
  ) : null;
};

export default React.memo(DestinationDeliveryWarning);
