/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import FormikTextInput from 'Components/fields/TextInput';
import { WizardPanel } from 'Components/Wizard';
import { FastField, useFormikContext } from 'formik';
import { Box, Card, Flex, Grid, Heading, Link, SimpleGrid, Text } from 'pouncejs';
import { CWL_MANUALLY_IAM_ROLE_DOC_URL } from 'Source/constants';
import EditIntegrationActions from 'Pages/Integrations/components/EditIntegrationActions';
import { CloudWatchLogSourceWizardValues } from '../CloudWatchLogSourceWizard';

const CustomMethod: React.FC = () => {
  const { values, isValid } = useFormikContext<CloudWatchLogSourceWizardValues>();

  const editMode = !!values.integrationId;

  return (
    <WizardPanel>
      <WizardPanel.Heading title="Set up role manually" />
      <SimpleGrid gap={8} width={0.8} mx="auto">
        <Card as="section" variant="dark" py={4} px={10}>
          <Text fontSize="x-large" textAlign="center">
            Go to
            <Link external mx={1} href={CWL_MANUALLY_IAM_ROLE_DOC_URL}>
              our documentation
            </Link>
            and read the instructions. After setting up the role, return here to continue the set up
            process
          </Text>
        </Card>
        <Box as="section">
          <Heading as="h2" color="teal-200" fontSize="x-large" fontWeight="medium">
            Enter Role ARN
          </Heading>
          <Text color="white-100" fontSize="medium" lineHeight="loose" my={1}>
            Go to the {'"'}Outputs{'"'} tab of the CloudFormation stack that you just deployed and
            copy the value for Bucket Name and RoleARN into the box below.
          </Text>

          <Grid templateColumns="2fr 3fr" gap={4}>
            <FastField
              name="s3Bucket"
              as={FormikTextInput}
              label="Bucket Name - Required"
              placeholder="The Bucket name that holds the logs"
              trimPastedText
              required
            />
            <FastField
              name="logProcessingRole"
              as={FormikTextInput}
              label="Role ARN - Required"
              placeholder="The ARN that Panther will use to read s3 objects."
              trimPastedText
              required
            />
          </Grid>
        </Box>
      </SimpleGrid>

      <WizardPanel.Actions>
        <WizardPanel.ActionPrev />
        {editMode ? (
          <EditIntegrationActions />
        ) : (
          <Flex spacing={4} direction="column" align="center">
            <Text fontSize="small" fontWeight="medium">
              After entering the Bucket Name and the RoleARN Role, click below to proceed
            </Text>
            <WizardPanel.ActionNext disabled={!isValid}>Continue Setup</WizardPanel.ActionNext>
          </Flex>
        )}
      </WizardPanel.Actions>
    </WizardPanel>
  );
};

export default CustomMethod;
