/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Box, Card, Flex, Link, SimpleGrid, Text } from 'pouncejs';
import Linkify from 'Components/Linkify';
import { Link as RRLink } from 'react-router-dom';
import urls from 'Source/urls';
import { AlertOriginPolicy } from 'Generated/schema';
import { formatDatetime } from 'Helpers/utils';
import { AlertDetails } from 'Pages/AlertDetails';
import AlertDeliverySection from 'Pages/AlertDetails/common/AlertDeliverySection';
import RelatedDestinations from 'Components/RelatedDestinations';
import useAlertDestinations from 'Hooks/useAlertDestinations';
import { useListComplianceSourceNames } from 'Source/graphql/queries';
import { GetPolicySummary } from '../graphql/getPolicySummary.generated';

interface PolicyAlertDetailsInfoProps {
  alert: AlertDetails['alert'];
  policy?: GetPolicySummary['policy'];
}

const PolicyAlertDetailsInfo: React.FC<PolicyAlertDetailsInfoProps> = ({ alert, policy }) => {
  const { data: complianceSourceData } = useListComplianceSourceNames({ errorPolicy: 'ignore' });
  const { alertDestinations, loading: loadingDestinations } = useAlertDestinations({ alert });

  const originData = alert.origin as AlertOriginPolicy;
  const source = complianceSourceData?.listComplianceIntegrations?.find(
    s => s.integrationId === originData.policySourceId
  );

  return (
    <Flex direction="column" spacing={4}>
      {policy && (
        <Card variant="dark" as="section" p={4}>
          {alert.description && <Text mb={6}>{alert.description}</Text>}
          <SimpleGrid columns={2} spacing={5}>
            <Flex direction="column" spacing={2}>
              <Box
                color="navyblue-100"
                fontSize="small-medium"
                aria-describedby="runbook-description"
              >
                Runbook
              </Box>
              {alert.runbook ? (
                <Linkify id="runbook-description">{alert.runbook}</Linkify>
              ) : (
                <Box fontStyle="italic" color="navyblue-100" id="runbook-description">
                  No runbook specified
                </Box>
              )}
            </Flex>
            <Flex direction="column" spacing={2}>
              <Box
                color="navyblue-100"
                fontSize="small-medium"
                aria-describedby="reference-description"
              >
                Reference
              </Box>
              {alert.reference ? (
                <Linkify id="reference-description">{alert.reference}</Linkify>
              ) : (
                <Box fontStyle="italic" color="navyblue-100" id="reference-description">
                  No reference specified
                </Box>
              )}
            </Flex>
          </SimpleGrid>
        </Card>
      )}
      <Card variant="dark" as="section" p={4}>
        <SimpleGrid columns={2} spacing={5} fontSize="small-medium">
          <Box>
            <SimpleGrid gap={2} columns={8} spacing={2}>
              {!policy && (
                <>
                  <Box color="navyblue-100" gridColumn="1/3" aria-describedby="policy-link">
                    Policy
                  </Box>
                  <Box gridColumn="3/8" color="red-300">
                    Associated policy has been deleted
                  </Box>
                </>
              )}
              {policy && (
                <>
                  <Box color="navyblue-100" gridColumn="1/3" aria-describedby="policy-link">
                    Policy
                  </Box>

                  <Link
                    id="policy-link"
                    gridColumn="3/8"
                    as={RRLink}
                    data-tid="link-to-policy-from-alert-details"
                    to={urls.analysis.policies.details(policy.id)}
                  >
                    {policy.displayName || policy.id}
                  </Link>

                  <Box color="navyblue-100" gridColumn="1/3" aria-describedby="tags-list">
                    Tags
                  </Box>

                  {policy.tags.length > 0 ? (
                    <Box id="tags-list" gridColumn="3/8">
                      {policy.tags.map((tag, index) => (
                        <Link
                          key={tag}
                          as={RRLink}
                          to={`${urls.detections.list()}?page=1&tags[]=${tag}`}
                          data-tid="link-to-detection-tag"
                        >
                          {tag}
                          {index !== policy.tags.length - 1 ? ', ' : null}
                        </Link>
                      ))}
                    </Box>
                  ) : (
                    <Box fontStyle="italic" color="navyblue-100" id="tags-list" gridColumn="3/8">
                      This policy has no tags
                    </Box>
                  )}

                  <Box color="navyblue-100" gridColumn="1/3" aria-describedby="resource-link">
                    Resource
                  </Box>
                  <Link
                    id="resource-link"
                    gridColumn="3/8"
                    as={RRLink}
                    to={urls.data.resources.details(originData.resourceId)}
                  >
                    {originData.resourceId}
                  </Link>

                  <Box
                    color="navyblue-100"
                    gridColumn="1/3"
                    aria-describedby="deduplication-string"
                  >
                    Deduplication String
                  </Box>
                  <Box gridColumn="3/8" id="deduplication-string">
                    {originData.dedupString}
                  </Box>
                </>
              )}
            </SimpleGrid>
          </Box>

          <Box>
            <SimpleGrid gap={2} columns={8} spacing={2}>
              <Box color="navyblue-100" gridColumn="1/3" aria-describedby="created-at">
                Created
              </Box>

              <Box id="created-at" gridColumn="3/8">
                {formatDatetime(alert.createdAt)}
              </Box>

              <Box color="navyblue-100" gridColumn="1/3" aria-describedby="last-matched-at">
                Last Matched
              </Box>
              <Box gridColumn="3/8" id="last-matched-at">
                {formatDatetime(alert.lastReceivedEventAt)}
              </Box>

              <Box color="navyblue-100" gridColumn="1/3" aria-describedby="policy-source">
                Source
              </Box>
              <Box gridColumn="3/8" id="policy-source" color={!source ? 'red-200' : undefined}>
                {source ? source.integrationLabel : 'Associated Source has been deleted'}
              </Box>

              <Box color="navyblue-100" gridColumn="1/3" aria-describedby="destinations">
                Destinations
              </Box>

              <Box id="destinations" gridColumn="3/8">
                <RelatedDestinations
                  destinations={alertDestinations}
                  loading={loadingDestinations}
                  limit={5}
                  verbose
                />
              </Box>
            </SimpleGrid>
          </Box>
        </SimpleGrid>
      </Card>
      <Card variant="dark" as="section" p={4}>
        <AlertDeliverySection alert={alert} />
      </Card>
    </Flex>
  );
};

export default PolicyAlertDetailsInfo;
