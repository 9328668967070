/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import * as Types from '../../../__generated__/schema';

import { S3LogIntegrationDetails } from '../fragments/S3LogIntegrationDetails.generated';
import { GraphQLError } from 'graphql';
import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';

export type GetS3LogSourceVariables = {
  id: Types.Scalars['ID'];
};

export type GetS3LogSource = { getS3LogIntegration: S3LogIntegrationDetails };

export const GetS3LogSourceDocument = gql`
  query GetS3LogSource($id: ID!) {
    getS3LogIntegration(id: $id) {
      ...S3LogIntegrationDetails
    }
  }
  ${S3LogIntegrationDetails}
`;

/**
 * __useGetS3LogSource__
 *
 * To run a query within a React component, call `useGetS3LogSource` and pass it any options that fit your needs.
 * When your component renders, `useGetS3LogSource` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetS3LogSource({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetS3LogSource(
  baseOptions?: ApolloReactHooks.QueryHookOptions<GetS3LogSource, GetS3LogSourceVariables>
) {
  return ApolloReactHooks.useQuery<GetS3LogSource, GetS3LogSourceVariables>(
    GetS3LogSourceDocument,
    baseOptions
  );
}
export function useGetS3LogSourceLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetS3LogSource, GetS3LogSourceVariables>
) {
  return ApolloReactHooks.useLazyQuery<GetS3LogSource, GetS3LogSourceVariables>(
    GetS3LogSourceDocument,
    baseOptions
  );
}
export type GetS3LogSourceHookResult = ReturnType<typeof useGetS3LogSource>;
export type GetS3LogSourceLazyQueryHookResult = ReturnType<typeof useGetS3LogSourceLazyQuery>;
export type GetS3LogSourceQueryResult = ApolloReactCommon.QueryResult<
  GetS3LogSource,
  GetS3LogSourceVariables
>;
export function mockGetS3LogSource({
  data,
  variables,
  errors,
}: {
  data: GetS3LogSource;
  variables?: GetS3LogSourceVariables;
  errors?: GraphQLError[];
}) {
  return {
    request: { query: GetS3LogSourceDocument, variables },
    result: { data, errors },
  };
}
