/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Destination, Policy, Rule } from 'Generated/schema';
import { useListDestinations } from 'Source/graphql/queries';

interface UseDetectionDestinationsProps {
  detection: Pick<Rule | Policy, 'severity' | 'outputIds'>;
}

interface UseDetectionDestinationsResponse {
  loading: boolean;
  detectionDestinations: Pick<
    Destination,
    'outputType' | 'outputId' | 'displayName' | 'defaultForSeverity'
  >[];
}

const useDetectionDestinations = ({
  detection,
}: UseDetectionDestinationsProps): UseDetectionDestinationsResponse => {
  const { data: destinations, loading } = useListDestinations();

  const detectionDestinations = React.useMemo(() => {
    if (!detection || !destinations?.destinations) {
      return [];
    }

    if (detection.outputIds.length) {
      return detection.outputIds
        .map(outputId => {
          return destinations.destinations.find(dest => dest.outputId === outputId);
        })
        .filter(Boolean);
    }
    return destinations.destinations.filter(dest => {
      return dest.defaultForSeverity.some(sev => sev === detection.severity);
    });
  }, [detection, destinations]);

  return React.useMemo(
    () => ({
      detectionDestinations,
      loading,
    }),
    [detectionDestinations, loading]
  );
};

export default useDetectionDestinations;
