/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Card, Flex, Heading, Img, Text } from 'pouncejs';

type SupportItemPros = {
  title: string;
  subtitle: string | React.ReactNode;
  imgSrc: string;
  cta: React.ReactNode;
};

const SupportItemCard: React.FC<SupportItemPros> = ({ title, subtitle, imgSrc, cta }) => {
  return (
    <Card backgroundColor="navyblue-500" p={4}>
      <Flex spacing={6} mx={6}>
        <Flex justify="center" align="center">
          <Flex
            justify="center"
            align="center"
            width={75}
            height={75}
            backgroundColor="navyblue-350"
            borderRadius="circle"
            fontSize="2x-small"
            fontWeight="medium"
          >
            <Img
              src={imgSrc}
              alt="Panther Enterprise logo"
              objectFit="contain"
              nativeHeight={40}
              nativeWidth={40}
            />
          </Flex>
        </Flex>
        <Flex direction="column" spacing={2} justify="space-between" align="space-between">
          <Heading size="small" color="white-100">
            {title}
          </Heading>

          {subtitle && (
            <Text fontSize="small-medium" color="navyblue-100" mt={1}>
              {subtitle}
            </Text>
          )}
          {cta}
        </Flex>
      </Flex>
    </Card>
  );
};

export default SupportItemCard;
