/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import { ApolloClient, ApolloLink } from '@apollo/client';

import { History } from 'history';
import { LocationErrorState } from 'Components/utils/ApiErrorFallback';
import createErrorLink from './createErrorLink';
import authLink from './authLink';
import cleanParamsLink from './cleanParamsLink';
import httpLink from './httpLink';
import createInMemoryCache from './cache';

/**
 * A function that will create an ApolloClient given a specific instance of a history
 */
const createApolloClient = (history: History<LocationErrorState>) =>
  new ApolloClient({
    link: ApolloLink.from([cleanParamsLink, createErrorLink(history), authLink, httpLink]),
    cache: createInMemoryCache(),
  });

export default createApolloClient;
