/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Box, Flex, Heading, Card } from 'pouncejs';

interface PanelProps {
  title: string | React.ReactNode;
  actions?: React.ReactNode;
  children: React.ReactNode;
}

const Panel: React.FC<PanelProps> = ({ title, actions, children, ...rest }) => {
  return (
    <Card as="section" width={1} {...rest}>
      <Flex
        p={6}
        borderBottom="1px solid"
        borderColor={children ? 'navyblue-300' : 'transparent'}
        justify="space-between"
        align="center"
        maxHeight={80}
      >
        <Heading size="x-small" as="h4">
          {title}
        </Heading>
        {actions}
      </Flex>
      {children && <Box p={6}>{children}</Box>}
    </Card>
  );
};

export default React.memo(Panel);
