/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { useFormikContext } from 'formik';
import {
  AbstractButton,
  Box,
  Flex,
  IconButton,
  useSnackbar,
  Heading,
  Text,
  Button,
} from 'pouncejs';
import { ApolloError } from '@apollo/client';
import { extractErrorMessage } from 'Helpers/utils';
import { WizardPanel, useWizardContext } from 'Components/Wizard';
import { LookupFormValues, LOOKUP_WIZARD_WIDTH } from '../lookupWizardHelpers';
import LookupIAMPolicyManual from './LookupIAMPolicyManual';
import LookupWizardContinueButton from '../LookupWizardContinueButton';
import LookupIAMPolicyConsole from './LookupIAMPolicyConsole';
import LookupIAMPolicyTemplate from './LookupIAMPolicyTemplate';
import EditLookupActions from '../EditLookupActions';

type IAMPermissionsMethod = 'console' | 'template' | 'manual' | null;

interface ChangeMethodButtonProps {
  onClick: () => void;
}

const ChangeMethodButton: React.FC<ChangeMethodButtonProps> = ({ onClick }) => {
  return (
    <Box position="absolute" top={6} left={6}>
      <IconButton
        icon="arrow-back"
        variant="outline"
        variantColor="navyblue-300"
        aria-label="Choose another method"
        onClick={onClick}
        data-tid="lookup-change-method-button"
      />
    </Box>
  );
};

/**
 * Display the actions that can be performed on this panel. If the lookup is being CREATED
 * this component will display a "Finish Setup" CTA, and if it's being EDITED it will
 * display the edit actions (save / cancel)
 */
const IAMPanelActions: React.FC = () => {
  const { submitForm, isSubmitting, initialValues, setFieldValue } = useFormikContext<
    LookupFormValues
  >();
  const { pushSnackbar } = useSnackbar();
  const { goToNextStep } = useWizardContext();
  const isEditing = Boolean(initialValues.id);

  const handleFinishSetup = React.useCallback(async () => {
    // We shouldn't get into a state where `isEditing === true` and the button is displayed, but
    // this safeguards against that and gives us some type safety.
    if (isEditing) {
      return;
    }

    const submitResult: unknown = await submitForm();

    if (submitResult instanceof Error) {
      // If there was an error during submission, surface that to the user.
      pushSnackbar({
        variant: 'error',
        title: "Couldn't create lookup",
        description:
          extractErrorMessage(submitResult as ApolloError) || 'Some unknown error occurred',
      });
    } else {
      // we need this because on the next view, we are going to potentially update this lookup with a new value and the form now needs its ID
      // @ts-ignore
      setFieldValue('id', submitResult.id);
      goToNextStep();
    }
  }, [isEditing, pushSnackbar, submitForm, setFieldValue, goToNextStep]);

  if (isEditing) {
    return <EditLookupActions />;
  }

  return (
    <Flex justify="center" mt={10}>
      <LookupWizardContinueButton
        loading={isSubmitting}
        fields={['iamRoleArn']}
        onClick={handleFinishSetup}
        data-tid="lookup-finish-setup-s3-upload"
      >
        Verify Setup
      </LookupWizardContinueButton>
    </Flex>
  );
};

const LookupIAMPolicyPanel = () => {
  const [iamPermissionsOption, setIAMPermissionsOption] = React.useState<IAMPermissionsMethod>(
    null
  );

  if (iamPermissionsOption === null) {
    return (
      <WizardPanel width={LOOKUP_WIZARD_WIDTH} margin="0 auto">
        <WizardPanel.Heading
          title="Setup an IAM role"
          subtitle="Select how you want to set up your IAM role"
        />
        <Flex spacing={4} direction="column" mx="auto" maxWidth={800}>
          <Flex
            as="section"
            backgroundColor="navyblue-350"
            align="center"
            alignItems="center"
            spacing={2}
            px={7}
            py={5}
          >
            <Box>
              <Heading as="h2" color="white-100" fontSize="x-large" mb={1}>
                Using the AWS Console UI
              </Heading>
              <Text color="gray-300" fontSize="medium">
                Launch a CloudFormation stack using the AWS console
              </Text>
            </Box>
            <Box ml="auto">
              <Button
                data-testid="console-button"
                onClick={() => setIAMPermissionsOption('console')}
                data-tid="lookup-iam-role-console"
              >
                Select
              </Button>
            </Box>
          </Flex>
          <Flex
            as="section"
            backgroundColor="navyblue-350"
            align="center"
            alignItems="center"
            spacing={2}
            px={7}
            py={5}
          >
            <Box>
              <Heading as="h2" color="white-100" fontSize="x-large" mb={1}>
                CloudFormation Template File
              </Heading>
              <Text color="gray-300" fontSize="medium">
                Download an infrastructure-as-code file to deploy in your environment
              </Text>
            </Box>
            <Box ml="auto">
              <Button
                data-testid="template-button"
                data-tid="lookup-iam-role-template"
                onClick={() => setIAMPermissionsOption('template')}
              >
                Select
              </Button>
            </Box>
          </Flex>
          <AbstractButton
            color="blue-200"
            fontSize="medium"
            data-tid="lookup-iam-role-manual"
            onClick={() => setIAMPermissionsOption('manual')}
          >
            I want to set up everything on my own
          </AbstractButton>
        </Flex>
        <WizardPanel.ActionPrev />
      </WizardPanel>
    );
  }

  if (iamPermissionsOption === 'console') {
    return (
      <WizardPanel width={LOOKUP_WIZARD_WIDTH} margin="0 auto">
        <WizardPanel.Heading title="Use AWS UI to setup your role" />
        <ChangeMethodButton onClick={() => setIAMPermissionsOption(null)} />
        <LookupIAMPolicyConsole />
        <IAMPanelActions />
      </WizardPanel>
    );
  }

  if (iamPermissionsOption === 'template') {
    return (
      <WizardPanel width={LOOKUP_WIZARD_WIDTH} margin="0 auto">
        <WizardPanel.Heading title="CloudFormation Template File" />
        <ChangeMethodButton onClick={() => setIAMPermissionsOption(null)} />
        <LookupIAMPolicyTemplate />
        <IAMPanelActions />
      </WizardPanel>
    );
  }

  if (iamPermissionsOption === 'manual') {
    return (
      <WizardPanel width={LOOKUP_WIZARD_WIDTH} mx="auto">
        <WizardPanel.Heading title="Set up role manually" />
        <ChangeMethodButton onClick={() => setIAMPermissionsOption(null)} />
        <LookupIAMPolicyManual />
        <IAMPanelActions />
      </WizardPanel>
    );
  }

  return null;
};

export default LookupIAMPolicyPanel;
