/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { FastField, Form, Formik } from 'formik';
import { ListAnalysisPackSourcesInput, Permission, SortDirEnum } from 'Generated/schema';
import { Box, Flex } from 'pouncejs';
import pick from 'lodash/pick';
import useRequestParamsWithoutPagination from 'Hooks/useRequestParamsWithoutPagination';
import FormikAutosave from 'Components/utils/Autosave';
import FormikTextInput from 'Components/fields/TextInput';
import FormikCombobox from 'Components/fields/ComboBox';
import urls from 'Source/urls';
import RoleRestrictedAccess from 'Components/utils/RoleRestrictedAccess/RoleRestrictedAccess';
import LinkButton from 'Components/buttons/LinkButton';

export type ListPackSourceInlineFiltersValues = Pick<
  ListAnalysisPackSourcesInput,
  'nameContains' | 'sortDir'
>;

const filters: (keyof ListPackSourceInlineFiltersValues)[] = ['nameContains', 'sortDir'];

const sortDirOpts = {
  [SortDirEnum.Descending]: 'Most Recent',
  [SortDirEnum.Ascending]: 'Oldest',
};

const defaultValues = {
  nameContains: '',
  sortDir: null,
};

const sortingOpts = Object.keys(sortDirOpts);
const sortDirToString = (sortDir: SortDirEnum) => sortDirOpts[sortDir];

const ListPackSourcesFilters: React.FC = () => {
  const { requestParams, updateRequestParams } = useRequestParamsWithoutPagination<
    ListAnalysisPackSourcesInput
  >();

  const initialFilterValues = React.useMemo(
    () => ({
      ...defaultValues,
      ...pick(requestParams, filters),
    }),
    [requestParams]
  );

  return (
    <Flex justify="flex-end" align="center">
      <Formik<ListPackSourceInlineFiltersValues>
        enableReinitialize
        initialValues={initialFilterValues}
        onSubmit={values => {
          updateRequestParams(values);
        }}
      >
        <Box flexGrow={1}>
          <Form>
            <FormikAutosave threshold={200} />
            <Flex spacing={4} align="center" pr={4}>
              <Box minWidth={425} flexGrow={1}>
                <FastField
                  name="nameContains"
                  icon="search"
                  iconAlignment="left"
                  as={FormikTextInput}
                  label="Filter Pack Sources by text"
                  placeholder="Search for a pack source..."
                />
              </Box>
              <Box minWidth={220}>
                <FastField
                  name="sortDir"
                  as={FormikCombobox}
                  items={sortingOpts}
                  itemToString={sortDirToString}
                  label="Sort By"
                  placeholder="Select a sorting option"
                />
              </Box>
            </Flex>
          </Form>
        </Box>
      </Formik>
      <RoleRestrictedAccess allowedPermissions={[Permission.PolicyModify, Permission.RuleModify]}>
        <LinkButton title="Create new Pack Source" to={urls.analysis.packs.packSources.create()}>
          Create New
        </LinkButton>
      </RoleRestrictedAccess>
    </Flex>
  );
};

export default React.memo(ListPackSourcesFilters);
