/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Router } from 'react-router-dom';
import Routes from 'Source/routes';
import { History } from 'history';
import { ApolloProvider } from '@apollo/client';
import useHiddenOutline from 'Hooks/useHiddenOutline';
import { AuthProvider } from 'Components/utils/AuthContext';
import UIProviders from 'Components/utils/UIProviders';
import ErrorBoundary from 'Components/ErrorBoundary';
import { createApolloClient } from 'Source/apollo';

interface AppProps {
  history: History;
}

const App: React.FC<AppProps> = ({ history }) => {
  useHiddenOutline();

  const client = React.useMemo(() => createApolloClient(history), [history]);
  return (
    <ErrorBoundary fallbackStrategy="passthrough">
      <ApolloProvider client={client}>
        <AuthProvider>
          <Router history={history}>
            <UIProviders>
              <Routes />
            </UIProviders>
          </Router>
        </AuthProvider>
      </ApolloProvider>
    </ErrorBoundary>
  );
};

export default App;
