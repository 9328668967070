/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Box, Flex, IconButton } from 'pouncejs';
import { useDataExplorerContext } from '../../DataExplorerContext';
import { useListColumnsForTable } from './graphql/listColumnsForTable.generated';
import ColumnListItem from './ColumnListItem';

const ColumnList: React.FC = () => {
  const {
    state: { selectedTable, selectedDatabase, selectedColumn, searchValue },
    dispatch,
  } = useDataExplorerContext();

  const { data } = useListColumnsForTable({
    fetchPolicy: 'cache-only', // will be there from the table listing. Throw if it's not
    variables: {
      input: {
        databaseName: selectedDatabase,
        tableName: selectedTable,
      },
    },
  });

  const selectColumn = React.useCallback(
    column => dispatch({ type: 'SELECT_COLUMN', payload: { column } }),
    [dispatch]
  );

  return (
    <React.Fragment>
      <Flex align="center" mx={3} as="h4" spacing={3}>
        <IconButton
          size="small"
          variant="ghost"
          variantBorderStyle="circle"
          icon="arrow-back"
          onClick={() => dispatch({ type: 'SELECT_TABLE', payload: { table: null } })}
          aria-label="Back to table list"
        />
        <Box fontWeight="bold" as="span">
          {selectedTable}
        </Box>
      </Flex>
      <Box overflowY="auto" overflowX="hidden" as="ul" ml={4} height={285}>
        {data?.dataLakeDatabaseTable?.columns
          .filter(({ name }) => name.includes(searchValue))
          .map(({ name, type, description }) => {
            return (
              <ColumnListItem
                key={name}
                name={name}
                type={type}
                description={description}
                isSelected={selectedColumn === name}
                isPristine={selectedColumn === null}
                onClick={selectColumn}
              />
            );
          })}
      </Box>
    </React.Fragment>
  );
};

export default ColumnList;
