/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Lookup } from 'Generated/schema';
import ConfirmModal from 'Components/modals/ConfirmModal';
import { useSnackbar } from 'pouncejs';
import { extractErrorMessage } from 'Helpers/utils';
import { useDeleteLookups } from '../graphql/deleteLookups.generated';

export interface DeleteSavedQueriesModalProps {
  id: Lookup['id'];
  onConfirm?: () => void;
}

const DeleteSavedQueriesModal: React.FC<DeleteSavedQueriesModalProps> = ({
  id,
  onConfirm = () => {},
  ...rest
}) => {
  const { pushSnackbar } = useSnackbar();
  const [deleteLookup] = useDeleteLookups({
    update: cache => {
      cache.evict({ id: cache.identify({ __typename: 'Lookup', id }) });
      cache.gc();
    },
    onCompleted: () => {
      onConfirm();
      pushSnackbar({
        variant: 'success',
        title: 'Lookup deleted successfully',
      });
    },
    onError: error => {
      pushSnackbar({
        variant: 'error',
        title: `Failed to delete lookup`,
        description: extractErrorMessage(error),
      });
    },
  });

  const onDelete = React.useCallback(
    () =>
      deleteLookup({
        variables: { input: { ids: [id] } },
      }),
    [id, deleteLookup]
  );

  return (
    <ConfirmModal
      subtitle="Are you sure you want to delete the selected Lookup?"
      onConfirm={onDelete}
      variant="destructive"
      {...rest}
    />
  );
};

export default DeleteSavedQueriesModal;
