/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { FastField, Form, Formik } from 'formik';
import { Box, Button, Card, Flex, Popover, PopoverContent, PopoverTrigger } from 'pouncejs';
import { ListAnalysisPacksInput } from 'Generated/schema';
import useRequestParamsWithoutPagination from 'Hooks/useRequestParamsWithoutPagination';
import isUndefined from 'lodash/isUndefined';
import TextButton from 'Components/buttons/TextButton';
import FormikCombobox from 'Components/fields/ComboBox';
import PopoverAutoSubmit from 'Components/PopoverAutoSubmit';

export type ListPacksDropdownFiltersValues = Pick<
  ListAnalysisPacksInput,
  'updateAvailable' | 'enabled'
>;

const defaultValues = {
  updateAvailable: null,
  enabled: null,
};

const booleanFilterOptions = [true, false];
const booleanFilterToString = (item: boolean | null) => {
  if (item === null) {
    return '';
  }

  return item ? 'Yes' : 'No';
};

const DropdownFilters: React.FC = () => {
  const { requestParams, updateRequestParams } = useRequestParamsWithoutPagination<
    ListPacksDropdownFiltersValues
  >();

  const initialDropdownFilters = React.useMemo(
    () =>
      ({
        ...defaultValues,
        ...requestParams,
      } as ListPacksDropdownFiltersValues),
    [requestParams]
  );

  const filtersCount = Object.keys(defaultValues).filter(key => !isUndefined(requestParams[key]))
    .length;

  return (
    <Popover>
      {({ close: closePopover, isOpen }) => (
        <React.Fragment>
          <PopoverTrigger
            data-tid="packs-dropdown-filters"
            as={Button}
            iconAlignment="right"
            icon="filter-light"
            size="large"
            aria-label="Pack Options"
          >
            Filters {filtersCount ? `(${filtersCount})` : ''}
          </PopoverTrigger>
          <PopoverContent>
            <Card
              shadow="dark300"
              my={14}
              p={6}
              pb={4}
              backgroundColor="navyblue-400"
              width={425}
              data-testid="dropdown-pack-listing-filters"
            >
              <Formik<ListPacksDropdownFiltersValues>
                enableReinitialize
                onSubmit={(values: ListPacksDropdownFiltersValues) => {
                  updateRequestParams(values);
                }}
                initialValues={initialDropdownFilters}
              >
                {({ setValues, values }) => (
                  <Form>
                    <PopoverAutoSubmit<ListPacksDropdownFiltersValues>
                      isOpen={isOpen}
                      values={values}
                      onSubmit={updateRequestParams}
                    />
                    <Box pb={4}>
                      <FastField
                        name="updateAvailable"
                        as={FormikCombobox}
                        items={booleanFilterOptions}
                        itemToString={booleanFilterToString}
                        label="Update Available"
                        placeholder="Only show packs with an available update?"
                      />
                    </Box>
                    <Box pb={4}>
                      <FastField
                        name="enabled"
                        as={FormikCombobox}
                        items={booleanFilterOptions}
                        itemToString={booleanFilterToString}
                        label="Enabled"
                        placeholder="Only show enabled packs?"
                      />
                    </Box>

                    <Flex direction="column" justify="center" align="center" spacing={4}>
                      <Box>
                        <Button onClick={closePopover}>Apply Filters</Button>
                      </Box>
                      <TextButton role="button" onClick={() => setValues(defaultValues)}>
                        Clear Filters
                      </TextButton>
                    </Flex>
                  </Form>
                )}
              </Formik>
            </Card>
          </PopoverContent>
        </React.Fragment>
      )}
    </Popover>
  );
};

export default React.memo(DropdownFilters);
