/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { ExecuteDataLakeQueryOutput } from 'Generated/schema';
import { MutationTuple, ApolloCache, MutationHookOptions } from '@apollo/client';
import useAsyncQueryContext from 'Hooks/useAsyncQueryContext';
import { extractErrorMessage } from 'Helpers/utils';

export interface UseAsyncQueryExecutionProps<Data, Variables, Context, Cache> {
  mutation: (
    options?: MutationHookOptions<Data, Variables, Context, ApolloCache<Cache>>
  ) => MutationTuple<Data, Variables, Context, ApolloCache<Cache>>;
  getResponse: (data: Data) => ExecuteDataLakeQueryOutput;
}

function useAsyncQueryExecution<
  Data = any,
  Variables extends { input: any } = any,
  Context = any,
  Cache = any
>({ mutation, getResponse }: UseAsyncQueryExecutionProps<Data, Variables, Context, Cache>) {
  const { dispatch } = useAsyncQueryContext();
  const [runQuery, { loading: isProvisioningQuery }] = mutation({
    onCompleted: data => {
      const response = getResponse(data);
      dispatch({
        type: 'QUERY_RUNNING',
        payload: { queryId: response.id },
      });
    },
    onError: error => {
      dispatch({ type: 'QUERY_ERRORED', payload: { message: extractErrorMessage(error) } });
    },
  });

  const executeQuery = React.useCallback(
    (variables: Variables) => {
      dispatch({ type: 'QUERY_PROVISIONING' });
      return runQuery({ variables });
    },
    [runQuery, dispatch]
  );

  return { executeQuery, isProvisioningQuery };
}

export default useAsyncQueryExecution;
