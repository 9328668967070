/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Text, AbstractButton } from 'pouncejs';

interface CollapsableCellProps {
  text: string;
  hideText: string;
  showText: string;
  sliceOn?: number;
}

const CollapsableCell: React.FC<CollapsableCellProps> = ({
  text,
  sliceOn = 100,
  hideText,
  showText,
}) => {
  const [showSnippet, setShowSnippet] = React.useState(true);

  const isTextLong = text.length > sliceOn;
  const snippet = React.useMemo(() => text.slice(0, sliceOn), [text, sliceOn]);

  if (!isTextLong) {
    return <Text wordBreak="break-all">{text} </Text>;
  }
  return (
    <>
      {showSnippet ? (
        <Text wordBreak="break-all">
          {snippet}{' '}
          <AbstractButton ml={1} color="blue-200" onClick={() => setShowSnippet(false)}>
            {showText}
          </AbstractButton>
        </Text>
      ) : (
        <Text wordBreak="break-all" color="gray-300">
          {text}
          <AbstractButton ml={1} color="blue-200" onClick={() => setShowSnippet(true)}>
            {hideText}
          </AbstractButton>
        </Text>
      )}
    </>
  );
};

export default React.memo(CollapsableCell);
